// Customizable Area Start
import React from "react";
import CustomerReviewController, {Props} from "./CustomerReviewController";
import CustomerReviewForm from "./CustomerReviewForm";

// Customizable Area End

export default class CustomerReview extends CustomerReviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
    // Customizable Area Start
     <div>
        <CustomerReviewForm {...this.props} />
     </div>
    // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
