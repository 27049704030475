import React from 'react'
import CustomerController, {Props} from '../../../../blocks/catalogue/src/CustomerController'
import { Popover, TableCell, Typography, Button, Dialog, DialogContent, DialogContentText, withStyles } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import ConfirmationDialog from '../ConfirmationDialog.web';

export class MenuPopover extends CustomerController {
  constructor(props: Props) {
    super(props);
  }
  render() {

    // @ts-ignore
    const { t } = this.props;
    // @ts-ignore
    const { classes,status, customerDetails } = this.props;

    const id = this.state.anchorEl ? "simple-popover" : undefined;
    const name = `${customerDetails.attributes.first_name} ${customerDetails.attributes.last_name}`

    const setIsDialogOpen = (value:boolean) => {
      this.setState({isDialogOpen:value})
    }
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      if (status === "invite_sent") {
        return;
      }
      const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
      const userRole = userData.role || '';
      const currentPage = window.location.href.split('/').pop();
      if ((currentPage === "Customers") && ((userRole !== "Finance") && (userRole !== "finance") && (userRole !== "operations") && (userRole !== "Operations"))) {
        this.setState({anchorEl:event.currentTarget});
      }
      if (currentPage !== "Customers") {
        this.setState({anchorEl:event.currentTarget});
      }
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      this.setState({anchorEl:null});
    };

    const handleUserStatus = (status:string,userDetails: any) => {
        
      const userStatus = status;

      let updatedUserStatus = ""
      if (userStatus === "active") {
          updatedUserStatus = "deactivated"
      } else if (userStatus === "inactive") {
          updatedUserStatus = "sendInvite"
      } else if (userStatus === "deactivated") {
          updatedUserStatus = "active"
      }
      const alertMessage = (userStatus === "inactive") ? "Invite sent" : (userStatus === "deactivated") ? "Account reactivated" : "Account deactivated";

      const accountId = userDetails?.attributes?.account_id;
      this.updateUserStatus(accountId, updatedUserStatus);
      this.setState({
        showAlert:true,
        alertMessage:alertMessage,
        alertType:'success'
      });
  }


    return (
      <TableCell
      className={classes.tableColumn}
      style={{ width: "20px" }}
    >
      <Typography
        aria-describedby={id}
        // id={rowData.id}
        onClick={handleClick}
      >
        <MoreVert />
      </Typography>
      <Popover
          id={id}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={handleClose}
          anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
          }}
          transformOrigin={{
              vertical: "top",
              horizontal: "right",
          }}
          className={classes.popoverContainer}
          >
          <Typography onClick={() => setIsDialogOpen(true)} className={classes.popoverContent}>
            {status === "inactive" ? t("send_invite") : status === "deactivated"? t('reactivate_account') : t('deactivate_account')}
          </Typography>
      </Popover>
      <ConfirmationDialog
          openDialog={this.state.isDialogOpen} 
          headingText={status === "inactive" ?  t("send_invite") : status === "deactivated"? t('reactivate_account') : t('deactivate_account')}
          paragraphText= {status === "inactive" ? (
            `${t('are_you_sure_you_want_to_invite')} ${name} ${t('to_create_an_account')}`
          ) : status === "deactivated" ? (
            `${t('are_you_sure_you_want_to_reactivate_the_account_for')} ${name}?`
          ) : (
            `${t('are_you_sure_you_want_to_deactivated_the_account_for')} ${name}?`
          )}
          btnCancelText={t('cancel')}
          btnOkText={status === "inactive" ?  t("invite") : status === "deactivated"? t('reactivate_account') : t('deactivate_account')}
          handleOk={() => handleUserStatus(status,customerDetails)}
          handleCancel={() => setIsDialogOpen(false)}
       />
    </TableCell>
    )
  }
}
const useStyles = ({
  tableColumn: {
      "border-top": "1px solid grey",
      "border-bottom": "1px solid grey",
      "padding": "12px",
      "text-align": "center",
    },
  popoverContainer: {
      "& :nth-of-type(3)": {
        "box-shadow": "0 0px 2px lightgray",
        "margin-top": "5px",
      },
  },
  dialoagButtonStyle: {
    "display": "flex",
    "justify-content": "space-evenly"
  },
  btnStyle: {
    "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    "text-transform":"none",
    "color": "white",
    "height": "50px",
    "border-radius": "10px",
    "padding-left": "10px",
    "padding-right": "10px"
  },
  popoverContent: {
      "width": "180px",
      "padding": "12px 16px",
      "border-radius": "5px",
      "cursor": "pointer",
  }
});
export default withStyles(useStyles)(withTranslation()(MenuPopover));