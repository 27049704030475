Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TaskAllocator";
exports.labelBodyText = "TaskAllocator Body";
exports.apiMethodTypeAddDetail = "POST";
exports.apiGetAllCustomersList = "/bx_block_customer/customers/?archived=false";
exports.apiGetAllInsurerList = "/bx_block_insurance_companies/insurance_companies?archived=false"
exports.apiGetOperationsList = "/bx_block_policies/policies/get_operations"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End