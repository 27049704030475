import React from 'react';
import { InfoRounded } from '@material-ui/icons';
import { makeStyles, createStyles, useTheme, withStyles, createTheme } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

interface LabelWithIconProps {
  label: string,
  tooltip?: string
}

const LabelWithIcon = (props: LabelWithIconProps) => {

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });

  const CustomToolTip = withStyles({
    arrow: {
      "&::before": {
        color: "white"
      }
    },
    tooltip: {
      backgroundColor: "#f5f5f9",
      boxShadow: theme.shadows[8],
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 14,
      maxWidth: 800
    },
    tooltipPlacementTop: {
      margin: "4px 0"
    }
  })(Tooltip);

  const classes = useStyles();
  const isArabicLenguage = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
  
  const getLabel = () => {
    if (isArabicLenguage) {
      return ( 
        <label className={classes.labelStyle} aria-controls="cutomerName" >
          {props.tooltip && (
            // @ts-ignore
            <CustomToolTip title={props.tooltip} placement="top-start" arrow>
              <InfoRounded className={classes.iconStyle} />
            </CustomToolTip>
          )}
          {props.label}
        </label>
      )
    } else {
      return (
        <label className={classes.labelStyle} aria-controls="cutomerName" >{props.label}
          {props.tooltip && (
            // @ts-ignore
            <CustomToolTip title={props.tooltip} placement="top-start" arrow>
              <InfoRounded className={classes.iconStyle} />
            </CustomToolTip>
          )}
        </label>
      )
    }
  }
  return (
    <React.Fragment>
      {getLabel()}
    </React.Fragment>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    labelStyle: {
      display: 'flex',
      fontSize: '14px',
      paddingBottom: "10px"
    },
    iconStyle: {
      fontSize: "17px",
      marginLeft: "5px"
    }
  })
)

export default LabelWithIcon