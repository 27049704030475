// Customizable Area Start
import getStageNameByNumber, { getStageNumberByName } from "../../../../../components/src/Common/PolicyRequest/StagesName";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import * as Yup from "yup";

const enumBtnType = {
  NEXT: "NEXT",
  DRAFT: "DRAFT",
  REJECT: "REJECT",
};
const enumRoles = {
  ADMIN: "Admin",
  OPERATIONS: "Operations",
  CONTACT: "Contact",
};

export interface Props {
  navigation?: any;
  id?: string;
  isExpanded?: boolean;
  isEdit?: boolean;
  onNext?: any;
  onReject?: any;
  onDraft?: any;
  t?: any;
  classes?: any;
  stageTwoData?:any;
  stageSixData?:any;
  setStageData?:any;
  stageOneData?:any;
  stageSevenData?:any;
  stageEightData?:any;
}

export interface S {
  loading: boolean;
  proofofPayement: any;
  policyId: string;
  policyActiveDate: string;
  policyExpiryData: string;
  contactName: any;
  email: string;
  emailSubject: string;
  emailDraft: string;
  markAsSubmitted: boolean;
  emailSent: string;
  additionalDocAttachments: any;
  currentFileName: string,
  currentFileUrl: any;
  currentAdditionalDoc?: any;
  expandedAccordion: string;
  showRejectDialog: boolean;
  isArabicLenguage: boolean;
  showAlert: boolean;
  alertMessage: string;
  alertType: string;
  errorSection: string;
  showNoteDialog?: boolean;
  isBtnVisible?: boolean;
  isMultiUpload: boolean;
  handOverNoteValue?: string;
  loggedInRole: string
  rejectedStage: string;
  rejectReason: string;
  hasViewPermissionList: any;
  insuranceType: string;
  customerName: string;
  approvedCompanyId: any;
  approvedCompanyName: string;
  isEdit:boolean;
  isStageSubmited:boolean;
  poliycRequestId:string;
  saveDraftLoading?:boolean;
  submitApiResponse: any;
}

interface SS {
  id: any;
}

export default class StageEightController extends BlockComponent<Props, S, SS> {

  uploadFileApiCall: any = "";
  formikRef: any = null;
  fieldName: string = ''
  fileToRemove: number = 0
  goToNextStepApi: any = "";
  rejectPolicyApi: any = "";
  getPolicyDetailsAPI: any = "";
  stageTwoApiCall:any = "";
  stageEightSubmitApi:any = "";
  saveDraftPolicyApi:any = "";
  removeFileAPI:any = "";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    const queryParameter: any = new URLSearchParams(window.location.search);
    const policyId: string = queryParameter?.get('id');

    const stageOne = JSON.parse(window.localStorage.getItem('stageOne') || `{}`)
    const loginData = JSON.parse(window.localStorage.getItem("loginData") || "{}")

    this.state = {
      loading: false,
      proofofPayement: "",
      policyId: policyId || "",
      policyActiveDate: "",
      policyExpiryData: "",
      poliycRequestId:"",
      contactName: "",
      email: "",
      emailSubject: "Welcome to SIIB",
      emailDraft: "",
      markAsSubmitted: false,
      emailSent: "",
      isStageSubmited:this.props.stageSevenData?.attributes?.is_next_stage_submitted || false,
      expandedAccordion: "p8",
      additionalDocAttachments: [],
      currentFileUrl: "",
      currentFileName: "",
      currentAdditionalDoc: [],
      showRejectDialog: false,
      handOverNoteValue: "",
      loggedInRole: loginData?.role || "",
      isArabicLenguage:
        (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      showAlert: false,
      alertMessage: "",
      alertType: "",
      errorSection: "",
      showNoteDialog: false,
      isBtnVisible: true,
      isMultiUpload: false,
      rejectedStage: "",
      rejectReason: "",
      hasViewPermissionList: [],
      insuranceType: stageOne && stageOne.stageOne?.insuranceType || "", // NOSONAR: typescript:S6582
      customerName: stageOne && stageOne.stageOne?.customer || "",// NOSONAR: typescript:S6582
      approvedCompanyId: "",
      approvedCompanyName:"",
      saveDraftLoading:false,
      isEdit:false,
      submitApiResponse: {}
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Setting up handover note text
    this.setState({expandedAccordion: this.props.isExpanded ? 'p8' : ''})
    /*istanbul ignore next*/
    if(this.props.stageEightData.submitApiResponse){
      this.setState({
        proofofPayement:this.props?.stageEightData?.submitApiResponse?.attributes?.issuance_insurance_infos?.data?.attributes?.policy_details,
        markAsSubmitted:this.props?.stageEightData?.submitApiResponse?.attributes?.issuance_insurance_infos?.data?.attributes?.mark_as_submitted,
        emailSent:this.props.stageEightData?.submitApiResponse?.attributes?.issuance_insurance_infos?.data?.attributes?.email_sent ,
      })
    }

    // Calling get API is ID found in URL
    const queryParameter: any = new URLSearchParams(window.location.search);
    const policyId: string = queryParameter?.get('id') || this.props.stageOneData?.policyId;
    if (policyId) {
      this.setState({policyId:policyId})
    }

  }
  componentDidUpdate() {
    if (this.state.showAlert) {
      setTimeout(() => {
        this.setState({
          showAlert: false,
          alertType: "",
          alertMessage: "",
        });
      }, 10000);
    }
  }
  operationSchema = (isArabicLanguage: boolean) => {
    const schema = {
      proofofPayement: Yup.mixed().required(isArabicLanguage ? 'يرجى تحميل مستند إثبات الدفع' : "Please upload proof of payment document"),
      policyActiveDate: Yup.date().required(isArabicLanguage ? "تاريخ تفعيل السياسة مطلوب": "Policy activation date required"),
      policyExpiryData: Yup.date().required(isArabicLanguage ? "تاريخ انتهاء السياسة مطلوب" : "Policy expire date required"),
      markAsSubmitted: Yup.boolean().test('is-checked', isArabicLanguage ? "يجب عليك التحقق من هذه المدخلات" : 'You must check this input', (value) => value === true),
      emailSent: Yup.string().required(!isArabicLanguage ? "Please enter email sent text" : "الرجاء إدخال نص البريد الإلكتروني المرسل"),
      additionalDocAttachments: Yup.array().min(1, isArabicLanguage ? 'يجب تحميل ملف واحد على الأقل باللغة العربية.' : "Atleast one file should be uploaded"),
    };
    return schema;
  };
  handleCopyClick = (ref: any) => {
    if (ref.current) {
        const textField = ref.current.querySelector('input') || ref.current.querySelector('textarea');
        if (textField) {
          textField.select();
          document.execCommand('copy'); // NOSONAR typescript:S1874
        }
      
    }
  };
  isAaccordionOpen = (isExpanded: any) => {
    const queryParameter: any =
      typeof window !== "undefined" &&
      new URLSearchParams(window.location.search);
    const id: string = queryParameter.get("id");
    if (this.state.loggedInRole === enumRoles.CONTACT) {
      return false;
    }
    if (isExpanded === true && this.state.expandedAccordion === "p8") {
      return true;
    }
    if (id !== "" && this.state.expandedAccordion === "p8") {
      return true;
    }
    return false;
  }
  handleAccordian = (isExpanded: boolean, accordionName: string) => {
    if (this.state.loggedInRole === enumRoles.CONTACT) {
      this.setState({
        alertMessage: `Insurance stage is not accessible to ${this.state.loggedInRole} role. Please login with other role. `,
        alertType: "warning",
        showAlert: true,
      });
    } else if (this.state.policyId !== "" && this.hasOnlyViewRights()) {
      this.setState({
        expandedAccordion: isExpanded ? accordionName : "", showAlert:false
      });
    } else {
      this.setState({
        expandedAccordion: isExpanded ? accordionName : "",
      });
    }
  };
  hasOnlyViewRights = () => {
    return this.state.hasViewPermissionList?.includes(
      this.state.loggedInRole
    ) as boolean;
  };

  setArabicLanguage = (value: boolean) => {
    const language = value === true ? "ar" : "en";
    window.localStorage.setItem("lang", language);
    this.setState({
      isArabicLenguage: value,
    });
  };

  uploadFileStageOne = (fileName: string, fileData: any, policyId: any, isMultiUpload: boolean, formikRef: any = null, fieldName: string = '') => {

    
    this.formikRef = formikRef
    this.fieldName = fieldName

    this.setState({
        currentFileName: fileName,
        isMultiUpload: isMultiUpload,
        errorSection:""
    });

   
    const formData = this.getFormData(fileName, fileData, policyId, isMultiUpload);

    const userData = JSON.parse(localStorage.getItem('loginData') || '{}');
    const token = userData.token || '';


    const header = {
      token
    };
    const fileUploadRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadFileApiCall = fileUploadRequestMessage.messageId;

    fileUploadRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    fileUploadRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_policy_request/policy_requests/${policyId}/file_upload`
    );
    fileUploadRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );

    fileUploadRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(fileUploadRequestMessage.id, fileUploadRequestMessage);
    return fileUploadRequestMessage.messageId;
  }
  removeFile = (fileId: number, formikRef: any) => {
    this.formikRef = formikRef
    this.fileToRemove = fileId
    this.removeFileAPI = this.apiCall({
        contentType: 'application/json',
        method: 'PUT',
        body: { data: { file_id: fileId } },
        endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/remove_file`,
    })
}
  getStepName = getStageNameByNumber;

  getStepNumber =getStageNumberByName;

  submitStageEight = (formData:any) => {
    
    
    const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
    const token = userData.token || '';
    const stageSevenData = this.props.stageSevenData;
    const editPolicy = (stageSevenData &&  stageSevenData.attributes?.is_next_stage_submitted) || false; // NOSONAR: typescript:S6582
    this.setState({isStageSubmited:editPolicy})

    this.setState({
      contactName: formData.contactName,
      markAsSubmitted: formData.markAsSubmitted,
      emailSent: formData.emailSent,
      policyActiveDate: formData.policyActiveDate,
      policyExpiryData: formData.policyExpiryData,
      approvedCompanyId: formData.approvedCompanyId
    })
    this.stageEightSubmitApi = this.apiCall({
      contentType: 'application/json',
      method: editPolicy ? 'PUT' :'POST',
      endPoint: editPolicy ? `/bx_block_policy_request/policy_requests/${this.state.policyId}?stage=8` : `/bx_block_policy_request/policy_requests?stage=8&id=${this.state.policyId}`,
      token: token || '',
      body: {
      "data": {
        "issuances": {
          "issuance_insurance_infos_attributes":{
            "insurance_company_id": formData.approvedCompanyId,
            "customer_contact_id": formData.contactName.value,
            "mark_as_submitted": formData.markAsSubmitted,
            "email_sent":formData.emailSent,
            "policy_activation_date": formData.policyActiveDate,
            "new_policy_expiry_date": formData.policyExpiryData
          },
          "save_as_draft":"no",
        }
      }
    }
    })
  }
  goToNextStep = (note: string) => {
    const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
    const token = userData.token || '';
    this.goToNextStepApi = this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/track_requests`,
      token: token || '',
      body: {
        "data": {
          "track_request": {
            "stage_status": "next_stage", // reject_stage, next_stage
            "current_stage": "invoicing",  // change into 
            "comment": note
          }
        }
      }
    })
  }
  rejectPolicy = (goToStep: string, comment: string) => {
    const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
    const token = userData.token || '';
    this.rejectPolicyApi = this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: `/bx_block_policy_request/policy_requests/${this.state.policyId}/track_requests?stage=8`,
      token: token || '',
      body: {
        "data": {
          "track_request": {
            "stage_status": "reject_stage",
            "comment": comment,
            "current_stage": goToStep
          }
        }
      }
    });
  }

  openNoteDialog = () => {
    this.setState({ showNoteDialog: true, isBtnVisible: false });
  };


  saveDraftData = async (formikRef: any) => {
    const formData=formikRef?.current?.values
    const stageSevenData = this.props.stageSevenData;
    const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
    const editPolicy = (stageSevenData &&  stageSevenData.attributes?.is_next_stage_submitted) || false; // NOSONAR: typescript:S6582
    const token = userData.token || '';
    this.setState({
      approvedCompanyId: formData.approvedCompanyId,
      isStageSubmited:editPolicy,
      markAsSubmitted: formData.markAsSubmitted,
      contactName: formData.contactName,
      policyActiveDate: formData.policyActiveDate,
      emailSent: formData.emailSent,
      policyExpiryData: formData.policyExpiryData,
    })
    this.saveDraftPolicyApi = this.apiCall({
      method: editPolicy ? 'PUT' :'POST',
      contentType: 'application/json',
      token: token || '',
      endPoint: editPolicy ? `/bx_block_policy_request/policy_requests/${this.state.policyId}?stage=8` : `/bx_block_policy_request/policy_requests?stage=8&id=${this.state.policyId}`,
      body: {
      "data": {
        "issuances": {
          "issuance_insurance_infos_attributes":{
            "customer_contact_id": formData.contactName.value,
            "insurance_company_id": formData.approvedCompanyId,
            "email_sent":formData.emailSent,
            "mark_as_submitted": formData.markAsSubmitted,
            "new_policy_expiry_date": formData.policyExpiryData,
            "policy_activation_date": formData.policyActiveDate,
          },
          "save_as_draft":"yes",
        }
      }
    }
    })
  };

  handleRejectDialoug = (value: boolean) => {
    this.setState({ showRejectDialog: value, isBtnVisible: true });
  };

  getFormData = (file_key: string, fileData: any, policyId: any, isMultiUpload: boolean) => {
    const stageEightFileUploadData = new FormData();

    stageEightFileUploadData.append("data[stage]", "8");
    stageEightFileUploadData.append("data[file_key]", file_key);

    if (isMultiUpload) {
      stageEightFileUploadData.append(`data[multiple]`, 'true');
      stageEightFileUploadData.append("data[is_already_existing]", "true");
      for (const file of fileData) {
        stageEightFileUploadData.append(`data[file][]`, file);
      }
    } else {
      stageEightFileUploadData.append(`data[multiple]`, 'false');
      stageEightFileUploadData.append("data[file]", fileData);
    }

    return stageEightFileUploadData;
  }

  getPoicyStageOption = () => {
    const array = [
      { value: "info_gathering", label: this.props.t('info_gathering') },
      { value: "processing", label: this.props.t('processing') },
      { value: "awaiting", label: this.props.t('awaiting') },
      { value: "operations_review", label: this.props.t('operations_review') },
      { value: "sales_review", label: this.props.t('sales_review') },
      { value: "customer_review", label: this.props.t('customere_review') },
      { value: "payment_review", label: this.props.t('payment_review') },
    ];
    return array;
  };

  getStageEightAlertMessage = (isDraft: boolean, reject: boolean = false) => {
    const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
    let alertMessage = "";
    if (isDraft) {
      alertMessage = isArabic ? "تم حفظ بيانات طلب المراجعة كمسودة!" : "Data has been saved as draft!"
    } 
    else if(!isDraft && reject) {
      alertMessage = isArabic ? "لقد تم رفض طلبك إلى المرحلة المحددة" : "Your request has been reject to selected stage!"
    }
    else {
      alertMessage = isArabic? "لقد تم نقل طلبك إلى مرحلة الفواتير" : "Your request has been moved to invoicing stage!"
    }
    return alertMessage
  }

  setApiErrorMessage = (responseJson:any) => {
    if (responseJson.errors) {
      const errorMessage = typeof(responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error  || "Something went wrong";
      this.setState({
        alertType: "error",
        alertMessage: errorMessage,
        showAlert: true,
        loading: false,
        saveDraftLoading:false,
        rejectedStage:"",
        showRejectDialog:false,
        rejectReason:"",
        showNoteDialog:false,
      })
    }
  }

  handleHandOverNote = (value:boolean) => {
    this.setState({showNoteDialog:value,handOverNoteValue:"", isBtnVisible: true})
  }

  handleOkDialog = (value: string) => {
    this.goToNextStep(value);
}

  getFormInitialValues = (stateEightData:any) => {
    const formData = {
      proofofPayement: stateEightData.proofofPayement||this.state.proofofPayement,
      policyId: this.state.policyId,
      emailSubject : "Welcome to SIIB",
      policyActiveDate: stateEightData.policyActiveDate || this.state.policyActiveDate,
      policyExpiryData: stateEightData.policyExpiryData || this.state.policyExpiryData,
      contactName: stateEightData.contactName || this.state.contactName,
      email: stateEightData.email || this.state.email,
      emailDraft: 
`Dear Valued Client,

Greetings

We would like to take this opportunity and welcome you as one of our clients at SIIB.

For daily operations (Addition, Deletion, Amendment), all you requests should be sent to Care@siib.com.sa

Best regards`,
      markAsSubmitted: stateEightData.markAsSubmitted || this.state.markAsSubmitted,
      emailSent: stateEightData.emailSent || this.state.emailSent,
      additionalDocAttachments: stateEightData.additionalDocAttachments || this.state.additionalDocAttachments,
    }
    return formData
  }
  apiCall(data: any) {
    const { contentType, method, endPoint, body } = data;
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const requestMessageForStageEight = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForStageEight.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageForStageEight.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForStageEight.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessageForStageEight.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(requestMessageForStageEight.id, requestMessageForStageEight);
    return requestMessageForStageEight.messageId;
  }
/* istanbul ignore next*/
  getFileUploadResponce = (responseJson: any) => {
    if (this.state.isMultiUpload && this.formikRef &&  this.formikRef.current) {
      const newData = {currentAdditionalDoc:responseJson.data.uploaded_file}
      this.formikRef.current.setFieldValue(`additionalDocAttachments`, responseJson.data.uploaded_file)
      this.setState(newData)
    } else {
      this.formikRef.current && this.formikRef.current.setFieldValue(this.fieldName, responseJson.data.uploaded_file) // NOSONAR: typescript:S6582
      }
    const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
    this.setState({
      currentFileUrl: responseJson.data.uploaded_file || "",
      showAlert: true,
      alertMessage: isArabic ? "تم تحميل الملف بنجاح" : "File successfully uploaded",
      alertType: "success"
    })
  }

  fileUploadReceive = (message:any) =>{
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.uploadFileApiCall != null &&
      this.uploadFileApiCall ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.getFileUploadResponce(responseJson)
      } else {        
        this.setApiErrorMessage(responseJson)
      }
    }
  }
  goToNextStepApiReceive = (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.goToNextStepApi !== null &&
      this.goToNextStepApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({
          showAlert: true,
          alertMessage: this.getStageEightAlertMessage(false),
          alertType: "success",
          loading: false,
          expandedAccordion: '',
          showNoteDialog: false
        })
        
        this.props.onNext(8);
      } else {
        this.setApiErrorMessage(responseJson)
      }
    }
  }

  handleHandOverNoteText = (inputText: string) => {
    this.setState({ handOverNoteValue: inputText })
}

  rejectPolicyApiReceive = (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.rejectPolicyApi !== null &&
      this.rejectPolicyApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({
          showAlert: true,
          alertMessage: this.getStageEightAlertMessage(false, true),
          alertType: "success",
          showRejectDialog: false,
          loading: false
        })
        const stageNumber = this.getStepNumber(this.state.rejectedStage)
        this.props.onReject(stageNumber);

      } else {
        this.setApiErrorMessage(responseJson)
      }
    }
  }
  stageEightSubmitApiReceive = (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.stageEightSubmitApi !== null &&
      this.stageEightSubmitApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({
          showNoteDialog: true,
          showRejectDialog: false,
          loading: false,
          submitApiResponse: responseJson.data
        })
        window.localStorage.setItem('stageEightData',JSON.stringify(this.state))
        this.props.setStageData('stageEightData', this.state);

      } else {
        this.setApiErrorMessage(responseJson)
      }
    }
  }
  saveDraftPolicyApiReceive = (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.saveDraftPolicyApi !== null &&
      this.saveDraftPolicyApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({
          showAlert: true,
          alertMessage: this.getStageEightAlertMessage(true),
          alertType: "success",
          showRejectDialog: false,
          loading: false,
          isStageSubmited:true,
          saveDraftLoading:false
        });
      } else {
        this.setApiErrorMessage(responseJson)
      }
    }
  }

  removeFileAPIReceive = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.removeFileAPI !== null &&
      this.removeFileAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.data) {
        const updatedAdditionalDocs = this.formikRef?.current?.values?.additionalDocAttachments?.filter((doc: { id: number; url: string }) => doc.id !== this.fileToRemove)
        this.formikRef.current.setFieldValue(`additionalDocAttachments`, updatedAdditionalDocs)
        /*istanbul ignore next*/
        this.fieldName=="proofofPayement"&&this.formikRef.current.setFieldValue("proofofPayement",{})
        this.setState({
          alertMessage: responseJson?.data?.message,
          alertType: 'warning',
          showAlert: true
        })
      }
      else {
        this.setApiErrorMessage(responseJson)
      }
    }
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.fileUploadReceive(message);
    this.goToNextStepApiReceive(message);
    this.rejectPolicyApiReceive(message);
    this.stageEightSubmitApiReceive(message);
    this.saveDraftPolicyApiReceive(message);
    this.removeFileAPIReceive(message);
  }

}
// Customizable Area End