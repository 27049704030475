import React, { useEffect, useState, useRef } from 'react';
import { List} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconDashboard from '@material-ui/icons/Dashboard'
import IconShoppingCart from '@material-ui/icons/ShoppingCart'
import IconPeople from '@material-ui/icons/People'
import IconBarChart from '@material-ui/icons/BarChart'
import IconLibraryBooks from '@material-ui/icons/LibraryBooks'
import AppMenuItems from './AppMenuItems'
import { useTranslation } from 'react-i18next'
const {baseURL} = require('../../../../framework/src/config')

const useStyles = makeStyles({
    sidebarWrapper: {
        // backgroundImage: 'linear-gradient(to bottom, #2d6f8f, #4fb7ba)',
        backgroundColor: '#fff',
        maxWidth: '275px',
        color: '#797979',
        minHeight: '100vh',
        padding: '15px',
        position: 'relative',
        zIndex: 2,
        overflowY: 'auto',
        height: '100%',
        '& svg': {
            color: '#797979',
        },
        '& ::-webkit-scrollbar':{
            width: '5px'
          },
          
          /* Track */
          '& ::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
           
          /* Handle */
          '& ::-webkit-scrollbar-thumb': {
            background: '#888',
          },
          
          /* Handle on hover */
          '& ::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          }
        // '@media(min-width: 700px)': {
        //     width: '180px',
        // }
    },
    navMenuWrapper: {
        // @media only screen and (min-device-width: 481px) and (max-device-width: 700px)'
        '@media(max-width: 700px)': {
            // width: '30px',
            // overflow: 'hidden',
            // '&>a': {
            //     padding: '0px',
            // },
            // '&>div': {
            //     padding: '0px',
            // },
        }
    },
    imgWrap:{
        maxWidth: '150px',
        height: 'auto',
        margin: 'auto',
        marginTop: '-30px',
        '& img':{
            width: '100%',
        }
    }
});

const enumMenums:any = Object.freeze({
    "dashboard": "/Dashboard",
    "analytics": '/Analytics',
    "customers": '/Customers',
    "requests": '/Requests',
    "policies": '/Policies',
    "insurers": '/Insurer',
    "insurers_detail": '/InsurerDetail',
    "settings": '/Settings5',
    "admin_console": '/AdminConsole'
})
const enumMenumsIcons:any = Object.freeze({
    
    "dashboard": '/assets/images/icons/dashboard',
    "analytics": '/assets/images/icons/analytics',
    "customers": '/assets/images/icons/customers',
    "requests": '/assets/images/icons/requests',
    "policies": '/assets/images/icons/policies',
    "insurers": '/assets/images/icons/insurers',
    "insurers_detail": '/assets/images/icons/insurers',
    "settings": '/assets/images/icons/settings',
    "admin_console": '/assets/images/icons/admin-console'
})

interface propsTypes {
    onClickOutside: (val:boolean) => void;
    show: boolean;
}

const SidebarMenu = (props: propsTypes) => {
    const classes = useStyles();
    const ref = useRef<any>({});
    const { t, i18n } = useTranslation();
    // const kp = useContext(ContextData);
    const { onClickOutside } = props;
    const [menuListLocalstorage, setMenuListLocalstorage] = useState<any>([])
    const [appMenues, setAppMenues] = useState<any>([])
    useEffect(() => {
        const tempLocalStorage:any = localStorage.getItem('loginData')
        if(tempLocalStorage !== undefined && tempLocalStorage !== "undefined" && tempLocalStorage !== null && tempLocalStorage !== "" ){
            const temp = JSON.parse(tempLocalStorage)
            setMenuListLocalstorage(temp?.navigation_menu)
        }else{
            handleLoginAgain()
        }

        const interval = setInterval(async () => {
            try {
                const tempLocalStorage:any = localStorage.getItem('loginData')
                if(tempLocalStorage !== undefined && tempLocalStorage !== "undefined" && tempLocalStorage !== null && tempLocalStorage !== "" ){
                    const temp = JSON.parse(tempLocalStorage)
                    setMenuListLocalstorage(temp?.navigation_menu)
                }else{
                    handleLoginAgain()
                }

                const { token } = await JSON.parse(tempLocalStorage);
                const isTokenExpire:any = await fetch(`${baseURL as any}/account_block/accounts/token_expire`, {
                  method: 'GET',
                  headers: {
                    "token": token
                  },
                })
                if (!isTokenExpire.ok) {
                  handleLoginAgain()
                }
              
                const tokenData = await isTokenExpire.json();
                if(!tokenData)handleLoginAgain();
                if(tokenData.data?.is_token_expire)handleLoginAgain()

              } catch (error) {
                console.log(error,"Error for token checking API")
              }
        }, 10000); // 10000 milliseconds = 10 seconds
        return () => {
            clearInterval(interval); // Checking user token is expire or not
        };
    }, [])
    

    const handleLoginAgain = () => {
        window.localStorage.removeItem('loginData');
        window.location.href = '/EmailAccountLoginBlock';
      };

    useEffect(() => {
        const handleClickOutside = (event:any) => {
          if (ref?.current && !ref?.current?.contains(event.target)) {
            if(window.innerWidth < 768){
                onClickOutside && onClickOutside(false);
            }
          }
        };
           
           document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
      }, [ onClickOutside ]);

    useEffect(() => {
        const tempArr: any = [];
        // menuListLocalstorage && menuListLocalstorage?.navigation_menu && menuListLocalstorage?.navigation_menu?.map((item: any) => {
            if(menuListLocalstorage.length !== 0 ){
                menuListLocalstorage && menuListLocalstorage?.map((item: string) => {
                const tempObj = {
                    name: item,
                    link: enumMenums[item],
                    Icon: enumMenumsIcons[item],
                }
            tempArr.push(tempObj)
            })
            }
            
      setAppMenues(tempArr)
    }, [menuListLocalstorage])

    if(!props.show) return null;
    return (
        <>
            <div className={classes.sidebarWrapper} data-testId="cmpWrapper"  role="root" ref={ref}>
                <div className={classes.imgWrap}>
                    <img src="/assets/images/SIIB-PNG.png" alt="" />
                </div>
                <List component="nav" disablePadding className={classes.navMenuWrapper} data-testid="parent">
                    {appMenues.map((item:any, index:any) => (
                        <AppMenuItems {...item} key={index}  data-testid="child" />
                    ))}
                </List>
            </div>
        </>
    )
}

export default SidebarMenu
