Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.ButtonNames = [{title:"all_tasks",filterKey:"",filterIndex:"0"}, {title:"my_task",filterKey:"my_tasks",filterIndex:"1"}, {title:"department_tasks",filterKey:"department_tasks",filterIndex:"2"}, {title:"task_for_others",filterKey:"task_for_others",filterIndex:"3"}];

// Customizable Area End