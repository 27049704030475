import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, CircularProgress, OutlinedInput, Grid, } from '@material-ui/core';
import LabelWithIcon from './LabelWithIcon';
import Select from "react-select";
import { Close } from '@material-ui/icons';


const useStyles = makeStyles({
    dialogWrapper:{
        "& [role='dialog']":{
            padding: '30px',
            borderRadius: '16px',
            width: '800px'
        }
    },
    headingStyle: {
        textAlign: 'center',
        '& h2': {
            fontWeight: 700,
            
        },
        fontSize: "16px"
    },
    paraStyle:{
        textAlign: 'center',
    },
    btnWrapper:{
        justifyContent: 'end',
        '& button': {
            padding: '15px 0px',
            width: '150px',
            borderColor: '#3b8da2',
            borderRadius: '15px',
        }
    },
    primaryBtnStyle: {
      background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
      "text-transform": "none",
      "color": "white"
    },
    cancelBtnStyle: {
      background: "linear-gradient(to bottom, red, #C87674)",
      "text-transform": "none",
      "color": "white",
      borderRadius: "10px"
    },
    textInputStyle: {
        // marginTop: "10px",
        // marginBottom: "10px"
        margin: "4px 0"
    },
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    disableBtn: {
        color: "#fff",
        borderRadius: '8px',
        background: '#757575'
    },
    reasonsSection: {
      marginTop: '25px'
    },
    downloaIcon: {
        cursor: "pointer"
    },
    pushBackBtn: {
      border: '1px solid primary',
      marginTop: '10px'
    },
    contentStyle: {
      overflowY: "visible"
    }
})

type RejectRequestDialogProps = {
    openDialog:boolean,
    headingText: string,
    btnCancelText: string,
    btnOkText: string,
    handleOk?: any;
    handleCancel?:any;
    handleInputText?:any;
    btnLoading?:boolean;
    inputDisable?: boolean;
    inputValue?: string;
    testIDProps?: string;
    subHeading?: string;
    showDialogBtns?: boolean;
    pushBackOptions?:any;
    textId?:string;
    setSelectedPushBackTo?: any;
    showCrossBtn?: boolean;
    stepValue?: any;
    labelText?: string;
    reasonLabel?: string;
}



const RejectRequestDialog = (props:RejectRequestDialogProps) => {
    const classes = useStyles();
    const [selectValue,setSelectValue] = useState("");
    const [reason,setReason] = useState("");
    const [loading,setLoading] = useState(false);
  return (
    <>
        
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title">
           <div className={classes.dialogTitle}>
                <div>
                    <span className={classes.headingStyle}>{props?.headingText}</span>
                    <span className={classes.subHeading}>{props.subHeading}</span>
                </div>
                {props.showCrossBtn ? (<Close
                    className={classes.downloaIcon}
                    onClick={props?.handleCancel}
                />) : <div></div>}
           </div>
        </DialogTitle>
        <DialogContent className={classes.contentStyle}>
            {props.showDialogBtns && <Grid>
            <LabelWithIcon label={props.labelText || ""} />
            <Select
              className={classes.pushBackBtn}
              onChange={(e: any) =>{
                setSelectValue(e.value)
                props.setSelectedPushBackTo(e)
              }}
              options={props.pushBackOptions}
              value={props.stepValue} 
            />
            </Grid>}
           
           <Grid className={props.showDialogBtns ? classes.reasonsSection : ''} item xs={12}>
                <LabelWithIcon label={props.reasonLabel || ""} />
                <OutlinedInput
                    id={props.textId}
                    className={classes.textInputStyle}
                    name={props.textId}
                    value={props.inputValue}
                    type="text"
                    fullWidth
                    multiline={true}
                    disabled={props.inputDisable}
                    minRows={4}
                    data-test-id={props.textId}
                    color='primary'
                    onChange={(e:any) => {
                      setReason(e.target.value);
                      props.handleInputText(e?.target?.value)}
                    } 
                />
           </Grid>
        
        </DialogContent>
        {props.showDialogBtns && <DialogActions className={classes.btnWrapper}>
          <Button variant="outlined" style={{color: '##757575', borderRadius: "8px", textTransform:"none"}} 
          onClick={() => {
            setReason("");
            setSelectValue("");
            setLoading(false);
            props?.handleCancel()
          }} 
          >
            {props.btnCancelText}
          </Button>
          
          <Button variant="contained" 
            disabled={!selectValue || !reason ? true :false} 
            style={{borderRadius: "8px"}} 
            className={((selectValue !== "") && (reason !== "")) ? classes.primaryBtnStyle:classes.disableBtn}
            onClick={() => {
              setLoading(true);
              props?.handleOk()
              setLoading(false)
            }} 
            color="primary" 
            autoFocus
          >
            {loading ? <CircularProgress size={25} /> : props.btnOkText}
          </Button>
        </DialogActions>}
      </Dialog>
    </>
  )
}

export default RejectRequestDialog