// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("../../config.json");

export interface Props {
// Customizable Area Start  
navigation: any;
id: string;
t: any;
classes: any;
importFor: string;
isUploadingCsvFile: boolean,
page: number;
rowPerPage: number;
totalPage: number;
isLoading:boolean;
csvData:object[],
showAlert: boolean,
alertMessage: string;
alertType: string;
errorMessage: string;
errorFile: string;
isSaveBtnClicked: boolean;
handleAnotherCSVUpload: any;
downloadFile: any,
handleSaveCSVUpload: any;
handleCSVUploader: any;
handleChangePage: any;
handleRowPerPage: any;
tableBody: any ;
closeToast: any
// Customizable Area End
}

interface S {
  // Customizable Area Start
  isArabicLanguage: boolean,
  isUploadingCsvFile: boolean,
  page: number;
  rowPerPage: number;
  totalPage: number;
  isLoading:boolean;
  customerCsvData:object[],
  showAlert: boolean,
  alertMessage: string;
  alertType: string;
  errorMessage: string;
  errorFile: string;
  isSaveBtnClicked: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CustomerCsvImportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadCustomerCsvAPIMessage: string = '';
  getUploadedCsvAPIMessage: string = '';
  saveCustomerCsvAPIMessage: string = ''
  fileId: any;
  deleteCsvAPIMessage: string = '';
  sampleCsvCustomerAPIKey : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isArabicLanguage: (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      isUploadingCsvFile: false,
      page: 1,
      rowPerPage: 10,
      totalPage: 1,
      isLoading:false,
      customerCsvData:[],
      alertMessage: '',
      showAlert: false,
      alertType: '',
      errorMessage: '',
      errorFile: '',
      isSaveBtnClicked: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.setUpdateCustomerCsv(message);
  this.setSaveCustomerCsv(message);
  this.fetchCustomerCsvList(message);
  this.deleteUploadedCsvList(message)
  this.receiveDownloadedSampleCsvForCustomer(message)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
  }
  handleCSVUploader=(file: any)=>{
    this.setState({isUploadingCsvFile:true})
    this.uploadCustomerCsv(file)
  }

  closeToast = () => {
    this.setState({ showAlert: false })
  }

  handleAnotherCSVUpload=(file: any)=>{
    this.uploadCustomerCsv(file, this.fileId)
  }
  
  handleSaveCSVUpload=()=>{
    this.setState({isSaveBtnClicked: true})
    this.saveCustomerCsv(this.fileId)
  }

  handleChangePage = (event: any, page: number) => {
    this.setState({ page: page });
  };

  handleRowPerPage = (event: any) => {
    this.setState({ rowPerPage: parseInt(event.target.value) });
    this.setState({ page: 1 });
  };

  downloadFile = (fileUrl: string) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } 

  downloadSampleCsvForCustomer = () => {
    this.sampleCsvCustomerAPIKey = this.apiCall({
      type : 'application/json',
      method: 'GET',
      endPoint: `/bx_block_sample_file/sample_files?name=Customer`,
    })
  }

  setArabicLanguage = (value: boolean) => {
    const language = value === true ? 'ar' : 'en';
    window.localStorage.setItem('lang', language);
    this.setState({
      isArabicLanguage: value
    })
  }
  setAlert = (responseMessage: string,alertType: string = 'error') => {
    this.setState({ showAlert: true, alertMessage: responseMessage, alertType: alertType})
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {
    if (
      (prevState.page !== this.state.page ||
        prevState.rowPerPage !== this.state.rowPerPage)
    ) {
      const {page, rowPerPage} = this.state
      this.getUploadedCsv(page, rowPerPage);
    }

  }

  getFormData = (file :  any, file_id: number| string) => {
    const formData = new FormData();
    file && formData.append('data[file]',file)
    file_id && formData.append('data[file_id]', file_id as string)
    
    return formData
  }

  uploadCustomerCsv = (file: any, file_id?: number) => {
    this.setState({
      isLoading:true
    })
    this.uploadCustomerCsvAPIMessage = this.apiCall({
      type : 'FormData',
      method: 'POST',
      body: this.getFormData(file, file_id as number),
      endPoint: '/bx_block_customer/customers/upload_customer_csv',
    })
  }

  getUploadedCsv = (page: number, perPage: number) => {
    this.getUploadedCsvAPIMessage = this.apiCall({
      type : 'application/json',
      method: 'GET',
      endPoint: `bx_block_customer/customers/fetch_csv_uploaded_data?page=${page}&per_page=${perPage}&file_id=${this.fileId}`,
    })
  }

  saveCustomerCsv = (file_id: number, ) => {
    this.saveCustomerCsvAPIMessage = this.apiCall({
      type : 'FormData',
      method: 'PUT',
      body: this.getFormData(null, file_id),
      endPoint: `bx_block_customer/customers/save_customer_csv`
    })
  }

  destroyUploadedCsv = (fileId: string) => {
    this.deleteCsvAPIMessage = this.apiCall({
      type : 'application/json',
      method: 'DELETE',
      endPoint: `bx_block_customer/customers/destroy_csv_uploaded_data?file_id=${fileId}`
    })
  }


  apiCall(data: any) {
    const { contentType, method, endPoint, body, type } = data;
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    let header: any = {
      token: token,
      "Content-Type": contentType
    };

    const customerCsvImportMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    customerCsvImportMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    customerCsvImportMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    customerCsvImportMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== 'FormData' ?
    customerCsvImportMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      ) : customerCsvImportMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      ) ;

    runEngine.sendMessage(customerCsvImportMessage.id, customerCsvImportMessage);
    return customerCsvImportMessage.messageId;
}

setUpdateCustomerCsv = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.uploadCustomerCsvAPIMessage !== null &&
    this.uploadCustomerCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const updateCustomerCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
    try {
      if(updateCustomerCsvAPIResponse?.data){
        if (updateCustomerCsvAPIResponse?.data.error_message || updateCustomerCsvAPIResponse?.data.error_file) {
          this.setState({errorMessage: updateCustomerCsvAPIResponse?.data.error_message, errorFile: updateCustomerCsvAPIResponse?.data.error_file})
          this.setAlert(updateCustomerCsvAPIResponse?.data.error_message)
        } 
        if(updateCustomerCsvAPIResponse?.data.file_id) {
          if(!updateCustomerCsvAPIResponse?.data.error_message){
            this.setState({errorMessage: '', errorFile: ''})
          }
          window.localStorage.setItem("file_id", JSON.stringify(updateCustomerCsvAPIResponse?.data?.file_id))
          this.fileId = updateCustomerCsvAPIResponse?.data?.file_id;
          this.getUploadedCsv(this.state.page, this.state.rowPerPage)
        }
      } else {
        this.setAlert(updateCustomerCsvAPIResponse?.error)
      }
    } catch (error) {
      //ignore
    } finally {
      this.setState({isLoading:false})
    }
  }
}

setSaveCustomerCsv = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.saveCustomerCsvAPIMessage !== null &&
    this.saveCustomerCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const saveCustomerCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

    if (saveCustomerCsvAPIResponse?.message) {
      this.setAlert(saveCustomerCsvAPIResponse?.message, 'success')
      this.fileId = ''
      setTimeout(() => {
        window.history.back()
      }, 700)
    } else {
      this.setAlert(saveCustomerCsvAPIResponse.errors)
    }
  }
}

fetchCustomerCsvList = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getUploadedCsvAPIMessage !== null &&
    this.getUploadedCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const fetchCustomerCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (fetchCustomerCsvAPIResponse?.data) {
      this.setState({isUploadingCsvFile: true})
      this.setState({customerCsvData: fetchCustomerCsvAPIResponse.data, totalPage: fetchCustomerCsvAPIResponse.meta.total_page})
    } else {
      this.setAlert(fetchCustomerCsvAPIResponse.errors)
    }
  }
}

deleteUploadedCsvList = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.deleteCsvAPIMessage !== null &&
    this.deleteCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const deleteCustomerCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (deleteCustomerCsvAPIResponse?.message) {
      this.setAlert(deleteCustomerCsvAPIResponse?.message);
    } else {
      this.setAlert(deleteCustomerCsvAPIResponse?.errors)
    }
  }
}

receiveDownloadedSampleCsvForCustomer = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.sampleCsvCustomerAPIKey !== null &&
    this.sampleCsvCustomerAPIKey ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const saveSampleCsv = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (saveSampleCsv?.url) {
      this.downloadFile(saveSampleCsv?.url)
    } else {
      this.setAlert(saveSampleCsv?.error)
    }
  }

}
componentWillUnmount(): any {
  this.fileId && this.destroyUploadedCsv(this.fileId)
}
  // Customizable Area End
}
