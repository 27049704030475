// Customizable Area Start
import moment from 'moment';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getLoggedUserToken } from "../../../components/src/Common/GetLoggedUserData";
export const configJSON = require("./config");

type OptionsType = {
    value: string;
    label: string;
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    t?: any;
    classes?: any;
    disableFileCheck?: (filter: string, activeIndex: string) => boolean;
    IPageChange?: (event: React.ChangeEvent<unknown>,page:number) => void;
    IRowPerPageChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    IBpageChange?: (event: React.ChangeEvent<unknown>,page:number) => void;
    filterData?: (event: React.ChangeEvent<HTMLInputElement>, change: boolean) => void;
    clearFilterData?: () => void;
    applyFilter?: (activeIndex: string) => void;
    filterDialog?: () => void;
    insurerSelect?: (options: OptionsType[]) => void;
    clearInsuranceBalanceFilter?: () => void;
    filterInsurerBalance?: () => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeTabIndex: string;
    showAlert: boolean;
    alertMessage: string;
    alertType: string;
    isLoading: boolean;
    showFilterSection: boolean;
    filerCustomOption: string;
    filterBy: string;
    startDate: string;
    endDate: string;
    searchQuery:string;
    // TRANSACTION
    transactionPage: number;
    transactionTotalPage: number;
    transactionList: any;
    transactionLoading:boolean;
    // SALES
    salesPage: number;
    salesTotalPage: number;
    salesTableList: any;
    salesLoading: boolean;
    // INSURANCE
    insuranceTableList:any,
    insuranceLoading: boolean,
    // Insurer
    insurerTableList: any;
    insurerPage: number,
    insurerTotalPage: number,
    insurerRowPerPage: number,
    insurerLoading: boolean,
    // Insurer Balance
    insurerBalanceLoading: boolean;
    insurerBalanceTableList: any[];
    insurerBalancePage: number;
    insurerBalanceTotalPage: number;
    insurerBalanceRowPerPage: number;
    filterDialog: boolean;
    selectedInsurers: OptionsType['value'][]
    selectedInsurersData: OptionsType[]
    insurerList: OptionsType[]
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AnalyticsListingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAllTransactionRecordsApi:string = "";
    getAllSalesRecordsApi: string = "";
    getAllInsuranceRecordsApi: string = "";
    getAllInsurerRecordsApi: string = "";
    getAllInsurerBalanceRecordsApi: string = "";
    searchDebounceTimer: any;
    getAllFilterInsurerListId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        this.state = {
            showAlert: false,
            alertMessage: "",
            alertType: "",
            isLoading: false,
            activeTabIndex:'1',
            showFilterSection:false,
            filerCustomOption:"",
            filterBy:"month_to_date",
            endDate: "",
            startDate:"",
            searchQuery:"",
            // TRANSACTION
            transactionPage:1,
            transactionTotalPage:1,
            transactionList:[],
            transactionLoading: false,
            // SALES
            salesPage: 1,
            salesTotalPage: 1,
            salesTableList: [],
            salesLoading: false,
            // INSURANCE
            insuranceTableList:[],
            insuranceLoading: false,
            // INSURER
            insurerTableList: [],
            insurerPage: 1,
            insurerTotalPage: 1,
            insurerRowPerPage: 10,
            insurerLoading: false,
            // Insurer Balance
            insurerBalanceTableList: [],
            insurerBalancePage: 1,
            insurerBalanceTotalPage: 1,
            insurerBalanceRowPerPage: 10,
            insurerBalanceLoading: false,
            filterDialog: false,
            selectedInsurers: [],
            selectedInsurersData: [],
            insurerList: [],
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }


    // API LOGIC START
    apiCallForAnalyticsListing(data: any) {
        const { contentType, method, endPoint, body } = data;
        const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
        const token = userData.token || "";

        const header = {
            "Content-Type": contentType,
            token: token,
        };

        const requestMessageForAnayticsListingQuery = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageForAnayticsListingQuery.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageForAnayticsListingQuery.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessageForAnayticsListingQuery.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessageForAnayticsListingQuery.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );

        runEngine.sendMessage(requestMessageForAnayticsListingQuery.id, requestMessageForAnayticsListingQuery);
        return requestMessageForAnayticsListingQuery.messageId;
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.getAllTransactionRecordsApiReceive(message)
        this.getAllSalesRecordsApiReceive(message)
        this.getAllInsuranceRecordsApiReceive(message)
        this.getAllInsurerRecordsApiReceive(message)
        this.getAllInsurerBalanceRecordsApiReceive(message)
        this.getAllFilterInsurerListIdReceive(message)
        // Customizable Area End
    }
    handErrorMessageForAnayticsListing  = (responseJson: any) => {
        if (!responseJson) return;
        let errorMessage = "Something went wrong"
        if (responseJson?.error) {
          errorMessage = typeof (responseJson.error) === "string" ? responseJson.error : responseJson.errors.error || "Something went wrong";
        }
        this.setState({
          alertType: "error",
          alertMessage: errorMessage,
          showAlert: true,
        })
      };

    // API LOGIC ENDS

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();

        this.getAllTransactionRecords(this.state.transactionPage);
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidUpdate() {
        if (this.state.showAlert) {
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertType: "",
                    alertMessage: "",
                });
            }, 10000);
        }
    }
    filterChange = (event: React.ChangeEvent<HTMLInputElement>,changeForCustomOption:boolean) => {
        const selectedValue = event.target.value;
        !changeForCustomOption && this.setState({ filterBy:selectedValue });
        changeForCustomOption && this.setState({ filerCustomOption:selectedValue });
    };
    clearFilter = () => {
        this.setState({filterBy:"month_to_date", filerCustomOption:"", showFilterSection:false})
    }
    applyFilter = (activeTabIndex: string) => {
        switch (activeTabIndex){
            case "1":
                this.getAllTransactionRecords(1, "filter");
                break;
            case "2":
                this.getAllInsuranceRecords("filter");
                break;
            case "3":
                this.getAllInsurerRecords(1, 10, "filter");
                break;
            case "4":
                this.getAllSalesRecords(1, "filter");
                break;
            case "5":
                this.getAllInsurerBalanceRecords(1, "filter");
                break;
            default:
                return `No tab found with this value`
            }
        }
    handleTabChange = (tabIndex: string) => {
        this.setState({
            activeTabIndex: tabIndex,
        },()=>this.setState({searchQuery:""}))
    }

    handleSearchDebounce = () =>{
         this.searchDebounceTimer = setTimeout(() => {
            if(this.state.activeTabIndex === "1"){
                this.getAllTransactionRecords(this.state.transactionPage)
            }else if(this.state.activeTabIndex==="4"){
                this.getAllSalesRecords(this.state.salesPage);
            }
          }, 500);
         }
    handleSearchQuery =(event:any)=>{
         if (this.searchDebounceTimer) {
         clearTimeout(this.searchDebounceTimer);
          }
          this.setState({searchQuery:event.target.value,transactionPage:1},this.handleSearchDebounce)
         }

    handleChange = (event: any, newValue:any ) => {
        this.setState({activeTabIndex:newValue,searchQuery:""},()=>{
            switch (newValue) {
                case "1":
                  this.getAllTransactionRecords(1);
                  break;
                case "2":
                  this.getAllInsuranceRecords();
                  break;
                case "3":
                  this.getAllInsurerRecords(1);
                  break;
                case "4":
                  this.getAllSalesRecords(1);
                  break;
                case "5":
                  this.getAllInsurerBalanceRecords(1);
                  this.getInsurerRecords();
                  break;
                default:
                  return `No tab found with this value`
              }
        })


      };

    getDateDisableState = (filterBy: string, activeTabIndex: string) => {
        return (filterBy === 'custom' || activeTabIndex === '4')
    }

    //   TRANSACTION TABLE LOGIC START
    handleTransactionPageChange = (event:any,page:number) => {
        this.setState({ transactionPage: page });
        this.getAllTransactionRecords(page);
    }
    getAllTransactionRecords = (transactionId:number, isFilter?:string) => {
        let searchParams = `page=${transactionId}&per_page=1&filter[date]=${this.state.filterBy}&filter[start_date]=${this.state.startDate}&filter[end_date]=${this.state.endDate}`
        this.setState({transactionLoading:true})
        this.getAllTransactionRecordsApi = this.apiCallForAnalyticsListing({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `/bx_block_visual_analytics/transactions?${searchParams}&query=${this.state.searchQuery}`,
            token: getLoggedUserToken()
        })
    }
    getAllTransactionRecordsApiReceive  = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAllTransactionRecordsApi != null &&
            this.getAllTransactionRecordsApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const transactionListingRecords = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (transactionListingRecords.data) {
                this.setState({
                    transactionLoading: false,
                    transactionList:transactionListingRecords.data,
                    transactionPage: transactionListingRecords.meta.current_page,
                    transactionTotalPage: transactionListingRecords.meta.total_page,
                    showFilterSection:false
                })
            } else {
                this.setState({transactionLoading:false});
                this.handErrorMessageForAnayticsListing(transactionListingRecords);
            }
        }
    }
    //   TRANSACTION TABLE LOGIC END

    // SALES TABLE LOGIC START
    getSalesString(time_period: string) {
        if(!this.state.startDate) this.setState({startDate: moment(new Date()).format('YYYY-MM-DD'), endDate: moment(new Date()).format('YYYY-MM-DD')})

        if(time_period === 'year_to_date') return 'yearly'
        else if(time_period === 'quarter_to_date') return 'quarterly'
        return 'monthly';
    }
    handleSalesPageChange = (event:any,page:number) => {
        this.setState({ salesPage: page });
        this.getAllSalesRecords(page);
    }
    getAllSalesRecords = (page: number, isFilter?:string) => {
        let searchParams = `&filter[time_period]=${this.getSalesString(this.state.filterBy)}&filter[start_date]=${this.state.startDate}&filter[end_date]=${this.state.endDate}`
        this.setState({salesLoading:true})
        this.getAllSalesRecordsApi = this.apiCallForAnalyticsListing({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_visual_analytics/sales?page=${page}&per_page=1${isFilter ? searchParams : ''}&query=${this.state.searchQuery}`,
        })
    }

    getAllSalesRecordsApiReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAllSalesRecordsApi != null &&
            this.getAllSalesRecordsApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const salesListingRecords = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (salesListingRecords.data) {
                this.setState({
                    salesLoading: false,
                    salesTableList:salesListingRecords.data,
                    // salesPage: salesListingRecords.meta.current_page,
                    salesTotalPage: salesListingRecords.meta.total_page,
                    showFilterSection:false
                })
            } else {
                this.setState({salesLoading:false});
                this.handErrorMessageForAnayticsListing(salesListingRecords);
            }
        }
    }
    // SALES TABLE LOGIC ENDS


    // INSURANCE TABLE LOGIC STARTS

    getAllInsuranceRecords = (isFilter?: string) => {
        this.setState({insuranceLoading: true})
        let searchParams = `filter[date]=${this.state.filterBy}&filter[start_date]=${this.state.startDate}&filter[end_date]=${this.state.endDate}`
        this.getAllInsuranceRecordsApi = this.apiCallForAnalyticsListing({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_visual_analytics/insurances/?${isFilter?searchParams:''}&query=${this.state.searchQuery}`,
        })
    }

    getAllInsuranceRecordsApiReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAllInsuranceRecordsApi != null &&
            this.getAllInsuranceRecordsApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const InsuranceListingRecords = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (InsuranceListingRecords.data) {
                this.setState({
                    insuranceTableList:InsuranceListingRecords.data,
                    insuranceLoading: false,
                })
            } else {
                this.setState({insuranceLoading:false});
                this.handErrorMessageForAnayticsListing(InsuranceListingRecords);
            }
        }
    }
    // INSURANCE TABLE LOGIC ENDS

    // INSURER TABLE LOGIC STARTS
    handleInsurerPageChange = (event:React.ChangeEvent<unknown>,page:number) => {
        this.setState({ insurerPage: page });
        this.getAllInsurerRecords(page);
    }
    handleInsurerRowPerPage = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({ insurerRowPerPage: parseInt(event.target.value) });
        this.getAllInsurerRecords(this.state.insurerPage, parseInt(event.target.value));
    }
    getAllInsurerRecords = (page:number, perPage: number = 10, isFilter?:string) => {
        this.setState({insuranceLoading: true})
        // Adding Filter
        let searchParams = `&filter[date]=${this.state.filterBy}&filter[start_date]=${this.state.startDate}&filter[end_date]=${this.state.endDate}`
        this.getAllInsurerRecordsApi = this.apiCallForAnalyticsListing({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_visual_analytics/insurers?page=${page}&per_page=${perPage}${isFilter ? searchParams : ''}&query=${this.state.searchQuery}`,
        })
    }

    getAllInsurerRecordsApiReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAllInsurerRecordsApi != null &&
            this.getAllInsurerRecordsApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const InsurerListingRecords = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (InsurerListingRecords.data) {
                this.setState({
                    insurerTableList:InsurerListingRecords.data,
                    insuranceLoading:false,
                    insurerPage: InsurerListingRecords.meta.current_page,
                    insurerTotalPage: InsurerListingRecords.meta.total_page,
                    showFilterSection:false
                })
            } else {
                this.setState({insuranceLoading:false});
                this.handErrorMessageForAnayticsListing(InsurerListingRecords);
            }
        }
    }
    // INSURER TABLE LOGIC ENDS

    // INSURER BALANCE TABLE LOGIC START
    handleInsurerBalancePageChange = (event: React.ChangeEvent<unknown>,page:number) => {
        this.setState({ insurerBalancePage: page });
        this.getAllInsurerBalanceRecords(page, this.state.selectedInsurers.length ? 'filter' : '');
    }
    clearInsuranceBalanceFilter = () => {
        this.handleFilterDialog()
        this.setState({selectedInsurers: [], selectedInsurersData: []})
        this.getAllInsurerBalanceRecords(1);
    }
    handleFilterDialog = () => {
        this.setState({filterDialog: !this.state.filterDialog})
    }
    handlerInsurerSelector = (option: any) => {
        if (Array.isArray(option)) {
            this.setState({ selectedInsurers: option.map((opt: any) => opt.value), selectedInsurersData: [...option]})
        }
    }
    filterInsurerBalance = () => {
        this.getAllInsurerBalanceRecords(1, 'filter_data');
        this.handleFilterDialog();
    }

    getAllInsurerBalanceRecords = (page: number, isFilter?:string) => {
        let searchParams = `&filter[insurance_company]=${this.state.selectedInsurers.join(',')}`
        this.setState({insurerBalanceLoading: true})
        this.getAllInsurerBalanceRecordsApi = this.apiCallForAnalyticsListing({
            contentType: "application/json",
            method: "GET",
            endPoint: `${configJSON.getAllInsurerBalanceList}?page=${page}&per_page=${1}${isFilter ? searchParams : ''}`,
        })
    }

    getInsurerRecords = () => {
        this.getAllFilterInsurerListId = this.apiCallForAnalyticsListing({
            contentType: "application/json",
            method: "GET",
            endPoint: configJSON.apiGetAllInsurerList,
        })
    }

    getAllFilterInsurerListIdReceive = (message: Message) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAllFilterInsurerListId != null &&
            this.getAllFilterInsurerListId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const insurerListingRecords = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (insurerListingRecords.data) {
                this.setState({
                    insurerList: insurerListingRecords.data.map((opt: any) => {
                        return {
                            value: opt?.attributes?.id,
                            label: opt?.attributes?.legal_name?.toLowerCase()
                                .split(' ')
                                .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
                                .join(' '),
                        }
                    }),
                })
            } else {
                this.setState({insurerBalanceLoading:false});
                this.handErrorMessageForAnayticsListing(insurerListingRecords);
            }
        }
    }

    getAllInsurerBalanceRecordsApiReceive = (message: Message) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAllInsurerBalanceRecordsApi != null &&
            this.getAllInsurerBalanceRecordsApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const insurerBalanceListingRecords = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (insurerBalanceListingRecords.data) {
                this.setState({
                    insurerBalanceLoading: false,
                    insurerBalanceTableList:insurerBalanceListingRecords.data,
                    insurerBalanceTotalPage: insurerBalanceListingRecords.meta.total_page,
                    showFilterSection:false
                })
            } else {
                this.setState({insurerBalanceLoading:false});
                this.handErrorMessageForAnayticsListing(insurerBalanceListingRecords);
            }
        }
    }
    // INSURER BALANCE TABLE LOGIC ENDS

    // Customizable Area End
}