// Customizable Area Start
import React from "react";
import { Container } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CustomerController, { Props } from "./CustomerController";
import CustomerContant from '../../../components/src/Customer/CustomersContant';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  }
});

export default class Customers extends CustomerController {
  constructor(props: Props) {
    super(props);
  }

  render() {


    const { page,totalPage,customerDetail,customerContacts} = this.state;
    return (
      <ThemeProvider theme={theme} >
        <Container id="customerContainer" maxWidth="lg" dir={(window.localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"} >
          <CustomerContant
            currentPage={page}
            totalPage={totalPage}
            pageChange={this.handleChangePage}
            handleRowPerPage={this.handleRowPerPage}
            customerDetails={customerDetail}
            customerContacts={customerContacts}
            fetchExistingPolicy={this.fetchExistingPolicy}
            fetchOnGoingPolicy={this.fetchOnGoingPolicy}
            existingPolicyDataTable = {this.state.existingPolicyDataTable}
            existingPolicyDataTableMeta = {this.state.existingPolicyDataTableMeta}
            onGoingPolicyDataTable = {this.state.onGoingPolicyDataTable}
            onGoingPolicyDataTableMeta = {this.state.onGoingPolicyDataTableMeta}
            updateUserStatus= {this.updateUserStatus}
            navigateToRevisionRequest={this.handleNavigateToRevisionRequest}
          />
        </Container>
      </ThemeProvider>
    );
  }
}
// Customizable Area End