import React, { ChangeEvent, useMemo,useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Typography,
  Checkbox,
} from "@material-ui/core";

import {
  createTheme,
  ThemeProvider,
  makeStyles,
  createStyles
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router";
import { MoreVert } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import { Pagination } from "@material-ui/lab";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../ConfirmationDialog.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetLoggedUserData from "../GetLoggedUserData";
import ActiveLanguage from "../ActiveLanguage";

const { get } = require("lodash");

const ROW_PER_PAGE_OPTION = [
  {
    label: "5",
    value: 5,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "15",
    value: 15,
  },
];


interface IPaginatedTable extends RouteComponentProps {
  curretPage: number;
  perPage?: number;
  totalPage: number;
  handleCheckBox?: (event: ChangeEvent<unknown>, data: any) => void;
  pageChange: (event: ChangeEvent<unknown>, page: number) => void;
  handleRowPerPage: (
    event:React.ChangeEvent<HTMLSelectElement>
  ) => void;
  tableFor:string;
  archiveList?: (id: any) => void;
  tableData: any;
  deletedData?: any;
  tableColumn: Array<{
    Title: string;
    dataKey?: string;
    tableAction?: Function;
  }>;
  path?: string;
  isArchived?: boolean;
  children?: React.ReactNode;
  isLoading?:boolean,
  navigateToRevisionRequest?: any;
  openEditDialog?: (data: any) => void
  innerState?: any;
  onRowClick?: (id: string | number) => void;
}

const theme = createTheme();


const PaginatedTable = (props: IPaginatedTable) => {
  const {
    history,
    curretPage,
    perPage,
    totalPage,
    pageChange,
    handleRowPerPage,
    handleCheckBox,
    tableFor,
    archiveList,
    tableData,
    deletedData,
    tableColumn,
    path,
    isArchived,
    isLoading,
    navigateToRevisionRequest,
    openEditDialog,
    innerState,
    onRowClick,
  } = props;
  // status popover
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isArchivedConfirmation,setIsArchivedConfirmation] = React.useState(false);
  const [isDeleteConfirmation,setIsDeleteConfirmation] = React.useState(false);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const [archiveDetail,setArchiveDeatil] = React.useState({
    archiveId:"",
    archiveCompany:""
  })
  const [deleteDetail,setDeleteDetail] = React.useState<{deleteId: string|number, deleteData:any}>({
    deleteId: "",
    deleteData: ""
  })
  const [loggedUserData, setLoggedUserData] = React.useState(GetLoggedUserData())
  const id = open ? "simple-popover" : undefined;
  const firstButton = useRef()
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>,archiveData:any) => {
    event.stopPropagation();
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const userRole = userData.role || '';
    if (tableFor === "csvCorrect" && openEditDialog) {
      openEditDialog(archiveData)
    } else if (tableFor === "insurer" && userRole === ("Finance" || "finance")) {
      return;
    }
    else {
      const currentPage = window.location.href.split('/').pop();
      if ((currentPage === "Customers") && ((userRole !== "Finance") && (userRole !== "finance") && (userRole !== "operations") && (userRole !== "Operations"))) {
        setAnchorEl(event.currentTarget);
      }
      if (currentPage !== "Customers") {
        setAnchorEl(event.currentTarget);
      }
      const archiveName = archiveData.attributes.name || archiveData.attributes.legal_name
      setArchiveDeatil({
        archiveId: archiveData.id,
        archiveCompany: archiveName
      })
      setDeleteDetail({
        deleteId: archiveData.id,
        deleteData: archiveData
      })
      }
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleCloseConfirmationModel = ()=>{
    setIsArchivedConfirmation(false)
    setIsDeleteConfirmation(false)
  }

  const archiveTableRow = (
    event: React.MouseEvent<HTMLElement>,
    id: string | undefined
  ) => {
    event.stopPropagation();
    setIsArchivedConfirmation(true)
    setAnchorEl(null);
  };
  const deleteTableRow = (
    event: React.MouseEvent<HTMLElement>,
    id: string | undefined
  ) => {
    event.stopPropagation();
    setIsDeleteConfirmation(true)
    setAnchorEl(null);
    id && setDeleteDetail((prevData: any) => ({...prevData, deleteId: id}))
  };

  const editTableRow = () => {
    onRowClick && onRowClick(deleteDetail.deleteId);
  }

  const handleArchiveConfirmation = ()=>{
    if (tableFor !== 'policy' && archiveList) archiveList(archiveDetail.archiveId)
    setIsArchivedConfirmation(false)
  }

  const handleDeleteConfirmation = ()=>{
    if (tableFor === 'task' && archiveList) archiveList(deleteDetail.deleteId)
    setIsDeleteConfirmation(false)
  }

  const classes = useStyles();

  const handleTableRowClick = (id: number,isSubmited:boolean,className:string) => {
    if (tableFor === "csv" || tableFor === "csvRemove" || tableFor === "csvCorrect"|| tableFor==="task") return;
    if(tableFor === 'insurer') {
      const navigationPath = `${path}?id=${id}&data=insurer`
      history.push(`${navigationPath} `);
      return
    }
    if((tableFor == 'policy' && !isSubmited) || tableFor == "PolicyOverview"){
      if(className=="BxBlockPolicyRequest::PolicyRequest"){
        const navigationPath = `/PolicyRequest?id=${id}`
    history.push(`${navigationPath} `);
      }
    else{
      const navigationPath = `/RevisionRequest?id=${id}`
      history.push(`${navigationPath} `);
    }
    }
    else{
    const navigationPath = `${path}?id=${id}`
    history.push(`${navigationPath} `);}
  };

  const getCellValue = (key: string, getData: any) => {
    if(key === 'current_rate' || key === 'balance_left' ) return `SAR ${getData}`;
    return getData;
  }

  const getCheckedData = (rowData: any) => {
    let checked = false;
    deletedData?.filter((data: any) => {
      if (data.id === rowData.id) checked = true;
    })
    return checked;
  }

  const showDeleteMenu = (rowData: any): boolean => {
    return deleteDetail.deleteData ? loggedUserData.id === deleteDetail.deleteData.attributes?.assigned_by.id : false
  }

  return (
    <div data-test-id="policyTable">
      <ThemeProvider theme={theme}>
      {innerState && innerState.stageData.revisionType === "cancel" ? null
          : <TableContainer className={classes.tabelContainer}>
        {tableData.length !== 0 && !isLoading ? (
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeadingColor}>
                {tableFor === 'csvRemove' && <TableCell />}
                {tableColumn.map((heading: any,index:number) => (
                  <TableCell align={ActiveLanguage() ? 'right' : 'left'} key={heading.title} className={classes.tabelHeading}>
                    {t(heading.Title)}
                  </TableCell>
                ))}
                {!isArchived && tableFor !== 'policy' && tableFor!=="overdue_payment" && tableFor !== 'csvRemove' && <TableCell />}
              </TableRow>
            </TableHead>
              <TableBody>
                {tableData.map((rowData: any, idx: number) => (
                  <TableRow
                  dir="ltr"
                  key={`${rowData.id}-table-row-${idx}`}
                  className={classes.tableRowStyle}
                  onClick={() => {
                    handleTableRowClick(rowData.id,rowData?.attributes?.is_next_stage_submitted,rowData?.attributes?.class_type)
                  }}
                  >
                     {tableFor === 'csvRemove' && handleCheckBox && (
                        <TableCell
                          className={classes.tableColumn}
                          style={{ width: "20px" }}
                        >
                          <Typography
                            aria-describedby={id}
                            id={rowData.id}
                            onClick={(event: any) => handleCheckBox(event, rowData)}
                          >
                            <Checkbox
                              checked={getCheckedData(rowData)}
                              inputProps={{ 'aria-label': 'controlled' }}
                              classes={{
                                root: classes.customCheckbox,
                                checked: classes.checked,
                              }}
                            />
                          </Typography>
                        </TableCell>
                      )}
                    {tableColumn.map((key: any,index) => (
                      <TableCell key={`${rowData.id}-table-cell-${index}`} className={classes.tableColumn}>
                        <div className={classes.tableRowOverflow}>{key.dataKey
                          ? getCellValue(key.Title, get(rowData, key.dataKey))
                          : key.tableAction(rowData,t, idx)}</div>
                      </TableCell>
                    ))}
                    {!isArchived && tableFor !== 'policy' && tableFor !== "overdue_payment" && tableFor !== 'csvRemove' && (
                      <TableCell
                        className={classes.tableColumn}
                        style={{ width: "20px" }}
                      >
                        <Typography
                          aria-describedby={id}
                          id={rowData.id}
                          onClick={(event:any)=> handleClick(event,rowData)}
                        >
                          {(tableFor !== 'csv' && tableFor !== 'csvCorrect' && tableFor !== 'PolicyListing' && tableFor !== 'PolicyOverview' && tableFor!=="overdue_payment") && <MoreVert />}
                          {tableFor === 'csvCorrect' && <EditIcon />}
                        </Typography>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
          </Table>) :
          (  tableData.length === 0 && !isLoading ?
          <><Table>
            <TableHead>
              <TableRow className={classes.tableHeadingColor}>
                {tableColumn.map((heading: any,index:number) => (
                  <TableCell key={heading.title} className={classes.tabelHeading}>
                    {t(heading.Title)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
          <div className={classes.loadingTableContainer} >
          <Typography data-test-id="noData" style={{ textAlign: "center" }}>{t('no_data')}</Typography>
          </div></>
          : <div className={classes.loadingTableContainer}>
                {isLoading ?
                  <div className={classes.loader}>
                      <CircularProgress/>
                  </div>
                  : <Typography data-test-id="noData" style={{ textAlign: "center" }}>{t('no_data')}</Typography>
                }
              </div>

            )
          }
          <div dir="ltr" className={classes.paginationClass}>
            <div style={{width:'100px'}}/>
            <Pagination
              shape="rounded"
              className={classes.paginationRoot}
              count={totalPage}
              page={curretPage}
              onChange={pageChange}
              showFirstButton
              showLastButton
              ref={firstButton}
            />
           <div className={classes.rowPerPageConatiner}>
            <Typography className={classes.itemPerPage}>{t('items_per_page')}</Typography>
           <select className={classes.rowPerPageSelect} onChange={handleRowPerPage}>
             {
              ROW_PER_PAGE_OPTION.map((options,index)=><option key={`${index}-${options.value}-${index}`} value={options.value} selected={options.value == (perPage || 10)}>{options.label}</option>)
             }
          </select>
           </div>
          </div>
        </TableContainer>}
        {tableFor !== 'policy' &&  <>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          className={classes.popoverContainer}
        >
          {(tableFor !== 'task' && tableFor !=="overdue_payment") ? (
            <Typography
              onClick={(event) => archiveTableRow(event, anchorEl?.id)}
              className={classes.popoverContent}
            >
              {t("archive")}
            </Typography>
          ) : (
            <>
              {showDeleteMenu(anchorEl) && <Typography
                onClick={(event) => deleteTableRow(event, anchorEl?.id)}
                className={classes.popoverContent}
              >
                {t("delete")}
              </Typography>}
              <Typography
                onClick={() => editTableRow()}
                className={classes.popoverContent}
              >
                {t("edit")}
              </Typography>
            </>
          )}

        </Popover>
       <ConfirmationDialog
          openDialog={isArchivedConfirmation}
          headingText={`${t('are_you_sure_you_want_to_archive')} ${archiveDetail.archiveCompany} ?`}
          paragraphText={tableFor === "insurer" ?
          t("This_will_keep_all_data_but_move_it_to_archive_accessible_from_this_screen_You_can_only_archive_a_insurer_if_no_requests_or_policies_are_active") :
          t("This_will_keep_all_data_but_move_it_to_archive_accessible_from_this_screen_You_can_only_archive_a_customer_if_no_requests_or_policies_are_active")
        }
          btnCancelText={t("cancel")}
          btnOkText={t("archive")}
          handleOk={handleArchiveConfirmation}
          handleCancel={handleCloseConfirmationModel}
       />
        <ConfirmationDialog
            openDialog={isDeleteConfirmation}
            headingText={`${t('delete_task')}`}
            paragraphText={t("are_you_sure_you_want_to_delete_task")}
            btnCancelText={t("cancel")}
            btnOkText={t("delete")}
            handleOk={handleDeleteConfirmation}
            handleCancel={handleCloseConfirmationModel}
        />
        </>}
      </ThemeProvider>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    tabelContainer: {
      padding: 0,
      border: "1px solid grey",
      borderRadius: "7px",
    },
    loadingTableContainer:{
      padding: 0,
      height: "250px",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    loader: {
      position: "absolute"
    },
    tableHeadingColor: {
      backgroundColor: "rgba(233, 244, 249, 1)",
    },
    tabelHeading: {
      fontSize: "14px",
      fontWeight: 700,
      padding: "13px 15px",
      "&:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4)": {
        minWidth: '150px',
      }

    },
    tableRowStyle: {
      cursor: "pointer",
      backgroundColor: "ghostwhite",
      transition: "transform .3s, box-shadow 0.5s",
      "&:hover": {
        transform: " scale(1.0) perspective(0px)",
        boxShadow: " 0 3px 6px #9ca3af",
      },
      borderTop: "1px solid grey",
      borderBottom: "1px solid grey",
    },
    tableRowOverflow: {
      overflow: 'hidden',
      maxWidth: '150px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    tableColumn: {
      borderTop: "1px solid grey",
      borderBottom: "1px solid grey",
      padding: "12px",
    },
    tableStatus: {
      width: "153px",
      padding: "8px 30px",
      backgroundColor: "rgba(233, 244, 249, 1)",
      fontSize: "14px",
      fontWeight: 700,
    },
    buttonStatus: {
      background: "lightgreen",
      padding: 0,
      width: "100px",
      fontSize: "12px",
      color: "darkgreen",
    },
    PaginationContainer: {
      "&>div": {
        justifyContent: "center",
        "& div": {
          flex: "none",
        },
      },
    },
    popoverContainer: {
      "& :nth-of-type(3)": {
        boxShadow: "0 0px 2px lightgray",
        marginTop: "5px",
      },
    },
    popoverContent: {
      width: "180px",
      padding: "12px 16px",
      borderRadious: "5px",
      cursor: "pointer",
    },
    boxpagi: {
      width: "auto",
      margin: "auto",
    },
    paginationClass: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
    },
    paginationRoot: {
      "& .MuiPaginationItem-root": {
        borderRadius: "50%",
        width: "32px",
        height: "32px",
        backgroundColor: "#ccc",
        margin: "0 4px",
        color: "#333",
      },
      "& .Mui-selected": {
        backgroundColor: "rgba(233, 244, 249, 1)",
      },
    },
    rowPerPageSelect: {
      padding: "5px 11px",
      borderRadius: "4px",
      border: "1px solid grey",
      backgroundColor: "white",
    },
    rowPerPageConatiner:{
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center"
    },
    itemPerPage:{
      fontSize: "revert",
      padding: "3px 8px",
      color: "grey"
    },
    customCheckbox: {
      color: '#ccc',
    },
    checked: {
      '&$checked': {
        color: 'rgb(64, 109, 127)', // Customize the checked color
      },
    }
  })
);

export default withRouter(PaginatedTable);
