import React from 'react'
import { Switch } from "@material-ui/core";
import { makeStyles, createStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';


const theme = createTheme({
  palette: {
      primary: {
          main: "#47d10e",
          contrastText: "#fff",
      },
  }
});
function Toggle({isChecked,handleToggle, isDisabled}:any) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
    <Switch
      className={classes.toggle}
      checked={isChecked}
      onChange={handleToggle}
      color="primary"
      inputProps={{ 'aria-label': 'controlled' }}
      disabled={isDisabled}
    />
    </ThemeProvider>
  )
}
const useStyles = makeStyles(theme=>createStyles({
  toggle:{
    width: '50px',
    height: '37px',
    "& .Mui-checked":{
      transform: "translateX(14px)",
      color:'#fff',
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track":{
      background:'#47d10e',
    },
    "&>span":{
      top: '5px',
      left: '5px',
      "&>span":{
        "&>span":{
        width: '8px',
        height: '8px'
        }
      }
    }
}
}));

export default Toggle