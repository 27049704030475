import React from 'react'
import { makeStyles, createStyles, useTheme, withStyles, createTheme } from '@material-ui/core/styles';

import { Tooltip } from '@material-ui/core';


const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });

const CustomToolTip = withStyles({
    arrow: {
      "&::before": {
        color: "white"
      }
    },
    tooltip: {
      backgroundColor: "#f5f5f9",
      boxShadow: theme.shadows[8],
      color: "black",
      fontSize: 14,
      padding:"20px",
      borderRadius:"5px",
      maxWidth: 800
    },
    tooltipPlacementTop: {
      margin: "4px 0"
    }
  })(Tooltip);



export default function StatusTooltip({statusTitle,children}:any) {
    const isArabicLenguage = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;

  return (
    // @ts-ignore
    <CustomToolTip title={statusTitle} placement="top" arrow>
        {children}
    </CustomToolTip>
  )
}
