import React, { ChangeEvent, useMemo,useRef } from "react";
import { RouteComponentProps } from "react-router";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import {
  createTheme,
  ThemeProvider,
  makeStyles,
  createStyles
} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import MenuPopover from "../MenuPopover/MenuPopover";

const { get } = require("lodash");


const ROW_PER_PAGE_OPTION = [
  {
    label: "5",
    value: 5,
  },
  {
    label: "10",
    value: 15,
  },
  {
    label: "15",
    value: 15,
  },
];


interface IPaginatedTable extends RouteComponentProps {
  currentPage: number;
  totalPage: number;
  pageChange: (event: ChangeEvent<unknown>, page: number) => void;
  handleRowPerPage: (
    event:React.ChangeEvent<HTMLSelectElement>
  ) => void;
  navigateToRevisionRequest?: any;
  tableData: any;
  tableFor?: string;
  tableColumn: Array<{
    Title: string;
    dataKey?: string;
    tableAction?: Function;
  }>;
  showMenuPopover?: boolean;
  handleUserStatus?:any;
  path?: string;
}

const theme = createTheme();



const AccordianTable = (props: IPaginatedTable) => {
  const {
    history,
    currentPage,
    totalPage,
    pageChange,
    handleRowPerPage,
    navigateToRevisionRequest,
    tableFor,
    tableData,
    tableColumn,
    showMenuPopover,
    path,
  } = props;
  // status popover
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = open ? "simple-popover" : undefined;
  const firstButton = useRef()
  const { t } = useTranslation();

  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   event.stopPropagation();
  //   const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
  //   const userRole = userData.role || '';
  //   const currentPage = window.location.href.split('/').pop();
  //   if ((currentPage === "Customers") && ((userRole !== "Finance") && (userRole !== "finance") && (userRole !== "operations") && (userRole !== "Operations"))) {
  //     setAnchorEl(event.currentTarget);
  //   }
  //   if (currentPage !== "Customers") {
  //     setAnchorEl(event.currentTarget);
  //   }
  // };
  // const handleClose = (event: React.MouseEvent<HTMLElement>) => {
  //   event.stopPropagation();
  //   setAnchorEl(null);
  // };

  const classes = useStyles();

  const handleTableRowClick = (id: number, class_type?: string) => {
    if(class_type && class_type === "BxBlockRevisionRequest::RevisionRequest") {
      navigateToRevisionRequest(id, true)
    } else if(path) {
      const navigationPath = `${path}?id=${id}`
      history.push(`${navigationPath} `);
    }
  }


  return (
    <Box className={classes.mainContainer}>
      <ThemeProvider theme={theme}>
        <TableContainer className={classes.tabelContainer}>
          <Table id={tableFor ?? ''}>
            <TableHead>
              <TableRow className={classes.tableHeadingColor}>
                {tableColumn.map((heading: any,index:number) => (
                  <TableCell key={heading.title} className={classes.tabelHeading}>
                    {t(heading.Title)}
                  </TableCell>
                ))}
                {showMenuPopover && <TableCell />}
              </TableRow>
            </TableHead>
            {tableData.length ? (
              <TableBody>
                {tableData.map((rowData: any) => (
                  <TableRow
                    key={rowData.id}
                    className={classes.tableRowStyle}
                    onClick={() => handleTableRowClick(rowData.id, rowData.class_type)}
                  >
                    {tableColumn.map((key: any,index) => (
                      <TableCell key={`${rowData.id}-table-cell-${key.dataKey}`} className={classes.tableColumn}>
                        {key.dataKey
                          ? get(rowData, key.dataKey)
                          : key.tableAction(rowData,t,classes)}
                      </TableCell>
                    ))}
                       {
                        // @ts-ignore
                  showMenuPopover && <MenuPopover customerDetails={rowData} status={rowData.attributes.status}  />
                       }
                  </TableRow>
                ))}

              </TableBody>

            ): null
        }
     </Table>
        {!tableData.length && <div className={classes.loadingTableContainer}>
          <Typography>{t('no_data')}</Typography>
        </div>}
          <Box dir="ltr" className={classes.paginationClass}>
            <Box sx={{width:'100px'}}/>
            <Pagination
              shape="rounded"
              className={classes.paginationRoot}
              count={totalPage}
              page={currentPage}
              onChange={pageChange}
              showFirstButton
              showLastButton
              ref={firstButton}
            />
           <Box className={classes.rowPerPageConatiner}>
            <Typography className={classes.itemPerPage}>{t('items_per_page')}</Typography>
           <select className={classes.rowPerPageSelect} onChange={handleRowPerPage}>
             {
              ROW_PER_PAGE_OPTION.map((options,index)=><option key={`${index}-${options.value}-${index}`} value={options.value}>{options.label}</option>)
             }
          </select>
           </Box>
          </Box>
        </TableContainer>
      </ThemeProvider>
    </Box>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    mainContainer:{
      width:"100%"
    },
    tabelContainer: {
      padding: 0,
      border: "1px solid grey",
      borderRadius: "7px",
    },
    tableHeadingColor: {
      backgroundColor: "rgba(233, 244, 249, 1)",
    },
    tabelHeading: {
      fontSize: "14px",
      fontWeight: 700,
      padding: "13px 15px",
    },
    tableRowStyle: {
      cursor: "pointer",
      backgroundColor: "ghostwhite",
    },
    tableColumn: {
      borderTop: "1px solid grey",
      borderBottom: "1px solid grey",
      padding: "12px",
    },
    tableStatus: {
      width: "153px",
      padding: "8px 30px",
      backgroundColor: "rgba(233, 244, 249, 1)",
      fontSize: "14px",
      fontWeight: 700,
    },
    buttonStatus: {
      background: "lightgreen",
      padding: 0,
      width: "100px",
      fontSize: "12px",
      color: "darkgreen",
    },
    PaginationContainer: {
      "&>div": {
        justifyContent: "center",
        "& div": {
          flex: "none",
        },
      },
    },
    popoverContainer: {
      "& :nth-of-type(3)": {
        boxShadow: "0 0px 2px lightgray",
        marginTop: "5px",
      },
    },
    popoverContent: {
      width: "180px",
      padding: "12px 16px",
      borderRadious: "5px",
      cursor: "pointer",
    },
    boxpagi: {
      width: "auto",
      margin: "auto",
    },
    paginationClass: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
    },
    paginationRoot: {
      "& .MuiPaginationItem-root": {
        borderRadius: "50%",
        width: "32px",
        height: "32px",
        backgroundColor: "#ccc",
        margin: "0 4px",
        color: "#333",
      },
      "& .Mui-selected": {
        backgroundColor: "rgba(233, 244, 249, 1)",
      },
    },
    rowPerPageSelect: {
      padding: "5px 11px",
      borderRadius: "4px",
      border: "1px solid grey",
      backgroundColor: "white",
    },
    rowPerPageConatiner:{
      display:"flex",
      justifyContent:"space-between",
      alignItems:"center"
    },
    itemPerPage:{
      fontSize: "revert",
      padding: "3px 8px",
      color: "grey"
    },
    tableGreenButton: { background: "#bfe3d7", color: "green", width: "100%", textAlign: "center", borderRadius: "7px", fontSize: "14px", padding: "5px" },
    tableGrayButton: { background: "#e0e0e0", color: "gray", width: "100%", textAlign: "center", borderRadius: "7px", fontSize: "14px", padding: "5px" },
    tableYellowButton: { background: "#fcf1d4", color: "#f2c94c", width: "100%", textAlign: "center", borderRadius: "7px", fontSize: "14px", padding: "5px" },
    loadingTableContainer:{
      padding: 0,
      height: "250px",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
  })
);

export default withRouter(AccordianTable);