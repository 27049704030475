import React from 'react'
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const DateSelector = (props:any) => {
  return (
    <div style={{justifyContent: 'center', display: 'flex'}}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          multiline
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled || false}
          required={props.require || false}
          open={true}
          variant="static"
          disableToolbar
          margin="normal"
          size='medium'
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default DateSelector