// Customizable Area Start
import React, { ReactNode } from "react";
import {
  withStyles,
} from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  Button,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  TextField,
  OutlinedInput
} from "@material-ui/core";
import {
  ExpandMore,
} from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import TransactionTableForPolicy from "../../../../components/src/Common/transactionTables/TransactionTableForPolicy";

import LabelWithIcon from "../../../../components/src/Common/LabelWithIcon";
import PolicyOverviewController from "./PolicyOverviewController";
import PaginatedTable from "../../../../components/src/Common/Table/PaginatedTable";
import { EMPLOYEE_LIST_TABLE_BODY, POLICY_DETAIL_LIST_TABLE_DATA, VEHICLE_LIST_TABLE_BODY } from "../../../../components/src/Common/Table/TableColumns";
import FileListItem from '../../../../components/src/Common/FileListItem';

export class PolicyOverviewForm extends PolicyOverviewController {

  render(): ReactNode {// NOSONAR: typescript:S3776
    const { t, classes }: any = this.props;

    return (
      <>
          {this.state.isLoading &&
            <div className={classes.policyPageLoader}>
              <CircularProgress/>
            </div>
          }
        <div className={this.state.isLoading ? classes.loadingState : classes.mainSection}>
          <Grid container>
            <Grid item xs={6} style={{ lineHeight: '50px' }}>Policy for : <span style={{fontWeight: 700 }}>{this.state.customer}</span> </Grid>
            <Grid item xs={6} className={classes.headerButtonStyle}>
              <Button
                color="primary"
                className={classes.btnStyle}
                data-test-id="btnForNewPolicy"
                id="btnForNewPolicy"
                onClick={()=> this.navigateToRevisionRequest()}
              >
                  {t('request_revision')}
              </Button>
            </Grid>
          </Grid>
          <Grid className={classes.customerDetailsAccordion} container spacing={3}>
            <Grid data-test-id={'overview'} item xs={12}>
              <Accordion className={classes.accordionStyle} defaultExpanded>
                <AccordionSummary
                  aria-expanded={true}
                  expandIcon={<ExpandMore />}
                  aria-controls="Insurers"
                  id="Insurers"
                >
                  <Typography variant="subtitle1" className={classes.accordianHeading}>{t('overview')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    className={classes.policyDetailStyle}
                    container
                    spacing={2}
                  >
                    <Grid item xs={4}>
                      <LabelWithIcon label={t("policy_id")} />
                      <TextField
                        data-test-id={"policy_id"}
                        id={"policy_id"}
                        inputProps={{ readOnly: true }}
                        className={classes.textInputStyle}
                        value={this.state.policy_id}
                        name={`policy_id`}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <LabelWithIcon label={t("type_of_insurance")}  />
                      <TextField
                        data-test-id={"insuranceType"}
                        id={"insuranceType"}
                        inputProps={{ readOnly: true }}
                        className={classes.textInputStyle}
                        value={this.state.insuranceType}
                        name={`insuranceType`}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <LabelWithIcon label={`${t("policy_insurer")}`} />
                      <TextField
                        data-test-id="insurer"
                        id={"insurer"}
                        inputProps={{ readOnly: true }}
                        className={classes.textInputStyle}
                        value={this.state.insurer}
                        name={`insurer`}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <LabelWithIcon label={t("policy_activation_date")} />
                      <TextField
                        data-test-id={`activationDate`}
                        id={`activationDate`}
                        className={classes.textInputStyle}
                        value={this.state.activationDate}
                        name={`activationDate`}
                        inputProps={{ readOnly: true }}
                        type={"date"}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4} >
                      <LabelWithIcon label={t("policy_expiry_date")} />
                      <TextField
                        data-test-id={`expiryDate`}
                        id={`expiryDate`}
                        className={classes.textInputStyle}
                        value={this.state.expiryDate}
                        name={`expiryDate`}
                        type={"date"}
                        inputProps={{ readOnly: true }}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4} >
                      <LabelWithIcon label={t("policy_revision_date")} />
                      <TextField
                        data-test-id={`revisionDate`}
                        id={`revisionDate`}
                        className={classes.textInputStyle}
                        value={this.state.revisionDate}
                        name={`revisionDate`}
                        type={"date"}
                        inputProps={{ readOnly: true }}
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <LabelWithIcon label={t('original_policy_value')} />
                      <OutlinedInput
                        data-test-id="originalValue"
                        name="originalValue"
                        className={classes.textInputStyle}
                        value={this.state.originalValue}
                        fullWidth
                        readOnly
                        startAdornment={<InputAdornment position="start" className={classes.sarText}>SAR</InputAdornment>}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <LabelWithIcon label={t('current_policy_value')} />
                      <OutlinedInput
                        data-test-id="currentValue"
                        name="currentValue"
                        className={classes.textInputStyle}
                        value={this.state.currentValue}
                        fullWidth
                        readOnly
                        startAdornment={<InputAdornment position="start" className={classes.sarText}>SAR</InputAdornment>}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <LabelWithIcon label={t('policy_balance_due')} />
                      <OutlinedInput
                        data-test-id="balanceDue"
                        name="balanceDue"
                        className={classes.textInputStyle}
                        value={this.state.balanceDue}
                        fullWidth
                        readOnly
                        startAdornment={<InputAdornment position="start" className={classes.sarText}>SAR</InputAdornment>}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <LabelWithIcon label={`${t("sales")}`} />
                      <TextField
                        id={"sales"}
                        className={classes.textInputStyle}
                        value={this.state.sales}
                        name={`sales`}
                        inputProps={{ readOnly: true }}
                        data-test-id="sales"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <LabelWithIcon label={`${t("operations")}`} />
                      <TextField
                        id={"operations"}
                        className={classes.textInputStyle}
                        value={this.state.operations}
                        name={`operations`}
                        inputProps={{ readOnly: true }}
                        data-test-id="operations"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <LabelWithIcon label={`${t("finance")}`} />
                      <TextField
                        id={"financePerson"}
                        className={classes.textInputStyle}
                        value={this.state.financePerson}
                        name={`financePerson`}
                        inputProps={{ readOnly: true }}
                        data-test-id="financePerson"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>

                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            {this.state.policyTitle !== 'other' &&
              <>
                {!this.state.csvList?.length && <Grid data-test-id={'overview'} item xs={12} className={classes.headerButtonStyle} >
                  <Button
                    color="primary"
                    className={classes.btnStyle}
                    data-test-id="csvImport"
                    id="csvImport"
                    onClick={()=> {

                      this.navigateToCSVImport(this.state.policyTitle === "vehicle" ? "vehi" : "emp")}}
                  >
                    {t('csv_import')}
                  </Button>
                </Grid>}
                <Grid data-test-id={'policyTable'} item xs={12}>
                  <div className={classes.tableContainer}>
                    <Typography variant="subtitle1" className={classes.tableTitle}>
                      {t(`${this.state.policyTitle}`)}
                    </Typography>
                  {
                    <PaginatedTable
                  key={'vehicle_employee_table'}
                  curretPage={this.state.policyPage}
                  totalPage={this.state.totalPage}
                  perPage={this.state.policyRowPerPage}
                  pageChange={this.handlePage}
                  handleRowPerPage={this.handleRow}
                  tableFor={this.state.csvList.length !== 0 ? 'csv' : 'policyOverviewCsv'}
                  tableData={this.state.csvList}
                  tableColumn={this.state.policyTitle === 'vehicle' ? VEHICLE_LIST_TABLE_BODY : EMPLOYEE_LIST_TABLE_BODY}
                />}
                  </div>
                </Grid>
              </>
            }
            <Grid data-test-id={'requestTable'} item xs={12}>
              <div className={classes.tableContainer}>
                <Typography variant="subtitle1" className={classes.tableTitle}>
                  {t('request')}
                </Typography>
                <PaginatedTable
                  key={'policy_request_table'}
                  curretPage={this.state.policyRequestPage}
                  totalPage={this.state.policyListMeta.total_page}
                  perPage={this.state.policyRequestRowPerPage}
                  pageChange={this.handlePolicyRequestPage}
                  handleRowPerPage={this.handlePolicyRequestRow}
                  tableFor={'PolicyOverview'}
                  tableData={this.state.policyList}
                  tableColumn={POLICY_DETAIL_LIST_TABLE_DATA}
                  path={'/PolicyRequest'}
                />
              </div>
            </Grid>
            <Grid data-test-id={'payment'} item xs={12}>
              <Accordion
                data-test-id="transaction_table_accordian"
                className={classes.accordionStyle}
                expanded={this.state.paymentAccordian}
                onChange={() => this.handlePaymentAccordian()}
              >
                <AccordionSummary
                  aria-expanded={false}
                  expandIcon={<ExpandMore />}
                  aria-controls="Payment"
                  id="Payment"
                >
                  <Typography variant="subtitle1" className={classes.accordianHeading}>{t('payment')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TransactionTableForPolicy
                      data-test-id="transaction_table"
                      {...this.props}
                      tableFor={'PolicyListing'}
                      tableData={this.state.transactionList}
                      transactionLoading={this.state.transactionLoading}
                    />
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid data-test-id={'fileData'} item xs={12}>
              <Accordion className={classes.accordionStyle} expanded={true}>
                <AccordionSummary
                  aria-expanded={false}
                  aria-controls="Files"
                  id="Files"
                >
                  <div className={classes.topSection}>
                    <div className={classes.displayFlex}>
                      <Typography variant="subtitle1" className={classes.accordianHeading}>{t('files')}</Typography>
                    </div>
                    <Button className={classes.descriptionIcon} data-test-id="noteDialog" component="label">
                      + Add File{' '}
                      <input type="file" hidden multiple data-test-id="noteDialogInput" onChange={(e: any) => this.uploadPolicyOverviewFile(e.target.files)} />
                    </Button>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Accordion className={classes.nestedAccordion} expanded={true}>
                    <AccordionSummary
                      aria-expanded={false}
                      aria-controls="Files"
                      id="Files"
                      className={classes.fileTopTitle}
                    >
                      <div className={classes.fileTopSection} id="files-name-section">
                          <Typography variant="subtitle1" className={classes.accordianHeading}>File Name</Typography>
                          <Typography variant="subtitle1" className={classes.accordianHeading}>Edit</Typography>
                      </div>
                    </AccordionSummary>
                    <FileListItem
                      uploadedFileList={this.state.uploadedFileList}
                      showDelete={this.state.showDelete}
                      removePolicyOverviewFile={this.removePolicyOverviewFile}
                    />
                  </Accordion>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

const classes = {
  accordianHeading: {
    fontWeight: 600,
    color: '#000'
  },
  policyDetailStyle: {
    padding: "15px 0px",
  },
  customerDetailsAccordion: {
    paddingTop: "10px",
    paddingBottom: "10px",
    "& .MuiAccordion-root-34.Mui-disabled, .MuiAccordion-root-42.Mui-disabled": {
      backgroundColor: "white",
    },
    " & .MuiAccordion-root.Mui-disabled": {
      backgroundColor: "white",
    },
  },
  topSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fileTopTitle: {
    backgroundColor: '#e9f4f9',
    borderRadius: "10px 10px 0 0",
    height: '50px',
  },
  fileTableData: {
    height: '50px',
    borderTop: '1px solid #979090',
  },
  fileTopSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 45px',
  },
  displayFlex: {
    display: "flex",
  },
  descriptionIcon: {
    color: '#007E98'
  },
  sarText: {
    "& .MuiTypography-colorTextSecondary-196" : {
      color: '#000',
    }
  },
  grayBtn: {
    "text-transform": 'none',
    padding: 15,
    borderRadius: 5,
    width: '32.5%',
    fontSize: 15,
    backgroundColor: "#979090",
    color: "white"
  },
  accordionStyle: {
    background: "white !important",
    borderRadius: "10px !important",
  },
  nestedAccordion: {
    flex: 'auto',
    borderRadius: "10px !important",
    border: '1px solid #979090 !important',
    boxShadow: 'none'
  },
  fileTableIconLeft: {
    display: 'flex',
    color: '#979090'
  },
  fileTableIconCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  fileIconLeft: {
    marginLeft: '20px',
    marginRight: '20px',
    color: '#979090'
  },
  fileTableIconRight: {
    display: 'flex',
    justifyContent: 'space-around',
    color: '#979090'
  },
  iconClick: {
    cursor: "pointer"
  },
  fileTopDataSection: {
    width: '100%',
    display: 'flex',
  },
  textInputStyle: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  tableContainer: {
    padding: "24px",
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  tableTitle: {
    fontWeight: 800,
    paddingBottom: 20
  },
  policyPageLoader: {
    display: 'flex',
    "position": "absolute",
    alignItems: 'center',
    justifyContent: 'center',
    left: '0',
    right: '0',
    margin: '0 auto',
    top: '50%',
    zIndex: '99'
  },
  mainSection: {
    "position": "relative"
  },
  loadingState: {
    "position": "relative",
    "pointer-events": "none",
    opacity: 0.5
  },
  headerButtonStyle: {
    display: "flex",
    justifyContent: "end"
  },
  primaryBtnStyle: {
    textDecoration:"none",
    "color": "white",
  },
  btnStyle: {
    background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    "text-transform":"none",
    "color": "white",
    "height": "50px",
    borderRadius: "10px",
    paddingLeft: "10px",
    paddingRight: "10px"
},
};

export default withStyles(classes as any)(withTranslation()(PolicyOverviewForm));
// Customizable Area End
