// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import React from 'react';

export interface Props {
  isExpanded?: boolean
  isEdit?: boolean;
  onNext?: any;
  onReject?: any;
  t?: any;
  classes?: any;
  setStageData?: any;
}

interface S {
  customerDataTable: any;
  existingPolicyDataTable: any;
  onGoingPolicyDataTable: any;
}

interface SS {
  id: any;
}

type medicalInsuranceDataType = {
  insurance_class: string,
  class_benefits: string,
}

const enumFormAction = {
  ADD: 'ADD',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
}

const enumYesNO = {
  YES: 'yes',
  NO: 'no',
}

const enumAccordioanState = {
  p5: 'p5'
}

const enumTosterType = {
  error: "error",
  success: 'success',
  warning: 'warning',
  info: 'info',
}


const enumBtnType = {
  NEXT: 'NEXT',
  DRAFT: 'DRAFT',
  REJECT: 'REJECT'
}

type MyState = {
  role: string,
  formType: string,
  isArabicLanguage: boolean,
  quotation_received: string,
  quotation_amount: string,
  comment: string,
  proposal: string,
  release_quotation_to_customer: string,
  additional_attachment: any,
  note_to_the_customer: any,
  release_premium_calculation: string,
  comparison_sheet: string,
  insuranceId: string,
  toster: boolean,
  tosterType: any,
  tosterText: string,
  blockError: boolean[],
  previousStageData: any,
  showRejectDialog: boolean,
  rejectReason: string,
  rejectedStage: string
  currentSteps: number,
  policyId: any,
  showNoteDialog: boolean,
  handOverNoteText: string,
  handleBtnClickName: string,
  expandedAccordion: boolean,
  btnGroupShow: boolean,
  accordionOpenClose: boolean,
  editData: any,
  viewDisable: boolean,
  saveDraftbool: boolean,
  accordianDisable: boolean,
  premiumCalculation:boolean,
  comparisionSheet:boolean,
  markAsLostDialog: boolean;
  isBtnVisible: boolean;
  attachmentIndex:any;
};

type MyProps = {
  isExpanded: boolean,
  isEdit: boolean,
  onNext: () => void;
};


type accordionTitleProps = {
  title: string,
  insuranceData: any,
}

const enumRoles = {
  ADMIN: "Admin",
  SALES: "Sales",
  OPERATIONS: "Operations",
  FINANCE: "Finance",
  CONTACT: "Contact",
}

export default class StageFiveController extends BlockComponent<Props, MyState, SS> {
  createApi: any;
  fileUploadApi: any;
  editApi: any;
  trackApi: any;
  markAsLostApi: any;
  getStageFiveApi: any;
  formikRefStage: any = null;
  fileToRemove: any = '';
  fieldName:any="";
  removeFileAPI:string="";
  getStageFourApi: any;
  static propTypes: any;
  formRef: any;
  policyStatusChange: any = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    /* istanbul ignore next */
    const queryParameter: any = new URLSearchParams(window.location.search);
    const policyId: string = queryParameter?.get('id')

    this.state = {
      policyId: policyId || "",
      role: '',
      formType: enumFormAction.ADD,
      isArabicLanguage: false,
      quotation_received: enumYesNO.YES,
      quotation_amount: '',
      comment: '',
      proposal: '',
      additional_attachment: [],

      note_to_the_customer: [],
      release_quotation_to_customer: enumYesNO.YES,

      release_premium_calculation: enumYesNO.YES,
      comparison_sheet: enumYesNO.YES,
      viewDisable: false,
      blockError: [],
      toster: false,
      tosterText: '',
      tosterType: enumTosterType.error,
      previousStageData: {},
      showRejectDialog: false,
      rejectReason: "",
      rejectedStage: "",
      currentSteps: 6,
      showNoteDialog: false,
      handOverNoteText: '',
      handleBtnClickName: '',
      expandedAccordion: false,
      btnGroupShow: true,
      accordionOpenClose: true,
      editData: {},
      saveDraftbool: false,
      accordianDisable: false,
      premiumCalculation:false,
      comparisionSheet:false,
      insuranceId: "",
      markAsLostDialog: false,
      isBtnVisible: true,
      attachmentIndex:null
    };
    this.formRef = React.createRef();
    this.formRef.current = [];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  componentDidMount(): any {
   const stageFiveData = JSON.parse(localStorage.getItem('stageFiveData')||"{}");
    const queryParameter: any = new URLSearchParams(window.location.search);
    const loginData = JSON.parse(localStorage.getItem('loginData') || '{}');
    const stageFourData = JSON.parse(localStorage.getItem('stageFourData') || '{}');
    this.setState({
      comparison_sheet:stageFiveData?.attributes?.comparison_sheet,
      release_premium_calculation:stageFiveData?.attributes?.release_premium_calculation
    })


    let policyId: string = queryParameter?.get('id');
    const url = window.location.href;
this.setState({premiumCalculation:stageFourData?.attributes?.premium_calculation?.id ? true :false ,
  comparisionSheet:stageFourData?.attributes?.comparison_sheet?.id ? true :false,
  insuranceId: stageFourData?.attributes?.submitting_insurance_infos?.data[0]?.attributes?.insurance_company_id?.id
})
    if (loginData) {
      this.setState({ role: loginData.role })
    }

    if (loginData?.role === enumRoles.CONTACT) {
      this.setState({ accordianDisable: true })
      this.setState({ accordionOpenClose: false })
    }
    const stageOne = JSON.parse(localStorage.getItem('stageOne') || '{}');
    policyId = policyId ? parseInt(policyId) : stageOne?.policyId
    this.setState({ policyId: policyId })

    const isCreateStage: boolean = url.toLowerCase().includes("policyrequest");
    /* istanbul ignore next */
    if (policyId && this.state.formType === enumFormAction.ADD && isCreateStage) {
      this.setState({ policyId: policyId });

      this.getApiCallFourAndFive(policyId)
      !this.props.isExpanded && this.setState({ accordionOpenClose: false })
    } else {
      this.getApiCallFourAndFive(policyId)
    }

    const ViewPolicy: boolean = url.toLowerCase().includes("viewpolicy"); //policyRequest
    /* istanbul ignore next */
    /* istanbul ignore next */
    ViewPolicy && this.setState({ formType: enumFormAction.VIEW }, () => {
      this.getApiCallFourAndFive(policyId)
      this.setState({ viewDisable: true })
      this.setState({ accordionOpenClose: false })
    })
    /* istanbul ignore next */
    this.props.isEdit && this.setState({ formType: enumFormAction.EDIT }, () => {
      this.getDataStageFiveApiCall(policyId);
    })

  }

  getApiCallFourAndFive = (policyId: any) => {
    if (this.props.isExpanded) {
      this.getDataStageFourApiCall(policyId)
    } else {
      this.getDataStageFiveApiCall(policyId)
    }
  }

  handleAccordianChange = () => {
    /* istanbul ignore if */
    if (this.state.accordianDisable) {
      const { role } = JSON.parse(window.localStorage.getItem('loginData') || '{}')
      this.setState({
        tosterText: `Releasing to customer is not accessible to ${role} in user`,
        toster: true,
        tosterType: enumTosterType.warning,
      })
    } else {
      this.setState({
        accordionOpenClose: !this.state.accordionOpenClose
      })
    }
  }

  ItemRemoveFromArr = (state: any[], index: number) => {
    /* istanbul ignore next */
    if (state?.length) {
      const itemRemove = [...state]
      if (index > -1) { // only splice array when item is found
        itemRemove?.splice(index, 1); // 2nd parameter means remove one item only
      }
      return itemRemove;
    }
  }

  getYesNo = (bool: boolean) => {
    return bool ? enumYesNO.YES : enumYesNO.NO;
  }

  handleAccordian(isExpanded: boolean, accordionName: string) {
    this.setState({
      expandedAccordion: isExpanded ? true : false
    })
  }

  getFormData = (file_key: string, fileData: any, index: any, isMultiUpload: boolean) => {
    const formData = new FormData();
    const insuranceCmpId = this.state.previousStageData.attributes.submitting_insurance_infos.data[index].attributes.insurance_company_id.id;

    formData.append("data[stage]", "5");
    formData.append("data[file_key]", file_key);
    formData.append("data[insurance_company_id]", insuranceCmpId)

    if (isMultiUpload) {
      formData.append(`data[multiple]`, 'true');
      for (const dataItem of fileData) {
        formData.append('data[file][]', dataItem);
      }
    } else {
      formData.append(`data[multiple]`, 'false');
      formData.append("data[file]", fileData);
    }

    return formData;
  }
  removeFile = (fileId: number, formikRef: any,fieldName:any,index:any) => {
    this.formikRefStage = formikRef
    this.fileToRemove = fileId
    this.fieldName=fieldName
    this.setState({attachmentIndex:index})
    this.removeFileAPI = this.apiCallForStageFive({
        contentType: 'application/json',
        method: 'PUT',
        body: JSON.stringify({ data: { file_id: fileId } }),
        endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/remove_file`,
    })
}
removeFileAPIReceive = (responseJson:any,errorResponse:any) => {

  /*istanbul ignore next*/
      if (responseJson?.data) {
          this.formRef.current[this.state.attachmentIndex].setFieldValue(this.fieldName, {})
          this.setState({
            tosterType: "warning",
            tosterText: responseJson.data.message,
            toster: true,
          })
      }
}

  handleFileUpload = async (fileObj: any, index: number,fieldName:any,formikRef:any) => {
    const fileName = 'additional_attachments';
    const isMultiUpload = false;
    this.formikRefStage = formikRef.current[index]
    /*istanbul ignore next*/
    this.fieldName = fieldName
    /*istanbul ignore next*/
    this.setState({attachmentIndex:index})
    const formData = this.getFormData(fileName, fileObj, index, isMultiUpload);
    const fileUploadApiResponse = await this.FileUploadApiCall(formData)
    return fileUploadApiResponse;
  }


  getPoicyStageOption = () => {
    const array = [
      { value: "info_gathering", label: this.props.t('info_gathering') },
      { value: "processing", label: this.props.t('processing') },
      { value: "awaiting", label: this.props.t('awaiting') },
      { value: "operations_review", label: this.props.t('operations_review') }
    ]
    return array;
  }

  handleOpenHandNote = (label: string) => {
    if (label === 'lost') this.setState({markAsLostDialog: true})
    this.setState({ showNoteDialog: true, isBtnVisible: false })
  }

  handleHandOverNoteText = (inputText: string) => {
    this.setState({ handOverNoteText: inputText })
  }

  closeNoteDialog = () => {
    this.setState({ markAsLostDialog: false, handOverNoteText:"", showNoteDialog: false, isBtnVisible: true })
  }

  openNoteDialog = () => {
    this.setState({ markAsLostDialog: true, showNoteDialog: true })
  }

  handleOkDialog = (value: string) => {
    const data = {
      data: {
        track_request: {
          stage_status: "next_stage", // reject_stage, next_stage
          comment: value,
          current_stage: 'customer_review', // change into new
        },
      },
    };

    this.policyStatusChange = this.TrackApiCall(data);
  }

  handleChangeRejectReason = (value: string) => {
    this.setState({
      rejectReason: value
    })
  }
  handleRejectDialog = (value: boolean) => {
    this.setState({
      showRejectDialog: value,
      isBtnVisible: true
    })
  }

  getStepName = (currentStep: number) => {
    switch (currentStep) {
      case (1):
        return "info_gathering"
      case (2):
        return "processing"
      case (3):
        return "awaiting"
      case (4):
        return "operations_review"
      case (5):
        return "sales_review"
      case (6):
        return "customer_review"
      default:
        return "";
    }
  }

  getStepNumber = (currentStep: string) => {
    switch (currentStep) {
      case ("info_gathering"):
        return 1
      case ("processing"):
        return 2
      case ("awaiting"):
        return 3
      case ("operations_review"):
        return 4
      case ("sales_review"):
        return 5
      case ("customer_review"):
        return 6
      default:
        return "";
    }
  }

  humanize(str: string) {
    let i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  changePolicyStep = async (currentStep: number, action: string,) => {

    this.setState({ showRejectDialog: false })
    const nextStatus = await (action === "next") ? "next_stage" : "reject_stage";

    const data = {
      data: {
        track_request: {
          stage_status: nextStatus, // reject_stage, next_stage
          comment: this.state.rejectReason,
          current_stage: this.state.rejectedStage // change into new
        }
      }
    }

    this.policyStatusChange = this.TrackApiCall(data)
  }

  getFormikValues = () => {
    const formikValues = this.formRef.current
    return formikValues
  }

  checkError = (value: any) => {
    const temp = [...this.state.blockError]
    let isError = false;
    value?.map((obj: any, index: number) => {
      value[index].handleSubmit();
      if (obj.values['note_to_the_customer'] === '' || !obj.values['additional_attachment']) {
        temp[index] = true;
        const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
        this.setState({
          tosterText: isArabic ? 'يرجى ملء كافة الحقول المطلوبة' : 'Please fill all required feilds',
          toster: true,
          tosterType: enumTosterType.error
        })
        this.setState({})
        isError = true
      } else {
        temp[index] = false
      }

    })
    this.setState({ blockError: temp })

    return isError;

  }

  checkOnChangeError = (values: any, index: number) => {
    const temp = [...this.state.blockError]
    temp[index] = false
    this.setState({ blockError: temp })
  }

  handleOnChange = (values: any, index: number, item: any) => {
    this.checkOnChangeError(values, index)

    const temp = { ...this.state.editData }
    if (!temp[values.insurance_company_id]) {
      temp[values.insurance_company_id] = values.insurance_company_id;
    }
    this.setState({ editData: temp })
  }


  formDataFormat = (values: any, isEdit: boolean = false) => {
    const formData = new FormData();
    const stageFourData = JSON.parse(localStorage.getItem('stageFourData') || '{}');
    const temp = [...values]
    temp?.map((item: any, index: number) => {
      item.values.release_quotation_to_customer && formData.append(`data[releasing_to_customer][release_insurance_infos_attributes][${index}][release_quotation_to_customer]`, item.values.release_quotation_to_customer);

      item.values.note_to_the_customer && formData.append(`data[releasing_to_customer][release_insurance_infos_attributes][${index}][note_to_the_customer]`, item.values.note_to_the_customer);
      formData.append(`data[releasing_to_customer][release_insurance_infos_attributes][${index}][insurance_company_id]`, stageFourData.attributes?.submitting_insurance_infos.data[index].attributes.insurance_company_id.id)
      /* istanbul ignore if */
      if (isEdit) {
        const stageFiveData = JSON.parse(window.localStorage.getItem('stageFiveData') ?? '');
        formData.append(`data[releasing_to_customer][release_insurance_infos_attributes][${index}][id]`, stageFiveData.attributes.release_insurance_infos.data[index].id); 
      }
      return formData;
    })
   formData.append(`data[releasing_to_customer][release_premium_calculation]`, this.state.release_premium_calculation=="yes"?"yes":"no");
   formData.append(`data[releasing_to_customer][comparison_sheet]`, this.state.comparison_sheet=="yes"?"yes":"no");

    if (this.state.handleBtnClickName === enumBtnType.DRAFT) {
      formData.append(`data[releasing_to_customer][save_as_draft]`, 'yes')
    } else {
      formData.append(`data[releasing_to_customer][save_as_draft]`, 'no')
    }
    return formData;
  }

  handleSubmit = (btnName: string) => {
    const stageFourData = JSON.parse(window.localStorage.getItem('stageFourData') ?? '{}')
    this.setState({ handleBtnClickName: btnName }, () => {
      const formikValues = this.getFormikValues();
      let isError = false;
      if (this.state.handleBtnClickName !== enumBtnType.DRAFT) {
        isError = this.checkError(formikValues)
      }
      const formData = this.formDataFormat(formikValues);
      /* istanbul ignore if */
      if (this.state.policyId && stageFourData?.attributes?.is_next_stage_submitted && !isError) {
        const tempData: any = [];
        let addData = false;
        formikValues.map((val: any, index: number) => {
          if (this.state.editData[val.values.insurance_company_id]) {
            tempData.push(val)
          }
        })

        const formEditData = this.formDataFormat(formikValues, true)
        this.EditApiCall(formEditData)
      } else if (!isError || this.state.handleBtnClickName === enumBtnType.DRAFT) {
        this.CreateApiCall(formData)
      }
    })
  }

  handleReject = () => {
    this.setState({
      showRejectDialog: true
    })
    this.setState({ handleBtnClickName: enumBtnType.REJECT })
  }

  handleMarkAsLost = (value: string) => {
    const formData = new FormData();
    formData.append(`data[mark_as]`, 'lost')
    formData.append(`data[lost_reason]`, value)
    this.MarkAsLostApiCall(formData)
  }


  async getDataStageFiveApiCall(policyId: any) {
    this.getStageFiveApi = this.apiCallForStageFive({
      method: 'GET',
      endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=5`,
    })
  }
  async getDataStageFourApiCall(policyId: any) {
    this.getStageFourApi = this.apiCallForStageFive({
      method: 'GET',
      endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=4`,
    })
  }

  async CreateApiCall(data: any) {
    this.createApi = this.apiCallForStageFive({
      method: 'POST',
      endPoint: `bx_block_policy_request/policy_requests?stage=5&id=${this.state.policyId}`,
      body: data
    })
  }

  async EditApiCall(data: any) {
    this.editApi = this.apiCallForStageFive({
      method: 'PUT',
      endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=5`,
      body: data
    })
  }

  async TrackApiCall(data: any) {
    const conCatString = await data.data.track_request.stage_status === 'reject_stage' ? '?stage=5' : '';
    this.trackApi = this.apiCallForStageFive({
      contentType: "application/json",
      method: 'POST',
      endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/track_requests${conCatString}`,
      body: JSON.stringify(data)
    })
  }

  async MarkAsLostApiCall(data: any) {
    this.markAsLostApi = this.apiCallForStageFive({
      method: 'PUT',
      endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/mark_as`,
      body: data
    })
  }


  async FileUploadApiCall(data: any) {
    this.fileUploadApi = this.apiCallForStageFive({
      method: 'PUT',
      endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/file_upload`,
      body: data
    })
  }

  getStageFiveAlertMessaeg = (isDraft: boolean) => {
    const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
    let alertMessage = "";
    if (isDraft) {
      alertMessage = isArabic ? "تم حفظ بيانات طلب المراجعة كمسودة!" : "Data has been saved as draft!"
    } else {
      alertMessage = isArabic? "لقد تم نقل طلبك إلى مراجعة العملاء" : "Your request has been moved to Customer Review"
    }
    return alertMessage
  }

  setStageFiveApiErrorMessage = (responseJson: any) => {
    if (!responseJson) return;
    let errorMessage = "Something went wrong"
    if (responseJson?.errors) {
      errorMessage = typeof (responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error || "Something went wrong";
    }
    this.setState({
      tosterType: enumTosterType.error,
      tosterText: errorMessage,
      toster: true,
      markAsLostDialog: false,
      showNoteDialog: false
    })
  }

  /* istanbul ignore next */
  fetchStageFiveData = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      if (responseJson.errors) {
        this.setStageFiveApiErrorMessage(responseJson)
      } else {
        const dataTemp: any = {
          attributes: {
            submitting_insurance_infos: {},
          }
        };

        dataTemp.attributes.submitting_insurance_infos = responseJson.data.attributes.release_insurance_infos;
        this.setState({ previousStageData: dataTemp,comparison_sheet:responseJson.data.attributes.comparison_sheet,release_premium_calculation:responseJson.data.attributes.release_premium_calculation })
        window.localStorage.setItem('stageFiveData', JSON.stringify(responseJson.data));
        this.props.setStageData('stageFiveData',responseJson.data);
      }
    } else {
      this.setStageFiveApiErrorMessage(responseJson)
    }
  }

/* istanbul ignore next */
  fetchStageFourData = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      if (responseJson.errors) {
        this.setStageFiveApiErrorMessage(responseJson)
      } else {
        console.log("stage Four: ", responseJson)
        const dataTemp: any = {
          attributes: {
            submitting_insurance_infos: {},
          }
        };

        dataTemp.attributes.submitting_insurance_infos = responseJson.data.attributes.submitting_insurance_infos;
        this.setState({ previousStageData: dataTemp })
        window.localStorage.setItem('stageFourData', JSON.stringify(responseJson.data))
      }
    } else {
      this.setStageFiveApiErrorMessage(responseJson)
    }
  }

  /* istanbul ignore next */
  handleCreateRequest = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      if (responseJson.errors) {
        this.setStageFiveApiErrorMessage(responseJson)
      } else {
        this.getDataStageFourApiCall(this.state.policyId)
        window.localStorage.setItem('stageFiveData', JSON.stringify(responseJson.data))
        this.props.setStageData('stageFiveData',responseJson.data);
        this.setState({ expandedAccordion: false, markAsLostDialog: false });

        if (this.state.handleBtnClickName !== enumBtnType.DRAFT) {
          this.setState({ saveDraftbool: true, showNoteDialog: true })
          this.setState({ accordionOpenClose: false })
        } else{
          this.setState({
            tosterText: this.getStageFiveAlertMessaeg(true),
            toster: true,
            tosterType: enumTosterType.success,
          })
        }  
      }
    } else {
      this.setStageFiveApiErrorMessage(responseJson)
    }
  }

/* istanbul ignore next */
  handleEditRequest = (responseJson: any, errorReponse: any) => {
    if (responseJson.data) {
      this.setState({
        markAsLostDialog: false
      });

      if (this.state.handleBtnClickName !== enumBtnType.DRAFT) {
        this.setState({ accordionOpenClose: false, showNoteDialog: true })
        this.setState({ saveDraftbool: true })
      } else{
        this.setState({
          tosterText: this.getStageFiveAlertMessaeg(true),
          toster: true,
          tosterType: enumTosterType.success,
        })
      }
    } else {
      this.setStageFiveApiErrorMessage(responseJson)
    }
  }

  /* istanbul ignore next */
  handleTrackRequest = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      if (responseJson.errors) {
        this.setStageFiveApiErrorMessage(responseJson)
      } else {
        this.setState({ showRejectDialog: false })
        if (this.state.handleBtnClickName === enumBtnType.NEXT) {
          this.setState({ btnGroupShow: false })
          this.setState({ accordionOpenClose: false })
          this.setState({
            tosterText: this.getStageFiveAlertMessaeg(false),
            toster: true,
            tosterType: enumTosterType.success,
            showNoteDialog: false, expandedAccordion: false
          })
          this.props.onNext(5);
        }

        if (this.state.handleBtnClickName === enumBtnType.REJECT) {
          this.setState({ accordionOpenClose: false })
          this.setState({ showRejectDialog: false })
          this.setState({
            tosterText: `Your request has been moved to ${this.humanize(this.state.rejectedStage)}`,
            toster: true,
            tosterType: enumTosterType.success
          })
          const setpNumber = this.getStepNumber(this.state.rejectedStage)
          localStorage.removeItem('stageFiveData')
          this.props.onReject(setpNumber);
        }
      }
    } else {
      this.setStageFiveApiErrorMessage(responseJson)
    }
  }

/* istanbul ignore next */
  handleMarkLostRequest = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      if (responseJson.errors) {
        this.setStageFiveApiErrorMessage(responseJson)
      } else {
        const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
        this.setState({
          tosterText: isArabic ? "تم وضع علامة على طلبك كمفقود" : "Your request has been mark as lost",
          toster: true,
          tosterType: enumTosterType.success,
          markAsLostDialog: false,
          showNoteDialog: false
        })
        this.getDataStageFourApiCall(this.state.policyId);
        this.getDataStageFiveApiCall(this.state.policyId);
        window.location.href = "/Requests"
      }
    } else {
      this.setStageFiveApiErrorMessage(responseJson)
    }
  }

/* istanbul ignore next */
  handleFileUploadRequest = (responseJson: any, errorReponse: any) => {
    if (responseJson) {
      if (responseJson.error) {
        this.setStageFiveApiErrorMessage(responseJson)
        this.setState({
          accordionOpenClose: false
        })
      } else {
        this.formRef.current[this.state.attachmentIndex].setFieldValue(this.fieldName, responseJson.data.uploaded_file)
        let additional_attachments=[...this.state.additional_attachment]
        additional_attachments[this.state.attachmentIndex]=responseJson.data?.uploaded_file
        this.setState({additional_attachment:additional_attachments})
        const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
        this.setState({
          tosterText: isArabic ? "تم تحميل الملف بنجاح" : "File Uploaded Sucessfully",
          toster: true,
          tosterType: enumTosterType.success
        })
      }
    } else {
      this.setStageFiveApiErrorMessage(responseJson)
    }
  }

/* istanbul ignore next */
  handleApiIDForStageFive = (apiRequestCallId: any, message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId === this.getStageFiveApi) {
      this.fetchStageFiveData(responseJson, errorReponse);
    }

    else if (apiRequestCallId === this.getStageFourApi) {
      this.fetchStageFourData(responseJson, errorReponse);
    }

    else if (apiRequestCallId === this.createApi) {
      this.handleCreateRequest(responseJson, errorReponse)
      this.parseApiCatchErrorResponse(errorReponse);
    }

    else if (apiRequestCallId === this.editApi) {
      this.handleEditRequest(responseJson, errorReponse)
      this.parseApiCatchErrorResponse(errorReponse);
    }

    else if (apiRequestCallId === this.trackApi) {
      this.handleTrackRequest(responseJson, errorReponse)
      this.parseApiCatchErrorResponse(errorReponse);
    }

    else if (apiRequestCallId === this.markAsLostApi) {
      this.handleMarkLostRequest(responseJson, errorReponse)
      this.parseApiCatchErrorResponse(errorReponse);
    }

    else if (apiRequestCallId === this.fileUploadApi) {
      this.handleFileUploadRequest(responseJson, errorReponse)
      this.parseApiCatchErrorResponse(errorReponse);
    }
    else if (apiRequestCallId === this.removeFileAPI) {
      this.removeFileAPIReceive(responseJson, errorReponse)
      this.parseApiCatchErrorResponse(errorReponse);
    }
  }

/* istanbul ignore next */
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId != null) {
        this.handleApiIDForStageFive(apiRequestCallId, message)
      }
    }
    // Customizable Area End
  }

  apiCallForStageFive(data: any) {
    const { contentType, method, endPoint, body } = data;
    const userDataForStageFive = JSON.parse(localStorage.getItem('loginData') || '{}');
    const tokenForStageFive = userDataForStageFive.token || '';

    const headerForStageFive = {
      "Content-Type": contentType,
      "token": tokenForStageFive
    };

    const requestMessageForStageFive = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForStageFive.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerForStageFive)
    );
    requestMessageForStageFive.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForStageFive.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    requestMessageForStageFive.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessageForStageFive.id, requestMessageForStageFive);
    return requestMessageForStageFive.messageId;
  }
}
// Customizable Area End