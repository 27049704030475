import * as Yup from 'yup';
export const configJSON = require("../../../blocks/email-account-registration/src/config");

const EmailRegistrationFormSchema = (isArabicLanguage:boolean) => {
    const schema = {
        firstName: Yup.string()
          .min(1,!isArabicLanguage ? "First name is too short" : "الاسم الأول قصير جدًا")
          .max(20,!isArabicLanguage ? "First name is too long" : "الاسم الأول طويل جدًا")
          .matches(/^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/, isArabicLanguage ? 'يجب ألا يحتوي هذا الحقل على مسافة بيضاء أو أحرف خاصة أو أرقام' : 'This field should not contain white space, special character or digits')
          .required(isArabicLanguage ? 'الاسم الأول مطلوب' : "First Name is required."),
        lastName: Yup.string()
          .min(2,!isArabicLanguage ? "Last name is too short" : "الاسم الأخير قصير جدًا")
          .max(20,!isArabicLanguage ? "Last name is too long" : "الاسم الأخير طويل جدًا")
          .matches(/^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/, isArabicLanguage ? 'يجب ألا يحتوي هذا الحقل على مسافة بيضاء أو أحرف خاصة أو أرقام' : 'This field should not contain white space, special character or digits')
          .required(isArabicLanguage ?  "اسم العائلة مطلوب" : 'Last Name is required.'),
        email: Yup.string()
          .min(1,!isArabicLanguage ? "Email is too short" : "البريد الإلكتروني قصير جدًا")
          .max(50,!isArabicLanguage ? "Email is too long" : "البريد الإلكتروني طويل جدًا")
          .email().required(isArabicLanguage ? 'اسم العائلة مطلوب' : 'Email is required.'),
        phoneNumber: Yup.string().matches(/^(05|5)(0|1|2|3|4|5|6|7|8|9)([0-9]{7})$/, isArabicLanguage ? 'رقم الهاتف غير صالح' :'Phone number is not valid').required(isArabicLanguage ? 'رقم الهاتف مطلوب' :'Phone number is required.'),
        password: Yup.string()
          .required(isArabicLanguage ?  configJSON.ArabicPleaseEnterPasswword : configJSON.pleaseEnterAPassword)
          .min(8, isArabicLanguage ? configJSON.ArabicMinimum8CharctorText : configJSON.passwordMustBeAtLeast8Characters)
          .matches(/^(?=.*[a-z])/, isArabicLanguage ? configJSON.ArabicAtLeasetOnceLowerCaseLetterText : configJSON.passwordMustContainLowercaseCharacter)
          .matches(/^(?=.*[A-Z])/, isArabicLanguage ? configJSON.ArabicAtLeastOnceUpperCaseLetterText : configJSON.passwordMustContainUppercaseCharacter)
          .matches(/^(?=.*[0-9])/, isArabicLanguage ? configJSON.ArabicAtLeaseOneNumaricCharacterText : configJSON.passwordMustContainAtLeastOneNumber),
        termAndCondition: Yup.boolean().oneOf([true],isArabicLanguage ? configJSON.ArabicTermAndConditionErrorMessage : configJSON.termAndConditionErrorMessage).required(isArabicLanguage ? configJSON.ArabicTermAndConditionErrorMessage : configJSON.termAndConditionErrorMessage)
      }
      return schema
}

export default EmailRegistrationFormSchema;