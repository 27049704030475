import React, { useState, useEffect} from 'react'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LanguageIcon from '@material-ui/icons/Language';
import { ContextData } from '../../utils';
import DehazeIcon from '@material-ui/icons/Dehaze';
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";



export const useStyles = makeStyles({
  topBarWrapper: {
    // boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.12)',
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    zIndex: 1,
    backgroundColor: 'transparent',
  },
  topActionWrapper: {
    width: "calc(100% - 245px)",
    display: "flex",
    alignItems: "center",
    padding: "15px",
    justifyContent: "flex-end",
    marginInlineStart: 'auto',
  },
  languageOption: {
    display: "flex",
    alignItems: "center",
    "& span": {
      padding: "0px 8px",
    },
    '& button': {
      padding: '0px',
    },
    '& > div':{
      display: 'flex',
      alignItems: 'center'
    },
    '& .LangButton':{
      fontWeight: 700,
    },
    '& .btnColorActive': {
      color: '#406d7f'
    },
    '& .btnColor':{
      color: '#979ea1'
    }
  },
  userName: {
    textTransform: 'capitalize',
    '& span': {
      color: '#2d6f8f',
      display: 'block',
      '& div': {
        textAlign: 'right'
      }
    },
    '& .MuiButton-label': {
      color: '#000',
    },
  },
  logout:{
    cursor: 'pointer'
  },
  barWrap:{
    display: 'none',
    '@media(max-width: 767px)':{
      display: 'inline-block',
    }
  },
  backButtonStyle:{
    marginInlineEnd: "auto",
    cursor: 'pointer'
  }
});

const enumLang = {
  EN : 'en',
  AR : 'ar',
}

interface propsTypes{
  langControll: (props:string) => void;
  sidebarToggle: () => void;
}


const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

// export languageToggleFun = ( )

type loginUser = {
  name: string;
  role: string;
}

const TopBar = (props:propsTypes) => {
  const classes = useStyles();
  const [activeLang, setActiveLang] = useState(localStorage.getItem('lang'))
  const [langControll, setlangControll] = useState(localStorage.getItem('lang'))
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userName, setUserName] = useState<loginUser>({
    name : '',
    role: '',
  })  

  const { t } = useTranslation();

  const handleLogout = () => {
    localStorage.removeItem("loginData")
    window.location.href = "/EmailAccountLoginBlock";
  }

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem('loginData') || '{}');
    if(loginData !== "{}"  && loginData !== "undefined" && loginData !== undefined && loginData !== null && loginData !== "" ){
      setUserName({
        name: loginData?.name,
        role: loginData?.role,
      })
    }
  }, [])

  return (
    <div className={classes.topBarWrapper}>

      <ContextData.Consumer>
        {
          (lang: string) => (
            
      <div className={`${classes.topActionWrapper}`}>
        <div onClick={() => history.back()} className={classes.backButtonStyle}>
        <ArrowBackIosIcon data-testId="backBtn" />
      </div>

        <div className={classes.languageOption}>
          <div>
            <LanguageIcon style={{color: '#406d7f'}} />
            {/* @ts-ignore */}
            <Button className={`LangButton ${activeLang === enumLang.EN ? 'btnColorActive' : 'btnColor'}`} data-testId="EnglishBtn" onClick={() => {
              localStorage.setItem("lang", enumLang.EN);
              setlangControll(enumLang.EN)
              setActiveLang(enumLang.EN)
              props?.langControll(enumLang.EN);
            }}>
              English
            </Button>{" "}
            | <Button className={`LangButton ${activeLang === enumLang.AR ? 'btnColorActive' : 'btnColor'}`} data-testId="ArabicBtn" onClick={() => {
              localStorage.setItem("lang", enumLang.AR);
              setlangControll(enumLang.AR)
              setActiveLang(enumLang.AR)
              props?.langControll(enumLang.AR)
            }}>العربي</Button>
          </div>
          <div>          
            <Button onClick={(e) => setAnchorEl(e.currentTarget)} data-testId="user" className={classes.userName}> <div style={{ color: '#1f272a', fontWeight: 700,}}> {t(userName?.name)}</div> <div style={{ color: '#979ea1'}}>{t(userName?.role?.toLowerCase())}</div></Button>
          </div>

        {/* <Button data-testId="logout" className={classes.logout} onClick={logout}><PowerSettingsNewIcon /></Button> */}
        
        {/* @ts-ignore */}
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
      <MenuItem onClick={handleLogout}>Log Out</MenuItem>
        {/* {dropDownList.map((list) =>
          list.navigate ? (
            <MenuItem onClick={handleNavigate}>{t(list.value)}</MenuItem>
          ) : (
            <MenuItem>{t(list.value)}</MenuItem>
          )
        )} */}
      </StyledMenu>
        </div>

      <div className={classes.barWrap}>
            <DehazeIcon onClick={props?.sidebarToggle} />
      </div>
      </div>
          )
        }
     </ContextData.Consumer>
    </div>
  )
}

export default TopBar