// Customizable Area Start
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import { getStorageData } from "../../../../../../framework/src/Utilities";
import { getStageNumberByName } from "../../../../../../components/src/Common/PolicyRequest/StagesName";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    t?: any;
    classes?: any;
    currentStep?: number;
    policyValue?: number;
    rrStageOneData?: any;
    rrStageTwoData?: any;
    rrStageThreeData?: any;
    rrStageFourData?: any;
    isExpanded?: boolean
    isEdit?: boolean;
    onNext?: any;
    onReject?: any;
    setStageData?: any;
    parentPolicyID?:any;
    revisionRequestID?:any
    handOverNoteForOperationsReview?:any
    hitStageFourGetAPI?:any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    markAsLostDialog: boolean,
    showHandOverNote: boolean,
    showNextDialog: boolean,
    isBtnVisible: boolean,
    showRejectDialog: boolean,
    trackFor: string,
    rejectReasonText: string,
    moveToStep: any,
    saveDraftLoading: boolean,
    policyIsSavedAsDraft: boolean,
    policyId: string,
    loading: boolean,
    handOverNoteText: string
    alertMessage: string,
    alertType: string,
    showAlert: boolean,
    expandedAccordion: boolean;
    isArabicLanguage:boolean;
    initialData:any;
    policy_impact:boolean;
    StageFourDataRevisionRequest:any;
    isPostResponseCalledOnce:any;
    revisionRequestId:string;
    loggedInRole: string;
	hasStageFourPermissionList: any[];
    isNextBtnClicked:boolean

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class StageFourController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    aveRvPolicyAsDraftApi: any = "";
    markPolicyAsLostApi: any = "";
    handoverNoteApi: any = "";
    stageFourNextApi:any="";
    removeFileAPIStageFour:any="";
    stageFourEditApi:any="";

    trackRequestApiStageFour:any = "";
    // Customizable Area End

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
        ];

        this.state = {
            isArabicLanguage: this.checkActiveLanguage(),
            showHandOverNote: false,
            showNextDialog: false,
            isBtnVisible: true,
            showRejectDialog: false,
            rejectReasonText: "",
            trackFor:"",
            moveToStep: {},
            markAsLostDialog: false,
            saveDraftLoading: false,
            policyIsSavedAsDraft: false,
            policyId: "",
            loading: false,
            handOverNoteText: "",
            alertMessage: "",
            alertType: "",
            showAlert: false,
            expandedAccordion: this.props.isExpanded || false,
            initialData:{
            revision_number: "",
            revision_date:"",
            policy_impact:"",
            revision_confirmation:{},
            invoice_number:"",
            invoice_amount:"",
            invoice_date: "",
            new_policy_value:"",
            comment:"",
            revision_invoice:{},
            credit_note_number:"",
            credit_note_date:"",
            credit_note_amount:"",
            revision_credit_note:{},
            save_as_draft:"no"
            },
            policy_impact:false,
            StageFourDataRevisionRequest:this.props.rrStageFourData || {},
            isPostResponseCalledOnce:false,
            revisionRequestId:"",
            hasStageFourPermissionList:[],
            loggedInRole:"",
            isNextBtnClicked:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }
    getRvRequestRejectOptions = () => {
        const array = [
            { value: "info_gathering", label: "Info gathering" },
            { value: "processing", label: "Processing" },
            { value: "awaiting", label: "Awaiting" },
        ];
        return array;
    }
    setApiErrorMessage = (responseJson: any) => {
        if (!responseJson) return;
        let errorMessage = "Something went wrong"
        if (responseJson.errors) {
          errorMessage = typeof (responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error || "Something went wrong";
        }
        this.setState({
          alertType: "error",
          alertMessage: errorMessage,
          showAlert: true,
          saveDraftLoading: false,
          showRejectDialog: false,
          showHandOverNote: false,
          showNextDialog: false
        })
      }
    showStageFourApiErrorMessage = (stageOneResponseJson: any) => {
        if (stageOneResponseJson.errors) {

            const stageFourKeys = Object.keys(stageOneResponseJson.errors);
            const stageFourFirstKey = stageFourKeys && stageFourKeys[0];
            const StageOneErrorValue = (typeof (stageOneResponseJson.errors) === "string") ? stageOneResponseJson.errors : (stageFourFirstKey && stageOneResponseJson.errors[stageFourFirstKey][0] as string) || undefined;

            const StageOneErrorMessage = StageOneErrorValue || "Something went wrong";
            this.setState({
                alertType: "error",
                alertMessage: StageOneErrorMessage,
                showAlert: true,
                loading: false,
                saveDraftLoading: false,
                handOverNoteText: "",
                showHandOverNote: false,
            })
        }
    }

    stageFourTrackApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.trackRequestApiStageFour !== null &&
            this.trackRequestApiStageFour ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                if (this.state.trackFor === "next") {
                    this.props.setStageData({ "handOverNoteForOperationsReview": this.state.handOverNoteText })
                    this.props.setStageData({ "handoverNote": this.state.handOverNoteText })
                    this.closeNextDialogStageFour();
                    this.props.hitStageFourGetAPI()
                    this.props.onNext(6)
                } else {
                    const stageNumber = getStageNumberByName(this.state.moveToStep?.value)
                    this.closeRejectDialogStageFour()
                    this.props.onReject(stageNumber);
                }
                let alertMessage = this.getAlertMessageForTrackRequest();
                this.setState({
                    showAlert: true,
                    alertMessage: alertMessage,
                    alertType: "success",
                })
                /* istanbul ignore if*/
                if (this.props.rrStageFourData?.data?.attributes?.policy_impact === 'unchanged') window.location.href = "/Requests";
            } else {
                this.setApiErrorMessage(responseJson)
            }
        } else {
            this.setApiErrorMessage(undefined)
        }
    }
    getAlertMessageForTrackRequest = () => {
        let alertMessage = "";
        if(this.props.rrStageFourData?.data?.attributes?.policy_impact !== 'unchanged') {
            if (this.state.trackFor === "next") {
                alertMessage = this.state.isArabicLanguage ? "لقد تم نقل طلبك إلى مراجعة العمليات" : "Your request has been moved to customer review!"
            } else {
                alertMessage = this.state.isArabicLanguage ? "لقد تم نقل طلبك إلى المرحلة المحددة" : `Your request has been moved to selected stage!`
            }
        } else /* istanbul ignore next*/{
            alertMessage = this.state.isArabicLanguage ? "اكتمل طلبك!" : `Your request is completed!`
        }
        return alertMessage
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start
this.stageFourEditAPIReceive(message)
this.stageFourNextAPIReceive(message)
this.fileRemoveApiReceiveStageFour(message)
        this.stageFourTrackApiReceive(message)

        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
       super.componentDidMount();
       this.getArabLang()
       this.setPolicyImpact()
       const { role } = JSON.parse(window.localStorage.getItem('loginData') || '{}')
       const stageThreeMeta = this.props.rrStageThreeData?.meta;
       if(this.props.rrStageFourData?.data?.attributes?.is_next_stage_submitted) this.setState({expandedAccordion: false})
        this.setState({loggedInRole: role})
			this.setState({
				hasStageFourPermissionList: stageThreeMeta.next_stage_view_permission_role,
			})
        this.getStageFourData()
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<S>,
        snapshot?: SS | undefined
      ) {


        if (
            prevProps.revisionRequestID !== this.props.revisionRequestID
          ) {
            this.setState({ revisionRequestId: this.props.revisionRequestID ?? "" })
          }
        if (this.state.showAlert) {
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertType: "",
                    alertMessage: "",
                });
            }, 50000);
        }
        if (prevProps.isExpanded !== this.props.isExpanded) {
            this.setState({expandedAccordion: this.props.isExpanded as boolean})
          }
      }
    /* istanbul ignore next*/
    setPolicyImpact = () => {
        if (this.props.rrStageOneData?.data?.attributes?.revision_type?.value === "correct") {this.setState({initialData: {policy_impact: "unchanged"}})}
        else if (this.props.rrStageOneData?.data?.attributes?.revision_type?.value === "remove" || this.props.rrStageOneData?.data?.attributes?.revision_type?.value === "cancel") {this.setState({initialData: {policy_impact: "down"}})}
        else this.setState({initialData: {policy_impact: "up"}})
    }

    getArabLang=async()=>{
     let arabData :boolean=  await getStorageData("lang") === "ar" ? true : false
     this.setState({isArabicLanguage:arabData})
    }
    getStageFourData =()=>{
        if(this.props.rrStageFourData?.data){
           let stageFourPrefill:any ={}
            if(this.props.rrStageFourData?.data?.attributes?.policy_impact=="up"){
          stageFourPrefill = {
          revision_number: this.props.rrStageFourData.data.attributes.revision_number,
          revision_date:this.props.rrStageFourData.data.attributes.revision_date,
          policy_impact:this.props.rrStageFourData.data.attributes.policy_impact,
          revision_confirmation:this.props.rrStageFourData.data.attributes.revision_confirmation,
          comment:this.props.rrStageFourData.data.attributes.comment,
          invoice_number:this.props.rrStageFourData.data.attributes.revision_invoice.data?.attributes.invoice_number,
          invoice_date:this.props.rrStageFourData.data.attributes.revision_invoice.data?.attributes.invoice_date,
          invoice_amount:this.props.rrStageFourData.data.attributes.revision_invoice.data?.attributes.invoice_amount,
          new_policy_value:this.props.rrStageFourData.data.attributes.new_policy_value,
          revision_invoice:this.props.rrStageFourData.data.attributes.revision_invoice.data?.attributes.revision_invoice,
          credit_note_number:"",
          credit_note_date:"",
          credit_note_amount:"",
          revision_credit_note:"",


  }
            }else if(this.props.rrStageFourData?.data?.attributes?.policy_impact=="down"){
                stageFourPrefill = {
          revision_number: this.props.rrStageFourData.data.attributes.revision_number,
          revision_date:this.props.rrStageFourData.data.attributes.revision_date,
          policy_impact:this.props.rrStageFourData.data.attributes.policy_impact,
          revision_confirmation:this.props.rrStageFourData.data.attributes.revision_confirmation,
          comment:this.props.rrStageFourData.data.attributes.comment,
          credit_note_number:this.props.rrStageFourData.data.attributes.revision_credit_note.data.attributes.credit_note_number,
         credit_note_date:this.props.rrStageFourData.data.attributes.revision_credit_note.data.attributes.credit_note_date,
         credit_note_amount:this.props.rrStageFourData.data.attributes.revision_credit_note.data.attributes.credit_note_amount,
         revision_credit_note:this.props.rrStageFourData.data.attributes.revision_credit_note.data.attributes.revision_credit_note,
         invoice_number:"",
            invoice_amount:"",
            new_policy_value:this.props.rrStageFourData.data.attributes.new_policy_value,
            revision_invoice:"",
  }

            }
            else if(this.props.rrStageFourData?.data?.attributes?.policy_impact=="unchanged"){
                stageFourPrefill = {
           revision_number: this.props.rrStageFourData.data.attributes.revision_number,
          revision_date:this.props.rrStageFourData.data.attributes.revision_date,
          policy_impact:this.props.rrStageFourData.data.attributes.policy_impact,
          revision_confirmation:this.props.rrStageFourData.data.attributes.revision_confirmation,
          comment:this.props.rrStageFourData.data.attributes.comment,
          credit_note_number:"",
         credit_note_date:"",
         credit_note_amount:"",
         revision_credit_note:"",
         invoice_number:"",
            invoice_amount:"",
            new_policy_value:this.props.rrStageFourData.data.attributes.new_policy_value,
            revision_invoice:"",
        }
            }else{

                stageFourPrefill = {
                    revision_number: "",
          revision_date:"",
          policy_impact: "up",
          revision_confirmation:"",
          comment:"",
          credit_note_number:"",
         credit_note_date:"",
         credit_note_amount:"",
         revision_credit_note:"",
         invoice_number:"",
            invoice_amount:"",
            new_policy_value:"",
            revision_invoice:"",
                }
            }




            this.setState({initialData:stageFourPrefill})
                }




        }

    formDataFormatStageFour = (values: any, isDraft: boolean) => {
        const formData = new FormData();
        formData.append('data[submitting_revision][revision_number]',values.revision_number||"")
        formData.append('data[submitting_revision][revision_date]',values.revision_date||"")
        formData.append('data[submitting_revision][policy_impact]',values.policy_impact)
        formData.append('data[submitting_revision][comment]',values.comment||"")
        values.new_policy_value&&formData.append('data[submitting_revision][new_policy_value]',values.new_policy_value)
        values.revision_confirmation?.name && formData.append('data[revision_confirmation]', values.revision_confirmation)
        if(values.policy_impact=="up"){
            values.invoice_number&&formData.append('data[submitting_revision][revision_invoice][invoice_number]',values.invoice_number)
            values.invoice_date&&formData.append('data[submitting_revision][revision_invoice][invoice_date]',values.invoice_date)
            values.invoice_amount&& formData.append('data[submitting_revision][revision_invoice][invoice_amount]',values.invoice_amount)
            values.revision_invoice?.name && formData.append('data[revision_invoice]',values.revision_invoice)

    }
        else if(values.policy_impact=="down"){

            values.credit_note_number&& formData.append('data[submitting_revision][revision_credit_note][credit_note_number]',values.credit_note_number)
            values.credit_note_date&&formData.append('data[submitting_revision][revision_credit_note][credit_note_date]',values.credit_note_date)
            values.credit_note_amount&& formData.append('data[submitting_revision][revision_credit_note][credit_note_amount]',values.credit_note_amount)
            values.revision_credit_note?.name && formData.append('data[revision_credit_note]',values.revision_credit_note)

        }
    formData.append(`data[submitting_revision][save_as_draft]`, values.save_as_draft)


        return formData;
    }
    hasOnlyStageFourViewRights = () => {
        return this.state.hasStageFourPermissionList.includes(this.state.loggedInRole)
    }
    handleAccordianStageFour =()=>{
        if(!this.hasOnlyStageFourViewRights() ) {
            this.setAlert(`Operation review is not accessible to ${this.state.loggedInRole} role. Please login with other role`, "warning")
            return
        }


        this.setState((prev: any) => ({
            ...prev,
            expandedAccordion: !prev.expandedAccordion,

          }))
          this.getStageFourData()
    }
    stageFournextSubmit = async (data: any) => {
            const formData = this.formDataFormatStageFour(data, false);
            this.stageFourNextApi = this.apiCallForStageFour({
                contentType:'multipart/form-data',
                method: 'POST',
                endPoint: `bx_block_revision_request/revision_requests?id=${this.props.parentPolicyID}&stage=4`,
                body: formData,
                type : 'FormData',
            })
    }
    stageFourEditSubmit = (formData: any) => {
        const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
        const token = userData.token || '';
        const formDataValue = this.formDataFormatStageFour(formData, false);

        this.stageFourEditApi = this.apiCallForStageFour({
            contentType:'multipart/form-data',
            method: 'PUT',
            endPoint: `bx_block_revision_request/revision_requests/${this.props.parentPolicyID}?stage=4`,
            token: token || '',
            body: formDataValue,
            type : 'FormData',
        })
    }
    handleHandOverNoteTextStageFour = (inputText: string) => {
        this.setState({ handOverNoteText: inputText });
    };
    handleRejectReasonTextStageThree = (inputText: string) => {
        this.setState({ rejectReasonText: inputText });
    };
    closeNoteDialogStageFour = () => {
        this.setState({ showHandOverNote: false });
    };
    openNoteDialogStageFour = () => {
        this.setState({ showHandOverNote: true });
    };
    openRejectDialogStageFour = () => {
        this.setState({ showRejectDialog: true });
    };
    closeRejectDialogStageFour = () => {
        this.setState({ showRejectDialog: false, rejectReasonText: "", moveToStep: "", isBtnVisible: true });
    };
    closeNextDialogStageFour = () => {
        this.setState({ showNextDialog: false, handOverNoteText: "" })
    }
    moveToSelectedStepStageFour = (step: any) => {
        this.setState({ moveToStep: step });
    };
    trackRequestStageFour = (action: string) => {
        this.setState({ trackFor: action })
        const policyId = this.props.rrStageOneData.data.attributes.id
        const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
        const token = userData.token || '';
        this.trackRequestApiStageFour = this.callTrackingApi({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `bx_block_revision_request/revision_requests/${policyId}/track_revision_requests?stage=4`,
            token: token || '',
            body: {
				"data": {
					"track_revision_request": {
						"stage_status": (action === "next") ? "next_stage" : "reject_stage",
						"comment": (action === "next") ? this.state.handOverNoteText : this.state.rejectReasonText,
                        "current_stage": (action === "next") ? this.getTitle() : this.getPushBackToStep()
					}
				}
			}
        });
    }

    getTitle = () => {
        if(this.props.rrStageOneData?.data?.attributes?.revision_type.value === "correct") {return "completed"}
        else {return "customer_review"}
    }

    getPushBackToStep(){
        return (this.state.moveToStep && this.state.moveToStep.value) ? this.state.moveToStep.value.toLowerCase() : ""
    }
    callTrackingApi(data: any) {
        const { contentType, method, endPoint, body } = data;
        const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
        const token = userData.token || "";

        const header = {
          "Content-Type": contentType,
          token: token,
        };

        const requestMessageForRvRequestStageFourTrack = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageForRvRequestStageFourTrack.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessageForRvRequestStageFourTrack.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        requestMessageForRvRequestStageFourTrack.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        body &&
        requestMessageForRvRequestStageFourTrack.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          );

        runEngine.sendMessage(requestMessageForRvRequestStageFourTrack.id, requestMessageForRvRequestStageFourTrack);
        return requestMessageForRvRequestStageFourTrack.messageId;
      }

    apiCallForStageFour(data: any) {
        const {  method, endPoint, body } = data;
        const userLoginDataData = JSON.parse(localStorage.getItem("loginData") || "{}");
        const userToken = userLoginDataData.token || "";

        const header = {

            token: userToken,
        };

        const requestMessageForRvRequestStageFour = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageForRvRequestStageFour.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageForRvRequestStageFour.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessageForRvRequestStageFour.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessageForRvRequestStageFour.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              body
            )

        runEngine.sendMessage(requestMessageForRvRequestStageFour.id, requestMessageForRvRequestStageFour);
        return requestMessageForRvRequestStageFour.messageId;
    }
    setAlert = (responseJson: any, alertType: string = 'error') => {
        this.setState({ showAlert: true, alertMessage: responseJson, alertType: alertType})
    }
    handleNextBtnClick=()=>{
        this.setState({isNextBtnClicked:true})
    }
    getErrorMessage = (touched:any,errors:any,inputName:any) => {
        if(this.state.isNextBtnClicked){

        if (touched[inputName] || errors[inputName] ) {
            return errors[inputName]
        } else {
            return ""
        }
    }
    }
    showErrorMessage = (touched:any,errors:any,inputName:any) => {
        if (errors[inputName] ) {
            return errors[inputName]
        } else {
            return ""
        }
    }
    checkActiveLanguage = () => {
        return (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
    }

    checkCondition = () => {
        return this.state.expandedAccordion && this.props.rrStageFourData?.data?.attributes?.is_reject
    }
    removeFileStageFour = (fileId: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
         this.removeFileAPIStageFour = this.apiCallForStageFour({
             method: 'DELETE',
             endPoint: `bx_block_revision_request/revision_requests/${id}/remove_file`,
             contentType: 'application/json',
            body: JSON.stringify({
                "data": {
                    "file_id": [fileId]
                }
            })
        });
    }
    fileRemoveApiReceiveStageFour = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.removeFileAPIStageFour !== null &&
            this.removeFileAPIStageFour ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
            const responseJsonStageFour = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            /*istanbul ignore next*/
            if (responseJsonStageFour.data) {
                responseJsonStageFour.data.error_file && this.setState({
                    alertType: "error",
                    alertMessage: responseJsonStageFour.data.error_file,
                    showAlert: true,
                })
                responseJsonStageFour.data?.success && this.setState({
                    alertType: "success",
                    alertMessage: responseJsonStageFour.data?.success,
                    showAlert: true,
                })
               
            }
        }
    }

    stageFourNextAPIReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.stageFourNextApi != null &&
            this.stageFourNextApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson?.data) {
                const isArabicLanguage = this.checkActiveLanguage()

                if (responseJson.data.attributes.save_as_draft) {
                    const alertMessage = isArabicLanguage ? "تم حفظ بيانات طلب المراجعة كمسودة!" : "Revision request data has been saved as draft!"
                    this.setAlert(alertMessage, 'success')
                    return
                }
                this.setState({ isPostResponseCalledOnce: true, showNextDialog: true })
                this.props.setStageData('stageFour', responseJson)
                this.setState({ expandedAccordion: false })
            } else {
                this.showStageFourApiErrorMessage(responseJson);
            }
        }
    }
    stageFourEditAPIReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.stageFourEditApi != null &&
            this.stageFourEditApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJsonForEdit = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJsonForEdit?.data) {
                const isArabicLanguage = this.checkActiveLanguage()
                if(responseJsonForEdit.data.attributes.save_as_draft){
                    const alertMessage = isArabicLanguage ? "تم حفظ بيانات طلب المراجعة كمسودة!" : "Revision request data has been saved as draft!"
                    this.setAlert(alertMessage, 'success' )
                    return
                }
                this.setState({isPostResponseCalledOnce: true,showNextDialog:true})
                this.props.setStageData('stageFour', responseJsonForEdit)
                this.setState({expandedAccordion: false})
            } else {
                this.showStageFourApiErrorMessage(responseJsonForEdit);
            }
        }
    }




    // Customizable Area End
}
