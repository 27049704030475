import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { ErrorOutlineRounded, ExpandMore } from '@material-ui/icons'
import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import StageSixController, { Props } from "../../../blocks/TaskAllocator/src/PolicyStage/StageSixController";
import FileUpload from "../Common/FileUpload";
import LabelWithIcon from "../Common/LabelWithIcon";
import InsurerQuotationApprovalAccordian from "./InsurerQuotationApprovalAccordian"
import CustomButton from "../Common/CustomButton";
import Toster from "../Common/Toster";
import RejectRequestDialog from "../Common/RejectRequestDialog.web";
import HandOverDialog from "../Common/HandOverDialog.web";
import StageSixFormSchema from "./StageSixFormSchema";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ActiveLanguage from "../Common/ActiveLanguage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

const CustomToolTip = withStyles({
    arrow: {
      "&::before": {
        color: "white"
      }
    },
    tooltip: {
      backgroundColor: "#f5f5f9",
      boxShadow: theme.shadows[8],
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 14,
      maxWidth: 800
    },
    tooltipPlacementTop: {
      margin: "4px 0"
    }
  })(Tooltip);

const enumBtnType = {
    NEXT: 'NEXT',
    DRAFT: 'DRAFT',
}

export class StageSixForm extends StageSixController {
    formikRef: any;
    constructor(props: Props) {
        super(props);
        this.formikRef = React.createRef();
    }

    handleSubmitFromOutside = () => {
        const { current: formikInstance } = this.formikRef;
        if (formikInstance) {
            this.checkStageFourForm(formikInstance.errors)
            formikInstance.handleSubmit();
        }
    }

    render() {

        const { t, classes, isExpanded } = this.props;
        const { isEdit } = this.props;
        const stageSixData = JSON.parse(localStorage.getItem('stageSixData') || '{}');


        const pushBackOption = [
            { value: "info_gathering", label: t('info_gathering') },
            { value: "processing", label: t('processing') },
            { value: "awaiting", label: t('awaiting') },
            { value: "operations_review", label: t('operations_review') },
            { value: "sales_review", label: t('sales_review') }
        ];

        const stageFiveData = JSON.parse(
            localStorage.getItem("stageFiveData") ?? "{}"
        );

        return (
            <ThemeProvider theme={theme} >
                <Toster
                    tosterText={this.state.alertMessage}
                    showToster={this.state.showAlert}
                    tosterType={this.state.alertType}
                    handleCloseToster={() => this.setState({ showAlert: false })}
                />
                <Grid className={classes.accordionContainer} item>
                    <Accordion
                        expanded={this.state.isExpandedAccordion}
                        className={classes.formTwoAccordian}
                        onChange={() => {
                            if (this.state.show.includes(this.state.role) && this.state.edit) { this.handleAccordian() }
                            else if (this.state.isAddPage || this.state.isEditPage) { this.handleAccordian() }
                        }}
                    >
                        <AccordionSummary
                            aria-expanded={true}
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div className={classes.toggleContainer}>
                              <Typography className={classes.accordianHeading} >{t('quotation_approval')}</Typography>
                              <div className={classes.iconAlignment}>
                                {this.state.isExpandedAccordion == true && <DescriptionOutlinedIcon onClick={this.openNoteDialog} />}
                                {this.state.isExpandedAccordion == true && stageSixData?.attributes?.is_reject && 
                                 <ErrorOutlineRounded style={{position: 'relative', left: '10px'}} onClick={() => this.setState({showRejectDialog: true, isBtnVisible: false})} />
                                }
                              </div>
                            </div>
                        </AccordionSummary>
                        <Formik
                            innerRef={this.formikRef}
                            enableReinitialize
                            initialValues={{
                                stageSixDetails: this.state.stageSixDetail,
                                premiumCalculation: this.state.premiumCalculation,
                                comparisionSheet: this.state.comparisionSheet,
                            }}
                            validationSchema={Yup.object().shape(StageSixFormSchema(ActiveLanguage()))}
                            validateOnMount={true}
                            validateOnBlur={true}
                            onSubmit={async (values, actions) => {
                                const stageFiveData = JSON.parse(window.localStorage.getItem('stageFiveData') ?? '{}')
                                if (values.stageSixDetails.filter((filter: any) => filter.approval).length > 0) {
                                    if (stageFiveData?.attributes?.is_next_stage_submitted) {
                                        this.editFormData(values, false)
                                    }
                                    else {
                                        this.submitCustomerForm(values, false);
                                    }
                                } else {
                                    this.setState({
                                        showAlert: true,
                                        alertType: "error",
                                        alertMessage: ActiveLanguage() ? "يرجى الموافقة على واحدة" : "Please approve one"
                                    });
                                }
                            }}
                        >
                            {({
                                errors,
                                touched,
                                values,
                                setFieldValue
                            }) => (

                                <React.Fragment>
                                    {
                                        values.stageSixDetails.map((releaseData: any, index: number) => <InsurerQuotationApprovalAccordian
                                            customerReleaseData={releaseData}
                                            removeFile={this.removeFile}
                                            values={values}
                                            isArabicLanguage={this.state.isArabicLanguage}
                                            setFieldValue={setFieldValue}
                                            touched={touched}
                                            errors={errors}
                                            index={index}
                                            fifthStageData={this.state.stageFiveData}
                                            fouthStageData={this.state.stageFourData}
                                            uploadFileStageSix={this.uploadFileStageSix}
                                            policyId={this.state.policyId}
                                            edit={this.state.edit}
                                            isExpanded={isExpanded}
                                            t={t}
                                        />
                                        )
                                    }
                                    <AccordionDetails className={classes.additionalStyle}>
                                        <Grid container spacing={2} >
                                            {values.premiumCalculation && this.state.stageFiveData?.attributes?.release_premium_calculation && <Grid item xs={4}>
                                                <LabelWithIcon label={t('premium_calculaton')} tooltip={t('premium_calculaton')} />
                                                <FileUpload
                                                    fileName={this.state.stageFourData?.attributes?.premium_calculation?.file_name}
                                                    filePath={values.premiumCalculation || ""}
                                                    data-test-id="txtInputpremiumCalculation"
                                                    disabled={true}
                                                    inputName={`premiumCalculation`}
                                                    onRemove={() => setFieldValue(`premiumCalculation`, {})}
                                                />
                                                
                                                <ErrorMessage
                                                    name={`premiumCalculation`}
                                                    className="field-error"
                                                    render={(msg: string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                />
                                            </Grid>}
                                            {values.comparisionSheet && this.state.stageFiveData?.attributes?.comparison_sheet && <Grid item xs={4}>
                                                <LabelWithIcon label={t('comparision_sheet')} tooltip={t('comparision_sheet')} />
                                                <FileUpload
                                                    fileName={this.state.stageFourData?.attributes?.comparison_sheet?.file_name}
                                                    filePath={values.comparisionSheet || ""}
                                                    data-test-id="txtInputcomparisionSheet"
                                                    disabled={true}
                                                    inputName={`comparisionSheet`}
                                                    onRemove={() => setFieldValue(`comparisionSheet`, {})}
                                                />
                                                
                                                <ErrorMessage
                                                    name={`comparisionSheet`}
                                                    className="field-error"
                                                    render={(msg: string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                />
                                            </Grid>}
                                        </Grid>
                                    </AccordionDetails>
                                    <HandOverDialog
                                        btnCancelText={t('cancel')}
                                        btnOkText={t('confirm')}
                                        openDialog={this.state.showNoteDialog as boolean}
                                        textId={'handOverNoteText'}
                                        headingText={t('handover_note')}
                                        subHeading={this.state.isBtnVisible?`(${t('customere_review')} > ${t('payment_review')})`:`(${t('sales_review')} > ${t('customere_review')})`}
                                        handleInputText={this.handleHandOverNoteText}
                                        inputLable={this.state.showNoteDialog ? t('note') : ""}
                                        inputValue={this.state.isBtnVisible ? this.state.handOverNoteText : stageFiveData.attributes?.handover_note_customer_review}
                                        handleOk={(value: string) => this.handleOkDialog(value)}
                                        handleCancel={this.closeNoteDialog}
                                        showDialogBtns={this.state.isBtnVisible}
                                        showCrossBtn={true}
                                        inputDisable={!this.state.isBtnVisible}
                                    />
                                </React.Fragment>
                            )}
                        </Formik>
                    </Accordion>
                    {(!this.state.edit && isExpanded) && (<div className={classes.BtnGroup} >
                        <div>
                        </div>
                        <div className={classes.buttonsRightWrapper}>
                            <CustomButton
                                btnText={t('save_draft')}
                                onClick={() => this.handleDraftSubmit(enumBtnType.DRAFT, this.formikRef)}
                                bgColor='white'
                                textColor='#0090a1'
                            />
                            <CustomButton
                                btnText={t('reject')}
                                onClick={() => { this.openRejectDialog() }}
                                bgColor='white'
                                textColor='#0090a1'
                            />
                            <CustomButton
                                btnText={t('next_stage')}
                                onClick={() => {
                                    this.handleSubmitFromOutside()
                                }}
                                bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                textColor='#fff'
                            />
                        </div>
                    </div>)}
                </Grid>
                <React.Fragment>
                    <RejectRequestDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showRejectDialog as boolean}
                        textId={'rejectReason'}
                        pushBackOptions={pushBackOption}
                        inputValue={this.state.isBtnVisible ? this.state.rejectReasonText : stageSixData?.attributes?.rejected_reason}
                        setSelectedPushBackTo={(e: any) => this.moveToStep(e)}
                        headingText={t('rejecting')}
                        handleInputText={this.handleRejectReasonText}
                        handleOk={this.successCloseRejectDialog}
                        handleCancel={this.closeRejectDialog}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                        labelText={t('push_back')}
                        reasonLabel={t('reason')}
                    />
                </React.Fragment>
            </ThemeProvider>
        )
    }
}

const useStyles = ({
    accordianHeading: {
        fontWeight: 600,
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    accordionContainer: { paddingTop: "10px", paddingBottom: "10px" },
    toggleContainer: {
        width: '100%',
            display: 'flex',
                justifyContent: 'space-between',
                    '& svg': {
            color: '#0090a1'
        }
    },
    iconAlignment: {
        display: 'flex',
            marginRight: "10px"
    },
    lostIcon: {
        marginLeft: "15px",
            '& svg': {
            color: '#0090a1'
        },
    },
    radioBtnContainer: {
        display: "flex",
        "flex-direction": "row"
    },
    btncontainer: {
        display: "flex",
        "flex-direction": "row"
    },
    buttonsRightWrapper: {
        display: 'flex',
        gap: '15px',
        padding: '15px 0px',
        justifyContent: 'flex-end'
    }, formTwoAccordian: {
        "border-radius": "10px !important",
    },
    BtnGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 15px'
    },
});

export default withStyles(useStyles)(withTranslation()(StageSixForm));