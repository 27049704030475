import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid, RadioGroup, ThemeProvider, Typography, createTheme, withStyles, makeStyles, FormControlLabel, Radio } from "@material-ui/core";
import { ErrorOutlineRounded, ExpandMore, Lock } from "@material-ui/icons";
import { Formik } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";
import * as Yup from 'yup';
import HandOverDialog from "../HandOverDialog.web";
import StageOneController, { Props } from "../../../../blocks/MultipageForms2/src/RevisionRequest/RevisionStages/StageOne/StageOneController";
import CustomButton from "../CustomButton";
import FileUpload from "../FileUpload";
import LabelWithIcon from "../LabelWithIcon";
import TextInput from "../TextInput";
import SearchBar from "../SearchBar/SearchBar";
import Toggle from "../Toggle/Toggle";
import PaginatedTable from "../Table/PaginatedTable";
import { EMPLOYEE_LIST_TABLE_BODY, VEHICLE_LIST_TABLE_BODY } from "../Table/TableColumns";
import RejectRequestDialog from "../RejectRequestDialog.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
});

const useStylesFunction = makeStyles({
    accordianHeading: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
    },
    lockedAccordion: {
        fontSize: "12px",
        height: "25px",
        padding: "5px",
        border: '1px solid #ccc',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 0 0 20px',
        backgroundColor: "lightgray",
        color: "darkgray",
        '& svg': {
            marginRight: '6px',
        }
    },
})

const AccordionTitle = (props: any) => {
    const classes = useStylesFunction()
    return (
        <Typography className={classes.accordianHeading}>
            {props.title}

            {props.isLock && (props.policyId === "") && (
                <div className={classes.lockedAccordion}>
                    <Lock fontSize="small" /> Please Select Revision Type to Unlock  this Fields
                </div>
            )}
        </Typography>
    )
}

export class StageOnePartTwoForm extends StageOneController {
    formikRef: any;
    constructor(props: Props) {
        super(props);
        this.formikRef = React.createRef();
    }

    render() {
        const { t, classes, innerState, rrStageOneData }: Props = this.props;

        const insuranceTypeArr = () => {
            if (["Medical", "Life", "Motor", "Vehicle"].includes(innerState.stageData.insuranceType)) return true;
            else return false;
        }
        
        const getStageTwoInitialValue: any = () => {
            let stageValue: any = {
                comments: innerState.comments || "",
                attachment: innerState.attachment || "",
                paymentReciepent:"",
            }

            if (innerState.stageData.revisionType === "upgrade" && innerState.stageData.insuranceType === "Medical") {
                stageValue.paymentReciepent =innerState.paymentReciepent|| "";
            }

            return stageValue;
        }

        return (
            <ThemeProvider theme={theme} >
                <React.Fragment>
                    {(innerState.p1Submited || innerState.isEdit) ? (
                        <div className={classes.customerDetailsAccordion}>
                            <Formik
                                data-test-id="partTwoId"
                                innerRef={this.formikRef}
                                enableReinitialize={true}
                                initialValues={getStageTwoInitialValue()}
                                validationSchema={Yup.object().shape(this.props.partTwoSchema())}
                                validateOnMount={true}
                                validateOnBlur={true}
                                onSubmit={(values, actions) => {
                                    if (innerState.isPartTwoDataCreated) this.props.partTwoApiCall(1, "next", values, this.props.revisionRequestID, true)
                                    else this.props.partTwoApiCall(1, "next", values, this.props.revisionRequestID)
                                }}
                            >
                                {({
                                    handleSubmit,
                                    errors,
                                    touched,
                                    values,
                                    setFieldValue,
                                    validateForm
                                }) => (
                                    <React.Fragment>
                                        <Accordion disabled={!innerState.revisionInput} data-test-id='p2Form' className={((innerState.errorSection === 'p2') && classes.errorSection) ? classes.errorSection : classes.accordionStyle} expanded={this.isPartTwoExpanded(this.props.isExpanded, innerState.p1Submited, innerState.p2Submitted)} onChange={(event: any, isExpanded: boolean) => {
                                            this.handleAccordian(isExpanded, "p2", innerState.p1Submited,  innerState.isEdit)
                                        }} >
                                            <AccordionSummary
                                                aria-expanded={true}
                                                expandIcon={<ExpandMore />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <div style={{justifyContent: 'space-between', width: '100%', display: 'flex'}}>
                                                <AccordionTitle title={t('revision_input')} policyId={innerState.revisionRequestId} isLock={!innerState.p1Submitted} />
                                                {this.state.expandedAccordion === 'p2' && rrStageOneData?.data?.attributes?.is_reject &&
                                                 <ErrorOutlineRounded style={{position: 'relative', left: '10px', color: '#0090a1'}} onClick={() => this.setState({showRejectDialog: true})} />
                                                }
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className={classes.accordionDetails}>
                                                    {((innerState.stageData.revisionType === "remove" || innerState.stageData.revisionType === "correct") && (insuranceTypeArr())) && <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                                                        <SearchBar
                                                            data-test-id="rrOnesearchbar"
                                                        />
                                                        <div style={{ display: "flex", position: "relative", top: "6px" }} > <Typography style={{ position: "relative", top: "6px" }}>
                                                            {(innerState.stageData.revisionType === "remove" && (insuranceTypeArr())) ? t('to_be_deleted') : t('edited_rows')}
                                                        </Typography> <Toggle isChecked={innerState.isChecked} handleToggle={this.props.handleToggle} /></div>
                                                    </div>}
                                                    {(innerState.stageData.revisionType !== "upgrade" && (insuranceTypeArr())) &&
                                                        <div className={classes.csvTable}>
                                                            {innerState.stageData.revisionType !== "cancel" && <Typography className={classes.noteMessage} >{t('revision_input_remove_message_note')}</Typography>}
                                                            <PaginatedTable
                                                                key={''}
                                                                curretPage={innerState.pageCount ?? 0}
                                                                totalPage={innerState.totalPage ?? 0}
                                                                pageChange={this.handlePage}
                                                                handleRowPerPage={this.handleRow}
                                                                handleCheckBox={this.props.handleCheckbox}
                                                                openEditDialog={this.props.openEditDialog}
                                                                deletedData={innerState.deletedCsvData}
                                                                tableFor={innerState.stageData.revisionType === "remove" ? 'csvRemove' : (innerState.stageData.revisionType === "correct" ? 'csvCorrect' : 'csv')}
                                                                tableData={(innerState.isChecked && innerState.stageData.revisionType === "remove") ? innerState.deletedCsvData : ((innerState.isChecked && innerState.stageData.revisionType === "correct") ? innerState?.editedCsvData : innerState?.csvData)}
                                                                tableColumn={innerState.csvData[0]?.type == "vehicle_list" ? VEHICLE_LIST_TABLE_BODY : EMPLOYEE_LIST_TABLE_BODY}
                                                                innerState={innerState}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        (innerState.stageData.revisionType === "upgrade" && innerState.stageData.insuranceType === "Medical") && <div  style={{ marginBottom: 20 }}>
                                                            <LabelWithIcon label={t('payment_recipient')} />
                                                            <FormControl style={{ marginLeft: 15, marginTop: 10}}>
                                                                <RadioGroup
                                                                    row
                                                                    name="paymentReciepent"
                                                                    value={values.paymentReciepent}
                                                                    onChange={(e: any) => {
                                                                        setFieldValue('paymentReciepent', e.target.value)
                                                                    }}
                                                                >
                                                                    <FormControlLabel
                                                                        value={"compulsary"} control={<Radio color="primary" />} label="Compulsary" />
                                                                    <FormControlLabel
                                                                        value={"compulsary+others"} control={<Radio color="primary" />} label="Compulsary + Others" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                            {touched.paymentReciepent && errors.paymentReciepent ? (
                                                                <Typography color="error" >{errors.paymentReciepent}</Typography>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    <Grid container spacing={2} >
                                                        <Grid item xs={(innerState.stageData.revisionType !== "add" && (insuranceTypeArr())) ? 12 : 10}>
                                                            <LabelWithIcon label={t('clients_comment')} />
                                                            <TextInput
                                                                id={"comments"}
                                                                className={classes.textInputStyle}
                                                                name="comments"
                                                                fullWidth
                                                                type="text"
                                                                multiline={true}
                                                                minRows={4}
                                                                data-test-id="comments"
                                                                disabled={!this.props.isExpanded || rrStageOneData?.data?.attributes?.lost_reason}
                                                            />
                                                            {touched.comments && errors.comments ? (
                                                                <Typography color="error" >{errors.comments}</Typography>
                                                            ) : null}
                                                        </Grid>
                                                        {(innerState.stageData.revisionType === "add" && (insuranceTypeArr())) && <Grid item xs={2} className={classes.saveBtnDiv} >
                                                            <Button onClick={() => {this.props.handleCsvImport(this.props.rrStageOneData.data.attributes.id,innerState.csvData[0]?.type == "vehicle_list" ? "vehi" : "emp")}} className={classes.csvBtn} disabled={!this.props.isExpanded || rrStageOneData?.data?.attributes?.lost_reason}>{t('import_csv')}</Button>
                                                        </Grid>}
                                                        <Grid item xs={12}>
                                                            <LabelWithIcon label={t('upload_attachment')} />
                                                            <FileUpload
                                                                eachFileSizeInMB={10}
                                                                maxFilesNumber={5}
                                                                fileName={values.attachment?.file_name || ""}
                                                                filePath={values.attachment?.url || ""}
                                                                data-test-id="attachment"
                                                                inputName={`attachment`}
                                                                onChange={(event: any) => {
                                                                    setFieldValue('attachment', event.target.files[0])
                                                                }}
                                                                onRemove={async() => {
                                                                    setFieldValue('attachment', {})
                                                                    values?.attachment?.id&& this.removeFilestageOne(values?.attachment?.id)
                                                                 }}
                                                                disabled={(this.props.isExpanded && innerState.p2Submited) || !this.props.isExpanded || rrStageOneData?.data?.attributes?.lost_reason}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        {(this.props.isExpanded && !rrStageOneData?.data?.attributes?.lost_reason) ? (
                                            <div className={classes.BtnGroup}>
                                                <div>
                                                    <CustomButton
                                                        btnText={t('mark_as_lost')}
                                                        onClick={() => { this.props.showMarkAsLostDialog() }}
                                                        textColor="#2d6f8f"
                                                        bgColor="white"
                                                        data-test-id="markAsLostBtn"
                                                    />
                                                </div>
                                                <div className={classes.buttonsRightWrapper}>

                                                    <CustomButton
                                                        btnText={t('save_draft')}
                                                        onClick={() => {
                                                            if (innerState.isPartTwoDataCreated) this.props.savePolicyAsDraft(this.formikRef.current.values, innerState.revisionRequestId, true)
                                                            else this.props.savePolicyAsDraft(this.formikRef.current.values, innerState.revisionRequestId, false)
                                                        }}
                                                        textColor="#2d6f8f"
                                                        bgColor="white"
                                                        loading={innerState.saveDraftLoading}
                                                        data-test-id="saveDraftBtn"
                                                    />

                                                    <CustomButton
                                                        btnText={t('next_stage')}
                                                        onClick={() => { handleSubmit() }}
                                                        textColor="white"
                                                        loading={innerState.loading}
                                                        bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                                        data-test-id="nextStageBtn"
                                                    />
                                                </div>
                                                <HandOverDialog
                                                    btnCancelText={t('cancel')}
                                                    btnOkText={t('confirm')}
                                                    openDialog={innerState.showHandOverNote || false}
                                                    textId={'handOverNoteText'}
                                                    headingText={innerState.markAsLostDialog ? t('mark_as_lost') : t('handover_note')}
                                                    subHeading={innerState.markAsLostDialog ? "" : `(${t('info_gathering')} > ${t("processing")})`}
                                                    handleInputText={this.props.handleInputText}
                                                    inputValue={!innerState.markAsLostDialog ? innerState.handOverNoteText : (innerState.isBtnVisible ? innerState.handOverNoteText : rrStageOneData?.data?.attributes?.lost_reason)}
                                                    inputLable={innerState.showHandOverNote ? t("note") : ""}
                                                    handleOk={(value: string) => innerState.markAsLostDialog ? this.props.markPolicyAsLost(value) : this.props.handOverNoteSubmit(value)}
                                                    handleCancel={() => this.props.handleHandOverNoteDialog(false, false)}
                                                    showDialogBtns={innerState.isBtnVisible}
                                                    showCrossBtn={true}
                                                />
                                                <RejectRequestDialog
									                btnCancelText={t('cancel')}
									                btnOkText={t('confirm')}
									                openDialog={this.state.showRejectDialog}
									                textId={'rvPolicyRejectReason'}
									                inputValue={rrStageOneData?.data?.attributes?.rejected_reason}
									                headingText={t('rejecting')}
									                handleCancel={()=> this.setState({showRejectDialog: false})}
									                showDialogBtns={false}
									                showCrossBtn={true}
                                                    reasonLabel={t('reason')}
								                />
                                            </div>
                                        ) : null}
                                    </React.Fragment>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <Accordion disabled={!innerState.revisionInput} data-test-id='p2' className={((innerState.errorSection === 'p2') && classes.errorSection) ? classes.errorSection : classes.accordionStyle} expanded={this.isPartTwoExpanded(this.props.isExpanded, innerState.p1Submited, innerState.p2Submitted)} onChange={(event: any, isExpanded: boolean) => {
                            if (innerState.p1Submitted) {
                                this.handleAccordian(isExpanded, "p2", innerState.p1Submited,  innerState.isEdit)
                            }
                        }} >
                            <AccordionSummary
                                aria-expanded={true}
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <AccordionTitle title={t('revision_input')} policyId={innerState.revisionRequestId} isLock={!innerState.p1Submitted} />
                            </AccordionSummary>
                            <AccordionDetails>
                                {this.props.policyID && "Please fill all details in overview form first"}
                            </AccordionDetails>
                        </Accordion>
                    )}
                </React.Fragment>
            </ThemeProvider >
        )
    }
}

const useStyles = ({
    customerDetailsAccordion: {
        paddingTop: "10px",
        paddingBottom: "10px",
        "& .MuiAccordion-root-34.Mui-disabled, .MuiAccordion-root-42.Mui-disabled": {
            backgroundColor: "white",
        },
        " & .MuiAccordion-root.Mui-disabled": {
            backgroundColor: "white",
        }
    },
    accordionStyle: {
        background: "white !important",
        "border-radius": "10px !important"
    },
    accordianHeading: {
        fontWeight: 600
    },
    BtnGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 15px'
    },
    buttonsRightWrapper: {
        display: 'flex',
        gap: '15px',
        padding: '15px 0px',
        justifyContent: 'flex-end'
    },
    saveBtnDiv: {
        marginTop: "28px"
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    noteMessage: {
        padding: "0px 8px",
        fontSize: "14px",
        marginBottom: "10px"
    },
    accordionDetails: {
        display: "block",
        width: "100%"
    },
    errorSection: {
        border: "1px solid red",
        "border-radius": "10px !important"
    },
    csvBtn: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "height": "55px",
        "width": "150px",
        borderRadius: "10px",
        top: "60px"
    },
    csvTable: {
        marginBottom: "20px"
    },
});

export default withStyles(useStyles)(withTranslation()(StageOnePartTwoForm));