// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import getStageNameByNumber, { getStageNumberByName } from "../../../components/src/Common/PolicyRequest/StagesName";


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    t?:any;
    data?:any;
}

interface S {
    customerName: string;
    policyId: string;
    isArabicLenguage: boolean;
    isEdit:boolean;
    currentSteps:number;
    stageOne:any;
    showRejectDialog:boolean;
    rejectReason:string;
    rejectedStage: any;
    showAlert:boolean;
    alertMessage: string;
    alertType: string;
    stageTwoData?:any;
    stageOnePartTwoData?:any;
    stageSixData?:any;
    stageEightData?:any;
    stageThreeData?:any;
    stageFourData?:any;
    stageFiveData?:any;
    stageSevenData?:any;
    stageNineData?: any;
    isStageTwoCreated: boolean,
    isStageThreeCreated: boolean,
    isStageEightCreated: boolean,
    isStageNineCreated: boolean,
    isStageSevenCreated: boolean,
    isStageSixCreated: boolean,
    isStageFiveCreated: boolean,
    isStageFourCreated: boolean,
    isLoading: boolean,
    isNextStageTen: boolean,
    isNextStageTwo: boolean,
    isNextStageThree: boolean,
    isNextStageFour: boolean,
    isNextStageFive: boolean,
    isNextStageSix: boolean,
    isNextStageSeven: boolean,
    isNextStageEight: boolean,
    isNextStageNine: boolean,
}

interface SS {
    id: any;
}

export default class PolicyInfoController extends BlockComponent<
    Props,
    S,
    SS
> {
    getPolicyDetailsAPI: any = "";
    stageOneNextApi:any = "";
    stageOneRejectApi:any = "";
    stageOneDraftApi:any = "";
    stageOneMastAsLostApi:any = "";
    stageFourNextApi:any = "";
    stageFourRejectApi:any = "";
    stageFourDraftApi:any = "";
    getStageTwoDetailsAPI:any = "";
    getStageThreeDetailsApi:any = "";
    getStageSixDetaisApi:any = "";
    getStageFourDetailsApi:any = "";
    getStageFiveDetailsApi:any = "";
    getStageEightDetaisApi:any = "";
    getStageSevenDetaisApi:any = "";
    getStageNineDetaisApi: any = "";


    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        const queryParameter: any = new URLSearchParams(window.location.search);
        const policyId: string = queryParameter?.get('id');
        const isEdit:boolean = window.location.href.includes('EditPolicy');


        this.state = {
            customerName: "",
            policyId: policyId || "",
            isArabicLenguage: (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
            isEdit:isEdit,
            currentSteps:1,
            stageOne:{},
            showRejectDialog:false,
            rejectReason:"",
            rejectedStage:"",
            showAlert:false,
            alertMessage:"",
            alertType:"",
            stageTwoData:{},
            stageOnePartTwoData:{},
            stageSixData:{},
            stageEightData:{},
            stageThreeData:{},
            stageFourData:{},
            stageFiveData:{},
            stageSevenData:{},
            stageNineData: {},
            isStageSevenCreated: false,
            isStageSixCreated: false,
            isStageFiveCreated: false,
            isStageFourCreated: false,
            isLoading: false,
            isStageTwoCreated: false,
            isStageThreeCreated: false,
            isStageEightCreated: false,
            isStageNineCreated: false,
            isNextStageTen: false,
            isNextStageTwo: false,
            isNextStageThree: false,
            isNextStageFour: false,
            isNextStageFive: false,
            isNextStageSix: false,
            isNextStageSeven: false,
            isNextStageEight: false,
            isNextStageNine: false,
        };
        
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    getStepName = getStageNameByNumber

    getStepNumber = getStageNumberByName

    setArabicLanguage = (value: boolean) => {
        const language = value === true ? 'ar' : 'en';
        window.localStorage.setItem('lang', language);
        this.setState({
            isArabicLenguage: value
        })
    }

    async componentDidMount() {
        super.componentDidMount();

        window.localStorage.removeItem('stageOne');
        window.localStorage.removeItem('stageOnePartTwoData');
        window.localStorage.removeItem('StageOneLostReason');
        window.localStorage.removeItem('stageTwoData');
        window.localStorage.removeItem('stageThreeData');
        window.localStorage.removeItem('stageFourData');
        window.localStorage.removeItem('stageFiveData');
        window.localStorage.removeItem('stageSixData');
        window.localStorage.removeItem('stageSevenData');
        window.localStorage.removeItem('stageEightData');
        window.localStorage.removeItem('stageNineData');

        const queryParameter: any = new URLSearchParams(window.location.search);
        const policyId: string = await queryParameter?.get('id');

        if (policyId) {
            window.localStorage.removeItem('selectedCustomer')
            this.setState({policyId:policyId})
            await this.getPolicyDetails(policyId);
        }else{
        const customerNameValue = await JSON.parse(localStorage.getItem('selectedCustomer') || '{}');
        this.setState({customerName:customerNameValue.name})
        }
    }

    getPolicyDetails = async (policyId:any) => {
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';

        this.getPolicyDetailsAPI = await this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=1`,
            token: token || ''
        })
    }

    onNext = (currentStage:number) => {
        const nextStep = currentStage + 1;
        if(nextStep === 7) {
            this.stageSixDetails();
        }
        /* istanbul ignore if*/
        if (nextStep === 8) {
            this.getStageSevenDetails();
            this.setStageEightContent();
        }
        if(nextStep === 9) {
            this.getStageEightDetails();
        }
        this.setState({currentSteps:nextStep})
    }
    /* istanbul ignore next*/
    onReject = (goBackTo:number) => {
        if(goBackTo == 1) {
            this.getPolicyDetails(this.state.policyId);
        }
        if(goBackTo == 2) {
            this.getStageTwoDetails();
        }
        if(goBackTo == 3) {
            this.getStageThreeDetails();
        }
        if(goBackTo == 4) {
            this.getStageThreeDetails();
            this.getStageFourDetails();
        }
        if(goBackTo == 5) {
            this.getStageFiveDetails();
        }
        if(goBackTo == 6) {
            this.getStageFiveDetails();
            this.stageSixDetails();
        }
        if(goBackTo == 7) {
            this.stageSixDetails();
            this.getStageSevenDetails()
            const isInstallmentPaid = window.localStorage.getItem('InstallmentPaid');
            if(isInstallmentPaid == 'true') {
                window.localStorage.removeItem('stageEightData');
                window.localStorage.removeItem('stageNineData');
                this.setState({stageNineData: {}, stageEightData:{}})
            }
        }
        if(goBackTo == 8) {
            this.getStageEightDetails();
        }
        this.setState({currentSteps:goBackTo})
    }

    getErrorMessageForApi = (responseJson:any) => {
        if (responseJson?.errors) {
            if(responseJson?.errors[0]?.token){
                this.setState({
                    alertMessage:responseJson.errors[0].token,
                    alertType:"error",
                    showAlert:true
                })
                window.localStorage.removeItem('loginData');
                window.location.href = "/EmailAccountLoginBlock"
            } else
            if (typeof(responseJson.errors[0]) === "string") {
               this.setState({
                    alertMessage:responseJson.errors[0],
                    alertType:"error",
                    showAlert:true
               });
            }
        }
    }

    getStageOneDataFromResponse = (apiResponse:any,responseJson:any) => {
        let stageOneData:any = {
            requestId: apiResponse.request_id,
            requestType: apiResponse.request_type,
            customer:apiResponse.customer_name,
            customerId:apiResponse.customer_id,
            contactName:{value:String(apiResponse.customer_contact_id),label:apiResponse.customer_contact_name, allData:{id:apiResponse.customer_contact_id}},
            contectPosition:apiResponse.customer_contact_position,
            contactPhoneNumber:apiResponse.customer_contact_contact_no,
            contactEmail:apiResponse.customer_contact_email,
            contactStatus:apiResponse.customer_contact_account_status === "invite_sent" ? "Invite sent" : apiResponse.customer_contact_account_status,
            sales: {value:String(apiResponse.sales_id.id),label:apiResponse.sales_id.name, allData:{id:apiResponse.sales_id.id}},
            operation: {value:String(apiResponse.operation_id.id),label:apiResponse.operation_id.name, allData:{id:apiResponse.operation_id.id}},
            finance: {value:String(apiResponse.finance_id.id),label:apiResponse.finance_id.name, allData:{id:apiResponse.finance_id.id}},
            insuranceType:apiResponse.types_of_insurance.value,
            p1Submited: apiResponse.rfq_input.data === null ? false :true,
            editFlowp1: ((apiResponse.rfq_input.data === null ) && (apiResponse.request_id !== "")) ? true : false,
            editFlowp2: (apiResponse.is_rfq_input_submitted === true) ? true : false,
            isNextStageSubmitted:apiResponse.is_next_stage_submitted || false,
            isRfqSubmitted:apiResponse.is_rfq_input_submitted,
            partOneResponse: responseJson
        }
        return stageOneData
    }
    setStageTwoDataFromResponse = (apiResponse:any) => {
        if ((apiResponse.is_rfq_input_submitted) && (apiResponse.rfq_input !== null)) {
            const rfqInputData = apiResponse.rfq_input.data.attributes
            
            let stageTwo:any ={
                "industry": rfqInputData.industry,
                "no_of_employees":rfqInputData.no_of_employees,
                "no_of_vehicles":rfqInputData.no_of_vehicles,
                "existingInsurance": rfqInputData.existing_insurance === true ? "yes" : "no",
                "nameOfExistingInsurer": rfqInputData.name_existing_insurance,
                "policyExpireDate": rfqInputData.policy_expiry_date,
                "tradeLicenseNumber": rfqInputData.trade_license_number,
                "tradeLicenseFile": rfqInputData.trade_license_cr_crs,
                "brokerOfRecord": rfqInputData.broker_of_record,
                "LrClaimDoc": rfqInputData.lr_claim_experience_past_5_yrs,
                "nationalAddress": rfqInputData.national_address,
                "proposalForm": rfqInputData.proposal_form,
                "clientComment": rfqInputData.client_comment,
                "additionalDoc": [...rfqInputData.rfq_input_additional_attachments],
                "insuranceType": apiResponse.types_of_insurance.value,
                "insuranceCompany": rfqInputData.insurance_company_id
            }
            switch (apiResponse.types_of_insurance.value) {
                case "medical":
                    const medical = {
                        numberOfEmployeeOfInsurer: rfqInputData.no_of_employees_to_medical_insure,
                        prefferedHospital: rfqInputData.preferred_hospital,
                        employeeListDoc: rfqInputData.employee_list_medical,
                        medicalSegment: rfqInputData.medical_segment
                    }
                    stageTwo.medical = medical;
                    break;

                case "motor":
                    const motor = {
                        numberOfVehiclesToInsure: rfqInputData.no_of_vehicles_to_insure,
                        policyType: rfqInputData.policy_type,
                        vehicalListDoc: rfqInputData.vehicle_list
                    }
                    stageTwo.motor = motor;
                    break;

                case "life":
                    const life = {
                        numberOfEmployeeOfInsurer: rfqInputData.no_of_employees_to_life_insure,
                        employeeListDoc: rfqInputData.employee_list_life
                    }
                    stageTwo.life = life;
                    break;

                case "engineering":
                    const engineering = {
                        numberOfEmployeeOfInsurer: rfqInputData.no_of_employees_to_engineering_insure,
                        employeeListDoc: rfqInputData.employee_list_engineering,
                        gpsCordinate: rfqInputData.engineering_gps_coordinates,
                        awardContractDoc: rfqInputData.awarded_contract_engineering,
                        billOfQualityDoc: rfqInputData.bill_of_quantity_engineering,
                        timeBarChatDoc: rfqInputData.time_bar_chart_engineering
                    }
                    stageTwo.engineering = engineering;
                    break;

                case "marine":
                    const marine = {
                        numberOfEmployeeOfInsurer: rfqInputData.no_of_employees_to_marine_insure,
                        employeeListDoc: rfqInputData.employee_list_marine
                    }
                    stageTwo.marine = marine;
                    break;

                case "property":
                    const property = {
                        numberOfEmployeeOfInsurer: rfqInputData.no_of_employees_to_property_insure,
                        employeeListDoc: rfqInputData.employee_list_property,
                        letterFormCivilDefenceDoc: rfqInputData.letter_from_civil_defence_property
                    }
                    stageTwo.property = property;
                    break;
                case "fire":
                    const fire = {
                        numberOfEmployeeOfInsurer: rfqInputData.no_of_employees_to_fire_insure,
                        employeeListDoc: rfqInputData.employee_list_fire,
                        gpsCordinate: rfqInputData.fire_gps_coordinates,
                        letterFormCivilDefenceDoc: rfqInputData.letter_from_civil_defence_fire,
                        muncipalCertificateDoc: rfqInputData.municipal_certificate_fire,
                        surveyReportDoc: rfqInputData.survey_report_fire,
                        fireAndSaftyContractDoc: rfqInputData.fire_safety_contract_fire,
                        samplePicture: rfqInputData.sample_pictures_fire
                    }
                    stageTwo.fire = fire;
                    break;
                case "aviation":
                    const aviation = {
                        insuranceFileDoc: rfqInputData.insurance_file_aviation,
                        gpsCordinate: rfqInputData.insurance_file_aviation,
                        letterFormCivilDefenceDoc: rfqInputData.letter_from_civil_defence_aviation,
                        muncipalCertificateDoc: rfqInputData.municipal_certificate_aviation,
                        surveyReportDoc: rfqInputData.survey_report_aviation,
                        fireAndSaftyContractDoc: rfqInputData.fire_safety_contract_aviation,
                        awardContractDoc: rfqInputData.awarded_contract_aviation,
                        billOfQualityDoc: rfqInputData.bill_of_quantity_aviation,
                        timeBarChatDoc: rfqInputData.time_bar_chart_aviation,
                        maintenanceHistoryRecordDoc: rfqInputData.maintenance_history_record_aviation,
                        samplePicture: rfqInputData.sample_pictures_aviation
                    }
                    stageTwo.aviation = aviation;
                    break;
                case "energy":
                    const energy = {
                        insuranceFileDoc: rfqInputData.insurance_file_energy,
                        gpsCordinate: rfqInputData.energy_gps_coordinates,
                        letterFormCivilDefenceDoc: rfqInputData.energy_gps_coordinates,
                        muncipalCertificateDoc: rfqInputData.municipal_certificate_energy,
                        surveyReportDoc: rfqInputData.survey_report_energy,
                        fireAndSaftyContractDoc: rfqInputData.fire_safety_contract_energy,
                        awardContractDoc: rfqInputData.awarded_contract_energy,
                        billOfQualityDoc: rfqInputData.bill_of_quantity_energy,
                        timeBarChatDoc: rfqInputData.time_bar_chart_energy,
                        maintenanceHistoryRecordDoc: rfqInputData.maintenance_history_record_energy,
                        samplePicture: rfqInputData.sample_pictures_energy
                    }
                    stageTwo.energy = energy;
                    break;
                case "protection & savings":
                    const protectionAndSaving = {
                        insuranceFileDoc: rfqInputData.insurance_file_protection_saving,
                        gpsCordinate: rfqInputData.ps_gps_coordinates,
                        letterFormCivilDefenceDoc: rfqInputData.letter_from_civil_defence_protection_saving,
                        muncipalCertificateDoc: rfqInputData.municipal_certificate_protection_saving,
                        surveyReportDoc: rfqInputData.survey_report_protection_saving,
                        fireAndSaftyContractDoc: rfqInputData.fire_safety_contract_protection_saving,
                        awardContractDoc: rfqInputData.awarded_contract_protection_saving,
                        billOfQualityDoc: rfqInputData.bill_of_quantity_protection_saving,
                        timeBarChatDoc: rfqInputData.time_bar_chart_protection_saving,
                        maintenanceHistoryRecordDoc: rfqInputData.maintenance_history_record_protection_saving,
                        samplePicture: rfqInputData.sample_pictures_protection_saving,
                    }
                    stageTwo.protectionAndSaving = protectionAndSaving;
                    break;

                case "general business":
                    const generalBusiness = {
                        insuranceFileDoc: rfqInputData.insurance_file_general_business,
                        gpsCordinate: rfqInputData.general_business_gps_coordinates,
                        letterFormCivilDefenceDoc: rfqInputData.letter_from_civil_defence_general_business,
                        muncipalCertificateDoc: rfqInputData.municipal_certificate_general_business,
                        surveyReportDoc: rfqInputData.survey_report_general_business,
                        fireAndSaftyContractDoc: rfqInputData.fire_safety_contract_general_business,
                        awardContractDoc: rfqInputData.awarded_contract_general_business,
                        billOfQualityDoc: rfqInputData.awarded_contract_general_business,
                        timeBarChatDoc: rfqInputData.time_bar_chart_general_business,
                        maintenanceHistoryRecordDoc: rfqInputData.maintenance_history_record_general_business,
                        samplePicture: rfqInputData.sample_pictures_general_business,
                    }
                    stageTwo.generalBusiness = generalBusiness;
                    break;

                default:
                    break;
            }
            window.localStorage.setItem('stageOnePartTwoData', JSON.stringify(stageTwo))
            this.setState({stageOnePartTwoData:stageTwo})
        }
    }
    getStageTwoDetails = async() => {
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';

        this.getStageTwoDetailsAPI = await this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=2`,
            token: token || ''
        });
    }
    getStageThreeDetails = async () => {
        
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';

        this.getStageThreeDetailsApi = await this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=3`,
            token: token || ''
        });
    }
    getStageFourDetails = async () => {
        
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';

        this.getStageFourDetailsApi = await this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=4`,
            token: token || ''
        });
    }
    getStageFiveDetails = async () => {
        
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';

        this.getStageFiveDetailsApi = await this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=5`,
            token: token || ''
        });
    }
    stageSixDetails = async () => {
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';
        const queryParameter: any = new URLSearchParams(window.location.search);
        const policyId: string = await queryParameter?.get('id');
        this.getStageSixDetaisApi = await this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=6`,
            token: token || ''
        });
    }
    getStageSevenDetails = async () => {
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';
        const queryParameter: any = new URLSearchParams(window.location.search);
        const policyId: string = await queryParameter?.get('id');        
        this.getStageSevenDetaisApi = await this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=7`,
            token: token || ''
        });
    }
    getStageEightDetails = async () => {
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';

        this.getStageEightDetaisApi = await this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=8`,
            token: token || ''
        });
    }

    getStageNineDetails = async () => {
        const userData = await JSON.parse(localStorage.getItem('loginData') ?? '{}');
        const token = await userData.token || '';

        this.getStageNineDetaisApi = await this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=9`,
            token: token || ''
        });
    }

    setStageData = (keyName:any,apiResponse:any) => {
        
        this.setState((prevState) => ({
            ...prevState,
            [keyName]: apiResponse,
        }));
    }
    setStageEightContent = () => {
        let stateEightStateData = {
            contactName: "",
            email: "",
            emailSubject: "Welcome to SIIB",
            emailDraft: "",
            markAsSubmitted: false,
            emailSent: "",
            insuranceType: this.state.stageOne.insuranceType || "",
            customerName: this.state.stageOne.customer || "",
            approvedCompanyId: "",
            approvedCompanyName:"",
            isEdit: false
        };

        // set approved insurance company from stage 6
        const InsuranceCompanysFromStageSix = this.state.stageSixData?.attributes?.quotation_insurance_infos?.data
        if (InsuranceCompanysFromStageSix.length > 0) {
            InsuranceCompanysFromStageSix.map((copmanyData: any) => {
                if (copmanyData.attributes.approval === true) {
                    stateEightStateData.approvedCompanyName =copmanyData.attributes.insurance_company_id.short_name;
                    stateEightStateData.approvedCompanyId = copmanyData.attributes.insurance_company_id.id
                }
            })
        }

        // set approved company email details from the stage 2
        const InsuranceComanyDataFromStageTwo = this.state.stageTwoData.attributes.processing_insurance_infos.data;

        if (InsuranceComanyDataFromStageTwo.length > 0 && stateEightStateData.approvedCompanyId) {
        
        InsuranceComanyDataFromStageTwo.map((data:any) => {
            
            if (String(data.attributes.insurance_company_id.id) === String(stateEightStateData.approvedCompanyId) ) {
                stateEightStateData = {
                    ...stateEightStateData,
                    email:data.attributes.email,
                    emailDraft:
`Dear Valued Client,

Greetings

We would like to take this opportunity and welcome you as one of our clients at SIIB.

For daily operations (Addition, Deletion, Amendment), all you requests should be sent to Care@siib.com.sa

Best regards`,
                    emailSent:data.attributes.email_sent,
                    contactName:data.attributes.insurance_contact_id,
                    markAsSubmitted: data.attributes.mark_as_submitted 
                }
            }
        });
        window.localStorage.setItem('stageEightData',JSON.stringify(stateEightStateData));
        this.setState({stageEightData:stateEightStateData})
        }
    }

    apiCall = async (data: any) => {
        this.setState({isLoading: true })
        const { contentType, method, endPoint, body, token } = data;
        const header = {
          "Content-Type": contentType,
          token
        };
        const apiRequestMessageForPolicyInfo = new Message(getName(MessageEnum.RestAPIRequestMessage));
        apiRequestMessageForPolicyInfo.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        apiRequestMessageForPolicyInfo.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        apiRequestMessageForPolicyInfo.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
        body && apiRequestMessageForPolicyInfo.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        runEngine.sendMessage(apiRequestMessageForPolicyInfo.id, apiRequestMessageForPolicyInfo);
        return apiRequestMessageForPolicyInfo.messageId;
      };
    /* istanbul ignore next*/
    getPolicyDetailsAPIReceive = (message:any) =>{
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getPolicyDetailsAPI != null &&
            this.getPolicyDetailsAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson?.data) {
                if (responseJson?.data?.attributes?.status) {  
                    const apiResponse = responseJson.data.attributes;
                    const activeStage = this.getStepNumber(responseJson.data.attributes.status);
                    
                    let stageOneData = this.getStageOneDataFromResponse(apiResponse,responseJson);
                    this.setStageTwoDataFromResponse(apiResponse)
                    window.localStorage.setItem('stageOne',JSON.stringify(stageOneData))
                    this.setState({currentSteps:activeStage as number, stageOne:stageOneData,customerName:stageOneData?.customer})
                    this.setState({isLoading: false })
                    if (activeStage >= 2 && responseJson.data.attributes.is_next_stage_submitted) {
                        this.setState({isNextStageTwo: true})
                        this.getStageTwoDetails();
                    }
                }
                
            } else {
                this.getErrorMessageForApi(responseJson)
            }
        }
    }
     /* istanbul ignore next*/
    getPolicyStageTwoDetailsAPIReceive = (message:any) =>{
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageTwoDetailsAPI != null &&
            this.getStageTwoDetailsAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson?.data) {
                if (responseJson.data) { 
                    window.localStorage.setItem('stageTwoData',JSON.stringify(responseJson.data))
                    this.setState({ stageTwoData:responseJson.data})
                    this.setState({isLoading: false , isStageTwoCreated: true})
                    if (this.state.currentSteps >= 3 && responseJson.data.attributes.is_next_stage_submitted) {
                        this.setState({isNextStageThree: true})
                        this.getStageThreeDetails();
                    }
                }
            } else {
                this.getErrorMessageForApi(responseJson)
                this.setState({isLoading: false})
            }
        }
    }
    getPolicySixDetailsAPIReceive = (message:any) =>{
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageSixDetaisApi != null &&
            this.getStageSixDetaisApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                
                window.localStorage.setItem('stageSixData',JSON.stringify(responseJson.data))
                this.setState({ stageSixData:responseJson.data, isLoading: false, isStageSixCreated: true })
                if (this.state.currentSteps >= 7 && /* istanbul ignore next*/responseJson?.data?.attributes?.is_next_stage_submitted) {
                    this.setState({isNextStageSeven: true})
                    this.getStageSevenDetails();
                }
                
            } else {
                this.getErrorMessageForApi(responseJson)
                this.setState({isLoading: false})
            }
        }
    }
    getPolicyStageThreeDetailsApiReceive = (message:any) =>{
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageThreeDetailsApi != null &&
            this.getStageThreeDetailsApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                window.localStorage.setItem('stageThreeData',JSON.stringify(responseJson.data))
                this.setState({ stageThreeData:responseJson.data, isStageThreeCreated: true})
                this.setState({isLoading: false })
                if (this.state.currentSteps >= 4 && responseJson.data.attributes.is_next_stage_submitted) {
                    this.setState({isNextStageFour: true})
                    this.getStageFourDetails();
                }
            } else {
                this.getErrorMessageForApi(responseJson)
                this.setState({isLoading: false})

            }
        }
    }
    getPolicyStageFourDetailsApiReceive = (message:any) =>{
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageFourDetailsApi != null &&
            this.getStageFourDetailsApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                window.localStorage.setItem('stageFourData',JSON.stringify(responseJson.data))
                this.setState({ stageFourData:responseJson.data, isStageFourCreated: true});
                this.setState({isLoading: false})
                if (this.state.currentSteps >= 5 && responseJson.data.attributes.is_next_stage_submitted) {
                    this.setState({isNextStageFive: true})
                    this.getStageFiveDetails();
                }
            } else {
                this.getErrorMessageForApi(responseJson)
                this.setState({isLoading: false})

            }
        }
    } 
    getPolicyStageFiveDetailsApiReceive = (message:any) =>{
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageFiveDetailsApi != null &&
            this.getStageFiveDetailsApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                window.localStorage.setItem('stageFiveData',JSON.stringify(responseJson.data))
                this.setState({ stageFiveData:responseJson.data, isStageFiveCreated: true})
                this.setState({isLoading: false})
                if (this.state.currentSteps >= 6 && responseJson.data.attributes.is_next_stage_submitted) {
                    this.setState({isNextStageSix: true})
                    this.stageSixDetails();
                }
            } else {
                this.getErrorMessageForApi(responseJson)
                this.setState({isLoading: false})

            }
        }
    }
    getPolicyEightDetailsAPIReceive = (message:any) =>{
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageEightDetaisApi != null &&
            this.getStageEightDetaisApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                const stageEightData = this.state.stageEightData;
                const mainData = {
                    ...stageEightData,
                    poliycRequestId:  responseJson.data.attributes?.issuance_insurance_infos?.data?.attributes?.policy_id || "",
                    policyActiveDate: responseJson.data.attributes?.issuance_insurance_infos?.data?.attributes?.policy_activation_date || "",
                    proofofPayement:  responseJson.data.attributes?.issuance_insurance_infos?.data?.attributes?.policy_details?.url || "",
                    hasViewPermissionList: responseJson.meta?.current_stage_view_permission_role || [],
                    additionalDocAttachments:responseJson.data.attributes?.issuance_insurance_infos?.data?.attributes?.issuance_upload_submitted_attachments || [],
                    isStageSubmited: responseJson.data.attributes?.is_next_stage_submitted,
                    policyExpiryData: responseJson.data.attributes?.issuance_insurance_infos?.data?.attributes?.new_policy_expiry_date,
                    submitApiResponse: responseJson.data
                }
                window.localStorage.setItem('stageEightData',JSON.stringify(mainData));
                this.setState({ stageEightData:mainData, isStageEightCreated: true});
                this.setState({isLoading: false })
                if (this.state.currentSteps >= 9 && responseJson.data.attributes?.is_next_stage_submitted) {
                    this.setState({isNextStageNine: true})
                    this.getStageNineDetails();
                }
            } else {
                this.getErrorMessageForApi(responseJson)
                this.setState({isLoading: false})

            }
        }
    }

    getPolicyNineDetailsAPIReceive = (message:any) =>{
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageNineDetaisApi != null &&
            this.getStageNineDetaisApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data && responseJson?.meta) {
                window.localStorage.setItem('stageNineData',JSON.stringify(responseJson?.data))
                this.setState({ stageNineData:responseJson?.data})
                this.setState({isStageNineCreated: true})
                this.setState({isLoading:  false })
                if (this.state.currentSteps >= 10 && responseJson.data.attributes.is_next_stage_submitted) {
                    this.setState({isNextStageTen: true})
                    this.getStageEightDetails();
                }
            } else {
                this.getErrorMessageForApi(responseJson)
                this.setState({isLoading: false})
            }
        }
    }

    /* istanbul ignore next*/
    getPolicySevenDetailsAPIReceive = (message:any) =>{
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageSevenDetaisApi != null &&
            this.getStageSevenDetaisApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data && responseJson?.meta) {
                window.localStorage.setItem('stageSevenData',JSON.stringify(responseJson?.data))
                this.setState({ stageSevenData:responseJson?.data})
                this.setState({isStageSevenCreated: true})
                this.setState({isLoading:  false })
                if (this.state.currentSteps >= 8 && responseJson.data.attributes?.is_next_stage_submitted) {
                    this.setState({isNextStageEight: true})
                    this.getStageEightDetails();
                }
            } else {
                this.getErrorMessageForApi(responseJson)
                this.setState({isLoading: false})

            }
        }
    }
    async receive(from: string, message: Message) {
        this.getPolicyDetailsAPIReceive(message);
        this.getPolicyStageTwoDetailsAPIReceive(message);
        this.getPolicyStageThreeDetailsApiReceive(message);
        this.getPolicyStageFourDetailsApiReceive(message);
        this.getPolicyStageFiveDetailsApiReceive(message);
        this.getPolicySixDetailsAPIReceive(message);
        this.getPolicySevenDetailsAPIReceive(message);
        this.getPolicyEightDetailsAPIReceive(message);
        this.getPolicyNineDetailsAPIReceive(message);
    }
}
// Customizable Area End