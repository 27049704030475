// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TaskAllocatorController, { Props } from "./taskAllocatorController";
import { withTranslation } from "react-i18next";
import { Box, Dialog } from "@material-ui/core";
import { Formik } from "formik";
import Toster from "../../../components/src/Common/Toster";
import TaskForm from "../../../components/src/Common/TaskForm";

// Customizable Area End
export class TaskAllocator extends TaskAllocatorController {

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.formikRef = React.createRef();
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
      // Customizable Area Start
        const {classes} = this.props;

      // Customizable Area End

      return (
        // Customizable Area Start
        <div data-test-id="taskAllocator">
						<Toster
								data-test-id="toaster"
								tosterText={this.state.alertMessage}
								showToster={this.state.showAlert}
								tosterType={this.state.alertType}
								handleCloseToster={() => this.setState({ showAlert: false })}
						/>
            <Box sx={classes.insurerTableContainer}>
                <Dialog
                    open={this.props.showTaskDialog}
                    onClose={this.props.handleTaskDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                    className={classes.dialogWrapper}
                >
                    <Formik
                        data-test-id="taskAllocator_formik"
                        innerRef={this.formikRef}
                        initialValues={this.state.formData}
                        enableReinitialize={true}
                        validateOnMount={true}
                        validateOnBlur={true}
                        validationSchema={this.validationSchema(this.state.isArabicLenguage)}
                        onSubmit={(values) => this.submitData(values, this.props.update)}
                    >
                        {({
                            values,
                            setFieldValue,
                            handleSubmit,
                            resetForm
                        }) => {
                            return (
                                <TaskForm
                                    classes={classes}
                                    update={this.props.update}
                                    values={values}
                                    state={this.state}
                                    assigneeList={this.state.assigneeList}
                                    customerList={this.state.customerList}
                                    setFieldValue={setFieldValue}
                                    handleTaskIdDialog={this.props.handleTaskIdDialog}
                                    handleSubmit={handleSubmit}
                                    getFilePath={this.getFilePath}
                                    handleRemoveFileData={this.handleRemoveFileData}
                                    resetForm={resetForm}
                                    completeTask={this.completeTask}
                                />
                            )
                        }}
                    </Formik>
                </Dialog>
            </Box>
        </div >
        // Customizable Area End
      );
    }
}

// Customizable Area Start
const useStyles = ({
    insurerTableContainer: {
        padding: "24px",
        backgroundColor: "white",
        borderRadius: "7px",
    },
    toggleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 8px 8px 0px",
    },
		archiveButton: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center"
		},
		primaryBtnStyle: {
			background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
			textTransform: "none",
			color: "white",
			borderColor: '#3b8da2',
			padding: '10px 0px',
			width: '150px',
			borderRadius: "8px"
	},
    groupStyles: {
        color: 'white',
        // background: '',
        padding: '5px 0px',
        display: 'flex',
      },

		dialogWrapper: {
			"& [role='dialog']": {
					padding: '30px',
					borderRadius: '16px',
					width: '800px',
					height: "780px"
			}
		},
		dialogTitle: {
			textAlign: "left",
			fontSize: "16px",
			fontWeight: 700,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		headingStyle: {
			textAlign: 'center',
			'& h2': {
					fontWeight: 700,

			},
			fontSize: "16px"
		},
		buttonWrapper: {
			justifyContent: 'end',
		},
		cancelBtnStyle: {
			color: '#757575', borderRadius: "8px", textTransform:"none", borderColor:"#757575", padding: '10px 0px', width: '150px',
		},
		selectorClass: {
			height: '56px'
		}
});

export default withStyles(useStyles as any)(withTranslation()(TaskAllocator));
// Customizable Area End
