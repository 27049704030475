import React from "react";
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import PaymentReviewController from "./PaymentReviewController";
import LabelWithIcon from "../../../../../../components/src/Common/LabelWithIcon";
import TextInput from "../../../../../../components/src/Common/TextInput";
import FileUpload from "../../../../../../components/src/Common/FileUpload";
import { ErrorMessage } from "formik";
import DownloadFile from "../../../../../../components/src/Common/DownloadFile";
import PaymentReviewInsurerInstallmentForm from "./PaymentReviewInsurerInstallmentForm";
import CloseIcon from "@material-ui/icons/Close";
import NoDeductedCommissionInstallmentForm from './NoDeductedCommissionInstallmentForm'
import Toster from "../../../../../../components/src/Common/Toster";

export class PaymentReviewSiibForm extends PaymentReviewController {

    renderInstallForm = (installments: any) => {
        const { classes } = this.props;

        if (this.props.flowValue === 6 && installments?.length > 0) {
            return installments.map((item: any, index: number) => (
                <div key={`installment-${index}`} className={classes?.installmentAlignment} style={{ marginTop: 25 }}>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography style={{ fontWeight: 400 }}>{this.getNumberWithOrdinal(index + 2)} installment</Typography>
                        <Button
                            data-test-id="closeBtn"
                            variant="contained"
                            color="primary"
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            onClick={() => this.props.removeInstallment(index)}
                            style={{ backgroundColor: "white", color: "#007E98", boxShadow: "none" }}
                        >
                            <CloseIcon />
                        </Button>
                    </Grid>
                    <PaymentReviewInsurerInstallmentForm installmentsData={this.props.installmentsData} isAmountConfigure={this.props.isAmountConfigure} isPayInstallment={this.props.isPayInstallment}  createVoucherInstallmentStateHandler={this.props.createVoucherInstallmentStateHandler} createInvoiceInstallmentStateHandler={this.props.createInvoiceInstallmentStateHandler} createReceiptInstallmentStateHandler={this.props.createReceiptInstallmentStateHandler} installmentIndex={index} flow={this.props.flowValue} installmentItem={item} insurerInstallmentChnageHandler={this.props.insurerInstallmentChnageHandler} isExpanded={this.props.isExpanded} values={this.props.values} formikRef={this.props.formikRef} rrStageOneData={this.props.rrStageOneData} parentPolicyID={this.props.parentPolicyID} paymentVoucherFlowFiveInstallmentStateHandler={this.props.paymentVoucherFlowFiveInstallmentStateHandler} />
                </div>
            ));
        }

        if (this.props.flowValue === 5 && installments?.length > 0) {
            return installments.map((item: any, index: number) => (<div className={classes?.installmentAlignment} style={{ marginTop: 25 }} key={`${item.id}-${index}`}>
                <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography style={{ fontWeight: 400 }}>{this.getNumberWithOrdinal(index + 2)} installment</Typography>
                    <Button
                        data-test-id="closeBtn"
                        aria-controls="customized-menu"
                        variant="contained"
                        aria-haspopup="true"
                        color="primary"
                        onClick={() => this.props.removeInstallment(index)}
                        style={{ backgroundColor: "white", color: "#007E98", boxShadow: "none" }}
                    >
                        <CloseIcon />
                    </Button>
                </Grid>
                <NoDeductedCommissionInstallmentForm installmentsData={this.props.installmentsData} isAmountConfigure={this.props.isAmountConfigure} isPayInstallment={this.props.isPayInstallment} createVoucherInstallmentStateHandler={this.props.createVoucherInstallmentStateHandler} parentPolicyID={this.props.parentPolicyID} installmentIndex={index} flow={this.props.flowValue} installmentItem={item} flowFiveInstallmentChangeHandler={this.props.flowFiveInstallmentChangeHandler} isExpanded={this.props.isExpanded} paymentVoucherFlowFiveInstallmentStateHandler={this.props.paymentVoucherFlowFiveInstallmentStateHandler} formikRef={this.props.formikRef} />
            </div>
            ));
        }
        return null;
    };

    flowSixUiRender = () => {
        const { t, classes } = this.props;
        const { fieldsData } = this.props.values || {}
        return <>{this.props.flowValue === 6 &&
            <>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('commision_rate')} />
                        <TextInput
                            data-test-id="commission_rate"
                            disabled={this.isSiibFieldDisable()}
                            id="commission_rate"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj'][commission_rate]`}
                            type="text"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.siiCommonFieldChangeHandler(event.target.value, "commission_rate")}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj'][commission_rate]`}
                            className="field-error"
                            data-test-id="commission_rate_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />

                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('commision_amount')} />
                        <TextInput
                            data-test-id="commission_amount"
                            id="commission_amount"
                            className={classes.textInputStyle}
                            disabled={true}
                            name={`fieldsData['flowFieldObj'][commission_amount]`}
                            type={"text"}
                        />
                        <ErrorMessage
                            name="payment_review_payment_date"
                            className="field-error"
                            data-test-id="commission_amount_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />

                    </Grid>
                    <Grid item xs={4}>
                        <LabelWithIcon label={t('vat_on_commission')} />
                        <FormControl className={classes?.radioFormControl} style={{ marginLeft: 15, marginTop: 17 }}>
                            <RadioGroup
                                row
                                data-test-id="deduct_commission_with_vat"
                                name="fieldsData['flowFieldObj']['deduct_commission_with_vat']"
                                value={fieldsData.flowFieldObj?.['deduct_commission_with_vat']}
                                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => { this.props.siiCommonFieldChangeHandler(evt.target.value, "deduct_commission_with_vat", this.props.values) }}
                            >
                                <FormControlLabel
                                    disabled={this.isSiibFieldDisable()}
                                    value={"yes"} control={<Radio color="primary" />} label={t("yes")} />
                                <FormControlLabel
                                    disabled={this.isSiibFieldDisable()}
                                    value={"no"} control={<Radio color="primary" />} label={t("no")} />
                            </RadioGroup>
                        </FormControl>
                        <ErrorMessage
                            name="fieldsData['flowFieldObj']['deduct_commission_with_vat']"
                            className="field-error"
                            data-test-id="deduct_commission_with_vat_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('invoice_date')} />
                        <TextInput
                            data-test-id="invoice_date"
                            disabled={this.isSiibFieldDisable()}
                            id="invoice_date"
                            className={classes.textInputStyle}
                            name="fieldsData['flowFieldObj']['invoice_date']"
                            type="date"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.siiCommonFieldChangeHandler(event.target.value, "invoice_date")}
                        />
                        <ErrorMessage
                            name="fieldsData['flowFieldObj']['invoice_date']"
                            className="field-error"
                            data-test-id="invoice_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />

                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('receipt_date')} />
                        <TextInput
                            data-test-id="receipt_date"
                            disabled={this.isSiibFieldDisable()}
                            id="receipt_date"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj'][receipt_date]`}
                            type={"date"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.siiCommonFieldChangeHandler(event.target.value, "receipt_date")}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj'][receipt_date]`}
                            className="field-error"
                            data-test-id="receipt_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />

                    </Grid>
                    <Grid style={{ paddingRight: '25px', position: 'relative', top: '37px' }} item xs={4} >
                        <Button
                            data-test-id="invoice_receipt_button_siib"
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            color="primary"
                            disabled={this.checkCreateInvoiceReceiptBtnIsDisabled(this.props.values)}
                            className={this.checkCreateInvoiceReceiptBtnIsDisabled(this.props.values) ? classes.grayBtn : classes.primaryRvBtn}
                            onClick={
                                () => this.createInvoiceAndReceipt(this.props.values, this.props.flowValue, this.props.formikRef, this.props.installmentIndex)
                            }
                        >
                            {
                                (this.state.isinvoiceAndReceiptBtnLoading || this.state.receiptBtnLoading)
                                ? <CircularProgress size={25} />
                                : t("create_invoice_receipt")
                            }
                        </Button>
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj'][siib_invoice_id]`}
                            className="field-error"
                            data-test-id="siib_invoice_err"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('invoice')} />
                        <DownloadFile
                            data-test-id="invoice_doc"
                            disabled={true}
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            isEditPage={true}
                            inputName={'invoice_doc'}
                            fileName={this.props.values.fieldsData.flowFieldObj?.invoice_file?.file_name || ""}
                            filePath={this.props.values.fieldsData.flowFieldObj?.invoice_file?.url || ""}
                        />
                        <ErrorMessage
                            name={'invoice_doc'}
                            className="field-error"
                            data-test-id="invoice_doc_err"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('siib_invoice_id')} />
                            <TextInput
                                disabled={true}
                                className={classes.textInputStyle}
                                name={`fieldsData['flowFieldObj'][siib_invoice_id]`}
                                type="text"
                                data-test-id="siib_invoice_id"
                            />

                        </React.Fragment>
                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('receipt')} />
                        <DownloadFile
                            data-test-id="receipt"
                            disabled={true}
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            isEditPage={true}
                            inputName={`receipt`}
                            fileName={this.props.values.fieldsData.flowFieldObj?.receipt?.file_name || ""}
                            filePath={this.props.values.fieldsData.flowFieldObj?.receipt?.url || ""}
                        />
                        <ErrorMessage
                            name={'receipt'}
                            className="field-error"
                            data-test-id="receipt_err"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('siib_receipt_id')} />
                            <TextInput
                                className={classes.textInputStyle}
                                disabled={true}
                                name={`fieldsData['flowFieldObj'][siib_receipt_id]`}
                                type="text"
                                data-test-id="siib_receipt_id"
                            />
                            <ErrorMessage
                                name={'siib_receipt_id'}
                                className="field-error"
                                data-test-id="receipt_id_err"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                        </React.Fragment>
                    </Grid>
                </Grid>
            </>
        }</>
    }

    isSiibFieldDisable = () => {
        return !this.props.isExpanded || this.props.isPayInstallment;
    }

    renderOtherFlowUI = () => {
        const { t, classes } = this.props;
        const { fieldsData } = this.props.values || {}
        const receipt_number = "insurance_receipt_number";
        return (
            <>
                {this.props.flowValue !== 6 && <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={this.props.flowValue === 7 ? t('insurer_receipt_number') : t('insurance_receipt_number')} />
                        <TextInput
                            data-test-id="payment_review_insurance_receipt_number"
                            disabled={this.isSiibFieldDisable()}
                            id="insurance_receipt_number"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj'][${receipt_number}]`}
                            type="text"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.siiCommonFieldChangeHandler(event.target.value, receipt_number)}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj'][${receipt_number}]`}
                            className="field-error"
                            data-test-id="insurance_receipt_number_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />

                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={this.props.flowValue === 7 ? t('insurer_refund_payment_date') : t('payment_date')} />
                        <TextInput
                            data-test-id="payment_review_siib_payment_date"
                            disabled={!this.props.isExpanded || (this.props.flowValue !== 7 && this.props.isPayInstallment)}
                            id="payment_date"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj'][payment_date]`}
                            type={"date"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.siiCommonFieldChangeHandler(event.target.value, "payment_date")}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj'][payment_date]`}
                            className="field-error"
                            data-test-id="payment_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />

                    </Grid>
                    <Grid item xs={4}>
                    <LabelWithIcon label={t('proof_of_payment')} />
                        <FileUpload
                            eachFileSizeInMB={10}
                            disabled={this.isSiibFieldDisable()}
                            maxFilesNumber={5}
                            fileName={fieldsData.flowFieldObj?.proof_of_payment?.file_name || ""}
                            filePath={fieldsData.flowFieldObj?.proof_of_payment?.url || ""}
                            data-test-id="proof_of_payment"
                            onChange={(event: any) => this.props.siiCommonFieldChangeHandler(event.target.files[0], "proof_of_payment")}
                            onRemove={/*istanbul ignore next*/()=>{
                                this.props.formikRef?.current?.setFieldValue(`fieldsData['flowFieldObj'][proof_of_payment]`,{})
                                fieldsData.flowFieldObj?.proof_of_payment?.id&&  this.removeFilePaymentReview(fieldsData.flowFieldObj?.proof_of_payment?.id)
                                this.props.siiCommonFieldChangeHandler({}, "proof_of_payment")
                            }}
                        />
                        <ErrorMessage
                            name="fieldsData['flowFieldObj'][proof_of_payment]"
                            className="field-error"
                            data-test-id="proof_of_payment_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                </Grid>}

            </>
        )
    }

    flowNotEqualtoseven = () => {
        const { t, classes } = this.props;
        const { fieldsData } = this.props.values || {}
        return (
            <>
                {this.props.flowValue !== 7 && <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('insurer_receipt_number')} />
                        <TextInput
                            id="payment_review_insurance_receipt_number"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['insurer_recipient_number']`}
                            type="text"
                            disabled={this.isSiibFieldDisable()}
                            data-test-id="payment_review_insurance_receipt_number"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.siiCommonFieldChangeHandler(event.target.value, "insurer_recipient_number")}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['insurer_recipient_number']`}
                            className="field-error"
                            data-test-id="insurer_recipient_number_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />

                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>

                        <LabelWithIcon label={t('payment_date')} />
                        <TextInput
                            data-test-id="payment_review_voucher_payment_date"
                            disabled={this.isSiibFieldDisable()}
                            id="payment_review_voucher_payment_date"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj'][payment_voucher_payment_date]`}
                            type={"date"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.siiCommonFieldChangeHandler(event.target.value, "payment_review_voucher_payment_date")}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj'][payment_voucher_payment_date]`}
                            className="field-error"
                            data-test-id="payment_voucher_payment_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />


                    </Grid>
                    <Grid item xs={4}>
                    <LabelWithIcon label={t('proof_of_payment')} />
                        <FileUpload
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            disabled={this.isSiibFieldDisable()}
                            fileName={fieldsData.flowFieldObj?.['proof_of_payment_voucher']?.file_name||""}
                            filePath={fieldsData.flowFieldObj?.['proof_of_payment_voucher']?.url||""}
                            data-test-id="proof_of_payment"
                            inputName="fieldsData['flowFieldObj'][proof_of_payment_voucher]"
                            onChange={(event: any) => this.props.siiCommonFieldChangeHandler(event.target.files[0], "proof_of_payment_voucher")}
                            onRemove={/*istanbul ignore next*/async()=>{
                                this.props.formikRef?.current?.setFieldValue(`fieldsData"["flowFieldObj"]['proof_of_payment_voucher']`,{})
                               await this.props.siiCommonFieldChangeHandler("", "proof_of_payment_voucher")
                                fieldsData.flowFieldObj?.['proof_of_payment_voucher']?.id&&  this.removeFilePaymentReview(fieldsData.flowFieldObj?.['proof_of_payment_voucher']?.id)
                            }}
                        />
                        <ErrorMessage
                            name="fieldsData['flowFieldObj'][proof_of_payment_voucher]"
                            className="field-error"
                            data-test-id="proof_of_payment_voucher_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                </Grid>}
            </>
        )
    }

    render() {
        const { t, classes } = this.props;
        const isCreatePaymentVoucherBtnDisabled = this.checkFlowValueForPaymentVoucherDisable(this.props.values, this.props.flowValue)

        return (
            <>
                <Toster
                    tosterText={this.state.alertMessage}
                    showToster={this.state.showAlert}
                    tosterType={this.state.alertType}
                    handleCloseToster={this.closeAlertMessage}
                />
                {this.flowSixUiRender()}
                {this.renderOtherFlowUI()}
                <Grid item xs={12} container className={classes.borderAlignment} style={{ marginBottom: 20 }}>
                    {this.props.flowValue === 6 && <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('payable_to_insurer')} />
                        <TextInput
                            data-test-id="payable_to_insurer"
                            id="payable_to_insurer"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj'][payable_to_insurer]`}
                            type={"text"}
                            disabled={true}
                        />
                    </Grid>}
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('payment_voucher_date')} />
                        <TextInput
                            data-test-id="payment_review_payment_voucher_date"
                            id="payment_review_payment_voucher_date"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj'][payment_voucher_date]`}
                            type={"date"}
                            disabled={this.isSiibFieldDisable()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.siiCommonFieldChangeHandler(event.target.value, "payment_voucher_date")}
                        />
                        <ErrorMessage
                            name="fieldsData['flowFieldObj'][payment_voucher_date]"
                            className="field-error"
                            data-test-id="payment_voucher_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid item xs={4} container style={{ marginTop: 35, marginBottom: 9, height: 56 }} >
                        <Button
                            aria-controls="customized-menu"
                            data-test-id="voucherBtn"
                            aria-haspopup="true"
                            variant="contained"
                            color="primary"
                            disabled={isCreatePaymentVoucherBtnDisabled}
                            className={isCreatePaymentVoucherBtnDisabled ? classes.grayBtn : classes.primaryRvBtn}
                            style={{ textTransform: 'none', marginRight: 25, borderRadius: 5, width: '100%', fontSize: 17, backgroundColor: "#979090", color: "white" }}
                            onClick={() => {
                                this.generateVoucherForSiib(this.props.values, this.props.formikRef, this.props.flowValue, this.props.parentPolicyID);
                            }}
                        >
                            {!this.state.voucherBtnLoading && t('create_payment_voucher')}
                            {this.state.voucherBtnLoading && (<CircularProgress size={25} />)}
                        </Button>
                        <ErrorMessage
                            data-test-id="payment_review_payment_voucher_id_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                            className="field-error"
                            name="fieldsData['flowFieldObj'][payment_review_payment_voucher_id]"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid item xs={4} style={{ paddingRight: '25px' }}>
                    <LabelWithIcon label={t('payment_voucher')} />
                        <FileUpload
                            inputName="fieldsData[flowFieldObj][payment_review_payment_voucher]"
                            fileName={this.props.values.fieldsData.flowFieldObj?.['payment_review_payment_voucher']?.file_name}
                            filePath={this.props.values.fieldsData.flowFieldObj?.['payment_review_payment_voucher']?.url}
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            disabled={true}
                            data-test-id="payment_voucher"
                        />
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('payment_voucher_id')} />
                        <TextInput
                            className={classes.textInputStyle}
                            disabled={true}
                            name="fieldsData['flowFieldObj'][payment_review_payment_voucher_id]"
                            type="text"
                            data-test-id="payment_review_payment_voucher_id"
                        />

                    </Grid>
                </Grid>
                {this.flowNotEqualtoseven()}
                {this.props.flowValue !== 7 && <Grid item xs={12} className={classes.voucherBorder}>
                    <LabelWithIcon label={t('policy_release_requested')} />
                    <FormControlLabel
                        color="primary"
                        name={`fieldsData['flowFieldObj']['policy_release_requested']`}
                        control={<Checkbox color="primary"
                            disabled={this.isSiibFieldDisable()}
                            checked={this.props.values.fieldsData.flowFieldObj?.['policy_release_requested'] === "yes" ? true : false}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => this.props.siiCommonFieldChangeHandler(evt.target.checked, 'policy_release_requested')}
                        />
                        }
                        data-test-id={`policy_release_requested`}
                        label={t('yes')}
                    />
                    <br/>
                    <ErrorMessage
                        name={`fieldsData['flowFieldObj']['policy_release_requested']`}
                        className="field-error"
                        data-test-id="policy_release_requested_error"
                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                    />

                </Grid>}
                {this.props.values?.fieldsData?.flowFieldObj?.installment_attributes && this.renderInstallForm(this.props.values.fieldsData.flowFieldObj?.['installment_attributes'])}
            </>
        )
    }
}

const useStyles = ({
    error: {
        color: 'red'
    },
    displayFlex: {
        display: "flex",
    },
    contactForm: { width: "100%" },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    voucherBorder: {
        paddingBottom: "25px"
    },
    borderAlignment: {
        paddingTop: "25px",
        borderTop: '3px solid #eaeaea'
    },
    installmentAlignment: {
        border: "3px solid #eaeaea",
        borderRadius: "10px",
        padding: "20px",
    },
    grayBtn: {
        "text-transform": 'none',
        padding: 13,
        borderRadius: 5,
        width: '100%',
        fontSize: 15,
        backgroundColor: "#979090",
        color: "white"
    },
    primaryRvBtn: {
        "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": 'none',
        "padding": 13,
        "borderRadius": 5,
        "width": '100%',
        "fontSize": 15,
        "color": "white"
    }
});

export default withStyles(useStyles)(withTranslation()(PaymentReviewSiibForm));