// Customizable Area Start
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import getStageNameByNumber, { getStageNumberByName } from "../../../../../../components/src/Common/PolicyRequest/StagesName";
import * as Yup from 'yup';

// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
		t: (key: string) => void;
    classes?: any;
		policyID?: any;
		currentStep?: number;
    rrStageOneData?: any;
    rrStageTwoData?: any;
    isExpanded?: boolean
    isEdit?: boolean;
    onNext?: any;
    setStageData?: any;
		onReject?:any;
		handoverNote?: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showAlert:boolean;
    alertMessage:string;
    alertType:string;
    isArabicLenguage: boolean;
    isLoading: boolean;
		lang: string;
    policyId: string;
		policyInfo: any;
		expandedAccordion: boolean;
		disableAccordian: boolean;
		stage2submitted: boolean;
		loggedInRole: string;
		hasViewPermissionList: any[];
		hasUpdatePermissionList: any[];
		showNoteDialog: boolean;
		handOverNote: string;
		handOverNoteText: string;
		fileLoading: boolean;
		stageData: any;
		contactList: any[];
		customerName: string;
		errorSection?: string;
		contactOption?:any[];
		rejectReasonText?: string;
		showRejectDialog: boolean;
		isBtnVisible: boolean;
		moveToStep?: any
		emailTemplate?: string
		insuranceData?: any,
		showDialog:boolean;
		isDraftAPIUsed?: boolean;
		uploadedFileList?: any;
		removeFileList?: any;
		expandProcessingAccordian?: boolean
		processingLoading: boolean,
		isViewOnly: boolean,
		fileUploadErrorIndexList: number[],
		showNextDialog: boolean;
		rejectLoading:boolean;
		rejectedStage: string;
		saveDraftLoading: boolean;
		trackFor: string;
		selectedtemplateOption:string;
		templateList:any[],
		selectedTemplateID:any,
		openCSVDialog:boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class StageTwoController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
		getProcessingDataAPI = '';
		setProcessingDataAPI = '';
		modifyProcessingDataAPI = '';
		policyId: string | number = '';
		insuranceRRType: string = '';
		stageInfo: any = '';
		insurerList: any = '';
		customerName: string = '';
		insurerName: string = '';
		removeFileAPI: string = '';
		idToRemove: number[] = [];
		rejectPolicyApi:any = "";
		selectedTemplateID:any;
		getAllTemplateListApi:string="";
		getPolicyDetailsAPI:string="";
		insuranceType:string=""
    // Customizable Area End

    constructor(props: Props) {
			// Customizable Area Start
			super(props);
			this.receive = this.receive.bind(this);
			this.subScribedMessages = [
				getName(MessageEnum.CountryCodeMessage),
				getName(MessageEnum.RestAPIResponceMessage),
				getName(MessageEnum.ReciveUserCredentials)
			];

			this.state = {
					showAlert: false,
					alertMessage: '',
					alertType: '',
					isArabicLenguage: false,
					isLoading: false,
					lang: '',
					policyId: this.props.policyID,
					policyInfo: {},
					expandedAccordion: this.props.isExpanded || false,
					disableAccordian: false,
					stage2submitted: false,
					loggedInRole: '',
					hasViewPermissionList: [],
					hasUpdatePermissionList: [],
					showNoteDialog: false,
					handOverNote: this.props.handoverNote || '',
					handOverNoteText: '',
					fileLoading: false,
					stageData: {},
					contactList: [],
					customerName: '',
					errorSection: '',
					rejectReasonText: '',
					showRejectDialog: false,
					isBtnVisible: true,
					moveToStep: '',
					emailTemplate: '',
					showDialog:false,
					isDraftAPIUsed: false,
					uploadedFileList: [],
					removeFileList: [],
					expandProcessingAccordian: false,
					processingLoading: false,
					isViewOnly: false,
					fileUploadErrorIndexList: [],
					showNextDialog: false,
					rejectLoading: false,
					rejectedStage: '',
					saveDraftLoading: false,
					trackFor: '',
					selectedtemplateOption:"Create a new template",
					templateList:[],
					selectedTemplateID:"",
					openCSVDialog:false
			};
			runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
			// Customizable Area End
    }

		async componentDidMount() {
			// Customizable Area Start
			super.componentDidMount();
			const { role } = JSON.parse(window.localStorage.getItem('loginData') || '{}')
			const stageOneData = this.props.rrStageOneData?.data.attributes;
			const stageOneMeta = this.props.rrStageOneData?.meta;
      this.insuranceRRType = stageOneData.revision_type.value;
      this.insurerList = stageOneData?.revision_input.data.attributes.insurance_company_id;
			this.stageInfo = { ...stageOneData };
			this.insurerName = stageOneData.revision_input.data.attributes.insurance_company_id.allData.short_name;
			this.policyId = stageOneData?.id;
      this.setState({expandedAccordion: this.props.isExpanded as boolean})
      this.setState({loggedInRole: role})
	  this.getPolicyInsuranceType(this.props.rrStageOneData?.data.attributes.policy_id)
			this.setState({
				hasViewPermissionList: stageOneMeta.next_stage_view_permission_role,
				hasUpdatePermissionList: stageOneMeta.next_stage_update_permission_role,
			})
			if(this.props.rrStageTwoData?.data?.attributes?.is_next_stage_submitted) this.setState({expandedAccordion: false})
			if(this.props.isEdit || stageOneData.is_next_stage_submitted) {
        if(!stageOneMeta?.next_stage_view_permission_role.includes(role)) this.setState({expandedAccordion: false})
        this.initialProcessingForm(this.props.rrStageTwoData?.data.attributes);
      } else {
        this.setState({handOverNoteText: ''})
        this.initialProcessingForm()
      }

			// Customizable Area End
		}

		async componentDidUpdate(
			prevProps: Readonly<Props>,
		  ) {
			if (prevProps.isExpanded !== this.props.isExpanded) {
				this.setState({expandedAccordion: this.props.isExpanded as boolean})
			}
		}

    // Customizable Area Start
		setInitialDataValues = (data: any, item: any) => {
			this.setState({uploadedFileList: data?.upload_submitted_attachments?.length ? data.upload_submitted_attachments : []})
			const contactData = item?.insurance_contacts.map((val: any)=> ({label: val.first_name , value: val.id, allData: {id: val.id, ...val}} ))
			const insuranceContact = contactData?.find((item:any)=>  data?.insurance_contact_id?.value === item.value)
			return ({
				contact: item?.insurance_contacts,
				id: item.id,
				insuranceContactId: insuranceContact || '',
				markAsSubmitted: data?.mark_as_submitted || false,
				emailDraft: insuranceContact?.allData.emailBody || '',
				emailSent: data.email_sent || '',
				emailSubject: item.emailSubject,
				email: data.email || '',
				uploadedAttachments: [],
				fileUploadHasOneElement: !!data.upload_submitted_attachments
			})
		}

		initialProcessingForm = (stageTwoData: any = {}) => {
			let stageData: any = {};
			const insuranceData = this.extractInsurerData(this.insurerList)
			if(insuranceData.insurance_contacts?.length && stageTwoData) {
				stageData = this.setInitialDataValues(stageTwoData, insuranceData);
			}
			this.setState({stageData});
		}

		capitalizeFirstCharacter = (str: string) => {
			if (!str) return str;
			return str.charAt(0).toUpperCase() + str.slice(1);
		}

		generateTemplateString =(segment: {label:string; value: string}[]) =>  `
Dear Team,

Hope you are doing well.

Kindly requested to process the Endorsement of (${this.capitalizeFirstCharacter(this.insuranceRRType)}) attached under policy ${segment?.map((item) => item.label).join(',')} as per client request.

${this.stageInfo?.branches?.data.map((branch: any) => (`- ${branch?.attributes?.name}`))?.join('\n') || ''}

For your reference, please find attached all relevant information.

Thank You,
Best Regards`;

		extractInsurerData = (insurerList: any) => {
			const {
				segment,
				short_name,
				insurance_contacts,
				id
			} = insurerList.allData;
			const formattedInsuranceContacts = insurance_contacts.data?.length ? insurance_contacts.data?.map((contact: any) => {
				const { email,first_name, last_name, ...rest } = contact.attributes;
				return {
					...rest,
					emailSubject: `Endorsement request for ${this.capitalizeFirstCharacter(this.stageInfo.customer_contact_name)} (${this.capitalizeFirstCharacter(this.insuranceRRType)})`,
					emailBody: this.generateTemplateString(segment),
					email,
					first_name,
				};
			}) : [];
			return {
					insurance_contacts: formattedInsuranceContacts,
					short_name,
					emailSubject: `Endorsement request for ${this.capitalizeFirstCharacter(this.stageInfo.customer_contact_name)} (${this.capitalizeFirstCharacter(this.insuranceRRType)})`,
					id
			};
    }

		setArabicLanguage = (value: boolean) => {
			this.setState({
				isArabicLenguage: value,
			});
		};

		isStageTwoPolicyCreated = () => {
      const stageOneData = this.stageInfo;
      return stageOneData.is_next_stage_submitted;
    }
		handleAccordian = () => {
			if(this.policyId && !this.hasOnlyViewRights() && !this.hasOnlyEditRights() && this.isStageTwoPolicyCreated()) {
				this.setAlert(this.state.isArabicLenguage ? `لا يمكن الوصول إلى معالجة RFQ لدور ${this.state.loggedInRole}. الرجاء تسجيل الدخول مع دور آخر` : `RFQ processing is not accessible to ${this.state.loggedInRole} role. Please login with other role`, "warning")
				return
			}
			this.setState((prev: any) => ({
				...prev,
				expandedAccordion: !prev.expandedAccordion
			}))

		};

		hasOnlyViewRights = () => {
			return this.state.hasViewPermissionList.includes(this.state.loggedInRole)
		}

		hasOnlyEditRights = () => {
			return this.state.hasUpdatePermissionList.includes(this.state.loggedInRole)
		}

		stageNineViewRights = () => {
			return this.state.hasViewPermissionList.includes(
				this.state.loggedInRole
			);
		};
		handleHandOverNoteText = (inputText: string) => {
			this.setState({handOverNoteText: inputText })
		}
		handleNoteDialog =()=>{
			this.setState({showNoteDialog: !this.state.showNoteDialog})
		}

		setAlert = (responseJson: any, alertType: string = 'error') => {
			this.setState({ showAlert: true, alertMessage: responseJson, alertType: alertType})
		}
		async submitProcessingData(values: any, isDraft: boolean = false){
			if(this.props.isEdit || this.isStageTwoPolicyCreated()) {
				this.setProcessingData(values, this.props.isEdit, isDraft)
			} else {
				this.setProcessingData(values, false, isDraft)
			}
		}
		handleRemoveFileData(id: number) {
				this.removeUploadedFile([id]);
		}
		getFormDataRRP = (item: any, isDraft: boolean = false) => {
			const formData = new FormData();
			item.insuranceContactId.value && formData.append(`data[revision_processing][insurance_contact_id]`, item.insuranceContactId.value );
			formData.append(`data[revision_processing][mark_as_submitted]`, item.markAsSubmitted );
			item.emailSent && formData.append(`data[revision_processing][email_sent]`, item.emailSent );
			formData.append(`data[revision_processing][save_as_draft]`, isDraft ? 'yes' : 'no' );
			for (const file of item.uploadedAttachments) {
				formData.append('data[upload_submitted_attachments][]', file);
			}
			return formData;
		}
		processingSchema = (isArabicLanguage: boolean, isAdded = false) => {
			const schema = {
					insuranceContactId: Yup.string().required(isArabicLanguage ? 'جهة الاتصال للمُؤمن مطلوبة.' : "Insurer contact is required."),
					markAsSubmitted: Yup.bool().oneOf([true], isArabicLanguage ? 'العلامة كمُقدَّم مطلوبة.' : "Mark as submitted is required."),
					emailSent: Yup.string().required(isArabicLanguage ? 'البريد الإلكتروني المُرسَل مطلوب.' : "Sent email is required."),
					uploadedAttachments: Yup.array().min(1, isArabicLanguage ? 'يجب تحميل ملف واحد على الأقل باللغة العربية.' : "Atleast one file should be uploaded").nullable(),
				}
				if(isAdded) schema.uploadedAttachments = Yup.array().nullable();
			return Yup.object().shape(schema);
		}
		apiCallRR(data: any) {
			const { contentType, method, endPoint, body, type } = data;
			const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
			const token = userData.token || "";
			let header: any = {
				token: token,
				"Content-Type": contentType
			};
			const revisionRequestCsvImportMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
			revisionRequestCsvImportMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
			revisionRequestCsvImportMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
			revisionRequestCsvImportMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);
			body && type !== 'FormData' ?
			revisionRequestCsvImportMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(body)) : revisionRequestCsvImportMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),body);
			runEngine.sendMessage(revisionRequestCsvImportMessage.id, revisionRequestCsvImportMessage);
			return revisionRequestCsvImportMessage.messageId;
		}

		setProcessingData = (values: any, isEdit: boolean = false, isDraft: boolean = false) => {
			this.setProcessingDataAPI = this.apiCallRR({
					type : 'FormData',
					method: isEdit ? 'PUT' : 'POST',
					body: this.getFormDataRRP(values, isDraft),
					endPoint: isEdit ? `bx_block_revision_request/revision_requests/${this.policyId}?stage=2` : `bx_block_revision_request/revision_requests?id=${this.policyId}&stage=2`,
			})
		}

		createApiResponse = (message: any) => {
			if (
				getName(MessageEnum.RestAPIResponceMessage) === message.id && this.setProcessingDataAPI !== null && this.setProcessingDataAPI ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
				)
			{
				const createStageTwoResponseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
				try {
					if (createStageTwoResponseJson.data) {
						const responseData = createStageTwoResponseJson.data;
						this.initialProcessingForm(responseData.attributes);
						this.setState({stage2submitted: true, uploadedFileList: responseData.attributes.upload_submitted_attachments})
						this.props.setStageData('stageTwo', createStageTwoResponseJson)
						if(responseData.attributes.save_as_draft){
							this.setAlert(this.props.t('saved_as_draft_successfully'), 'success' )
							this.setState({saveDraftLoading: false})
							return
						} else {
							this.setState({saveDraftLoading: false})
							this.handleNextDialog(true);
						}
					} else {
						this.setApiErrorMessage(createStageTwoResponseJson);
					}
				} catch (error: any) {
				} finally {
					if(createStageTwoResponseJson.meta) {
						const {current_stage_view_permission_role, current_stage_update_permission_role} = createStageTwoResponseJson.meta
						this.setState({
							hasViewPermissionList : current_stage_view_permission_role,
							hasUpdatePermissionList: current_stage_update_permission_role,
						})
					}
				}
			}
		}

		getStepName = getStageNameByNumber;
  	getStepNumber = getStageNumberByName;
	  setApiErrorMessage = (responseJson:any) => {
			if (!responseJson) return
			let errorMessage = "Something went wrong"
			if (responseJson?.errors) {
				errorMessage = typeof(responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error  || "Something went wrong";
			}
			this.setState({
				alertType: "error",
				alertMessage: errorMessage,
				showAlert: true,
				isLoading: false,
				fileLoading:false,
				rejectLoading:false,
				saveDraftLoading:false,
				rejectedStage:"",
				showRejectDialog:false,
				showNoteDialog:false,
			})
	  }
		rejectRvPolicyApiReceive = (message:any) => {
			if (
				getName(MessageEnum.RestAPIResponceMessage) === message.id &&
				this.rejectPolicyApi !== null &&
				this.rejectPolicyApi ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
				) {
				const responseJson = message.getData(
					getName(MessageEnum.RestAPIResponceSuccessMessage)
				);
				if (responseJson.data) {
					const stageNumber = this.getStepNumber(this.state.rejectedStage)

					if (this.state.trackFor === "next") {
						this.props.setStageData({"handOverNoteForAwaiting": this.state.handOverNoteText})
						this.setAlert(this.props.t('moved_to_awaiting'), 'success')
						this.setState({showNextDialog: false, expandedAccordion: false, handOverNoteText: "" })
						this.props.onNext(2)
					} else {
						this.setState({
							showAlert: true,
							alertMessage: this.state.isArabicLenguage ? 'لقد تم رفض طلبك للمرحلة المحددة!' : `Your request has been reject to selected stage!`,
							alertType: "success",
							showRejectDialog: false,
							rejectLoading: false,
							expandedAccordion: false,
						})
						this.props.onReject(stageNumber);
					}
				} else {
					this.setApiErrorMessage(responseJson)
				}
			}
		}
		fileRemoveApiReceive = (message:any) => {
			if (
				getName(MessageEnum.RestAPIResponceMessage) === message.id &&
				this.removeFileAPI !== null &&
				this.removeFileAPI ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
				) {
				const responseJson = message.getData(
					getName(MessageEnum.RestAPIResponceSuccessMessage)
				);
				if (responseJson.data) {
					responseJson.data.success && this.setState({
					showAlert: true,
					alertMessage: responseJson.data.success,
					alertType: "success",
					})
					responseJson.data.error_file && this.setState({
						showAlert: true,
						alertMessage: responseJson.data.error_file,
						alertType: "error",
					})
					responseJson.data.data_file?.length && this.setState({stageData: {
						uploadedAttachments: responseJson.data.data_file
					}})
				} else {
					this.setApiErrorMessage(responseJson)
				}
			}
		}
		handleRejectDialog = (value:boolean) => {
			this.setState({showRejectDialog:value, isBtnVisible: true})
			if (value === false) {
				this.setState({rejectLoading:false})
			}
		}

		checkStageTwoCondition = () => {
			return this.state.expandedAccordion && this.props.rrStageTwoData?.data?.attributes?.is_reject
		}

		handleNextDialog = (value:boolean) => {
			this.setState({showNextDialog:value});
			if (value === false) {
				this.setState({isLoading:false, handOverNoteText:""})
			}
		}
		handleTrackData = (value: string) => {
			this.setState({trackFor: value});
			this.rejectPolicy(value)
		}

		rejectPolicy = (action: string) => {
			this.rejectPolicyApi = this.apiCallRR({
				contentType: 'application/json',
				method: 'POST',
				endPoint: `bx_block_revision_request/revision_requests/${this.policyId}/track_revision_requests?stage=2`,
				body: {
					"data": {
						"track_revision_request": {
							"stage_status": (action === "next") ? "next_stage" : "reject_stage",
							"comment": (action === "next") ? this.state.handOverNoteText : this.state.rejectReasonText,
							"current_stage": (action === "next") ? "awaiting" : this.state.moveToStep.value
						}
					}
				}
			});
		}
		removeUploadedFile = (action: number[] | string[]) => {
			if(action.length) this.removeFileAPI = this.apiCallRR({
				contentType: 'application/json',
				method: 'DELETE',
				endPoint: `bx_block_revision_request/revision_requests/${this.policyId}/remove_file`,
				body: {
					"data": {
						"file_id": action
					}
				}
			});
		}
		handleTemplateIDChange=(selectedOption:any)=>{
			this.setState({selectedTemplateID:selectedOption})
		  }
		handleopenCSVDialog=()=>{
			this.setState({openCSVDialog:!this.state.openCSVDialog})
		  }
		  handleSelectTemplateChange=(event:any)=>{
			this.setState({selectedtemplateOption:event.target.value},()=>{
			  if(this.state.selectedtemplateOption==="Select from existing template"){
				this.getAllTemplateListApi=this.apiCallRR({
				  contentType: 'application/json',
				  method: 'GET',
				  endPoint: `/bx_block_xmlcsvtemplatebuilder2/csv_templates?insurance_type=${this.insuranceRRType}`,
			  })
			  }
			})
		  }
		  handleConfirmCSvDialog = () => {
			const state:{
			  [key: string]: string;
			} = { insuranceType: this.insuranceType };
			const queryString = Object.keys(state)
			  .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(state[key]))
			  .join('&');
			  const templateID=this.state.selectedTemplateID.value ? `&templateID=${this.state.selectedTemplateID.value}`:""
			const newUrl = '/CSVExport?' + queryString+templateID;
			window.location.href = newUrl;
		  }
		  getPolicyInsuranceType = async (policyID: any) => {
			const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
			const token = await userData.token || '';
			this.getPolicyDetailsAPI = this.apiCallRR({
				contentType: 'application/json',
				method: "GET",
				endPoint: `bx_block_policies/policies/${policyID}`,
				token: token || '',
			})
		}
		getinsuranceTypeAPIResponse = (message: any) => {
			if (
			  getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			  this.getPolicyDetailsAPI !== null &&
			  this.getPolicyDetailsAPI ===
			  message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
			  ) {
				const getInsuranceTypeResponseJson = message.getData(
					getName(MessageEnum.RestAPIResponceSuccessMessage)
				  );
				   
					if (getInsuranceTypeResponseJson.data) { 
					    this.insuranceType= getInsuranceTypeResponseJson.data.attributes.types_of_insurance;
					}
			  }
			}
		getAllTemplateListAPIResponse = (message: any) => {
			if (
			  getName(MessageEnum.RestAPIResponceMessage) === message.id &&
			  this.getAllTemplateListApi !== null &&
			  this.getAllTemplateListApi ===
			  message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
			  ) {
				const getAllTemplateListJson = message.getData(
					getName(MessageEnum.RestAPIResponceSuccessMessage)
				  );
				   
					if (getAllTemplateListJson.data) { 
					  const transformedData=getAllTemplateListJson.data.map((ele:any)=>{
						return {value:ele.id.toString(),label:ele.template_name}
					  })
					this.setState({templateList:transformedData})  
					}
			  }
			}
    // Customizable Area End
		async receive(from: string, message: Message) {
			// Customizable Area Start
			this.createApiResponse(message);
			this.rejectRvPolicyApiReceive(message);
			this.fileRemoveApiReceive(message);
			this.getAllTemplateListAPIResponse(message);
			this.getinsuranceTypeAPIResponse(message)
			// Customizable Area End
		}
}
