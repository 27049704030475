// Customizable Area Start
import React from "react";
import { Container } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import StageFourController from "./StageFourController";
import { withTranslation } from "react-i18next";
import StageFourForm from './StageFourForm';
const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff"
        }
    }
});

// Customizable Area End
export class StageFour extends StageFourController {

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container id="customerContainer" maxWidth="lg" dir={(window.localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"} >
                    <StageFourForm {...this.props} />
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const useStyles = ({});
// Customizable Area End

export default withStyles(useStyles)(withTranslation()(StageFour));
