import React, { forwardRef, useEffect } from 'react'
import ListItem from '@material-ui/core/ListItem'
import { NavLink, NavLinkProps } from 'react-router-dom'
import UserPermission from '../../Common/Auth/UserPermission'

export interface AppMenuItemComponentProps {
  className?: string
  link?: string | null // because the InferProps props allows alows null value
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const AppMenuItemComponent: React.FC<AppMenuItemComponentProps> = props => {
  const { className, onClick, link, children } = props
  const [isActiveMenu, setIsActiveMenu] = React.useState(false);
  
  
    
  useEffect(() => {
    UserPermission()
    if (
      
      // Checking for the policy child page
      (window.location.pathname.includes('PolicyRequest') && link === "/Requests") || 
      (window.location.pathname.includes('CSVExport') && link === "/Requests") || 
      (window.location.pathname.includes('ViewPolicy') && link === "/Requests") || 
      (window.location.pathname.includes('ImportPolicyCsv') && link === "/Requests") || 
      (window.location.pathname.includes('ImportEmployeeCsv') && link === "/Requests") || 
      (window.location.pathname.includes('RevisionRequest') && link === "/Requests") || 

      // Checking fot the customer child page
      (window.location.pathname.includes('CustomersDetail') && link === "/Customers") ||
      (window.location.pathname.includes('ViewCustomer') && link === "/Customers") ||
      (window.location.pathname.includes('AddCustomer') && link === "/Customers") ||
      (window.location.pathname.includes('ImportCustomerCsv') && link === "/Customers") ||
      
      // Checking for the insurer child page
      (window.location.pathname.includes('PolicyOverview') && link === "/Policies") ||
      (window.location.pathname.includes('InsurerDetail') && link === "/Insurer") ||
      (window.location.pathname.includes('ImportInsurerCsv') && link === "/Insurer")
      
    ) {
      setIsActiveMenu(true);
    } else {
      setIsActiveMenu(false);
    }
  }, [window.location.pathname]);

  // If link is not set return the orinary ListItem
  if (!link || typeof link !== 'string') {
    return (
      <ListItem
        button
        className={className}
        children={children}
        onClick={onClick}
      />
    )
  }
  
  // Return a LitItem with a link component
  return (
    <ListItem
      button
      className={isActiveMenu ? (`${className} active`) : className}
      children={children}
      component={forwardRef((props: NavLinkProps, ref: any) => <NavLink target={link === '/ReviewAndApproval' ? '_blank' : ''}  exact {...props} innerRef={ref} />)}
      to={link}
    />
  )
}

export default AppMenuItemComponent
