// Customizable Area Start
import getStageNameByNumber, { getStageNumberByName } from "../../../../../components/src/Common/PolicyRequest/StagesName";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import * as Yup from "yup";
import ActiveLanguage from "../../../../../components/src/Common/ActiveLanguage";

const stageNineRoles = {
  ADMIN: "Admin",
  OPERATIONS: "Operations",
  CONTACT: "Contact",
  FINANCE: 'Finance'
};

export interface Props {
  formikRef?: any;
  navigation?: any;
  id?: string;
  isExpanded?: boolean;
  isEdit?: boolean;
  onNext?: any;
  onReject?: any;
  t?: any;
  classes?: any;
  stageTwoData?: any;
  stageSixData?: any;
  setStageData?: any;
  stageOneData?: any;
  stageSevenData?: any;
  stageEightData?: any;
  values?: any;
  setFieldValue?: any;
  currentFlow?: number;
  paidAmount?: number;
  installmentIndex?: any;
  touched?: any;
  errors?: any;
  insurance_company_id?: any;
  isDraft?: boolean;
}

export interface S {
  loading: boolean;
  flow: number;
  policyId: string;
  expandedAccordion: string;
  showRejectDialog: boolean;
  isBtnVisible: boolean;
  isArabicLenguage: boolean;
  showAlert: boolean;
  alertMessage: string;
  alertType: string;
  errorSection: string;
  showNoteDialog?: boolean;
  handOverNoteValue?: string;
  loggedInRole: string
  currentRejectedStage: string;
  stageNineRejectReason: string;
  hasViewPermissionList: any;
  isEdit: boolean;
  isStageSubmited: boolean;
  saveDraftLoading?: boolean;
  isReject: boolean;
  isDraft: boolean;
  isInstallmentPaid: boolean;
  stageSevenInstallments: any[];
  lastInvoicePath: any;
  lastSIIBInvoiceId: string;
  lastInvoicePathName: string;
  isInvoiceIsCreated: boolean;
  invoiceBtnLoding: boolean;
  isCreditNoteBtnLoading: boolean;
  currentInstallmentIndex: number;
}

interface SS {
  id: any;
}

export default class StageNineController extends BlockComponent<Props, S, SS> {

  uploadFileApiCall: any = "";
  removeFileAPI:any="";
  formikRef: any = null;
  fieldName: string = ''
  fileToRemove: number = 0
  trackPolicyApi: any = "";
  payInstallmentApi: any = "";
  getPolicyDetailsAPI: any = "";
  stageTwoApiCall: any = "";
  stageNineSubmitApi: any = "";
  saveDraftPolicyApi: any = "";
  generateInvoiceForInstallmentApi: any = "";
  generateInvoiceForOtherInstallmentApi: any = "";
  createCreditNoteApiId: any = "";
  createCreditNoteForInstallmentApiId: any = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    const queryParameter: any = new URLSearchParams(window.location.search);
    const policyId: string = queryParameter?.get('id');

    const loginData = JSON.parse(window.localStorage.getItem("loginData") || "{}")

    this.state = {
      loading: false,
      flow: 0,
      policyId: policyId || "",
      isStageSubmited: this.props.stageEightData?.submitApiResponse.attributes?.is_next_stage_submitted || false,
      expandedAccordion: "p9",
      showRejectDialog: false,
      isBtnVisible: true,
      handOverNoteValue: "",
      loggedInRole: loginData?.role || "",
      isArabicLenguage:
        (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      showAlert: false,
      alertMessage: "",
      alertType: "",
      errorSection: "",
      showNoteDialog: false,
      currentRejectedStage: "",
      stageNineRejectReason: "",
      hasViewPermissionList: [],
      saveDraftLoading: false,
      isEdit: false,
      isReject: false,
      isDraft: false,
      isInstallmentPaid: false,
      stageSevenInstallments: [],
      lastInvoicePath: "",
      lastSIIBInvoiceId: "",
      lastInvoicePathName: "",
      currentInstallmentIndex: 0,
      isInvoiceIsCreated: false,
      invoiceBtnLoding: false,
      isCreditNoteBtnLoading: false

    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();



    // Calling get API is ID found in URL
    const queryParameter: any = new URLSearchParams(window.location.search);
    const policyId: string = queryParameter?.get('id') ? queryParameter?.get('id') : this.props.stageOneData.policyId;

    if (policyId) {
      this.setState({ policyId: policyId })
    }

    const stageSevenData = JSON.parse(window.localStorage.getItem("stageSevenData") || "{}")
    // Getting stage 7 flow
    if (stageSevenData) {
      const installments = stageSevenData?.attributes?.installments?.data?.sort((a: any, b: any) => parseInt(a.attributes.installment_no) - parseInt(b.attributes.installment_no));
      this.setState({ flow: stageSevenData.attributes.flow, stageSevenInstallments: installments || [] })
    }
  }

  componentDidUpdate() {
    if (this.state.showAlert) {
      setTimeout(() => {
        this.setState({
          showAlert: false,
          alertType: "",
          alertMessage: "",
        });
      }, 10000);
    }
  }
  getSuffix(value: number) {
    const lastDigit = value % 10;
    const numberArr = [11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    if (numberArr.includes(value)) return "th";
    else if (lastDigit === 1) return "st";
    else if (lastDigit === 2) return "nd";
    else if (lastDigit === 3) return "rd";
    else return "th";
  }

  handleSubmitFromOutside = (showPayInstalmentBtn: boolean) => {
    const { current: formikInstance } = this.formikRef;
    if (!showPayInstalmentBtn) this.setState({ isInstallmentPaid: true })
    if (formikInstance) {
      this.checkInvoicePaid(formikInstance.errors)
      formikInstance.handleSubmit();
    }
  };

  checkInvoicePaid = (errorsData: any) => {
    if (Object.keys(errorsData).length !== 0 && Object.keys(errorsData)[0] === "invoicePaid") {
        this.setState({
            showAlert: true,
            alertMessage: errorsData.invoicePaid,
            alertType: "error",
            errorSection: "p2",
        })
    }
  }

  flowOneToFiveSchema = (isArabicLanguage: boolean) => ({
    commisionRate: Yup.number().required(isArabicLanguage ? 'مطلوب معدل العمولة' : "Commission rate is required"),
    invoiceDate: Yup.date().required(isArabicLanguage ? 'تاريخ الفاتورة مطلوب' : "Invoice date is required"),
    siibInvoiceId: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء الفاتورة' : "Please create invoice"),
    invoicePaid: Yup.boolean().oneOf([true], isArabicLanguage ? 'يجب التحقق من الفاتورة المدفوعة' : 'Invoice paid must be checked'),
    paymentDate: Yup.date().required(isArabicLanguage ? 'تاريخ الدفع مطلوب' : "Payment date is required"),
    proofOfPaymentReceviedDoc: Yup.string().required(isArabicLanguage ? 'مطلوب مستند إثبات الدفع' : "Proof of payment document is required"),
    salesCommisionRate: Yup.number().required(isArabicLanguage ? 'مطلوب معدل عمولة المبيعات' : "Sales commission rate is required"),
    creditNoteDate: Yup.date().required(isArabicLanguage ? 'تاريخ مذكرة الائتمان مطلوب' : "Credit note date is required"),
    creditNoteDocId: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء مذكرة الائتمان' : "Please create credit note"),
  });

  flowSevenAndEightInstallmentSchema = (isArabicLanguage: boolean) => {
    return {
      commisionRate: Yup.number().required(isArabicLanguage ? 'مطلوب معدل العمولة' : "Commission rate is required"),
      invoiceDate: Yup.date().required(isArabicLanguage ? 'تاريخ الفاتورة مطلوب' : "Invoice date is required"),
      siibInvoiceId: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء الفاتورة' : "Please create invoice"),
      invoicePaid: Yup.boolean().oneOf([true], isArabicLanguage ? 'يجب التحقق من الفاتورة المدفوعة' : 'Invoice paid must be checked'),
      paymentDate: Yup.date().required(isArabicLanguage ? 'تاريخ الدفع مطلوب' : "Payment date is required"),
      proofOfPaymentReceviedDoc: Yup.string().required(isArabicLanguage ? 'مطلوب مستند إثبات الدفع' : "Proof of payment document is required"),
      salesCommisionRate: Yup.number().required(isArabicLanguage ? 'مطلوب معدل عمولة المبيعات' : "Sales commission rate is required"),
      creditNoteDate: Yup.date().required(isArabicLanguage ? 'تاريخ مذكرة الائتمان مطلوب' : "Credit note date is required"),
      salesCreditNoteId: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء مذكرة الائتمان' : "Please create credit note"),
    }
  }

  flowSevenAndEightSchema = (isArabicLanguage: boolean) => {
    return {
      commisionRate: Yup.number().required(isArabicLanguage ? 'مطلوب معدل العمولة' : "Commission rate is required"),
      invoiceDate: Yup.date().required(isArabicLanguage ? 'تاريخ الفاتورة مطلوب' : "Invoice date is required"),
      siibInvoiceId: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء الفاتورة' : "Please create invoice"),
      invoicePaid: Yup.boolean().oneOf([true], isArabicLanguage ? 'يجب التحقق من الفاتورة المدفوعة' : 'Invoice paid must be checked'),
      paymentDate: Yup.date().required(isArabicLanguage ? 'تاريخ الدفع مطلوب' : "Payment date is required"),
      proofOfPaymentReceviedDoc: Yup.string().required(isArabicLanguage ? 'مطلوب مستند إثبات الدفع' : "Proof of payment document is required"),
      salesCommisionRate: Yup.number().required(isArabicLanguage ? 'مطلوب معدل عمولة المبيعات' : "Sales commission rate is required"),
      creditNoteDate: Yup.date().required(isArabicLanguage ? 'تاريخ مذكرة الائتمان مطلوب' : "Credit note date is required"),
      creditNoteDocId: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء مذكرة الائتمان' : "Please create credit note"),
      installment_attributes: Yup.array().of(
        Yup.object().shape(this.flowSevenAndEightInstallmentSchema(isArabicLanguage))
      ),
    }
  };

  flowNinechema = (isArabicLanguage: boolean) => {
    return {
      salesCommisionRate: Yup.number().required(isArabicLanguage ? 'مطلوب معدل عمولة المبيعات' : "Sales commission rate is required"),
      creditNoteDate: Yup.date().required(isArabicLanguage ? 'تاريخ مذكرة الائتمان مطلوب' : "Credit note date is required"),
      creditNoteDocId: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء مذكرة الائتمان' : "Please create credit note"),
      installment_attributes: Yup.array().of(
        Yup.object().shape({
          salesCommisionRate: Yup.number().required(isArabicLanguage ? 'مطلوب معدل عمولة المبيعات' : "Sales commission rate is required"),
          creditNoteDate: Yup.date().required(isArabicLanguage ? 'تاريخ مذكرة الائتمان مطلوب' : "Credit note date is required"),
          salesCreditNoteId: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء مذكرة الائتمان' : "Please create credit note"),
        })
      ),
    }
  };

  operationSchema = (checkInstallment: boolean) => {
    const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false

    const salesSchema = {
      salesCommisionRate: Yup.number().required(isArabic ? 'مطلوب معدل عمولة المبيعات' : "Sales commission rate is required"),
      creditNoteDate: Yup.date().required(isArabic ? 'تاريخ مذكرة الائتمان مطلوب' : "Credit note date is required"),
      creditNoteDocId: Yup.string().required(isArabic ? 'الرجاء إنشاء مذكرة الائتمان' : "Please create credit note"),
    }
    let schema = {}
    switch (true) {
      case (this.state.flow < 5):
        schema = this.flowOneToFiveSchema(isArabic);
        break;
      case (this.state.flow == 5 || this.state.flow == 6):
        schema = salesSchema
        break;
      case (this.state.flow == 7 || this.state.flow == 8):
        schema = this.flowSevenAndEightSchema(isArabic);
        break;
      case (this.state.flow == 9):
        schema = this.flowNinechema(isArabic)
        break;
    }
    return schema;
  };

  isStageNineAccordionOpen = (isExpanded: any) => {
    const queryParameter: any =
      typeof window !== "undefined" &&
      new URLSearchParams(window.location.search);
    const id: string = queryParameter.get("id");
    if (isExpanded === true && this.state.expandedAccordion === "p9") {
      return true;
    }
    if (id !== "" && this.state.expandedAccordion === "p9") {
      return true;
    }
    if (this.state.loggedInRole !== stageNineRoles.ADMIN && this.state.loggedInRole !== stageNineRoles.FINANCE) {
      return false;
    }
    return false;
  }

  handleStageNineAccordian = (isExpanded: boolean, accordionName: string) => {
    if (this.state.loggedInRole !== stageNineRoles.ADMIN && this.state.loggedInRole !== stageNineRoles.FINANCE) {
      this.setState({
        alertMessage: `Invoicing stage is not accessible to ${this.state.loggedInRole} role. Please login with other role. `,
        alertType: "warning",
        showAlert: true,
      });
    } else if (this.state.policyId !== "" && this.stageNineViewRights()) {
      this.setState({
        expandedAccordion: isExpanded ? accordionName : "", showAlert: false
      });
    } else {
      this.setState({
        expandedAccordion: isExpanded ? accordionName : "",
      });
    }
  };

  stageNineViewRights = () => {
    return this.state.hasViewPermissionList?.includes(
      this.state.loggedInRole
    ) as boolean;
  };

  setArabicLanguage = (value: boolean) => {
    const language = value === true ? "ar" : "en";
    window.localStorage.setItem("lang", language);
    this.setState({
      isArabicLenguage: value,
    });
  };

  uploadFileDoc = (file_key: string, fileData: any, formikRef: any, fieldName: string, insatllmentNo?: any) => {
    this.formikRef = formikRef;
    this.fieldName = fieldName;
    const formata = this.getFormDataForFileUpload(file_key, fileData, formikRef, insatllmentNo)
    const loginData = JSON.parse(localStorage.getItem('loginData') ?? '{}');
    const token = loginData.token || '';

    const stageNineHeader = {
      token
    };

    const uploadStageNineFile = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadFileApiCall = uploadStageNineFile.messageId;

    uploadStageNineFile.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(stageNineHeader)
    );
    uploadStageNineFile.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_policy_request/policy_requests/${this.state.policyId}/file_upload`
    );
    uploadStageNineFile.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );

    uploadStageNineFile.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formata
    );

    runEngine.sendMessage(uploadStageNineFile.id, uploadStageNineFile);
    return uploadStageNineFile.messageId;
  }
  removeFileStageNine = (fileId: number) => {    
    this.removeFileAPI = this.apiCall({
      contentType: 'application/json',
      endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/remove_file`,
      method: 'PUT',
      body: { data: { file_id: fileId } },
    })
}
removeFileAPIReceive = (message:any) => {
  if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.removeFileAPI !== null &&
      this.removeFileAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    )   {
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    /*istanbul ignore next*/
      if (responseJson?.data) {
        this.formikRef.current.setFieldValue(this.fieldName,{})
          this.setState({
          alertMessage:responseJson?.data?.message, 
          alertType:'warning',
          showAlert:true
          })
      }
  }
}
  getFormDataForFileUpload = (key: string, fileData: any, formikRef: any, insatllmentId: any) => {
    const formData = new FormData();
    formData.append("data[stage]", "9");
    key && formData.append("data[file_key]", key);
    fileData && formData.append("data[file]", fileData);
    insatllmentId && formData.append("data[installment_id]", insatllmentId);

    return formData
  }

  getStepName = getStageNameByNumber;

  getStepNumber = getStageNumberByName;

  createCreditNoteHandler = (values: any, insurance_company_id: any) => {
    this.setState({
      isCreditNoteBtnLoading: true,
      showAlert: true,
      alertType:"warning",
      alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
    });
    const userData = JSON.parse(window.localStorage.getItem('loginData') ?? '{}');
    const stageSevenData = JSON.parse(window.localStorage.getItem('stageSevenData') ?? '{}');
    const token = userData.token || '';
    const formData = new FormData();
    formData.append("data[commission_amount]",`${parseFloat(values?.commisionAmount||stageSevenData?.attributes?.revenue_siib_commission)}`);
    formData.append("data[file_key]", "credit_note_pdf");
    formData.append("data[recipient_type]", "sales");
    formData.append("data[credit_note_date]", values.creditNoteDate);
    formData.append("data[commission_rate]", values.salesCommisionRate);
    formData.append("data[insurance_company_id]", insurance_company_id);
    formData.append("data[deduct_siib_commission_with_vat]", stageSevenData.attributes?.commission?.data?.attributes?.deduct_commission_with_vat);

    this.createCreditNoteApiId = this.apiCall({
      contentType: 'FormData',
      method: 'POST',
      endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=9&flow=${this.state.flow}`,
      token: token || '',
      body: formData
    })
  }

  createCreditNoteForInstallments = (values: any, insurance_company_id: any, installmentIndex: any, formikRef: any) => {
    this.formikRef= formikRef;
    this.setState({
      isCreditNoteBtnLoading: true,
      currentInstallmentIndex: installmentIndex,
      showAlert: true,
      alertType:"warning",
      alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
    });
    const userData = JSON.parse(window.localStorage.getItem('loginData') ?? '{}');
    const stageSevenData = JSON.parse(window.localStorage.getItem('stageSevenData') ?? '{}');
    const token = userData.token || '';
    const formData = new FormData();
    formData.append("data[commission_amount]", values.installment_attributes[installmentIndex].commisionAmount ||  /* istanbul ignore next */stageSevenData.attributes?.installments?.data?.[installmentIndex]?.attributes?.commission?.data?.attributes?.commission_amount);
    formData.append("data[file_key]", "credit_note_pdf");
    formData.append("data[recipient_type]", "sales");
    formData.append("data[credit_note_date]", values.installment_attributes[installmentIndex].creditNoteDate);
    formData.append("data[commission_rate]", values.installment_attributes[installmentIndex].salesCommisionRate);
    formData.append("data[insurance_company_id]", insurance_company_id);
    formData.append("data[installment_no]", installmentIndex + 2);
    formData.append("data[deduct_siib_commission_with_vat]", stageSevenData.attributes?.installments?.data?.[installmentIndex]?.attributes?.commission?.data?.attributes?.deduct_commission_with_vat);

    this.createCreditNoteForInstallmentApiId = this.apiCall({
      contentType: 'FormData',
      method: 'POST',
      endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=9&flow=${this.state.flow}`,
      token: token || '',
      body: formData
    })
  }


  submitStageNine = (values: any, isDraft: boolean = false) => {
    if (this.state.isInstallmentPaid) {
      window.localStorage.setItem('InstallmentPaid', "true")
    }
    else {
      window.localStorage.setItem('InstallmentPaid', "false")
    }
    const userData = JSON.parse(window.localStorage.getItem('loginData') ?? '{}');
    const token = userData.token || '';
    this.setState({ isDraft: isDraft })
    const editPolicy = (this.state.policyId && this.props.stageEightData?.submitApiResponse.attributes?.is_next_stage_submitted) as boolean ?? false; // NOSONAR: typescript:S6582
    this.setState({ isStageSubmited: editPolicy })
    const isInstallment = (this.state.flow >= 7);
    this.stageNineSubmitApi = this.apiCall({
      contentType: !isInstallment ? 'application/json' : 'FormData',
      method: editPolicy ? 'PUT' : 'POST',
      endPoint: editPolicy ? `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=9&flow=${this.state.flow}` : `bx_block_policy_request/policy_requests?stage=9&id=${this.state.policyId}&flow=${this.state.flow}`,
      token: token || '',
      body: this.getFormData(values, this.state.flow, isDraft, editPolicy)
    })
  }

  payInstallment = () => {
    const userData = JSON.parse(window.localStorage.getItem('loginData') ?? '{}');
    const token = userData.token || '';
    this.payInstallmentApi = this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: `/bx_block_policy_request/policy_requests/${this.state.policyId}/track_requests?stage=7`,
      token: token || '',
      body: { data: { track_request: { stage_status: 'reject_stage', comment: 'pay instalments', current_stage: 'payment_review', add_installment: true } } }
    })
  }

  getPaidAmount = (stageSevenFormData: any) => {
    if (this.state.flow == 2 || this.state.flow == 4) {
      return (Number(stageSevenFormData?.attributes?.payment_insurance_infos?.data?.attributes?.paid_amount) - Number(stageSevenFormData?.attributes?.payment_insurance_infos?.data?.attributes?.refund_amount_to_customer))
    }
    else return Number(stageSevenFormData?.attributes?.payment_insurance_infos?.data?.attributes?.paid_amount);
  }

  generateInvoiceForInstallment = (formData: any, formikRef: any, insurance_company_id: any) => {
    this.setState({
      invoiceBtnLoding: true,
      showAlert: true,
      alertType:"warning",
      alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
    });
    this.formikRef = formikRef;
    const userData = JSON.parse(window.localStorage.getItem('loginData') ?? '{}');
    const token = userData.token || '';
    const stageSevenFormData = JSON.parse(localStorage.getItem('stageSevenData') ?? '{}');
    this.generateInvoiceForInstallmentApi = this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=9&flow=${this.state.flow}`,
      token: token || '',
      body: {
        data: {
          paid_amount: this.getPaidAmount(stageSevenFormData),
          file_key: "invoice_pdf",
          recipient_type: "customer",
          invoice_date: formData.invoiceDate,
          commission_rate: formData.commisionRate,
          insurance_company_id: insurance_company_id
        }
      }
    })
  }

  generateInvoiceForOtherInstallment = (formData: any, installmentNumber: number, formikRef: any, insurance_company_id: any) => {

    this.setState({
      invoiceBtnLoding: true,
      currentInstallmentIndex: installmentNumber ,
      showAlert: true,
      alertType:"warning",
      alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
    });
    this.formikRef = formikRef;
    const userData = JSON.parse(window.localStorage.getItem('loginData') ?? '{}');
    const token = userData.token || '';
    const stageSevenFormData = JSON.parse(localStorage.getItem('stageSevenData') ?? '{}');
    this.generateInvoiceForOtherInstallmentApi = this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=9&flow=${this.state.flow}`,
      token: token || '',
      body: {
        data: {
          paid_amount: Number(stageSevenFormData?.attributes?.installments?.data[installmentNumber]?.attributes?.installment_amount),
          file_key: "invoice_pdf",
          recipient_type: "customer",
          invoice_date: formData.installment_attributes[installmentNumber].invoiceDate,
          commission_rate: formData.installment_attributes[installmentNumber].commisionRate,
          insurance_company_id: insurance_company_id,
          installment_no: installmentNumber + 2
        }
      }
    })
  }

  trackStageNinePolicy = (goToStep: string, stageStatus: string, comment: string = '') => {
    const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
    const token = userData.token || '';
    const setpNumber = this.getStepNumber(this.state.currentRejectedStage)
    const queryParam = `?stage=${setpNumber}`
    this.setState({ isReject: !stageStatus.includes('next') })
    this.trackPolicyApi = this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: `/bx_block_policy_request/policy_requests/${this.state.policyId}/track_requests${!stageStatus.includes('next') ? queryParam : ''}`,
      token: token || '',
      body: { data: { track_request: { stage_status: stageStatus, ...(comment && { comment }), current_stage: goToStep } } }
    })
  }

  openNoteDialog = () => {
    this.setState({ showNoteDialog: true, isBtnVisible: false });
  };

  getSubheading = (isBtnVisible:boolean, t:any) => {
    return isBtnVisible ? `(${t('invoicing')} > ${t('complete')})` : `(${t('issuance')} > ${t('invoicing')})`
  }

  saveDraftData = async (formikRef: any) => {
    const { current: formikInstance } = formikRef;
    if (formikInstance) {
      window.localStorage.setItem('InstallmentPaid', "true")
      this.submitStageNine(formikInstance.values, true)
    }
  };

  handleStageNineRejectDialoug = (value: boolean) => {
    this.setState({ showRejectDialog: value });
  };

  setFlowOneToFourData = (values: any, isDraft: boolean, isUpdate: boolean, stageNineData: any) => {
   this.setState({lastSIIBInvoiceId:values.siibInvoiceId})
    const flowOneToFourSchema = {
      "data": {
        "invoicing": {
          "siib_commission": {
            "id": isUpdate ? stageNineData?.attributes?.siib_commission?.data[0]?.id : "",
            "commission_rate": values.commisionRate,
            "commission_amount": values.commisionAmount,
            "invoice_date": values.invoiceDate,
            "invoice_paid": values.invoicePaid,
            "payment_date": values.paymentDate,
            "siib_invoice_id":values.siibInvoiceId
          },
          "save_as_draft": isDraft ? "yes" : 'no',
          "sales_commission": {
            "id": isUpdate ? stageNineData?.attributes?.sales_commission?.data[0]?.id : "",
            "commission_rate": values.salesCommisionRate,
            "commission_amount": values.salesCommisionAmount
          },
          "credit_note": {
            "id": isUpdate ? stageNineData?.attributes?.credit_note?.data[0]?.id : "",
            "credit_note_date": values.creditNoteDate,
          },
          "balance": "0"
        },
        "revenue_sales_commission": values.salesCommisionAmount,
        "revenue_siib_commission": values.commisionAmount
      }
    }
    return flowOneToFourSchema
  }

  setFlowFourToSixData = (values: any, isDraft: boolean, isUpdate: boolean, stageNineData: any) => {
    const flowFourToSixSchema = {
      "data": {
        "invoicing": {
          "save_as_draft": isDraft ? "yes" : "no",
          "sales_commission": {
            "id": isUpdate ? stageNineData?.attributes?.sales_commission?.data[0]?.id : "",
            "commission_rate": values.salesCommisionRate,
            "commission_amount": values.salesCommisionAmount
          },
          "credit_note": {
            "id": isUpdate ? stageNineData?.attributes?.credit_note?.data[0]?.id : "",
            "credit_note_date": values.creditNoteDate,
          },
          "balance": "0"
        }
      }
    }
    return flowFourToSixSchema
  }

  appendIfFlowNotNine = (values: any, stageNineData: any, formData: any, flow: number, isUpdate: boolean,) => {
    if (flow != 9) {
      // siib_commissions
      formData.append(`data[invoicing][siib_commissions][0][commission_rate]`, values?.commisionRate);
      formData.append(`data[invoicing][siib_commissions][0][commission_amount]`, values?.commisionAmount);
      formData.append(`data[invoicing][siib_commissions][0][invoice_date]`, values?.invoiceDate);
      formData.append(`data[invoicing][siib_commissions][0][invoice_paid]`, values?.invoicePaid);
      formData.append(`data[invoicing][siib_commissions][0][payment_date]`, values?.paymentDate);
      isUpdate && formData.append(`data[invoicing][siib_commissions][0][id]`, stageNineData.attributes?.siib_commission.data[0]?.id);
      formData.append(`data[invoicing][siib_commissions][0][siib_invoice_id]`,values?.siibInvoiceId);
    }
  }

  installmentAttributeIteration = (values: any, stageNineData: any, formData: any, flow: number, isUpdate: boolean) => {
   let revenueSiibCommission = Number(values?.commisionAmount || 0);
   let revenueSalesCommission = Number(values?.salesCommisionAmount || 0);
    values?.installment_attributes.map((data: any, index: number) => {
      if (flow != 9) {
        // siib_commissions
        formData.append(`data[invoicing][siib_commissions][${index + 1}][commission_rate]`, data.commisionRate);
        formData.append(`data[invoicing][siib_commissions][${index + 1}][commission_amount]`, data.commisionAmount);
        formData.append(`data[invoicing][siib_commissions][${index + 1}][invoice_date]`, data.invoiceDate);
        formData.append(`data[invoicing][siib_commissions][${index + 1}][invoice_paid]`, data.invoicePaid ? 'yes' : 'no');
        formData.append(`data[invoicing][siib_commissions][${index + 1}][payment_date]`, data.paymentDate);
        flow == 8 && formData.append(`data[invoicing][siib_commissions][${index+1}][siib_invoice_id]`, data.siibInvoiceId);
        isUpdate && (stageNineData.attributes?.siib_commission.data[index + 1] != undefined) && formData.append(`data[invoicing][siib_commissions][${index + 1}][id]`, stageNineData.attributes?.siib_commission.data[index + 1]?.id);
        revenueSiibCommission += Number(data.commisionAmount);
        revenueSalesCommission += Number(data.salesCommisionAmount);
      }
      // sales_commissions
      formData.append(`data[invoicing][sales_commissions][${index + 1}][commission_rate]`, data.salesCommisionRate);
      formData.append(`data[invoicing][sales_commissions][${index + 1}][commission_amount]`, data.salesCommisionAmount);
      isUpdate && (stageNineData.attributes?.sales_commission.data[index + 1] != undefined) && formData.append(`data[invoicing][sales_commissions][${index + 1}][id]`, stageNineData.attributes?.sales_commission.data[index + 1]?.id);
      // credit_notes
      formData.append(`data[invoicing][credit_notes][${index + 1}][credit_note_date]`, data.creditNoteDate);
      formData.append(`data[invoicing][credit_notes][${index + 1}][credit_note_id]`, data.salesCreditNoteId);
      isUpdate && (stageNineData.attributes?.credit_note.data[index + 1] != undefined) && formData.append(`data[invoicing][credit_notes][${index + 1}][id]`, stageNineData.attributes?.credit_note.data[index + 1]?.id);
    });
    

    if (flow != 9) {
      formData.append(`data[revenue_siib_commission]`, (revenueSiibCommission).toFixed(2));
      formData.append(`data[revenue_sales_commission]`, (revenueSalesCommission).toFixed(2));
    }
  }

  setFlowSevenToNineData = (values: any, isDraft: boolean, isUpdate: boolean, stageNineData: any, flow: number) => {
    const formData = new FormData();

    this.appendIfFlowNotNine(values, stageNineData, formData, flow, isUpdate)
    // sales_commissions
    formData.append(`data[invoicing][sales_commissions][0][commission_rate]`, values?.salesCommisionRate);
    formData.append(`data[invoicing][sales_commissions][0][commission_amount]`, values?.salesCommisionAmount);
    isUpdate && formData.append(`data[invoicing][sales_commissions][0][id]`, stageNineData.attributes?.sales_commission.data[0]?.id);
    // credit_notes
    formData.append(`data[invoicing][credit_notes][0][credit_note_date]`, values?.creditNoteDate);
    formData.append(`data[invoicing][credit_notes][0][credit_note_id]`, values?.salesCreditNoteId);
    isUpdate && formData.append(`data[invoicing][credit_notes][0][id]`, stageNineData.attributes?.credit_note.data[0]?.id);

    formData.append("data[invoicing][balance]", '0');
    formData.append("data[invoicing][save_as_draft]", isDraft ? "yes" : "no");

    formData.append(`data[invoicing][credit_notes][0][credit_note_date]`, values?.creditNoteDate);
    isUpdate && formData.append(`data[invoicing][credit_notes][0][id]`, stageNineData.attributes?.credit_note.data[0]?.id);

    formData.append("data[invoicing][balance]", '0');
    formData.append("data[invoicing][save_as_draft]", isDraft ? "yes" : "no");
    this.installmentAttributeIteration(values, stageNineData, formData, flow, isUpdate)

    return formData
  }
  getFormData = (values: any, flow: number, isDraft: boolean, isUpdate: boolean = false) => {
    let data = {};
    const stageNineData = JSON.parse(window.localStorage.getItem('stageNineData') ?? '{}')
    if (flow < 5) {
      data = this.setFlowOneToFourData(values, isDraft, isUpdate, stageNineData)
    }
    else if (flow == 5 || flow == 6) {
      data = this.setFlowFourToSixData(values, isDraft, isUpdate, stageNineData)
    }
    else if (flow >= 7) {
      data = this.setFlowSevenToNineData(values, isDraft, isUpdate, stageNineData, flow);
    }
    return data
  }

  getPoicyOption = () => {
    const array = [
      { value: "info_gathering", label: this.props.t('info_gathering') },
      { value: "processing", label: this.props.t('processing') },
      { value: "awaiting", label: this.props.t('awaiting') },
      { value: "operations_review", label: this.props.t('operations_review') },
      { value: "sales_review", label: this.props.t('sales_review') },
      { value: "customer_review", label: this.props.t('customere_review') },
      { value: "payment_review", label: this.props.t('payment_review') },
      { value: "issuance", label: this.props.t('issuance') }
    ];
    return array;
  };


  getStageNineAlertMessage = (isDraft: boolean, reject: boolean = false) => {
    const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
    let alertMessage = "";
    if (isDraft) {
      alertMessage = isArabic ? "تم حفظ بيانات طلب المراجعة كمسودة!" : "Data has been saved as draft!"
    }
    else if (!isDraft && reject) {
      alertMessage = isArabic ? "لقد تم رفض طلبك إلى المرحلة المحددة" : "Your request has been reject to selected stage!"
    }
    else {
      alertMessage = isArabic ? "لقد تم الانتهاء من طلبك" : "Your request has been completed"
    }
    return alertMessage
  }

  setStageNineErrorMessage = (responseJson: any) => {
    if (responseJson.errors) {
      const errorMessage = typeof (responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error || "Something went wrong";
      this.setState({
        alertType: "error",
        alertMessage: errorMessage,
        showAlert: true,
        loading: false,
        currentRejectedStage: "",
        stageNineRejectReason: ""
      })
    }
    if (responseJson.error) {
      const errorMessage = typeof (responseJson.error) === "string" ? responseJson.error : responseJson.error.error || "Something went wrong";
      this.setState({
        alertType: "error",
        alertMessage: errorMessage,
        showAlert: true,
        loading: false,
        currentRejectedStage: "",
        stageNineRejectReason: ""
      })
    }
  }


  getCommission = (currentFlow: number, values: any, stageSevenData: any) => {
    if (currentFlow < 5 || currentFlow == 7 || currentFlow == 8) return Number(values.commisionAmount);
    else if(currentFlow == 6) return Number(stageSevenData?.attributes?.revenue_siib_commission)
      else {return Number(stageSevenData?.attributes?.commission?.data?.attributes?.commission_amount);}
  }
  
  handleHandOverNote = (value: boolean) => {
    this.setState({ showNoteDialog: value, handOverNoteValue:"", isBtnVisible: true })
  }

  getInstallmentData = (salesPerson: string) => {
    const stageSevenFormData = JSON.parse(localStorage.getItem('stageSevenData') ?? '{}');
    let installment: any = [{
      commisionRate: "",
      commisionAmount: "",
      invoiceDate: "",
      siibInvoiceId: "",
      invoicePaid: "",
      paymentDate: "",
      proofOfPaymentReceviedDoc: "",
      salesCommisionRate: "",
      salesCommisionAmount: "",
      creditNoteDate: "",
      salesCreditNoteId: "",
      salesPerson: salesPerson,
    }];

    stageSevenFormData?.attributes?.installments?.data.map((item: any) => {
      if (item.attributes.installment_paid || item.attributes.installment_paid === "yes") {
        installment.push({
          commisionRate: "",
          commisionAmount: "",
          invoiceDate: "",
          siibInvoiceId: "",
          invoicePaid: "",
          paymentDate: "",
          proofOfPaymentReceviedDoc: "",
          salesCommisionRate: "",
          salesCommisionAmount: "",
          creditNoteDate: "",
          salesCreditNoteId: "",
          salesPerson: salesPerson,
        })
      }
    })
    return installment;
  }

  handleHandOverNoteText = (inputText: string) => {
    this.setState({ handOverNoteValue: inputText })
  }

  checkInvoiceIsDisabled = (values: any, index: number) => {
    if ((values?.installment_attributes[index]?.commisionRate === "" ||
      values?.installment_attributes[index]?.commisionAmount === "" ||
      values?.installment_attributes[index]?.invoiceDate === ""
    ) || (values.installment_attributes[index]?.siibInvoiceId !== "")
    ) {
      return true;
    } else return false
  }
  checkCreateNoteIsDisabled = (values: any, index: number) => {
    if ((values?.installment_attributes[index]?.salesCommisionRate === "" ||
      values?.installment_attributes[index]?.salesCommisionAmount === "" ||
      values?.installment_attributes[index]?.creditNoteDate === ""
    ) || (values.installment_attributes[index]?.salesCreditNoteId !== "")
    ) {
      return true;
    } else return false
  }

  handleOkDialog = (value: string) => {
    this.trackStageNinePolicy('completed', 'next_stage', value)
  }

  createResponseData = (stageNineFormData: any, stageOneData: any, installment: any) => {
    const responseData = {
      commisionRate: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.commission_rate.replace(/%/g, "") || "",
      commisionAmount: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.commission_amount || "",
      invoiceDate: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.invoice_date || "",
      invoicePaid: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.invoice_paid || false,
      paymentDate: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.payment_date || "",
      /*istanbul ignore next*/proofOfPaymentReceviedDoc: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.upload_proof_of_payment_recevied,
      salesCommisionRate: stageNineFormData?.attributes?.sales_commission?.data[0].attributes.commission_rate || "",
      salesCommisionAmount: stageNineFormData?.attributes?.sales_commission?.data[0].attributes.commission_amount || "",
      creditNoteDate: stageNineFormData?.attributes?.credit_note?.data[0].attributes.credit_note_date || "",
      salesPerson: stageOneData?.sales?.label || "",
      installment_attributes: installment.slice(1),
      invoiceDoc: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.invoice || {},
      siibInvoiceId: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.siib_invoice_id || this.state.lastSIIBInvoiceId,
      creditNoteDoc: stageNineFormData?.attributes?.credit_note?.data[0].attributes.credit_note || {},
      creditNoteDocId: stageNineFormData?.attributes?.credit_note?.data[0].attributes.credit_note_id || "",
    }

    return responseData;
  }

  getFormInitialValues = () => {
    const stageOneData = JSON.parse(localStorage.getItem('stageOne') ?? '{}');
    const stageNineFormData = JSON.parse(localStorage.getItem('stageNineData') ?? '{}');
    const SiibData = [5, 6, 9];

    let installment: any = this.getInstallmentData(stageOneData?.sales?.label);
    !SiibData.includes(this.state.flow) && stageNineFormData?.attributes?.siib_commission?.data?.map((data: any, index: number) => {
      installment[index] = {
        ...installment[index],
        commisionRate: data.attributes.commission_rate?.replace(/%/g, "") || "",
        commisionAmount: data.attributes.commission_amount || "",
        invoiceDate: data.attributes.invoice_date || "",
        invoicePaid: data.attributes.invoice_paid,
        paymentDate: data.attributes.payment_date || "",
        invoiceDoc: data.attributes?.invoice || {},
        siibInvoiceId: data.attributes?.siib_invoice_id || "",
        proofOfPaymentReceviedDoc: data.attributes.upload_proof_of_payment_recevied || "",
      }

    })
    stageNineFormData?.attributes?.sales_commission?.data?.map((data: any, index: number) => {
      installment[index] = {
        ...installment[index],
        salesCommisionRate: data.attributes.commission_rate?.replace(/%/g, "") || "",
        salesCommisionAmount: data.attributes.commission_amount || "",
        salesPerson: stageOneData?.sales?.label || "",
      }

    })
    stageNineFormData?.attributes?.credit_note?.data?.map((data: any, index: number) => {
      installment[index] = {
        ...installment[index],
        creditNoteDate: data.attributes?.credit_note_date || "",
        salesCreditNoteId: data.attributes?.credit_note_id || "",
        creditNoteDoc: data.attributes?.credit_note || {},
      }

    })

    let responseData = this.createResponseData(stageNineFormData, stageOneData, installment)

    return responseData
  }

  apiCall(data: any) {
    const { contentType, method, endPoint, body } = data;
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": contentType,
      token: token,
    };
    contentType === 'FormData' && delete header["Content-Type"];

    const requestMessageForStageNine = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForStageNine.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageForStageNine.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForStageNine.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && contentType !== 'FormData' ?
      requestMessageForStageNine.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      ) : requestMessageForStageNine.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessageForStageNine.id, requestMessageForStageNine);
    return requestMessageForStageNine.messageId;
  }

  fileUploadReceive = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.uploadFileApiCall != null &&
      this.uploadFileApiCall ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const fileUploadStageSevenAPIResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (fileUploadStageSevenAPIResponse.data) {
        this.formikRef.current.setFieldValue(this.fieldName, fileUploadStageSevenAPIResponse.data?.uploaded_file)
        const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
        this.setState({
          showAlert: true,
          alertMessage: isArabic ? "تم تحميل الملف بنجاح" : "File successfully uploaded",
          alertType: "success"
        })
      } else if (fileUploadStageSevenAPIResponse.errors) {
        this.setStageNineErrorMessage(fileUploadStageSevenAPIResponse)
      }
    }
  }

  rejectPolicyApiReceive = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.trackPolicyApi !== null &&
      this.trackPolicyApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      /* istanbul ignore if */
      if (responseJson.data) {
        window.localStorage.setItem('InstallmentPaid', "true")
        if (this.state.isReject) {
          this.setState({
            showAlert: true,
            alertMessage: this.getStageNineAlertMessage(false, true),
            alertType: "success",
            showRejectDialog: false,
            loading: false
          })
          const stageNumber = this.getStepNumber(this.state.currentRejectedStage);
          window.localStorage.removeItem('stageEightData');
          window.localStorage.removeItem('stageNineData');
          setTimeout(() => {
            this.props.onReject(stageNumber)
          }, 700)
        }
        else {
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: this.getStageNineAlertMessage(false)
          })
          window.location.href = "/Requests";
        }
      } else {
        this.setStageNineErrorMessage(responseJson)
      }
    }
  }

  payInstallmentsApiReceive = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.payInstallmentApi !== null &&
      this.payInstallmentApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        window.localStorage.setItem('InstallmentPaid', "false")
        const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
        this.setState({
          showAlert: true,
          alertMessage: isArabic ? "لقد تم نقل طلبك لتقسيط الدفع" : "Your request has been moved for pay installment!",
          alertType: "success",
          showRejectDialog: false,
          loading: false
        })

        setTimeout(() => {
          this.props.onReject(7)
        }, 700)
      } else {
        this.setStageNineErrorMessage(responseJson)
      }
    }
  }

  stageNineSubmitApiReceive = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.stageNineSubmitApi !== null &&
      this.stageNineSubmitApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      /* istanbul ignore if */
      if (responseJson.data) {
        const isInstallmentPaid = JSON.parse(window.localStorage.getItem('InstallmentPaid') ?? 'true');
        if (this.state.isDraft && responseJson.data.attributes.save_as_draft) {
          this.setState({
            showAlert: true,
            alertMessage: this.getStageNineAlertMessage(true),
            alertType: "success",
            showRejectDialog: false,
            loading: false,
            isStageSubmited: true
          })
          window.localStorage.setItem('stageNineData', JSON.stringify({ ...responseJson.data, isStageSubmited: true }))

          return;
        }

        if (!isInstallmentPaid) {
          this.payInstallment()
          this.setState({
            showAlert: true,
            alertMessage: this.getStageNineAlertMessage(false),
            alertType: "success",
            showRejectDialog: false,
            showNoteDialog: false,
            loading: false,
            isDraft: false
          })
        } else {
          this.setState({
            showRejectDialog: false,
            loading: false,
            isDraft: false,
            showNoteDialog: true
          })
        }


        window.localStorage.setItem('stageNineData', JSON.stringify(responseJson.data))

        this.props.setStageData('stageNineData', responseJson.data);
      } else {
        this.setStageNineErrorMessage(responseJson)
      }
    }
  }
  generateInvoiceForInstallmentReceive = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.generateInvoiceForInstallmentApi !== null &&
      this.generateInvoiceForInstallmentApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({
          lastInvoicePath: responseJson.data.attributes.invoice_pdf.url,
          lastInvoicePathName: responseJson.data.attributes.invoice_pdf.file_name,
          isInvoiceIsCreated: true,
          invoiceBtnLoding: false,
          showAlert: true,
          alertMessage: ActiveLanguage() ? "تم إنشاء الفاتورة بنجاح" : "Invoice successfully generated!",
          alertType: "success"
        })
        this.formikRef.current.setFieldValue("invoiceDoc", responseJson.data.attributes.invoice_pdf);
        this.formikRef.current.setFieldValue("siibInvoiceId", String(responseJson.data.attributes.file_id));
      } else {
        this.setStageNineErrorMessage(responseJson)
      }
    }
  }
  createCreditNoteReceived = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createCreditNoteApiId !== null &&
      this.createCreditNoteApiId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({
          isCreditNoteBtnLoading: false,
          showAlert: true,
          alertMessage: ActiveLanguage() ? "تم إنشاء مذكرة الائتمان بنجاح!" : "Credit note successfully generated!",
          alertType: "success"
        })
        this.formikRef.current.setFieldValue("creditNoteDoc", responseJson.data.attributes.credit_note_pdf);
        this.formikRef.current.setFieldValue("creditNoteDocId", String(responseJson.data.attributes.file_id));
      } else {
        this.setStageNineErrorMessage(responseJson)
      }

    }
  }

  createCreditNoteForInstallmentReceived = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createCreditNoteForInstallmentApiId !== null &&
      this.createCreditNoteForInstallmentApiId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({
          isCreditNoteBtnLoading: false,
          showAlert: true,
          alertMessage: ActiveLanguage() ? "تم إنشاء مذكرة الائتمان بنجاح!" : "Credit note successfully generated!",
          alertType: "success"
        })
        this.formikRef.current.setFieldValue(`installment_attributes[${this.state.currentInstallmentIndex}].creditNoteDoc`, responseJson.data.attributes.credit_note_pdf);
        this.formikRef.current.setFieldValue(`installment_attributes[${this.state.currentInstallmentIndex}].salesCreditNoteId`, String(responseJson.data.attributes.file_id));
      } else {
        this.setStageNineErrorMessage(responseJson)
      }

    }
  }


  generateInvoiceForOtherInstallmentReceive = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.generateInvoiceForOtherInstallmentApi !== null &&
      this.generateInvoiceForOtherInstallmentApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({
          invoiceBtnLoding: false,
          showAlert: true,
          alertMessage: ActiveLanguage() ? "تم إنشاء الفاتورة بنجاح" : "Invoice successfully ganerated!",
          alertType: "success"
        })
        this.formikRef.current.setFieldValue(`installment_attributes[${this.state.currentInstallmentIndex}].invoiceDoc`, responseJson.data.attributes.invoice_pdf);
        this.formikRef.current.setFieldValue(`installment_attributes[${this.state.currentInstallmentIndex}].siibInvoiceId`, String(responseJson.data.attributes.file_id));
      } else {
        this.setStageNineErrorMessage(responseJson)
      }
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    this.removeFileAPIReceive(message)
    this.fileUploadReceive(message);
    this.rejectPolicyApiReceive(message);
    this.stageNineSubmitApiReceive(message);
    this.payInstallmentsApiReceive(message);
    this.generateInvoiceForInstallmentReceive(message);
    this.createCreditNoteReceived(message)
    this.generateInvoiceForOtherInstallmentReceive(message);
    this.createCreditNoteForInstallmentReceived(message)
  }

}
// Customizable Area End
