import * as Yup from 'yup';

function flowTwoObjInstallment(isArabicLanguage: boolean) {
  return Yup.array(
    Yup.object().shape({
      installment_amount: Yup.string().matches(/^\d+$/, (isArabicLanguage ? 'مبلغ القسط يقبل الأرقام فقط' : 'Installment amount only accept numbers')).required(isArabicLanguage ? 'مبلغ القسط مطلوب' : 'Installment amount is required'),
      due_date: Yup.string().required(isArabicLanguage ? "تاريخ الاستحقاق مطلوب" : 'Due date is required'),
      insurer_receipt_number: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? "مطلوب معرف إيصال التأمين" : 'Insurer receipt ID is required'), otherwise: Yup.string().nullable()}),
      payment_date: Yup.string().nullable().when('installment_paid', {is: "yes", then: Yup.string().required(isArabicLanguage ? "تاريخ الدفع مطلوب" : 'Payment date is required').nullable(), otherwise: Yup.string().nullable()}),
      installment_proof_of_payment: Yup.mixed().when('installment_paid', {is: "yes", then: Yup.mixed().required(isArabicLanguage ? "يرجى تحميل مستند إثبات الدفع" : 'Please upload proof of payment document'), otherwise: Yup.mixed().nullable()}),
    })
  )
}

function InsurerObjWithInstallments(isArabicLanguage: boolean) {
  return {
    paid_amount: Yup.string().matches(/^\d+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
    payment_recipient: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم" : 'Deduct commission is required.').nullable(),
    flowFieldObj: Yup.object().shape({
      insurance_receipt_number: Yup.string().required(isArabicLanguage ? "مطلوب رقم إيصال التأمين." : 'Insurer receipt number is required.').nullable(),
      payment_date: Yup.string().required(isArabicLanguage ? "تاريخ الدفع مطلوب" : 'Payment date is required.').nullable(),
      proof_of_payment: Yup.mixed().required(isArabicLanguage ? "يرجى تحميل مستند إثبات الدفع" : 'Please upload proof of payment document').nullable(),
      policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يجب التحقق من إصدار السياسة المطلوب" :'Policy release requested must be checked'),
      installment_attributes: flowTwoObjInstallment(isArabicLanguage)
    })
  }
}

function SiibObj(isArabicLanguage: boolean) {
  return {
    insurance_receipt_number: Yup.string().required(isArabicLanguage ? "رقم إيصال Siib مطلوب." : 'Siib receipt number is required.').nullable(),
    payment_date: Yup.string().required(isArabicLanguage ? "تاريخ الدفع مطلوب." : 'Payment date is required.').nullable(),
    proof_of_payment: Yup.string().required(isArabicLanguage ? 'مطلوب مستند إثبات الدفع' : "Proof of payment document is required"),

    payment_voucher_date: Yup.string().required(isArabicLanguage ? "تاريخ قسيمة الدفع مطلوب" : 'Payment voucher date is required'),
    payment_review_payment_voucher_id: Yup.string().required(isArabicLanguage ? "قسيمة الدفع مطلوبة" : "Payment Voucher is required"),
    policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يجب التحقق من إصدار السياسة المطلوب" :'Policy release requested must be checked'),
    insurer_recipient_number: Yup.string().required(isArabicLanguage ? "مطلوب رقم إيصال التأمين." : 'Insurer receipt number is required.').nullable(),
    payment_voucher_payment_date: Yup.string().required(isArabicLanguage ? "تاريخ قسيمة الدفع مطلوب." : 'Payment voucher date is required.').nullable(),
    proof_of_payment_voucher: Yup.string().required(isArabicLanguage ? 'مطلوب مستند إثبات الدفع' : "Proof of payment document is required"),
  }
}

function InsurerObj(isArabicLanguage: boolean) {
  return {
    paid_amount: Yup.string().matches(/^\d+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
    payment_recipient: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم." : 'Deduct commission is required.').nullable(),
    flowFieldObj: Yup.object().shape({
      insurance_receipt_number: Yup.string().required(isArabicLanguage ? "مطلوب رقم إيصال التأمين." : 'Insurer receipt number is required.').nullable(),
      payment_date: Yup.string().required(isArabicLanguage ? "تاريخ الدفع مطلوب" : 'Payment date is required.').nullable(),
      proof_of_payment: Yup.mixed().required(isArabicLanguage ? "يرجى تحميل مستند إثبات الدفع" : 'Please upload proof of payment document'),
      policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يجب التحقق من إصدار السياسة المطلوب" :'Policy release requested must be checked'),
    })
  }

}

function SiibObject(isArabicLanguage: boolean) {
  return {
    paid_amount: Yup.string().matches(/^\d+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
    payment_recipient: Yup.string().required(isArabicLanguage ? "مطلوب مستلم الدفع" : 'payment recipient is required.').nullable(),
    deduct_commission_from_payment: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم." : 'Deduct commission is required.').nullable(),
    flowFieldObj: Yup.object().shape(SiibObj(isArabicLanguage))
  }
}

function flowFiveObjInstallment(isArabicLanguage: boolean) {
  return Yup.array(
    Yup.object().shape({
      installment_amount: Yup.string().matches(/^\d+$/, (isArabicLanguage ? 'مبلغ القسط يقبل الأرقام فقط' : 'Installment amount only accept numbers')).required(isArabicLanguage ? 'مبلغ القسط مطلوب' : 'Installment amount is required'),
      due_date: Yup.string().required(isArabicLanguage ? 'تاريخ الاستحقاق مطلوب' : 'Due date is required'),
      insurance_receipt_number: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? "يرجى تحميل مستند إثبات الدفع" : 'Insurerance receipt ID is required'), otherwise: Yup.string().nullable()}),
      insurer_receipt_date: Yup.string().nullable().when('installment_paid', {is: "yes", then: Yup.string().required(isArabicLanguage ? "يرجى تحميل مستند إثبات الدفع" : 'Insurer receipt date is required').nullable(), otherwise: Yup.string().nullable()}),
      proof_of_payment_installment: Yup.mixed().when('installment_paid', {is: "yes", then: Yup.mixed().required(isArabicLanguage ? "يرجى تحميل مستند إثبات الدفع" : 'Please upload proof of payment document'), otherwise: Yup.mixed().nullable()}),
      payment_voucher_id: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? 'قسيمة الدفع مطلوبة' : 'Payment Voucher is required'), otherwise: Yup.string().nullable()}),
      insurer_receipt_number: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? "مطلوب معرف إيصال التأمين" : 'Insurer receipt ID is required'), otherwise: Yup.string().nullable()}),
      payment_date: Yup.string().nullable().when('installment_paid', {is: "yes", then: Yup.string().required(isArabicLanguage ? "تاريخ الدفع مطلوب" : 'Payment date is required').nullable(), otherwise: Yup.string().nullable()}),
      proof_of_payment_voucher_installment: Yup.mixed().when('installment_paid', {is: "yes", then: Yup.mixed().required(isArabicLanguage ? "يرجى تحميل مستند إثبات الدفع" : 'Please upload proof of payment document'), otherwise: Yup.mixed().nullable()}),
    })
  )
}

function SiibObjectInstallment(isArabicLanguage: boolean) {
  return {
    paid_amount: Yup.string().matches(/^\d+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
    payment_recipient: Yup.string().required(isArabicLanguage ? "مطلوب مستلم الدفع" : 'payment recipient is required.').nullable(),
    deduct_commission_from_payment: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم." : 'Deduct commission is required.').nullable(),
    flowFieldObj: Yup.object().shape({
      ...SiibObj(isArabicLanguage),
      installment_attributes: flowFiveObjInstallment(isArabicLanguage)
    })
  }
}

function SiibWithCommissionObj(isArabicLanguage: boolean) {
  return {
    commission_rate: Yup.string().matches(/^\d+$/, (isArabicLanguage ? 'معدل العمولة يقبل الأرقام فقط' : 'Commission rate only accept numbers')).required(isArabicLanguage ? 'معدل العمولة مطلوب' : 'Commission rate is required'),
    deduct_commission_with_vat: Yup.string().required(isArabicLanguage ? "مطلوب عمولة خصم مع ضريبة القيمة المضافة." : 'Deduct commission with vat is required.').nullable(),

    invoice_date: Yup.string().required(isArabicLanguage ? "تاريخ الفاتورة مطلوب" : 'Invoice date is required.').nullable(),
    receipt_date: Yup.string().required(isArabicLanguage ? "تاريخ الاستلام مطلوب" : 'Receipt date is required.').nullable(),
    siib_invoice_id: Yup.string().required(isArabicLanguage ? "مطلوب الفاتورة والاستلام" : 'Invoice and Receipt are require').nullable(),

    payment_voucher_date: Yup.string().required(isArabicLanguage ? "تاريخ الدفع مطلوب" : 'Payment date is required').nullable(),
    payment_voucher_id: Yup.string().required(isArabicLanguage ? 'قسيمة الدفع مطلوبة' : "Payment Voucher is required"),

    insurer_recipient_number: Yup.string().required(isArabicLanguage ? "مطلوب رقم إيصال التأمين." : 'Insurer receipt number is required.').nullable(),
    payment_date: Yup.string().required(isArabicLanguage ? "تاريخ الدفع مطلوب" : 'Payment date is required.').nullable(),
    proof_of_payment_voucher: Yup.string().required(isArabicLanguage ? 'مطلوب مستند إثبات الدفع' : "Proof of payment document is required"),

    policy_release_requested : Yup.string().matches(/^yes$/g, isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" :'Policy release requested must be checked'),
  }
}

function SiibWithCommissionObjInstallment(isArabicLanguage: boolean) {
  return {
    installment_amount: Yup.string().matches(/^\d+$/, (isArabicLanguage ? 'مبلغ القسط يقبل الأرقام فقط' : 'Installment amount only accept numbers')).required(isArabicLanguage ? 'مبلغ القسط مطلوب' : 'Installment amount is required'),
    due_date: Yup.string().required(isArabicLanguage ? 'تاريخ الاستحقاق مطلوب' : 'Due date is required'),
    commission_rate: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? 'معدل العمولة مطلوب' : 'Commission rate is required'), otherwise: Yup.string().nullable()}),
    commission_amount: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? 'معدل العمولة مطلوب' : 'Commission amount is required'), otherwise: Yup.string().nullable()}),
    deduct_commission_with_vat: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? "مطلوب عمولة الخصم" : 'Deduct commission with vat is required.'), otherwise: Yup.string().nullable()}),
    invoice_date: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? "تاريخ الفاتورة مطلوب" : 'Invoice date is required.'), otherwise: Yup.string().nullable()}),
    receipt_date: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? "تاريخ الاستلام مطلوب" : 'Receipt date is required.'), otherwise: Yup.string().nullable()}),
    siib_invoice_id: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().nullable().required( isArabicLanguage ? "مطلوب الفاتورة والاستلام" : 'Invoice and Receipt are require'), otherwise: Yup.string().nullable()}),
    payment_voucher_date: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? 'هوية إيصال SIIB مطلوبة' : 'Payment date is required'), otherwise: Yup.string().nullable()}),
    payment_review_payment_voucher_id: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? 'قسيمة الدفع مطلوبة' : "Payment review payment voucher is required"), otherwise: Yup.string().nullable()}),
    insurer_receipt_number: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? "مطلوب رقم إيصال التأمين." : 'Insurer receipt number is required.'), otherwise: Yup.string().nullable()}),
    payment_date: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? "تاريخ قسيمة الدفع مطلوب." : 'Insurer receipt date is required.'), otherwise: Yup.string().nullable()}),
    installment_proof_of_payment: Yup.string().when('installment_paid', {is: "yes", then:Yup.string().required( isArabicLanguage ? 'مطلوب مستند إثبات الدفع' : "Proof of payment document is required"), otherwise: Yup.string().nullable()}),
  }
}

function SiibObjectWithCommission(isArabicLanguage: boolean) {
  return {
    paid_amount: Yup.string().matches(/^\d+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
    payment_recipient: Yup.string().required(isArabicLanguage ? "مطلوب مستلم الدفع" : 'payment recipient is required.').nullable(),
    deduct_commission_from_payment: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم." : 'Deduct commission is required.').nullable(),
    flowFieldObj: Yup.object().shape(SiibWithCommissionObj(isArabicLanguage))
  }
}

function SiibDeductCommitionSixDownpayment(isArabicLanguage: boolean) {
  return {
    commission_rate: Yup.string().matches(/^\d+$/, (isArabicLanguage ? 'معدل العمولة يقبل الأرقام فقط' : 'Commission rate only accept numbers')).required(isArabicLanguage ? 'معدل العمولة مطلوب' : 'Commission rate is required'),
    deduct_commission_with_vat: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم" : 'Deduct commission with vat is required.').nullable(),

    invoice_date: Yup.string().required(isArabicLanguage ? "تاريخ الفاتورة مطلوب" : 'Invoice date is required.').nullable(),
    receipt_date: Yup.string().required(isArabicLanguage ? "تاريخ الاستلام مطلوب" : 'Receipt date is required.').nullable(),
    siib_invoice_id: Yup.string().required(isArabicLanguage ? "مطلوب الفاتورة والاستلام" : 'Invoice and Receipt are require').nullable(),

    payment_voucher_date: Yup.string().required(isArabicLanguage ? 'هوية إيصال SIIB مطلوبة' : 'Payment date is required').nullable(),
    payment_review_payment_voucher_id: Yup.string().required(isArabicLanguage ? 'قسيمة الدفع مطلوبة' : "Payment Voucher is required"),

    insurer_recipient_number: Yup.string().required(isArabicLanguage ? "مطلوب رقم إيصال التأمين." : 'Insurer receipt number is required.').nullable(),
    payment_voucher_payment_date: Yup.string().required(isArabicLanguage ? "تاريخ قسيمة الدفع مطلوب." : 'Payment voucher date is required.').nullable(),
    proof_of_payment_voucher: Yup.string().required(isArabicLanguage ? 'مطلوب مستند إثبات الدفع' : "Proof of payment document is required"),

    policy_release_requested : Yup.string().matches(/^yes$/g, isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" :'Policy release requested must be checked'),
  }
}

function SiibObjectWithCommissionInstallment(isArabicLanguage: boolean) {
  return {
    paid_amount: Yup.string().matches(/^\d+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
    payment_recipient: Yup.string().required(isArabicLanguage ? "مطلوب مستلم الدفع" : 'payment recipient is required.').nullable(),
    deduct_commission_from_payment: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم." : 'Deduct commission is required.').nullable(),
    flowFieldObj: Yup.object().shape({
      ...SiibDeductCommitionSixDownpayment(isArabicLanguage),
      installment_attributes: Yup.array(
        Yup.object().shape(SiibWithCommissionObjInstallment(isArabicLanguage))
      )
    })
  }
}

function SiibObjectStageSeven (isArabicLanguage: boolean) {
  return {
    flowFieldObj: Yup.object().shape({
      policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" :'Policy release requested must be checked'),
      refund_amount_to_customer: Yup.string().matches(/^\d+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),

      insurance_receipt_number: Yup.string().required(isArabicLanguage ? "مطلوب رقم إيصال التأمين." : 'Insurer receipt number is required.').nullable(),
      payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
      proof_of_payment: Yup.string().required(isArabicLanguage ? 'مطلوب مستند إثبات الدفع' : "Proof of payment document is required"),

      payment_review_payment_voucher_id: Yup.string().required(isArabicLanguage ? 'قسيمة الدفع مطلوبة' : "Payment Voucher is required"),
      payment_voucher_date: Yup.string().required(isArabicLanguage ? 'هوية إيصال SIIB مطلوبة' : 'Payment voucher date is required').nullable(),
    })

  }
}

const PaymentReviewSchema = (flow: number, isArabicLanguage: boolean = false) => {
  let schema = {};
  switch (flow) {
    case 1: schema = InsurerObj(isArabicLanguage);
    break;
    case 2: schema = InsurerObjWithInstallments(isArabicLanguage);
    break;
    case 3: schema = SiibObject(isArabicLanguage);
    break;
    case 4: schema = SiibObjectWithCommission(isArabicLanguage);
    break;
    case 5: schema = SiibObjectInstallment(isArabicLanguage);
    break;
    case 6: schema = SiibObjectWithCommissionInstallment(isArabicLanguage);
    break;
    case 7: schema = SiibObjectStageSeven(isArabicLanguage);
    break;
  }

  return schema;
}

export default PaymentReviewSchema;