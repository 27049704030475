// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import RevisionRequestController, { Props } from "./RevisionRequestController";
import { withTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import HorizontalStepper from "../../../../components/src/Common/HorizontalStepper";
import StageOne from "./RevisionStages/StageOne/StageOne.web";
import StageFour from "./RevisionStages/StageFour/StageFour.web";
import StageTwo from "./RevisionStages/StageTwo/StageTwo.web";
import StageThree from "./RevisionStages/StageThree/StageThree.web";
import CustomerReview from "./RevisionStages/StageSix/CustomerReview.web";
import PaymentReview from "./RevisionStages/PaymentReview/PaymentReview.web";
import InvoiceStage from "./RevisionStages/StageNine/InvoiceStage.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import Toster from "../../../../components/src/Common/Toster";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff"
        }
    }
});

// Customizable Area End
export class RevisionRequest extends RevisionRequestController {

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { t, classes } = this.props;

        /* istanbul ignore next */
        const getStepContent = () => {
            return [t('info_gathering'), t('processing'), t('awaiting'), t('operations_review'), t('sales_review'), t('customere_review'), t('payment_review'), t('issuance'), t('invoicing'), t('completed')]
        }

        // Customizable Area End
        return (
            // Customizable Area Start
            <div data-test-id="PolicyStageOne" className={this.state.isLoading ? classes.loadingState : classes.mainSection}>
                <ThemeProvider theme={theme} >
                    <Container maxWidth="xl">
                        {
                            this.state.customerName && (<div><strong>{t('new_policy_request')}</strong> {t('for')} <strong>{this.state.customerName}</strong></div>)
                        }
                        <HorizontalStepper getStepContent={getStepContent} activeStep={this.state.currentSteps} />
                        {
                        this.state.isLoading &&
                            <div className={classes?.rvRequestPageLoader}>
                                 <CircularProgress/>
                            </div>
                        }
                        <Toster
                         id="alertMessageStageFour"
                         tosterText={this.state.alertMessage}
                         showToster={this.state.showAlert}
                         tosterType={this.state.alertType}
                         handleCloseToster={this.handleToster}
                         />
                        {(this.state.currentSteps >= 1 && (this.state.policyId || this.state.revisionRequestId)) && (
                            <StageOne
                                currentStep={this.state.currentSteps}
                                rrStageOneData={this.state.stageOne}
                                isExpanded={this.state.currentSteps === 1}
                                isEdit={this.state.isEdit}
                                onNext={this.onNext}
                                setStageData={this.setStageData}
                                policyID={this.state.policyId}
                                invoiceAmount={this.state.invoiceAmount}
                                handleCsvImport ={this.handleNavigationToCSVImport}
                                revisionRequestID={this.state.revisionRequestId}
                                handoverNote={this.state.handoverNote}
                                currentSteps={this.state.currentSteps}
                            />
                        )}

                        {(this.state.currentSteps >= 2) && (
                            <StageTwo
                                rrStageOneData={this.state.stageOne}
                                rrStageTwoData={this.state.stageTwo}
                                isExpanded={this.state.currentSteps === 2}
                                isEdit={this.state.stageOne?.data?.attributes?.is_next_stage_submitted}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                setStageData={this.setStageData}
                                handoverNote={this.state.handoverNote}
                            />
                        )}
                        {(this.state.currentSteps >= 3) && (
                            <StageThree
                                isExpanded={this.state.currentSteps === 3}
                                isEdit={this.state.stageTwo?.data?.attributes?.is_next_stage_submitted}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                policyId={this.state.policyId}
                                setStageData={this.setStageData}
                                handOverNoteForAwaiting={this.state.handOverNoteForAwaiting}
                                rrStageOneData={this.state.stageOne}
                                rrStageTwoData={this.state.stageTwo}
                                rrStageThreeData={this.state.stageThree}
                            />
                        )}

                        {(this.state.currentSteps >= 4) && (
                            <StageFour
                                currentStep={this.state.currentSteps}
                                isExpanded={this.state.currentSteps === 4}
                                isEdit={this.state.isEdit}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                parentPolicyID={this.state.revisionRequestId}
                                handOverNoteForOperationsReview={this.state.handOverNoteForOperationsReview}
                                setStageData={this.setStageData}
                                policyValue={this.state.policyValue}
                                rrStageOneData={this.state.stageOne}
                                rrStageTwoData={this.state.stageTwo}
                                rrStageThreeData={this.state.stageThree}
                                rrStageFourData={this.state.stageFour}
                                revisionRequestID={this.state.revisionRequestId}
                                hitStageFourGetAPI= {this.getStageFourData}

                            />
                        )}

                        {(this.state.currentSteps >= 6) && (
                            <CustomerReview
                                isExpanded={this.state.currentSteps === 6}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                policyId={this.state.policyId}
                                isEdit={this.state.stageFour?.data?.attributes?.is_next_stage_submitted}
                                setStageData={this.setStageData}
                                rrStageFourData={this.state.stageFour}
                                handoverNote={this.state.handoverNoteForCustomerReview ||  this.state.handOverNoteForOperationsReview}
                                rrStageOneData={this.state.stageOne}
                                rrStageSixData={this.state.stageSix}
                                revisionRequestID={this.state.revisionRequestId}
                            />
                        )}

                        {(this.state.currentSteps >= 7) && (
                           <PaymentReview
                            isExpanded={this.state.currentSteps === 7}
                            onNext={this.onNext}
                            isEdit={this.state.stageFour?.data?.attributes?.is_stage_7_submitted}
                            setStageData={this.setStageData}
                            parentPolicyID={this.state.revisionRequestId}
                            rrStageOneData={this.state.stageOne}
                            rrStageFourData={this.state.stageFour}
                            rrStageSevenData={this.state.stageSeven}
                            onReject={this.onReject}
                            handOverNoteForPaymentReview={this.state.handOverNoteForPaymentReview}
                            deductedCommissionFields={{}}
                            updateFlowFourBalance={()=>{}}
                           />
                        )}

                        {(this.state.currentSteps >= 9) && (
                        <InvoiceStage
                                isExpanded={this.state.currentSteps === 9}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                policyId={this.state.policyId}
                                isEdit={this.state.stageSeven?.data?.attributes?.is_next_stage_submitted || this.state.stageNine?.data?.id}
                                setStageData={this.setStageData}
                                rrStageOneData={this.state.stageOne}
                                rrStageSevenData={this.state.stageSeven}
                                rrStageNineData={this.state.stageNine}
                                handoverNote={this.state.handOverNoteForInvoicing}
                                revisionRequestID={this.state.revisionRequestId}
                            />
                        )}
                    </Container>
                </ThemeProvider>
            </div >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const useStyles = ({
    mainSection: {
        "position": "relative"
    },
    loadingState: {
        "position": "relative",
        "pointer-events": "none",
        opacity: 0.5
    },
    rvRequestPageLoader:{
        display: 'flex',
        "position": "absolute",
        alignItems: 'center',
        justifyContent: 'center',
        left: '0',
        right: '0',
        margin: '0 auto',
        top: '50%',
        zIndex: '99'
    },
});

export default withStyles(useStyles as any)(withTranslation()(RevisionRequest));
// Customizable Area End
