// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");

export interface Props {
  navigation?: any;
  id?: string;
}

interface S {
  requestList: any;
  page: number;
  rowPerPage: number;
  pageCount: number;
  searchQuery: string;
  activeFilterTab: number;
  activeFilterName: string;
  showfilterDialog: boolean;
  filterCustomerList: any;
  filteredData: any;
  customer_ids: string;
  user_ids: string;
  types_of_insurances: string;
  request_type: string;
  isFilter: boolean;
  filterUserList: any;
}

interface SS {
  id: any;
}

export default class RequestListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllPolicyListId: any;
  filteredCustomerId: any;
  getAllFilterRequestListId: any;
  getFilterDataId: any;
  getAllFilterUserListId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      requestList: [],
      page: 1,
      rowPerPage: 10,
      pageCount: 1,
      searchQuery: "",
      activeFilterTab: 0,
      activeFilterName: "All",
      showfilterDialog: false,
      filterCustomerList: [],
      filteredData: {},
      customer_ids: "",
      user_ids: "",
      types_of_insurances: "",
      request_type: "",
      isFilter: false,
      filterUserList: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleRequestApiData = (message: Message, getApiId: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      getApiId != null &&
      getApiId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const requestResponce = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (requestResponce && !requestResponce.errors && requestResponce.data) {
        this.setState({
          requestList: requestResponce.data,
          pageCount: requestResponce.meta.total_page,
        });
      }
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.handleRequestApiData(message, this.getAllPolicyListId)
    this.handleRequestApiData(message, this.filteredCustomerId)

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null && apiRequestCallId == this.getAllFilterRequestListId) {
        this.funcGetAllCustomersResponse(responseJson);
      }
      else if (apiRequestCallId != null && apiRequestCallId == this.getFilterDataId) {
        this.getFilterResponse(responseJson);
      }
      else if (apiRequestCallId != null && apiRequestCallId == this.getAllFilterUserListId) {
        this.funcGetAllUsersResponse(responseJson);
      }
    }
  }

  async componentDidMount() {
    this.getAllPolicyList();
    this.funcForGetApiAllRequest();
    this.funcForGetUserRequest();
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {
    if (
      this.state.isFilter === false &&
      this.state.activeFilterTab === 0 &&
      (prevState.page !== this.state.page ||
        prevState.rowPerPage !== this.state.rowPerPage)
    ) {
      this.getFilteredData();
    }

    if (
      this.state.isFilter === false &&
      prevState.searchQuery !== this.state.searchQuery
    ) {
      this.getFilteredData();
    }

    if (
      this.state.isFilter === false &&
      this.state.activeFilterTab !== 0 &&
      (prevState.page !== this.state.page ||
        prevState.rowPerPage !== this.state.rowPerPage)
    ) {
      this.getAllPolicyList();
    }

    if (
      this.state.isFilter === false &&
      prevState.activeFilterName !== this.state.activeFilterName
    ) {
      this.getAllPolicyList();
    }

    if (
      this.state.isFilter === true &&
      (prevState.page !== this.state.page ||
        prevState.rowPerPage !== this.state.rowPerPage)
    ) {
      this.getApiFilteredList();
    }
  }
   handleNavigateToRevisionRequest =(id:number, isTable: boolean = false)=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationRevisionRequestMessage)        
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
    msg.addData(getName(MessageEnum.RevisionRequestIdPayload), JSON.stringify({id:id, isTable: isTable}));
    this.send(msg);
  }
  handleChangePage = (event: any, page: number) => {
    this.setState({ page: page });
  };

  handleRowPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ rowPerPage: parseInt(event.target.value) });
    this.setState({ page: 1 });
  };

  handleSearchCustomerQuery = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({
      searchQuery: event.target.value,
      activeFilterTab: 0,
      activeFilterName: "All",
      isFilter: false,
      page:1
    });
  };

  async getAllPolicyList() {
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";
    this.setState({ isFilter: false });

    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const customerApiPath = `bx_block_policy_request/policy_requests?page=${this.state.page}&per_page=${this.state.rowPerPage}&options=${this.state.activeFilterName}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPolicyListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      customerApiPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async getFilteredData() {
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";
    this.setState({ isFilter: false });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    let customerApiPath =this.state.searchQuery ? `bx_block_policy_request/policy_requests?page=${this.state.page}&per_page=${this.state.rowPerPage}&query=${this.state.searchQuery}`: `bx_block_policy_request/policy_requests?page=${this.state.page}&per_page=${this.state.rowPerPage}`

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filteredCustomerId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      customerApiPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTabChange = (event: any, newValue: number) => {
    this.setState({
      activeFilterTab: newValue,
      activeFilterName: event.target.textContent,
      isFilter: false,
    });
  };

  closefilterDialog = () => {
    this.setState({ showfilterDialog: false });
  };

  handleFilterDialog = async (
    customers: any,
    insurer: any,
    operation: any,
    insurance: any,
    requestValue: any
  ) => {

    this.setState(
      {
        customer_ids: customers.map((customer: any) => customer.value.toString()),
        user_ids: insurer.map((user: any) => user.value.toString()),
        request_type: requestValue.toString(),
        types_of_insurances: insurance.toString(),
      },
      () => {
        this.getApiFilteredList();
      }
    );

    this.closefilterDialog();
  };

  openFilterDialog = () => {
    this.setState({ showfilterDialog: true });
  };

  funcGetAllCustomersResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ filterCustomerList: responseJson.data });
    }
  };

  funcGetAllUsersResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({filterUserList : responseJson.data });
    }
  };

  funcForGetApiAllRequest = async () => {
    const userDetails = JSON.parse(localStorage.getItem("loginData") ?? "{}");
    const token = userDetails.token || "";

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllFilterRequestListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiGetAllCustomersList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  funcForGetUserRequest = async () => {
    const userDetails = JSON.parse(localStorage.getItem("loginData") ?? "{}");
    const token = userDetails.token || "";

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: token,
    };

    const userApi = "account_block/accounts?internal_users=yes"

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllFilterUserListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      userApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getApiFilteredList = async () => {
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";
    this.setState({ isFilter: true });

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFilterDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_policy_request/policy_requests?[filter][customer_ids]=${this.state.customer_ids}&[filter][user_id]=${this.state.user_ids}&[filter][types_of_insurance]=${this.state.types_of_insurances}&[filter][request_type]=${this.state.request_type}&page=${this.state.page}&per_page=${this.state.rowPerPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFilterResponse = async (responseJson: any) => {
    this.setState({
      requestList: responseJson.data,
      pageCount: responseJson.meta.total_page,
      activeFilterName: "All",
      activeFilterTab: 0,
      searchQuery: "",
    });
  };
}
// Customizable Area End
