import React from "react";
import { Container } from "@material-ui/core";
// @ts-ignore
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import StageFourController from "./StageFourController";
import StageFourForm from "../../../../components/src/Policy/StageFourForm";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

export default class StageFour extends StageFourController {
    
    render() {

        return (
                <ThemeProvider theme={theme} >
                    <Container maxWidth="lg" dir={typeof localStorage !== 'undefined' && (localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"}>
                    {/* @ts-ignore */}
                    <StageFourForm {...this.props}/>
                    </Container>
                </ThemeProvider>
        )
    }
}

const useStyles = ({
    primaryBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "height": "50px",
        "width": "150px",
        borderRadius: "10px"
    },
    accordianHeading: {
        fontWeight: 600
    },
});