import React from "react";
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    Typography
} from "@material-ui/core";
import { ErrorMessage } from "formik";
// @ts-ignore
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import StageSevenController from "../../../blocks/TaskAllocator/src/PolicyStage/StageSevenController";
import LabelWithIcon from "../Common/LabelWithIcon";
import TextInput from "../Common/TextInput";
import FileUpload from "../Common/FileUpload";
import InstallmentForm from "./StageSevenSiibForms/InstallmentForm";
import Toster from "../Common/Toster"
import DownloadFile from "../Common/DownloadFile";
export class StageSevenInsurerForm extends StageSevenController {

    render() {
        const { t, classes, values, setFieldValue, flowValue, installmentData, formikRef, errors, checkDisable } = this.props;

        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') || '{}')
        const stageSevenData = JSON.parse(window.localStorage.getItem('stageSevenData') || '{}')

        const dummyData = stageSixData?.attributes?.quotation_insurance_infos?.data?.filter((item: any) => {
            return item?.attributes?.approval === true
        })[0];
        const quotationAmount = Number(dummyData?.attributes?.quotation_amount)
        const checkRevisedSectionVisible=()=>{
            const {flowTwoData}=values && values.stageData;
            console.log("returning",777,flowTwoData)
            return !!(values?.paid_amount && flowTwoData?.payment_date && flowTwoData?.proof_of_payment_doc && flowTwoData?.insure_recipent_number)
        }
        const isInstallmentPaid = JSON.parse(window.localStorage.getItem('InstallmentPaid') || '{}')
        const showBalance=stageSixData?.attributes.is_next_stage_submitted && !stageSevenData?.attributes.save_as_draft
        const calculateBalance = (values: any) => {
            if (flowValue === 1) {
                return quotationAmount - Number(values?.paid_amount)
            }

            else if (flowValue === 2) {
                const refundedAmount = this.state.showRefund ? Number(values?.paid_amount) - quotationAmount : Number(0)
                return  Number(values?.paid_amount) - refundedAmount - quotationAmount
            }
            else if (flowValue === 7) {
                const dueAmount = quotationAmount - Number(values?.paid_amount)
                const instalmentPaid = values?.stageData?.flowSevenData?.installment_attributes.reduce((accumulator: any, current: any) => {
                    // Check if showInstallmentAmount is true and installment_paid is 'yes'
                    if (current.showInstallmentAmount && current.installment_paid === 'yes') {
                        window.localStorage.setItem("InstallmentPaid","true")
                        accumulator += Number(current.installment_amount);
                    }
                    return accumulator;
                }, 0) || 0

                return dueAmount - Number(instalmentPaid)
            }
            else {
                return 0
            }
        }

        return (
            <React.Fragment>
                <Toster
                    tosterText={this.state.alertMessage}
                    showToster={this.state.showAlert}
                    // @ts-ignore
                    tosterType={this.state.alertType}
                    handleCloseToster={() => this.setState({ showAlert: false })}
                />
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('insurer_receipt_number')} />
                        <TextInput
                            id={""}
                            disabled={checkDisable}
                            className={classes?.textInputStyle}
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.insure_recipent_number`}
                            onBlur={() => this.checkStep(values, flowValue as number)}
                            type="text"
                            data-test-id="insure_recipent_number"
                        />
                        <ErrorMessage
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.insure_recipent_number`}
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('payment_date')} />
                            <TextInput
                                data-test-id={`payment_date`}
                                disabled={checkDisable}
                                id={`payment_date`}
                                className={classes?.textInputStyle}
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_date`}
                                type={"date"}
                                onChange={(e: any) => {
                                    setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_date`, e?.target?.value)
                                }}
                                onBlur={() => this.checkStep(values, flowValue as number)}

                            />
                            <ErrorMessage
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_date`}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={4} >
                        <LabelWithIcon label={t('proof_of_payment')} />
                        <FileUpload
                            eachFileSizeInMB={10}
                            disabled={checkDisable}
                            maxFilesNumber={5}
                            fileName={(values && values?.stageData && flowValue && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.proof_of_payment_doc?.file_name) || '' }
                            filePath={(values && values?.stageData && flowValue && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.proof_of_payment_doc?.url) || ''}
                            data-test-id="proofOfPayment"
                            inputName={`stageData.flow${this.convertToWord(flowValue as number)}Data.proof_of_payment_doc`}
                            onChange={(event: any) => {
                                this.uploadFileDoc('proof_of_payment', event.target.files[0], formikRef, `stageData.flow${this.convertToWord(flowValue as number)}Data.proof_of_payment_doc`)
                            }}
                            onRemove={async() => {
                                setFieldValue(`stageData.flow${this.convertToWord(flowValue as number)}Data.proof_of_payment_doc`, "")
                                await this.removeFile(values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.proof_of_payment_doc?.id)
                            }}
                        />
                        <ErrorMessage
                            name={`stageData.flow${this.convertToWord(flowValue as number)}Data.proof_of_payment_doc`}
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <LabelWithIcon label={t('policy_release_requested')} />
                    <FormControlLabel
                        color="primary"
                        name={`stageData.flow${this.convertToWord(flowValue as number)}Data.policy_release_requested`}
                        control={<Checkbox color="primary"
                            disabled={checkDisable}
                            checked={values && values.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.policy_release_requested === "yes" ? true : false}
                            onChange={(e: any) => {
                                setFieldValue(`stageData.flow${this.convertToWord(flowValue as number)}Data.policy_release_requested`, e.target.checked ? "yes" : "no")
                            }
                            }
                        />
                        }

                        label={t('yes')}
                    />
                </Grid>
                {
                    Number(dummyData?.attributes.quotation_amount) > Number(values?.paid_amount) && Number(values?.paid_amount) > 0 && this.state.showInstallment && (
                        installmentData.map((item: any, index: number) => <div key='instalment' className={errors?.stageData?.flowSevenData?.installment_attributes?.[index] !== undefined ? classes?.installmentErrorAlignment : classes?.installmentAlignment} style={{ marginTop: 25 }}>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography style={{ fontWeight: 400 }}>{index + 2}{this.getSuffix(index + 2)} {t('installment')}</Typography>
                                {((!stageSixData?.attributes?.is_next_stage_submitted) || (stageSevenData?.attributes?.save_as_draft)) && <Button
                                    aria-controls="customized-menu"
                                    aria-haspopup="true"
                                    variant="contained"
                                    color="primary"
                                    style={{ backgroundColor: "white", color: "#007E98", boxShadow: "none" }}
                                    onClick={() => { this.props.removeInstallmentData(index) }}
                                >
                                    <CloseIcon />
                                </Button>}
                            </Grid>
                            <InstallmentForm installmentIndex={index} formikRef={formikRef} flowValue={flowValue} setFieldValue={setFieldValue} values={values} errors={errors} isExpanded={this.props.isExpanded} stageSixData={this.props.stageSixData} checkStepProps={this.checkStep}  dummyData={dummyData} checkDisable={checkDisable} />
                        </div>)
                    )
                }
                {Number(quotationAmount) < Number(values?.paid_amount) && this.state.formPartOne && checkRevisedSectionVisible() &&
                    (
                        <>
                            <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30, marginTop: 25, paddingTop: 25, borderTop: '3px solid #eaeaea' }}>
                            <Button
                                data-test-id="createRevisedPaymentVoucher"
                                disabled={this.checkBtnDisabledForInsurer(values,Number(quotationAmount) < Number(values?.paid_amount) && this.state.formPartOne )}
                                className={this.checkBtnDisabledForInsurer(values,Number(quotationAmount) < Number(values?.paid_amount) && this.state.formPartOne ) ? classes.grayBtn : classes.primaryRvBtn}
                                aria-haspopup="true"
                                aria-controls="customized-menu"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.generateRevisedPaymentVoucherForInsurer(values, dummyData, formikRef);
                                }}
                            >
                                {!this.state.voucherInsuranceBtnLoading && t('create_revised_payment_voucher')}
                                {this.state.voucherInsuranceBtnLoading && (<CircularProgress size={25} />)}
                            </Button>
                            </Grid>
                            <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_proof_of_payment_doc_id`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                            <Grid item xs={12} container>
                                <Grid item xs={4} style={{ paddingRight: '25px' }} >
                                <LabelWithIcon label={t('payment_voucher')} />
                                <DownloadFile
                                    data-test-id="txtInputInvoiceDoc"
                                    disabled={true}
                                    eachFileSizeInMB={10}
                                    maxFilesNumber={5}
                                    fileName={
                                        values?.stageData &&
                                        values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`] &&
                                        values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`][`revised_proof_of_payment_doc`] &&
                                        values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`][`revised_proof_of_payment_doc`]["file_name"] || ""
                                    }
                                    isEditPage={true}
                                    filePath={
                                        values?.stageData &&
                                        values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`] &&
                                        values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`][`revised_proof_of_payment_doc`] &&
                                        values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`][`revised_proof_of_payment_doc`]["url"] || ""
                                    }
                                    inputName={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_proof_of_payment_doc`}
                                />
                                </Grid>
                                <Grid item xs={4} style={{ paddingRight: '25px' }}>
                                    <LabelWithIcon label={t('payment_voucher_id')} />
                                    <TextInput
                                        id={""}
                                        className={classes?.textInputStyle}
                                        disabled={true}
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_proof_of_payment_doc_id`}
                                        type="text"
                                        data-test-id="paymentVoucherID"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container style={{ marginTop: 20 }}>
                                <Grid style={{ paddingRight: '25px' }} item xs={4}>
                                    <LabelWithIcon label={t('customer_receipt_number')} />
                                    <TextInput
                                        id={""}
                                        disabled={checkDisable}
                                        className={classes?.textInputStyle}
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.customer_receipt_number`}
                                        type="text"
                                        data-test-id="insurance_receipt_number"
                                        onChange={(e: any) => {
                                            setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.customer_receipt_number`, e.target.value)


                                        }}
                                        onBlur={() => this.checkStep(values, flowValue as number)}
                                    />
                                    <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.customer_receipt_number`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                                </Grid>
                                <Grid style={{ paddingRight: '25px' }} item xs={4}>
                                    <React.Fragment>
                                        <LabelWithIcon label={t('payment_date')} />
                                        <TextInput
                                            disabled={checkDisable}
                                            data-test-id={`paymentDate`}
                                            id={`paymentDateTxtInput`}
                                            className={classes?.textInputStyle}
                                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.customer_payment_date`}
                                            type={"date"}
                                            onChange={(e: any) => {
                                                setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.customer_payment_date`, e?.target?.value)

                                            }}
                                            onBlur={() => this.checkStep(values, flowValue as number)}
                                        />
                                        <ErrorMessage
                                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.customer_payment_date`}
                                            className="field-error"
                                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                    </React.Fragment>
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('proof_of_payment')} />
                                    <FileUpload
                                        eachFileSizeInMB={10}
                                        maxFilesNumber={5}
                                        disabled={checkDisable}
                                        fileName={values && values?.stageData && flowValue && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.revised_voucher_proof_of_payment_doc_0?.file_name || ''}
                                        data-test-id="proofOfPayment"
                                        inputName={`stageData.flow${this.convertToWord(flowValue as number)}Data.revised_voucher_proof_of_payment_doc_0`}
                                        filePath={values && values?.stageData && flowValue && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.revised_voucher_proof_of_payment_doc_0?.url || ''}
                                        onChange={(event: any) => {
                                            this.uploadFileDoc('proof_of_payment_refund_to_cust', event.target.files[0], formikRef, `stageData.flow${this.convertToWord(flowValue as number)}Data.revised_voucher_proof_of_payment_doc_0`)

                                        }}
                                        onRemove={async() => {
                                            setFieldValue(`stageData.flow${this.convertToWord(flowValue as number)}Data.revised_voucher_proof_of_payment_doc_0`, "")
                                            await this.removeFile(values?.stageData && flowValue && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.revised_voucher_proof_of_payment_doc_0?.id)
                                        }}
                                    />
                                    <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(flowValue as number)}Data.revised_voucher_proof_of_payment_doc_0`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                </Grid>
                            </Grid>
                        </>
                    )
                }
                <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 80 }}>
                    <Typography>{t("quotation_amount")}</Typography>
                    <Typography>SAR {quotationAmount}</Typography>
                </Grid>
                {(Number(quotationAmount) > Number(values?.paid_amount) && Number(values?.paid_amount) > 0 && this.state.showBalance) ?
                    <>
                        <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                            <Typography>{t('down_payment')}</Typography>
                            <Typography>-SAR {values?.paid_amount}</Typography>
                        </Grid>
                        {installmentData.map((item: any, index: any) =>
                            <Grid key='instalment' item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, marginBottom: 15 }}>
                                <Typography>{index + 2}{this.getSuffix(index + 2)} {t('installment')}</Typography>
                                <Typography>-SAR {values?.stageData?.flowSevenData?.installment_attributes?.[index]?.showInstallmentAmount ? values?.stageData?.flowSevenData?.installment_attributes?.[index]?.installment_amount : 0}</Typography>
                            </Grid>
                        )}
                    </> : <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                        <Typography>{t("payment_made_to_insurer")}</Typography>
                        <Typography>-SAR {this.state.showPaidTo ? values?.paid_amount : 0}</Typography>
                    </Grid>
                }
                {
                    Number(quotationAmount) < Number(values?.paid_amount) && this.state.showRefund && <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                        <Typography>{t("refunded_to_customer")}</Typography>
                        <Typography>SAR {Number(values?.paid_amount) - quotationAmount}</Typography>
                    </Grid>
                }
                <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 7, paddingTop: 15, borderTop: '3px solid #eaeaea' }}>
                    <Typography>{t("balance")}</Typography>
                    <Typography>{(this.state.showBalance && flowValue === 2 && calculateBalance(values) != 0) && '-'}SAR {this.state.showBalance ? calculateBalance(values).toFixed(2) : quotationAmount.toFixed(2)}</Typography>
                </Grid>
            </React.Fragment>
        )
    }
}

const useStyles = ({
    displayFlex: {
        display: "flex",
    },
    contactForm: { width: "100%" },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    voucherBorder: {
        paddingBottom: "25px",
        borderBottom: '3px solid #eaeaea'
    },
    borderAlignment: {
        paddingTop: "25px",
        borderTop: '3px solid #eaeaea'
    },
    installmentAlignment: {
        border: "3px solid #eaeaea",
        borderRadius: "10px",
        padding: "20px",
    },
    installmentErrorAlignment: {
        border: "1px solid #f44336",
        borderRadius: "10px",
        padding: "20px",
    },
    footerBorder: {
        paddingBottom: '15px', borderBottom: '3px solid #eaeaea'
    },
    grayBtn: {
        "text-transform": 'none',
        padding: 15,
        borderRadius: 5,
        width: '27%',
        fontSize: 15,
        backgroundColor: "#979090",
        color: "white"
    },
    primaryRvBtn: {
        "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": 'none',
        "padding": 15,
        "borderRadius": 5,
        "width": '27%',
        "fontSize": 15,
        "color": "white"
    }
});

export default withStyles(useStyles)(withTranslation()(StageSevenInsurerForm));