// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import ActiveLanguage from "../../../components/src/Common/ActiveLanguage";
export const configJSON = require("./config");

export interface Props {
  formType: string,
  t?:any,
  classes?:any
  navigation?: any
}
interface SS {
  id: any;
}

const enumFormAction = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
}

const enumAccordianBox = {
  medical : 'medical',
  contacts : 'contacts',
  document: 'document'
}

const enumErrorsState = {
  siib_comission_rate_error: 'siib_comission_rate_error',
  medical_insurances_attributes_error: 'medical_insurances_attributes_error',
  motor_insurance_attributes_error: 'motor_insurance_attributes_error',
  insurance_contacts_attributes_error: 'insurance_contacts_attributes_error',
  documents_attributes_error: 'documents_attributes_error',
}

const enumUploadAttachedment = {
  name: 'name',
  comment: 'comment',
}
const enumMotorInsurance = {
  insurance_class: "insurance_class",
  hcv_minimum_excess: "hcv_minimum_excess",
  lcv_minimum_execess: "lcv_minimum_execess",
  motor_premium: "motor_premium",
  motor_premium_minimum_value: "motor_premium_minimum_value",
  pickup_minimum_execess: "pickup_minimum_execess",
  sedan_suv_minimum_excess: "sedan_suv_minimum_excess",
  van_minimum_excess: "van_minimum_excess",
}

type errorsType = {
  siib_comission_rate_error: boolean,
  medical_insurances_attributes_error: boolean,
  motor_insurance_attributes_error: boolean,
  insurance_contacts_attributes_error: boolean,
  documents_attributes_error: boolean,
}


type S = {
  role: string,
  insuranceData: any,
  siib_comission_rate: any,
  motor_insurance: any,
  medical_insurances: any,
  deleted_medical_insurances: any[],
  insurance_contacts: any,
  deleted_insurance_contacts: any[],
  documents: any,
  deleted_insurer_documents: any[],
  errorSates: errorsType,
  checkErrors: boolean,
  showToster: boolean,
  showTosterText: string,
  showSuccessToster: boolean,
  successTosterText: string,
  openConfirmationDialog: boolean,
  dialogType: string,
  headingText: string,
  paragraphText: string,
  dialogItemIndex: number,
  openFormSave: boolean,
  formDialogHeadingText: string,
  formDialogParagraphText: string,
  formType: string,
  formDialogBtnOkText:string,
  formDialogBtnCancelText:string,
  leaveForm: boolean,
  isArabicLanguage:boolean,
  insurerId: string
};

export default class InsurerController extends BlockComponent<Props, S, SS> {
  addInsurerApi: any;
  EditInsurerApi: any;
  getInsurerApi:any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
  

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      insurerId: "",
      role: '',
      insuranceData: null,
      siib_comission_rate: {},
      medical_insurances: [
        {
          insurance_class: '',
          class_benefits: '',
        }
      ],
      deleted_medical_insurances:[],
      motor_insurance: {},
      insurance_contacts: [
        {
          first_name: '',
          last_name: '',
          position: '',
          phone_number: '',
          email: '',
        }
      ],
      deleted_insurance_contacts : [],
      documents: {
        data: [],
      },
      deleted_insurer_documents: [],
      errorSates: {
        siib_comission_rate_error: false,
        medical_insurances_attributes_error: false,
        motor_insurance_attributes_error: false,
        insurance_contacts_attributes_error: false,
        documents_attributes_error: false,
      },
      checkErrors: false,
      showToster: false,
      showTosterText: '',
      showSuccessToster: false,
      successTosterText: '',
      openConfirmationDialog: false,
      dialogItemIndex: 0,
      dialogType: '',
      headingText: '',
      paragraphText: '',
      openFormSave: false,
      formDialogHeadingText: '',
      formDialogParagraphText: '',
      formType: enumFormAction.ADD,
      formDialogBtnOkText: '',
      formDialogBtnCancelText: 'Cancel',
      leaveForm: false,
      isArabicLanguage:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  componentDidMount(): any {
    const queryParameter: any = new URLSearchParams(window.location.search);
    const id: string = queryParameter.get('id');
    const comingFrom: string = queryParameter.get('data');
    if(comingFrom === 'insurer') {
      if(id) {
        this.getDataByIdApiCall(id)
      }else{
        this.setState({formType: enumFormAction.ADD})
      }
    }   
    const loginData = JSON.parse(localStorage.getItem('loginData') || '{}');

    if(loginData){
      this.setState({role : loginData.role})
    }
}


viewSetStates = (data:any) => {
    const editObj = data.data.attributes;
    const medical:any = []
    const contact:any = []
    if(editObj['medical_insurances']['data']){
      editObj['medical_insurances']['data'].map((item:any, index:number) => {
         medical.push({...editObj['medical_insurances']['data'][index]['attributes']})
      })
    }
    editObj['insurance_contacts']['data'].map((item:any, index:number) => {
      contact.push({...editObj['insurance_contacts']['data'][index]['attributes']})
    })
    if(editObj['motor_insurance']['data'] !== null){
      const motor:any = editObj['motor_insurance']['data']['attributes']
  
      if(!Array.isArray(motor['insurance_class'])){
          const arr =  motor['insurance_class'].split(",")
          const objOfArr:any = [];
          arr.map((item:string) => {
           objOfArr.push({ value: item, label: item })
          })
          motor['insurance_class'] = objOfArr;
      }
      this.setState({ motor_insurance : motor })
    }

    const insurer:any =  {...editObj};
    if(Object.keys(insurer).length){
        delete insurer['medical_insurances']
        // delete insurer['motor_insurance']
        // delete insurer['siib_comission_rate']
        delete insurer['insurance_contacts']

        // insurer['documents'] && delete insurer['documents']
    }


    this.setState({ insuranceData : insurer })
    this.setState({ siib_comission_rate : editObj['siib_comission_rate'] })
    this.setState({ medical_insurances : medical})
    // this.setState({ motor_insurance : editObj['motor_insurance']['data']['attributes'] })
    this.setState({ insurance_contacts : contact })
    this.setState({ documents: editObj['documents'] })
}

ItemRemoveFromArr = (state: any[], index: number) => {
  /* istanbul ignore next */
  if(state?.length){
    const itemRemove = [...state]
    if (index > -1) { // only splice array when item is found
        itemRemove?.splice(index, 1); // 2nd parameter means remove one item only
    }
    return itemRemove;
  }
}

handleRemoveError = (arr: any) => {
  let removeError = false;
  arr.map((item: any) => {
      for (const key in item) {
          if (item[key] !== '') {
              removeError = true
          }
      }
  })
  return removeError;
}

handleRemoveErrorFromObj = (obj: any) => {
  let removeError = false;
  for (const key in obj) {
      if (obj[key] !== '') {
          removeError = true
      }
  }
  return removeError;
}

handleAddClass = (e: any) => {
  e.stopPropagation()
  const medicalCmp = {
      insurance_class: '',
      class_benefits: '',
  }
  this.setState((prevState: any) => ({
      medical_insurances: [...prevState.medical_insurances, medicalCmp]
  }))
}

handleCancelDialog = (type:string, index: number) => {
  if(type === enumAccordianBox.medical){
      const updateState = this.ItemRemoveFromArr(this.state.medical_insurances, index)
      if(this.state.medical_insurances[index]['id']){
        const deletedItems = [...this.state.deleted_medical_insurances]
        deletedItems.push(this.state.medical_insurances[index]['id'])
        this.setState({ deleted_medical_insurances: deletedItems })
      }
      // oldArray => [...oldArray, newElement]
      this.setState({ medical_insurances: updateState })
  }

  if(type === enumAccordianBox.contacts){
      const updateState = this.ItemRemoveFromArr(this.state.insurance_contacts, index)
      if(this.state.insurance_contacts[index]['id']){
        const deletedItems = [...this.state.deleted_insurance_contacts]
        deletedItems.push(this.state.insurance_contacts[index]['id'])
        this.setState({ deleted_insurance_contacts: deletedItems })
      }
      this.setState({ insurance_contacts: updateState })
  }

  if (type === enumAccordianBox.document) {
    const temp = this.ItemRemoveFromArr(this.state.documents.data, index)
    const updateState = {
      data: temp,
    }
    if (this.state.documents.data[index]['id']) {
      const deletedItems = [...this.state.deleted_insurer_documents]
      deletedItems.push(this.state.documents.data[index]['id'])
      this.setState({ deleted_insurer_documents: deletedItems })
    }

    this.setState({ documents: updateState })
  }
}

isAdditionalAttechmentIsVisible = () => {
    return (this.state.formType === enumFormAction.EDIT || this.state.formType === enumFormAction.ADD)
}

handleAddContact = (e: any) => {
  e.stopPropagation()
  const contactsCmp = {
      first_name: '',
      last_name: '',
      position: '',
      phone_number: '',
      email: '',
  }
  this.setState((prevState: any) => ({
      insurance_contacts: [...prevState.insurance_contacts, contactsCmp]
  }))
}

MedicalClose = (index:number) => {
    const isArabicLanguage = ActiveLanguage();
    this.setState({ dialogType: enumAccordianBox.medical })
    this.setState({ dialogItemIndex: index })
    this.setState({ headingText: isArabicLanguage ? 'حذف التأمين الطبي' : 'Delete Medical Insurance' })
    this.setState({ paragraphText: isArabicLanguage ? 'هل أنت متأكد أنك تريد حذف هذا التأمين الطبي؟' : 'Are you sure want to delete this Medical Insurance?' })
    this.setState({ openConfirmationDialog: true })
}

ContactClose = (index:number) => {
    const isArabicLanguage = ActiveLanguage();
    // this.handleContactCancel(index) 
    this.setState({ dialogType: enumAccordianBox.contacts })
    this.setState({ dialogItemIndex: index })
    this.setState({ headingText: isArabicLanguage ? 'حذف الصف' : 'Delete Class' })
    this.setState({ paragraphText: isArabicLanguage ? 'هل أنت متأكد أنك تريد حذف هذا الفصل؟' : 'Are you sure want to delete this class?' })
    this.setState({ openConfirmationDialog: true })
}

handleOkConfirmation = () => {
  this.handleCancelDialog(this.state.dialogType, this.state.dialogItemIndex);
  this.setState({ openConfirmationDialog: false })
}
handleCancelConfirmation = () => {
  this.setState({ openConfirmationDialog: false })
}

handleContactAddData = (key: string, value: string, index: number) => {
  const temp: any = [...this.state.insurance_contacts];
  temp[index][key] = value
  this.setState({ insurance_contacts: temp })



  const errorRemove = this.handleRemoveError(temp)
  if (errorRemove) {
      const errorState: any = { ...this.state.errorSates }
      errorState[enumErrorsState.insurance_contacts_attributes_error] = false;
      this.setState({ errorSates: errorState })
  }
}

handleSIIBCommission = (label: string, val: number) => {
  const temp = { ...this.state.siib_comission_rate };
  temp[label] = val;
  this.setState({ siib_comission_rate: temp })
  let errorRemove = false;
  for (const key in temp) {
      if (temp[key] !== '') {
          errorRemove = true
      }
  }
  if (errorRemove) {
      const errorStateTemp: any = { ...this.state.errorSates }
      errorStateTemp[enumErrorsState.siib_comission_rate_error] = false;
      // this.setState({ errorSates: errorStateTemp })
      this.setState({ errorSates: errorStateTemp })
  }
}

handleMedicalInsuranceClass = (value: string, index: number) => {
  const temp = [...this.state.medical_insurances];
  temp[index]['insurance_class'] = value;
  this.setState({ medical_insurances: temp })

  const errorRemove = this.handleRemoveError(temp)
  if (errorRemove) {
      const errorState: any = { ...this.state.errorSates }
      errorState[enumErrorsState.medical_insurances_attributes_error] = false;
      this.setState({ errorSates: errorState })
  }
}

handleMedicalClassBenefits = (value: string, index: number) => {
  const temp = [...this.state.medical_insurances];
  temp[index]['class_benefits'] = value;
  this.setState({ medical_insurances: temp })

  const errorRemove = this.handleRemoveError(temp)
  if (errorRemove) {
      const errorState: any = { ...this.state.errorSates }
      errorState[enumErrorsState.medical_insurances_attributes_error] = false;
      this.setState({ errorSates: errorState })
  }

}

handleMotorInsurance = (key: string, value: string) => {
  const temp: any = { ...this.state.motor_insurance }
  temp[key] = value;
  this.setState({ motor_insurance: temp })

  const errorRemove = this.handleRemoveErrorFromObj(temp)
  if (errorRemove) {
      const errorState: any = { ...this.state.errorSates }
      errorState[enumErrorsState.motor_insurance_attributes_error] = false;
      this.setState({ errorSates: errorState })
  }
}

handelImageUpload = async (e: any) => {

  // dispatch(imageUploade('reset'))
  const target = e.target
  const imageFile = await e.target.files[0]

  if (imageFile) {
 /* istanbul ignore next */
      try {
          const maxAllowedSize = 10 * 1024 * 1024;
          if (imageFile?.size > maxAllowedSize) {
              alert("Reduce the file size !")
              // Here you can ask your users to load correct file
              target.value = ''
          } else {
              // setSelectedimageName(compressedFile?.name)
              // setImgUrl(URL.createObjectURL(imageFile))
              if (this.state?.documents?.data?.length <= 10) {
                const newDoc = {
                  attributes: {
                    comment: "",
                    insurance_document: imageFile,
                    name: imageFile?.name
                  }
                }


                  const temp = {...this.state.documents};
                  temp?.data.push(newDoc);
                  this.setState({ documents: temp })

              } else {
                  alert("You can upload max 10 files !")
              }

          }
      } catch (error) {
          console.log(error);
      }

  }
}

handleImageInfo = (value: any, inputName: string, index: number) => {
  /* istanbul ignore next */
  if (inputName === enumUploadAttachedment.name) {
      const temp:any = {...this.state.documents};
      temp['data'][index]['attributes']['name'] = value;
      this.setState({ documents: temp })
  }

  /* istanbul ignore next */
  if (inputName === enumUploadAttachedment.comment) {
      const temp:any = {...this.state.documents};
      temp['data'][index]['attributes']['comment'] = value;
      this.setState({ documents: temp })

  }
}

handleRemoveDoc = (index: number) => {

  this.setState({ dialogType: enumAccordianBox.document })
  this.setState({ dialogItemIndex: index })
  this.setState({ headingText: 'Delete Attachment' })
  this.setState({ paragraphText: 'Are you sure want to delete this Attachment?' })
  this.setState({ openConfirmationDialog: true })
}

updateSIIBComissionRate = (apiData:any, errorState:any) => {
  const siib_comission_rate = apiData['siib_comission_rate']
  if (siib_comission_rate !== null && Object.keys(siib_comission_rate).length) {
      for (const key in siib_comission_rate) {
          if (siib_comission_rate[key] === '' || siib_comission_rate[key] === undefined) {
              errorState[enumErrorsState.siib_comission_rate_error] = true;
          }
      }
  } else {
      errorState[enumErrorsState.siib_comission_rate_error] = true;
  }
}

updateMedicalInsurance = (apiData:any, errorState:any) => {
  /* istanbul ignore next */
  this.state?.insuranceData && this.state?.insuranceData?.types_of_insurance?.map((item: any) => {
      if (item?.value === 'Medical') {
          const medical = apiData['medical_insurances_attributes'];
          if (medical.length) {
              medical.map((item: any) => {
                  if (item['insurance_class'] === '' || item['class_benefits'] === '') {
                      errorState[enumErrorsState.medical_insurances_attributes_error] = true;
                      // this.setState({ errorSates: errorState })
                  }
              })
          } else {
              errorState[enumErrorsState.medical_insurances_attributes_error] = true;
          }
      }
  })
}

updateMotorInsurance = (apiData:any, errorState:any) => {
  /* istanbul ignore next */
  this.state?.insuranceData && this.state?.insuranceData?.types_of_insurance?.map((item: any) => {
      if (item?.value === 'Motor') {
          const motor_insurance_attributes = apiData['motor_insurance_attributes']
          if (Object.keys(motor_insurance_attributes).length) {
              const allKeys = { ...this.state.errorSates }
              for (const key in enumMotorInsurance) {
                  if (motor_insurance_attributes[key] === '' || motor_insurance_attributes[key] === undefined) {
                      errorState[enumErrorsState.motor_insurance_attributes_error] = true;
                  }
                  if (motor_insurance_attributes['insurance_class'] === undefined || !motor_insurance_attributes['insurance_class']?.length) {
                      errorState[enumErrorsState.motor_insurance_attributes_error] = true;
                  }
              }
          } else {
              errorState[enumErrorsState.motor_insurance_attributes_error] = true;
          }

      }
  })
}

updateContactInsurance = (apiData: any, errorState: any) => {
  const contact = apiData['insurance_contacts_attributes'];
  if (contact.length) {
      contact.map((item: any) => {
          for (const key in item) {
            /* istanbul ignore next */
              if (item[key] === '' && key !== 'position') {
                  errorState[enumErrorsState.insurance_contacts_attributes_error] = true;
                  // this.setState({ errorSates: errorState })
              }
          }
      })
  } else {
      errorState[enumErrorsState.insurance_contacts_attributes_error] = true;
  }
}

ObjData = (apiData: any, formData: any, key: string) => {
  if (key === 'siib_comission_rate') {
      const nestedObj = {...apiData[key]}
      let conCatString = ''
      for(const nestedKey in nestedObj){
          conCatString += `'${nestedKey}':'${nestedObj[nestedKey]}',`
      }
      // formData.append(`data[${key}]`, `${apiData[key]}`)
      formData.append(`data[${key}]`, `{${conCatString}}`)
      // formData.append(`data[${key}]`, "{ 'Medical' => '20%',  'Motor' => '10%', 'Life' => '5%' }")
  }else{
      const nestedObj = {...apiData[key]}
      for(const nestedKey in nestedObj){
          if(this.state.formType !== enumFormAction.EDIT && nestedKey !== 'id' && nestedKey !== 'insurance_company_id'){
              formData.append(`data[${key}][${nestedKey}]`, nestedObj[nestedKey])
              // formData.append(nestedKey, nestedObj[nestedKey])
          } 
          
          if(this.state.formType === enumFormAction.EDIT){
              formData.append(`data[${key}][${nestedKey}]`, nestedObj[nestedKey])  
          }
      }
  }

}

arrayDataCognitive = (formData:any, nestedKey:string,nestedObj:any, key:string, i:number) => {
  /* istanbul ignore next */
  if(key === 'documents_attributes'){
    const tempObj = {...nestedObj[i]['attributes']}
    if(!tempObj['id']){
      // formData.append(`data[${key}][${i}][${'id'}]`, tempObj['id'])
      formData.append(`data[${key}][${i}][${'name'}]`, tempObj['name'])
      formData.append(`data[${key}][${i}][${'comment'}]`, tempObj['comment'])
      formData.append(`data[${key}][${i}][${'insurance_document'}]`, tempObj['insurance_document'])
    }
  }else{
      formData.append(`data[${key}][${i}][${nestedKey}]`, nestedObj[i][nestedKey])
  }
}

arrayData = (apiData: any, formData: any, key: string) => {
  for (let i = 0; i < apiData[key].length; i++) {
      const nestedObj = { ...apiData[key] }
      if(key === 'documents_attributes'){
        this.arrayDataCognitive(formData,'attributes',nestedObj,key,i)
      }else{
        for (const nestedKey in nestedObj[i]) {
            // formData.append(nestedKey, nestedObj[nestedKey])
            this.arrayDataCognitive(formData,nestedKey,nestedObj,key,i)
        }
      }
  }
}

arrayDataMedicalInsurance = (apiData: any, formData: any, key: string) => {
  const nestedObj = {...apiData[key]}
  for (let i = 0; i < apiData[key].length; i++) {
      for(const _nestedKey in nestedObj){
        /* istanbul ignore next */
        if(this.state.formType === enumFormAction.EDIT && nestedObj[i]['id']){
          formData.append(`data[${key}][${i}][id]`, nestedObj[i]['id'])
        }
        if(nestedObj[i]['insurance_class'] !== '' && nestedObj[i]['class_benefits'] ){
          formData.append(`data[${key}][${i}][insurance_class]`, nestedObj[i]['insurance_class'])
          formData.append(`data[${key}][${i}][class_benefits]`, nestedObj[i]['class_benefits'])
        }
      }
    }
}

changeDataToFromData = (formData:any, apiData: any, _errorState: any) => {
  for(const key in apiData){
      if(typeof apiData[key] === 'object' && Object.keys(apiData[key] || {}).length && !Array.isArray(apiData[key]) ){
          this.ObjData(apiData, formData, key)
  
      }else if (Array.isArray(apiData[key])){
          this.setMedicalInsuranceAttributes(apiData, formData, key);
      } else {
          if (this.state.formType !== enumFormAction.EDIT && key !== 'id' && key !== 'insurer_id') {
              formData.append(`data[${key}]`, apiData[key])
          }
          
          if(this.state.formType === enumFormAction.EDIT){
              formData.append(`data[${key}]`, apiData[key])
          }
      }

  }

  return formData;
}

setMedicalInsuranceAttributes = (apiData:any, formData:any, key:string) => {
  if(key === 'medical_insurances_attributes') {
    this.arrayDataMedicalInsurance(apiData, formData, key)
  } else {
      this.arrayData(apiData, formData, key)
  }
}

/* istanbul ignore next */
addInsurerCustomization = (apiData:any) => {
  apiData['segment'] = apiData['segment']?.map((segment: any) => segment.value).join(',');
  apiData['types_of_insurance'] = apiData['types_of_insurance'].map((item: any) => item.value).join(',');
  apiData['deleted_medical_insurances'] = apiData['deleted_medical_insurances']?.map((item: any) => item).join(',');
  apiData['deleted_insurance_contacts'] = apiData['deleted_insurance_contacts']?.map((item: any) => item).join(',');
  apiData['deleted_insurer_documents'] = apiData['deleted_insurer_documents']?.map((item: any) => item).join(',');
  
  if (Array.isArray(apiData['motor_insurance_attributes']['insurance_class'])) {
      apiData['motor_insurance_attributes']['insurance_class'] = apiData['motor_insurance_attributes']['insurance_class']?.map((segment: any) => segment.value).join(',');
  }
if(!apiData['medical_insurances_attributes'].length){
delete apiData['medical_insurances_attributes']
}
  if (!apiData['documents_attributes'].length) {
    delete apiData['documents_attributes']
}
  if (!apiData['deleted_medical_insurances'].length) {
      delete apiData['deleted_medical_insurances']
  }
  if (!apiData['deleted_insurance_contacts'].length) {
      delete apiData['deleted_insurance_contacts']
  }
  if (!apiData['deleted_insurer_documents'].length) {
      delete apiData['deleted_insurer_documents']
  }
}

/* istanbul ignore next */
handleAddInsurer = () => {
        const data = {
          medical_insurances_attributes: this.state.medical_insurances,
          siib_comission_rate: this.state.siib_comission_rate,
          motor_insurance_attributes: this.state.motor_insurance,
          documents_attributes: this.state.documents.data,
          insurance_contacts_attributes: this.state.insurance_contacts,
          deleted_insurance_contacts: this.state.deleted_insurance_contacts,
          deleted_medical_insurances: this.state.deleted_medical_insurances,
          ...this.state.insuranceData,
          deleted_insurer_documents: this.state.deleted_insurer_documents
        }
        this.setState({ checkErrors: !this.state.checkErrors })

        const errorState: any = { ...this.state.errorSates };
        this.updateMedicalInsurance(data, errorState)
        this.updateSIIBComissionRate(data, errorState)
        this.updateContactInsurance(data, errorState)
        this.updateMotorInsurance(data, errorState)
        this.setState({ errorSates: errorState })
        
/* istanbul ignore next */
        if (errorState[enumErrorsState.insurance_contacts_attributes_error] || errorState[enumErrorsState.medical_insurances_attributes_error] || errorState[enumErrorsState.motor_insurance_attributes_error] || errorState[enumErrorsState.siib_comission_rate_error]) {
          const  isArabicLanguage = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
          this.setState({ showTosterText: isArabicLanguage ? 'يرجى ملء جميع الحقول المطلوبة' : 'Please fill all the required fields' })
          this.setState({ showToster: true })
        } else {
          const formData = new FormData();
          this.addInsurerCustomization(data)
        this.changeDataToFromData(formData, data, errorState)

      if(this.state.formType === enumFormAction.EDIT){
        const queryParameter: any = new URLSearchParams(window.location.search);
        const insurerId: string = queryParameter?.get('id')
        const id = this.state.insurerId || insurerId;
        this.EditInsurerApiCall(formData,  id)
      }else{
        this.AddInsurerApiCall(formData)
      }
  }
}



handleCancel = () => {
  const isArabicLanguage =  (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
  this.setState({openFormSave: true, isArabicLanguage:isArabicLanguage})
  this.setState({leaveForm: true})
  this.setState({formDialogBtnOkText: isArabicLanguage ? 'يترك' : 'Leave'})
  this.setState({formDialogHeadingText: isArabicLanguage ? "هل أنت متأكد؟" : 'Are you sure?'})
  this.setState({formDialogParagraphText: isArabicLanguage ? "إذا تركت هذه الصفحة قبل الحفظ، فسيتم فقدان جميع البيانات الموجودة على هذه الصفحة." : 'If you leave this page before saving, all data on this page will be lost.'})
}

handleSave = () => {
  const isArabicLanguage =  (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
  if(this.state.formType === enumFormAction.EDIT){
    this.setSaveInsurerContant(isArabicLanguage);
  }else{
    this.setAddInsurerContant(isArabicLanguage);
  }
}

setSaveInsurerContant = (isArabicLanguage:boolean) =>{
  this.setState({openFormSave: true, isArabicLanguage:isArabicLanguage})
  this.setState({formDialogBtnOkText: isArabicLanguage ? "يحفظ" : 'Save'})
  this.setState({formDialogHeadingText: isArabicLanguage ? "حفظ التغييرات" : 'Save Changes'})
  this.setState({formDialogParagraphText: isArabicLanguage ? "هل تريد حفظ التغيرات؟" : 'Do you want to save the changes?'})
}
setAddInsurerContant  = async(isArabicLanguage:boolean) =>{
 this.setState({ checkErrors: !this.state.checkErrors })
 const apiData = {
     ...this.state.insuranceData,
     siib_comission_rate: this.state.siib_comission_rate,
     medical_insurances_attributes: this.state.medical_insurances,
     motor_insurance_attributes: this.state.motor_insurance,
     insurance_contacts_attributes: this.state.insurance_contacts,
     documents_attributes: this.state.documents.data,
     deleted_medical_insurances: this.state.deleted_medical_insurances,
     deleted_insurance_contacts: this.state.deleted_insurance_contacts,
     deleted_insurer_documents: this.state.deleted_insurer_documents
 }
 
 const errorState: any = { ...this.state.errorSates };
 this.updateSIIBComissionRate(apiData, errorState)
 this.updateMedicalInsurance(apiData, errorState)
 this.updateMotorInsurance(apiData, errorState)
 this.updateContactInsurance(apiData, errorState)

 this.setState({ errorSates: errorState })
 
/* istanbul ignore next */
 if (errorState[enumErrorsState.insurance_contacts_attributes_error] || errorState[enumErrorsState.medical_insurances_attributes_error] || errorState[enumErrorsState.motor_insurance_attributes_error] || errorState[enumErrorsState.siib_comission_rate_error]) {
   const  isArabicLanguage = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
     this.setState({ showToster: true })
     this.setState({ showTosterText: isArabicLanguage ? 'يرجى ملء جميع الحقول المطلوبة' : 'Please fill all the required fields' })
 } else {
   this.setState({openFormSave: true})
    this.setState({formDialogBtnOkText: isArabicLanguage ? "يضيف" : 'Add'})
    this.setState({formDialogHeadingText: isArabicLanguage ? "أضف شركة التأمين":'Add insurer'})
    this.setState({formDialogParagraphText: isArabicLanguage ? "هل أنت متأكد أنك تريد إضافة شركة التأمين هذه؟" : 'Are you sure you want to add this insurer?'})
  }
  }

formSaveBtn = () => {
  if(this.state.leaveForm){
      this.setState({openFormSave: false})
      this.setState({leaveForm: false})
      window.location.href = "/Insurer"
      /* istanbul ignore next */
  }else{
      this.handleAddInsurer()
      this.setState({openFormSave: false,leaveForm:false})
  }
}

formCancelBtn = () => {
  this.setState({openFormSave: false,leaveForm:false})
}


async getDataByIdApiCall(id:any) {
  this.getInsurerApi = this.apiCall({
    // contentType: 'multipart/form-data',
    method: 'GET',
    endPoint: `bx_block_insurance_companies/insurance_companies/${id}`,
    // body: data
  })
}

async AddInsurerApiCall(data:any) {
  this.addInsurerApi = this.apiCall({
    // contentType: 'multipart/form-data',
    method: 'POST',
    endPoint: 'bx_block_insurance_companies/insurance_companies',
    body: data
  })
}

async EditInsurerApiCall(data:any, id:string) {
  this.EditInsurerApi = this.apiCall({
    // contentType: 'multipart/form-data',
    method: 'PUT',
    endPoint: `bx_block_insurance_companies/insurance_companies/${id}`,
    body: data
  })
}

getInsurerApiReceive = (apiRequestCallId:any,responseJson:any,errorReponse:any) => {
  if(apiRequestCallId === this.getInsurerApi){
    if (responseJson) {
      this.viewSetStates(responseJson)
      this.setState({formType: enumFormAction.VIEW})
    } else {
     console.log("errorReponse :", errorReponse)
    //  Insurer
     this.setState({showToster: true})
     this.setState({ showTosterText: errorReponse })
    }
    
  }
}
addInsurerApiReceive = (apiRequestCallId:any,responseJson:any,errorReponse:any) => {
  const isArabicLanguage = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
  if (apiRequestCallId === this.addInsurerApi) {
    if (responseJson.data) {
      const englishTxt = "Insurer Added";
      const arabicTxt = "وأضافت شركة التأمين";
      const data = {
        englishTxt:englishTxt,
        arabicTxt:arabicTxt,
        page:"Insurer",
        alertType:"success"
      }
      window.localStorage.setItem('alertDetail',JSON.stringify(data))
      this.setState({showSuccessToster: true})
      this.setState({successTosterText: isArabicLanguage ? arabicTxt : englishTxt})
      window.location.href = "/Insurer"
      
    } else {
      const errorMessage = typeof(responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error  || "Something went wrong, Please try again!";
      this.setState({showToster:true,showTosterText:errorMessage})
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }
}
EditInsurerApiReceive = (apiRequestCallId:any,responseJson:any,errorReponse:any) => {
  if (apiRequestCallId === this.EditInsurerApi) {
    if (responseJson) {
      const englishTxt = "Insurer updated!";
      const arabicTxt = "تم تحديث شركة التأمين";
      const data = {
        englishTxt:englishTxt,
        arabicTxt:arabicTxt,
        page:"Insurer",
        alertType:"success"
      }
      window.localStorage.setItem('alertDetail',JSON.stringify(data))
      const isArabicLanguage = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
      this.setState({showSuccessToster: true})
      this.setState({successTosterText: isArabicLanguage ? arabicTxt : englishTxt})
      window.location.href = "/Insurer"
      
    } else {
      console.log("errorReponse :", errorReponse)
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }
}

getNavigationParams =(message:any)=>{
  if(getName(MessageEnum.NavigationPayLoadMessage)===message.id){
    const params = message.getData(getName(MessageEnum.InsurerDetailIdPayload))
    const insurerId = String(params)
    this.setState({insurerId:insurerId})
    const id = insurerId;
    if(id) {
        this.getDataByIdApiCall(id)
    }else{
        this.setState({formType: enumFormAction.ADD})
    }
  }
}
async receive(from: string, message: Message) {
  // Customizable Area Start
  this.getNavigationParams(message)
  /* istanbul ignore next */
  if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
    const _userName = message.getData(getName(MessageEnum.LoginUserName));

    const _password = message.getData(getName(MessageEnum.LoginPassword));

    const _countryCode = message.getData(
      getName(MessageEnum.LoginCountryCode)
    );
/* istanbul ignore next */
  } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
/* istanbul ignore next */
    if (apiRequestCallId != null) {
/* istanbul ignore next */
      this.getInsurerApiReceive(apiRequestCallId, responseJson, errorReponse);
      this.addInsurerApiReceive(apiRequestCallId, responseJson, errorReponse);
      this.EditInsurerApiReceive(apiRequestCallId, responseJson, errorReponse);
    }
  }
  
  // Customizable Area End
}

apiCall(data: any) {
  const { contentType, method, endPoint, body } = data;
  const userData = JSON.parse(localStorage.getItem('loginData') || '{}'); 
  const token = userData.token || '';
  
  const header = {
    "Content-Type": contentType,
    "token":token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // JSON.stringify(body)
      body
    );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};

}
// Customizable Area End