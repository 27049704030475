import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  classes: any;
  importFor: string;
  isEmpUploadingCsvFile: boolean,
  empPage: number;
  empRowPerPage: number;
  empTotalPage: number;
  isLoading:boolean;
  empCsvData:object[],
  empShowAlert: boolean,
  empAlertMessage: string;
  empAlertType: string;
  empErrorMessage: string;
  empErrorFile: string;
  isEmpSaveBtnClicked: boolean;
  handleEmpAnotherCSVUpload: any;
  downloadEmpFile: any,
  handleEmpSaveCSVUpload: any;
  handleEmpCSVUploader: any;
  handleEmpChangePage: any;
  handleempRowPerPage: any;
  empTableBody: any;
  closeEmpToast: any 
  // Customizable Area End

}

interface S {
  // Customizable Area Start
  isArabicLanguage: boolean;
  isUploadingCsvFile: boolean;
  page: number;
  rowPerPage: number;
  totalPage: number;
  isLoading:boolean;
  empCsvData:object[],
  showAlert: boolean,
  alertMessage: string;
  alertType: string;
  errorMessage: string;
  errorFile: string;
  isSaveBtnClicked: boolean;
  policyInfo:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmployeeCsvImportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadEmpCsvAPIMessage: string = '';
  getUploadedEmpCsvAPIMessage: string = '';
  saveEmpCsvAPIMessage: string = ''
  empFileId: any;
  deleteEmpCsvAPIMessage: string = '';
  sampleCsvEmployeeAPIKey: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
     // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
     // Customizable Area Start
     isArabicLanguage:
        (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      isUploadingCsvFile: false,
      page: 1,
      rowPerPage: 10,
      totalPage: 1,
      isLoading:false,
      empCsvData:[],
      alertMessage: '',
      showAlert: false,
      alertType: '',
      errorMessage: '',
      errorFile: '',
      isSaveBtnClicked: false,
      policyInfo:{}
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
 
    // Customizable Area Start
    // Customizable Area End
  }

  

 
  async receive(from: string, message: Message) {
     // Customizable Area Start
     this.setUpdateEmpCsv(message);
     this.setSaveEmpCsv(message);
     this.fetchEmpCsvList(message);
     this.deleteEmpUploadedCsvList(message)
     this.receiveDownloadedSampleCsvForEmployee(message)
     // Customizable Area End

  }
  
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const csvData = JSON.parse(localStorage.getItem('csvinfo') || '{}');  
    csvData &&  this.setState({policyInfo:csvData})
    // Customizable Area End
   
    
  }
// Customizable Area Start
handleEmpCSVUploader=(file: any)=>{
  this.setState({isUploadingCsvFile:true})
  this.uploadEmpCsv(file)
}

closeEmpToast = () => {
  this.setState({ showAlert: false })
}

handleEmpAnotherCSVUpload=(file: any)=>{
  this.uploadEmpCsv(file, this.empFileId)
}

handleEmpSaveCSVUpload=()=>{
  this.setState({isSaveBtnClicked: true})
  this.saveEmpCsv(this.empFileId)
}

handleEmpChangePage = (event: any, page: number) => {
  this.setState({ page: page });
};

handleEmpRowPerPage = (event: any) => {
  this.setState({ rowPerPage: parseInt(event.target.value) });
  this.setState({ page: 1 });
};

downloadEmpFile = (fileUrl: string) => {
  
  const emplink = document.createElement('a');
  emplink.href = fileUrl;
  emplink.style.display = 'none';
  document.body.appendChild(emplink);
  emplink.click();
  document.body.removeChild(emplink);
} 

setAlertEmp = (responseMessage: string,alertType: string = 'error') => {
  this.setState({ showAlert: true, alertMessage: responseMessage, alertType: alertType})
}

async componentDidUpdate(
  prevProps: Readonly<Props>,
  prevStateEmp: Readonly<S>,
  snapshot?: SS | undefined
) {
  if (
    (prevStateEmp.page !== this.state.page ||
      prevStateEmp.rowPerPage !== this.state.rowPerPage)
  ) {
    const {page, rowPerPage} = this.state
    this.getEmpUploadedCsv(page, rowPerPage);
  }

}

getEmpFormData = (file :  any, file_id: number| string) => {
  const formData = new FormData();
  file && formData.append('data[file]',file)
  file_id && formData.append('data[file_id]', file_id as string)
  
  return formData
}

uploadEmpCsv = (file: any, file_id?: number) => {
  this.setState({
    isLoading:true
  })
  this.uploadEmpCsvAPIMessage = this.apiEmpCall({
    type : 'FormData',
    method: 'POST',
    body: this.getEmpFormData(file, file_id as number),
    endPoint: `bx_block_policy_request/policy_requests/upload_csv?id=${this.state.policyInfo.id}`,
  })
}

getEmpUploadedCsv = (page: number, perPage: number) => {
  this.getUploadedEmpCsvAPIMessage = this.apiEmpCall({
    type : 'application/json',
    method: 'GET',
    endPoint: `bx_block_policy_request/policy_requests/fetch_csv_uploaded_data?page=${page}&per_page=${perPage}&file_id=${this.empFileId}&id=${this.state.policyInfo.id}`,
  })
}

saveEmpCsv = (file_id: number ) => {
  this.saveEmpCsvAPIMessage = this.apiEmpCall({
    type : 'FormData',
    method: 'PUT',
    body: this.getEmpFormData(null, file_id),
    endPoint: `bx_block_policy_request/policy_requests/update_csv_uploaded_data?id=${this.state.policyInfo.id}`
  })
}

destroyEmpUploadedCsv = (fileId: string) => {
  this.deleteEmpCsvAPIMessage = this.apiEmpCall({
    type : 'application/json',
    method: 'DELETE',
    endPoint: `bx_block_policy_request/policy_requests/destroy_csv_uploaded_data?file_id=${fileId}&id=${this.state.policyInfo.id}`
  })
}

downloadSampleCsvForEmployee = () => {
  this.sampleCsvEmployeeAPIKey = this.apiEmpCall({
    type : 'application/json',
    method: 'GET',
    endPoint: `/bx_block_sample_file/sample_files?name=${this.state.policyInfo.type === 'emp' ? 'Employee' : 'Vehicle'}`,
  })
}

apiEmpCall(data: any) {
  const { contentType, method, endPoint, body, type } = data;
  const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
  const token = userData.token || "";

  let header: any = {
    token: token,
    "Content-Type": contentType
  };

  const empCsvImportMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  empCsvImportMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  empCsvImportMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  empCsvImportMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body && type !== 'FormData' ?
  empCsvImportMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    ) : empCsvImportMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    ) ;

  runEngine.sendMessage(empCsvImportMessage.id, empCsvImportMessage);
  return empCsvImportMessage.messageId;
}

setUpdateEmpCsv = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.uploadEmpCsvAPIMessage !== null &&
    this.uploadEmpCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const updateEmpCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
    try {
      if(updateEmpCsvAPIResponse?.data){
        if (updateEmpCsvAPIResponse?.data.error_message || updateEmpCsvAPIResponse?.data.error_file) {
          this.setState({errorMessage: updateEmpCsvAPIResponse?.data.error_message, errorFile: updateEmpCsvAPIResponse?.data.error_file})
          this.setAlertEmp(updateEmpCsvAPIResponse?.data.error_message)
        } 
        if(updateEmpCsvAPIResponse?.data.file_id) {
          if(!updateEmpCsvAPIResponse?.data.error_message) {
            this.setState({errorMessage: '', errorFile: ''})
          }
          window.localStorage.setItem("insurer_file_id", JSON.stringify(updateEmpCsvAPIResponse?.data?.file_id))
          this.empFileId = updateEmpCsvAPIResponse?.data?.file_id;
          this.getEmpUploadedCsv(this.state.page, this.state.rowPerPage)
        }
      } else {
        this.setAlertEmp(updateEmpCsvAPIResponse?.error)
      }
    } catch (error) {
      //ignore
    } finally {
      this.setState({isLoading:false})
    }
  }
}

setSaveEmpCsv = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.saveEmpCsvAPIMessage !== null &&
    this.saveEmpCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const saveEmpCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

    if (saveEmpCsvAPIResponse?.message) {
      this.setAlertEmp(saveEmpCsvAPIResponse?.message, 'success')
      this.empFileId = ''
      setTimeout(() => {
        window.location.href=`/PolicyRequest?id=${this.state.policyInfo.id}`
      }, 700)
    } else {
      this.setAlertEmp(saveEmpCsvAPIResponse.errors)
    }
  }
}

fetchEmpCsvList = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getUploadedEmpCsvAPIMessage !== null &&
    this.getUploadedEmpCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const fetchEmpCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (fetchEmpCsvAPIResponse?.data) {
      this.setState({isUploadingCsvFile: true})
      this.setState({empCsvData: fetchEmpCsvAPIResponse.data, totalPage: fetchEmpCsvAPIResponse.meta.total_page})
    } else {
      this.setAlertEmp(fetchEmpCsvAPIResponse.errors)
    }
  }
}

deleteEmpUploadedCsvList = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.deleteEmpCsvAPIMessage !== null &&
    this.deleteEmpCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const deleteEmpCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (deleteEmpCsvAPIResponse?.message) {
      this.setAlertEmp(deleteEmpCsvAPIResponse?.message);
    } else {
      this.setAlertEmp(deleteEmpCsvAPIResponse?.errors)
    }
  }
}

receiveDownloadedSampleCsvForEmployee = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.sampleCsvEmployeeAPIKey !== null &&
    this.sampleCsvEmployeeAPIKey ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const saveEmpSampleCsv = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (saveEmpSampleCsv?.url) {
      this.downloadEmpFile(saveEmpSampleCsv?.url);
    } else {
      this.setAlertEmp(saveEmpSampleCsv?.error)
    }
  }

}
setArabicLanguage = (value: boolean) => {
  const language = value === true ? "ar" : "en";
  window.localStorage.setItem("lang", language);
  this.setState({
    isArabicLanguage: value
  });
};
componentWillUnmount(): any {
  this.empFileId && this.destroyEmpUploadedCsv(this.empFileId)
}
  // Customizable Area End
}
