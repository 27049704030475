import { IconButton, InputAdornment, OutlinedInput } from '@material-ui/core';
import { Search, FileCopyOutlined } from '@material-ui/icons';
import { useField } from "formik"
import React from 'react'

const TextInput = (props: any) => {
    const [field, meta] = useField(props);


   
    return (
        <OutlinedInput
            style={{
                marginTop: "10px",
                marginBottom: "10px",
                background: props.disabled ? "hsl(0, 0%, 95%)" : "none"
            }}
            fullWidth
            type={props.type ? props.type : "text"}
            {...field}
            {...props}
            
            error={meta.error && meta.touched}
            testID={props.testIDProps}
            value={field.value}
            disabled={props.disabled}
            onBlur={props.onBlur}
            
        />
    );
}

export default TextInput