import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Button as MuButton,
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { Close, CheckCircle, Error } from "@material-ui/icons";
import { withTranslation } from "react-i18next";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const ResetButton = styled(MuButton)({
  "&": {
    color: "#fff",
    fontWeight: "600",
    padding:'8px 15px',
    "text-transform": 'none',
  },
});

const style = makeStyles({
  heading: {
    fontWeight: 900,
  },
  subHeading: {
    fontSize: "small",
    color: "grey",
  },
});

// Customizable Area End

import Settings5Controller, { Props } from "./Settings5Controller";

export class Settings5 extends Settings5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container
          maxWidth="xl"
          dir={
            (window.localStorage.getItem("lang") || "en") === "ar"
              ? "rtl"
              : "ltr"
          }
          style={webStyle.mainContainer}
        >
          {this.state.showAlertMessage && (
            <div style={webStyle.alertDiv} dir="ltr">
              <Alert
                style={
                  this.state.alertType === "error"
                    ? webStyle.redAlertMessage
                    : webStyle.greenAlertMessage
                }
                icon={
                  this.state.alertType === "success" ? (
                    <CheckCircle style={webStyle.alertIconStyle} />
                  ) : (
                    <Error style={webStyle.alertIconStyle} />
                  )
                }
                severity={
                  this.state.alertType === "success" ? "success" : "error"
                }
              >
                <div style={webStyle.displayFlex}>
                  {this.state.alertType === "success"
                    ? t(
                        "We_have_sent_a_password_reset_link_to_your_email_address"
                      )
                    : this.state.alertMessage}
                  <Close
                    data-test-id="closeAlert"
                    style={webStyle.alertCloseIcon}
                    onClick={() => this.setState({ showAlertMessage: false })}
                  />
                </div>
              </Alert>
            </div>
          )}
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h5" style={webStyle.mainHeading}  data-test-id="resetPasswordSubHeading" >
              {t("reset_your_password")}
            </Typography>
            <Typography
              variant="subtitle1"
              style={webStyle.subHeading}
              component="div"
              data-test-id="resetPasswordSubHeading"
            >
              {t(
              "Get_a_password_reset_link_send_to_your_registered_email_by_clicking_below"
              )}
            </Typography>
            <Box
              component="button"
              sx={webStyle.submitButtonContainer}
              onClick={this.handleResetPassword}
              data-test-id="resetPassword"
            >
              <ResetButton>{t("Reset_password")}</ResetButton>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer:{
    marginTop:"24px",
    paddingLeft:"0"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    padding: "20px",
    background: "#fff",
    width: "100%",
    borderRadius: "10px",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  submitButtonContainer: {
    background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    color: "white",
    width:'165px',
    minWidth: "fit-content",
    height:'51px',
    borderRadius: "7px",
    borderStyle: "none",
  },
  mainHeading: {
    fontWeight: 700,
  },
  subHeading: {
    color: "grey",
    fontSize: "15px",
    marginBottom: "34px",
    marginTop: "14px",
    fontWeight:400
  },
  alertDiv: {
    display: "flex",
    justifyContent: "end",
    paddingBottom: "15px",
    paddingTop: "15px",
  },
  AlertMessage: {
    backgroundColor: "#ec5655",
    color: "white",
  },
  redAlertMessage: {
    backgroundColor: "#ec5655",
    color: "white",
  },
  greenAlertMessage: {
    backgroundColor: "#028f5d",
    color: "white",
  },
  alertCloseIcon: {
    color: "white",
    cursor: "pointer",
    paddingLeft: "10px",
  },
  alertIconStyle: {
    color: "white",
  },
  displayFlex: {
    display: "flex",
  },
};

export default withTranslation()(Settings5);
// Customizable Area End
