import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { ExpandMore,ErrorOutlineRounded, HighlightOff, Lock } from '@material-ui/icons'
// @ts-ignore
import { withTranslation } from 'react-i18next';
import { createTheme, makeStyles, ThemeProvider, withStyles } from "@material-ui/core/styles";
import StageOneController, { Props } from "../../../blocks/TaskAllocator/src/PolicyStage/StageOneController";
import LabelWithIcon from "../Common/LabelWithIcon";
import TextInput from "../Common/TextInput";
import MultiSelectFormik from "../Common/MultiSelectFormik";
import ConfirmationDialog from "../Common/ConfirmationDialog.web";
import FileUpload from "../Common/FileUpload";
import MultipleFileUpload from "../Common/MultipleFileUpload";
import StageOnePartTwoFormSchema from "./StageOnePartTwoFormSchema";
import CustomButton from "../Common/CustomButton";
import Toster from "../Common/Toster";
import HandOverDialog from "../Common/HandOverDialog.web";
import SelectBoxFormik from "../Common/SelectBoxFormik";
import { Link as Redirect } from 'react-router-dom';
import ActiveLanguage from "../Common/ActiveLanguage";
import { t } from "i18next";
import RejectRequestDialog from "../Common/RejectRequestDialog.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

const useStylesFunction = makeStyles({
    toggleContainer: {
        width: '100%',
            display: 'flex',
                justifyContent: 'space-between',
                    '& svg': {
            color: '#0090a1'
        }
    },
    accordianHeading: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
    },
    lockedAccordion: {
        fontSize: "12px",
        height: "35px",
        padding: "15px",
        border: '1px solid #ccc',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 0 0 20px',
        backgroundColor: "lightgray",
        color: "darkgray",
        '& svg': {
            marginRight: '6px',
        }
    },
})
  
const AccordionTitle = (props: any) => {
    const classes = useStylesFunction()
    return (
        <Typography className={classes.accordianHeading}>
            {props.title}

            {props.isLock && (props.policyId === "") && (
                <div className={classes.lockedAccordion}>
                    <Lock fontSize="small" /> {t('please_select_insurance_type_to_unlock_this_fields')}
                </div>
            )}
        </Typography>
    )
}
interface stageOneInitialValue  {
    requestId: string;
    requestType: string;
    customer: any;
    customerId: any;
    contactName: any;
    contectPosition: any;
    contactPhoneNumber: string;
    contactEmail: string;
    contactStatus: string;
    sales: any;
    operation: any;
    finance: any;
    insuranceType: any;
}
  
export class StageOneForm extends StageOneController {
    formikRef: any;
    constructor(props: Props) {
        super(props);
        this.formikRef = React.createRef();  
        

    }
    getLocalStorageValue = (key: string) => {
        try {
            const storedDataString = localStorage.getItem("stageOnePartTwoData");
            if (storedDataString !== null) {
                const storedValue = JSON.parse(storedDataString);
                return storedValue ? storedValue[key] : null;
            }
        } catch (error) {
            console.error(`Error retrieving value for key "${key}" from local storage.`, error);
            return null;
        }
    }
    
    render() {        
        // @ts-ignore
        const { t, classes, } = this.props;
        const currentFileUrl=this.state.currentFileUrl
        const { isEdit } = this.props;
        const insuranceTypeOption = [
            { value: "medical", label: "Medical"},
            { value: "motor", label: "Motor" },
            { value: "life", label: "Life" },
            { value: "engineering", label: "Engineering" },
            { value: "marine", label: "Marine" },
            { value: "property", label: "Property" },
            { value: "fire", label: "Fire" },
            { value: "aviation", label: "Aviation" },
            { value: "energy", label: "Energy" },
            { value: "protection & savings", label: "Protection & Savings" },
            { value: "general business", label: "General Business" }
        ]
        const motorPolicTypeOption = [
            { value: "Motor TPL", label: "Motor TPL" },
            { value: "Comprehensive", label: "Comprehensive" }
        ]
        const enumRoles = {
            sales: 'Sales'
        }

        const getStageOneInitialValue = () => {
            const stageOne = JSON.parse(localStorage.getItem('stageOne') || '{}');
            const stageValue : stageOneInitialValue = {
                requestId: stageOne?.requestId || this.state.stageData.requestId,
                requestType:( stageOne?.requestType )|| (this.state.policyId ? "New policy" : ""),
                customer: stageOne?.customer || this.state.stageData.customer || "",
                customerId: stageOne?.customerId || this.state.stageData.customerId || "",
                contactName: stageOne?.contactName && stageOne?.contactName ||  this.state.stageData.contactName,
                contectPosition: stageOne?.contectPosition || this.state.stageData.contectPosition,
                contactPhoneNumber: stageOne?.contactPhoneNumber || this.state.stageData.contactPhoneNumber,
                contactEmail: stageOne?.contactEmail || this.state.stageData.contactEmail,
                contactStatus: stageOne?.contactStatus || this.state.stageData.contactStatus,
                sales: stageOne?.sales || this.state.stageData.sales,
                operation: stageOne?.operation || this.state.stageData.operation,
                finance: stageOne?.finance || this.state.stageData.finance,
                insuranceType: stageOne?.insuranceType || this.state.stageData.insuranceType,
            }
            
            return stageValue
        }

        const getStageTwoInitialValue = () => {
            const stageOne = JSON.parse(localStorage.getItem('stageOne') || '{}');
            const stgeTwo = JSON.parse(localStorage.getItem('stageOnePartTwoData') || '{}');
            const stageValue = {
                industry: stgeTwo?.industry || this.state.stageTwoData.industry,
                existingInsurance: stgeTwo?.existingInsurance || this.state.stageTwoData.existingInsurance,
                nameOfExistingInsurer: stgeTwo?.nameOfExistingInsurer || this.state.stageTwoData.nameOfExistingInsurer,
                policyExpireDate: (stgeTwo?.policyExpireDate || this.state.stageTwoData.policyExpireDate).substring(0, 10),
                tradeLicenseNumber: stgeTwo?.tradeLicenseNumber || this.state.stageTwoData.tradeLicenseNumber,
                tradeLicenseFile: stgeTwo?.tradeLicenseFile || this.state.stageTwoData.tradeLicenseFile,
                brokerOfRecord: stgeTwo?.brokerOfRecord || this.state.stageTwoData.brokerOfRecord,
                LrClaimDoc: stgeTwo?.LrClaimDoc || this.state.stageTwoData.LrClaimDoc,
                nationalAddress: stgeTwo?.nationalAddress || this.state.stageTwoData.nationalAddress,
                proposalForm: stgeTwo?.proposalForm || this.state.stageTwoData.proposalForm,
                clientComment: stgeTwo?.clientComment || this.state.stageTwoData.clientComment,
                additionalDoc: stgeTwo?.additionalDoc || this.state.stageTwoData.additionalDoc,
                insuranceType: stageOne?.insuranceType || this.state.stageTwoData.insuranceType,
                insuranceCompany: stgeTwo?.insuranceCompany || this.state.stageTwoData.insuranceCompany,
                motor: stgeTwo?.motor || this.state.stageTwoData.motor,
                medical: stgeTwo?.medical || this.state.stageTwoData.medical,
                life: stgeTwo?.life || this.state.stageTwoData.life,
                fire: stgeTwo?.fire || this.state.stageTwoData.fire,
                property: stgeTwo?.property || this.state.stageTwoData.property,
                engineering: stgeTwo?.engineering || this.state.stageTwoData.engineering,
                marine: stgeTwo?.marine || this.state.stageTwoData.marine,
                protectionAndSaving: stgeTwo?.protectionAndSaving || this.state.stageTwoData.protectionAndSaving,
                generalBusiness: stgeTwo?.generalBusiness || this.state.stageTwoData.generalBusiness,
                aviation: stgeTwo?.aviation || this.state.stageTwoData.aviation,
                energy: stgeTwo?.energy || this.state.stageTwoData.energy,
            }
            
            return stageValue
        }

        const disableAssignee = (requestId: string) => {
            if (this.state.p1Submited || requestId !== "") return true;
            else false;
        }

        const stageOne = JSON.parse(localStorage.getItem('stageOne') || '{}');
        const role = JSON.parse(localStorage.getItem('loginData') || '{}').role;
        const stageOneData = JSON.parse(localStorage.getItem('stageOne') || '{}');

        
        const getErrorHighlightedAccordion = (errors:any, touched:any, dynamicKey:string) => {
            const hasErrorForKey = errors?.[dynamicKey] && Object.keys(errors[dynamicKey]).length > 0;
            const hasTouchedFieldForKey = touched?.[dynamicKey] && Object.keys(touched[dynamicKey]).length > 0;
            return hasErrorForKey && hasTouchedFieldForKey;
        };
        
        
        return (
            <ThemeProvider theme={theme} >


                <React.Fragment>
                    <Toster
                        tosterText={this.state.alertMessage}
                        showToster={this.state.showAlert}
                        // @ts-ignore
                        tosterType={this.state.alertType}
                        handleCloseToster={()=> this.setState({showAlert:false})}
                    />
                    <div className={classes.customerDetailsAccordion}>
                        <Accordion data-test-id='p1' id="p1-accordion" className={((this.state.errorSection === 'p1') && classes.errorSection) ? classes.errorSection : classes.accordionStyle} expanded={this.isP1Expanded(this.props.isExpanded)} onChange={(event: any, isExpanded: boolean) => {
                            this.handleAccordian(isExpanded, "p1")
                        }} >
                            <AccordionSummary
                                aria-expanded={true}
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            ><div className={classes.toggleContainer}>
                            <Typography style={{fontWeight: 600}} >{t('overview')}</Typography>
                            <div>
                             {this.state.expandedAccordion === 'p1' && stageOne?.partOneResponse?.data?.attributes?.lost_reason && 
                             <HighlightOff style={{ color: 'red'}} onClick={() => this.openNoteDialog()} />
                             }
                            </div>
                           </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={getStageOneInitialValue()}
                                    validationSchema={Yup.object().shape(this.overviewSchema(ActiveLanguage()))}
                                    validateOnMount={true}
                                    validateOnBlur={true}
                                    onSubmit={async (values, actions) => {

                                        let stageTwoData = await this.state.stageTwoData;
                                        stageTwoData = await { ...stageTwoData, insuranceType: values.insuranceType}
                                        this.setState({ stageTwoData: stageTwoData, loading:true })

                                        if (values.requestId !== "") {
                                           this.edtiPolicy(values)
                                        } else {
                                           this.callAPI(0.5,"next",values)
                                        }
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        errors,
                                        touched,
                                        values,
                                        setFieldValue,
                                        validateForm,
                                        setFieldTouched
                                    }) => (
                                        <React.Fragment>
                                            <Grid className={classes.contactForm} >
                                                <Grid container spacing={2} >
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('request_id')} />
                                                        <TextInput
                                                            id={"requestId"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="requestId"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="requestIdTxtInput"
                                                        />
                                                        {touched.requestId && errors.requestId ? (
                                                            <Typography color="error" >{errors.requestId}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('request_type')} />
                                                        <TextInput
                                                            id={"requestType"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="requestType"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="requestTypeTxtInput"
                                                        />
                                                        {touched.requestType && errors.requestType ? (
                                                            <Typography color="error" >{errors.requestType}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('customer')} />
                                                        <TextInput
                                                            id={"cutomer"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="customer"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="customerIdTxtInput"
                                                        />
                                                        {touched.customer && errors.customer ? (
                                                            <Typography color="error" >{errors.customer}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                </Grid>
                                                <Divider className={classes.textInputStyle} />
                                                <Typography className={classes.divierStyle} >{t('contact')}</Typography>
                                                <Grid className={classes.contactDetailDiv} container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('name')} />
                                                        <MultiSelectFormik
                                                            value={values.contactName}
                                                            name='contactName'
                                                            id='contactName'
                                                            // @ts-ignore
                                                            setOtherInput={(customerDetail: any) => {
                                                                setFieldValue('contectPosition', customerDetail.position)
                                                                setFieldValue('contactPhoneNumber', `${customerDetail.phone_number}`)
                                                                setFieldValue('contactEmail', customerDetail.email)
                                                                setFieldValue('contactStatus', customerDetail.status)
                                                            }}
                                                            options={this.state.contectOption}
                                                            data-test-id="contectName"
                                                            disabled={disableAssignee(values.requestId)}
                                                        />
                                                        {touched.contactName && errors.contactName ? (
                                                            <Typography color="error" >{errors.contactName}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('position')} />
                                                        <TextInput
                                                            id={"contectPosition"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="contectPosition"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="contectPositionTxtInput"
                                                        />
                                                        {touched.contectPosition && errors.contectPosition ? (
                                                            <Typography color="error" >{errors.contectPosition}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('contact_no')} />
                                                        <TextInput
                                                            className={classes.textInputStyle}
                                                            name="contactPhoneNumber"
                                                            type="text"
                                                            data-test-id="contactPhoneNumber"
                                                            id="contactPhoneNumber"
                                                            disabled={true}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    + 966<div className={classes.phoneInputLine} />
                                                                </InputAdornment>
                                                            }
                                                        />
                                                        {touched.contactPhoneNumber && errors.contactPhoneNumber ? (
                                                            <Typography color="error" >{errors.contactPhoneNumber}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('email')} />
                                                        <TextInput
                                                            id={"contactEmail"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="contactEmail"
                                                            disabled={true}
                                                            type="email"
                                                            data-test-id="contactEmailTxtInput"
                                                        />
                                                        {touched.contactEmail && errors.contactEmail ? (
                                                            <Typography color="error" >{errors.contactEmail}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('account_status')} />
                                                        <TextInput
                                                            id={"contactStatus"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="contactStatus"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="contactStatusTxtInput"
                                                        />
                                                        {touched.contactStatus && errors.contactStatus ? (
                                                            <Typography color="error" >{errors.contactStatus}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                </Grid>
                                                <Divider className={classes.textInputStyle} />
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('sales')} />
                                                        <MultiSelectFormik
                                                            value={values.sales}
                                                            name='sales'
                                                            id='sales'
                                                            options={this.state.salesOption}
                                                            data-test-id="salesTxtInput"
                                                            disabled={disableAssignee(values.requestId)}
                                                        />
                                                        {touched.sales && errors.sales ? (
                                                            <Typography color="error" >{errors.sales}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('operations')} />
                                                         <MultiSelectFormik
                                                            value={values.operation}
                                                            name='operation'
                                                            id='operation'
                                                            options={this.state.operationOptions}
                                                            data-test-id="operationTxtInput"
                                                            disabled={disableAssignee(values.requestId)}
                                                        />
                                                        {touched.operation && errors.operation ? (
                                                            <Typography color="error" >{errors.operation}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('finance')} />
                                                        <MultiSelectFormik
                                                            value={values.finance}
                                                            name='finance'
                                                            id='finance'
                                                            options={this.state.financeOptions}
                                                            data-test-id="financeTxtInput"
                                                            disabled={disableAssignee(values.requestId)}
                                                        />
                                                        {touched.finance && errors.finance ? (
                                                            <Typography color="error" >{errors.finance}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid className={classes.insuranceTypeStyle} item xs={10}>
                                                        <LabelWithIcon label={t('insurance_type')} />
                                                        <SelectBoxFormik
                                                            name={`insuranceType`}
                                                            onChange={(newValue: any) => {
                                                                setFieldValue(`insuranceType`, newValue)
                                                            }}
                                                            options={insuranceTypeOption}
                                                            disabled={this.state.p1Submited || (values.requestId !== "")}
                                                        />
                                                        {touched.insuranceType && errors.insuranceType ? (
                                                            <Typography color="error" >{errors.insuranceType}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.saveBtnDiv} >
                                                        {<Button onClick={() => {
                                                            Object.keys(values).forEach((fieldName) => {
                                                                setFieldTouched(fieldName, true, false);
                                                              });
                                                            validateForm()
                                                            this.checkErrorForStageOneForm(errors, "p1");
                                                        }} className={classes.saveBtnStyle} disabled={this.state.p1Submited || values.requestId !== ""} >{t('save')}</Button>}
                                                    </Grid>
                                                    <Typography className={classes.noteMessage} >{t('save_insurance_type_message_note')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <ConfirmationDialog
                                                openDialog={this.state.showDialog}
                                                btnLoading={this.state.loading}

                                                // Get static codes
                                                headingText={t('save_insurance_type_heading')}
                                                paragraphText={t('save_insurance_type_sub_title')}
                                                btnCancelText={t('cancel')}
                                                btnOkText={t('save')}

                                                // Call funcations on button click
                                                handleCancel={() => this.setState({ showDialog: false })}
                                                handleOk={() => handleSubmit()}
                                                showRedButton={false}
                                            />
                                        </React.Fragment>
                                    )}
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    {(this.state.p1Submited || stageOneData?.attributes?.is_reject) ? (
                        <div className={classes.customerDetailsAccordion}>
                            <Formik
                                innerRef={this.formikRef}
                                enableReinitialize={true }
                                initialValues={getStageTwoInitialValue()}
                                validationSchema={Yup.object().shape(StageOnePartTwoFormSchema(ActiveLanguage()))}
                                validateOnMount={true}
                                validateOnBlur={true}
                                onSubmit={(values, actions) => {
                                    this.setState({loading:true})
                                    const getApiType = this.wantToEditPolicy();
                                    this.callAPI(1,getApiType === "yes" ? "edit" : "next",values);
                                }}
                            >
                                {({
                                    handleSubmit,
                                    errors,
                                    touched,
                                    values,
                                    setFieldValue,
                                    validateForm
                                }) => (
                                    <React.Fragment>
                                        <Accordion disabled={this.isRfqInputIsDisabled()} data-test-id='p2' className={((this.state.errorSection === 'p2') && classes.errorSection) ? classes.errorSection : classes.accordionStyle} expanded={this.isP2Expanded(this.props.isExpanded)} onChange={(event: any, isExpanded: boolean) => {
                                                this.handleAccordian(isExpanded, "p2")
                                        }} >
                                            <AccordionSummary
                                                aria-expanded={true}
                                                expandIcon={<ExpandMore />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                              <Typography style={{ fontWeight: 600 }} >{t('rfq_input')}</Typography>
                                              <div className={classes.iconAlignment}>
                                                {this.state.expandedAccordion && stageOneData?.partOneResponse?.data?.attributes?.is_reject &&
                                                 <ErrorOutlineRounded style={{position: 'relative', left: '10px', color: '#0090a1'}} onClick={() => this.setState({showRejectDialog: true})} />
                                                }
                                              </div>
                                            </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2} >
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('industry')} tooltip={t('if_this_is_incorrect_please_go_to_the_customer_proflie_and_change_it_there')} />
                                                        <TextInput
                                                            id={""}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="industry"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="industryTxtInput"
                                                        />
                                                        {touched.industry && errors.industry ? (
                                                            <Typography color="error" >{errors.industry}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>

                                                    </Grid>
                                                    <Grid item xs={4}>

                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('existing_insurance')} />
                                                        <FormControl className={classes.radioFormControl}>
                                                            <RadioGroup
                                                                row
                                                                defaultValue={values.existingInsurance}
                                                                name="existingInsurance"
                                                                value={values.existingInsurance}
                                                                onChange={(e: any) => {
                                                                    setFieldValue('existingInsurance',e.target.value)
                                                                }}
                                                            >
                                                                <FormControlLabel disabled={!this.props.isExpanded} checked={values.existingInsurance === "yes"} value={"yes"} control={<Radio color="primary" />} label={t("yes")} />
                                                                <FormControlLabel disabled={!this.props.isExpanded} checked={values.existingInsurance === "no"} value={"no"} control={<Radio color="primary" />} label={t("no")} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        {touched.existingInsurance && errors.existingInsurance ? (
                                                            <Typography color="error" >{errors.existingInsurance}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {values.existingInsurance === "yes" && (
                                                            <React.Fragment>
                                                                <LabelWithIcon label={t('name_existing_insurer')} />
                                                                <TextInput
                                                                    id={""}
                                                                    className={classes.textInputStyle}
                                                                    isArabicLenguage={this.state.isArabicLenguage}
                                                                    name="nameOfExistingInsurer"
                                                                    type="text"
                                                                    disabled={!this.props.isExpanded}
                                                                    data-test-id="nameOfExistingInsurerTxtInput"
                                                                />
                                                                {touched.nameOfExistingInsurer && errors.nameOfExistingInsurer ? (
                                                                    <Typography color="error" >{errors.nameOfExistingInsurer}</Typography>
                                                                ) : null}
                                                            </React.Fragment>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        {values.existingInsurance === "yes" && (
                                                            <React.Fragment>
                                                                <LabelWithIcon label={t('policy_expire_data')} />
                                                                <TextInput
                                                                    data-test-id={`policyExpireDate`}
                                                                    id={`policyExpireDateTxtInput`}
                                                                    className={classes.textInputStyle}
                                                                    name={`policyExpireDate`}
                                                                    type={"date"}
                                                                    disabled={!this.props.isExpanded}
                                                                    value={values?.policyExpireDate}
                                                                    onChange={(e:any)=> setFieldValue('policyExpireDate',e.target.value)}
                                                                />
                                                                {touched.policyExpireDate && errors.policyExpireDate ? (
                                                                    <Typography color="error" >{errors.policyExpireDate}</Typography>
                                                                ) : null}
                                                            </React.Fragment>
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('trade_license_number')} />
                                                        <TextInput
                                                            id={""}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="tradeLicenseNumber"
                                                            type="number"
                                                            disabled={!this.props.isExpanded}
                                                            data-test-id="tradeLicenseNumberTxtInput"
                                                        />
                                                        {touched.tradeLicenseNumber && errors.tradeLicenseNumber ? (
                                                            <Typography color="error" >{errors.tradeLicenseNumber}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('trade_license_cr_crs')} />
                                                        <FileUpload
                                                            eachFileSizeInMB={10}
                                                            maxFilesNumber={5}
                                                            fileName={values.tradeLicenseFile?.file_name || ""}
                                                            filePath={values?.tradeLicenseFile?.url}
                                                            data-test-id="txtInputTradeLicenseFile"
                                                            inputName={`tradeLicenseFile`}
                                                            disabled={!this.props.isExpanded}
                                                            onChange={(event: any) => {
                                                             this.uploadFileStageOne("trade_license_cr_crs", event.target.files[0], this.state.policyId, false, this.formikRef, "tradeLicenseFile");
                                                            }}
                                                            onRemove={async () => {
                                                                if (!this.props.isExpanded) {
                                                                    return
                                                                }
                                                                else {
                                                                    setFieldValue(`tradeLicenseFile`, "")
                                                                    await this.removeFile(values?.tradeLicenseFile?.id, this.formikRef)
                                                                }
                                                            }

                                                            }
                                                            // onRemove={() => { 
                                                            //     if (!this.props.isExpanded) return
                                                            //     setFieldValue(`tradeLicenseFile`, "") //need api call for file remove
                                                            // }}
                                                        />
                                                        {touched.tradeLicenseFile && errors.tradeLicenseFile ? (
                                                            <Typography color="error" >{errors.tradeLicenseFile}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('broker_of_record_bor')} />
                                                        <FileUpload
                                                            eachFileSizeInMB={10}
                                                            maxFilesNumber={5}
                                                            fileName={values.brokerOfRecord?.file_name || ""}
                                                            filePath={values.brokerOfRecord?.url || ""}
                                                            data-test-id="txtInputBrokerOfRecord"
                                                            inputName={`brokerOfRecord`}
                                                            disabled={!this.props.isExpanded}
                                                            onChange={(event: any) => {
                                                                this.uploadFileStageOne("broker_of_record", event.target.files[0], this.state.policyId ,false, this.formikRef, "brokerOfRecord")
                                                                
                                                            }}
                                                            onRemove={async () => {
                                                                if (!this.props.isExpanded) {
                                                                    return
                                                                }
                                                                else {
                                                                    setFieldValue(`brokerOfRecord`, "")
                                                                    await this.removeFile(values?.brokerOfRecord?.id, this.formikRef)
                                                                }
                                                            }

                                                            }
                                                        />
                                                        {touched.brokerOfRecord && errors.brokerOfRecord ? (
                                                            <Typography color="error" >{errors.brokerOfRecord}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('lr_claims_experience_past_5_years')} />
                                                        <FileUpload
                                                            eachFileSizeInMB={10}
                                                            maxFilesNumber={5}
                                                            fileName={values.LrClaimDoc?.file_name || ""}
                                                            filePath={values.LrClaimDoc?.url || ""}
                                                            disabled={!this.props.isExpanded}
                                                            data-test-id="txtInputLrClaimDoc"
                                                            inputName={`LrClaimDoc`}
                                                            onChange={(event: any) => {
                                                                this.uploadFileStageOne("lr_claim_experience_past_5_yrs", event.target.files[0], this.state.policyId ,false, this.formikRef, "LrClaimDoc" )
                                                            }}
                                                            onRemove={async () => {
                                                                if (!this.props.isExpanded) {
                                                                    return
                                                                }
                                                                else {
                                                                    setFieldValue(`LrClaimDoc`, "")
                                                                    await this.removeFile(values?.LrClaimDoc?.id, this.formikRef)
                                                                }
                                                            }

                                                            }
                                                        />
                                                        {touched.LrClaimDoc && errors.LrClaimDoc ? (
                                                            <Typography color="error" >{errors.LrClaimDoc}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('national_address')} />
                                                        <TextInput
                                                            id={""}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="nationalAddress"
                                                            disabled={!this.props.isExpanded}
                                                            type="text"
                                                            data-test-id="nationalAddressTxtInput"
                                                        />
                                                        {touched.nationalAddress && errors.nationalAddress ? (
                                                            <Typography color="error" >{errors.nationalAddress}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('proposal_form')} />
                                                        <FileUpload
                                                            eachFileSizeInMB={10}
                                                            maxFilesNumber={5}
                                                            fileName={values.proposalForm?.file_name || ""}
                                                            filePath={values.proposalForm?.url || ""}
                                                            data-test-id="fileInputProposalForm"
                                                            inputName={`proposalForm`}
                                                            onChange={(event: any) => {
                                                             this.uploadFileStageOne("proposal_form", event.target.files[0], this.state.policyId ,false, this.formikRef, "proposalForm")
                                                            }}
                                                            disabled={!this.props.isExpanded}
                                                            onRemove={async () => {
                                                                if (!this.props.isExpanded) {
                                                                    return
                                                                }
                                                                else {
                                                                    setFieldValue(`proposalForm`, "")
                                                                    await this.removeFile(values?.proposalForm?.id, this.formikRef)
                                                                }
                                                            }

                                                            }
                                                        />
                                                        {/* @ts-ignore */}
                                                        <ErrorMessage
                                                            name={`proposalForm`}
                                                            className="field-error"
                                                            render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <LabelWithIcon label={t('comapnies_to_get_offers_from')} />
                                                        <MultiSelectFormik
                                                            value={values.insuranceCompany}
                                                            name='insuranceCompany'
                                                            id='insuranceCompany'
                                                            isMulti={true}
                                                            options={this.state.insuranceCompany}
                                                            data-test-id="contectName"
                                                            disabled={!this.props.isExpanded}
                                                        />
                                                        {touched.insuranceCompany && errors.insuranceCompany ? (
                                                            <Typography color="error" >{errors.insuranceCompany}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <LabelWithIcon label={t('clients_comment')} />
                                                        <TextInput
                                                            id={""}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="clientComment"
                                                            type="text"
                                                            multiline
                                                            rows={4}
                                                            data-test-id="clientCommentTxtInput"
                                                            disabled={!this.props.isExpanded}
                                                        />
                                                        {touched.clientComment && errors.clientComment ? (
                                                            <Typography color="error" >{errors.clientComment}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <LabelWithIcon label={t('additional_attechment')} />
                                                        <MultipleFileUpload
                                                            id={'additionalDoc'}
                                                            filesInputs={values?.additionalDoc}
                                                            name={`additionalDoc`}
                                                            disabled={!this.props.isExpanded}
                                                            isEditPage={true}
                                                            filePath={
                                                            values?.additionalDoc?.length > 0 ? 
                                                            [...values?.additionalDoc, ...this.state.currentAdditionalDoc]
                                                            : []
                                                            }
                                                            data-test-id={`additionalDoc`}
                                                            inputName={`additionalDoc`}
                                                            onChange={(e: any) => {
                                                               this.uploadFileStageOne("rfq_input_additional_attachments", e.target.files, this.state.policyId ,true, this.formikRef)
                                                            }} 
                                                            onRemove={async (id: number) => {
                                                                if(!this.props.isExpanded) return
                                                                 await this.removeFile(id, this.formikRef)}
                                                            }
                                                            maxFilesNumber={5}
                                                            eachFileSizeInMB={10}
                                                        />
                                                        {touched.additionalDoc && errors.additionalDoc ? (
                                                            <Typography color="error" >{errors.additionalDoc}</Typography>
                                                        ) : null}
                                                    </Grid>

                                                    {/* Motor Insurance */}
                                                    {this.showMotorInsurance(values.insuranceType) && (
                                                        <Grid item xs={12}>
                                                            <Accordion disabled={!this.state.p1Submited} data-test-id='motorAccordion' className={getErrorHighlightedAccordion(errors,touched,"motor") ? classes.subErrorAccordionStyle : classes.subAccordionStyle} >
                                                                <AccordionSummary
                                                                    aria-expanded={true}
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography className={classes.accordianHeading} >{t('motor')}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('number_of_Vehicles_to_insure')} />
                                                                            <TextInput
                                                                                id={""}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="motor.numberOfVehiclesToInsure"
                                                                                type="number"
                                                                                data-test-id="numberOfVehiclesToInsureTxtInput"
                                                                                disabled={!this.props.isExpanded}
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`motor.numberOfVehiclesToInsure`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('policy_type')} />
                                                                            <MultiSelectFormik
                                                                                value={values.motor.policyType}
                                                                                name='motor.policyType'
                                                                                id='motorInsuranceType'
                                                                                isMulti={true}
                                                                                options={motorPolicTypeOption}
                                                                                data-test-id="motorInsuranceType"
                                                                                disabled={!this.props.isExpanded}
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`motor.policyType`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                        <Button
                                         className={classes.uploadCsvBtnStyle}
                                        variant="outlined"
                                        data-test-id="btnForViewUserDetails"
                                        id="btnForViewUserDetails"
                                        disabled={!this.props.isExpanded || this.getLocalStorageValue("no_of_vehicles") > 0}
                                        onClick={()=>{this.navigateImportCSV(isEdit as boolean,values,"vec")}}
                                    >
                                        {t('upload_vehcles_csv')}
                                    
                                    </Button>
                                   {this.getLocalStorageValue("no_of_vehicles") > 0 &&  <Grid style={{ margin: '5px' }}><LabelWithIcon label={t(`Number of imported rows: ${this.getLocalStorageValue("no_of_vehicles")}`)} /></Grid>}
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    )}

                                                    {/* Medical */}
                                                    {this.showMedicalInsurance(values.insuranceType) && (
                                                        <Grid item xs={12}>
                                                            <Accordion disabled={!this.state.p1Submited} data-test-id='p2' className={getErrorHighlightedAccordion(errors,touched,"medical") ? classes.subErrorAccordionStyle : classes.subAccordionStyle} >
                                                                <AccordionSummary
                                                                    aria-expanded={true}
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography className={classes.accordianHeading} >{t('medical')}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('number_of_employee_to_insure')} />
                                                                            <TextInput
                                                                                id={"numberOfEmployeeOfInsurer"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="medical.numberOfEmployeeOfInsurer"
                                                                                type="number"
                                                                                disabled={!this.props.isExpanded}
                                                                                data-test-id="numberOfEmployeeOfInsurerTxtInput"
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`medical.numberOfEmployeeOfInsurer`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('preffered_hospital')} />
                                                                            <TextInput
                                                                                id={"prefferedHospital"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="medical.prefferedHospital"
                                                                                type="text"
                                                                                disabled={!this.props.isExpanded}
                                                                                data-test-id="prefferedHospitalTxtInput"
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`medical.prefferedHospital`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                    
                                                    <Button
                                         className={classes.uploadCsvBtnStyle}
                                        variant="outlined"
                                        data-test-id="btnForViewUserDetails"
                                        id="btnForViewUserDetails"
                                        disabled={!this.props.isExpanded || this.getLocalStorageValue("no_of_employees") > 0}
                                        onClick={()=>{this.navigateImportCSV(isEdit as boolean,values,"emp")}}
                                    >
                                        {t('upload_employee_csv')}
                                    
                                    </Button>
                                   {this.getLocalStorageValue("no_of_employees") > 0 &&  <Grid style={{ margin: '5px' }}><LabelWithIcon label={t(`Number of imported rows: ${this.getLocalStorageValue("no_of_employees")}`)} /></Grid>}
                                    
                                                                            {/* @ts-ignore */}
                                                                           
                                                                        </Grid> 
                                                                        <Grid item xs={8}>
                                                                            <LabelWithIcon label={t('medical_segment')} /><FormControl className={classes.radioFormControl}>
                                                                                <RadioGroup
                                                                                    row
                                                                                    defaultValue={values.medical.medicalSegment}
                                                                                    name="medicalSegment"
                                                                                    value={values.medical.medicalSegment}
                                                                                    onChange={(e: any) => { 
                                                                                        setFieldValue('medical.medicalSegment',e.target.value)
                                                                                    }}
                                                                                >
                                                                                    <FormControlLabel disabled={!this.props.isExpanded} checked={values.medical.medicalSegment === "Compulsary"} value={"Compulsary"} control={<Radio color="primary" />} label={t('compulsary')} />
                                                                                    <FormControlLabel disabled={!this.props.isExpanded} checked={values.medical.medicalSegment === "Compulsary + others"} value={"Compulsary + others"} control={<Radio color="primary" />} label={t('compulsaryOther')} />
                                                                                </RadioGroup>
                                                                            </FormControl>
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`medical.medicalSegment`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    )}

                                                    {/* Life */}
                                                    {this.showLifeInsurance(values.insuranceType) && (
                                                        <Grid item xs={12}>
                                                            <Accordion disabled={!this.state.p1Submited} data-test-id='lifeAccordion' 
                                                             className={getErrorHighlightedAccordion(errors,touched,"life") ? classes.subErrorAccordionStyle : classes.subAccordionStyle}
                                                            >
                                                                <AccordionSummary
                                                                    aria-expanded={true}
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography className={classes.accordianHeading} >{t('life')}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('number_of_employee_to_insure')} />
                                                                            <TextInput
                                                                                id={"numberOfEmployeeOfInsurer"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="life.numberOfEmployeeOfInsurer"
                                                                                type="number"
                                                                                disabled={!this.props.isExpanded}
                                                                                data-test-id="numberOfEmployeeOfInsurerTxtInput"
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`life.numberOfEmployeeOfInsurer`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                        
                                                                        <Button
                                         className={classes.uploadCsvBtnStyle}
                                        variant="outlined"
                                        data-test-id="btnForViewUserDetails"
                                        id="btnForViewUserDetails"
                                        disabled={!this.props.isExpanded || this.getLocalStorageValue("no_of_employees") > 0}
                                        onClick={()=>{this.navigateImportCSV(isEdit as boolean,values,"emp")}}
                                    >
                                    {t('upload_employee_csv')}
                                    </Button>
                                   {this.getLocalStorageValue("no_of_employees") > 0 &&  <Grid style={{ margin: '5px' }}><LabelWithIcon label={t(`Number of imported rows: ${this.getLocalStorageValue("no_of_employees")}`)} /></Grid>}
                                    
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    )}
                                                    {/* fire */}
                                                    {this.showFireInsurance(values.insuranceType) && (
                                                        <Grid item xs={12}>
                                                            <Accordion disabled={!this.state.p1Submited} data-test-id='fireAccordion' className={getErrorHighlightedAccordion(errors,touched,"fire") ? classes.subErrorAccordionStyle : classes.subAccordionStyle} >
                                                                <AccordionSummary
                                                                    aria-expanded={true}
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography className={classes.accordianHeading} >{t('fire')}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('number_of_employee_to_insure')} />
                                                                            <TextInput
                                                                                id={"numberOfEmployeeOfInsurer"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="fire.numberOfEmployeeOfInsurer"
                                                                                type="number"
                                                                                disabled={!this.props.isExpanded}
                                                                                data-test-id="numberOfEmployeeOfInsurerTxtInput"
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`fire.numberOfEmployeeOfInsurer`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('gps_coordinates')} />
                                                                            <TextInput
                                                                                id={"gpsCordinate"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="fire.gpsCordinate"
                                                                                type="text"
                                                                                disabled={!this.props.isExpanded}
                                                                                data-test-id="gpsCordinateTxtInput"
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`fire.gpsCordinate`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('letter_from_civil_defence')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.fire.letterFormCivilDefenceDoc?.file_name || ""}
                                                                                filePath={values.fire.letterFormCivilDefenceDoc?.url || ""}
                                                                                data-test-id="txtInputletterFormCivilDefenceDoc"
                                                                                inputName={`fire.letterFormCivilDefenceDoc`}
                                                                                onChange={(event: any) => {
                                                                                 this.uploadFileStageOne("letter_from_civil_defence_fire", event.target.files[0], this.state.policyId ,false, this.formikRef, "fire.letterFormCivilDefenceDoc")
                                                                                }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`fire.letterFormCivilDefenceDoc`, "")
                                                                                        await this.removeFile(values?.fire.letterFormCivilDefenceDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`fire.letterFormCivilDefenceDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('municipal_certificate')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.fire.muncipalCertificateDoc?.file_name || ""}
                                                                                filePath={values.fire.muncipalCertificateDoc?.url || ""}
                                                                                data-test-id="txtInputmuncipalCertificateDoc"
                                                                                inputName={`fire.muncipalCertificateDoc`}
                                                                                onChange={(event: any) => {
                                                                                   this.uploadFileStageOne("municipal_certificate_fire", event.target.files[0], this.state.policyId ,false, this.formikRef, "fire.muncipalCertificateDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`fire.muncipalCertificateDoc`, "")
                                                                                        await this.removeFile(values?.fire.muncipalCertificateDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`fire.muncipalCertificateDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('survey_report')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.fire.surveyReportDoc?.file_name || ""}
                                                                                filePath={values.fire.surveyReportDoc?.url || ""}
                                                                                data-test-id="txtInputsurveyReportDoc"
                                                                                inputName={`fire.surveyReportDoc`}
                                                                                onChange={(event: any) => {
                                                                                   this.uploadFileStageOne("survey_report_fire", event.target.files[0], this.state.policyId ,false, this.formikRef, "fire.surveyReportDoc")
                                                                                }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`fire.surveyReportDoc`, "")
                                                                                        await this.removeFile(values?.fire.surveyReportDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`fire.surveyReportDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('fire_and_safety_contract')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.fire.fireAndSaftyContractDoc?.file_name || ""}
                                                                                filePath={values.fire.fireAndSaftyContractDoc?.url || ""}
                                                                                data-test-id="txtInputfireAndSaftyContractDoc"
                                                                                inputName={`fire.fireAndSaftyContractDoc`}
                                                                                onChange={(event: any) => {
                                                                                   this.uploadFileStageOne("fire_safety_contract_fire", event.target.files[0], this.state.policyId ,false, this.formikRef, "fire.fireAndSaftyContractDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`fire.fireAndSaftyContractDoc`, "")
                                                                                        await this.removeFile(values?.fire.fireAndSaftyContractDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`fire.fireAndSaftyContractDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('sample_pictures')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.fire.samplePicture?.file_name || ""}
                                                                                filePath={values.fire.samplePicture?.url|| ""}
                                                                                data-test-id="txtInputsamplePicture"
                                                                                inputName={`fire.samplePicture`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("sample_pictures_fire", event.target.files[0], this.state.policyId ,false, this.formikRef, "fire.samplePicture")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`fire.samplePicture`, "")
                                                                                        await this.removeFile(values?.fire.samplePicture?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`fire.samplePicture`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    )}
                                                    {/* Property */}
                                                    {this.showPropertyInsurance(values.insuranceType) && (
                                                        <Grid item xs={12}>
                                                            <Accordion disabled={!this.state.p1Submited} data-test-id='propertyAccordion' className={getErrorHighlightedAccordion(errors,touched,"property") ? classes.subErrorAccordionStyle : classes.subAccordionStyle} >
                                                                <AccordionSummary
                                                                    aria-expanded={true}
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography className={classes.accordianHeading} >{t('property')}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={6}>
                                                                            <LabelWithIcon label={t('number_of_employee_to_insure')} />
                                                                            <TextInput
                                                                                id={"numberOfEmployeeOfInsurer"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="property.numberOfEmployeeOfInsurer"
                                                                                type="number"
                                                                                disabled={!this.props.isExpanded}
                                                                                data-test-id="numberOfEmployeeOfInsurerTxtInput"
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`property.numberOfEmployeeOfInsurer`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <LabelWithIcon label={t('letter_from_civil_defence')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.property.letterFormCivilDefenceDoc?.file_name || ""}
                                                                                filePath={values.property.letterFormCivilDefenceDoc?.url || ""}
                                                                                data-test-id="txtInputletterFormCivilDefenceDoc"
                                                                                inputName={`property.letterFormCivilDefenceDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("letter_from_civil_defence_property", event.target.files[0], this.state.policyId ,false, this.formikRef, "property.letterFormCivilDefenceDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`property.letterFormCivilDefenceDoc`, "")
                                                                                        await this.removeFile(values?.property?.letterFormCivilDefenceDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`property.letterFormCivilDefenceDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    )}
                                                    {/* Engineering */}
                                                    {this.showEngineeringInsurance(values.insuranceType) && (
                                                        <Grid item xs={12}>
                                                            <Accordion disabled={!this.state.p1Submited} data-test-id='engineeringAccordion' 
                                                            className={getErrorHighlightedAccordion(errors,touched,"engineering") ? classes.subErrorAccordionStyle : classes.subAccordionStyle}>
                                                                <AccordionSummary
                                                                    aria-expanded={true}
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography className={classes.accordianHeading} >{t('engineering')}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('number_of_employee_to_insure')} />
                                                                            <TextInput
                                                                                id={"numberOfEmployeeOfInsurer"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="engineering.numberOfEmployeeOfInsurer"
                                                                                type="number"
                                                                                data-test-id="numberOfEmployeeOfInsurerTxtInput"
                                                                                disabled={!this.props.isExpanded}
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`engineering.numberOfEmployeeOfInsurer`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('gps_coordinates')} />
                                                                            <TextInput
                                                                                id={"gpsCordinate"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="engineering.gpsCordinate"
                                                                                type="text"
                                                                                disabled={!this.props.isExpanded}
                                                                                data-test-id="gpsCordinateTxtInput"
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`engineering.gpsCordinate`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('awarded_contract')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.engineering.awardContractDoc?.file_name || ""}
                                                                                filePath={values.engineering.awardContractDoc?.url || ""}
                                                                                data-test-id="txtInputawardContractDoc"
                                                                                inputName={`engineering.awardContractDoc`}
                                                                                onChange={(event: any) => {
                                                                                   this.uploadFileStageOne("awarded_contract_engineering", event.target.files[0], this.state.policyId ,false, this.formikRef, "engineering.awardContractDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`engineering.awardContractDoc`, "")
                                                                                        await this.removeFile(values?.engineering.awardContractDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`engineering.awardContractDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('bill_of_quantity')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.engineering.billOfQualityDoc?.file_name || ""}
                                                                                filePath={values.engineering.billOfQualityDoc?.url || ""}
                                                                                data-test-id="txtInputbillOfQualityDoc"
                                                                                inputName={`engineering.billOfQualityDoc`}
                                                                                onChange={(event: any) => {
                                                                                   this.uploadFileStageOne("bill_of_quantity_engineering", event.target.files[0], this.state.policyId ,false, this.formikRef, "engineering.billOfQualityDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`engineering.billOfQualityDoc`, "")
                                                                                        await this.removeFile(values?.engineering.billOfQualityDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`engineering.billOfQualityDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('time_bar_chart')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.engineering.timeBarChatDoc?.file_name || ""}
                                                                                filePath={values.engineering.timeBarChatDoc?.url || ""}
                                                                                data-test-id="txtInputtimeBarChatDoc"
                                                                                inputName={`engineering.timeBarChatDoc`}
                                                                                onChange={(event: any) => {
                                                                                        this.uploadFileStageOne("time_bar_chart_engineering", event.target.files[0], this.state.policyId ,false, this.formikRef, "engineering.timeBarChatDoc")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`engineering.timeBarChatDoc`, "")
                                                                                        await this.removeFile(values?.engineering.timeBarChatDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`engineering.timeBarChatDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    )}
                                                    {/* Marine */}
                                                    {this.showMarineInsurance(values.insuranceType) && (
                                                        <Grid item xs={12}>
                                                            <Accordion disabled={!this.state.p1Submited} data-test-id='marineAccordion' 
                                                            className={getErrorHighlightedAccordion(errors,touched,"marine") ? classes.subErrorAccordionStyle : classes.subAccordionStyle}>
                                                                <AccordionSummary
                                                                    aria-expanded={true}
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography className={classes.accordianHeading} >{t('marine')}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={6}>
                                                                            <LabelWithIcon label={t('number_of_employee_to_insure')} />
                                                                            <TextInput
                                                                                id={"numberOfEmployeeOfInsurer"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="marine.numberOfEmployeeOfInsurer"
                                                                                type="number"
                                                                                disabled={!this.props.isExpanded}
                                                                                data-test-id="numberOfEmployeeOfInsurerTxtInput"
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`marine.numberOfEmployeeOfInsurer`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    )}
                                                    {/* Protection & Savings */}
                                                    {this.showProtectionSavingInsurance(values.insuranceType) && (
                                                        <Grid item xs={12}>
                                                            <Accordion disabled={!this.state.p1Submited} data-test-id='protectionSavingAccordion' 
                                                            className={getErrorHighlightedAccordion(errors,touched,"protectionAndSaving") ? classes.subErrorAccordionStyle : classes.subAccordionStyle}>
                                                                <AccordionSummary
                                                                    aria-expanded={true}
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography className={classes.accordianHeading} >{t('protection_and_saving')}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('insurance_file')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.protectionAndSaving.insuranceFileDoc?.file_name || ""}
                                                                                filePath={values.protectionAndSaving.insuranceFileDoc?.url || ""}
                                                                                data-test-id="txtInputinsuranceFileDoc"
                                                                                inputName={`protectionAndSaving.insuranceFileDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("insurance_file_protection_saving", event.target.files[0], this.state.policyId ,false, this.formikRef, "protectionAndSaving.insuranceFileDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`protectionAndSaving.insuranceFileDoc`, "")
                                                                                        await this.removeFile(values?.protectionAndSaving.insuranceFileDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`protectionAndSaving.insuranceFileDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('gps_coordinates')} />
                                                                            <TextInput
                                                                                id={"gpsCordinate"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="protectionAndSaving.gpsCordinate"
                                                                                type="text"
                                                                                disabled={!this.props.isExpanded}
                                                                                data-test-id="gpsCordinateTxtInput"
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`protectionAndSaving.gpsCordinate`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('letter_from_civil_defence')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.protectionAndSaving.letterFormCivilDefenceDoc?.file_name || ""}
                                                                                filePath={values.protectionAndSaving.letterFormCivilDefenceDoc?.url || ""}
                                                                                data-test-id="txtInputletterFormCivilDefenceDoc"
                                                                                inputName={`protectionAndSaving.letterFormCivilDefenceDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("letter_from_civil_defence_protection_saving", event.target.files[0], this.state.policyId ,false, this.formikRef, "protectionAndSaving.letterFormCivilDefenceDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`protectionAndSaving.letterFormCivilDefenceDoc`, "")
                                                                                        await this.removeFile(values?.protectionAndSaving.letterFormCivilDefenceDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`protectionAndSaving.letterFormCivilDefenceDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('municipal_certificate')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.protectionAndSaving.muncipalCertificateDoc?.file_name || ""}
                                                                                filePath={values.protectionAndSaving.muncipalCertificateDoc?.url || ""}
                                                                                data-test-id="txtInputmuncipalCertificateDoc"
                                                                                inputName={`protectionAndSaving.muncipalCertificateDoc`}
                                                                                onChange={(event: any) => {
                                                                                        this.uploadFileStageOne("municipal_certificate_protection_saving", event.target.files[0], this.state.policyId ,false, this.formikRef,  "protectionAndSaving.muncipalCertificateDoc")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`protectionAndSaving.muncipalCertificateDoc`, "")
                                                                                        await this.removeFile(values?.protectionAndSaving.muncipalCertificateDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`protectionAndSaving.muncipalCertificateDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('survey_report')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.protectionAndSaving.surveyReportDoc?.file_name || ""}
                                                                                filePath={values.protectionAndSaving.surveyReportDoc?.url || ""}
                                                                                data-test-id="txtInputsurveyReportDoc"
                                                                                inputName={`protectionAndSaving.surveyReportDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("survey_report_protection_saving", event.target.files[0], this.state.policyId ,false, this.formikRef, "protectionAndSaving.surveyReportDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`protectionAndSaving.surveyReportDoc`, "")
                                                                                        await this.removeFile(values?.protectionAndSaving.surveyReportDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`protectionAndSaving.surveyReportDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('fire_and_safety_contract')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.protectionAndSaving.fireAndSaftyContractDoc?.file_name || ""}
                                                                                filePath={values.protectionAndSaving.fireAndSaftyContractDoc?.url || ""}
                                                                                data-test-id="txtInputfireAndSaftyContractDoc"
                                                                                inputName={`protectionAndSaving.fireAndSaftyContractDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("fire_safety_contract_protection_saving", event.target.files[0], this.state.policyId ,false, this.formikRef, "protectionAndSaving.fireAndSaftyContractDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`protectionAndSaving.fireAndSaftyContractDoc`, "")
                                                                                        await this.removeFile(values?.protectionAndSaving.fireAndSaftyContractDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`protectionAndSaving.fireAndSaftyContractDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('awarded_contract')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.protectionAndSaving.awardContractDoc?.file_name || ""}
                                                                                filePath={values.protectionAndSaving.awardContractDoc?.url || ""}
                                                                                data-test-id="txtInputawardContractDoc"
                                                                                inputName={`protectionAndSaving.awardContractDoc`}
                                                                                onChange={(event: any) => {
                                                                                        this.uploadFileStageOne("awarded_contract_protection_saving", event.target.files[0], this.state.policyId ,false, this.formikRef, "protectionAndSaving.awardContractDoc")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`protectionAndSaving.awardContractDoc`, "")
                                                                                        await this.removeFile(values?.protectionAndSaving.awardContractDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                    
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`protectionAndSaving.awardContractDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('bill_of_quantity')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.protectionAndSaving.billOfQualityDoc?.file_name || ""}
                                                                                filePath={values.protectionAndSaving.billOfQualityDoc?.url || ""}
                                                                                data-test-id="txtInputbillOfQualityDoc"
                                                                                inputName={`protectionAndSaving.billOfQualityDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("bill_of_quantity_protection_saving", event.target.files[0], this.state.policyId ,false, this.formikRef,"protectionAndSaving.billOfQualityDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`protectionAndSaving.billOfQualityDoc`, "")
                                                                                        await this.removeFile(values?.protectionAndSaving.billOfQualityDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`protectionAndSaving.billOfQualityDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('time_bar_chart')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.protectionAndSaving.timeBarChatDoc?.file_name || ""}
                                                                                filePath={values.protectionAndSaving.timeBarChatDoc?.url || ""}
                                                                                data-test-id="txtInputtimeBarChatDoc"
                                                                                inputName={`protectionAndSaving.timeBarChatDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("time_bar_chart_protection_saving", event.target.files[0], this.state.policyId ,false, this.formikRef, "protectionAndSaving.timeBarChatDoc")
                                                                                    
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`protectionAndSaving.timeBarChatDoc`, "")
                                                                                        await this.removeFile(values?.protectionAndSaving.timeBarChatDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`protectionAndSaving.timeBarChatDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('maintenance_history_record')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.protectionAndSaving.maintenanceHistoryRecordDoc?.file_name || ""}
                                                                                filePath={values.protectionAndSaving.maintenanceHistoryRecordDoc?.url || ""}
                                                                                data-test-id="txtInputmaintenanceHistoryRecordDoc"
                                                                                inputName={`protectionAndSaving.maintenanceHistoryRecordDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("maintenance_history_record_protection_saving", event.target.files[0], this.state.policyId ,false, this.formikRef, "protectionAndSaving.maintenanceHistoryRecordDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`protectionAndSaving.maintenanceHistoryRecordDoc`, "")
                                                                                        await this.removeFile(values?.protectionAndSaving.maintenanceHistoryRecordDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`protectionAndSaving.maintenanceHistoryRecordDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('sample_pictures')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.protectionAndSaving.samplePicture?.file_name || ""}
                                                                                filePath={values.protectionAndSaving.samplePicture?.url || ""}
                                                                                data-test-id="txtInputsamplePicture"
                                                                                inputName={`protectionAndSaving.samplePicture`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("sample_pictures_protection_saving", event.target.files[0], this.state.policyId ,false, this.formikRef, "protectionAndSaving.samplePicture")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`protectionAndSaving.samplePicture`, "")
                                                                                        await this.removeFile(values?.protectionAndSaving.samplePicture?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`protectionAndSaving.samplePicture`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    )}
                                                    {/* General Business */}
                                                    {this.showGeneralBusinessInsurance(values.insuranceType) && (
                                                        <Grid item xs={12}>
                                                            <Accordion disabled={!this.state.p1Submited} data-test-id='generalBusinessAccordion' 
                                                            className={getErrorHighlightedAccordion(errors,touched,"generalBusiness") ? classes.subErrorAccordionStyle : classes.subAccordionStyle}>
                                                                <AccordionSummary
                                                                    aria-expanded={true}
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography className={classes.accordianHeading} >{t('general_business')}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('insurance_file')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.generalBusiness.insuranceFileDoc?.file_name || ""}
                                                                                filePath={values.generalBusiness.insuranceFileDoc?.url || ""}
                                                                                data-test-id="txtInputinsuranceFileDoc"
                                                                                inputName={`generalBusiness.insuranceFileDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("insurance_file_general_business", event.target.files[0], this.state.policyId ,false, this.formikRef, "generalBusiness.insuranceFileDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`generalBusiness.insuranceFileDoc`, "")
                                                                                        await this.removeFile(values?.generalBusiness.insuranceFileDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`generalBusiness.insuranceFileDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('gps_coordinates')} />
                                                                            <TextInput
                                                                                id={"gpsCordinate"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="generalBusiness.gpsCordinate"
                                                                                type="text"
                                                                                data-test-id="gpsCordinateTxtInput"
                                                                                disabled={!this.props.isExpanded}
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`generalBusiness.gpsCordinate`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('letter_from_civil_defence')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.generalBusiness.letterFormCivilDefenceDoc?.file_name || ""}
                                                                                filePath={values.generalBusiness.letterFormCivilDefenceDoc?.url || ""}
                                                                                data-test-id="txtInputletterFormCivilDefenceDoc"
                                                                                inputName={`generalBusiness.letterFormCivilDefenceDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("letter_from_civil_defence_general_business", event.target.files[0], this.state.policyId ,false, this.formikRef, "generalBusiness.letterFormCivilDefenceDoc")
                                                                                    
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`generalBusiness.letterFormCivilDefenceDoc`, "")
                                                                                        await this.removeFile(values?.generalBusiness.letterFormCivilDefenceDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`generalBusiness.letterFormCivilDefenceDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('municipal_certificate')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.generalBusiness.muncipalCertificateDoc?.file_name || ""}
                                                                                filePath={values.generalBusiness.muncipalCertificateDoc?.url || ""}
                                                                                data-test-id="txtInputmuncipalCertificateDoc"
                                                                                inputName={`generalBusiness.muncipalCertificateDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("municipal_certificate_general_business", event.target.files[0], this.state.policyId ,false,this.formikRef ,"generalBusiness.muncipalCertificateDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`generalBusiness.muncipalCertificateDoc`, "")
                                                                                        await this.removeFile(values?.generalBusiness.muncipalCertificateDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`generalBusiness.muncipalCertificateDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('survey_report')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.generalBusiness.surveyReportDoc?.file_name || ""}
                                                                                filePath={values.generalBusiness.surveyReportDoc?.url || ""}
                                                                                data-test-id="txtInputsurveyReportDoc"
                                                                                inputName={`generalBusiness.surveyReportDoc`}
                                                                                onChange={(event: any) => {
                                                                                        this.uploadFileStageOne("survey_report_general_business", event.target.files[0], this.state.policyId ,false,this.formikRef, "generalBusiness.surveyReportDoc")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`generalBusiness.surveyReportDoc`, "")
                                                                                        await this.removeFile(values?.generalBusiness.surveyReportDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`generalBusiness.surveyReportDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('fire_and_safety_contract')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.generalBusiness.fireAndSaftyContractDoc?.file_name || ""}
                                                                                filePath={values.generalBusiness.fireAndSaftyContractDoc?.url || ""}
                                                                                data-test-id="txtInputfireAndSaftyContractDoc"
                                                                                inputName={`generalBusiness.fireAndSaftyContractDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("fire_safety_contract_general_business", event.target.files[0], this.state.policyId ,false, this.formikRef, "generalBusiness.fireAndSaftyContractDoc")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`generalBusiness.fireAndSaftyContractDoc`, "")
                                                                                        await this.removeFile(values?.generalBusiness.fireAndSaftyContractDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`generalBusiness.fireAndSaftyContractDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('awarded_contract')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.generalBusiness.awardContractDoc?.file_name || ""}
                                                                                filePath={values.generalBusiness.awardContractDoc?.url || ""}
                                                                                data-test-id="txtInputawardContractDoc"
                                                                                inputName={`generalBusiness.awardContractDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("awarded_contract_general_business", event.target.files[0], this.state.policyId ,false, this.formikRef, "generalBusiness.awardContractDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`generalBusiness.awardContractDoc`, "")
                                                                                        await this.removeFile(values?.generalBusiness.awardContractDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`generalBusiness.awardContractDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('bill_of_quantity')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.generalBusiness.billOfQualityDoc?.file_name || ""}
                                                                                filePath={values.generalBusiness.billOfQualityDoc?.url || ""}
                                                                                data-test-id="txtInputbillOfQualityDoc"
                                                                                inputName={`generalBusiness.billOfQualityDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("bill_of_quantity_general_business", event.target.files[0], this.state.policyId ,false, this.formikRef, "generalBusiness.billOfQualityDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`generalBusiness.billOfQualityDoc`, "")
                                                                                        await this.removeFile(values?.generalBusiness.billOfQualityDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`generalBusiness.billOfQualityDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('time_bar_chart')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.generalBusiness.timeBarChatDoc?.file_name || ""}
                                                                                filePath={values.generalBusiness.timeBarChatDoc?.url || ""}
                                                                                data-test-id="txtInputtimeBarChatDoc"
                                                                                inputName={`generalBusiness.timeBarChatDoc`}
                                                                                onChange={(event: any) => {
                                                                                        this.uploadFileStageOne("time_bar_chart_general_business", event.target.files[0], this.state.policyId ,false, this.formikRef, "generalBusiness.timeBarChatDoc")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`generalBusiness.timeBarChatDoc`, "")
                                                                                        await this.removeFile(values?.generalBusiness?.timeBarChatDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`generalBusiness.timeBarChatDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('maintenance_history_record')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.generalBusiness.maintenanceHistoryRecordDoc?.file_name || ""}
                                                                                filePath={values.generalBusiness.maintenanceHistoryRecordDoc?.url || ""}
                                                                                data-test-id="txtInputmaintenanceHistoryRecordDoc"
                                                                                inputName={`generalBusiness.maintenanceHistoryRecordDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("maintenance_history_record_general_business", event.target.files[0], this.state.policyId ,false, this.formikRef, "generalBusiness.maintenanceHistoryRecordDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`generalBusiness.maintenanceHistoryRecordDoc`, "")
                                                                                        await this.removeFile(values?.generalBusiness?.maintenanceHistoryRecordDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`generalBusiness.maintenanceHistoryRecordDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('sample_pictures')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.generalBusiness.samplePicture?.file_name || ""}
                                                                                filePath={values.generalBusiness.samplePicture?.url || ""}
                                                                                data-test-id="txtInputsamplePicture"
                                                                                inputName={`generalBusiness.samplePicture`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("sample_pictures_general_business", event.target.files[0], this.state.policyId ,false, this.formikRef, "generalBusiness.samplePicture")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`generalBusiness.samplePicture`, "")
                                                                                        await this.removeFile(values?.generalBusiness?.samplePicture?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`generalBusiness.samplePicture`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    )}
                                                    {/* Aviation */}
                                                    {this.showAviatoinInsurance(values.insuranceType) && (
                                                        <Grid item xs={12}>
                                                            <Accordion disabled={!this.state.p1Submited} data-test-id='aviationAccordion' 
                                                            className={getErrorHighlightedAccordion(errors,touched,"aviation") ? classes.subErrorAccordionStyle : classes.subAccordionStyle}>
                                                                <AccordionSummary
                                                                    aria-expanded={true}
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography className={classes.accordianHeading} >{t('aviation')}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('insurance_file')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.aviation.insuranceFileDoc?.file_name || ""}
                                                                                filePath={values.aviation.insuranceFileDoc?.url || ""}
                                                                                data-test-id="txtInputinsuranceFileDoc"
                                                                                inputName={`aviation.insuranceFileDoc`}
                                                                                onChange={(event: any) => {
                                                                                   this.uploadFileStageOne("insurance_file_aviation", event.target.files[0], this.state.policyId ,false, this.formikRef, "aviation.insuranceFileDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`aviation.insuranceFileDoc`, "")
                                                                                        await this.removeFile(values?.aviation?.insuranceFileDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`aviation.insuranceFileDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('gps_coordinates')} />
                                                                            <TextInput
                                                                                id={"gpsCordinate"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="aviation.gpsCordinate"
                                                                                type="text"
                                                                                data-test-id="gpsCordinateTxtInput"
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`aviation.gpsCordinate`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('letter_from_civil_defence')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.aviation.letterFormCivilDefenceDoc?.file_name || ""}
                                                                                filePath={values.aviation.letterFormCivilDefenceDoc?.url || ""}
                                                                                data-test-id="txtInputletterFormCivilDefenceDoc"
                                                                                inputName={`aviation.letterFormCivilDefenceDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("insurance_file_aviation", event.target.files[0], this.state.policyId ,false, this.formikRef, "aviation.letterFormCivilDefenceDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`aviation.letterFormCivilDefenceDoc`, "")
                                                                                        await this.removeFile(values?.aviation?.letterFormCivilDefenceDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`aviation.letterFormCivilDefenceDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('municipal_certificate')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.aviation.muncipalCertificateDoc?.file_name || ""}
                                                                                filePath={values.aviation.muncipalCertificateDoc?.url || ""}
                                                                                data-test-id="txtInputmuncipalCertificateDoc"
                                                                                inputName={`aviation.muncipalCertificateDoc`}
                                                                                onChange={(event: any) => {
                                                                                        this.uploadFileStageOne("insurance_file_aviation", event.target.files[0], this.state.policyId ,false, this.formikRef, "aviation.muncipalCertificateDoc")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`aviation.muncipalCertificateDoc`, "")
                                                                                        await this.removeFile(values?.aviation?.muncipalCertificateDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`aviation.muncipalCertificateDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('survey_report')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.aviation.surveyReportDoc?.file_name || ""}
                                                                                filePath={values.aviation.surveyReportDoc?.url || ""}
                                                                                data-test-id="txtInputsurveyReportDoc"
                                                                                inputName={`aviation.surveyReportDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("insurance_file_aviation", event.target.files[0], this.state.policyId ,false, this.formikRef, "aviation.surveyReportDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`aviation.surveyReportDoc`, "")
                                                                                        await this.removeFile(values?.aviation?.surveyReportDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`aviation.surveyReportDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('fire_and_safety_contract')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.aviation.fireAndSaftyContractDoc?.file_name || ""}
                                                                                filePath={values.aviation.fireAndSaftyContractDoc?.url || ""}
                                                                                data-test-id="txtInputfireAndSaftyContractDoc"
                                                                                inputName={`aviation.fireAndSaftyContractDoc`}
                                                                                onChange={(event: any) => {
                                                                                        this.uploadFileStageOne("fire_safety_contract_aviation", event.target.files[0], this.state.policyId ,false, this.formikRef, "aviation.fireAndSaftyContractDoc")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`aviation.fireAndSaftyContractDoc`, "")
                                                                                        await this.removeFile(values?.aviation?.fireAndSaftyContractDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`aviation.fireAndSaftyContractDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('awarded_contract')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.aviation.awardContractDoc?.file_name || ""}
                                                                                filePath={values.aviation.awardContractDoc?.url || ""}
                                                                                data-test-id="txtInputawardContractDoc"
                                                                                inputName={`aviation.awardContractDoc`}
                                                                                onChange={(event: any) => {
                                                                                        this.uploadFileStageOne("awarded_contract_aviation", event.target.files[0], this.state.policyId ,false, this.formikRef, "aviation.awardContractDoc")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`aviation.awardContractDoc`, "")
                                                                                        await this.removeFile(values?.aviation?.awardContractDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`aviation.awardContractDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('bill_of_quantity')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.aviation.billOfQualityDoc?.file_name || ""}
                                                                                filePath={values.aviation.billOfQualityDoc?.url || ""}
                                                                                data-test-id="txtInputbillOfQualityDoc"
                                                                                inputName={`aviation.billOfQualityDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("bill_of_quantity_aviation", event.target.files[0], this.state.policyId ,false,this.formikRef, "aviation.billOfQualityDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`aviation.billOfQualityDoc`, "")
                                                                                        await this.removeFile(values?.aviation?.billOfQualityDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`aviation.billOfQualityDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('time_bar_chart')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.aviation.timeBarChatDoc?.file_name || ""}
                                                                                filePath={values.aviation.timeBarChatDoc?.url || ""}
                                                                                data-test-id="txtInputtimeBarChatDoc"
                                                                                inputName={`aviation.timeBarChatDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("time_bar_chart_aviation", event.target.files[0], this.state.policyId ,false, this.formikRef, "aviation.timeBarChatDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`aviation.timeBarChatDoc`, "")
                                                                                        await this.removeFile(values?.aviation?.timeBarChatDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`aviation.timeBarChatDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('maintenance_history_record')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.aviation.maintenanceHistoryRecordDoc?.file_name || ""}
                                                                                filePath={values.aviation.maintenanceHistoryRecordDoc?.url || ""}
                                                                                data-test-id="txtInputmaintenanceHistoryRecordDoc"
                                                                                inputName={`aviation.maintenanceHistoryRecordDoc`}
                                                                                onChange={(event: any) => {
                                                                                   this.uploadFileStageOne("maintenance_history_record_aviation", event.target.files[0], this.state.policyId ,false, this.formikRef, "aviation.maintenanceHistoryRecordDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`aviation.maintenanceHistoryRecordDoc`, "")
                                                                                        await this.removeFile(values?.aviation?.maintenanceHistoryRecordDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`aviation.maintenanceHistoryRecordDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('sample_pictures')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.aviation.samplePicture?.file_name || ""}
                                                                                filePath={values.aviation.samplePicture?.url || ""}
                                                                                data-test-id="txtInputsamplePicture"
                                                                                inputName={`aviation.samplePicture`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("sample_pictures_aviation", event.target.files[0], this.state.policyId ,false, this.formikRef, "aviation.samplePicture")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`aviation.samplePicture`, "")
                                                                                        await this.removeFile(values?.aviation?.samplePicture?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`aviation.samplePicture`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    )}
                                                    {/* Energy */}
                                                    {this.showEnergyInsurance(values.insuranceType) && (
                                                        <Grid item xs={12}>
                                                            <Accordion disabled={!this.state.p1Submited} data-test-id='energyAccordion' 
                                                            className={getErrorHighlightedAccordion(errors,touched,"energy") ? classes.subErrorAccordionStyle : classes.subAccordionStyle}>
                                                                <AccordionSummary
                                                                    aria-expanded={true}
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography className={classes.accordianHeading} >{t('energy')}</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Grid container spacing={2} >
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('insurance_file')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.energy.insuranceFileDoc?.file_name || ""}
                                                                                filePath={values.energy.insuranceFileDoc?.url || ""}
                                                                                data-test-id="txtInputinsuranceFileDoc"
                                                                                inputName={`energy.insuranceFileDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("insurance_file_energy", event.target.files[0], this.state.policyId ,false, this.formikRef, "energy.insuranceFileDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`energy.insuranceFileDoc`, "")
                                                                                        await this.removeFile(values?.energy?.insuranceFileDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`energy.insuranceFileDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('gps_coordinates')} />
                                                                            <TextInput
                                                                                id={"gpsCordinate"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                                name="energy.gpsCordinate"
                                                                                type="text"
                                                                                disabled={!this.props.isExpanded}
                                                                                data-test-id="gpsCordinateTxtInput"
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`energy.gpsCordinate`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('letter_from_civil_defence')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.energy.letterFormCivilDefenceDoc?.file_name || ""}
                                                                                filePath={values.energy.letterFormCivilDefenceDoc?.url || ""}
                                                                                data-test-id="txtInputletterFormCivilDefenceDoc"
                                                                                inputName={`energy.letterFormCivilDefenceDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("letter_from_civil_defence_energy", event.target.files[0], this.state.policyId ,false, this.formikRef, "energy.letterFormCivilDefenceDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`energy.letterFormCivilDefenceDoc`, "")
                                                                                        await this.removeFile(values?.energy?.letterFormCivilDefenceDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`energy.letterFormCivilDefenceDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('municipal_certificate')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.energy.muncipalCertificateDoc?.file_name || ""}
                                                                                filePath={values.energy.muncipalCertificateDoc?.url || ""}
                                                                                data-test-id="txtInputmuncipalCertificateDoc"
                                                                                inputName={`energy.muncipalCertificateDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("municipal_certificate_energy", event.target.files[0], this.state.policyId ,false, this.formikRef, "energy.muncipalCertificateDoc")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`energy.muncipalCertificateDoc`, "")
                                                                                        await this.removeFile(values?.energy?.muncipalCertificateDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`energy.muncipalCertificateDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('survey_report')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.energy.surveyReportDoc?.file_name || ""}
                                                                                filePath={values.energy.surveyReportDoc?.url || ""}
                                                                                data-test-id="txtInputsurveyReportDoc"
                                                                                inputName={`energy.surveyReportDoc`}
                                                                                onChange={(event: any) => {
                                                                                   this.uploadFileStageOne("survey_report_energy", event.target.files[0], this.state.policyId ,false, this.formikRef, "energy.surveyReportDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`energy.surveyReportDoc`, "")
                                                                                        await this.removeFile(values?.energy?.surveyReportDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`energy.surveyReportDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('fire_and_safety_contract')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.energy.fireAndSaftyContractDoc?.file_name || ""}
                                                                                filePath={values.energy.fireAndSaftyContractDoc?.url || ""}
                                                                                data-test-id="txtInputfireAndSaftyContractDoc"
                                                                                inputName={`energy.fireAndSaftyContractDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("fire_safety_contract_energy", event.target.files[0], this.state.policyId ,false, this.formikRef, "energy.fireAndSaftyContractDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`energy.fireAndSaftyContractDoc`, "")
                                                                                        await this.removeFile(values?.energy?.fireAndSaftyContractDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`energy.fireAndSaftyContractDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('awarded_contract')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.energy.awardContractDoc?.file_name || ""}
                                                                                filePath={values.energy.awardContractDoc?.url || ""}
                                                                                data-test-id="txtInputawardContractDoc"
                                                                                inputName={`energy.awardContractDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("awarded_contract_energy", event.target.files[0], this.state.policyId ,false, this.formikRef, "energy.awardContractDoc")                                                                                    }
                                                                                } 
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`energy.awardContractDoc`, "")
                                                                                        await this.removeFile(values?.energy?.awardContractDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`energy.awardContractDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('bill_of_quantity')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.energy.billOfQualityDoc?.file_name || ""}
                                                                                filePath={values.energy.billOfQualityDoc?.url || ""}
                                                                                data-test-id="txtInputbillOfQualityDoc"
                                                                                inputName={`energy.billOfQualityDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("bill_of_quantity_energy", event.target.files[0], this.state.policyId ,false, this.formikRef, "energy.billOfQualityDoc")                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`energy.billOfQualityDoc`, "")
                                                                                        await this.removeFile(values?.energy?.billOfQualityDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`energy.billOfQualityDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('time_bar_chart')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.energy.timeBarChatDoc?.file_name || ""}
                                                                                filePath={values.energy.timeBarChatDoc?.url || ""}
                                                                                data-test-id="txtInputtimeBarChatDoc"
                                                                                inputName={`energy.timeBarChatDoc`}
                                                                                onChange={(event: any) => {
                                                                                    this.uploadFileStageOne("time_bar_chart_energy", event.target.files[0], this.state.policyId ,false, this.formikRef, "energy.timeBarChatDoc")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`energy.timeBarChatDoc`, "")
                                                                                        await this.removeFile(values?.energy?.timeBarChatDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`energy.timeBarChatDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('maintenance_history_record')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.energy.maintenanceHistoryRecordDoc?.file_name || ""}
                                                                                filePath={values.energy.maintenanceHistoryRecordDoc?.url || ""}
                                                                                data-test-id="txtInputmaintenanceHistoryRecordDoc"
                                                                                inputName={`energy.maintenanceHistoryRecordDoc`}
                                                                                onChange={(event: any) => {
                                                                                     this.uploadFileStageOne("maintenance_history_record_energy", event.target.files[0], this.state.policyId ,false, this.formikRef, "energy.maintenanceHistoryRecordDoc")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`energy.maintenanceHistoryRecordDoc`, "")
                                                                                        await this.removeFile(values?.energy?.maintenanceHistoryRecordDoc?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`energy.maintenanceHistoryRecordDoc`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>

                                                                        <Grid item xs={4}>
                                                                            <LabelWithIcon label={t('sample_pictures')} />
                                                                            <FileUpload
                                                                                eachFileSizeInMB={10}
                                                                                maxFilesNumber={5}
                                                                                fileName={values.energy.samplePicture?.file_name || ""}
                                                                                filePath={values.energy.samplePicture?.url || ""}
                                                                                data-test-id="txtInputsamplePicture"
                                                                                inputName={`energy.samplePicture`}
                                                                                onChange={(event: any) => {
                                                                                        this.uploadFileStageOne("sample_pictures_energy", event.target.files[0], this.state.policyId ,false, this.formikRef ,"energy.samplePicture")
                                                                                    }
                                                                                }
                                                                                disabled={!this.props.isExpanded}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        setFieldValue(`energy.samplePicture`, "")
                                                                                        await this.removeFile(values?.energy?.samplePicture?.id, this.formikRef)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {/* @ts-ignore */}
                                                                            <ErrorMessage
                                                                                name={`energy.samplePicture`}
                                                                                className="field-error"
                                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                        
                                        {(this.state.expandedAccordion === "p2" && !stageOne?.partOneResponse?.data?.attributes?.lost_reason) && (this.props.isExpanded) ? (
                                            <div className={classes.BtnGroup}>
                                                <div>
                                                    <CustomButton
                                                        btnText={t('mark_as_lost')}
                                                        onClick={() => { this.showMarkAsLostDialog() }}
                                                        textColor="#2d6f8f"
                                                        bgColor="white"
                                                    />
                                                </div>
                                                <div className={classes.buttonsRightWrapper}>
                                                    
                                                    <CustomButton
                                                        btnText={t('save_draft')}
                                                        onClick={() => {
                                                            const stageOneData =  JSON.parse(localStorage.getItem('stageOneData') || '{}')
                                                            this.savePolicyAsDraft(stageOneData?.attributes?.is_rfq_input_submitted,values)
                                                        }}
                                                        textColor="#2d6f8f"
                                                        bgColor="white"
                                                        loading={this.state.saveDraftLoading}
                                                    />
                                            
                                                    <CustomButton
                                                        btnText={t('next_stage')}
                                                        onClick={() => { handleSubmit() }}
                                                        textColor="white"
                                                        loading={this.state.loading}
                                                        bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                                    />
                                                </div>
                                            </div>
                                        ) : null
                                        }
                                    </React.Fragment>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <Accordion disabled={!this.state.p1Submited} data-test-id='p2' className={((this.state.errorSection === 'p2') && classes.errorSection) ? classes.errorSection : classes.accordionStyle} expanded={(this.state.expandedAccordion === "p2") && (this.props.isExpanded)} onChange={(event: any, isExpanded: boolean) => {
                            if (this.state.p1Submited) {
                                this.handleAccordian(isExpanded, "p2")
                            }
                        }} >
                            <AccordionSummary
                                aria-expanded={true}
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                               <AccordionTitle title={t('rfq_input')} policyId={this.state.policyId} isLock={!this.state.p1Submited} />
                            </AccordionSummary>
                            <AccordionDetails>
                            { this.state.policyId && "Please fill all details in overview form first" }
                            </AccordionDetails>
                        </Accordion>
                    )}

                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showHandOverNote as boolean}
                        textId={'handOverNoteText'}
                        headingText={this.state.markAsLostDialog ? t('mark_as_lost') : t('handover_note')}
                        subHeading={this.state.markAsLostDialog ? "" :`(${t('info_gathering')} > ${t('processing')})`}
                        handleInputText={(value:string)=>{this.setState({handOverNoteText:value})}}
                        inputValue={this.state.isBtnVisible ? this.state.handOverNoteText : (this.state.markAsLostDialog ? stageOne?.partOneResponse?.data?.attributes?.lost_reason : '')}
                        inputLable={this.state.showHandOverNote ? t('note') : ""}
                        handleOk={(value:string)=> this.state.markAsLostDialog ? this.markPolicyAsLost(value) : this.handOverNoteSubmit(value)}
                        handleCancel={() => this.handleHandOverNoteDialog(false,false)}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                    />

                    <RejectRequestDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showRejectDialog as boolean}
                        textId={'rejectReason'}
                        inputValue={stageOneData?.partOneResponse?.data?.attributes?.rejected_reason}
                        headingText={t('rejecting')}
                        showDialogBtns={false}
                        showCrossBtn={true}
                        handleCancel={() => this.setState({showRejectDialog: false})}
                        reasonLabel={t('reason')}
                    /> 

                </React.Fragment>

            </ThemeProvider >
        )
    }
}
const useStyles = ({
    saveBtnDiv: {
        marginTop: "28px"
    },
    insuranceTypeStyle: {
        "padding-bottom": "0px !important"
    },
    loadingBtn:{
        background:"linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        textColor:"white"
    },
    customerDetailsAccordion: {
        paddingTop: "10px",
        paddingBottom: "10px",
        "& .MuiAccordion-root-34.Mui-disabled, .MuiAccordion-root-42.Mui-disabled": {
            backgroundColor: "white",
        },
        " & .MuiAccordion-root.Mui-disabled": {
            backgroundColor: "white",
        }
    },
    primaryBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "height": "50px",
        "width": "150px",
        borderRadius: "10px"
    },
    BtnGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 15px'
    },
    buttonsRightWrapper: {
        display: 'flex',
        gap: '15px',
        padding: '15px 0px',
        justifyContent: 'flex-end'
    },
    outlineBtn:{ color: '#3b8da2', "text-transform": "none" },
    headerButtonStyle: {
        display: "flex",
        justifyContent: "end"
    },
    saveBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "height": "55px",
        "width": "150px",
        "margin-top": "10px",
        borderRadius: "10px"
    },
    uploadCsvBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "height": "55px",
        "width": "200px",
        marginTop:"35px",
        borderRadius: "10px"
    },
    fireCsvBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "height": "55px",
        "width": "295px",
        marginTop:"10px",
        borderRadius: "10px"
    },
    displayFlexEnd: { display: "flex", justifyContent: "end" },
    cancelBtn: {
        marginRight: "10px",
        marginLeft: "10px",
        background: "white",
        borderRadius: "10px",
        "text-transform": "none",
        "height": "50px",
        "width": "150px",
    },
    errorSection: {
        border: "1px solid red",
        "border-radius":"10px !important"
    },
    contactDetailDiv: {
        paddingTop:"20px"
    },
    accordionStyle: {
        background: "white !important",
        "border-radius":"10px !important"
    },
    subAccordionStyle: {
        background: "white !important",
        border: "1px solid lightgray"
    },
    subErrorAccordionStyle: {
        background: "white !important",
        border: "1px solid red"
    },
    accordianHeading: {
        fontWeight: 600
    },
    displayFlex: {
        display: "flex",
    },
    contactForm: { width: "100%" },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    toggleContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    divierStyle: {
        marginBottom: "10px",
        fontWeight: 500
    },
    radioStyle: {
        display: "flex",
        height: "55px",
        paddingTop: "25px"
    },
    noteMessage: {
        padding: "0px 8px",
        fontSize: "12px"
    },
    redAlertMessage: {
        backgroundColor: "#ec5655",
        color: "white",
        width: 'auto',
        height: "55px"
    },
    stageBtn:{
        diplay:"flex",
        justifyContant:"end"
    },
    greenAlertMessage: {
        backgroundColor: "#028f5d",
        color: "white",
        width: 'auto',
        height: "55px"
    },
    viewDetailBtn:{
        marginRight: "10px", 
        marginLeft:"10px" ,
        background: "white", 
        borderRadius: "10px" 
       },
    alertCloseIconLeft: {
        color: "white",
        justifyContent: "end",
        cursor: "pointer",
        marginLeft: "20px"
    },
    alertCloseIconRight: {
        color: "white",
        justifyContent: "end",
        cursor: "pointer",
        marginRight: "20px"
    },
    alertIconStyle: {
        color: "white"
    },
    alertDivWithSearch: {
        display: "flex",
        "justify-content": "space-between",
    },
    alertDiv: {
        "display": "flex",
        "justify-content": "end",
        "padding-bottom": "15px",
    },
    alertCloseIcon: {
        color: "white",
        cursor: "pointer",
        paddingLeft: "10px"
    },
    viewDetial:{
        textDecoration:"none",
        "text-transform":"none",
        color:"white"
      }
});

export default withStyles(useStyles)(withTranslation()(StageOneForm));