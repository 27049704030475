import React from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { createTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const theme = createTheme();

interface IAddDropdownButton extends RouteComponentProps {
  buttonText: string;
  route?: string;
  moveToSelected: any;
  dropDownList: Array<{
    label: string;
    value: string;
    navigate: boolean;
  }>;
}

const DropdownButton = (props: IAddDropdownButton) => {
  const { history, buttonText, dropDownList } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (val: string) => {
    props.moveToSelected(val)
    // history.push(route);
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        className={classes.btnText}
      >
        {t(buttonText)}
      </Button>
      {/* @ts-ignore */}
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {dropDownList.map((list,index) =>
          list.navigate ? (
            <MenuItem key={`${list.value}-${index}`} onClick={() => handleNavigate(list.value)}>{t(list.value)}</MenuItem>
          ) : (
            <MenuItem  key={`${list.value}-${index}`}>{t(list.value)}</MenuItem>
          )
        )}
      </StyledMenu>
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    btnText: {
      background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
      color: "white",
      width: "155px",
      minWidth: "fit-content",
      height: "51px",
      borderRadius: "7px",
      borderStyle: "none",
      "&": {
        color: "#fff",
        fontWeight: "600",
        padding: "8px 15px",
        "text-transform": "capitalize",
      },
    },
  })
);

export default withRouter(DropdownButton);
