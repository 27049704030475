import { Accordion, AccordionDetails, AccordionSummary, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, Radio, RadioGroup, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { ErrorMessage } from 'formik'
import React from 'react'
import FileUpload from '../Common/FileUpload'
import LabelWithIcon from '../Common/LabelWithIcon'
import TextInput from '../Common/TextInput'
import Toggle from '../Common/Toggle/Toggle'
import { makeStyles, createStyles } from '@material-ui/core/styles';

const InsurerQuotationApprovalAccordian = ({ customerReleaseData,policyId,uploadFileStageSix,removeFile, values, isArabicLanguage, setFieldValue, touched, errors ,index,fouthStageData,edit,isExpanded, t}: any) => {
    const [isApproved, setIsApproved] = React.useState(false);
    const [isExpandAcordian,setIsExpandAcordian] = React.useState(false)
    const classes = useStyles();
    const handelApprovedCustomer = (event: React.MouseEvent<HTMLElement>,) => {
       if(customerReleaseData.release_quotation_to_customer ) 
       { event.stopPropagation()
        setIsApproved(!isApproved)
        //values.stageSixDetails.filter((filter:any)=>filter.approval==true).length==0 &&  
         setFieldValue(`stageSixDetails[${index}].approval`, !values.stageSixDetails[index].approval)
         values.stageSixDetails.map((item:any,indexValue:number)=>{if(indexValue!=index){
            return item.approval=false
         }})} 
    }
    const handleAccordion = ()=>{
        setIsExpandAcordian(!isExpandAcordian) 
    }
   

    
    const enumpaid_to = {
        SIIB: "Payment to SIIB",
        InC: "Payment to Insurance company",
    }
    const enumBtnType = {
        NEXT: 'NEXT',
        DRAFT: 'DRAFT',
    }
    
    const enumRoles = {
        Operations:"Operations"
    }
    


    return (
        
        <div className={classes.accordionWrapper}>
            <div className={classes.childAccordion}>
            <Accordion  expanded={isExpandAcordian}  >
                <AccordionSummary
                    aria-expanded={true}
                    expandIcon={<ExpandMore onClick={handleAccordion}/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                   
                  
                >
                    <div className={classes.toggleContainer}>
                        <Typography onClick={handleAccordion} className={classes.accordianHeading}>
                            {customerReleaseData?.label}
                        </Typography>
                     <div className={classes.approveContainer}> <Typography className={classes.accordianHeading}>
                           { t('approve')}
                        </Typography> <Toggle   isChecked={customerReleaseData?.approval} handleToggle={handelApprovedCustomer} isDisabled={edit || !isExpanded ? true : false} /></div>  
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {
                             customerReleaseData.release_quotation_to_customer &&
                            <>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('quotation_amount')} />
                                    <TextInput
                                        id={`stageSixDetails[${index}].quotationAmount`}
                                        className={classes.textInputStyle}
                                        isArabicLenguage={isArabicLanguage}
                                        name={`stageSixDetails[${index}].quotationAmount`}
                                        type="text"
                                        disabled={true}
                                        data-test-id="quatationAmountTxtInput"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('proposal')} />
                                    <FileUpload 
                                     disabled={true}
                                     filePath={values.stageSixDetails[index].proposal  || ""}
                                     fileName={values.stageSixDetails[index].proposal_file_name || ""}
                                     // disabled={!this.state.enableEditing}
                                     inputName={`stageSixDetails[${index}].proposal`}
                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('additional_attechment')} />
                                    <FileUpload 
                                     disabled={true}
                                     filePath={values.stageSixDetails[index].addtitional_attachment  || ""}
                                     fileName={values.stageSixDetails[index].addtitional_attachment_file_name || ""}
                                     // disabled={!this.state.enableEditing}
                                     inputName={`stageSixDetails[${index}].addtitional_attachment`}
                                    />
                                </Grid>
                            </>
                        }
                          <Grid item xs={12}>
                                    <LabelWithIcon label={t('extra_info')} />
                                    <TextInput
                                id={`stageSixDetails[${index}].note_to_the_customer`}
                                className={classes.textInputStyle}
                                isArabicLenguage={isArabicLanguage}
                                name={`stageSixDetails[${index}].note_to_the_customer`}
                                type="text"
                                disabled={true}
                                data-test-id={`stageSixDetails[${index}].note_to_the_customer`}
                                multiline
                                minRows={4}
                                maxRows={4}
                            />
                                </Grid>
                      <Divider />
                        {
                            customerReleaseData.approval &&
                            <>
                            <Grid item xs={4}>
                                <LabelWithIcon label={t('firm_order')} />
                                <FileUpload
                                    filePath={values.stageSixDetails[index].firm_order?.url || ""}
                                    fileName={values.stageSixDetails[index]?.firm_order_file_name || ""}
                                 disabled={(edit || !isExpanded) ?true :false }
                                   inputName={`stageSixDetails[${index}].firm_order`}
                                    onChange={(event: any) => {
                                  let res:any=  uploadFileStageSix("firm_order", event.target.files[0], policyId , customerReleaseData.insurance_company_id,`stageSixDetails[${index}].firm_order`)
                                    if(res){
                                        setFieldValue(`stageSixDetails[${index}].firm_order`,event.target.files[0])
                                    }else{
                                        setFieldValue(`stageSixDetails[${index}].firm_order`,{})
                                    }                             
                                }}
                                    onRemove={async() => {await setFieldValue(`stageSixDetails[${index}].firm_order`, {})
                                    await removeFile(values?.stageSixDetails[index]?.firm_order?.id)
                                    }} 
                                    />
                                
                                <ErrorMessage
                                    name={`stageSixDetails[${index}].firm_order`}
                                    className="field-error"
                                    render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                 </Grid>
                                  <Grid item xs={4}>
                                    <LabelWithIcon label={t('vat_certificate')} />
                                    <FileUpload
                                        filePath={values.stageSixDetails[index]?.vat_certificate?.url  || ""}
                                        fileName={values.stageSixDetails[index].vat_certificate_file_name || ""}
                                        disabled={edit || !isExpanded ? true : false}
                                        inputName={`stageSixDetails[${index}].vat_certificate`}
                                        onChange={(event: any) => {
    
                                           let res:any=  uploadFileStageSix("vat_certificate", event.target.files[0], policyId , customerReleaseData.insurance_company_id,`stageSixDetails[${index}].vat_certificate`)
                                            if(res){
                                                setFieldValue(`stageSixDetails[${index}].vat_certificate`,event.target.files[0])
                                            }else{
                                                setFieldValue(`stageSixDetails[${index}].vat_certificate`,{})
                                            }
                                        }}
                                        onRemove={async() => {await setFieldValue(`stageSixDetails[${index}].vat_certificate`, {})
                                        await removeFile(values.stageSixDetails[index].vat_certificate.id)
                                        }}  />
                                    
                                    <ErrorMessage
                                        name={`stageSixDetails[${index}].vat_certificate`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                   </Grid>
                                  <Grid item xs={4}>
                                    <LabelWithIcon label={t('owner_id')} />
                                    <FileUpload
                                        filePath={values.stageSixDetails[index]?.owner_id?.url || ""}
                                        fileName={values.stageSixDetails[index].owner_id_file_name || ""}
                                        disabled={edit || !isExpanded ? true : false}
                                        inputName={`stageSixDetails[${index}].owner_id`}
                                        onChange={(event: any) => {
    
                                          
                                            let res:any=  uploadFileStageSix("owner_id", event.target.files[0], policyId , customerReleaseData.insurance_company_id,`stageSixDetails[${index}].owner_id`)
                                            if(res){
                                                setFieldValue(`stageSixDetails[${index}].owner_id`,event.target.files[0])
                                            }else{
                                                setFieldValue(`stageSixDetails[${index}].owner_id`,{})
                                            }
                                      
                                        }}
                                        onRemove={async() => {await setFieldValue(`stageSixDetails[${index}].owner_id`, {})
                                        await removeFile(values.stageSixDetails[index].owner_id.id)
                                        }}  />
                                    
                                    <ErrorMessage
                                        name={`stageSixDetails[${index}].owner_id`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                    </Grid>
                                    <Grid item xs={4}>
                                    <LabelWithIcon
                                        label={t('facilities_credit')} />
                                    <FileUpload
                                        filePath={values.stageSixDetails[index]?.facilities_credit?.url|| ""}
                                        fileName={values.stageSixDetails[index].facilities_credit_file_name || ""}
                                        disabled={edit || !isExpanded ? true : false}
                                        inputName={`stageSixDetails[${index}].facilities_credit`}
                                        onChange={(event: any) => {
    
                                         
                                     
                                            let res:any=  uploadFileStageSix("facilities_credit", event.target.files[0], policyId , customerReleaseData.insurance_company_id,`stageSixDetails[${index}].facilities_credit`)
                                            if(res){
                                                setFieldValue(`stageSixDetails[${index}].facilities_credit`,event.target.files[0])
                                            }else{
                                                setFieldValue(`stageSixDetails[${index}].facilities_credit`,{})
                                            }
                                        }}
                                        onRemove={async() => {await setFieldValue(`stageSixDetails[${index}].facilities_credit`, {})
                                        await removeFile(values.stageSixDetails[index].facilities_credit.id)
                                        }} />
                                    
                                    <ErrorMessage
                                        name={`stageSixDetails[${index}].facilities_credit`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                    </Grid>
                                    <Grid item xs={4}>
                                    <LabelWithIcon label={t('instalment_form_by_client')} />
                                    <FileUpload
                                        filePath={values.stageSixDetails[index]?.installment_form_filled_by_client?.url|| ""}
                                        fileName={values.stageSixDetails[index].installment_form_filled_by_client_file_name || ""}
                                        disabled={edit || !isExpanded ? true : false}
                                        inputName={`stageSixDetails[${index}].installment_form_filled_by_client`}
                                        onChange={(event: any) => {
    
                                         
                                            let res:any=  uploadFileStageSix("installment_form_filled_by_client", event.target.files[0], policyId , customerReleaseData.insurance_company_id,`stageSixDetails[${index}].installment_form_filled_by_client`)
                                            if(res){
                                                setFieldValue(`stageSixDetails[${index}].installment_form_filled_by_client`,event.target.files[0])
                                            }else{
                                                setFieldValue(`stageSixDetails[${index}].installment_form_filled_by_client`,{})
                                            }

                                        }}
                                        onRemove={async() => {await setFieldValue(`stageSixDetails[${index}].installment_form_filled_by_client`, {})
                                        await removeFile(values.stageSixDetails[index].installment_form_filled_by_client.id)
                                        }} />
                                    
                                    <ErrorMessage
                                        name={`stageSixDetails[${index}].installment_form_filled_by_client`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                    </Grid>
                                    <Grid item xs={4}>
                                    <LabelWithIcon label={t('client_iban')} />
                                    <FileUpload
                                        filePath={values.stageSixDetails[index]?.client_iban?.url|| ""}
                                        fileName={values.stageSixDetails[index].client_iban_file_name || ""}
                                        disabled={edit || !isExpanded ? true : false}
                                        inputName={`stageSixDetails[${index}].client_iban`}
                                        onChange={(event: any) => {
    
                                          
                                            let res:any=  uploadFileStageSix("client_iban", event.target.files[0], policyId , customerReleaseData.insurance_company_id,`stageSixDetails[${index}].client_iban`)
                                            if(res){
                                                setFieldValue(`stageSixDetails[${index}].client_iban`,event.target.files[0])
                                            }else{
                                                setFieldValue(`stageSixDetails[${index}].client_iban`,{})
                                            }

                                        }}
                                        onRemove={async() => {await setFieldValue(`stageSixDetails[${index}].client_iban`, {})
                                        await removeFile(values.stageSixDetails[index].client_iban.id)
                                        }} />
                                    
                                    <ErrorMessage
                                        name={`stageSixDetails[${index}].client_iban`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                    </Grid>
                                    <Grid item xs={4}>
                                    <LabelWithIcon
                                        label={t('health_declarations')} />
                                    <FileUpload
                                        filePath={values.stageSixDetails[index]?.health_declarations?.url|| ""}
                                        fileName={values.stageSixDetails[index].health_declarations_file_name || ""}
                                        disabled={edit || !isExpanded ? true : false}
                                        inputName={`stageSixDetails[${index}].health_declarations`}
                                        onChange={(event: any) => {
    
                                         
                                     
                                            let res:any=  uploadFileStageSix("health_declarations", event.target.files[0], policyId , customerReleaseData.insurance_company_id,`stageSixDetails[${index}].health_declarations`)
                                            if(res){
                                                setFieldValue(`stageSixDetails[${index}].health_declarations`,event.target.files[0])
                                            }else{
                                                setFieldValue(`stageSixDetails[${index}].health_declarations`,{})
                                            }
                                        }}
                                        onRemove={async() => {await setFieldValue(`stageSixDetails[${index}].health_declarations`, {})
                                        await removeFile(values.stageSixDetails[index].health_declarations.id)
                                        }} />
                                    
                                    <ErrorMessage
                                        name={`stageSixDetails[${index}].health_declarations`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                    </Grid>
                                    <Grid item xs={4}>
                                    <LabelWithIcon label={t('sima_form')} />
                                    <FileUpload
                                        filePath={values.stageSixDetails[index]?.sima_form?.url|| ""}
                                        fileName={values.stageSixDetails[index].sima_form_file_name || ""}
                                        disabled={edit || !isExpanded ? true : false}
                                        inputName={`stageSixDetails[${index}].sima_form`}
                                        onChange={(event: any) => {
    
                                         
                                            let res:any=  uploadFileStageSix("sima_form", event.target.files[0], policyId , customerReleaseData.insurance_company_id,`stageSixDetails[${index}].sima_form`)
                                            if(res){
                                                setFieldValue(`stageSixDetails[${index}].sima_form`,event.target.files[0])
                                            }else{
                                                setFieldValue(`stageSixDetails[${index}].sima_form`,{})
                                            }

                                        }}
                                        onRemove={async() => {await setFieldValue(`stageSixDetails[${index}].sima_form`, {})
                                        await removeFile(values.stageSixDetails[index].sima_form.id)
                                        }} />
                                    
                                    <ErrorMessage
                                        name={`stageSixDetails[${index}].sima_form`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                    </Grid>
                                    <Grid item xs={4}>
                                    <LabelWithIcon label={t('account_creation_form')} />
                                    <FileUpload
                                        filePath={values.stageSixDetails[index]?.account_creation_form?.url|| ""}
                                        fileName={values.stageSixDetails[index].account_creation_form_file_name || ""}
                                        disabled={edit || !isExpanded ? true : false}
                                        inputName={`stageSixDetails[${index}].account_creation_form`}
                                        onChange={(event: any) => {
    
                                          
                                            let res:any=  uploadFileStageSix("account_creation_form", event.target.files[0], policyId , customerReleaseData.insurance_company_id,`stageSixDetails[${index}].account_creation_form`)
                                            if(res){
                                                setFieldValue(`stageSixDetails[${index}].account_creation_form`,event.target.files[0])
                                            }else{
                                                setFieldValue(`stageSixDetails[${index}].account_creation_form`,{})
                                            }

                                        }}
                                        onRemove={async() => {await setFieldValue(`stageSixDetails[${index}].account_creation_form`, {})
                                        await removeFile(values.stageSixDetails[index].account_creation_form.id)
                                        }} />
                                    
                                    <ErrorMessage
                                        name={`stageSixDetails[${index}].account_creation_form`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                    </Grid>
                                    <Grid item xs={4}>
                                    <FormControl component="fieldset">
                                        <LabelWithIcon label={t('paid_to')} />
                                        <RadioGroup className={classes.radioBtnContainer} name={`stageSixDetails[${index}].paid_to`} 
                                        value={values.stageSixDetails[index].paid_to}
                                        
                                        onChange={(event: any) => setFieldValue(`stageSixDetails[${index}].paid_to`, event.target.value)}

                                        >
                                            <FormControlLabel
                                                value={enumpaid_to.SIIB}
                                                control={<Radio color="primary" />}
                                                label={t('SIIB')} 
                                                disabled={edit || !isExpanded ? true : false}
                                                />
                                            <FormControlLabel
                                                value={enumpaid_to.InC}
                                                disabled={edit || !isExpanded ? true : false}
                                                control={<Radio color="primary" />}
                                                label={t('insurance_company')} />
                                        </RadioGroup>
                                        <ErrorMessage
                                        name={`stageSixDetails[${index}].paid_to`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                    </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                    <LabelWithIcon label={t('payment_made_on')} />
                                    <TextInput
                                        id={`stageSixDetails[${index}].payment_made_on`}
                                        className={classes.textInputStyle}
                                        isArabicLanguage={isArabicLanguage}
                                        disabled={edit || !isExpanded ? true : false}
                                        name={`stageSixDetails[${index}].payment_made_on`}                                   
                                        type="date"
                                        value={values.stageSixDetails[index].payment_made_on}                
                                      onChangeText = {(event: any) => setFieldValue(`stageSixDetails[${index}].payment_made_on`, event.target.value)}
                                        // endAdornment={<InputAdornment position="end" >
                                        //     <IconButton>
                                        //         <CalendarTodayIcon      />
                                        //     </IconButton>
                                        // </InputAdornment>} 
                                        />
                                    <ErrorMessage
                                        name={`stageSixDetails[${index}].payment_made_on`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                    </Grid>
                                    <Grid item xs={4}>
                                    <LabelWithIcon label={t('payment_proof_reciept')} />
                                    <FileUpload
                                        filePath={values.stageSixDetails[index]?.payment_proof?.url|| ""}
                                        fileName={values.stageSixDetails[index].payment_proof_file_name || ""}
                                        disabled={edit || !isExpanded ? true : false}
                                        inputName={`stageSixDetails[${index}].payment_proof`}
                                        onChange={(event: any) => {
    
                                          

                                            let res:any=  uploadFileStageSix("payment_proof", event.target.files[0], policyId , customerReleaseData.insurance_company_id,`stageSixDetails[${index}].payment_proof`)
                                            if(res){
                                                setFieldValue(`stageSixDetails[${index}].payment_proof`,event.target.files[0])
                                            }else{
                                                setFieldValue(`stageSixDetails[${index}].payment_proof`,{})
                                            }

                                        }}
                                        onRemove={async() => {await setFieldValue(`stageSixDetails[${index}].payment_proof`, {})
                                        await removeFile(values.stageSixDetails[index].payment_proof.id)
                                        }} />
                                    
                                    <ErrorMessage
                                        name={`stageSixDetails[${index}].payment_proof`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                    </Grid>
                                    <Grid item xs={4}>
                                    <LabelWithIcon label={t('authorisation_letter_for_other')} />
                                    <FileUpload
                                        filePath={values.stageSixDetails[index]?.authorization_letter_for_other_person?.url|| ""}
                                        fileName={values.stageSixDetails[index].authorization_letter_for_other_person_file_name || ""}
                                        disabled={edit || !isExpanded ? true : false}
                                        inputName={`stageSixDetails[${index}].authorization_letter_for_other_person`}
                                        onChange={(event: any) => {
    
                                         
                                            let res:any=  uploadFileStageSix("authorization_letter_for_other_person", event.target.files[0], policyId , customerReleaseData.insurance_company_id,`stageSixDetails[${index}].authorization_letter_for_other_person`)
                                            if(res){
                                                setFieldValue(`stageSixDetails[${index}].authorization_letter_for_other_person`,event.target.files[0])
                                            }else{
                                                setFieldValue(`stageSixDetails[${index}].authorization_letter_for_other_person`,{})
                                            }
                                        }}
                                        onRemove={async() => {await setFieldValue(`stageSixDetails[${index}].authorization_letter_for_other_person`, {})
                                        await removeFile(values.stageSixDetails[index].authorization_letter_for_other_person.id)
                                        }} />
                                    
                                    <ErrorMessage
                                        name={`stageSixDetails[${index}].authorization_letter_for_other_person`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                    </Grid>
                                    <Grid item xs={4}>
                                    <LabelWithIcon label={t('authorisation_person_id')} />
                                    <FileUpload
                                        filePath={values.stageSixDetails[index]?.authorized_person_id?.url || ""}
                                        fileName={values.stageSixDetails[index].authorized_person_id_file_name || ""}
                                        disabled={edit || !isExpanded ? true : false}
                                        inputName={`stageSixDetails[${index}].authorized_person_id`}
                                        onChange={(event: any) => {
    
                                            let res:any=  uploadFileStageSix("authorized_person_id", event.target.files[0], policyId , customerReleaseData.insurance_company_id,`stageSixDetails[${index}].authorized_person_id`)
                                            if(res){
                                                setFieldValue(`stageSixDetails[${index}].authorized_person_id`,event.target.files[0])
                                            }else{
                                                setFieldValue(`stageSixDetails[${index}].authorized_person_id`,{})
                                            }
                                        }}
                                        onRemove={async() => {await setFieldValue(`stageSixDetails[${index}].authorized_person_id`, {})
                                        await removeFile(values.stageSixDetails[index].authorized_person_id.id)
                                        }} />
                                    
                                    <ErrorMessage
                                        name={`stageSixDetails[${index}].authorized_person_id`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                </Grid></>
                        }
                         <Grid item xs={12}>
                            <LabelWithIcon label={t('comment')} />
                            <TextInput
                                id={`stageSixDetails[${index}].comment`}
                                className={classes.textInputStyle}
                                isArabicLenguage={isArabicLanguage}
                                name={`stageSixDetails[${index}].comment`}
                                type="text"
                                disabled={true}
                                data-test-id={`stageSixDetails[${index}].comment`}
                                multiline
                                minRows={4}
                                maxRows={4}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            </div>
            </div>
     
    )
}

const useStyles = makeStyles(theme => createStyles({
    accordianContainer: {
        margin: "8px"
    },
    childAccordion: {
        width: '100%',
       
      //  margin: '0px 15px',
        '&>div': {
            boxShadow: 'none',
            width: '100%',
            border: '1px solid #ccc',
            margin: '15px 0px',
            "border-radius":"10px !important",
            //    padding: '15px',
        }
    },
    accordionWrapper: {
        width: '100%',
        marginTop:'15px',
        padding: '0px 15px',
       
        '& .MuiAccordion-root': {
            backgroundColor: '#fff',
            
        }
    },
    accordianHeading: {
        fontWeight: 600,
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    accordionContainer: { paddingTop: "10px", paddingBottom: "10px" },
    toggleContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems:"center"
    },
    approveContainer: {
        display: "flex",
     alignItems:"center"
       
    },
    radioBtnContainer: {
        display: "flex",
        "flex-direction": "row"
    }
}));
export default InsurerQuotationApprovalAccordian