// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from 'yup';
import ActiveLanguage from '../../../components/src/Common/ActiveLanguage';

interface IFormData {
	task_name: string;
    assigned_by?: string | {id: string, name: string};
	assignee: any;
	customer: any;
	attachment: string;
	description: string;
    assignee_type: string;
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    t: (value: string) => string;
    classes?:any;
    data?: any;
    update: boolean;
    showTaskDialog: boolean;
    handleTaskDialog: () => void;
    handleTaskIdDialog: (testId: string | number, dialog: boolean) => void;
    getAllTasksList: (query: string, page: number, rowPerPage: number) => void;
    taskId?: string | number;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loginUserData: any;
    isLoading: boolean;
    formData: IFormData;
    showAlert:boolean;
    alertMessage:string;
    alertType:string;
    isArabicLenguage: boolean;
    customerList: any[];
    assigneeList: any[];
    uploadedFileList: any[]
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class TaskAllocatorController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    formikRef: any = null;
    getTaskDataApi: any = "";
    createTaskDataApi: string = "";
    getAssigneeAPI: string = '';
    getCustomerAPI: string = '';
    getTaskAPI: string = '';
    completeTaskDataApi: string = "";
    removeFileAPI: string = "";

    // Customizable Area End

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        const loginData = JSON.parse(window.localStorage.getItem("loginData") || "{}")


        this.state = {
            loginUserData: loginData,
            showAlert: false,
            alertMessage: '',
            alertType: '',
            isArabicLenguage: ActiveLanguage(),
            isLoading: false,
            formData: {
                task_name: "",
                assignee: "",
                assigned_by: "",
                customer: "",
                attachment: "",
                description: "",
                assignee_type: ""
            },
            customerList: [],
            assigneeList: [],
            uploadedFileList: [],
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.createApiResponse(message);
        this.getAssignee(message);
        this.getCustomers(message);
        this.getTaskDataAPIMessage(message)
        this.fileRemoveApiReceive(message);
        this.completeApiResponse(message)
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        this.getCustomerFunction();
        this.getAssigneeFunction();
        if(this.props.update && this.props.taskId) this.getTaskFunction()
        // Customizable Area End
    }

    // Customizable Area Start

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if(this.props.taskId && prevProps.taskId != this.props.taskId) this.getTaskFunction();
    }

    getFormData = (item: any, isComplete: boolean = false) => {
        const formData = new FormData();
        formData.append(`data[task_name]`, item.task_name );
        formData.append(`data[assignee]`, item.assignee.value );
        formData.append(`data[assignee_type]`, item.assignee_type );
        (item.customer?.value) && formData.append(`data[customer_id]`, item.customer.value );
        (isComplete) && formData.append(`data[completed]`, 'true');
        formData.append(`data[description]`, item.description );
        for (const file of item.attachment) {
            formData.append('data[attachments][]', file);
        }
        return formData;
    }

    setInitialDataValues = (data: any) => {
        this.setState({uploadedFileList: data?.attachments?.length ? data.attachments : [], formData: {
            task_name: data.task_name || '',
            assignee: data ? {value: data.assignee.id, label: data.assignee.name} : '',
            assigned_by: data ? data.assigned_by : '',
            customer: data ? {value: data.customer_id.id, label: data.customer_id.name} : '',
            description: data.description || '',
            attachment: '',
            assignee_type: data.assignee_type || ''
        }})

    }

    getFilePath = (values: any[]) => {
        if(this.state.uploadedFileList.length) values = [...values, ...this.state.uploadedFileList]
        return values?.length > 0 ? values : [];
    }

    apiCall(data: any) {
        const { contentType, method, endPoint, body } = data;
        const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
        const token = userData.token || "";
        let header: any = {
            token: token,
            "Content-Type": contentType
        };
        const revisionRequestCsvImportMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        revisionRequestCsvImportMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
        revisionRequestCsvImportMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
        revisionRequestCsvImportMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);
        body && revisionRequestCsvImportMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),body);
        runEngine.sendMessage(revisionRequestCsvImportMessage.id, revisionRequestCsvImportMessage);
        return revisionRequestCsvImportMessage.messageId;
    }

    createTask = (values: any, isEdit: boolean = false) => {
        this.createTaskDataApi = this.apiCall({
            type : 'FormData',
            method: isEdit ? 'PUT' : 'POST',
            body: this.getFormData(values),
            endPoint: isEdit ? `bx_block_tasks/tasks/${this.props.taskId}` : `bx_block_tasks/tasks`,
        })
    }

    completeTask = (values: any) => {
        this.completeTaskDataApi = this.apiCall({
            type : 'FormData',
            method: 'PUT',
            body: this.getFormData(values, true),
            endPoint: `bx_block_tasks/tasks/${this.props.taskId}`,
        })
    }
    async submitData(values: any, isUpdate: boolean){
        if(isUpdate && this.props.taskId) {
            this.createTask(values, isUpdate)
        } else {
            this.createTask(values, false)
        }
    }

    removeAttachment = (attachment_id: any) => {
        this.removeFileAPI = this.apiCall({
            type : 'FormData',
            method: 'DELETE',
            endPoint: `bx_block_tasks/tasks/${this.props.taskId}/delete_tasks_attachment?attachment_id=${attachment_id}`
        })
    }

    handleRemoveFileData = (id: string | number) => {
        this.setState({uploadedFileList: this.state.uploadedFileList?.filter((fileName: any) => fileName.id !== id)})
        this.removeAttachment(id);
    }


    getCustomerFunction = () => {
        this.getCustomerAPI = this.apiCall({
            type : 'FormData',
            method: 'GET',
            endPoint: `bx_block_customer/customers/?archived=false`,
        })
    }
    getTaskFunction = () => {
        this.getTaskAPI = this.apiCall({
            type : 'FormData',
            method: 'GET',
            endPoint: `bx_block_tasks/tasks/${this.props.taskId}`,
        })
    }
    getAssigneeFunction = () => {
        this.getAssigneeAPI = this.apiCall({
            type : 'FormData',
            method: 'GET',
            endPoint: `account_block/accounts?internal_users=yes`,
        })
    }

    setApiErrorMessage = (responseJson:any) => {
        if (!responseJson) return
        let errorMessage = "Something went wrong"
        if (responseJson?.errors) {
            errorMessage = typeof(responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error  || "Something went wrong";
        }
        this.setState({
            alertType: "error",
            alertMessage: errorMessage,
            showAlert: true,
            isLoading: false,
        })
    }

    validationSchema = (isArabicLanguage: boolean) => {
        const schema = {
            task_name: Yup.string().required(isArabicLanguage ? 'البريد الإلكتروني المُرسَل مطلوب.' : "Task named is required."),
            assignee: Yup.string().required(isArabicLanguage ? 'البريد الإلكتروني المُرسَل مطلوب.' : "Assignee is required."),
            description: Yup.string().required(isArabicLanguage ? 'البريد الإلكتروني المُرسَل مطلوب.' : "Description is required."),
        }
        return Yup.object().shape(schema);
    }

    getAlertMessage = () => {
        let alertMessage = this.props.update ? "Task Updated" : "Task created successfully!";
        let arabicAlertMessage = this.props.update ? "تم تحديث المهمة" : "تم إنشاء المهمة بنجاح!";
        return ActiveLanguage() ? arabicAlertMessage : alertMessage;
    }

    createApiResponse = (message: Message) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id && this.createTaskDataApi !== null && this.createTaskDataApi === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
        {
            const createTaskResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (createTaskResponse.data) {
                this.setState({
                    showAlert: true,
                    alertMessage: this.getAlertMessage(),
                    alertType: "success"
                });
                this.formikRef.current?.resetForm();
                this.props.handleTaskIdDialog('', false);
            } else {
                this.setApiErrorMessage(createTaskResponse);
            }
        }
    }

    completeApiResponse = (message: Message) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id && this.completeTaskDataApi !== null && this.completeTaskDataApi === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
        {
            const createTaskResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            try {
                if (createTaskResponse.data) {
                    this.setState({
                        showAlert: true,
                        alertMessage: ActiveLanguage() ? "تمت المهمة" : "Task Completed" ,
                        alertType: "success"
                    });
                    this.formikRef.current?.resetForm();
                    this.props.handleTaskIdDialog('', false);
                    this.props.getAllTasksList("", 1, 5);
                }
            } catch (error) {
                this.setApiErrorMessage(createTaskResponse);
            }
        }
    }

    getTaskDataAPIMessage = (message: Message) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getTaskAPI !== null && this.getTaskAPI === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
        {
            const createTaskResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            try {
                if (createTaskResponse.data) {
                    const responseData = createTaskResponse.data;
                    let assignee = {};
                    if(responseData.attributes.assignee_type === 'department') {
                        assignee = {
                            id: responseData.attributes.assignee,
                            name: responseData.attributes.assignee.toLowerCase()
                                .split(' ')
                                .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
                                .join(' ')
                            }
                    } else assignee = responseData.attributes.assignee;
                    this.setInitialDataValues({...responseData.attributes, assignee});
                } else {
                    this.setApiErrorMessage(createTaskResponse);
                    this.props.handleTaskIdDialog("", false)
                    this.setInitialDataValues({})
                }
            } catch (error) {
                this.setApiErrorMessage(createTaskResponse);
                this.props.handleTaskIdDialog("", false)
            }
        }
    }

    getCustomers = (message: Message) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getCustomerAPI !== null && this.getCustomerAPI === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
        {
            const getCustomerList = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            try {
                if (getCustomerList.data) {
                    const responseData = getCustomerList.data.map((opt: any) => {
                        return {
                            value: opt?.attributes?.id,
                            label: opt?.attributes?.name?.toLowerCase()
                                .split(' ')
                                .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
                                .join(' '),
                        }
                    });
                    this.setState({customerList: responseData});
                }
            } catch (error) {
                this.setApiErrorMessage(getCustomerList);
            }
        }
    }

    getAssignee = (message: Message) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id && this.getAssigneeAPI !== null && this.getAssigneeAPI === message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))
        {
            const getAssigneeList = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            try {
                if (getAssigneeList.data) {
                    const defaultAssigneeList = [
                        {label: 'Me', options: [{value: this.state.loginUserData.id, label: "Me", group: 'myself'}]},
                        {label: 'Departments', options: [{value: 'sales', label: "Sales", group: 'department'}, {value: 'operations', label: "Operations", group: 'department'}, {value: 'finance', label: "Finance", group: 'department'}]}
                    ]
                    const responseData = getAssigneeList.data.map((opt: any) => {
                        return {
                            value: Number(opt?.id),
                            label: opt?.attributes?.first_name?.toLowerCase()
                                .split(' ')
                                .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
                                .join(' ') + ' ' + opt?.attributes?.last_name?.toLowerCase()
                                .split(' ')
                                .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
                                .join(' '),
                            group: 'user'
                        }
                    });
                    const assigneeList = [...defaultAssigneeList, {label: 'Users', options: responseData}];
                    this.setState({assigneeList, formData: {...this.state.formData, assignee: assigneeList[0]?.['options'][0]}});
                }
            } catch (error) {
                this.setApiErrorMessage(getAssigneeList);
            }
        }
    }

    fileRemoveApiReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.removeFileAPI !== null &&
            this.removeFileAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.message) {
                this.setState({
                    showAlert: true,
                    alertMessage: responseJson.message,
                    alertType: "success",
                })
            } else {
                this.setApiErrorMessage(responseJson)
            }
        }
    }


    // Customizable Area End
}
