import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    InputAdornment,
    Tooltip
} from "@material-ui/core";
import { ErrorOutlineRounded, ExpandMore } from '@material-ui/icons'
// @ts-ignore
import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import StageSevenController, { Props } from "../../../blocks/TaskAllocator/src/PolicyStage/StageSevenController";
import CustomButton from "../Common/CustomButton";
import Toster from "../Common/Toster";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AddIcon from '@material-ui/icons/Add';
import StageSevenInsurerForm from "./StageSevenInsurerForm";
import StageSevenSiibForm from "./StageSevenSiibForm";
import { ErrorMessage, Formik } from "formik";
import LabelWithIcon from "../Common/LabelWithIcon";
import TextInput from "../Common/TextInput";
import RejectRequestDialog from "../Common/RejectRequestDialog.web"
import HandOverDialog from '../Common/HandOverDialog.web'

import * as Yup from "yup";
import StageSevenFormSchema from "./StageSevenSchema";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

const CustomToolTip = withStyles({
    arrow: {
        "&::before": {
            color: "white"
        }
    },
    tooltip: {
        backgroundColor: "#f5f5f9",
        boxShadow: theme.shadows[8],
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: 14,
        maxWidth: 800
    },
    tooltipPlacementTop: {
        margin: "4px 0"
    }
})(Tooltip);

interface FlowData {
    stageData: {
        flowOneData?: any;
        flowTwoData?: any;
        flowThreeData?: any;
        flowFourData?: any;
        flowFiveData?: any;
        flowSixData?: any;
        flowSevenData?: any;
        flowEightData?: any;
        flowNineData?: any;
    };
    paid_amount: string;
    deduct_commission: string | boolean;
    payment_reciepent: string;
}

const installmentObjForFlowSeven = {
    installment_amount: "",
    due_date: "",
    installment_paid: "no",
    insurance_receipt_number: "",
    payment_date_2: "",
    proof_of_payment_doc: "",
}

const installmentObjForFlowEight = {
    installment_amount: "",
    due_date: "",
    installment_paid: "no",
    siib_receipt_number: "",
    payment_date: "",
    proof_of_payment_doc: "",
    payment_date_2: "",
    voucher_proof_of_payment_doc: "",
    insurer_receipt_number: "",
    payment_voucher_id:"",
    payment_voucher:""
}

const installmentObjForFlowNine = {
    installment_amount: "",
    due_date: "",
    installment_paid: "no",
    commission_rate: "",
    commission_amount: "",
    vat_on_siib_commission: "",
    invoice_date: "",
    receipt_date: "",
    payable_to_insurer: "",
    voucher_payment_date: "",
    insurer_receipt_number: "",
    proof_of_payment_doc: "",
};

export class StageSevenForm extends StageSevenController {
    formikRef: any;
    installmentCount: number = 0;

    constructor(props: Props) {
        super(props);
        this.formikRef = React.createRef();
    }

    handleSubmitFromOutside = () => {
        const { current: formikInstance } = this.formikRef;

        if (formikInstance) {
            this.checkStageSevenForm(formikInstance.errors)
            formikInstance.handleSubmit();

        }
    }

    updateFlow = (paymentRecipient: string, paidAmount: number, quotationAmount: number, deductCommission: string, formikRef: any) => {
        switch (true) {
            case (paymentRecipient === "Insurer" && paidAmount === quotationAmount):
                {
                    this.setState({ flow: 1 }
                    )
                    break;
                }
            case (paymentRecipient === "Insurer" && paidAmount > quotationAmount):
                {
                    this.setState({ flow: 2 }
                    )
                    break;
                }
            case (paymentRecipient === "Siib" && paidAmount === quotationAmount && deductCommission === 'no'):
                {
                    this.setState({ flow: 3 });
                    break;
                }
            case (paymentRecipient === "Siib" && paidAmount > quotationAmount && deductCommission === 'no'):
                {
                    this.setState({ flow: 4 });
                    break;
                }
            case (paymentRecipient === "Siib" && paidAmount === quotationAmount && deductCommission === 'yes'):
                {
                    this.setState({ flow: 5 });
                    break;
                }
            case (paymentRecipient === "Siib" && paidAmount > quotationAmount && deductCommission === 'yes'):
                {
                    this.setState({ flow: 6 });
                    break;
                }
            case (paymentRecipient === "Insurer" && paidAmount < quotationAmount):
                {
                    this.setState({ flow: 7 });
                    break;
                }
            case (paymentRecipient === "Siib" && paidAmount < quotationAmount && deductCommission === 'no'):
                {
                    this.setState({ flow: 8 });
                    break;
                }
            case (paymentRecipient === "Siib" && paidAmount < quotationAmount && deductCommission === 'yes'):
                {
                    this.setState({ flow: 9 });
                    break;
                }
        }
    }
    addInstallmentDisable = (values: any):boolean => {
        if (this.state.flow === 7) {
            let checkAllFields = values?.stageData?.flowSevenData
            if (checkAllFields?.insure_recipent_number &&
                checkAllFields?.payment_date &&
                checkAllFields?.proof_of_payment_doc) {
                return false
            }
        }
        else if (this.state.flow === 8) {
            let checkAllFields = values?.stageData?.flowEightData
            if (checkAllFields?.siib_receipt_number &&
                checkAllFields?.proof_of_payment_doc &&
                checkAllFields?.payment_date &&
                checkAllFields?.insurance_receipt_number &&
                checkAllFields?.voucher_payment_date &&
                checkAllFields?.voucher_proof_of_payment_doc) {
                return false
            }
        }
        else if (this.state.flow === 9) {
            let checkAllFields = values?.stageData?.flowNineData
            if (
                checkAllFields?.commission_amount &&
                checkAllFields?.commission_rate &&
                checkAllFields?.insurance_receipt_number &&
                checkAllFields?.invoice_date &&
                // checkAllFields?.invoice_doc &&
                checkAllFields?.payable_to_insurer &&
                checkAllFields?.receipt_date &&
                // checkAllFields?.receipt_doc &&
                // checkAllFields?.siib_invoice_id &&
                // checkAllFields?.siib_receipt_id &&
                checkAllFields?.vat_on_siib_commission &&
                checkAllFields?.voucher_payment_date &&
                checkAllFields?.voucher_proof_of_payment_doc
            ) {
                return false
            }
        }
        return true
    }
    removeInstallment = (installmentIndex: number) => {
        if (this.state.flow === 7) {
            const flowSevenData = this.state.flowSevenData;
            const installmentAttributes = flowSevenData.installment_attributes;
            installmentAttributes.pop();
            if (this.installmentCount > 0) this.installmentCount = this.state.flowSevenData.installment_attributes.length - 1;
            this.setState({ flowSevenData: flowSevenData })
            const formikData = [...this.formikRef.current.values.stageData.flowSevenData.installment_attributes]
            formikData.splice(installmentIndex, 1)
            this.formikRef.current.setFieldValue(`stageData.flowSevenData.installment_attributes`, formikData)
            this.formikRef.current.validateForm().then((error: any) => {
                const formikErr = [...error.stageData.flowSevenData.installment_attributes]
                formikErr.splice(installmentIndex,1)
                this.formikRef.current.setErrors(formikErr)
            })
        }
        if (this.state.flow === 8) {
            const flowEightData = this.state.flowEightData;
            const installmentAttributes = flowEightData.installment_attributes;
            installmentAttributes.pop();
            if (this.installmentCount > 0) this.installmentCount = this.state.flowEightData.installment_attributes.length - 1;
            this.setState({ flowEightData: flowEightData })
            const formikData = [...this.formikRef.current.values.stageData.flowEightData.installment_attributes]
            formikData.splice(installmentIndex, 1)
            this.formikRef.current.setFieldValue(`stageData.flowEightData.installment_attributes`, formikData)
            this.formikRef.current.validateForm().then((error: any) => {
                const formikErr = [...error.stageData.flowEightData.installment_attributes]
                formikErr.splice(installmentIndex,1)
                this.formikRef.current.setErrors(formikErr)
            })
        }
        if (this.state.flow === 9) {
            const flowNineData = this.state.flowNineData;
            const installmentAttributes = flowNineData.installment_attributes;
            installmentAttributes.pop();
            if (this.installmentCount > 0) this.installmentCount = this.state.flowNineData.installment_attributes.length - 1;
            this.setState({ flowNineData: flowNineData })
            const formikData = [...this.formikRef.current.values.stageData.flowNineData.installment_attributes]
            formikData.splice(installmentIndex, 1)
            this.formikRef.current.setFieldValue(`stageData.flowNineData.installment_attributes`, formikData)
            this.formikRef.current.validateForm().then((error: any) => {
                const formikErr = [...error.stageData.flowNineData.installment_attributes]
                formikErr.splice(installmentIndex,1)
                this.formikRef.current.setErrors(formikErr)
            })
        }
    }

    addInstallment = () => {
        if (this.state.flow === 7) {
            const flowSevenData = this.state.flowSevenData;
            const installmentAttributes = flowSevenData.installment_attributes;
            installmentAttributes.push(installmentObjForFlowSeven);
            this.setState({ flowSevenData: flowSevenData });
            this.installmentCount = this.state.flowSevenData.installment_attributes.length + 1;
        }
        else if (this.state.flow === 8) {
            const flowEightData = this.state.flowEightData;
            const installmentAttributes = flowEightData.installment_attributes;
            installmentAttributes.push(installmentObjForFlowEight);
            this.setState({ flowEightData: flowEightData });
            this.installmentCount = this.state.flowEightData.installment_attributes.length + 1;
        }
        else if (this.state.flow === 9) {
            const flowNineData = this.state.flowNineData;
            const installmentAttributes = flowNineData.installment_attributes;
            installmentAttributes.push(installmentObjForFlowNine);
            this.setState({ flowNineData: flowNineData });
            this.installmentCount = this.state.flowNineData.installment_attributes.length + 1;
        }
    }

    render() {
        const { t, classes } = this.props;
        const pushBackOption = [
            { value: "info_gathering", label: t('info_gathering') },
            { value: "processing", label: t('processing') },
            { value: "awaiting", label: t('awaiting') },
            { value: "operations_review", label: t('operations_review') },
            { value: "sales_review", label: t('sales_review') },
            { value: "customer_review", label: t('customere_review') }
        ];

        const { expandedAccordion } = this.state

        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') || '{}')
        const stageSevenData = JSON.parse(window.localStorage.getItem('stageSevenData') || '{}')

        const dummyData = stageSixData?.attributes?.quotation_insurance_infos?.data?.filter((item: any) => {
            return item?.attributes?.approval === true
        });

        const isInstallmentPaid = JSON.parse(window.localStorage.getItem('InstallmentPaid') || '{}')
        const checkDisable = (!this.props.isExpanded || !isInstallmentPaid || stageSixData?.attributes?.is_next_stage_submitted) && (!stageSevenData?.attributes?.save_as_draft||stageSevenData?.attributes?.is_next_stage_submitted);
        return (
            <ThemeProvider theme={theme} >
                <React.Fragment>
                    <Toster
                        tosterText={this.state.alertMessage}
                        showToster={this.state.showAlert}
                        // @ts-ignore
                        tosterType={this.state.alertType}
                        handleCloseToster={() => this.setState({ showAlert: false })}
                    />
                    <div className={classes?.accordianGap}>
                        <Accordion
                            data-test-id='p7'
                            expanded={expandedAccordion}
                            className={classes?.formSevenAccordian}
                            onChange={() => { this.handleAccordian() }} >
                            <AccordionSummary
                                aria-expanded={true}
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={classes?.toggleContainer}>
                                    <Typography className={classes?.accordianHeading} >{t('quotation_payment')}</Typography>
                                    <div className={classes?.iconAlignment}>
                                        {expandedAccordion && <DescriptionOutlinedIcon onClick={this.openNoteDialog} />}
                                        {expandedAccordion && stageSevenData?.attributes?.is_reject &&
                                            <ErrorOutlineRounded style={{ position: 'relative', left: '10px' }} onClick={() => this.setState({ isBtnVisible: false, showRejectDialog: true })} />
                                        }
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Formik
                                    innerRef={this.formikRef}
                                    enableReinitialize={true}
                                    initialValues={
                                        this.updateInitialValues() as FlowData
                                    }
                                    validationSchema={Yup.object().shape(StageSevenFormSchema(this.state.flow))}
                                    validateOnMount={true}
                                    validateOnBlur={true}

                                    onSubmit={async (values, actions) => {
                                        this.submitProcessingData(values)
                                    }}
                                >
                                    {({
                                        values,
                                        setFieldValue,
                                        touched,
                                        errors
                                    }) => (
                                        <React.Fragment>
                                            <Grid className={classes?.contactForm}>
                                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography style={{ fontWeight: 400, marginBottom: 25 }}>{dummyData?.[0]?.attributes?.insurance_company_id?.short_name}</Typography>
                                                    {(!this.addInstallmentDisable(values) && (
                                                        Number(dummyData?.[0]?.attributes?.quotation_amount) > Number(values?.paid_amount) &&
                                                        Number(values?.paid_amount) > 0) && (this.state.selectedStepFromReject==7||stageSevenData?.attributes?.save_as_draft ||!stageSixData?.attributes?.is_next_stage_submitted)  ? (
                                                            (() => {
                                                                return (
                                                                    <Button
                                                                        aria-controls="customized-menu"
                                                                        aria-haspopup="true"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        disabled={this.installmentCount > 9}
                                                                        onClick={this.addInstallment}
                                                                        style={{
                                                                            textTransform: 'none',
                                                                            backgroundColor: "white",
                                                                            color: (this.installmentCount > 9) ? "grey" : "#007E98",
                                                                            boxShadow: "none",
                                                                            fontSize: 17,
                                                                            padding: 0,
                                                                            fontWeight: 400
                                                                        }}
                                                                    >
                                                                        
                                                                        <AddIcon style={{ width: 15, height: 15 }} />{t('add_installment')}
                                                                    </Button>
                                                                );
                                                            })()
                                                        ):null
                                                    )}

                                                </Grid>
                                                {!this.addInstallmentDisable(values) && (<Grid><LabelWithIcon label={t('down_payment')} /></Grid>)}
                                                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                                                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                                                        <LabelWithIcon label={t('payment_recipient')} />
                                                        <FormControl className={classes?.radioFormControl} style={{ marginLeft: 15, marginTop: 17 }}>
                                                            <RadioGroup
                                                                row
                                                                name="payment_reciepent"
                                                                value={this.state.payment_reciepent}
                                                                onChange={(e: any) => {
                                                                    this.setState({ payment_reciepent: e.target.value })
                                                                    this.updateFlow(e.target.value, Number(values.paid_amount), Number(dummyData[0].attributes.quotation_amount), values.deduct_commission as any, setFieldValue);
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    disabled={checkDisable}
                                                                    value={"Siib"} control={<Radio color="primary" />} label="SIIB" />
                                                                <FormControlLabel
                                                                    disabled={checkDisable}
                                                                    value={"Insurer"} control={<Radio color="primary" />} label={t('insurer')} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                                                        <LabelWithIcon label={t('paid_amount')} />
                                                        <TextInput
                                                            className={classes?.textInputStyle}
                                                            name="paid_amount"
                                                            type="text"
                                                            data-test-id="paid_amount"
                                                            disabled={checkDisable}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <div> &nbsp;SAR&nbsp;</div>
                                                                </InputAdornment>
                                                            }
                                                            onChange={(e: any) => {
                                                                this.setState({ paid_amount: e.target.value })
                                                                this.updateFlow(values.payment_reciepent, Number(e.target.value), Number(dummyData[0].attributes.quotation_amount), values.deduct_commission as any, this.formikRef);
                                                            }}
                                                        />
                                                        <ErrorMessage
                                                            name={`paid_amount`}
                                                            className="field-error"
                                                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                                    </Grid>
                                                    {values?.payment_reciepent === "Siib" && <Grid item xs={4}>
                                                        <LabelWithIcon label={t('deduct_commission')} />
                                                        <FormControl className={classes?.radioFormControl} style={{ marginLeft: 15, marginTop: 17 }}>
                                                            <RadioGroup
                                                                row
                                                                name="deduct_commission"
                                                                value={this.state.deduct_commission}
                                                                onChange={(e: any) => {
                                                                    this.setState({ deduct_commission: e.target.value })
                                                                    this.updateFlow(values.payment_reciepent, Number(values.paid_amount), Number(dummyData[0].attributes.quotation_amount), e.target.value, setFieldValue);
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    disabled={checkDisable}
                                                                    value={'yes'} control={<Radio color="primary" />} label={t("yes")} />
                                                                <FormControlLabel
                                                                    disabled={checkDisable}
                                                                    value={'no'} control={<Radio color="primary" />} label={t("no")} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                        <ErrorMessage
                                                            name={`deduct_commission`}
                                                            className="field-error"
                                                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                                                        />
                                                    </Grid>}
                                                </Grid>
                                                {
                                                    values.payment_reciepent === "Insurer" &&
                                                    <StageSevenInsurerForm
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        flowValue={this.state.flow} installmentData={this.state.flowSevenData.installment_attributes}
                                                        formikRef={this.formikRef}
                                                        removeInstallmentData={this.removeInstallment}
                                                        stageSixData={this.props.stageSixData}
                                                        errors={errors}
                                                        touched={touched}
                                                        isExpanded={this.props.isExpanded}
                                                        checkDisable={checkDisable}
                                                    />
                                                }
                                                {
                                                    values.deduct_commission && values.payment_reciepent === "Siib" &&
                                                    <StageSevenSiibForm
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        flowValue={this.state.flow}
                                                        installmentData={this.state.flow === 8 ? this.state.flowEightData.installment_attributes : this.state.flowNineData.installment_attributes}
                                                        formikRef={this.formikRef}
                                                        updateFlow={this.updateFlow}
                                                        removeInstallmentData={this.removeInstallment}
                                                        touched={touched} errors={errors}
                                                        isExpanded={this.props.isExpanded}
                                                        checkDisable={checkDisable}
                                                    />
                                                }
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                        {this.props.isExpanded && (
                            <div className={classes?.displayFlexEnd}>
                                <div className={classes?.btnStyle}>
                                    <CustomButton
                                        btnText={t('save_draft')}
                                        onClick={() => this.saveDraftData(this.formikRef)}
                                        bgColor={'#fff'}
                                        textColor={'#007E98'}
                                    />
                                </div>
                                <div className={classes?.btnStyle}>
                                    <CustomButton
                                        data-test-id={'rejectBtn'}
                                        btnText={t('reject')}
                                        onClick={this.openRejectDialog}
                                        bgColor={'#fff'}
                                        textColor={'#007E98'}
                                    />
                                </div>
                                <div>
                                    <CustomButton
                                        data-test-id={'nextBtn'}
                                        btnText={t('next_stage')}
                                        onClick={this.handleSubmitFromOutside}
                                        bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                        disabled={this.state.flow == 0}
                                        textColor={'#fff'}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNoteDialog as boolean}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={this.state.isBtnVisible?`(${t('payment_review')} > ${t('issuance')})`: `(${t('customere_review')} > ${t('payment_review')})`}
                        handleInputText={this.handleHandOverNoteText}
                        inputLable={this.state.showNoteDialog ? t('note') : ""}
                        inputValue={this.state.isBtnVisible ? this.state.handOverNoteText : stageSixData?.attributes?.handover_note_payment_review}
                        inputDisable={!this.state.isBtnVisible}
                        handleOk={(value: string) => this.handleOkDialog(value)}
                        handleCancel={this.closeNoteDialog}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                    />
                    <RejectRequestDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showRejectDialog as boolean}
                        textId={'rejectReason'}
                        pushBackOptions={pushBackOption}
                        inputValue={this.state.isBtnVisible ? this.state.rejectReasonText : stageSevenData?.attributes?.rejected_reason}
                        setSelectedPushBackTo={(e: any) => this.moveToStep(e)}
                        headingText={t('rejecting')}
                        handleInputText={this.handleRejectReasonText}
                        handleOk={this.successCloseRejectDialog}
                        handleCancel={this.closeRejectDialog}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                        labelText={t('push_back')}
                        reasonLabel={t('reason')}
                    />
                </React.Fragment>
            </ThemeProvider >
        )
    }
}

const useStyles = ({
    accordianGap: {
        padding: "10px 0"
    },
    contactForm: { width: "100%" },
    btnStyle: {
        margin: '0 10px'
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    displayFlexEnd: { display: "flex", justifyContent: "end", margin: '16px 0' },
    errorSection: {
        border: "1px solid red"
    },
    formSevenAccordian: {
        "border-radius": "10px !important",
    },
    accordianHeading: {
        fontWeight: 600
    },
    displayFlex: {
        display: "flex",
    },
    toggleContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '& svg': {
            color: '#0090a1'
        }
    },
    iconAlignment: {
        display: 'flex',
        marginRight: "10px"
    },
    lostIcon: {
        marginLeft: "15px",
        '& svg': {
            color: '#0090a1'
        },
    },
});

export default withStyles(useStyles)(withTranslation()(StageSevenForm));