// Customizable Area Start
import React, { ReactNode } from "react";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import StageNineController, {
  Props
} from "./StageNineController";
import {
  Grid,
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  ExpandMore,
} from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import LabelWithIcon from "../../../../../components/src/Common/LabelWithIcon";
import TextInput from "../../../../../components/src/Common/TextInput";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CustomButton from "../../../../../components/src/Common/CustomButton";
import Toster from "../../../../../components/src/Common/Toster";
import RejectRequestDialog from "../../../../../components/src/Common/RejectRequestDialog.web";
import HandOverDialog from "../../../../../components/src/Common/HandOverDialog.web";
import BorderedFileUpload from "../../../../../components/src/Common/BorderedFileUpload";
import * as Yup from "yup";
import StageNineInstalmentForm from "./StageNineInstalmentForm";
import FileUpload from "../../../../../components/src/Common/FileUpload";
import DownloadFile from "../../../../../components/src/Common/DownloadFile";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
});

class StageNineForm extends StageNineController {
  formikRef: any;

  constructor(props: Props) {
    super(props);
    this.formikRef = React.createRef();
  }

  render(): ReactNode {// NOSONAR: typescript:S3776
    const { t, classes } = this.props;
    const stageEightData = JSON.parse(window.localStorage.getItem("stageEightData") ?? "{}")

    const stageSevenData = JSON.parse(window.localStorage.getItem("stageSevenData") ?? "{}")
    const stageNineData = JSON.parse(window.localStorage.getItem("stageNineData") ?? "{}")
    const isDraft = stageNineData?.attributes?.save_as_draft;
    /* istanbul ignore next*/
    const siibId = stageNineData?.attributes?.siib_commission?.data?.[0]?.id && !isDraft;
    /* istanbul ignore next*/
    const salesId = stageNineData?.attributes?.sales_commission?.data?.[0]?.id && !isDraft;
    /* istanbul ignore next*/
    const creditNoteId = stageNineData?.attributes?.credit_note?.data?.[0]?.id && !isDraft;
    const currentFlow = Number(stageSevenData.attributes?.flow)
    let showPayInstalmentBtn: boolean = false;
    if (currentFlow == 7 || currentFlow == 8 || currentFlow == 9) {
      showPayInstalmentBtn = stageSevenData.attributes.installments?.data.find((record: any) => record.attributes.installment_paid === false)
    }
    return (
        <ThemeProvider theme={theme}>
          <Toster
            tosterText={this.state.alertMessage}
            showToster={this.state.showAlert}
            // @ts-ignore
            tosterType={this.state.alertType}
            handleCloseToster={() => this.setState({ showAlert: false })}
          />

          <Grid className={classes.customerDetailsAccordion} item>
            <Accordion
              data-test-id="p9"
              className={
                this.state.errorSection === "p9" &&   /* istanbul ignore next*/classes.errorSection
                  ?   /* istanbul ignore next*/classes.errorSection
                  : classes.accordionStyle
              }
              expanded={this.isStageNineAccordionOpen(this.props.isExpanded)}
              onChange={(event, isExpanded) => {
                this.handleStageNineAccordian(isExpanded, "p9")
              }}
            >
              <AccordionSummary
                aria-expanded={true}
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.toggleContainer}>
                  <Typography className={classes.accordianHeading} >{t('invoicing')}</Typography>
                  <div className={classes.iconAlignment}>
                    {this.state.expandedAccordion && <DescriptionOutlinedIcon onClick={this.openNoteDialog} />}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Formik
                  data-test-id="StageNineForm"
                  innerRef={this.formikRef}
                  initialValues={this.getFormInitialValues() as any}
                  enableReinitialize={true}
                  validateOnMount={true}
                  validateOnBlur={true}
                  validationSchema={Yup.object().shape(
                    this.operationSchema(this.state.isInstallmentPaid)
                  )}
                  onSubmit={(values) => {
                    this.submitStageNine(values, false)
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    touched,
                    errors,
                  }) => {
                    const paidAmount = stageSevenData.attributes?.payment_insurance_infos?.data?.attributes?.paid_amount
                    const insurance_company = stageSevenData.attributes?.payment_insurance_infos?.data?.attributes?.insurance_company_id.short_name;
                    const insurance_company_id = stageSevenData.attributes?.payment_insurance_infos?.data?.attributes?.insurance_company_id.id;
                    const isInstallment = (currentFlow == 7 || currentFlow == 8 || currentFlow == 9);
                    let balance: number = (currentFlow < 5 ? Number(values.commisionAmount) : 0) ?? 0;
                    if (isInstallment && (currentFlow == 7 || currentFlow == 8)) {
                      balance = Number(values.commisionAmount);
                      values.installment_attributes?.map((data: any) => {
                        balance = balance + (Number(data.commisionAmount) || 0);
                      })
                    }
                    /* istanbul ignore next*/
                    const checkDownPaymentValidations = () => {
                      if ((currentFlow === 7 || currentFlow === 8) && (!values.commisionRate || !values.invoiceDate || !values.invoicePaid || !values.paymentDate || !values.proofOfPaymentReceviedDoc || !values.salesCommisionRate || !values.creditNoteDate) && Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0) {
                        return true;
                      } else if (currentFlow === 9 && (!values.salesCommisionRate || !values.creditNoteDate) && Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0) {
                        return true;
                      }
                      return false
                    }
                    /* istanbul ignore next*/
                    const checkInstalmentValidation = (index: number) => {
                      const installment = values?.installment_attributes?.[index]
                      if ((currentFlow === 7 || currentFlow === 8) && stageSevenData.attributes.installments?.data?.[index]?.attributes?.installment_paid && (!installment?.commisionRate || !installment?.invoiceDate || !installment?.invoicePaid || !installment?.paymentDate || !installment?.proofOfPaymentReceviedDoc || !installment?.salesCommisionRate || !installment?.creditNoteDate) && Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0) {
                        return true;
                      } else if (currentFlow === 9 && stageSevenData.attributes.installments?.data?.[index]?.attributes?.installment_paid && (!installment.salesCommisionRate || !installment.creditNoteDate) && Object.keys(errors).length !== 0 && Object.keys(touched).length !== 0) {
                        return true;
                      }
                      return false
                    }

                    return (
                      <React.Fragment>
                        <Grid className={classes.contactForm}>
                          <div key={`installment-${currentFlow}`} className={checkDownPaymentValidations() ? /* istanbul ignore next*/classes.downPaymentErrorAlignment : (isInstallment ? classes.installmentAlignment : "")}>
                            {isInstallment && <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography style={{ fontWeight: 800, paddingBottom: isInstallment ? 10 : 0 }}>{t("down_payment")}</Typography>
                            </Grid>
                            }
                            {
                              (currentFlow == 1 || currentFlow == 2 || currentFlow == 3 || currentFlow == 4 || currentFlow == 7 || currentFlow == 8) && <React.Fragment>
                                <Typography className={classes.policySpacing}>
                                  {t("siib_commision")}
                                </Typography>
                                <Grid
                                  className={classes.policyDetailStyle}
                                  container
                                  spacing={2}
                                  style={{ borderBottom: '3px solid #eaeaea', marginBottom: 20 }}
                                >
                                  <Grid item xs={4}>
                                    <LabelWithIcon label={t("commision_rate")} />
                                    <TextInput
                                      data-test-id={"commisionRate"}
                                      className={classes.textInputStyle}
                                      isArabicLanguage={this.state.isArabicLenguage}
                                      name={`commisionRate`}
                                      value={values.commisionRate}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            %
                                          </div>
                                        </InputAdornment>
                                      }
                                      onChange={(e: any) => {
                                        setFieldValue('commisionRate', e.target.value)
                                        if(currentFlow === 2 || currentFlow === 4) /* istanbul ignore next*/setFieldValue('commisionAmount', 
                                        (((Number(stageSevenData?.attributes?.payment_insurance_infos?.data?.attributes?.paid_amount) - Number(stageSevenData?.attributes?.payment_insurance_infos?.data?.attributes?.refund_amount_to_customer))/1.15) * (e.target.value / 100)).toFixed(2))
                                        else setFieldValue('commisionAmount', ((paidAmount/1.15) * (e.target.value / 100)).toFixed(2))
                                      }}
                                      disabled={siibId}
                                    />
                                    {touched.commisionRate && errors.commisionRate ? (
                                      <Typography color="error">
                                        {errors.commisionRate}
                                      </Typography>
                                    ) : null}
                                  </Grid>
                                  <Grid item xs={4}>
                                    <LabelWithIcon label={`${t("commision_amount")} (${t('without_vat')})`} />
                                    <TextInput
                                      id={"commisionAmount"}
                                      className={classes.textInputStyle}
                                      isArabicLanguage={this.state.isArabicLenguage}
                                      name={`commisionAmount`}
                                      disabled={true}
                                      value={values.commisionAmount}
                                      type="number"
                                      data-test-id="email"
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <LabelWithIcon label={t("invoice_date")} />
                                    <TextInput
                                      data-test-id={`invoiceDate`}
                                      id={`policyExpireDateTxtInput`}
                                      className={classes.textInputStyle}
                                      name={`invoiceDate`}
                                      type={"date"}
                                      value={values.invoiceDate}
                                      disabled={siibId}
                                      onChange={(e: any) => setFieldValue('invoiceDate', e.target.value)}
                                    />
                                    {touched.invoiceDate && errors.invoiceDate ? (
                                      <Typography color="error">
                                        {errors.invoiceDate}
                                      </Typography>
                                    ) : null}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Button
                                      data-test-id="mainCreateInvoiceBtn"
                                      className={(values.commisionRate === "" || values.commisionAmount === "" || values.invoiceDate === "") || (this.state.isInvoiceIsCreated || values.invoiceDoc.url) ? classes.grayBtn : classes.primaryRvBtn}
                                      disabled={(values.commisionRate === "" || values.commisionAmount === "" || values.invoiceDate === "") || (this.state.isInvoiceIsCreated || values.invoiceDoc.url)}
                                      aria-haspopup="true"
                                      aria-controls="customized-menu"
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        /* istanbul ignore next*/
                                        this.generateInvoiceForInstallment(values, this.formikRef, insurance_company_id);
                                      }}
                                    >
                                      {!this.state.invoiceBtnLoding && t('create_invoice')}
                                      {this.state.invoiceBtnLoding && (<CircularProgress size={25} />)}
                                    </Button>
                                    {touched.siibInvoiceId && errors.siibInvoiceId ? (
                                      <Typography color="error">
                                        {errors.siibInvoiceId}
                                      </Typography>
                                    ) : null}
                                  </Grid>
                                  <Grid item xs={4}>
                                    <LabelWithIcon label={t("invoice")} />
                                    <DownloadFile
                                      data-test-id="txtInputInvoiceDoc"
                                      disabled={true}
                                      eachFileSizeInMB={10}
                                      maxFilesNumber={5}
                                      fileName={values.invoiceDoc.file_name || ""}
                                      isEditPage={this.state.isEdit}
                                      filePath={values.invoiceDoc.url || ""}
                                      inputName={`invoiceDoc`}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <LabelWithIcon label={t("ssib_invoice_id")} />
                                    <TextInput
                                      data-test-id={`siibInvoiceId`}
                                      id={`siibInvoiceId`}
                                      className={classes.textInputStyle}
                                      values={values.siibInvoiceId}
                                      name={`siibInvoiceId`}
                                      type={"text"}
                                      disabled={true}
                                    />
                                  </Grid>
                                  <Grid item xs={4} />
                                  <Grid item xs={4}>
                                    <LabelWithIcon label={t('invoice_paid')} />
                                    <FormControlLabel
                                      data-test-id={`invoicePaid`}
                                      className={classes.checkBoxInput}
                                      color="primary"
                                      name={`invoicePaid`}
                                      control={<Checkbox color="primary"
                                        disabled={siibId}
                                        checked={values.invoicePaid}
                                        onChange={(e: any) => {
                                          setFieldValue(`invoicePaid`, e.target.checked)
                                        }
                                        }
                                      />
                                      }
                                      label={t('yes')}
                                    />
                                  </Grid>
                                  <Grid xs={4} item>
                                    <LabelWithIcon label={t("payment_date")} />
                                    <TextInput
                                      data-test-id={`paymentDate`}
                                      className={classes.textInputStyle}
                                      name={`paymentDate`}
                                      type={"date"}
                                      value={values.paymentDate}
                                      disabled={siibId}
                                      onChange={(e: any) => setFieldValue('paymentDate', e.target.value)}
                                    />
                                    {touched.paymentDate && errors.paymentDate ? (
                                      <Typography color="error">
                                        {errors.paymentDate}
                                      </Typography>
                                    ) : null}
                                  </Grid>
                                  <Grid xs={4} item>
                                    <LabelWithIcon label={t("upload_proof_of_payment_recevied")} />
                                    <FileUpload
                                      eachFileSizeInMB={10}
                                      disabled={siibId}
                                      maxFilesNumber={5}
                                      fileName={values.proofOfPaymentReceviedDoc?.file_name || ""}
                                      filePath={values.proofOfPaymentReceviedDoc?.url || ""}
                                      data-test-id="proofOfPaymentReceviedDoc"
                                      inputName={`proofOfPaymentReceviedDoc`}
                                      onChange={(event: any) => this.uploadFileDoc('upload_proof_of_payment_recevied', event.target.files[0], this.formikRef, 'proofOfPaymentReceviedDoc')}
                                      onRemove={async() => {setFieldValue(`proofOfPaymentReceviedDoc`, "")
                                      this.removeFileStageNine(values.proofOfPaymentReceviedDoc?.id)
                                      }}
                                    />
                                    {touched.proofOfPaymentReceviedDoc && errors.proofOfPaymentReceviedDoc ? (
                                      <Typography color="error" style={{ paddingTop: 10 }}>
                                        {errors.proofOfPaymentReceviedDoc}
                                      </Typography>
                                    ) : null}
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                            }
                            {/* sales commision */}
                            <Grid />
                            <Typography className={classes.policySpacing}>
                              {t("sales_commision")}
                            </Typography>
                            <Grid
                              className={classes.policyDetailStyle}
                              container
                              spacing={2}
                            >
                              <Grid item xs={4}>
                                <LabelWithIcon label={t("commision_rate")} />
                                <TextInput
                                  id={"salesCommisionRate"}
                                  data-test-id={"salesCommisionRate"}
                                  className={classes.textInputStyle}
                                  isArabicLanguage={this.state.isArabicLenguage}
                                  name={`salesCommisionRate`}
                                  disabled={salesId}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        %
                                      </div>
                                    </InputAdornment>
                                  }
                                  onChange={(e: any) => {
                                    const commission = this.getCommission(currentFlow, values, stageSevenData)
                                    setFieldValue('salesCommisionRate', e.target.value)
                                    if(stageSevenData?.attributes?.commission?.data?.attributes?.deduct_commission_with_vat === 'yes') setFieldValue('salesCommisionAmount', (((commission/1.15) * e.target.value) / 100).toFixed(2))
                                  else setFieldValue('salesCommisionAmount', ((commission * e.target.value) / 100).toFixed(2))
                                  }}
                                />
                                {touched.salesCommisionRate && errors.salesCommisionRate ? (
                                  <Typography color="error">
                                    {errors.salesCommisionRate}
                                  </Typography>
                                ) : null}
                              </Grid>
                              <Grid item xs={4}>
                                <LabelWithIcon label={`${t("commision_amount")} (${t('without_vat')})`} />
                                <TextInput
                                  id={"salesCommisionAmount"}
                                  className={classes.textInputStyle}
                                  isArabicLanguage={this.state.isArabicLenguage}
                                  name={`salesCommisionAmount`}
                                  disabled={true}
                                  value={values.salesCommisionAmount}
                                  type="number"
                                  data-test-id="email"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <LabelWithIcon label={t("credit_note_date")} />
                                <TextInput
                                  data-test-id={`creditNoteDate`}
                                  id={`policyExpireDateTxtInput`}
                                  className={classes.textInputStyle}
                                  name={`creditNoteDate`}
                                  type={"date"}
                                  value={values.creditNoteDate}
                                  disabled={creditNoteId}
                                  onChange={(e: any) => setFieldValue('creditNoteDate', e.target.value)}
                                />
                                {touched.creditNoteDate && errors.creditNoteDate ? (
                                  <Typography color="error">
                                    {errors.creditNoteDate}
                                  </Typography>
                                ) : null}
                              </Grid>
                              <Grid item xs={4}>
                                <LabelWithIcon label={t("sales_person")} />
                                <TextInput
                                  data-test-id={`salesPerson`}
                                  id={`salesPerson`}
                                  className={classes.textInputStyle}
                                  name={`salesPerson`}
                                  type={"text"}
                                  disabled={true}
                                  value={values.salesPerson.label}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => this.createCreditNoteHandler(values, insurance_company_id)}
                                  className={((values.salesCommisionRate === "" || values.salesCommisionAmount === "" || values.creditNoteDate === "") || (values.creditNoteDocId !== undefined && values.creditNoteDocId !== "")) ? classes.salesGrayBtn : classes.salesPrimaryBtn}
                                  disabled={((values.salesCommisionRate === "" || values.salesCommisionAmount === "" || values.creditNoteDate === "") || (values.creditNoteDocId !== undefined && values.creditNoteDocId !== ""))}
                                  aria-haspopup="true"
                                  aria-controls="customized-menu"
                                  data-test-id="createCreditNoteBtn"
                                >
                                  {this.state.isCreditNoteBtnLoading ? <CircularProgress size={25} /> : t('create_credit_note')}
                                </Button>
                                {touched.creditNoteDocId && errors.creditNoteDocId ? (
                                  <Typography color="error">
                                    {errors.creditNoteDocId}
                                  </Typography>
                                ) : null}
                              </Grid>
                              <Grid item xs={4} />
                              <Grid item xs={4}>
                                <LabelWithIcon label={t("credit_note")} />
                                <DownloadFile
                                  data-test-id="creditNoteInvoice"
                                  disabled={true}
                                  eachFileSizeInMB={10}
                                  maxFilesNumber={5}
                                  fileName={values?.creditNoteDoc?.file_name || ""}
                                  isEditPage={this.state.isEdit}
                                  filePath={values?.creditNoteDoc?.url || ""}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <LabelWithIcon label={t("credit_note_id")} />
                                <TextInput
                                  data-test-id={`creditNoteDocId`}
                                  id={`policyExpireDateTxtInput`}
                                  className={classes.textInputStyle}
                                  name={`creditNoteDocId`}
                                  disabled={true}
                                  value={values.creditNoteDocId}
                                />
                              </Grid>
                            </Grid>
                          </div>
                          {isInstallment && this.state.stageSevenInstallments.map((item: any, index: number) =>
                            <div key={`installment-${index.toString()}`} style={{ marginTop: 25 }}>
                              <Accordion
                                data-test-id="p9"
                                className={checkInstalmentValidation(index) ? /* istanbul ignore next*/classes.installmentErrorAlignment : classes.downPaymentAccordionStyle}
                                defaultExpanded={false}
                              >
                                <AccordionSummary
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  expandIcon={<ExpandMore />}
                                >
                                  <div className={classes.topSection}>
                                    <div className={classes.displayFlex}>
                                      <Typography style={{ fontWeight: 600 }}>{index + 2}{this.getSuffix(index + 2)} {t("installment")}</Typography>
                                    </div>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails style={{ borderTop: '3px solid #eaeaea' }}>
                                  <StageNineInstalmentForm
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    currentFlow={currentFlow}
                                    paidAmount={paidAmount}
                                    installmentIndex={index}
                                    stageSevenData={stageSevenData}
                                    touched={touched}
                                    errors={errors}
                                    formikRef={this.formikRef}
                                    insurance_company_id={insurance_company_id}
                                    isDraft={isDraft}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          )}
                          {(currentFlow == 1 || currentFlow == 2 || currentFlow == 3 || currentFlow == 4 || currentFlow == 7 || currentFlow == 8) && <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                            <Typography>{t("revenue_siib_commission_from")} {insurance_company}</Typography>
                            <Typography>SAR {(balance).toFixed(2)}</Typography>
                          </Grid>}
                        </Grid>
                      </React.Fragment>
                    );
                  }}
                </Formik>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid>
            {(this.props.isExpanded && this.isStageNineAccordionOpen(this.props.isExpanded)) ? (
              <div className={classes.BtnGroupSpacing}>
                <div className={classes.buttonsRightWrapper}>
                  <CustomButton
                    data-test-id="saveDraft"
                    btnText={t("save_draft")}
                    onClick={() => this.saveDraftData(this.formikRef)}
                    bgColor={"#fff"}
                    textColor={"#007E98"}
                  />
                  <CustomButton
                    data-test-id="rejectBtn"
                    btnText={t("reject")}
                    onClick={() => this.handleStageNineRejectDialoug(true)}
                    bgColor={"#fff"}
                    textColor={"#007E98"}
                  />
                  {showPayInstalmentBtn ?
                    /* istanbul ignore next*/ <CustomButton
                      data-test-id="payInstalmentBtn"
                      btnText={t("pay_installment")}
                      onClick={() => this.handleSubmitFromOutside(showPayInstalmentBtn)}
                      bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                      
                      textColor={"#fff"}
                    /> : <CustomButton
                      data-test-id="nextStageBtn"
                      btnText={t("complete")}
                      onClick={() => this.handleSubmitFromOutside(showPayInstalmentBtn)}
                      bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                      textColor={"#fff"}
                    />
                  }
                </div>
              </div>
            ) : null}
          </Grid>

          <HandOverDialog
            btnCancelText={t('cancel')}
            btnOkText={t('confirm')}
            openDialog={this.state.showNoteDialog as boolean}
            textId={'handOverNoteText'}
            headingText={t('handover_note')}
            subHeading={String(this.getSubheading(this.state.isBtnVisible, t))}
            inputLable={this.state.showNoteDialog ? t('note') : ""}
            inputValue={this.state.isBtnVisible ? this.state.handOverNoteValue : stageEightData.submitApiResponse?.attributes?.handover_note_invoicing}
            handleOk={(value: string) => this.handleOkDialog(value)}
            handleCancel={() => this.handleHandOverNote(false)}
            handleInputText={this.handleHandOverNoteText}
            showDialogBtns={this.state.isBtnVisible}
            showCrossBtn={true}
            inputDisable={!this.state.isBtnVisible}
          />

          <RejectRequestDialog
            btnCancelText={t('cancel')}
            btnOkText={t('confirm')}
            openDialog={this.state.showRejectDialog}
            textId={'rejectReason'}
            pushBackOptions={this.getPoicyOption()}
            setSelectedPushBackTo={(e: any) => {
              this.setState({ currentRejectedStage: e.value })
            }}
            headingText={t('rejecting')}
            handleInputText={(value: string) => this.setState({ stageNineRejectReason: value })}
            handleOk={() => this.trackStageNinePolicy(this.state.currentRejectedStage, 'reject_stage', this.state.stageNineRejectReason)}
            handleCancel={() => this.handleStageNineRejectDialoug(false)}
            showDialogBtns={true}
            showCrossBtn={true}
            labelText={t('push_back')}
            reasonLabel={t('reason')} />
        </ThemeProvider>
    );
  }
}
const useStyles = {
  policyDetailStyle: {
    padding: "15px 0px",
  },
  policySpacing: {
    margin: "10px 0px",
    fontWeight: 600,
  },
  customerDetailsAccordion: {
    paddingTop: "10px",
    paddingBottom: "10px",
    "& .MuiAccordion-root-34.Mui-disabled, .MuiAccordion-root-42.Mui-disabled": {
      backgroundColor: "white",
    },
    " & .MuiAccordion-root.Mui-disabled": {
      backgroundColor: "white",
    },
  },
  BtnGroupSpacing: {
    padding: "0px 15px",
  },
  checkBoxInput: {
    marginTop: "15px"
  },
  grayBtn: {
    "text-transform": 'none',
    padding: 15,
    borderRadius: 5,
    width: '32.5%',
    fontSize: 15,
    backgroundColor: "#979090",
    color: "white"
  },
  salesGrayBtn: {
    "text-transform": 'none',
    padding: 15,
    borderRadius: 5,
    width: '100%',
    fontSize: 15,
    backgroundColor: "#979090",
    color: "white",
    marginTop: "37px",
  },
  salesPrimaryBtn: {
    "text-transform": 'none',
    padding: 15,
    borderRadius: 5,
    width: '100%',
    fontSize: 15,
    "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    color: "white",
    marginTop: "37px",
  },
  buttonsRightWrapper: {
    display: "flex",
    gap: "15px",
    padding: "15px 0px",
    justifyContent: "flex-end",
  },
  toggleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      color: '#0090a1'
    }
  },
  iconAlignment: {
    display: 'flex',
    marginRight: "10px"
  },
  lostIcon: {
    marginLeft: "15px",
    '& svg': {
      color: '#0090a1'
    },
  },
  topSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  displayFlex: {
    display: "flex",
  },

  errorSection: {
    border: "1px solid red",
  },

  accordionStyle: {
    background: "white !important",
    borderRadius: "10px !important",
  },
  accordianHeading: {
    fontWeight: 600,
  },
  contactForm: { width: "100%" },
  textInputStyle: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  installmentAlignment: {
    border: "3px solid #eaeaea",
    borderRadius: "10px",
    padding: "20px",
  },
  downPaymentAccordionStyle: {
    border: '3px solid #eaeaea',
    borderRadius: "10px !important",
    boxShadow: 'none'
  },
  primaryRvBtn: {
    "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    "text-transform": 'none',
    "padding": 15,
    "borderRadius": 5,
    "width": '32.5%',
    "fontSize": 15,
    "color": "white"
  },
  downPaymentErrorAlignment: {
    border: "1px solid #f44336",
    borderRadius: "10px",
    padding: "20px",
  },
  installmentErrorAlignment: {
    border: "1px solid #f44336",
    borderRadius: "10px !important",
    boxShadow: 'none'
  }
};

export default withStyles(useStyles)(withTranslation()(StageNineForm));
// Customizable Area End
