// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import React from "react";

export const configJSON = require("../config");

const enumBtnType = {
  NEXT: "NEXT",
  DRAFT: "DRAFT",
  REJECT: "REJECT",
};

const enumTosterType = {
  error: "error",
  success: "success",
  warning: "warning",
  info: "info",
};
const enumRoles = {
  ADMIN: "Admin",
  OPERATIONS: "Operations",
  CONTACT: "Contact",
};

export interface Props {
  isExpanded: boolean;
  isEdit: boolean;
  onNext?: any;
  onReject?: any;
  t?:any
  setStageData?:any;
}

interface S {
  policyId: string;
  isArabicLanguage: boolean;
  expandedAccordion: boolean;
  insurerDetail: any;
  role: any;
  stageOneData: any;
  view: boolean;
  isCreate: boolean;
  errorSection: string;
  premiumCalculation: any;
  premiumCalculationFile:any;
  currentFileName: any;
  comparisionSheet: any;
  comparisionSheetFile: any;
  showDialog: boolean;
  contectOption?: any[];
  insuranceCompany?: any[];
  showAlert: boolean;
  alertMessage: string;
  alertType: string;
  blockError: boolean[];
  toster: boolean;
  dialoagFor: any;
  radioVal: any;
  tosterType: any;
  stepNumber: any;
  tosterText: string;
  showRejectDialog: boolean;
  accordianDisable: boolean;
  rejectReason: string;
  rejectedStage: string;
  currentFileUrl: any;
  currentSteps: number;
  showNoteDialog: boolean;
  isBtnVisible: boolean;
  handOverNoteText: string;
  handleDarftBtn: string;
  hasCreatePermissionList?: string[];
  hasViewPermissionList?: string[];
  hasUpdatePermissionList?: string[];
  loggedInRole: string;
}

interface SS {
  id: any;
}
export default class StageFourController extends BlockComponent<Props, S, SS> {
  addQuotationApi: any;
  EditQuotationApi: any;
  getQuotationApi: any;
  trackApi: any;
  formRef: any;
  getStageThreeData: any;
  modifyDraftApiCall: any = "";
  draftQuotationApiCall: any = "";
  formikRefStage: any = null;
  fileToRemove: any = '';
  fieldName:any="";
  removeFileAPI:string="";
  uploadFileApiCallForStageFour: any = "";
  policyStatusChange: any = "";
  getStageThreeQuotationApi: any = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    const queryParameter: any = new URLSearchParams(window.location.search);
    const policyId: string = queryParameter?.get("id");
    const isView: boolean = window.location.href.includes("ViewPolicy");
    const isCreate: boolean = window.location.href.includes("PolicyRequest");

    this.state = {
      role: "",
      policyId: policyId || "",
      stageOneData: JSON.parse(
        localStorage.getItem("stageOnePartTwoData") || "{}"
      ),
      isArabicLanguage:
        (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      expandedAccordion: false,
      showDialog: false,
      showAlert: false,
      alertMessage: "",
      alertType: "",
      blockError: [],
      dialoagFor: "",
      view: isView,
      isCreate: isCreate,
      insurerDetail: [
        {
          proposal: "",
          proposal_file_name:"",
          comment: "",
          quotationAmount: "",
          dateofReceiving: "",
          quotationId: "",
          quotationReceived: false,
        },
      ],
      accordianDisable: false,
      premiumCalculation: {},
      premiumCalculationFile:"",
      comparisionSheet: {},
      comparisionSheetFile:"",
      errorSection: "",
      toster: false,
      radioVal: "",
      tosterText: "",
      showRejectDialog: false,
      rejectReason: "",
      rejectedStage: "",
      currentFileName: "",
      stepNumber: "",
      loggedInRole: JSON.parse(
        window.localStorage.getItem("loginData") || "{}"
      ),
      hasCreatePermissionList: [],
      hasViewPermissionList: [],
      hasUpdatePermissionList: [],
      currentFileUrl: "",
      currentSteps: 1,
      showNoteDialog: false,
      isBtnVisible: true,
      handOverNoteText: "",
      handleDarftBtn: "",
      tosterType: enumTosterType.error,
    };
    this.formRef = React.createRef();
    this.formRef.current = [];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
/* istanbul ignore next */
  isViewPermissionHasLoggedInRole = () => {
    if (this.state.hasViewPermissionList?.includes(this.state.loggedInRole)) {
      if (
        this.state?.hasUpdatePermissionList?.includes(
          this.state?.loggedInRole
        )
      ) {
        return;
      }
    } else {
      this.setState({ expandedAccordion: false });
    }
  }

  checkIsStateCreated(id: string) {
    const handoverNote = localStorage.getItem("handoverNote");
    const stagePartOneData = JSON.parse(
    localStorage.getItem("stageOne") ?? "{}"
    );
    const policyID = stagePartOneData.policyId;
    const stageOneData = JSON.parse(
    localStorage.getItem("stageOnePartTwoData") ?? "{}"
    );

    if (id) {
      
      this.setState({ policyId: id });
      this.getStageThreeDataByIdApiCall(id)
     
      if (
        this.state.hasCreatePermissionList?.includes(this.state.loggedInRole)
      ) {
        this.setState({ role: true });
      }
      this.isViewPermissionHasLoggedInRole();
    } else {
      this.initialOperationForm(stageOneData.insuranceCompany);

      if (this.state.isCreate) {
        this.setState({ policyId: id });
      }
      if (handoverNote !== null)
        this.setState({ policyId: policyID, handOverNoteText: handoverNote });
    }
  }

  componentDidMount(): any {
    const queryParameter: any = new URLSearchParams(window.location.search);
    const id: string = queryParameter.get("id");
    const loginData = JSON.parse(localStorage.getItem("loginData") || "{}");

    if (
      loginData.role === enumRoles.ADMIN ||
      loginData.role === enumRoles.OPERATIONS
    ) {
      this.setState({ role: true });
    }
    // else if (loginData.role === enumRoles.CONTACT) {
    //   this.setState({ accordianDisable: true });
    // }

    this.checkIsStateCreated(id);
    this.getUpdatedComments(this.state.policyId);
  }

  getInitialInsurerDetail = async (insurerData: any) => {
    if (insurerData.length > 0) {
      let array: {
        id: any;
        insurance_id: any;
        label: string;
        comment: string;
        quotationAmount: string;
        dateofReceiving: any;
        proposal: any;
        proposal_file_name:any;
        quotationId: any;
        quotationReceived: any;
      }[] = [];

      await insurerData.map((insurer: any) => {
        const item = insurer;

        array.push({
          id: item.id,
          insurance_id: item.value,
          label: item.label,
          proposal: "",
          proposal_file_name: "",
          comment: "",
          quotationAmount: "",
          dateofReceiving: "",
          quotationId: "",
          quotationReceived: false,
        });
      });
      return array;
    } else {
      return this.state.insurerDetail;
    }
  };

  componentDidUpdate() {
    if (this.state.showAlert) {
      setTimeout(() => {
        this.setState({
          showAlert: false,
          alertType: "",
          alertMessage: "",
        });
      }, 10000);
    }
  }

  getStageFourAlertMessaeg = (isDraft: boolean) => {
    const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
    let alertMessage = "";
    if (isDraft) {
      alertMessage = isArabic ? "تم حفظ بيانات طلب المراجعة كمسودة!" : "Data has been saved as draft!"
    } else {
      alertMessage = isArabic? "لقد تم نقل طلبك إلى المرحلة التالية" : `Your request has been moved to next stage!`
    }
    return alertMessage
  }

  setStageFourApiErrorMessage = (responseJson: any) => {
    if (!responseJson) return;
    let errorMessage = "Something went wrong"
    if (responseJson?.errors) {
      errorMessage = typeof (responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error || "Something went wrong";
    }
    this.setState({
      alertType: "error",
      alertMessage: errorMessage,
      showAlert: true,
      showDialog: false,
      showNoteDialog: false
    })
  }

  handleAccordian(isExpanded: boolean, accordionName: string) {
    if (this.state.loggedInRole === enumRoles.CONTACT) {
      this.setState({
        alertMessage: `Submitting quotations is not accessible to ${this.state.loggedInRole} in user`,
        alertType: "warning",
        showAlert: true,
      });
    } else {
      this.setState({
        expandedAccordion: isExpanded ? true : false,
      });
    }
  }
  hasOnlyViewRights = () => {
    return this.state.hasViewPermissionList?.includes(
      this.state.loggedInRole
    ) as boolean;
  };
  isP4Expanded = (isExpanded: any) => {
    const queryParameter: any =
      typeof window !== "undefined" &&
      new URLSearchParams(window.location.search);
    const id: string = queryParameter.get("id");
    if (this.state.loggedInRole === enumRoles.CONTACT) {
      return false;
    }
    if (isExpanded === true && this.state.expandedAccordion) {
      return true;
    }
    if (id !== "" && this.state.expandedAccordion) {
      return true;
    }
    return false;
  };
  getPoicyStageOption = () => {
    const array = [
      { value: "info_gathering", label: this.props.t('info_gathering') },
      { value: "processing", label: this.props.t('processing') },
      { value: "awaiting", label: this.props.t('awaiting') }
    ];
    return array;
  };

  handleHandOverNoteText = (inputText: string) => {
    this.setState({ handOverNoteText: inputText });
  };

  closeNoteDialog = () => {
    this.setState({ showNoteDialog: false,handOverNoteText:"", isBtnVisible: true });
  };

  openNoteDialog = () => {
    this.setState({ showNoteDialog: true, isBtnVisible: false });
  };

  handleOkDialog = () => {
    this.closeNoteDialog();
  };

  handleChangeRejectReason = (value: string) => {
    this.setState({
      rejectReason: value,
    });
  };
  handleRejectDialog = (value: boolean) => {
    this.setState({
      showRejectDialog: value,
      isBtnVisible: true
    });
  };

  getStepName = (currentStep: number) => {
    switch (currentStep) {
      case 1:
        return "info_gathering";
      case 2:
        return "processing";
      case 3:
        return "awaiting";
      case 4:
        return "operations_review";
      case 5:
        return "sales_review";
      case 6:
        return "customer_review";
      default:
        return "";
    }
  };

  getStepNumber = (stageName: string) => {
    switch (stageName) {
      case "info_gathering":
        return 1;
      case "processing":
        return 2;
      case "awaiting":
        return 3;
      case "operations_review":
        return 4;
      case "sales_review":
        return 5;
      case "customer_review":
        return 6;
      default:
        return "";
    }
  }
  getStepAccordianName = (currentStep: number) => {
    switch (currentStep) {
      case (1):
        return "Info Gathering"
      case (2):
        return "Processing"
      case (3):
        return "Awaiting"
      case (4):
        return "operations_review"
      case (5):
        return "sales_review"
      case (6):
        return "customer_review"
      default:
        return "";
    }
  }

  changePolicyStep = (currentStep: string, action: string) => {
    const currentStepNum = this.getStepNumber(currentStep);
    this.setState({ stepNumber: currentStepNum });

    const data = {
      data: {
        track_request: {
          stage_status: action, // reject_stage, next_stage
          comment: this.state.rejectReason,
          current_stage: currentStep,
        },
      },
    };

    this.policyStatusChange = this.TrackApiCall(data, true);
    this.handleRejectDialog(false);
  };

  async initialOperationForm(stageOneData: any = []) {
    const initial = await this.getInitialInsurerDetail(stageOneData);
    this.setState({
      insurerDetail: initial,
      premiumCalculation: "",
      comparisionSheet: "",
    });
  }

  getFormikValues = () => {
    const formikValues = this.formRef.current;
    return formikValues;
  };

  setErrorData(firstKey: string, errors: any) {
    let errorValue = "";
    let errorSection = "";

    if (firstKey === "insurerDetails") {
      const array = errors.insurerDetails;

      for (const element of array) {
        if (element !== null && element !== undefined && typeof element === "object") {
          const obj = element;
          const firstValue = Object.values(obj)[0] as string;
          errorValue = firstValue;
        }
      }

      errorSection = "p4";
    } else {
      const keyValue = ["quotationID", "dateofReceiving", "quotationAmount", "proposal", "comment"]
      if (
        keyValue.includes(firstKey)
      ) {
        errorSection = "p4";
      } else {
        errorSection = "p1";
      }
      errorValue = errors[firstKey];
    }
    return { errorValue, errorSection }
  }

  checkStageFourForm = (errors: any) => {
    if (Object.keys(errors).length === 0) {
      this.setState({ showDialog: true, errorSection: "" });
    } else {
      const keys = Object.keys(errors);

      const firstKey = keys[0];

      const { errorValue, errorSection } = this.setErrorData(firstKey, errors)

      this.setState({
        showAlert: true,
        alertMessage: errorValue,
        alertType: "error",
        expandedAccordion: false,
        errorSection: errorSection,
      });
    }
  };

  closeAlertMessage = async () => {
    this.setState({
      showAlert: false,
      alertMessage: "",
      alertType: "",
    });
  };

  handOverNoteSubmit = (value: string) => {
      const data = {
          data: {
            track_request: {
              stage_status: "next_stage", // reject_stage, next_stage
              comment: value,
              current_stage: 'sales_review', // change into new
            },
          },
      };

      this.policyStatusChange = this.TrackApiCall(data);
  }

  formDataFormat = (
    values: any,
    isDraft: boolean = false,
    isEdit: boolean = false
  ) => {
    const formData = new FormData();
    const stageFourData = JSON.parse(localStorage.getItem('stageFourData') || '{}');
    values?.insurerDetails?.forEach((item: any, index: number) => {
      if ((item.quotationReceived === true || item.quotationReceived === "yes")) {
        this.setState({ radioVal: "yes" });
      } else {
        this.setState({ radioVal: "no" });
      }
      isEdit &&
        formData.append(
          `data[submitting_quotation][submitting_insurance_infos_attributes][${[
            index,
          ]}][id]`,
          stageFourData?.attributes?.submitting_insurance_infos?.data?.[index]?.id
        );
        (item.quotationReceived === true || item.quotationReceived === "yes") &&
        formData.append(
          `data[submitting_quotation][submitting_insurance_infos_attributes][${[
            index,
          ]}][quotation_received]`,
          "yes"
        );
      item.quotationId &&
        formData.append(
          `data[submitting_quotation][submitting_insurance_infos_attributes][${[
            index,
          ]}][quotation_id]`,
          item.quotationId
        );
      item.dateofReceiving &&
        formData.append(
          `data[submitting_quotation][submitting_insurance_infos_attributes][${[
            index,
          ]}][date_of_receiving_quotation]`,
          item.dateofReceiving
        );
      item.quotationAmount &&
        formData.append(
          `data[submitting_quotation][submitting_insurance_infos_attributes][${[
            index,
          ]}][quotation_amount]`,
          item.quotationAmount
        );
      item.comment &&
        formData.append(
          `data[submitting_quotation][submitting_insurance_infos_attributes][${[
            index,
          ]}][comment]`,
          item.comment
        );
      item.insurance_id &&
        formData.append(
          `data[submitting_quotation][submitting_insurance_infos_attributes][${[
            index,
          ]}][insurance_company_id]`,
          item.insurance_id
        );
    });
    formData.append(
      `data[submitting_quotation][save_as_draft]`,
      isDraft ? "yes" : "no"
    );
    return formData;
  };
  getUploadFormData = (file_key: string, fileData: any, policyId: any) => {
    if (policyId == this.state.policyId) {
      const formData = new FormData();
      formData.append("data[stage]", "4");
      formData.append("data[file_key]", file_key);
      formData.append("data[file]", fileData);
      formData.append("data[multiple]", "false");

      return formData;
    } else {
      const formData = new FormData();
      formData.append("data[stage]", "4");
      formData.append("data[file_key]", file_key);
      formData.append("data[file]", fileData);
      formData.append("data[insurance_company_id]", policyId);
      formData.append("data[multiple]", "false");
      return formData;
    }
  };

  submitCustomerForm = (formikValues: any) => {
    this.AddQuotationApiCall(formikValues);
  };
  updateCustomerForm = (formikValues: any) => {
    this.EditQuotationApiCall(formikValues, this.state.policyId);
  };

  getUpdatedComments = async (policyId: any) => {
    this.getStageThreeData = this.apiCallForStageFour({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=3`,
    });
  };

  DraftQuotationApiCall = async (values: any) => {
    this.draftQuotationApiCall = this.apiCallForStageFour({
      type: "FormData",
      method: "POST",
      body: values,
      endPoint: `bx_block_policy_request/policy_requests?stage=4&id=${this.state.policyId}`,
    });
  };

  modifyDraftApiCallData = async (values: any) => {
    this.modifyDraftApiCall = this.apiCallForStageFour({
      type: "FormData",
      method: "PUT",
      body: values,
      endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=4`,
    });
  };
  handleDraftSubmit = async (action: string, stageFourData: any) => {
    const stagThreeData = JSON.parse(window.localStorage.getItem('stageThreeData') ?? '{}')
    const formikValues = this.getFormikValues();
    if(this.state.policyId && stagThreeData.attributes?.is_next_stage_submitted){
      const formData=this.formDataFormat(formikValues.values, true, true)
      this.modifyDraftApiCallData(formData)
    }else{
      const formData=this.formDataFormat(formikValues.values, true)
      this.DraftQuotationApiCall(formData);
    }
    if (action === enumBtnType.DRAFT) {
      this.setState({ handleDarftBtn: enumBtnType.DRAFT });
    }
  };
  handleReject = () => {
    this.setState({
      showRejectDialog: true,
      handleDarftBtn: enumBtnType.REJECT,
    });
  };
  removeFile = (fileId: number, formikRef: any,fieldName:any) => {
    this.fileToRemove = fileId;
    this.formRef = formikRef;
    this.fieldName=fieldName;
    this.removeFileAPI = this.apiCallForStageFour({
      endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/remove_file`,
      method: 'PUT',
      contentType: 'application/json',
      body: { data: { file_id: fileId } },
    })
}
removeStageFourFileAPIReceive = (message:any) => {
  if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.removeFileAPI !== null &&
      this.removeFileAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    )   {
    const responseJsonFile = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    /*istanbul ignore next*/
      if (responseJsonFile?.data) {
          this.formRef.current && this.formRef.current.setFieldValue(this.fieldName,{})
          this.setState({
            showAlert:true,
            alertType:'warning',
            alertMessage:responseJsonFile.data?.message,
          })
      }
  }
}
  uploadFileStageFour = async (
    fileName: string,
    fileData: any,
    policyId: any,formikRef:any,fieldName:any
  ) => {
    this.setState({
      currentFileName: fileName,
    });

    const formDataForStageFour = this.getUploadFormData(fileName, fileData, policyId);
  

const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
const token = await userData.token || '';

  this.formikRefStage = formikRef
  this.fieldName = fieldName
const header = {
    token
};
    const requestMessageForStageFour = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadFileApiCallForStageFour = requestMessageForStageFour.messageId;

    requestMessageForStageFour.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageForStageFour.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_policy_request/policy_requests/${this.state.policyId}/file_upload`
    );
    requestMessageForStageFour.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );

    requestMessageForStageFour.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataForStageFour
    );

    runEngine.sendMessage(requestMessageForStageFour.id, requestMessageForStageFour);
    return requestMessageForStageFour.messageId;
  }
  async getStageThreeDataByIdApiCall(id: any) {
    this.getStageThreeQuotationApi = this.apiCallForStageFour({
      method: "GET",
      endPoint: `bx_block_policy_request/policy_requests/${id}?stage=3`,
    });
  }


  async getDataByIdApiCall(id: any) {
    this.getQuotationApi = this.apiCallForStageFour({
      method: "GET",
      endPoint: `bx_block_policy_request/policy_requests/${id}?stage=4`,
    });
  }

  async AddQuotationApiCall(data: any) {
    const formData = this.formDataFormat(data);
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addQuotationApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_policy_request/policy_requests?id=${this.state.policyId}&stage=4`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  async EditQuotationApiCall(data: any, id: string) {
    const formData = this.formDataFormat(data, false, true);
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.EditQuotationApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_policy_request/policy_requests/${id}?stage=4`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  async TrackApiCall(data: any, isReject: boolean = false) {
    const policy_request_id = this.state.policyId;
    this.trackApi = this.apiCallForStageFour({
      contentType: "application/json",
      method: "POST",
      endPoint: isReject
        ? `bx_block_policy_request/policy_requests/${policy_request_id}/track_requests?stage=4`
        : `bx_block_policy_request/policy_requests/${policy_request_id}/track_requests`,
      body: data,
    });
  }

  getInsurerDetail = async (insurerData: any) => {
    if (insurerData.length > 0) {
      let array: {
        id: any;
        insurance_id: any;
        label: string;
        comment: string;
        quotationAmount: string;
        dateofReceiving: any;
        proposal: any;
        proposal_file_name: any;
        quotationId: any;
        quotationReceived: boolean;
      }[] = [];

      await insurerData.map((insurer: any, insurerVal: any) => {
        const item = insurer.attributes;
        array.push({
          id: insurer.id,
          insurance_id: item.insurance_company_id.id,
          label: item.insurance_company_id.short_name,
          proposal: item.proposal,
          proposal_file_name: item.proposal?.file_name,
          comment: item.comment,
          quotationAmount: item.quotation_amount,
          dateofReceiving: item.date_of_receiving_quotation,
          quotationId: item.quotation_id,
          quotationReceived: item.quotation_received,
        });
      });
      return array;
    } else {
      return this.state.insurerDetail;
    }
  };

  apiCallForStageFour(data: any) {
    const { contentType, method, endPoint, body, type } = data;
    const loginDetails = JSON.parse(localStorage.getItem("loginData") ?? "{}");
    const accessToken = loginDetails.token || "";

    let headerForStageFour: any = {
      "Content-Type": contentType,
      token: accessToken,
    };

    const requestMessageForStageFour = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForStageFour.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerForStageFour)
    );
    requestMessageForStageFour.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForStageFour.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== 'FormData' ?
    requestMessageForStageFour.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      ) : requestMessageForStageFour.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
    );

    runEngine.sendMessage(requestMessageForStageFour.id, requestMessageForStageFour);
    return requestMessageForStageFour.messageId;
  }

  async getQuotationData(message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getQuotationApi != null &&
      this.getQuotationApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        window.localStorage.setItem(
          "stageFourData",
          JSON.stringify(responseJson.data)
        );
        this.props.setStageData("stageFourData",responseJson.data);
        const insurerDetails = await responseJson.data.attributes;
        const indexWiseDetails =
          await insurerDetails.submitting_insurance_infos;
        const insurerArray = await this.getInsurerDetail(indexWiseDetails.data);
        this.setState({
          insurerDetail: insurerArray,
          premiumCalculation: insurerDetails.premium_calculation,
          premiumCalculationFile: insurerDetails.premium_calculation.file_name,
          comparisionSheet: insurerDetails.comparison_sheet,
          comparisionSheetFile: insurerDetails.comparison_sheet.file_name,
          // handOverNoteText: insurerDetails.handover_note,
          policyId:responseJson.data.attributes.policy_request_id
        });
      } else {
        this.setStageFourApiErrorMessage(responseJson)
      }
      if (responseJson.meta) {
        const {
          current_stage_create_permission_role,
          current_stage_view_permission_role,
          current_stage_update_permission_role,
        } = responseJson.meta;
        const { role } = JSON.parse(
          window.localStorage.getItem("loginData") || "{}"
        );

        this.setState({
          hasCreatePermissionList: current_stage_create_permission_role,
          hasViewPermissionList: current_stage_view_permission_role,
          hasUpdatePermissionList: current_stage_update_permission_role,
          loggedInRole: role,
        });
      }
    }
  }
  checkSubmitFlow =(responseJson:any)=>{
    if (responseJson.data.attributes.is_next_stage_submitted) {
      this.getDataByIdApiCall(responseJson.data.attributes.policy_request_id) } else { 
      const handoverNote = localStorage.getItem("handoverNote");
      const stageOneData = JSON.parse(
      localStorage.getItem("stageOnePartTwoData") ?? "{}"
      );
      this.initialOperationForm(stageOneData.insuranceCompany);

      if (this.state.isCreate) {
        this.setState({ policyId: responseJson.data.attributes.policy_request_id });
      }
      if (handoverNote !== null)
        {this.setState({ policyId: responseJson.data.attributes.policy_request_id, handOverNoteText: handoverNote });}
    }
  }
  async getQuotationstageThreeData(message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getStageThreeQuotationApi != null &&
      this.getStageThreeQuotationApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        const { role } = JSON.parse(
          window.localStorage.getItem("loginData") || "{}"
        );
        this.setState({
          policyId:responseJson.data.attributes.policy_request_id,
          hasCreatePermissionList: responseJson.meta.next_stage_create_permission_role,
          hasViewPermissionList: responseJson.meta.next_stage_view_permission_role,
          hasUpdatePermissionList: responseJson.meta.next_stage_update_permission_role,
          loggedInRole: role,

      })
      window.localStorage.setItem('stageThreeData', JSON.stringify(responseJson.data))
      this.props.setStageData("stageThreeData",responseJson.data);
      this.checkSubmitFlow(responseJson)


      } else {
        this.setStageFourApiErrorMessage(responseJson)
      }
      
    }
  }


  fetchStageThreeRecords(message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getStageThreeData != null &&
      this.getStageThreeData ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.meta) {
        const {
          next_stage_create_permission_role,
          next_stage_view_permission_role,
          next_stage_update_permission_role,
        } = responseJson.meta;
        const { role } = JSON.parse(
          window.localStorage.getItem("loginData") || "{}"
        );

        this.setState({
          hasCreatePermissionList: next_stage_create_permission_role,
          hasViewPermissionList: next_stage_view_permission_role,
          hasUpdatePermissionList: next_stage_update_permission_role,
          loggedInRole: role,
        });
      } else {
       this.setStageFourApiErrorMessage(responseJson)
      }
    }
  }

  handleTrackApi(message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.trackApi != null &&
      this.trackApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        if (this.state.handleDarftBtn != enumBtnType.REJECT) {
          this.props.onNext(4);
          const stageFourAlertMessage = this.getStageFourAlertMessaeg(false)
          this.setState({ showNoteDialog: false, expandedAccordion: false, showAlert: true, alertMessage: stageFourAlertMessage, alertType: "success" });
        }
        if (this.state.handleDarftBtn == enumBtnType.REJECT) {
          const stepname = this.getStepAccordianName(this.state.stepNumber)
          this.setState({
            expandedAccordion: false
          })
          setTimeout(() => {
            this.props.onReject(this.state.stepNumber);
          }, 900)

        }
        this.setState({ handleDarftBtn: "" });
      } else {
        this.setStageFourApiErrorMessage(responseJson)
      }
    }
  }

  handleQuotationData(message: Message, quotationApiID: any) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      quotationApiID != null &&
      quotationApiID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        window.localStorage.setItem(
          "stageFourData",
          JSON.stringify(responseJson.data)
        );
        this.props.setStageData("stageFourData", responseJson.data);


        this.setState({
          showNoteDialog: true
        })

      } else {
        this.setStageFourApiErrorMessage(responseJson)
      }
    }

  }

  handleDraftRequest(message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.draftQuotationApiCall != null &&
      this.draftQuotationApiCall ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        const queryParameter: any = new URLSearchParams(window.location.search);
        const id: string = queryParameter?.get("id");
        this.getStageThreeDataByIdApiCall(id)
        window.localStorage.setItem(
          "stageFourData",
          JSON.stringify(responseJson.data)
        );
        const alertMessage = this.getStageFourAlertMessaeg(true)
        this.setState({
          showAlert: true,
          alertType: "success",
          alertMessage: alertMessage,
          showNoteDialog: false
        });
      } else {
       this.setStageFourApiErrorMessage(responseJson)
      }
    }
  }

  handleModifyDraftRequest(message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.modifyDraftApiCall != null &&
      this.modifyDraftApiCall ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        window.localStorage.setItem(
          "stageFourData",
          JSON.stringify(responseJson.data)
        );
        const alertMessage = this.getStageFourAlertMessaeg(true)
        this.setState({
          showAlert: true,
          alertType: "success",
          alertMessage: alertMessage
        });
        return;
      } else {
        this.setStageFourApiErrorMessage(responseJson)
      }
    }
  }

  handleFileUploadRequest(message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.uploadFileApiCallForStageFour != null &&
      this.uploadFileApiCallForStageFour ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        /*istanbul ignore next*/this.formRef.current && this.formRef.current.setFieldValue(this.fieldName, responseJson.data.uploaded_file)
        const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
        this.setState({
          currentFileUrl: responseJson.data.uploaded_file || "",
          showAlert: true,
          alertType: "success",
          alertMessage: isArabic ? "تم تحميل الملف بنجاح" : "File is uploaded successfully",
        });
      } else {
        this.setStageFourApiErrorMessage(responseJson)
      }
    }
  }

  async receive(from: string, message: Message) {
    this.removeStageFourFileAPIReceive(message)
    this.getQuotationstageThreeData(message)
    this.getQuotationData(message);
    this.fetchStageThreeRecords(message);
    this.handleQuotationData(message, this.addQuotationApi)
    this.handleQuotationData(message, this.EditQuotationApi)
    this.handleTrackApi(message);
    this.handleDraftRequest(message);
    this.handleModifyDraftRequest(message);
    this.handleFileUploadRequest(message);
  }
}
// Customizable Area End
