// Customizable Area Start
import React, { ChangeEvent, useEffect } from "react";
import {
    Container,
    Grid,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    OutlinedInput,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Paper
} from "@material-ui/core";
import { ExpandMore, MoreVert } from '@material-ui/icons';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Close, CheckCircle, Error } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { CUSTOMER_CONTACTS_COLUMN } from "../Common/Table/TableColumns";
import AccordianTable from "../Common/Table/AccordionTable"
import { Link as Redirect } from 'react-router-dom';
import StaticMultiSelect from "../Common/StaticMultiSelect";
import LabelWithIcon from "../Common/LabelWithIcon";
import PaginatedTable from "../Common/Table/PaginatedTable";
import { CUSTOMER_ONGOING_POLICY_HEADER, CUSTOMER_EXISTING_POLICY_HEADER } from "../Common/Table/TableColumns";


const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

enum POLICY_ROUTE {
    ONGOING = "/PolicyRequest",
    EXISTING = "/PolicyOverview",
  }

export interface CustomersWebComponentProps {
    className?: string
    link?: string | null
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
    currentPage: number;
    totalPage:number;
    pageChange: (event: ChangeEvent<unknown>, page: number) => void;
    handleRowPerPage: (
        event:React.ChangeEvent<HTMLSelectElement>
    ) => void;
    customerDetails:any;
    customerContacts:any;
    fetchContactPerson?: any
    fetchExistingPolicy?: any
    fetchOnGoingPolicy?: any
    updateUserStatus?: any
    contactPersonDataTable?: any
    existingPolicyDataTable?: any
    existingPolicyDataTableMeta?: any
    onGoingPolicyDataTable?: any
    onGoingPolicyDataTableMeta?: any
    navigateToRevisionRequest?: any;
}
interface SelectedCustomerProps {
    id: number;
    name: string;
    email: string;
    phoneNumber?: string;
    designation: string;
    status: string;
}
interface ExistingPolicyProps {
    pNo: number;
    insurer: string;
    policyType: string;
    activationDate: string;
    expiryDate: string;
    issuedBy: string;
}
interface OnGoingPolicyProps {
    requestID: number;
    requestType: string;
    insuranceType: string;
    assignees: string;
    lastUpdateOn: string;
    status: string;
}

const CustomersWebComponent: React.FC<CustomersWebComponentProps> = (props) => {
    const [modal, setModal] = React.useState(false);
    const [ongoingPolicyPage, setOngoingPolicyPage] = React.useState<{page: number, perPage: number}>({
        page: 1, perPage: 5
    })
    const [existingPolicyPage, setExistingPolicyPage] = React.useState<{page: number, perPage: number}>({
        page: 1, perPage: 5
    })
    const [alertMessage, setAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState("");
    const [showAlertMessage, setShowAlertMessage] = React.useState(false);
    const [expandedAccordion,setExpandedAccordion] = React.useState("p1");
    const handleAccordion = (accordionPanel:string) => (event:any,isExpanded:boolean) => {
        setExpandedAccordion(isExpanded ? accordionPanel : "");
    }

    const queryParameter: any = new URLSearchParams(window.location.search);
    const userId: string = queryParameter?.get('id');

    const classes = useStyles();
    const { t } = useTranslation();
    const getTooltipText = (status:any) => {
        if(status === 'new'){
            return  t('default_state')
        }
        if(status ==="open"){
            return t('one_or_more_requests_in_process')
        }
        if(status==="partial"){
            return t('minimum_of_one_won_requests_and_one_or_more_lost')
        }
        if(status==="won"){
            return t('minimum_of_1_won_requests_no_lost_requests')
        }
        if(status==="lost"){
            return t('only_lost_requests')
        }
    }

    const sagmentOption = [
        { value: 'Mega KA', label: t('mega_ka') },
        { value: 'Corporate', label: t('corporate') },
        { value: 'SME', label: t('sme') },
        { value: 'Government', label: t('government') },
    ]

    React.useEffect(() => {
        props.fetchExistingPolicy(userId, existingPolicyPage.page, existingPolicyPage.perPage);
    }, [existingPolicyPage.page, existingPolicyPage.perPage]);

    React.useEffect(() => {
        props.fetchOnGoingPolicy(userId, ongoingPolicyPage.page, ongoingPolicyPage.perPage)
    }, [ongoingPolicyPage.page, ongoingPolicyPage.perPage]);

    React.useEffect(() => {
        setTimeout(() => {
            setShowAlertMessage(false);
            setAlertType("");
            setAlertMessage("")
            localStorage.removeItem('alertDetail')
        }, 10000)
    }, [alertMessage])

    useEffect(()=>{
        const checkAlertDetails = async () => {
        const alertData = await JSON.parse(localStorage.getItem("alertDetail") || "{}");
        const currentPage = window.location.href.split('/').pop();
            if (Object.keys(alertData).length !== 0 && ((Object.keys(alertData).length !== 0) && (currentPage === alertData.page) || (currentPage?.includes('CustomersDetail')))) {
                setShowAlertMessage(true);
                setAlertType(alertData.alertType);
                setAlertMessage(alertData.alertMessage || "successfully modified")
                localStorage.removeItem('alertDetail')
            }
        }
        checkAlertDetails();
    },[])


    const handleModal = (value: boolean) => {
        setModal(value);
    }

    const createNewPolicy = () => {
        window.localStorage.removeItem('stageOne');
        window.localStorage.removeItem('stageOnePartTwoData');
        window.localStorage.removeItem('stageTwoData');
        window.localStorage.removeItem('stageThreeData');
        window.localStorage.removeItem('stageFourData');
        window.localStorage.removeItem('stageFiveData');
        window.localStorage.removeItem('stageSixData');
        window.localStorage.removeItem('stageEightData');
        window.location.href = "/PolicyRequest"
    }

      const {customerDetails,customerContacts} = props;

    return (
            <ThemeProvider theme={theme}>
                <div className={classes.mainDiv}>
                    <Container maxWidth="xl">
                        {showAlertMessage && (
                            <div data-test-id="alertDiv" className={classes.alertDiv} >
                                <Alert className={alertType === "error" ? classes.redAlertMessage : classes.greenAlertMessage} icon={alertType === "success" ? <CheckCircle className={classes.alertIconStyle} /> : <Error className={classes.alertIconStyle} />} severity={alertType === "success" ? "success" : "error"}>
                                    <div className={classes.displayFlex} >
                                        {alertMessage}
                                        <Close className={classes.alertCloseIcon} onClick={() => setShowAlertMessage(false)} style={{ paddingLeft: "10px" }} />
                                    </div>
                                </Alert>
                            </div>
                        )}
                        <Grid className={classes.customerHeader} container spacing={2}>
                            <Grid item xs={6}>  <h3 className={classes.displayFlex} >{customerDetails.name}</h3></Grid>
                            <Grid item xs={6}>
                                <div className={classes.headerButtonStyle} >
                                  <Button
                                         className={classes.viewDetailBtn}
                                        variant="outlined"
                                        data-test-id="btnForViewUserDetails"
                                        id="btnForViewUserDetails"
                                    >
                                    <Redirect to={`/ViewCustomer?id=${userId}`} className={classes.viewDetial} >
                                        {t('view_details')}
                                    </Redirect>
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={customerDetails.is_archived}
                                        className={classes.btnStyle}
                                        data-test-id="btnForNewPolicy"
                                        id="btnForNewPolicy"
                                        onClick={()=> createNewPolicy()}
                                    >
                                        {t('new_policy_request')}
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid>
                            <Grid item>
                                <Accordion  data-test-id='customerDetailsAccordion' expanded={expandedAccordion === "p1"} onChange={handleAccordion('p1')}>
                                    <AccordionSummary
                                        aria-expanded={true}
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.accordianHeading} >{t('overview')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={3} >
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('customer_name')}  />
                                                <OutlinedInput
                                                    className={classes.textInputStyle}
                                                    fullWidth
                                                    name="customerName"
                                                    data-test-id="txtInputCutomerName"
                                                    id={"txtInputCutomerName"}
                                                    disabled
                                                    value={customerDetails.name}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('status')} tooltip={getTooltipText(customerDetails.statusId)} />
                                                <OutlinedInput
                                                    className={classes.textInputStyle}
                                                    fullWidth
                                                    name="statusID"
                                                    data-test-id="txtInputStatusId"
                                                    id={"txtInputStatusId"}
                                                    disabled
                                                    value={customerDetails.statusId}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                {/* @ts-ignore */}
                                                <LabelWithIcon label={t('customer_id')} tooltip={t('this_is_an_internal_ID_that_is_generated_once_you_save_this_form')} />
                                                <OutlinedInput
                                                    className={classes.textInputStyle}
                                                    fullWidth
                                                    name="customerId"
                                                    data-test-id="txtInputCustomerId"
                                                    id={"txtInputCustomerId"}
                                                    disabled
                                                    value={customerDetails.customerId}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <LabelWithIcon label={t('industry')}  />
                                                <OutlinedInput
                                                    className={classes.textInputStyle}
                                                    fullWidth
                                                    disabled
                                                    name="industry"
                                                    data-test-id="txtInputIndustry"
                                                    id={"txtInputIndustry"}
                                                    value={customerDetails.industry}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                            <LabelWithIcon label={t('segment')}  />
                                             <StaticMultiSelect
                                                value={customerDetails?.segment}
                                                isMulti={true}
                                                name='segment'
                                                disabled={true}
                                                options={sagmentOption}
                                             />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid className={classes.table} item>
                                <Accordion >
                                <AccordionSummary
                                        aria-expanded={true}
                                        expandIcon={<ExpandMore />}
                                        aria-controls="contactTable"
                                        id="contactTable"
                                    >
                                        <Typography className={classes.accordianHeading}>{t("contacts")}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails >
                                     <AccordianTable
                                      currentPage={props.currentPage}
                                      totalPage={props.totalPage}
                                      pageChange={props.pageChange}
                                      handleRowPerPage={props.handleRowPerPage}
                                      tableData={customerContacts}
                                      tableColumn={CUSTOMER_CONTACTS_COLUMN}
                                      showMenuPopover
                                     />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid className={classes.table} item>
                                <Accordion  expanded={expandedAccordion === "p3"} onChange={handleAccordion('p3')} data-test-id='existingAccordion'>
                                    <AccordionSummary
                                        aria-expanded={true}
                                        expandIcon={<ExpandMore />}
                                        aria-controls="existingPoliciesTable"
                                        id="existingPoliciesTable"
                                    >
                                        <Typography className={classes.accordianHeading}>{t('existing_policies')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.coverOverflow}>
                                        <AccordianTable
                                            currentPage={ongoingPolicyPage.page}
                                            totalPage={props.existingPolicyDataTableMeta?.total_page}
                                            pageChange={(e: React.ChangeEvent<unknown>, page: number) => setExistingPolicyPage((prev) => ({...prev, page}))}
                                            handleRowPerPage={(e: React.ChangeEvent<HTMLSelectElement>) => setExistingPolicyPage({page: 1, perPage: Number(e.target.value)})}
                                            tableFor={'existing_policy'}
                                            tableData={props.existingPolicyDataTable}
                                            tableColumn={CUSTOMER_EXISTING_POLICY_HEADER}
                                            path={POLICY_ROUTE.EXISTING}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>

                            <Grid className={classes.table} item>
                                <Accordion  expanded={expandedAccordion === "p4"} onChange={handleAccordion('p4')}  data-test-id='onGoingAccordion' >
                                    <AccordionSummary
                                        aria-expanded={true}
                                        expandIcon={<ExpandMore />}
                                        aria-controls="ongoingRequestTable"
                                        id="ongoingRequestTable"
                                    >
                                        <Typography className={classes.accordianHeading}>{t('ongoing_requests')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.coverOverflow}>
                                        <AccordianTable
                                            currentPage={ongoingPolicyPage.page}
                                            totalPage={props.onGoingPolicyDataTableMeta?.total_page}
                                            pageChange={(e: React.ChangeEvent<unknown>, page: number) => setOngoingPolicyPage((prev) => ({...prev, page}))}
                                            handleRowPerPage={(e: React.ChangeEvent<HTMLSelectElement>) => setOngoingPolicyPage({page: 1, perPage: Number(e.target.value)})}
                                            tableFor={'ongoing_policy_request'}
                                            navigateToRevisionRequest={props.navigateToRevisionRequest}
                                            tableData={props.onGoingPolicyDataTable}
                                            tableColumn={CUSTOMER_ONGOING_POLICY_HEADER}
                                            path={POLICY_ROUTE.ONGOING}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </ThemeProvider>
    )
}
const useStyles = makeStyles(theme =>
    createStyles({
        mainDiv: {
            background: "#e9f4f9",
            padding: "20px"
        },
        tableGreenButton: { background: "#bfe3d7", color: "green", width: "100%", textAlign: "center", borderRadius: "7px", fontSize: "14px", padding: "5px" },
        tableGrayButton: { background: "#e0e0e0", color: "gray", width: "100%", textAlign: "center", borderRadius: "7px", fontSize: "14px", padding: "5px" },
        tableYellowButton: { background: "#fcf1d4", color: "#f2c94c", width: "100%", textAlign: "center", borderRadius: "7px", fontSize: "14px", padding: "5px" },
        customerHeader:{
            paddingTop:"10px",
            paddingBottom:"10px"
        },
        accordianHeading: {
            fontWeight: 600
        },
        tablePaginationText: {
            textSize: "18px"
        },
        multiSelectLabel:{
            'margin-top': "10px"
        },
        redAlertMessage: {
            backgroundColor: "#ec5655",
            color: "white"
        },
        greenAlertMessage: {
            backgroundColor: "#028f5d",
            color: "white"
        },
        alertCloseIcon: {
            color: "white",
            cursor: "pointer"
        },
        alertDiv: {
            "display": "flex",
            "justify-content": "end",
            "padding-bottom": "15px",
            "padding-top": "15px"
        },
        alertIconStyle: {
            color: "white"
        },
        table: {
            paddingTop: "25px"
        },
        dialoagButtonStyle: {
            display: "flex",
            justifyContent: "space-evenly"
        },
        btnStyle: {
            background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
            "text-transform":"none",
            "color": "white",
            "height": "50px",
            borderRadius: "10px",
            paddingLeft: "10px",
            paddingRight: "10px"
        },
        redirectStyle:{
            "color": "white",
            "textDecoration":"none"
        },
        displayFlex: {
            display: "flex",
        },
        headerButtonStyle: {
            display: "flex",
            justifyContent: "end"
        },
        textInputStyle: {
            marginTop: "10px",
            marginBottom: "10px"
        },
        tableHeader: {
            background: "#e9f4f9",
            "font-size": "1rem",
        },
        tableMenuButtonStyle: {
            padding: "10px"
        },
        createAccountDialogButtonStyle: {
            display: "felx",
            justifyContent: "center"
        },
        viewDetailBtn:{
         marginRight: "10px",
         marginLeft:"10px" ,
         background: "white",
         borderRadius: "10px"
        },
        viewDetial:{
          textDecoration:"none",
          "text-transform":"none",
          color:"black"
        },
        coverOverflow: {
            overflow: 'auto',
        }
    }),
)


export default CustomersWebComponent;
// Customizable Area End