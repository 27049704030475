// Customizable Area Start
import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CustomerListingController, { Props } from "./CutomerListingController";
import PaginatedTable from "../../../components/src/Common/Table/PaginatedTable";
import Toggle from "../../../components/src/Common/Toggle/Toggle";
import SearchBar from "../../../components/src/Common/SearchBar/SearchBar";
import { CUSTOMER_LIST_TABLE_BODY } from "../../../components/src/Common/Table/TableColumns";
import { Alert } from '@material-ui/lab';
import { Close, CheckCircle, Error } from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import DropdownButton from "../../../components/src/Common/Button/DropdownButton";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
  mainContainer: {
    width: "97%",
    marginLeft: "0",
    position: "relative",
  },
  SearchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.7rem 8px",
  },
  displayFlex:{
    display:'flex'
  },
  togleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 8px 8px 0px",
  },
  insurerTableContainer: {
    padding: "24px",
    backgroundColor: "white",
    borderRadius: "7px",
  },
  archiveCustomer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  addInsurereBtn: {
    "&": {
      color: "#fff",
      fontWeight: "600",
      padding: "8px 15px",
      "text-transform": "none",
    },
  },
  insurerHeading: {
    fontSize: "large",
    fontWeight: 800,
  },
  alertType: {
    display: "flex",
    justifyContent: "end"
  },
  redAlertMessage: {
    backgroundColor: "#ec5655",
    color: "white",
    width: 'auto',
    height: "55px"
  },
  greenAlertMessage: {
    backgroundColor: "#028f5d",
    color: "white",
    width: 'auto',
    height: "55px"
  },
  alertCloseIconLeft: {
    color: "white",
    justifyContent: "end",
    cursor: "pointer",
    marginLeft: "20px"
  },
  alertCloseIconRight: {
    color: "white",
    justifyContent: "end",
    cursor: "pointer",
    marginRight: "20px"
  },
  alertIconStyle: {
    color: "white"
  },
  alertDivWithSearch: {
    display: "flex",
    "justify-content": "space-between",
  },
  alertDiv: {
    "display": "flex",
    "justify-content": "end",
    "padding-bottom": "15px",
  },
  addCustomerBtn:{
    background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    "text-transform":"none",
    "color": "white",
    "width": "200px",
    "height": "50px",
    borderRadius: "10px"
  }

};
enum CUSTOMER_ROUTE_AND_TEXT {
  CUSTOMER = "customer",
  EDITROUTE = "/CustomersDetail",
  ADDROUTE = "CustomersProfileAdd",
  UPLOADCSVROUTE = "CustomerCsvImport",
  ADD_COSTOMER = "Add Customer",
}

const CUSTOMER_DROPDOWN_LIST = [
  {
    label: "web form",
    value: "web_form",
    navigate:true
  },
  {
    label: "upload csv",
    value: "upload_csv",
    navigate:true
  },
];

export class CustomerListing extends CustomerListingController {
  constructor(props: Props) {
    super(props);
  }

  
  
  render() {

    const { page, totalPage, customerList, isArchived } = this.state;
    //@ts-ignore
    const { t } = this.props;
    const showAddButton = this.showAddCustomerBtn();
    const moveToSelectedRoute = (btn: string) => {
      this.props.navigation.navigate(btn === 'upload_csv' ? CUSTOMER_ROUTE_AND_TEXT.UPLOADCSVROUTE : CUSTOMER_ROUTE_AND_TEXT.ADDROUTE)
    }
    
    return (
      <>
        <ThemeProvider theme={theme}>
          <Box
            dir={
              (window.localStorage.getItem("lang") || "en") === "ar"
                ? "rtl"
                : "ltr"
            }
            sx={webStyle.mainContainer}
          >
            {this.state.showAlert && (
              <div data-test-id="alertDiv" style={webStyle.alertDiv} >
                <Alert style={this.state.alertType === "error" ? webStyle.redAlertMessage : webStyle.greenAlertMessage} icon={this.state.alertType === "success" ? <CheckCircle style={webStyle.alertIconStyle} /> : <Error style={webStyle.alertIconStyle} />} severity={this.state.alertType === "success" ? "success" : "error"}>
                  <div style={webStyle.displayFlex} >
                    {this.state.alertMessage}
                    <Close data-test-id="closeIconBtn" style={this.state.isArabicLenguage ? webStyle.alertCloseIconRight : webStyle.alertCloseIconLeft} onClick={() => this.closeAlertMessage()} />
                  </div>
                </Alert>
              </div>
            )}
          <Box style={webStyle.alertDivWithSearch}>
            <SearchBar
              data-test-id="customer-filter-searchbar"
              searchQuery={this.handleSearchCustomerQuery}
            />
            {
              showAddButton && 
              <DropdownButton  
                buttonText={t('add_customer')}                     
                moveToSelected={moveToSelectedRoute}  
                route={CUSTOMER_ROUTE_AND_TEXT.ADDROUTE} 
                dropDownList={CUSTOMER_DROPDOWN_LIST} 
              />
            }
          </Box>
            <Box sx={webStyle.insurerTableContainer}>
              <Box style={webStyle.togleContainer}>
                <Typography variant="h6" style={{ fontWeight: 800 }}>
                  {isArchived ? t("archived_customers") : t("customers")}
                </Typography>
                <Box style={webStyle.archiveCustomer}>
                  <Typography>{t('archived')}</Typography>
                  <Toggle
                    data-test-id="archive-customer"
                    isChecked={isArchived}
                    handleToggle={this.handleArchiveCustomerTable}
                  />
                </Box>
              </Box>
              <PaginatedTable
                curretPage={page}
                totalPage={totalPage}
                pageChange={this.handleChangePage}
                handleRowPerPage={this.handleRowPerPage}
                tableFor={CUSTOMER_ROUTE_AND_TEXT.CUSTOMER}
                archiveList={this.archiveCustomer}
                tableData={customerList}
                tableColumn={CUSTOMER_LIST_TABLE_BODY}
                isArchived={isArchived}
                path={CUSTOMER_ROUTE_AND_TEXT.EDITROUTE}
              />
            </Box>
          </Box>
        </ThemeProvider>
      </>
    );
  }
}

export default withTranslation()(CustomerListing);
// Customizable Area End
