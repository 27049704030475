// Customizable Area Start
import React from "react";
import { Box, Button, Tab, Tabs } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import RequestListingController, { Props } from "./RequestListingController";
import PaginatedTable from "../../../../components/src/Common/Table/PaginatedTable";
import SearchBar from "../../../../components/src/Common/SearchBar/SearchBar";
import { REQUEST_LIST_TABLE_BODY } from "../../../../components/src/Common/Table/TableColumns";
import { withTranslation } from "react-i18next";
import FilterAltSharpIcon from "@material-ui/icons/FilterList";
import RequestFilterDialog from "../../../../components/src/Common/RequestFilterDialog.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
  insurerTable: {
    padding: "24px",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  searchBox: {
    display: "flex",
    marginBottom: '30px',
    gap: '20px',
  },
  searchBoxTop: {
    display: "flex",
    marginBottom: '10px',
    marginTop: '15px',
    gap: '20px',
    "justify-content": "space-between",
  },
  filterBtnText: {
    background: "#fff",
    color: "rgb(64, 109, 127)",
    minWidth: "155px",
    height: "53px",
    border: 'solid 1px rgb(64, 109, 127)',
    boxShadow: 'none',
    padding: '8px 15px',
    fontWeight: 600,
    'text-transform': 'none'
  },
  filterTab: {
    background: "#fff",
    minWidth: "155px",
    height: "51px",
    borderStyle: "none",
    borderRadius: "25px",
    border: 'solid 1px rgba(0, 0, 0, 0.3)',
    boxShadow: 'none',
    marginRight: '20px',
    color: "rgba(0, 0, 0, 0.3)",
    fontWeight: 400,
    padding: "8px 15px",
    "text-transform": "none",
  },
  activeTab: {
    background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    minWidth: "155px",
    height: "51px",
    borderStyle: "none",
    borderRadius: "25px",
    borderColor: '#2d6f8f',
    boxShadow: 'none',
    marginRight: '20px',
    color: "#FFFF",
    fontWeight: 400,
    padding: "8px 15px",
    "text-transform": "none",
  }
};

const ButtonNames = ['all', 'priority', 'basket', 'in_progress', 'completed'];

export class RequestListing extends RequestListingController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    const { page, pageCount, requestList, activeFilterTab } = this.state;
    //@ts-ignore
    const { t } = this.props;

    return (
      <>
        <ThemeProvider theme={theme}>
          <div
            style={{
              width: "97%",
              marginLeft: "0",
              position: "relative"
            }}
          >
            <div style={webStyle.searchBoxTop}>
              <SearchBar
                data-test-id="customer-filter-searchbar"
                searchQuery={this.handleSearchCustomerQuery}
              />
              <div>
                <Button
                  data-test-id="filterBtn"
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  style={webStyle.filterBtnText}
                  onClick={this.openFilterDialog}
                >{t("filter")} <FilterAltSharpIcon /></Button>
              </div>
              <RequestFilterDialog
                  data-test-id="filterDialog"
                  btnCancelText={t('cancel')}
                  btnfilterText={t('filter')}
                  handleFilter={this.handleFilterDialog}
                  handleCancel={this.closefilterDialog}
                  showfilterDialog={this.state.showfilterDialog}
                  headingText={t('filter')}
                  openDialog={this.state.showfilterDialog}
                  showDialogBtns={true}
                  showCrossBtn={true}
                  customerList={this.state.filterCustomerList}
                  insurerList={this.state.filterUserList}
                />
            </div>
            <Box sx={webStyle.insurerTable}>
            <div style={webStyle.searchBox}>
              <p>{t("sort_by")}</p>
              <Tabs
                value={activeFilterTab}
                onChange={this.handleTabChange}
                TabIndicatorProps={{
                  style: { display: 'none' }
                }}
              >
                {
                  ButtonNames.map((name, index) => <Tab key={index} style={activeFilterTab === index ? webStyle.activeTab : webStyle.filterTab} label={t(name)} />)
                }
              </Tabs>
            </div>
            {
              ButtonNames.map((name, index) => <div
                key={index}
                role="tabpanel"
                id={`scrollable-force-tabpanel-${index}`}
                aria-labelledby={`scrollable-force-tab-${index}`}
              >
                <div key={index} role="tabpanel" id={`scrollable-force-tabpanel-${index}`} aria-labelledby={`scrollable-force-tab-${index}`}>
                {activeFilterTab === index && (
                  // @ts-ignore
                  <PaginatedTable
                    key={index}
                    curretPage={page}
                    totalPage={pageCount}
                    pageChange={this.handleChangePage}
                    handleRowPerPage={this.handleRowPerPage}
                    navigateToRevisionRequest={this.handleNavigateToRevisionRequest}
                    tableFor={'policy'}
                    tableData={requestList}
                    tableColumn={REQUEST_LIST_TABLE_BODY}
                    path={'/ViewPolicy'}
                  />
                )}
              </div>
              </div>
              )
            }
            </Box>
          </div>
        </ThemeProvider >
      </>
    );
  }
}

export default withTranslation()(RequestListing);
// Customizable Area End
