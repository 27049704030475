import React from 'react'
import Button from '@material-ui/core/Button';
import { CircularProgress, makeStyles } from '@material-ui/core';



const useStyles = makeStyles({
    btnStyle: {
        padding: '12px 24px',
        borderRadius: '9px',
        border: '1.5px solid #0090a1',
        textTransform: 'none',
        minWidth: '150px',
        fontWeight:"bold",
        fontSize:"14px",
    }
})

type CustomButtonProps = {
    btnText: string,
    onClick: () => void,
    bgColor?: string,
    textColor?: string,
    loading?:boolean,
    testId?:string,
    disabled?:boolean
}

const CustomButton = (props: CustomButtonProps) => {
    const classes = useStyles();

    const getStyle = () => {
        if (props.disabled) {
            return { background: "gray", color: "white", border:"gray" }
        } else {
            return { background: props.bgColor ? props.bgColor : '#fff', color: props.textColor}
        }
    }

    return (
        <div>
            <Button disabled={props.disabled ? true : false} data-test-id={props.testId || ""} variant="contained" color="primary" onClick={props.onClick} className={classes.btnStyle} style={getStyle()}>
                {props.loading ? <CircularProgress size={25} /> : props.btnText}
            </Button>
        </div>
    )
}

export default CustomButton