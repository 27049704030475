Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start

exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.buttonTextContinue = "Continue";
exports.ArabicButtonTextContinue = "إستمرار";
exports.resendEmailText = "Resend Email";
exports.ArabicResendEmailText = "أعد إرسال البريد الإلكتروني";
exports.backToLoginText = "Back to login";
exports.ArabicBackToLoginText = "أعد إرسال البريد الإلكتروني";
exports.ArabicTextEmail = "البريد الإلكتروني";
exports.labelTextIsPasswordRecovery = "Password Recovery";
exports.ArabicLabelTextIsPasswordRecovery = "إسترجاع كلمة السر"
exports.labelTextForForgotPassword = "Forgot your password?";
exports.ArabicLabelTextForForgotPassword = "هل نسيت كلمة السر؟"
exports.subTitle = "No worries, we'll send a recovery link to your email"
exports.confirmPasswordNotMatch = "Confirm new password doesn't match"
exports.ArabcConfirmPasswordNotMatch = "تأكيد كلمة المرور الجديدة غير متطابق"
exports.ArabicInvalidPassword="رمز مرور خاطئ";
exports.ArabicPleaseEnterAPassword = "الرجاء إدخال كلمة المرور";
exports.ArabicPleaseConfirmYourPassword="يرجى التأكد من صحة كلمة المرور الخاصة بك";
exports.ArabicPsswordsMustMatch="يجب أن تتطابق كلمات المرور";
exports.arabicSubTitle = "أدخل عنوان البريد الإلكتروني الذي تريد منا استخدامه لإرسال رابط إعادة تعيين كلمة المرور."
exports.checkMailText = "Check Mail"
exports.ArabicCheckMailText = "تفقد البريد الإلكتروني"
exports.passwordRecovertSubTitle = "We have sent a password reset link to your email address."
exports.ArabicPasswordRecovertSubTitle = "لقد ارسلنا رابط لتغيير كلمة السر الى بريدك الإلكترونيلقد ارسلنا رابط لتغيير كلمة السر الى بريدك الإلكتروني"
exports.setNewPasswordText = "Set your new password";
exports.emailTextBoxLabel = "Email"
exports.ArabicPleaseEnteraValidEmail="يرجى إدخال البريد الإلكتروني الصحيح";
exports.ArabicEmailIsRequire = "يتطلب البريد الإلكتروني"
exports.passwordMustBeAtLeast8Characters = "Password must be at least 8 characters";
exports.passwordMustContainLowercaseCharacter = "Password must contain lowercase character";
exports.passwordMustContainUppercaseCharacter = "Password must contain uppercase charcater";
exports.passwordMustContainAtLeastOneNumber = "Password must contain at least one number";
exports.ArabicPasswordMustContainUppercaseCharacter ="أن تحتوي كلمة السر على حرف كبيرعلى الأقل ";
exports.ArabicPasswordMustContainLowercaseCharacter ="أن تحتوي كلمة السر على حرف صغير على الأقل";
exports.ArabicPasswordMustContainAtLeastOneNumber ="أن تحتوي كلمة السر على رقم على الأقل";
exports.ArabicPasswordMustBeAtLeast8Characters ="أن تحتوي كلمة السر على ثمانية رموز على الأقل";
exports.emailErrorMsg = "No account registered with this email"
exports.ArabicEmailErrorMsg = "لا يوجد حساب مسجل بهذا البريد الإلكتروني"
exports.emailIsTooShort = "Email is too short"
exports.ArabicEmailIsTooShort = "البريد الإلكتروني قصير جدًا"
exports.emailIsTooLong = "Email is too long"
exports.ArabicEmailIsTooLong = "البريد الإلكتروني طويل جدًا"
// Customizable Area End