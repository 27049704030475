import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@material-ui/core/styles'
// import { SvgIconProps } from '@material-ui/core/SvgIcon'

import List from '@material-ui/core/List'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'

import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import { useTranslation } from 'react-i18next'

import AppMenuItemComponent from './AppMenuItemComponent'

// React runtime PropTypes
export const AppMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  // Icon: PropTypes.elementType,
  Icon: PropTypes.string,
  items: PropTypes.array,
}

// TypeScript compile-time props type, infered from propTypes
// https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
type AppMenuItemPropTypes = PropTypes.InferProps<typeof AppMenuItemPropTypes>
type AppMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, 'items'>

// Improve child items declaration
export type AppMenuItemProps = AppMenuItemPropsWithoutItems & {
  items?: AppMenuItemProps[]
}

const AppMenuItem: React.FC<AppMenuItemProps> = props => {
  const { t } = useTranslation();
  const { name, link, Icon, items = [] } = props
  const classes = useStyles()
  const isExpandable = items && items.length > 0
  const [open, setOpen] = React.useState(false)

  function handleClick() {
    setOpen(!open)
  }

  const MenuItemRoot = (
    <AppMenuItemComponent className={classes.menuItem} link={link} onClick={handleClick}>
      {/* Display an icon if any */}
      {!!Icon && (
        <ListItemIcon className={classes.menuItemIcon}>
          {/* <Icon /> */}
          <div className='default-icon'>
            <img src={`${Icon}.png`} alt="" />
          </div>
          <div className='active-icon'>
            <img src={`${Icon}-active.png`} alt="" />
          </div>
        </ListItemIcon>
      )}
      <ListItemText primary={t(name)} inset={!Icon} />
      {/* Display the expand menu if the item has children */}
      {isExpandable && !open && <IconExpandMore />}
      {isExpandable && open && <IconExpandLess />}
    </AppMenuItemComponent>
  )

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {items.map((item, index) => (
          <AppMenuItem {...item} key={index} />
        ))}
      </List>
    </Collapse>
  ) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}

const useStyles = makeStyles(theme =>
  createStyles({
    menuItem: {
      borderRadius: '8px',
      '& .MuiTypography-root.MuiListItemText-primary': {
        fontSize: '12px',
        textTransform: 'capitalize',
      },
      '&:hover' : {
        background: 'rgb(255 255 255 / 30%)',
        '& .MuiListItemIcon-root': {
          color: '#fff',
        },
      },
      '& .active-icon':{
        display: 'none'
      },
      '&.active': {
        // background: 'rgba(0, 0, 0, 0.08)',
        '& svg': {
          // color: 'linear-gradient(to bottom, #2d6f8f, #4fb7ba);',
          backgroundImage: 'linear-gradient(to bottom, #2d6f8f, #4fb7ba)',
          // background: '-webkit-linear-gradient(#2d6f8f, #4fb7ba)',
          "-webkit-background-clip": 'text',
          "-webkit-text-fill-color": 'transparent',
        },
        '& .MuiTypography-root.MuiListItemText-primary': {
          backgroundImage: 'linear-gradient(to bottom, #2d6f8f, #4fb7ba)',
          "-webkit-background-clip": 'text',
          "-webkit-text-fill-color": 'transparent',
        },
        '& .default-icon':{
          display: 'none'
        },
        '& .active-icon':{
          display: 'block'
        },
      },
    },
    menuItemIcon: {
      color: '#fff',
      minWidth: 'auto',
      paddingInlineEnd:'10px',
      '& img':{
        width: '15px',
      }
    },
  }),
)

export default AppMenuItem
