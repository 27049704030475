import React, {useState} from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, makeStyles, CircularProgress, OutlinedInput } from '@material-ui/core'
import TextInput from './TextInput';
import LabelWithIcon from './LabelWithIcon';
import { Close } from '@material-ui/icons';
import { t } from 'i18next';


const useStyles = makeStyles({
    dialogWrapper:{
        "& [role='dialog']":{
            padding: '30px',
            borderRadius: '16px',
            width: '800px'
        }
    },
    headingStyle: {
        textAlign: 'center',
        '& h2': {
            fontWeight: 700,
            
        },
        fontSize: "16px"
    },
    paraStyle:{
        textAlign: 'center',
    },
    btnWrapper:{
        justifyContent: 'end',
        '& button': {
            padding: '15px 0px',
            width: '150px',
            borderColor: '#3b8da2',
            borderRadius: '15px',
        }
    },
    primaryBtnStyle: {
      background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
      "text-transform": "none",
      "color": "white"
    },
    cancelBtnStyle: {
      background: "linear-gradient(to bottom, red, #C87674)",
      "text-transform": "none",
      "color": "white",
      borderRadius: "10px"
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    disableBtn: {
        color: "#fff",
        borderRadius: '8px',
        background: '#757575'
    },
    downloaIcon: {
      cursor: "pointer"
  },
})

type HandOverDialogProps = {
    openDialog:boolean,
    headingText: string,
    btnCancelText: string,
    btnOkText: string,
    handleOk: any;
    handleCancel:any;
    handleInputText?:any;
    btnLoading?:boolean;
    inputDisable?: boolean;
    inputValue?: string;
    testIDProps?: string;
    subHeading?: string;
    showDialogBtns?: boolean;
    textId?: string;
    showCrossBtn?: boolean
    inputLable?: string
}

const HandOverDialog = (props:HandOverDialogProps) => {
    const classes = useStyles();

    const [note,setNote] = useState("");

  return (
    <>
        
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title">
            <div className={classes.dialogTitle}>
              <div>
                <span className={classes.headingStyle}>{props?.headingText}</span>
                <span className={classes.subHeading}>{props.subHeading}</span>
              </div>
            {props.showCrossBtn ? (<Close
                    className={classes.downloaIcon}
                    onClick={props?.handleCancel}
                />) : <div></div>}
            </div>
        </DialogTitle>
        <DialogContent>
        <label>{props.inputLable || t('note')}</label>
          <OutlinedInput
            id={props.textId}
            style={{
                margin: "4px 0"
            }} 
            type="text"
            fullWidth
            minRows={4} 
            multiline={true} 
            name={props.textId} 
            value={props.inputValue}
            disabled={props.inputDisable}
            data-test-id={props.textId}
            onChange={(e:any) =>{
              setNote(e.target.value.replace(/^\s+|\s+$/gm,''));
              props.handleInputText(e.target.value)}
            } 
            
        />
        </DialogContent>
        {props.showDialogBtns && <DialogActions className={classes.btnWrapper}>
          <Button variant="outlined" style={{color: '##757575', borderRadius: "8px", textTransform:"none"}} onClick={props?.handleCancel}>
            {props.btnCancelText}
          </Button>
          <Button variant="contained" disabled={!props.inputValue} style={{borderRadius: "8px"}} className={!props.inputValue ? classes.disableBtn :classes.primaryBtnStyle} onClick={() => props?.handleOk(note)} color="primary" autoFocus>
            {props.btnLoading ? <CircularProgress size={25} /> : props.btnOkText}
          </Button>
        </DialogActions>}
      </Dialog>
    </>
  )
}

export default HandOverDialog