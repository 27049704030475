// Customizable Area Start
import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    TextField,
    Typography
} from "@material-ui/core";
import { ErrorOutlineRounded, ExpandMore } from '@material-ui/icons'
import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import StageThreeController, { Props } from "./StageThreeController";
import LabelWithIcon from "../../../../../../components/src/Common/LabelWithIcon";
import CustomButton from "../../../../../../components/src/Common/CustomButton";
import HandOverDialog from "../../../../../../components/src/Common/HandOverDialog.web";
import RejectRequestDialog from "../../../../../../components/src/Common/RejectRequestDialog.web";
import Toster from "../../../../../../components/src/Common/Toster";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';


const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});
// Customizable Area End

export class StageThreeForm extends StageThreeController {
    // Customizable Area Start
    formik3ref: any;
    constructor(props: Props) {
        super(props);
        this.formik3ref = React.createRef();
    }

    // Customizable Area End

    render() {

        // Customizable Area Start
        const {  t, classes, isExpanded, rrStageThreeData } = this.props;
        const pushBackOption = [
            { value: 'info_gathering', label: "Info Gathering" },
            { value: 'processing', label: "Processing" }
        ];
        // Customizable Area End

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme} >
                <React.Fragment>
                    <Toster
                        tosterText={this.state.alertMessage}
                        showToster={this.state.showAlert}
                        tosterType={this.state.alertType}
                        handleCloseToster={() => this.setState({ showAlert: false })}
                    />
                    <div className={classes.accordianGap}>
                        <Accordion
                            data-test-id='p3'
                            className={classes.accordianSection}
                            expanded={(this.state.expandedAccordion)}
                            disabled={this.state.disableAccordion}
                            onChange={() => {
                                this.handleAccordionStageThree()
                            }} >
                            <AccordionSummary
                                aria-expanded={true}
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={classes.topSection}>
                                    <Typography className={classes.accordionHeading} >{t('awaiting_revision')}</Typography>
                                   <div style={{display: 'flex'}}>
                                    {
                                        this.state.expandedAccordion &&
                                        (<div data-test-id="noteDialog" className={classes.descriptionIcon} onClick={() => this.openNoteDialogStageThree()}>
                                            <DescriptionOutlinedIcon />
                                        </div>)
                                    }
                                    {this.checkStageThree() &&
                                        <ErrorOutlineRounded style={{position: 'relative', left: '8px', color: '#0090a1'}} onClick={() => this.setState({showRejectDialog: true, isBtnVisible: false})} />
                                    }
                                   </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className={classes.awatingStage}>
                                    <Grid container className={classes.contactForm} >
                                        <Grid item xs={12}>
                                            <LabelWithIcon label={t("post_updates_on_submissions_here_if_any")} />
                                            <TextField
                                                id={"awaitingComment"}
                                                className={classes.textInputStyle}
                                                name="awaitingComment"
                                                fullWidth
                                                variant="outlined"
                                                type="text"
                                                onChange={(e) => this.setState({ awaitingComment: e.target.value })}
                                                value={this.state.awaitingComment}
                                                disabled={!this.props.isExpanded}
                                                multiline={true}
                                                minRows={4}
                                                data-test-id="rvAwaitingComment"
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        {(this.state.expandedAccordion && isExpanded) && (
                            <div className={classes.displayFlexEnd}>
                                <div className={classes.btnStyle}>

                                    <CustomButton
                                        data-test-id="rv_savedraft_btn"
                                        btnText={t('save_draft')}
                                        disabled={(this.state.awaitingComment === "") ? true : false}
                                        loading={this.state.saveDraftLoading}
                                        onClick={() => this.updateStageThreeData(true)}
                                        bgColor={'#fff'}
                                        textColor={'#007E98'}
                                    />
                                </div>
                                <div className={classes.btnStyle}>
                                    <CustomButton
                                        data-test-id="rv_reject_btnstageFour"
                                        btnText={t('reject')}
                                        onClick={() => this.openRejectDialogStageThree()}
                                        bgColor={'#fff'}
                                        textColor={'#007E98'}
                                    />
                                </div>
                                <div>
                                    <CustomButton
                                        data-test-id="rv_nextBtn_btn"
                                        btnText={t('next_stage')}
                                        onClick={() => this.updateStageThreeData(false)}
                                        bgColor={"linear-gradient(to bottom, #2d6f8f, #4fb7ba)"}
                                        textColor={'#fff'}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNoteDialog}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={`(${t('processing')} > ${t('awaiting')})`}
                        handleInputText={this.handleHandOverNoteTextStageThree}
                        inputValue={this.props.handOverNoteForAwaiting}
                        handleOk={this.closeNoteDialogStageThree}
                        inputDisable={!isExpanded}
                        handleCancel={this.closeNoteDialogStageThree}
                        showDialogBtns={false}
                        showCrossBtn={true}
                    />


                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        data-test-id="nextStageDialog"
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNextDialog}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={`(${t('awaiting')} > ${t('operations_review')})`}
                        handleInputText={this.handleHandOverNoteTextStageThree}
                        inputValue={this.state.handOverNoteText}
                        handleOk={() => this.trackRequest("next")}
                        inputDisable={!isExpanded}
                        handleCancel={this.closeNextDialogStageThree}
                        showDialogBtns={true}
                        showCrossBtn={true}
                    />


                    <RejectRequestDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showRejectDialog}
                        textId={'rejectReason'}
                        pushBackOptions={pushBackOption}
                        inputValue={this.state.isBtnVisible ? this.state.rejectReasonText : rrStageThreeData?.data?.attributes?.rejected_reason}
                        setSelectedPushBackTo={this.moveToSelectedStepStageThree}
                        stepValue={this.state.moveToStep}
                        headingText={t('reject')}
                        handleInputText={this.handleRejectReasonTextStageThree}
                        handleOk={() => this.trackRequest("reject")}
                        handleCancel={this.closeRejectDialogStageThree}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                        labelText={t('push_back')}
                        reasonLabel={t('reason')}
                    />
                </React.Fragment>

            </ThemeProvider >
            // Customizable Area Start
        )
    }
}
const useStyles = ({
    awatingStage: {
        display: 'flex',
        "flex-flow": 'column',
        width: '100%'
    },
    displayFlexEnd: { display: "flex", justifyContent: "end", margin: '16px 0' },
    accordionHeading: {
        fontWeight: 600
    },
    displayFlex: {
        display: "flex",
    },
    contactForm: { width: "100%" },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    btnStyle: {
        margin: '0 10px'
    },
    topSection: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    descriptionIcon: {
        color: '#007E98'
    },
    accordianGap: {
        padding: "10px 0"
    },
    accordianSection: {
        "border-radius": "10px !important",
        '&::before': {
            content: '" "',
            display: 'none'
        }
    },

});

export default withStyles(useStyles)(withTranslation()(StageThreeForm));