import React from 'react'
import { Accordion,makeStyles, AccordionSummary, AccordionDetails, IconButton, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import MotorInsurance from './MotorInsurance.web';
import Contact from './Contact.web';
import { useTranslation } from 'react-i18next';


const useStylesFunction = makeStyles({
    accordianHeading: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
    },
    lockedAccordion: {
        padding: '6px 10px',
        backgroundColor: '#e9f4f9',
        border: '1px solid #ccc',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 8px',
        '& svg': {
            marginRight: '6px',
        }
    },


    accordionWrapper: {
        padding: '0px 15px',
        '& .MuiAccordion-root': {
            backgroundColor: '#fff',
            margin: '15px 0px'
        }
    },
    btnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "color": "white",
        // "height": "50px",
        borderRadius: "10px",
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    headerButtonStyle: {
        display: "flex",
        justifyContent: "end"
    },
    dialogBtnWrapper: {
        justifyContent: 'center',
        marginBottom: '15px',
        textAlign: 'right',
        '@media(min-width: 500px)': {
            '& button': {
                padding: '12px 78px',
            }
        },
    },
    dialogBtnCancel: {
        color: 'rgb(0 0 0 / 54%)',
        textTransform: 'capitalize',
        margin: '0px 15px',
        borderRadius: '15px',
    },
    dialogBtnSave: {
        backgroundColor: '#45a3ad',
        textTransform: 'capitalize',
        borderColor: '#45a3ad',
        color: '#fff',
        "&:hover": {
            backgroundColor: '#45a3ad',
        }
    },
    addCmp: {
        '& > div': {
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 700,
        },
        '& > p': {
            color: 'rgba(0, 0, 0, 0.87)',
        }
    },
    accordionDetailsWrapper: {
        display: 'block',
        textAlign: 'right',
        '& > svg': {
            color: '#2f2f2f',
            cursor: 'pointer'
        }
    },
    lineSaprator: {
        backgroundColor: '#e2e2e2',
        height: '1.3px',
        width: '100%',
        margin: '18px 0px'
    },
    inputFile: {
        // '& input[type="file"]::file-selector-button':{
        //     display: 'none',
        // },
        color: '#7f7f7f',
        '& fieldset': {
            border: '1px dashed rgba(0, 0, 0, 0.23) !important'
        },
        '& > div': {
            height: '40px',
            overflow: 'hidden',
        },
        '& input': {
            opacity: '0',
            position: 'absolute',
            left: '0px',
            right: '0px',
            maxWidth: '75%',
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 'auto',
            position: 'absolute',
            right: '0px',
            '& > *': {
                background: '#fff',
            }
        }
    },
    accordionStyle: {
        background: "white !important",
        "border-radius": "10px !important",
    },
    errorstyle: {
        border: '1px solid red !important',
        "border-radius": "10px !important",
    },
    editWrapper: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 15px',
        paddingTop: '15px',
        '.MuiGrid-root.MuiGrid-item':{
            fontWeight: 600,
        },
        '& button': {
            padding: '15px 0px',
            width: '150px',
            borderColor: '#3b8da2',
            borderRadius: '15px',
            textTransform: 'capitalize',
        }
    },
    editBtnStyle: {
        backgroundColor: '#3b8da2',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#3b8da2',
            color: '#fff',
        }
    },

})

const enumFormAction = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
}

type accordionTitleProps = {
    title: string,
    insuranceData: any,
    insurerId: any
}

const AccordionTitle = (props: accordionTitleProps) => {
    const classes = useStylesFunction()
    const {t} = useTranslation();
    return (
        <Typography className={classes.accordianHeading}>
            {props.title}
            {
                !props.insurerId && props.insuranceData === null && (
                    <div className={classes.lockedAccordion}>
                        <LockIcon /> {t('please_select_insurance_type_to_unlock_this_fields')}
                    </div>
                )
            }

        </Typography>
    )
}



const ContactJSX = (props:any) => {
    const classes = useStylesFunction();
    const {t} = useTranslation();
    return (
    <Accordion disabled={props.insuranceData === null ? true : false} className={props.errorSates.insurance_contacts_attributes_error ? classes.errorstyle : classes.accordionStyle}>
    <AccordionSummary
        aria-expanded={true}
        expandIcon={<ExpandMore />}
        aria-controls="Contacts"
        id="Contacts"
        className={classes.addCmp}
    >
        <AccordionTitle title={t('contacts')} insurerId={props.insurerId} insuranceData={props.insuranceData} />
        {(props.insurance_contacts.length < 10 && props.formType !== enumFormAction.VIEW) && (<div onClick={props.handleAddContact} style={{color: '#449fac'}}>+ {t('add_contact')}</div>)}
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetailsWrapper}>
        {
            props.insurance_contacts && props.insurance_contacts.map((item: any, index: number) => (
                <div key={item}>
                <IconButton disabled={props.formType === enumFormAction.VIEW ? true : false}>
                    
                    {index > 0 && (
                        <CloseIcon onClick={/* istanbul ignore next */() =>  props.ContactClose(index)
                        } />
                    )}
                    </IconButton>
                    <div style={{ width: '100%' }}>
                        <Contact
                            addData={/* istanbul ignore next */(key: string, value: string) => props.handleContactAddData(key, value, index)}
                            contactProp={item}
                            submitEvent={props.checkErrors}
                            formType={props.formType}
                        />
                    </div>
                    <div className={classes.lineSaprator}></div>
                </div>
            ))
        }
    </AccordionDetails>
</Accordion>
    )
}

export default ContactJSX