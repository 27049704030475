import { Accordion, AccordionSummary, AccordionDetails, Typography, makeStyles, withStyles, Grid, TextField, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, createTheme } from '@material-ui/core'
import { ErrorOutlineRounded, ExpandMore } from '@material-ui/icons'
import React, { Component } from 'react'
import LockIcon from '@material-ui/icons/Lock';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import TextInput from '../Common/TextInput'
import LabelWithIcon from '../Common/LabelWithIcon';
import StageFiveFormSchema from './StageFiveFormSchema';
import FileUpload from '../Common/FileUpload';
import StageFiveController from '../../../blocks/TaskAllocator/src/PolicyStage/StageFiveController'
import Toster from '../Common/Toster';
import CustomButton from '../Common/CustomButton';
import RejectRequestDialog from '../Common/RejectRequestDialog.web';
import { t } from 'i18next';
import HandOverDialog from '../Common/HandOverDialog.web';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { HighlightOff } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadFile from '../Common/DownloadFile';
import ActiveLanguage from '../Common/ActiveLanguage';

const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });
  
const CustomToolTip = withStyles({
    arrow: {
      "&::before": {
        color: "white"
      }
    },
    tooltip: {
      backgroundColor: "#f5f5f9",
      boxShadow: theme.shadows[8],
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 14,
      maxWidth: 800
    },
    tooltipPlacementTop: {
      margin: "4px 0"
    }
  })(Tooltip);

export const useStyles: any = (theme: any) => ({
    accordionWrapper: {
        width: '100%',
        padding: '0px 15px',
        '& .MuiAccordion-root': {
            backgroundColor: '#fff',
        }
    },
    accWrapper:{
        borderRadius: '10px !important',
        '&::before':{
            height: '0px !important'
        }
    },
    childAccordion: {
        width: '100%',
        '&>div': {
            boxShadow: 'none',
            width: '100%',
            border: '1px solid #ccc',
            margin: '15px 0px',
            "border-radius":"10px !important",
            //    padding: '15px',
        }
    },
    lineSaprator: {
        margin: '30px 0px',
    },
    errorstyle: {
        border: '1px solid red !important'
    },
    fileInput: {
        '& fieldset': {
            borderStyle: 'dashed',
        }
    },
    accordianGap: {
        padding: '10px 0'
    },
    radioFormControl: {
        display: 'block',
    },
    flexDirection: {
        flexDirection: 'column'
    },
    toggleContainer: {
        width: '100%',
            display: 'flex',
                justifyContent: 'space-between',
                    '& svg': {
            color: '#0090a1'
        }
    },
    iconAlignment: {
        display: 'flex',
            marginRight: "10px"
    },
    lostIcon: {
        marginLeft: "15px",
            '& svg': {
            color: '#0090a1'
        },
    },
    BtnGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 15px',
        flexWrap: 'wrap',
    },
    buttonsRightWrapper: {
        display: 'flex',
        gap: '15px',
        padding: '15px 0px',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        '@media(max-width: 700px)':{
            justifyContent: 'flex-start',
        }
    },
    accTextIconWrap: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        '& svg': {
            color: '#0090a1'
        }
    },
    fileUploadError: {
        '& label.uploadFileInput': {
            borderColor: 'red',
        }
    }

})

const useStylesFunction = makeStyles({
    accordianHeading: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
    },
    lockedAccordion: {
        padding: '6px 10px',
        backgroundColor: '#e9f4f9',
        border: '1px solid #ccc',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 8px',
        '& svg': {
            marginRight: '6px',
        }
    },
    errorstyle: {
        border: '1px solid red !important'
    },

})

const enumYesNO = {
    YES: 'yes',
    NO: 'no',
}
const enumFormAction = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
}

const enumAccordioanState = {
    p5: 'p5'
}

const enumTosterType = {
    error: "error",
    success: 'success',
    warning: 'warning',
    info: 'info',
}

const enumBtnType = {
    NEXT: 'NEXT',
    DRAFT: 'DRAFT',
}

const enumRoles = {
    ADMIN: "Admin",
    SALES: "Sales",
    OPERATIONS: "Operations",
    FINANCE: "Finance",
    CONTACT: "Contact",
}

type accordionTitleProps = {
    title: string,
    // insuranceData: any,
}

const AccordionTitle = (props: accordionTitleProps) => {
    const classes = useStylesFunction()
    return (
        <Typography className={classes.accordianHeading}>
            {props?.title}
            {/* {
                    <div className={classes.lockedAccordion}>
                        <LockIcon /> Please select insurance type to unlock  this fields
                    </div>
            } */}

        </Typography>
    )
}


// class StageFiveForm extends Component<MyProps, MyState> {
class StageFiveForm extends StageFiveController {
    static propTypes: any;
    formikRef: any;
    constructor(props: any) {
        super(props);
        this.formikRef = React.createRef();  
        // this.handleStorage = this.handleStorage.bind(this);
    }

    render() {
        const { t, classes,isExpanded }: any = this.props;
        const stageFiveData = JSON.parse(localStorage.getItem('stageFiveData') || '{}');
        const stageFourData = JSON.parse(localStorage.getItem('stageFourData') || '{}');
        const updatedDetails = stageFiveData?.attributes?.release_insurance_infos?.data
        
        return (
            <div>
                <Toster
                    tosterText={this.state.tosterText}
                    showToster={this.state.toster}
                    tosterType={this.state.tosterType} // error, success, warning, info
                    handleCloseToster={() => this.setState({ toster: false })}
                />
                <div className={classes.accordianGap}>
                <Tooltip title={this.state.accordianDisable ? "Not accessible for Contact role" : ""}>
                    <Accordion expanded={this.state.accordionOpenClose} onChange={this.handleAccordianChange} className={classes.accWrapper}>
                        <AccordionSummary
                            aria-expanded={true}
                            expandIcon={<ExpandMore />}
                            aria-controls="Insurers"
                            id="Insurers"
                        >
                            <div className={classes.toggleContainer}>
                              <Typography style={{fontWeight: 600}} >{t('releasing_to_customer')}</Typography>
                              <div className={classes.iconAlignment}>
                                {this.state.accordionOpenClose && <DescriptionOutlinedIcon onClick={() => this.handleOpenHandNote('')} /> }
                                {this.state.accordionOpenClose && stageFiveData?.attributes?.is_reject &&
                                 <ErrorOutlineRounded style={{position: 'relative', left: '10px'}} onClick={() => this.setState({isBtnVisible: false, showRejectDialog: true})} />
                                }
                              </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.flexDirection}>
                            {
                                this.state.previousStageData?.attributes?.submitting_insurance_infos?.data?.map((item: any, index: number) => (
                                    <div key={index}>
                                        <Formik
                                        //    innerRef={this.formikRef}

                                            // innerRef={this.formRef[index]}
                                            innerRef={(element) => {
                                                this.formRef.current[index] = element;
                                            }}
                                            enableReinitialize={true}
                                            initialValues={{
                                                quotation_received: item?.attributes?.quotation_received ? enumYesNO.YES : enumYesNO.NO,
                                                quotation_amount: item?.attributes?.quotation_amount,
                                                comment: item?.attributes?.comment,
                                                // proposal: item?.attributes?.proposal,
                                                proposal: item.attributes?.proposal?.file_name || "",
                                                additional_attachment:updatedDetails?.[index]?.attributes?.additional_attachments || this.state?.additional_attachment[index]||"",
                                                // additional_attachment:this.formRef?.current[index]?.values?.additional_attachment,
                                                note_to_the_customer: updatedDetails?.[index]?.attributes?.note_to_the_customer ||this.state?.note_to_the_customer[index]|| item.attributes.note_to_the_customer || "",
                                                release_quotation_to_customer: updatedDetails?.length > 0? this.getYesNo(updatedDetails?.[index]?.attributes?.release_quotation_to_customer) : enumYesNO.YES,
                                                insurance_company_id: item?.attributes?.insurance_company_id.id,
                                                id: item?.id,
                                                release_premium_calculation: stageFiveData?.id ? this.getYesNo(stageFiveData?.attributes?.release_premium_calculation) : enumYesNO.YES,
                                                comparison_sheet: stageFiveData?.id ? this.getYesNo(stageFiveData?.attributes?.comparison_sheet) : enumYesNO.YES,
                                            }}
                                            validationSchema={Yup.object().shape(StageFiveFormSchema(ActiveLanguage()))}
                                            validateOnMount={true}
                                            validateOnBlur={true}
                                            onSubmit={async (values, actions) => {
                                                // this.checkError(values)
                                                console.log("formik data :", values)
                                                // if (this.state.isEdit) {
                                                //     this.editCustomer(values);
                                                // } else {
                                                //     this.submitCustomerForm(values);
                                                // }
                                            }}
                                        >
                                            {({
                                                handleSubmit,
                                                errors,
                                                touched,
                                                values,
                                                setFieldValue,
                                                validateForm,
                                                handleChange
                                            }) => (
                                                <form className={classes.accordionWrapper} onSubmit={handleSubmit} onChange={(e) => {
                                                    // setTimeout(() => {
                                                    // handleChange
                                                    // console.log("this.formRef.current[index] :", this.formRef.current[index])
                                                    this.handleOnChange(values, index, item)
                                                    // }, 5000)
                                                }}>

                                                    <div className={classes.childAccordion}>
                                                        <Accordion className={this.state.blockError[index] ? classes.errorstyle : ''}>
                                                            <AccordionSummary
                                                                aria-expanded={true}
                                                                expandIcon={<ExpandMore />}
                                                                aria-controls="Insurers"
                                                                id="Insurers"
                                                            // style={{border: '1px solid red'}}
                                                            >
                                                                <AccordionTitle title={item?.attributes?.insurance_company_id.short_name} />
                                                            </AccordionSummary>
                                                            <AccordionDetails style={{ width: '100%' }}>

                                                                <div style={{ width: '100%' }}>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} sm={4}>
                                                                            <FormControl disabled={true} className={classes.radioFormControl}>
                                                                                <LabelWithIcon label={t('quotation_received')} />
                                                                                <RadioGroup
                                                                                    row
                                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                    defaultValue={item?.attributes?.quotation_received ? enumYesNO.YES : enumYesNO.NO}
                                                                                    // name="quotation_received"
                                                                                    // value={values.tawuniya.quotation_recived}
                                                                                    onChange={(e: any) => { console.log("recive :", e.target.value); this.setState({ quotation_received: e.target.value }) }}
                                                                                // error={this.state.quotation_received ? false : true}
                                                                                >
                                                                                    <FormControlLabel value={enumYesNO.YES} control={<Radio color="primary" />} label={t('yes')} />
                                                                                    <FormControlLabel value={enumYesNO.NO} control={<Radio color="primary" />} label={t('no')} />
                                                                                </RadioGroup>
                                                                            </FormControl>
                                                                        </Grid>
                                                                        {
                                                                            item?.attributes?.quotation_received ? (
                                                                                <>                                              <Grid item xs={12} sm={4}>
                                                                                    <LabelWithIcon label={t('quotation_amount')} />

                                                                                    <TextInput
                                                                                        id={"quotation_amount"}
                                                                                        className={classes.textInputStyle}
                                                                                        isArabicLanguage={this.state.isArabicLanguage}
                                                                                        name="quotation_amount"
                                                                                        type="text"
                                                                                        disabled={true}
                                                                                        data-test-id="customerNameTxtInput"
                                                                                    />
                                                                                    {touched.quotation_amount && errors.quotation_amount ? (
                                                                                        <Typography color="error" >{errors.quotation_amount}</Typography>
                                                                                    ) : null}
                                                                                </Grid>
                                                                                    <Grid item xs={12} sm={4}>
                                                                                        <LabelWithIcon label={t('proposal')} />
                                                                                        <DownloadFile
		                                                                                    data-test-id="customerNameTxtInput"
		                                                                                    inputName="proposal"
		                                                                                    disabled={true}
		                                                                                    eachFileSizeInMB={10}
		                                                                                    maxFilesNumber={5}
		                                                                                    isEditPage={true}
		                                                                                    fileName={item.attributes?.proposal?.file_name || ""}
		                                                                                    filePath={item.attributes?.proposal?.url || ""}
                                                                                        />
                                                                                        {touched.proposal && errors.proposal ? (
                                                                                            <Typography color="error" >{errors.proposal}</Typography>
                                                                                        ) : null}
                                                                                    </Grid>
                                                                                </>
                                                                            ) : null
                                                                        }
                                                                    </Grid>

                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12}>
                                                                            <LabelWithIcon label={t('comment')} />
                                                                            <TextInput
                                                                                id={"comment"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLanguage={this.state.isArabicLanguage}
                                                                                name="comment"
                                                                                type="text"
                                                                                disabled={true}
                                                                                data-test-id="customerNameTxtInput"
                                                                                multiline
                                                                                rows={6}
                                                                                maxRows={10}
                                                                            />
                                                                            {touched.comment && errors.comment ? (
                                                                                <Typography color="error" >{errors.comment}</Typography>
                                                                            ) : null}
                                                                        </Grid>
                                                                    </Grid>

                                                                    <hr className={classes.lineSaprator} />


                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} sm={4}>
                                                                            <FormControl disabled={this.state.viewDisable || !isExpanded ? true :false}>
                                                                                <LabelWithIcon label={t('release_quotation_to_customer')} />
                                                                                <RadioGroup
                                                                                    row
                                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                                    defaultValue={updatedDetails?.length > 0 ? this.getYesNo(updatedDetails?.[index]?.attributes?.release_quotation_to_customer) : enumYesNO.YES}
                                                                                    // name="release_quotation_to_customer"
                                                                                    // value={values.tawuniya.release_quotation_to_customer}
                                                                                    // onChange={handleChange}
                                                                                    onChange={(e: any) => setFieldValue('release_quotation_to_customer', e.target.value)}
                                                                                >
                                                                                    <FormControlLabel value={enumYesNO.YES} control={<Radio color="primary" />} label={t('yes')} />
                                                                                    <FormControlLabel value={enumYesNO.NO} control={<Radio color="primary" />} label={t('no')} />
                                                                                </RadioGroup>
                                                                            </FormControl>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={4} className={classes.fileUploadError}>
                                                                            <LabelWithIcon label={t('additional_attechment_single')} />
                                                                            <FileUpload
                                                                                fileName={values?.additional_attachment?.file_name || ""}
                                                                                isEditPage={this.state.formType === enumFormAction.EDIT ? true : false}
                                                                                filePath={values?.additional_attachment?.url|| ""}
                                                                                data-test-id="additional_attachment"
                                                                                inputName={`additional_attachment`}
                                                                                disabled={this.state.viewDisable || !isExpanded ? true :false}
                                                                                onChange={async (event: any) => {
                                                                                    const file = await event.target.files[0];
                                                                                    const res = await this.handleFileUpload(file, index,`additional_attachment`,this.formRef)
                                                                                    setFieldValue(`additional_attachment`, file)
                                                                                }}
                                                                                onRemove={async () => {
                                                                                    if (!this.props.isExpanded) {
                                                                                        return
                                                                                    }
                                                                                    else {
                                                                                        this.setState({attachmentIndex:index})
                                                                                        await setFieldValue(`additional_attachment`, {})
                                                                                        await this.removeFile(values?.additional_attachment?.id, this.formRef,`additional_attachment`,index)
                                                                                    }
                                                                                }
                                                                                }
                                                                            />
                                                                            {touched.additional_attachment && errors.additional_attachment ? (
                                                                                <Typography color="error" >{errors.additional_attachment}</Typography>
                                                                            ) : null}
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12}>
                                                                            <LabelWithIcon label={t('note_to_the_customer')} />
                                                                            <TextInput
                                                                                id={"note_to_the_customer"}
                                                                                className={classes.textInputStyle}
                                                                                isArabicLanguage={this.state.isArabicLanguage}
                                                                                name="note_to_the_customer"
                                                                                type="text"
                                                                                // disabled={!this.state.enableEditing}
                                                                                data-test-id="customerNameTxtInput"
                                                                                multiline
                                                                                rows={6}
                                                                                maxRows={10}
                                                                                onChange={(e:any)=>{
                                                                                    handleChange(e)
                                                                                    let noteToCustomers=[...this.state.note_to_the_customer]
                                                                                    noteToCustomers[index]=e.target.value
                                                                                    this.setState({note_to_the_customer:noteToCustomers})
                                                                                }}
                                                                                disabled={this.state.viewDisable || !isExpanded ? true :false}
                                                                            />
                                                                            {touched.note_to_the_customer && errors.note_to_the_customer ? (
                                                                                <Typography color="error" >{errors.note_to_the_customer}</Typography>
                                                                            ) : null}
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>

                                                    </div>

                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                ))
                            }
                            <div style={{ padding: '15px', textAlign: 'left' }}>
                                <Grid container spacing={3}>
                                    {this.state.premiumCalculation && <Grid item xs={12} sm={4}>
                                        <FormControl disabled={this.state.viewDisable || !isExpanded ? true :false}>
                                            <LabelWithIcon label={t('release_premium_calculation')} />
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                // name="release_premium_calculation"
                                                defaultValue={stageFiveData?.id ? this.getYesNo(stageFiveData?.attributes?.release_premium_calculation) : enumYesNO.YES}
                                                onChange={(e: any) => this.setState({ release_premium_calculation: e.target.value })}
                                            >
                                                <FormControlLabel value={enumYesNO.YES} control={<Radio color="primary" />} label={t('yes')} />
                                                <FormControlLabel value={enumYesNO.NO} control={<Radio color="primary" />} label={t('no')} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>}

                                    {this.state.comparisionSheet &&  <Grid item xs={12} sm={4}>
                                        <FormControl disabled={this.state.viewDisable || !isExpanded ? true :false}>
                                            <LabelWithIcon label={t('release_comparison_sheet')} />
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="comparison_sheet"
                                                defaultValue={stageFiveData?.id ? this.getYesNo(stageFiveData?.attributes?.comparison_sheet) : enumYesNO.YES}
                                                onChange={(e: any) => this.setState({ comparison_sheet: e.target.value })}
                                            >
                                                <FormControlLabel value={enumYesNO.YES} control={<Radio color="primary" />} label={t('yes')} />
                                                <FormControlLabel value={enumYesNO.NO} control={<Radio color="primary" />} label={t('no')} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>}
                                </Grid>
                            </div>

                        </AccordionDetails>
                    </Accordion>
                </Tooltip>
                </div>
                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNoteDialog as boolean}
                        textId={'handOverNoteText'}
                        headingText={this.state.markAsLostDialog ? t('mark_as_lost') : t('handover_note')}
                        subHeading={this.state.markAsLostDialog ? "" : this.state.isBtnVisible ? `(${t('sales_review')} > ${t('customere_review')})`:t('stage4_subheading')}
                        handleInputText={(value:string)=>{this.setState({handOverNoteText:value})}}
                        inputValue={this.state.isBtnVisible ? this.state.handOverNoteText : (this.state.markAsLostDialog ? stageFourData.attributes?.lost_reason  : stageFourData.attributes?.handover_note_sales_review)}
                        inputLable={this.state.showNoteDialog ? t('note') : ""}
                        handleOk={(value:string)=> this.state.markAsLostDialog ? this.handleMarkAsLost(value) : this.handleOkDialog(value)}
                        handleCancel={() => this.closeNoteDialog()}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                        inputDisable={!this.state.isBtnVisible}
                    />

                <RejectRequestDialog
                    btnCancelText={t('cancel')}
                    btnOkText={t('confirm')}
                    openDialog={this.state.showRejectDialog}
                    textId={'rejectReason'}
                    pushBackOptions={this.getPoicyStageOption()}
                    setSelectedPushBackTo={(e: any) => {
                        this.setState({rejectedStage: e.value})
                    }}
                    inputValue={this.state.isBtnVisible ? this.state.rejectReason : stageFiveData?.attributes?.rejected_reason}
                    headingText={t('rejecting')}
                    handleInputText={(value: string) => this.handleChangeRejectReason(value)}
                    handleOk={() => this.changePolicyStep(this.state.currentSteps, "reject")}
                    handleCancel={() => this.handleRejectDialog(false)}
                    showDialogBtns={this.state.isBtnVisible}
                    showCrossBtn={true}
                    labelText={t('push_back')}
                    reasonLabel={t('reason')}
                />



                {/* <button onClick={() => this.checkError(values)} type='submit'>Submit btn</button> */}
                {/* <button type='submit' onClick={this.handleSubmit}>Submit btn</button> */}

                {
                    // this.state.role === enumRoles.sales ? (
                    (this.state.btnGroupShow && this.props.isExpanded && this.state.accordionOpenClose && !stageFourData.attributes?.lost_reason && (this.state.formType !== enumFormAction.VIEW)) && (
                        <div className={classes.BtnGroup}>
                            <div style={{ marginRight: '15px' }}>
                                <CustomButton
                                    btnText={t('mark_as_lost')}
                                    onClick={this.openNoteDialog}
                                    // bgColor='#0090a1'
                                    textColor='#0090a1'
                                />
                            </div>
                            <div className={classes.buttonsRightWrapper}>
                                <CustomButton
                                    btnText={t('save_draft')}
                                    onClick={() => this.handleSubmit(enumBtnType.DRAFT)}
                                    // bgColor='#0090a1'
                                    textColor='#0090a1'
                                />
                                <CustomButton
                                    btnText={t('reject')}
                                    onClick={this.handleReject}
                                    // bgColor='#0090a1'
                                    textColor='#0090a1'
                                />
                                <CustomButton
                                    btnText={t('next_stage')}
                                    onClick={() => this.handleSubmit(enumBtnType.NEXT)}
                                    bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                    textColor='#fff'
                                />
                            </div>
                        </div>
                    )}

            </div>
        )
    }
}

StageFiveForm.propTypes = {
    classes: PropTypes.string.isRequired,
};

export default withStyles(useStyles)(withTranslation()(StageFiveForm));