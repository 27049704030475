// Customizable Area Start
import React from "react";
import CustomerCsvImportController, {
    Props
  } from "./CustomerCsvImportController";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End
import { Container } from "@material-ui/core";
import CSVImportDetails from '../../../../components/src/Common/CSVImportDetails'
import {CUSTOMER_CSV_TABLE_BODY} from "../../../../components/src/Common/Table/TableColumns"





// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});
// Customizable Area End



export default class CustomerCsvImport extends CustomerCsvImportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { 
      isUploadingCsvFile, 
      page, 
      totalPage, 
      alertMessage, 
      showAlert, 
      alertType, 
      customerCsvData, 
      errorMessage, 
      errorFile, 
      isLoading 
    } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme} >
        <Container id="customerCSVImportPage" maxWidth="lg" dir={(window.localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"} >
          {/* @ts-ignore */}
           <CSVImportDetails 
              importFor={'customer'} 
              isUploadingCsvFile={isUploadingCsvFile}
              page={page}
              totalPage={totalPage} 
              alertMessage={alertMessage} 
              showAlert={showAlert}
              alertType={alertType} 
              csvData={customerCsvData} 
              errorMessage={errorMessage} 
              errorFile={errorFile} 
              isLoading={isLoading}
              handleAnotherCSVUpload={this.handleAnotherCSVUpload}
              downloadFile={this.downloadFile}
              handleSaveCSVUpload={this.handleSaveCSVUpload}
              handleCSVUploader={this.handleCSVUploader}
              handleChangePage={this.handleChangePage}
              handleRowPerPage={this.handleRowPerPage}
              closeToast={this.closeToast}
              tableBody={CUSTOMER_CSV_TABLE_BODY} 
              downloadSampleCsv={this.downloadSampleCsvForCustomer}
            />
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
