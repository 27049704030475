import React from "react";
import {
    Button,
    FormControlLabel,
    Grid,
    InputAdornment,
    Typography,
    Checkbox,
    FormControl,
    Radio,
    RadioGroup,
    CircularProgress
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PaymentReviewController from "./PaymentReviewController";
import LabelWithIcon from "../../../../../../components/src/Common/LabelWithIcon";
import TextInput from "../../../../../../components/src/Common/TextInput";
import FileUpload from "../../../../../../components/src/Common/FileUpload";
import DownloadFile from "../../../../../../components/src/Common/DownloadFile";
import { ErrorMessage } from "formik";
import Toster from "../../../../../../components/src/Common/Toster";

export class PaymentReviewDeductedCommisionForm extends PaymentReviewController {

    renderUpperFormUi = () => {
        const { t, classes } = this.props;

        return <>
            <Grid item xs={12} container className={classes.bottomAlignment}>
                <Grid style={{ paddingRight: "25px" }} item xs={4}>
                    <LabelWithIcon label={t("commission_rate")} />
                    <TextInput
                        className={classes.textInputStyle}
                        name={`fieldsData['flowFieldObj'][commission_rate]`}
                        type="text"
                        disabled={!this.props.isExpanded}
                        data-test-id="paymentReviewDeductedCommisionFormcommissionrate"
                        endAdornment={"%"}
                        onChange={(event: any) => this.props.deductedCommissionFeildsHandlers(event.target.value, "commission_rate")}
                    />
                    <ErrorMessage
                        name={`fieldsData['flowFieldObj'][commission_rate]`}
                        className="field-error"
                        data-test-id="commission_rate_error"
                        render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                    />
                </Grid>
                <Grid style={{ paddingRight: "25px" }} item xs={4}>
                    <LabelWithIcon label={t("commission_amount")} />
                    <TextInput
                        disabled={true}
                        className={classes.textInputStyle}
                        name={`fieldsData['flowFieldObj'][commission_amount]`}
                        type="text"
                        data-test-id="paymentReviewDeductedCommisionFormcommissionamount"
                        startAdornment={
                            <InputAdornment position="start">
                                <div> &nbsp;SAR&nbsp;</div>
                            </InputAdornment>
                        }
                    />
                    <ErrorMessage
                        name={`fieldsData['flowFieldObj'][commission_amount]`}
                        className="field-error"
                        data-test-id="commission_amount_error"
                        render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LabelWithIcon label={t("vat_on_siib_commission")} />
                    <FormControl className={classes?.textInputStyle} style={{ marginLeft: 15, marginTop: 17 }}>
                        <RadioGroup
                            row
                            data-test-id="vat_on_commission"
                            value={this.props.values.fieldsData['flowFieldObj']['deduct_commission_with_vat']}
                            name={`fieldsData['flowFieldObj'][deduct_commission_with_vat]`}
                            onChange={(event: any) => this.props.deductedCommissionFeildsHandlers(event.target.value, "deduct_commission_with_vat")}
                        >
                            <FormControlLabel
                                disabled={!this.props.isExpanded}
                                value={'yes'} control={<Radio color="primary" />} label={t("yes")} />
                            <FormControlLabel
                                disabled={!this.props.isExpanded}
                                value={'no'} control={<Radio color="primary" />} label={t("no")} />
                        </RadioGroup>
                    </FormControl>
                    <ErrorMessage
                        name={`fieldsData['flowFieldObj'][deduct_commission_with_vat]`}
                        className="field-error"
                        data-test-id="deduct_commission_with_vat_error"
                        render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.bottomAlignment}>
                <Grid style={{ paddingRight: "25px" }} item xs={4}>
                    <React.Fragment>
                        <LabelWithIcon label={t("invoice_date")} />
                        <TextInput
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj'][invoice_date]`}
                            type={"date"}
                            disabled={!this.props.isExpanded}
                            data-test-id="paymentReviewDeductedCommisionForminvoivedate"
                            onChange={(event: any) => this.props.deductedCommissionFeildsHandlers(event.target.value, "invoice_date")}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj'][invoice_date]`}
                            className="field-error"
                            data-test-id="invoice_date_error"
                            render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                        />
                    </React.Fragment>
                </Grid>
                <Grid style={{ paddingRight: "25px" }} item xs={4}>
                    <React.Fragment>
                        <LabelWithIcon label={t("receipt_date")} />
                        <TextInput
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj'][receipt_date]`}
                            type={"date"}
                            disabled={!this.props.isExpanded}
                            data-test-id="paymentReviewDeductedCommisionFormpaymentVoucherDate"
                            onChange={(event: any) => this.props.deductedCommissionFeildsHandlers(event.target.value, "receipt_date")}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj'][receipt_date]`}
                            className="field-error"
                            data-test-id="receipt_date_error"
                            render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                        />
                    </React.Fragment>
                </Grid>
                <Grid item xs={4} container style={{ marginTop: 37, marginBottom: 9, height: 56}}>
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        data-test-id="paymentReviewDeductedCommisionFormcreateInvoiceBtn"
                        disabled={this.checkCreateInvoiceReceiptBtnIsDisabled(this.props.values)}
                        className={this.checkCreateInvoiceReceiptBtnIsDisabled(this.props.values) ? classes.grayBtn : classes.primaryRvBtn}
                        onClick={() => this.createInvoiceAndReceipt(this.props.values, this.props.flowValue, this.props.formikRef, this.props.installmentIndex)}
                    >
                        {(this.state.isinvoiceAndReceiptBtnLoading || this.state.receiptBtnLoading) ? <CircularProgress size={25} /> : t("create_invoice_receipt")}
                    </Button>
                    <ErrorMessage
                        name={`fieldsData['flowFieldObj'][siib_invoice_id]`}
                        className="field-error"
                        data-test-id="siib_invoice_id_error"
                        render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.bottomAlignment}>
                <Grid style={{ paddingRight: "25px" }} item xs={4}>
                    <LabelWithIcon label={t("invoice")} />
                    <DownloadFile
                        data-test-id="invoice_file"
                        disabled={true}
                        eachFileSizeInMB={10}
                        maxFilesNumber={5}
                        isEditPage={true}
                        inputName={"invoice_file"}
                        fileName={this.props.values?.fieldsData?.flowFieldObj?.invoice_file?.file_name || ""}
                        filePath={this.props.values?.fieldsData?.flowFieldObj?.invoice_file?.url || ""}
                    />
                    <ErrorMessage
                        name={"invoice_file"}
                        className="field-error"
                        data-test-id="invoice_file_error"
                        render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                    />
                </Grid>
                <Grid style={{ paddingRight: "25px" }} item xs={4}>
                    <React.Fragment>
                        <LabelWithIcon label={t("siib_invoice_id")} />
                        <TextInput
                            disabled={true}
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj'][siib_invoice_id]`}
                            type="text"
                            data-test-id="siib_invoice_id"
                        />
                    </React.Fragment>
                </Grid>
            </Grid>
        </>
    }


    render() {
        const { t, classes } = this.props;
        const isDisabledPaymentVoucherBtn = this.checkBtnPaymentVoucherBtnDisabledflowFour()
        return (
            <>
                <Toster
                    tosterText={this.state.alertMessage}
                    showToster={this.state.showAlert}
                    tosterType={this.state.alertType}
                    handleCloseToster={this.closeAlertMessage}
                />
                {this.renderUpperFormUi()}

                <Grid item xs={12} container className={classes.bottomAlignment}>
                    <Grid style={{ paddingRight: "25px" }} item xs={4}>
                        <LabelWithIcon label={t("receipt")} />
                        <DownloadFile
                            data-test-id="receipt"
                            disabled={true}
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            isEditPage={true}
                            fileName={this.props.values?.fieldsData?.flowFieldObj?.receipt?.file_name || ""}
                            filePath={this.props.values?.fieldsData?.flowFieldObj?.receipt?.url || ""}
                            inputName={`receipt`}
                        />
                        <ErrorMessage
                            name={"receipt"}
                            className="field-error"
                            data-test-id="receipt_error"
                            render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid style={{ paddingRight: "25px" }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t("siib_receipt_id")} />
                            <TextInput
                                className={classes.textInputStyle}
                                disabled={true}
                                name={`fieldsData['flowFieldObj'][siib_receipt_id]`}
                                type="text"
                                data-test-id="siib_receipt_id"
                            />
                            <ErrorMessage
                                name={`fieldsData['flowFieldObj'][siib_receipt_id]`}
                                className="field-error"
                                data-test-id="siib_receipt_id_error"
                                render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                            />
                        </React.Fragment>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12}
                    container
                    className={classes.borderAlignment}
                    style={{ marginBottom: 20 }}
                >
                    <Grid style={{ paddingRight: "25px" }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t("payable_to_insurer")} />
                            <TextInput
                                data-test-id="paymentReviewDeductedCommisionFormpayabletoinsurer"
                                id={`payable_to_insurer`}
                                className={classes.textInputStyle}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <div> &nbsp;SAR&nbsp;</div>
                                    </InputAdornment>
                                }
                                name={`fieldsData['flowFieldObj'][payable_to_insurer]`}
                                type={"text"}
                                disabled={true}
                            />
                            <ErrorMessage
                                name={`fieldsData['flowFieldObj'][payable_to_insurer]`}
                                className="field-error"
                                data-test-id="payable_to_insurer_error"
                                render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                            />
                        </React.Fragment>
                    </Grid>
                    <Grid style={{ paddingRight: "25px" }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t("payment_voucher_date")} />
                            <TextInput
                                data-test-id="paymentReviewDeductedCommisionFormpaymentdate"
                                id={`payment_voucher_date`}
                                className={classes.textInputStyle}
                                disabled={!this.props.isExpanded}
                                name={`fieldsData['flowFieldObj'][payment_voucher_date]`}
                                type={"date"}
                                onChange={(event: any) => this.props.deductedCommissionFeildsHandlers(event.target.value, "payment_voucher_date")}
                            />
                            <ErrorMessage
                                name={`fieldsData['flowFieldObj'][payment_voucher_date]`}
                                className="field-error"
                                data-test-id="payment_voucher_date_error"
                                render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                            />
                        </React.Fragment>
                    </Grid>

                    <Grid item xs={4} container style={{ marginTop: 37, marginBottom: 9, height: 56 }} >
                        <Button
                            data-test-id="paymentReviewDeductedCommisionFormCreatePaymentVoucher"
                            disabled={isDisabledPaymentVoucherBtn}
                            className={isDisabledPaymentVoucherBtn ? classes.grayBtn : classes.primaryRvBtn}
                            aria-haspopup="true"
                            aria-controls="customized-menu"
                            variant="contained"
                            color="primary"
                            onClick={() => this.createPaymentVoucherFlowFour()}
                        >
                            {!this.state.isPaymentVoucherFlowFourBtnLoading && t("create_payment_voucher")}
                            {this.state.isPaymentVoucherFlowFourBtnLoading && (<CircularProgress size={25} />)}
                        </Button>
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj'][payment_voucher_id]`}
                            className="field-error"
                            data-test-id="payment_voucher_button_error"
                            render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid item xs={12} container style={{ marginBottom: 20 }}>
                        <Grid item xs={4} style={{ paddingRight: "25px" }}>
                            <LabelWithIcon label={t("payment_voucher")} />

                            <FileUpload
                                disabled={true}
                                eachFileSizeInMB={10}
                                maxFilesNumber={5}
                                data-test-id="paymentReviewDeductedCommisionFormpaymentVoucher"
                                fileName={this.props.values.fieldsData?.flowFieldObj?.payment_voucher?.file_name || ""}
                                filePath={this.props.values.fieldsData?.flowFieldObj?.payment_voucher?.url || ""}
                                inputName={`proof_of_payment`}
                            />
                        </Grid>
                        <Grid style={{ paddingRight: "25px" }} item xs={4}>
                            <LabelWithIcon label={t("payment_voucher_id")} />
                            <TextInput
                                className={classes.textInputStyle}
                                disabled={true}
                                name={`fieldsData['flowFieldObj'][payment_voucher_id]`}
                                type="text"
                                data-test-id="payment_voucher_id"
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container style={{ marginBottom: 20 }}>
                        <Grid style={{ paddingRight: "25px" }} item xs={4}>
                            <LabelWithIcon label={t("insurer_receipt_number")} />
                            <TextInput
                                id={""}
                                className={classes.textInputStyle}
                                name={`fieldsData['flowFieldObj'][insurer_recipient_number]`}
                                type="text"
                                disabled={!this.props.isExpanded}
                                data-test-id="paymentReviewDeductedCommisionForminsurerrecipientnumber"
                                onChange={(event: any) => this.props.deductedCommissionFeildsHandlers(event.target.value, "insurer_recipient_number")}
                            />
                            <ErrorMessage
                                name={`fieldsData['flowFieldObj'][insurer_recipient_number]`}
                                className="field-error"
                                data-test-id="insurer_recipient_number_error"
                                render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                            />
                        </Grid>
                        <Grid style={{ paddingRight: "25px" }} item xs={4}>
                            <React.Fragment>
                                <LabelWithIcon label={t("payment_date")} />
                                <TextInput
                                    data-test-id="paymentReviewDeductedCommisionFormreceiptdate"
                                    disabled={!this.props.isExpanded}
                                    id={`payment_date`}
                                    className={classes.textInputStyle}
                                    name={`fieldsData['flowFieldObj'][payment_date]`}
                                    type={"date"}
                                    onChange={(event: any) => this.props.deductedCommissionFeildsHandlers(event.target.value, "payment_date")}
                                />
                                <ErrorMessage
                                    name={`fieldsData['flowFieldObj'][payment_date]`}
                                    className="field-error"
                                    data-test-id="payment_date_error"
                                    render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                                />
                            </React.Fragment>
                        </Grid>
                        <Grid item xs={4}>
                            <LabelWithIcon label={t("proof_of_payment")} />
                            <FileUpload
                                eachFileSizeInMB={10}
                                maxFilesNumber={5}
                                disabled={!this.props.isExpanded}
                                data-test-id="paymentReviewDeductedCommisionFormproofofpaymentvoucher"
                                fileName={this.props.values.fieldsData?.flowFieldObj?.proof_of_payment_voucher?.file_name || ""}
                                filePath={this.props.values.fieldsData?.flowFieldObj?.proof_of_payment_voucher?.url || ""}
                                inputName="fieldsData['flowFieldObj'][proof_of_payment_voucher]"
                                onChange={(event: any) => this.props.deductedCommissionFeildsHandlers(event.target.files[0], "proof_of_payment_voucher")}
                                onRemove={/*istanbul ignore next*/()=>{
                                    this.props.formikRef?.current?.setFieldValue("fieldsData['flowFieldObj'][proof_of_payment_voucher]",{})
                                    this.props.values.fieldsData?.flowFieldObj?.proof_of_payment_voucher?.id&&  this.removeFilePaymentReview(this.props.values.fieldsData?.flowFieldObj?.proof_of_payment_voucher.id)
                                    this.props.deductedCommissionFeildsHandlers({}, "proof_of_payment_voucher")
                                }}
                            />
                            <ErrorMessage
                                name="fieldsData['flowFieldObj'][proof_of_payment_voucher]"
                                className="field-error"
                                data-test-id="proof_of_payment_voucher_error"
                                render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.voucherBorder}>
                        <LabelWithIcon label={t("policy_release_requested")} />
                        <FormControlLabel
                            color="primary"
                            name={`fieldsData['flowFieldObj']['policy_release_requested']`}
                            control={<Checkbox color="primary"
                                disabled={!this.props.isExpanded}
                                checked={this.props.values.fieldsData['flowFieldObj']['policy_release_requested'] === "yes"}
                                onChange={(event: any) => this.props.deductedCommissionFeildsHandlers(event.target.checked, "policy_release_requested")}
                            />
                            }
                            label={t('yes')}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['policy_release_requested']`}
                            className="field-error"
                            data-test-id="policy_release_requested_error"
                            render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 115 }}>
                    <Typography>{t("quotation_amount")}</Typography>
                    <Typography>{`SAR ${this.props.quotation_amount}`}</Typography>
                </Grid>
                <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                    <Typography>{t("revenue_siib_commission_from")} {this.state.insurer_name}</Typography>
                    <Typography>{`-SAR ${this.state.isShownComissionAmountFlowFour && this.props.deductedCommissionFields.commission_amount !== "" ? Number(this.props.deductedCommissionFields.commission_amount) : 0}`}</Typography>
                </Grid>
                <Grid item xs={12} container style={{ display: "flex", justifyContent: "space-between", marginBottom: 15, marginTop: 20 }}>
                    <Typography>{t("payment_made_to_insurer")}</Typography>
                    <Typography>{`-SAR ${this.state.isShownPayableToInsurerFlowFour && this.props.deductedCommissionFields.payable_to_insurer !== "" ? this.props.deductedCommissionFields.payable_to_insurer : 0} `}</Typography>
                </Grid>
            </>
        );
    }
}

const useStyles: any = {
    bottomAlignment: {
        marginBottom: "20px",
    },
    grayBtn: {
        textTransform: "none",
        borderRadius: 5,
        width: "100%",
        fontSize: 17,
        backgroundColor: "#979090",
        color: "white",
    },
    primaryRvBtn: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        textTransform: "none",
        borderRadius: 5,
        width: "100%",
        fontSize: 17,
        backgroundColor: "#979090",
        color: "white",
    },
    displayFlex: {
        display: "flex",
    },
    contactForm: { width: "100%" },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    voucherBorder: {
        paddingBottom: "25px",
        borderBottom: "3px solid #eaeaea",
    },
    borderAlignment: {
        paddingTop: "25px",
        borderTop: "3px solid #eaeaea",
    },
    installmentAlignment: {
        border: "3px solid #eaeaea",
        borderRadius: "10px",
        padding: "20px",
    },
    installmentErrorAlignment: {
        border: "1px solid #f44336",
        borderRadius: "10px",
        padding: "20px",
    },
};
export default withStyles(useStyles)(
    withTranslation()(PaymentReviewDeductedCommisionForm)
);
