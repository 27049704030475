import React, { useEffect, useRef, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import LabelWithIcon from '../Common/LabelWithIcon'
import TextInput from '../Common/TextInput';
import { useTranslation } from 'react-i18next';
import MultiSelectFormik from '../Common/MultiSelectFormik';
import ConfirmationDialog from '../Common/ConfirmationDialog.web';

const useStyles = makeStyles({
    formWrapper: {
        backgroundColor: '#fff',
        padding: '30px',
        width: '100%',
        '& legend': {
            textAlign: 'left',
            marginBottom: '10px',
            display: 'flex',
            fontSize: '14px',
            // color: '#d0d0d0',
            color: '#3d3d3d',
            '& svg': {
                fontSize: '15px',
                color: '#222',
                marginLeft: '6px',
            }
        },
        '& label':{
            color: '#3d3d3d !important',
        }
    },
    selectStyle: {
        '& .css-3w2yfm-ValueContainer > div': {
            backgroundColor: '#cbdeec',
        },
        '$ .css-1p3m7a8-multiValue':{
            backgroundColor: '#cbdeec !important',
        },
        '& .css-3w2yfm-ValueContainer > div:last-child': {
            backgroundColor: '#fff',
        }
    },
    selectError: {
        '& > div': {
            // height: '56px',
            borderColor: 'red',
            backgroundColor: '#fff',
        }
    },
    lineSaprator: {
        backgroundColor: '#e2e2e2',
        height: '1.3px',
        width: '100%',
        margin: '18px 0px'
    },
    submitBtn: {
        height: 'max-content',
        backgroundColor: '#45a3ad',
        textTransform: 'capitalize',
        '&:hover':{
            backgroundColor: '#45a3ad',
        }
    },
    dialogTitle:{
        textAlign: 'center',
        '& > h2':{
            fontWeight: '700',
        }
    },
    dialogBtnWrapper:{
        justifyContent: 'center',
        marginBottom: '15px',
        '@media(min-width: 500px)':{
            '& button': {
                padding: '12px 78px',
            }
        },
    },
    dialogBtnCancel:{
        color: 'rgb(0 0 0 / 54%)',
        textTransform: 'capitalize',
    },
    dialogBtnSave:{
        backgroundColor: '#45a3ad',
        textTransform: 'capitalize',
        borderColor: '#45a3ad',
        color: '#fff',
        "&:hover":{
            backgroundColor: '#45a3ad',
        }
    },
    selectRemoveicon:{
        '& [role="button"]':{
            display: 'none'
        }
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    insuranceTypeStyle: {
        "padding-bottom": "0px !important"
    },
    saveBtnDiv: {
        marginTop: "35px",
        display:"flex",
        justifyContent:"center"
    },
    saveBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "height": "55px",
        "width": "150px",
        borderRadius: "10px"
    },
});

const typesOfInsuranceOptions: any = [
    { value: 'Medical', label: 'Medical' },
    { value: 'Motor', label: 'Motor' },
    { value: 'Energy', label: 'Energy' },
    { value: 'Life', label: 'Life' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Marine', label: 'Marine' },
    { value: 'Property', label: 'Property' },
    { value: 'Fire', label: 'Fire' },
    { value: 'Aviation', label: 'Aviation' },
    { value: 'Protection & Saving', label: 'protection and saving' },
    { value: 'General Business', label: 'General Bussiness' },
]

const enumFormAction = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
}


type InsurerOverviewProps = {
    insuranceTypes: (data: string[] | null) => void,
    data:any,
    formType: string,
}

const InsurerOverview: React.FC<InsurerOverviewProps> = (props: InsurerOverviewProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const getSegmentOption = () => {
        return [
            { value: 'Mega KA', label: t('mega_ka') },
            { value: 'Corporate', label: t('corporate') },
            { value: 'SME', label: t('sme') },
            { value: 'Government', label: t('government') },
        ]
    }
    const [typesOfInsuranceDefaultOptions, setTypesOfInsuranceDefaultOptions] = useState(typesOfInsuranceOptions)
    const [selectDisable, setSelectDisable] = useState(false)
    const [showErrorTypesOfInsurance, setShowErrorTypesOfInsurance] = useState(false)
    const [open, setOpen] = useState(false);
    const [loading, setLoading] =  useState(false);
    const [formValues, setFormValues] = useState<any>(null)

    const initialValues = {
        insurer_id: "",
        legal_name: "",
        short_name: '',
        address: '',
        vat_number: "",
        bank_name: '',
        swift_code: "",
        account_number: '',
        iban: "",
        segment:"",
        types_of_insurance:""
    }
      


    const validate = () => {
        const isArabicLanguage = window.localStorage.getItem('lang') === "en" ?  false : true;
        return Yup.object().shape({
            legal_name: Yup.string()
            .min(2, !isArabicLanguage ? "Legal Name is too short" : "الاسم الأخير قصير جدًا")
            .max(20, !isArabicLanguage ? "Legal Name name is too long" : "الاسم الأخير طويل جدًا")
            .required(isArabicLanguage ? "الاسم القانوني مطلوب"  : "Legal name is required."),
            short_name: Yup.string()
            .min(1, !isArabicLanguage ? "Short Name is too short" : "الاسم الأخير قصير جدًا")
            .max(20, !isArabicLanguage ? "Short Name name is too long" : "الاسم الأخير طويل جدًا")
            .required(isArabicLanguage ? "مطلوب اسم قصير"  : "Short name is required."),
            address: Yup.string()
            .min(1, !isArabicLanguage ? "Address is too short" : "الاسم الأخير قصير جدًا")
            .max(50, !isArabicLanguage ? "Address name is too long" : "الاسم الأخير طويل جدًا")
            .required(isArabicLanguage ? "العنوان مطلوب" : "Adress is required."),
            vat_number: Yup.string().required(isArabicLanguage ?  "رقم ضريبة القيمة المضافة مطلوب" : "VAT number is required."),
            bank_name: Yup.string()
            .min(1, !isArabicLanguage ? "Bank Name is too short" : "الاسم الأخير قصير جدًا")
            .max(20, !isArabicLanguage ? "Bank Name name is too long" : "الاسم الأخير طويل جدًا")
            .required(isArabicLanguage ? "اسم البنك مطلوب": "Bank name is required."),
            swift_code: Yup.string()
            .min(1, !isArabicLanguage ? "Swift code is too short" : "الاسم الأخير قصير جدًا")
            .max(20, !isArabicLanguage ? "Swift code name is too long" : "الاسم الأخير طويل جدًا")
            .required(isArabicLanguage ? "مطلوب رمز سويفت" : "Swift code is required."),
            account_number: Yup.string().required(isArabicLanguage ? "رقم الحساب مطلوب": "Account number  is required."),
            iban: Yup.string()
            .min(1, !isArabicLanguage ? "IBAN Name is too short" : "الاسم الأخير قصير جدًا")
            .max(20, !isArabicLanguage ? "IBAN Name name is too long" : "الاسم الأخير طويل جدًا")
            .required(isArabicLanguage ? "مطلوب رقم الحساب المصرفي الدولي" : "IBAN is required."),
            segment: Yup.array().min(1, isArabicLanguage ? "الرجاء تحديد جزء واحد على الأقل" : "Please select at least one segment").required(isArabicLanguage ? 'الرجاء تحديد جزء واحد على الأقل' : "Please select atleast one segment."),
            types_of_insurance: Yup.string().required(isArabicLanguage ? 'نوع التأمين مطلوب' : "Type of insurance is required.")
        })
    }

    const handleCancel = () => {
        setOpen(false);
    };

    const handleSave = () => {
        // setLoading(true);
        setSelectDisable(!selectDisable)
        setOpen(false);
        props.insuranceTypes(formValues)  
    }

    return (
        <>

            <Formik
                data-test-id="addEditInsurerFormOverview"
                initialValues={props.data ? props.data : initialValues}
                enableReinitialize={true}
                validateOnMount={true}
                validateOnBlur={true}
                validationSchema={validate}
                onSubmit={(values) => {
                    setSelectDisable(!selectDisable)  
                    setOpen(true);
                    setFormValues(values);
                }}
            >
                {({
                    values,
                    setFieldValue,
                    handleSubmit,
                    touched,
                    errors,
                    validateForm
                }) => {
                    return (
                        <React.Fragment>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('insurer_id')} tooltip={t('this_is_an_internal_ID_that_is_generated_once_you_save_this_form') as string} />
                                    <TextInput
                                        id={"insurer_id"}
                                        className={classes.textInputStyle}
                                        isArabicLanguage={false}
                                        name="insurer_id"
                                        type="text"
                                        value={values.insurer_id || ''}
                                        disabled={true}
                                        data-test-id="insurer_id"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('legal_name')} tooltip={t('registered_name_of_the_insurance_company') as string} />
                                    <TextInput
                                        id={""}
                                        className={classes.textInputStyle}
                                        isArabicLenguage={false}
                                        name="legal_name"
                                        type="text"
                                        data-test-id="legal_name"
                                        disabled={props.formType === enumFormAction.VIEW}
                                        error={touched.legal_name && Boolean(errors.legal_name)}
                                        inputProps={
                                            { readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true, }
                                        }
                                    />
                                    {touched.legal_name && errors.legal_name ? (
                                        <Typography color="error" >{errors.legal_name}</Typography>
                                    ) : null}
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('short_name')}/>
                                    <TextInput
                                        id={""}
                                        className={classes.textInputStyle}
                                        isArabicLenguage={false}
                                        name="short_name"
                                        type="text"
                                        data-test-id="short_name"
                                        disabled={props.formType === enumFormAction.VIEW}
                                        error={touched.short_name && Boolean(errors.short_name)}
                                        helperText={touched.short_name && errors.short_name === ""}
                                        inputProps={
                                            { readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true, }
                                        }
                                    />
                                    {touched.short_name && errors.short_name ? (
                                        <Typography color="error" >{errors.short_name}</Typography>
                                    ) : null}
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('address')} tooltip={t('full_address') as string} />
                                    <TextInput
                                        id={""}
                                        className={classes.textInputStyle}
                                        isArabicLenguage={false}
                                        name="address"
                                        type="text"
                                        data-test-id="address"
                                        error={touched.address && Boolean(errors.address)}
                                        helperText={touched.address && errors.address === ""}
                                        disabled={props.formType === enumFormAction.VIEW}
                                        inputProps={
                                            { readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true, }
                                        }
                                    />
                                    {touched.address && errors.address ? (
                                        <Typography color="error" >{errors.address}</Typography>
                                    ) : null}
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('vat_number')}/>
                                    <TextInput
                                        id={""}
                                        className={classes.textInputStyle}
                                        isArabicLenguage={false}
                                        name="vat_number"
                                        type="text"
                                        disabled={props.formType === enumFormAction.VIEW}
                                        data-test-id="vat_number"
                                        error={touched.vat_number && Boolean(errors.vat_number)}
                                        helperText={touched.vat_number && errors.vat_number === ""}
                                        inputProps={
                                            { readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true, }
                                        }
                                    />
                                    {touched.vat_number && errors.vat_number ? (
                                        <Typography color="error" >{errors.vat_number}</Typography>
                                    ) : null}
                                </Grid>
                            

                            <div className={classes.lineSaprator}></div>

                                <Grid xs={4} item>
                                    <LabelWithIcon label={t('bank_name')}/>
                                    <TextInput
                                        id={""}
                                        className={classes.textInputStyle}
                                        isArabicLenguage={false}
                                        name="bank_name"
                                        type="text"
                                        data-test-id="bank_name"
                                        error={touched.bank_name && Boolean(errors.bank_name)}
                                        helperText={touched.bank_name && errors.bank_name === ""}
                                        disabled={props.formType === enumFormAction.VIEW}
                                        inputProps={
                                            { readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true, }
                                        }
                                    />
                                    {touched.bank_name && errors.bank_name ? (
                                        <Typography color="error" >{errors.bank_name}</Typography>
                                    ) : null}
                                </Grid>
                                <Grid xs={4} item>
                                    <LabelWithIcon label={t('swift_code')} tooltip={t('swift_code_tooltip') as string}/>
                                    <TextInput
                                        id={""}
                                        className={classes.textInputStyle}
                                        isArabicLenguage={false}
                                        name="swift_code"
                                        type="text"
                                        data-test-id="swift_code"
                                        error={touched.swift_code && Boolean(errors.swift_code)}
                                        helperText={touched.swift_code && errors.swift_code === ""}
                                        disabled={props.formType === enumFormAction.VIEW}
                                        inputProps={
                                            { readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true, }
                                        }
                                    />
                                    {touched.swift_code && errors.swift_code ? (
                                        <Typography color="error" >{errors.swift_code}</Typography>
                                    ) : null}
                                </Grid>
                                <Grid xs={4} item>
                                    <LabelWithIcon label={t("account_number")}/>
                                    <TextInput
                                        id={""}
                                        className={classes.textInputStyle}
                                        isArabicLenguage={false}
                                        name="account_number"
                                        type="text"
                                        data-test-id="account_number"
                                        disabled={props.formType === enumFormAction.VIEW}
                                        error={touched.account_number && Boolean(errors.account_number)}
                                        helperText={touched.account_number && errors.account_number === ""}
                                        inputProps={
                                            { readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true, }
                                        }
                                    />
                                    {touched.account_number && errors.account_number ? (
                                        <Typography color="error" >{errors.account_number}</Typography>
                                    ) : null}
                                </Grid>
                                <Grid xs={4} item>
                                    <LabelWithIcon label={t('iban')} tooltip={t('IBAN_tooltip') as string} />
                                    <TextInput
                                        id={""}
                                        className={classes.textInputStyle}
                                        isArabicLenguage={false}
                                        name="iban"
                                        type="text"
                                        data-test-id="iban"
                                        disabled={props.formType === enumFormAction.VIEW}
                                        error={touched.iban && Boolean(errors.iban)}
                                        helperText={touched.iban && errors.iban === ""}
                                        inputProps={
                                            { readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true, }
                                        }
                                    />
                                    {touched.iban && errors.iban ? (
                                        <Typography color="error" >{errors.iban}</Typography>
                                    ) : null}
                                </Grid>

                            <div className={classes.lineSaprator}></div>

                                <Grid item xs={12} sm={12}>
                                    <LabelWithIcon label={t('segment')} tooltip={t('to_define_the_size_of_the_company') as string} />
                                    <MultiSelectFormik
                                        value={values.segment}
                                        isMulti={true}
                                        name='segment'
                                        disabled={props.formType === enumFormAction.VIEW}
                                        // @ts-ignore
                                        onChange={(newValue: any) => setFieldValue(`segment`, newValue.value)}
                                        options={getSegmentOption()}
                                    />
                                    {touched.segment && errors.segment ? (
                                        <Typography color="error" >{errors.segment}</Typography>
                                    ) : null}
                                </Grid>

                            <div className={classes.lineSaprator}></div>
                            
                                <Grid className={classes.insuranceTypeStyle} item xs={selectDisable ? 12 :10}>
                                    <LabelWithIcon label={t('types_of_insurance')} tooltip={t('select_all_insurance_types_offered_by_this_company') as string} />
                                    <MultiSelectFormik
                                        value={values.types_of_insurance}
                                        isMulti={true}
                                        name='types_of_insurance'
                                        disabled={selectDisable || props.formType === enumFormAction.VIEW}
                                        // @ts-ignore
                                        onChange={(newValue: any) => setFieldValue(`types_of_insurance`, newValue.value)}
                                        options={typesOfInsuranceDefaultOptions}
                                    />
                                    {touched.types_of_insurance && errors.types_of_insurance ? (
                                        <Typography color="error" >{errors.types_of_insurance}</Typography>
                                    ) : null}
                                </Grid>
                                {!selectDisable && props.formType !== enumFormAction.VIEW && (
                                <Grid item xs={2} className={classes.saveBtnDiv} >

                                    <Button disabled={selectDisable || props.formType === enumFormAction.VIEW} onClick={() => {
                                        validateForm()
                                        handleSubmit();
                                    }} className={classes.saveBtnStyle}>{t('save')}
                                    </Button>
                                </Grid>
                                )}
                            </Grid>
                        </React.Fragment>
                    );
                }}
            </Formik>


            <ConfirmationDialog
                openDialog={open}
                btnLoading={loading}

                // Get static codes
                headingText={t('save_insurance_type_heading')}
                paragraphText={t('save_insurance_type_sub_title')}
                btnCancelText={t('cancel')}
                btnOkText={t('save')}

                // Call funcations on button click
                handleCancel={() => handleCancel()}
                handleOk={() => handleSave()}
                showRedButton={false}
            />
        </>
    )
}

export default InsurerOverview