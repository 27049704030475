import React from "react";
// Customizable Area Start
import {
    Container,
    Grid,
    ListSubheader,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import {FormControlLabel,Switch, Tab, Tabs} from "@material-ui/core"
import DashboardWebController from "./DashboardWebController";
import PaginatedTable from "../../../components/src/Common/Table/PaginatedTable";
import {  REQUEST_LIST_TABLE_BODY,TASK_lIST_TABLE_COLUMNS,OVERDUE_PAYMENTS_COLUMNS } from "../../../components/src/Common/Table/TableColumns";
import { folder_icon } from "./assets";
import CustomButton from "../../../components/src/Common/CustomButton";
const configJson=require("./config.js");
import TaskAllocator from '../../TaskAllocator/src/taskAllocator.web';
import Toster from "../../../components/src/Common/Toster";

import { getLoggedUserRole } from "../../../components/src/Common/GetLoggedUserData";
// Customizable Area End


// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

// const ButtonNames = [{title:"All tasks",filterKey:"",filterIndex:"0"}, {title:"My tasks",filterKey:"my_tasks",filterIndex:"1"}, {title:"Department tasks",filterKey:"department_tasks",filterIndex:"2"}, {title:"Tasks for others",filterKey:"task_for_others",filterIndex:"3"}];


// Customizable Area End

export class Dashboard extends DashboardWebController {

    // Customizable Area Start
    TargetGraphBox = ({data, dataType}: {
        data : {
            title: string;
            total: number;
            completed: number;
            completed_per: number;
        }[],
        dataType: string,
    }) => {
        return (
            <div className={this.props.classes.targetsGraphBox}>
                {data && data.length > 0 && <Typography className={this.props.classes.progressTitle}>{dataType}</Typography>}
                {data && data.length > 0 ? data.map((el: any) => {
                    return (
                        <div key={el.title} className={this.props.classes.progressBox} >
                            <div className={this.props.classes.progressTitleMain} >
                                <Typography>{this.props.t(el.title)}</Typography>
                                <Typography className={el.completed_per == 100 ? this.props.classes.progressDetail : this.props.classes.progressDetailSuccess} >{`${el.completed}/${el.total} (${el.completed_per}%)`}</Typography>
                            </div>
                            <div className={this.props.classes.wrapper}>
                                <div className={this.props.classes.barContainer}>
                                    <div className={el.completed_per === 100 ? this.props.classes.fillerFilled : this.props.classes.filler} style={{ width: `${el.completed_per}%` }} />
                                </div>
                            </div>
                        </div>
                    )})
                : <div className={this.props.classes.noTargetsMain} >
                    <img className={this.props.classes.noTargetsImg} src={folder_icon} alt="" />
                    <Typography className={this.props.classes.noTargetsDesc}>{this.props.t('no_targets_assigned')}</Typography>
                </div>
                }
            </div>

        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { t, navigation } = this.props;
        const { classes } = this.props;

        const renderOptions = (options: { id: number; name: string }[]) => {
          return options.map(option => (
            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
          ));
        };


        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme} >
              <Toster
								data-test-id="toaster"
								tosterText={this.state.alertMessage}
								showToster={this.state.showAlert}
								tosterType={this.state.alertType}
								handleCloseToster={this.handleCloseToaster}
						  />
                    <Container maxWidth="xl">
            <div>
                <div className={classes.targetsMain}>
                    <div className={classes.targetsTopMain} >
                        <div>
                            <Typography className={classes.targetTitle}>{t('target')}</Typography>
                        </div>
                        <div>
                        <Select data-test-id="target-filter-input" variant="outlined" className={classes.selectBoxControl} value={this.state.selectedGraphOption} onChange={(e)=>{this.filterTask(e.target.value as string)}}>
                          {this.state.targetGraphOptionData?.current_period?.length > 0 && [
                            <ListSubheader key="current_period" className={classes.ListSubheaderText}>{t("current_period")}</ListSubheader>,
                            renderOptions(this.state.targetGraphOptionData.current_period)
                          ]}
                          {this.state.targetGraphOptionData?.past_period?.length > 0 && [
                            <ListSubheader key="past_period" className={classes.ListSubheaderText}>{t("past_period")}</ListSubheader>,
                            renderOptions(this.state.targetGraphOptionData.past_period)
                          ]}
                          {this.state.targetGraphOptionData?.future_period?.length > 0 && [
                            <ListSubheader key="future_period" className={classes.ListSubheaderText}>{t("future_period")}</ListSubheader>,
                            renderOptions(this.state.targetGraphOptionData.future_period)
                          ]}
                          {(!this.state.targetGraphOptionData?.current_period?.length &&
                            !this.state.targetGraphOptionData?.past_period?.length &&
                            !this.state.targetGraphOptionData?.future_period?.length) && (
                            <MenuItem disabled>
                              {t("no_targets")}
                            </MenuItem>
                          )}
                        </Select>
                    
                        

                        </div>
                    </div>
                    <div className={classes.targetsGraphMain}>
                        {this.TargetGraphBox({data:this.state.targetGraphData.personalData, dataType:t('personal')})}
                        {this.TargetGraphBox({data:this.state.targetGraphData.departmentalData, dataType:t('departmental')})}
                    </div>
                </div>
            <div className={classes.requestTableListing}>
                        <Grid className={classes.requestTable} item xs={12}>
                            <div className={classes.requestTableTitle}>
                                {t('my_requests')}
                            </div>
                            <PaginatedTable
                                key={'all'}
                                curretPage={this.state.requestTablePage}
                                totalPage={this.state.requestTableListMeta.total_page}
                                perPage={this.state.requestTableRowPerPage}
                                pageChange={this.handleRequestTablePageChange}
                                handleRowPerPage={this.handleRequestTableRow}
                                tableFor={'policy'}
                                tableData={this.state.requestTableList}
                                tableColumn={REQUEST_LIST_TABLE_BODY}
                                path={'/ViewPolicy'}
                            />
                        </Grid>
                </div>
             <div className={classes.taskTable}>
                  <div className={classes.requestTableTitle}>
                    {t("tasks")}
                    <div className={classes.displayFlexEnd}>
                      <FormControlLabel
                        value={this.state.showCompletedTask}
                        control={<Switch className={classes.switch} data-test-id="completeTaskswitch" color="primary" onChange={this.getCompleteTasks}/>}
                        label={t('completed_tasks')}
                        labelPlacement="start"
                      />
                      <div>
                        <CustomButton
                          btnText={t("create_task")}
                          onClick={() => this.handleTaskDialog()}
                          bgColor={"linear-gradient(to bottom, #2d6f8f, #4fb7ba)"}
                          textColor={"#fff"}
                          data-test-id="create_tasks_button"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={classes.filterDiv}
                  >
                    <span>{t('filter_by')}</span>
                    <Tabs
                      value={this.state.activeFilterTab}
                      onChange={this.handleTabChange}
                      TabIndicatorProps={{
                        style: { display: "none" },
                      }}
                      data-test-id="tabsDiv"
                    >
                      {configJson.ButtonNames.map((btn:any, index:number) => (
                        <Tab
                          key={index}
                          className={
                            this.state.activeFilterTab === index
                              ? classes.activeTab
                              : classes.filterTab
                          }
                          label={t(btn.title)}
                        />
                      ))}
                    </Tabs>
                  </div>
                  {configJson.ButtonNames.map((name:any, index:number) => (
                    <div
                      key={index}
                      role="tabpanel"
                      id={`scrollable-force-tabpanel-${index}`}
                      aria-labelledby={`scrollable-force-tab-${index}`}
                    >
                      <div
                        key={index}
                        role="tabpanel"
                        id={`scrollable-force-tabpanel-${index}`}
                        aria-labelledby={`scrollable-force-tab-${index}`}
                      >
                        {this.state.activeFilterTab === index && (
                          <PaginatedTable
                            data-test-id="task_table"
                            key={"all"}
                            curretPage={this.state.taskTablePage}
                            totalPage={
                              this.state.taskTableListMeta.total_page
                            }
                            perPage={this.state.taskTableRowPerPage}
                            pageChange={this.handleTaskTablePageChange}
                            handleRowPerPage={this.handleTaskTableRow}
                            tableFor={"task"}
                            tableData={this.state.tasksList}
                            tableColumn={TASK_lIST_TABLE_COLUMNS}
                            // path={"/"}
                            archiveList={this.deleteTaskData}
                            isLoading={this.state.taskTableLoading}
                            onRowClick={this.handleTaskIdDialog}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                  <TaskAllocator
                      navigation={navigation}
                      id={'create_task'}
                      update={(!!this.state.taskId)}
                      taskId={this.state.taskId}
                      showTaskDialog={this.state.showTaskDialog}
                      handleTaskDialog={this.handleTaskDialog}
                      handleTaskIdDialog={this.handleTaskIdDialog}
                      getAllTasksList={this.getTaskList}
                  />
          </div>


      {getLoggedUserRole()==="Sales" &&  
      <div className={classes.taskTable}>
                <Grid  xs={12}>
                            <div className={classes.requestTableTitle}>{t('overdue_payments')}</div>
                            <PaginatedTable
                            path={"/CustomersDetail"}
                            curretPage={this.state.overDuePaymentPage}
                            key={"all"}
                            perPage={this.state.overdueTableRowPerPage}
                            totalPage={this.state.overdueTableListMeta.total_page}
                            handleRowPerPage={this.handleOverdueTableRow}
                            pageChange={this.handleOverdueTablePageChange}
                            tableData={this.state.overDuePlaymentList}
                            tableFor={'overdue_payment'}
                            tableColumn={OVERDUE_PAYMENTS_COLUMNS}
                            isLoading={this.state.isOverdueLoading}
                          />
                        </Grid>
          </div>}
          </div>
            </Container>
            </ThemeProvider>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const useStyles = ({
    requestTableTitle: {
        display: "flex",
        marginBottom: '15px',
        marginTop: '15px',
        gap: '20px',
        justifyContent: "space-between",
        alignItems: "center",
        fontSize:"20px",
        "font-weight":"800"
    },
    selectBoxControl: {
      minWidth: 200,
    },
    ListSubheaderText: {
      fontSize: "15px",
      "font-weight":"600",
      color: "black",
    },
    requestTableListing:{
        marginBottom:"20px"
    },
    requestTable:{
        borderRadius:"10px",
        backgroundColor:"white",
        marginBottom:"20px",
        padding: "10px 20px",

    },
    displayFlexEnd: {
      display: "flex",
      justifyContent: "end",
      margin: "16px 0",
      gap: "10px",
    },
    filterTab: {
      background: "#fff",
      minWidth: "155px",
      height: "51px",
      borderStyle: "none",
      borderRadius: "25px",
      border: "solid 1px rgba(0, 0, 0, 0.3) !important",
      boxShadow: "none",
      marginRight: "20px",
      color: "rgba(0, 0, 0, 0.3)",
      fontWeight: 400,
      padding: "8px 15px",
      "text-transform": "none",
    },
    activeTab: {
      background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
      minWidth: "155px",
      height: "51px",
      borderStyle: "none",
      borderRadius: "25px",
      borderColor: "#2d6f8f",
      boxShadow: "none",
      marginRight: "20px",
      color: "#FFFF",
      fontWeight: 400,
      padding: "8px 15px",
      "text-transform": "none",
    },
    filterDiv: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
      padding: "10px",
      marginBottom: "20px",
    },
    customSwitch: {
      '& .MuiSwitch-thumb': {
        width: 24,
        height: 24,
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#00ff00',
      }
    },
    taskTable:{
        backgroundColor:"white",
        padding: "10px 20px",
        marginBottom:"20px",
        marginTop:"20px",
        borderRadius:"10px"
    },
    targetsMain: {
        background: "white",
        padding: '10px 20px 20px 20px',
        borderRadius: '10px',
        marginTop:"25px",
        marginBottom:"25px",
        "box-shadow": "0 0px 2px lightgray",
    },
    targetsTopMain: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    targetTitle: {
        fontSize: '20px',
        "font-weight":"800",
    },
    targetFilter:{
        width: '300px'
    },
    targetsGraphMain: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: '20px',
        marginTop: '10px',
    },
    targetsGraphBox: {
        border: '1px solid grey',
        width: '100%',
        padding: '25px 20px 20px 20px',
        borderRadius: '10px',
    },
    progressBox: {
        marginBottom: '25px',
    },
    progressTitleMain: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    progressTitle: {
        marginBottom: '25px',
        'font-weight': 'bold'
    },
    progressDetail: {
        color: 'black'
    },
    progressDetailSuccess: {
        color: 'grey'
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    barContainer: {
        flex: '1',
        height: '8px',
        background: '#dfdfe7',
        borderRadius: '12px',
    },
    filler: {
        height: '100%',
        borderRadius: 'inherit',
        background: 'linear-gradient(to left, #2d6f8f, #4fb7ba)',
    },
    fillerFilled: {
        height: '100%',
        borderRadius: 'inherit',
        background: '#028f5d',
    },
    textValue: {
        marginLeft: '8px',
        width: '42px',
        'text-align': 'right',
    },
    noTargetsMain: {
        width: '100%',
        height: '100%',
        display: 'flex',
        'flex-direction': 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'grey',
    },
    noTargetsImg: {
        width: '130px',
        height: '130px',
    },
    noTargetsDesc: {
        marginTop: '10px',
    },
});

// Customizable Area End
export default withStyles(useStyles)(withTranslation()(Dashboard));

