// Customizable Area Start
import React from 'react';
import { makeStyles, Grid, Button, TextField, Accordion, AccordionDetails, AccordionSummary, Typography, InputAdornment, Snackbar } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
// import CustomDataTable from '/Components/CustomDataTable'
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import InsurerOverview from '../../../components/src/Insurer/InsurerOverview.web'
import SiibCommissionRate from '../../../components/src/Insurer/SiibCommissionRate.web'
import UploadAttachedment from '../../../components/src/Insurer/UploadAttachedment.web'
import LabelWithIcon from '../../../components/src/Common/LabelWithIcon'
import InsurerController, {Props} from '../../../../packages/blocks/landingpage/src/InsurerAddController';
import { Alert } from '@material-ui/lab';
import ConfirmationDialog from '../../../components/src/Common/ConfirmationDialog.web'
import MedicalJSX from '../../../components/src/Insurer/MedicalJSX'
import MotorJSX from '../../../components/src/Insurer/MotorJSX'
import ContactJSX from '../../../components/src/Insurer/ContactJSX'
import { withTranslation } from 'react-i18next';



export const useStyles: any = (theme: any) => ({
    accordionWrapper: {
        padding: '0px 15px',
        '& .MuiAccordion-root': {
            backgroundColor: '#fff',
            margin: '15px 0px'
        }
    },
    btnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "color": "white",
        // "height": "50px",
        borderRadius: "10px",
        paddingLeft: "10px",
        paddingRight: "10px",
        textTransform: 'capitalize',
    },
    headerButtonStyle: {
        display: "flex",
        justifyContent: "end"
    },
    dialogBtnWrapper: {
        justifyContent: 'center',
        marginBottom: '15px',
        textAlign: 'right',
        '@media(min-width: 150px)': {
            '& button': {
                "padding": "15px",
                "width": "150px"
            }
        },
    },
    dialogBtnCancel: {
        color: 'rgb(0 0 0 / 54%)',
        textTransform: 'capitalize',
        margin: '0px 15px',
        borderRadius: '15px',
    },
    dialogBtnSave: {
        backgroundColor: '#45a3ad',
        textTransform: 'capitalize',
        borderColor: '#45a3ad',
        color: '#fff',
        "&:hover": {
            backgroundColor: '#45a3ad',
        }
    },
    addCmp: {
        '& > div': {
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 700,
        },
        '& > p': {
            color: 'rgba(0, 0, 0, 0.87)',
        }
    },
    accordionDetailsWrapper: {
        display: 'block',
        textAlign: 'right',
        '& > svg': {
            color: '#2f2f2f',
            cursor: 'pointer'
        }
    },
    accordionStyle: {
        background: "white !important",
        "border-radius": "10px !important",
    },
    lineSaprator: {
        backgroundColor: '#e2e2e2',
        height: '1.3px',
        width: '100%',
        margin: '18px 0px'
    },
    inputFile: {
        // '& input[type="file"]::file-selector-button':{
        //     display: 'none',
        // },
        color: '#7f7f7f',
        '& fieldset': {
            border: '1px dashed rgba(0, 0, 0, 0.23) !important'
        },
        '& > div': {
            height: '40px',
            overflow: 'hidden',
        },
        '& input': {
            opacity: '0',
            position: 'absolute',
            left: '0px',
            right: '0px',
            maxWidth: '75%',
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 'auto',
            position: 'absolute',
            right: '0px',
            '& > *': {
                background: '#fff',
            }
        }
    },
    errorstyle: {
        border: '1px solid red !important',
        background: "white !important",
        "border-radius": "10px !important",
    },
    editWrapper: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '15px',
        paddingTop: '15px',
        '.MuiGrid-root.MuiGrid-item':{
            fontWeight: 600,
        },
        '& button': {
            padding: '15px 0px',
            width: '150px',
            borderColor: '#3b8da2',
            borderRadius: '15px',
            textTransform: 'capitalize',
        }
    },
    editBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white"
    },
    accordianHeading: {
        fontWeight: 600,
    },
})
const useStylesFunction = makeStyles({
    accordianHeading: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center'
    },
    lockedAccordion: {
        padding: '6px 10px',
        backgroundColor: '#e9f4f9',
        border: '1px solid #ccc',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 8px',
        '& svg': {
            marginRight: '6px',
        }
    },
})

const enumRoles = {
    sales: 'Sales'
}

const enumFormAction = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
}

type accordionTitleProps = {
    title: string,
    insuranceData: any,
    t: any,
    insurerId?: string
}


const AccordionTitle = (props: accordionTitleProps) => {
    const classes = useStylesFunction()
    const  {t} = props
    
    return (
        <Typography className={classes.accordianHeading}>
            {props.title}
            {
                !props.insurerId && props.insuranceData === null && (
                    <div className={classes.lockedAccordion}>
                        <LockIcon /> {t("please_select_insurance_type_to_unlock_this_fields")}
                    </div>
                )
            }

        </Typography>
    )
}

class InsurerContant extends InsurerController {
    constructor(props: Props) {
        super(props);
    }



    render() {
        // @ts-ignore
        const { t, classes, } = this.props;
        const allowedFileTypes = ['.jpg', '.jpeg', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.csv']
        return (
            <>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} key={'top' + 'right'} open={this.state.showToster} autoHideDuration={3000} onClose={() => this.setState({ showToster: false })}>
                    <Alert onClose={() => this.setState({ showToster: false })} severity="error">
                        {this.state.showTosterText}
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} key={'top' + 'right'} open={this.state.showSuccessToster} autoHideDuration={3000} onClose={() => this.setState({ showSuccessToster: false })}>
                    <Alert onClose={() => this.setState({ showSuccessToster: false })} severity="success">
                        {this.state.successTosterText}
                    </Alert>
                </Snackbar>

                        <Grid container className={classes.editWrapper}>
                            <Grid item style={{fontWeight: 700, marginBottom: '12px'}}>{this.state.formType === enumFormAction.ADD ? t('add_insurer') : (this.state.insuranceData?.legal_name || "")}</Grid>
                            <Grid item>
                {
                    
                    this.state.formType === enumFormAction.VIEW ? (
                                <Button onClick={() => this.setState({ formType: enumFormAction.EDIT })} disabled={this.state.role === enumRoles.sales ? true : false} variant="outlined" className={classes.editBtnStyle} color="primary">
                                    {t('edit_details')}
                                </Button>
                    ) : ''
                }
                            </Grid>
                        </Grid>

                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.accordionWrapper} id='accordianWrap'>
                            <Accordion className={classes.accordionStyle}>
                                <AccordionSummary
                                    aria-expanded={true}
                                    expandIcon={<ExpandMore />}
                                    aria-controls="Insurers"
                                    id="Insurers"
                                >
                                    <Typography className={classes.accordianHeading}>{t('overview')}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <InsurerOverview
                                        data={this.state.insuranceData}
                                        insuranceTypes={ (data: any | null) => { 
                                            this.setState({ insuranceData: data })}
                                        }
                                        formType={this.state.formType}
                                    />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion disabled={this.state.insuranceData === null ? true : false} className={this.state.errorSates.siib_comission_rate_error ? classes.errorstyle : classes.accordionStyle}>
                                <AccordionSummary
                                    aria-expanded={true}
                                    expandIcon={<ExpandMore />}
                                    aria-controls="SIIB commission rate"
                                    id="SIIB commission rate"
                                >
                                    <AccordionTitle title={t('siib_commision_rate')} insurerId={this.state.insurerId} insuranceData={this.state.insuranceData} t={t}/>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <SiibCommissionRate
                                        isArabicLanguage={this.state.isArabicLanguage}
                                        addSiibCommission={this.handleSIIBCommission}
                                        insuranceInputs={this.state.insuranceData}
                                        submitEvent={this.state.checkErrors}
                                        formType={this.state.formType}
                                        siibComissionRate={this.state.siib_comission_rate || {}}
                                    />
                                </AccordionDetails>
                            </Accordion>

                           
                            <MedicalJSX 
                            insurerId={this.state.insurerId}
                            insuranceData={this.state.insuranceData}
                            formType={this.state.formType}
                            errorSates={this.state.errorSates}
                            medical_insurances={this.state.medical_insurances}
                            checkErrors={this.state.checkErrors}
                            handleAddClass={(e:any) => this.handleAddClass(e)}
                            MedicalClose={(index:any) => this.MedicalClose(index)}
                            handleMedicalInsuranceClass={(data:any, index:any)=>  {
                                this.handleMedicalInsuranceClass(data, index)}
                            }
                            handleMedicalClassBenefits={(data:any, index:any) => this.handleMedicalClassBenefits(data, index)}
                            />

                            <MotorJSX
                                insurerId={this.state.insurerId}
                                insuranceData={this.state.insuranceData}
                                errorSates={this.state.errorSates}
                                handleMotorInsurance={(key: string, value: string) => { 
                                    this.handleMotorInsurance(key, value,)}
                                }
                                motor_insurance={this.state.motor_insurance}
                                checkErrors={this.state.checkErrors}
                                formType={this.state.formType}
                            />


                            
                            <ContactJSX 
                                insuranceData={this.state.insuranceData}
                                errorSates={this.state.errorSates}
                                checkErrors={this.state.checkErrors}
                                formType={this.state.formType}
                                insurerId={this.state.insurerId}
                                insurance_contacts={this.state.insurance_contacts}
                                handleAddContact={(e:any) => this.handleAddContact(e)}
                                ContactClose={(index:any) => this.ContactClose(index)}
                                handleContactAddData={(key: string, value: string, index: number) => this.handleContactAddData(key, value, index)}
                            />


                            <Accordion disabled={this.state.insuranceData === null ? true : false} className={this.state.errorSates.documents_attributes_error ? classes.errorstyle : classes.accordionStyle}>
                                <AccordionSummary
                                    aria-expanded={true}
                                    expandIcon={<ExpandMore />}
                                    aria-controls="Attachment (Optional)"
                                    id="Attachment (Optional)"
                                >
                                    <AccordionTitle title={`${t('attachment')} (${t("optional")})`} insurerId={this.state.insurerId} insuranceData={this.state.insuranceData} t={t}/>
                                </AccordionSummary>
                                <AccordionDetails style={{ display: 'block' }}>
                                    {
                                        this.state.documents?.data?.map((item: any, index: number) => (
                                            <div key={item}>
                                                <UploadAttachedment
                                                    data={item}
                                                    eventChange={(eventValue: any, docName: string) => this.handleImageInfo(eventValue, docName, index)}
                                                    removeDoc={() => this.handleRemoveDoc(index)}
                                                    formType={this.state.formType}
                                                // docIndex={index}
                                                />
                                            </div>
                                        ))
                                    }

                                    {
                                        this.isAdditionalAttechmentIsVisible() ? (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={4} style={{ paddingTop: '30px' }}>
                                                    <LabelWithIcon label={t('additional_attechment')} />
                                                    <TextField
                                                        style={{
                                                            marginTop: "10px",
                                                            marginBottom: "10px",
                                                            height:"60px",
                                                            background: (this.isAdditionalAttechmentIsVisible()? false : true) ? "hsl(0, 0%, 95%)" : "none"
                                                        }}
                                                        label=""
                                                        id="image-upload"
                                                        name='image'
                                                        type='file'
                                                        variant="outlined"
                                                        hidden
                                                        fullWidth
                                                        inputProps={{
                                                            accept: allowedFileTypes.join(',')
                                                        }}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <div style={{ color: 'rgba(0, 0, 0, 0.23)' }}>{t('upload_another_attachment')}</div>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        title={t('choose_a_photo_please')}
                                                        className={classes.inputFile}
                                                        onChange={this.handelImageUpload}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : ''
                                    }

                                </AccordionDetails>
                            </Accordion>



                            <div className={classes.dialogBtnWrapper}>
                                {this.state.formType !== enumFormAction.VIEW && (
                                    <Button className={classes.dialogBtnCancel} variant="outlined" onClick={this.handleCancel}>
                                        {t('cancel')}
                                    </Button>
                                )}
                                {
                                    this.state.formType !== enumFormAction.VIEW && (
                                        <Button type='submit' className={classes.btnStyle} onClick={this.handleSave} disabled={this.state.insuranceData === null ? true : false}>
                                            {this.state.formType === enumFormAction.ADD && (
                                                t('add_insurer')
                                            )} 
                                            {this.state.formType === enumFormAction.EDIT && (
                                                t('save_changes')
                                            )} 
                                        </Button>
                                    ) 
                                }
                            </div>


                        </div>
                    </Grid>
                </Grid>


                <ConfirmationDialog
                    btnCancelText={t('cancel')}
                    btnOkText={t('delete')}
                    showRedButton={false}
                    openDialog={this.state.openConfirmationDialog}
                    headingText={this.state.headingText}
                    paragraphText={this.state.paragraphText}
                    handleOk={() => this.handleOkConfirmation()}
                    handleCancel={() => this.handleCancelConfirmation()}
                />


                <ConfirmationDialog
                    btnCancelText={t('cancel')}
                    showRedButton={this.state.leaveForm}
                    btnOkText={this.state.formDialogBtnOkText}
                    openDialog={this.state.openFormSave}
                    headingText={this.state.formDialogHeadingText}
                    paragraphText={this.state.formDialogParagraphText}
                    handleOk={() => this.formSaveBtn()}
                    handleCancel={() => this.formCancelBtn()}
                />
            </>
        )
    }
}
export default withStyles(useStyles)(withTranslation()(InsurerContant));
// Customizable Area End
