import React from "react";
import {
    Button,
    CircularProgress,
    Grid,
    Typography
} from "@material-ui/core";
// @ts-ignore
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import StageSevenController from "../../../../blocks/TaskAllocator/src/PolicyStage/StageSevenController";
import LabelWithIcon from "../../Common/LabelWithIcon";
import TextInput from "../../Common/TextInput";
import FileUpload from "../../Common/FileUpload";
import Toster from "../../Common/Toster"
import { ErrorMessage } from "formik";
import DownloadFile from "../../Common/DownloadFile";
export class CreateVoucherForm extends StageSevenController {

    render() {
        const { t, classes, setFieldValue, formikRef, dataIndex, values,flowValue ,checkStepProps,updateDocProps, dummyData, checkDisable } = this.props;
        const isInstallmentPaid = JSON.parse(window.localStorage.getItem('InstallmentPaid') || '{}')
        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') || '{}')
        const stageSevenData = JSON.parse(window.localStorage.getItem('stageSevenData') || '{}')



        return (
            <>
                <Toster
                    tosterText={this.state.alertMessage}
                    showToster={this.state.showAlert}
                    // @ts-ignore
                    tosterType={this.state.alertType}
                    handleCloseToster={() => this.setState({ showAlert: false })}
                />
                <Grid item xs={12} container className={classes.btnGrid} >
                    <Grid item xs={4} className={classes.fieldAlignment}>
                        <Button
                            data-test-id="createRevisedPaymentVoucher"
                            disabled={this.checkRevisedBtnDisabled(values,(dataIndex || 0))}
                            className={this.checkRevisedBtnDisabled(values,(dataIndex || 0)) ? classes.grayBtn : classes.primaryRvBtn}
                            aria-haspopup="true"
                            aria-controls="customized-menu"
                            variant="contained"
                            color="primary"
                            onClick={()=>{
                            this.generateCreateRevisedPaymentVoucher(values,dummyData,(dataIndex || 0),formikRef);
                            }}
                        >
                            {!this.state.voucherBtnLoadingForRevisedPayment && t('create_revised_payment_voucher')}
                            {this.state.voucherBtnLoadingForRevisedPayment && ( <CircularProgress size={25} />)}
                        </Button>
                        <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_payment_voucher_id_${dataIndex ?? 0}`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={4} className={classes.fieldAlignment} >
                        <LabelWithIcon label={t('revised_payment_voucher')} />
                         <DownloadFile
                            data-test-id="txtInputInvoiceDoc"
                            disabled={true}
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            fileName={
                                 values?.stageData &&
                                 values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`] &&
                                 values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`][`revised_payment_voucher_${dataIndex ?? 0}`]&&
                                 values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`][`revised_payment_voucher_${dataIndex ?? 0}`]["file_name"] || ""
                            }
                            isEditPage={true}
                            filePath={
                                 values?.stageData &&
                                 values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`] &&
                                 values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`][`revised_payment_voucher_${dataIndex ?? 0}`] &&
                                 values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`][`revised_payment_voucher_${dataIndex ?? 0}`]["url"] || ""
                            }
                            inputName={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_payment_voucher_${dataIndex ?? 0}`}
                        />
                    </Grid>
                    <Grid item xs={4} className={classes.fieldAlignment} >
                        <LabelWithIcon label={t('revised_payment_voucher_id')} />
                        <TextInput
                            id={""}
                            disabled={true}
                            className={classes.textInputStyle}
                            // isArabicLenguage={this.state.isArabicLenguage}
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_payment_voucher_id_${dataIndex ?? 0}`}
                            type="text"
                            data-test-id="revised_payment_voucher_id"

                        />

                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginTop: 20, marginBottom: 20 }}>
                    <Grid className={classes.fieldAlignment} item xs={4}>
                        <LabelWithIcon label={t(dataIndex ? `insurer_receipt_number` : 'customer_receipt_number')} />
                        <TextInput
                            id={""}
                            disabled={(isInstallmentPaid == false || !this.props.isExpanded || (stageSixData?.attributes?.is_next_stage_submitted&&!stageSevenData?.attributes?.save_as_draft))}
                            className={classes.textInputStyle}
                            // isArabicLenguage={this.state.isArabicLenguage}
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_insurance_receipt_number_${dataIndex ?? 0}`}
                            type="text"
                            data-test-id="customer_receipt_number"
                            onChange={(e: any) => setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_insurance_receipt_number_${dataIndex ?? 0}`, e.target.value)}
                            onBlur={()=>checkStepProps(values,flowValue as number)}
                        />
                          <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_insurance_receipt_number_${dataIndex ?? 0}`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                    </Grid>
                    <Grid className={classes.fieldAlignment} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('payment_date')} />
                            <TextInput
                                data-test-id={`payment_date`}
                                id={`payment_date`}
                                disabled={checkDisable}
                                className={classes.textInputStyle}
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_voucher_payment_date_${dataIndex ?? 0}`}
                                type={"date"}
                                onChange={(e: any) => setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_voucher_payment_date_${dataIndex ?? 0}`, e.target.value)}
                                onBlur={()=>checkStepProps(values,flowValue as number)}
                           />
                              <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_voucher_payment_date_${dataIndex ?? 0}`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                        </React.Fragment>
                    </Grid>
                    <Grid item xs={4}>
                        <LabelWithIcon label={t('proof_of_payment')} />
                        <FileUpload
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            disabled={checkDisable}
                            data-test-id="proof_of_payment"
                            fileName={values?.stageData && this.props.flowValue && values?.stageData[`flow${this.convertToWord(this.props.flowValue as number)}Data`]?.[`${dataIndex ? 'voucher_proof_of_payment_doc' : `revised_voucher_proof_of_payment_doc_${dataIndex ?? 0}`}`]?.file_name || ''}
                            filePath={values?.stageData && this.props.flowValue && values?.stageData[`flow${this.convertToWord(this.props.flowValue as number)}Data`]?.[`${dataIndex ? 'voucher_proof_of_payment_doc' : `revised_voucher_proof_of_payment_doc_${dataIndex ?? 0}`}`]?.url || ''}
                            inputName={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data[${dataIndex ? 'voucher_proof_of_payment_doc' : `revised_voucher_proof_of_payment_doc_${dataIndex ?? 0}`}]`}
                            onChange={(event: any) => { updateDocProps(dataIndex ? 'proof_of_payment_to_insurer' : 'proof_of_payment_refund_to_cust', event.target.files[0], formikRef, `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data[${dataIndex ? 'voucher_proof_of_payment_doc' : `revised_voucher_proof_of_payment_doc_${dataIndex ?? 0}`}]`) }}
                            onRemove={async() =>{setFieldValue(`stageData.[flow${this.convertToWord(this.props.flowValue as number)}Data]${dataIndex ? ['voucher_proof_of_payment_doc'] : [`revised_voucher_proof_of_payment_doc_${dataIndex ?? 0}`]}`, {})
                                await this.removeFile(values?.stageData[`flow${this.convertToWord(this.props.flowValue as number)}Data`]?.[`${dataIndex ? 'voucher_proof_of_payment_doc' : `revised_voucher_proof_of_payment_doc_${dataIndex ?? 0}`}`]?.id)
                        }}
                        />
                         <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data[${dataIndex ? 'voucher_proof_of_payment_doc' : `revised_voucher_proof_of_payment_doc_${dataIndex ?? 0}`}]`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                </Grid>
            </>
        )
    }
}

const useStyles = ({
    fieldAlignment: {
        paddingRight: '25px'
    },
    voucherBtn: {
        padding: "15px",
        borderRadius: '5px',
        width: '100%',
        fontSize: "15px",
        backgroundColor: "#979090",
        color: "white"
    },
    btnGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: "30px",
        marginTop: "25px",
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    grayBtn: {
        "text-transform": 'none',
        padding: 15,
        borderRadius: 5,
        width: '100%',
        fontSize: 15,
        backgroundColor: "#979090",
        color: "white"
    },
    primaryRvBtn: {
        "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": 'none',
        "padding": 15,
        "borderRadius": 5,
        "width": '100%',
        "fontSize": 15,
        "color": "white"
    }
});

export default withStyles(useStyles)(withTranslation()(CreateVoucherForm));