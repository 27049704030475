import React, { Component } from 'react'
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import LanguageIcon from '@material-ui/icons/Language';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
const fetch = require("node-fetch");
export const useStyles:any = (theme:any) => ({
  cmpWrap:{
    backgroundColor : 'transparant',
    padding: '0px 60px',
  },
  topActionWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px",
    width: "100%",
  },
  languageOption: {
    display: "flex",
    alignItems: "center",
    "& span": {
      padding: "0px 8px",
    },
  },
  contentWrap:{
    '& .titleHeading':{
      // fontSize: '32px',
      fontSize: '24px',
      color: '#000000',
      fontWeight: '600',
    },
    '& .paraText':{
      fontSize: '14px',
      color: '#828282',

    }
  },
  activeLang: {
    // color: '#44697b',
    color: '#3f51b5',
    fontWeight: '700',
  }
});

const enumLang = {
  EN: 'en',
  AR: 'ar',
}

// const classes = useStyles();
class TermsAndConditions extends Component<{}, { activeLang: string, apiData: any }> {
  // static propTypes: { classes: PropTypes.Validator<object>; };
  static propTypes: any;
  constructor(props: any) {
    super(props);
  
    // Initializing the state 
    this.state = { activeLang: enumLang.EN, apiData: [] };
  }
  componentDidMount() {
  this.apiCall()
  }

  async apiCall(){
    /* istanbul ignore next */
    await fetch("https://insurancebroker-300102-ruby.b300102.dev.eastus.az.svc.builder.cafe/bx_block_content/contents")
                  .then((res: { json: () => any; }) => res.json())
                  .then((json: any) => {
                    // console.log("term data :", json)
                    this.setState({apiData: json})
                  })
  }

  render() {
    const { classes }:any = this.props;
    return (
      <div style={{ width: "100%", backgroundColor: '#fff' }} className={classes.cmpWrap}>
        <>
        <div className={classes.topActionWrapper} data-test-id="topActionWrapper">
              <div style={{maxWidth: '80px'}}>
                {/* <ArrowBackIosIcon data-test-id="backBtn" /> */}
                <img src="/assets/images/SIIB_WHITE_BG.jpg" style={{maxWidth: '100%'}}  alt="" />
              </div>
              <div className={classes.languageOption}>
              <LanguageIcon color='primary' />
                {/* @ts-ignore */}
                <Button style={{paddingLeft: '0px'}} className={this.state.activeLang === enumLang.EN ? classes.activeLang : ""} data-test-id="EnglishBtn" onClick={() => {
                localStorage.setItem("lang", enumLang.EN);
                  this.setState({activeLang: enumLang.EN })
              }}>
                  English
                </Button>{" "}
                {/* @ts-ignore */}
                | <Button className={this.state.activeLang === enumLang.AR ? classes.activeLang : ""} data-test-id="ArabicBtn" onClick={() => {
                localStorage.setItem("lang", enumLang.AR)
                this.setState({activeLang: enumLang.AR })
              }}>العربي</Button>
              </div>
            </div>
            <div style={{ padding: "15px" }}>
  
                {
                  this.state.apiData.length && this.state.apiData?.map((item: any, index: number) => (
                    <div style={{ marginTop: "20px" }} className={classes.contentWrap} key={index}>
                   { /* @ts-ignore */}
                      <Typography variant="h5" className={`titleHeading ${this.state.activeLang === enumLang.AR ? 'text-right' : 'text-left'}`}>
                {/* @ts-ignore */}
                        {index > 0 && index + "."} { this.state.activeLang === enumLang.EN ?  item?.title : item?.ar_title }
                      </Typography>{" "}
                      {/* @ts-ignore */}
                      <Typography component={'span'} variant={'body2'} className={`paraText ${this.state.activeLang === enumLang.AR ? 'childern-text-right' : 'childern-text-left'}`}>
                {/* @ts-ignore */}
                      { this.state.activeLang === enumLang.EN ? <div dangerouslySetInnerHTML={{ __html: item?.description }} /> : <div dangerouslySetInnerHTML={{ __html: item?.ar_description }} /> }
                        {/* {ReactHtmlParser(item.description)} */}
                      </Typography>{" "}
                    </div>
                  ))
                  }
              </div>
              </>
      </div>
    )
  }
}

TermsAndConditions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default  withStyles(useStyles)(TermsAndConditions);