// Customizable Area Start
import React from "react";
import { Container } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PolicyCsvImportController, { Props } from "./PolicyCsvImportController"
import CSVImportDetails from '../../../../components/src/Common/CSVImportDetails'
import { POLICY_LIST_TABLE_DATA } from "../../../../components/src/Common/Table/TableColumns";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  }
});


export default class PolicyCsvImport extends PolicyCsvImportController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { 
      isUploadingCsvPolicyFile, 
      policyPage, 
      totalPolicyPage, 
      policyAlertMessage, 
      policyShowAlert, 
      policyAlertType, 
      policyCsvData, 
      policyErrorMessage, 
      errorFile, 
      isPolicyPageLoading 
    } = this.state;
    return (
      <ThemeProvider theme={theme} >
        <Container id="policyCSVImportPage" maxWidth="lg" dir={(window.localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"} >
          {/* @ts-ignore */}
          <CSVImportDetails
            importFor={"policy"}
            isUploadingCsvFile={isUploadingCsvPolicyFile}
            page={policyPage}
            totalPage={totalPolicyPage}
            alertMessage={policyAlertMessage}
            showAlert={policyShowAlert}
            alertType={policyAlertType}
            csvData={policyCsvData}
            errorMessage={policyErrorMessage}
            errorFile={errorFile}
            isLoading={isPolicyPageLoading}
            handleAnotherCSVUpload={this.handleAnotherCSVUpload}
            downloadFile={this.downloadPolicyFile}
            handleSaveCSVUpload={this.handleSaveCSVUpload}
            handleCSVUploader={this.handleCSVUploader}
            handleChangePage={this.handleChangePolicyPage}
            handleRowPerPage={this.handleRowPerPolicyPage}
            closeToast={this.closeToast}
            tableBody={POLICY_LIST_TABLE_DATA} 
            downloadSampleCsv={this.downloadSampleCsvForPolicy}
            />
        </Container>
      </ThemeProvider>
    );
  }
}
// Customizable Area End