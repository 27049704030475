// Customizable Area Start
import React from "react";
import {
  Container,
  Paper
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import EmailLoginForm from "../../../components/src/EmailLogin/EmailLoginForm.web";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <div className="mainDiv">
        <Container maxWidth="sm" dir={(window.localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"}  >
        <div style={componentStyles.centerContent} >
                <img style={componentStyles.logoImage} src="/assets/images/SIIB_WHITE_BG.jpg" alt="logo" />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px 0px",
                  width: "100%"
                }}
              >
                <Paper style={{ width: '100%' }} elevation={4} >
                <EmailLoginForm
                  loginSchema={this.loginSchema}
                  isArabicLenguage={this.state.isArabicLenguage}
                  userLogin={this.userLogin}
                  setArabicLanguage={this.setArabicLanguage}
                  ArabicLoginTitle={this.ArabicLoginTitle}
                  titleLogin={this.titleLogin}
                  emailNotValid={this.state.emailNotValid}
                  ArabicEmailErrorMsg={this.ArabicEmailErrorMsg}
                  emailErrorMsg={this.emailErrorMsg}
                  passwordNotValid={this.state.passwordNotValid}
                  ArabicPasswordErrorMsg={this.ArabicPasswordErrorMsg}
                  passwordErrorMsg={this.passwordErrorMsg}
                  ArabicEmailText={this.ArabicEmailText}
                  emailText={this.emailText}
                  ArabicPasswordText={this.ArabicPasswordText}
                  passwordText={this.passwordText}
                  enablePasswordField={this.state.enablePasswordField}
                  handleClickShowPassword={this.handleClickShowPassword}
                  ArabicTextForgotPassword={this.ArabicTextForgotPassword}
                  forgotPasswordText={this.forgotPasswordText}
                />
              </Paper>
            </div>
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}

const componentStyles = {
  centerContent: {
    display: "flex",
    justifyContent: "center"
  },
  logoImage: {
    height: '200px',
    width: '200px'
  }
}
// Customizable Area End