import * as Yup from 'yup';

const RvRequestStageFourSchema = (isArabicLanguage: boolean) => {
  const schema = {
    revision_number: Yup.string()
    .required(isArabicLanguage ? "رقم المراجعة مطلوب" : "Revision number is required")
    .matches(/^(?!\s)(?!.*\s$)(?!.*\s{2,}).*$/, isArabicLanguage ? 'التنسيق غير صالح. لا يُسمح بمسافات بادئة أو لاحقة أو متعددة.' : 'Invalid format. No leading, trailing, or multiple spaces allowed.'),
    revision_date: Yup.date().required(isArabicLanguage ? "تاريخ المراجعة مطلوب" : "Revision date is required"),
    policy_impact: Yup.string().required(isArabicLanguage ? "يرجى اختيار واحد على الأقل من تأثير السياسة" : "Please select at least one from policy impact"),
    revision_confirmation: Yup.mixed()
      .test(
        "isNotEmpty",
        isArabicLanguage ? "يرجى تحميل وثيقة مذكرة تأكيد المراجعة" : 'Please upload revision confirmation note document',
        function (value) {
          const sponsorIdCr = this.resolve(Yup.ref("revision_confirmation"));
          if ((typeof sponsorIdCr === "string" && sponsorIdCr.trim() === "") // Check if it's an empty string (URL)
          //  || (sponsorIdCr && typeof sponsorIdCr === "object" && !sponsorIdCr.size) // Check if it's a file blob without size
          ) {
            return false;
          } else {
            return true;
          }
        }
      )
      .test(
        "isValidFile",
        isArabicLanguage ? "يرجى تحميل وثيقة مذكرة تأكيد المراجعة" : "Please upload revision confirmation note document",
        function (value) {
           const urlToValidate = value && typeof value === "object" ? value.url : value;
          if (
            (typeof urlToValidate === "string" && urlToValidate.trim() !== "") || // Check if it's a non-empty string (URL)
            (value && value instanceof File) // Check if it's a file blob
          ) {
            return true;
          } else {
            return false;
          }
        }
      )
      .nullable()
      .required(
        isArabicLanguage
          ? "يرجى تحميل وثيقة مذكرة تأكيد المراجعة"
          : "Please upload revision confirmation note document"
      ),
    invoice_number: Yup.string().when('policy_impact', {
      is: "up",
      then: Yup.string()
      .required(isArabicLanguage ? "رقم الفاتورة مطلوب" : "Invoice number is required")
      .matches(/^(?!\s)(?!.*\s$)(?!.*\s{2,}).*$/, isArabicLanguage ? 'التنسيق غير صالح. لا يُسمح بمسافات بادئة أو لاحقة أو متعددة.' : 'Invalid format. No leading, trailing, or multiple spaces allowed.'),
      otherwise: Yup.string().notRequired()
    }),
    invoice_date: Yup.string().when('policy_impact', {
      is: "up",
      then: Yup.string().required(isArabicLanguage ? "تاريخ الفاتورة مطلوب" : "Invoice date is required"),
      otherwise: Yup.string().notRequired()
    }),
    revision_invoice: Yup.mixed().when('policy_impact', {
      is: "up",
      then: Yup.mixed()
        .test(
          "isNotEmpty",
          isArabicLanguage ? "يرجى تحميل وثيقة مذكرة الائتمان المراجعة" : 'Please upload revision credit note document',
          function (value) {
            const sponsorIdCr = this.resolve(Yup.ref("revision_invoice"));
            if ((typeof sponsorIdCr === "string" && sponsorIdCr.trim() === "") // Check if it's an empty string (URL)
            // || (sponsorIdCr && typeof sponsorIdCr === "object" && !sponsorIdCr.size) // Check if it's a file blob without size
            ) {
              return false;
            } else {
              return true;
            }
          }
        )
        .test(
          "isValidFile",
          isArabicLanguage ? "يرجى تحميل وثيقة مذكرة الائتمان المراجعة" : "Please upload revision credit note document",
          function (value) {
            const urlToValidate = value && typeof value === "object" ? value.url : value;
            if (
              (typeof urlToValidate === "string" && urlToValidate.trim() !== "") || // Check if it's a non-empty string (URL)
              (value && value instanceof File) // Check if it's a file blob
            ) {
              return true;
            } else {
              return false;
            }
          }
        )
        .nullable()
        .required(
          isArabicLanguage
            ? "يرجى تحميل وثيقة مذكرة الائتمان المراجعة"
            : "Please upload revision credit note document"
        ),
      otherwise: Yup.string().notRequired()
    }),
    invoice_amount: Yup.mixed().when('policy_impact', {
      is: "up",
      then: Yup.number()
        .typeError(isArabicLanguage ? 'يجب أن يكون رقمًا' : 'Invoice amount must be a number')
        .transform((value) => (typeof value === 'string' ? parseFloat(value) : value))
        .min(1, isArabicLanguage ? 'يجب أن يكون على الأقل 1' : 'Invoice amount should be at least 1')
        .required(isArabicLanguage ? 'رقم الفاتورة مطلوب' : 'Invoice amount is required'),
      otherwise: Yup.string().notRequired()
    }),
    credit_note_number: Yup.string().when('policy_impact', {
      is: "down",
      then: Yup.string()
      .required(isArabicLanguage ? "مذكرة الائتمان مطلوبة" : "Credit note is required")
      .matches(/^(?!\s)(?!.*\s$)(?!.*\s{2,}).*$/, isArabicLanguage ? 'التنسيق غير صالح. لا يُسمح بمسافات بادئة أو لاحقة أو متعددة.' : 'Invalid format. No leading, trailing, or multiple spaces allowed.'),
      otherwise: Yup.string().notRequired()
    }),
    credit_note_date: Yup.string().when('policy_impact', {
      is: "down",
      then: Yup.string().required(isArabicLanguage ? "تاريخ مذكرة الائتمان مطلوب" : "Credit note date is required"),
      otherwise: Yup.string().notRequired()
    }),
    revision_credit_note: Yup.mixed().when('policy_impact', {
      is: "down",
      then: Yup.mixed()
        .test(
          "isNotEmpty",
          isArabicLanguage ? "يرجى تحميل وثيقة مذكرة الائتمان المراجعة" : 'Please upload revision credit note document',
          function (value) {
            const sponsorIdCr = this.resolve(Yup.ref("revision_credit_note"));
            if ((typeof sponsorIdCr === "string" && sponsorIdCr.trim() === "") // Check if it's an empty string (URL)
            // || (sponsorIdCr && typeof sponsorIdCr === "object" && !sponsorIdCr.size) // Check if it's a file blob without size
            ) {
              return false;
            } else {
              return true;
            }
          }
        )
        .test(
          "isValidFile",
          isArabicLanguage ? "يرجى تحميل وثيقة مذكرة الائتمان المراجعة" : "Please upload revision credit note document",
          function (value) {
              const urlToValidate = value && typeof value === "object" ? value.url : value;
            if (
              (typeof urlToValidate === "string" && urlToValidate.trim() !== "") || // Check if it's a non-empty string (URL)
              (value && value instanceof File) // Check if it's a file blob
            ) {
              return true;
            } else {
              return false;
            }
          }
        )
        .nullable()
        .required(
          isArabicLanguage
            ? "يرجى تحميل وثيقة مذكرة الائتمان المراجعة"
            : "Please upload revision credit note document"
        ),
      otherwise: Yup.string().notRequired()
    }),
    credit_note_amount: Yup.mixed().when('policy_impact', {
      is: "down",
      then: Yup.number()
        .typeError(isArabicLanguage ? 'يجب أن يكون مبلغ مبلغ إشعار الائتمان رقمًا' : 'Credit note amount amount must be a number')
        .transform((value) => (typeof value === 'string' ? parseFloat(value) : value))
        .min(1, isArabicLanguage ? "يجب أن يكون مبلغ إشعار الائتمان 1 على الأقل" : 'Credit note amount should be at least 1')
        .required(isArabicLanguage ? "مطلوب مبلغ مذكرة الائتمان" : 'Credit note amount is required'),
      otherwise: Yup.string().notRequired()
    }),
    comment: Yup.string().required(isArabicLanguage ? "التعليق مطلوب" : "Comment is required"),
  };

  return schema;
};

export default RvRequestStageFourSchema;