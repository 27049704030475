import React from "react";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography
} from "@material-ui/core";
import { ErrorMessage } from "formik";
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PaymentReviewController, {Props} from "./PaymentReviewController";
import PaymentReviewInsurerInstallmentForm from  "../PaymentReview/PaymentReviewInsurerInstallmentForm"
import LabelWithIcon from "../../../../../../components/src/Common/LabelWithIcon";
import TextInput from "../../../../../../components/src/Common/TextInput";
import FileUpload from "../../../../../../components/src/Common/FileUpload";
import Toster from "../../../../../../components/src/Common/Toster";
import { t } from "i18next";

export class PaymentReviewInsurerForm extends PaymentReviewController {
     constructor(props: Props) {
        super(props);
    }

    isValidate = () => {
        return !this.props.isExpanded || this.props.isPayInstallment;
    }
   renderInstallForm = (installments:any) => {
        const { classes } = this.props;

        if (this.props.flowValue === 2 && installments?.length > 0) {
            return installments.map((item:any, index:number) => (
                <div key={`installment-${index}`} className={classes?.installmentAlignment} style={{ marginTop: 25 }}>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography style={{ fontWeight: 400 }}>{this.getNumberWithOrdinal(index + 2)} {t("installment")}</Typography>
                        <Button
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            color="primary"
                            onClick={()=> this.props.removeInstallment(index)}
                            style={{ backgroundColor: "white", color: "#007E98", boxShadow: "none" }}
                        >
                            <CloseIcon />
                        </Button>
                    </Grid>
                    <PaymentReviewInsurerInstallmentForm formikRef={this.props.formikRef} installmentsData={this.props.installmentsData} isAmountConfigure={this.props.isAmountConfigure} isPayInstallment={this.props.isPayInstallment} installmentIndex={index} installmentItem={item} insurerInstallmentChnageHandler={this.props.insurerInstallmentChnageHandler} isExpanded={this.props.isExpanded} values={this.props.values}/>
                </div>
            ));
        }
        return null;
    };



    render() {
        const { t, classes } = this.props;

        return (
            <React.Fragment>
                <Toster
                    tosterText={this.state.alertMessage}
                    showToster={this.state.showAlert}
                    tosterType={this.state.alertType}
                    handleCloseToster={this.closeAlertMessage}
                />
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('insurer_receipt_number')} />
                        <TextInput
                            id={""}
                            disabled={this.isValidate()}
                            className={classes?.textInputStyle}
                            name={`fieldsData['flowFieldObj']['insurance_receipt_number']`}
                            type="text"
                            data-test-id="insurance_receipt_number"
                            onChange={(evt:React.ChangeEvent<HTMLInputElement>)=> this.props.insurerCommonFieldsChangeHandler(evt.target.value,'insurance_receipt_number')}

                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['insurance_receipt_number']`}
                            className="field-error"
                            data-test-id="insurance_receipt_number_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('payment_date')} />
                            <TextInput
                                data-test-id={`insurer_payment_date`}
                                disabled={this.isValidate()}
                                id={`payment_date`}
                                className={classes?.textInputStyle}
                                name={`fieldsData['flowFieldObj']['payment_date']`}
                                type={"date"}
                                onChange={(evt:React.ChangeEvent<HTMLInputElement>) => this.props.insurerCommonFieldsChangeHandler(evt.target.value,'payment_date')}
                            />
                            <ErrorMessage
                                name={`fieldsData['flowFieldObj']['payment_date']`}
                                className="field-error"
                                data-test-id="payment_date_error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                            />
                        </React.Fragment>
                    </Grid>
                    <Grid item xs={4} >
                        <LabelWithIcon label={t('proof_of_payment')} />
                        <FileUpload
                            eachFileSizeInMB={10}
                            disabled={this.isValidate()}
                            maxFilesNumber={5}
                            fileName={this.props.values.fieldsData?.flowFieldObj?.proof_of_payment?.file_name || ""}
                            filePath={this.props.values.fieldsData?.flowFieldObj?.proof_of_payment?.url || ""}
                            onChange={(evt:any)=> this.props.insurerCommonFieldsChangeHandler(evt.target.files[0],'proof_of_payment')}
                            data-test-id="fieldsData['flowFieldObj']['proof_of_payment']"
                            inputName={`fieldsData['flowFieldObj']['proof_of_payment']`}
                            onRemove={/*istanbul ignore next*/()=>{
                                this.props.formikRef?.current?.setFieldValue(`fieldsData['flowFieldObj']['proof_of_payment']`,{})
                                this.props.values.fieldsData?.flowFieldObj?.proof_of_payment?.url&&  this.removeFilePaymentReview(this.props.values.fieldsData?.flowFieldObj?.proof_of_payment?.id)
                                this.props.insurerCommonFieldsChangeHandler({},'proof_of_payment')
                            }}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['proof_of_payment']`}
                            className="field-error"
                            data-test-id="proof_of_payment_doc_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <LabelWithIcon label={t('policy_release_requested')} />
                    <FormControlLabel
                        color="primary"
                        name={`fieldsData['flowFieldObj']['policy_release_requested']`}
                        control={<Checkbox color="primary"
                            disabled={this.isValidate()}
                            checked={(this.props.values.fieldsData['flowFieldObj']['policy_release_requested'] === "yes")}
                            onChange={(evt:React.ChangeEvent<HTMLInputElement>)=> this.props.insurerCommonFieldsChangeHandler(evt.target.checked,'policy_release_requested')}
                            />
                        }
                        data-test-id={`policy_release_requested`}
                        label={t('yes')}
                    />
                    <br/>
                    <ErrorMessage
                        name={`fieldsData['flowFieldObj']['policy_release_requested']`}
                        className="field-error"
                        data-test-id="policy_release_requested_error"
                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                    />
                </Grid>
                {this.props.values?.fieldsData?.flowFieldObj?.installment_attributes && this.renderInstallForm(this.props.values.fieldsData['flowFieldObj']['installment_attributes'])}
            </React.Fragment>
        )
    }
}

const useStyles = ({
    displayFlex: {
        display: "flex",
    },
    contactForm: { width: "100%" },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    voucherBorder: {
        paddingBottom: "25px",
        borderBottom: '3px solid #eaeaea'
    },
    borderAlignment: {
        paddingTop: "25px",
        borderTop: '3px solid #eaeaea'
    },
    installmentAlignment: {
        border: "1px solid #eaeaea",
        borderRadius: "10px",
        padding: "20px",
    },
    footerBorder: {
        paddingBottom: '15px', borderBottom: '3px solid #eaeaea'
    }
});

export default withStyles(useStyles)(withTranslation()(PaymentReviewInsurerForm));