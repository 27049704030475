// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

export const configJSON = require("../config");

export interface Props {
  isExpanded?: boolean;
  isEdit?: boolean;
  onNext?: any;
  onReject?: any;
  t?: any;
  classes?: any;
  setStageData?:any;
}

interface S {
  customerName: string;
  policyId: string;
  isArabicLenguage: boolean;
  expandedAccordion: boolean;
  stageData?: any;
  errorSection?: string;
  contectOption?: any[];
  handOverNoteText: string;
  rejectReasonText: string;
  showNoteDialog: boolean;
  isBtnVisible: boolean;
  showRejectDialog: boolean;
  moveToStep: any;
  showAlert: boolean;
  alertMessage: string;
  alertType: string;
  isDraftAPIUsed: boolean;
  hasViewPermissionList?: string[];
  hasUpdatePermissionList?: string[];
  loggedInRole: string;
  isViewOnly: boolean;
  disableAccordian: boolean;
  isStageThreeCreated: boolean;
  isStageThreeSubmitted: boolean
}

interface SS {
  id: any;
}

export default class StageThreeController extends BlockComponent<Props, S, SS> {
  getUpdatedCommentAPI: any = "";
  saveUpdatedCommentAPI: any = "";
  modifiedUpdatedCommentAPI: any = "";
  trackRequestAPI: any = "";
  policyId: number | null = null;
  stageTwoAPIResponse: any = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      customerName: "",
      policyId: this.getPolicyId() || "",
      isArabicLenguage:
        (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      expandedAccordion: false,
      stageData: {
        updateComments: "",
      },
      errorSection: "",
      handOverNoteText: "",
      rejectReasonText: "",
      showNoteDialog: false,
      isBtnVisible: true,
      showRejectDialog: false,
      moveToStep: null,
      showAlert: false,
      alertMessage: "",
      alertType: "",
      isDraftAPIUsed: false,
      hasViewPermissionList: [],
      hasUpdatePermissionList: [],
      loggedInRole: "",
      isViewOnly: false,
      disableAccordian: false,
      isStageThreeCreated: false,
      isStageThreeSubmitted: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getPolicyId() {
    const queryParameter: any = new URLSearchParams(window.location.search);
    const policyId: string = queryParameter?.get("id");
    return policyId;
  }

  async componentDidMount() {
    super.componentDidMount();
    
    const stageOneInitialData = JSON.parse(
      localStorage.getItem("stageOne") ?? "{}"
    );

    this.policyId = this.getPolicyId() ? Number(this.getPolicyId()) : stageOneInitialData?.policyId;
    this.policyId && this.getStageTwoResponse(this.policyId);
    this.setState({ expandedAccordion: this.props.isExpanded as boolean });
  }

  handleAccordian = () => {
    if (this.policyId && !this.hasOnlyViewRights() && this.state.isStageThreeCreated) {
      const { role } = JSON.parse(
        window.localStorage.getItem("loginData") ?? "{}"
      );
      this.setState({
        showAlert: true,
        alertMessage: `Awaiting is not accessible to ${role} role. Please login with other role`,
        alertType: "warning"
      })
      return;
    }
    this.setState((prev: any) => ({
      ...prev,
      expandedAccordion: !prev.expandedAccordion,
    }));
  };

  hasOnlyViewRights = () => {
    const { role } = JSON.parse(
      window.localStorage.getItem("loginData") ?? "{}"
    );

    return (this.policyId &&
      this.state.hasViewPermissionList?.includes(role)) as boolean;
  };

  hasOnlyEditRights = () => {
    const { role } = JSON.parse(
      window.localStorage.getItem("loginData") ?? "{}"
    );

    return (this.policyId &&
      this.state.hasUpdatePermissionList?.includes(role)) as boolean;
  };

  handleHandOverNoteText = (inputText: string) => {
    this.setState({ handOverNoteText: inputText });
  };
  handleRejectReasonText = (inputText: string) => {
    this.setState({ rejectReasonText: inputText });
  };
  closeNoteDialog = () => {
    this.setState({ showNoteDialog: false ,handOverNoteText:"", isBtnVisible: true});
  };
  openNoteDialog = () => {
    this.setState({ showNoteDialog: true, isBtnVisible: false });
  };
  openRejectDialog = () => {
    this.setState({ showRejectDialog: true });
  };
  closeRejectDialog = () => {
    this.setState({ showRejectDialog: false, isBtnVisible: true });
  };
  successCloseRejectDialog = () => {
    this.closeRejectDialog();
    this.trackRequest(
      this.policyId,
      "reject_stage",
      this.state.rejectReasonText,
      this.state.moveToStep.value === "processing"
        ? "processing"
        : "info_gathering"
    );
  };
  moveToSelectedStep = (step: any) => {
    this.setState({ moveToStep: step });
  };

  setArabicLanguage = (value: boolean) => {
    const language = value === true ? "ar" : "en";
    window.localStorage.setItem("lang", language);
    this.setState({
      isArabicLenguage: value,
    });
  };

  saveDraftData = (formik3ref: any) => {
    const { current: formikInstance } = formik3ref;
    if (formikInstance) {
      this.props.isEdit ||
      this.state.isDraftAPIUsed ||
      this.state.isStageThreeCreated || this.state.isStageThreeSubmitted
        ? this.modifyUpdatedComments(
            { comment: formikInstance.values, isDraft: true },
            this.policyId as number
          )
        : this.saveUpdateComment(
            { comment: formikInstance.values, isDraft: true },
            this.policyId as number
          );
      this.setState({ isDraftAPIUsed: true });
    }
  };

  saveUpdateComment = async (values: any, policyId: number) => {
    this.saveUpdatedCommentAPI = this.apiCallForStageThree({
      contentType: "application/json",
      method: "POST",
      body: this.getRequestPayload(values),
      endPoint: `bx_block_policy_request/policy_requests?stage=3&id=${policyId}`,
    });
  };

  getStageTwoResponse = async (policyId: number) => {
    this.stageTwoAPIResponse = this.apiCallForStageThree({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=2`,
    });
  };

  getRequestPayload = (values: {
    comment: { updateComments: string };
    isDraft: boolean;
  }) => {
    const { comment, isDraft = false } = values;
    return {
      data: {
        awaiting_quotation: {
          post_updates: comment.updateComments,
          ...(isDraft && { save_as_draft: "yes" }),
        },
      },
    };
  };

  handOverNoteSubmit = (value:string) => {
    this.trackRequest(this.policyId,'next_stage', value, 'operations_review');
}

  getUpdatedComments = (policyId: any) => {
    this.getUpdatedCommentAPI = this.apiCallForStageThree({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=3`,
    });
  };

  modifyUpdatedComments = (values: any, policyId: any) => {
    this.modifiedUpdatedCommentAPI = this.apiCallForStageThree({
      contentType: "application/json",
      method: "PUT",
      body: this.getRequestPayload(values),
      endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=3`,
    });
  };

  trackRequest = (
    policyId: any,
    stageStatus: string,
    comment: string,
    currentStage: string
  ) => {
    const stageInfo = stageStatus === "reject_stage" ? "?stage=3" : "";
    this.trackRequestAPI = this.apiCallForStageThree({
      contentType: "application/json",
      method: "POST",
      body: {
        data: {
          track_request: {
            stage_status: stageStatus,
            ...(comment && { comment }),
            current_stage: currentStage,
          },
        },
      },
      endPoint: `/bx_block_policy_request/policy_requests/${policyId}/track_requests${stageInfo}`,
    });
  };

  apiCallForStageThree(data: any) {
    const { contentType, method, endPoint, body } = data;
    const loginUserData = JSON.parse(localStorage.getItem("loginData") ?? "{}");
    const accessToken = loginUserData.token || "";

    const headerForStageThree = {
      "Content-Type": contentType,
      token: accessToken,
    };

    const requestMessageForStageThree = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForStageThree.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerForStageThree)
    );
    requestMessageForStageThree.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForStageThree.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessageForStageThree.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(requestMessageForStageThree.id, requestMessageForStageThree);
    return requestMessageForStageThree.messageId;
  }

  getStageThreeAlertMessage = (isDraft: boolean, reject: boolean = false) => {
    const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
    let alertMessage = "";
    if (isDraft) {
      alertMessage = isArabic ? "تم حفظ بيانات طلب المراجعة كمسودة!" : "Data has been saved as draft!"
    } 
    else if(!isDraft && reject) {
      alertMessage = isArabic ? "لقد تم رفض طلبك إلى المرحلة المحددة" : "Your request has been reject to selected stage!"
    }
    else {
      alertMessage = isArabic? "لقد تم نقل طلبك إلى مرحلة تقديم عروض الأسعار" : "Your request has been moved to Submitting quotations stage"
    }
    return alertMessage
  }

  setStageThreeApiErrorMessage = (responseJson:any) => {
    if (responseJson.errors) {
      const errorMessage = typeof(responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error  || "Something went wrong";
      this.setState({
        alertType: "error",
        alertMessage: errorMessage,
        showAlert: true,
        showNoteDialog: false
      })
    }
  }

  handleMetaData = (meta: any) => {
    if (meta) {
      const { current_stage_view_permission_role, current_stage_update_permission_role } = meta;
      const { role } = JSON.parse(window.localStorage.getItem("loginData") ?? "{}");
  
      this.setState({
        hasViewPermissionList: current_stage_view_permission_role,
        hasUpdatePermissionList: current_stage_update_permission_role,
        loggedInRole: role,
      });
    }
  };

  getCommentResponse = async (responseJson: any) => {
    this.setState({isStageThreeSubmitted: true})
          const { attributes } = await responseJson.data;
          this.setState((prevState) => ({
            stageData: {
              ...prevState,
              updateComments: attributes.post_updates,
            },
          }));
         
          if (responseJson?.data?.attributes?.save_as_draft) {
            this.setState({
              showAlert: true,
              alertMessage: this.getStageThreeAlertMessage(true),
              alertType: "success",
            });
            return;
          }
          
          window.localStorage.setItem(
            "stageThreeData",
            JSON.stringify(responseJson?.data)
          );
          this.props.setStageData("stageThreeData",responseJson?.data);
          this.setState({ 
            expandedAccordion: false, 
            isDraftAPIUsed: false,
            showNoteDialog: true
           });

  }

  saveUpdatedCommentAPIReceive = async(message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.saveUpdatedCommentAPI != null &&
      this.saveUpdatedCommentAPI ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = await message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      try {
        if (responseJson.data) {
          this.getCommentResponse(responseJson);
        } else {
          this.setStageThreeApiErrorMessage(responseJson);
        }
      } catch (error) {
        //ignore
      } finally {
        if (responseJson?.meta) {
          this.handleMetaData(responseJson?.meta);
        }
      }
    }
  }
  getUpdatedCommentAPIReceive = async (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getUpdatedCommentAPI != null &&
      this.getUpdatedCommentAPI ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      try {
        if (responseJson.data) {
          const { attributes } = await responseJson.data;
          this.setState((prevState) => ({
            stageData: {
              ...prevState,
              updateComments: attributes.post_updates,
            },
          }));
          window.localStorage.setItem(
            "stageThreeData",
            JSON.stringify(responseJson?.data)
          );
          this.props.setStageData("stageThreeData",responseJson?.data);
        } else {
          this.setStageThreeApiErrorMessage(responseJson);
        }
      } catch (error) {
        //ignore
      } finally {
        if (responseJson?.meta) {
          this.handleMetaData(responseJson?.meta);
        }
      }
    }
  }
  modifiedUpdatedCommentAPIReceive = async (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.modifiedUpdatedCommentAPI != null &&
      this.modifiedUpdatedCommentAPI ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      try {
        if (responseJson.data) {
          const { attributes } = await responseJson.data;
          this.setState((prevState) => ({
            stageData: {
              ...prevState,
              updateComments: attributes.post_updates,
            },
          }));
          if (
            this.state.isDraftAPIUsed &&
            responseJson?.data?.attributes?.save_as_draft
          ) {
            this.setState({
              showAlert: true,
              alertMessage: this.getStageThreeAlertMessage(true),
              alertType: "success"
            })
            return;
          }
          window.localStorage.setItem(
            "stageThreeData",
            JSON.stringify(responseJson?.data)
          );
          this.props.setStageData("stageThreeData",responseJson?.data);
          this.setState({
            showNoteDialog: true,
            expandedAccordion: false,
            isDraftAPIUsed: false
          })
        } else {
          this.setStageThreeApiErrorMessage(responseJson);
        }
      } catch (error) {
        //ignore
      } finally {
        if (responseJson?.meta) {
          this.handleMetaData(responseJson?.meta);
        }
      }
    }
  }
  trackRequestAPIReceive =  async (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.trackRequestAPI != null &&
      this.trackRequestAPI ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        if(responseJson.data.attributes.stage_status === 'reject_stage') {
          this.setState({
            showAlert: true,
            alertMessage: this.getStageThreeAlertMessage(false, true),
            alertType: "success"
          })
          setTimeout(()=> {
              this.props.onReject(this.state.moveToStep.value === "Processing" ? 2 : 1);
          }, 700)
        }
        else {
          const stageThreeAlertMessage = this.getStageThreeAlertMessage(false)
          this.setState({showNoteDialog:false, expandedAccordion:false,showAlert:true,alertMessage: stageThreeAlertMessage,alertType:"success"});
          this.props.onNext(3)
        }
      } else {
        this.setStageThreeApiErrorMessage(responseJson);
      }
    }

  }

  editResponseCheck = ({hasOnlyView, isCreateStage, is_next_stage_submitted}: any) => {
    if (
      this.props.isEdit ||
      hasOnlyView ||
      (isCreateStage &&
        this.policyId &&
        is_next_stage_submitted)
    ) {
      this.getUpdatedComments(this.policyId);
      if (
        !this.state.hasViewPermissionList?.includes(this.state.loggedInRole)
      ) {
        this.setState({ expandedAccordion: false });
      }
    } else {
      const handoverNote =
        window.localStorage.getItem("handoverNote") || "";
      this.handleHandOverNoteText(handoverNote);
    }
  }
  stageTwoAPIResponseRecevie = async (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.stageTwoAPIResponse != null &&
      this.stageTwoAPIResponse ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.data) {
        window.localStorage.setItem(
          "stageTwoData",
          JSON.stringify(responseJson?.data)
        );
        this.props.setStageData("stageTwoData",responseJson?.data);
        const hasOnlyView: boolean =
          window.location.href.includes("ViewPolicy");
        const isCreateStage: boolean =
          window.location.href.includes("PolicyRequest");
        const stageTwoData = JSON.parse(
          localStorage.getItem("stageTwoData") || "{}"
        );
        this.setState({
          isStageThreeCreated:
            stageTwoData?.attributes?.is_next_stage_submitted,
        });
        this.setState({ isViewOnly: hasOnlyView });
        this.editResponseCheck({hasOnlyView, isCreateStage, is_next_stage_submitted: stageTwoData?.attributes?.is_next_stage_submitted})
      }
    }
  };

  receive(from: string, message: Message) {
    this.saveUpdatedCommentAPIReceive(message);
    this.getUpdatedCommentAPIReceive(message);
    this.modifiedUpdatedCommentAPIReceive(message);
    this.trackRequestAPIReceive(message);
    this.stageTwoAPIResponseRecevie(message);
  }
  
}
// Customizable Area End
