// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";


export interface Props {
  t:any, 
  classes:any, 
  importFor:string,
  isUploadingCsvFile:boolean, 
  page:number, 
  totalPage:number, 
  alertMessage:string, 
  showAlert:boolean, 
  alertType:string, 
  csvData:any, 
  errorMessage:any, 
  errorFile:any, 
  isLoading:boolean,
  handleAnotherCSVUpload:any,
  downloadFile:any,
  handleSaveCSVUpload:any,
  handleCSVUploader:any,
  handleChangePage:any,
  handleRowPerPage:any,
  tableBody:any,
  closeToast:any
}

interface S {
  isPageArabicLanguage: boolean,
  isUploadingCsvPolicyFile: boolean,
  policyPage: number;
  rowPerPolicyPage: number;
  totalPolicyPage: number;
  isPolicyPageLoading:boolean;
  policyCsvData:object[],
  policyShowAlert: boolean,
  policyAlertMessage: string;
  policyAlertType: string;
  policyErrorMessage: string;
  errorFile: string;
  isSaveBtnClicked: boolean;
}

interface SS {
  id: any;
}

export default class PolicyCsvImportController extends BlockComponent<
  Props,
  S,
  SS
> {
  uploadPolicyCsvAPIMessage: string = '';
  getUploadedCsvAPIMessage: string = '';
  savePolicyCsvAPIMessage: string = ''
  policyFileId: any;
  deletePolicyCsvAPIMessage: string = '';
  sampleCsvPolicyAPIKey: string = ''

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];




    this.state = {
      isPageArabicLanguage: (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      isUploadingCsvPolicyFile: false,
      policyPage: 1,
      rowPerPolicyPage: 10,
      totalPolicyPage: 1,
      isPolicyPageLoading:false,
      policyCsvData:[],
      policyAlertMessage: '',
      policyShowAlert: false,
      policyAlertType: '',
      policyErrorMessage: '',
      errorFile: '',
      isSaveBtnClicked: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  
  async componentDidMount() {
    super.componentDidMount();
  }


  handleCSVUploader=(file: any)=>{
    this.setState({isUploadingCsvPolicyFile:true})
    this.uploadPolicyCsv(file)
  }

  handleAnotherCSVUpload=(file: any)=>{
    this.uploadPolicyCsv(file, this.policyFileId)
  }
  
  handleSaveCSVUpload=()=>{
    this.setState({isSaveBtnClicked: true})
    this.savePolicyCsv(this.policyFileId)
  }

  handleChangePolicyPage = (event: any, page: number) => {
    this.setState({ policyPage: page });
  };

  handleRowPerPolicyPage = (event: any) => {
    this.setState({ rowPerPolicyPage: parseInt(event.target.value) });
    this.setState({ policyPage: 1 });
  };

  downloadPolicyFile = (fileUrl: string) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } 

  setArabicLanguageForPolicy = (value: boolean) => {
    const language = value === true ? 'ar' : 'en';
    window.localStorage.setItem('lang', language);
    this.setState({
      isPageArabicLanguage: value
    })
  }
  setAlert = (responseMessage: string,alertType: string = 'error') => {
    this.setState({ policyShowAlert: true, policyAlertMessage: responseMessage, policyAlertType: alertType})
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {
    if (
      (prevState.policyPage !== this.state.policyPage ||
        prevState.rowPerPolicyPage !== this.state.rowPerPolicyPage)
    ) {
      const {policyPage, rowPerPolicyPage} = this.state
      this.getUploadedCsv(policyPage, rowPerPolicyPage);
    }

  }

  getPolicyFormData = (file :  any, file_id: number| string) => {
    const formData = new FormData();
    file && formData.append('data[file]',file)
    file_id && formData.append('data[file_id]', file_id as string)
    
    return formData
  }

  uploadPolicyCsv = (file: any, file_id?: number) => {
    this.setState({
      isPolicyPageLoading:true
    })
    this.uploadPolicyCsvAPIMessage = this.apiCall({
      type : 'FormData',
      method: 'POST',
      body: this.getPolicyFormData(file, file_id as number),
      endPoint: 'bx_block_policies/policies/upload_policy_csv',
    })
  }

  closeToast = () => {
    this.setState({ policyShowAlert: false })
  }

  getUploadedCsv = (page: number, perPage: number) => {
    this.getUploadedCsvAPIMessage = this.apiCall({
      type : 'application/json',
      method: 'GET',
      endPoint: `bx_block_policies/policies/fetch_csv_uploaded_data?file_id=${this.policyFileId}&per_page=${perPage}&page=${page}`,
    })
  }

  savePolicyCsv = (file_id: number, ) => {
    this.savePolicyCsvAPIMessage = this.apiCall({
      type : 'FormData',
      method: 'PUT',
      body: this.getPolicyFormData(null, file_id),
      endPoint: `bx_block_policies/policies/update_csv_uploaded_data`
    })
  }

  destroyUploadedPolicyCsv = (fileId: string) => {
    this.deletePolicyCsvAPIMessage = this.apiCall({
      type : 'application/json',
      method: 'DELETE',
      endPoint: `bx_block_policies/policies/destroy_csv_uploaded_data?file_id=${fileId}`
    })
  }

  downloadSampleCsvForPolicy = () => {
    this.sampleCsvPolicyAPIKey = this.apiCall({
      type : 'application/json',
      method: 'GET',
      endPoint: `/bx_block_sample_file/sample_files?name=Policy`,
    })
  }


  apiCall(data: any) {
    const { contentType, method, endPoint, body, type } = data;
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    let header: any = {
      token: token,
      "Content-Type": contentType
    };

    const policyCsvImportMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    policyCsvImportMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    policyCsvImportMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    policyCsvImportMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== 'FormData' ?
    policyCsvImportMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      ) : policyCsvImportMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      ) ;

    runEngine.sendMessage(policyCsvImportMessage.id, policyCsvImportMessage);
    return policyCsvImportMessage.messageId;
}

setUpdatePolicyCsv = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.uploadPolicyCsvAPIMessage !== null &&
    this.uploadPolicyCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const updatePolicyCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
    try {
      if(updatePolicyCsvAPIResponse?.data){
        if (updatePolicyCsvAPIResponse?.data.error_message || updatePolicyCsvAPIResponse?.data.error_file) {
          this.setState({policyErrorMessage: updatePolicyCsvAPIResponse?.data.error_message, errorFile: updatePolicyCsvAPIResponse?.data.error_file})
          this.setAlert(updatePolicyCsvAPIResponse?.data.error_message)
        } 
        if(updatePolicyCsvAPIResponse?.data.file_id) {
          if(!updatePolicyCsvAPIResponse?.data.error_message) {
            this.setState({policyErrorMessage: '', errorFile: ''})
          }
          window.localStorage.setItem("file_id", JSON.stringify(updatePolicyCsvAPIResponse?.data?.file_id))
          this.policyFileId = updatePolicyCsvAPIResponse?.data?.file_id;
          this.getUploadedCsv(this.state.policyPage, this.state.rowPerPolicyPage)
        }
      } else {
        this.setAlert(updatePolicyCsvAPIResponse?.error)
      }
    } catch (error) {
      //ignore
    } finally {
      this.setState({isPolicyPageLoading:false})
    }
  }
}

setSavePolicyCsv = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.savePolicyCsvAPIMessage !== null &&
    this.savePolicyCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const savePolicyCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

    if (savePolicyCsvAPIResponse?.message) {
      this.setAlert(savePolicyCsvAPIResponse?.message, 'success')
      this.policyFileId = ''
      setTimeout(() => {
        window.history.back()
      }, 700)
    } else {
      this.setAlert(savePolicyCsvAPIResponse.errors)
    }
  }
}

fetchPolicyCsvList = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getUploadedCsvAPIMessage !== null &&
    this.getUploadedCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const fetchPolicyCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (fetchPolicyCsvAPIResponse?.data) {
      this.setState({isUploadingCsvPolicyFile: true})
      this.setState({policyCsvData: fetchPolicyCsvAPIResponse.data, totalPolicyPage: fetchPolicyCsvAPIResponse.meta.total_page})
    } else {
      this.setAlert(fetchPolicyCsvAPIResponse.errors)
    }
  }
}

deleteUploadedCsvList = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.deletePolicyCsvAPIMessage !== null &&
    this.deletePolicyCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const deletePolicyCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (deletePolicyCsvAPIResponse?.message) {
      this.setAlert(deletePolicyCsvAPIResponse?.message);
    } else {
      this.setAlert(deletePolicyCsvAPIResponse?.errors)
    }
  }
}

receiveDownloadedSampleCsvForPolicy = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.sampleCsvPolicyAPIKey !== null &&
    this.sampleCsvPolicyAPIKey ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const saveInsurerSampleCsv = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (saveInsurerSampleCsv?.url) {
      this.downloadPolicyFile(saveInsurerSampleCsv?.url);
    } else {
      this.setAlert(saveInsurerSampleCsv?.error)
    }
  }

}

async receive(from: string, message: Message) {
  this.setUpdatePolicyCsv(message);
  this.setSavePolicyCsv(message);
  this.fetchPolicyCsvList(message);
  this.deleteUploadedCsvList(message)
  this.receiveDownloadedSampleCsvForPolicy(message)
}

componentWillUnmount(): any {
  this.policyFileId && this.destroyUploadedPolicyCsv(this.policyFileId)
}

}
// Customizable Area End