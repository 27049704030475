import React, {useRef} from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { createTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";




const theme = createTheme();



const FileUpladButton = (props: any) => {
  const { uploadFileType, buttonText } = props;
  
  const classes = useStyles();
  const { t } = useTranslation();

  const fileInputRef: any = useRef(null);

  const handleFileUpload = () => {

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  
    fileInputRef.current.click();

  };

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];

    props.handleUpload(selectedFile);
    console.log('Selected File:', selectedFile);
  };

  return (
    <div>
      <Button
        className={classes.uploadCsvBtn}
        onClick={handleFileUpload}
    >
        {t(`${buttonText}`)}
    </Button>
      <input
        type="file"
        accept={uploadFileType} 
        style={{ display: 'none' }}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    uploadCsvBtn: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        color: "white",
        height: "55px",
        width: "150px",
        borderRadius: "10px",
        margin: "20px 0",
      },
  })
);

export default withRouter(FileUpladButton);
