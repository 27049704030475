import React, { useRef } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Pagination } from "@material-ui/lab";

const ROW_PER_PAGE_OPTION = [
    {
      label: "5",
      value: 5,
    },
    {
      label: "10",
      value: 10,
    },
    {
      label: "15",
      value: 15,
    },
];

const InsurerBalanceTable = (props: any) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { totalPage, currentPage, pageChange, tableData, insurerBalanceLoading, perPage, handleRowPerPage } = props;
    const paginationButtonRef = useRef();

    return (
        <div data-test-id="InsurerBalanceTable">
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow id="salesTableHead" className={classes.salesTableHead}>
                            <TableCell className={classes.tableHeading}>{t('insurer')}</TableCell>
                            <TableCell className={classes.tableHeading}>{t('policy_id')}</TableCell>
                            <TableCell className={classes.tableHeading}>{t('balance')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {insurerBalanceLoading &&
                            <TableRow>
                                <TableCell colSpan={11}>
                                    <Typography align="center" className={classes.tableLoading}>
                                        <CircularProgress size={50} />
                                    </Typography>
                                    <Typography align="center">
                                        {t('fetching_the_data_please_wait')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        }
                        {!insurerBalanceLoading && tableData.length > 0 ? tableData.map((insurerPerson: any, rowIndex: number) => (
                            <React.Fragment key={insurerPerson.insurer + rowIndex}>
                             {insurerPerson.insurer_balance.map((insurerData: any, index: number) => (
                                <TableRow key={insurerData.policy_id + rowIndex}>
                                    <TableCell>{index === 0 && insurerPerson.insurer}</TableCell>
                                    <TableCell>{insurerData.policy_id}</TableCell>
                                    <TableCell>SAR {insurerData.balance}</TableCell>
                                </TableRow>
                             ))}
                            </React.Fragment>
                        )): (
                            <TableRow>
                                <TableCell colSpan={11}>
                                    <Typography align="center">
                                        {!insurerBalanceLoading && t('no_records_found')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <div dir="ltr" id="salesPagination" className={classes.paginationSection}>
                    <Pagination
                        shape="rounded"
                        className={classes.paginationRoot}
                        count={totalPage}
                        page={currentPage}
                        onChange={pageChange}
                        showFirstButton
                        showLastButton
                        ref={paginationButtonRef}
                    />
                </div>
            </TableContainer>
        </div>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        salesTableHead: {
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        tableContainer: {
            padding: 0,
            border: "1px solid grey",
            borderRadius: "7px",
        },
        tableHeading: {
            fontSize: "14px",
            fontWeight: 700,
            padding: "13px 15px"
        },
        policyIdCell: {
            position: "sticky",
            left: 0,
            zIndex: 3,
            width: "120px",
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        paginationSection: {
            position: "sticky",
            top: 0,
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            zIndex: 2,
            marginLeft: "-1px",
        },
        paginationRoot: {
            "& .MuiPaginationItem-root": {
                borderRadius: "50%",
                width: "32px",
                height: "32px",
                backgroundColor: "#ccc",
                margin: "0 4px",
                color: "#333",
            },
            "& .Mui-selected": {
                backgroundColor: "rgba(233, 244, 249, 1)",
            },
        },
        rowPerPageConatiner: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        tableLoading:{
            padding:"50px"
        },
        rowPerPageContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        itemPerPage:{
            fontSize: "revert",
            padding: "3px 8px",
            color: "grey"
        },
        rowPerPageSelect: {
            padding: "5px 11px",
            borderRadius: "4px",
            border: "1px solid grey",
            backgroundColor: "white",
        },
    })
);

export default InsurerBalanceTable;
