import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Divider,
    Grid,
    InputAdornment,
    Typography
} from "@material-ui/core";
import { ErrorMessage, FieldArray, Form, Formik, useField } from "formik";
import * as Yup from "yup";
import { ExpandMore, CloseSharp, Description, ErrorOutlineRounded } from '@material-ui/icons'
// @ts-ignore
import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import StageTwoController, { Props } from "../../../blocks/TaskAllocator/src/PolicyStage/StageTwoController";
import ProcessingInternalForm from "./ProcessingInternalForm";
import HandOverDialog from '../Common/HandOverDialog.web'
import RejectRequestDialog from "../Common/RejectRequestDialog.web";
import CustomButton from "../Common/CustomButton";
import Toster from "../Common/Toster";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ActiveLanguage from "../Common/ActiveLanguage";



const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});
    
export class StageTwoForm extends StageTwoController {
    formikRef: any;

    constructor(props: Props) {
        super(props); 
        this.formikRef = React.createRef();       
    }

   

    handleSubmitFromOutside = () => {
        const { current: formikInstance } = this.formikRef;
        if (formikInstance) {
          this.checkErrorForStageTwoForm(formikInstance)
          formikInstance.handleSubmit();
        }
    }
    render() {
        
        const { t, classes, isExpanded } = this.props;
        const {stageData, stage2submitted, expandedAccordion, processingLoading, disableAccordian} = this.state

        const pushBackOption = [
            { value: "info_gathering", label: t('info_gathering') }
        ];
        
        const stageOneData = JSON.parse(
            localStorage.getItem("stageOneData") ?? "{}"
          );
        const stageTwoData = JSON.parse(localStorage.getItem('stageTwoData') || '{}');

        
        return (
            <ThemeProvider theme={theme} >
                <React.Fragment>
                    <Toster
                        tosterText={this.state.alertMessage}
                        showToster={this.state.showAlert}
                        // @ts-ignore
                        tosterType={this.state.alertType}
                        handleCloseToster={()=> this.setState({showAlert:false})}
                    />
                    <div className={classes.accordianGap}>
                        <Accordion 
                            data-test-id='p2'  
                            className={classes.formTwoAccordian} 
                            expanded={expandedAccordion} 
                            disabled={disableAccordian}
                            onChange={() => this.handleAccordian()} >
                            <AccordionSummary
                                aria-expanded={true}
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={classes.topSection}>
                                    <div className={classes.displayFlex}>
                                        <Typography className={classes.accordianHeading} >{t('rfq_processing')}</Typography>
                                        {(stage2submitted && !isExpanded) && 
                                        (<div className={classes.lockedAccordion}>
                                            {t('please_go_the_processing_stage_to_edit_this_section')}
                                        </div>)
                                        }
                                    </div>
                                    <div className={classes.iconAlignment}>
        {this.state.expandedAccordion && <DescriptionOutlinedIcon onClick={this.openNoteDialog} />}
        {this.state.expandedAccordion && stageTwoData?.attributes?.is_reject &&
            <ErrorOutlineRounded style={{position: 'relative', left: "10px"}} onClick={() => this.setState({isBtnVisible: false, showRejectDialog: true})} />
        }
    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Formik
                                    innerRef={this.formikRef}
                                    enableReinitialize={true}
                                    initialValues={{
                                    stageData: this.state.stageData
                                    }}
                                    validationSchema={Yup.object().shape({
                                        stageData: Yup.array().of(
                                        Yup.object().shape(this.processingSchema(ActiveLanguage()))
                                        ) 
                                        })
                                    }
                                    validateOnMount={true}
                                    validateOnBlur={true}
                                    onSubmit={async (values, actions) => {
                                        await this.submitProcessingData(values)
                                    
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        errors,
                                        touched,
                                        values,
                                        setFieldValue,
                                        validateForm
                                    }) => (
                                        <React.Fragment>
                                                <Grid xs={12} className={classes.contactForm} >
                                                    {stageData.some((company: any) => company?.contact?.length > 0) ? (
                                                        stageData.map((company: any, index: number) => (
                                                            company?.contact?.length > 0 && (
                                                            <ProcessingInternalForm
                                                                key={company.id}
                                                                errors={errors}
                                                                insurerIndex={index}
                                                                touched={touched}
                                                                setFieldValue={setFieldValue}
                                                                insurer={company}
                                                                isExpanded={isExpanded}
                                                                formikRefInst={this.formikRef}
                                                                values={values}
                                                                navigation={this.props.navigation}
                                                            />
                                                            )
                                                        ))
                                                        ) : (
                                                        <div className={classes.noContacts}>{processingLoading ? t('fetchiing_processing') : t('please_add_contacts_for_the_selected_insurance_company')}</div>
                                                        )}
                                                </Grid>
                                        </React.Fragment>
                                    )}
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                        { (expandedAccordion && !this.state?.isViewOnly && isExpanded) && (
                        <div className={classes.displayFlexEnd}>
                                                
                                {(stageData.some((company: any) => company?.contact?.length > 0))&& (
                                    <div className={classes.btnStyle}>
                                        <CustomButton 
                                            btnText={t('save_draft')} 
                                            onClick={() => this.saveDraftData(this.formikRef)} 
                                            bgColor={'#fff'} 
                                            textColor={'#007E98'}
                                        />
                                    </div>
                                )}
                                <div className={classes.btnStyle}>
                                    <CustomButton 
                                        btnText={t('reject')} 
                                        onClick={() => this.openRejectDialog()}
                                        bgColor={'#fff'} 
                                        textColor={'#007E98'}
                                    />
                                </div>
                                {(stageData.some((company: any) => company?.contact?.length > 0)) && (
                                    <div>
                                        <CustomButton 
                                            btnText={t('next_stage')} 
                                            onClick={this.handleSubmitFromOutside} 
                                            bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                            textColor={'#fff'}
                                        />
                                    </div>
                                )}
                    
                        </div>
                        )}
                    </div>
                    
                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNoteDialog as boolean}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={this.state.isBtnVisible?`(${t('processing')} > ${t('awaiting')})`:`(${t('info_gathering')} > ${t('processing')})`}
                        handleInputText={this.handleHandOverNoteText}
                        inputLable={this.state.showNoteDialog ? t('note') : ""}
                        inputValue={this.state.isBtnVisible ? this.state.handOverNoteText : stageOneData.attributes.handover_note}
                        inputDisable={!isExpanded}
                        handleOk={(value: string) => this.handOverNoteSubmit(value)}
                        handleCancel={this.closeNoteDialog}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                    /> 
                            
                     
                    <RejectRequestDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showRejectDialog as boolean}
                        textId={'rejectReason'}
                        pushBackOptions={pushBackOption}
                        inputValue={this.state.isBtnVisible ? this.state.rejectReasonText : stageTwoData.attributes?.rejected_reason}
                        setSelectedPushBackTo={this.moveToSelectedStep}
                        stepValue={this.state.moveToStep}
                        headingText={t('rejecting')}
                        handleInputText={this.handleRejectReasonText}
                        handleOk={this.successCloseRejectDialog}
                        handleCancel={this.closeRejectDialog}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                        labelText={t('push_back')}
                        reasonLabel={t('reason')}
                    /> 
                           

                    
                </React.Fragment>

            </ThemeProvider >
        )
    }
}
const useStyles = ({
    accordianGap: {
        padding: "10px 0"
    },
    btnStyle: {
        margin: '0 10px'
    },
    displayFlexEnd: { display: "flex", justifyContent: "end", margin: '16px 0' },
    noContacts: {
        "font-weight": 'bold',
        'text-align': 'center',
        fontSize:"14px",
        display: "flex", 
        justifyContent: "center"
    },
    cancelBtn: {
        marginRight: "10px",
        marginLeft: "10px",
        background: "white",
        borderRadius: "10px",
        "text-transform": "none",
        "height": "50px",
        "width": "150px",
    },
    errorSection: {
        border: "1px solid red"
    },
    formTwoAccordian: {
        "border-radius":"10px !important"
    },
    accordianHeading: {
        fontWeight: 600
    },
    displayFlex: {
        display: "flex",
    },
    contactForm: { width: "100%" },
    lockedAccordion: {
        fontSize: "12px",
        height: "35px",
        padding: "15px",
        border: '1px solid #ccc',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 0 0 20px',
        backgroundColor: "lightgray",
        color: "darkgray",
        '& svg': {
            marginRight: '6px',
        }
    },
    topSection: {
        width: '100%',   
        display: 'flex',
        justifyContent: 'space-between',
        '& svg': {
            color: '#0090a1'
        }
    },
    descriptionIcon: {
        color: '#007E98'
    },
    iconAlignment: {
        display: "flex",
        marginRight: "10px"
    }
});

export default withStyles(useStyles)(withTranslation()(StageTwoForm));