// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import React from "react";
import moment from "moment";

export const configJSON = require("../config");
const enumBtnType = {
    NEXT: 'NEXT',
    DRAFT: 'DRAFT',
}

export interface Props {
    isExpanded?: boolean
    isEdit?: boolean;
    onNext?: any;
    onReject?: any;
    onDraft?: any;
    t?: any;
    classes?: any;
    setStageData?: any;

}

interface S {
    isArabicLanguage: boolean,
    stageData: {
        expandedAccordion: string,
        firmOrder: string,
        vatCertificate: string,
        ownerId: string,
        facilitiesCredit: string,
        instalmentFormFilledByClient: string,
        clientIBAN: string,
        requestID: boolean,
        paymentMadeOn: string,
        paymentProof: string,
        authorisationLetterForOtherPerson: string,
        authorisationPersonId: string,

    },
    role: string,
    showAlert: boolean,
    alertMessage: string,
    alertType: string,
    errorSection: string,
    expandedAccordion: string,
    showDialog: any,
    stageOnePartTwoData: any;
    stageFiveData: any;
    stageSixDetail: any;
    stageFourData: any;
    policyId: string;
    rejectReasonText: string;
    showNoteDialog: boolean;
    showRejectDialog: boolean;
    moveToStep: string
    edit: boolean,
    show: any,
    update: any,
    isEditPage: boolean,
    isAddPage: boolean,
    isDraftAPIUsed: boolean,
    isExpandedAccordion: boolean
    isNextStageSbmitted: boolean,
    premiumCalculation: any,
    comparisionSheet: any,
    handOverNoteText: string,
    isReject:boolean,
    isBtnVisible: boolean,

}

interface SS {
    id: any;
}

export default class StageSixController extends BlockComponent<
    Props,
    S,
    SS
> {
    addQuotationApi: any
    getPolicyDetailsAPI: any = "";
    //addCustomerReview: string;
    formRef: any;
    trackRequestAPI: any = ''
    uploadFileApiCall: any = "";
    draftQuotationApiCall: any = '';
    getQuotationApi: any = '';
    EditQuotationApi: any = "";
    draftQuotationApiPutCall: any = "";
    getStageFourQuotationApi: any = "";
    getStageFiveQuotationApi: any = "";
    formikRef:any="";
    fieldName:any="";
    removeFileAPI:any="";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.openNoteDialog = this.openNoteDialog.bind(this)
        this.closeNoteDialog = this.closeNoteDialog.bind(this)
        this.moveToStep = this.moveToStep.bind(this)
        this.handleRejectReasonText = this.handleRejectReasonText.bind(this)
        this.closeRejectDialog = this.closeRejectDialog.bind(this)
        this.successCloseRejectDialog = this.successCloseRejectDialog.bind(this)

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        /* istanbul ignore next */
        const queryParameter: any = new URLSearchParams(window.location.search);
        const policyId: string = queryParameter?.get('id')
        const isView: boolean = window.location.href.includes("ViewPolicy");
        const isEditPage: boolean = window.location.href.includes("EditPolicy");
        const isAddPage: boolean = window.location.href.includes("PolicyRequest");
        this.state = {
            isArabicLanguage: (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
            policyId: policyId || "1",
            stageData: {
                expandedAccordion: "p1",


                firmOrder: '',
                vatCertificate: '',
                ownerId: '',
                facilitiesCredit: '',
                instalmentFormFilledByClient: '',
                clientIBAN: '',
                requestID: true,
                paymentMadeOn: '',
                paymentProof: '',
                authorisationLetterForOtherPerson: '',
                authorisationPersonId: ''
            },
            role: "",
            showAlert: false,
            showDialog: false,
            errorSection: "",
            alertMessage: "",
            expandedAccordion: "",
            alertType: "",
            stageOnePartTwoData: JSON.parse(localStorage.getItem('stageOnePartTwoData') || '{}'),
            stageFiveData: JSON.parse(localStorage.getItem('stageFiveData') || '{}'),
            stageFourData: JSON.parse(localStorage.getItem('stageFourData') || '{}'),
            edit: isView,
            isEditPage: isEditPage,
            isAddPage: isAddPage,
            stageSixDetail: [{
                paid_to: "",
                payment_made_on: "",
                insurance_company_id: "",
                approval: false,
                quoataionAmount: "",
                firm_order: {},
                vat_certificate: {},
                owner_id: {},
                facilities_credit: {},
                installment_form_filled_by_client: {},
                client_iban: {},
                paymentMadeOn: {},
                payment_proof: {},
                authorization_letter_for_other_person: {},
                authorized_person_id: {},
                note_to_the_customer: "",
                proposal: {},
                addtitional_attachment: {},
                health_declarations: {},
                sima_form: {},
                account_creation_form: {},

                dataId: "",


            }],
            rejectReasonText: '',
            showNoteDialog: false,
            showRejectDialog: false,
            moveToStep: '',
            show: [],
            update: [],
            isDraftAPIUsed: false,
            isExpandedAccordion: false,
            isNextStageSbmitted: false,
            premiumCalculation: {},
            handOverNoteText: "",
            isReject:false,
            comparisionSheet: {},
            isBtnVisible: true

        };
        this.formRef = React.createRef();
        this.formRef.current = [];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    setArabicLanguage = (value: boolean) => {
        const language = value === true ? 'ar' : 'en';
        window.localStorage.setItem('lang', language);
        this.setState({
            isArabicLanguage: value
        })
    }

    componentDidMount(): any {
        const queryParameter: any = new URLSearchParams(window.location.search);
        const id: string = queryParameter.get('id');
        const loginData = JSON.parse(localStorage.getItem('loginData') || '{}');
        const stagePartOneData = JSON.parse(localStorage.getItem('stageOne') || '{}');
        const policyID = stagePartOneData.policyId
        const stageFourDataValue = JSON.parse(localStorage.getItem('stageFourData') || '{}');

        this.setState({ policyId: policyID })




       
        if (loginData) {
            this.setState({ role: loginData.role })
        }

        if (id) {
            this.getDataByIdApiStagefiveCall(id)

        } else {

            this.initialOperationForm(stageFourDataValue?.attributes?.submitting_insurance_infos?.data)


        }
    }

    handleAccordian = () => {
   


        this.setState((prev: any) => ({
            ...prev,
            isExpandedAccordion: !prev.isExpandedAccordion
        }))
     

    }
    
    async getDataByIdApiCall(id: any) {

        this.getQuotationApi = this.apiCall({
           
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${id}?stage=6`,

        })
    }
    async getDataByIdStageFourApiCall(id: any) {

        this.getStageFourQuotationApi = this.apiCall({
            // contentType: 'multipart/form-data',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${id}?stage=4`,

        })
    }
    async getDataByIdApiStagefiveCall(id: any) {

        this.getStageFiveQuotationApi = this.apiCall({
            // contentType: 'multipart/form-data',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${id}?stage=5`,

        })
    }
  
    getInitialInsurerDetail = async (insurerData: any) => {
        if (insurerData.length > 0) {
            let array: {
                paid_to: string;
                payment_made_on: string;
                insurance_company_id: string;
                approval: boolean;
                quotationAmount: string;
                comment: string;
                label: string;
                release_quotation_to_customer: boolean;
                firm_order: any;
                vat_certificate: any;
                owner_id: any;
                facilities_credit: any;
                installment_form_filled_by_client: any;
                client_iban: any;
                note_to_the_customer: any;
                payment_proof: any;
                authorization_letter_for_other_person: any;
                authorized_person_id: any;
                health_declarations: any,
                sima_form: any,
                account_creation_form: any,

                proposal: any;
                proposal_file_name?: any;
                addtitional_attachment: any;
                addtitional_attachment_file_name?: any;
                dataId: string;


            }[] = [];
            /* istanbul ignore next */
            await insurerData.map((insurer: any, index: number) => {
                const item = insurer
                 
              let quotationAmountCondiiton =this.state.stageFiveData.attributes.release_insurance_infos.data[index].attributes.release_quotation_to_customer && item?.attributes?.quotation_received ? true:false
               
              array.push(
                    {
                        paid_to: "",
                        payment_made_on: "",
                        dataId: "",
                        insurance_company_id: item.attributes?.insurance_company_id?.id,
                        comment: this.state.stageFiveData.attributes?.release_insurance_infos.data[index]?.attributes?.comment,
                        note_to_the_customer: this.state.stageFiveData?.attributes?.release_insurance_infos?.data[index]?.attributes?.note_to_the_customer,
                        release_quotation_to_customer: quotationAmountCondiiton,
                        approval: false,
                        quotationAmount: item?.attributes?.quotation_amount,
                        label: item.attributes?.insurance_company_id?.short_name,
                        firm_order: {},
                        vat_certificate: {},
                        owner_id: {},
                        facilities_credit: {},
                        installment_form_filled_by_client: {},
                        client_iban: {},

                        payment_proof: {},
                        authorization_letter_for_other_person: {},
                        authorized_person_id: {},

                        proposal: this.state.stageFiveData?.attributes?.release_insurance_infos?.data[index]?.attributes?.proposal?.url,
                        proposal_file_name: this.state.stageFiveData?.attributes?.release_insurance_infos?.data[index]?.attributes?.proposal?.file_name,
                        addtitional_attachment: this.state.stageFiveData?.attributes?.release_insurance_infos?.data[index]?.attributes.additional_attachments?.url,
                        addtitional_attachment_file_name:this.state.stageFiveData?.attributes?.release_insurance_infos?.data[index]?.attributes.additional_attachments?.file_name,
                        health_declarations: {},
                        sima_form: {},
                        account_creation_form: {},
                    }
                )
            })
            this.setState({
                premiumCalculation: this.state.stageFourData?.attributes?.premium_calculation?.url,
                comparisionSheet: this.state.stageFourData?.attributes?.comparison_sheet?.url
            })
            return array
        } else {
            return this.state.stageSixDetail
        }
    }
    

    getFormDataFormat = (file_key: string, fileData: any, policyId: any, insu_comany_id: any) => {

        const formData = new FormData();

        formData.append("data[stage]", "6");
        formData.append("data[file_key]", file_key);
        formData.append("data[insurance_company_id]", insu_comany_id);
        formData.append("data[file]", fileData);


        return formData;
    }
    removeFile = (fileId: number) => {  
        this.removeFileAPI = this.apiCall({
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/remove_file`,
            method: 'PUT',
            contentType: 'application/json',
            body: { data: { file_id: fileId } },
        })
    }
    removeFileAPIReceivestageSix = (message:any) => {
      if (
          getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.removeFileAPI !== null &&
          this.removeFileAPI ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        )   {
        const responseJsonSix = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
          if (responseJsonSix.data) {
            this.formikRef.current.setFieldValue(this.fieldName,{})
            /*istanbul ignore next*/
            this.setState({
                alertType:'warning',
                showAlert:true,
                alertMessage:responseJsonSix?.data?.message, 
              })
          }
      }
    }
    uploadFileStageSix = async (fileName: string, fileData: any, policyId: any, insu_comany_id: any,fieldName?:any) => {       
        this.fieldName=fieldName
        const formData = this.getFormDataFormat(fileName, fileData, policyId, insu_comany_id);

        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';


        const header = {
            token
        };
        const requestMessageStageSixFile = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.uploadFileApiCall = requestMessageStageSixFile.messageId;

        requestMessageStageSixFile.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageStageSixFile.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_policy_request/policy_requests/${this.state.policyId}/file_upload`
        );
        requestMessageStageSixFile.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `PUT`
        );

        requestMessageStageSixFile.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        runEngine.sendMessage(requestMessageStageSixFile.id, requestMessageStageSixFile);
        return requestMessageStageSixFile.messageId;
    }
    getFormikValues = (formikRef: any) => {
        const formikValues = formikRef.current
        /*istanbul ignore next*/this.formikRef=formikRef
        return formikValues
    }
    handleDraftSubmit = async (action: string, formikRef: any) => {
        const formikValues = this.getFormikValues(formikRef);
        const formData = (this.props.isEdit || this.state.isDraftAPIUsed) ? this.formDataFormatforEdit(formikValues.values, true) : this.formDataFormat(formikValues.values, true);
        (this.props.isEdit || this.state.isDraftAPIUsed) ?
            await this.EditDraftQuotationApiCall(formData) :
            await this.DraftQuotationApiCall(formData)

    }
    DraftQuotationApiCall = async (values: any) => {
        this.draftQuotationApiCall = this.apiCall({
            type: 'FormData',
            method: 'POST',
            body: values,
            endPoint: `bx_block_policy_request/policy_requests?stage=6&id=${this.state.policyId}`,
        })
    }
    EditDraftQuotationApiCall = async (values: any) => {
        this.draftQuotationApiPutCall = this.apiCall({
            type: 'FormData',
            method: 'PUT',
            body: values,
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=6`,
        })
    }
    handleRejectReasonText = (inputText: string) => {
        this.setState({ rejectReasonText: inputText })
    }
    closeNoteDialog = () => {
        this.setState({ showNoteDialog: false,handOverNoteText:"", isBtnVisible: true })
    }
    openNoteDialog = () => {
        this.setState({ showNoteDialog: true, isBtnVisible: false })
    }
    openRejectDialog = () => {
        this.setState({ showRejectDialog: true })
    }
    closeRejectDialog = () => {
        this.setState({ showRejectDialog: false, isBtnVisible: true })
    }
    successCloseRejectDialog = async () => {
        this.closeRejectDialog();
        await this.trackRequest(this.state.policyId, 'reject_stage', this.state.rejectReasonText, this.state.moveToStep);
    }
    humanize(str:string) {
        let i, frags = str.split('_');
        for (i=0; i<frags.length; i++) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
      }
  handleHandOverNoteText = (inputText: string) => {
    this.setState({ handOverNoteText: inputText })
  }
    
    moveToStep(step: any) {
        
        this.setState({ moveToStep: step.value })
    }

    handleOkDialog = (value: string) => {
        this.trackRequest(this.state.policyId, 'next_stage', value, 'payment_review');
    }

    dateFormate(date: any) {
        return moment(date)?.format("yyyy-MM-DD")
    }
    getInsurerDetail = async (insurerData: any) => {
        try {


            if (insurerData.length > 0) {
                let array: {
                    paid_to: string;
                    payment_made_on: string;
                    dataId: string;
                    insurance_company_id: string;
                    approval: boolean;
                    quotationAmount: string;
                    comment: string;
                    label: string;
                    release_quotation_to_customer: boolean;
                    firm_order: any;
                    firm_order_file_name:any;
                    vat_certificate: any;
                    vat_certificate_file_name: any;
                    owner_id: any;
                    owner_id_file_name:any;
                    facilities_credit: any;
                    facilities_credit_file_name:any;
                    installment_form_filled_by_client: any;
                    installment_form_filled_by_client_file_name:any;
                    client_iban: any;
                    client_iban_file_name:any;

                    payment_proof: any;
                    payment_proof_file_name: any;
                    authorization_letter_for_other_person: any;
                    authorization_letter_for_other_person_file_name: any;
                    authorized_person_id: any;
                    authorized_person_id_file_name: any;

                    proposal: any;
                    proposal_file_name: any;
                    addtitional_attachment: any;
                    addtitional_attachment_file_name: any;
                    health_declarations: any,
                    health_declarations_file_name: any,
                    sima_form: any,
                    sima_form_file_name: any,
                    account_creation_form: any,
                    account_creation_form_file_name: any,
                    note_to_the_customer: any;
                    note_to_the_customer_file_name: any;


                }[] = [];

                await insurerData.map((insurer: any, index: number) => {
                    const item = insurer
                    let quotationAmountCondiiton =item.attributes.release_quotation_to_customer && item.attributes.quotation_received ? true:false
                   /* istanbul ignore next */ 
                    array.push(
                        {
                            paid_to: item?.attributes?.paid_to,
                            payment_made_on: this.dateFormate(item?.attributes?.payment_made_on),
                            dataId: item?.id,
                            insurance_company_id: item?.attributes?.insurance_company_id?.id,
                            comment: item.attributes?.comment,
                            release_quotation_to_customer: quotationAmountCondiiton,
                            approval: item.attributes.approval,
                            quotationAmount: item?.attributes?.quotation_amount,
                            label: item.attributes?.insurance_company_id?.short_name,
                            note_to_the_customer: item?.attributes?.note_to_the_customer,
                            firm_order: item?.attributes?.firm_order,
                            firm_order_file_name: item?.attributes?.firm_order?.file_name,
                            vat_certificate: item?.attributes?.vat_certificate,
                            vat_certificate_file_name: item?.attributes?.vat_certificate?.file_name,

                            owner_id: item?.attributes?.owner_id,
                            owner_id_file_name: item?.attributes?.owner_id?.file_name,
                            facilities_credit: item?.attributes?.facilities_credit,
                            facilities_credit_file_name: item?.attributes?.facilities_credit?.file_name,
                            installment_form_filled_by_client: item?.attributes?.installment_form_filled_by_client,
                            installment_form_filled_by_client_file_name: item?.attributes?.installment_form_filled_by_client?.file_name,
                            client_iban: item?.attributes?.client_iban,
                            client_iban_file_name: item?.attributes?.client_iban?.file_name,
                            payment_proof: item?.attributes?.payment_proof,
                            payment_proof_file_name: item?.attributes?.payment_proof?.file_name,
                            authorization_letter_for_other_person: item?.attributes?.authorization_letter_for_other_person,
                            authorization_letter_for_other_person_file_name: item?.attributes?.authorization_letter_for_other_person?.file_name,
                            authorized_person_id: item?.attributes?.authorized_person_id,
                            authorized_person_id_file_name: item?.attributes?.authorized_person_id?.file_name,

                            proposal: item?.attributes?.proposal?.url,
                            proposal_file_name: item?.attributes?.proposal?.file_name,
                            addtitional_attachment: item?.attributes?.addtitional_attachment?.url,
                            addtitional_attachment_file_name: item?.attributes?.addtitional_attachment?.file_name,
                            health_declarations: item?.attributes?.health_declarations,
                            health_declarations_file_name: item?.attributes?.health_declarations?.file_name,
                            sima_form: item?.attributes?.sima_form,
                            sima_form_file_name: item?.attributes?.sima_form?.file_name,
                            account_creation_form: item?.attributes?.account_creation_form,
                            account_creation_form_file_name: item?.attributes?.account_creation_form?.file_name,
                            note_to_the_customer_file_name: undefined
                        }
                    )
                })
                return array
            } else {
                return this.state.stageSixDetail
            }
        } catch (error) {
        }
    }

    async initialOperationForm(stageFourData: any = []) {

        const initial = await this.getInitialInsurerDetail(stageFourData)

        this.setState({
            stageSixDetail: initial,

        });

    }

    checkStageFourForm = (errors: any) => {

        if (Object.keys(errors).length === 0) {
            this.setState({ showDialog: true, errorSection: "" })
        } else {
            let errorSection = "";
            const keys = Object.keys(errors);

            const firstKey = keys[0];

            let errorValue = ""
            this.checkFormCondition(errors,firstKey,errorSection,errorValue)
            
        }
    }
    checkFormCondition =(errors:any,firstKey:any,errorSection:string,errorValue:string)=>{
        if ((firstKey === "stageSixDetails") || (firstKey === "branchDetail")) {
            const array = (firstKey === "stageSixDetails") ? errors.stageSixDetails : errors.branchDetail;

            for (const element of array) {
                if ((element !== null) && (element !== undefined) && typeof (element === 'object')) {
                    const obj = element;
                    const firstValue = Object.values(obj)[0] as string
                    errorValue = firstValue
                }
            }      

            errorSection = firstKey === "stageSixDetails" ? "p4" : "p2"
        } 

        this.setState({
            showAlert: true,
            alertMessage: errorValue,
            alertType: "error",
            expandedAccordion: "",
            errorSection: errorSection
        })
    }

    submitCustomerForm = (formikValues: any, draft: boolean) => {


        this.AddQuotationApiCall(formikValues, draft)



    }
    editFormData = (formikValues: any, draft: boolean) => {
        const queryParameter: any = new URLSearchParams(window.location.search);
        const formData = this.formDataFormatforEdit(formikValues, draft);
        const userData = JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = userData.token || '';
        const id: string = queryParameter.get('id') ? queryParameter.get('id') : this.state.policyId;


        const header = {
            token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.EditQuotationApi = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_policy_request/policy_requests/${id}?stage=6`

        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;

    }


    async AddQuotationApiCall(data: any, draft: boolean) {
        
        const formData = this.formDataFormat(data, false);
        const userData = JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = userData.token || '';


        const header = {
            token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addQuotationApi = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_policy_request/policy_requests?id=${this.state.policyId}&stage=6`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    }
    formDataFormat = (values: any, isDraft: boolean) => {
        const formData = new FormData();
        

        values.stageSixDetails?.forEach((item: any, index: number) => {
  



            formData.append(`data[quotation_approval][quotation_insurance_infos_attributes][${[index]}][paid_to]`, item.paid_to)
            formData.append(`data[quotation_approval][quotation_insurance_infos_attributes][${[index]}][payment_made_on]`, item.payment_made_on)
            formData.append(`data[quotation_approval][quotation_insurance_infos_attributes][${[index]}][insurance_company_id]`, item.insurance_company_id)
            formData.append(`data[quotation_approval][quotation_insurance_infos_attributes][${[index]}][approval]`, item.approval)

        })
        isDraft ? formData.append(`data[quotation_approval][save_as_draft]`, 'yes') : formData.append(`data[quotation_approval][save_as_draft]`, 'no')


        return formData;
    }
    formDataFormatforEdit = (values: any, isDraft: boolean) => {
        const formData = new FormData();
       

        values.stageSixDetails?.forEach((item: any, index: number) => {
            



            formData.append(`data[quotation_approval][quotation_insurance_infos_attributes][${[index]}][paid_to]`, item.paid_to)
            formData.append(`data[quotation_approval][quotation_insurance_infos_attributes][${[index]}][payment_made_on]`, item.payment_made_on)
            formData.append(`data[quotation_approval][quotation_insurance_infos_attributes][${[index]}][id]`, item.dataId)
            formData.append(`data[quotation_approval][quotation_insurance_infos_attributes][${[index]}][approval]`, item.approval)

        })
        isDraft ? formData.append(`data[quotation_approval][save_as_draft]`, 'yes') : formData.append(`data[quotation_approval][save_as_draft]`, 'no')

        
        return formData;
    }


    getStepName = (currentStep: number) => {
        switch (currentStep) {
            case (1):
                return "info_gathering"
            case (2):
                return "processing"
            case (3):
                return "awaiting"
            case (4):
                return "operations_review"
            case (5):
                return "sales_review"
            case (6):
                return "customer_review"
            default:
                return "";
        }
    }
    getStepNumberforReject = (currentStep: string) => {
        switch (currentStep) {
          case ("info_gathering"):
            return 1
          case ("processing"):
            return 2
          case ("awaiting"):
            return 3
          case ("operations_review"):
            return 4
          case ("sales_review"):
            return 5
          case ("customer_review"):
            return 6
          default:
            return "";
        }
      }
    apiCall(data: any) {

        const { contentType, method, endPoint, body, type } = data;
        const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
        const token = userData.token || "";

        let header: any = {
            token: token,
        };

        if (contentType) {
            header = {
                ...header,
                "Content-Type": contentType,

            }
        }

        const requestMessageStageSix = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageStageSix.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageStageSix.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessageStageSix.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== 'FormData' ?
        requestMessageStageSix.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            ) : requestMessageStageSix.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );

        runEngine.sendMessage(requestMessageStageSix.id, requestMessageStageSix);
        return requestMessageStageSix.messageId;
    }
    trackRequest = async (policyId: any, stageStatus: string, comment: string, currentStage: string) => {
    let rejectStaus = stageStatus.includes('reject') ? true :false
    this.setState({isReject:rejectStaus})
        {
            if(comment)  {this.trackRequestAPI = this.apiCall({
                contentType: 'application/json',
                method: 'POST',
                body: { data: { track_request: { stage_status: stageStatus, ...({ comment }), current_stage: currentStage } } },
                endPoint: `bx_block_policy_request/policy_requests/${policyId}/track_requests${rejectStaus ? '?stage=6' : ''}`,
            })} else{ this.trackRequestAPI =  this.apiCall({
                contentType: 'application/json',
                method: 'POST',
                body: { data: { track_request: { stage_status: stageStatus, current_stage: currentStage } } },
                endPoint: `bx_block_policy_request/policy_requests/${policyId}/track_requests`,
            })}
        }
    }

    getStageSixAlertMessaeg = (isDraft: boolean) => {
        const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
        let alertMessage = "";
        if (isDraft) {
          alertMessage = isArabic ? "تم حفظ بيانات طلب المراجعة كمسودة!" : "Data has been saved as draft!"
        } else {
          alertMessage = isArabic? "لقد تم نقل طلبك إلى مراجعة الدفع" : "Your request has been moved to Payment Review"
        }
        return alertMessage
      }

    setStageSixApiErrorMessage = (responseJson: any) => {
        if (!responseJson) return;
        let errorMessage = "Something went wrong"
        if (responseJson?.errors) {
          errorMessage = typeof (responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error || "Something went wrong";
        }
        this.setState({
          alertType: "error",
          alertMessage: errorMessage,
          showAlert: true,
          showDialog: false,
          showNoteDialog: false
        })
      };

     EditQuotationApiCall = async(message:any)=>{
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.EditQuotationApi != null &&
            this.EditQuotationApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                localStorage.removeItem("InstallmentPaid")
                this.setState({
                    showNoteDialog: true,
                })
                window.localStorage.setItem('stageSixData', JSON.stringify(responseJson.data));
                this.props.setStageData('stageSixData',responseJson.data);
            } else {
                if (responseJson.errors) {
                    this.setStageSixApiErrorMessage(responseJson)
                }
               
            }
        }
    }
    TrackApiRequestApiCall =async(message:any)=>{
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.trackRequestAPI != null &&
            this.trackRequestAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                if(this.state.isReject){
                    this.setState({
                        showAlert: true,
                        alertType: "success",
                        alertMessage: `Your request has been moved to ${this.humanize(this.state.moveToStep)}`
                    })
                    const setpNumber = this.getStepNumberforReject(this.state.moveToStep)
                    this.props.onReject(setpNumber);
                }else{
                    this.setState({
                        showAlert: true,
                        alertType: "success",
                        alertMessage: this.getStageSixAlertMessaeg(false),
                        showNoteDialog: false,
                        expandedAccordion: "",
                    })
                    window.localStorage.removeItem('stageSevenData');
                    this.props.onNext(6)
                }
               this.handleAccordian()
            } else {
                if (responseJson.errors) {
                    this.setStageSixApiErrorMessage(responseJson)
                }
               
            }
        }
    }
    DraftQuotationApiRequestCall =async(message:any)=>{if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.draftQuotationApiCall != null &&
        this.draftQuotationApiCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.data) {



            const insurerDetails = await responseJson.data.attributes;
            const indexWiseDetails = await insurerDetails.quotation_insurance_infos;

            const sixStage = await this.getInsurerDetail(indexWiseDetails.data);


            this.setState({
                stageSixDetail: sixStage,
                show: responseJson.meta.current_stage_view_permission_role,
                update: responseJson.meta.current_stage_update_permission_role,
                isDraftAPIUsed: true,
                showAlert: true,
                alertType: "success",
                alertMessage: this.getStageSixAlertMessaeg(true)


            });
          
            window.localStorage.setItem('stageSixData', JSON.stringify(responseJson.data));
            this.props.setStageData('stageSixData',responseJson.data);
        } else {
            if (responseJson.errors) {
                this.setStageSixApiErrorMessage(responseJson)
            }
           
        }

    }}
    GetQuotationApiCall =async(message:any)=>{if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.getQuotationApi != null &&
        this.getQuotationApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.data) {
            const insurerDetails = await responseJson.data.attributes;
            const indexWiseDetails = await insurerDetails.quotation_insurance_infos;

            const sixStage = await this.getInsurerDetail(indexWiseDetails.data);


            this.setState({
                stageSixDetail: sixStage,
                show: responseJson.meta.current_stage_view_permission_role,
                update: responseJson.meta.current_stage_update_permission_role,
                premiumCalculation: responseJson.data?.attributes?.premium_calculation?.url,
                comparisionSheet: responseJson.data?.attributes?.comparison_sheet?.url,
                // handOverNoteText:insurerDetails.handover_note,
            });

            window.localStorage.setItem('stageSixData', JSON.stringify(responseJson.data));
            this.props.setStageData('stageSixData',responseJson.data);

        } else {

            if (responseJson.errors) {
                this.setStageSixApiErrorMessage(responseJson)
            }

        }
    }}
    GetStageFiveQuotationApiCall =async(message:any)=>{if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.getStageFiveQuotationApi != null &&
        this.getStageFiveQuotationApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.data) {
            this.setState({
                stageFiveData: responseJson.data,
                isNextStageSbmitted: responseJson.data.attributes.is_next_stage_submitted,
                show: responseJson.meta.next_stage_view_permission_role,
                update: responseJson.meta.next_stage_create_permission_role,
                policyId:responseJson.data.attributes.policy_request_id

            })
            window.localStorage.setItem('stageFiveData', JSON.stringify(responseJson.data))
            if (responseJson.data.attributes.is_next_stage_submitted) { this.getDataByIdApiCall(responseJson.data.attributes.policy_request_id) } else { this.getDataByIdStageFourApiCall(responseJson.data.attributes.policy_request_id) }



        } else {


            if (responseJson.errors) {
                this.setStageSixApiErrorMessage(responseJson)
            }
            
        }
    }}
    GetStageFourQuotationApiCAll =async(message:any)=>{if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.getStageFourQuotationApi != null &&
        this.getStageFourQuotationApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.data) {
            this.setState({
                stageFourData: responseJson.data,
                policyId: responseJson.data.attributes.policy_request_id
            })

            window.localStorage.setItem('stageFourData', JSON.stringify(responseJson.data));
            this.props.setStageData('stageFourData',responseJson.data);

            this.initialOperationForm(responseJson.data.attributes.submitting_insurance_infos.data)


        } else {

            if (responseJson.errors) {
                this.setStageSixApiErrorMessage(responseJson)
            }
           
        }
    }}
    DraftQuotationApiPutRequestCall =async(message:any)=>{if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.draftQuotationApiPutCall != null &&
        this.draftQuotationApiPutCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.data) {
            this.setState({
                isDraftAPIUsed: true,
                showAlert: true,
                alertType: "success",
                alertMessage: this.getStageSixAlertMessaeg(true)
            })

        } else {
            if (responseJson.errors) {
                this.setStageSixApiErrorMessage(responseJson)
            }
          
        }

    }}
    UploadFileApiRequestCall =async(message:any)=>{if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.uploadFileApiCall != null &&
        this.uploadFileApiCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.data) {
        this.formikRef.current.setFieldValue(this.fieldName,responseJson.data.uploaded_file)
            const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
            this.setState({
                showAlert: true,
                alertMessage: isArabic ? "تم تحميل الملف بنجاح" : "File successfully uploaded",
                alertType: "success"
            })

        } else {

            if (responseJson.errors) {
                this.setStageSixApiErrorMessage(responseJson)
            }
        }
    }}
    AddQuotationApiRequiestCall =async(message:any)=>{if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.addQuotationApi != null &&
        this.addQuotationApi ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {


        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.data) {
            localStorage.removeItem('InstallmentPaid')
            const insurerDetails = await responseJson.data.attributes;
            const indexWiseDetails = await insurerDetails.quotation_insurance_infos;

            const sixStage = await this.getInsurerDetail(indexWiseDetails.data);


            this.setState({
                stageSixDetail: sixStage,
                isDraftAPIUsed: true,
                show: responseJson.meta.current_stage_view_permission_role,
                update: responseJson.meta.current_stage_update_permission_role
            });

            window.localStorage.setItem('stageSixData', JSON.stringify(responseJson.data));
            this.props.setStageData('stageSixData',responseJson.data);
            this.setState({
                showNoteDialog: true
            })

        } else {

            if (responseJson.errors) {
                this.setStageSixApiErrorMessage(responseJson)
            }
        }
    }};

    async receive(from: string, message: Message) {
        this.removeFileAPIReceivestageSix(message)
        this.EditQuotationApiCall(message)
        this.TrackApiRequestApiCall(message)
        this.GetQuotationApiCall(message)
        this.GetStageFiveQuotationApiCall(message)
        this.GetStageFourQuotationApiCAll(message)
        this.DraftQuotationApiRequestCall(message)
        this.DraftQuotationApiPutRequestCall(message)
        this.UploadFileApiRequestCall(message)
        this.AddQuotationApiRequiestCall(message)
    }
}
// Customizable Area End