// Customizable Area Start
import React from "react";
import { Container } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import StageThreeController, { Props } from "./StageThreeController";
import StageThreeForm from "../../../../components/src/Policy/StageThreeForm";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
});

export default class StageThree extends StageThreeController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container
          id="customerContainer"
          maxWidth="lg"
          dir={
            /* istanbul ignore next */ (window.localStorage.getItem("lang") ||
              "en") === "ar"
              ? "rtl"
              : "ltr"
          }
        >
          <StageThreeForm {...this.props} />
        </Container>
      </ThemeProvider>
    );
  }
}
// Customizable Area End
