// Customizable Area Start
import React from "react";
import { withTranslation } from "react-i18next";
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import StageFourController, { Props } from "./StageFourController";
import CustomButton from "../../../../../../components/src/Common/CustomButton";
import Toster from "../../../../../../components/src/Common/Toster";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, Typography } from "@material-ui/core";
import { Formik } from "formik";
import { ErrorOutlineRounded, ExpandMore } from "@material-ui/icons";
import FileUpload from "../../../../../../components/src/Common/FileUpload";
import LabelWithIcon from "../../../../../../components/src/Common/LabelWithIcon";
import TextInput from "../../../../../../components/src/Common/TextInput";
import HandOverDialog from "../../../../../../components/src/Common/HandOverDialog.web";
import RejectRequestDialog from "../../../../../../components/src/Common/RejectRequestDialog.web";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import * as Yup from "yup";
import RvRequestStageFourSchema from "../../../../../../components/src/Common/RevisionRequest/FormSchema/RvRequestStageFourSchema"

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
});
const enum_impact = {
    up: "up",
    down: "down",
    unchanged: "unchanged",

}
// Customizable Area End

export class StageFourForm extends StageFourController {

    formikRef: any;
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.formikRef = React.createRef();
        // Customizable Area End
    }

    // Customizable Area Start

    handleStageFourSubmitBtnFromOutside = (isDraft:boolean=false) => {
        const { current: formikInstance } = this.formikRef;
        if (formikInstance) {
            const updatedValues = { ...formikInstance.values };
            updatedValues.save_as_draft = isDraft ? "yes" : "no";
            formikInstance.setValues(updatedValues);
            if(isDraft){
                this.handleFormSubmit(updatedValues);
            }
            else{
                this.handleNextBtnClick()
                formikInstance.handleSubmit();
            }
        }
    }

    isCompleted = (isExpanded: boolean | undefined, showCompleteBtn: boolean, isCurrentStage: boolean ) => {
        return !isExpanded || (showCompleteBtn && isCurrentStage)
    }

    handleFormSubmit = (values: any) => {
        if(this.props.rrStageThreeData?.data.attributes.is_next_stage_submitted || this.state.isPostResponseCalledOnce) {
            this.stageFourEditSubmit(values)
           }
       else{
        this.stageFournextSubmit(values)
       }
    }
    // Customizable Area End

    render() {

        // Customizable Area Start

        const { t, classes, rrStageOneData, rrStageFourData }: Props = this.props;
        const {expandedAccordion,isArabicLanguage} =this.state
        const showCompleteBtn: boolean = rrStageOneData?.data?.attributes.revision_type.value === 'correct';

        // Customizable Area End

        return (
					// Customizable Area Start
					<ThemeProvider theme={theme}>
                <React.Fragment>
                    <Toster
                        id="alertMessageStageFour"
                        tosterText={this.state.alertMessage}
                        showToster={this.state.showAlert}
                        tosterType={this.state.alertType}
                        handleCloseToster={() => this.setState({ showAlert: false, alertMessage:"", alertType:"" })}
                    />
                    <div className={classes.customerDetailsAccordionStageFour}>
                        <Accordion data-test-id='p4' id="p4-accordion" className={classes.accordionStyleStageFour} expanded={expandedAccordion}
                            onChange={() => this.handleAccordianStageFour()} >
                            <AccordionSummary
                                aria-expanded={true}
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={classes.submitingRevisionTopSection}>
                                <Typography className={classes.accordianHeadingStageFour} >{t('submiting_revision')}</Typography>
                               <div style={{display: 'flex'}}>
                                {
                                    this.state.expandedAccordion &&
                                    (<div data-test-id="noteDialog" className={classes.handoverNoteStageFourIcon} onClick={() => this.openNoteDialogStageFour()}>
                                        <DescriptionOutlinedIcon />
                                    </div>)
                                }
                                {this.checkCondition() &&
                                    <ErrorOutlineRounded style={{position: 'relative', left: '8px', color: '#0090a1'}} onClick={() => this.setState({showRejectDialog: true, isBtnVisible: false})}  />
                                }
                               </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Formik
                                    innerRef={this.formikRef}
                                    enableReinitialize={true}
                                    initialValues={this.state.initialData}
                                    validationSchema={Yup.object().shape(
                                        RvRequestStageFourSchema(this.checkActiveLanguage())
                                    )}
                                    onSubmit={async (values, actions)  => {this.handleFormSubmit(values)}}
                                >

                                    {({
                                        handleSubmit,
                                        errors,
                                        touched,
                                        values,
                                        setFieldValue,
                                        validateForm
                                    }) => (
                                        <React.Fragment>
                                            <Grid container spacing={2}>
                                                 <Grid item xs={4}>
                                                    <>
                                    <LabelWithIcon label={t("revision_number")} />
                                    <TextInput
                                        id={`revision_number`}
                                        className={classes.textInputStyleStageFour}
                                        isArabicLenguage={isArabicLanguage}
                                        type="text"
                                        disabled={this.isCompleted(this.props.isExpanded, showCompleteBtn,rrStageFourData?.data?.attributes.is_current_stage_submitted)}
                                        name={`revision_number`}
                                        data-test-id="revision_number"
                                    />
                                    <Typography color="error" >{this.getErrorMessage(touched,errors,"revision_number")}</Typography>
                                    </>
                                </Grid>
                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t("revision_date")} />
                                    <TextInput
                                        id={`revision_date`}
                                        className={classes.textInputStyleStageFour}
                                        isArabicLanguage={isArabicLanguage}
                                        type="date"
                                        disabled={this.isCompleted(this.props.isExpanded, showCompleteBtn,rrStageFourData?.data?.attributes.is_current_stage_submitted)}
                                        name={`revision_date`}
                                        />
                                        </>
                                        <Typography color="error" >{this.getErrorMessage(touched,errors,"revision_date")}</Typography>
                                    </Grid>


                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t('revision_confirmation')} />
                                    <FileUpload
                                     fileName={values.revision_confirmation?.file_name || ""}
                                     eachFileSizeInMB={10}
                                     disabled={this.isCompleted(this.props.isExpanded, showCompleteBtn,rrStageFourData?.data?.attributes.is_current_stage_submitted)}
                                     filePath={values.revision_confirmation?.url || ""}
                                     data-test-id="revision_confirmation"
                                     inputName={`revision_confirmation`}
                                     onChange={(event: any) => {
                                        setFieldValue("revision_confirmation", event.target.files[0])                                                                                    }
                                     }
                                     onRemove={async() => {
                                        /*istanbul ignore next*/
                                         values.revision_confirmation?.url&&  this.removeFileStageFour(values?.revision_confirmation?.id)
                                         setFieldValue(`revision_confirmation`, {})
                                     }}

                                    />
                                    <Typography color="error" >{ this.getErrorMessage(touched,errors,"revision_confirmation")}</Typography>
                                    </>
                                </Grid>
                                <Grid item xs={6}>
                               <>
                               <LabelWithIcon label={t('impact_of_revision_to_policy_value')} />
                               <FormControl component="fieldset">

                                        <RadioGroup className={classes.radioBtnContainerStageFour} name={`policy_impact`} value={values.policy_impact} ata-test-id="policy_impact_radio" onChange={(event: any) => setFieldValue(`policy_impact`, event.target.value)}>
                                            <FormControlLabel value={enum_impact.up} disabled={true} control={<Radio color="primary" />} label={t("up")} />
                                            <FormControlLabel disabled={true} value={enum_impact.down} control={<Radio color="primary" />} label={t("down")} />
                                            <FormControlLabel disabled={true} value={enum_impact.unchanged} control={<Radio color="primary" />} label={t("unchanged")} />
                                        </RadioGroup>
                                        <Typography color="error" >{this.getErrorMessage(touched,errors,"policy_impact")}</Typography>
                                    </FormControl>
                         </>


                                </Grid>

                                <Grid item xs={6}></Grid>

                               {values.policy_impact =="up" && (<>
                                <Grid xs={4} item >
                                    <LabelWithIcon label={t('invoice_number')} />
                                    <TextInput
                                        disabled={!this.props.isExpanded}
                                        id={`invoice_number`}
                                        className={classes.textInputStyleStageFour}
                                        isArabicLenguage={isArabicLanguage}
                                        type="text"
                                        name="invoice_number"

                                        data-test-id="invoice_number"
                                    />
                                    <Typography color="error" >{this.getErrorMessage(touched,errors,"invoice_number")}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t('invoice_date')} />
                                    <TextInput
                                        id={`invoice_date`}
                                        disabled={!this.props.isExpanded}
                                        className={classes.textInputStyleStageFour}
                                        isArabicLanguage={isArabicLanguage}
                                        type="date"
                                        name="invoice_date"
                                        />
                                        <Typography color="error" >{this.getErrorMessage(touched,errors,"invoice_date")}</Typography>
                                 </>
                                    </Grid>
                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t('invoice')} />
                                        <FileUpload
                                            disabled={!this.props.isExpanded}
                                            fileName={values.revision_invoice?.file_name || ""}
                                            inputName={`revision_invoice`}
                                            filePath={values.revision_invoice?.url || ""}
                                            eachFileSizeInMB={10}
                                            data-test-id="revision_invoice"
                                            onChange={(event: any) => {
                                                setFieldValue("revision_invoice", event.target.files[0])
                                            }
                                            }
                                           
                                            onRemove={/*istanbul ignore next*/() => {
                                                values.revision_invoice?.url&&  this.removeFileStageFour(values?.revision_invoice?.id)
                                                setFieldValue(`revision_invoice`, {})
                                            }}

                                        />
                                        <Typography color="error" >{this.getErrorMessage(touched,errors,"revision_invoice")}</Typography>
                                    </>
                                </Grid>
                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t('invoice_amount')} />
                                    <TextInput
                                        id={`invoice_amount`}
                                        disabled={!this.props.isExpanded}
                                        className={classes.textInputStyleStageFour}
                                        isArabicLanguage={isArabicLanguage}
                                        data-test-id="invoice_amount"
                                        name={"invoice_amount"}
                                        type="number"
                                        onBlur={()=>{
                                            let newPolicyVAlue = Number(this.props.rrStageOneData.data.attributes.current_policy_value) + Number(values.invoice_amount)
                                            setFieldValue("new_policy_value",newPolicyVAlue)
                                            this.props.setStageData('invoiceAmount', Number(values.invoice_amount))
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <div> &nbsp;SAR&nbsp;</div>
                                            </InputAdornment>
                                        }
                                        />
                                        <Typography color="error" >{this.getErrorMessage(touched,errors,"invoice_amount")}</Typography>
                                        </>
                                </Grid>
                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t('new_policy_value')} />
                                    <TextInput
                                        name ={"new_policy_value"}
                                        id={`new_policy_value`}
                                        disabled={true}
                                        data-test-id="new_policy_value_up"
                                        className={classes.texnew_policy_valuetInputStyle}
                                        isArabicLanguage={isArabicLanguage}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <div> &nbsp;SAR&nbsp;</div>
                                            </InputAdornment>
                                        }
                                        />
                                        <Typography color="error" >{this.getErrorMessage(touched,errors,"new_policy_value")}</Typography>
                                    </>
                                </Grid>

                                </> )}
                                {values.policy_impact =="down" &&
                                (<>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('credit_note_number')} />
                                    <TextInput
                                        id={`credit_note_number`}
                                        disabled={!this.props.isExpanded}
                                        className={classes.textInputStyleStageFour}
                                        isArabicLenguage={isArabicLanguage}
                                        type="text"
                                        name="credit_note_number"
                                        data-test-id="revsion_date"
                                    />
                                    <Typography color="error" >{this.getErrorMessage(touched,errors,"credit_note_number")}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('credit_note_date')} />
                                    <TextInput
                                        id={`credit_note_date`}
                                        disabled={!this.props.isExpanded}
                                        className={classes.textInputStyleStageFour}
                                        isArabicLanguage={isArabicLanguage}
                                        type="date"
                                        name="credit_note_date"
                                        onChangeText = {(event: any) => {}}
                                        />
                                        <Typography color="error" >{this.getErrorMessage(touched,errors,"credit_note_number")}</Typography>
                                    </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('credit_note')} />
                                    <FileUpload
                               filePath={values.invoice?.url || ""}
                               disabled={!this.props.isExpanded}
                               fileName={values.invoice?.file_name || ""}
                               inputName={`revision_credit_note`}
                                 eachFileSizeInMB={10}
                                data-test-id="revision_credit_note"
                                onChange={(event: any) => {
                                        setFieldValue("revision_credit_note", event.target.files[0])                                                                                    }
                                     }
                                     onRemove={async() => {
                                        /*istanbul ignore next*/
                                        values.invoice?.url&&  this.removeFileStageFour(values?.invoice?.id)
                                        setFieldValue(`revision_credit_note`, {})
                                    }}

                                    />
                                    <Typography color="error" >{this.getErrorMessage(touched,errors,"revision_credit_note")}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('credit_note_amount')} />
                                    <TextInput
                                        id={`credit_note_amount`}
                                        disabled={!this.props.isExpanded}
                                        className={classes.textInputStyleStageFour}
                                        isArabicLanguage={isArabicLanguage}
                                        name={"credit_note_amount"}
                                        data-test-id="credit_note_amount"
                                        type="number"
                                        onBlur={()=> setFieldValue("new_policy_value", Number(this.props.rrStageOneData.data.attributes.current_policy_value) - Number(values.credit_note_amount) )}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <div> &nbsp;SAR&nbsp;</div>
                                            </InputAdornment>
                                        }
                                        />
                                        <Typography color="error" >{this.getErrorMessage(touched,errors,"credit_note_amount")}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t('new_policy_value')} />
                                    <TextInput
                                        name ={"new_policy_value"}
                                        id={`new_policy_value`}
                                        data-test-id="new_policy_value_down"
                                        disabled={true}
                                        className={classes.texnew_policy_valuetInputStyle}
                                        isArabicLanguage={isArabicLanguage}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <div> &nbsp;SAR&nbsp;</div>
                                            </InputAdornment>
                                        }
                                        />
                                        <Typography color="error" >{this.getErrorMessage(touched,errors,"new_policy_value")}</Typography>
                                    </>
                                </Grid>

                                </>)}
                                <Grid item xs={12}>
                            <LabelWithIcon label={t('comment')} />
                            <TextInput
                                id={`comment`}
                                disabled={this.isCompleted(this.props.isExpanded, showCompleteBtn,rrStageFourData?.data?.attributes.is_current_stage_submitted)}
                                className={classes.textInputStyleStageFour}
                                isArabicLenguage={isArabicLanguage}
                                name={`comment`}
                                type="text"
                                data-test-id={`comment`}
                                multiline
                                minRows={4}
                                maxRows={4}
                            />
                            <Typography color="error" >{this.getErrorMessage(touched,errors,"comment")}</Typography>
                        </Grid>

                                </Grid>

                                        </React.Fragment>
                                    )}

                                </Formik>

                            </AccordionDetails>
                        </Accordion>

                                {this.props.isExpanded && this.state.expandedAccordion && !(showCompleteBtn && /* istanbul ignore next*/rrStageFourData?.data?.attributes.is_current_stage_submitted) && <div className={classes.BtnGroupStageFour}>
                                        <div>

                                        </div>
                                        <div className={classes.buttonsRightWrapperStageFour}>

                                            <CustomButton
                                                btnText={t('save_draft')}
                                                onClick={() => {this.handleStageFourSubmitBtnFromOutside(true)}}
                                                textColor="#2d6f8f"
                                                bgColor="white"
                                                loading={this.state.saveDraftLoading}
                                                data-test-id="saveDraftBtnStageFour"
                                            />
                                            <CustomButton
                                                data-test-id="rv_reject_btnstageFour"
                                                btnText={t('reject')}
                                                onClick={() => this.openRejectDialogStageFour()}
                                                bgColor={'#fff'}
                                                textColor={'#007E98'}
                                            />

                                            {showCompleteBtn ?
                                                <CustomButton
                                                data-test-id="rv_complete_btn"
                                                btnText={t("complete")}
                                                onClick={() => this.handleStageFourSubmitBtnFromOutside()}
                                                bgColor={"#007E98"}
                                                textColor={"#fff"}
                                                /> : <CustomButton
                                                btnText={t('next_stage')}
                                                onClick={() => {this.handleStageFourSubmitBtnFromOutside(false)}}
                                                textColor="white"
                                                loading={this.state.loading}
                                                bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                                data-test-id="nextStageFourBtn"
                                            />
                                            }
                                        </div>


                                    </div>}

                    </div>
                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showHandOverNote}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={`(${t('awaiting')} > ${t('operations_review')})`}
                        handleInputText={this.handleHandOverNoteTextStageFour}
                        inputValue={this.props.handOverNoteForOperationsReview}
                        handleOk={this.closeNoteDialogStageFour}
                        inputDisable={!this.props.isExpanded}
                        handleCancel={this.closeNoteDialogStageFour}
                        showDialogBtns={false}
                        showCrossBtn={true}
                    />


                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        data-test-id="nextStageDialog"
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNextDialog}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={`(${t('operations_review')} > ${t('customere_review')})`}
                        handleInputText={(value:string) => this.handleHandOverNoteTextStageFour(value)}
                        inputValue={this.state.handOverNoteText}
                        handleOk={() => this.trackRequestStageFour("next")}
                        inputDisable={!this.props.isExpanded}
                        handleCancel={this.closeNextDialogStageFour}
                        showDialogBtns={true}
                        showCrossBtn={true}
                    />


                    <RejectRequestDialog
                        data-test-id="rejectDialog"
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showRejectDialog}
                        textId={'rejectReason'}
                        pushBackOptions={this.getRvRequestRejectOptions()}
                        inputValue={this.state.isBtnVisible? this.state.rejectReasonText : rrStageFourData?.data?.attributes?.rejected_reason}
                        setSelectedPushBackTo={this.moveToSelectedStepStageFour}
                        stepValue={this.state.moveToStep}
                        headingText={t('reject')}
                        handleInputText={this.handleRejectReasonTextStageThree}
                        handleOk={() => this.trackRequestStageFour("reject")}
                        handleCancel={this.closeRejectDialogStageFour}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                        labelText={t('push_back')}
                        reasonLabel={t('reason')}
                    />
                </React.Fragment>
            </ThemeProvider>
					// Customizable Area End
        );
    }
}
// Customizable Area Start
const useStyles = ({
    customerDetailsAccordionStageFour: {
        paddingTop: "10px",
        paddingBottom: "10px",
        "& .MuiAccordion-root-34.Mui-disabled, .MuiAccordion-root-42.Mui-disabled": {
            backgroundColor: "white",
        },
        " & .MuiAccordion-root.Mui-disabled": {
            backgroundColor: "white",
        }
    },
    accordionStyleStageFour: {
        background: "white !important",
        "border-radius": "10px !important"
    },
    accordianHeadingStageFour: {
        fontWeight: 600
    },
    BtnGroupStageFour: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 15px'
    },
    buttonsRightWrapperStageFour: {
        display: 'flex',
        gap: '15px',
        padding: '15px 0px',
        justifyContent: 'flex-end'
    },
    textInputStyleStageFour: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    radioBtnContainerStageFour: {
        display: "flex",
        "flex-direction": "row"
    },
    handoverNoteStageFourIcon: {
        color: '#007E98'
    },
    submitingRevisionTopSection:{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    }
});
// Customizable Area End

export default withStyles(useStyles as any)(withTranslation()(StageFourForm));
// Customizable Area End