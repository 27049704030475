import { makeStyles } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import React from "react";
import Select from "react-select";
import { StylesConfig } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";


const useStyles = makeStyles({
    selectError: {
        '& > div': {
            borderColor: 'red',
            backgroundColor: '#fff',
        }
    },
    selectRemoveicon: {
        '& [role="button"]': {
            display: 'none'
        }
    }
})

type MyOption = {
    label: string;
    value: any;
  };

  type GroupedOption = {
    label: string; // group label
    options: MyOption[];
  };

  type Props = {
    name: string;
  } & Omit<
    StateManagerProps<MyOption, false | true, GroupedOption>,
    "value" | "onChange"
  >;

const colourStyles: StylesConfig = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', marginTop: "10px", marginBottom: "10px", height: '55px' }),
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: "#cbdeec",
        };
    },
};

const SelectBoxFormik = (props: any) => {
    const classes = useStyles();
    const { name, ...restProps } = props;
    const [field] = useField(name);
    const { setFieldValue } = useFormikContext();

    //flatten the options so that it will be easier to find the value
    const flattenedOptions = props.options?.flatMap((o:any) => {
      const isNotGrouped = "value" in o;
      if (isNotGrouped) {
        return o;
      } else {
        return o.options;
      }
    });

    //get the value using flattenedOptions and field.value
    const value = flattenedOptions?.filter((o:any) => {
      const isArrayValue = Array.isArray(field.value);

      if (isArrayValue) {
        const values = field.value as Array<any>;
        return values.includes(o.value);
      } else {
          return field.value === o.value;
      }
    });

    return (
      <Select
        {...restProps}
        value={value || ''}
        isDisabled={props.disabled}
        styles={colourStyles}
        className={`${props.showError ? classes.selectError : ''} ${props.disable ? classes.selectRemoveicon : ''}`}
        onChange={(val) => {
          //here I used explicit typing but there maybe a better way to type the value.
          const _val = val as MyOption[] | MyOption;
          const isArray = Array.isArray(_val);
          if (isArray) {
            // @ts-ignore
            const values = _val.map((o) => o.value);
            // @ts-ignore
            setFieldValue(name, values);
          } else {
            // @ts-ignore
            setFieldValue(name, _val.value);
          }
        }}
      />
    );
}

export default SelectBoxFormik