// Customizable Area Start
import React, { ReactNode } from "react";
import {
  withStyles,
} from "@material-ui/core/styles";
import StageNineController, {
  Props,
} from "./StageNineController";
import {
  Grid,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
} from "@material-ui/core";

import { withTranslation } from "react-i18next";
import LabelWithIcon from "../../../../../components/src/Common/LabelWithIcon";
import TextInput from "../../../../../components/src/Common/TextInput";
import BorderedFileUpload from "../../../../../components/src/Common/BorderedFileUpload";
import FileUpload from "../../../../../components/src/Common/FileUpload";
import Toster from "../../../../../components/src/Common/Toster";
import { ErrorMessage } from 'formik';
import DownloadFile from "../../../../../components/src/Common/DownloadFile";
class StageNineInstalmentForm extends StageNineController {

  render(): ReactNode {// NOSONAR: typescript:S3776
    const { t, currentFlow, classes, values, setFieldValue, installmentIndex, stageSevenData, formikRef, insurance_company_id, isDraft }: Props = this.props;
    const stageNineData = JSON.parse(window.localStorage.getItem("stageNineData") ?? "{}")
    /* istanbul ignore next*/
    const siibId = stageNineData?.attributes?.siib_commission?.data?.[installmentIndex + 1]?.id && !isDraft;
    /* istanbul ignore next*/
    const salesId = stageNineData?.attributes?.sales_commission?.data?.[installmentIndex + 1]?.id && !isDraft;
    /* istanbul ignore next*/
    const creditNoteId = stageNineData?.attributes?.credit_note?.data?.[installmentIndex + 1]?.id && !isDraft;

    const getTitle = (fieldName: string) => {
      return `installment_attributes[${installmentIndex}].${fieldName}`
    }

    const validateInstallment = () => {
      if (stageSevenData.attributes?.installments?.data[installmentIndex]?.attributes?.installment_paid) return false
      else return true
    }

    return (
      <React.Fragment>
        <Toster
          tosterText={this.state.alertMessage}
          showToster={this.state.showAlert}
          // @ts-ignore
          tosterType={this.state.alertType}
          handleCloseToster={() => this.setState({ showAlert: false })}
        />
        <Grid className={classes.contactMainForm}>
          {
            (currentFlow != 9) && <React.Fragment>
              <Typography className={classes.policyStyleTitle}>
                {t("siib_commision")}
              </Typography>
              <Grid
                className={classes.policyDetailsGrid}
                container
                spacing={2}
                style={{ borderBottom: '3px solid #eaeaea', marginBottom: 20 }}
              >
                <Grid item xs={4}>
                  <LabelWithIcon label={t("commision_rate")} />
                  <TextInput
                    data-test-id={"commisionRate"}
                    className={classes.textInputStyle}
                    isArabicLanguage={this.state.isArabicLenguage}
                    name={getTitle(`commisionRate`)}
                    value={values?.installment_attributes[installmentIndex]?.commisionRate}
                    endAdornment={
                      <InputAdornment position="end">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          %
                        </div>
                      </InputAdornment>
                    }
                    onChange={(e: any) => {
                      setFieldValue(getTitle('commisionRate'), e.target.value)
                      setFieldValue(getTitle('commisionAmount'), (((/* istanbul ignore next*/stageSevenData?.attributes?.installments?.data[installmentIndex]?.attributes?.installment_amount ?? 0)/1.15) * (e.target.value / 100)).toFixed(2))
                    }}
                    disabled={validateInstallment() || siibId}
                  />
                  <ErrorMessage
                    data-test-id="commisionRate"
                    name={getTitle('commisionRate')}
                    className="field-error"
                    render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                </Grid>
                <Grid item xs={4}>
                  <LabelWithIcon label={`${t("commision_amount")} (${t('without_vat')})`} />
                  <TextInput
                    id={"commisionAmount"}
                    type="number"
                    disabled={true}
                    name={getTitle(`commisionAmount`)}
                    className={classes.textInputStyle}
                    isArabicLanguage={this.state.isArabicLenguage}
                    value={values?.installment_attributes[installmentIndex]?.commisionAmount}
                    data-test-id="commisionAmount"
                  />
                </Grid>
                <Grid item xs={4}>
                  <LabelWithIcon label={t("invoice_date")} />
                  <TextInput
                    data-test-id={`invoiceDate`}
                    id={`policyExpireDateTxtInput`}
                    className={classes.textInputStyle}
                    name={getTitle(`invoiceDate`)}
                    type={"date"}
                    value={values?.installment_attributes[installmentIndex]?.invoiceDate}
                    onChange={(e: any) => setFieldValue(getTitle('invoiceDate'), e.target.value)}
                    disabled={validateInstallment() || siibId}
                  />
                  <ErrorMessage
                    data-test-id="invoiceDate"
                    name={getTitle('invoiceDate')}
                    className="field-error"
                    render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    data-test-id="subInstallmentBtn"
                    disabled={this.checkInvoiceIsDisabled(values, installmentIndex)}
                    className={this.checkInvoiceIsDisabled(values, installmentIndex) ? classes.grayBtn : classes.primaryRvBtn}
                    aria-haspopup="true"
                    aria-controls="customized-menu"
                    variant="contained"
                    color="primary"
                    onClick={/* istanbul ignore next*/() => {
                      this.generateInvoiceForOtherInstallment(values, installmentIndex, formikRef, insurance_company_id)
                    }}
                  >
                    {!this.state.invoiceBtnLoding && t('create_invoice')}
                    {this.state.invoiceBtnLoding && (<CircularProgress size={25} />)}
                  </Button>
                  <ErrorMessage
                    data-test-id="siibInvoiceId"
                    name={getTitle('siibInvoiceId')}
                    className="field-error"
                    render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                </Grid>

                <Grid item xs={4}>
                  <LabelWithIcon label={t("invoice")} />
                  <DownloadFile
                    data-test-id="installmentInoiceDoc"
                    disabled={true}
                    eachFileSizeInMB={10}
                    maxFilesNumber={5}
                    fileName={values?.installment_attributes[installmentIndex]?.invoiceDoc?.file_name || ""}
                    isEditPage={true}
                    filePath={values?.installment_attributes[installmentIndex]?.invoiceDoc?.url || ""}
                    inputName={"invoiceDoc"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <LabelWithIcon label={t("ssib_invoice_id")} />
                  <TextInput
                    data-test-id={`siibInvoiceId`}
                    id={`siibInvoiceId`}
                    className={classes.textInputStyle}
                    name={getTitle(`siibInvoiceId`)}
                    type={"text"}
                    disabled={true}
                    value={values?.installment_attributes[installmentIndex]?.siibInvoiceId}
                  />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <LabelWithIcon label={t('invoice_paid')} />
                  <FormControlLabel
                    data-test-id={`invoicePaid`}
                    className={classes.checkBoxInput}
                    color="primary"
                    name={getTitle(`invoicePaid`)}
                    control={<Checkbox color="primary"
                      checked={values?.installment_attributes[installmentIndex]?.invoicePaid}
                      onChange={/* istanbul ignore next*/(e: any) => {
                        setFieldValue(getTitle(`invoicePaid`), e.target.checked)
                      }
                      }
                      disabled={validateInstallment() || siibId}
                    />
                    }
                    label={t('yes')}
                  />
                  <ErrorMessage
                    data-test-id="invoicePaid"
                    name={getTitle('invoicePaid')}
                    className="field-error"
                    render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                </Grid>
                <Grid xs={4} item>
                  <LabelWithIcon label={t("payment_date")} />
                  <TextInput
                    data-test-id={`paymentDate`}
                    id={`paymentDate`}
                    className={classes.textInputStyle}
                    name={getTitle(`paymentDate`)}
                    type={"date"}
                    value={values?.installment_attributes[installmentIndex]?.paymentDate}
                    onChange={(e: any) => setFieldValue(getTitle('paymentDate'), e.target.value)}
                    disabled={validateInstallment() || siibId}
                  />
                  <ErrorMessage
                    data-test-id="paymentDate"
                    name={getTitle('paymentDate')}
                    className="field-error"
                    render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                </Grid>
                <Grid xs={4} item>
                  <LabelWithIcon label={t("upload_proof_of_payment_recevied")} />
                  <FileUpload
                    eachFileSizeInMB={10}
                    maxFilesNumber={5}
                    fileName={values?.installment_attributes[installmentIndex]?.proofOfPaymentReceviedDoc?.file_name || ""}
                    filePath={values?.installment_attributes[installmentIndex]?.proofOfPaymentReceviedDoc?.url || ""}
                    data-test-id="proofOfPaymentReceviedDoc"
                    inputName={`proofOfPaymentReceviedDoc`}
                    onChange={(event: any) => this.uploadFileDoc('upload_proof_of_payment_recevied', event.target.files[0], formikRef, `installment_attributes[${installmentIndex}].proofOfPaymentReceviedDoc`, stageSevenData?.attributes?.installments?.data[Number(installmentIndex)]?.id)}
                    onRemove={async() => {setFieldValue(`proofOfPaymentReceviedDoc`, "")
                     this.removeFileStageNine(values?.installment_attributes[installmentIndex]?.proofOfPaymentReceviedDoc?.id)
                    }} 
                    disabled={validateInstallment() || siibId}
                  />
                  <ErrorMessage
                    data-test-id="proofOfPaymentReceviedDoc"
                    name={getTitle('proofOfPaymentReceviedDoc')}
                    className="field-error"
                    render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                </Grid>
              </Grid>
            </React.Fragment>
          }

          {/* sales commision */}
          <Grid />
          <Typography className={classes.policyStyleTitle}>
            {t("sales_commision")}
          </Typography>
          <Grid
            className={classes.policyDetailsGrid}
            container
            spacing={2}
          >
            <Grid item xs={4}>
              <LabelWithIcon label={t("commision_rate")} />
              <TextInput
                id={"salesCommisionRate"}
                data-test-id={"salesCommisionRate"}
                className={classes.textInputStyle}
                isArabicLanguage={this.state.isArabicLenguage}
                name={getTitle(`salesCommisionRate`)}
                endAdornment={
                  <InputAdornment position="end">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      %
                    </div>
                  </InputAdornment>
                }
                onChange={(e: any) => {
                  const commission = (currentFlow != 9) ? Number(values?.installment_attributes[installmentIndex].commisionAmount) : Number(stageSevenData.attributes.installments?.data[installmentIndex]?.attributes?.commission?.data?.attributes?.commission_amount)
                  setFieldValue(getTitle('salesCommisionRate'), e.target.value)
                  if (stageSevenData.attributes.installments?.data[installmentIndex]?.attributes?.commission?.data?.attributes?.deduct_commission_with_vat === 'yes')                   setFieldValue(getTitle('salesCommisionAmount'), (((commission/1.15) * e.target.value) / 100).toFixed(2))
                  else setFieldValue(getTitle('salesCommisionAmount'), ((commission * e.target.value) / 100).toFixed(2))
                }}
                disabled={validateInstallment() || salesId}
              />
              <ErrorMessage
                data-test-id="salesCommisionRate"
                name={getTitle('salesCommisionRate')}
                className="field-error"
                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
            </Grid>
            <Grid item xs={4}>
              <LabelWithIcon label={`${t("commision_amount")} (${t('without_vat')})`} />
              <TextInput
                type="number"
                id={"salesCommisionAmount"}
                data-test-id="salesCommisionAmount"
                className={classes.textInputStyle}
                disabled={true}
                name={getTitle(`salesCommisionAmount`)}
                value={values?.installment_attributes[installmentIndex]?.salesCommisionAmount}
                isArabicLanguage={this.state.isArabicLenguage}
              />
            </Grid>
            <Grid item xs={4}>
              <LabelWithIcon label={t("credit_note_date")} />
              <TextInput
                data-test-id={`creditNoteDate`}
                id={`creditNoteDate`}
                className={classes.textInputStyle}
                name={getTitle(`creditNoteDate`)}
                type={"date"}
                value={values?.installment_attributes[installmentIndex]?.creditNoteDate}
                onChange={(e: any) => setFieldValue(getTitle('creditNoteDate'), e.target.value)}
                disabled={validateInstallment() || creditNoteId}
              />
              <ErrorMessage
                data-test-id="creditNoteDate"
                name={getTitle('creditNoteDate')}
                className="field-error"
                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
            </Grid>
            <Grid item xs={4}>
              <LabelWithIcon label={t("sales_person")} />
              <TextInput
                type={"text"}
                id={`salesPerson`}
                data-test-id={`salesPerson`}
                disabled={true}
                name={getTitle(`salesPerson`)}
                className={classes.textInputStyle}
                value={values?.installment_attributes[installmentIndex]?.salesPerson}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => this.createCreditNoteForInstallments(values, insurance_company_id, installmentIndex, formikRef)}
                className={this.checkCreateNoteIsDisabled(values,installmentIndex) ? classes.salesGrayBtnPayment : classes.salesPrimaryBtn}
                disabled={this.checkCreateNoteIsDisabled(values,installmentIndex)}
                aria-haspopup="true"
                aria-controls="customized-menu"
                data-test-id="createCreditNoteBtn"
              >
                {!this.state.isCreditNoteBtnLoading && t('create_credit_note')}
                {this.state.isCreditNoteBtnLoading && (<CircularProgress size={25} />)}
              </Button>
              <ErrorMessage
                data-test-id="salesCreditNoteId"
                name={getTitle('salesCreditNoteId')}
                className="field-error"
                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <LabelWithIcon label={t("credit_note")} />
              <DownloadFile
                data-test-id="installmentInoiceDoc"
                disabled={true}
                eachFileSizeInMB={10}
                maxFilesNumber={5}
                fileName={values?.installment_attributes[installmentIndex]?.creditNoteDoc?.file_name || ""}
                isEditPage={true}
                filePath={values?.installment_attributes[installmentIndex]?.creditNoteDoc?.url || ""}
              />
            </Grid>
            <Grid item xs={4}>
              <LabelWithIcon label={t("credit_note_id")} />
              <TextInput
                data-test-id={`salesCreditNoteId`}
                id={`salesCreditNoteId`}
                className={classes.textInputStyle}
                name={getTitle(`salesCreditNoteId`)}
                disabled={true}
                value={values?.installment_attributes[installmentIndex]?.salesCreditNoteId}
              />
            </Grid>

          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
const useStyles = {
  policyDetailsGrid: {
    padding: "15px 0px",
  },
  policyStyleTitle: {
    margin: "10px 0px",
    fontWeight: 600,
  },
  checkBoxInput: {
    marginTop: "15px"
  },
  grayBtnInvoice: {
    "text-transform": 'none',
    padding: 15,
    borderRadius: 5,
    width: '32.5%',
    fontSize: 15,
    backgroundColor: "#979090",
    color: "white"
  },

  salesGrayBtnPayment: {
    "text-transform": 'none',
    padding: 15,
    borderRadius: 5,
    width: '100%',
    fontSize: 15,
    backgroundColor: "#979090",
    color: "white",
    marginTop: "37px",
  },
  salesPrimaryBtn: {
    "text-transform": 'none',
    padding: 15,
    borderRadius: 5,
    width: '100%',
    fontSize: 15,
    "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    color: "white",
    marginTop: "37px",
  },
  contactMainForm: { width: "100%" },
  textInputStyle: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  grayBtn: {
    "text-transform": 'none',
    padding: 15,
    borderRadius: 5,
    width: '32.5%',
    fontSize: 15,
    backgroundColor: "#979090",
    color: "white"
  },
  primaryRvBtn: {
    "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    "text-transform": 'none',
    "padding": 15,
    "borderRadius": 5,
    width: '32.5%',
    "fontSize": 15,
    "color": "white"
  }
};

export default withStyles(useStyles)(withTranslation()(StageNineInstalmentForm));
// Customizable Area End
