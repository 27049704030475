// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import * as Yup from 'yup';

export const configJSON = require("../config");
export interface Props {
    isExpanded?: boolean
    isEdit?: boolean;
    onNext?: any;
    t?:any;
    classes?:any;
    setStageData?:any;
    navigation?:any;
}

interface S {
    customerName: string;
    policyId: string;
    isArabicLenguage: boolean;
    expandedAccordion: string;
    stageData?: any;
    stageTwoData?: any;
    errorSection?: string;
    contectOption?:any[];
    insuranceCompany?: any[];
    p1Submited:boolean;
    showDialog:boolean;
    showAlert:boolean;
    alertMessage:string;
    alertType:string;
    loading: boolean;
    saveDraftLoading: boolean;
    currentFileName: string;
    currentFileUrl: any;
    isMultiUpload: boolean;
    showHandOverNote: boolean;
    isBtnVisible: boolean;
    markAsLostDialog: boolean;
    showRejectDialog: boolean;
    handOverNoteText: string;
    p2Submited: boolean;
    role:string;
    editFlowp1:boolean;
    editFlowp2:boolean;
    currentAdditionalDoc?: any;
    policyIsSavedAsDraft?:boolean;
    isNextStageSubmitted?:boolean;
    viewPermission?: [string];
    updatePermission?:[];
    salesOption?: any,
    operationOptions?: any[],
    financeOptions?: any[],
}

interface SS {
    id: any;
}

const enumRoles = {
    ADMIN: "Admin",
    SALES: "Sales",
    OPERATIONS: "Operations",
    FINANCE: "Finance",
    CONTACT: "Contact",
}
interface RfqInputAttributes {
    existing_insurance: boolean | string;
    name_existing_insurance?: string;
    policy_expiry_date?:string;
    save_as_draft?:string;
    no_of_vehicles_to_insure?:string;
    trade_license_number?:any;
    national_address?:string;
    insurance_company_id?:any;
    client_comment?:string;
    policy_type?:string;
    no_of_employees_to_medical_insure?:any;
    preferred_hospital?:any;
    medical_segment?:any;
    no_of_employees_to_life_insure?:any;
    no_of_employees_to_fire_insure?:any;
    fire_gps_coordinates?:any;
    no_of_employees_to_property_insure?:any;
    no_of_employees_to_engineering_insure?:any;
    engineering_gps_coordinates?:any;
    no_of_employees_to_marine_insure?:any;
    ps_gps_coordinates?:any;
    general_business_gps_coordinates?:any;
    aviation_gps_coordinates?:any;
    energy_gps_coordinates?:any;
  }
  
  interface BodyData {
    data: {
      rfq_input_attributes: RfqInputAttributes;
      customer_contact_id?: any
    };
  }

export default class StageOneController extends BlockComponent<
    Props,
    S,
    SS
> {
    getPolicyDetailsAPI: any = "";
    getContectsApi:any = "";
    getInsuranceCompanyApi:any = "";
    stageOnePartOneNextApi:any = "";
    stageOnePartTwoNextAPI:any = "";
    uploadFileApiCall:any = "";
    nextStepApi:any = "";
    getSalesAPI: any = "";
    getFinanceAPI: any = "";
    getOperationsAPI: any = "";
    savePolicyAsDraftApi:any = "";
    markPolicyAsLostApi:any = "";
    removeFileAPI:any = "";
    stageOnePartOneEditApi:any = "";
    stageOnePartTwoEditAPI:any = "";
    formikRefStage: any = null;
    fieldName: string = ''
    fieldNameData:string=""
    fileToRemove: number = 0

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        const queryParameter: any = new URLSearchParams(window.location.search);
        const policyId: string = queryParameter?.get('id');

        const selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer') || '{}');

        const getUserRole = () => {
            const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
            const userRole = userData.role || "";
            return userRole as string
        }


        this.state = {
            customerName: "",
            policyId: policyId || "",
            isArabicLenguage: (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
            role: getUserRole(),
            expandedAccordion: "",
            stageData: {
                requestId: "",
                requestType:"New policy",
                customer: selectedCustomer?.name || "",
                customerId:selectedCustomer?.id || "",
                contactName:"",
                contectPosition: "",
                contactPhoneNumber: "",
                contactEmail: "",
                contactStatus: "",
                sales: "",
                operation: "",
                finance: "",
                insuranceType:  ""
            },
            policyIsSavedAsDraft:false,
            errorSection: "",
            p1Submited: false,
            showDialog:false,
            showAlert:false,
            alertMessage:"",
            alertType:"",
            loading:false,
            saveDraftLoading:false,
            currentFileName:"",
            currentFileUrl:"",
            isMultiUpload: false,
            contectOption:[{
                value:"",label:"", allData:{}
            }],
            insuranceCompany:[{
                value:"",label:"", allData:{}
            }],
            stageTwoData:{
                industry: selectedCustomer?.industry || "",
                existingInsurance:"no",
                LrClaimDoc:"",
                policyExpireDate:"",
                tradeLicenseNumber:"",
                tradeLicenseFile:"",
                insuranceCompany:[],
                brokerOfRecord:"",
                LrCaimDoc:"",
                nationalAddress:"",
                proposalForm:"",
                clientComment:"",
                additionalDoc: [],
                nameOfExistingInsurer:"",
                insuranceType: "",
                motor:{
                    numberOfVehiclesToInsure:"",
                    policyType:"",
                    vehicalListDoc:""
                },
                medical:{
                    numberOfEmployeeOfInsurer:"",
                    prefferedHospital:"",
                    employeeListDoc:"",
                    medicalSegment:""
                },
                life:{
                    numberOfEmployeeOfInsurer:"",
                    employeeListDoc:""
                },
                fire:{
                    numberOfEmployeeOfInsurer:"",
                    employeeListDoc:"",
                    gpsCordinate:"",
                    letterFormCivilDefenceDoc:"",
                    muncipalCertificateDoc:"",
                    surveyReportDoc:"",
                    fireAndSaftyContractDoc:"",
                    samplePicture:"",
                },
                property:{
                    numberOfEmployeeOfInsurer:"",
                    employeeListDoc:"",
                    letterFormCivilDefenceDoc:""
                },
                engineering:{
                    numberOfEmployeeOfInsurer:"",
                    employeeListDoc:"",
                    gpsCordinate:"",
                    awardContractDoc:"",
                    billOfQualityDoc:"",
                    timeBarChatDoc:""
                },
                marine:{
                    numberOfEmployeeOfInsurer:"",
                    employeeListDoc:""
                },
                protectionAndSaving:{
                    insuranceFileDoc:"",
                    gpsCordinate:"",
                    letterFormCivilDefenceDoc:"",
                    muncipalCertificateDoc:"",
                    surveyReportDoc:"",
                    fireAndSaftyContractDoc:"",
                    awardContractDoc:"",
                    billOfQualityDoc:"",
                    timeBarChatDoc:"",
                    maintenanceHistoryRecordDoc:"",
                    samplePicture:""
                },
                generalBusiness:{
                    insuranceFileDoc:"",
                    gpsCordinate:"",
                    letterFormCivilDefenceDoc:"",
                    muncipalCertificateDoc:"",
                    surveyReportDoc:"",
                    fireAndSaftyContractDoc:"",
                    awardContractDoc:"",
                    billOfQualityDoc:"",
                    timeBarChatDoc:"",
                    maintenanceHistoryRecordDoc:"",
                    samplePicture:""
                },
                aviation:{
                    insuranceFileDoc:"",
                    gpsCordinate:"",
                    letterFormCivilDefenceDoc:"",
                    muncipalCertificateDoc:"",
                    surveyReportDoc:"",
                    fireAndSaftyContractDoc:"",
                    awardContractDoc:"",
                    billOfQualityDoc:"",
                    timeBarChatDoc:"",
                    maintenanceHistoryRecordDoc:"",
                    samplePicture:""
                },
                energy:{
                    insuranceFileDoc:"",
                    gpsCordinate:"",
                    letterFormCivilDefenceDoc:"",
                    muncipalCertificateDoc:"",
                    surveyReportDoc:"",
                    fireAndSaftyContractDoc:"",
                    awardContractDoc:"",
                    billOfQualityDoc:"",
                    timeBarChatDoc:"",
                    maintenanceHistoryRecordDoc:"",
                    samplePicture:""
                }
            },
            showHandOverNote:false,
            isBtnVisible: true,
            handOverNoteText: "",
            p2Submited: false,
            markAsLostDialog: false,
            showRejectDialog: false,
            editFlowp1: false,
            editFlowp2: false,
            currentAdditionalDoc: [],
            isNextStageSubmitted: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    navigateImportCSV =(isEdit:boolean,values:any, type:string)=>{
        this.savePolicyAsDraft(isEdit,values) 
        localStorage.setItem("csvinfo",JSON.stringify({id:this.state.policyId,type:type}))
        setTimeout(() => {
            this.props.navigation.navigate(`EmployeeCsvImport`)
        }, 1000);
        
    }

    checkErrorForStageOneForm = (errors:any,stage:string) => {
        if (Object.keys(errors).length === 0) {
          this.setState({ showDialog: true,errorSection:""})
        } else {
            const keys = Object.keys(errors);
            const firstKey = keys[0];
            const errorValue = errors[firstKey] as string;

            this.setState({
                showAlert:true,
                alertMessage:errorValue,
                alertType:"error",
                errorSection:stage,
                showDialog:false,
                loading:false
            })
        }
}

    hasOnlyViewRights = () => {
        return (this.state.policyId && this.state.viewPermission?.includes(this.state.role)) as boolean 
    }

    changeAccordion = (isExpanded: boolean, accordionName: string) => {
        this.setState({
            expandedAccordion: isExpanded ? accordionName : ""
        })
    }

    handleAccordian(isExpanded: boolean, accordionName: string) {
        if (!this.state.p1Submited && accordionName === "p2" ) {
            const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
            this.setState({
                alertMessage: isArabic ? "يرجى تقديم نموذج النظرة العامة أولاً" : "Please first submit overview form!", 
                alertType:"warning",
                showAlert:true
            })
        } else if(this.state.policyId !=="" && this.hasOnlyViewRights()) {
            this.setState({
                expandedAccordion: isExpanded ? accordionName : "",
                alertType:"",
                showAlert:false
            })
        } else {
            this.setState({
                expandedAccordion: isExpanded ? accordionName : ""
            })
        }
    }

    setArabicLanguage = (value: boolean) => {
        const language = (value === true) ? 'ar' : 'en';
        window.localStorage.setItem('lang', language);
        this.setState({
            isArabicLenguage: value
        })
    }

    async componentDidMount() {
        super.componentDidMount();

        const stageOneData = await JSON.parse(localStorage.getItem('stageOne') || '{}');
        const selectedCustomer = await JSON.parse(localStorage.getItem('selectedCustomer') || '{}');
        const userId = (stageOneData.customerId) || (selectedCustomer?.id);
        if (userId) {
           await this.getConectOptions(userId)
        }
        await this.getInsurerCompanyOption()
        if (Object.keys(stageOneData).length) {
            this.setState({
                p1Submited:true,
            })
        }
        if (stageOneData.editFlowp1 && stageOneData.editFlowp2) {
            this.setState({
                editFlowp1: stageOneData.editFlowp1,
                editFlowp2:stageOneData.editFlowp2
            })
        }
        if (stageOneData.partOneResponse?.meta) {
            this.setState({
                viewPermission: stageOneData.partOneResponse?.meta?.current_stage_view_permission_role || [],
                updatePermission: stageOneData.partOneResponse?.meta?.current_stage_update_permission_role || []
            })
        }
        if(this.state.role === "Admin") {
            this.getSalesData()
            this.getFinanceData()
            this.getOperationsData()
        }
    }

    componentDidUpdate() {
        if(this.state.showAlert){
          setTimeout(() => {
            this.setState({
              showAlert: false,
              alertType: "",
              alertMessage: "",
            });
          }, 10000);
        }
      }

    overviewSchema = (isArabicLanguage: boolean) => {
        const schema = {
            requestId: Yup.string().notRequired(),
            requestType: Yup.string().notRequired(),
            customer: Yup.string().notRequired(),
            contactName: Yup.string().required(isArabicLanguage ? 'يتطلب اسم جهة الاتصال' : "Contact name is required."),
            contectPosition: Yup.string().notRequired(),
            contactPhoneNumber: Yup.string().notRequired(),
            contactEmail: Yup.string().notRequired(),
            contactStatus: Yup.string().notRequired(),
            sales: Yup.string().notRequired(),
            operation: Yup.string().notRequired(),
            finance: Yup.string().notRequired(),
            insuranceType: Yup.string().required(isArabicLanguage ? 'نوع التأمين مطلوب' : "insurance type is required.")
        }
        return schema;
    }

    isP1Expanded = (isExpanded:any) => {
        if ((isExpanded === true) && (this.state.expandedAccordion === "p1")) {
            return true;   
        }
        if (this.state.policyId !== "" && (isExpanded === true)) {
            return false;
        }
        if ((this.state.policyId !== "") && (this.state.expandedAccordion === "p1")) {
            return true;
        }
        return false;
    }
 
    isP2Expanded = (isExpanded:any) => {
        if ((isExpanded === true) && (this.state.expandedAccordion === "p2")) {
            return true;
        }
        if (this.state.policyId !== "" && (isExpanded === true)) {
            return false;
        }
        if ((this.state.policyId !== "") && (this.state.expandedAccordion === "p2")) {
            return true;
        }
        return false;
    }

    getConectOptions = async (customerId:string) => {
        const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
        const token =  userData.token || '';
        this.getContectsApi = this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_customer/customers/contacts?id=${customerId}`,
            token: token || ''
        })
    }

    openNoteDialog = () => {
        this.setState({ markAsLostDialog: true, showHandOverNote: true, isBtnVisible: false })
    }

    handleHandOverNoteDialog =  (show:boolean,loading=false) => {
        this.setState({markAsLostDialog:false, handOverNoteText:"",showHandOverNote:show, loading:loading, isBtnVisible: true})
    }

    wantToEditPolicy = () => {
        const stageOneData = JSON.parse(localStorage.getItem('stageOne') || '{}');
        if ((stageOneData.isNextStageSubmitted === true) || (stageOneData.isRfqSubmitted === true)) {
            return "yes"
        }
        return "no"
    }

    handOverNoteSubmit = (value:string) => {
        this.goToNextStep(value);
    }

    markPolicyAsLost = (value:string) => {
        const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
        const token =  userData.token || '';
        this.markPolicyAsLostApi = this.apiCall({
            contentType: 'application/json',
            method: 'PUT',
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/mark_as`,
            token: token || '',
            body:{
                data:{
                    mark_as: "lost",
                    lost_reason: value
                }
            }
        })
    }

    getInsurerCompanyOption = async () => {
        const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
        const token =  userData.token || '';
        this.getInsuranceCompanyApi = this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_insurance_companies/insurance_companies?archived=false`,
            token: token || ''
        })
    }

    showMarkAsLostDialog = () => {
        this.setState({markAsLostDialog:true,showHandOverNote:true})
    }

    showMotorInsurance = (insuranceType:string) => {
        if (insuranceType === "motor") {
            return true;
        } else {
            return false;
        }
    }

    showMedicalInsurance = (insuranceType:string) => {
        if (insuranceType === "medical") {
            return true;
        } else {
            return false;
        }
    }

    showLifeInsurance = (insuranceType:string) => {
        if (insuranceType === "life") {
            return true;
        } else {
            return false;
        }
    }

    showFireInsurance = (insuranceType:string) => {
        if (insuranceType === "fire") {
            return true;
        } else {
            return false;
        }
    }

    showPropertyInsurance = (insuranceType:string) => {
        if (insuranceType === "property") {
            return true;
        } else {
            return false;
        }
    }

    showEngineeringInsurance = (insuranceType:string) => {
        if (insuranceType === "engineering") {
            return true;
        } else {
            return false;
        }
    }

    showMarineInsurance = (insuranceType:string) => {
        if (insuranceType === "marine") {
            return true;
        } else {
            return false;
        }
    }

    showProtectionSavingInsurance = (insuranceType:string) => {
        if (insuranceType === "protection & savings") {
            return true;
        } else {
            return false;
        }
    }

    showGeneralBusinessInsurance = (insuranceType:string) => {
        if (insuranceType === "general business") {
            return true;
        } else {
            return false;
        }
    }
    
    showAviatoinInsurance = (insuranceType:string) => {
        if (insuranceType === "aviation") {
            return true;
        } else {
            return false;
        }
    }
    showEnergyInsurance = (insuranceType:string) => {
        if (insuranceType === "energy") {
            return true;
        } else {
            return false;
        }
    }
    goToNextStep = (comment: string) => {
        const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
        const token =  userData.token || '';
        this.nextStepApi = this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/track_requests`,
            token: token || '',
            body:{
                "data": {
                    "track_request": {
                        "stage_status": "next_stage", // reject_stage, next_stage
                        "comment": comment,
                        "current_stage": "processing"  // change into new
                    }
                }
            }
        })
    }
    callAPI = (stage:number,action:string,formData:any) => {
    
        if(stage === 0.5){
            this.stageOnePartOneApiCall(stage,action,formData);
        }
        if (stage === 1) {
            window.localStorage.setItem('stageOnePartTwoData',JSON.stringify(formData));
            this.stageOnePartTwoAPICall(action,formData)
        }
    }
    stageOnePartOneApiCall = async (stage:any,action:string,formData:any) => {
        
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';
        
        if (action == "next") {
            

            
            const bodyData = {
                data:{
                    customer_contact_id: formData.contactName.value,
                    types_of_insurance: formData.insuranceType,
                    customer_id: formData.customerId,
                    sales_id: formData.sales.value,
                    operation_id: formData.operation.value,
                    finance_id: formData.finance.value
                }
            }

            this.stageOnePartOneNextApi = this.apiCall({
                contentType: 'application/json',
                method: 'POST',
                endPoint: `bx_block_policy_request/policy_requests?stage=1&part=1`,
                token: token || '',
                body: bodyData
            })

        }
    }
    stageOnePartTwoAPICall = async (action:string,formData:any) => {
        
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';
        const stageOne = await JSON.parse(localStorage.getItem('stageOne') || '{}');

        const getInsuranceCompanyId = (insurerList:any) => {
            let array: any[] = [];
            insurerList.map((item:any)=>{
                array.push(item.value);
            })
            return array;
        }
        let bodyData:BodyData = {
            data:{
                rfq_input_attributes: {
                    existing_insurance:formData.existingInsurance === "yes" ? 'true' : 'false',
                    trade_license_number:formData.tradeLicenseNumber,
                    national_address:formData.nationalAddress,
                    insurance_company_id: `[${getInsuranceCompanyId(formData.insuranceCompany)}]`,
                    client_comment: formData.clientComment,
                },
                customer_contact_id: stageOne.contactName.value
            }
        }

        let rfqInputValue = bodyData.data.rfq_input_attributes;

        // Existing Policy details
        if (formData.existingInsurance === 'yes') {

            rfqInputValue = {
                ...rfqInputValue,
                name_existing_insurance:formData.nameOfExistingInsurer,
                policy_expiry_date:formData.policyExpireDate,
                save_as_draft:"no"
            }
            bodyData.data.rfq_input_attributes = rfqInputValue
        }

        switch (formData.insuranceType) {
            case "motor":
                rfqInputValue = {
                    ...rfqInputValue,
                    no_of_vehicles_to_insure: formData.motor.numberOfVehiclesToInsure,
                    policy_type: formData?.motor?.policyType?.map((policyType: any) => policyType.value).join(',')
                } 
                bodyData.data.rfq_input_attributes = rfqInputValue
            break;

            case "medical":
                rfqInputValue = {
                    ...rfqInputValue,
                    no_of_employees_to_medical_insure: formData.medical.numberOfEmployeeOfInsurer,
                    preferred_hospital:formData.medical.prefferedHospital,
                    medical_segment:formData.medical.medicalSegment
                }
                bodyData.data.rfq_input_attributes = rfqInputValue
            break;

            case "life":
                rfqInputValue = {
                    ...rfqInputValue,
                    no_of_employees_to_life_insure: formData.life.numberOfEmployeeOfInsurer
                }
                bodyData.data.rfq_input_attributes = rfqInputValue
            break;

            case "fire":
                rfqInputValue = {
                    ...rfqInputValue,
                    no_of_employees_to_fire_insure: formData.fire.numberOfEmployeeOfInsurer,
                    fire_gps_coordinates: formData.fire.gpsCordinate
                }
                bodyData.data.rfq_input_attributes = rfqInputValue
            break;

            case "property":
                rfqInputValue = {
                    ...rfqInputValue,
                    no_of_employees_to_property_insure: formData.property.numberOfEmployeeOfInsurer,
                }
                bodyData.data.rfq_input_attributes = rfqInputValue
            break;

            case "engineering":
                rfqInputValue = {
                    ...rfqInputValue,
                    no_of_employees_to_engineering_insure: formData.engineering.numberOfEmployeeOfInsurer,
                    engineering_gps_coordinates: formData.engineering.gpsCordinate,
                }
                bodyData.data.rfq_input_attributes = rfqInputValue
            break;
        
            case "marine":
                rfqInputValue = {
                    ...rfqInputValue,
                    no_of_employees_to_marine_insure: formData.marine.numberOfEmployeeOfInsurer
                }
                bodyData.data.rfq_input_attributes = rfqInputValue
            break;


            case "protection & savings":
                rfqInputValue = {
                    ...rfqInputValue,
                    ps_gps_coordinates: formData.protectionAndSaving.gpsCordinate
                }
                bodyData.data.rfq_input_attributes = rfqInputValue
            break;

            case "general business":
                rfqInputValue = {
                    ...rfqInputValue,
                    general_business_gps_coordinates: formData.generalBusiness.gpsCordinate
                }
                bodyData.data.rfq_input_attributes = rfqInputValue
            break;

            case "aviation":
                rfqInputValue = {
                    ...rfqInputValue,
                    aviation_gps_coordinates: formData.aviation.gpsCordinate
                }
                bodyData.data.rfq_input_attributes = rfqInputValue
            break;

            case "energy":
                rfqInputValue = {
                    ...rfqInputValue,
                    energy_gps_coordinates: formData.energy.gpsCordinate
                }
                bodyData.data.rfq_input_attributes = rfqInputValue
            break;

            default:
                break;
        }

        if (action === "next") {
            this.stageOnePartTwoNextAPI = this.apiCall({
                contentType: 'application/json',
                method: this.state.policyIsSavedAsDraft ? 'PUT' :'POST',
                endPoint: this.state.policyIsSavedAsDraft ? `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=1&part=2` : `bx_block_policy_request/policy_requests?stage=1&part=2&id=${this.state.policyId}`,
                token: token || '',
                body: bodyData
            })
        } else
        if (action === "edit") {
            this.stageOnePartTwoEditAPI = this.apiCall({
                contentType: 'application/json',
                method: 'PUT',
                endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=1&part=2`,
                token: token || '',
                body: bodyData
            })
        }
        // Mark as lost API
    }
    edtiPolicy = (formData:any) => {
        const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
        const token =  userData.token || '';
        this.stageOnePartOneEditApi = this.apiCall({
            contentType: 'application/json',
            method: 'PUT',
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}?stage=1&part=1`,
            token: token || '',
            body:{
                "data": {
                    "customer_contact_id": formData.contactName.value,
                    "sales_id": formData.sales.value,
                    "operation_id": formData.operation.value,
                    "finance_id": formData.finance.value
                }
            }
        })
    }
    getFormData = (file_key: string, fileData: any, policyId: any, isMultiUpload: boolean) => {
        
        const apiFormData = new FormData();
        
        apiFormData.append("data[stage]", "1");
        apiFormData.append("data[file_key]", file_key);

        if (isMultiUpload) {
            apiFormData.append(`data[multiple]`, 'true');
            apiFormData.append("data[is_already_existing]", "true");
            for (const file of fileData) {
                apiFormData.append(`data[file][]`, file);
            }
        } else {
            apiFormData.append(`data[multiple]`, 'false');
            apiFormData.append("data[file]", fileData);
        }

        return apiFormData;
    }
    
    getDraftBodyData = async (formValue:any, isEdit: boolean) => {// NOSONAR: typescript:S3776
        const stageOneData = await JSON.parse(localStorage.getItem('stageOneData') || '{}');

        const getInsuranceCompanyId = (insurerList:any) => {
            let array: any[] = [];
            insurerList.map((item:any)=>{
                array.push(item.value);
            })
            return array;
        }
        let draftData:BodyData = isEdit ? {
            data:{
                rfq_input_attributes: {
                    existing_insurance:formValue.existingInsurance === "yes" ? "true" : "false",
                    save_as_draft:"yes"
                },
                customer_contact_id: stageOneData?.attributes?.customer_contact_id
            }
        }
         : {
            data:{
                rfq_input_attributes: {
                    existing_insurance:formValue.existingInsurance === "yes" ? "true" : "false",
                    save_as_draft:"yes"
                }
            }
        }

        let rfqInputValue = draftData.data.rfq_input_attributes;

            rfqInputValue = {
                ...rfqInputValue,
                national_address:formValue.nationalAddress,
                insurance_company_id: `[${getInsuranceCompanyId(formValue.insuranceCompany)}]`,
                client_comment: formValue.clientComment,
                trade_license_number:formValue.tradeLicenseNumber
            }
            draftData.data.rfq_input_attributes = rfqInputValue
        
        if (formValue.existingInsurance === 'yes') {
                rfqInputValue = {
                    ...rfqInputValue,
                    name_existing_insurance:formValue.nameOfExistingInsurer,
                    policy_expiry_date:formValue.policyExpireDate,
                }
                draftData.data.rfq_input_attributes = rfqInputValue
        }

        switch (formValue.insuranceType) {
            case "motor":
                
                    rfqInputValue = {
                        ...rfqInputValue,
                        no_of_vehicles_to_insure: formValue.motor.numberOfVehiclesToInsure,
                        policy_type: formValue?.motor?.policyType?.map((policyType: any) => policyType.value).join(',')
                    } 
            break;

            case "medical":
            
                    rfqInputValue = {
                        ...rfqInputValue,
                        preferred_hospital:formValue.medical.prefferedHospital,
                        medical_segment:formValue.medical.medicalSegment,
                        no_of_employees_to_medical_insure: formValue.medical.numberOfEmployeeOfInsurer
                    }
                    draftData.data.rfq_input_attributes = rfqInputValue

            break;

            case "life":
                    rfqInputValue = {
                        ...rfqInputValue,
                        no_of_employees_to_life_insure: formValue.life.numberOfEmployeeOfInsurer
                    }
                    draftData.data.rfq_input_attributes = rfqInputValue
            break;

            case "fire":
                
                    rfqInputValue = {
                        ...rfqInputValue,
                        no_of_employees_to_fire_insure: formValue.fire.numberOfEmployeeOfInsurer,
                        fire_gps_coordinates: formValue.fire.gpsCordinate
                    }
                    draftData.data.rfq_input_attributes = rfqInputValue

            break;

            case "property":
                    rfqInputValue = {
                        ...rfqInputValue,
                        no_of_employees_to_property_insure: formValue.property.numberOfEmployeeOfInsurer,
                    }
                    draftData.data.rfq_input_attributes = rfqInputValue
                
            break;

            case "engineering":
               
                if (formValue.engineering.numberOfEmployeeOfInsurer) {
                    rfqInputValue = {
                        ...rfqInputValue,
                        engineering_gps_coordinates: formValue.engineering.gpsCordinate,
                        no_of_employees_to_engineering_insure: formValue.engineering.numberOfEmployeeOfInsurer
                    }
                    draftData.data.rfq_input_attributes = rfqInputValue
                }

            break;
        
            case "marine":
                    rfqInputValue = {
                        ...rfqInputValue,
                        no_of_employees_to_marine_insure: formValue.marine.numberOfEmployeeOfInsurer
                    }
                    draftData.data.rfq_input_attributes = rfqInputValue
            break;


            case "protection & savings":
                   rfqInputValue = {
                        ...rfqInputValue,
                        ps_gps_coordinates: formValue.protectionAndSaving.gpsCordinate
                    }
                    draftData.data.rfq_input_attributes = rfqInputValue
            break;

            case "general business":
                rfqInputValue = {
                    ...rfqInputValue,
                    general_business_gps_coordinates: formValue.generalBusiness.gpsCordinate
                }
                draftData.data.rfq_input_attributes = rfqInputValue
            break;

            case "aviation":
                    rfqInputValue = {
                        ...rfqInputValue,
                        aviation_gps_coordinates: formValue.aviation.gpsCordinate
                    }
                    draftData.data.rfq_input_attributes = rfqInputValue
            break;

            case "energy":
                    rfqInputValue = {
                        ...rfqInputValue,
                        energy_gps_coordinates: formValue.energy.gpsCordinate
                    }
                    draftData.data.rfq_input_attributes = rfqInputValue
            break;

            default:
                break;
        }
        return draftData;
        
    }
    savePolicyAsDraft = async(isEditPage:boolean = false,value:any) => {
        
        this.setState({saveDraftLoading:true,policyIsSavedAsDraft:true});
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';
        const bodyData = await this.getDraftBodyData(value, isEditPage);
        
        this.savePolicyAsDraftApi = this.apiCall({
            contentType: 'application/json',
            method: isEditPage ? "PUT" : "POST",
            endPoint: isEditPage ? `/bx_block_policy_request/policy_requests/${this.state.policyId}?stage=1&part=2` : `bx_block_policy_request/policy_requests?stage=1&part=2&id=${this.state.policyId}`,
            token: token || '',
            body: bodyData
        })
    }
    showP2acordion = () => {
        const stageOne = JSON.parse(localStorage.getItem('stageOne') || '{}');
        const isP1Submited = stageOne.p1Submited || this.state.p1Submited
        return isP1Submited;
    }
    uploadFileStageOne = (fileName: string, fileData: any, policyId: any, isMultiUpload: boolean, formikRef: any = null, fieldName: string = '') => {
        
        this.formikRefStage = formikRef
        this.fieldName = fieldName

        this.setState({
            currentFileName: fileName,
            isMultiUpload: isMultiUpload,
            errorSection:"",
            loading:false
        });

        const formFileData = this.getFormData(fileName, fileData, policyId, isMultiUpload);

        const loggedInUser = JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = loggedInUser.token || '';


        const header = {
            token
        };
        const uploadFlieRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.uploadFileApiCall = uploadFlieRequestMessage.messageId;

        uploadFlieRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        uploadFlieRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_policy_request/policy_requests/${this.state.policyId}/file_upload`
        );
        uploadFlieRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `PUT`
        );

        uploadFlieRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formFileData
        );

        runEngine.sendMessage(uploadFlieRequestMessage.id, uploadFlieRequestMessage);
        return uploadFlieRequestMessage.messageId;
    }
    removeFile = (fileId: number, formikRef: any) => {
        this.formikRefStage = formikRef
        this.fileToRemove = fileId
        this.removeFileAPI = this.apiCall({
            contentType: 'application/json',
            method: 'PUT',
            body: { data: { file_id: fileId } },
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/remove_file`,
        })
    }

    getSalesData = async () => {
        const userData =  JSON.parse(localStorage.getItem('loginData') || '{}');
        const token =  userData.token || '';
        this.getSalesAPI = this.apiCall({
            contentType: 'application/json',
            method: "GET",
            endPoint: `account_block/accounts?role=Sales`,
            token: token || '',
        })
    }

    getFinanceData = async () => {
        const userData =  JSON.parse(localStorage.getItem('loginData') || '{}');
        const token =  userData.token || '';
        this.getFinanceAPI = this.apiCall({
            contentType: 'application/json',
            method: "GET",
            endPoint: `account_block/accounts?role=Finance`,
            token: token || '',
        })
    }

    getOperationsData = async () => {
        const userData =  JSON.parse(localStorage.getItem('loginData') || '{}');
        const token =  userData.token || '';
        this.getOperationsAPI = this.apiCall({
            contentType: 'application/json',
            method: "GET",
            endPoint: `account_block/accounts?role=Operations`,
            token: token || '',
        })
    }

    getSuccesMessageForStageOne = (isDraft: boolean) => {
        let alertMessage = "";
        const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
        if (isDraft) {
          alertMessage = isArabic ? /* istanbul ignore next */"تم حفظ بيانات طلب المراجعة كمسودة!" : "Data has been saved as draft!"
        } else {
          alertMessage = isArabic ? /* istanbul ignore next */'لقد تم نقل طلبك إلى معالجة RFQ' : `Your request has been moved to RFQ processing!`
        }
        return alertMessage
      }

    showApiErrorMessage = (responseJson: any) => {
        if (!responseJson) return;
        let errorMessage = "Something went wrong"
        if (responseJson?.errors) {
          errorMessage = typeof (responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error || "Something went wrong";
        }
        this.setState({
          alertType: "error",
          alertMessage: errorMessage,
          showAlert: true,
          loading:false,
          expandedAccordion:"",
          saveDraftLoading:false,
          handOverNoteText:"",
          showHandOverNote:false,
          showDialog:false,
          markAsLostDialog:false,
        })
      };

    getContectsApiReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getContectsApi != null &&
            this.getContectsApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                let array = [] as any;
                responseJson.data.map((item:any)=>{
                    array.push({
                        value: parseInt(item.id),
                        label: `${item.attributes.first_name} ${item.attributes.last_name}`,
                        allData: item.attributes
                    })
                })
                this.setState({contectOption:array})
            } else {
                this.showApiErrorMessage(responseJson);
            }
        }
    }
    getInsuranceCompanyApiRecive =(message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getInsuranceCompanyApi != null &&
            this.getInsuranceCompanyApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                
                let array = [] as any;
                responseJson.data.map((item:any)=>{
                    array.push({
                        value: item.id,
                        label: item.attributes.short_name,
                        allData: item.attributes
                    })
                })
                
                this.setState({insuranceCompany:array})
                
            } else {
                this.showApiErrorMessage(responseJson);
            }
        }
    }

    getList = (attributes: any) => {
        return {
            sales: {value: attributes.sales_id.id, label: attributes.sales_id.name, allData: {id: attributes.sales_id.id }},
            operation: {value: attributes.operation_id.id, label: attributes.operation_id.name, allData: {id: attributes.operation_id.id }},
            finance: {value: attributes.finance_id.id, label: attributes.finance_id.name, allData: {id: attributes.finance_id.id }},
        }
    }

    stageOnePartOneNextApiReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.stageOnePartOneNextApi != null &&
            this.stageOnePartOneNextApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                let stageOnedetails = this.state.stageData;

                const {attributes} = responseJson.data

                stageOnedetails = {
                    ...stageOnedetails,
                    partOneResponse:responseJson,
                    requestId:responseJson.data.attributes.request_id,
                    policyId:responseJson.data.attributes.id,
                    contectPosition: attributes.customer_contact_position,
                    contactStatus: attributes.customer_contact_account_status,
                    contactEmail: attributes.customer_contact_email,
                    contactPhoneNumber: attributes.customer_contact_contact_no,
                    contactName: {value: attributes.customer_contact_id, label: attributes.customer_contact_name, allData: {id: attributes.customer_contact_id }},
                    insuranceType: attributes.types_of_insurance.value,
                    customerId: attributes.customer_id,
                }
                const roleList = this.getList(attributes)
                window.localStorage.setItem('stageOne',JSON.stringify({...stageOnedetails, ...roleList}))
                window.localStorage.setItem('stageOneData',JSON.stringify(responseJson.data))
                this.setPolicyIdToUrl(responseJson.data.attributes.id, window.location.href)
                this.setState({
                    policyId:responseJson.data.attributes.id,
                    expandedAccordion: "p2", 
                    p1Submited: true, 
                    showDialog: false,
                    loading:false,
                })
                this.props.setStageData('stageOne',({...stageOnedetails, ...roleList}))
                
            } else {
                this.showApiErrorMessage(responseJson);
            }
        }
    }
    setPolicyIdToUrl = (policyId: string, currentURL: string) => {
        let regex = /[?&]id=([^&]+)/;
        let newURL = '';
        if (regex.test(currentURL)) {
            newURL = currentURL.replace(regex, (match, capture) => match.replace(capture, policyId));
        } else {
            if (currentURL.indexOf('?') !== -1) {
            newURL = currentURL + '&id=' + policyId;
            } else {
            newURL = currentURL + '?id=' + policyId;
            }
        }
        window.history.pushState({path: newURL}, '', newURL);
    }
    isRfqInputIsDisabled = () => {
        const stageOneData = JSON.parse(window.localStorage.getItem('stageOne') || '{}');
        const requestId = stageOneData.requestId || this.state.stageData.requestId;
        return requestId === ""
    }
    stageOnePartTwoNextAPIReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.stageOnePartTwoNextAPI != null &&
            this.stageOnePartTwoNextAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {

                const partTwoData = JSON.parse(window.localStorage.getItem('stageOnePartTwoData') || '{}');
                if (partTwoData.id) {
                    let allData = partTwoData
                    allData = {...allData,partTwoResponse:responseJson.data}
                    window.localStorage.setItem('stageOnePartTwoData',JSON.stringify(allData))
                    this.props.setStageData('stageOnePartTwoData',allData);
                }

                this.setState({
                    p2Submited: true, 
                    showDialog: false,
                    expandedAccordion:""
                })
                this.handleHandOverNoteDialog(true);
                
            } else {
                this.showApiErrorMessage(responseJson);
            }
        }
    }
    stageOnePartTwoEditAPIReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.stageOnePartTwoEditAPI != null &&
            this.stageOnePartTwoEditAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {

                const partTwoData = JSON.parse(window.localStorage.getItem('stageOnePartTwoData') || '{}');
                if (partTwoData.insuranceType) {
                    let allData = partTwoData
                    allData = {...allData,partTwoResponse:responseJson.data}
                    window.localStorage.setItem('stageOnePartTwoData',JSON.stringify(allData))
                    this.props.setStageData('stageOnePartTwoData',allData);
                }

                this.setState({
                    p2Submited: true, 
                    showDialog: false,
                    expandedAccordion:""
                })
                this.handleHandOverNoteDialog(true);
                
            } else {
                this.showApiErrorMessage(responseJson);
            }
        }
    }
/* istanbul ignore next*/
    getFileUploadDetails = (responseJson: any) => {
        if (this.state.isMultiUpload && this.formikRefStage &&  this.formikRefStage.current) {
            const newData = {currentAdditionalDoc:responseJson.data.uploaded_file}
            this.formikRefStage.current.setFieldValue(`additionalDoc`, responseJson.data.uploaded_file)
            this.setState(newData)
    } else {
        this.fieldNameData=responseJson?.data?.uploaded_file
        this.formikRefStage.current && this.formikRefStage.current.setFieldValue(this.fieldName, responseJson.data.uploaded_file)
    }
    const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
    this.setState({
        currentFileUrl: responseJson.data.uploaded_file || "",
        showAlert:true,
        alertMessage: isArabic ? "تم تحميل الملف بنجاح" : "File successfully uploaded",
        alertType:"success"
    })
    }
    uploadFileApiCallReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.uploadFileApiCall != null &&
            this.uploadFileApiCall ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
               this.getFileUploadDetails(responseJson)
                
            } else {
                this.showApiErrorMessage(responseJson);
            }
        }
    }
    savePolicyAsDraftApiReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.savePolicyAsDraftApi != null &&
            this.savePolicyAsDraftApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
               const stageOneAlertMessage = this.getSuccesMessageForStageOne(true);
               this.setState({
                    saveDraftLoading:false,
                    loading:false,
                    showAlert:true,
                    alertType:"success",
                    alertMessage: stageOneAlertMessage
               })
               
                window.localStorage.setItem('stageOneData', JSON.stringify(responseJson.data))
            } else {
                this.showApiErrorMessage(responseJson);
            }
        }
    }

    nextStepApiReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.nextStepApi != null &&
            this.nextStepApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            /* istanbul ignore if */
            if (responseJson.data) {
                const stageOneAlertMessage = this.getSuccesMessageForStageOne(false);
                this.setState({p2Submited:true, handOverNoteText:"",markAsLostDialog:false,showHandOverNote:false, showAlert:true, alertMessage: stageOneAlertMessage, alertType:"success"}) 
                this.props.onNext(1);
            } else {
                this.showApiErrorMessage(responseJson);
            }
        }
    }
    markPolicyAsLostApiReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.markPolicyAsLostApi != null &&
            this.markPolicyAsLostApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({showHandOverNote:false, markAsLostDialog:false});
                window.localStorage.setItem('StageOneLostReason', JSON.stringify(responseJson.data))
                window.location.href = "/Requests"
            } else {
                this.showApiErrorMessage(responseJson);
            }
        }
    }
    removeFileAPIReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.removeFileAPI !== null &&
            this.removeFileAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          )   {
          const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
            if (responseJson?.data) {
                const updatedAdditionalDocs = this.formikRefStage?.current?.values?.additionalDoc?.filter((doc: {id: number; url: string}) => doc.id !== this.fileToRemove)
                this.formikRefStage.current.setFieldValue(`additionalDoc`, updatedAdditionalDocs)
                /*istanbul ignore next*/this.fieldName!=="additionalDoc"&&this.formikRefStage.current && this.formikRefStage.current.setFieldValue(this.fieldName, "")
              
                this.setState({
                alertMessage:responseJson?.data?.message, 
                alertType:'warning',
                showAlert:true
                })
            }
            else{
                this.showApiErrorMessage(responseJson);   
            }
        }
    }
    stageOnePartOneEditApiReceive = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.stageOnePartOneEditApi !== null &&
            this.stageOnePartOneEditApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          )   {
          const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (responseJson.data) {
            let updatedStageOnedetails = this.state.stageData;

            const { attributes } = responseJson.data

            updatedStageOnedetails = {
                ...updatedStageOnedetails,
                partOneResponse: responseJson,
                requestId: responseJson.data.attributes.request_id,
                policyId: responseJson.data.attributes.id,
                contectPosition: attributes.customer_contact_position,
                contactStatus: attributes.customer_contact_account_status,
                contactEmail: attributes.customer_contact_email,
                contactPhoneNumber: attributes.customer_contact_contact_no,
                contactName: { value: attributes.customer_contact_id, label: attributes.customer_contact_name, allData: { id: attributes.customer_contact_id } },
                insuranceType: attributes.types_of_insurance.value,
                customerId: attributes.customer_id,
            }
            const roleList = this.getList(attributes)
            window.localStorage.setItem('stageOneData',JSON.stringify(responseJson.data))
            window.localStorage.setItem('stageOne', JSON.stringify({...updatedStageOnedetails, ...roleList}))
            this.props.setStageData('stageOne', {...updatedStageOnedetails, ...roleList});
            const stageOneAlertMessage =   /* istanbul ignore next*/this.getSuccesMessageForStageOne(false)
              /* istanbul ignore next*/this.setState({
                policyId:responseJson.data.attributes.id,
                expandedAccordion: "p2", 
                p1Submited: true, 
                showDialog: false,
                saveDraftLoading:false,
                loading:false,
                showAlert:true,
                alertMessage: stageOneAlertMessage,
                alertType:"success"
            })
        } else {
            this.showApiErrorMessage(responseJson);
        }

        }
    }

    getSalesAPIReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getSalesAPI != null &&
            this.getSalesAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const stageOneSalesRoleList = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (stageOneSalesRoleList.data) {
                let stageOneRoleListDataArray = [] as any;
                stageOneSalesRoleList.data.map((item: any) => {
                    stageOneRoleListDataArray.push({
                        value: parseInt(item.id),
                        label: `${item.attributes.first_name} ${item.attributes.last_name}`,
                        allData: item.attributes
                    })
                })
                this.setState({salesOption: stageOneRoleListDataArray})
            } else {
                this.showApiErrorMessage(stageOneSalesRoleList);
            }
        }
    }

    getOperationsAPIReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getOperationsAPI != null &&
            this.getOperationsAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const stageOneOperationList = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (stageOneOperationList.data) {
                let roleListArray = [] as any;
                stageOneOperationList.data.map((item: any) => {
                    roleListArray.push({
                        value: parseInt(item.id),
                        label: `${item.attributes.first_name} ${item.attributes.last_name}`,
                        allData: item.attributes
                    })
                })
                this.setState({operationOptions: roleListArray})
            } else {
                this.showApiErrorMessage(stageOneOperationList);
            }
        }
    }

    getFinanceAPIReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getFinanceAPI != null &&
            this.getFinanceAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const stageOneFinanceList = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (stageOneFinanceList.data) {
                let financeListArray = [] as any;
                stageOneFinanceList.data.map((item: any) => {
                    financeListArray.push({
                        value: parseInt(item.id),
                        label: `${item.attributes.first_name} ${item.attributes.last_name}`,
                        allData: item.attributes
                    })
                })
                this.setState({financeOptions: financeListArray})
            } else {
                this.showApiErrorMessage(stageOneFinanceList);
            }
        }
    }

    apiCall(data: any) {
        const { contentType, method, endPoint, body, type } = data;
        const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
        const token = userData.token || "";

        let header: any = {
            token: token,
        };

        if (contentType) {
            header = {
                ...header,
                "Content-Type": contentType,
            }
        }

        const stageSevenRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== 'FormData' ?
            stageSevenRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            ) : stageSevenRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== 'FormData' ?
            stageSevenRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            ) : stageSevenRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );

        runEngine.sendMessage(stageSevenRequestMessage.id, stageSevenRequestMessage);
        return stageSevenRequestMessage.messageId;
    }

    async receive(from: string, message: Message) {

        this.getContectsApiReceive(message);
        this.getInsuranceCompanyApiRecive(message);
        this.stageOnePartOneNextApiReceive(message);
        this.stageOnePartTwoNextAPIReceive(message);
        this.stageOnePartTwoEditAPIReceive(message);
        this.uploadFileApiCallReceive(message);
        this.savePolicyAsDraftApiReceive(message);
        this.nextStepApiReceive(message);
        this.markPolicyAsLostApiReceive(message);
        this.removeFileAPIReceive(message);
        this.stageOnePartOneEditApiReceive(message);
        this.getSalesAPIReceive(message);
        this.getFinanceAPIReceive(message);
        this.getOperationsAPIReceive(message);
    }
}
// Customizable Area End