import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import {imgPasswordVisible , imgPasswordInVisible } from "./assets"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: Boolean;
  enableConfirmPasswordField: Boolean;
  isArabicLenguage: Boolean,
  btnConfirmPasswordShowHide: Boolean;
  showPasswordRecovery: Boolean;
  emailNotValid:Boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any,
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;

  //Properties from config
  labelTextIsPasswordRecovery: string = configJSON.labelTextIsPasswordRecovery;
  ArabicLabelTextIsPasswordRecovery: string = configJSON.ArabicLabelTextIsPasswordRecovery;
  labelTextForForgotPassword: string = configJSON.labelTextForForgotPassword;
  ArabicLabelTextForForgotPassword: string = configJSON.ArabicLabelTextForForgotPassword;
  checkMailText: string = configJSON.checkMailText;
  ArabicCheckMailText: string = configJSON.ArabicCheckMailText;
  passwordRecovertSubTitle: string = configJSON.passwordRecovertSubTitle;
  ArabicPasswordRecovertSubTitle: string = configJSON.ArabicPasswordRecovertSubTitle;
  subTitle: string = configJSON.subTitle;
  arabicSubTitle: string = configJSON.arabicSubTitle;
  setNewPasswordText: string = configJSON.setNewPasswordText;
  secondLabelText: string = configJSON.secondLabelText;
  thirdLabelText: string = configJSON.thirdLabelText;
  forthLabelText: string = configJSON.forthLabelText;
  fifthLabelText: string = configJSON.fifthLabelText;
  sixthLabelText: string = configJSON.sixthLabelText;
  firstInputAutoCompleteType: any = configJSON.firstInputAutoCompleteType;
  emailTextBoxLabel: string = configJSON.emailTextBoxLabel;
  ArabicTextEmail: string = configJSON.ArabicTextEmail;
  buttonTextContinue: string = configJSON.buttonTextContinue;
  ArabicResendEmailText: string = configJSON.ArabicResendEmailText;
  resendEmailText: string = configJSON.resendEmailText;
  ArabicButtonTextContinue: string = configJSON.ArabicButtonTextContinue;
  backToLoginText: string = configJSON.backToLoginText;
  ArabicBackToLoginText: string = configJSON.ArabicBackToLoginText;
  firstInputKeyboardStyle: any = configJSON.firstInputKeyboardStyle;
  firstInputPlaceholder: string = configJSON.firstInputPlaceholder;
  firstInputErrorColor: any = configJSON.firstInputErrorColor;
  buttonTextIsNext: string = configJSON.buttonTextIsNext;
  buttonColorForNextButton: any = configJSON.buttonColorForNextButton;
  secondInputAutoCompleteType: any = configJSON.secondInputAutoCompleteType;
  secondInputKeyboardType: any = configJSON.secondInputKeyboardType;
  secondInputPlaceholder: string = configJSON.secondInputPlaceholder;
  secondInputErrorColor: any = configJSON.secondInputErrorColor;
  thirdInputPlaceholder: string = configJSON.thirdInputPlaceholder;
  thirdInputErrorColor: any = configJSON.thirdInputErrorColor;
  buttonTitleIsSMSPhoneAccount: string =
    configJSON.buttonTitleIsSMSPhoneAccount;
  buttonTitleIsEmailAccount: string = configJSON.buttonTitleIsEmailAccount;
  labelTextIsPleaseEnterYourNewPassword: string =
    configJSON.labelTextIsPleaseEnterYourNewPassword;
  labelTextIsYourPasswordHasBeenSuccessfullyChanged: string =
    configJSON.labelTextIsYourPasswordHasBeenSuccessfullyChanged;
  placeholderIsPassword: string = configJSON.placeholderIsPassword;
  imgPasswordInVisible: any = imgPasswordInVisible;
  imgPasswordVisible: any = imgPasswordVisible;
  placeholderIsReTypePassword: string = configJSON.placeholderIsReTypePassword;
  buttonTitleIsOk: string = configJSON.buttonTitleIsOk;
  buttonColorForOkButton: any = configJSON.buttonColorForOkButton;
  countryCodeSelectorPlaceholder: string =
    configJSON.countryCodeSelectorPlaceholder;
  emailSendAPI: string = ""
  resetPasswordAPI: string = ""
  emailErrorMsg:string = configJSON.emailErrorMsg;
  ArabicEmailErrorMsg:string = configJSON.ArabicEmailErrorMsg;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired)
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired)
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number()
        .min(4)
        .required(configJSON.otpCodeIsRequired)
    };

    const isArabicLanguage = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false
    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(isArabicLanguage ? configJSON.ArabicPleaseEnterPasswword : configJSON.pleaseEnterAPassword)
        .min(8, isArabicLanguage ? configJSON.ArabicPasswordMustBeAtLeast8Characters : configJSON.passwordMustBeAtLeast8Characters)
        .matches(/^(?=.*[a-z])/,isArabicLanguage ? configJSON.ArabicPasswordMustContainLowercaseCharacter : configJSON.passwordMustContainLowercaseCharacter)
        .matches(/^(?=.*[A-Z])/,isArabicLanguage ? configJSON.ArabicPasswordMustContainUppercaseCharacter : configJSON.passwordMustContainUppercaseCharacter)
        .matches(/^(?=.*[0-9])/,isArabicLanguage ? configJSON.ArabicPasswordMustContainAtLeastOneNumber : configJSON.passwordMustContainAtLeastOneNumber),
      confirmPassword: Yup.string().required(isArabicLanguage ? configJSON.ArabcConfirmPasswordNotMatch : configJSON.confirmPasswordNotMatch).oneOf([Yup.ref('password'), null],configJSON.passwordsMustMatch)
    };

    this.state = {
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      enableConfirmPasswordField: true,
      btnConfirmPasswordShowHide: true,
      showPasswordRecovery: false,
      emailNotValid:false,
      isArabicLenguage: (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.emailSendAPI !== null &&
      this.emailSendAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson !== undefined && responseJson.data) {
        this.setState({
          showPasswordRecovery: true
        })
      } else if (responseJson !== undefined && responseJson.errors) {
       
        if (responseJson.errors[0].otp === "Account not found") {
          this.setState({emailNotValid:true})
          return;
        }
        
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.resetPasswordAPI !== null &&
      this.resetPasswordAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson !== undefined && responseJson.data) {
        window.location.href = "/EmailAccountLoginBlock"
      } else if (responseJson !== undefined && responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

// Customizable Area Start
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };

  setArabicLanguage = (value: boolean) => {
    const language = value === true ? 'ar' : 'en';
    window.localStorage.setItem('lang',language);
    
    this.setState({
      isArabicLenguage: value
    })
  }

  forgotPasswordFormSchema = (isArabicLanguage:Boolean) => {
    let schema = {
      email: Yup.string()
        .min(3,!isArabicLanguage ? configJSON.emailIsTooShort : configJSON.ArabicEmailIsTooShort)
        .max(50,!isArabicLanguage ? configJSON.emailIsTooLong : configJSON.ArabicEmailIsTooLong)
        .email(isArabicLanguage ? configJSON.ArabicPleaseEnteraValidEmail : configJSON.pleaseEnterAValidEmail)
        .required(isArabicLanguage ? configJSON.ArabicEmailIsRequire : configJSON.emailIsRequired)
    };
    return schema;
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    // const token = (await StorageProvider.get("USER_TOKEN")) || "";
    // console.log("@@@ Header Token =========>", token);
    const header = {
      "Content-Type": contentType,
      // token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    // console.log("@@@ Login API form =======", requestMessage);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async sendForgotPasswordEmail(email: string) {
    if(!email){
      return false;
    }
    this.emailSendAPI = await this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: 'bx_block_forgot_password/send_email',
      body: {
        "data": {
          "email": email
        },
        "email": email
      }
    })
  }

  async resetPassoword(password: string) {
    
    const queryParameter:any = await new URLSearchParams(window.location.search);
    const token:string = await queryParameter?.get('token')

    this.resetPasswordAPI = await this.apiCall({
      contentType: 'application/json',
      method: 'POST',
      endPoint: 'bx_block_forgot_password/reset_password',
      body: {
        data:{
          "token":token,
          "new_password": password
        }
      }
    })
  }

  async setShowPasswordRecovery(value: boolean, email: string) {
    await this.sendForgotPasswordEmail(email)
  }

  // Customizable Area End
}
