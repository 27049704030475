// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("../../config.json");

export interface Props {
// Customizable Area Start  
navigation: any;
id: string;
t: any;
classes: any;
importFor: string;
isInsurerUploadingCsvFile: boolean,
insurerPage: number;
insurerRowPerPage: number;
insurerTotalPage: number;
isLoading:boolean;
insurerCsvData:object[],
insurerShowAlert: boolean,
insurerAlertMessage: string;
insurerAlertType: string;
insurerErrorMessage: string;
insurerErrorFile: string;
isInsurerSaveBtnClicked: boolean;
handleInsurerAnotherCSVUpload: any;
downloadInsurerFile: any,
handleInsurerSaveCSVUpload: any;
handleInsurerCSVUploader: any;
handleInsurerChangePage: any;
handleInsurerRowPerPage: any;
insurertableBody: any;
closeInsurerToast: any 
// Customizable Area End
}

interface S {
  // Customizable Area Start
  isArabicLanguage: boolean;
  isUploadingCsvFile: boolean;
  page: number;
  rowPerPage: number;
  totalPage: number;
  isLoading:boolean;
  insurerCsvData:object[],
  showAlert: boolean,
  alertMessage: string;
  alertType: string;
  errorMessage: string;
  errorFile: string;
  isSaveBtnClicked: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class InsurerCsvImportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadInsurerCsvAPIMessage: string = '';
  getUploadedInsurerCsvAPIMessage: string = '';
  saveInsurerCsvAPIMessage: string = ''
  insurerFileId: any;
  deleteInsurerCsvAPIMessage: string = '';
  sampleCsvInsurerAPIKey: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isArabicLanguage:
        (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      isUploadingCsvFile: false,
      page: 1,
      rowPerPage: 10,
      totalPage: 1,
      isLoading:false,
      insurerCsvData:[],
      alertMessage: '',
      showAlert: false,
      alertType: '',
      errorMessage: '',
      errorFile: '',
      isSaveBtnClicked: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.setUpdateInsurerCsv(message);
    this.setSaveInsurerCsv(message);
    this.fetchInsurerCsvList(message);
    this.deleteInsurerUploadedCsvList(message)
    this.receiveDownloadedSampleCsvForInsurer(message)
    // Customizable Area End
  }
  componentWillUnmount(): any {
    this.insurerFileId && this.destroyInsurerUploadedCsv(this.insurerFileId)
  }
  // Customizable Area Start
  setArabicLanguage = (value: boolean) => {
    const language = value === true ? "ar" : "en";
    window.localStorage.setItem("lang", language);
    this.setState({
      isArabicLanguage: value
    });
  };

  async componentDidMount() {
    super.componentDidMount();
  }

  handleInsurerCSVUploader=(file: any)=>{
    this.setState({isUploadingCsvFile:true})
    this.uploadInsurerCsv(file)
  }

  closeInsurerToast = () => {
    this.setState({ showAlert: false })
  }

  handleInsurerAnotherCSVUpload=(file: any)=>{
    this.uploadInsurerCsv(file, this.insurerFileId)
  }
  
  handleInsurerSaveCSVUpload=()=>{
    this.setState({isSaveBtnClicked: true})
    this.saveInsurerCsv(this.insurerFileId)
  }

  handleInsurerChangePage = (event: any, page: number) => {
    this.setState({ page: page });
  };

  handleInsurerRowPerPage = (event: any) => {
    this.setState({ rowPerPage: parseInt(event.target.value) });
    this.setState({ page: 1 });
  };

  downloadInsurerFile = (fileUrl: string) => {
    
    const link = document.createElement('a');
    link.href = fileUrl;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } 

  setAlert = (responseMessage: string,alertType: string = 'error') => {
    this.setState({ showAlert: true, alertMessage: responseMessage, alertType: alertType})
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {
    if (
      (prevState.page !== this.state.page ||
        prevState.rowPerPage !== this.state.rowPerPage)
    ) {
      const {page, rowPerPage} = this.state
      this.getInsurerUploadedCsv(page, rowPerPage);
    }

  }

  getFormData = (file :  any, file_id: number| string) => {
    const formData = new FormData();
    file && formData.append('data[file]',file)
    file_id && formData.append('data[file_id]', file_id as string)
    
    return formData
  }

  uploadInsurerCsv = (file: any, file_id?: number) => {
    this.setState({
      isLoading:true
    })
    this.uploadInsurerCsvAPIMessage = this.apiCall({
      type : 'FormData',
      method: 'POST',
      body: this.getFormData(file, file_id as number),
      endPoint: 'bx_block_insurance_companies/insurance_companies/upload_insurer_csv',
    })
  }

  getInsurerUploadedCsv = (page: number, perPage: number) => {
    this.getUploadedInsurerCsvAPIMessage = this.apiCall({
      type : 'application/json',
      method: 'GET',
      endPoint: `bx_block_insurance_companies/insurance_companies/fetch_csv_uploaded_data?page=${page}&per_page=${perPage}&file_id=${this.insurerFileId}`,
    })
  }

  saveInsurerCsv = (file_id: number, ) => {
    this.saveInsurerCsvAPIMessage = this.apiCall({
      type : 'FormData',
      method: 'PUT',
      body: this.getFormData(null, file_id),
      endPoint: `bx_block_insurance_companies/insurance_companies/save_insurer_csv`
    })
  }

  destroyInsurerUploadedCsv = (fileId: string) => {
    this.deleteInsurerCsvAPIMessage = this.apiCall({
      type : 'application/json',
      method: 'DELETE',
      endPoint: `bx_block_insurance_companies/insurance_companies/destroy_csv_uploaded_data?file_id=${fileId}`
    })
  }

  downloadSampleCsvForInsurer = () => {
    this.sampleCsvInsurerAPIKey = this.apiCall({
      type : 'application/json',
      method: 'GET',
      endPoint: `/bx_block_sample_file/sample_files?name=Insurer`,
    })
  }

  apiCall(data: any) {
    const { contentType, method, endPoint, body, type } = data;
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    let header: any = {
      token: token,
      "Content-Type": contentType
    };

    const insurerCsvImportMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    insurerCsvImportMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    insurerCsvImportMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    insurerCsvImportMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== 'FormData' ?
    insurerCsvImportMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      ) : insurerCsvImportMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      ) ;

    runEngine.sendMessage(insurerCsvImportMessage.id, insurerCsvImportMessage);
    return insurerCsvImportMessage.messageId;
  }

  setUpdateInsurerCsv = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.uploadInsurerCsvAPIMessage !== null &&
      this.uploadInsurerCsvAPIMessage ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    )   {
      const updateInsurerCsvAPIResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
      try {
        if(updateInsurerCsvAPIResponse?.data){
          if (updateInsurerCsvAPIResponse?.data.error_message || updateInsurerCsvAPIResponse?.data.error_file) {
            this.setState({errorMessage: updateInsurerCsvAPIResponse?.data.error_message, errorFile: updateInsurerCsvAPIResponse?.data.error_file})
            this.setAlert(updateInsurerCsvAPIResponse?.data.error_message)
          } 
          if(updateInsurerCsvAPIResponse?.data.file_id) {
            if(!updateInsurerCsvAPIResponse?.data.error_message) {
              this.setState({errorMessage: '', errorFile: ''})
            }
            window.localStorage.setItem("insurer_file_id", JSON.stringify(updateInsurerCsvAPIResponse?.data?.file_id))
            this.insurerFileId = updateInsurerCsvAPIResponse?.data?.file_id;
            this.getInsurerUploadedCsv(this.state.page, this.state.rowPerPage)
          }
        } else {
          this.setAlert(updateInsurerCsvAPIResponse?.error)
        }
      } catch (error) {
        //ignore
      } finally {
        this.setState({isLoading:false})
      }
    }
  }
  
  setSaveInsurerCsv = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.saveInsurerCsvAPIMessage !== null &&
      this.saveInsurerCsvAPIMessage ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    )   {
      const saveInsurerCsvAPIResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
      if (saveInsurerCsvAPIResponse?.message) {
        this.setAlert(saveInsurerCsvAPIResponse?.message, 'success')
        this.insurerFileId = ''
        setTimeout(() => {
          window.history.back()
        }, 700)
      } else {
        this.setAlert(saveInsurerCsvAPIResponse.errors)
      }
    }
  }
  
  fetchInsurerCsvList = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getUploadedInsurerCsvAPIMessage !== null &&
      this.getUploadedInsurerCsvAPIMessage ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    )   {
      const fetchInsurerCsvAPIResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (fetchInsurerCsvAPIResponse?.data) {
        this.setState({isUploadingCsvFile: true})
        this.setState({insurerCsvData: fetchInsurerCsvAPIResponse.data, totalPage: fetchInsurerCsvAPIResponse.meta.total_page})
      } else {
        this.setAlert(fetchInsurerCsvAPIResponse.errors)
      }
    }
  }
  
  deleteInsurerUploadedCsvList = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteInsurerCsvAPIMessage !== null &&
      this.deleteInsurerCsvAPIMessage ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    )   {
      const deleteInsurerCsvAPIResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (deleteInsurerCsvAPIResponse?.message) {
        this.setAlert(deleteInsurerCsvAPIResponse?.message);
      } else {
        this.setAlert(deleteInsurerCsvAPIResponse?.errors)
      }
    }
  }
  receiveDownloadedSampleCsvForInsurer = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.sampleCsvInsurerAPIKey !== null &&
      this.sampleCsvInsurerAPIKey ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    )   {
      const saveInsurerSampleCsv = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (saveInsurerSampleCsv?.url) {
        this.downloadInsurerFile(saveInsurerSampleCsv?.url);
      } else {
        this.setAlert(saveInsurerSampleCsv?.error)
      }
    }
  
  }
  // Customizable Area End
}
