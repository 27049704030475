// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
export const configJSON = require("../config");

export interface Props {
  navigation?: any;
  id?: string;
}

interface S {
  policyList: any;
  page: number;
  rowPerPage: number;
  totalPage: number;
  searchQuery: string;
  showfilterDialog: boolean;
  filterCustomerList: any;
  filterInsurerList: any;
  operationsList: any;
  filteredData: any;
  customer_ids: string;
  insurer_ids: string;
  operation_id: string;
  types_of_insurances: string;
  expiry_limit: string;
  isFiltered: boolean;
  isExpire: boolean
}

interface SS {
  id: any;
}

export default class PolicyListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllPolicyListId: any;
  filteredCustomerId: any;
  getAllFilterCustomerListId: any;
  getAllFilterInsurerListId: any;
  getAllOperationsListId: any;
  getFilterDataId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      policyList: [],
      page: 1,
      rowPerPage: 10,
      totalPage: 1,
      searchQuery: "",
      showfilterDialog: false,
      filterCustomerList: [],
      filterInsurerList: [],
      operationsList: [],
      filteredData: {},
      customer_ids: "",
      insurer_ids: "",
      operation_id: "",
      types_of_insurances: "",
      expiry_limit: "",
      isFiltered: false,
      isExpire: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleToggle = () => {
    this.setState({ isExpire: !this.state.isExpire })
  }
  
  handlePolicyApiData = (message: Message, getId: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      getId != null &&
      getId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          policyList: responseJson.data,
          totalPage: responseJson.meta.total_page,
        });
      }
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.handlePolicyApiData(message, this.getAllPolicyListId)
    this.handlePolicyApiData(message, this.filteredCustomerId)

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null && apiRequestCallId == this.getAllFilterCustomerListId) {
        this.funcGetAllCustomersResponse(responseJson);
      }

      if (apiRequestCallId != null && apiRequestCallId == this.getAllFilterInsurerListId) {
        this.funcGetAllInsurerResponse(responseJson);
      }

      if (apiRequestCallId != null && apiRequestCallId == this.getAllOperationsListId) {
        this.funcGetAllOperationsResponse(responseJson);
      }

      else if (apiRequestCallId != null && apiRequestCallId == this.getFilterDataId) {
        this.getFilterResponse(responseJson);
      }
    }
  }

  async componentDidMount() {
    this.getFilteredData();
    this.funcForGetApiAllPolicies();
    this.funcForGetApiAllInsurer();
    this.funcForGetAllOperations();
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {
    if (this.state.isFiltered === false &&
      prevState.searchQuery !== this.state.searchQuery
    ) {
      this.setState({ page: 1 })
      this.getFilteredData();
    }

    if (prevState.isExpire !== this.state.isExpire) {
      this.setState({ page: 1, searchQuery: '' })
      this.getFilteredData();
    }

    if (this.state.isFiltered === false &&
      (prevState.page !== this.state.page ||
        prevState.rowPerPage !== this.state.rowPerPage)
    ) {
      this.getFilteredData();
    }

    if (
      this.state.isFiltered === true &&
      (prevState.page !== this.state.page ||
        prevState.rowPerPage !== this.state.rowPerPage)
    ) {
      this.getPoliciesFilteredList();
    }
  }

  handlePage = (event: any, page: number) => {
    this.setState({ page: page });
  };

  handleRow = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ rowPerPage: parseInt(event.target.value) });
    this.setState({ page: 1 });
  };

  handleSearchQuery = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({
      searchQuery: event.target.value,
      isFiltered: false,
      page: 1
    });
  };

  getFilteredData() {
    const userData = JSON.parse(localStorage.getItem("loginData") ?? "{}");
    const token = userData.token || "";
    this.setState({ isFiltered: false });
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    let customerApiPath = this.state.searchQuery ? `/bx_block_policies/policies?page=${this.state.page}&per_page=${this.state.rowPerPage}&query=${this.state.searchQuery}` : `/bx_block_policies/policies?page=${this.state.page}&per_page=${this.state.rowPerPage}&expired_policy=${this.state.isExpire}`

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filteredCustomerId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      customerApiPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  closefilterDialog = () => {
    this.setState({ showfilterDialog: false });
  };

  handleFilterDialog = async (
    customers: any,
    insurer: any,
    operation: any,
    insurance: any,
    expiryLimit: any
  ) => {
    this.setState(
      {
        customer_ids: customers.map((customer: any) => customer.value.toString()),
        insurer_ids: insurer.map((user: any) => user.value.toString()),
        operation_id: operation.map((item: any) => item.value.toString()),
        expiry_limit: expiryLimit.toString(),
        types_of_insurances: insurance.toString(),
        page: 1
      },
      () => {
        this.getPoliciesFilteredList();
      }
    );

    this.closefilterDialog();
  };

  openFilterDialog = () => {
    this.setState({ showfilterDialog: true });
  };

  funcGetAllCustomersResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ filterCustomerList: responseJson.data });
    }
  };

  funcGetAllInsurerResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ filterInsurerList: responseJson.data });
    }
  };

  funcGetAllOperationsResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ operationsList: responseJson.data });
    }
  };

  funcForGetApiAllPolicies = async () => {
    const userData = JSON.parse(localStorage.getItem("loginData") ?? "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllFilterCustomerListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiGetAllCustomersList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  funcForGetApiAllInsurer = async () => {
    const userData = JSON.parse(localStorage.getItem("loginData") ?? "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllFilterInsurerListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiGetAllInsurerList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  funcForGetAllOperations = async () => {
    const userData = JSON.parse(localStorage.getItem("loginData") ?? "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllOperationsListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiGetOperationsList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPoliciesFilteredList = async () => {
    const userData = JSON.parse(localStorage.getItem("loginData") ?? "{}");
    const token = userData.token || "";
    this.setState({ isFiltered: true });

    const header = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFilterDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_policies/policies?filter[customer_id]=${this.state.customer_ids}&filter[types_of_insurance]=${this.state.types_of_insurances}&filter[insurance_company_id]=${this.state.insurer_ids}&filter[operation_id]=${this.state.operation_id}&filter[policy_expiry_date]=${this.state.expiry_limit}&expired_policy=${this.state.isExpire}&page=${this.state.page}&per_page=${this.state.rowPerPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFilterResponse = async (responseJson: any) => {
    this.setState({
      policyList: responseJson.data,
      totalPage: responseJson.meta.total_page,
      searchQuery: "",
    });
  };
}
// Customizable Area End
