// Customizable Area Start
import React from "react";
import { withTranslation } from "react-i18next";
import {
    Grid,
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails,
    IconButton,
  } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import { Formik } from "formik";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Toster from "../../../../../../components/src/Common/Toster";
import CustomButton from "../../../../../../components/src/Common/CustomButton";
import HandOverDialog from "../../../../../../components/src/Common/HandOverDialog.web";
import RejectRequestDialog from "../../../../../../components/src/Common/RejectRequestDialog.web";
import StageNineController, {Props} from "./InvoiceStageController";
import StageNineInstalmentForm from "./InvoiceInstallment";
import * as Yup from "yup";

type Severity = "error" | "success" | "info" | "warning" | undefined;

// Customizable Area End

export class StageNineForm extends StageNineController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.formikRef = React.createRef();
    // Customizable Area End
  }

    // Customizable Area Start

    handleSubmitFromOutside = () => {
      this.setState({isError: true})
      const { current: formikInstance } = this.formikRef;
      if (formikInstance) {
        formikInstance.handleSubmit();
      }
    }
    getPayInstallment = (currentFlow: number, stageSevenData: any) => {
        let showPayInstalmentBtn: boolean = false;
        if (currentFlow == 2 || currentFlow == 5 || currentFlow == 6) {
            showPayInstalmentBtn = !!stageSevenData?.data?.attributes.revision_installments?.data.find((record: any) => record.attributes.installment_paid === false);
        }
        return showPayInstalmentBtn
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { t, classes, rrStageNineData } = this.props;
        const {flow: currentFlow, alertMessage, showAlert, alertType, showNoteDialog, handOverNoteValue, showRejectDialog, stageSevenInstallments} = this.state;
        const stageSevenData = this.state.rrStageSeven;
        const insurance_company = stageSevenData.data?.attributes?.insurance_company_id?.short_name || '';
        let showPayInstalmentBtn: boolean = this.getPayInstallment(currentFlow, stageSevenData);
        // Customizable Area End

        return (
            // Customizable Area Start
            <React.Fragment>
                <Toster
                    tosterText={alertMessage}
                    showToster={showAlert}
                    tosterType={alertType as Severity}
                    handleCloseToster={() => this.setState({ showAlert: false })}
                />
                <Grid className={classes.customerDetailsAccordion} item>
                    <Formik
                        data-test-id="StageNineForm"
                        innerRef={this.formikRef}
                        initialValues={this.getFormInitialValues()}
                        enableReinitialize={true}
                        validateOnMount={true}
                        validateOnBlur={true}
                        validationSchema={Yup.object().shape(
                            this.operationSchema(currentFlow)
                        )}
                        onSubmit={(values) => this.submitProcessingData(values, false)}
                    >
                    {({
                        values,
                        errors,
                        setFieldValue,
                    }) => {
                        const isInstallment = [2, 5, 6].includes(currentFlow);
                        let balance = 0;
                        if([1, 2, 3, 5].includes(currentFlow)) {
                            balance = Number(values?.commissionAmount);
                        }
                        if(isInstallment && [2, 5].includes(currentFlow)) {
                            values?.revision_installments?.map((data: any) => {
                              balance = balance + (Number(data?.commissionAmount) || 0);
                            })
                        }
                        return (
                            <React.Fragment>
                                <Accordion
                                    data-test-id="p9"
                                    className={
                                        this.state.errorSection === "p9" && classes.errorSection
                                        ? classes.errorSection
                                        : classes.accordionStyle
                                    }
                                    expanded={this.isStageNineAccordionOpen(this.props.isExpanded)}
                                    onChange={(event, isExpanded) => {this.handleStageNineAccordian(isExpanded, "p9") }}
                                >
                                <AccordionSummary
                                    aria-expanded={true}
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className={classes.topSection}>
                                    <div className={classes.displayFlex}>
                                        <Typography className={classes.accordianHeading} >{t('invoicing')}</Typography>
                                    </div>
                                    { this.state.expandedAccordion && (
                                        <IconButton className={classes.descriptionIcon} data-test-id="noteDialog" onClick={() => this.handleNoteDialog(true)}>
                                            <DescriptionOutlinedIcon/>
                                        </IconButton>
                                    )}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid className={classes.contactForm}>
                                        <div key={`installment-${currentFlow}`} className={isInstallment ? classes.installmentAlignment : ''}>
                                            {isInstallment && (
                                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Typography style={{ fontWeight: 800, paddingBottom: 10 }}>{t("down_payment")}</Typography>
                                                </Grid>
                                            )}
                                            <StageNineInstalmentForm
                                                isInstallment={false}
                                                values={values}
                                                formikRef={this.formikRef}
                                                setFieldValue={setFieldValue}
                                                rrStageSevenData={stageSevenData}
                                                rrStageNineData={this.props.rrStageNineData}
                                            />
                                        </div>
                                        {isInstallment && stageSevenInstallments.map((item: any, index: number) =>
                                            <div key={`installment-${index.toString()}`} style={{ marginTop: 25 }}>
                                            <Accordion
                                                data-test-id="p9"
                                                defaultExpanded={false}
                                                className={(this.state.isError && (errors?.revision_installments as string[])?.[index]) ? classes.accordionErrorStyle  : classes.downPaymentAccordionStyle}
                                            >
                                                <AccordionSummary
                                                id="panel1a-header"
                                                aria-controls="panel1a-content"
                                                expandIcon={<ExpandMore />}
                                                >
                                                <div className={classes.topSection}>
                                                    <div className={classes.displayFlex}>
                                                        <Typography className={classes.installmentTitle} >{index + 2}{this.getSuffix(index + 2)}
                                                            {t("installment")}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                </AccordionSummary>
                                                <AccordionDetails style={{}} className={classes.installmentForm}>
                                                    <StageNineInstalmentForm
                                                        isInstallment={true}
                                                        installmentIndex={index}
                                                        values={values}
                                                        formikRef={this.formikRef}
                                                        setFieldValue={setFieldValue}
                                                        rrStageSevenData={stageSevenData}
                                                        rrStageNineData={this.props.rrStageNineData}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>
                                            </div>
                                        )}
                                        {([1, 2, 3, 5].includes(currentFlow)) && (
                                            <Grid item xs={12} container className={classes.balanceGrid}>
                                                <Typography>{t("revenue_siib_commission_from")} {insurance_company}</Typography>
                                                <Typography>SAR {balance.toFixed(2)}</Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </AccordionDetails>
                                </Accordion>
                                <Grid>
                                    {(!rrStageNineData?.data?.attributes?.is_next_stage_submitted && this.isStageNineAccordionOpen(this.props.isExpanded)) ? (
                                        <div className={classes.BtnGroupSpacing}>
                                            <div className={classes.buttonsRightWrapper}>
                                                <CustomButton
                                                    data-test-id="rv_savedraft_btn"
                                                    btnText={t("save_draft")}
                                                    onClick={() => this.submitProcessingData(values, true)}
                                                    bgColor={"#fff"}
                                                    textColor={"#007E98"}
                                                />
                                                <CustomButton
                                                    data-test-id="rv_reject_btn"
                                                    btnText={t("reject")}
                                                    onClick={() => this.handleStageNineRejectDialog(true)}
                                                    bgColor={"#fff"}
                                                    textColor={"#007E98"}
                                                />
                                                {showPayInstalmentBtn ?
                                                  <CustomButton
                                                    data-test-id="rv_nextBtn_btn"
                                                    btnText={t("pay_installment")}
                                                    onClick={() => {
                                                        this.payInstallmentFlag()
                                                        this.handleSubmitFromOutside()
                                                    }}
                                                    bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                                    textColor={"#fff"}
                                                  /> : <CustomButton
                                                    data-test-id="rv_nextBtn_btn"
                                                    btnText={t("complete")}
                                                    onClick={() => this.handleSubmitFromOutside()}
                                                    bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                                    textColor={"#fff"}
                                                  />
                                                }
                                            </div>
                                        </div>
                                    ) : null}
                                </Grid>
                            </React.Fragment>
                        );
                    }}
                    </Formik>
                </Grid>

                <HandOverDialog
                    data-test-id="HandOverDialog"
                    btnCancelText={t('cancel')}
                    btnOkText={t('confirm')}
                    openDialog={this.state.handOverNote as boolean}
                    textId={'handOverNoteText'}
                    headingText={t('handover_note')}
                    subHeading={`(${t('payment_review')} > ${t('invoicing')})`}
                    inputValue={this.props.handoverNote}
                    handleOk={() => this.handleNoteDialog(false)}
                    handleCancel={() => this.handleNoteDialog(false)}
                    showDialogBtns={false}
                    showCrossBtn={true}
                    inputDisable={true}
                />
                <HandOverDialog
                    data-test-id="HandOverDialog"
                    btnCancelText={t('cancel')}
                    btnOkText={t('confirm')}
                    openDialog={showNoteDialog as boolean}
                    textId={'handOverNoteText'}
                    headingText={t('handover_note')}
                    subHeading={`(${t('invoicing')} > ${t('complete')})`}
                    inputValue={handOverNoteValue}
                    handleInputText={(inputText: string) => this.setState({ handOverNoteValue: inputText })}
                    handleOk={() => this.trackStageNine('next')}
                    handleCancel={() => this.handleHandOverNote(false)}
                    showDialogBtns={true}
                    showCrossBtn={true}
                    inputDisable={!this.props.isExpanded}
                />

                <RejectRequestDialog
                    btnCancelText={t('cancel')}
                    btnOkText={t('confirm')}
                    openDialog={showRejectDialog}
                    textId={'rejectReason'}
                    pushBackOptions={this.getPolicyOption()}
                    setSelectedPushBackTo={(e: any) => {
                    this.setState({ currentRejectedStage: e.value })}}
                    headingText={'Rejecting'}
                    handleInputText={(value: string) => this.setState({ stageNineRejectReason: value })}
                    handleOk={() => this.trackStageNine('reject')}
                    handleCancel={() => this.handleStageNineRejectDialog(false)}
                    showDialogBtns={true}
                    showCrossBtn={true}
                    labelText={t('push_back')}
                    reasonLabel={t('reason')} 
                />
            </React.Fragment>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const useStyles = ({
    customerDetailsAccordion: {
      paddingTop: "10px",
      paddingBottom: "10px",
      "& .MuiAccordion-root-34.Mui-disabled, .MuiAccordion-root-42.Mui-disabled": {
        backgroundColor: "white",
      },
      " & .MuiAccordion-root.Mui-disabled": {
        backgroundColor: "white",
      },
    },
    grayBtn: {
      "text-transform": 'none',
      padding: 15,
      borderRadius: 5,
      width: '32.5%',
      fontSize: 15,
      backgroundColor: "#979090",
      color: "white"
    },
    installmentForm: { display: "grid", borderTop: '3px solid #eaeaea' },
    descriptionIcon: { color: '#007E98' },
    topSection: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
    displayFlex: { display: "flex" },
    errorSection: { border: "1px solid red" },
    accordionStyle: {
      background: "white !important",
      borderRadius: "10px !important",
    },
    accordianHeading: { fontWeight: 600 },
    contactForm: { width: "100%" },
    installmentAlignment: {
      border: "3px solid #eaeaea",
      borderRadius: "10px",
      padding: "20px",
    },
    downPaymentAccordionStyle: {
      border: '3px solid #eaeaea',
      borderRadius: "10px !important",
      boxShadow: 'none'
    },
    accordionErrorStyle: {
        border: '1px solid #f44336',
        borderRadius: "10px !important",
        boxShadow: 'none'
    },
    installmentTitle: {
      fontWeight: 600
    },
    balanceGrid:{
      display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20
    },
    BtnGroupSpacing: {
      padding: "0px 15px",
    },
    buttonsRightWrapper: {
      display: "flex",
      gap: "15px",
      padding: "15px 0px",
      justifyContent: "flex-end",
    },
});
// Customizable Area End

export default withStyles(useStyles)(withTranslation()(StageNineForm));
