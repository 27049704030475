// Customizable Area Start
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import * as Yup from 'yup';
import ActiveLanguage from "../../../../../../components/src/Common/ActiveLanguage";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    t?: any;
    classes?: any;
    currentStep?: number;
    rrStageOneData?: any;
    isExpanded?: boolean
    isEdit?: boolean;
    onNext?: any;
    setStageData?: any;
    policyID?: string;
    invoiceAmount?: number;
    revisionRequestID?: string;
    innerState?: any;
    handleToggle?: any;
    openEditDialog?: any;
    handleInputText?: any;
    handleCheckbox?: any;
    partTwoApiCall?: any;
    handOverNoteSubmit?: any;
    markPolicyAsLost?: any;
    showMarkAsLostDialog?: any;
    handleHandOverNoteDialog?: any;
    handleCsvImport?:any;
    handleAccordian?: any;
    partTwoSchema?: any;
    savePolicyAsDraft?: any;
    handoverNote?: string;
    currentSteps: number;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    markAsLostDialog: boolean,
    isBtnVisible: boolean;
    showHandOverNote: boolean,
    showRejectDialog: boolean,
    saveDraftLoading: boolean,
    policyIsSavedAsDraft: boolean,
    policyId: string,
    revisionRequestId: string,
    p1Submited: boolean,
    showDialog: boolean,
    loading: boolean,
    handOverNoteText: any,
    alertMessage: string,
    alertType: string,
    showAlert: boolean,
    errorSection: string,
    stageData?: any,
    isEdit?: boolean;
    isArabicLenguage: boolean,
    contectOption?: any[],
    salesOption?: any,
    operationOptions?: any[],
    financeOptions?: any[],
    expandedAccordion: string,
    revisionInput: boolean,
    p2Submitted: boolean,
    isChecked: boolean,
    isEditDialog: boolean,
    dataToBeEdited: any,
    editedCsvData: any,
    pageCount: number;
    perPageCount: number;
    totalPage: number;
    deletedCsvData: any[];
    csvData: any[];
    searchQuery: string;
    isPartTwoDataCreated: boolean;
    userRole: string;
    attachment: string;
    comments: string;
    paymentReciepent:string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class StageOneController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    saveRvPolicyAsDraftApi: any = "";
    removeFileAPIStageOne:any=""
    markPolicyAsLostApi: any = "";
    handoverNoteApi: any = "";
    stageOnePartOneNextApi: any = "";
    getPolicyDetailsAPI: any = "";
    getSalesListAPI: any = "";
    getFinanceListAPI: any = "";
    getOperationsListAPI: any = "";
    getContactsApi: any = "";
    getAllCsvDataAPI: any = "";
    stageOnePartTwoNextAPI: any = "";
    stageOnePartOneEditApi: any = "";
    // Customizable Area End

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),

        ];

        const getRole = () => {
            const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
            const userRole = userData.role || "";
            return userRole as string
        }

        this.state = {
            showHandOverNote: false,
            showRejectDialog: false,
            markAsLostDialog: false,
            isBtnVisible: true,
            saveDraftLoading: false,
            policyIsSavedAsDraft: false,
            policyId: "",
            revisionRequestId: "",
            loading: false,
            handOverNoteText: this.props.handoverNote || "",
            alertMessage: "",
            alertType: "",
            showAlert: false,
            isArabicLenguage: false,
            expandedAccordion: this.props.isExpanded ? "p1" : "",
            p1Submited: false,
            showDialog: false,
            revisionInput: false,
            errorSection: "",
            isChecked: false,
            isEditDialog: false,
            dataToBeEdited: {},
            editedCsvData: [],
            pageCount: 1,
            perPageCount: 10,
            totalPage: 1,
            deletedCsvData: [],
            csvData: [],
            searchQuery: "",
            isPartTwoDataCreated: false,
            userRole: getRole(),
            attachment: "",
            comments: "",
            paymentReciepent:"",
            isEdit: false,
            p2Submitted: false,
            stageData: {
                insuranceType: "",
                insurer: "",
                covered: "",
                policyActivationDate: "",
                policExpiryDate: "",
                policyValue: "",
                currentPolicyValue: "",
                policyBalanceDue: "",
                policyID: "",
                contactID: "",
                contactName: "",
                contectPosition: "",
                contactPhoneNumber: "",
                contactEmail: "",
                contactStatus: "",
                sales: "",
                operation: "",
                finance: "",
                revisionType: ""
            }
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.getPolicyDetailsAPIReceive(message);
        this.getContactsApiReceive(message)
        this.stageOnePartOneNextApiReceive(message);
        this.getAllCsvDataApiReceive(message);
        this.stageOnePartTwoNextAPIReceive(message);
        this.stageOnePartOneEditApiReceive(message);
        this.handoverNoteAPIReceive(message);
        this.saveRvPolicyAsDraftApiReceive(message);
        this.fileRemoveApiReceiveStageOne(message)
        this.getSalesListAPIReceive(message);
        this.getFinanceListAPIReceive(message);
        this.getOperationsListAPIReceive(message);
        this.markPolicyAsLostAPIReceive(message);
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        if (this.props.policyID) {
            this.getPolicy(this.props.policyID);
            if(this.state.userRole === "Admin") {
                this.setState({expandedAccordion:""})
                this.getSalesList()
                this.getFinanceList()
                this.getOperationsList()
            }
        }
        if (this.props.revisionRequestID) {
            this.setState({revisionRequestId: this.props.revisionRequestID})
            if(this.state.userRole === "Admin") {
                this.getSalesList()
                this.getFinanceList()
                this.getOperationsList()
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<S>,
        snapshot?: SS | undefined
      ) {
        if (
          prevState.searchQuery !== this.state.searchQuery
        ) {
          this.setState({ pageCount: 1 })
          this.getAllCsvData(this.state.revisionRequestId);
        }

        if (prevProps.isExpanded !== this.props.isExpanded) {
            if (this.props.isExpanded) {
                this.setState({expandedAccordion: 'p2'})
            } else {
                this.setState({expandedAccordion: ''})
            }
        }

        if (
            prevProps.revisionRequestID !== this.props.revisionRequestID
          ) {
            this.setState({ revisionRequestId: this.props.revisionRequestID || "" })
          }

        if (prevState.pageCount !== this.state.pageCount ||
            prevState.perPageCount !== this.state.perPageCount)
        {
          this.getAllCsvData(this.state.revisionRequestId);
        }
        /* istanbul ignore if */
        if (this.props.revisionRequestID && this.props.policyID === "" && (prevProps.rrStageOneData !== this.props.rrStageOneData)){
            this.getAllCsvData(this.props.revisionRequestID)
            const stageOneData = this.props?.rrStageOneData?.data?.attributes
            const revisionInputData = stageOneData?.revision_input?.data?.attributes
            this.getPolicy(stageOneData?.policy_id)
            this.setState({
                revisionInput: true,
                handOverNoteText: this.props.handoverNote,
                isEdit: true,
                attachment: revisionInputData?.upload_attachment[revisionInputData?.upload_attachment.length - 1],
                comments: revisionInputData?.client_comment,
                paymentReciepent:revisionInputData?.upgrade_to,
                stageData: {
                contactID: stageOneData?.customer_contact_id,
                currentPolicyValue: stageOneData?.current_policy_value,
                contactName: { value: stageOneData?.customer_contact_name, label: stageOneData?.customer_contact_name, allData: { id: stageOneData?.customer_contact_id } },
                contectPosition: stageOneData?.customer_contact_position,
                contactPhoneNumber: stageOneData?.customer_contact_contact_no,
                contactEmail: stageOneData?.customer_contact_email,
                contactStatus: stageOneData?.customer_contact_account_status,
                revisionType: stageOneData?.revision_type.value,
                sales: { value: stageOneData?.sales_id?.name, label: stageOneData?.sales_id?.name, allData: { id: stageOneData?.sales_id?.id } },
                operation: { value: stageOneData?.operation_id?.name, label: stageOneData?.operation_id?.name, allData: { id: stageOneData?.operation_id?.id } },
                finance: { value: stageOneData?.finance_id?.name, label: stageOneData?.finance_id?.name, allData: { id: stageOneData?.finance_id?.id } }
            }})
            if (revisionInputData) this.setState({isPartTwoDataCreated: true})
        }
        if (prevProps.invoiceAmount !== this.props.invoiceAmount) {
            this.updateCurrentPolicyValue();
        }
      }

      updateCurrentPolicyValue = () => {
        const { stageData } = this.state;
        const { invoiceAmount } = this.props;
        const currentPolicyValue = Number(stageData.policyValue) + (invoiceAmount || 0);
        this.setState({
            stageData: {
                ...stageData,
                currentPolicyValue,
            },
        });
      };
      

      apiCall(data: any) {
        const { contentType, method, endPoint, body } = data;
        const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
        const token = userData.token || "";

        const header = {
            "Content-Type": contentType,
            token: token,
        };
        contentType === 'FormData' && delete header["Content-Type"];

        const requestMessageForStageOne = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        requestMessageForStageOne.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageForStageOne.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessageForStageOne.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );

        body && contentType !== 'FormData' ?
            requestMessageForStageOne.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            ) : requestMessageForStageOne.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );

        runEngine.sendMessage(requestMessageForStageOne.id, requestMessageForStageOne);
        return requestMessageForStageOne.messageId;
    }

    overviewSchema = () => {
        const schema = {
            contactName: Yup.string().required(ActiveLanguage() ? "اسم جهة الاتصال مطلوب" : "Contact name is required."),
            revisionType: Yup.string().required(ActiveLanguage() ? "نوع المراجعة مطلوب" : "Revision type is required.")
        }
        return schema;
    }

    partTwoSchema = () => {
        let schema = {};
        if(this.state.stageData.revisionType === "upgrade" && this.state.stageData.insuranceType === "Medical") {
            schema = {
                comments: Yup.string().required(ActiveLanguage() ? "التعليقات مطلوبة" : "Comments is required."),
                paymentReciepent: Yup.string().required(ActiveLanguage() ? "مطلوب مستلم الدفع" : "Payment Recipient is required.")
            }
        }
        else schema = {
            comments: Yup.string().required(ActiveLanguage() ? "التعليقات مطلوبة" : "Comments is required."),
        }
        return schema;
    }

    getAllCsvData = (revisionRequestID: string) => {
        this.getAllCsvDataAPI = this.apiCall({
            contentType: 'application/json',
            method: "GET",
            endPoint: `bx_block_revision_request/revision_requests/get_vehicle_employee_data?page=${this.state.pageCount}&per_page=${this.state.perPageCount}&id=${revisionRequestID}`,
        })
    }

    handleSearchQuery = (
        event: any
      ) => {
        this.setState({
          searchQuery: event.target.value,
          pageCount: 1
        });
      };


    handlePage = (event: any, page: number) => {
        this.setState({
            pageCount: page,
        });
    };

    handleRow = (event: any) => {
        this.setState({
            perPageCount: Number(event.target.value),
        });
    };
    removeFilestageOne = (fileId: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
         this.removeFileAPIStageOne = this.apiCall({
            method: 'DELETE',
            endPoint: `bx_block_revision_request/revision_requests/${id}/remove_file`,
            contentType: 'application/json',
            body: {
                "data": {
                    "file_id": [fileId]
                }
            }
        });
    }
    fileRemoveApiReceiveStageOne = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.removeFileAPIStageOne !== null &&
            this.removeFileAPIStageOne ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
            const responseJsonStageOne = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            /* istanbul ignore next*/
            if (responseJsonStageOne.data) {
              
                responseJsonStageOne.data.error_file && this.setState({
                    alertType: "error",
                    alertMessage: responseJsonStageOne.data.error_file,
                    showAlert: true,
                })
                responseJsonStageOne.data?.success && this.setState({
                    alertType: "success",
                    showAlert: true,
                    alertMessage: responseJsonStageOne.data?.success,
                    })
                responseJsonStageOne.data.data_file?.length && this.setState({stageData: {
                    uploadedAttachments: responseJsonStageOne.data.data_file
                }})
            }
        }
    }
    handleCheckbox = (event: any, data: any) => {
        const removeData = this.state.deletedCsvData.find((csvData) => csvData.id === data.id)
        if (removeData) {
            const newData = this.state.deletedCsvData.filter(item => item.id !== data.id);

            this.setState({
                deletedCsvData: newData,
            });
        }
        else {
            this.setState((prevState) => ({
                deletedCsvData: [...prevState.deletedCsvData, data], // Add the newData to the existing array
            }));
        }
    };


    handleAccordian(isExpanded: boolean, accordionName: string, p1Submited: boolean = false, isEdit:boolean = false) {
        if (!(p1Submited || isEdit) && accordionName === "p2") {
            this.setState({
                alertMessage: ActiveLanguage() ? "يرجى تقديم نموذج النظرة العامة أولاً" : "Please first submit overview form.",
                alertType: "warning",
                showAlert: true
            })
        } else if (this.state.policyId !== "" || this.state.revisionRequestId !== "") {
            this.setState({
                expandedAccordion: isExpanded ? accordionName : "",
                alertType: "",
                showAlert: false
            })
        } else {
            this.setState({
                expandedAccordion: isExpanded ? accordionName : ""
            })
        }
    }

    handleEditedData = (data: any) => {
        const isEdited = this.state.editedCsvData.find((editedData: any) => editedData.id === data.id)
        if (!isEdited) this.setState({ editedCsvData: [...this.state.editedCsvData, data], isEditDialog: false })
        else {
            this.state.editedCsvData.forEach((editedData: any, index: number) => {
                if (editedData.id === data.id) {
                    this.setState((prevState): any => {
                        const updatedDataArray = [...prevState.editedCsvData];
                        updatedDataArray[index] = {
                            ...updatedDataArray[index],
                            ['attributes']: data.attributes,
                        };

                        return { editedCsvData: updatedDataArray, isEditDialog: false };
                    });
                }
            })
        }
    }

    isPartOneExpanded = (isExpanded: any) => {
        if ((isExpanded === true) && (this.state.expandedAccordion === "p1")) {
            return true;
        }
        if ((this.state.policyId !== "" || this.state.revisionRequestId !== "") && (isExpanded === true)) {
            return false;
        }
        if ((this.state.policyId !== "" || this.state.revisionRequestId !== "") && (this.state.expandedAccordion === "p1")) {
            return true;
        }
        return false;
    }

    isPartTwoExpanded = (isExpanded: boolean = false, p1Submited: boolean = false, p2Submitted: boolean = false) => {
        if (p1Submited && this.state.expandedAccordion === 'p1' && !p2Submitted) {
            return true
        }
        if (p1Submited && this.state.expandedAccordion === 'p2' && p2Submitted) {
            return true
        }
        if ((isExpanded === true) && (this.state.expandedAccordion === "p2")) {
            return true;
        }
        if ((this.state.policyId !== "" || this.state.revisionRequestId !== "") && (isExpanded === true)) {
            return false;
        }
        if ((this.state.policyId !== "" || this.state.revisionRequestId !== "") && (this.state.expandedAccordion === "p2")) {
            return true;
        }
        return false;
    }

    showStageOneApiErrorMessage = (stageOneResponseJson: any) => {
        if (stageOneResponseJson.errors) {

            const stageOneKeys = Object.keys(stageOneResponseJson.errors);
            const stageOneFirstKey = stageOneKeys && stageOneKeys[0];
            const StageOneErrorValue = (typeof (stageOneResponseJson.errors) === "string") ? stageOneResponseJson.errors : (stageOneFirstKey && stageOneResponseJson.errors[stageOneFirstKey][0] as string) || undefined;

            const StageOneErrorMessage = StageOneErrorValue || "Something went wrong";
            this.setState({
                alertType: "error",
                alertMessage: StageOneErrorMessage,
                showAlert: true,
                loading: false,
                expandedAccordion: "",
                saveDraftLoading: false,
                handOverNoteText: "",
                showHandOverNote: false,
                showDialog: false,
                markAsLostDialog: false
            })
        }
    }

    showMarkAsLostDialog = () => {
        this.setState({ markAsLostDialog: true, showHandOverNote: true })
    }

    savePolicyAsDraft = async (data: any, id: string, isCreated: boolean) => {
        this.setState({ saveDraftLoading: true, policyIsSavedAsDraft: true });

        let stageOneFormData = new FormData();
        stageOneFormData.append(`data[revision_input][client_comment]`, data.comments)
        stageOneFormData.append(`data[revision_input][save_as_draft]`, 'yes')

        typeof data?.attachment === 'object'&&(Object.keys(data?.attachment).length!==0||data?.attachment?.size)&&stageOneFormData.append(`data[upload_attachment][]`, data.attachment)
        isCreated && stageOneFormData.append(`data[customer_contact_id]`, this.state.stageData.contactID)
        isCreated && stageOneFormData.append(`data[policy_id]`, this.props?.rrStageOneData?.data?.attributes?.policy_id)

        if (this.state.stageData.revisionType === "upgrade")  stageOneFormData.append(`data[revision_input][upgrade_to]`, data.paymentReciepent)

        if (this.state.stageData.revisionType === "remove") {
            this.state.deletedCsvData.forEach((data: any, index: number) => {
                if(this.state.stageData.insuranceType === "Medical" || this.state.stageData.insuranceType === "Life") stageOneFormData.append(`data[employee_lists][${index}][id]`, data.id)
                else stageOneFormData.append(`data[vehicle_lists][${index}][id]`, data.id)
            })
        }
        if (this.state.stageData.revisionType === "correct") {
            this.state.editedCsvData.forEach((data: any, index: number) => {
                this.getPartTwoFormData(data, index)
            })
        }
            this.saveRvPolicyAsDraftApi = this.apiCall({
                contentType: 'FormData',
                method:  isCreated ? 'PUT' : 'POST',
                endPoint: isCreated ? `bx_block_revision_request/revision_requests/${this.state.revisionRequestId}?stage=1&part=2` : `bx_block_revision_request/revision_requests?stage=1&part=2&id=${this.state.revisionRequestId}`,
                body: stageOneFormData
            })
    }

    markPolicyAsLost = (value: string) => {
        if (this.state.revisionRequestId) {
            const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
            const token = userData.token || '';
            this.markPolicyAsLostApi = this.apiCall({
                contentType: 'application/json',
                method: 'PUT',
                endPoint: `bx_block_revision_request/revision_requests/${this.state.revisionRequestId}/mark_as`,
                token: token || '',
                body: {
                    data: {
                        mark_as: "lost",
                        lost_reason: value
                    }
                }
            })
        }
    }

    handOverNoteSubmit = (value: string) => {
        interface HandoverNoteApiProps {
            stage_status: string;
            current_stage: string;
            comment?: string
        }

        if (this.state.revisionRequestId) {
            const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
            const token = userData.token || '';
            let bodyData: HandoverNoteApiProps = {
                "stage_status": "next_stage",
                "current_stage": "processing"
            }
            if (value) {
                bodyData.comment = value
            }
            this.handoverNoteApi = this.apiCall({
                contentType: 'application/json',
                method: 'POST',
                endPoint: `bx_block_revision_request/revision_requests/${this.state.revisionRequestId}/track_revision_requests?stage=1`,
                token: token || '',
                body: {
                    "data": {
                        "track_revision_request": bodyData
                    }
                }
            })
        }
    }

    handleHandOverNoteDialog = (show: boolean, loading = false) => {
        this.setState({ markAsLostDialog: false, handOverNoteText: "", showHandOverNote: show, loading: loading, isBtnVisible: true })
    }

    submitStageOne = (stage: number, action: string, formData: any, id: string = "", isEdit: boolean = false) => {
        if (stage === 0.5 && action == "next") {
            this.stageOnePartOneApiCall(formData);
        }
        if (stage === 1 && action === "next") {
            this.stageOnePartTwoAPICall(formData, id, isEdit)
        }
    }

    getPolicy = async (policyID: any) => {
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';
        this.getPolicyDetailsAPI = this.apiCall({
            contentType: 'application/json',
            method: "GET",
            endPoint: `bx_block_policies/policies/${policyID}`,
            token: token || '',
        })
    }

    handleLostIcon = () => {this.setState({showHandOverNote: true, isBtnVisible: false, markAsLostDialog: true})}

    getSalesList = async () => {
        const userData =  JSON.parse(localStorage.getItem('loginData') || '{}');
        const token =  userData.token || '';
        this.getSalesListAPI = this.apiCall({
            contentType: 'application/json',
            method: "GET",
            endPoint: `account_block/accounts?role=Sales`,
            token: token || '',
        })
    }

    getFinanceList = async () => {
        const userData =  JSON.parse(localStorage.getItem('loginData') || '{}');
        const token =  userData.token || '';
        this.getFinanceListAPI = this.apiCall({
            contentType: 'application/json',
            method: "GET",
            endPoint: `account_block/accounts?role=Finance`,
            token: token || '',
        })
    }

    getOperationsList = async () => {
        const userData =  JSON.parse(localStorage.getItem('loginData') || '{}');
        const token =  userData.token || '';
        this.getOperationsListAPI = this.apiCall({
            contentType: 'application/json',
            method: "GET",
            endPoint: `account_block/accounts?role=Operations`,
            token: token || '',
        })
    }

    getConectOptions = async (customerId: string) => {
        const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
        const token = userData.token || '';
        this.getContactsApi = this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_customer/customers/contacts?id=${customerId}`,
            token: token || ''
        })
    }

    stageOnePartOneApiCall = async (formData: any) => {
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';
        const bodyData = {
            data: {
                covered: formData.covered,
                policy_id: formData.policyID,
                revision_type: formData.revisionType,
                customer_contact_id: formData.contactName.allData.id,
                sales_id: this.state.stageData.sales.allData.id,
                operation_id: this.state.stageData.operation.allData.id,
                finance_id: this.state.stageData.finance.allData.id
            }
        }

        this.stageOnePartOneNextApi = this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `/bx_block_revision_request/revision_requests?part=1&stage=1`,
            token: token || '',
            body: bodyData
        })
    }

    editPolicy = (formData: any) => {
        const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
        const token = userData.token || '';
    
        const bodyData = {
            data: {
                customer_contact_id: formData.contactName.allData?.id,
                policy_id: this.props?.rrStageOneData?.data?.attributes?.policy_id,
                sales_id: this.state.stageData.sales.allData?.id,
                operation_id: this.state.stageData.operation.allData?.id,
                finance_id: this.state.stageData.finance.allData?.id
            }
        }

        this.stageOnePartOneEditApi = this.apiCall({
            contentType: 'application/json',
            method: 'PUT',
            endPoint: `bx_block_revision_request/revision_requests/${this.props.revisionRequestID}?stage=1&part=1`,
            token: token || '',
            body: bodyData
        })
    }

    stageOnePartTwoAPICall = async (formData: any, revisionRequestID: string, isEdit: boolean) => {
        let bodyData = new FormData();

        bodyData.append(`data[revision_input][client_comment]`, formData.comments)
        bodyData.append(`data[revision_input][save_as_draft]`, 'no')
  
        typeof(formData.attachment) === 'object'&&(Object.keys(formData.attachment).length!==0||formData?.attachment?.size) && bodyData.append(`data[upload_attachment][]`, formData.attachment)
        isEdit && bodyData.append(`data[customer_contact_id]`, this.state.stageData.contactID)
        isEdit && bodyData.append(`data[policy_id]`, this.props?.rrStageOneData?.data?.attributes?.policy_id)

        if (this.state.stageData.revisionType === "upgrade")  bodyData.append(`data[revision_input][upgrade_to]`, formData.paymentReciepent)

        if (this.state.stageData.revisionType === "remove") {
            this.state.deletedCsvData.forEach((data: any, index: number) => {
                if(this.state.stageData.insuranceType === "Medical" || this.state.stageData.insuranceType === "Life") bodyData.append(`data[employee_lists][${index}][id]`, data.id)
                else bodyData.append(`data[vehicle_lists][${index}][id]`, data.id)
            })
        }
        if (this.state.stageData.revisionType === "correct") {
            this.state.editedCsvData.forEach((data: any, index: number) => {
                this.getPartTwoFormData(data, index)
            })
        }
            this.stageOnePartTwoNextAPI = this.apiCall({
                contentType: 'FormData',
                method:  isEdit ? 'PUT' : 'POST',
                endPoint: isEdit ? `bx_block_revision_request/revision_requests/${this.state.revisionRequestId}?stage=1&part=2` : `bx_block_revision_request/revision_requests?stage=1&part=2&id=${this.state.revisionRequestId}`,
                body: bodyData
            })
    }

    getPartTwoFormData = (data: any, index: number) => {
        let bodyData = new FormData();
            if(this.state.stageData.insuranceType === "Medical" || this.state.stageData.insuranceType === "Life") {
                bodyData.append(`data[employee_lists][${index}][id]`, data.id)
                bodyData.append(`data[employee_lists][${index}][employee_id]`, data.attributes.employee_id)
                bodyData.append(`data[employee_lists][${index}][member_name]`, data.attributes.member_name)
                bodyData.append(`data[employee_lists][${index}][gender]`, data.attributes.gender)
                bodyData.append(`data[employee_lists][${index}][marital_status]`, data.attributes.marital_status)
                bodyData.append(`data[employee_lists][${index}][nationality]`, data.attributes.nationality)
                bodyData.append(`data[employee_lists][${index}][relation]`, data.attributes.relation)
                bodyData.append(`data[employee_lists][${index}][date_of_birth]`, data.attributes.date_of_birth)
                bodyData.append(`data[employee_lists][${index}][sponsor_id]`, data.attributes.sponsor_id)
                bodyData.append(`data[employee_lists][${index}][employee_class]`, data.attributes.class)
                bodyData.append(`data[employee_lists][${index}][mobile_number]`, data.attributes.mobile_number)
                bodyData.append(`data[employee_lists][${index}][employee_category]`,data.attributes.employee_category)
                bodyData.append(`data[employee_lists][${index}][occupation]`, data.attributes.occupation)
            }
            else {
                bodyData.append(`data[vehicle_lists][${index}][id]`, data.id)
                bodyData.append(`data[vehicle_lists][${index}][serial_number]]`, data.attributes.serial_number)
                bodyData.append(`data[vehicle_lists][${index}][make]`, data.attributes.make)
                bodyData.append(`data[vehicle_lists][${index}][model]`, data.attributes.model)
                bodyData.append(`data[vehicle_lists][${index}][type_of_body]`, data.attributes.type_of_body)
                bodyData.append(`data[vehicle_lists][${index}][plate_type]`, data.attributes.plate_type)
                bodyData.append(`data[vehicle_lists][${index}][number_of_seats]`, data.attributes.number_of_seats)
                bodyData.append(`data[vehicle_lists][${index}][year_make]`, data.attributes.year_make)
                bodyData.append(`data[vehicle_lists][${index}][chassis_number]`, data.attributes.chassis_number)
                bodyData.append(`data[vehicle_lists][${index}][registered_number]`, data.attributes.registered_number)
                bodyData.append(`data[vehicle_lists][${index}][vehicle_owner]`, data.attributes.vehicle_owner)
                bodyData.append(`data[vehicle_lists][${index}][sequence_number]`,data.attributes.sequence_number)
                bodyData.append(`data[vehicle_lists][${index}][mobile_number]`, data.attributes.mobile_number)
                bodyData.append(`data[vehicle_lists][${index}][owner_id]`, data.attributes.owner_id)
                bodyData.append(`data[vehicle_lists][${index}][hijri_date_of_istamarah]`, data.attributes.hijri_date_of_istamarah)
                bodyData.append(`data[vehicle_lists][${index}][color]`, data.attributes.color)
                bodyData.append(`data[vehicle_lists][${index}][cover]`, data.attributes.cover)
                bodyData.append(`data[vehicle_lists][${index}][value]`,data.attributes.value)
                bodyData.append(`data[vehicle_lists][${index}][plate_number]`, data.attributes.plate_number)
            }
        return bodyData
    }

    stageOnePartOneNextApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.stageOnePartOneNextApi != null &&
            this.stageOnePartOneNextApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const stageOneResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            /* istanbul ignore if */
            if (stageOneResponseJson.data) {
                this.getAllCsvData(stageOneResponseJson.data.attributes.id);
                this.setRevisionIdToUrl(stageOneResponseJson.data.attributes.id, window.location.href)
                this.setState({
                    revisionRequestId: stageOneResponseJson.data.attributes.id,
                    expandedAccordion: "p2",
                    showAlert:true,
                    alertMessage: "Your data successfully saved",
                    alertType:"success",
                    p1Submited: true,
                    showDialog: false,
                    loading: false,
                    revisionInput: true,
                    stageData: {
                        revisionType: stageOneResponseJson.data.attributes.revision_type.value,
                        insuranceType: this.state.stageData.insuranceType,
                        contactID: stageOneResponseJson.data.attributes.customer_contact_id,  
                        sales: { value: stageOneResponseJson.data.attributes?.sales_id?.name, label: stageOneResponseJson.data.attributes?.sales_id?.name, allData: { id: stageOneResponseJson.data.attributes?.sales_id?.id } },
                        operation: { value: stageOneResponseJson.data.attributes?.operation_id?.name, label: stageOneResponseJson.data.attributes?.operation_id?.name, allData: { id: stageOneResponseJson.data.attributes?.operation_id?.id } },
                        finance: { value: stageOneResponseJson.data.attributes?.finance_id?.name, label: stageOneResponseJson.data.attributes?.finance_id?.name, allData: { id: stageOneResponseJson.data.attributes?.finance_id?.id } }
                    }
                });
                this.props.setStageData('stageOne', stageOneResponseJson)
                this.props.setStageData('revisionRequestId', stageOneResponseJson.data.attributes.id)
            } else {
                this.showStageOneApiErrorMessage(stageOneResponseJson);
            }
        }
    }

    setRevisionIdToUrl = (revisionId: string, currentURL: string) => {
        let regex = /[?&]id=([^&]+)/;
        let newURL = '';
        if (regex.test(currentURL)) {
            newURL = currentURL.replace(regex, (match, capture) => match.replace(capture, revisionId));
        } else {
            if (currentURL.indexOf('?') !== -1) {
            newURL = currentURL + '&id=' + revisionId;
            } else {
            newURL = currentURL + '?id=' + revisionId;
            }
        }
        window.history.pushState({path: newURL}, '', newURL);
    }

    stageOnePartOneEditApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.stageOnePartOneEditApi !== null &&
            this.stageOnePartOneEditApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.props.setStageData('stageOne', responseJson);
                this.setState({
                    revisionRequestId: responseJson.data.attributes.id,
                    expandedAccordion: "p2",
                    p1Submited: true,
                    revisionInput: true,
                    showDialog: false,
                    saveDraftLoading: false,
                    loading: false,
                    showAlert: true,
                    alertMessage: "Your data successfully saved",
                    alertType: "success",
                    stageData: {
                        contactID: responseJson.data.attributes.customer_contact_id
                    }
                })
            } else {
                this.showStageOneApiErrorMessage(responseJson);
            }

        }
    }

    checkErrorForStageOneForm = (errors: any, stage: string) => {
        if (Object.keys(errors).length === 0) {
            this.setState({ showDialog: true, errorSection: "" })
        } else {
            const keys = Object.keys(errors);
            const firstKey = keys[0];
            const errorValue = errors[firstKey] as string;

            this.setState({
                showAlert: true,
                alertMessage: errorValue,
                alertType: "error",
                errorSection: stage
            })
        }
    }

    getPolicyDetailsAPIReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getPolicyDetailsAPI != null &&
            this.getPolicyDetailsAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            /* istanbul ignore if */
            if (responseJson?.data?.attributes) {
                const apiResponse = responseJson.data.attributes;
                this.setState({
                    stageData: {
                        ...this.state.stageData,
                        policyID: responseJson?.data?.id,
                        insuranceType: apiResponse.types_of_insurance,
                        insurer: apiResponse.insurance_company_id.short_name,
                        covered: apiResponse.covered,
                        policyActivationDate: apiResponse.policy_activation_date,
                        policyExpiryDate: apiResponse.policy_expiry_date,
                        policyValue: apiResponse.current_policy_value,
                        policyBalanceDue: apiResponse.policy_balance_value,
                        sales: { value: apiResponse?.sales_id?.name, label: apiResponse?.sales_id?.name, allData: { id: apiResponse?.sales_id?.id } },
                        operation: { value: apiResponse?.operation_id?.name, label: apiResponse?.operation_id?.name, allData: { id: apiResponse?.operation_id?.id } },
                        finance: { value: apiResponse?.finance_id?.name, label: apiResponse?.finance_id?.name, allData: { id: apiResponse?.finance_id?.id } }
                    }
                })
                this.updateCurrentPolicyValue();
                this.props.setStageData("policyValue", apiResponse.current_policy_value)
                this.getConectOptions(apiResponse.customer.id)
            } else {
                this.setState({ loading: false });
            }
        }
    };

    getSalesListAPIReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getSalesListAPI != null &&
            this.getSalesListAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const roleList = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (roleList.data) {
                let roleListDataArray = [] as any;
                roleList.data.map((item: any) => {
                    roleListDataArray.push({
                        value: parseInt(item.id),
                        label: `${item.attributes.first_name} ${item.attributes.last_name}`,
                        allData: item.attributes
                    })
                })
                this.setState({salesOption: roleListDataArray})
            } else {
                this.showStageOneApiErrorMessage(roleList);
            }
        }
    }

    getOperationsListAPIReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getOperationsListAPI != null &&
            this.getOperationsListAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const roleList = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (roleList.data) {
                let roleListDataArray = [] as any;
                roleList.data.map((item: any) => {
                    roleListDataArray.push({
                        value: parseInt(item.id),
                        label: `${item.attributes.first_name} ${item.attributes.last_name}`,
                        allData: item.attributes
                    })
                })
                this.setState({operationOptions: roleListDataArray})
            } else {
                this.showStageOneApiErrorMessage(roleList);
            }
        }
    }

    getFinanceListAPIReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getFinanceListAPI != null &&
            this.getFinanceListAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const roleList = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (roleList.data) {
                let roleListDataArray = [] as any;
                roleList.data.map((item: any) => {
                    roleListDataArray.push({
                        value: parseInt(item.id),
                        label: `${item.attributes.first_name} ${item.attributes.last_name}`,
                        allData: item.attributes
                    })
                })
                this.setState({financeOptions: roleListDataArray})
            } else {
                this.showStageOneApiErrorMessage(roleList);
            }
        }
    }

    getContactsApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getContactsApi != null &&
            this.getContactsApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const stageOneResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (stageOneResponseJson.data) {
                let stageOneDataArray = [] as any;
                stageOneResponseJson.data.map((item: any) => {
                    stageOneDataArray.push({
                        value: parseInt(item.id),
                        label: `${item.attributes.first_name} ${item.attributes.last_name}`,
                        allData: item.attributes
                    })
                })
                this.setState({ contectOption: stageOneDataArray })
            } else {
                this.showStageOneApiErrorMessage(stageOneResponseJson);
            }
        }
    }

    getAllCsvDataApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAllCsvDataAPI != null &&
            this.getAllCsvDataAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({ csvData: responseJson.data, totalPage: responseJson.meta.total_page })
            } else {
                this.showStageOneApiErrorMessage(responseJson);
            }
        }
    };

    stageOnePartTwoNextAPIReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.stageOnePartTwoNextAPI != null &&
            this.stageOnePartTwoNextAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    showDialog: false,
                    isPartTwoDataCreated: true
                })
                this.handleHandOverNoteDialog(true);
                this.props.setStageData('stageOne',responseJson)
                // this.props.setStageData('revisionRequestId', responseJson.data.attributes.id)
            } else {
                this.showStageOneApiErrorMessage(responseJson);
            }
        }
    }

    saveRvPolicyAsDraftApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.saveRvPolicyAsDraftApi != null &&
            this.saveRvPolicyAsDraftApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const stageOneResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            /* istanbul ignore if */
            if (stageOneResponseJson.data) {
                this.getAllCsvData(stageOneResponseJson.data.attributes.id)
                this.setState({
                    revisionRequestId: stageOneResponseJson.data.attributes.id,
                    showAlert: true,
                    alertMessage: "Your data is successfully saved",
                    alertType: "success",
                    saveDraftLoading: false,
                    isPartTwoDataCreated: true,
                    deletedCsvData: []
                });
                this.props.setStageData('stageOne', stageOneResponseJson)
            } else {
                this.showStageOneApiErrorMessage(stageOneResponseJson);
            }
        }
    }

    handoverNoteAPIReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.handoverNoteApi != null &&
            this.handoverNoteApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    showAlert: true,
                    alertMessage: "Your request has been moved to Processing!",
                    alertType: "success",
                    handOverNoteText: "",
                    markAsLostDialog: false,
                    showHandOverNote: false,
                    expandedAccordion: "",
                    p2Submitted: true
                });
                this.props.onNext(1);
            } else {
                this.showStageOneApiErrorMessage(responseJson);
            }
        }
    }

    markPolicyAsLostAPIReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.markPolicyAsLostApi != null &&
            this.markPolicyAsLostApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    showAlert: true,
                    alertMessage: "Your request has been marked as lost!",
                    alertType: "success",
                    markAsLostDialog: false,
                    showHandOverNote: false,
                    handOverNoteText: "",
                })
                window.location.href = "/Requests"
            } else {
                this.showStageOneApiErrorMessage(responseJson);
            }
        }
    }
    // Customizable Area End
}
