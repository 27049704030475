// Customizable Area Start
import React from "react";
import { Container } from "@material-ui/core";
import StageThreeForm from "./StageThreeForm";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import StageThreeController, { Props } from "./StageThreeController";
const themeStageThree = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StageThree extends StageThreeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
    // Customizable Area Start
      <ThemeProvider theme={themeStageThree}>
        <>
        <Container
          id="customerContainerStageThree"
          maxWidth="lg"
          dir={
            /* istanbul ignore next */ (window.localStorage.getItem("lang") ||
              "en") === "ar"
              ? "rtl"
              : "ltr"
          }
        > 
         {/* @ts-ignore */}
          <StageThreeForm {...this.props} />
        </Container>
        </>
      </ThemeProvider>
    // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
