// Customizable Area Start
import React from "react";
import {
    Button,
    Typography,
    InputAdornment,
    IconButton,
    OutlinedInput,
    Link,
    Grid
} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { Formik, useField } from 'formik';
import { Link as Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { Alert } from '@material-ui/lab';
import { CancelRounded } from '@material-ui/icons';

const EmailLoginForm = (props: any) => {
    return (
        <Formik
            initialValues={{ password: "", email: "" }}
            validationSchema={Yup.object().shape(props.loginSchema(props.isArabicLenguage))}
            validateOnMount={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
                props.userLogin(values);
                actions.setSubmitting(false);
                actions.resetForm();
            }}
        >
            {({
                handleSubmit,
                errors,
                touched,
                values,
                resetForm
            }) => (
                <React.Fragment>

                    <div style={{ textAlign: "center" }}>
                        <div style={{ paddingTop: '25px' }} >
                            <Button style={{
                                color: !props.isArabicLenguage ? 'rgba(45, 111, 143, 1)' : 'black' ,
                                textTransform:"none",
                                textDecoration: !props.isArabicLenguage ? "underline" : "",
                                fontWeight:!props.isArabicLenguage ? 'bolder' : 'normal' ,
                                fontSize:"15px"
                            }} data-test-id="switchToEngBtn" onClick={() => {
                                resetForm()
                                props.setArabicLanguage(false)
                            }}>English</Button> |
                            <Button style={{
                                color: props.isArabicLenguage ? 'rgba(45, 111, 143, 1)' : 'black',
                                fontWeight:props.isArabicLenguage ? 'bolder' : 'normal' ,
                                fontSize:"15px"
                            }} data-test-id="switchToArbBtn" onClick={() => {
                                resetForm()
                                props.setArabicLanguage(true)
                            }} >العربي</Button>
                        </div>
                        <div style={{ paddingTop: '15px', paddingBottom:"15px" }} >
                            <Typography style={{ justifyContent: "cetner", fontWeight:600 }} variant="h5" component="h5">
                                {props.isArabicLenguage ? props.ArabicLoginTitle : props.titleLogin}
                            </Typography>
                        </div>
                    </div>
                    <div>
                        <div style={{ padding: "15px 20px" }}>
                            {props.emailNotValid ? (
                                <Alert style={componentStyles.alertIconStyleForEmail} icon={false} severity="error">
                                    {props.isArabicLenguage ? (
                                        <div style={componentStyles.displayFlex} >
                                                <CancelRounded style={{marginTop:"-3px", verticalAlign:"bottom"}}/>
                                                <Typography variant="body1">{props.ArabicEmailErrorMsg}</Typography>
                                        </div>
                                    ) : (
                                        <div style={componentStyles.displayFlex} >
                                            <CancelRounded style={{marginTop:"-3px", verticalAlign:"bottom"}}/>&nbsp; {props.emailErrorMsg}&nbsp;
                                        </div>
                                    )}
                                </Alert>
                            ) : null}
                            {props.passwordNotValid ? (
                                <Alert style={componentStyles.alertIconStyle} icon={false} severity="error">
                                    {props.isArabicLenguage ? (
                                        <div>
                                            <CancelRounded style={{marginTop:"-3px", verticalAlign:"bottom"}} />&nbsp;{props.ArabicPasswordErrorMsg}&nbsp;
                                        </div>
                                    ) : (
                                        <div>
                                            <CancelRounded style={{marginTop:"-3px", verticalAlign:"bottom"}} />&nbsp;{props.passwordErrorMsg}&nbsp;
                                        </div>
                                    )}
                                </Alert>
                            ) : null}
                            <Grid style={componentStyles.labelStyle}>
                                <label aria-controls="email" >{props.isArabicLenguage ? props.ArabicEmailText : props.emailText}</label><br />
                                <TextFieldControl
                                    id={"txtInputEmail"}
                                    style={componentStyles.textInputStyle}
                                    isArabicLenguage={props.isArabicLenguage}
                                    name="email"
                                    type="email"
                                    data-test-id="txtInputEmail"
                                />
                                {touched.email && errors.email ? (
                                    <Typography color="error" >{errors.email}</Typography>
                                ) : null}
                            </Grid>
                            <Grid style={componentStyles.labelStyle}>
                                <label aria-label="password" >{props.isArabicLenguage ? props.ArabicPasswordText : props.passwordText}</label>
                                <TextFieldControl
                                    data-test-id="txtInputPassword"
                                    id={"txtInputPassword"}
                                    style={componentStyles.textInputStyle}
                                    isArabicLenguage={props.isArabicLenguage}
                                    name="password"
                                    type={props.enablePasswordField ? "password" : "text"}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                data-test-id="handleClickShowPassword"
                                                aria-label="toggle password visibility"
                                                onClick={props.handleClickShowPassword}
                                                edge="end"
                                            >
                                                {props.enablePasswordField ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {touched.password && errors.password ? (
                                    <Typography color="error" >{errors.password}</Typography>
                                ) : null}
                                <p style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    paddingTop: '10px',
                                    color: "black"
                                }}  >
                                    <Redirect to="/ForgotPassword">
                                        <Link href="#" style={{
                                             display: 'flex',
                                             justifyContent: 'end',
                                             paddingTop: '10px',
                                             color: "black"
                                        }} underline="always" data-test-id='btnForgotPassword'>{props.isArabicLenguage ? props.ArabicTextForgotPassword : props.forgotPasswordText}</Link>
                                    </Redirect></p>
                            </Grid>
                        </div>

                        <div style={componentStyles.loginButton}>
                            <Button
                                style={componentStyles.submitButton}
                                data-test-id="btnEmailLogIn"
                                id="btnEmailLogIn"
                                title={props.titleLogin}
                                onClick={() => handleSubmit()}
                            >
                                {props.isArabicLenguage ? props.ArabicLoginTitle : props.titleLogin}
                            </Button>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </Formik>
    )
}
const componentStyles = {
    cardStyle: {
        minWidth: 275,
        marginTop: 100
    },
    centerContent: {
        display: "flex",
        justifyContent: "center"
    },
    logoImage: {
        height: '200px',
        width: '200px'
    },
    textInputStyle: {
        marginTop: "10px"
    },
    arabicTextStyle: {
        "text-align": "end"
    },
    labelStyle: { marginTop: "15px" },
    submitButton: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform":"none",
        "color": "white",
        "width": "200px",
        "height": "50px",
        borderRadius: "10px"
    },
    alertIconStyleForEmail:{
        display: "flex",
        justifyContent: "start",
        "width": "70%" 
    },
    alertIconStyle: {
        marginTop:"10px",
        display: "flex",
        justifyContent: "start",
        "width": "40%"
    },
    displayFlex: {
        display: "flex"
    },
    loginButton: {
        display: "flex",
        justifyContent: "center",
        borderRadius: "10px",
        paddingBottom: "25px",
        paddingTop:"20px",
    }
}

const TextFieldControl = (props: any) => {
    const [field, meta] = useField(props);

    return (
        <OutlinedInput
            style={{
                direction: props.isArabicLenguage ? "rtl" : "ltr",
                marginTop: "10px",
                marginBottom: "10px"
            }}
            fullWidth
            type={props.type}
            {...field}
            {...props}
            error={meta.error && meta.touched}
        />
    );
};
export default EmailLoginForm
// Customizable Area End