// Customizable Area Start
import React from "react";
import { Container } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import StageNineController from "./InvoiceStageController";
import { withTranslation } from "react-i18next";
import StageNineForm from './InvoiceStageForm';
const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff"
        }
    }
});

// Customizable Area End
export class StageNine extends StageNineController {

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container
                    id="customerContainer"
                    maxWidth="lg"
                    dir={ (window.localStorage.getItem("lang") || "en") === "ar" ? "rtl" : "ltr" }
                >
                    <StageNineForm {...this.props} />
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const useStyles = ({});
// Customizable Area End

export default withStyles(useStyles)(withTranslation()(StageNine));
