// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import getStageNameByNumber, { getStageNumberByName } from "../../../../components/src/Common/PolicyRequest/StagesName";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    t?: any;
    classes?:any;
    data?: any;
    handleCsvImport:any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    rrStageOneData:any;
    customerName: string;
    policyId: string;
    revisionRequestId: string;
    isArabicLenguage: boolean;
    isEdit: boolean;
    currentSteps: number;
    stageOne: any;
    showAlert: boolean;
    alertMessage: string;
    alertType: string;
    isLoading: boolean;
    stageFour:any;
    stageTwo: any;
    stageThree: any;
    stageFive: any;
    stageSix: any;
    stageSeven: any;
    stageNine: any;
    handoverNote: string;
    handOverNoteForAwaiting: string;
    handOverNoteForOperationsReview: string;
    handoverNoteForCustomerReview: string;
    handOverNoteForPaymentReview: string;
    handOverNoteForInvoicing: string;
    invoiceAmount: number;
    policyValue: number;
    isTwoCreated: boolean;
    isNextStageTwo: boolean;
    isPayInstallment: boolean;
    isReject: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class RevisionRequestController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getStageOneApi: any = "";
    getStageFourApi: any = "";
    getStageSevenApi:string = "";

    getStageTwoApi: any = "";
    getStageInvoiceApi: any = "";
    getAwaitingDataApi: any = "";
    getStageSevenDataApi: any = "";
    // Customizable Area End

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        const queryParameter: any = new URLSearchParams(window.location.search);
        const revisionId: string = queryParameter.get('id');

        this.state = {
            rrStageOneData:{},
            customerName: "",
            policyId: "",
            revisionRequestId: revisionId,
            isArabicLenguage: (window.localStorage.getItem("lang") || "en") === "ar",
            isEdit: false,
            handoverNote:"",
            handOverNoteForAwaiting: "",
            handOverNoteForOperationsReview: "",
            handoverNoteForCustomerReview: "",
            handOverNoteForPaymentReview: "",
            handOverNoteForInvoicing: "",
            invoiceAmount: 0,
            policyValue: 0,
            currentSteps: 1,
            stageOne: {},
            stageTwo: {},
            stageThree:{},
            showAlert: false,
            alertMessage: "",
            alertType: "",
            isLoading: false,
            stageFour:{},
            isNextStageTwo: false,
            isTwoCreated: false,
            stageFive:{},
            stageSix:{},
            stageSeven: {},
            stageNine: {},
            isPayInstallment: false,
            isReject: false
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.getStageOneApiReceive(message);
        this.getStageFourApiReceive(message);
        this.getStageTwoApiReceive(message);
        this.getNavigationParams(message);
        this.getStageThreeApiReceive(message);
        this.getStageSevenApiReceive(message);
        this.getStageInvoiceApiReceive (message);
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        const queryParameter: any = new URLSearchParams(window.location.search);
        const revisionId: string = await queryParameter.get('id');
        if (revisionId) {
          this.setState({revisionRequestId: revisionId})
          this.getStageOneData();
        }
        // Customizable Area End
    }

    // Customizable Area Start

    revisionRequestApiCall(data: any) {
        this.setState({isLoading: true })
        const { rvcontentType, apiMethod, apiEndPoint } = data;
        const userLoginData = JSON.parse(localStorage.getItem("loginData") || "{}");
        const userLoginToken = userLoginData.token || "";

        const apiHeader = {
            "Content-Type": rvcontentType,
            token: userLoginToken,
        };

        const requestMessageForRvRequest = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageForRvRequest.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(apiHeader)
        );
        requestMessageForRvRequest.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            apiEndPoint
        );
        requestMessageForRvRequest.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            apiMethod
        );

        runEngine.sendMessage(requestMessageForRvRequest.id, requestMessageForRvRequest);
        return requestMessageForRvRequest.messageId;
    }

    getApiErrorMessage = (response:any) => {
        if (response.errors) {
            if(response.errors[0]?.token){
                this.setState({
                    alertMessage:response.errors[0].token,
                    alertType:"error",
                    showAlert:true
                })
                window.localStorage.removeItem('loginData');
                window.location.href = "/EmailAccountLoginBlock"
            } else
            if (typeof(response.errors[0]) === "string") {
               this.setState({
                    alertMessage:response.errors[0],
                    alertType:"error",
                    showAlert:true
               });
            }
        }
    }

    showRejectMessage = () => {
        if (this.state.isReject)  this.setState({
            showAlert: true,
            alertMessage: this.state.isArabicLenguage ? "لقد تم رفض طلبك للمرحلة المحددة!" : "Your request has been rejected to selected stage!",
            alertType: "success",
        })
        if (this.state.isPayInstallment) this.setState({
        showAlert: true,
        alertMessage: this.state.isArabicLenguage ? "لقد تم نقل طلبك لتقسيط الدفع" : "Your request has been moved for pay installment!",
        alertType: "success",
        })
    }

    getStageOneData = () => {
        this.getStageOneApi = this.revisionRequestApiCall({
            rvcontentType: 'application/json',
            apiMethod: "GET",
            apiEndPoint: `/bx_block_revision_request/revision_requests/${this.state.revisionRequestId}?stage=1`,
        })
    }
    getStageFourData = () => {
        this.getStageFourApi = this.revisionRequestApiCall({
            rvcontentType: 'application/json',
            apiMethod: "GET",
            apiEndPoint: `/bx_block_revision_request/revision_requests/${this.state.revisionRequestId}?stage=4`,
        })
    }
    getStageTwoData = (policyId: any) => {
        this.getStageTwoApi = this.revisionRequestApiCall({
            rvcontentType: 'application/json',
            apiMethod: "GET",
            apiEndPoint: `/bx_block_revision_request/revision_requests/${policyId}?stage=2`,
        })
    }

    getStageInvoice = (policyId: any) => {
                this.getStageInvoiceApi = this.revisionRequestApiCall({
            rvcontentType: 'application/json',
            apiMethod: "GET",
            apiEndPoint: `/bx_block_revision_request/revision_requests/${policyId}?stage=9`,
        })
    }

    onNext = (currentStage:number, isPayInstallment: boolean = false) => {
        const nextStep = currentStage + 1;
        this.setState({currentSteps:nextStep})
        if(currentStage === 1) this.getStageOneData();
        if(currentStage === 2) this.getStageTwoData(this.state.revisionRequestId);
        if(currentStage === 3) this.getStageThreeData();
        if(currentStage === 4) this.getStageFourData();
        if(currentStage === 7) this.getStagesSevenData();
        if(currentStage === 8 && isPayInstallment) this.getStageInvoice(this.state.revisionRequestId);
    }
    setStageData = (keyName:any,apiResponse:any) => {
        this.setState((prevState) => ({
            ...prevState,
            [keyName]: apiResponse,
        }));
    }
    onReject = (goBackTo:number, isPayInstallment: boolean= false) => {
        this.setState({currentSteps:goBackTo})
        if(goBackTo === 1) {
            this.getStageOneData();
            this.setState({stageTwo: {},stageThree: {},stageFour: {}, stageSeven: {}, stageNine: {}})
        }
        if(goBackTo === 2) {
            this.getStageTwoData(this.state.revisionRequestId);
            this.setState({stageThree: {},stageFour: {}, stageSeven: {}, stageNine: {}})
        }
        if(goBackTo === 3) {
            this.getStageThreeData();
            this.setState({stageFour: {}, stageSeven: {}, stageNine: {}})
        }
        if(goBackTo === 4) {
            this.getStageFourData();
            this.setState({stageSeven: {}, stageNine: {}})
        }
        if(goBackTo === 7) {
            this.getStageFourData();
            this.getStagesSevenData();
        }
        
        if (isPayInstallment) {
            this.setState({
                isPayInstallment: true,
                isReject: false
            })
        } else {
            this.setState({isReject: true,isPayInstallment: false})
            if (goBackTo === 7) this.setState({stageNine: {}})
        }
    }
    getStepName = getStageNameByNumber

    getStepNumber = getStageNumberByName

    getStageOneApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageOneApi != null &&
            this.getStageOneApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    stageOne: responseJson,
                    handoverNote: responseJson.data.attributes.handover_note,
                    isLoading: false,
                })
                this.showRejectMessage()
                if(responseJson.data.attributes.is_next_stage_submitted) {
                    this.setState({isNextStageTwo: true, isLoading:false, isPayInstallment: false, isReject: false})
                    this.getStageTwoData(responseJson.data.attributes.id);
                }
                if(!responseJson.data.attributes.is_next_stage_submitted && responseJson.data.attributes.is_current_stage_submitted) {
                    this.setState({currentSteps:2})
                }
            } else {
                this.getApiErrorMessage(responseJson);
                this.setState({isLoading: false, isPayInstallment: false, isReject: false})
            }
        }
    }
    getStageTwoApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageTwoApi != null &&
            this.getStageTwoApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    stageTwo: responseJson,
                    currentSteps: 2,
                    isTwoCreated: true,
                    handoverNote: responseJson.data.attributes.handover_note,
                    handOverNoteForAwaiting: responseJson.data.attributes.handover_note_awaiting,
                    isLoading: false
                })
                this.showRejectMessage();
                if(responseJson.data.attributes.is_next_stage_submitted) {
                    this.setState({isPayInstallment: false, isReject: false})
                    this.getStageThreeData();
                }
                if(!responseJson.data.attributes.is_next_stage_submitted && responseJson.data.attributes.is_current_stage_submitted) {
                    this.setState({currentSteps:3})
                }
            } else {
                this.getApiErrorMessage(responseJson);
                this.setState({isLoading: false, isPayInstallment: false, isReject: false})
            }
        }
    }

    getStageThreeApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAwaitingDataApi != null &&
            this.getAwaitingDataApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    stageThree: responseJson,
                    currentSteps:3,
                    handOverNoteForAwaiting:responseJson.data.attributes.handover_note,
                    handOverNoteForOperationsReview: responseJson.data.attributes.handover_note_operation_review,
                    isLoading: false
                })
                this.showRejectMessage()
                if(responseJson.data.attributes.is_next_stage_submitted) {
                    this.setState({isPayInstallment: false, isReject: false})
                    this.getStageFourData();
                }
                if(!responseJson.data.attributes.is_next_stage_submitted && responseJson.data.attributes.is_current_stage_submitted) {
                    this.setState({currentSteps:4})
                }

            } else {
                this.getApiErrorMessage(responseJson);
                this.setState({isLoading: false, isPayInstallment: false, isReject: false})
            }
        }
    }



    getStageSevenApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageSevenDataApi != null &&
            this.getStageSevenDataApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                                this.setState({
                    stageSeven: responseJson,
                    currentSteps:7,
                    isLoading: false,
                    handOverNoteForPaymentReview: responseJson.data.attributes.handover_note,
                    handOverNoteForInvoicing:responseJson.data.attributes.handover_note_nine,
                })
                
                if(responseJson.data.attributes.is_stage_9_submitted && !responseJson.data.attributes.add_installment) {
                    this.getStageInvoice(this.state.revisionRequestId);
                }
                if(!responseJson.data.attributes.is_stage_9_submitted && responseJson.data.attributes.is_current_stage_submitted && responseJson.data.attributes.save_as_draft) {
                    this.setState({currentSteps: 7})
                }
                if(!responseJson.data.attributes.is_stage_9_submitted && responseJson.data.attributes.is_current_stage_submitted && !responseJson.data.attributes.save_as_draft && !responseJson.data.attributes.add_installment) {
                    this.setState({currentSteps: 9})
                    this.getStageInvoice(this.state.revisionRequestId);
                }
                if(!responseJson.data.attributes.is_stage_9_submitted && responseJson.data.attributes.is_current_stage_submitted && responseJson.data.attributes.add_installment && responseJson.data.attributes.is_reject) {
                    this.setState({currentSteps: 7})
                } else if(!responseJson.data.attributes.is_stage_9_submitted && responseJson.data.attributes.is_current_stage_submitted && responseJson.data.attributes.add_installment) {
                    this.setState({currentSteps: 9})
                }
            } else {
                this.getApiErrorMessage(responseJson);
                this.setState({isLoading: false})
            }
        }
    }

    getStageFourApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageFourApi != null &&
            this.getStageFourApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    stageFour: responseJson,
                    currentSteps:4,
                    handOverNoteForOperationsReview:responseJson.data.attributes.handover_note,
                    handoverNoteForCustomerReview:responseJson.data.attributes.handover_note_customer_review,
                    handOverNoteForPaymentReview: responseJson.data.attributes.handover_note_payment_review,
                    isLoading: false
                })
                this.showRejectMessage()
                if(responseJson.data.attributes.save_as_draft || responseJson.data.attributes.is_reject){
                    this.setState({currentSteps: 4})
                }
                else if(responseJson.data.attributes.policy_impact !== "unchanged" && !responseJson.data.attributes.is_reject) {
                    this.setState({currentSteps:6});
                }
                if (responseJson.data.attributes.is_stage_6_submitted) {
                    this.setState({
                        stageSix:responseJson,
                        isPayInstallment: false, 
                        isReject: false
                        // currentSteps:7
                    })
                    this.getStagesSevenData()
                }

            } else {
                this.getApiErrorMessage(responseJson);
                this.setState({isLoading: false, isPayInstallment: false, isReject: false})
            }
        }
    }
    getStageInvoiceApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getStageInvoiceApi != null &&
            this.getStageInvoiceApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    stageNine: responseJson,
                    currentSteps: 9,
                    handOverNoteForInvoicing: responseJson.data.attributes.handover_note, isLoading: false
                })
            } else {
                this.getApiErrorMessage(responseJson);
                this.setState({isLoading: false})
            }
        }
    }
    getNavigationParams =(message:any)=>{
        if(getName(MessageEnum.NavigationPayLoadMessage)===message.id){
          const naviParams = message.getData(getName(MessageEnum.RevisionRequestIdPayload))
          const isTable = JSON.parse(naviParams).isTable
          if (isTable) {
            this.setState({revisionRequestId:JSON.parse(naviParams).id})
        }
          else this.setState({policyId:JSON.parse(naviParams).id})
        }
    }

    getStageThreeData = () => {
        this.getAwaitingDataApi = this.revisionRequestApiCall({
            rvcontentType: 'application/json',
            apiMethod: "GET",
            apiEndPoint: `/bx_block_revision_request/revision_requests/${this.state.revisionRequestId}?stage=3`,
        })
    }


    getStagesSevenData = () => {
        this.getStageSevenDataApi = this.revisionRequestApiCall({
            rvcontentType: 'application/json',
            apiMethod: "GET",
            apiEndPoint: `/bx_block_revision_request/revision_requests/${this.state.revisionRequestId}?stage=7`,
        })
    }

    handleNavigationToCSVImport =(id:number,type:string)=>{
        const msg: Message = new Message(
            getName(MessageEnum.NavigationRevisionRequestCSVImportMessage)
          );
          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
          msg.addData(getName(MessageEnum.RevisionRequestPayload), JSON.stringify({id:id,type:type}));
          this.send(msg);
    }

    handleToster = () => {
     this.setState({ showAlert: false, alertMessage:"", alertType:"" })
    }
    // Customizable Area End
}
