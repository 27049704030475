import React, { useState } from 'react'
import { makeStyles, Grid, FormLabel, TextField, InputAdornment, IconButton } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LabelWithIcon from '../Common/LabelWithIcon'
import ConfirmationDialog from '../Common/ConfirmationDialog.web';
import { useTranslation } from 'react-i18next';
import { GetApp } from '@material-ui/icons';



const useStyles = makeStyles({
    cmpWrapper: {
        backgroundColor: '#fff',
        '& legend': {
            textAlign: 'left',
            marginBottom: '10px',
            display: 'flex',
            fontSize: '14px',
            color: '#3d3d3d',
            '& svg': {
                fontSize: '15px',
                color: '#222',
                marginLeft: '6px',
            }
        }
    },
    uploadedDoc: {
        '& input': {
            color: '#7f7f7f'
        },
        marginTop: "10px",
        marginBottom: "10px",
        "& .MuiInputBase-root.Mui-disabled": {
            background: "hsl(0, 0%, 95%)"
        }
    },
    classBenefits: {
        width: '60%',
        display: 'block'
    },
    inputFile: {
        color: '#7f7f7f',
        '& fieldset': {
            border: '1px dashed rgba(0, 0, 0, 0.23) !important'
        },
        '& > div': {
            height: '40px',
            overflow: 'hidden',
        },
        '& input': {
            opacity: '0',
            position: 'absolute',
            left: '0px',
            right: '0px',
            maxWidth: '75%',
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 'auto',
            position: 'absolute',
            right: '0px',
            '& > *': {
                background: '#fff',
            }
        }
    },
    inputStyle: {
        marginTop: "10px",
        marginBottom: "10px",
        "& .MuiInputBase-root.Mui-disabled": {
            background: "hsl(0, 0%, 95%)"
        }
    },
    iconBtn: { cursor: 'pointer', color: '#1c1c1c' }
})

const enumUploadAttachedment = {
    name: 'name',
    comment: 'comment',
}

const enumFormAction = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
}

type documentUploadType = {
    name: string,
    comment: string,
    insurance_document: any,
}


type UploadAttachedmentProps = {
    data: any,
    eventChange: any,
    formType: string,
    removeDoc: () => void,
}

const UploadAttachedment: React.FC<UploadAttachedmentProps> = (props: UploadAttachedmentProps) => {
    const classes = useStyles();
    const [selectedimageName, setSelectedimageName] = useState<string>()
    const [imgUrl, setImgUrl] = useState<string>()
    const [dialogState, setDialogState] = useState({
        openConfirmationDialog: false,
        dialogItemIndex: 0,
        headingText: '',
        paragraphText: '',
    })

    const { t } = useTranslation();


    const handleCancelImage = () => {
        setSelectedimageName('')
        setImgUrl('')
    }
    const isFileUrl = async (data: any) => {
        if (typeof data === 'string' && (data.startsWith('file://') || data.startsWith('http://') || data.startsWith('https://'))) {
            return true;
        }
        return false;
    }
    const downloadFile = async (isDisabled: boolean, filePath: string) => {
        if (isDisabled) return
        const isURl = await isFileUrl(filePath)
        if (!isURl) {
            // @ts-ignore
            const blob = new Blob([filePath], { type: filePath.type });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = await url;
            // @ts-ignore
            link.download = await filePath.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } else {
            const response = await fetch(filePath, { method: 'GET' });
            const contentDisposition = response.headers.get('content-disposition');
            // @ts-ignore
            const match = contentDisposition.match(/filename="(.+)"/);
            const filename = match ? match[1] : 'file';
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${filename}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }
    }

    return (
        <div className={classes.cmpWrapper}>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <FormLabel component="legend">{t('upload_attachment')}</FormLabel>
                    <TextField
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        fullWidth
                        name="insuranceClass"
                        inputProps={{ readOnly: true }}
                        className={classes.uploadedDoc}
                        value={props?.data?.attributes?.name}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <div>
                                        {
                                            props.formType === enumFormAction.VIEW ? <IconButton>
                                                <GetApp className={classes.iconBtn} onClick={() => {
                                                    downloadFile(false, (props?.data?.attributes?.insurance_document || ""))
                                                }} />
                                            </IconButton> :
                                                <IconButton>
                                                    <GetApp className={classes.iconBtn} onClick={() => {
                                                        downloadFile(false, (props?.data?.attributes?.insurance_document || ""))
                                                    }} />
                                                    <CloseIcon className={classes.iconBtn} onClick={() => {
                                                        props.removeDoc()
                                                    }} />
                                                </IconButton>
                                        }

                                    </div>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <InsertDriveFileIcon />
                                </InputAdornment>
                            ),
                            readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <LabelWithIcon label={t('document_name')} />
                    <TextField
                        className={classes.inputStyle}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        fullWidth
                        name="docName"
                        value={props?.data?.attributes?.name}
                        onChange={(e: any) => props?.eventChange(e.target.value, enumUploadAttachedment.name)}
                        InputProps={{
                            readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                            disabled: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <LabelWithIcon label={`${t("comment")} ${t("if_any")}`} />
                    <TextField
                        className={classes.inputStyle}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        fullWidth
                        name="comment"
                        value={props?.data?.attributes?.comment}
                        onChange={(e: any) => props?.eventChange(e.target.value, enumUploadAttachedment.comment)}
                        InputProps={{
                            readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                            disabled: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                        }}
                    />
                </Grid>
            </Grid>


            <ConfirmationDialog
                btnCancelText={t('cancel')}
                showRedButton={false}
                btnOkText='Delete'
                openDialog={dialogState?.openConfirmationDialog}
                headingText={dialogState?.headingText}
                paragraphText={dialogState?.paragraphText}
                handleOk={() => {
                    props.removeDoc();
                    setDialogState((prevState) => ({
                        ...prevState,
                        openConfirmationDialog: false
                    }))
                }}
                handleCancel={() => {
                    setDialogState((prevState) => ({
                        ...prevState,
                        openConfirmationDialog: false
                    }))
                }}
            />
        </div>
    )
}

export default UploadAttachedment