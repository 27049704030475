// Customizable Area Start
import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PolicyListingController, { Props } from "./PolicyListingController";
import PaginatedTable from "../../../../components/src/Common/Table/PaginatedTable";
import SearchBar from "../../../../components/src/Common/SearchBar/SearchBar";
import { POLICY_LIST_TABLE_DATA } from "../../../../components/src/Common/Table/TableColumns";
import { withTranslation } from "react-i18next";
import FilterAltSharpIcon from "@material-ui/icons/FilterList";
import RequestFilterDialog from "../../../../components/src/Common/RequestFilterDialog.web";
import Toggle from "../../../../components/src/Common/Toggle/Toggle";
import { Link as Redirect } from 'react-router-dom'

const policyTheme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
});

const policyListingStyle = {
  mainContainer: {
    width: "97%",
    marginLeft: "0",
    position: "relative",
  },
  insurerTableContainer: {
    padding: "24px",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  alertDivWithSearch: {
    display: "flex",
    marginBottom: '30px',
    gap: '20px',
  },
  alertDivWithSearchTop: {
    display: "flex",
    marginBottom: '10px',
    marginTop: '15px',
    gap: '20px',
    "justify-content": "space-between",
  },
  alertDiv: {
    "display": "flex",
    "justify-content": "end",
    "padding-bottom": "15px",
  },
  btnText: {
    background: "#fff",
    color: "rgb(64, 109, 127)",
    minWidth: "155px",
    height: "53px",
    border: 'solid 1px rgb(64, 109, 127)',
    boxShadow: 'none',
    padding: '8px 15px',
    fontWeight: 600,
    'text-transform': 'none'
  },
  filterTab: {
    background: "#fff",
    minWidth: "155px",
    height: "51px",
    borderStyle: "none",
    borderRadius: "25px",
    border: 'solid 1px rgba(0, 0, 0, 0.3)',
    boxShadow: 'none',
    marginRight: '20px',
    color: "rgba(0, 0, 0, 0.3)",
    fontWeight: 400,
    padding: "8px 15px",
    "text-transform": "none",
  },
  activeFilterTab: {
    background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    minWidth: "155px",
    height: "51px",
    borderStyle: "none",
    borderRadius: "25px",
    borderColor: '#2d6f8f',
    boxShadow: 'none',
    marginRight: '20px',
    color: "#FFFF",
    fontWeight: 400,
    padding: "8px 15px",
    "text-transform": "none",
  },
  toggle: {
    paddingTop: "10px",
    display: "flex"
  },
  toggleText: {
    paddingTop: "6px"
  },
  rightPanel: {
    display: "flex"
  },
  primaryBtnStyle: {
    background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    "text-transform": "none",
    "color": "white",
    "height": "50px",
    "width": "150px",
     borderRadius: "10px"
  },
  importBtn:{
    textDecoration: "none",
    color:"white",
    display:"flex",
    "justify-content":"end",
    "padding-top":"15px",
    "padding-bottom":"15px"
  }
};

export class PolicyListing extends PolicyListingController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    const { page, totalPage, policyList } = this.state;
    //@ts-ignore
    const { t } = this.props;

    return (
      <>
        <ThemeProvider theme={policyTheme}>
          <div
            style={{
              width: "97%",
              marginLeft: "0",
              position: "relative"
            }}
          >
            <div style={policyListingStyle.alertDivWithSearchTop}>
              <SearchBar
                data-test-id="customer-filter-searchbar"
                searchQuery={this.handleSearchQuery}
              />
              <div style={policyListingStyle.rightPanel}>
                <div style={policyListingStyle.toggle}> <Typography style={policyListingStyle.toggleText} >
                  {t('expired_policies')}
                </Typography> <Toggle isChecked={this.state.isExpire} handleToggle={this.handleToggle} /></div>
                <Button
                  data-test-id="filterBtn"
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  style={policyListingStyle.btnText}
                  onClick={this.openFilterDialog}
                >{t("filter")} <FilterAltSharpIcon /></Button>
              </div>
              <RequestFilterDialog
                data-test-id="policyFilterDialog"
                btnCancelText="Cancel"
                btnfilterText="Filter"
                handleFilter={this.handleFilterDialog}
                handleCancel={this.closefilterDialog}
                showfilterDialog={this.state.showfilterDialog}
                headingText={"Filter"}
                openDialog={this.state.showfilterDialog}
                showDialogBtns={true}
                showCrossBtn={true}
                customerList={this.state.filterCustomerList}
                insurer={true}
                insurerList={this.state.filterInsurerList}
                operationList={this.state.operationsList}
              />
            </div>
            <Box sx={policyListingStyle.insurerTableContainer}>
              <Typography variant="h6" style={{ fontWeight: 800, paddingBottom: 20 }}>
                {t('policies')}
              </Typography>
              <PaginatedTable
                key={''}
                curretPage={page}
                totalPage={totalPage}
                pageChange={this.handlePage}
                handleRowPerPage={this.handleRow}
                tableFor={'PolicyListing'}
                tableData={policyList}
                tableColumn={POLICY_LIST_TABLE_DATA}
                path={'/PolicyOverview'}
              />
            </Box>
            <div style={policyListingStyle.importBtn}>
              <Redirect to="/ImportPolicyCsv">
                <Button style={policyListingStyle.primaryBtnStyle}>
                  {t('import_csv')}
              </Button>
              </Redirect>
            </div>
          </div>
        </ThemeProvider >
      </>
    );
  }
}

export default withTranslation()(PolicyListing);
// Customizable Area End
