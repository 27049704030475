Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
// exports.placeHolderEmail = "Email";

// exports.labelHeader = "Value proposition: why users should sign up.";
// exports.labelFirstName = "First name";
// exports.lastName = "Last name";
// exports.labelEmail = "Email";
// exports.labelPassword = "Password";
// exports.labelRePassword = "Re-Type Password";
// exports.errorCountryCodeNotSelected = "Please select country code";
// exports.errorBlankField = "can't be blank";

// exports.labelLegalText =
//   "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

// exports.labelLegalTermCondition = "Terms and conditions";
// exports.labelLegalPrivacyPolicy = "Privacy policy";
// exports.btnTextSignUp = "Sign up";
// exports.errorPasswordNotValid = "Password not valid.";

// exports.errorTitle = "Error";

// exports.errorBothPasswordsNotSame = "Passwords should be same.";
// exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

// exports.contentTypeApiAddDetail = "application/json";

// exports.accountsAPiEndPoint =
//   "account/accounts";

// exports.apiMethodTypeAddDetail = "POST";

// exports.errorEmailNotValid = "Email not valid.";
// exports.urlGetValidations =
//   "profile/validations";
// exports.validationApiContentType = "application/json";

// exports.validationApiMethodType = "GET";

exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First Name";
exports.labelLastName = "Last Name"
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.singnUpTitle = "Complete Sign Up";
exports.ArabicSignUpTitle = "أكمل التسجيل"
exports.pleaseEnterAPassword = "Please enter a password";
exports.ArabicPleaseEnterPasswword = 'الاسم الأول مطلوب';
exports.passwordMustBeAtLeast8Characters = "Password must be at least 8 characters";
exports.passwordMustContainLowercaseCharacter = "Password must contain lowercase character";
exports.passwordMustContainUppercaseCharacter = "Password must contain uppercase charcater";
exports.passwordMustContainAtLeastOneNumber = "Password must contain at least one number";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";
exports.alreadyHaveAccountTitle = "Already have an account?";
exports.ArabicAlreadyHaveAcconutTitle = "لدي حساب";
exports.signUpText = "Sign Up";
exports.loginText = "Login";
exports.ArabicLoginText = "تسجيل الدخول";
exports.firstNameText = "First Name";
exports.ArabicFirstNameText="الاسم الأول";
exports.ArabicLastNameText="اسم العائلة";
exports.lastNameText="Last Name";
exports.ArabicPhoneNumberText = "رقم التليفون";
exports.phoneNumberText ="Phone Number";
exports.ArabicPasswordText = "كلمة السر";
exports.passwordText = "Password";
exports.emailText = "Email";
exports.ArabicEmailText = "كلمة السر"
exports.TermAndConditionFirstText = "I agree with";
exports.ArabicTermAndConditionFirstText = "أنا أتفق مع";
exports.ArabicTermAndConditionSecondText = "البنود و الظروف";
exports.TermAndConditionSecondText = "Terms & Conditions";
exports.termAndConditionErrorMessage = "You have to agree to Terms & Conditions to Sign Up";
exports.ArabicTermAndConditionErrorMessage = "عليك الموافقة على الشروط و الأحكام لتتمكن من التسجيل";
exports.pleaseTryAgainText="Please try again";
exports.ArabicPleaseTryAgainText = "حاول مرة اخرى";

exports.atLeastOnceUpperCaseLetterText = "At least 1 uppercase letter"
exports.ArabicAtLeastOnceUpperCaseLetterText = "أن تحتوي كلمة السر على حرف كبيرعلى الأقل "

exports.atLeasetOnceLowerCaseLetterText = "At least 1 lowercase letter"
exports.ArabicAtLeasetOnceLowerCaseLetterText = "أن تحتوي كلمة السر على حرف صغير على الأقل"

exports.atLeaseOneNumaricCharacterText ="At least 1 numeric character"
exports.ArabicAtLeaseOneNumaricCharacterText = "أن تحتوي كلمة السر على رقم على الأقل"

exports.minimum8CharctorText="Minimum 8 character"
exports.ArabicMinimum8CharctorText="أن تحتوي كلمة السر على ثمانية رموز على الأقل"

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
// Customizable Area End
