// Customizable Area Start
import React from "react";
import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CustomerReviewController, { Props } from "./CustomerReviewController";
import CustomButton from "../../../../../../components/src/Common/CustomButton";
import { Accordion, AccordionDetails, AccordionSummary, Container, FormControl, FormControlLabel, Grid, InputAdornment, Radio, RadioGroup, Typography } from "@material-ui/core";
import HandOverDialog from "../../../../../../components/src/Common/HandOverDialog.web";
import RejectRequestDialog from "../../../../../../components/src/Common/RejectRequestDialog.web";
import Toster from "../../../../../../components/src/Common/Toster";
import TextInput from "../../../../../../components/src/Common/TextInput";
import LabelWithIcon from "../../../../../../components/src/Common/LabelWithIcon";
import FileUpload from "../../../../../../components/src/Common/FileUpload";
import { Formik } from "formik";
import { ErrorOutlineRounded, ExpandMore } from "@material-ui/icons";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';


const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }

});
const enum_impact = {
    up: "up",
    down: "down",
    unchanged: "unchanged",

}
// Customizable Area End

export class CustomerReviewForm extends CustomerReviewController {
    // Customizable Area Start
    formikRef: any;
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.formikRef = React.createRef();
        // Customizable Area End

    }

    // Customizable Area End

    render() {

        // Customizable Area Start
        const { t, classes, isExpanded, rrStageSixData } = this.props;
        const {expandedAccordion,isArabicLanguage} =this.state;

        // Customizable Area End

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme} >
                <React.Fragment>
                    <Container>
                    <Toster
                        data-test-id="alertMessageCustomerReviewForm"
                        tosterText={this.state.alertMessage}
                        showToster={this.state.showAlert}
                        tosterType={this.state.alertType}
                        handleCloseToster={() => this.setState({ showAlert: false })}
                    />
                    <div>
                    <Accordion data-test-id='p4' id="p4-accordion" className={classes.accordionStyleStageSix} expanded={expandedAccordion}
                            onChange={() => this.handleAccordianStageSix()} >
                            <AccordionSummary
                                aria-expanded={true}
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={classes.customerReviewTopSection}>
                                <Typography className={classes.accordianHeadingStageSix} >{t('revision_release')}</Typography>
                               <div style={{display: 'flex'}}>
                                {
                                    this.state.expandedAccordion &&
                                    (<div data-test-id="noteDialog" className={classes.handoverNoteStageSixIcon} onClick={() => (this.setState({showNoteDialog:true}))}>
                                        <DescriptionOutlinedIcon />
                                    </div>)
                                }
                                {this.checkStageSixCondition() &&
                                    <ErrorOutlineRounded style={{position: 'relative', left: '8px', color: '#0090a1'}} onClick={() => this.setState({showRejectDialog: true})}  />
                                }
                               </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Formik
                                    innerRef={this.formikRef}
                                    enableReinitialize={true}
                                    initialValues={this.getStageFourDataForStageSix()}
                                    onSubmit={async (values, actions)  => {}}
                                >

                                    {({
                                        handleSubmit,
                                        errors,
                                        touched,
                                        values,
                                        setFieldValue,
                                        validateForm
                                    }) => (
                                        <React.Fragment>
                                            <Grid container spacing={2}>
                                                 <Grid item xs={4}>
                                                    <>
                                    <LabelWithIcon label={t("revision_number")} />
                                    <TextInput
                                        id={`revsion_date`}
                                        disabled={true}
                                        className={classes.textInputStyleStageSix}
                                        isArabicLenguage={isArabicLanguage}
                                        type="text"
                                        name={`revision_number`}
                                        data-test-id="revsion_date"
                                    />
                                    </>
                                </Grid>
                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t("revision_date")} />
                                    <TextInput
                                        id={`revsion_date`}
                                        disabled={true}
                                        className={classes.textInputStyleStageSix}
                                        isArabicLanguage={isArabicLanguage}
                                        type="date"
                                        name={`revision_date`}
                                        />
                                        </>
                                    </Grid>


                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t('revision_confimation')} />
                                    <FileUpload
                                     eachFileSizeInMB={10}
                                     disabled={true}
                                     fileName={values?.revision_confirmation?.file_name || ""}
                                     filePath={values?.revision_confirmation?.url || ""}
                                     data-test-id="revision_confimation"
                                     inputName={`revision_confimation`}
                                     onChange={(event: any) => {
                                        setFieldValue("revision_confimation", event.target.files[0])                                                                                    }
                                     }
                                     onRemove={() => {
                                         setFieldValue(`revision_confimation`, {})
                                     }}

                                    />
                                    </>
                                </Grid>
                                <Grid item xs={6}>
                               <>
                               <LabelWithIcon label={t('impact_of_revision_to_policy_value')} />
                               <FormControl component="fieldset">

                                        <RadioGroup className={classes.radioBtnContainerStageSix} name={`policy_impact`}
                                        value={values?.policy_impact}
                                        data-test-id="radio_policy"

                                        onChange={(event: any) => setFieldValue(`policy_impact`, event.target.value)}

                                        >
                                             <FormControlLabel
                                                value={enum_impact.up}
                                                disabled={true}
                                                control={<Radio color="primary" />}
                                                label={t("up")} />
                                                 <FormControlLabel
                                                 disabled={true}
                                                value={enum_impact.down}
                                                control={<Radio color="primary" />}
                                                label={t("down")} />
                                                 <FormControlLabel
                                                 disabled={true}
                                                value={enum_impact.unchanged}
                                                control={<Radio color="primary" />}
                                                label={t("unchanged")} />
                                        </RadioGroup>
                                    </FormControl>
                         </>


                                </Grid>

                                <Grid item xs={6}></Grid>

                               {values?.policy_impact =="up" && (<>
                                <Grid xs={4} item >
                                    <LabelWithIcon label={t('invoice_number')} />
                                    <TextInput
                                        id={`invoice_number`}
                                        disabled={true}
                                        className={classes.textInputStyleStageSix}
                                        isArabicLenguage={isArabicLanguage}
                                        type="text"
                                        name="invoice_number"

                                        data-test-id="revsion_date"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t('invoice_date')} />
                                    <TextInput
                                        id={`revsion_date`}
                                        disabled={true}
                                        className={classes.textInputStyleStageSix}
                                        isArabicLanguage={isArabicLanguage}
                                        type="date"
                                        name="invoice_date"
                                        />
                                 </>
                                    </Grid>
                                <Grid item xs={4}>

                                    <LabelWithIcon label={t('invoice')} />
                                 <FileUpload
                                 disabled={true}
                                 fileName={values.revision_invoice?.file_name || ""}
                                filePath={values.revision_invoice?.url || ""}
                                eachFileSizeInMB={10}
                                data-test-id="revision_invoice"
                                onChange={(event: any) => {
                                        setFieldValue("revision_invoice", event.target.files[0])                                                                                    }
                                     }
                                     onRemove={() => {
                                         setFieldValue(`revision_invoice`, {})
                                     }}


                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t('invoice_amount')} />
                                    <TextInput
                                        id={`revsion_date`}
                                        disabled={true}
                                        className={classes.textInputStyleStageSix}
                                        isArabicLanguage={isArabicLanguage}
                                        name={"invoice_amount"}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <div> &nbsp;SAR&nbsp;</div>
                                            </InputAdornment>
                                        }
                                        />
                                        </>
                                </Grid>
                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t('new_policy_value')} />
                                    <TextInput
                                        name ={"new_policy_value"}
                                        disabled={true}
                                        id={`new_policy_value`}
                                        className={classes.texnew_policy_valuetInputStyle}
                                        isArabicLanguage={isArabicLanguage}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <div> &nbsp;SAR&nbsp;</div>
                                            </InputAdornment>
                                        }
                                        />
                                    </>
                                </Grid>

                                </> )}
                                {values?.policy_impact =="down" &&
                                (<>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('credit_note_number')} />
                                    <TextInput
                                        id={`credit_note_number`}
                                        disabled={true}
                                        className={classes.textInputStyleStageSix}
                                        isArabicLenguage={isArabicLanguage}
                                        type="text"
                                        name="credit_note_number"
                                        data-test-id="revsion_date"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('credit_note_date')} />
                                    <TextInput
                                        id={`credit_note_date`}
                                        disabled={true}
                                        className={classes.textInputStyleStageSix}
                                        isArabicLanguage={isArabicLanguage}
                                        type="date"
                                        name="credit_note_date"
                                        />

                                    </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('credit_note')} />
                                    <FileUpload
                               filePath={values.revision_credit_note?.url || ""}
                               disabled={true}
                               fileName={values.revision_credit_note?.file_name || ""}
                               inputName={`revision_credit_note`}
                                 eachFileSizeInMB={10}
                                data-test-id="revision_credit_note"
                                 />
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('credit_note_amount')} />
                                    <TextInput
                                        id={`credit_note_amount`}
                                        disabled={true}
                                        className={classes.textInputStyleStageSix}
                                        isArabicLanguage={isArabicLanguage}
                                        name={"credit_note_amount"}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <div> &nbsp;SAR&nbsp;</div>
                                            </InputAdornment>
                                        }
                                        />
                                </Grid>
                                <Grid item xs={4}>
                                    <>
                                    <LabelWithIcon label={t('new_policy_value')} />
                                    <TextInput
                                        name ={"new_policy_value"}
                                        disabled={true}
                                        id={`new_policy_value`}
                                        className={classes.texnew_policy_valuetInputStyle}
                                        isArabicLanguage={isArabicLanguage}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <div> &nbsp;SAR&nbsp;</div>
                                            </InputAdornment>
                                        }
                                        />
                                    </>
                                </Grid>
                                </>)}
                                <Grid item xs={12}>
                            <LabelWithIcon label={t('comment')} />
                            <TextInput
                                id={`comment`}
                                disabled={true}
                                className={classes.textInputStyleStageSix}
                                isArabicLenguage={isArabicLanguage}
                                name={`comment`}
                                type="text"
                                data-test-id={`comment`}
                                multiline
                                minRows={4}
                                maxRows={4}
                            />
                        </Grid>

                                </Grid>

                                        </React.Fragment>
                                    )}

                                </Formik>

                            </AccordionDetails>
                        </Accordion>
                        </div>

                {this.props.isExpanded && (
                    <div className={classes.displayFlexEnd}>
                        <div>
                            <CustomButton
                                data-test-id="stageSix_next_btn"
                                btnText={t('next_stage')}
                                onClick={() => this.setState({showNextDialog:true,expandedAccordion:false})}
                                bgColor={"linear-gradient(to bottom, #2d6f8f, #4fb7ba)"}
                                textColor={'#fff'}
                            />
                        </div>
                    </div>
                )}

                    <HandOverDialog
                        data-test-id="handOverNoteDialog"
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNoteDialog}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={`(${t('sales_review')} > ${t('customere_review')})`}
                        handleInputText={()=>{}}
                        inputValue={this.props.handoverNote}
                        handleOk={()=>{this.setState({showNoteDialog:false,loading:false})}}
                        inputDisable={!isExpanded}
                        handleCancel={()=>{this.setState({showNoteDialog:false,loading:false})}}
                        showDialogBtns={false}
                        showCrossBtn={true}
                    />
                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        data-test-id="nextStageDialog"
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNextDialog}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={`(${t('customere_review')} > ${t('payment_review')})`}
                        handleInputText={(value:string)=>{
                            this.setState({handOverNoteText:value})
                            this.props.setStageData("handOverNoteForPaymentReview",value)
                        }}
                        inputValue={this.state.handOverNoteText}
                        handleOk={()=>{this.trackRequest()}}
                        handleCancel={()=>{this.setState({showNextDialog:false,handOverNoteText:"", loading:false})}}
                        showDialogBtns={true}
                        showCrossBtn={true}
                    />
                    <RejectRequestDialog
                        data-test-id="rejectDialog"
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showRejectDialog}
                        textId={'rejectReason'}
                        inputValue={rrStageSixData?.data?.attributes?.rejected_reason}
                        headingText={t('reject')}
                        handleCancel={() => this.setState({showRejectDialog: false})}
                        showDialogBtns={false}
                        showCrossBtn={true}
                        labelText={t('push_back')}
                        reasonLabel={t('reason')}
                    />
                    </Container>
                </React.Fragment>

            </ThemeProvider >
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const useStyles = ({
    displayFlexEnd: { display: "flex", justifyContent: "end", margin: '16px 0' },
    btnStyle: {
        margin: '0 10px'
    },
    accordionStyleStageSix: {
        background: "white !important",
        "border-radius": "10px !important"
    },
    accordianHeadingStageSix: {
        fontWeight: 600
    },
    textInputStyleStageSix: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    radioBtnContainerStageSix: {
        display: "flex",
        "flex-direction": "row"
    },
    handoverNoteStageSixIcon: {
        color: '#007E98'
    },
    customerReviewTopSection:{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    }

});

export default withStyles(useStyles)(withTranslation()(CustomerReviewForm));
// Customizable Area End