import * as Yup from 'yup';

const StageSevenFormSchema = (flow: number) => {
    let schema = {};
    const isArabicLanguage = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false
    switch (flow) {
        case 1: schema = {
            paid_amount: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
            stageData: Yup.object().shape({
                flowOneData:
                    Yup.object().shape({
                        insure_recipent_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Insurer receipt number is required.').nullable(),
                        payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                        proof_of_payment_doc: Yup.mixed().required(isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" : 'Please upload proof of payment document').nullable(),
                        policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" :'Policy release requested must be checked'),
                    }
                    )


            }),


        }
            break;
        case 2: schema = {
            paid_amount: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
            stageData: Yup.object().shape({
                flowTwoData:
                    Yup.object().shape({
                        insure_recipent_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Insurer receipt number is required.').nullable(),
                        payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                        customer_payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : ' Payment date is required.').nullable(),
                        customer_receipt_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Customer receipt number is required.').nullable(),
                        proof_of_payment_doc: Yup.mixed().required(isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" : 'Please upload proof of payment document').nullable(),
                        revised_voucher_proof_of_payment_doc_0: Yup.mixed().required(isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" : 'Please upload proof of payment document').nullable(),
                        policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" :'Policy release requested must be checked'),
                        revised_proof_of_payment_doc_id: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء قسيمة دفع منقحة" : "Please create revised payment voucher")

                    })



            })
        }
            break;
        case 3: schema = {
            paid_amount: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
            deduct_commission: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم" : 'Deduct commission is required.').nullable(),
            stageData: Yup.object().shape({
                flowThreeData:
                    Yup.object().shape({
                        siib_receipt_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'SIIB receipt number is required.').nullable(),
                        payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                        proof_of_payment_doc: Yup.mixed().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Please upload proof of payment document').nullable(),
                        insurance_receipt_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Insurer receipt number is required.').nullable(),
                        voucher_payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                        voucher_proof_of_payment_doc: Yup.mixed().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Please upload proof of payment document.').nullable(),
                        policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" :'Policy release requested must be checked'),
                        payment_date_2: Yup.string().required(isArabicLanguage ? 'الرجاء إدخال تاريخ قسيمة الدفع' : 'Please enter payment voucher date'),
                        payment_voucher_id: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء قسيمة الدفع' : "Please create payment voucher"),
                    }
                    )


            }),
        }
            break;
        case 4: schema = {
            paid_amount: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
            deduct_commission: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم" : 'Deduct commission is required.').nullable(),
            stageData: Yup.object().shape({
                flowFourData:
                    Yup.object().shape({
                        siib_receipt_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'SIIB receipt number is required.').nullable(),
                        payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                        proof_of_payment_doc: Yup.mixed().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Please upload proof of payment document').nullable(),
                        insurance_receipt_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Insurer receipt number is required.').nullable(),
                        voucher_payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                        voucher_proof_of_payment_doc: Yup.mixed().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Please upload proof of payment document').nullable(),
                        revised_insurance_receipt_number_0: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Customer receipt number is required.').nullable(),
                        revised_voucher_payment_date_0: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                        revised_voucher_proof_of_payment_doc_0: Yup.mixed().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Please upload proof of payment document').nullable(),
                        policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" :'Policy release requested must be checked'),
                        payment_date_2: Yup.string().required(isArabicLanguage ? 'الرجاء إدخال تاريخ قسيمة الدفع' : 'Please enter payment voucher date'),
                        payment_voucher_id: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء قسيمة الدفع' : "Please create payment voucher"),
                        revised_payment_voucher_id_0: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء قسيمة دفع منقحة" : "Please create revised payment voucher")
                    }
                    )

            }),
        }
            break;
        case 5: schema = {
            paid_amount: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
            deduct_commission: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم" : 'Deduct commission is required.').nullable(),
            stageData: Yup.object().shape({
                flowFiveData:
                    Yup.object().shape({
                        vat_on_siib_commission: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'VAT on SIIB commission is required.').nullable(),
                        commission_rate: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'معدل العمولة يقبل الأرقام فقط' : 'Commission rate only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Commision rate is required.').nullable(),
                        commission_amount: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Commission amount is required.').nullable(),
                        invoice_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Invoice date is required.').nullable(),
                        receipt_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Receipt date is required.').nullable(),
                        payable_to_insurer: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payable to insurer is required.').nullable(),
                        insurance_receipt_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Insurer receipt is required.').nullable(),
                        voucher_payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                        voucher_proof_of_payment_doc: Yup.mixed().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Please upload proof of payment document').nullable(),
                        policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" :'Policy release requested must be checked'),
                        siib_invoice_id: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء الفاتورة والإيصال" : "Please create Invoice and receipt"),
                        siib_receipt_id: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء الفاتورة والإيصال" : "Please create invoice and receipt"),
                        payment_voucher_id: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء قسيمة الدفع" : "Please create payment voucher"),
                    }
                    )


            }),
        }
            break;

        case 6: schema = {
            paid_amount: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
            deduct_commission: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم" : 'Deduct commission is required.').nullable(),
            stageData: Yup.object().shape({
                flowSixData:
                    Yup.object().shape({
                        vat_on_siib_commission: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'VAT on SIIB commission is required.').nullable(),
                        commission_rate: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'معدل العمولة يقبل الأرقام فقط' : 'Commission rate only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Commission rate is required.').nullable(),
                        commission_amount: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Commission amount is required.').nullable(),

                        invoice_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Invoice date is required.').nullable(),
                        receipt_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Receipt date is required.').nullable(),
                        payable_to_insurer: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payable to insurer is required.').nullable(),
                        insurance_receipt_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Insurer receipt number is required.').nullable(),
                        voucher_payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                        voucher_proof_of_payment_doc: Yup.mixed().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Please upload proof of payment document').nullable(),
                        revised_insurance_receipt_number_0: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Customer receipt number is required.').nullable(),
                        revised_voucher_payment_date_0: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                        revised_voucher_proof_of_payment_doc_0: Yup.mixed().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Please upload proof of payment document').nullable(),
                        revised_insurance_receipt_number_1: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Insurer receipt number is required.').nullable(),
                        revised_voucher_payment_date_1: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                        policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" :'Policy release requested must be checked'),
                        siib_invoice_id: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء الفاتورة والإيصال" : "Please create Invoice and receipt"),
                        siib_receipt_id: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء الفاتورة والإيصال" : "Please create invoice and receipt"),
                        payment_voucher_id: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء قسيمة الدفع" : "Please create payment voucher"),
                        revised_payment_voucher_id_0: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء قسيمة دفع منقحة" : "Please create revised payment voucher"),
                        revised_payment_voucher_id_1: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء قسيمة دفع منقحة" : "Please create revised payment voucher")

                    })
            }),
        }
            break;

        case 7: schema = {
            paid_amount: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
            stageData: Yup.object().shape({
                flowSevenData:
                    Yup.object().shape({
                        insure_recipent_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Insurer receipt number is required.').nullable(),
                        proof_of_payment_doc: Yup.mixed().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Please upload proof of payment document.').nullable(),
                        policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" :'Policy release requested must be checked'),
                        installment_attributes: Yup.array(
                            Yup.object().shape({
                                installment_amount: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'مبلغ القسط يقبل الأرقام فقط' : 'Installment amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Installment amount is required.').nullable(),
                                due_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Due date is required.').nullable(),
                                insurance_receipt_number: Yup.string().when('installment_paid', {
                                    is: "yes",
                                    then: Yup.string().required(isArabicLanguage ? 'التعليق مطلوب' : "Insurer receipt number is required."),
                                    otherwise: Yup.string().nullable()
                                }),
                                payment_date_2: Yup.string().nullable().when('installment_paid', {
                                    is: "yes",
                                    then: Yup.string().required(isArabicLanguage ? 'التعليق مطلوب' : "Payment date is required."),
                                    otherwise: Yup.string().nullable()
                                }),
                                proof_of_payment_doc: Yup.mixed().when('installment_paid', {
                                    is: "yes",
                                    then: Yup.mixed().required(isArabicLanguage ? 'التعليق مطلوب' : "Please upload proof of payment document"),
                                    otherwise: Yup.mixed().nullable()
                                }),

                            })),
                        balance: Yup.string().matches(/^[0]*$/g, isArabicLanguage ? 'يجب أن يكون مبلغ التكوين مساوياً لمبلغ الرصيد' : 'Configure amount should be equal to balance amount')
                    })




            }),
        }
            break;

        case 8: schema = {
            paid_amount: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
            deduct_commission: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم" : 'Deduct commission is required.').nullable(),
            stageData: Yup.object().shape({
                flowEightData: Yup.object().shape({
                    siib_receipt_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'SIIB receipt number is required.').nullable(),
                    insurance_receipt_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'insurance receipt number is required.').nullable(),
                    payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                    proof_of_payment_doc: Yup.mixed().required(isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" : 'Please upload proof of payment document'),
                    voucher_payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                    voucher_proof_of_payment_doc: Yup.mixed().required(isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" : 'Please upload proof of payment document'),
                    policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" :'Policy release requested must be checked'),
                    payment_voucher_id: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء قسيمة الدفع" : "Please create payment voucher"),
                    installment_attributes: Yup.array().of(
                        Yup.object().shape({
                            installment_amount: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'مبلغ القسط يقبل الأرقام فقط' : 'Installment amount only accept numbers')).required(
                                isArabicLanguage
                                    ? 'مبلغ القسط مطلوب'
                                    : 'Installment amount is required'
                            ),
                            due_date: Yup.string().required(
                                isArabicLanguage ? 'تاريخ الاستحقاق مطلوب' : 'Due date is required'
                            ),
                            siib_receipt_number: Yup.string().when('installment_paid', {
                                is: "yes",
                                then: Yup.string().required(
                                    isArabicLanguage ? 'القسط المدفوع مطلوب' : 'SIIB number is required'
                                ), otherwise: Yup.string().nullable()
                            }),
                            payment_date:
                                Yup.string().when('installment_paid', {
                                    is: "yes",
                                    then: Yup.string().required(
                                        isArabicLanguage ? 'القسط المدفوع مطلوب' : 'Payment date is required'
                                    ), otherwise: Yup.string().nullable()
                                }),

                            proof_of_payment_doc:
                                Yup.mixed().when('installment_paid', {
                                    is: "yes",
                                    then: Yup.mixed().required(isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" : 'Please upload proof of payment document'),
                                    otherwise: Yup.mixed().nullable()
                                }),

                            insurer_receipt_number: Yup.string().when('installment_paid', {
                                is: "yes",
                                then: Yup.string().required(
                                    isArabicLanguage ? 'القسط المدفوع مطلوب' : 'Insurer number is required'
                                ), otherwise: Yup.string().nullable()
                            }),


                            payment_date_2: Yup.string().nullable().when('installment_paid', {
                                is: "yes",
                                then: Yup.string().required(
                                    isArabicLanguage ? 'القسط المدفوع مطلوب' : 'Payment date is required'
                                ), otherwise: Yup.string().nullable()
                            }),


                            voucher_proof_of_payment_doc: Yup.mixed().when('installment_paid', {
                                is: "yes",
                                then: Yup.mixed().required(isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" : 'Please upload proof of payment document'),
                                otherwise: Yup.mixed().nullable()
                            }),
                            
                            payment_voucher_id:
                            Yup.string().when('installment_paid', {
                                is: "yes",
                                then: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء قسيمة الدفع" : "Please create payment voucher"), 
                                otherwise: Yup.string().nullable()
                            }),
                        })
                    ),
                    balance: Yup.string().matches(/^[0]*$/g, isArabicLanguage ? 'يجب أن يكون مبلغ التكوين مساوياً لمبلغ الرصيد' : 'Configure amount should be equal to balance amount')

                }),
            }),


        }
            break;
        case 9: schema = {
            paid_amount: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'المبلغ المدفوع يقبل الأرقام فقط' : 'Paid amount only accept numbers')).required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Paid amount is required.').nullable(),
            deduct_commission: Yup.string().required(isArabicLanguage ? "مطلوب عمولة الخصم" : 'Deduct commission is required.').nullable(),
            stageData: Yup.object().shape({
                flowNineData: Yup.object().shape({
                    insurance_receipt_number: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Insurer receipt number is required.').nullable(),
                    voucher_payment_date: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Payment date is required.').nullable(),
                    commission_rate: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'معدل العمولة يقبل الأرقام فقط' : 'Commission rate only accept numbers')).required(
                        isArabicLanguage
                            ? 'معدل العمولة مطلوب'
                            : 'Commission rate is required'
                    ).nullable(),
                    commission_amount: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Commission amount is required.').nullable(),

                    vat_on_siib_commission: Yup.string().required(
                        isArabicLanguage
                            ? 'ضريبة القيمة المضافة على عمولة SIIB مطلوبة'
                            : 'VAT on SIIB commission is required'
                    ).nullable(),
                    invoice_date: Yup.string().required(
                        isArabicLanguage ? 'تاريخ الفاتورة مطلوب' : 'Invoice date is required'
                    ).nullable(),
                    receipt_date: Yup.string().required(
                        isArabicLanguage ? 'تاريخ الاستلام مطلوب' : 'Receipt date is required'
                    ).nullable(),
                    payable_to_insurer: Yup.string().required(
                        isArabicLanguage
                            ? 'الدفع للمؤمن عليه مطلوب'
                            : 'Payable to insurer is required'
                    ).nullable(),
                    policy_release_requested : Yup.string().matches(/^yes*$/g, isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" :'Policy release requested must be checked'),
                    payment_voucher_id: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء قسيمة الدفع" : "Please create payment voucher"),
                    siib_invoice_id: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء inovice والاستلام" : "Please create inovice and receipt"),
                    siib_receipt_id: Yup.string().required(isArabicLanguage ? "الرجاء إنشاء inovice والاستلام" : "Please create inovice and receipt"),

                    installment_attributes: Yup.array().of(
                        Yup.object().shape({
                            installment_amount: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'مبلغ القسط يقبل الأرقام فقط' : 'Installment amount only accept numbers')).required(
                                isArabicLanguage
                                    ? 'مبلغ القسط مطلوب'
                                    : 'Installment amount is required'
                            ),
                            due_date: Yup.string().required(
                                isArabicLanguage ? 'تاريخ الاستحقاق مطلوب' : 'Due date is required'
                            ),

                            commission_rate:
                            Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'معدل العمولة يقبل الأرقام فقط' : 'Commission rate only accept numbers')).when('installment_paid', {
                                is: "yes",
                                then: Yup.string().matches(/^[0-9]+$/, (isArabicLanguage ? 'معدل العمولة يقبل الأرقام فقط' : 'Commission rate only accept numbers')).required(
                                    isArabicLanguage
                                        ? 'معدل العمولة مطلوب'
                                        : 'Commission rate is required'
                                ), otherwise: Yup.string().nullable()
                            }),
                            commission_amount:Yup.string().when('installment_paid', {
                                is: "yes",
                                then: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" : 'Commission amount is required.').nullable(),
                                otherwise: Yup.string().nullable()
                            }),
                            vat_on_siib_commission:
                            Yup.string().when('installment_paid', {
                                is: "yes",
                                then:Yup.string().required(
                                    isArabicLanguage
                                        ? 'ضريبة القيمة المضافة على عمولة SIIB مطلوبة'
                                        : 'VAT on SIIB commission is required'
                                ), otherwise: Yup.string().nullable()
                            }),
                            invoice_date:
                            Yup.string().nullable().when('installment_paid', {
                                is: "yes",
                                then: Yup.string().required(
                                    isArabicLanguage ? 'تاريخ الفاتورة مطلوب' : 'Invoice date is required'
                                ).nullable(), otherwise: Yup.string().nullable()
                            }),
                            receipt_date:
                            Yup.string().nullable().when('installment_paid', {
                                is: "yes",
                                then:  Yup.string().required(
                                    isArabicLanguage ? 'تاريخ الاستلام مطلوب' : 'Receipt date is required'
                                ), otherwise: Yup.string().nullable()
                            }),
                            payable_to_insurer:
                            Yup.string().when('installment_paid', {
                                is: "yes",
                                then: Yup.string().required(
                                    isArabicLanguage
                                        ? 'هوية إيصال SIIB مطلوبة'
                                        : 'Payble to insurer is required'
                                ).nullable(), otherwise: Yup.string().nullable()
                            }),
                            insurer_receipt_number:
                            Yup.string().when('installment_paid', {
                                is: "yes",
                                then:Yup.string().required(
                                    isArabicLanguage
                                        ? 'هوية إيصال SIIB مطلوبة'
                                        : 'Insurer receipt ID is required'
                                ), otherwise: Yup.string().nullable()
                            }),
                            voucher_payment_date: Yup.string().nullable().when('installment_paid', {
                                is: "yes",
                                then: Yup.string().required(
                                    isArabicLanguage
                                        ? 'هوية إيصال SIIB مطلوبة'
                                        : 'Payment date is required'
                                ).nullable(), otherwise: Yup.string().nullable()
                            }),
                            proof_of_payment_doc:Yup.mixed().when('installment_paid', {
                                is: "yes",
                                then: Yup.mixed().required(
                                    isArabicLanguage
                                        ? 'هوية إيصال SIIB مطلوبة'
                                        : 'Please upload proof of payment document'
                                ), otherwise: Yup.mixed().nullable()
                            }),
                            siib_invoice_id: Yup.mixed().when('installment_paid', {
                                is: "yes",
                                then: Yup.mixed().required(
                                    isArabicLanguage
                                        ? "الرجاء إنشاء inovice والاستلام"
                                        : "Please create inovice and receipt"
                                ), otherwise: Yup.mixed().nullable()
                            }),
                            siib_receipt_id: Yup.mixed().when('installment_paid', {
                                is: "yes",
                                then: Yup.mixed().required(
                                    isArabicLanguage
                                        ? 'الرجاء إنشاء inovice والاستلام'
                                        : 'Please create inovice and receipt'
                                ), otherwise: Yup.mixed().nullable()
                            }),
                            payment_voucher_id: Yup.mixed().when('installment_paid', {
                                is: "yes",
                                then: Yup.mixed().required(
                                    isArabicLanguage
                                        ? 'الرجاء إنشاء قسيمة الدفع'
                                        : 'Please create payment voucher'
                                ), otherwise: Yup.mixed().nullable()
                            })                            
                        })
                    ),
                    balance: Yup.string().matches(/^[0]*$/g, isArabicLanguage ? 'يجب أن يكون مبلغ التكوين مساوياً لمبلغ الرصيد' : 'Configure amount should be equal to balance amount')

                }),
            }),


        }

    }


    return schema;
}
export default StageSevenFormSchema;