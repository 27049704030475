// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import PaymentReviewForm from "./PaymentReviewForm"
import PaymentReviewController , { Props } from './PaymentReviewController'
import { Container } from "@material-ui/core";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

export default class PaymentReview extends PaymentReviewController {

    constructor(props:Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme} >
                <Container id="customerContainer" maxWidth="lg" dir={(window.localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"} >
                <PaymentReviewForm {...this.props}/>
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End

