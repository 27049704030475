import React from "react";
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    InputAdornment,
    Typography
} from "@material-ui/core";
// @ts-ignore
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import StageSevenController from "../../../../blocks/TaskAllocator/src/PolicyStage/StageSevenController";
import LabelWithIcon from "../../Common/LabelWithIcon";
import TextInput from "../../Common/TextInput";
import FileUpload from "../../Common/FileUpload";
import CommissionForm from "./CommissionForm";
import Toster from "../../Common/Toster"
import { ErrorMessage } from "formik";
import DownloadFile from "../../Common/DownloadFile";


export class InstallmentForm extends StageSevenController {

    alreadyFilled = (installmentIndex: any) => {
        const stageSevenData = JSON.parse(localStorage.getItem('stageSevenData') || '{}')
        if (this.convertToWord(this.state.flow) === "Seven" && stageSevenData?.attributes?.installments?.data?.[installmentIndex as number]?.attributes?.installment_paid&&!stageSevenData?.attributes?.save_as_draft) {
            return true
        }
        if (this.convertToWord(this.state.flow) === "Eight" && stageSevenData?.attributes?.installments?.data?.[installmentIndex as number]?.attributes?.installment_paid && !stageSevenData?.attributes?.save_as_draft) {
            return true
        }
        if (this.convertToWord(this.state.flow) === "Nine" && stageSevenData?.attributes?.installments?.data?.[installmentIndex as number]?.attributes?.installment_paid && !stageSevenData?.attributes?.save_as_draft) {
            return true
        }
        return false
    }
    checkInstallments = (installmentIndex: any) => {
        if (this.convertToWord(this.props.flowValue as number) === "Seven" && this.props.values.stageData?.flowSevenData?.installment_attributes?.[installmentIndex as number]?.installment_paid !== "yes") {
            return true
        }
        if (this.convertToWord(this.props.flowValue as number) === "Eight" && this.props.values.stageData?.flowEightData?.installment_attributes?.[installmentIndex as number]?.installment_paid !== "yes") {
            return true
        }
        if (this.convertToWord(this.props.flowValue as number) === "Nine" && this.props.values.stageData?.flowNineData?.installment_attributes?.[installmentIndex as number]?.installment_paid !== "yes") {
            return true
        }
        return false
    }

    render() {
        const { t, classes, setFieldValue, values, paymentRecipient, formikRef, flowValue, installmentIndex, stageSixData, checkStepProps, dummyData, checkDisable } = this.props;
        return (
            <>
                <Toster
                    tosterText={this.state.alertMessage}
                    showToster={this.state.showAlert}
                    // @ts-ignore
                    tosterType={this.state.alertType}
                    handleCloseToster={() => this.setState({ showAlert: false })}
                />
                <Grid item xs={12} container className={classes.btnGrid} >
                    <Grid item xs={4} className={classes.fieldAlignment} >
                        <LabelWithIcon label={t('installment_amount')} />
                        <TextInput
                            className={classes.textInputStyle}
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].installment_amount`}
                            type="text"
                            disabled={checkDisable}
                            data-test-id="installmentAmount"
                            startAdornment={
                                <InputAdornment position="start">
                                    <div> &nbsp;SAR&nbsp;</div>
                                </InputAdornment>
                            }
                            onBlur={() => {
                                const dummyData = stageSixData?.attributes?.quotation_insurance_infos?.data?.filter((item: any) => {
                                    return item?.attributes?.approval === true
                                })[0];
                                const quotationAmount = Number(dummyData?.attributes?.quotation_amount)
                                const dueAmount = quotationAmount - Number(values?.paid_amount)
                                const instalmentPaid = values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`].installment_attributes.reduce((sum: any, installment: any) => sum + Number(installment?.installment_amount), 0)
                                let bal = Number(dueAmount) - Number(instalmentPaid)
                                setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.balance`, bal)
                                checkStepProps(values, flowValue as number)
                            }}
                        />
                        <ErrorMessage
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].installment_amount`}
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />


                    </Grid>
                    <Grid item xs={4} className={classes.fieldAlignment} >
                        <LabelWithIcon label={t('due_date')} />
                        <TextInput
                            className={classes.textInputStyle}
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].due_date`}
                            type={"date"}
                            disabled={checkDisable}
                            onChange={(e: any) => setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].due_date`, e.target.value)}
                            onBlur={() => checkStepProps(values, flowValue as number)}
                        />
                        <ErrorMessage
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].due_date`}
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />


                    </Grid>
                    <Grid item xs={4} >
                        <LabelWithIcon label={t('installment_paid')} />
                        <FormControlLabel
                            color="primary"
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].installment_paid`}
                            control={<Checkbox color="primary"
                            disabled={!this.props.isExpanded || this.alreadyFilled(installmentIndex)}
                            checked={values && values.stageData &&  values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number]?.installment_paid === "yes" ? true : false}
                                onChange={(e: any) => {
                                    if ((installmentIndex && installmentIndex > 0 && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[`${installmentIndex-1}`]?.installment_paid === "yes") || installmentIndex === 0) setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].installment_paid`, e.target.checked ? "yes" : "no")
                                    else {
                                        const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
                                        this.setState({
                                            showAlert: true,
                                            alertMessage: isArabic ? "يرجى دفع القسط السابق أولا" : "Please pay the previous installment first",
                                            alertType: "warning"                                        })
                                }
                                }} />
                            }
                            label={t('yes')}
                            value={'yes'}
                            style={{ marginLeft: 5, marginTop: 10 }}
                        />
                    </Grid>
                </Grid>
                {
                    values?.payment_reciepent === "Siib" && values.deduct_commission === 'yes' ?
                        <React.Fragment>
                            <CommissionForm values={values} setFieldValue={setFieldValue} title="Installment" installmentIndex={installmentIndex} flowValue={this.props.flowValue} formikRef={formikRef} isExpanded={this.props.isExpanded} checkStepProps={checkStepProps}/>
                            <Grid item xs={12} container style={{ marginBottom: 20, borderTop: "3px solid #eaeaea", paddingTop: 25 }}>
                                <Grid item xs={4} className={classes.fieldAlignment} >
                                    <LabelWithIcon label={t('payable_to_insurer')} />
                                    <TextInput
                                        className={classes.textInputStyle}
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payable_to_insurer`}
                                        disabled={!this.props.isExpanded || this.checkInstallments(installmentIndex) || this.alreadyFilled(installmentIndex)}
                                        onBlur={() => checkStepProps(values, flowValue as number)}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <div> &nbsp;SAR&nbsp;</div>
                                            </InputAdornment>
                                        }
                                        type={"text"}
                                    />
                                    <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payable_to_insurer`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                                </Grid>
                                <Grid item xs={4} className={classes.fieldAlignment} >
                                    <LabelWithIcon label={t('payment_voucher_date')} />
                                    <TextInput
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_date_2`}
                                        type={"date"}
                                        onChange={(e: any) => setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_date_2`, e.target.value)}
                                        disabled={!this.props.isExpanded || this.checkInstallments(installmentIndex) || this.alreadyFilled(installmentIndex)}
                                    />
                                    <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_date_2`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: 28, marginBottom: 9 }}>
                                    <Button
                                        data-test-id="mainCreateInvoiceBtn"
                                        disabled={this.checkPaymentVoucherBtnDisabledForFlow9(values,flowValue, installmentIndex)}
                                        className={this.checkPaymentVoucherBtnDisabledForFlow9(values, flowValue, installmentIndex) ? classes.grayBtn : classes.primaryRvBtn}
                                        aria-haspopup="true"
                                        aria-controls="customized-menu"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            this.generateVoucherForSiibInstallment(values,installmentIndex,formikRef,flowValue,dummyData)
                                        }}
                                    >
                                        {!this.state.voucherBtnLoading && t('create_payment_voucher')}
                                        {this.state.voucherBtnLoading && (<CircularProgress size={25} />)}
                                    </Button>
                                    <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_voucher_id`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container style={{ marginBottom: 20 }}>
                                <Grid item xs={4} className={classes.fieldAlignment} >
                                    <LabelWithIcon label={t('payment_voucher')} />
                                    <DownloadFile
                                        data-test-id="txtInputInvoiceDoc"
                                        disabled={true}
                                        eachFileSizeInMB={10}
                                        maxFilesNumber={5}
                                        fileName={
                                            values?.stageData &&
                                            values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number] &&
                                            values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number].payment_voucher?.file_name || ""
                                        }
                                        isEditPage={true}
                                        filePath={
                                            values?.stageData &&
                                            values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number] &&
                                            values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number].payment_voucher?.url || ""
                                        }
                                        inputName={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_voucher`}
                                    />
                                </Grid>
                                <Grid item xs={4} className={classes.fieldAlignment} >
                                    <LabelWithIcon label={t('payment_voucher_id')} />
                                    <TextInput
                                        disabled={true}
                                        className={classes.textInputStyle}
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_voucher_id`}
                                        type={"text"}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container >
                                <Grid item xs={4} className={classes.fieldAlignment} >
                                    <LabelWithIcon label={t('insurer_receipt_number')} />
                                    <TextInput
                                        className={classes.textInputStyle}
                                        disabled={!this.props.isExpanded || this.checkInstallments(installmentIndex) || this.alreadyFilled(installmentIndex)}
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].insurer_receipt_number`}
                                        type="text"
                                        data-test-id="insurance_receipt_number"
                                        onBlur={() => checkStepProps(values, flowValue as number)}
                                    />
                                    <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].insurer_receipt_number`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                                </Grid>
                                <Grid item xs={4} className={classes.fieldAlignment} >
                                    <LabelWithIcon label={t('payment_date')} />
                                    <TextInput
                                        disabled={!this.props.isExpanded || this.checkInstallments(installmentIndex) || this.alreadyFilled(installmentIndex)}
                                        data-test-id={`paymentDate`}
                                        id={`paymentDateTxtInput`}
                                        className={classes.textInputStyle}
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].voucher_payment_date`}
                                        type={"date"}
                                        onBlur={() => checkStepProps(values, flowValue as number)}
                                    />
                                    <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].voucher_payment_date`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                                </Grid>
                                <Grid item xs={4}>
                                    <LabelWithIcon label={t('proof_of_payment')} />
                                    <FileUpload
                                        eachFileSizeInMB={10}
                                        maxFilesNumber={5}
                                        disabled={!this.props.isExpanded || this.checkInstallments(installmentIndex) || this.alreadyFilled(installmentIndex)}
                                        fileName={values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes?.[installmentIndex || 0]?.proof_of_payment_doc?.file_name || ''}
                                        filePath={values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes?.[installmentIndex || 0]?.proof_of_payment_doc?.url || ''}
                                        inputName={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex || 0}].proof_of_payment_doc`}
                                        onChange={(event: any) => this.uploadFileDoc('proof_of_payment_to_insurer', event.target.files[0], formikRef, `stageData.flow${this.convertToWord(flowValue as number)}Data.installment_attributes[${installmentIndex || 0}].proof_of_payment_doc`, installmentIndex)}
                                        onRemove={async() => {setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex || 0}].proof_of_payment_doc`, {})
                                                        await this.removeFile(values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes?.[installmentIndex || 0]?.proof_of_payment_doc?.id)
                                    
                                    }}
                                    />

                                    <ErrorMessage
                                        name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].proof_of_payment_doc`}
                                        className="field-error"
                                        render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                                </Grid>
                            </Grid>
                        </React.Fragment> :
                        <Grid item xs={12} container>
                            <Grid item xs={4} className={classes.fieldAlignment} >
                                <LabelWithIcon label={paymentRecipient === "SIIB" ? t('siib_receipt_number') : t('insurer_receipt_number')} />
                                <TextInput
                                    disabled={this.checkInstallments(installmentIndex) || this.alreadyFilled(installmentIndex)}
                                    className={classes.textInputStyle}
                                    name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].${paymentRecipient === "SIIB" ? 'siib' : 'insurance'}_receipt_number`}
                                    type="text"
                                    data-test-id="insurance_receipt_number"
                                    onBlur={() => checkStepProps(values, flowValue as number)}
                                />
                                <ErrorMessage
                                    name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].${paymentRecipient === "SIIB" ? 'siib' : 'insurance'}_receipt_number`}
                                    className="field-error"
                                    render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                            </Grid>
                            <Grid item xs={4} className={classes.fieldAlignment} >
                                <LabelWithIcon label={t('payment_date')} />
                                <TextInput
                                    disabled={!this.props.isExpanded || this.checkInstallments(installmentIndex) || this.alreadyFilled(installmentIndex)}
                                    data-test-id={`paymentDate`}
                                    id={`paymentDateTxtInput`}
                                    className={classes.textInputStyle}
                                    name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_date_2`}
                                    type={"date"}
                                    onChange={(e: any) => setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_date_2`, e.target.value)}
                                    onBlur={() => checkStepProps(values, flowValue as number)}
                                />
                                <ErrorMessage
                                    name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_date_2`}
                                    className="field-error"
                                    render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                            </Grid>
                            <Grid item xs={4} >
                                <LabelWithIcon label={t('proof_of_payment')} />
                                <FileUpload
                                    eachFileSizeInMB={10}
                                    maxFilesNumber={5}
                                    disabled={!this.props.isExpanded || this.checkInstallments(installmentIndex) || this.alreadyFilled(installmentIndex)}
                                    fileName={(values && values?.stageData && flowValue && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number]?.proof_of_payment_doc?.file_name) || ''}
                                    filePath={(values && values?.stageData && flowValue && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number]?.proof_of_payment_doc?.url) || ''}
                                    data-test-id="proofOfPayment"
                                    inputName={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].proof_of_payment_doc`}
                                    onChange={(event: any) => this.uploadFileDoc('proof_of_payment', event.target.files[0], formikRef, `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].proof_of_payment_doc`, installmentIndex)}
                                    onRemove={async() => {setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].proof_of_payment_doc`, {})
                                    await this.removeFile(values?.stageData && flowValue && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number]?.proof_of_payment_doc?.id)

                                }}
                                />
                                <ErrorMessage
                                    name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].proof_of_payment_doc`}
                                    className="field-error"
                                    render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                            </Grid>
                        </Grid>
                }
                {values.payment_reciepent === "Siib" && values.deduct_commission === 'no' && <>
                    <Grid item xs={12} container style={{ marginBottom: 20, marginTop: 20 }}>
                        <Grid item xs={4} style={{ paddingRight: '25px' }}>
                            <LabelWithIcon label={t('payment_voucher_date')} />
                            <TextInput
                                className={classes.textInputStyle}
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_voucher_date`}
                                type={"date"}
                                onChange={(e: any) => setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_voucher_date`, e.target.value)}
                                disabled={this.checkInstallments(installmentIndex) || this.alreadyFilled(installmentIndex)}
                            />
                            <ErrorMessage
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_voucher_date`}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />


                        </Grid>
                        <Grid item xs={4} style={{ paddingRight: '25px', paddingTop:'30px' }}>
                            <Button
                                data-test-id="mainCreateInvoiceBtn"
                                disabled={this.checkPaymentVoucherBtnDisabledForFlow8(values, flowValue, installmentIndex)}
                                className={this.checkPaymentVoucherBtnDisabledForFlow8(values, flowValue, installmentIndex) ? classes.grayBtn : classes.primaryRvBtn}
                                aria-haspopup="true"
                                aria-controls="customized-menu"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.generateVoucherForSiibInstallment(values, installmentIndex, formikRef, flowValue, dummyData)
                                }}
                            >
                                {!this.state.voucherBtnLoading && t('create_payment_voucher')}
                                {this.state.voucherBtnLoading && (<CircularProgress size={25} />)}
                            </Button>
                            <ErrorMessage
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_voucher_id`}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container style={{ marginBottom: 20 }}>
                        <Grid item xs={4} style={{ paddingRight: '25px' }}>
                            <LabelWithIcon label={t('payment_voucher')} />
                            <DownloadFile
                                data-test-id="txtInputInvoiceDoc"
                                disabled={true}
                                eachFileSizeInMB={10}
                                maxFilesNumber={5}
                                fileName={
                                    values?.stageData &&
                                    values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number] &&
                                    values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number].payment_voucher?.file_name || ""
                                }
                                isEditPage={true}
                                filePath={
                                    values?.stageData &&
                                    values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number] &&
                                    values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number].payment_voucher?.url || ""
                                }
                                inputName={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_voucher`}
                            />
                        </Grid>
                        <Grid style={{ paddingRight: '25px' }} item xs={4}>
                            <LabelWithIcon label={t('payment_voucher_id')} />
                            <TextInput
                                className={classes.textInputStyle}
                                disabled={true}
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_voucher_id`}
                                type="text"
                            />



                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid style={{ paddingRight: '25px' }} item xs={4}>
                            <LabelWithIcon label={t('insurer_receipt_number')} />
                            <TextInput
                                id={""}
                                disabled={this.checkInstallments(installmentIndex) || this.alreadyFilled(installmentIndex)}
                                className={classes.textInputStyle}
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].insurer_receipt_number`}
                                type="text"
                                data-test-id="insurer_receipt_number"
                                onBlur={() => checkStepProps(values, flowValue as number)}
                            />
                            <ErrorMessage
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].insurer_receipt_number`}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />



                        </Grid>
                        <Grid style={{ paddingRight: '25px' }} item xs={4}>
                            <React.Fragment>
                                <LabelWithIcon label={t('payment_date')} />
                                <TextInput
                                    disabled={!this.props.isExpanded || this.checkInstallments(installmentIndex) || this.alreadyFilled(installmentIndex)}
                                    className={classes.textInputStyle}
                                    name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_date`}
                                    type={"date"}
                                    onChange={(e: any) => setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_date`, e.target.value)}
                                    onBlur={() => checkStepProps(values, flowValue as number)}
                                />
                                <ErrorMessage
                                    name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].payment_date`}
                                    className="field-error"
                                    render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                            </React.Fragment>
                        </Grid>
                        <Grid item xs={4}>
                            <LabelWithIcon label={t('proof_of_payment')} />
                            <FileUpload
                                eachFileSizeInMB={10}
                                maxFilesNumber={5}
                                filePath={(values && values?.stageData && flowValue && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number]?.voucher_proof_of_payment_doc?.url) || ''}
                                disabled={!this.props.isExpanded || this.checkInstallments(installmentIndex) || this.alreadyFilled(installmentIndex)}
                                fileName={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].voucher_proof_of_payment_doc`}
                                data-test-id="proofOfPayment"
                                inputName={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].voucher_proof_of_payment_doc`}
                                onChange={(event: any) => this.uploadFileDoc('proof_of_payment_to_insurer', event.target.files[0], formikRef, `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].voucher_proof_of_payment_doc`, installmentIndex)}
                                onRemove={async() => {setFieldValue(`stageData.flow${this.convertToWord(flowValue as number)}Data.voucher_proof_of_payment_doc`, {})
                                await this.removeFile(values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex as number]?.voucher_proof_of_payment_doc?.id)
                            
                            }}
                            />
                            <ErrorMessage
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${installmentIndex}].voucher_proof_of_payment_doc`}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                        </Grid>
                    </Grid></>
                }
            </>
        )
    }
}

const useStyles = ({
    fieldAlignment: {
        paddingRight: '25px'
    },
    btnGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: "30px",
        marginTop: "25px",
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    grayBtn: {
        "text-transform": 'none',
        padding: 15,
        borderRadius: 5,
        width: '100%',
        fontSize: 15,
        backgroundColor: "#979090",
        color: "white",
        marginTop: '8px',
    },
    primaryRvBtn: {
        "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": 'none',
        "padding": 15,
        "borderRadius": 5,
        "width": '100%',
        "fontSize": 15,
        "color": "white",
        "marginTop": '8px',
    }
});

export default withStyles(useStyles)(withTranslation()(InstallmentForm));