import React from "react";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    InputAdornment,
    CircularProgress
} from "@material-ui/core";
// @ts-ignore
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import StageSevenController from "../../../blocks/TaskAllocator/src/PolicyStage/StageSevenController";
import LabelWithIcon from "../Common/LabelWithIcon";
import TextInput from "../Common/TextInput";
import FileUpload from "../Common/FileUpload";
import CreateVoucherForm from "./StageSevenSiibForms/CreateVoucherForm";
import CommissionForm from "./StageSevenSiibForms/CommissionForm";
import InstallmentForm from "./StageSevenSiibForms/InstallmentForm";
import Toster from "../Common/Toster";
import { ErrorMessage } from "formik";
import DownloadFile from "../Common/DownloadFile";

export class StageSevenSiibForm extends StageSevenController {

    render() {
        const { t, classes, values, setFieldValue, flowValue, installmentData, formikRef, removeInstallmentData, errors, checkDisable } = this.props;
        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') ?? '{}')

        const dummyData = stageSixData?.attributes?.quotation_insurance_infos?.data?.filter((item: any) => {
            return item?.attributes?.approval === true
        });

        const quotationAmount = Number(dummyData[0]?.attributes?.quotation_amount)
        const stageSevenData = JSON.parse(window.localStorage.getItem('stageSevenData') ?? '{}')

        const checkRevisedSectionVisibleforFlow4=()=>{
           const {flowFourData}= values?.stageData
            return flowValue==4 && !!(values?.paid_amount &&flowFourData?.voucher_proof_of_payment_doc && flowFourData?.payment_date_2 && flowFourData?.voucher_payment_date && flowFourData?.payment_voucher_id)
        }
        const insurerCalculation = (values: any) => {
            switch (flowValue) {
                case 5:
                    return Number(values?.stageData?.flowFiveData?.payable_to_insurer);
                case 6:
                    return values?.stageData?.flowSixData?.payable_to_insurer;
                default:
                    return values?.paid_amount;
            }
        }
        const calculateBalance = (values: any) => {
            const dueAmount = quotationAmount - Number(values.paid_amount);
            const refundedAmount = this.state.showRefund ? Number(values.paid_amount) - quotationAmount : Number(0)
            switch (flowValue) {
                case 3:
                    return dueAmount;
                case 4:
                    return Number(values.paid_amount) - refundedAmount - quotationAmount;
                case 6:
                    if(this.state.showBalancebeforeRefund){
                        return Number(values.paid_amount) - quotationAmount
                    }
                   else if (!this.state.showRefund) {
                        return quotationAmount - Number(values?.stageData?.flowSixData?.commission_amount)
                    }
                    else {
                        return Number(values.paid_amount) - refundedAmount - quotationAmount;
                    }

                case 5: {
                        let paybleAmount = this.state.showPaidTo ? Number(values?.stageData?.flowFiveData?.payable_to_insurer) : 0
                        return quotationAmount - Number(values?.stageData?.flowFiveData?.commission_amount) - Number(paybleAmount);
                    }
                case 8:const isInstalmentPaid = values?.stageData?.[`flow${this.convertToWord(this.props.flowValue as number)}Data`]?.installment_attributes?.map((current: any) => {
                    if (current.showInstallmentAmount && current.installment_paid === 'yes') {
                        window.localStorage.setItem("InstallmentPaid", "true");
                    }
                    return current;
                });
                case 9:
                    const instalmentPaid = values?.stageData?.[`flow${this.convertToWord(this.props.flowValue as number)}Data`]?.installment_attributes?.reduce((accumulator: any, current: any) => {
                        if (current.showInstallmentAmount && current.installment_paid === 'yes') {
                            window.localStorage.setItem("InstallmentPaid","true");
                            accumulator += Number(current.installment_amount);
                        }
                        return accumulator;
                    }, 0) || 0

                    return dueAmount - Number(instalmentPaid);
                default:
                    return 0;
            }
        }

        const paymentMadeToInsurerFun = (values: any) => {
            const isQuotationAmountGreaterThanPaid = Number(dummyData[0].attributes.quotation_amount) > Number(values.paid_amount) && Number(values.paid_amount) > 0;
            const shouldDeductCommission = values.deduct_commission === 'yes';

            let content;

            if (isQuotationAmountGreaterThanPaid && shouldDeductCommission) {
                content = (
                    <React.Fragment>
                        <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                            <Typography>{t('down_payment')}</Typography>
                            <Typography>-SAR {Number(values.paid_amount) - Number(values.stageData?.flowNineData?.commission_amount)}</Typography>
                        </Grid>
                        {this.state.showInstallment && installmentData?.map((item: any, index: number) => (
                            <Grid key='instalment' item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                                <Typography>{index + 2}{this.getSuffix(index + 2)} {t('installment')}</Typography>
                                <Typography>-SAR {values?.stageData?.flowNineData?.installment_attributes?.[index]?.showInstallmentAmount ? Number(values?.stageData?.flowNineData?.installment_attributes?.[index]?.installment_amount) - Number(values?.stageData?.flowNineData?.installment_attributes?.[index]?.commission_amount) : 0} </Typography>
                            </Grid>
                        ))}
                    </React.Fragment>
                );
            } else if (isQuotationAmountGreaterThanPaid && !shouldDeductCommission) {
                content = null; // You can replace this with an appropriate JSX component or content
            } else {
                content = (
                    <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                        <Typography>{t("payment_made_to_insurer")}</Typography>
                        <Typography>-SAR {this.state.showPaidTo ||this.state.showBalancebeforeRefund ?insurerCalculation(values) : 0}</Typography>
                    </Grid>
                );
            }
            return content;
        }

        const currentFlow = this.getCurrentFlow(values.payment_reciepent, Number(values.paid_amount), Number(dummyData[0].attributes.quotation_amount), values.deduct_commission as any, this.formikRef)

        return (
            <React.Fragment>
                <Toster
                    tosterText={this.state.alertMessage}
                    showToster={this.state.showAlert}
                    // @ts-ignore
                    tosterType={this.state.alertType}
                    handleCloseToster={() => this.setState({ showAlert: false })}
                />
                {
                    values.deduct_commission === 'no' && <Grid item xs={12} container style={{ marginBottom: 20 }}>
                        <Grid style={{ paddingRight: '25px' }} item xs={4}>
                            <LabelWithIcon label={t('siib_receipt_number')} />
                            <TextInput
                                disabled={checkDisable}
                                id={"siib_receipt_number"}
                                className={classes.textInputStyle}
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.siib_receipt_number`}
                                type="text"
                                data-test-id="siib_receipt_number"
                                onBlur={() => this.checkStep(values, flowValue as number)}
                            />
                            <ErrorMessage
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.siib_receipt_number`}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                        </Grid>
                        <Grid style={{ paddingRight: '25px' }} item xs={4}>
                            <React.Fragment>
                                <LabelWithIcon label={t('payment_date')} />
                                <TextInput
                                    data-test-id={`payment_date`}
                                    disabled={checkDisable}
                                    id={`payment_date`}
                                    className={classes.textInputStyle}
                                    name={`stageData.flow${this.convertToWord(flowValue as number)}Data.payment_date`}
                                    type={"date"}
                                    onChange={(e: any) => setFieldValue(`stageData.flow${this.convertToWord(flowValue as number)}Data.payment_date`, e.target.value)}
                                    onBlur={() => this.checkStep(values, flowValue as number)}
                                />
                                <ErrorMessage
                                    name={`stageData.flow${this.convertToWord(flowValue as number)}Data.payment_date`}
                                    className="field-error"
                                    render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                            </React.Fragment>
                        </Grid>
                        <Grid item xs={4}>
                            <LabelWithIcon label={t('proof_of_payment')} />
                            <FileUpload
                                eachFileSizeInMB={10}
                                disabled={checkDisable}
                                maxFilesNumber={5}
                                fileName={values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.proof_of_payment_doc?.file_name || ''}
                                filePath={values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.proof_of_payment_doc?.url || ''}
                                data-test-id="proof_of_payment"
                                inputName={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.proof_of_payment_doc`}
                                onChange={(event: any) => this.uploadFileDoc('proof_of_payment', event.target.files[0], formikRef, `stageData.flow${this.convertToWord(flowValue as number)}Data.proof_of_payment_doc`)}
                                onRemove={async() => {setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.proof_of_payment_doc`, {})
                                await this.removeFile(values?.stageData && flowValue && values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.proof_of_payment_doc?.id)
                            
                            }}

                            />
                            <ErrorMessage
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.proof_of_payment_doc`}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                        </Grid>
                    </Grid>
                }
                {
                    values.deduct_commission === 'yes' && <CommissionForm setFieldValue={setFieldValue} values={values} flowValue={this.props.flowValue} formikRef={formikRef} isExpanded={this.props.isExpanded} checkStepProps={this.checkStep} checkDisable={checkDisable} />
                }
                {
                    values.deduct_commission === 'yes' && Number(dummyData[0].attributes.quotation_amount) < Number(values.paid_amount) && this.state.formPartTwo &&
                    <CreateVoucherForm setFieldValue={setFieldValue} dataIndex={1} flowValue={this.props.flowValue} formikRef={formikRef} values={values} isExpanded={this.props.isExpanded} checkStepProps={this.checkStep} updateDocProps={this.uploadFileDoc} dummyData={dummyData} checkDisable={checkDisable} />
                }
                <Grid item xs={12} container className={values.deduct_commission === 'yes' && classes.borderAlignment} style={{ marginBottom: 20 }}>
                    {values.deduct_commission === 'yes' && <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('payable_to_insurer')} />
                            <TextInput
                                data-test-id={`payable_to_insurer`}
                                id={`payable_to_insurer`}
                                className={classes.textInputStyle}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <div> &nbsp;SAR&nbsp;</div>
                                    </InputAdornment>
                                }
                                name={`stageData.flow${this.convertToWord(flowValue as number)}Data.payable_to_insurer`}
                                type={"text"}
                                onChange={(e: any) => setFieldValue(`stageData.flow${this.convertToWord(flowValue as number)}Data.payable_to_insurer`, e.target.value)}
                            />
                            <ErrorMessage
                                name={`stageData.flow${this.convertToWord(flowValue as number)}Data.payable_to_insurer`}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                        </React.Fragment>
                    </Grid>}
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('payment_voucher_date')} />
                            <TextInput
                                data-test-id={`payment_voucher_date`}
                                id={`payment_voucher_date`}
                                className={classes.textInputStyle}
                                disabled={checkDisable}
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_date_2`}
                                type={"date"}
                                onChange={(e: any) => setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_date_2`, e.target.value)}
                            />
                            <ErrorMessage
                                name={`stageData.flow${this.convertToWord(flowValue as number)}Data.payment_date_2`}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                        </React.Fragment>
                    </Grid>
                    <Grid item xs={4} container style={{ marginTop: 28, marginBottom: 9 }} >
                        <Button
                            data-test-id="mainCreateInvoiceBtn"
                            disabled={
                                this.state.voucherBtnLoading || (currentFlow === 6 || currentFlow === 5 || currentFlow === 9
                                    ? this.checkPaymentVoucherBtnDisabledForFlow5And6(values, flowValue)
                                    : this.checkBtnDisabled(values))
                            }
                            className={(currentFlow === 6 || currentFlow === 5 || currentFlow === 9 ? this.checkPaymentVoucherBtnDisabledForFlow5And6(values, flowValue) : this.checkBtnDisabled(values)) ? classes.grayBtn : classes.primaryRvBtn}
                            aria-haspopup="true"
                            aria-controls="customized-menu"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                currentFlow === 6 || currentFlow === 5
                                    ? this.generateCreatePaymentVoucherForFlow5And6(values, dummyData, formikRef, flowValue)
                                    : this.generateCreatePaymentVoucher(values, dummyData, formikRef);
                            }}
                        >
                            {!this.state.voucherBtnLoading && t('create_payment_voucher')}
                            {this.state.voucherBtnLoading && (<CircularProgress size={25} />)}
                        </Button>
                        <ErrorMessage
                                name={`stageData.flow${this.convertToWord(flowValue as number)}Data.payment_voucher_id`}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid item xs={4} style={{ paddingRight: '25px' }}>
                        <LabelWithIcon label={t('payment_voucher')} />
                        <DownloadFile
                            data-test-id="txtInputInvoiceDoc"
                            disabled={true}
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            fileName={
                                currentFlow === 6 || currentFlow === 5
                                    ? values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.payment_voucher_doc?.file_name || ""
                                    : values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.payment_voucher?.file_name || ""}
                            isEditPage={true}
                            filePath={
                                currentFlow === 6 || currentFlow === 5
                                    ? values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.payment_voucher_doc?.url || ""
                                    : values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.payment_voucher?.url || ""}
                            inputName={
                                currentFlow === 6 || currentFlow === 5
                                    ? `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_voucher_doc`
                                    : `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_voucher`}
                        />
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('payment_voucher_id')} />
                        <TextInput
                            className={classes.textInputStyle}
                            disabled={true}
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_voucher_id`}
                            type="text"
                            data-test-id="payment_voucher_id"
                        />

                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('insurer_receipt_number')} />
                        <TextInput
                            id={""}
                            className={classes.textInputStyle}
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.insurance_receipt_number`}
                            type="text"
                            disabled={checkDisable}
                            data-test-id="insurer_receipt_number"
                            onBlur={() => this.checkStep(values, flowValue as number)}
                        />
                        <ErrorMessage
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.insurance_receipt_number`}
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('payment_date')} />
                            <TextInput
                                data-test-id={`payment_date`}
                                disabled={checkDisable}
                                id={`payment_date`}
                                className={classes.textInputStyle}
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.voucher_payment_date`}
                                type={"date"}
                                onChange={(e: any) => setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.voucher_payment_date`, e.target.value)}
                                onBlur={() => this.checkStep(values, flowValue as number)}
                            />
                            <ErrorMessage
                                name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.voucher_payment_date`}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                        </React.Fragment>
                    </Grid>
                    <Grid item xs={4}>
                        <LabelWithIcon label={t('proof_of_payment')} />
                        <FileUpload
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            disabled={checkDisable}
                            fileName={values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.voucher_proof_of_payment_doc?.file_name || ''}
                            filePath={values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.voucher_proof_of_payment_doc?.url || ''}
                            data-test-id="proof_of_payment"
                            inputName={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.voucher_proof_of_payment_doc`}
                            onChange={(event: any) => this.uploadFileDoc('proof_of_payment_to_insurer', event.target.files[0], formikRef, `stageData.flow${this.convertToWord(flowValue as number)}Data.voucher_proof_of_payment_doc`)}
                            onRemove={async() => {setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.voucher_proof_of_payment_doc`, {})
                            await this.removeFile(values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.voucher_proof_of_payment_doc?.id)
                        
                        }}
                        />
                        <ErrorMessage
                            name={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.voucher_proof_of_payment_doc`}
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={Number(dummyData[0]?.attributes?.quotation_amount) < Number(values.paid_amount) && values.deduct_commission === 'no' ? classes.voucherBorder : ""}>
                    <LabelWithIcon label={t('policy_release_requested')} />
                    <FormControlLabel
                        color="primary"
                        name={`stageData.flow${this.convertToWord(flowValue as number)}Data.policy_release_requested`}
                        control={<Checkbox color="primary"
                            disabled={checkDisable}
                            checked={values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.policy_release_requested === "yes"}
                            onChange={(e: any) => {
                                setFieldValue(`stageData.flow${this.convertToWord(flowValue as number)}Data.policy_release_requested`, e.target.checked ? "yes" : "no")
                            }
                            }
                        />
                        }

                        label={t('yes')}
                    />

                </Grid>
                {
                    Number(dummyData[0]?.attributes?.quotation_amount) > Number(values.paid_amount) && Number(values.paid_amount) > 0 && this.state.showInstallment && installmentData?.map((item: any, index: number) => <div key='installment' className={errors?.stageData?.[`flow${this.convertToWord(this.props.flowValue as number)}Data`]?.installment_attributes?.[index] !== undefined ? classes.installmentErrorAlignment : classes.installmentAlignment} style={{ marginTop: 25 }}>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ fontWeight: 400 }}>{index + 2}{this.getSuffix(index + 2)} instalment</Typography>
                            {((!stageSixData?.attributes?.is_next_stage_submitted) || (stageSevenData?.attributes?.save_as_draft)) && <Button
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                variant="contained"
                                color="primary"
                                style={{ backgroundColor: "white", color: "#007E98", boxShadow: "none" }}
                                onClick={() => removeInstallmentData(index)}
                            >
                                <CloseIcon />
                            </Button>}
                        </Grid>
                        <InstallmentForm installmentIndex={index} flowValue={this.props.flowValue} paymentRecipient={"SIIB"} setFieldValue={setFieldValue} values={values} isExpanded={this.props.isExpanded} formikRef={formikRef} stageSixData={stageSixData} checkStepProps={this.checkStep} dummyData={dummyData} checkDisable={checkDisable}/>
                    </div>)
                }
                {Number(dummyData[0].attributes.quotation_amount) < Number(values.paid_amount) && this.state.formPartTwo && (checkRevisedSectionVisibleforFlow4() ||flowValue==6) &&
                  <CreateVoucherForm setFieldValue={setFieldValue} dataIndex={0} flowValue={this.props.flowValue} formikRef={formikRef} values={values} isExpanded={this.props.isExpanded} checkStepProps={this.checkStep} updateDocProps={this.uploadFileDoc} dummyData={dummyData} checkDisable={checkDisable} />
                }
                <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 115 }}>
                    <Typography>{t("quotation_amount")}</Typography>
                    <Typography>SAR {dummyData[0].attributes.quotation_amount}</Typography>
                </Grid>
                {values.deduct_commission === 'yes' && Number(dummyData[0].attributes.quotation_amount) > Number(values.paid_amount) &&
                    <React.Fragment>
                        <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                            <Typography>{t("revenue_siib_commission_from")} {dummyData[0].attributes.insurance_company_id.short_name} for {t('down_payment')}</Typography>
                            <Typography> {this.state.showRevenue ? "-SAR " + values?.stageData?.flowNineData?.commission_amount : "Create invoice & reciept first"}</Typography>
                        </Grid>
                        {this.state.showInstallment && installmentData?.map((item: any, index: number) => <Grid key='instalment' item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                            <Typography>{t("revenue_siib_commission_from")} {dummyData[0].attributes.insurance_company_id.short_name} for {index + 2}{this.getSuffix(index + 2)} instalment</Typography>
                            <Typography> {values?.stageData?.flowNineData?.installment_attributes?.[index]?.showInstallmentAmount ? "-SAR " + values?.stageData?.flowNineData?.installment_attributes?.[index]?.commission_amount : "Create invoice & reciept first"}</Typography>
                        </Grid>)}
                    </React.Fragment>
                }
                {values.deduct_commission === 'yes' && Number(dummyData[0].attributes.quotation_amount) <= Number(values.paid_amount) &&
                    <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                        <Typography>{t("revenue_siib_commission_from")} {dummyData[0].attributes.insurance_company_id.short_name}</Typography>
                        <Typography> {flowValue === 5 && this.state.showRevenue ? "-SAR " + values?.stageData?.flowFiveData?.commission_amount : flowValue === 6 && this.state.showRevenue ? "-SAR " + values?.stageData?.flowSixData?.commission_amount : "Create invoice & reciept first"}</Typography>
                    </Grid>
                }
                {values.deduct_commission === 'yes' && Number(dummyData[0].attributes.quotation_amount) < Number(values.paid_amount) && this.state.showRefund && <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                 
                    <Typography>{t("refund_siib_commission_to")} {dummyData[0].attributes.insurance_company_id.short_name}</Typography>
                    {values?.stageData?.flowSixData?.vat_on_siib_commission=="yes"?<Typography>SAR {(((Number(values?.paid_amount) - quotationAmount) * Number(values?.stageData?.flowSixData?.commission_rate)) / 100)}</Typography>:
                    <Typography>SAR {((((Number(values?.paid_amount) - quotationAmount) /1.15) * Number(values?.stageData?.flowSixData?.commission_rate))/100).toFixed(2)}</Typography>}
                </Grid>}
                {(Number(dummyData[0].attributes.quotation_amount) > Number(values.paid_amount) && Number(values.paid_amount) > 0) && values.deduct_commission !== 'yes' &&
                    <>
                        <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                            <Typography>{t('down_payment')}</Typography>
                            <Typography>-SAR {this.state.showRevenue ? values.paid_amount : 0}</Typography>
                        </Grid>
                        {this.state.showInstallment &&
                            installmentData?.map((item: any, index: number) => <Grid key='instalment' item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20, marginBottom: 15 }}>
                                <Typography>{index + 2}{this.getSuffix(index + 2)} {t('installment')}</Typography>
                                <Typography>-SAR {values?.stageData?.flowEightData?.installment_attributes[index]?.showInstallmentAmount ? values?.stageData?.flowEightData?.installment_attributes[index]?.installment_amount : 0}</Typography>
                            </Grid>)
                        }
                    </>
                }
                {paymentMadeToInsurerFun(values)}
                {
                    Number(dummyData[0].attributes.quotation_amount) < Number(values.paid_amount) && values.deduct_commission === 'no' && this.state.showRefund && <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                        <Typography>{t("refunded_to_customer")}</Typography>
                        <Typography>SAR {Number(values.paid_amount) - quotationAmount}</Typography>
                    </Grid>
                }
                {
                    Number(dummyData[0].attributes.quotation_amount) < Number(values.paid_amount) && values.deduct_commission !== 'no' && this.state.showRefund && <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, marginTop: 20 }}>
                        <Typography>{t("refunded_to_customer")}</Typography>
                        <Typography>SAR {Number(values.paid_amount) - quotationAmount}</Typography>
                    </Grid>
                }
                <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 7, paddingTop: 15, borderTop: '3px solid #eaeaea' }}>
                    <Typography>{t("balance")}</Typography>
                    <Typography>{(this.state.showBalance && flowValue === 4 && calculateBalance(values) != 0) || this.state.showBalancebeforeRefund && '-'}SAR {this.state.showBalance ? calculateBalance(values).toFixed(2) : quotationAmount.toFixed(2)} </Typography>
                </Grid>
            </React.Fragment>
        )
    }
}

const useStyles = ({
    displayFlex: {
        display: "flex",
    },
    contactForm: { width: "100%" },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    voucherBorder: {
        paddingBottom: "25px",
        borderBottom: '3px solid #eaeaea'
    },
    borderAlignment: {
        paddingTop: "25px",
        borderTop: '3px solid #eaeaea'
    },
    installmentAlignment: {
        border: "3px solid #eaeaea",
        borderRadius: "10px",
        padding: "20px",
    },
    installmentErrorAlignment: {
        border: "1px solid #f44336",
        borderRadius: "10px",
        padding: "20px",
    },
    grayBtn: {
        "text-transform": 'none',
        padding: 15,
        borderRadius: 5,
        width: '100%',
        fontSize: 15,
        backgroundColor: "#979090",
        color: "white",
        marginTop: '8px',
    },
    primaryRvBtn: {
        "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": 'none',
        "padding": 15,
        "borderRadius": 5,
        "width": '100%',
        "fontSize": 15,
        "color": "white",
        "marginTop": '8px',
    }
});

export default withStyles(useStyles)(withTranslation()(StageSevenSiibForm));