Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Reset Your password";
exports.labelBodyText = "get your password reset link send to your registered email by clicking below";
exports.resetPassword = "Reset Password";
exports.mockApiRepsonseLoadUser ={
    data:{
      attributes:{
        activated: true,
        contacts: null,
        country_code: null,
        created_at: "2023-03-03T06:37:50.291Z",
        email: "test@insurencebroker.com",
        first_name: "user",
        full_phone_number: "999999999",
        job_title: "testjobtitle",
        last_name: "test",
        phone_number: null,
        role: "Admin",
        status: "invite_sent",
        updated_at: "2023-03-27T09:16:54.424Z"
      },
      id: "1",
      type: "account"
    }

};
exports.mockApiRepsonseResetPassword = {
    id: 50,
    type: "email_otp",
    meta: {
      token: "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NTAsImV4cCI6MTY3OTkxNTkyMSwidHlwZSI6IkFjY291bnRCbG9jazo6RW1haWxPdHAiLCJhY2NvdW50X2lkIjozNX0._rCOrpGww5DkOtQ6SFhqnxBas4AH0jX1s2vuCIzylJm4sjw5aptfYatco0QHi7TJhJhUekeWOKeXMt2_zO1ccg"
    }  
};
exports.mockApiLoadUserError = [
  {
    "token": "Invalid token"
  }
];
exports.mockApiResetpasswordError = [ {
  "otp": "Email required"
}]


exports.resetPasswordSuccess = "We have sent a password reset link to your email address."

// Customizable Area End