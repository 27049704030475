// Customizable Area Start
import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, Grid } from '@material-ui/core'
import LabelWithIcon from './LabelWithIcon';
import { Close } from '@material-ui/icons';
import Select, { components } from 'react-select';
import { useTranslation } from "react-i18next";


const useStyles = makeStyles({
    dialogWrapper: {
        "& [role='dialog']": {
            padding: '30px',
            borderRadius: '16px',
            width: '800px',
            height: "600px"
        }
    },
    headingStyle: {
        textAlign: 'center',
        '& h2': {
            fontWeight: 700,

        },
        fontSize: "16px"
    },
    paraStyle: {
        textAlign: 'center',
    },
    btnWrapper: {
        justifyContent: 'end',
        // '& button': {
        //     padding: '15px 0px',
        //     width: '150px',
        //     borderColor: '#3b8da2',
        //     borderRadius: '15px',
        // }
    },
    primaryBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        textTransform: "none",
        color: "white",
        borderColor: '#3b8da2',
        padding: '10px 0px',
        width: '150px',
        borderRadius: "8px"
    },
    cancelBtnStyle: {
        color: '#757575', borderRadius: "8px", textTransform:"none", borderColor:"#757575", padding: '10px 0px', width: '150px',
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    dialogTitle: {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subHeading: {
        fontSize: "16px",
        margin: "0 4px"
    },
    confirmBtn: {
        borderRadius: '8px',
    },
    disablePrimaryBtn: {
        color: "#fff",
        borderRadius: '8px',
        background: '#757575',
        textTransform: "none",
        borderColor: '#3b8da2',
        padding: '10px 0px',
        width: '150px',
    },
    downloaIcon: {
        cursor: "pointer"
    },
    clearallBtn: {
        margin: "0px 10px",
        color: '#757575',
    },
    selectStyle: {
        '& .css-3w2yfm-ValueContainer > div': {
            backgroundColor: '#cbdeec',
        },
        '$ .css-1p3m7a8-multiValue':{
            backgroundColor: '#cbdeec !important',
        },
        '& .css-3w2yfm-ValueContainer > div:last-child': {
            backgroundColor: '#fff',
        }
    },
    selectError: {
        '& > div': {
            // height: '56px',
            borderColor: 'red',
            backgroundColor: '#fff',
        }
    },
    selectRemoveicon: {
        '& [role="button"]': {
            display: 'none'
        }
    },
    checkboxItem: {
        display: "flex", alignItems: "center", margin: "10px 0px"
    },
    checkboxContainer: {
        display: "flex", flexWrap: "wrap"
    },
    checkboxLabel: {
        margin: "0px 5px"
    },

    labelStyle: {
        display: 'flex',
        fontSize: '14px'
    },

})

//


type MultiSelectProps = {
    selectOptions: any,
    selectValue: any,
    disable?: boolean,
    showError?: boolean,
    eventHandler?: (selected: any) => void,
}

type RequestFilterDialog = {
    openDialog: boolean,
    headingText: string,
    btnCancelText: string,
    btnfilterText: string,
    handleFilter: any;
    handleCancel: any;
    showfilterDialog:boolean;
    customerList: any;
    insurerList?: any;
    showCrossBtn?:boolean;
    showDialogBtns?: boolean;
    insurer?: boolean;
    operationList?: any;
}


type OptionsType = {
     value: string;
     label: string;
}[]

export const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    isHeader,
    ...rest
}: any) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex "
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style,
        isHeader
    };

    return (
        <>
        {/* <components.GroupHeading {...props} /> */}
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
            >
            <input type="checkbox" checked={isSelected} />
            {children}
        </components.Option>
        </>
    );
};

export const GroupHeading = (
    props: any
  ) => (
    <components.GroupHeading {...props} />
  );

const RequestFilterDialog = (props: RequestFilterDialog) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [note, setNote] = useState("");
    const [customerSelected, setcustomerSelected] = useState<OptionsType>([])
    const [insurerSelected, setInsurerSelected] = useState<OptionsType>([])
    const [operationSelected, setOperationSelected] = useState<OptionsType>([])
    const [customerOptions, setcustomerOptions] = useState<any>([])
    const [insurerOptions, setInsurerOptions] = useState<any>([])
    const [operatioOptions, setOperationOptions] = useState<any>([])
    const [insuranceSelected, setinsuranceSelected] = useState<string[]>([])
    const [requestSelected, setRequestSelected] = useState<string[]>([])

    useEffect(() => {
        if (Array.isArray(props.customerList)) {
            setcustomerSelected([])
            setcustomerOptions(props.customerList.map((opt: any) => {
                return {
                    value: opt?.attributes?.id,
                    label: opt?.attributes?.name?.toLowerCase()
                        .split(' ')
                        .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' '),
                }
            }))
        }

        if (Array.isArray(props.insurerList)) {
            setInsurerSelected([])
            setInsurerOptions(props.insurerList.map((opt: any) => {
                return {
                    value: props?.insurer ? opt?.attributes?.id : opt?.id,
                    label: (props?.insurer ? opt?.attributes?.legal_name : (opt?.attributes?.first_name + " " + opt?.attributes?.last_name))?.toLowerCase()
                        .split(' ')
                        .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' '),
                }
            }))
        }

        if (Array.isArray(props.operationList)) {
            setOperationSelected([])
            setOperationOptions(props.operationList.map((opt: any) => {
                return {
                    value: opt?.id,
                    label: opt?.attributes?.first_name?.toLowerCase()
                        .split(' ')
                        .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(' '),
                }
            }))
        }

    }, [props.customerList, props.insurerList, props.operationList])


    const insuranceTypeOption = [
        { value: "medical", label: t('medical') },
        { value: "motor", label: t('motor') },
        { value: "life", label: t('life') },
        { value: "engineering", label: t('engineering') },
        { value: "marine", label: t('marine') },
        { value: "property", label: t('property') },
        { value: "fire", label: t('fire') },
        { value: "aviation", label: t('aviation') },
        { value: "energy", label: t('energy') },
        { value: "protection & saving", label: t('protection_and_saving') },
        { value: "general", label: t('general') },
    ]

    const requestType = [
        { value: "new policy", label: t('new_policy') },
        { value: "add", label: t('add') },
        { value: "remove", label: t('remove') },
        { value: "correct", label: t('correct') },
        { value: "upgrade", label: t('upgrade') },
        { value: "cancel", label: t('cancel') },
    ]

    const expiryLimit = [
        { value: "30", label: t('30_days') },
        { value: "60", label: t('60_days') },
        { value: "90", label: t('90_days') }
    ]

    const handlerSelect = (option: any) => {
        if (Array.isArray(option)) {
            setcustomerSelected(option);
        }
    }

    const handlerInsurer = (option: any) => {
        if (Array.isArray(option)) {
            setInsurerSelected(option);
        }
    }

    const handlerOperations = (option: any) => {
        if (Array.isArray(option)) {
            setOperationSelected(option);
        }
    }

    const handlerInsuranceSelect = (event:any, items:any) => {
        if(event.target.checked){
            setinsuranceSelected([...insuranceSelected, items.value])
        }
        else{
            setinsuranceSelected(insuranceSelected.filter((option:any) => option !== items.value))
        }
    }

    const handlerRequestSelect = (event:any, items:any) => {
        if(event.target.checked){
            setRequestSelected([...requestSelected, items.value])
        }
        else{
            setRequestSelected(requestSelected.filter((option:any) => option !== items.value))
        }
    }



    return (
        <>

            <Dialog
                open={props?.openDialog}
                onClose={props?.handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                className={classes.dialogWrapper}
            >
                <DialogTitle id="alert-dialog-title">
                    <div className={classes.dialogTitle}>
                        <div>
                            <span className={classes.headingStyle}>{props?.headingText}</span>
                        </div>
                        {props.showCrossBtn ? (<div style={{ display: "flex" }} onClick={() => {
                            setinsuranceSelected([])
                            setRequestSelected([])
                            setcustomerSelected([])
                            setInsurerSelected([])
                            setOperationSelected([])
                            props.handleFilter([], [], [], [], [])
                            props?.handleCancel
                        }}
                            className={classes.downloaIcon}>
                                <span className={classes.clearallBtn}>{t('clear_all')}</span>
                                <Close className={classes.clearallBtn} />
                        </div>) : <div></div>}
                    </div>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>

                            <LabelWithIcon label={t('customer')} />
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                onChange={(opt) => handlerSelect(opt)}
                                options={customerOptions}
                                value={customerSelected}
                                components={{
                                    Option: InputOption,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <LabelWithIcon label={props.insurer ? t('insurer') : t('users')} />
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                onChange={(opt) => handlerInsurer(opt)}
                                options={insurerOptions}
                                value={insurerSelected}
                                components={{
                                    Option: InputOption,
                                }}
                            />
                        </Grid>
                        {
                            props.insurer && <Grid item xs={12}>
                            <LabelWithIcon label={t('operations')} />
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                onChange={(opt) => handlerOperations(opt)}
                                options={operatioOptions}
                                value={operationSelected}
                                components={{
                                    Option: InputOption,
                                }}
                            />
                        </Grid>
                        }
                        <Grid item xs={12}>
                            <label className={classes.labelStyle} aria-controls="cutomerName" >{t('insurance_type')}</label>
                            <div className={classes.checkboxContainer}>
                                {insuranceTypeOption.map((item) => {
                                    return (
                                        <div className={classes.checkboxItem}>
                                            <input
                                                type="checkbox"
                                                className={classes.checkboxLabel}
                                                onChange={(event) => handlerInsuranceSelect(event, item)}
                                                checked={insuranceSelected.includes(item.value)}
                                                value={insuranceSelected}
                                            />{" "}
                                            <label className={classes.checkboxLabel}>{item.label}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <label className={classes.labelStyle}>{props.insurer ? t('policy_expiration_date') : t('request_type')}</label>
                            <div className={classes.checkboxContainer}>
                                {(props.insurer ? expiryLimit : requestType).map((item) => {
                                    return (
                                        <div className={classes.checkboxItem}>
                                            <input
                                                type="checkbox"
                                                className={classes.checkboxLabel}
                                                onChange={(event) => handlerRequestSelect(event, item)}
                                                value={requestSelected}
                                                checked={requestSelected.includes(item.value)}
                                            />{" "}
                                            <label className={classes.checkboxLabel}>{item.label}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </Grid>

                        {/* </Grid> */}

                    </Grid>
                </DialogContent>
                {props.showDialogBtns && <DialogActions className={classes.btnWrapper}>
                    <Button variant="outlined"
                        className={classes.cancelBtnStyle}
                        onClick={props?.handleCancel}>
                        {props.btnCancelText}
                    </Button>
                    <Button variant="contained"
                        style={{ borderRadius: "8px" }}
                        className={classes.primaryBtnStyle}
                        onClick={() => props.handleFilter(customerSelected, insurerSelected, operationSelected, insuranceSelected, requestSelected)} color="primary" autoFocus
                        >
                        {props.btnfilterText}
                    </Button>
                </DialogActions>}
            </Dialog>
        </>
    )
}

export default RequestFilterDialog
// Customizable Area End