import React from 'react'
import { makeStyles } from '@material-ui/core'
import Select from 'react-select'
import LabelWithIcon from './LabelWithIcon'
import { StylesConfig } from 'react-select'

const useStyles = makeStyles({

    selectError: {
        '& > div': {
            // height: '56px',
            borderColor: 'red',
            backgroundColor: '#fff',
        }
    },
    selectRemoveicon: {
        '& [role="button"]': {
            display: 'none'
        }
    }
})

type MultiSelectProps = {
    selectOptions: any,
    selectValue: any,
    disable?: boolean,
    showError?: boolean,
    eventHandler?: (selected: any) => void,
}

const colourStyles: StylesConfig = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', marginTop: "10px", marginBottom: "10px", height: '55px' }),
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: "#cbdeec",
        };
    },
};

const MultiSelect = (props: MultiSelectProps) => {
    const classes = useStyles();

    return (
        <div>
            {/* @ts-ignore */}
            <Select
                isMulti
                styles={colourStyles}
                options={props.selectOptions}
                value={props.selectValue}
                onChange={props.eventHandler}
                isDisabled={props.disable || false}
                className={`${props?.showError ? classes.selectError : ''} ${props.disable ? classes.selectRemoveicon : ''}`}
            />
        </div>
    )
}

export default MultiSelect