// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getLoggedUserRole, isTokenExpire,getLoggedUserToken } from "../../../components/src/Common/GetLoggedUserData";
const configJson=require("./config.js")
import ActiveLanguage from '../../../components/src/Common/ActiveLanguage';

// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation?: any;
    id?: string;
    t?: any;
    classes?: any;
    // Customizable Area End
}
export interface OverduePayment {
    customer_name: string;
    balance: number;
}
interface Attachment {
    id: number;
    file_name: string;
    url: string;
}

interface Customer {
    id: number;
    name: string;
}

interface AssignedBy {
    id: number;
    name: string;
}

interface TaskAttributes {
    id: number;
    task_name: string;
    description: string;
    created_at: string;
    assignee_type: string;
    completed: boolean;
    assignee: string | { id: number; name: string };
    attachments: Attachment[] | string;
    customer_id: Customer;
    assigned_by: AssignedBy;
}

interface Task {
    id: string;
    type: string;
    attributes: TaskAttributes;
}
interface S {
    // Customizable Area Start
    showAlert: boolean;
    alertMessage: string;
    alertType: string;
    isLoading: boolean;
    // Request Table variables
    targetGraphLoading: boolean;
    targetGraphOptionData: any;
    targetFetched: boolean;
    selectedGraphOption: string;
    requestTablePage: number,
    taskTablePage: number,
    requestTableTotalPage: number,
    taskTableTotalPage: number,
    requestTableList: any[],
    requestTableLoading: boolean,
    requestTableListMeta: any
    taskTableListMeta: any;
    showCompletedTask: string;
    activeFilterTab:number
    requestTableRowPerPage: number
    taskTableRowPerPage: number
    targetGraphData: {
        personalData: {
            title: string;
            total: number;
            completed: number;
            completed_per: number;
        }[],
        departmentalData: {
            title: string;
            total: number;
            completed: number;
            completed_per: number;
        }[],
    }
    showTaskDialog: boolean;
    taskId: string | number;
    tasksList:Task[]
    overDuePlaymentList:OverduePayment[]
    overDuePaymentPage:number;
    overdueTableRowPerPage:number;
    overdueTableListMeta:any;
    taskTableLoading: boolean;
    isOverdueLoading:boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

// Customizable Area Start
interface DepartmentTarget {
    title: string;
    total: number;
    completed: number;
    completed_per: number;
}
interface TargetDataFromApi {
    metric: string;
    target: number;
    target_achieved: number;
    completed_percentage: number;
}
interface MyTargetData {
    personalData: DepartmentTarget[];
    departmentalData: DepartmentTarget[];
}
// Customizable Area End
export default class DashboardWebController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAllRequestRecordsApi: string = "";
    getAllTaskListApi: string = "";
    targetGraphTimePeriodApi: string = "";
    getTargetRecordsApi: string = "";
    getAllOverDuePaymentsList:string="";
    deleteTaskApi:string="";

    // Customizable Area End

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        this.state = {
            showAlert: false,
            overDuePlaymentList:[],
            alertMessage: "",
            alertType: "",
            isLoading: false,
            // RequestTable
            requestTablePage: 1,
            taskTablePage: 1,
            requestTableTotalPage: 1,
            taskTableTotalPage: 1,
            requestTableList: [],
            requestTableLoading: false,
            requestTableListMeta: {},
            taskTableListMeta: {},
            requestTableRowPerPage: 5,
            tasksList: [],
            targetGraphOptionData: {},
            targetGraphLoading: false,
            targetFetched: false,
            selectedGraphOption:"",
            taskTableRowPerPage: 5,
            activeFilterTab:0,
            taskTableLoading:false,
            showCompletedTask: "no",
            targetGraphData: {
                personalData: [],
                departmentalData: []
            },
            showTaskDialog: false,
            taskId: '',
            overDuePaymentPage:1,
            overdueTableRowPerPage:5,
            overdueTableListMeta:{},
            isOverdueLoading:false
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }


    // API LOGIC START
    apiCallForDashBoardWebPage(data: any) {
        const { contentType, method, endPoint, body } = data;
        const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
        const token = userData.token || "";

        const header = {
            "Content-Type": contentType,
            token: token,
        };

        const requestMessageForAnayticsListingQuery = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessageForAnayticsListingQuery.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessageForAnayticsListingQuery.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessageForAnayticsListingQuery.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessageForAnayticsListingQuery.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );

        runEngine.sendMessage(requestMessageForAnayticsListingQuery.id, requestMessageForAnayticsListingQuery);
        return requestMessageForAnayticsListingQuery.messageId;
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.getAllRequestRecordsApiReceive(message)
        this.getAllTasksListApiReceive(message)
        this.targetGraphTimePeriodApiReceive(message)
        this.getTargetRecordsApiReceive(message);
        this.getAllOverDuePaymentsListApiReceive(message)
        this.deleteTaskApiReceive(message)
        // Customizable Area End
    }
    handErrorMessageForDashboard = (responseJson: any) => {
        if (!responseJson) return;
        let errorMessage = "Something went wrong"
        if (responseJson?.error ||responseJson.errors) {
            errorMessage = responseJson.error?responseJson.error : responseJson.errors || "Something went wrong";
        }
        this.setState({
            alertType: "error",
            alertMessage: errorMessage,
            showAlert: true,
        })
    };

    // API LOGIC ENDS

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
           await isTokenExpire()
            this.checkAlertMesssage()
            this.getAllRequestRecords(this.state.requestTablePage, this.state.requestTableRowPerPage);
            this.getTargetTimeData();
            this.getAllRequestRecords(this.state.requestTablePage,this.state.requestTableRowPerPage);
            this.getAllTasksList("",this.state.taskTablePage,this.state.taskTableRowPerPage)
            getLoggedUserRole() === "Sales" && this.AllOverDuePaymentsList(this.state.overDuePaymentPage,this.state.overdueTableRowPerPage)
        

        // Customizable Area End
    }

    // Customizable Area Start
    componentDidUpdate() {
        if (this.state.showAlert) {
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertType: "",
                    alertMessage: "",
                });
            }, 10000);
        }
    }


    //   REQUEST TABLE LOGIC START
    handleRequestTablePageChange = (event: any, page: number) => {
        this.setState({ requestTablePage: page });
        this.getAllRequestRecords(page, this.state.requestTableRowPerPage);
    }
    handleRequestTableRow = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            requestTableRowPerPage: Number(event.target.value),
            requestTablePage: 1
        });
        this.getAllRequestRecords(1, Number(event.target.value))
    };
    getAllRequestRecords = (page: number, perPage: number) => {

        this.setState({ requestTableLoading: true })

        this.getAllRequestRecordsApi = this.apiCallForDashBoardWebPage({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests?page=${page}&per_page=${perPage}&options=Priority`,
            token: getLoggedUserToken()
        })
    }
    getAllRequestRecordsApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAllRequestRecordsApi != null &&
            this.getAllRequestRecordsApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    isLoading: false,
                    requestTableList: responseJson.data,
                    requestTableListMeta: responseJson.meta,
                })
            } else {
                this.handErrorMessageForDashboard(responseJson);
                this.setState({ isLoading: false });
            }
        }
    }
    //   REQUEST TABLE LOGIC END
handleTabChange=(e:  React.ChangeEvent<{}>, value: number) => {
   const filter= this.getFilterKeyByIndex(value)
    this.setState({
      activeFilterTab: value,
      taskTablePage:1,
      taskTableRowPerPage:5
    },()=>this.getAllTasksList(filter,1,5));
  };
    checkAlertMesssage = () => {
        const alertDataDashBoard = JSON.parse(localStorage.getItem("alertDetail") || "{}");
        if (alertDataDashBoard.alertType){
          this.setState({
            showAlert: true,
            alertType: alertDataDashBoard.alertType,
            alertMessage: ActiveLanguage() ? alertDataDashBoard.arabicMessage : alertDataDashBoard.englishMessage
          })
        }
    }
    //   TARGET FEATURE LOGIC START
    filterTask = (value:string) => {
     this.setState({selectedGraphOption: String(value)});
     this.getTargetRecords(value);
    }
    getTargetTimeData = () => {

        this.setState({ targetGraphLoading: true })
        this.targetGraphTimePeriodApi = this.apiCallForDashBoardWebPage({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_dashboard/time_periods`,
            token: getLoggedUserToken()
        })
    }
    getTargetRecords = (id?:string) => {

        this.setState({ targetGraphLoading: true })
        this.getTargetRecordsApi = this.apiCallForDashBoardWebPage({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_visual_analytics/targets?time_period_id=${id ? id : this.state.selectedGraphOption}`,
            token: getLoggedUserToken()
        })
    }
    targetGraphTimePeriodApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.targetGraphTimePeriodApi != null &&
            this.targetGraphTimePeriodApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    targetGraphLoading: false,
                    targetGraphOptionData: responseJson.data,
                    selectedGraphOption: responseJson.data.default?.id || ""
                })
                if (!this.state.targetFetched && responseJson.data.default?.id) {
                    this.getTargetRecords();
                    this.setState({
                        targetFetched: true
                    })
                }
            } else {
                this.handErrorMessageForDashboard(responseJson);
                this.setState({ targetGraphLoading: false });
            }
        }
    }
    getTargetRecordsApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getTargetRecordsApi != null &&
            this.getTargetRecordsApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                let myTargetData: MyTargetData = {
                    personalData: [],
                    departmentalData: []
                };
                const personalData = responseJson.data.personal;
                const departmentData = responseJson.data.departmental;

                const fillTargetData = (targetData: TargetDataFromApi[]): DepartmentTarget[] => {
                    const newArray: DepartmentTarget[] = [];
                    targetData.forEach((obj: TargetDataFromApi) => {
                        newArray.push({
                            title: obj.metric,
                            total: obj.target,
                            completed: obj.target_achieved,
                            completed_per: obj.completed_percentage
                        });
                    });
                    return newArray;
                };


                if (personalData.length > 0) {
                    myTargetData.personalData = fillTargetData(personalData);
                }
                if (departmentData.length > 0) {
                    myTargetData.departmentalData = fillTargetData(departmentData);
                }
                this.setState({targetGraphData:myTargetData})


            } else {
                this.handErrorMessageForDashboard(responseJson);
                this.setState({ targetGraphLoading: false });
            }
        }
    }
    //   TARGET FEATURE LOGIC END

    //TASK FEATURE LOGIC START
    getFilterKeyByIndex(index: string | number) {
        const filter = configJson.ButtonNames.find((filter: any) => filter.filterIndex === index.toString());
        return filter ? filter.filterKey : "";
    }
    handleTaskTablePageChange = (event: any, page: number) => {
        this.setState({ taskTablePage: page });
        this.getAllTasksList("",page,this.state.taskTableRowPerPage);
    }
    handleTaskTableRow = (event: any) => {
        this.setState({
            taskTableRowPerPage: Number(event.target.value),
            taskTablePage:1
        });
        this.getAllTasksList("",1,Number(event.target.value))
    };
    getCompleteTasks = () => {
        this.setState({
            showCompletedTask:  this.state.showCompletedTask === "no" ? "yes" : "no",
            taskTablePage:1,
            taskTableRowPerPage:5
        })
        this.getAllTasksList(this.state.showCompletedTask === "no" ? "completed_task" : "",1,5)
    }
    getAllTasksList = (filterKey?:string,page?:number,perPage?:number) => {

        this.setState({ taskTableLoading: true })
        let updatedEndPoint = ''
        if (filterKey && filterKey !== "") {
            if (filterKey === "completed_task") updatedEndPoint = `&completed_task=yes`
            else updatedEndPoint = `&filter=${filterKey}`
        }

        this.getAllTaskListApi = this.apiCallForDashBoardWebPage({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `/bx_block_tasks/tasks?page=${page}&per_page=${perPage}`+updatedEndPoint,
            token: getLoggedUserToken()
        })
    }
    getAllTasksListApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAllTaskListApi != null &&
            this.getAllTaskListApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    taskTableLoading: false,
                    tasksList: responseJson.data,
                    taskTableListMeta: responseJson.meta
                })
            } else {
                this.handErrorMessageForDashboard(responseJson);
                this.setState({ taskTableLoading: false });
            }
        }
    }

    handleTaskDialog = () => {
        this.setState({ showTaskDialog: !this.state.showTaskDialog });
    };

    handleTaskIdDialog = (taskId: string | number, taskDialog: boolean = true) => {
        this.setState({ taskId: taskId }, () => {
            this.setState({ showTaskDialog: taskDialog });
        })
        this.getAllTasksList("",this.state.taskTablePage,this.state.taskTableRowPerPage)
    };

    getTaskList = () => {
        this.getAllTasksList("", this.state.taskTablePage, this.state.taskTableRowPerPage)
    }

    deleteTaskData = (id: string | number) => {
        this.setState({ targetGraphLoading: true })
        this.deleteTaskApi = this.apiCallForDashBoardWebPage({
            contentType: 'application/json',
            method: 'DELETE',
            endPoint: `/bx_block_tasks/tasks/${id}`,
            token: getLoggedUserToken()
        })
    }
    deleteTaskApiReceive = (message: Message) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.deleteTaskApi != null &&
            this.deleteTaskApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData( getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (responseJson.message) {
                this.setState({
                    taskTableLoading: false,
                    showAlert: true,
                    alertMessage: ActiveLanguage() ? "تم حذف المهمة" : "Task deleted",
                    alertType: "success"
                });
                this.getAllTasksList('', this.state.taskTablePage, this.state.taskTableRowPerPage);
            } else {
                this.handErrorMessageForDashboard(responseJson);
                this.setState({ taskTableLoading: false });
            }
        }
    }
    //TASK FEATURE LOGIC END

    getAllOverDuePaymentsListApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAllOverDuePaymentsList != null &&
            this.getAllOverDuePaymentsList ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    isOverdueLoading: false,
                    overDuePlaymentList: responseJson.data,
                    overdueTableListMeta: responseJson.meta,
                })
            } else {
                this.handErrorMessageForDashboard(responseJson);
                this.setState({ isOverdueLoading: false });
            }
        }
    }
    AllOverDuePaymentsList = (page?:number,perPage?:number) => {

        this.setState({ isOverdueLoading: true })

        this.getAllOverDuePaymentsList = this.apiCallForDashBoardWebPage({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `/bx_block_visual_analytics/overdue_payments?per_page=${perPage}&page=${page}`,
            token: getLoggedUserToken()
        })
    }
    handleOverdueTablePageChange = (event: any, page: number) => {
        this.setState({ overDuePaymentPage: page });
        this.AllOverDuePaymentsList(page,this.state.overdueTableRowPerPage);
    }
    handleOverdueTableRow = (event: any) => {
        this.setState({
            overdueTableRowPerPage: Number(event.target.value),
            overDuePaymentPage:1
        });
        this.AllOverDuePaymentsList(1,Number(event.target.value))
    };
    handleCloseToaster=()=>{
        this.setState({ showAlert: false })
    }

    // Customizable Area End
}
