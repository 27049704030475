// Customizable Area Start
import React from "react";
import { Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// Customizable Area End

import InsurerListingController, { Props } from "./InsurerListingController";
import SearchBar from "../../../components/src/Common/SearchBar/SearchBar";
import Toggle from "../../../components/src/Common/Toggle/Toggle";
import PaginatedTable from "../../../components/src/Common/Table/PaginatedTable";
import { INSURER_LIST_TABLE_DATA } from "../../../components/src/Common/Table/TableColumns";
import { withTranslation } from "react-i18next";
import DropdownButton from "../../../components/src/Common/Button/DropdownButton";
import { Alert } from "@material-ui/lab";
import { CheckCircle, Close, Error } from "@material-ui/icons";
import Toster from "../../../components/src/Common/Toster";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff"
    }
  }
});

const webtStyles = {
  mainContainer: {
    width: "97%",
    marginLeft: "0",
  },
  SearchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.7rem 8px"
  },
  togleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0px"
  },
  insurerTableContainer: {
    padding: "24px",
    backgroundColor: "white",
    borderRadius: "7px"
  },
  addInsurereBtn: {
    "&": {
      color: "#fff",
      fontWeight: "600",
      padding: "8px 15px",
      "text-transform": "none"
    }
  },
  insurerHeading: {
    fontSize: "large",
    fontWeight: 800
  },
  archiveInsurer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  alertDiv: {
    display: "flex",
    "justify-content": "end",
    "padding-bottom": "15px"
  },
  redAlertMessage: {
    backgroundColor: "#ec5655",
    color: "white",
    width: "auto",
    height: "55px"
  },
  greenAlertMessage: {
    backgroundColor: "#028f5d",
    color: "white",
    width: "auto",
    height: "55px"
  },
  alertIconStyle: {
    color: "white"
  },
  displayFlex: {
    display: "flex"
  },
  alertCloseIconRight: {
    color: "white",
    justifyContent: "end",
    cursor: "pointer",
    marginRight: "20px"
  },
  alertCloseIconLeft: {
    color: "white",
    justifyContent: "end",
    cursor: "pointer",
    marginLeft: "20px"
  }
};

const enumRoles = {
  ADMIN: "Admin",
  SALES: "Sales",
  OPERATIONS: "Operations",
  FINANCE: "Finance",
  CONTACT: "Contact"
};

enum INSURER_ROUTE_AND_TEXT {
  INSURER = "insurer",
  EDITROUTE = "/InsurerDetail",
  ADDROUTE = "InsurerAdd",
  UPLOADCSVROUTE = "InsurerCsvImport",
  BUTTON_TEXT = "add_insurer"
}

const INSURER_DROPDOWN_LIST = [
  {
    label: "web form",
    value: "web_form",
    navigate: true
  },
  {
    label: "upload csv",
    value: "upload_csv",
    navigate: true
  }
];
export class InsurerListing extends InsurerListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { page, totalPage, insurersList, isArchived } = this.state;
    //@ts-ignore
    const { t } = this.props;
    const moveToSelectedRoute = (btn: string) => {
      this.props.navigation.navigate(
        btn === "upload_csv"
          ? INSURER_ROUTE_AND_TEXT.UPLOADCSVROUTE
          : INSURER_ROUTE_AND_TEXT.ADDROUTE
      );
    };
    type alertType = "error" | "success" | "info" | "warning" | undefined;

    return (
      // Required for all blocks
      <div>
        <ThemeProvider theme={theme}>
          <div style={webtStyles.mainContainer}>
            {this.state.needtoShowAlert && (
              <div dir="ltr" data-test-id="alertDiv" style={webtStyles.alertDiv}>
                <Toster
                  tosterText={this.state.alertMessage}
                  showToster={this.state.needtoShowAlert}
                  tosterType={this.state.alertType as alertType}
                  handleCloseToster={() => this.closeAlertMessage()}
                />
              </div>
            )}
            <div style={webtStyles.SearchContainer}>
              <SearchBar
                data-test-id="insurer-filter-searchbar"
                searchQuery={this.handleSearchInsurerQuery}
              />
              {this.state.role !== enumRoles.SALES ? (
                // @ts-ignore
                <DropdownButton
                  moveToSelected={moveToSelectedRoute}
                  buttonText={INSURER_ROUTE_AND_TEXT.BUTTON_TEXT}
                  dropDownList={INSURER_DROPDOWN_LIST}
                />
              ) : (
                ""
              )}
            </div>
            <div style={webtStyles.insurerTableContainer}>
              <div style={webtStyles.togleContainer}>
                <Typography style={webtStyles.insurerHeading}>
                  {isArchived ? t("archived_insurers") : t("insurers")}
                </Typography>
                <div style={webtStyles.archiveInsurer}>
                  <Typography style={{ margin: "5px" }}>
                    {t("archived")}
                  </Typography>
                  <Toggle
                    data-test-id="archived-insurer"
                    isChecked={isArchived}
                    handleToggle={this.handleArchiveInsurerTable}
                  />
                </div>
              </div>
              {/* @ts-ignore */}
              <PaginatedTable
                curretPage={page}
                totalPage={totalPage}
                pageChange={this.handleChangePage}
                handleRowPerPage={this.handleRowPerPage}
                tableFor={INSURER_ROUTE_AND_TEXT.INSURER}
                archiveList={this.archiveInsurer}
                tableData={insurersList}
                tableColumn={INSURER_LIST_TABLE_DATA}
                path={INSURER_ROUTE_AND_TEXT.EDITROUTE}
                isArchived={isArchived}
              />
            </div>
          </div>
        </ThemeProvider>
      </div>
    );
  }
}
// Customizable Area Start
export default withTranslation()(InsurerListing);
// Customizable Area End
