// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";


export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  customerName: string,
  status: string,
  customerId: string,
  sourceName: string,
  industry: string,
  VATNumber: string,
  segment: string,
  isArabicLenguage: boolean,
  isEdit: boolean,
  userId: any,
  expandedAccordion: string,
  branchCount: number,
  contactsCount: number,
  branchDetail: any,
  addressId:any,
  contactsDetail: any,
  country: string,
  region: any,
  city: any,
  district: any,
  streetName: string,
  buildingNumber: string,
  postalCode: string,
  unitNumber: string,
  additionalNumber: string,
  addressProof: any,
  risk: string,
  showAlert: boolean,
  alertMessage: string,
  alertType:string,
  showDialog:boolean,
  dialoagFor:string,
  enableEditing:boolean,
  errorSection:string,
  is_archived:boolean,
  btnLoading:boolean
}

interface SS {
  id: any;
}

export default class CustomerProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCustomerDetailsAPI: any = ""
  addCustomerAPI: any = ""
  editCustomerAPI:any = ""


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    const queryParameter: any = new URLSearchParams(window.location.search);
    const userId: string = queryParameter?.get('id')


    this.state = {
      customerName: "",
      status: "New",
      customerId: "",
      sourceName: "",
      industry: "",
      VATNumber: "",
      segment: "",
      isArabicLenguage: (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      isEdit: userId ? true : false,
      showAlert: false,
      alertMessage: "",
      alertType:"",
      userId: userId || "",
      expandedAccordion: "p1",
      branchCount: 1,
      branchDetail: [
        {
          id:"",
          branchId: "",
          first_commercial_license_legal_name: "",
          sponsor_IDCR: "",
          upload_sponsor_id_cr: {},
          upload_sponsor_id_cr_file_name: ""
        }
      ],
      contactsCount: 1,
      contactsDetail: [
        {
          id:"",
          customerId: "",
          customerFirstName: "",
          customerLastName: "",
          position: "",
          email: "",
          phoneNumber: "",
          preferredLanguage: ""
        }
      ],
      addressId:"",
      country: "",
      region: "",
      city: "",
      district: "",
      buildingNumber: "",
      streetName: "",
      postalCode: "",
      unitNumber: "",
      additionalNumber: "",
      addressProof: {},
      risk: "",
      showDialog: false,
      dialoagFor:"",
      enableEditing:userId ? false : true,
      is_archived:false,
      errorSection:"",
      btnLoading:false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  setArabicLanguage = (value: boolean) => {
    const language = value === true ? 'ar' : 'en';
    window.localStorage.setItem('lang', language);
    this.setState({
      isArabicLenguage: value
    })
  }

  async componentDidMount() {
    super.componentDidMount();

    const queryParameter: any = new URLSearchParams(window.location.search);
    const userId: string = queryParameter?.get('id');

    if (userId) {
      this.getCustomerDetail(userId)
    }
  }

  handleAccordian(isExpanded: boolean, accordionName: string) {
    this.setState({
      expandedAccordion: isExpanded ? accordionName : ""
    })
  }

  handleEnableEditing = (value:boolean) => {
    this.setState({
      enableEditing:value
    })
  }

  getCustomerDetail = async (userId: string) => {
      const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
      const token = await userData.token || '';
      this.getCustomerDetailsAPI = await this.apiCall({
        contentType: 'application/json',
        method: 'GET',
        endPoint: `bx_block_customer/customers/${userId}`,
        token: token || ''
      })
  }

  componentDidUpdate() {
    if(this.state.showAlert){
      setTimeout(() => {
        this.setState({
          showAlert: false,
          alertType: "",
          alertMessage: "",
        });
      }, 10000);
    }
  }

  closeAlertMessage = () => {
    const arbicLanguage = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
    this.setState({
        showAlert:false,
        alertMessage:"",
        alertType:"",
        isArabicLenguage:arbicLanguage
      })
    
  }

  getFormData = (values:any) => {
    const formData = new FormData();

      formData.append("data[name]", values?.customerName)
      formData.append("data[status]", values.status.toLowerCase())
      if (values.customerId) {
        formData.append("data[customer_id]", values.customerId)
      }
      formData.append("data[source_name]", values.sourceName)
      formData.append("data[industry]", values.industry)
      if (values.VATNumber) {
        formData.append("data[vat_number]", values.VATNumber)
      }
      formData.append("data[segment]", values.segment.map((segment: any) => segment.value).join(','))

      values.branchDetail.forEach((item: any, index: number) => {
        if (item.id) {
          formData.append(`data[branches_attributes][${index}][id]`, item.id);
        }
        formData.append(`data[branches_attributes][${index}][name]`, item.first_commercial_license_legal_name);
        formData.append(`data[branches_attributes][${index}][sponsor]`, item.sponsor_IDCR);
        if ((index+1 > this.state.branchDetail.length) || ((index+1 <= this.state.branchDetail.length) && (item.upload_sponsor_id_cr && (item.upload_sponsor_id_cr !== this.state.branchDetail[index].upload_sponsor_id_cr)))) {
          formData.append(`data[branches_attributes][${index}][sponsor_file]`, item.upload_sponsor_id_cr);
        }
      });

      values.contactsDetail.forEach((item: any, index: number) => {
        if (item.id) {
          formData.append(`data[contacts_attributes][${index}][id]`, item.id);
        }
        formData.append(`data[contacts_attributes][${index}][first_name]`, item.customerFirstName);
        formData.append(`data[contacts_attributes][${index}][last_name]`, item.customerLastName);
        formData.append(`data[contacts_attributes][${index}][position]`, item.position);
        formData.append(`data[contacts_attributes][${index}][email]`, item.email);
        formData.append(`data[contacts_attributes][${index}][phone_number]`, item.phoneNumber);
        formData.append(`data[contacts_attributes][${index}][prefered_language]`, item.preferredLanguage);
      });

      if (values.addressId) {
        formData.append("data[addresses_attributes][id]", values.addressId)  
      }

      formData.append("data[addresses_attributes][country]", values.country)
      formData.append("data[addresses_attributes][region]", values.region)
      formData.append("data[addresses_attributes][city]", values.city)
      formData.append("data[addresses_attributes][district]", values.district)
      formData.append("data[addresses_attributes][street_name]", values.streetName)
      formData.append("data[addresses_attributes][building_number]", values.buildingNumber)
      formData.append("data[addresses_attributes][postal_code]", values.postalCode)
      formData.append("data[addresses_attributes][unit_number]", values.unitNumber)
      formData.append("data[addresses_attributes][additional_number]", values.additionalNumber)
      formData.append("data[addresses_attributes][risk]", values.risk)

      if (values.addressProof && (values.addressProof !== this.state.addressProof)) {
        formData.append("data[addresses_attributes][address_proof]", values.addressProof);
      }

      return formData;
  }

  submitCustomerForm = (values: any) => {
      this.setState({btnLoading:true})
      const formData = this.getFormData(values);
      const userData = JSON.parse(localStorage.getItem('loginData') || '{}');
      const token = userData.token || '';

      const header = {
        token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.addCustomerAPI = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.addCustomerEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `${configJSON.apiMethodTypePost}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
  }

  checkCustomerForm = (errors:any) => {
    if (Object.keys(errors).length === 0) {
      this.setState({ showDialog: true, dialoagFor: "ok" })
    } else {
      let errorSection = "";
      const keys = Object.keys(errors);
        const firstKey = keys[0];
        let errorValue = ""
        if ((firstKey === "contactsDetail") ||  (firstKey ==="branchDetail")) {
          const array = (firstKey === "contactsDetail") ? errors.contactsDetail : errors.branchDetail;

          for (let i = 0; i < array.length; i++) {
            if ( (array[i] !== null) && (array[i] !== undefined) && typeof(array[i] === 'object')) {
              const obj = array[i];
              const firstValue = Object.values(obj)[0] as string
              errorValue = firstValue
            }  
          }

          errorSection = firstKey === "contactsDetail" ? "p3" : "p2"
        } else {
          if (
            (firstKey === "region") ||
            (firstKey === "city") ||
            (firstKey === "district") ||
            (firstKey === "buildingNumber") ||
            (firstKey === "streetName") ||
            (firstKey === "postalCode") ||
            (firstKey === "unitNumber") ||
            (firstKey === "additionalNumber") ||
            (firstKey === "addressProof") ||
            (firstKey === "risk")
          ) {
            errorSection = "p4"
          } else {
            errorSection = "p1"
          }
          errorValue = errors[firstKey];
        }

      this.setState({
        showAlert : true,
        alertMessage : errorValue,
        alertType: "error",
        expandedAccordion:"",
        errorSection: errorSection
      })
    }
  }

  editCustomer= (values:any) => {
      this.setState({btnLoading:true})
      const formData = this.getFormData(values);
      const userData = JSON.parse(localStorage.getItem('loginData') || '{}');
      const token = userData.token || '';
      const endPoint = `${configJSON.editCustomerEndPoint}/${this.state.userId}`;
      const method = `${configJSON.apiMethodTypePut}`;

      const header = {
        token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      
      this.editCustomerAPI = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, token } = data;
    const header = {
      "Content-Type": contentType,
      token
    };
    const apiRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    apiRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  getContactDetail = async (contactData: any) => {
    if (contactData.length > 0) {
      let array: { id:any; customerId: any; customerFirstName: any; customerLastName: any; position: any; email: any; phoneNumber: any; preferredLanguage: any; }[] = [];

      await contactData.map((contact: any) => {
        const item = contact.attributes
        array.push(
          {
            id: item.id,
            customerId: item.id,
            customerFirstName: item.first_name,
            customerLastName: item.last_name,
            position: item.position,
            email: item.email,
            phoneNumber: item.phone_number,
            preferredLanguage: item.prefered_language
          }
        )
      })
      return array;
    } else {
      return this.state.contactsDetail
    }
  }

  getBranchDetail = async (branchDetail: any) => {
    if (branchDetail.length > 0) {

      let array: { id:any; branchId: any; first_commercial_license_legal_name: any; sponsor_IDCR: any; upload_sponsor_id_cr: any; upload_sponsor_id_cr_file_name:any; }[] = [];

      await branchDetail.map((branch: any) => {
        const item = branch.attributes;
        array.push({
          id: item.id,
          branchId: item.id,
          first_commercial_license_legal_name: item.name,
          sponsor_IDCR: item.sponsor,
          upload_sponsor_id_cr: item.sponsor_file,
          upload_sponsor_id_cr_file_name: item.sponsor_file_name
        })
      })
      return array;
    } else {
      return this.state.branchDetail
    }
  }

  setAlertForAddCustomerApiFail = (responseJson: any) => {
    const isArabicLanguage = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false
    if (!responseJson) return;
    let errorMessage = "Something went wrong"
    if (responseJson.errors) {
      errorMessage = typeof (responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error || "Something went wrong";
    }
    this.setState({
      showAlert: true, 
      alertMessage: errorMessage, 
      alertType: "error", 
      dialoagFor:"", 
      showDialog:false, 
      expandedAccordion:"",
      errorSection:""
    })
  }

  async receive(from: string, message: Message) {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCustomerDetailsAPI != null &&
      this.getCustomerDetailsAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {

        const customerDetails = await responseJson.data.attributes;
        const addressDatails = await customerDetails.addresses.data[0].attributes;

        const contactArray = await this.getContactDetail(customerDetails.contacts.data);
        const branchArray = await this.getBranchDetail(customerDetails.branches.data);

        this.setState({
          customerName: customerDetails?.name,
          status: customerDetails.status.charAt(0).toLocaleUpperCase() + customerDetails.status.slice(1),
          customerId: customerDetails.customer_id,
          sourceName: customerDetails.source_name,
          industry: customerDetails.industry,
          segment: customerDetails.segment,
          VATNumber: customerDetails.vat_number,
          branchCount: customerDetails.branches.data.length,
          branchDetail: branchArray,
          contactsCount: customerDetails.contacts.data.length,
          contactsDetail: contactArray,
          country: "Saudi Arabia",
          addressId: addressDatails.id,
          region: addressDatails.region,
          city: addressDatails.city,
          district: addressDatails.district,
          streetName: addressDatails.street_name,
          buildingNumber: addressDatails.building_number,
          postalCode: addressDatails.postal_code,
          unitNumber: addressDatails.unit_number,
          additionalNumber: addressDatails.additional_number,
          addressProof: addressDatails.address_proof,
          risk: addressDatails.risk,
          is_archived: customerDetails.is_archived,
        });
      } else {
        this.setAlertForAddCustomerApiFail(responseJson)
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addCustomerAPI != null &&
      this.addCustomerAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
          const englishTxt = await configJSON.customerAddedTxt;
          const arabicTxt = await configJSON.ArabicCustomerAddedTxt;
          const data = {
            englishTxt:englishTxt,
            arabicTxt:arabicTxt,
            page:"Customers",
            alertType:"success"
          }
          this.setState({btnLoading:false,showDialog:false,dialoagFor:""})
          window.localStorage.setItem('alertDetail',JSON.stringify(data))
          window.location.href = "/Customers"
      } else {
        this.setState({btnLoading:false,showDialog:false,dialoagFor:""})
        this.setAlertForAddCustomerApiFail(responseJson)
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.editCustomerAPI != null &&
      this.editCustomerAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
          const englishTxt = `${this.state?.customerName} ${configJSON.savedTxt}`;
          const arabicTxt = `${this.state?.customerName} ${configJSON.arabicSavedTxt}`;
          const data = {
            englishTxt:englishTxt,
            arabicTxt:arabicTxt,
            page:"Customers",
            alertType:"success"
          }
          this.setState({btnLoading:false,showDialog:false,dialoagFor:""})
          window.localStorage.setItem('alertDetail',JSON.stringify(data))
          window.location.href = "/Customers"
      } else {
        this.setState({btnLoading:false,showDialog:false,dialoagFor:""})
        this.setAlertForAddCustomerApiFail(responseJson)
      }
    }
  }
}
// Customizable Area End