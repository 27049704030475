import React from "react";
import {
    Button,
    Typography,
    OutlinedInput,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import { Formik, useField } from "formik";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import * as Yup from "yup";
import { CancelRounded, CheckCircle } from '@material-ui/icons'
// @ts-ignore
import { changeLanguage } from "i18next";
import { Alert } from "@material-ui/lab";
import { withTranslation } from 'react-i18next';
import ForgotPasswordController from "../../../blocks/forgot-password/src/ForgotPasswordController";

const componentStyles = {
    subTitle: { color: "gray", fontSize: '12px' },
    cardStyle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px 0px",
        width: "100%"

    },
    centerContent: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "30px",
        paddingBottom: "20px"
    },
    logoImage: {
        height: '200px',
        width: '200px'
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    submitButton: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform":"none",
        "color": "white",
        "width": "200px",
        "height": "50px",
        borderRadius: "10px"
    },
    displayFlex: {
        display: "flex"
    },
}
const TextFieldControl = (props: any) => {
    const [field, meta] = useField(props);

    return (
        <OutlinedInput
            style={{
                direction: props.isArabicLenguage ? "rtl" : "ltr",
                marginTop: "10px",
                marginBottom: "10px"
            }}
            fullWidth
            type={props.type}
            {...field}
            {...props}
            error={meta.error && meta.touched}
        />
    );
};

export class NewPasswordForm extends ForgotPasswordController {
  render() {
    // @ts-ignore
    const {t} = this.props;
    return (
        <div data-test-id="newPasswordForm" dir={ this.state.isArabicLenguage === true ? "rtl" : "ltr"} >
        <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={Yup.object().shape(this.state.passwordSchema)}
            validateOnMount={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
                this.resetPassoword(values.password)
                actions.setSubmitting(false);
            }}
        >
            {({
                handleChange,
                handleSubmit,
                errors,
                setFieldTouched,
                touched,
                values,
                resetForm
            }) => (
                <React.Fragment>
                    <div style={{ textAlign: "center" }}>
                        <div style={{ paddingTop: '25px' }} >
                            <Button
                                style={{
                                    color: !this.state.isArabicLenguage ? 'rgba(45, 111, 143, 1)' : 'black',
                                    textTransform: "none",
                                    textDecoration: !this.state.isArabicLenguage ? "underline" : "",
                                    fontWeight: !this.state.isArabicLenguage ? 'bolder' : 'normal',
                                    fontSize:"15px"
                                }}
                                onClick={() => {
                                    resetForm()
                                    this.setArabicLanguage(false)
                                    changeLanguage('en');
                                }}>English</Button> |
                            <Button
                                style={{
                                    color: this.state.isArabicLenguage ? 'rgba(45, 111, 143, 1)' : 'black',
                                    fontWeight: this.state.isArabicLenguage ? 'bolder' : 'normal',
                                    fontSize:"15px"
                                }}
                                onClick={() => {
                                    resetForm()
                                    this.setArabicLanguage(true)
                                    changeLanguage('ar');
                                }} color={this.state.isArabicLenguage ? 'primary' : 'default'} >العربي</Button>
                        </div>
                        <div style={{ paddingTop: '10px', paddingBottom:"30px"}} >
                            <Typography style={{ fontWeight: 600 }} variant="h5" component="h5">
                                {t('password_recovery')}
                            </Typography>
                            <p style={componentStyles.subTitle} >{t('set_your_new_password')}</p>
                        </div>
                    </div>
                    <div>

                        {touched.confirmPassword && errors.confirmPassword ? (
                            <Alert style={{ width: "75%", marginBottom:"15px" }} icon={false} severity="error">
                                <div style={componentStyles.displayFlex} >
                                    <CancelRounded style={{ verticalAlign: "bottom" }} />&nbsp;
                                    <Typography variant="body1">{t('confirm_password_not_match')}</Typography>
                                </div>
                            </Alert>
                        ) : null}
                        <label aria-label="password" >{t('new_password')}</label>
                        <TextFieldControl
                            style={componentStyles.textInputStyle}
                            data-test-id="txtInputPassword"
                            type={this.state.enablePasswordField ? "password" : "text"}
                            name="password"
                            fullWidth={true}
                            isArabicLenguage={this.state.isArabicLenguage}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowPassword}
                                        edge="end"
                                    >
                                        {this.state.enablePasswordField ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {touched.password && errors.password ? (
                            <Typography color="error" >{t('password_field_require')}</Typography>
                        ) : null}
                    </div>

                    <div>
                        <label aria-label="password" >{t('confirm_password')}</label>
                        <TextFieldControl
                            style={componentStyles.textInputStyle}
                            data-test-id="txtInputConfirmPassword"
                            type={this.state.enableConfirmPasswordField ? "password" : "text"}
                            name="confirmPassword"
                            fullWidth={true}
                            isArabicLenguage={this.state.isArabicLenguage}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickShowConfirmPassword}
                                        edge="end"
                                    >
                                        {this.state.enableConfirmPasswordField ? (
                                            <Visibility />
                                        ) : (
                                            <VisibilityOff />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <div>
                            <div style={{ display: 'flex' }}>
                                <CheckCircle style={{ color: `${/^(?=.*[A-Z])/.test(values.password) ? "green" : "gray"}` }} />  <Typography>{t('at_least_1_uppercase_letter')}</Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <CheckCircle style={{ color: `${/^(?=.*[a-z])/.test(values.password) ? "green" : "gray"}` }} /> <Typography>{t('at_least_1_lowercase_letter')}</Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <CheckCircle style={{ color: `${/^(?=.*[0-9])/.test(values.password) ? "green" : "gray"}` }} /> <Typography>{t('at_least_1_numeric_character')}</Typography>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <CheckCircle style={{ color: `${values.password.length >= 8 ? "green" : "gray"}` }} /> <Typography>{t('minimum_8_character')}</Typography>
                            </div>
                        </div>
                    </div>

                    <div style={componentStyles.centerContent}>
                        <Button
                            // @ts-ignore
                            style={componentStyles.submitButton}
                            data-test-id={"btnChangePasswordId"}
                            variant="contained"
                            color="primary"
                            onClick={() => handleSubmit()}
                        >
                            {t('change_password')}
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </Formik>
    </div>
    )
  }
}

export default withTranslation()(NewPasswordForm);