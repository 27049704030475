import React, { useEffect, useState } from 'react';
import { FormControl, Grid, InputAdornment, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import LabelWithIcon from '../Common/LabelWithIcon';
import MultiSelect from '../Common/MultiSelect.web'
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles({
    cmpWrapper: {
        backgroundColor: '#fff',
        width: '100%',
        '& legend': {
            textAlign: 'left',
            marginBottom: '10px',
            display: 'flex',
            fontSize: '14px',
            // color: '#d0d0d0',
            color: '#3d3d3d !important',
            '& svg': {
                fontSize: '15px',
                color: '#222',
                marginLeft: '6px',
            }
        },
        '& label': {
            color: '#3d3d3d !important',
        }
    },
    formControl: {
        // margin: theme.spacing(1),
        minWidth: '100%',
    },
    lineSaprator: {
        backgroundColor: '#e2e2e2',
        height: '1.3px',
        width: '100%',
        margin: '18px 0px'
    },
    inputStyle:{
        marginTop: "10px",
        marginBottom: "10px",
        "& .MuiInputBase-root.Mui-disabled":{
            background:"hsl(0, 0%, 95%)"
        }
    }
})

const enumFormAction = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
}

type motorInsuranceDataType = {
    insurance_class: string,
    motor_premium: string,
    motor_premium_minimum_value: string,
    sedan_suv_minimum_excess: string,
    pickup_minimum_execess: string,
    van_minimum_excess: string,
    lcv_minimum_execess: string,
    hcv_minimum_excess: string,
}

const enumMotorInsuranceProps = {
    insurance_class: 'insurance_class',
    motor_premium: 'motor_premium',
    motor_premium_minimum_value: 'motor_premium_minimum_value',
    sedan_suv_minimum_excess: 'sedan_suv_minimum_excess',
    pickup_minimum_execess: 'pickup_minimum_execess',
    van_minimum_excess: 'van_minimum_excess',
    lcv_minimum_execess: 'lcv_minimum_execess',
    hcv_minimum_excess: 'hcv_minimum_excess',
}

const segmentOptions: any = [
    { value: 'Comprehensive', label: 'Comprehensive' },
    { value: 'TPL', label: 'TPL' },
];


type MotorInsuranceType = {
    motorInsuranceProps: (key: string, value: string) => void,
    data: motorInsuranceDataType,
    submitEvent: boolean, 
    formType: string,
}

const MotorInsurance: React.FC<MotorInsuranceType> = (props: MotorInsuranceType) => {
    const classes = useStyles();
    const [insuranceClass, setInsuranceClass] = useState('')
    const [count, setCount] = useState(0)
    const [errors, setErrors] = useState<any>({
        insurance_class: false,
        motor_premium: false,
        motor_premium_minimum_value: false,
        sedan_suv_minimum_excess: false,
        pickup_minimum_execess: false,
        van_minimum_excess: false,
        lcv_minimum_execess: false,
        hcv_minimum_excess: false,
    })
    const {t} = useTranslation();
    
    useEffect(() => {
        setCount(count+1)
        if (count) {
            const data:any = { ...props.data };
            const temp:any = { ...errors };
            if(Object.keys(data).length){
                if((data['insurance_class'] === undefined) || data['insurance_class'].length === 0){
                    temp['insurance_class'] = true
                }
                for (const key in temp) {
                    if (data[key] === '' || data[key] === undefined) {
                        temp[key] = true
                    }
                }
            }else{
                for (const key in temp) {
                        temp[key] = true
                }
            }

            setErrors(temp)
        }
    }, [props.submitEvent])

    return (
        <div className={classes.cmpWrapper}>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <LabelWithIcon label={t('first_commercial_legal_name')} />
                    <MultiSelect
                        selectOptions={segmentOptions}
                        selectValue={props.data?.insurance_class}
                        eventHandler={(selected: any) => {
                            props.motorInsuranceProps(enumMotorInsuranceProps.insurance_class, selected)
                            setInsuranceClass(selected)
                            if (selected.length === 0) {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.insurance_class] = true;
                                setErrors(temp)
                            } else {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.insurance_class] = false;
                                setErrors(temp)
                            }
                        }}
                        showError={errors['insurance_class']}
                        disable={props.formType === enumFormAction.VIEW}
                    />
                    {errors["insurance_class"] && (
                        <Typography color="error">
                            {`${t('please_enter_valid')} ${t('first_commercial_legal_name')}`}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <LabelWithIcon label={`${t('premium')} %`} tooltip={t('coverage_premium_percentage_from_vehicle_value') as string} />
                    <TextField
                        className={classes.inputStyle}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        fullWidth
                        disabled={(props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD) ? false : true}
                        value={props.data?.motor_premium}
                        onChange={(e: any) => {
                            const val = e.target.value.replace(/\D/g, "");
                            props.motorInsuranceProps(enumMotorInsuranceProps.motor_premium, val)
                            if (val) {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.motor_premium] = false;
                                setErrors(temp)
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">%</InputAdornment>
                            ),
                            readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                            disabled: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                        }}
                        error={errors[enumMotorInsuranceProps.motor_premium]}
                        required
                    />
                    {errors[enumMotorInsuranceProps.motor_premium] && (
                        <Typography color="error">
                            {`${t('please_enter_valid')} ${t('premium')}`}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <LabelWithIcon label={t('premium_minimum_value')} tooltip={t('coverage_premium_value_from_vehicle_value') as string} />
                    <TextField
                        className={classes.inputStyle}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        fullWidth
                        value={props.data?.motor_premium_minimum_value}
                        name={enumMotorInsuranceProps.motor_premium_minimum_value}
                        onChange={(e: any) => {
                            props.motorInsuranceProps(enumMotorInsuranceProps.motor_premium_minimum_value, e.target.value)
                        
                            if (props?.data?.motor_premium_minimum_value === '') {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.motor_premium_minimum_value] = true;
                                setErrors(temp)
                            } else {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.motor_premium_minimum_value] = false;
                                setErrors(temp)
                            }
                        }}
                        error={errors[enumMotorInsuranceProps.motor_premium_minimum_value]}
                        InputProps={{
                         readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                         disabled: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                        }}
                    />
                    {errors[enumMotorInsuranceProps.motor_premium_minimum_value] && (
                        <Typography color="error">
                            {`${t('please_enter_valid')} ${t('premium_minimum_value')}`}
                        </Typography>
                    )}
                </Grid>
            </Grid>

            <div className={classes.lineSaprator}></div>


            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <LabelWithIcon label={t('sedan_suv_minimum_excess')} tooltip={t('sedan_charges_per_accident') as string} />
                    <TextField
                        className={classes.inputStyle}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        fullWidth
                        name={enumMotorInsuranceProps.sedan_suv_minimum_excess}
                        value={props.data?.sedan_suv_minimum_excess}
                        onChange={(e: any) => {
                            props.motorInsuranceProps(enumMotorInsuranceProps.sedan_suv_minimum_excess, e.target.value)
                        
                            if (props?.data?.sedan_suv_minimum_excess === '') {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.sedan_suv_minimum_excess] = true;
                                setErrors(temp)
                            } else {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.sedan_suv_minimum_excess] = false;
                                setErrors(temp)
                            }
                        }}
                        error={errors[enumMotorInsuranceProps.sedan_suv_minimum_excess]}
                        InputProps={{
                         readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                         disabled: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                        }}
                    />
                    {errors[enumMotorInsuranceProps.sedan_suv_minimum_excess] && (
                        <Typography color="error">
                            {`${t('please_enter_valid')} ${t('sedan_suv_minimum_excess')}`}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <LabelWithIcon label={t('pickup_minimum_excess')} tooltip={t('pickup_charges_per_accident') as string} />
                    <TextField
                        className={classes.inputStyle}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        fullWidth
                        name={enumMotorInsuranceProps.pickup_minimum_execess}
                        value={props.data?.pickup_minimum_execess}
                        onChange={(e: any) => {
                            props.motorInsuranceProps(enumMotorInsuranceProps.pickup_minimum_execess, e.target.value)
                            if (props?.data?.pickup_minimum_execess === '') {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.pickup_minimum_execess] = true;
                                setErrors(temp)
                            } else {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.pickup_minimum_execess] = false;
                                setErrors(temp)
                            }
                        }}
                        error={errors[enumMotorInsuranceProps.pickup_minimum_execess]}
                        InputProps={{
                         readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                         disabled: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                        }}
                    />
                    {errors[enumMotorInsuranceProps.pickup_minimum_execess] && (
                        <Typography color="error">
                            {`${t('please_enter_valid')} ${t('pickup_minimum_excess')}`}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <LabelWithIcon label={t('van_minimum_excess')} tooltip={t('van_charges_per_accident') as string} />
                    <TextField
                        className={classes.inputStyle}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        fullWidth
                        name={enumMotorInsuranceProps.van_minimum_excess}
                        value={props.data?.van_minimum_excess}
                        onChange={(e: any) => {
                            props.motorInsuranceProps(enumMotorInsuranceProps.van_minimum_excess, e.target.value)
                            if (props?.data?.van_minimum_excess === '') {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.van_minimum_excess] = true;
                                setErrors(temp)
                            } else {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.van_minimum_excess] = false;
                                setErrors(temp)
                            }
                        }}
                        error={errors[enumMotorInsuranceProps.van_minimum_excess]}
                        InputProps={{
                         readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                         disabled: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                        }}
                    />
                    {errors[enumMotorInsuranceProps.van_minimum_excess] && (
                        <Typography color="error">
                            {`${t('please_enter_valid')} ${t('van_minimum_excess')}`}
                        </Typography>
                    )}
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <LabelWithIcon label={t('lcv_minimum_excess')} tooltip={t('lcv_charges_per_accident') as string} />
                    <TextField
                        className={classes.inputStyle}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        fullWidth
                        name={enumMotorInsuranceProps.lcv_minimum_execess}
                        value={props.data?.lcv_minimum_execess}
                        onChange={(e: any) => {
                            props.motorInsuranceProps(enumMotorInsuranceProps.lcv_minimum_execess, e.target.value)
                            if (props?.data?.lcv_minimum_execess === '') {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.lcv_minimum_execess] = true;
                                setErrors(temp)
                            } else {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.lcv_minimum_execess] = false;
                                setErrors(temp)
                            }
                        }}
                        error={errors[enumMotorInsuranceProps?.lcv_minimum_execess]}
                        InputProps={{
                         readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                         disabled: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                        }}
                    />
                    {errors[enumMotorInsuranceProps.lcv_minimum_execess] && (
                        <Typography color="error">
                            {`${t('please_enter_valid')} ${t('lcv_minimum_excess')}`}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <LabelWithIcon label={t('hcv_minimum_excess')} tooltip={t('hcv_charges_per_accident') as string} />
                    <TextField
                        className={classes.inputStyle}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        fullWidth
                        name={enumMotorInsuranceProps.hcv_minimum_excess}
                        value={props.data?.hcv_minimum_excess}
                        onChange={(e: any) => {
                            props.motorInsuranceProps(enumMotorInsuranceProps.hcv_minimum_excess, e.target.value)
                            if (props?.data?.hcv_minimum_excess === '') {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.hcv_minimum_excess] = true;
                                setErrors(temp)
                            } else {
                                const temp = { ...errors };
                                temp[enumMotorInsuranceProps.hcv_minimum_excess] = false;
                                setErrors(temp)
                            }
                        }}
                        error={errors[enumMotorInsuranceProps?.hcv_minimum_excess]}
                        InputProps={{
                         readOnly: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                         disabled: props.formType === enumFormAction.EDIT || props.formType === enumFormAction.ADD ? false : true,
                        }}
                    />
                    {errors[enumMotorInsuranceProps.hcv_minimum_excess] && (
                        <Typography color="error">
                            {`${t('please_enter_valid')} ${t('hcv_minimum_excess')}`}
                        </Typography>
                    )}
                </Grid>
            </Grid>

        </div>
    )
}

export default MotorInsurance