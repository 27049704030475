// Customizable Area Start
import React from "react";
import StageOneController, { Props } from "./StageOneController";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import Toster from "../../../../../../components/src/Common/Toster";
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, InputAdornment, Typography } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from 'yup';
import { ExpandMore, HighlightOff } from "@material-ui/icons";
import LabelWithIcon from "../../../../../../components/src/Common/LabelWithIcon";
import TextInput from "../../../../../../components/src/Common/TextInput";
import MultiSelectFormik from "../../../../../../components/src/Common/MultiSelectFormik";
import SelectBoxFormik from "../../../../../../components/src/Common/SelectBoxFormik";
import ConfirmationDialog from "../../../../../../components/src/Common/ConfirmationDialog.web";
import StageOnePartTwoForm from "../../../../../../components/src/Common/RevisionRequest/StageOnePartTwoForm";
import EditDialog from "../../../../../../components/src/Common/RevisionRequest/EditDialog";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

export class StageOneForm extends StageOneController {
    formikRef: any;

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.formikRef = React.createRef();
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { t, classes, rrStageOneData }: Props = this.props;

        const revisionTypeOption = [
            { value: "add", label: "Add" },
            { value: "remove", label: "Remove" },
            { value: "correct", label: "Correct" },
            { value: "upgrade", label: "Upgrade" },
            { value: "cancel", label: "Cancel"},
        ]

        const getStageOneInitialValue = () => {
            const stageValue: any = {
                insuranceType: this.state.stageData.insuranceType,
                insurer: this.state.stageData.insurer,
                covered: this.state.stageData.covered,
                policyActivationDate: this.state.stageData.policyActivationDate,
                policyExpireDate: this.state.stageData.policyExpiryDate,
                currentPolicyValue: this.state.stageData.currentPolicyValue,
                policyBalanceDue: this.state.stageData.policyBalanceDue,
                policyID: this.state.stageData.policyID,
                contactName: this.state.stageData.contactName,
                contectPosition: this.state.stageData.contectPosition,
                contactPhoneNumber: this.state.stageData.contactPhoneNumber,
                contactEmail: this.state.stageData.contactEmail,
                contactStatus: this.state.stageData.contactStatus,
                sales: this.state.stageData.sales,
                operation: this.state.stageData.operation,
                finance: this.state.stageData.finance,
                revisionType: this.state.stageData.revisionType
            }

            return stageValue
        }
        /* istanbul ignore next */
        const isDisable = () => {
            if (this.props.isExpanded && this.state.p1Submited) return true;
            else if (this.props.isExpanded && this.props.rrStageOneData.data) return true;
            else if (!this.props.isExpanded) return true;
            else return false;
        }

        // Customizable Area End

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <React.Fragment>
                    <Toster
                        tosterText={this.state.alertMessage}
                        showToster={this.state.showAlert}
                        // @
                        tosterType={this.state.alertType}
                        handleCloseToster={() => this.setState({ showAlert: false })}
                    />
                    <div className={classes?.customerDetailsAccordion}>
                        <Accordion data-test-id='p1' id="p1-accordion" className={classes?.accordionStyle} expanded={this.isPartOneExpanded(this.props.isExpanded)} onChange={(event: any, isExpanded: boolean) => {
                            this.handleAccordian(isExpanded, "p1")
                        }} >
                            <AccordionSummary
                                aria-expanded={true}
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div style={{display: 'flex', justifyContent: "space-between", width: "100%"}}>
                                <Typography className={classes?.accordianHeading} >{t('overview')}</Typography>
                                { this.state.expandedAccordion  && rrStageOneData?.data?.attributes?.lost_reason &&
                             <HighlightOff style={{ color: 'red'}} onClick={this.handleLostIcon} />
                             }
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Formik
                                    innerRef={this.formikRef}
                                    enableReinitialize={true}
                                    initialValues={getStageOneInitialValue()}
                                    validationSchema={Yup.object().shape(this.overviewSchema())}
                                    validateOnMount={true}
                                    validateOnBlur={true}
                                    onSubmit={(values) => {
                                        if (this.props.revisionRequestID) {
                                            this.editPolicy(values)
                                        } else {
                                            this.submitStageOne(0.5, "next", values)
                                        }
                                    }}
                                >
                                    {({
                                        values,
                                        setFieldValue,
                                        handleSubmit,
                                        validateForm,
                                        touched,
                                        errors
                                    }) => (
                                        <React.Fragment>
                                            <Grid className={classes?.contactForm} >
                                                <Grid container spacing={2} >
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('type_of_insurance')} />
                                                        <TextInput
                                                            id={"insuranceType"}
                                                            className={classes?.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="insuranceType"
                                                            disabled={true}
                                                            type="text"
                                                            data-test-id="insuranceType"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('insurer')} />
                                                        <TextInput
                                                            id={"insurer"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="insurer"
                                                            type="text"
                                                            data-test-id="insurer"
                                                            disabled={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('covered')} />
                                                        <TextInput
                                                            id={"covered"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="covered"
                                                            type="text"
                                                            data-test-id="covered"
                                                            disabled={true}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    # <div className={classes.phoneInputLine} />
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2} >
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('policy_activation_date')} />
                                                        <TextInput
                                                            id={"policyActivationDate"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="policyActivationDate"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="policyActivationDate"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('policy_expire_data')} />
                                                        <TextInput
                                                            id={"policyExpireDate"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="policyExpireDate"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="policyExpireDate"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('current_policy_value')} />
                                                        <TextInput
                                                            id={"currentPolicyValue"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="currentPolicyValue"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="currentPolicyValue"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('policy_balance_due')} />
                                                        <TextInput
                                                            id={"policyBalanceDue"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="policyBalanceDue"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="policyBalanceDue"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4} >
                                                        <LabelWithIcon label={t('policy_id')} />
                                                        <TextInput
                                                            id={"policyID"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="policyID"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="policyID"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Typography className={classes.divierStyle} >{t('contact')}</Typography>
                                                <Grid className={classes.contactDetailDiv} container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('name')} />
                                                        <MultiSelectFormik
                                                            value={values.contactName}
                                                            name='contactName'
                                                            id='contactName'
                                                            setOtherInput={(customerDetail: any) => {
                                                                setFieldValue('contectPosition', customerDetail.position)
                                                                setFieldValue('contactPhoneNumber', `${customerDetail.phone_number}`)
                                                                setFieldValue('contactEmail', customerDetail.email)
                                                                setFieldValue('contactStatus', customerDetail.status)
                                                            }}
                                                            options={this.state.contectOption}
                                                            data-test-id="contectName"
                                                            disabled={isDisable()}
                                                        />
                                                        {touched.contactName && errors.contactName ? (
                                                            <Typography color="error" >{errors.contactName}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('position')} />
                                                        <TextInput
                                                            id={"contectPosition"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="contectPosition"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="contectPositionTxtInput"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('contact_no')} />
                                                        <TextInput
                                                            className={classes.textInputStyle}
                                                            name="contactPhoneNumber"
                                                            type="text"
                                                            data-test-id="contactPhoneNumber"
                                                            id="contactPhoneNumber"
                                                            disabled={true}
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    + 966<div className={classes.phoneInputLine} />
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('email')} />
                                                        <TextInput
                                                            id={"contactEmail"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="contactEmail"
                                                            type="email"
                                                            disabled={true}
                                                            data-test-id="contactEmailTxtInput"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('account_status')} />
                                                        <TextInput
                                                            id={"contactStatus"}
                                                            className={classes.textInputStyle}
                                                            isArabicLenguage={this.state.isArabicLenguage}
                                                            name="contactStatus"
                                                            type="text"
                                                            disabled={true}
                                                            data-test-id="contactStatusTxtInput"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Divider className={classes.bottomBorderAlignment} />
                                                <Grid container spacing={2}>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('sales')} />
                                                        <MultiSelectFormik
                                                            value={values.sales || this.state.stageData.sales}
                                                            name='sales'
                                                            id='sales'
                                                            options={this.state.salesOption}
                                                            data-test-id="salesTxtInput"
                                                            disabled={isDisable()}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('operations')} />
                                                        <MultiSelectFormik
                                                            value={values.operation || this.state.stageData.operation}
                                                            name='operation'
                                                            id='operation'
                                                            options={this.state.operationOptions}
                                                            data-test-id="operationTxtInput"
                                                            disabled={isDisable()}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <LabelWithIcon label={t('finance')} />
                                                        <MultiSelectFormik
                                                            value={values.finance || this.state.stageData.finance}
                                                            name='finance'
                                                            id='finance'
                                                            options={this.state.financeOptions}
                                                            data-test-id="financeTxtInput"
                                                            disabled={isDisable()}
                                                        />
                                                    </Grid>
                                                    <Grid className={classes.insuranceTypeStyle} item xs={10}>
                                                        <LabelWithIcon label={t('revision_type')} />
                                                        <SelectBoxFormik
                                                            data-test-id='revisionSelect'
                                                            name={`revisionType`}
                                                            onChange={(newValue: any) => {
                                                                setFieldValue(`revisionType`, newValue)
                                                            }}
                                                            options={revisionTypeOption}
                                                            disabled={(this.props.isExpanded && this.state.p1Submited) || (this.props.isExpanded && this.props.rrStageOneData.data) || !this.props.isExpanded}
                                                        />
                                                        {touched.revisionType && errors.revisionType ? (
                                                            <Typography color="error" >{errors.revisionType}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.saveBtnDiv} >
                                                        <Button data-test-id='saveBtn'
                                                            disabled={isDisable()}
                                                            onClick={() => {
                                                                validateForm();
                                                                this.checkErrorForStageOneForm(errors, "p1");
                                                            }} className={classes.saveBtnStyle}>{t('save')}</Button>
                                                    </Grid>
                                                    <Typography className={classes.noteMessage} >{t('save_revision_type_message_note')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <EditDialog openDialog={this.state.isEditDialog} handleCancel={() => this.setState({isEditDialog: false})} headingText={'Edit'} editFor={this.state.stageData.insuranceType} selectedData={this.state.dataToBeEdited} handleEditedData={this.handleEditedData} />
                                            <ConfirmationDialog
                                                openDialog={this.state.showDialog}
                                                btnLoading={this.state.loading}
                                                headingText={t('save_revision_type_heading')}
                                                paragraphText={t('save_revision_type_sub_title')}
                                                btnCancelText={t('cancel')}
                                                btnOkText={t('save')}
                                                handleCancel={() => this.setState({ showDialog: false })}
                                                handleOk={() => handleSubmit()}
                                                showRedButton={false}
                                            />
                                        </React.Fragment>
                                    )}
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <StageOnePartTwoForm
                    data-test-id="partTwoForm"
                    isExpanded={this.props.isExpanded}
                    innerState={this.state}
                    policyID={this.state.policyId}
                    revisionRequestID={this.props.revisionRequestID}
                    handleCheckbox={this.handleCheckbox}
                    partTwoApiCall={this.submitStageOne}
                    handleToggle={() => { this.setState({ isChecked: !this.state.isChecked }) }}
                    openEditDialog={(data: any) => {
                        this.setState({isEditDialog: true, dataToBeEdited: data})
                    }}
                    handleInputText={(value: string) => { this.setState({ handOverNoteText: value }) }}
                    handOverNoteSubmit={this.handOverNoteSubmit}
                    markPolicyAsLost={this.markPolicyAsLost}
                    showMarkAsLostDialog={this.showMarkAsLostDialog}
                    handleHandOverNoteDialog={this.handleHandOverNoteDialog}
                    handleCsvImport={this.props.handleCsvImport}
                    handleAccordian={this.handleAccordian}
                    partTwoSchema={this.partTwoSchema}
                    rrStageOneData={this.props.rrStageOneData}
                    savePolicyAsDraft={this.savePolicyAsDraft}
                    currentSteps={this.props.currentSteps}
                    />
                </React.Fragment>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const useStyles = ({
    customerDetailsAccordion: {
        paddingTop: "10px",
        paddingBottom: "10px",
        "& .MuiAccordion-root-34.Mui-disabled, .MuiAccordion-root-42.Mui-disabled": {
            backgroundColor: "white",
        },
        " & .MuiAccordion-root.Mui-disabled": {
            backgroundColor: "white",
        }
    },
    accordionStyle: {
        background: "white !important",
        "border-radius": "10px !important"
    },
    accordianHeading: {
        fontWeight: 600
    },
    saveBtnDiv: {
        marginTop: "28px",
        display: 'flex',
        alignItems: 'center'
    },
    insuranceTypeStyle: {
        "padding-bottom": "0px !important"
    },
    saveBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "height": "55px",
        "width": "150px",
        borderRadius: "10px"
    },
    contactDetailDiv: {
        paddingTop: "20px"
    },
    contactForm: { width: "100%" },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    bottomBorderAlignment: {
        marginTop: "10px",
        marginBottom: "20px"
    },
    divierStyle: {
        fontWeight: 500,
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        marginTop: "10px",
        marginBottom: "20px",
        paddingTop: "15px"
    },
    noteMessage: {
        padding: "0px 8px",
        fontSize: "14px",
        marginBottom: "10px"
    }

});
// Customizable Area End

export default withStyles(useStyles)(withTranslation()(StageOneForm));
