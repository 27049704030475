import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  classes: any;
  importFor: string;
  isPolicyRequestUploadingCsvFile: boolean,
  policyRequestPage: number;
  policyRequestRowPerPage: number;
  policyRequestTotalPage: number;
  isLoading:boolean;
  policyRequestCsvData:object[],
  policyRequestShowAlert: boolean,
  policyRequestAlertMessage: string;
  policyRequestAlertType: string;
  policyRequestErrorMessage: string;
  policyRequestErrorFile: string;
  isPolicyRequestSaveBtnClicked: boolean;
  handlePolicyRequestAnotherCSVUpload: any;
  downloadPolicyRequestFile: any,
  handlePolicyRequestSaveCSVUpload: any;
  handlePolicyRequestCSVUploader: any;
  handlePolicyRequestChangePage: any;
  handlepolicyRequestRowPerPage: any;
  policyRequestTableBody: any;
  closePolicyRequestToast: any 
  // Customizable Area End

}

interface S {
  // Customizable Area Start
  isArabicLanguage: boolean;
  isUploadingCsvFile: boolean;
  page: number;
  rowPerPage: number;
  totalPage: number;
  isLoading:boolean;
  policyRequestCsvData:object[],
  showAlert: boolean,
  alertMessage: string;
  alertType: string;
  errorMessage: string;
  errorFile: string;
  isSaveBtnClicked: boolean;
  policyInfo:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PolicyRequestCsvImportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadPolicyRequestCsvAPIMessage: string = '';
  getUploadedPolicyRequestCsvAPIMessage: string = '';
  savePolicyRequestCsvAPIMessage: string = ''
  policyRequestFileId: any;
  deletePolicyRequestCsvAPIMessage: string = '';
  sampleCsvPolicyRequestAPIKey:string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
     // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage)

    ];

    this.state = {
     // Customizable Area Start
     isArabicLanguage:
        (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      isUploadingCsvFile: false,
      page: 1,
      rowPerPage: 10,
      totalPage: 1,
      isLoading:false,
      policyRequestCsvData:[],
      alertMessage: '',
      showAlert: false,
      alertType: '',
      errorMessage: '',
      errorFile: '',
      isSaveBtnClicked: false,
      policyInfo:{}
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
 
    // Customizable Area Start
    // Customizable Area End
  }

  

 
  async receive(from: string, message: Message) {
     // Customizable Area Start
     this.setUpdatePolicyRequestCsv(message);
     this.setSavePolicyRequestCsv(message);
     this.fetchPolicyRequestCsvList(message);
     this.deletePolicyRequestUploadedCsvList(message);
     this.getNavigationParams(message)
    this.receiveDownloadedSampleCsvForPolicyRequest(message)
     // Customizable Area End

  }
  
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
   
    
  }
// Customizable Area Start

handlePolicyRequestCSVUploader=(file: any)=>{
  this.setState({isUploadingCsvFile:true})
  this.uploadPolicyRequestCsv(file)
}

closePolicyRequestToast = () => {
  this.setState({ showAlert: false })
}

handlePolicyRequestAnotherCSVUpload=(file: any)=>{
  this.uploadPolicyRequestCsv(file, this.policyRequestFileId)
}

handlePolicyRequestSaveCSVUpload=()=>{
  this.setState({isSaveBtnClicked: true})
  this.savePolicyRequestCsv(this.policyRequestFileId)
}

handlePolicyRequestChangePage = (event: any, page: number) => {
  this.setState({ page: page });
};

handlePolicyRequestRowPerPage = (event: any) => {
  this.setState({ rowPerPage: parseInt(event.target.value) });
  this.setState({ page: 1 });
};

downloadPolicyRequestFile = (fileUrl: string) => {
  
  const policyRequestlink = document.createElement('a');
  policyRequestlink.href = fileUrl;
  policyRequestlink.style.display = 'none';
  document.body.appendChild(policyRequestlink);
  policyRequestlink.click();
  document.body.removeChild(policyRequestlink);
} 

setAlertPolicyRequest = (responseMessage: string,alertType: string = 'error') => {
  this.setState({ showAlert: true, alertMessage: responseMessage, alertType: alertType})
}

async componentDidUpdate(
  prevProps: Readonly<Props>,
  prevStatePolicyRequest: Readonly<S>,
  snapshot?: SS | undefined
) {
  if (
    (prevStatePolicyRequest.page !== this.state.page ||
      prevStatePolicyRequest.rowPerPage !== this.state.rowPerPage)
  ) {
    const {page, rowPerPage} = this.state
    this.getPolicyRequestUploadedCsv(page, rowPerPage);
  }

}

getPolicyRequestFormData = (file :  any, file_id: number| string) => {
  const formData = new FormData();
  file && formData.append('data[file]',file)
  file_id && formData.append('data[file_id]', file_id as string)
  
  return formData
}

uploadPolicyRequestCsv = (file: any, file_id?: number) => {
  this.setState({
    isLoading:true
  })
  this.uploadPolicyRequestCsvAPIMessage = this.apiPolicyRequestCall({
    type : 'FormData',
    method: 'POST',
    body: this.getPolicyRequestFormData(file, file_id as number),
    endPoint: `bx_block_policies/policies/upload_policy_csv?id=${this.state.policyInfo.id}`,
  })
}

getPolicyRequestUploadedCsv = (page: number, perPage: number) => {
  this.getUploadedPolicyRequestCsvAPIMessage = this.apiPolicyRequestCall({
    type : 'application/json',
    method: 'GET',
    endPoint: `bx_block_policies/policies/fetch_csv_uploaded_data?page=${page}&per_page=${perPage}&file_id=${this.policyRequestFileId}&id=${this.state.policyInfo.id}`,
  })
}

savePolicyRequestCsv = (file_id: number ) => {
  this.savePolicyRequestCsvAPIMessage = this.apiPolicyRequestCall({
    type : 'FormData',
    method: 'PUT',
    body: this.getPolicyRequestFormData(null, file_id),
    endPoint: `bx_block_policies/policies/update_csv_uploaded_data?id=${this.state.policyInfo.id}`
  })
}

destroyPolicyRequestUploadedCsv = (fileId: string) => {
  this.deletePolicyRequestCsvAPIMessage = this.apiPolicyRequestCall({
    type : 'application/json',
    method: 'DELETE',
    endPoint: `bx_block_policies/policies/destroy_csv_uploaded_data?file_id=${fileId}&id=${this.state.policyInfo.id}`
  })
}

downloadSampleCsvForPolicyRequest = () => {
  this.sampleCsvPolicyRequestAPIKey = this.apiPolicyRequestCall({
    type : 'application/json',
    method: 'GET',
    endPoint: `/bx_block_sample_file/sample_files?name=${this.state.policyInfo.type === 'emp' ? 'Employee' : 'Vehicle'}`,
  })
}

apiPolicyRequestCall(data: any) {
  const { contentType, method, endPoint, body, type } = data;
  const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
  const token = userData.token || "";

  let header: any = {
    token: token,
    "Content-Type": contentType
  };

  const policyRequestCsvImportMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  policyRequestCsvImportMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  policyRequestCsvImportMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  policyRequestCsvImportMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body && type !== 'FormData' ?
  policyRequestCsvImportMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    ) : policyRequestCsvImportMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    ) ;

  runEngine.sendMessage(policyRequestCsvImportMessage.id, policyRequestCsvImportMessage);
  return policyRequestCsvImportMessage.messageId;
}
getNavigationParams =(message:any)=>{
  if(getName(MessageEnum.NavigationPayLoadMessage)===message.id){
    const naviParams = message.getData(getName(MessageEnum.PolicyRequestPayload))
    this.setState({policyInfo:JSON.parse(naviParams)})
  }
}

receiveDownloadedSampleCsvForPolicyRequest = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.sampleCsvPolicyRequestAPIKey !== null &&
    this.sampleCsvPolicyRequestAPIKey ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const saveSampleCsv = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (saveSampleCsv?.url) {
      this.downloadPolicyRequestFile(saveSampleCsv?.url)
    } else {
      this.setAlertPolicyRequest(saveSampleCsv?.error)
    }
  }
}

setUpdatePolicyRequestCsv = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.uploadPolicyRequestCsvAPIMessage !== null &&
    this.uploadPolicyRequestCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const updatePolicyRequestCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
    try {
      if(updatePolicyRequestCsvAPIResponse?.data){
        if (updatePolicyRequestCsvAPIResponse?.data.error_message || updatePolicyRequestCsvAPIResponse?.data.error_file) {
          this.setState({errorMessage: updatePolicyRequestCsvAPIResponse?.data.error_message, errorFile: updatePolicyRequestCsvAPIResponse?.data.error_file})
          this.setAlertPolicyRequest(updatePolicyRequestCsvAPIResponse?.data.error_message)
        } 
        if(updatePolicyRequestCsvAPIResponse?.data.file_id) {
          if(!updatePolicyRequestCsvAPIResponse?.data.error_message) {
            this.setState({errorMessage: '', errorFile: ''})
          }
          window.localStorage.setItem("insurer_file_id", JSON.stringify(updatePolicyRequestCsvAPIResponse?.data?.file_id))
          this.policyRequestFileId = updatePolicyRequestCsvAPIResponse?.data?.file_id;
          this.getPolicyRequestUploadedCsv(this.state.page, this.state.rowPerPage)
        }
      } else {
        this.setAlertPolicyRequest(updatePolicyRequestCsvAPIResponse?.error)
      }
    } catch (error) {
      //ignore
    } finally {
      this.setState({isLoading:false})
    }
  }
}

setSavePolicyRequestCsv = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.savePolicyRequestCsvAPIMessage !== null &&
    this.savePolicyRequestCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const savePolicyRequestCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

    if (savePolicyRequestCsvAPIResponse?.message) {
      this.setAlertPolicyRequest(savePolicyRequestCsvAPIResponse?.message, 'success')
      this.policyRequestFileId = ''
      setTimeout(() => {
        window.history.back()
      }, 700)
    } else {
      this.setAlertPolicyRequest(savePolicyRequestCsvAPIResponse.errors)
    }
  }
}

fetchPolicyRequestCsvList = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getUploadedPolicyRequestCsvAPIMessage !== null &&
    this.getUploadedPolicyRequestCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const fetchPolicyRequestCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (fetchPolicyRequestCsvAPIResponse?.data) {
      this.setState({isUploadingCsvFile: true})
      this.setState({policyRequestCsvData: fetchPolicyRequestCsvAPIResponse.data, totalPage: fetchPolicyRequestCsvAPIResponse.meta.total_page})
    } else {
      this.setAlertPolicyRequest(fetchPolicyRequestCsvAPIResponse.errors)
    }
  }
}

deletePolicyRequestUploadedCsvList = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.deletePolicyRequestCsvAPIMessage !== null &&
    this.deletePolicyRequestCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const deletePolicyRequestCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (deletePolicyRequestCsvAPIResponse?.message) {
      this.setAlertPolicyRequest(deletePolicyRequestCsvAPIResponse?.message);
    } else {
      this.setAlertPolicyRequest(deletePolicyRequestCsvAPIResponse?.errors)
    }
  }
}
setArabicLanguage = (value: boolean) => {
  const language = value === true ? "ar" : "en";
  window.localStorage.setItem("lang", language);
  this.setState({
    isArabicLanguage: value
  });
};
componentWillUnmount(): any {
  this.policyRequestFileId && this.destroyPolicyRequestUploadedCsv(this.policyRequestFileId)
}
  // Customizable Area End
}
