import React from "react";
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    Radio,
    RadioGroup,
    Typography,
    CircularProgress
} from "@material-ui/core";
// @ts-ignore
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import StageSevenController from "../../../../blocks/TaskAllocator/src/PolicyStage/StageSevenController";
import LabelWithIcon from "../../Common/LabelWithIcon";
import TextInput from "../../Common/TextInput";
import Toster from "../../Common/Toster"
import FileUpload from "../../Common/FileUpload";
import { ErrorMessage } from "formik";
import DownloadFile from "../../Common/DownloadFile";

export class CommissionForm extends StageSevenController {

    render() {
        const { t, classes, setFieldValue, values, title, installmentIndex, flowValue, formikRef, checkStepProps, checkDisable } = this.props;
        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') || '{}')
        const dummyData = stageSixData?.attributes?.quotation_insurance_infos?.data?.filter((item: any) => {
            return item?.attributes?.approval === true
        });
        const getTitle = (fieldName: string) => {
            if (title === 'Installment') {
                return `stageData.flow${this.convertToWord(flowValue as number)}Data.installment_attributes[${installmentIndex}].${fieldName}`
            }
            else {
                return `stageData.flow${this.convertToWord(flowValue as number)}Data.${fieldName}`
            }
        }

        const checkInstallments = (fieldname: string) => {
            if (this.convertToWord(this.props.flowValue as number) === "Seven" && values.stageData?.flowSevenData?.installment_attributes?.[installmentIndex as number]?.installment_paid !== "yes") {
                return true
            }
            if (this.convertToWord(this.props.flowValue as number) === "Eight" && values.stageData?.flowEightData?.installment_attributes?.[installmentIndex as number]?.installment_paid !== "yes") {
                return true
            }
            if ( this.convertToWord(this.props.flowValue as number) === "Nine"  && (values.stageData?.flowNineData?.installment_attributes?.[installmentIndex as number]?.installment_paid === 'yes' && fieldname !== '' && checkDisable)) {
                return true;
            } else if (values.stageData?.flowNineData?.installment_attributes?.[installmentIndex as number]?.installment_paid === 'yes') {
                return false;
            } else {
                return true;
            }
        }

        const handleVat = (e: any) => {
            {
                setFieldValue(getTitle('vat_on_siib_commission'), e.target.value)
                if (e.target.value === "no") {
                    if (title !== 'Installment') {
                        setFieldValue(getTitle('commission_amount'),
                            (parseInt(values.paid_amount) / 1.15 * (parseInt(values?.stageData?.flowFiveData?.commission_rate || values?.stageData?.flowSixData?.commission_rate || values?.stageData?.flowNineData?.commission_rate) / 100)).toFixed(2))
                        setFieldValue(getTitle('payable_to_insurer'), ((parseInt(values?.paid_amount) -
                            parseInt(values.paid_amount) / 1.15 * (parseInt(values?.stageData?.flowFiveData?.commission_rate || values?.stageData?.flowSixData?.commission_rate || values?.stageData?.flowNineData?.commission_rate) / 100)
                        )).toFixed(2))
                    }
                    else {
                        setFieldValue(getTitle('commission_amount'),
                            (parseInt(values?.stageData?.flowNineData?.installment_attributes?.[installmentIndex ?? 0]?.installment_amount) / 1.15 * (parseInt(values?.stageData?.flowNineData?.installment_attributes?.[installmentIndex ?? 0]?.commission_rate) / 100)).toFixed(2))
                        setFieldValue(getTitle('payable_to_insurer'), ((parseInt(values?.stageData?.flowNineData?.installment_attributes?.[installmentIndex ?? 0]?.installment_amount) -
                            parseInt(values?.stageData?.flowNineData?.installment_attributes?.[installmentIndex ?? 0]?.installment_amount) / 1.15 * (parseInt(values?.stageData?.flowNineData?.installment_attributes?.[installmentIndex ?? 0]?.commission_rate) / 100)
                        )).toFixed(2))
                    }
                }
                else {
                    if (title !== 'Installment') {
                        setFieldValue(getTitle('commission_amount'),
                            (parseInt(values.paid_amount) * (parseInt(values?.stageData?.flowFiveData?.commission_rate || values?.stageData?.flowSixData?.commission_rate || values?.stageData?.flowNineData?.commission_rate) / 100)).toFixed(2))
                        setFieldValue(getTitle('payable_to_insurer'), ((parseInt(values?.paid_amount) -
                            parseInt(values.paid_amount) * (parseInt(values?.stageData?.flowFiveData?.commission_rate || values?.stageData?.flowSixData?.commission_rate || values?.stageData?.flowNineData?.commission_rate) / 100)
                        )).toFixed(2))
                    }
                    else {
                        setFieldValue(getTitle('commission_amount'),
                            (parseInt(values?.stageData?.flowNineData?.installment_attributes?.[installmentIndex ?? 0]?.installment_amount) * (parseInt(values?.stageData?.flowNineData?.installment_attributes?.[installmentIndex ?? 0]?.commission_rate) / 100)).toFixed(2))
                        setFieldValue(getTitle('payable_to_insurer'), (parseInt(values?.stageData?.flowNineData?.installment_attributes?.[installmentIndex ?? 0]?.installment_amount) -
                            parseInt(values?.stageData?.flowNineData?.installment_attributes?.[installmentIndex ?? 0]?.installment_amount) * (parseInt(values?.stageData?.flowNineData?.installment_attributes?.[installmentIndex ?? 0]?.commission_rate) / 100)).toFixed(2))
                    }
                }
            }
        };

        return (
            <>
                <Toster
                    tosterText={this.state.alertMessage}
                    showToster={this.state.showAlert}
                    // @ts-ignore
                    tosterType={this.state.alertType}
                    handleCloseToster={() => this.setState({ showAlert: false })}
                />
                <Grid item xs={12} container className={classes.bottomAlignment}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('commission_rate')} />
                        <TextInput
                            className={classes.textInputStyle}
                            name={getTitle('commission_rate')}
                            type="text"
                            disabled={title === 'Installment' ? checkInstallments(values.stageData?.flowNineData?.installment_attributes?.[installmentIndex as number]?.commission_rate) : checkDisable}
                            data-test-id="commission_rate"
                            onChange={(e: any) => {
                                setFieldValue(getTitle('commission_rate'), e.target.value)
                            }}
                            onBlur={() => checkStepProps(values, flowValue as number)}
                            endAdornment={'%'}
                        />
                        <ErrorMessage
                            name={getTitle('commission_rate')}
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('commission_amount')} />
                        <TextInput
                            disabled={true}
                            className={classes.textInputStyle}
                            name={getTitle('commission_amount')}
                            type="text"
                            data-test-id="commission_amount"
                            onChange={(e: any) => setFieldValue(getTitle('commission_amount'), e.target.value)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <div> &nbsp;SAR&nbsp;</div>
                                </InputAdornment>
                            }
                        />
                        <ErrorMessage
                            name={getTitle('commission_amount')}
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />


                    </Grid>
                    <Grid item xs={4}>
                        <LabelWithIcon label={t('deduct_siib_commission_with_vat')} />
                        <FormControl className={classes?.textInputStyle} style={{ marginLeft: 15, marginTop: 17 }}>
                            <RadioGroup
                                row
                                data-test-id="vat_on_commission"
                                name={getTitle("vat_on_siib_commission")}
                                onChange={(e: any) => handleVat(e)}
                                value={values?.stageData?.flowFiveData?.vat_on_siib_commission || values?.stageData?.flowSixData?.vat_on_siib_commission || (title === 'Installment' ? values?.stageData?.flowNineData?.installment_attributes?.[installmentIndex || 0]?.vat_on_siib_commission : values?.stageData?.flowNineData?.vat_on_siib_commission)}
                                onBlur={() => checkStepProps(values, flowValue as number)}
                            >
                                <FormControlLabel
                                    disabled={title === 'Installment' ? checkInstallments(values.stageData?.flowNineData?.installment_attributes?.[installmentIndex as number]?.vat_on_siib_commission) : checkDisable}
                                    value={'yes'} control={<Radio color="primary" />} label={t("yes")} />
                                <FormControlLabel
                                    disabled={title === 'Installment' ? checkInstallments(values.stageData?.flowNineData?.installment_attributes?.[installmentIndex as number]?.vat_on_siib_commission) : checkDisable}
                                    value={'no'} control={<Radio color="primary" />} label={t("no")} />
                            </RadioGroup>
                        </FormControl>
                        <ErrorMessage
                            name={getTitle('vat_on_siib_commission')}
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                    </Grid>
                </Grid >
                <Grid item xs={12} container className={classes.bottomAlignment}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('invoice_date')} />
                            <TextInput
                                className={classes.textInputStyle}
                                name={getTitle('invoice_date')}
                                type={"date"}
                                disabled={title === 'Installment' ? checkInstallments(values.stageData?.flowNineData?.installment_attributes?.[installmentIndex as number]?.invoice_date) : checkDisable}
                                onChange={(e: any) => setFieldValue(getTitle('invoice_date'), e.target.value)}
                                onBlur={() => checkStepProps(values, flowValue as number)}
                            />
                            <ErrorMessage
                                name={getTitle('invoice_date')}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                        </React.Fragment>
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('receipt_date')} />
                            <TextInput
                                className={classes.textInputStyle}
                                name={getTitle('receipt_date')}
                                type={"date"}
                                disabled={title === 'Installment' ? checkInstallments(values.stageData?.flowNineData?.installment_attributes?.[installmentIndex as number]?.receipt_date) : checkDisable}
                                onChange={(e: any) => setFieldValue(getTitle('receipt_date'), e.target.value)}
                                onBlur={() => checkStepProps(values, flowValue as number)}
                            />
                            <ErrorMessage
                                name={getTitle('receipt_date')}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                        </React.Fragment>
                    </Grid>
                    <Grid item xs={4} container style={{ marginTop: 28, marginBottom: 9 }} >
                        <Button
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            color="primary"
                            disabled={(this.state.invAndRcptBtnLoadingForInvoice || this.state.invAndRcptBtnLoadingForReceipt) || (flowValue && flowValue === 9 && installmentIndex !== undefined ? (this.checkInstallmentInvAndRcptBtnDisabled(values, flowValue, installmentIndex) !== undefined ? this.checkInstallmentInvAndRcptBtnDisabled(values, flowValue, installmentIndex) : true) : this.checkInvAndRcptBtnDisabled(values, flowValue))}
                            className={(this.state.invAndRcptBtnLoadingForInvoice || this.state.invAndRcptBtnLoadingForReceipt) ? classes.primaryRvBtn : (flowValue && flowValue === 9 && installmentIndex !== undefined ? (this.checkInstallmentInvAndRcptBtnDisabled(values, flowValue, installmentIndex) === undefined ? classes.grayBtn : this.checkInstallmentInvAndRcptBtnDisabled(values, flowValue, installmentIndex) ? classes.grayBtn : classes.primaryRvBtn) : (this.checkInvAndRcptBtnDisabled(values, flowValue) ? classes.grayBtn : classes.primaryRvBtn))}
                            onClick={() => { installmentIndex === undefined ? this.generateInvoiceAndReceipt(values, dummyData, formikRef, flowValue) : this.generateInstallmentInvoiceAndReceipt(values, dummyData, formikRef, flowValue, installmentIndex) }}
                        >
                            {!this.state.invAndRcptBtnLoadingForInvoice && !this.state.invAndRcptBtnLoadingForReceipt && t('create_invoice_receipt')}
                            {(this.state.invAndRcptBtnLoadingForInvoice || this.state.invAndRcptBtnLoadingForReceipt) && ( <CircularProgress size={25} />)}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} container className={classes.bottomAlignment}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('invoice')} />
                        <DownloadFile
                            data-test-id="invoice"
                            disabled={true}
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            isEditPage={true}
                            fileName={installmentIndex !== undefined ? values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex] && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex].invoice_doc?.file_name : values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.invoice_doc?.file_name || ""}
                            filePath={installmentIndex !== undefined ? values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex] && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex].invoice_doc?.url : values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.invoice_doc?.url || ""}
                            inputName={getTitle('invoice_doc')}
                        />
                        <ErrorMessage
                            name={getTitle('invoice_doc')}
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('siib_invoice_id')} />
                            <TextInput
                                disabled={true}
                                className={classes.textInputStyle}
                                name={getTitle('siib_invoice_id')}
                                type="text"
                                data-test-id="siib_invoice_id"
                                onBlur={() => checkStepProps(values, flowValue as number)}
                            />
                            <ErrorMessage
                                name={getTitle('siib_invoice_id')}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                        </React.Fragment>
                    </Grid>
                </Grid>
                <Grid item xs={12} container className={classes.bottomAlignment}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('receipt')} />
                        <DownloadFile
                            data-test-id="receipt"
                            disabled={true}
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            isEditPage={true}
                            fileName={installmentIndex !== undefined ? values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex] && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex].receipt_doc?.file_name : values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.receipt_doc?.file_name || ""}
                            filePath={installmentIndex !== undefined ? values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex] && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.installment_attributes[installmentIndex].receipt_doc?.url : values?.stageData && values?.stageData[`flow${this.convertToWord(flowValue as number)}Data`]?.receipt_doc?.url || ""}
                            inputName={`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.receipt_doc`}
                        />
                        <ErrorMessage
                            name={getTitle('receipt_doc')}
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('siib_receipt_id')} />
                            <TextInput
                                className={classes.textInputStyle}
                                disabled={true}
                                name={getTitle('siib_receipt_id')}
                                type="text"
                                data-test-id="siib_receipt_id"
                                onBlur={() => checkStepProps(values, flowValue as number)}
                            />
                            <ErrorMessage
                                name={getTitle('siib_receipt_id')}
                                className="field-error"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                        </React.Fragment>
                    </Grid>
                </Grid>
            </>
        )
    }
}

const useStyles: any = ({
    bottomAlignment: {
        marginBottom: "20px"
    },
    grayBtn: {
        textTransform: 'none',
        borderRadius: 5,
        width: '100%',
        fontSize: 17,
        backgroundColor: "#979090",
        color: "white",
        marginTop: '8px',
    },
    primaryRvBtn: {
        "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        textTransform: 'none',
        borderRadius: 5,
        width: '100%',
        fontSize: 17,
        backgroundColor: "#979090",
        color: "white",
        marginTop: '8px',
    },
});


export default withStyles(useStyles)(withTranslation()(CommissionForm));