import * as Yup from 'yup';
// import { emptyStringToUndefined } from '../Common/EmptyStringToUndefined';

const StageFourFormSchema = (isArabicLanguage: boolean) => {
    const schema = {
      insurerDetails: Yup.array(Yup.object().shape({
        quotationReceived: Yup.string().required(isArabicLanguage ? 'التعليق مطلوب' : "Comment is required.").nullable(),
        comment: Yup.string().required(isArabicLanguage ? 'التعليق مطلوب' : "Comment is required.").nullable(),
        quotationAmount: Yup.string().when('quotationReceived', {
          is: "yes",
          then: Yup.string().required(isArabicLanguage ? 'المبلغ مطلوب' : "Amount is required.").nullable(),
          otherwise: Yup.string().notRequired().nullable()
        }),
        quotationId: Yup.string().when('quotationReceived', {
          is: "yes",
          then: Yup.string().required(isArabicLanguage ? 'المعرف مطلوب' : "ID is required.").nullable(),
          otherwise: Yup.string().notRequired().nullable()
        }),
        dateofReceiving: Yup.date().when('quotationReceived', {
          is: "yes",
          then: Yup.date().required(isArabicLanguage ? 'التاريخ مطلوب' : "Date is required.").nullable(),
          otherwise: Yup.date().notRequired().nullable()
        }),
        proposal: Yup.mixed().when('quotationReceived', {
          is: 'yes',
          then: Yup.mixed().test(
            "isNotEmpty", 'Please upload proposal document',
            function (value) {
              const file = this.resolve(Yup.ref("proposal"));
              
              if ((typeof file === "string" && file === "") || (file === undefined)) {
                return false;
              } else if (
                file &&
                typeof (file) === "object" &&
               Object.keys(file).length===0
              ) {
                return false;
              } else {
                return true;
              }
            }
          ).test(
            "fileSize",
            "Proposal document too large, allowed only less than 10 MB",
            (file) => {
              if (typeof (file) === "string" && file !== "") {
                return true;
              } else
                if (file && file.size) {
                  return file.size <= 10000000;
                } else {
                  return true;
                }
            }
          ),
          otherwise: Yup.mixed().notRequired()
        }),

      })),
      premiumCalculation: Yup.mixed()
        .test(
          "fileSize",
          isArabicLanguage ? "مستند الحساب المميز كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "premium calculation document too large, allowed only less than 10 MB", (file) => {
            if (typeof (file) === "string" && file !== "") {
              return true;
            } else
              if (file && file.size) {
                return file.size <= 10000000;
              } else {
                return true;
              }
          }
        ),
      comparisionSheet: Yup.mixed()
        .test(
          "fileSize",
          isArabicLanguage ? "مستند ورقة المقارنة كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "comparision sheet document too large, allowed only less than 10 MB",
          (file) => {
            if (typeof (file) === "string" && file !== "") {
              return true;
            } else
              if (file && file.size) {
                return file.size <= 10000000;
              } else {
                return true;
              }
          }
        ),

    }
    return schema;
  }

export default StageFourFormSchema;