import React from "react";
import {
    Button,
    Typography,
    Container,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    List,
    ListItem,
    IconButton,
    InputAdornment,
} from "@material-ui/core";
import { ErrorMessage, FieldArray, Form, Formik, useField } from "formik";
import * as Yup from "yup";
import { ExpandMore, CloseSharp, Close, CheckCircle, Error } from '@material-ui/icons'
// @ts-ignore
import { withTranslation } from 'react-i18next';
import CustomerProfileController from "../../../blocks/catalogue/src/Customer/CustomerProfileController";
import LabelWithIcon from "../Common/LabelWithIcon";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import TextInput from "../Common/TextInput";
import FileUpload from "../Common/FileUpload";
import MultiSelectFormik from "../Common/MultiSelectFormik";
import SelectBoxFormik from "../Common/SelectBoxFormik";
import { Alert } from '@material-ui/lab';
import ConfirmationDialog from "../Common/ConfirmationDialog.web";
import GetHeadingTitle, { GetBtnTxt, GetParagraphTxt } from "./GetDialoagContent";
import CustomerFormSchema from "./CustomerFormSchema";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

export class CustomerDetails extends CustomerProfileController {

    render() {
        // @ts-ignore
        const { t } = this.props;
        const sagmentOption = [
            { value: 'Mega KA', label: t('mega_ka') },
            { value: 'Corporate', label: t('corporate') },
            { value: 'SME', label: t('sme') },
            { value: 'Government', label: t('government') },
        ]
        const languageOption = [
            { value: 'English', label: "English" },
            { value: 'Arabic', label: "Arabic" }
        ]
        const riskOption = [
            { value: "Hara", label: "Hara" },
            { value: "Malaz", label: "Malaz" },
            { value: "Olaya", label: "Olaya" },
            { value: "Maather", label: "Maather" },
            { value: "Sulaimanya", label: "Sulaimanya" },
            { value: "Simeshi", label: "Simeshi" },
            { value: "Khaleej District", label: "Khaleej District" },
            { value: "Manar Area", label: "Manar Area" },
            { value: 'Corniche', label: "Corniche" }
        ]

        const backToCustomer = () => {
            window.location.href = "/Customers"
        }

        const isEditButtonVisible = () => {
            const userData = JSON.parse(window.localStorage.getItem("loginData") || '{}')
            const userRole = userData && userData?.role;
            if (this.state.isEdit && ((userRole !== "Finance") && (userRole !== "finance") && (userRole !== "operations") && (userRole !== "Operations"))) {
                return true
            } else {
                return false
            }
        }

        const getTooltipText = (value:string) => {
            switch (value) {
                case ("New" || "new"):
                    return t('default_state')
                case ("Open" || "open"):
                    return t('one_or_more_requests_in_process')
                case ("Partial" || "partial"):
                    return t('minimum_of_one_won_requests_and_one_or_more_lost')
                case ("Won" || 'won'):
                    return t('minimum_of_1_won_requests_no_lost_requests')
                case ("Lost" || 'lost'):
                    return t('only_lost_requests')
                default:
                    return "";
            }
        }

        const isCloseBtnVisible = (isEditPage: boolean, previousCount: number, count: number) => {
            if (!isEditPage) {
                return true;
            } else {
                const show = (isEditPage && (previousCount < count + 1))
                return (isEditPage && (previousCount < count + 1))
            }
        }

        // @ts-ignore
        const { classes } = this.props;

        return (
            <div data-test-id="CustomerDetails">
                <ThemeProvider theme={theme} >
                    <Container maxWidth="xl">
                        {this.state.showAlert && (
                            <div data-test-id="alertDiv" className={classes.alertDiv} >
                                <Alert className={this.state.alertType === "error" ? classes.redAlertMessage : classes.greenAlertMessage} icon={this.state.alertType === "success" ? <CheckCircle className={classes.alertIconStyle} /> : <Error className={classes.alertIconStyle} />} severity={this.state.alertType === "success" ? "success" : "error"}>
                                    <div className={classes.displayFlex} >
                                        {this.state.alertMessage}
                                        <Close className={classes.alertCloseIcon} onClick={() => this.closeAlertMessage()} />
                                    </div>
                                </Alert>
                            </div>
                        )}
                        <Grid container spacing={2}>
                            <Grid item xs={6}>  <h3> {!this.state.isEdit ? t('add_customer') : `${this.state.customerName}`}</h3> </Grid>
                            {
                                isEditButtonVisible() && !this.state.enableEditing && (
                                    <Grid item xs={6}>
                                        <div className={classes.displayFlexEnd} >
                                            <Button
                                                className={classes.primaryBtnStyle}
                                                disabled={this.state.is_archived}
                                                color="primary"
                                                data-test-id="btnForNewPolicy"
                                                id="btnForNewPolicy"
                                                onClick={() => this.handleEnableEditing(true)}
                                            >
                                                {t('edit_details')}
                                            </Button>
                                        </div>
                                    </Grid>
                                )}
                        </Grid>
                        <Grid>
                            <Formik
                                enableReinitialize={this.state.isEdit ? true : false}
                                initialValues={{
                                    customerName: this.state.customerName,
                                    status: this.state.status,
                                    customerId: this.state.customerId,
                                    sourceName: this.state.sourceName,
                                    industry: this.state.industry,
                                    VATNumber: this.state.VATNumber,
                                    segment: this.state.segment,
                                    branchCount: this.state.branchCount,
                                    branchDetail: this.state.branchDetail,
                                    contactsCount: this.state.contactsCount,
                                    contactsDetail: this.state.contactsDetail,
                                    country: "Saudi Arabia",
                                    region: this.state.region,
                                    city: this.state.city,
                                    district: this.state.district,
                                    addressId: this.state.addressId,
                                    streetName: this.state.streetName,
                                    buildingNumber: this.state.buildingNumber,
                                    postalCode: this.state.postalCode,
                                    unitNumber: this.state.unitNumber,
                                    additionalNumber: this.state.additionalNumber,
                                    addressProof: this.state.addressProof,
                                    risk: this.state.risk,
                                }}
                                validationSchema={Yup.object().shape(CustomerFormSchema(this.state.isArabicLenguage))}
                                validateOnMount={true}
                                validateOnBlur={true}
                                onSubmit={async (values, actions) => {
                                    if (this.state.isEdit) {
                                        this.editCustomer(values);
                                    } else {
                                        this.submitCustomerForm(values);
                                    }
                                }}
                            >
                                {({
                                    handleSubmit,
                                    errors,
                                    touched,
                                    values,
                                    setFieldValue,
                                    validateForm
                                }) => (
                                    <React.Fragment>
                                        <Grid className={classes.customerDetailsAccordion} item>
                                            <Accordion data-test-id='p1' className={(this.state.errorSection === 'p1') && classes.errorSection}  expanded={this.state.expandedAccordion === "p1"} onChange={(event, isExpanded) => {
                                                this.handleAccordian(isExpanded, "p1")
                                            }} >
                                                <AccordionSummary
                                                    aria-expanded={true}
                                                    expandIcon={<ExpandMore />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className={classes.accordianHeading} >{t('customer_details')}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2} >
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('customer_name')} />
                                                            <TextInput
                                                                id={"customerName"}
                                                                className={classes.textInputStyle}
                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                name="customerName"
                                                                type="text"
                                                                disabled={!this.state.enableEditing}
                                                                data-test-id="customerNameTxtInput"
                                                            />
                                                            {touched.customerName && errors.customerName ? (
                                                                <Typography color="error" >{errors.customerName}</Typography>
                                                            ) : null}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('status')} tooltip={getTooltipText(values.status)} />
                                                            <TextInput
                                                                id={"status"}
                                                                className={classes.textInputStyle}
                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                name="status"
                                                                type="text"
                                                                disabled={true}
                                                                data-test-id="statusTxtInput"
                                                            />
                                                            {touched.status && errors.status ? (
                                                                <Typography color="error" >{errors.status}</Typography>
                                                            ) : null}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('customer_id')} tooltip={t('this_is_an_internal_ID_that_is_generated_once_you_save_this_form')} />
                                                            <TextInput
                                                                id={"customerId"}
                                                                className={classes.textInputStyle}
                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                name="customerId"
                                                                type="text"
                                                                disabled={true}
                                                                data-test-id="customerIdTxtInput"
                                                            />
                                                            {touched.customerId && errors.customerId ? (
                                                                <Typography color="error" >{errors.customerId}</Typography>
                                                            ) : null}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('source_name')} tooltip={t('source_name_tooltip')} />
                                                            <TextInput
                                                                id={"sourceName"}
                                                                className={classes.textInputStyle}
                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                name="sourceName"
                                                                disabled={!this.state.enableEditing}
                                                                type="text"
                                                                data-test-id="sourceNameTxtInput"
                                                            />
                                                            {touched.sourceName && errors.sourceName ? (
                                                                <Typography color="error" >{errors.sourceName}</Typography>
                                                            ) : null}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('industry')} />
                                                            <TextInput
                                                                id={"industry"}
                                                                className={classes.textInputStyle}
                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                name="industry"
                                                                type="text"
                                                                disabled={!this.state.enableEditing}
                                                                data-test-id="industryTxtInput"

                                                            />
                                                            {touched.industry && errors.industry ? (
                                                                <Typography color="error" >{errors.industry}</Typography>
                                                            ) : null}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('vat_number')} />
                                                            <TextInput
                                                                id={"VATNumber"}
                                                                className={classes.textInputStyle}
                                                                isArabicLenguage={this.state.isArabicLenguage}
                                                                name="VATNumber"
                                                                type="number"
                                                                disabled={!this.state.enableEditing}
                                                                data-test-id="txtInputVATNumber"

                                                            />
                                                            {touched.VATNumber && errors.VATNumber ? (
                                                                <Typography color="error" >{errors.VATNumber}</Typography>
                                                            ) : null}
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                                <Divider/>
                                                <AccordionDetails>
                                                    <Grid item xs={12}>
                                                        <LabelWithIcon label={t('segment')} />
                                                        <MultiSelectFormik
                                                            value={values.segment}
                                                            isMulti={true}
                                                            name='segment'
                                                            disabled={!this.state.enableEditing}
                                                            // @ts-ignore
                                                            onChange={(newValue: any) => setFieldValue(`contactsDetail[${index}].preferredLanguage`, newValue.value)}
                                                            options={sagmentOption}
                                                        />
                                                        {touched.segment && errors.segment ? (
                                                            <Typography color="error" >{errors.segment}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>

                                        <Grid className={classes.customerDetailsAccordion} item>
                                            <Accordion data-test-id='p2' className={(this.state.errorSection === 'p2') && classes.errorSection}  expanded={this.state.expandedAccordion === "p2"} onChange={(event, isExpanded) => {
                                                this.handleAccordian(isExpanded, "p2")
                                            }} >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMore />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Grid id="branchContant" className={classes.branchDetailAccordion} >
                                                        <Typography className={classes.accordianHeading} >{t('branch_details')}</Typography>
                                                        {this.state.expandedAccordion === "p2" && this.state.enableEditing && values.branchDetail.length < 50 && (
                                                            // @ts-ignore
                                                            <Form>
                                                                {/* @ts-ignore */}
                                                                <FieldArray name="branchDetail">
                                                                    {({ push}: {push: (value: any)=> any}) => (
                                                                        <React.Fragment>
                                                                            <Button className={classes.addMoreButton}
                                                                                disabled={!this.state.enableEditing}
                                                                                onClick={(e: any) => {
                                                                                    e.stopPropagation()
                                                                                    push({
                                                                                        first_commercial_license_legal_name: "",
                                                                                        sponsor_IDCR: "",
                                                                                        upload_sponsor_id_cr: ""
                                                                                    })
                                                                                }
                                                                                }
                                                                            >
                                                                                <Typography color="primary" >+{t("add_branch")}</Typography>
                                                                            </Button>
                                                                        </React.Fragment>
                                                                    )}
                                                                </FieldArray>
                                                            </Form>
                                                        )}
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {/* @ts-ignore */}
                                                    <Form className={classes.branchDetails} >
                                                        <List id="branchDetailDiv" className={classes.branchDetailsSubContact}>
                                                            {/* @ts-ignore */}
                                                            <FieldArray name="branchDetail">
                                                                {({ remove }: {remove: (value: any)=> any}) => (
                                                                    <React.Fragment>
                                                                        {values.branchCount > 0 &&
                                                                            values.branchDetail.map((branch: any, index: number) => (
                                                                                <React.Fragment key={index}>
                                                                                    {((index >= 1) && (values.branchDetail.length !== index)) && (
                                                                                        <Divider />
                                                                                    )}
                                                                                    <ListItem>
                                                                                        <Grid container spacing={2} >
                                                                                            {(index >= 1) && (
                                                                                                <Grid
                                                                                                    className={classes.branchListItem}
                                                                                                    item
                                                                                                    xs={12} sm={12} md={12}
                                                                                                >
                                                                                                    <IconButton disabled={!this.state.enableEditing} className={classes.closeIcon} onClick={() => remove(index)}>
                                                                                                        <CloseSharp />
                                                                                                    </IconButton>
                                                                                                </Grid>
                                                                                            )}
                                                                                            <Grid item xs={4}>
                                                                                                <LabelWithIcon label={t('first_commercial_license_legal_name')} />
                                                                                                <TextInput
                                                                                                    id={`branchDetail[${index}].first_commercial_license_legal_name`}
                                                                                                    fullWidth
                                                                                                    className={classes.textInputStyle}
                                                                                                    name={`branchDetail[${index}].first_commercial_license_legal_name`}
                                                                                                    type="text"
                                                                                                    disabled={!this.state.enableEditing}
                                                                                                    value={branch.first_commercial_license_legal_name}
                                                                                                />
                                                                                                {/* @ts-ignore */}
                                                                                                <ErrorMessage
                                                                                                    name={`branchDetail[${index}].first_commercial_license_legal_name`}
                                                                                                    className="field-error"
                                                                                                    render={(msg: string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <LabelWithIcon label={t('sponsor_IDCR')} tooltip={t('commercial_trade_license')} />
                                                                                                <TextInput
                                                                                                    className={classes.textInputStyle}
                                                                                                    fullWidth
                                                                                                    type="number"
                                                                                                    disabled={!this.state.enableEditing}
                                                                                                    name={`branchDetail[${index}].sponsor_IDCR`}
                                                                                                    data-test-id={`branchDetail[${index}].sponsor_IDCR`}
                                                                                                    id={`branchDetail[${index}].sponsor_IDCR`}
                                                                                                    value={branch.sponsor_IDCR}
                                                                                                />
                                                                                                {/* @ts-ignore */}
                                                                                                <ErrorMessage
                                                                                                    name={`branchDetail[${index}].sponsor_IDCR`}
                                                                                                    className="field-error"
                                                                                                    render={(msg: string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <LabelWithIcon label={t('upload_sponsor_id_cr')} tooltip={t('commercial_trade_license')} />
                                                                                                <FileUpload
                                                                                                    eachFileSizeInMB={10}
                                                                                                    maxFilesNumber={5}
                                                                                                    // handleImageUpload={handleImageUpload}
                                                                                                    index={index}
                                                                                                    isEditPage={this.state.isEdit}
                                                                                                    filePath={branch.upload_sponsor_id_cr || ""}
                                                                                                    fileName={branch.upload_sponsor_id_cr_file_name || ""}
                                                                                                    disabled={!this.state.enableEditing}
                                                                                                    inputName={`branchDetail[${index}].upload_sponsor_id_cr`}
                                                                                                    onChange={(event: any) => setFieldValue(`branchDetail[${index}].upload_sponsor_id_cr`, event.target.files[0])}
                                                                                                    onRemove={() => setFieldValue(`branchDetail[${index}].upload_sponsor_id_cr`, {})}
                                                                                                />
                                                                                                {/* @ts-ignore */}
                                                                                                <ErrorMessage
                                                                                                    name={`branchDetail[${index}].upload_sponsor_id_cr`}
                                                                                                    className="field-error"
                                                                                                    render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                                />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </ListItem>
                                                                                </React.Fragment>
                                                                            ))}
                                                                    </React.Fragment>
                                                                )}
                                                            </FieldArray>
                                                        </List>
                                                    </Form>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>

                                        <Grid className={classes.customerDetailsAccordion} item>
                                            <Accordion data-test-id='p3' className={(this.state.errorSection === 'p3') && classes.errorSection}  expanded={this.state.expandedAccordion === "p3"} onChange={(event, isExpanded) => {
                                                this.handleAccordian(isExpanded, "p3")
                                            }} >
                                                <AccordionSummary
                                                    aria-expanded={true}
                                                    expandIcon={<ExpandMore />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Grid id="contactContant" className={classes.branchDetailAccordion} >
                                                        <Typography className={classes.accordianHeading} >{t('contacts')}</Typography>
                                                        {this.state.expandedAccordion === "p3" && this.state.enableEditing && values.contactsDetail.length < 20 && (
                                                            // @ts-ignore
                                                            <Form>
                                                                {/* @ts-ignore */}
                                                                <FieldArray name="contactsDetail">
                                                                    {({ push }:  { push: (value: any) => any }) => (
                                                                        <React.Fragment>
                                                                            <Button className={classes.addMoreButton}
                                                                                disabled={!this.state.enableEditing}
                                                                                onClick={(e: any) => {
                                                                                    e.stopPropagation()
                                                                                    push({
                                                                                        customerFirstName: "",
                                                                                        customerLastName: "",
                                                                                        position: "",
                                                                                        email: "",
                                                                                        phoneNumber: "",
                                                                                        preferredLanguage: ""
                                                                                    })
                                                                                }
                                                                                }
                                                                            >
                                                                                <Typography color="primary" >+ {t("add_contact")}</Typography>
                                                                            </Button>
                                                                        </React.Fragment>
                                                                    )}
                                                                </FieldArray>
                                                            </Form>
                                                        )}
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {/* @ts-ignore */}
                                                    <Form className={classes.contactForm} >
                                                        <List id="contactsDetailDiv" className={classes.contactsDetail}>
                                                            {/* @ts-ignore */}
                                                            <FieldArray name="contactsDetail">
                                                                {({ remove } : { remove: (value: any) => any }) => (
                                                                    <React.Fragment>
                                                                        {values.contactsDetail.length > 0 &&
                                                                            values.contactsDetail.map((contact: any, index: number) => (
                                                                                <React.Fragment key={index}>
                                                                                    {((index >= 1) && (values.contactsDetail.length !== index)) && (
                                                                                        <Divider />
                                                                                    )}
                                                                                    <ListItem>
                                                                                        <Grid container spacing={2} >
                                                                                            {(index >= 1) && isCloseBtnVisible(this.state.isEdit,this.state.contactsCount ,index) && (
                                                                                                <Grid
                                                                                                    className={classes.contactDetailRemoveDiv}
                                                                                                    item
                                                                                                    xs={12} sm={12} md={12}
                                                                                                >
                                                                                                    <IconButton disabled={!this.state.enableEditing} className={classes.closeIcon} onClick={() => remove(index)}>
                                                                                                        <CloseSharp />
                                                                                                    </IconButton>
                                                                                                </Grid>
                                                                                            )}
                                                                                            <Grid item xs={4}>
                                                                                                <LabelWithIcon label={t('first_name')} />
                                                                                                <TextInput
                                                                                                    className={classes.textInputStyle}
                                                                                                    fullWidth
                                                                                                    name={`contactsDetail[${index}].customerFirstName`}
                                                                                                    data-test-id={`contactsDetail[${index}].customerFirstName`}
                                                                                                    id={`contactsDetail[${index}].customerFirstName`}
                                                                                                    value={contact.customerFirstName}
                                                                                                    disabled={!this.state.enableEditing}
                                                                                                />
                                                                                                {/* @ts-ignore */}
                                                                                                <ErrorMessage
                                                                                                    name={`contactsDetail[${index}].customerFirstName`}
                                                                                                    className="field-error"
                                                                                                    render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <LabelWithIcon label={t('last_name')} />
                                                                                                <TextInput
                                                                                                    className={classes.textInputStyle}
                                                                                                    fullWidth
                                                                                                    name={`contactsDetail[${index}].customerLastName`}
                                                                                                    data-test-id={`contactsDetail[${index}].customerLastName`}
                                                                                                    id={`contactsDetail[${index}].customerLastName`}
                                                                                                    value={contact.customerLastName}
                                                                                                    disabled={!this.state.enableEditing}
                                                                                                />
                                                                                                {/* @ts-ignore */}
                                                                                                <ErrorMessage
                                                                                                    name={`contactsDetail[${index}].customerLastName`}
                                                                                                    className="field-error"
                                                                                                    render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <LabelWithIcon label={t('position')} />
                                                                                                <TextInput
                                                                                                    className={classes.textInputStyle}
                                                                                                    fullWidth
                                                                                                    name={`contactsDetail[${index}].position`}
                                                                                                    data-test-id={`contactsDetail[${index}].position`}
                                                                                                    id={`contactsDetail[${index}].position`}
                                                                                                    value={contact.position}
                                                                                                    disabled={!this.state.enableEditing}
                                                                                                />
                                                                                                {/* @ts-ignore */}
                                                                                                <ErrorMessage
                                                                                                    name={`contactsDetail[${index}].position`}
                                                                                                    className="field-error"
                                                                                                    render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <LabelWithIcon label={t('email')} />
                                                                                                <TextInput
                                                                                                    className={classes.textInputStyle}
                                                                                                    fullWidth
                                                                                                    name={`contactsDetail[${index}].email`}
                                                                                                    data-test-id={`contactsDetail[${index}].email`}
                                                                                                    id={`contactsDetail[${index}].email`}
                                                                                                    value={contact.email}
                                                                                                    disabled={!this.state.enableEditing}
                                                                                                />
                                                                                                {/* @ts-ignore */}
                                                                                                <ErrorMessage
                                                                                                    name={`contactsDetail[${index}].email`}
                                                                                                    className="field-error"
                                                                                                    render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <LabelWithIcon label={t('phone')} />
                                                                                                <TextInput
                                                                                                    className={classes.textInputStyle}
                                                                                                    name={`contactsDetail[${index}].phoneNumber`}
                                                                                                    type="text"
                                                                                                    data-test-id={`contactsDetail[${index}].phoneNumber`}
                                                                                                    id={`contactsDetail[${index}].phoneNumber`}
                                                                                                    value={contact.phoneNumber}
                                                                                                    startAdornment={
                                                                                                        <InputAdornment position="start">
                                                                                                            <img className={classes.countryLogoStyleForPhoneInput} src="/assets/images/SAUDI_ARABIA_FLAG.png" height="35px" width="35px" />&nbsp;+ 966&nbsp;<div className={classes.phoneInputLine} />&nbsp;
                                                                                                        </InputAdornment>
                                                                                                    }
                                                                                                    disabled={!this.state.enableEditing}
                                                                                                />
                                                                                                {/* @ts-ignore */}
                                                                                                <ErrorMessage
                                                                                                    name={`contactsDetail[${index}].phoneNumber`}
                                                                                                    className="field-error"
                                                                                                    render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <LabelWithIcon label={t('preferred_language')} tooltip={t('preffered_language_tooltip')} />
                                                                                                <SelectBoxFormik
                                                                                                    name={`contactsDetail[${index}].preferredLanguage`}
                                                                                                    onChange={(newValue: any) => setFieldValue(`contactsDetail[${index}].preferredLanguage`, newValue.value)}
                                                                                                    options={languageOption}
                                                                                                    disabled={!this.state.enableEditing}
                                                                                                />
                                                                                                {/* @ts-ignore */}
                                                                                                <ErrorMessage
                                                                                                    name={`contactsDetail[${index}].preferredLanguage`}
                                                                                                    className="field-error"
                                                                                                    render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                                />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </ListItem>
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                    </React.Fragment>
                                                                )}
                                                            </FieldArray>
                                                        </List>
                                                    </Form>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>

                                        <Grid className={classes.customerDetailsAccordion} item>
                                            <Accordion data-test-id='p4' className={(this.state.errorSection === 'p4') && classes.errorSection} expanded={this.state.expandedAccordion === "p4"} onChange={(event, isExpanded) => {
                                                this.handleAccordian(isExpanded, "p4")
                                            }} >
                                                <AccordionSummary
                                                    aria-expanded={true}
                                                    expandIcon={<ExpandMore />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography className={classes.accordianHeading} >{t('address')}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2} >
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('country')} />
                                                            <TextInput
                                                                className={classes.textInputStyle}
                                                                fullWidth
                                                                name="country"
                                                                data-test-id="txtInputCountry"
                                                                id={"txtInputCountry"}
                                                                disabled={true}
                                                            />
                                                            {/* @ts-ignore */}
                                                            <ErrorMessage
                                                                name={`country`}
                                                                className="field-error"
                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('region')} />
                                                            <TextInput
                                                                className={classes.textInputStyle}
                                                                fullWidth
                                                                name="region"
                                                                data-test-id="txtInputRegion"
                                                                id={"txtInputRegion"}
                                                                disabled={!this.state.enableEditing}
                                                            />
                                                            {/* @ts-ignore */}
                                                            <ErrorMessage
                                                                name={`region`}
                                                                className="field-error"
                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('city')} />
                                                            <TextInput
                                                                className={classes.textInputStyle}
                                                                fullWidth
                                                                name="city"
                                                                data-test-id="txtInputCity"
                                                                id={"txtInputCity"}
                                                                disabled={!this.state.enableEditing}
                                                            />
                                                            {/* @ts-ignore */}
                                                            <ErrorMessage
                                                                name={`city`}
                                                                className="field-error"
                                                                render={(msg :string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('district')} />
                                                            <TextInput
                                                                className={classes.textInputStyle}
                                                                fullWidth
                                                                name="district"
                                                                data-test-id="txtInputDistrict"
                                                                id={"txtInputDistrict"}
                                                                vlaue={values.district}
                                                                disabled={!this.state.enableEditing}
                                                            />
                                                            {/* @ts-ignore */}
                                                            <ErrorMessage
                                                                name={`district`}
                                                                className="field-error"
                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('street_name')} />
                                                            <TextInput
                                                                className={classes.textInputStyle}
                                                                fullWidth
                                                                name="streetName"
                                                                data-test-id="txtInputStreetName"
                                                                id={"txtInputStreetName"}
                                                                disabled={!this.state.enableEditing}
                                                            />
                                                            {/* @ts-ignore */}
                                                            <ErrorMessage
                                                                name={`streetName`}
                                                                className="field-error"
                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('building_number')} />
                                                            <TextInput
                                                                className={classes.textInputStyle}
                                                                fullWidth
                                                                type="number"
                                                                name="buildingNumber"
                                                                data-test-id="txtInputBuildingNumber"
                                                                id={"txtInputBuildingNumber"}
                                                                disabled={!this.state.enableEditing}
                                                            />
                                                            {/* @ts-ignore */}
                                                            <ErrorMessage
                                                                name={`buildingNumber`}
                                                                className="field-error"
                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('postal_code')} />
                                                            <TextInput
                                                                className={classes.textInputStyle}
                                                                fullWidth
                                                                type="number"
                                                                name="postalCode"
                                                                disabled={!this.state.enableEditing}
                                                                data-test-id="txtInputPostalCode"
                                                                id={"txtInputPostalCode"}
                                                            />
                                                            {/* @ts-ignore */}
                                                            <ErrorMessage
                                                                name={`postalCode`}
                                                                className="field-error"
                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('unit_number')} />
                                                            <TextInput
                                                                className={classes.textInputStyle}
                                                                fullWidth
                                                                name="unitNumber"
                                                                data-test-id="txtInputUnitNumber"
                                                                id={"txtInputUnitNumber"}
                                                                disabled={!this.state.enableEditing}
                                                            />
                                                            {/* @ts-ignore */}
                                                            <ErrorMessage
                                                                name={`unitNumber`}
                                                                className="field-error"
                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('aditional_number')} />
                                                            <TextInput
                                                                className={classes.textInputStyle}
                                                                fullWidth
                                                                name="additionalNumber"
                                                                type="number"
                                                                data-test-id="txtInputAdditionalNumber"
                                                                id={"txtInputAdditionalNumber"}
                                                                disabled={!this.state.enableEditing}
                                                            />
                                                            {/* @ts-ignore */}
                                                            <ErrorMessage
                                                                name={`additionalNumber`}
                                                                className="field-error"
                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('address_proof')} />
                                                            <FileUpload
                                                                eachFileSizeInMB={10}
                                                                maxFilesNumber={5}
                                                                fileName={values.addressProof?.file_name || ""}
                                                                isEditPage={this.state.isEdit}
                                                                filePath={values.addressProof || ""}
                                                                data-test-id="txtInputAddressProof"
                                                                disabled={!this.state.enableEditing}
                                                                inputName={`addressProof`}
                                                                onChange={(event: any) => setFieldValue(`addressProof`, event.target.files[0])}
                                                                onRemove={() => setFieldValue(`addressProof`, {})}
                                                            />
                                                            {/* @ts-ignore */}
                                                            <ErrorMessage
                                                                name={`addressProof`}
                                                                className="field-error"
                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t('risk')} tooltip={t('risk_tooltip')} />
                                                            <SelectBoxFormik
                                                                name="risk"
                                                                onChange={(newValue: any) => setFieldValue(`risk`, newValue.value)}
                                                                options={riskOption}
                                                                disabled={!this.state.enableEditing}
                                                            />
                                                            {/* @ts-ignore */}
                                                            <ErrorMessage
                                                                name={`risk`}
                                                                className="field-error"
                                                                render={(msg:string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>


                                        <Grid spacing={2} className={classes.loginButtonDiv} item>
                                            {
                                                ((isEditButtonVisible() && this.state.enableEditing) || !this.state.isEdit) && (

                                                    <React.Fragment>
                                                        <Button
                                                            className={classes.cancelBtn}
                                                            data-test-id="btnEmailLogIn"
                                                            id="btnEmailLogIn"
                                                            variant="outlined"
                                                            disabled={!this.state.enableEditing}
                                                            onClick={() => { this.setState({ showDialog: true, dialoagFor: "cancel" }) }}
                                                        >
                                                            {t('cancel')}
                                                        </Button>

                                                        <Button
                                                            className={classes.primaryBtnStyle}
                                                            color="primary"
                                                            data-test-id="btnForNewPolicy"
                                                            id="btnForNewPolicy"
                                                            disabled={!this.state.enableEditing}
                                                            onClick={() => {
                                                                validateForm()
                                                                this.checkCustomerForm(errors);
                                                            }}
                                                        >
                                                            {this.state.isEdit ? t('save_changes') : t('add_customer')}
                                                        </Button>
                                                    </React.Fragment>
                                                )
                                            }

                                            <ConfirmationDialog
                                                openDialog={this.state.showDialog}

                                                // Get static codes
                                                headingText={GetHeadingTitle(this.state.isEdit, this.state.dialoagFor)}
                                                paragraphText={GetParagraphTxt(this.state.isEdit, this.state.dialoagFor)}
                                                btnCancelText={t('cancel')}
                                                btnOkText={GetBtnTxt(this.state.isEdit, this.state.dialoagFor)}
                                                btnLoading={this.state.btnLoading}


                                                // Call funcations on button click
                                                handleCancel={() => this.setState({ showDialog: false, dialoagFor: "" })}
                                                handleOk={() => this.state.dialoagFor === "cancel" ? backToCustomer() : handleSubmit()}
                                                showRedButton={this.state.dialoagFor === "cancel"}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )}

                            </Formik>
                        </Grid>
                    </Container>
                </ThemeProvider>
            </div >
        )
    }
}

const useStyles = ({
    primaryBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "height": "50px",
        "width": "150px",
        borderRadius: "10px"
    },
    displayFlexEnd: { display: "flex", justifyContent: "end" },
    cancelBtn: {
        marginRight: "10px",
        marginLeft: "10px",
        background: "white",
        borderRadius: "10px",
        "text-transform": "none",
        "height": "50px",
        "width": "150px",
    },
    errorSection:{
        border:"1px solid red"
    },
    accordianHeading: {
        fontWeight: 600
    },
    loginButtonDiv: { paddingTop: "10px", paddingBottom: "10px", display: "flex", justifyContent: "end" },
    displayFlex: {
        display: "flex",
    },
    contactDetailRemoveDiv: {
        display: "flex",
        justifyContent: "end",
        paddingTop: "25px",
        paddingBottom: "25px"
    },
    contactForm: { width: "100%" },
    alertIconStyle: {
        color: "white"
    },
    alertCloseIcon: {
        color: "white",
        cursor: "pointer",
        paddingLeft: "10px"
    },
    alertDiv: {
        "display": "flex",
        "justify-content": "end",
        "padding-bottom": "15px",
        "padding-top": "15px"
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    branchDetails: { width: "100%" },
    branchDetailsSubContact: {
        "width": "100%",
        "border": "1px solid lightgray",
        borderRadius: "10px"
    },
    branchListItem: {
        display: "flex",
        justifyContent: "end",
        paddingTop: "25px",
        paddingBottom: "25px"
    },
    closeIcon: { padding: 0 },
    redAlertMessage: {
        backgroundColor: "#ec5655",
        color: "white"
    },
    greenAlertMessage: {
        backgroundColor: "#028f5d",
        color: "white"
    },
    branchDetailAccordion: {
        "width": "100%",
        "display": "flex",
        "justifyContent": "space-between",
        "padding": 0
    },
    contactsDetail: {
        "width": "100%",
        "border": "1px solid lightgray",
        borderRadius: "10px"
    },
    customerDetailsAccordion: { paddingTop: "10px", paddingBottom: "10px" },
    countryLogoStyleForPhoneInput: {
        paddingRight: "5px"
    },
    phoneInputLine: {
        borderLeft: "2px solid gray",
        height: "25px",
        marginLeft: "5px"
    },
    addMoreButton: {
        padding: 0,
        "text-transform": "none",
    }
});

export default withStyles(useStyles)(withTranslation()(CustomerDetails));

