import * as React from "react";
import { Badge, Box, Typography, styled, withStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import StatusTooltip from "../StatusTooltip";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

const webStyle = {
  tableStatusBlue: {
    backgroundColor: "#93c5fd",
    color: "#3b82f6",
    padding: "2px 5px",
    "text-align": "center",
    borderRadius: "7px",
  },
  tableStatusGreen: {
    backgroundColor: "#bbf7d0",
    color: "green",
    padding: "2px 5px",
    "text-align": "center",
    borderRadius: "7px",
  },
  tableStatusYellow: {
    backgroundColor: "#fef9c3",
    color: "#F3C81B",
    padding: "2px 5px",
    "text-align": "center",
    borderRadius: "7px",
  },
  tableStatusOrange: {
    backgroundColor: "#fed7aa",
    color: "#fb923c",
    padding: "2px 5px",
    "text-align": "center",
    borderRadius: "7px",
  },
  tableStatusRed: {
    backgroundColor: "#fecaca",
    color: "#ef4444",
    padding: "2px 5px",
    "text-align": "center",
    borderRadius: "7px",
  },
  tableStatusArchived: {
    backgroundColor: "#d1d5db",
    color: "#9ca3af",
    padding: "2px 5px",
    "text-align": "center",
    borderRadius: "7px",
  },
};

enum CUSTOMER_LIST_STATUS {
  OPEN = "open",
  NEW = "new",
  PARTIAL = "partial",
  WON = "won",
  LOST = "lost",
  INACTIVE = "inactive",
  ASSIGNED = "assigned"
}

export const INSURER_CSV_TABLE_BODY = [
  {
    Title: "row",
    dataKey: "attributes.row_number"

  },
  {
    Title: "legal_name",
    dataKey: "attributes.legal_name",
  },
  {
    Title: "short_name",
    dataKey: "attributes.short_name",
  },
  {
    Title: "segment",
    tableAction: (rowData: any) => {
      const segments = rowData.attributes.segment
        .map((segment: { value: string; label: string }) => segment.value)
        .join(",");
      return segments;
    }
  },
  {
    Title: "insurance",
    tableAction: (rowData: any) => {
      const insurance = rowData.attributes.types_of_insurance
        .map((insurance: { value: string; label: string }) => insurance.value)
        .join(", ");
      return insurance;
    }
  }
]

export const CUSTOMER_CSV_TABLE_BODY = [
  {
    Title: "row",
    dataKey: "attributes.row_number"

  },
  {
    Title: "customer_name",
    dataKey: "attributes.name",
  },
  {
    Title: "segment",
    tableAction: (rowData: any) => {
      const segments = rowData.attributes.segment
        .map((segment: { value: string; label: string }) => segment.value)
        .join(",");
      return segments;
    }
  },
  {
    Title: "industry",
    dataKey: "attributes.industry",
  },
  {
    Title: "number_of_contacts",
    tableAction: (rowData: any) => {
      const contacts = rowData.attributes.contacts?.data
      return contacts?.length;
    }
  },
]

export const CUSTOMER_LIST_TABLE_BODY = [
  {
    Title: "customer_id",
    dataKey: "attributes.customer_id",
  },
  {
    Title: "customer_name",
    dataKey: "attributes.name",
  },
  {
    Title: "segment",
    tableAction: (rowData: any) => {
      const segments = rowData.attributes.segment
        .map((segment: { value: string; label: string }) => segment.value)
        .join(",");
      return segments;
    }
  },
  {
    Title: "industry",
    dataKey: "attributes.industry",
  },
  {
    Title: "active_requests",
    dataKey: "attributes.active_requests",
  },
  {
    Title: "status",
    tableAction: (rowData: any, t: any) => {
      if (rowData.attributes.is_archived) {
        return (
          <Typography style={webStyle.tableStatusArchived}>
            {t("Archived")}
          </Typography>
        );
      }
      if (rowData.attributes.status === CUSTOMER_LIST_STATUS.OPEN) {
        return (
          <StatusTooltip statusTitle={t('one_or_more_requests_in_process')}>
            <Typography style={webStyle.tableStatusYellow}>
              {t("open")}
            </Typography>
          </StatusTooltip>

        );
      }
      if (rowData.attributes.status === CUSTOMER_LIST_STATUS.INACTIVE) {
        return (
            <Typography style={webStyle.tableStatusYellow}>
              {t("inactive")}
            </Typography>
        );
      }
      if (rowData.attributes.status === CUSTOMER_LIST_STATUS.NEW) {
        return (
          <StatusTooltip statusTitle={t('default_state')} >
            <Typography style={webStyle.tableStatusBlue}>
              {t("new")}
            </Typography>
          </StatusTooltip>

        );
      }
      if (rowData.attributes.status === CUSTOMER_LIST_STATUS.PARTIAL) {
        return (
          <StatusTooltip statusTitle={t('minimum_of_one_won_requests_and_one_or_more_lost')}>
            <Typography style={webStyle.tableStatusOrange}>
              {t("partial")}
            </Typography>
          </StatusTooltip>
        );
      }
      if (rowData.attributes.status === CUSTOMER_LIST_STATUS.ASSIGNED) {
        return (
            <Typography style={webStyle.tableStatusOrange}>
              {t("assigned")}
            </Typography>
        );
      }
      if (rowData.attributes.status === CUSTOMER_LIST_STATUS.WON) {
        return (
          <StatusTooltip statusTitle={t('minimum_of_1_won_requests_no_lost_requests')}>
            <Typography style={webStyle.tableStatusGreen}>
              {t("won")}
            </Typography>
          </StatusTooltip>
        );
      }
      if (rowData.attributes.status === CUSTOMER_LIST_STATUS.LOST) {
        return (
          <StatusTooltip statusTitle={t('only_lost_requests')}>
            <Typography style={webStyle.tableStatusRed}>
              {t("lost")}
            </Typography>
          </StatusTooltip>
        );
      }
    },
  },
];
export const EMPLOYEE_LIST_TABLE_BODY = [
  {
    Title: "employee_id",
    dataKey: "attributes.employee_id",
  },
  {
    Title: "employee_name",
    dataKey: "attributes.member_name",
  },

  {
    Title: "employee_gender",
    dataKey: "attributes.gender",
  },
  {
    Title: "employee_nation",
    dataKey: "attributes.nationality",
  },
  {
    Title: "employee_dob",
    dataKey: "attributes.date_of_birth",
  },

];
export const VEHICLE_LIST_TABLE_BODY = [
  {
    Title: "plate",
    dataKey: "attributes.plate_number",
  },
  {
    Title: "make",
    dataKey: "attributes.make",
  },

  {
    Title: "model",
    dataKey: "attributes.model",
  },
  {
    Title: "type",
    dataKey: "attributes.type_of_body",
  },
  {
    Title: "value",
    dataKey: "attributes.value",
  },

];

const CustomLinearProgress = styled(LinearProgress)(({ theme: any }) => ({
  '&.demo > div': {
    backgroundColor: '#406d7f !important', // Set the progress bar color
  },
}));

const stages= ["info_gathering", "awaiting", "sales_review", "processing", "operations_review", "payment_review", "customer_review"];

const styles = (theme: any) => ({
  badge: {
    backgroundColor: (props: any) => {
      switch (props.stage.attributes.status) {
        case "info_gathering":
        case "awaiting":
        case "sales_review":
        case "processing":
        case "operations_review":
        case "payment_review":
        case "customer_review":
          if(props.stage.attributes.mark_as !== "lost") return "yellow";
          else return "red";
        case "issuance":
        case "invoicing":
        case "completed":
          return "green";
      }
    },
  },
});

const StyledBadge = ({ classes, stage, ...props }: any) => {
  return (
    <Badge
      classes={{ badge: classes.badge }}
      {...props}
    />
  )
};

// Apply the styles and cast the props
const StyledBadgeWithStyles = withStyles(styles)(StyledBadge);

const MyComponent = styled(Box)({
  '& span span': {
    position: 'static',
    marginRight: '5px',
  }
});


export const REQUEST_LIST_TABLE_BODY = [
  {
    Title: "request_id",
    dataKey: "attributes.request_id",
  },
  {
    Title: "customer",
    dataKey: "attributes.customer",
  },
  {
    Title: "request_type",
    dataKey: "attributes.request_type",
  },
  {
    Title: "insurance_type",
    dataKey: "attributes.types_of_insurance",
  },
  {
    Title: "stages",
    tableAction: (rowData: any, t: any) => {
      return (
        <>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px',}}>
            {/* @ts-ignore */}
            <MyComponent>
              {/* @ts-ignore */}
              <StyledBadgeWithStyles
                variant="dot"
                stage={rowData} // Change this value based on your condition
              />
            </MyComponent>
            <Typography style={{ marginBottom: 5 }}>{rowData.attributes.current_status}</Typography>
          </div>
          {/* @ts-ignore */}
          <CustomLinearProgress className="demo" variant="determinate" value={rowData.attributes.progress_bar || 0 } /></>
      )
    }
  },
  {
    Title: "assignee",
    dataKey: "attributes.assignee"
  },
  {
    Title: "last_update",
    dataKey: "attributes.updated_at"
  }
];

export const POLICY_LIST_TABLE_DATA = [
  {
    Title: "policy_id",
    dataKey: "attributes.policy_id",
  },
  {
    Title: "customer",
    dataKey: "attributes.customer.name",
  },
  {
    Title: "insurer",
    dataKey: "attributes.insurance_company_id.short_name",
  },
  {
    Title: "insurance_type",
    dataKey: "attributes.types_of_insurance",
  },
  {
    Title: "current_rate",
    dataKey: "attributes.current_policy_value",
  },
  {
    Title: "balance_left",
    dataKey: "attributes.policy_balance_value",
  },
  {
    Title: "activation_date",
    dataKey: "attributes.policy_activation_date",
  },
  {
    Title: "issued_by",
    dataKey: "attributes.issued_by.name",
  },
];
export const OVERDUE_PAYMENTS_COLUMNS=[
  {
    Title: "customer",
    dataKey: "customer_name",
  },
  {
    Title: "amount_past_due",
    dataKey: "balance",
  }
]
export const TASK_lIST_TABLE_COLUMNS=[
  {
    Title: "name",
    dataKey: "attributes.task_name",
  },
  {
    Title: "assignee",
    dataKey: "attributes.assignee_person",
  },
  {
    Title: "customer",
    dataKey: "attributes.customer_id.name",
  },
  {
    Title: "description",
    dataKey: "attributes.description",
  }
]

export const INSURER_LIST_TABLE_DATA = [
  {
    Title: "insurer_id",
    dataKey: "attributes.insurer_id",
  },
  {
    Title: "legal_name",
    dataKey: "attributes.legal_name",
  },
  {
    Title: "short_name",
    dataKey: "attributes.short_name",
  },
  {
    Title: "segment",
    tableAction: (rowData: any) => {
      const segments = rowData.attributes.segment
        .map((segment: { value: string; label: string }) => segment.value)
        .join(",");
      return segments;
    }
  },
  {
    Title: "insurance_types",
    tableAction: (rowData: any) => {
      const insuranceType = rowData.attributes.types_of_insurance
        .map((insurenceType: { value: string; label: string }) => insurenceType.value)
        .join(", ");
      return insuranceType;
    },
  },
];

export const CUSTOMER_CONTACTS_COLUMN = [
  {
    Title: "contact",
    tableAction: (rowData: any) => {
      return `${rowData.attributes.first_name} ${rowData.attributes.last_name}`
    }
  },
  {
    Title: "email",
    dataKey: "attributes.email",
  },
  {
    Title: "contact_no",
    tableAction: (rowData: any) => {
      return `+966${rowData.attributes.phone_number}`
    }
  },
  {
    Title: "designation",
    dataKey: "attributes.position",
  },
  {
    Title: "status",
    tableAction: (rowData: any, t: any, classes: { tableGrayButton: string | undefined; tableGreenButton: string | undefined; tableYellowButton: string | undefined; }) => {
      if (rowData.attributes.status === "inactive") {
        return (
          <StatusTooltip statusTitle={t('no_account_invite_sent')}>
            <Typography className={classes.tableGrayButton} >{t("inactive")}</Typography>
          </StatusTooltip>)
      }
      if (rowData.attributes.status === "active") {
        return (
          <StatusTooltip statusTitle={t('signup_completed')}>
            <Typography className={classes.tableGreenButton} >{t("active")}</Typography>
          </StatusTooltip>
        )
      }
      if (rowData.attributes.status === "deactivated") {
        return (
          <StatusTooltip statusTitle={t('account_access_blocked')}>
            <Typography className={classes.tableGrayButton}>{t("deactivated")}</Typography>
          </StatusTooltip>
        )
      }
      if (rowData.attributes.status === "invite_sent") {
        return (
            <Typography className={classes.tableYellowButton}>{t("invite_sent")}</Typography>
        )
      }
    }
  }
]

export const CUSTOMER_ONGOING_POLICY_HEADER = [
  {
    Title: "request_id",
    dataKey: "requestID",
  },

  {
    Title: "request_type",
    dataKey: "requestType",
  },
  {
    Title: "insurance_type",
    dataKey: "insuranceType",
  },
  {
    Title: "assignee",
    dataKey: "assignees",
  },
  {
    Title: "last_update",
    dataKey: "lastUpdateOn",
  },
  {
    Title: "status",
    dataKey: "status",
  },

];

export const CUSTOMER_EXISTING_POLICY_HEADER = [
  {
    Title: "policy_no",
    dataKey: "pNo",
  },
  {
    Title: "insurer",
    dataKey: "insurer",
  },
  {
    Title: "policy_type",
    dataKey: "policyType",
  },
  {
    Title: "activation_date",
    dataKey: "activationDate",
  },
  {
    Title: "expiry_date",
    dataKey: "expiryDate",
  },
  {
    Title: "issued_by",
    dataKey: "issuedBy",
  },

];

export const POLICY_DETAIL_LIST_TABLE_DATA = [
  {
    Title: "policy_id",
    dataKey: "attributes.request_id",
  },
  {
    Title: "request_type",
    dataKey: "attributes.request_type",
  },
  {
    Title: "insurance_type",
    dataKey: "attributes.types_of_insurance",
  },
  {
    Title: "stages",
    tableAction: (rowData: any, t: any) => {
      return (
        <>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px',}}>
            {/* @ts-ignore */}
            <MyComponent>
            {/* @ts-ignore */}
              <StyledBadgeWithStyles
                variant="dot"
                stage={rowData}
              />
            </MyComponent>
            <Typography style={{ marginBottom: 5 }}>{rowData.attributes.current_status}</Typography>
          </div>
            {/* @ts-ignore */}
          <CustomLinearProgress className="demo" variant="determinate" value={rowData.attributes.progress_bar || 0 } /></>
      )
    }
  },
  {
    Title: "assignee",
    dataKey: "attributes.assignee"
  },
  {
    Title: "last_update",
    dataKey: "attributes.updated_at"
  },
]