const getStageNameByNumber = (currentStep:number) => {
    switch (currentStep) {
        case (1):
            return "info_gathering"
        case (2):
            return "processing"
        case (3):
            return "awaiting"
        case (4):
            return "operations_review"
        case (5):
            return "sales_review"
        case (6):
            return "customer_review"
        case (7):
            return "payment_review"
        case (8):
            return "issuance"
        case (9):
            return "invoicing"
        case (10):
            return "completed"
        default:
            return "";
    }
}
export const getStageNumberByName = (stageName:string) => {
    switch (stageName) {
        case ("info_gathering"):
            return 1;
        case ("processing"):
            return 2;
        case ("awaiting"):
            return 3;
        case ("operations_review"):
            return 4;
        case ("sales_review"):
            return 5;
        case ("customer_review"):
            return 6;
        case ("payment_review"):
            return 7;
        case ("issuance"):
            return 8;
        case ("invoicing"):
            return 9;
        case ("completed"):
            return 10;
        default:
            return 1;
    }
}

export default getStageNameByNumber