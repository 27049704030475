// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import StageEightController from "./StageEightController";
import StageEightForm from "./StageEightForm";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
});

class StageEight extends StageEightController {
  render() {
    return (
      <div data-test-id="CustomerDetails">
        <ThemeProvider theme={theme}>
          <Container
            id="customerContainer"
            maxWidth="lg"
            dir={
              (window.localStorage.getItem("lang") || "en") === "ar"
                ? "rtl"
                : "ltr"
            }
          >
            {/* @ts-ignore */}
            <StageEightForm {...this.props} />
          </Container>
        </ThemeProvider>
      </div>
    );
  }
}

export default StageEight;
// Customizable Area End