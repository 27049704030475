import React from "react";
import {
  Button,
  CircularProgress,
  Container,
  Typography
} from "@material-ui/core";
// @ts-ignore
import { withTranslation } from "react-i18next";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";

import { createStyles } from "@material-ui/styles";
import CustomButton from "../Common/CustomButton";
import PaginatedTable from "../Common/Table/PaginatedTable";
import FileUploadButton from  '../Common/Button/FileUploadButton'
import Toster from "./Toster";
type MyProps = { 
  t?: any; 
  classes?: any;
  importFor?: string;
  isUploadingCsvFile?: boolean; 
  page?: number; 
  totalPage?: number; 
  alertMessage?: string; 
  showAlert?: boolean; 
  alertType?: string;
  csvData?: object[];
  errorMessage?: string; 
  errorFile?: string; 
  isLoading?: boolean
  handleAnotherCSVUpload?: (value: any) => void ;
  downloadFile?: (value: string) => void ;
  handleSaveCSVUpload?: (value: boolean) => void ;
  handleCSVUploader?: (value: any) => void ;
  handleChangePage?: (event: any, page: number) => void;
  handleRowPerPage?: (value: boolean) => void | undefined ;
  tableBody?: { Title: string; dataKey?: string | undefined; tableAction?: Function | undefined; }[];
  closeToast?: () => void ;
  downloadSampleCsv?: () => void;
};


const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
});


export class CSVImportDetails extends React.Component<MyProps> {

  render() {
    // @ts-ignore
    const { 
        t, 
        classes, 
        importFor,
        isUploadingCsvFile, 
        page, 
        totalPage, 
        alertMessage, 
        showAlert, 
        alertType, 
        csvData, 
        errorMessage, 
        errorFile, 
        isLoading,
        handleAnotherCSVUpload,
        downloadFile,
        handleSaveCSVUpload,
        handleCSVUploader,
        handleChangePage,
        handleRowPerPage,
        tableBody,
        closeToast,
        downloadSampleCsv

      } = this.props;

      const setTitle = (importFor: string) => {
        switch(importFor) {
          case 'customer':
            return 'customer_csv_import'  
          case "insurer" :
            return 'insurer_csv_import'
          case "policy" :
            return "policy_csv_import" 
            case "employee" :
            return "employee_csv_import" 
            case "vehicle" :
            return "vehicle_csv_import" 
          default:
              return '' 
        }     
      }

    return (
      <div data-test-id="customerCsvImport">
        <Toster
          tosterText={alertMessage as string}
          showToster={showAlert as boolean}
          // @ts-ignore
          tosterType={alertType}
          handleCloseToster={() => closeToast?.()}
        />
        <ThemeProvider theme={theme}>
          <Container maxWidth="xl">
            {isUploadingCsvFile ? (
              <>
                <div className={classes.anotherCsvBtn}>
                <FileUploadButton 
                  uploadFileType={'.csv'} 
                  buttonText={t("upload_another_csv")}
                  handleUpload={(e: any) => handleAnotherCSVUpload?.(e)}
                />
                </div>
                <div className={classes.warningMessage}>*{t('csv_upload_warning_message')}</div>
                <div className={classes.tableBox}>
                <Typography className={classes.tableHeading}>
                  {t(`${setTitle(importFor as string)}`)}
                </Typography>
                  <PaginatedTable
                    isLoading={isLoading}
                    curretPage={page as number}
                    totalPage={totalPage as number}
                    pageChange={handleChangePage as never}
                    handleRowPerPage={handleRowPerPage as never}
                    tableFor={"csv"}
                    tableData={csvData}
                    tableColumn={tableBody as never}
                  />
                  
                </div>

                <div className={classes.actionSection}>
                  <div className={classes.errorMessage}>
                    {errorMessage}
                  </div>
                  <div>
                    <Button
                      disabled={!errorFile}
                      className={classes.downloadBtn}
                      onClick={() => downloadFile?.(errorFile as string)}
                    >
                      {t("download_error_file")}
                    </Button>
                    <Button
                      className={classes?.uploadCsvBtn}
                      onClick={handleSaveCSVUpload as never}
                      disabled={Object.keys(csvData as object).length > 0 ? false:true }
                    >
                      {t("save")}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={classes.sideText}>{t(`${setTitle(importFor as string)}`)}</div>
                <div className={classes.uploadImg}>
                  <div className={classes.imgContainer}>
                    <img
                      src={require("../../../web/public/assets/images/icons/file-upload.png")}
                      height="150px"
                      width="150px"
                    />
                  </div>
                </div>

                <div className={classes.btnBox}>
                  <div className={classes.noCsvText}>{t('no_csv_file_upload')}</div>
                  {/* @ts-ignore */}
                  <FileUploadButton uploadFileType={'.csv'} buttonText={t('upload_file')} handleUpload={(e: any) => handleCSVUploader(e)}/>
                  <CustomButton
                    btnText={t("download_CSV_sample")}
                    onClick={downloadSampleCsv as any}
                    bgColor={"#fff"}
                    textColor={"#007E98"}
                  />
                </div>
              </>
            )}
          </Container>
        </ThemeProvider>
      </div>
    );
  }
}

const useStyles = createStyles({
  primaryBtnStyle: {
    background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    "text-transform": "none",
    color: "white",
    height: "50px",
    width: "150px",
    borderRadius: "10px",
  },
  sideText: {
    fontWeight: "bold",
  },
  uploadImg: {
    display: "flex",
    justifyContent: "center",
    margin: "10px 0",
    width: "100%",
    position: "relative",
  },
  imgContainer: {
    width: "250px",
    height: "250px",
    margin: "20px auto",
    background: "rgba(227, 235, 239, 1)",
    "border-radius": "50%",
    "text-align": "center",
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
  },
  noCsvText: {
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
  },
  uploadCsvBtn: {
    background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
    "text-transform": "none",
    color: "white",
    height: "55px",
    width: "150px",
    borderRadius: "10px",
    margin: "20px 11px",
    marginLeft: '10px'
  },
  btnBox: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  actionSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  anotherCsvBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  downloadBtn: {
    color: "#007E98",
    height: "55px",
    width: "200px",
    border: '1.5px solid #0090a1',
    background: '#fff',
    borderRadius: "10px",
    margin: "20px 0",
  },
  tableBox: {
    padding: "20px",
    background: '#fff',
    borderRadius: "10px",
    border: "1px solid lightgrey"
  },
  tableHeading: {
    fontSize: "large",
    fontWeight: 800,
    padding: "10px",
  }, 
  warningMessage: {
    color: "red",
    padding: "10px 0",
    fontStyle: "italic",
    fontSize: "12px"
  },
  errorMessage : {
    color: 'red'
  },
  
});

export default withStyles(useStyles)(withTranslation()(CSVImportDetails));
