import React, { useRef } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const TransactionTableForPolicy = (props: any) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { tableData, transactionLoading } = props;

    return (
        <div data-test-id="TransactionTable" className={classes.table}>
            <div className={classes.tableWithPagination}>
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow className={classes.transactionTableHead}>
                            <TableCell className={`${classes.policyIdCell} ${classes.tableHeading}`}>{t('policy_id')}</TableCell>
                            <TableCell className={`${classes.dateCell} ${classes.tableHeading}`}>{t('date')}</TableCell>
                            <TableCell className={classes.tableHeading}>{t('insurance_type')}</TableCell>
                            <TableCell className={classes.tableHeading}>{t('customer')}</TableCell>
                            <TableCell align="center" className={classes.tableHeading}>{t('paid_amount_ex_vat')}</TableCell>
                            <TableCell className={classes.tableHeading}>{t('remaining_amount')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactionLoading &&
                            <TableRow>
                                <TableCell colSpan={11}>
                                    <Typography align="center" className={classes.tableLoading}>
                                        <CircularProgress size={50} />
                                    </Typography>
                                    <Typography align="center">
                                        {t('fetching_the_data_please_wait')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        }
                        {!transactionLoading && tableData.length > 0 ? tableData.map((rowData: any, index: number) => (
                            <React.Fragment key={rowData.id}>
                                    <TableRow key={rowData.id}>
                                        <TableCell className={`${classes.policyIdCell} ${classes.stickyCell} ${classes.whiteBackground}`}>
                                            {rowData.attributes.policy_id}
                                        </TableCell>
                                        <TableCell className={`${classes.dateCell} ${classes.stickyCell} ${classes.whiteBackground}` }>{rowData.attributes.date || "-"}</TableCell>
                                        <TableCell className={classes.whiteBackground}>{rowData.attributes.types_of_insurance  || "-" }</TableCell>
                                        <TableCell className={classes.whiteBackground}>{rowData.attributes.customer  || "-" }</TableCell>
                                        <TableCell align="center" className={classes.whiteBackground}>{rowData.attributes.revenue_change ? `SAR ${rowData.attributes.revenue_change }` : "-" }</TableCell>
                                        <TableCell className={classes.whiteBackground}>{rowData.attributes.remaining_amount  || "-" }</TableCell>
                                    </TableRow>
                            </React.Fragment>
                        )) : (
                            <TableRow>
                            <TableCell colSpan={11}>
                                <Typography align="center">
                                    { !transactionLoading && t('no_records_found')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            width: "100%",
        },
        primaryBtnStyle: {
            background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
            textTransform: "none",
            color: "white",
            height: "50px",
            width: "150px",
            borderRadius: "10px"
        },
        transactionTableHead: {
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        tableWithPagination:{
            border: "1px solid grey",
            borderRadius: "7px",
        },
        tableContainer: {
            padding: 0,
            width: "100%",
            overflowX: "auto",
        },
        tableHeading: {
            fontSize: "14px",
            fontWeight: 700,
            padding: "13px 15px"
        },
        stickyCell: {
            position: "sticky",
            zIndex: 1
        },
        policyIdCell: {
            position: "sticky",
            left: 0,
            zIndex: 3,
            width: "200px",
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        dateCell: {
            position: "sticky",
            left: "91px",
            zIndex: 2,
            width: "200px",
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        transactionIdCell: {
            position: "sticky",
            left: "205px",
            zIndex: 3,
            width: "200px",
            backgroundColor: "rgba(233, 244, 249, 1)",
            overflow: "hidden",
            "&:after": {
                content: '""',
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "5px",
                background: "linear-gradient(to right, #ccc, rgba(204, 204, 204, 0))"
            },
        },
        whiteBackground: {
            backgroundColor: "white"
        },
        tableLoading:{
            padding:"50px"
        }
    })
);

export default TransactionTableForPolicy;
