import React from 'react'
import {
  Grid,
  makeStyles,
  Typography,

} from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAltSharp';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import DeleteIcon from '@material-ui/icons/Delete'
import Toster from '../Common/Toster';
import ActiveLanguage from './ActiveLanguage';


const useStyles = makeStyles({
  fileTableIconLeft: {
    display: 'flex',
    color: '#979090'
  },
  fileTableIconCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  fileIconLeft: {
    marginLeft: '20px',
    marginRight: '20px',
    color: '#979090'
  },
  accordianHeading: {
    fontWeight: 600,
    color: '#000'
  },
  fileTableIconRight: {
    display: 'flex',
    justifyContent: 'space-around',
    color: '#979090'
  },
  iconClick: {
    cursor: "pointer"
  },
  fileTableData: {
    height: '50px',
    borderTop: '1px solid #979090',
  },
})

type fileTypes = {
  id: number;
  file_name: string;
  url: string
}

type FileListItemProps = {
  uploadedFileList: fileTypes[];
  showDelete: boolean;
  removePolicyOverviewFile: (id: number) => void;
}

const FileListItem = (props: FileListItemProps) => {
    const classes = useStyles();
    const [errorMsg, setErrorMsg] = React.useState<string>('')
    const [showToaster,setShowToaster] =  React.useState(false);

    const downloadFile = async (file: fileTypes) => {
      try {
        const response = await fetch(file.url);
        const blob = await response.blob();
        const link = document.createElement('a');
        const blobUrl = window.URL.createObjectURL(blob);

        link.href = blobUrl;
        link.download = `${file.file_name}`;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        setShowToaster(true);
        setErrorMsg(ActiveLanguage() ? "حدث خطأ ما في الملف ولم يتم تنزيله" : "Something went wrong file not download!")
      }
    }

    React.useEffect(()=> {
      setShowToaster(false);
    },[showToaster])

    return (
      <>
        <Toster
          tosterText={errorMsg}
          showToster={showToaster}
          tosterType={'error'}
          handleCloseToster={() => setShowToaster(false)}
        />
        {props.uploadedFileList.map((ele: any) => {
          return (
            <Grid key={`fileItem-${1}`} container alignItems="center" className={classes.fileTableData}>
              <Grid item xs={11} className={classes.fileTableIconLeft}>
                <InsertDriveFileIcon className={classes.fileIconLeft} />
                <Typography variant="subtitle1" className={classes.accordianHeading}>{ele.file_name}</Typography>
              </Grid>
              <Grid item xs={1} className={classes.fileTableIconRight}>
                <SaveAltIcon
                  className={classes.iconClick}
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadFile(ele);
                  }}
                />
                {props.showDelete && <DeleteIcon data-test-id="deleteIcon" onClick={() => props.removePolicyOverviewFile(ele.id)} className={classes.iconClick} />}
              </Grid>
            </Grid>
          )
        })}
        {!props.uploadedFileList.length && (
          <Grid key={`fileItem-${1}`} container alignItems="center" className={classes.fileTableData}>
            <Grid item xs={12} className={classes.fileTableIconCenter}>
              <Typography variant="body1">No file uploaded</Typography>
            </Grid>
          </Grid>
        )}
      </>
    )
}

export default FileListItem