// Customizable Area Start
import React from "react";
import { Container } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import InsurerController from "./InsurerAddController";
import InsurerContant from "../../../components/src/Insurer/InsurerContant";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

export default class InsurerAdd extends InsurerController {
   
    render() {
        return (
            <ThemeProvider theme={theme} >
                <Container id="customerContainer" maxWidth="lg" dir={(window.localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"} >

                    {/* @ts-ignore */}
                    
                    <InsurerContant  {...this.props} />

                </Container>
            </ThemeProvider>
        );
    }
}
// Customizable Area End