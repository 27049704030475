Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
// exports.errorEmailNotValid = "Email not valid.";
// exports.errorPasswordNotValid = "Password not valid.";
// exports.placeHolderEmail = "Email";
// exports.placeHolderPassword = "Password";
// exports.labelHeader = "The reason we require login";
// exports.btnTxtLogin = "LOG IN";
// exports.labelRememberMe = "Remember me";
// exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
// exports.labelOr = "OR";
// exports.labelTitle = "Log in";

// exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
// exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";

exports.APIUserType = "email_account"
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.titleLogin = 'Login'
exports.ArabicLoginTitle = "البريد الإلكتروني"
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.emailNotValid= false;
exports.passwordNotValid = false;
exports.emailErrorMsg = "No account registered with this email"
exports.ArabicEmailErrorMsg = "لا يوجد حساب مسجل بهذا البريد الإلكتروني"
exports.passwordErrorMsg = "Incorrect Password"
exports.ArabicPasswordErrorMsg = "كلمة سر خاطئة"
exports.isArabicLenguage = false
exports.ArabicTextLogin = "تسجيل الدخول"
exports.ArabicTextEmail = "البريد الإلكتروني"
exports.emailText = "Email"
exports.passwordText = "Password"
exports.ArabicPasswordText = "كلمة السر"
exports.ArabicTextForgotPassword = "البريد الإلكتروني"
exports.forgotPasswordText = "Forgot Password?"
exports.ArabicEmailText = "كلمة السر"
exports.pleaseEnterAPassword = "Please enter a password";
exports.ArabicPleaseEnterPasswword = 'الاسم الأول مطلوب';

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
// Customizable Area End