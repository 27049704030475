import { makeStyles } from "@material-ui/core";
import React from "react";
import Select from "react-select";
import { StylesConfig } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";


const useStyles = makeStyles({

    selectError: {
        '& > div': {
            // height: '56px',
            borderColor: 'red',
            backgroundColor: '#fff',
        }
    },
    selectRemoveicon: {
        '& [role="button"]': {
            display: 'none'
        }
    }
})

type MyOption = {
    label: string;
    value: any;
};

type GroupedOption = {
    label: string; // group label
    options: MyOption[];
};

type Props = {
    name: string;
} & Omit<
    StateManagerProps<MyOption, false | true, GroupedOption>,
    "value" | "onChange"
>;

const colourStyles: StylesConfig = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', marginTop: "10px", marginBottom: "10px", height: '55px' }),
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: "#cbdeec",
        };
    },
};

const StaticMultiSelect = (props: any) => {
    const { name, ...restProps } = props;
    const classes = useStyles();

    return (
        <div>
     {/*@ts-ignore*/}
           
            <Select
                styles={colourStyles}
                {...restProps}
                options={props.options}
                isDisabled={props.disabled || false}
                className={`${props.showError ? classes.selectError : ''} ${props.disable ? classes.selectRemoveicon : ''}`}
                {...props}
                value={props.value}
            />
        </div>
    )
}

export default StaticMultiSelect