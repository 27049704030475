import React from "react";

import {
    Container,
    Paper,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import EmailRegistrationForm from "../../../components/src/EmailRegistration/EmailRegistrationForm";

import EmailAccountRegistrationController, {
    Props
} from "./EmailAccountRegistrationController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
});
const componentStyles = {
    centerContent: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    logoImage: {
        height: '200px',
        width: '200px'
    }
}
export default class EmailAccountRegistration extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }
    
    render() {
        return (
            <React.Fragment>
            <div className="mainDiv">
                <ThemeProvider theme={theme}>
                    <Container maxWidth="sm" dir={(window.localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"}  >
                        <div style={componentStyles.centerContent} >
                            <img style={componentStyles.logoImage} src="/assets/images/SIIB_WHITE_BG.jpg" alt="logo" />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "10px 0px",
                                width: "100%"
                            }}
                        >
                            <Paper style={{ width: '100%' }} elevation={4} >
                                <div style={{ padding: "20px 20px" }}>
                                  <EmailRegistrationForm
                                    firstName= {this.state.firstName}
                                    lastName= {this.state.lastName}
                                    phoneNumber= {this.state.phone}
                                    email= {this.state.email}
                                    isArabicLenguage={this.state.isArabicLenguage}
                                    signUpUser={this.signUpUser}
                                    setArabicLanguage={this.setArabicLanguage}
                                    ArabicSignUpTitle={this.ArabicSignUpTitle}
                                    singnUpTitle={this.singnUpTitle}
                                    termAndConditionErrorMessage={this.termAndConditionErrorMessage}
                                    apiError={this.state.apiError}
                                    pleaseTryAgainText={this.pleaseTryAgainText}
                                    ArabicFirstNameText={this.ArabicFirstNameText}
                                    firstNameText={this.firstNameText}
                                    ArabicLastNameText={this.ArabicLastNameText}
                                    lastNameText={this.lastNameText}
                                    ArabicEmailText={this.ArabicEmailText}
                                    emailText={this.emailText}
                                    handleClickShowPassword={this.handleClickShowPassword}
                                    ArabicPhoneNumberText={this.ArabicPhoneNumberText}
                                    phoneNumberText={this.phoneNumberText}
                                    ArabicPasswordText={this.ArabicPasswordText}
                                    passwordText={this.passwordText}
                                    enablePasswordField={this.state.enablePasswordField}
                                    atLeastOnceUpperCaseLetterText={this.atLeastOnceUpperCaseLetterText}
                                    atLeasetOnceLowerCaseLetterText={this.atLeasetOnceLowerCaseLetterText}
                                    atLeaseOneNumaricCharacterText={this.atLeaseOneNumaricCharacterText}
                                    minimum8CharctorText={this.minimum8CharctorText}
                                    ArabicTermAndConditionFirstText={this.ArabicTermAndConditionFirstText}
                                    TermAndConditionFirstText={this.TermAndConditionFirstText}
                                    btnTextSignUp={this.btnTextSignUp}
                                    signUpText={this.signUpText}
                                    ArabicAlreadyHaveAcconutTitle={this.ArabicAlreadyHaveAcconutTitle}
                                    alreadyHaveAccountTitle={this.alreadyHaveAccountTitle}
                                    ArabicLoginText={this.ArabicLoginText}
                                    loginText={this.loginText}
                                    ArabicTermAndConditionSecondText={this.ArabicTermAndConditionSecondText}
                                    TermAndConditionSecondText={this.TermAndConditionSecondText}
                                    ArabicTermAndConditionErrorMessage={this.ArabicTermAndConditionErrorMessage}
                                    ArabicAtLeastOnceUpperCaseLetterText={this.ArabicAtLeastOnceUpperCaseLetterText}
                                    ArabicAtLeasetOnceLowerCaseLetterText={this.ArabicAtLeasetOnceLowerCaseLetterText}
                                    ArabicAtLeaseOneNumaricCharacterText={this.ArabicAtLeaseOneNumaricCharacterText}
                                    ArabicMinimum8CharctorText={this.ArabicMinimum8CharctorText}
                                    ArabicPleaseTryAgainText={this.ArabicPleaseTryAgainText}
                                  />
                                </div>
                            </Paper>
                        </div>
                    </Container>
                </ThemeProvider>
            </div>
            </React.Fragment>
        );
    }
}
