// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import moment from "moment";
export const configJSON = require("../config");

export interface Props {
  navigation?: any;
  id?: string;
  t?: (key: string) => void;
  classes?: any;
}

interface S {
  showAlert: boolean;
  alertMessage: string;
  alertType: string;
  isArabicLenguage: boolean;
  isLoading: boolean;
  policyId: string;
  customer: string;
  policy_id: string;
  insuranceType: string;
  insurer: string;
  activationDate: string;
  expiryDate: string;
  revisionDate: string;
  originalValue: string;
  currentValue: string;
  balanceDue: string;
  sales: string;
  operations: string;
  financePerson: string;
  policyTitle: string;
  policyPage: number;
  policyRowPerPage: number;
  totalPage: number;
  policyList: any[];
  csvList: any[];
  policyListMeta: any;
  policyRequestPage: number;
  policyRequestRowPerPage: number;
  insurance_company_id: string | number;
  uploadedFileList: any[]
  policy_request_id: number | string;
  showDelete: boolean;
  removedFileId: string;
  paymentAccordian: boolean;
  transactionLoading: boolean;
  transactionPage: number;
  transactionTotalPage: number;
  transactionList: any[];
}

interface SS {
  id: any;
}

export default class PolicyListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  getPolicyDetailsAPI: any;
  getCSVDetailsAPI: any;
  getPolicyRequestAPI: any;
  getAllTransactionRecordsApi: any;

  uploadPolicyOverviewFileRequestAPI: any;
  removePolicyOverviewFileRequestAPI: any;

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    const queryParameter: any = new URLSearchParams(window.location.search);
    const policyId: string = queryParameter?.get('id');


    this.state = {
      showAlert: false,
      alertMessage: "",
      alertType: "",
      isArabicLenguage: false,
      isLoading: false,
      policyId: policyId ?? "",
      customer: "",
      policy_id: "",
      insuranceType: "",
      insurer: "",
      activationDate: "",
      expiryDate: "",
      revisionDate: "",
      originalValue: "",
      currentValue: "",
      balanceDue: "",
      sales: "",
      operations: "",
      financePerson: "",
      policyTitle: "",
      policyPage: 1,
      policyRowPerPage: 5,
      totalPage: 1,
      policyList: [],
      csvList:[],
      policyListMeta: {},
      policyRequestPage: 1,
      policyRequestRowPerPage: 5,
      insurance_company_id: '',
      policy_request_id: '',
      uploadedFileList: [],
      showDelete: true,
      removedFileId: '',
      paymentAccordian: true,
      transactionLoading: false,
      transactionPage: 1,
      transactionTotalPage:1,
      transactionList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const queryParameter: any = new URLSearchParams(window.location.search);
    const loginData = JSON.parse(localStorage.getItem('loginData') || '{}');
    const policyId: string = queryParameter?.get('id');

    if (loginData) {
      this.setState({ showDelete: ['Admin', 'Operations'].includes(loginData.role) })
    }
    if (policyId) {
      this.setState({ policyId: policyId });
      await this.getPolicyDetails(policyId);
      this.getAllTransactionRecords(policyId);
      this.getCsvDetails(policyId);
      this.getRequestDetails(policyId);
    }
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.policyRequestPage != this.state.policyRequestPage) this.getRequestDetails(this.state.policyId, this.state.policyRequestPage, this.state.policyRequestRowPerPage);
  }
  navigateToCSVImport(type: string) {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPolicyRequestCSVImportMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.PolicyRequestPayload), JSON.stringify({id:this.state.policyId , type:type}));
    this.send(msg);

  }

  navigateToRevisionRequest =()=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationRevisionRequestMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.RevisionRequestIdPayload), JSON.stringify({id: this.state.policyId}));
    this.send(msg);
  }

  setArabicLanguage = (value: boolean) => {
    const language = value === true ? "ar" : "en";
    window.localStorage.setItem("lang", language);
    this.setState({
      isArabicLenguage: value,
    });

  };

  handlePage = (event: any, page: number) => {
    this.setState({
      policyPage: page,
    });
  };

  handleRow = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      policyRowPerPage: Number(event.target.value),
    });
  };


  policyDetailApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    this.setState({isLoading: true })

    const userData = JSON.parse(localStorage.getItem("loginData") ?? "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const requestMessageForPolicyDetail = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessageForPolicyDetail.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessageForPolicyDetail.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    requestMessageForPolicyDetail.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body && type !== 'FormData' ?
      requestMessageForPolicyDetail.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body))
      :
      requestMessageForPolicyDetail.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);

    runEngine.sendMessage(requestMessageForPolicyDetail.id, requestMessageForPolicyDetail);
    return requestMessageForPolicyDetail.messageId;
  }

  getPolicyDetails = async (policyId:any) => {
    this.getPolicyDetailsAPI = await this.policyDetailApiCall({
      contentType: 'application/json',
      method: 'GET',
      endPoint: `bx_block_policies/policies/${policyId}`,
    })
  }
  getCsvDetails = async (policyId:any) => {
    this.getCSVDetailsAPI = await this.policyDetailApiCall({
      contentType: 'application/json',
      method: 'GET',
      endPoint: `bx_block_policies/policies/get_vehicle_employee_data?id=${policyId}`,
    })
  }
  getRequestDetails = async (policyId:any, page: number = 1, perPage: number = 5) => {
    this.getPolicyRequestAPI = await this.policyDetailApiCall({
      contentType: 'application/json',
      method: 'GET',
      endPoint: `bx_block_policy_request/policy_requests/?filter[policy_id]=${policyId}&page=${page}&per_page=${perPage}`,
    })
  }
  getAllTransactionRecords = async (policyId: string) => {
    this.setState({transactionLoading:true})
    this.getAllTransactionRecordsApi = await this.policyDetailApiCall({
      contentType: 'application/json',
      method: 'GET',
      endPoint: `bx_block_visual_analytics/transactions/?policy_id=${policyId}`,
    })
  }
  handlePaymentAccordian = () => {
    this.setState({
      paymentAccordian: !this.state.paymentAccordian
    })
  }
  getAllTransactionRecordsApiReceive = (message:Message) =>{
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllTransactionRecordsApi != null &&
      this.getAllTransactionRecordsApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      if (responseJson.data) {
        this.setState({
          isLoading: false,
          transactionLoading: false,
          transactionList: responseJson.data,
        })
      } else {
        this.errorMessageApi(responseJson);
        this.setState({isLoading: false, transactionLoading: false});
      }
    }
  }
  handlePolicyRequestPage = (event: any, page: number) => {
    this.setState({
      policyRequestPage: page,
    });
  };

  handlePolicyRequestRow = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      policyRequestRowPerPage: Number(event.target.value),
    });
  };

  errorMessageApi = (responseJson:any) => {
    if (responseJson?.errors) {
      if(responseJson.errors[0]?.token){
        this.setState({
          showAlert:true,
          alertType:"error",
          alertMessage:responseJson.errors[0].token
        })
        window.localStorage.removeItem('loginData');
        window.location.href = "/EmailAccountLoginBlock"
      } else
      if (typeof(responseJson.errors[0]) === "string") {
        this.setState({
          showAlert:true,
          alertType:"error",
          alertMessage:responseJson.errors[0]
        });
        window.location.href = "/Policies"
      }
    }
  }

  getPolicyDetailsAPIReceive = (message:Message) =>{
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPolicyDetailsAPI != null &&
      this.getPolicyDetailsAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      if (responseJson?.data?.attributes) {
        const apiResponse = responseJson.data.attributes;
        let policyTitle = 'other'
        if(apiResponse.types_of_insurance.toLowerCase() === 'motor') policyTitle = 'vehicle';
        if(apiResponse.types_of_insurance.toLowerCase() === 'medical' || apiResponse.types_of_insurance.toLowerCase() === 'life') policyTitle = 'employees';
        this.setState({
          isLoading: false,
          customer: apiResponse.customer.name[0].toUpperCase() + apiResponse.customer.name.slice(1),
          policy_id: apiResponse.policy_id,
          policy_request_id: apiResponse.policy_request_id,
          insuranceType: apiResponse.types_of_insurance,
          insurer: apiResponse.insurance_company_id.short_name,
          insurance_company_id: apiResponse.insurance_company_id.id,
          activationDate: moment(apiResponse.policy_activation_date).format("YYYY-MM-DD"),
          expiryDate: moment(apiResponse.policy_expiry_date).format("YYYY-MM-DD"),
          revisionDate: moment(apiResponse.last_revision_date).format("YYYY-MM-DD"),
          originalValue: apiResponse.original_policy_value,
          currentValue: apiResponse.current_policy_value,
          balanceDue: apiResponse.policy_balance_value,
          sales: apiResponse.sales_id.name,
          operations: apiResponse.operation_id.name,
          financePerson: apiResponse.finance_id.name,
          policyTitle: policyTitle,
          uploadedFileList: apiResponse.proposal_files || [],
        })
      } else {
        this.errorMessageApi(responseJson);
        this.setState({isLoading: false});
      }
    }
  }
  getPolicyRequestAPIReceive = (message:Message) =>{
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPolicyRequestAPI != null &&
      this.getPolicyRequestAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      if (responseJson.data) {
        this.setState({
          isLoading: false,
          policyList: responseJson.data,
          policyListMeta: responseJson.meta,
        })
      } else {
        this.errorMessageApi(responseJson);
        this.setState({isLoading: false});
      }
    }
  }

  getFormDataForFileUpload = (fileData: any) => {
        const formData = new FormData();
        formData.append("data[stage]", "4");
        formData.append("data[file_key]", 'proposal');
        formData.append("data[multiple]", "true");
        formData.append("data[insurance_company_id]", `${this.state.insurance_company_id}`)
        for (const item of  fileData) {
            formData.append(`data[file][]`,item);
        }
        return formData;
  }
  uploadPolicyOverviewFile = async (fileData: any) => {
    this.uploadPolicyOverviewFileRequestAPI = await this.policyDetailApiCall({
      type : 'FormData',
      method: 'PUT',
      body: this.getFormDataForFileUpload(fileData),
      endPoint: `bx_block_policy_request/policy_requests/${this.state.policy_request_id}/file_upload`,
    })
  }
  uploadPolicyOverviewFileAPIReceive = (message:Message) =>{
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.uploadPolicyOverviewFileRequestAPI != null &&
      this.uploadPolicyOverviewFileRequestAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      if (responseJson.data) {
        this.setState({
          isLoading: false,
          uploadedFileList: [...this.state.uploadedFileList, ...responseJson.data.uploaded_file],
        })
      } else {
        this.errorMessageApi(responseJson);
        this.setState({isLoading: false});
      }
    }
  }
  removePolicyOverviewFile = async (fileId: any) => {
    this.setState({ removedFileId: fileId})
    this.removePolicyOverviewFileRequestAPI = await this.policyDetailApiCall({
      contentType: 'application/json',
      method: 'PUT',
      endPoint: `bx_block_policy_request/policy_requests/${this.state.policy_request_id}/remove_file`,
      body: {
        data: {
          "file_id": fileId
        }
      },
    })
  }
  removePolicyOverviewFileAPIReceive = (message:Message) =>{
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.removePolicyOverviewFileRequestAPI != null &&
      this.removePolicyOverviewFileRequestAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      if (responseJson.data) {
        const updatedFileList = this.state.uploadedFileList.filter((ele: any) => ele.id != this.state.removedFileId)
        this.setState({
          isLoading: false,
          uploadedFileList: updatedFileList,
          removedFileId: '',
        })
      } else {
        this.errorMessageApi(responseJson);
        this.setState({isLoading: false});
      }
    }
  }
  getCsvDetailsAPIReceive = (message:Message) =>{
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCSVDetailsAPI != null &&
      this.getCSVDetailsAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      if (responseJson.data) {
       this.setState({
          isLoading: false,
          csvList:responseJson.data,
          totalPage: responseJson.meta.total_page,
        })
      } else {
        this.errorMessageApi(responseJson);
        this.setState({isLoading: false});
      }
    }
  }
  async receive(from: string, message: Message) {
    this.getPolicyDetailsAPIReceive(message);
    this.getCsvDetailsAPIReceive(message);
    this.getPolicyRequestAPIReceive(message);
    this.getAllTransactionRecordsApiReceive(message);
    this.uploadPolicyOverviewFileAPIReceive(message);
    this.removePolicyOverviewFileAPIReceive(message);
  }
}
// Customizable Area End
