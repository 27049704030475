const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
const {baseURL} = require('../../../framework/src/config')

export const  GetLoggedUserData = () => {
  return userData
}

export const getLoggedUserToken = () => {
  return userData?.token || ""
}

export const getLoggedUserRole = () => {
  return userData?.role || ""
}

export const isTokenExpire= async () => {
  try {
      const tempLocalStorage:any = localStorage.getItem('loginData')
        const { token } = await JSON.parse(tempLocalStorage);
      const isTokenExpire:any = await fetch(`${baseURL as any}/account_block/accounts/token_expire`, {
        method: 'GET',
        headers: {
          "token": token
        },
      })
      if (!isTokenExpire.ok) {
        window.localStorage.removeItem('loginData');
        window.location.href = '/EmailAccountLoginBlock';
        return;
      }
    
      const tokenData = await isTokenExpire.json();
      if(tokenData.data?.is_token_expire ){
        window.localStorage.removeItem('loginData');
        window.location.href = '/EmailAccountLoginBlock';
        return;
      }

    } catch (error) {
      console.log(error,"Error for token checking API")
    }}

export default GetLoggedUserData;