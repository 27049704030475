import React from "react";
import { Container } from "@material-ui/core";
// @ts-ignore
import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import PolicyInfoController, { Props } from "./PolicyInfoController";
import StageFour from "./PolicyStage/StageFour.web";
import StageTwo from "./PolicyStage/StageTwo.web"
import StageThree from "./PolicyStage/StageThree.web";
import StageOne from './PolicyStage/StageOne.web';
import StageFive from './PolicyStage/StageFive.web';
import HorizontalStepper from "../../../components/src/Common/HorizontalStepper";
import StageSix from "./PolicyStage/StageSix.web";
import StageEight from "./PolicyStage/StageEight/StageEight.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import StageSeven from "./PolicyStage/StageSeven.web";
import StageNine from "./PolicyStage/StageNine/StageNine.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
});

let queryParameter: any;
let policyId: string; 
export class PolicyInfo extends PolicyInfoController {
    constructor(props: Props) {
        super(props);
        queryParameter = new URLSearchParams(window.location.search);
        policyId =  queryParameter?.get('id') || '';
    }
    checkIfPreviousStageIsCreated = () => {

    }
    checkCreationOfStage = (stageBool: boolean, step: number, isNext: boolean, prevStageCreated: boolean) => {
        if(policyId){
            if(!isNext && prevStageCreated) return true
            return stageBool
        } else {
            return true
        }
    }
    render() {
        // @ts-ignore
        const { t } = this.props;
        // @ts-ignore
        const { classes } = this.props;

        
        function getStepContent(step: number) {
            return [t('info_gathering'), t('processing'), t('awaiting'), t('operations_review'), t('sales_review'), t('customere_review'), t('payment_review'), t('issuance'), t('invoicing'), t('completed')]
        }

        return (
            <div data-test-id="PolicyStageOne" className={this.state.isLoading ? classes?.loadingState : classes?.mainSection}>
                        
                <ThemeProvider theme={theme} >
                    <Container maxWidth="xl">
                        {
                        this.state?.customerName && 
                        (<div className={classes?.newPolicyTitle}>
                            <strong>{t('new_policy_request')}</strong> {t('for')} <strong>{this.state.customerName}</strong>
                        </div>)
                        }

                        
                        <HorizontalStepper getStepContent={getStepContent} activeStep={this.state.currentSteps} />

                        {
                        this.state.isLoading && 
                            <div className={classes?.policyPageLoader}>
                                 <CircularProgress/>
                            </div>
                        }   
                        {(this.state.currentSteps >= 1) && (
                            <StageOne
                                isExpanded={this.state.currentSteps === 1}
                                isEdit={this.state.isEdit}
                                onNext={this.onNext}
                                setStageData={this.setStageData}
                                navigation={this.props.navigation}
                            />
                        )}
                        {((this.state.currentSteps >= 2) && this.checkCreationOfStage(this.state.isStageTwoCreated, 2, this.state.isNextStageTwo, true)) && (
                            <StageTwo
                                isExpanded={this.state.currentSteps === 2}
                                isEdit={this.state.isEdit}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                setStageData={this.setStageData}
                                navigation={this.props.navigation}
                            />
                        )}
                        {((this.state.currentSteps >= 3) && this.checkCreationOfStage(this.state.isStageThreeCreated, 3, this.state.isNextStageThree, true)) && (
                            <StageThree
                                isExpanded={this.state.currentSteps === 3}
                                isEdit={this.state.isEdit}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                setStageData={this.setStageData}
                            />
                        )}
                        {((this.state.currentSteps >= 4) && this.checkCreationOfStage(this.state.isStageFourCreated, 4, this.state.isNextStageFour, true)) && (
                            <StageFour
                                isExpanded={this.state.currentSteps === 4}
                                isEdit={this.state.isEdit}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                setStageData={this.setStageData}
                            />
                        )}
                        {((this.state.currentSteps >= 5) && this.checkCreationOfStage(this.state.isStageFiveCreated, 5, this.state.isNextStageFive, true)) && (
                            <StageFive
                                isExpanded={this.state.currentSteps === 5}
                                isEdit={this.state.isEdit}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                setStageData={this.setStageData}
                            />
                        )}
                        {((this.state.currentSteps >= 6) && this.checkCreationOfStage(this.state.isStageSixCreated, 6, this.state.isNextStageSix, true)) && (
                            <StageSix
                                isExpanded={this.state.currentSteps === 6}
                                isEdit={this.state.isEdit}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                setStageData={this.setStageData}
                            />
                        )}
                       {((this.state.currentSteps >= 7) && (this.checkCreationOfStage(this.state.isStageSevenCreated, 7, this.state.isNextStageSeven, this.state.isStageSixCreated))) && (
                            <StageSeven
                                isExpanded={this.state.currentSteps === 7}
                                isEdit={this.state.isEdit}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                stageFourData={this.state.stageFourData}
                                stageSixData={this.state.stageSixData}
                                stageSevenData={this.state.stageSevenData}

                            />
                        )}
                        {(this.state.currentSteps >= 8) && (this.checkCreationOfStage(this.state.isStageEightCreated, 8, this.state.isNextStageEight, this.state.isStageSevenCreated)) && (
                            <StageEight
                                isExpanded={this.state.currentSteps === 8}
                                isEdit={this.state.isEdit}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                stageEightData={this.state.stageEightData}
                                stageOneData={this.state.stageOne}
                                stageTwoData={this.state.stageTwoData}
                                stageSixData={this.state.stageSixData}
                                stageSevenData={this.state.stageSevenData}
                                setStageData={this.setStageData}
                            />
                        )}
                        {(this.state.currentSteps >= 9) && (this.checkCreationOfStage(this.state.isStageNineCreated, 9, this.state.isNextStageNine, this.state.isStageEightCreated)) && (
                            <StageNine
                                isExpanded={this.state.currentSteps === 9}
                                isEdit={this.state.isEdit}
                                onNext={this.onNext}
                                onReject={this.onReject}
                                stageOneData={this.state.stageOne}
                                stageSevenData={this.state.stageSevenData}
                                stageEightData={this.state.stageEightData}
                                setStageData={this.setStageData}
                            />
                        )}
                       
                    </Container>
                </ThemeProvider>
            </div >
        )
    }
}

const useStyles = ({
    primaryBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "height": "50px",
        "width": "150px",
        borderRadius: "10px"
    },
    policyPageLoader: {
        display: 'flex',
        "position": "absolute",
        alignItems: 'center',
        justifyContent: 'center',
        left: '0',
        right: '0',
        margin: '0 auto',
        top: '50%',
        zIndex: '99'
    },
    mainSection: {
        "position": "relative"
    },
    loadingState: {
        "position": "relative",
        "pointer-events": "none",
        opacity: 0.5
    },
    outlineBtn: { color: '#3b8da2', "text-transform": "none" }
    
});

export default withStyles(useStyles as any)(withTranslation()(PolicyInfo));
