import * as Yup from 'yup';
import { emptyStringToUndefined } from '../Common/EmptyStringToUndefined';

const CustomerFormSchema = (isArabicLenguage:boolean) => {
    const arbicLanguage = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
    const schema = {
        customerName: Yup.string()
            .min(2, !arbicLanguage ? "Customer name is too short" : "اسم العميل قصير جدا")
            .max(20, !arbicLanguage ? "Customer name is too Long" : "اسم العميل طويل جدًا")
            .required(arbicLanguage ? 'اسم العميل مطلوب' : "Customer Name is required."),
        status: Yup.string().required(arbicLanguage ? 'الحالة مطلوبة' : "Status is required."),
        customerId: Yup.string().notRequired(),
        
        sourceName: Yup.string()
            .min(2, !arbicLanguage ? "Source name is too short" : "اسم المصدر قصير جدًا")
            .max(20, !arbicLanguage ? "First name is too long" : "اسم المصدر طويل جدًا")
            .matches(/^[A-Za-z\u0600-\u06FF]+(?:[\s]+[A-Za-z\u0600-\u06FF]+)*$/ , arbicLanguage ? 'لا يمكن أن يحتوي هذا الحقل على أحرف أو أرقام خاصة' : 'Source name cannot contain extra space, special character or digits')
            .required(arbicLanguage ? 'المصدر مطلوب' : "Source name is required."),
       
            industry: Yup.string()
            .min(2, !arbicLanguage ? "Industry Name is too short" : "هذا المجال قصير جدا")
            .max(20, !arbicLanguage ? "Industry Name is too long" : "هذا الحقل طويل جدًا")
            .matches(/^[A-Za-z\u0600-\u06FF]+(?:[\s]+[A-Za-z\u0600-\u06FF]+)*$/, arbicLanguage ? 'لا يمكن أن يحتوي هذا الحقل على أحرف أو أرقام خاصة' : 'Industry cannot contain extra space, special character or digits')
            .required(arbicLanguage ? 'الصناعة مطلوبة' : "Industry is required."),
        VATNumber: Yup
            .number().min(1,arbicLanguage ? 'يجب أن يبدأ رقم ضريبة القيمة المضافة من 1' : "VAT number should be starts from 1").typeError(arbicLanguage ? 'يجب أن يكون رقمًا' : 'VAT number must be a number')
            .transform(emptyStringToUndefined)
            .nullable()
            .notRequired(),
        segment: Yup.array().min(1, arbicLanguage ? "الرجاء تحديد جزء واحد على الأقل" : "Please select at least one segment").required(arbicLanguage ? 'الرجاء تحديد جزء واحد على الأقل' : "Please select atleast one segment."),
        contactsDetail: Yup.array(Yup.object().shape({
            customerFirstName: Yup.string()
                .min(2, !arbicLanguage ? "First name is too short" : "الاسم الأول قصير جدًا")
                .max(20, !arbicLanguage ? "First name is too long" : "الاسم الأول طويل جدًا")
                .matches(/^[A-Za-z\u0600-\u06FF]+(?:[\s]+[A-Za-z\u0600-\u06FF]+)*$/, arbicLanguage ? 'يجب ألا يحتوي هذا الحقل على مسافة بيضاء أو أحرف خاصة أو أرقام' : 'Customer first name field should not contain white space, special character or digits')
                .required(arbicLanguage ? 'الاسم الأول مطلوب' : "First Name is required."),
            customerLastName: Yup.string()
                .min(2, !arbicLanguage ? "Last name is too short" : "الاسم الأخير قصير جدًا")
                .max(20, !arbicLanguage ? "Last name is too long" : "الاسم الأخير طويل جدًا")
                .matches(/^[A-Za-z\u0600-\u06FF]+(?:[\s]+[A-Za-z\u0600-\u06FF]+)*$/, arbicLanguage ? 'يجب ألا يحتوي هذا الحقل على مسافة بيضاء أو أحرف خاصة أو أرقام' : 'Customer last name should not contain white space, special character or digits')
                .required(arbicLanguage ? "اسم العائلة مطلوب" : 'Last Name is required.'),
            position: Yup.string()
                .min(2, !arbicLanguage ? "Position is too short" : "هذا المجال قصير جدا")
                .max(20, !arbicLanguage ? "Position is too long" : "هذا الحقل طويل جدًا")
                .matches(/^[A-Za-z\u0600-\u06FF]+(?:[\s]+[A-Za-z\u0600-\u06FF]+)*$/, arbicLanguage ? 'لا يمكن أن يحتوي هذا الحقل على أحرف أو أرقام خاصة' : 'Position cannot contain extra space, special character or digits')
                .required(arbicLanguage ? "الموقف يتطلب" : "Position is required"),
            email: Yup.string()
                .min(2, !arbicLanguage ? "Email is too short" : "البريد الإلكتروني قصير جدًا")
                .max(50, !arbicLanguage ? "Email is too long" : "البريد الإلكتروني طويل جدًا")
                .email(arbicLanguage ? 'يجب ان يكون البريد الاكتروني صحيح' : 'Email must be valid').required(arbicLanguage ? 'اسم العائلة مطلوب' : 'Email is required.'),
            phoneNumber: Yup.string().matches(/^(05|5)(0|1|2|3|4|5|6|7|8|9)([0-9]{7})$/, arbicLanguage ? 'رقم الهاتف غير صالح' : 'Phone number is not valid').required(arbicLanguage ? 'رقم الهاتف مطلوب' : 'Phone number is required.'),
            preferredLanguage: Yup.string().required(arbicLanguage ? 'اللغة المفضلة تتطلب' : "Preferred language is required."),
        })),
        branchDetail: Yup.array(Yup.object().shape({
            first_commercial_license_legal_name: Yup.string()
                .min(2, !arbicLanguage ? "First commercial license legal name is too short" : "هذا المجال قصير جدا")
                .max(50, !arbicLanguage ? "First commercial license legal name is too long" : "هذا الحقل طويل جدًا")
                .matches(/^[A-Za-z\u0600-\u06FF]+(?:[\s]+[A-Za-z\u0600-\u06FF]+)*$/, arbicLanguage ? 'لا يمكن أن يحتوي هذا الحقل على أحرف أو أرقام خاصة' : 'First commercial license legal name cannot contain extra space, special character or digits')
                .required(arbicLanguage ? 'الرجاء إدخال الاسم القانوني الأول للرخصة التجارية' : "Please enter first commercial license legal name."),
            sponsor_IDCR: Yup.number()
                .min(1,!arbicLanguage ? 'Sponsor ID / CR should be minimum 1' : 'يجب ألا يقل رقم تعريف الكفيل / السجل التجاري عن 1')
                .max(999999999999,arbicLanguage ? "يجب أن يكون رقم هوية الكفيل / السجل التجاري بحد أقصى 999999999999" : "Sponsor ID / CR should be maximum 999999999999").required(arbicLanguage ? 'الرجاء إدخال IDCR الراعي' : "Please enter sponsor IDCR."),
            upload_sponsor_id_cr: Yup.mixed()
                .test(
                "isNotEmpty",
                arbicLanguage ? 'يرجى تحميل وثيقة السجل التجاري لمعرف الكفيل' : 'Please upload sponsor ID CR document',
                function (value) {
                    const sponsorIdCr = this.resolve(Yup.ref("upload_sponsor_id_cr"));
                    if ((typeof sponsorIdCr === "string" && sponsorIdCr.trim() === "") || // Check if it's an empty string (URL)
                      (sponsorIdCr && typeof sponsorIdCr === "object" && !sponsorIdCr.size) // Check if it's a file blob without size
                    ) {
                    return false;
                    } else {
                        return true;
                    }
                }
                )
                .test(
                    "isValidFile",
                    arbicLanguage ? "يرجى تحميل وثيقة السجل التجاري لمعرف الكفيل" : "Please upload sponsor ID CR document",
                  function (value) {
                    if (
                      (typeof value === "string" && value.trim() !== "") || // Check if it's a non-empty string (URL)
                      (value && value instanceof File) // Check if it's a file blob
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                )
                .nullable()
                .required(
                  arbicLanguage
                    ? "يرجى تحميل وثيقة السجل التجاري لمعرف الكفيل"
                    : "Please upload sponsor ID CR document"
                )
        })),
        country: Yup.string()
            .min(2, !arbicLanguage ? "Country name is too short" : "الاسم الأخير قصير جدًا")
            .max(20, !arbicLanguage ? "Country name is too long" : "الاسم الأخير طويل جدًا")
            .matches(/^[A-Za-z\u0600-\u06FF]+(?:[\s]+[A-Za-z\u0600-\u06FF]+)*$/, arbicLanguage ? 'لا يمكن أن يحتوي هذا الحقل على أحرف أو أرقام خاصة' : 'Country cannot contain extra space, special character or digits')
            .required(arbicLanguage ? 'الرجاء إدخال البلد' : "Please enter the country"),
        region: Yup.string()
            .min(2, !arbicLanguage ? "Region name is too short" : "الاسم الأخير قصير جدًا")
            .max(20, !arbicLanguage ? "Region name is too long" : "الاسم الأخير طويل جدًا")
            .matches(/^[A-Za-z\u0600-\u06FF]+(?:[\s]+[A-Za-z\u0600-\u06FF]+)*$/, arbicLanguage ? 'لا يمكن أن يحتوي هذا الحقل على أحرف أو أرقام خاصة' : 'Region cannot contain extra space, special character or digits')
            .required(arbicLanguage ? 'الرجاء إدخال المنطقة' : "Please enter region."),
        city: Yup.string()
            .min(2, !arbicLanguage ? "City name is too short" : "الاسم الأخير قصير جدًا")
            .max(20, !arbicLanguage ? "City name is too long" : "الاسم الأخير طويل جدًا")
            .matches(/^[A-Za-z\u0600-\u06FF]+(?:[\s]+[A-Za-z\u0600-\u06FF]+)*$/, arbicLanguage ? 'لا يمكن أن يحتوي هذا الحقل على أحرف أو أرقام خاصة' : 'City cannot contain extra space, special character or digits')
            .required(arbicLanguage ? 'الرجاء إدخال المنطقة' : "Please enter city."),
        district: Yup.string()
            .min(2, !arbicLanguage ? "District name is too short" : "الاسم الأخير قصير جدًا")
            .max(20, !arbicLanguage ? "District name is too long" : "الاسم الأخير طويل جدًا")
            .matches(/^[A-Za-z\u0600-\u06FF]+(?:[\s]+[A-Za-z\u0600-\u06FF]+)*$/, arbicLanguage ? 'لا يمكن أن يحتوي هذا الحقل على أحرف أو أرقام خاصة' : 'District cannot contain extra space, special character or digits')
            .required(arbicLanguage ? 'الرجاء إدخال الحي' : "Please enter district"),
        streetName: Yup.string()
            .min(2, !arbicLanguage ? "Street name is too short" : "الاسم الأخير قصير جدًا")
            .max(20, !arbicLanguage ? "Street name is too long" : "الاسم الأخير طويل جدًا")
            .required(arbicLanguage ? 'الرجاء إدخال اسم الشارع' : "Please enter street name."),
        buildingNumber: Yup.number()
            .min(1,!arbicLanguage ? 'Bulding number should be minimum 1' :'يجب أن يبدأ رقم المبنى من 1')
            .max(9999999999, !arbicLanguage ? 'Bulding number should be maximum 9999999999':'يجب أن يكون رقم المبنى بحد أقصى 9999999999').required(arbicLanguage ? 'الرجاء إدخال رقم المبنى' : "Please enter bulding number."),
        postalCode: Yup.number()
            .min(10000,arbicLanguage ? 'يجب أن يبدأ الرمز البريدي للمملكة العربية السعودية بـ 10000' : "Saudi Arabia's postal code should be start with 10000")
            .max(999999, arbicLanguage ? 'يجب ألا يزيد الرمز البريدي للمملكة العربية السعودية عن 999999' : "Saudi Arabia Postal code should not be more than 999999").required(arbicLanguage ? 'الرجاء إدخال الرمز البريدي' : "Please enter postal code."),
        unitNumber: Yup.string().required(arbicLanguage ? 'الرجاء إدخال الرمز البريدي' : "Please enter unit number."),
        additionalNumber: Yup.number()
            .min(1,!arbicLanguage ? 'Additional number should be minimum 1' : "يجب ألا يقل الرقم الإضافي عن 1")
            .max(99999,!arbicLanguage ? 'Additional number should be maximum 99999' : "يجب ألا يتجاوز الرقم الإضافي 99999").required(arbicLanguage ? 'الرجاء إدخال رقم إضافي' : "Please enter additional number."),
        addressProof:Yup.mixed()
            .test(
                "isNotEmpty",
                arbicLanguage ? 'يرجى تقديم وثيقة إثبات العنوان' : 'Please upload address proof document',
                function (value) {
                    const sponsorIdCr = value;
                    if (typeof sponsorIdCr === "string" && sponsorIdCr === "") {
                        return false;
                    } else if (
                        sponsorIdCr &&
                        typeof(sponsorIdCr) === "object" &&
                        !sponsorIdCr.size 
                    ) {
                    return false;
                    } else {
                        return true;
                    }
                }
                )
                .test(
                    "fileSize",
                    arbicLanguage ? "مستند إثبات العنوان كبير جدًا ، يسمح بأقل من 10 ميغابايت" :"Address proof document too large, allowed less than 10 MB",
                    (file) => {
                        if (typeof (file) === "string" && file !== "") {
                            return true;
                        } else 
                        if (file && file.size) {
                            return file.size <= 10000000;
                        } else {
                            return true;
                        }
                    }
                ),
        risk: Yup.string()
            .required(arbicLanguage ? 'الرجاء إدخال رقم إضافي' : "Please enter the risk detail.")
    }
    return schema;
}

export default CustomerFormSchema;