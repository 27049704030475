import React from "react";
import {
  Typography,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { ErrorMessage, FieldArray, Form, Formik, useField } from "formik";
import {
  ExpandMore,
  FileCopyOutlined
} from "@material-ui/icons";
// @ts-ignore
import { withTranslation } from "react-i18next";
import {
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import StageTwoController, {Props} from "../../../blocks/TaskAllocator/src/PolicyStage/StageTwoController";
import TextInput from "../../../components/src/Common/TextInput";
import LabelWithIcon from "../../../components/src/Common/LabelWithIcon";
import SelectBoxFormik from "../../../components/src/Common/SelectBoxFormik";
import CustomCheckBox from "../../../components/src/CustomCheckBox";
import MultipleFileUpload from "../../src/Common/MultipleFileUpload";
import MultiSelectFormik from '../../src/Common/MultiSelectFormik'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Toster from "../Common/Toster";
import  CSVExport  from "../Common/CsvExportDetails";




const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
});

export class ProcessingInternalForm extends StageTwoController {
    emailRef: React.RefObject<HTMLInputElement>;
    emailDraftRef: React.RefObject<HTMLInputElement>;
    emailSentRef: React.RefObject<HTMLInputElement>;
    emailSubjectRef: React.RefObject<HTMLInputElement>;
    constructor(props: Props) {
        super(props);
        this.emailRef = React.createRef<HTMLInputElement>();
        this.emailDraftRef = React.createRef<HTMLInputElement>() as any;
        this.emailSubjectRef = React.createRef<HTMLInputElement>();
        this.emailSentRef = React.createRef<HTMLInputElement>();           
    }
   

  render() {
    // @ts-ignore
      const { 
        classes,
        t,
        touched, 
        errors, 
        setFieldValue, 
        insurerIndex, 
        insurer, 
        values,
        formikRefInst,
        isExpanded

        
      } = this.props;
    
          const handleCopyClick = (ref: any) => {
            if (ref.current) {
                const textField = ref.current.querySelector('input') || ref.current.querySelector('textarea');
                if (textField) {
                  textField.select();
                  document.execCommand('copy');
                }
              
            }
          };



      const contactList = insurer.contact?.map((val: any)=> ({label: val.first_name , value: val.id, allData: {id: val.id}} ))

      const getSelectedInsurerContact = (id: any) => {
        return insurer.contact.find((val:any)=> val.id === id)
      }

      const getStageDataValues = () => {
        if(insurerIndex !== undefined &&  values?.stageData?.length> 0)
        return values?.stageData[insurerIndex]
      }

      const getTouched =() => {
        const touchedKeys = Object.keys(touched)
        if(touchedKeys?.length > 0 && touchedKeys.includes("stageData") && insurerIndex !== undefined)
        return touched?.stageData[insurerIndex]
      }
      const getErrors = () => {
        const errorKeys = Object.keys(errors)

        if(errorKeys.length > 0 && errorKeys.includes("stageData") && insurerIndex !== undefined)
        return errors?.stageData[insurerIndex]
      }

      const getselectedCustomer = () => {
        if(insurer?.contact && insurer.contact.length > 0){
          const list = insurer.contact?.map((val: any)=> ({label: val.first_name , value: val.id, allData: {id: val.id}} ))
          return list?.find((item:any)=>  getStageDataValues()?.insuaranceContactId === item.value)
        }
      }
      
      const formErrors = getErrors() || {};
      const touchedFields = getTouched() || {};
      const hasErrors = Object.keys(formErrors).length > 0;
      const hasTouchedFields = Object.keys(touchedFields).length > 0;
      const accordionClass =(hasTouchedFields &&  hasErrors)? classes.errorFormBorder : classes.formBorder;

    return (
            <div data-test-id="StageTwoDetails" className={classes.textInputStyle}>
              <Toster
                tosterText={this.state.alertMessage}
                showToster={this.state.showAlert}
                  //@ts-ignore
                tosterType={this.state.alertType as string}
                handleCloseToster={()=> this.setState({showAlert:false})}
              />
            <Accordion 
              elevation={0} 
              className={accordionClass} 
              expanded={this.state.expandProcessingAccordian}
            >
              <AccordionSummary 
                aria-expanded={true}
                expandIcon={<ExpandMore/>}
                onClick={this.handleProcessingAccording}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                  <Typography className={classes.accordianHeading}>
                    <strong>{insurer.lable}</strong> 
                  </Typography>
                  {
                    this.state?.expandProcessingAccordian && 
                   (
                    <span className={classes.openInTab}>
                      <OpenInNewIcon onClick={() => this.navigateInsurerDetails(insurer.id)} fontSize="small"/>
                    </span>
                    )
                    }
              </AccordionSummary>
              <AccordionDetails>
              <Grid container spacing={2}>
              <Grid item xs={6}>
                <LabelWithIcon label={t("contact")} />
                <MultiSelectFormik
                    disabled={!isExpanded}
                    value={insurer?.contact && insurer.contact.length > 0 && getselectedCustomer()}
                    name={`stageData[${insurerIndex}].insuaranceContactId`}
                    id='insuaranceContactId'
                    setOtherInput={(newValue: any) => {
                      setFieldValue(`stageData[${insurerIndex}].email`, getSelectedInsurerContact(newValue.id)?.email)
                      setFieldValue(`stageData[${insurerIndex}].emailSubject`, getSelectedInsurerContact(newValue.id)?.emailSubject)
                      setFieldValue(`stageData[${insurerIndex}].emailDraft`, getSelectedInsurerContact(newValue.id)?.emailBody)
                      // setFieldValue(`stageData[${insurerIndex}].emailSent`, getSelectedInsurerContact(newValue.id)?.emailBody)
                    }}
                    options={contactList}
                />
                {getTouched()?.insuaranceContactId && getErrors()?.insuaranceContactId && (
                    <Typography color="error" >{getErrors()?.insuaranceContactId?.value}</Typography>
                  )}
              </Grid>
              <Grid item xs={6}>
                <LabelWithIcon label={t("email")} />
                  
                <TextInput
                  id={"email"}
                  innerRef={this.emailRef}
                  className={classes.textInputStyle}
                  name={`stageData[${insurerIndex}].email`}
                  readOnly={true}
                  disabled={!isExpanded}
                  type="text"
                  endAdornment={ <InputAdornment position="end">
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton
                                        edge="end"
                                        onClick={() => handleCopyClick(this.emailRef)}
                                        style={{ pointerEvents: 'auto' }}
                                    >
                                        <FileCopyOutlined />
                                    </IconButton>
                                </div>
                            </InputAdornment>
                    }
                  data-test-id="email"
              />
              
              </Grid>
              <Grid item xs={12}>
                <LabelWithIcon label={t("email_subject")} />
                <TextInput
                  id={"emailSubject"}
                  innerRef={this.emailSubjectRef}
                  className={classes.textInputStyle}
                  name={`stageData[${insurerIndex}].emailSubject`}
                  type="text"
                  disabled={!isExpanded}
                  endAdornment={ <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={() => handleCopyClick(this.emailSubjectRef)}
                                        style={{ pointerEvents: 'auto' }}
                                    >
                                        <FileCopyOutlined />
                                    </IconButton>
                                </InputAdornment>
                    }
                  readOnly={true}
                  data-test-id="emailSubject"
              />
              </Grid>
              <Grid item xs={12}>
                <LabelWithIcon label={t("email_draft")} />
                <TextInput
                  id={"emailDraft"}
                  innerRef={this.emailDraftRef}
                  className={classes.textInputStyle}
                  name={`stageData[${insurerIndex}].emailDraft`}
                  type="text"
                  multiline={true}
                  minRows={4}
                  disabled={!isExpanded}
                  endAdornment={ <InputAdornment position="end">
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton
                                        edge="end"
                                        onClick={() => handleCopyClick(this.emailDraftRef)}
                                        style={{ pointerEvents: 'auto' }}
                                    >
                                        <FileCopyOutlined />
                                    </IconButton>
                                </div>
                            </InputAdornment>
                    }
                  readOnly={true}
                  data-test-id="emailDraft"
              />
              </Grid>
              <Divider className={classes.textInputStyle} />
              <Grid item xs={12}>
              <LabelWithIcon label={this.state?.stage2submitted ? t("existing_insurance") : t("mark_as_submitted")} />
                <FormControlLabel
                    color="primary"
                    disabled={!(insurerIndex !== undefined &&  values?.stageData?.length> 0 && values?.stageData[insurerIndex]?.insuaranceContactId?.value) || !isExpanded}
                    control={<Checkbox color="primary" 
                      checked={insurerIndex !== undefined && values?.stageData?.length> 0 && values?.stageData[insurerIndex]?.markAsSubmitted} 
                      onChange={(e: any) => {
                        setFieldValue(`stageData[${insurerIndex}].markAsSubmitted`, e.target.checked)

                      }} />
                    }
                    label={t('yes')}
                />
                {getTouched()?.markAsSubmitted && getErrors()?.markAsSubmitted && (
                    <Typography color="error" >{getErrors()?.markAsSubmitted}</Typography>
                  )}
                
              </Grid>
              
              <Grid item style={{display:"flex",gap:"10px"}} xs={12}>
                <div style={{width:"90%"}}>
                <LabelWithIcon label={t("email_sent")} />
                    <TextInput
                    id={"emailSent"}
                    innerRef={this.emailSentRef}
                    className={classes.textInputStyle}
                    name={`stageData[${insurerIndex}].emailSent`}
                    multiline={true}
                    disabled={!(getStageDataValues()?.markAsSubmitted) || !isExpanded}
                    minRows={4}
                    endAdornment={ <InputAdornment position="end">
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton
                                        edge="end"
                                        onClick={() => handleCopyClick(this.emailSentRef)}
                                        style={{ pointerEvents: 'auto' }}
                                    >
                                        <FileCopyOutlined />
                                    </IconButton>
                                </div>
                            </InputAdornment>
                    }
                    data-test-id="emailSent"
                />
                
                </div>
              
                                  <div style={{display:"flex",paddingBottom:"13px",flexDirection:"column",justifyContent:"flex-end"}}>
                                  <CSVExport
                                        disabled={!isExpanded}
                                        selectedTemplateID={this.state.selectedTemplateID}
                                        handleTemplateIDChange={this.handleTemplateIDChange}
                                        templateList={this.state.templateList}
                                        handleConfirmCSvDialog={this.handleConfirmCSvDialog}
                                        handleSelectTemplateChange={this.handleSelectTemplateChange}
                                        openCSVDialog={this.state.openCSVDialog}
                                        handleopenCSVDialog={this.handleopenCSVDialog}
                                        selectedtemplateOption={this.state.selectedtemplateOption}
                                        btnName={"Export Csv"} 
                                        />
                                  </div>
                                  
              </Grid>
              {getTouched()?.emailSent && getErrors()?.emailSent && (
                    <Typography color="error" >{getErrors()?.emailSent}</Typography>
                  )}
              <Grid item xs={12}>
                  <LabelWithIcon label={t("upload_submitted_attachments")} />
                  <MultipleFileUpload
                        id={'uploadedAttachments'}
                        filesInputs={insurerIndex !== undefined && values?.stageData?.length > 0 ? values.stageData[insurerIndex].uploadedAttachments : []} in processing internal multiplefile upload 
                        name={`stageData[${insurerIndex}].uploadedAttachments`}
                        isEditPage={true}
                        isFileLoading={this.state.fileLoading}
                        filePath={
                          insurerIndex !== undefined && values?.stageData?.length > 0 ? 
                          [...values.stageData[insurerIndex].uploadedAttachments, ...this.state.uploadedFileList] 
                          : []
                        }
                        disabled={!(getStageDataValues()?.markAsSubmitted) || !isExpanded}
                        data-test-id={`stageData[${insurerIndex}].uploadedAttachments`}
                        inputName={`stageData[${insurerIndex}].uploadedAttachments`}
                        hasAtleastOneFileUploaded={insurerIndex !== undefined && values?.stageData?.length > 0 && values?.stageData[insurerIndex]?.fileUploadHasOneElement}
                        onChange={ (event: any) => {
                           if(!isExpanded) return 
                           this.setState({fileLoading: true})
                          this.uploadFileStageTwo("upload_submitted_attachments", insurer?.id, event.target.files, true, formikRefInst, insurerIndex as number)
                        }
                          
                      } 
                        onRemove={async (id: number) => {
                          if(!isExpanded) return 
                          this.removeFile(id, insurerIndex as number, formikRefInst)
                        }}
                        maxFilesNumber={5}
                        eachFileSizeInMB={10}
                    />

                    {getTouched()?.uploadedAttachments && getErrors()?.uploadedAttachments && (
                      <Typography color="error" >{getErrors()?.uploadedAttachments}</Typography>
                    )}  
                    
              </Grid>
            </Grid>
              </AccordionDetails>
            </Accordion>                    
          </div>
    );
  }
}

const useStyles = ({
textInputStyle: {
    marginTop: "10px",
    marginBottom: "10px"
},
formBorder: {
    border: "1px solid lightgray",
    "border-radius":"10px !important",
    '&::before': {
      content: '" "',
      display: 'none'
  }
},
errorFormBorder: {
    border: "1px solid red",
    "border-radius":"10px !important",
    '&::before': {
      content: '" "',
      display: 'none'
  }
},
openInTab: {
  fontSize: "12px",
  color: '#007E98',
  margin: "0 8px"
}
});

export default withStyles(useStyles)(withTranslation()(ProcessingInternalForm));
