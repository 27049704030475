// Customizable Area Start
import React, { ReactNode } from "react";
import {
    createTheme,
    ThemeProvider,
    withStyles,
} from "@material-ui/core/styles";
import StageEightController, {
    Props,
    S
} from "./StageEightController";
import {
    Grid,
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails,
    Divider,
    InputAdornment,
    IconButton,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import {
    ErrorOutlineRounded,
    ExpandMore,
    FileCopyOutlined,
} from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import LabelWithIcon from "../../../../../components/src/Common/LabelWithIcon";
import FileUpload from "../../../../../components/src/Common/FileUpload";
import TextInput from "../../../../../components/src/Common/TextInput";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import MultiSelectFormik from "../../../../../components/src/Common/MultiSelectFormik";
import MultipleFileUpload from "../../../../../components/src/Common/MultipleFileUpload";
import CustomButton from "../../../../../components/src/Common/CustomButton";
import * as Yup from "yup";
import Toster from "../../../../../components/src/Common/Toster";
import RejectRequestDialog from "../../../../../components/src/Common/RejectRequestDialog.web";
import HandOverDialog from "../../../../../components/src/Common/HandOverDialog.web";
import ActiveLanguage from "../../../../../components/src/Common/ActiveLanguage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",

        },
    },
});

const enumBtnType = {
    NEXT: "NEXT",
    DRAFT: "DRAFT",
};

class StageEightForm extends StageEightController {
  emailRef: React.RefObject<HTMLInputElement>;
  emailDraftRef: React.RefObject<HTMLInputElement>;
  emailSentRef: React.RefObject<HTMLInputElement>;
  emailSubjectRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        super(props);
        this.formikRef = React.createRef();
        this.emailRef = React.createRef<HTMLInputElement>();
        this.emailDraftRef = React.createRef<HTMLInputElement>() as any;
        this.emailSubjectRef = React.createRef<HTMLInputElement>();
        this.emailSentRef = React.createRef<HTMLInputElement>();           
    }

    handleSubmitFromOutside = () => {
        const { current: formikInstance } = this.formikRef;
        if (formikInstance) {
            formikInstance.handleSubmit();

        }
    };

    render(): ReactNode {// NOSONAR: typescript:S3776
        const { t, classes } = this.props;
        const stageEightData = JSON.parse(localStorage.getItem('stageEightData') || '{}');


        const getFormInitialValue = () => {
            const stageEight = this.props.stageEightData?.poliycRequestId || JSON.parse(localStorage.getItem('stageEightData') || '{}');
            const queryParameter: any = new URLSearchParams(window.location.search);
            const policyId: string = queryParameter?.get('id') 
            let stateEightData: S = {
                ...this.state,
                proofofPayement:this.state.proofofPayement||"",
                poliycRequestId: stageEight.poliycRequestId || "",
                policyActiveDate: stageEight.policyActiveDate || "",
                policyExpiryData: stageEight.policyExpiryData || "",
                insuranceType: this.props.stageOneData?.insuranceType || "",
                customerName: this.props.stageOneData?.customer || "",
            };

            // set approved insurance company from stage 6
            if (this.props.stageSixData) {
                const stageSixInsuranceCompanys = this.props.stageSixData?.attributes?.quotation_insurance_infos?.data
                if (stageSixInsuranceCompanys?.length > 0) {
                    stageSixInsuranceCompanys.map((copmanyData: any) => {
                        if (copmanyData.attributes.approval === true) {
                            stateEightData.approvedCompanyName = copmanyData.attributes.insurance_company_id.short_name;
                            stateEightData.approvedCompanyId = copmanyData.attributes.insurance_company_id.id
                        }
                    })
                }
            }

            // set approved company email details from the stage 2
            if (this.props.stageTwoData) {
                const stageTwoInsuranceComanyData = this.props.stageTwoData?.attributes?.processing_insurance_infos?.data;
                if (stageTwoInsuranceComanyData?.length > 0 && stateEightData.approvedCompanyId !== "") {
                    stageTwoInsuranceComanyData.map((data: any) => {
                        if (String(data.attributes.insurance_company_id.id) === String(stateEightData.approvedCompanyId)) {
                            stateEightData = {
                                ...stateEightData,
                                email: data.attributes.email,
                                emailDraft: data.attributes.email_sent,
                                emailSent: "",
                                contactName: data.attributes.insurance_contact_id,
                                markAsSubmitted: false,
                                policyExpiryData: data.attributes.attributes
                            }
                        }
                    });
                }
            }
            if(Object.keys(this.props.stageEightData).length){
                     stateEightData = {
                                ...stateEightData,
                                emailSent: this.state.emailSent,
                                markAsSubmitted: this.state.markAsSubmitted
                            }

            }
         /* istanbul ignore next*/
            const initialValue = {
                proofofPayement: this.state.proofofPayement||"",
                policyId: this.props.stageEightData?.poliycRequestId || this.state.poliycRequestId,
                emailSubject: "Welcome to SIIB",
                policyActiveDate: this.props.stageEightData?.policyActiveDate || this.state.policyActiveDate,
                policyExpiryData: this.props.stageEightData?.policyExpiryData || this.state.policyExpiryData,
                contactName: this.props.stageOneData?.contactName || this.state.contactName,
                email: this.props.stageOneData?.contactEmail || this.state.email,
                emailDraft: (this.props.stageOneData?.contactEmail || this.state.email) && (
`Dear Valued Client,

Greetings

We would like to take this opportunity and welcome you as one of our clients at SIIB.
    
For daily operations (Addition, Deletion, Amendment), all you requests should be sent to Care@siib.com.sa

Best regards`) || this.state.emailDraft,
                markAsSubmitted: this.state.markAsSubmitted,
                emailSent:this.state.emailSent,
                additionalDocAttachments: this.props.stageEightData?.additionalDocAttachments || this.state.additionalDocAttachments,
                approvedCompanyId: stateEightData.approvedCompanyId || "",
                approvedCompanyName: stateEightData.approvedCompanyName || ""
            }

            return initialValue
        }

        const stageSevenData = JSON.parse(localStorage.getItem('stageSevenData') || '{}');


        return (
            <>
                <ThemeProvider theme={theme}>
                    <Toster
                        tosterText={this.state.alertMessage}
                        showToster={this.state.showAlert}
                        // @ts-ignore
                        tosterType={this.state.alertType}
                        handleCloseToster={() => this.setState({ showAlert: false })}
                    />

                    <Grid className={classes.customerDetailsAccordion} item>
                        <Accordion
                            data-test-id="p8"
                            className={
                                this.state.errorSection === "p8" && classes.errorSection
                                    ? classes.errorSection
                                    : classes.accordionStyle
                            }
                            expanded={this.isAaccordionOpen(this.props.isExpanded)} onChange={(event, isExpanded) => {
                                this.handleAccordian(isExpanded, "p8")
                            }}
                        >
                            <AccordionSummary
                                aria-expanded={true}
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                            <div className={classes.toggleContainer}>
                              <Typography className={classes.accordianHeading} >{t('issuance')}</Typography>
                              <div className={classes.iconAlignment}>
                                {this.state.expandedAccordion && <DescriptionOutlinedIcon onClick={this.openNoteDialog} />}
                                {this.state.expandedAccordion && stageEightData?.submitApiResponse?.attributes?.is_reject &&
                                 <ErrorOutlineRounded style={{position: 'relative', left: '10px'}} onClick={() => this.setState({showRejectDialog: true, isBtnVisible: false})} />
                                }
                              </div>
                            </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Formik
                                    data-test-id="stageEightForm"
                                    innerRef={this.formikRef}
                                    initialValues={getFormInitialValue()}
                                    enableReinitialize={true}
                                    validateOnMount={true}
                                    validateOnBlur={true}
                                    validationSchema={Yup.object().shape(
                                        this.operationSchema(ActiveLanguage())
                                    )}
                                    onSubmit={(values: any) => {
                                        this.submitStageEight(values)
                                    }}
                                >
                                    {({
                                        values,
                                        setFieldValue,
                                        handleChange,
                                        handleSubmit,
                                        touched,
                                        errors,
                                    }) => {
                                        return (
                                            <React.Fragment>
                                                <Grid className={classes.contactForm}>
                                                    <Grid
                                                        className={classes.policyDetails}
                                                        container
                                                        spacing={2}
                                                    >
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t("policy")} />
                                                            <FileUpload
                                                                fileName={values.proofofPayement?.fileName || ""}
                                                                filePath={values.proofofPayement?.url || ""}
                                                                isArabicLanguage={this.state.isArabicLenguage}
                                                                data-test-id={`proof_payment`}
                                                                inputName={`proof_payment`}
                                                                disabled={!this.props.isExpanded}
                                                                onChange={(event: any) => {
                                                                    this.uploadFileStageOne("policy_detail", event.target.files[0], this.state.policyId, false, this.formikRef, "proofofPayement");
                                                                }}
                                                                onRemove={async() =>{
                                                                    await setFieldValue(`proofofPayement`,"")
                                                                    await this.removeFile(this.state.proofofPayement?.id||values.proofofPayement?.id,this.formikRef)
                                                                    await this.setState({proofofPayement:{}})
                                                                }}
                                                                className={classes.textInputStyle}
                                                            />
                                                            {touched.proofofPayement &&
                                                                errors.proofofPayement ? (
                                                                <Typography color="error">
                                                                    {errors.proofofPayement}
                                                                </Typography>
                                                            ) : null}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t("policy_id")} tooltip={t("auto_generated_id")} />
                                                            <TextInput
                                                                id={"policId"}
                                                                className={classes.textInputStyle}
                                                                isArabicLanguage={this.state.isArabicLenguage}
                                                                name="policyId"
                                                                type="text"
                                                                value={values.policyId}
                                                                disabled={true}
                                                                data-test-id="policyIdTxtInput"
                                                            />
                                                            {touched.policyId && errors.policyId ? (
                                                                <Typography color="error">
                                                                    {errors.policyId}
                                                                </Typography>
                                                            ) : null}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon
                                                                label={t("policy_activation_date")}
                                                            />
                                                            <TextInput
                                                                data-test-id="policyactivedate"
                                                                id="policyactivedate"
                                                                className={classes.textInputStyle}
                                                                isArabicLanguage={this.state.isArabicLenguage}
                                                                name="policyActiveDate"
                                                                type="date"
                                                                disabled={!this.props.isExpanded}
                                                                value={values.policyActiveDate}
                                                                onChange={handleChange}
                                                            />
                                                            {touched.policyActiveDate &&
                                                                errors.policyActiveDate ? (
                                                                <Typography color="error">
                                                                    {errors.policyActiveDate}
                                                                </Typography>
                                                            ) : null}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                    >
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon
                                                                label={t("policy_expire_data")}
                                                            />
                                                            <TextInput
                                                                data-test-id="policyExpiryData"
                                                                id="policyExpiryData"
                                                                className={classes.textInputStyle}
                                                                isArabicLanguage={this.state.isArabicLenguage}
                                                                name="policyExpiryData"
                                                                type="date"
                                                                disabled={!this.props.isExpanded}
                                                                value={values.policyExpiryData}
                                                                onChange={handleChange}
                                                            />
                                                            {touched.policyExpiryData &&
                                                                errors.policyExpiryData ? (
                                                                <Typography color="error">
                                                                    {errors.policyExpiryData}
                                                                </Typography>
                                                            ) : null}
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.textInputStyle} />
                                                    <Typography className={classes.policyStyle}>
                                                        {t("customer_welcome_email")}
                                                    </Typography>
                                                    <Grid
                                                        className={classes.policyDetails}
                                                        container
                                                        spacing={2}
                                                    >
                                                        <Grid item xs={4}>
                                                            <LabelWithIcon label={t("contact")} />
                                                            <MultiSelectFormik
                                                                value={values.contactName}
                                                                name="contactName"
                                                                id="contactName"
                                                                isArabicLanguage={this.state.isArabicLenguage}
                                                                disabled={true}
                                                                options={[]}
                                                                data-test-id="contectName"
                                                            />
                                                        </Grid>
                                                        <Grid xs={4} item>
                                                            <LabelWithIcon label={t("email")} />
                                                            <TextInput
                                                                id={"email"}
                                                                innerRef={this.emailRef}
                                                                className={classes.textInputStyle}
                                                                isArabicLanguage={this.state.isArabicLenguage}
                                                                name={`email`}
                                                                value={values.email}
                                                                readOnly={true}
                                                                type="text"
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-end",
                                                                            }}
                                                                        >
                                                                            <IconButton
                                                                                edge="end"
                                                                                style={{ pointerEvents: "auto" }}
                                                                                onClick={() => this.handleCopyClick(this.emailRef)}
                                                                            >
                                                                                <FileCopyOutlined />
                                                                            </IconButton>
                                                                        </div>
                                                                    </InputAdornment>
                                                                }
                                                                data-test-id="email"
                                                            />
                                                        </Grid>
                                                        <Grid xs={4} item>
                                                            <LabelWithIcon label={t("email_subject")} />
                                                            <TextInput
                                                                id={"emailSubject"}
                                                                innerRef={this.emailSubjectRef}
                                                                className={classes.textInputStyle}
                                                                isArabicLanguage={this.state.isArabicLenguage}
                                                                name={`emailSubject`}
                                                                readOnly={true}
                                                                type="text"
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-end",
                                                                            }}
                                                                        >
                                                                            <IconButton
                                                                                edge="end"
                                                                                style={{ pointerEvents: "auto" }}
                                                                                onClick={() => this.handleCopyClick(this.emailSubjectRef)}
                                                                            >
                                                                                <FileCopyOutlined />
                                                                            </IconButton>
                                                                        </div>
                                                                    </InputAdornment>
                                                                }
                                                                data-test-id="emailSubject"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LabelWithIcon label={t("email_draft")} />
                                                            <TextInput
                                                                id={`emailDraft`}
                                                                innerRef={this.emailDraftRef}
                                                                className={classes.textInputStyle}
                                                                isArabicLanguage={this.state.isArabicLenguage}
                                                                name={`emailDraft`}
                                                                type="text"
                                                                fullWidth
                                                                multiline={true}
                                                                readOnly={true}
                                                                minRows={4}
                                                                value={values.emailDraft}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-end",
                                                                            }}
                                                                        >
                                                                            <IconButton
                                                                                edge="end"
                                                                                style={{ pointerEvents: "auto" }}
                                                                                onClick={() => this.handleCopyClick(this.emailDraftRef)}
                                                                            >
                                                                                <FileCopyOutlined />
                                                                            </IconButton>
                                                                        </div>
                                                                    </InputAdornment>
                                                                }
                                                                data-test-id={`emailDraft`}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.textInputStyle} />
                                                    <Grid
                                                        className={classes.policyDetails}
                                                        container
                                                        spacing={2}
                                                    >
                                                        <Grid item xs={12}>
                                                            <Typography className={classes.policyStyle}>
                                                                {t("mark_as_submitted_without_questionmark")} 
                                                            </Typography>
                                                            <FormControlLabel
                                                                label={<label>{t('yes')}</label>}
                                                                value={values.markAsSubmitted}
                                                                checked={values.markAsSubmitted}
                                                                disabled={!this.props.isExpanded}
                                                                name="markAsSubmitted"
                                                                control={
                                                                    <Checkbox
                                                                        color="primary"
                                                                        onChange={(e: any) => {
                                                                            setFieldValue(
                                                                                `markAsSubmitted`,
                                                                                e.target.checked
                                                                            );
                                                                        }}
                                                                    />
                                                                }
                                                                color="primary"
                                                            />
                                                            {touched.markAsSubmitted &&
                                                                errors.markAsSubmitted ? (
                                                                <Typography color="error">
                                                                    {errors.markAsSubmitted}
                                                                </Typography>
                                                            ) : null}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LabelWithIcon label={t("email_sent")} />
                                                            <TextInput
                                                                id={"emailSent"}
                                                                innerRef={this.emailSentRef}
                                                                isArabicLanguage={this.state.isArabicLenguage}
                                                                className={classes.textInputStyle}
                                                                name={`emailSent`}
                                                                multiline={true}
                                                                disabled={!values.markAsSubmitted}
                                                                readOnly={!this.props.isExpanded && !values.markAsSubmitted}
                                                                minRows={4}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <div
                                                                            style={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-end",
                                                                            }}
                                                                        >
                                                                            <IconButton
                                                                                edge="end"
                                                                                style={{ pointerEvents: "auto" }}
                                                                                onClick={() => this.handleCopyClick(this.emailSentRef)}
                                                                            >
                                                                                <FileCopyOutlined />
                                                                            </IconButton>
                                                                        </div>
                                                                    </InputAdornment>
                                                                }
                                                                data-test-id="emailSent"
                                                            />
                                                            {touched.emailSent && errors.emailSent ? (
                                                                <Typography color="error">
                                                                    {errors.emailSent}
                                                                </Typography>
                                                            ) : null}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LabelWithIcon
                                                                label={t("upload_submitted_attachments")}
                                                            />

                                                            <MultipleFileUpload
                                                                id={'additionalDocAttachments'}
                                                                fileName="Upload Attachments"
                                                                filesInputs={values?.additionalDocAttachments}
                                                                name={`additionalDocAttachments`}
                                                                disabled={!this.props.isExpanded}
                                                                filePath={
                                                                    values?.additionalDocAttachments?.length > 0 ?
                                                                        /* istanbul ignore next*/[...values?.additionalDocAttachments, ...this.state.currentAdditionalDoc] // NOSONAR: typescript:S6523
                                                                        : []
                                                                }
                                                                data-test-id={`additionalDocAttachments`}
                                                                inputName={`additionalDocAttachments`}
                                                                onChange={(e: any) => {
                                                                    this.uploadFileStageOne("issuance_upload_submitted_attachments", e.target.files, this.state.policyId, true, this.formikRef)
                                                                }}
                                                                onRemove={(id: number) => {
                                                                    if (!this.props.isExpanded) return
                                                                    this.removeFile(id, this.formikRef)
                                                                }
                                                                }
                                                                maxFilesNumber={5}
                                                                eachFileSizeInMB={10}
                                                            />
                                                            {touched.additionalDocAttachments &&  /* istanbul ignore next*/errors.additionalDocAttachments ? (
                                                                /* istanbul ignore next*/<Typography color="error" >{errors.additionalDocAttachments}</Typography>
                                                            ) : null}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        );
                                    }}
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    <Grid>
                        {(this.props.isExpanded && this.isAaccordionOpen(this.props.isExpanded)) ? (
                            <div className={classes.BtnGroup}>
                                <div className={classes.buttonsRightWrapper}>
                                    <CustomButton
                                        data-test-id={"nextStageBtn"}
                                        btnText={t("save_draft")}
                                        onClick={() => this.saveDraftData(this.formikRef)}
                                        bgColor={"#fff"}
                                        textColor={"#007E98"}
                                    />
                                    <CustomButton
                                        data-test-id={"rejectBtn"}
                                        btnText={t("reject")}
                                        onClick={() => this.handleRejectDialoug(true)}
                                        bgColor={"#fff"}
                                        textColor={"#007E98"}
                                    />
                                    <CustomButton
                                        testId="nextStageBtn"
                                        btnText={t("next_stage")}
                                        onClick={this.handleSubmitFromOutside}
                                        bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                        textColor={"#fff"}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </Grid>

                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNoteDialog as boolean}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={this.state.isBtnVisible ? `(${t('issuance')} > ${t('invoicing')})`: `(${t('payment_review')} > ${t('issuance')})`}
                        inputValue={this.state.isBtnVisible ? this.state.handOverNoteValue : stageSevenData?.attributes?.handover_note_issuance}
                        handleOk={(value: string) => this.handleOkDialog(value)}
                        handleCancel={() => this.handleHandOverNote(false)}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                        inputDisable={!this.state.isBtnVisible}
                        inputLable={this.state.showNoteDialog ? t('note') : ""}
                        handleInputText={this.handleHandOverNoteText}
                    />

                    <RejectRequestDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showRejectDialog}
                        textId={'rejectReason'}
                        pushBackOptions={this.getPoicyStageOption()}
                        setSelectedPushBackTo={(e: any) => {
                            this.setState({ rejectedStage: e.value })
                        }}
                        inputValue={this.state.isBtnVisible ? this.state.rejectReason : stageEightData?.submitApiResponse?.attributes?.rejected_reason}
                        headingText={t('rejecting')}
                        handleInputText={(value: string) => this.setState({ rejectReason: value })}
                        handleOk={() => this.rejectPolicy(this.state.rejectedStage, this.state.rejectReason)}
                        handleCancel={() => this.handleRejectDialoug(false)}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                        labelText={t('push_back')}
                        reasonLabel={t('reason')}
                    />
                </ThemeProvider>
            </>
        );
    }
}
const useStyles = {
    policyDetails: {
        padding: "15px 0px",
    },
    policyStyle: {
        margin: "10px 0px",
        fontWeight: 600,
    },
    customerDetailsAccordion: {
        paddingTop: "10px",
        paddingBottom: "10px",
        "& .MuiAccordion-root-34.Mui-disabled, .MuiAccordion-root-42.Mui-disabled": {
            backgroundColor: "white",
        },
        " & .MuiAccordion-root.Mui-disabled": {
            backgroundColor: "white",
        },
    },
    BtnGroup: {
        padding: "0px 15px",
    },
    buttonsRightWrapper: {
        display: "flex",
        gap: "15px",
        padding: "15px 0px",
        justifyContent: "flex-end",
    },
    toggleContainer: {
        width: '100%',
            display: 'flex',
                justifyContent: 'space-between',
                    '& svg': {
            color: '#0090a1'
        }
    },
    iconAlignment: {
        display: 'flex',
            marginRight: "10px"
    },
    lostIcon: {
        marginLeft: "15px",
            '& svg': {
            color: '#0090a1'
        },
    },
    displayFlex: {
        display: "flex",
    },

    errorSection: {
        border: "1px solid red",
    },

    accordionStyle: {
        background: "white !important",
        "border-radius": "10px !important",
    },
    subAccordionStyle: {
        background: "white !important",
        border: "1px solid lightgray",
    },
    accordianHeading: {
        fontWeight: 600,
    },
    contactForm: { width: "100%" },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px",
    },
};

export default withStyles(useStyles)(withTranslation()(StageEightForm));
// Customizable Area End 