Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.addCustomerEndPoint = "bx_block_customer/customers";
exports.editCustomerEndPoint = "bx_block_customer/customers";
exports.labelBodyText = "TaskList Body";
exports.createAccountTitle = "Create Account";
exports.ArabicCreateAccoutnTitle = "إنشاء حساب";
exports.startNewPolicyRequestTitle= "Start new policy request";
exports.ArabicStartNewPolicyRequestTitle= "بدء طلب سياسة جديدة";
exports.companyDetails = "Company details";
exports.ArabicCompanyDetails = "تفاصيل الشركة";
exports.customerNameTitle = "Customer Name";
exports.ArabicCustomerNameTitle = "اسم الزبون";
exports.companyNameTitle = "Company Name";
exports.ArabicCompanyNameTitle = "اسم الشركة";
exports.emaliTitle = "Email";
exports.ArabicEmailTitle = "اسم الشركة";
exports.MobileNumberTitle = "Mobile Number";
exports.ArabicMobileNumberTitle = "رقم الهاتف المحمول";
exports.existingPolicies= 'Existing Policies';
exports.ArabicExistingPolicies = "النُهج الحالية";
exports.somethingWentWrongTxt = 'Something went wrong please try again';
exports.ArabicSomethingWentWrongTxt = 'حدث خطأ ما. أعد المحاولة من فضلك';
exports.ArabicCustomerAddedTxt = "أضاف الزبون";
exports.customerAddedTxt = "Customer added"
exports.savedTxt = "Saved"
exports.arabicSavedTxt = "أنقذ"
exports.savingChangesFail = "Saving changes failed"
exports.arabicSavingChangesFail = "فشل حفظ التغييرات"
exports.emailExists="A customer with the same email already exists"
exports.ArabicEmailExists="يوجد بالفعل عميل بنفس البريد الإلكتروني"
exports.arabicWrongInputForm = "يرجى ملء المعلومات الصحيحة في النموذج قبل التقديم"
exports.wrongInputForm = "Please fill in the valid information in the form before submitting"
// Customizable Area End