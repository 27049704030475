import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton } from "@material-ui/core";
import { GetApp, InsertDriveFile, Close, ExpandMore } from "@material-ui/icons";
import { makeStyles } from '@material-ui/styles';
import Toster from '../Common/Toster'
import { useTranslation } from "react-i18next";

type fileDataType = {id: number; name: string, file_name?: string, url: string};

const useStyles = makeStyles({
  fileExistDesign: {
    border: "solid lightgrey 1px",
    borderRadius: "5px",
    marginTop: "10px",
  },
  fileNameStyle:{
    width:"100%"
  },
  uploadAttachemntAccordian: {
    marginTop: "10px",
    boxShadow: 'none',
    width: "100%",
    background: "transparent",
  },
  uploadFileInput: {
    marginTop: "10px",
    boxShadow: 'none',
    border: "dashed lightgrey 2px",
    height: "55px",
    background: "transparent",
    width: "100%",
    textTransform: "none"
  },
  downloaIcon: {
    cursor: "pointer"
  },
  textDesign:{
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textTransform: "none"
  },
  attachmentSectionTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  accordianDetails: {
    display: "block"
  },
  attachmentItems: {
    padding: "8px 0",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
})

const MultipleFileUpload = (props: any) => {
  const {t} = useTranslation();
  const {filePath} = props;
  const [isRemovedClicked, setIsRemovedClicked] = React.useState<boolean>(false);
  const [fileNames, setFileNames] = React.useState<fileDataType[]>([]);
  const [showToaster, setShowToaster] = React.useState<boolean>(false)
  const [errorMsg, setErrorMsg] = React.useState<string>('')
  const [expandAccordian, setExpandAccordian]  = React.useState<boolean>(false)
  const [allowedFileTypes] = React.useState<string[]>(['.jpg', '.jpeg', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.csv'])
  const classes = useStyles();

  React.useEffect(() => {
    if (filePath?.length === 0) {
      return;
    } else {
      filePath && filePath?.length > 0 ?  setExpandAccordian(true) : setExpandAccordian(false)
      if (filePath?.length > 0 && !isRemovedClicked) {
        setFileNames([ ...filePath]);
      }
    }
  }, [props?.filePath]);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(props.disabled) {
      return
    }
    setIsRemovedClicked(false)
    const files = e.target.files;

    if (files) {
      const fileArray = Array.from(files);
      const totalSize = fileArray.reduce((total, file) => total + file.size, 0);

      fileArray && fileArray?.length > 0 ?  setExpandAccordian(true) : setExpandAccordian(false)

      const eachFileMaxSize =  props.eachFileSizeInMB * 1024 * 1024;

      let invalidFiles: any[] = [];

      if (fileArray.length > props.maxFilesNumber || (fileNames?.length + fileArray.length) > props.maxFilesNumber ) {
        setShowToaster(true)
        setErrorMsg(`Max ${props.maxFilesNumber} files are only allowed`)
        return;
      }

      fileArray.forEach((file) => {
        if (file.size > 10 * 1024 * 1024) {
          invalidFiles.push(file);
        }
      });

      if (totalSize > (props.maxFilesNumber * eachFileMaxSize)) {
        setShowToaster(true)
        setErrorMsg(`Files exceeded size limit`)
        return;
      }
      if (invalidFiles.length === 0) {
        props.onChange(e);
      } else {
        setShowToaster(true)
        setErrorMsg(`Each file should be less than or equal to ${props.eachFileSizeInMB} MB`)
        return;
      }
    }
  };

  const handleAccordionToggle = () => {
    setExpandAccordian(!expandAccordian)
  }

  const handleFileRemove = (fileData: fileDataType, index: number) => {
    if(props.disabled) {
      return
    }
    if (fileData.name || fileData.file_name){
      setIsRemovedClicked(true);
      fileNames.splice(index, 1)
      setFileNames([...fileNames]);
      if(fileData.url) {
        props.onRemove({id: fileData.id, fileData: fileNames.filter((fileName: any) => !fileName.id && !fileName.url)});
      } else { props.onRemove({fileData: fileNames.filter((fileName: any) => !fileName.id && !fileName.url)}) }
    }
  }

  const downloadFile = async (fileIndex: number, filename: string) => {
    const fileUrl = props?.filePath[fileIndex]; // Assuming props.filePath is an array of file URLs
    const response = await fetch(fileUrl?.url, { method: 'GET' });
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <React.Fragment>

      <Toster
        tosterText={errorMsg}
        showToster={showToaster}
        tosterType={'error'} // error, success, warning, info
        handleCloseToster={() => setShowToaster(false)}
      />

      {!fileNames?.length ? (
      <Button className={classes.uploadFileInput}  component="label" >
         {t('upload_file_text')} {" "}
        <input name={props.inputName} disabled={props.disabled} hidden onChange={(e) => handleFileUpload(e)} accept={allowedFileTypes.join(',')} type="file" multiple />
      </Button>) :
      <Accordion
        elevation={0}
        disabled={props.disabled}
        expanded={expandAccordian}
        className={classes.fileExistDesign}
        onChange={handleAccordionToggle}>
      <AccordionSummary
        aria-expanded={true}
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
      {fileNames && fileNames?.length > 0 && (<div className={classes.attachmentSectionTitle}>
        <div>{ fileNames?.length + (fileNames?.length === 1 ? ' file ' : ' files ') + 'uploaded' }</div>

        <IconButton  component="label">
          <img src={require("../../../web/public/assets/images/icons/upload_attachment.svg")} height="16px" width="16px"/>
          <input name={props.inputName} hidden onChange={(e) => handleFileUpload(e)} accept={allowedFileTypes.join(',')}  type="file" multiple />
        </IconButton>
      </div>)}
      </AccordionSummary>
      <AccordionDetails className={classes.accordianDetails}>
        {fileNames && fileNames?.length > 0 && fileNames.map((file, index)=> (
          <div key={`file-${index.toString()}`} className={classes.attachmentSectionTitle}>
            <div className={classes.attachmentItems}>
              <InsertDriveFile />
              <span className={classes.textDesign}>{file?.name || (file?.file_name || '')}</span>
            </div>
            <div className={classes.attachmentItems}>
            {props.disabled ? (
               <GetApp className={classes.downloaIcon} onClick={(e) => {
                downloadFile(index, file?.name || (file?.file_name || ''))
          }} />
            ) : <>
            <GetApp className={classes.downloaIcon} onClick={(e) => {
              downloadFile(index, file?.name || (file?.file_name || ''))
        }} />
          <Close
          className={classes.downloaIcon}
          onClick={() =>  handleFileRemove(file, index)}
        />
        </> }
            </div>
          </div>
        ))}
      </AccordionDetails>
    </Accordion>}
    </React.Fragment>
  );

}

export default MultipleFileUpload