// Customizable Area Start
import React from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import StageTwoController from "./InvoiceStageController";
import LabelWithIcon from "../../../../../../components/src/Common/LabelWithIcon";
import TextInput from "../../../../../../components/src/Common/TextInput";
import FileUpload from "../../../../../../components/src/Common/FileUpload";
import { ErrorMessage } from 'formik';
import Toster from "../../../../../../components/src/Common/Toster";

// Customizable Area End

export class StageNineInstallment extends StageTwoController {

    // Customizable Area Start
    getUpdateKey = (fieldKey: string, installmentIndex: any, isInstallment: any): string => {
      return this.getUpdateFieldValue(fieldKey, installmentIndex, isInstallment)
    }

    validateInstallmentForm = (isInstallment: any, installmentIndex: any) => {
      return this.validateInstallment(isInstallment, installmentIndex)
    }
    isFieldDisable = (isInstallment: any, installmentIndex: any, keyName: string, filterObj: string = 'siib_commission') => {
      const isInstallmentValid = this.validateInstallmentForm(isInstallment, installmentIndex);
      const isStage7Disabled = this.chekcDisableAfterStage7InstallmentPaid(keyName, filterObj, installmentIndex);
      const isStage9Submitted = this.props.rrStageNineData?.data?.attributes?.is_current_stage_submitted;
      return (isInstallmentValid || isStage7Disabled || (isStage7Disabled &&/*istanbul ignore next*/ isStage9Submitted))&&!this.props.rrStageNineData?.data?.attributes?.save_as_draft;
  }
  
    renderFlow = () => {
      const {classes, t, values, setFieldValue, isInstallment, installmentIndex, formikRef} = this.props;
      const currentFlow = this.state.flow;

      return <>{
        (currentFlow != 4 && currentFlow != 6 ) && (
          <React.Fragment>
            <Typography className={classes.policySpacing}>
            {t("siib_commision")}
            </Typography>
            <Grid
              className={classes.policyDetailStyle}
              container
              spacing={2}
              style={{ borderBottom: '3px solid #eaeaea', marginBottom: 20 }}
            >
              <Grid item xs={4}>
                  <LabelWithIcon label={t("commission_rate")} />
                  <TextInput
                  data-test-id={"commissionRate"}
                  name={this.getUpdateKey('commissionRate',installmentIndex, isInstallment)}
                  className={classes.textInputStyle}
                  type="number"
                  endAdornment={
                      <InputAdornment position="end">
                      <div
                          style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          }}
                      >
                          %
                      </div>
                      </InputAdornment>
                  }
                  onChange={(e: any) => {
                    setFieldValue(this.getUpdateKey('commissionRate', installmentIndex, isInstallment), e.target.value);
                    setFieldValue(this.getUpdateKey('commissionAmount', installmentIndex, isInstallment), Math.round(((this.getSiibCommission(isInstallment, installmentIndex) * e.target.value) / (1.15 * 100)) * 100) / 100)
                  }}
                  disabled={this.isFieldDisable(isInstallment, installmentIndex, 'commission_rate')}
                  isArabicLanguage={this.state.isArabicLenguage}
                  />
                  <ErrorMessage
                    data-test-id="commissionRate"
                    name={this.getUpdateKey('commissionRate', installmentIndex, isInstallment)}
                    className="field-error"
                    render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>} />
              </Grid>
              <Grid item xs={4}>
                  <LabelWithIcon label={`${t("commission_amount")} (${t('without_vat')})`} />
                  <TextInput
                  id={"commissionAmount"}
                  data-test-id="commissionAmount"
                  name={this.getUpdateKey('commissionAmount', installmentIndex, isInstallment)}
                  className={classes.textInputStyle}
                  type="number"
                  disabled={true}
                  isArabicLanguage={this.state.isArabicLenguage}
                  startAdornment={
                    <InputAdornment position="start">
                        <div> &nbsp;SAR&nbsp;</div>
                    </InputAdornment>
                  }
                  />
              </Grid>
              <Grid item xs={4}>
                  <LabelWithIcon label={t("invoice_date")} />
                  <TextInput
                  id={`policyExpireDateTxtInput`}
                  data-test-id={`invoiceDate`}
                  name={this.getUpdateKey(`invoiceDate`, installmentIndex, isInstallment)}
                  className={classes.textInputStyle}
                  type={"date"}
                  disabled={this.isFieldDisable(isInstallment, installmentIndex, 'invoice_date')}
                  onChange={(e: any) => setFieldValue(this.getUpdateKey('invoiceDate', installmentIndex, isInstallment), e.target.value)}
                  />
                  <ErrorMessage
                    data-test-id="invoiceDate"
                    name={this.getUpdateKey('invoiceDate', installmentIndex, isInstallment)}
                    className="field-error"
                    render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>} />
              </Grid>
              <Grid item xs={12}>
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    className={
                      classes[this.getClassNameOnInvoiceBtn(values,installmentIndex, isInstallment) as string]
                    }
                    data-test-id="createInvoice"
                    variant="contained"
                    color="primary"
                    disabled={this.validateInstallmentForm(isInstallment, installmentIndex) || ((installmentIndex !== undefined &&  installmentIndex >= 0) ? this.isInstallmentInvoiceBtnDisabled(values,installmentIndex) : this.checkInvoiceBtnDisable(values))}
                    onClick={()=> (installmentIndex !== undefined &&  installmentIndex >= 0) ? this.generateInvoiceForInstallment(values,installmentIndex,formikRef) : this.generateInvoice(values, formikRef)}
                  >
                  {this.state.invoiceBtnLoading ? (<CircularProgress size={25} />) : t(this.getFieldValue('create_invoice'))}
                  </Button>
                <ErrorMessage
                    data-test-id="siibInvoiceIdError"
                    name={this.getUpdateKey(this.getFieldValue('siibInvoiceId'), installmentIndex, isInstallment)}
                    className="field-error"
                    render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>} />
              </Grid>
              <Grid item xs={4}>
                  <LabelWithIcon label={t(this.getFieldValue("invoice"))} />
                  <FileUpload
                    data-test-id="txtInputAddressProof"
                    fileName={(installmentIndex !== undefined &&  installmentIndex >= 0) ? values?.revision_installments[installmentIndex]?.invoice?.file_name :this.getValueFromKey('invoice', values, isInstallment, installmentIndex)?.file_name}
                    filePath={(installmentIndex !== undefined &&  installmentIndex >= 0) ? values?.revision_installments[installmentIndex]?.invoice?.url :this.getValueFromKey('invoice', values, isInstallment, installmentIndex)?.url}
                    inputName={"invoice"}
                    disabled={true}
                    eachFileSizeInMB={10}
                    maxFilesNumber={5}
                    isEditPage={this.state.isEdit}
                  />
              </Grid>
              <Grid item xs={4}>
                  <LabelWithIcon label={t(this.getFieldValue("siib_invoice_id"))} />
                  <TextInput
                    id={`siibInvoiceId`}
                    data-test-id={`siibInvoiceId`}
                    name={this.getUpdateKey(this.getFieldValue('siibInvoiceId'), installmentIndex, isInstallment)}
                    className={classes.textInputStyle}
                    type={"text"}
                    disabled={true}
                  />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                  <LabelWithIcon label={t('invoice_paid')} />
                  <FormControlLabel
                    data-test-id={`invoicePaid`}
                    name={this.getUpdateKey('invoicePaid', installmentIndex, isInstallment)}
                    className={classes.checkBoxInput}
                    color="primary"
                    control={<Checkbox color="primary"
                        data-test-id="invoicePaidCheck"
                        checked={!this.validateInstallmentForm(isInstallment, installmentIndex) && this.getValueFromKey('invoicePaid', values, isInstallment, installmentIndex)}
                        onChange={(e: any) => setFieldValue(this.getUpdateKey('invoicePaid', installmentIndex, isInstallment), e.target.checked)}
                        disabled={this.isFieldDisable(isInstallment, installmentIndex, 'invoice_paid')}
                      />
                    }
                    label={t('yes')}
                  />
                  <ErrorMessage
                    data-test-id="invoicePaid"
                    name={this.getUpdateKey('invoicePaid', installmentIndex, isInstallment)}
                    className="field-error"
                    render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>} />
              </Grid>
              <Grid xs={4} item>
                  <LabelWithIcon label={t("payment_date")} />
                  <TextInput
                    data-test-id={`paymentDate`}
                    name={this.getUpdateKey('paymentDate', installmentIndex, isInstallment)}
                    className={classes.textInputStyle}
                    type={"date"}
                    onChange={(e: any) => setFieldValue(this.getUpdateKey('paymentDate', installmentIndex, isInstallment), e.target.value)}
                    disabled={this.isFieldDisable(isInstallment, installmentIndex, 'payment_date')}
                  />
                  <ErrorMessage
                    data-test-id="paymentDate"
                    name={this.getUpdateKey('paymentDate', installmentIndex, isInstallment)}
                    className="field-error"
                    render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>} />
              </Grid>
              <Grid xs={4} item>
                  <LabelWithIcon label={t("upload_proof_of_payment_recevied")} />
                  <FileUpload
                      data-test-id="proofOfPaymentReceivedDoc"
                      inputName={this.getUpdateKey('proofOfPaymentReceivedDoc', installmentIndex, isInstallment)}
                      fileName={this.getFileInputValues(values, 'proofOfPaymentReceivedDoc', 'file_name', isInstallment, installmentIndex)}
                      eachFileSizeInMB={10}
                      maxFilesNumber={5}
                     /*istanbul ignore next*/ filePath={this.getFileInputValues(values, 'proofOfPaymentReceivedDoc', 'url', isInstallment, installmentIndex)?this.getFileInputValues(values, 'proofOfPaymentReceivedDoc', 'url', isInstallment, installmentIndex):""}
                      onChange={(e: any) => setFieldValue(this.getUpdateKey('proofOfPaymentReceivedDoc', installmentIndex, isInstallment), e.target.files[0])}
                      disabled={this.isFieldDisable(isInstallment, installmentIndex, 'upload_proof_of_payment_recevied')}
                      /*istanbul ignore next*/onRemove={()=>{
                        this.props.formikRef?.current?.setFieldValue(this.getFileInputValues(values, 'proofOfPaymentReceivedDoc', '', isInstallment, installmentIndex),{})
                        this.getFileInputValues(values, 'proofOfPaymentReceivedDoc', 'id', isInstallment, installmentIndex)&&  this.removeFileInvoice(this.getFileInputValues(values, 'proofOfPaymentReceivedDoc', 'id', isInstallment, installmentIndex))
                        setFieldValue(`proofOfPaymentReceivedDoc`, "")
                    }}/>
                  <ErrorMessage
                    data-test-id="proofOfPaymentReceivedDoc"
                    name={this.getUpdateKey('proofOfPaymentReceivedDoc', installmentIndex, isInstallment)}
                    className="field-error"
                    render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>} />
              </Grid>
            </Grid>
          </React.Fragment>
        )
      }</>
    }
    // Customizable Area End

    render() {

      // Customizable Area Start
      const {classes, t, values, setFieldValue, isInstallment, installmentIndex, formikRef} = this.props;
            const currentFlow = this.state.flow;
      // Customizable Area End

      return (
        // Customizable Area Start
        <React.Fragment>
          <Toster
            data-test-id="installmentToster"
            tosterText={this.state.alertMessage}
            showToster={this.state.showAlert}
            tosterType={this.state.alertType}
            handleCloseToster={() => this.setState({ showAlert: false })}
          />
          {
            this.renderFlow()
            }
            {/* sales commission */}
            <Grid />
            <Typography className={classes.policySpacing}>
              {t("sales_commision")}
            </Typography>
            <Grid
              className={classes.policyDetailStyle}
              container
              spacing={2}
            >
                <Grid item xs={4}>
                    <LabelWithIcon label={t("commission_rate")} />
                    <TextInput
                    id={"salesCommissionRate"}
                    data-test-id={"salesCommissionRate"}
                    name={this.getUpdateKey('salesCommissionRate', installmentIndex, isInstallment)}
                    className={classes.textInputStyle}
                    type="number"
                    endAdornment={
                      <InputAdornment position="end">
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            >
                            %
                        </div>
                        </InputAdornment>
                    }
                    onChange={(e: any) => {
                      setFieldValue(this.getUpdateKey('salesCommissionRate', installmentIndex, isInstallment), e.target.value)
                      if(this.state.rrStageSeven?.data?.attributes.commission.data?.attributes.deduct_commission_with_vat == "yes") {
                        setFieldValue(this.getUpdateKey('salesCommissionAmount', installmentIndex, isInstallment), ((this.getCommission(isInstallment, installmentIndex, currentFlow, values)/1.15)*e.target.value/100).toFixed(2))
                      }
                      else {
                        /* istanbul ignore next */
                        setFieldValue(this.getUpdateKey('salesCommissionAmount', installmentIndex, isInstallment), Math.round(((this.getCommission(isInstallment, installmentIndex, currentFlow, values) * e.target.value) / 100) * 100) / 100)
                      }
                    }}
                    isArabicLanguage={this.state.isArabicLenguage}
                    disabled={this.isFieldDisable(isInstallment, installmentIndex,"commission_rate")}
                    />
                  <ErrorMessage
                    data-test-id="salesCommissionRate"
                    name={this.getUpdateKey('salesCommissionRate', installmentIndex, isInstallment)}
                    className="field-error"
                    render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>} />
                </Grid>
                <Grid item xs={4}>
                    <LabelWithIcon label={`${t("commission_amount")} (${t('without_vat')})`} />
                    <TextInput
                    id={"salesCommissionAmount"}
                    data-test-id="salesCommissionAmount"
                    name={this.getUpdateKey('salesCommissionAmount', installmentIndex, isInstallment)}
                    className={classes.textInputStyle}
                    isArabicLanguage={this.state.isArabicLenguage}
                    type="number"
                    disabled={true}
                    startAdornment={
                      <InputAdornment position="start">
                          <div> &nbsp;SAR&nbsp;</div>
                      </InputAdornment>
                    }
                    />
                </Grid>
                <Grid item xs={4}>
                    <LabelWithIcon label={t(this.getFieldValue("credit_note_date"))} />
                    <TextInput
                    id={`creditNoteDate`}
                    data-test-id={`creditNoteDate`}
                    name={this.getUpdateKey(this.getFieldValue('creditNoteDate'), installmentIndex, isInstallment)}
                    className={classes.textInputStyle}
                    type={"date"}
                    // disabled={this.props.rrStageNineData?.data?.attributes?.credit_note?.data[installmentIndex !== undefined ? (installmentIndex + 1) : 0]?.attributes?.credit_note_date
                    // && this.props.rrStageNineData?.data?.attributes?.is_current_stage_submitted
                    // }
                    disabled={this.isFieldDisable(isInstallment, installmentIndex, 'credit_note_date',"credit_note")}
                    onChange={(e: any) => setFieldValue(this.getUpdateKey(this.getFieldValue('creditNoteDate'), installmentIndex, isInstallment), e.target.value)}
                    />
                    <ErrorMessage
                      data-test-id="creditNoteDate"
                      name={this.getUpdateKey(this.getFieldValue('creditNoteDate'), installmentIndex, isInstallment)}
                      className="field-error"
                      render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>} />
                </Grid>
                <Grid item xs={4}>
                    <LabelWithIcon label={t("sales_person")} />
                    <TextInput
                    id={`salesPerson`}
                    data-test-id={`salesPerson`}
                    name={`salesPerson`}
                    className={classes.textInputStyle}
                    type={"text"}
                    disabled={true}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                    data-test-id="create_credit_note"
                    className={this.checkCreditDebitNoteBtnDisable(values, installmentIndex)  || this.validateInstallmentForm(isInstallment, installmentIndex) ? classes.salesGrayBtn : classes.salesActiveBtn}
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    disabled={this.checkCreditDebitNoteBtnDisable(values, installmentIndex) || this.validateInstallmentForm(isInstallment, installmentIndex)}
                    color="primary"
                    variant="contained"
                    onClick={()=> this.generateCreditDebitNote(values, formikRef, installmentIndex)}
                    >
                    {this.state.creditDebitBtnLoading ? (<CircularProgress size={25} />) : t(this.getFieldValue('create_credit_note'))}
                    </Button>
                    <ErrorMessage
                      data-test-id="salesCreditNoteIdError"
                      name={this.getUpdateKey(this.getFieldValue('salesCreditNoteId'), installmentIndex, isInstallment)}
                      className="field-error"
                      render={(msg: string) => <div><Typography  color="error">{msg}</Typography></div>} />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={4}>
                    <LabelWithIcon label={t(this.getFieldValue("credit_note"))} />
                    <FileUpload
                        data-test-id="salesCreditNoteDoc"
                        fileName={this.getCreditDebitNote(values, 'file_name', installmentIndex)}
                        inputName="salesCreditNoteDoc"
                        isEditPage={this.state.isEdit}
                        filePath={this.getCreditDebitNote(values, 'url', installmentIndex)}
                        disabled={true}
                        eachFileSizeInMB={10}
                        maxFilesNumber={5}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LabelWithIcon label={t(this.getFieldValue("credit_note_id"))} />
                    <TextInput
                    id={`policyExpireDateTxtInput`}
                    data-test-id={`salesCreditNoteId`}
                    name={this.getUpdateKey(this.getFieldValue('salesCreditNoteId'), installmentIndex, isInstallment)}
                    className={classes.textInputStyle}
                    disabled={true}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
        // Customizable Area End
      );
    }
}

// Customizable Area Start
const useStyles = ({
    policyDetailStyle: {
      padding: "15px 0px",
    },
    policySpacing: {
      margin: "10px 0px",
      fontWeight: 600,
    },
    checkBoxInput: {
      marginTop: "15px"
    },
    grayBtn: {
      "text-transform": 'none',
      padding: 15,
      borderRadius: 5,
      width: '32.5%',
      fontSize: 15,
      backgroundColor: "#979090",
      color: "white"
    },
    primaryBtn: {
      "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
      "text-transform": 'none',
      "padding": 15,
      "borderRadius": 5,
      "width": '32.5%',
      "fontSize": 15,
      "color": "white"
    },
    salesGrayBtn: {
      "text-transform": 'none',
      padding: 15,
      borderRadius: 5,
      width: '100%',
      fontSize: 15,
      backgroundColor: "#979090",
      color: "white",
      marginTop: "37px"
    },
    salesActiveBtn: {
      "text-transform": 'none',
      padding: 15,
      borderRadius: 5,
      width: '100%',
      fontSize: 15,
      "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
      color: "white",
      marginTop: "37px"
    },
    accordionStyle: {
      background: "white !important",
      borderRadius: "10px !important",
    },
    textInputStyle: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  });
// Customizable Area End

export default withStyles(useStyles)(withTranslation()(StageNineInstallment));
