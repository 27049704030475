// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AnalyticsListing from '../../blocks/visualanalytics/src/AnalyticsListing.web';
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SplitPayments from "../../blocks/SplitPayments/src/SplitPayments";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import StripeIntegration from "../../blocks/StripeIntegration/src/StripeIntegration";
import SubscriptionBilling2 from "../../blocks/SubscriptionBilling2/src/SubscriptionBilling2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Payments from "../../blocks/Payments/src/Payments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import DeepLinking from "../../blocks/DeepLinking/src/DeepLinking";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ProjectTaskTracking from "../../blocks/ProjectTaskTracking/src/ProjectTaskTracking";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import CustomiseInterface from "../../blocks/CustomiseInterface/src/CustomiseInterface";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailLogin.web";
import TaskList from "../../blocks/TaskList/src/TaskList";
import SidebarMenu from '../../components/src/NativeWebRouteWrapper/SidebarMenu/SidebarMenu'
import TermsAndConditions from '../../components/src/TermsAndConditions.web'
import Customers from '../../blocks/catalogue/src/Customers.web';
import CustomersProfile from '../../blocks/catalogue/src/Customer/CustomerProfile';
import CustomersDetail from '../../blocks/catalogue/src/Customers.web';
import PolicyRequest from '../../blocks/TaskAllocator/src/PolicyInfo.web';
import RevisionRequest from '../../blocks/MultipageForms2/src/RevisionRequest/RevisionRequest.web';
import RequestListing from "../../blocks/TaskAllocator/src/PolicyStage/RequestListing.web";
import PolicyListing from "../../blocks/TaskAllocator/src/PolicyStage/PolicyListing.web";
import PolicyOverview from "../../blocks/MultipageForms2/src/PolicyOverview/PolicyOverview.web";
import ImportPolicyCsv from "../../blocks/importexportdata/src/PolicyCSV/PolicyCsvImport.web"
import Grid from '@material-ui/core/Grid';
import TopBar from '../../components/src/NativeWebRouteWrapper/SidebarMenu/TopBar';
import InsurerAdd from '../../blocks/landingpage/src/InsurerAdd.web';
import InsurerCsvImport from "../../blocks/importexportdata/src/InsurerCsv/InsurerCsvImport.web"
import CustomerListing from "../../blocks/catalogue/src/CustomerListing.web";
import InsurerListing from "../../blocks/landingpage/src/InsurerListing.web";
import CustomerCsvImport from "../../blocks/importexportdata/src/CustomerCSV/CustomerCsvImport.web"
import EmployeeCsvImport from "../../blocks/importexportdata/src/EmployeeCSV/EmployeeCsvImport.web"
import PolicyRequestCsvImport from "../../blocks/importexportdata/src/PolicyRequestCSV/PolicyRequestCsvImport.web"
import RevisionRequestCsvImport from "../../blocks/importexportdata/src/RevisionRequestCSV/RevisionRequestCsvImport.web"



// import { useHistory ,useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import { ContextData } from '../../components/src/utils'
import { baseURL } from "../../framework/src/config";
import  CSVExport  from "../../blocks/importexportdata/src/ExportCSV/ExportCSV.web";
import { getLoggedUserToken } from "../../components/src/Common/GetLoggedUserData";

const routeMap = {
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  SplitPayments: {
    component: SplitPayments,
    path: "/SplitPayments"
  },
  SaveAsPdf: {
    component: SaveAsPdf,
    path: "/SaveAsPdf"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: "/StripeIntegration"
  },
  SubscriptionBilling2: {
    component: SubscriptionBilling2,
    path: "/SubscriptionBilling2"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Payments: {
    component: Payments,
    path: "/Payments"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  DeepLinking: {
    component: DeepLinking,
    path: "/DeepLinking"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  ProjectTaskTracking: {
    component: ProjectTaskTracking,
    path: "/ProjectTaskTracking"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: "/ReviewAndApproval"
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  MultipageForms2: {
    component: MultipageForms2,
    path: "/MultipageForms2"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  CustomiseInterface: {
    component: CustomiseInterface,
    path: "/CustomiseInterface"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: "/EmailNotifications2"
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: "/ElasticSearch"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/TermsAndConditions"
  },
  InsurerAdd: {
    component: InsurerAdd,
    path: "/InsurerDetail",
  },
  Insurer: {
    component: InsurerListing,
    path: "/Insurer",
    exact: true
  },

  TaskList: {
    component: TaskList,
    path: "/TaskList"
  },
  Customers: {
    component: CustomerListing,
    path: "/Customers"
  },
  CustomersDetail: {
    component: CustomersDetail,
    path: "/CustomersDetail"
  },
  CustomersProfileAdd: {
    component: CustomersProfile,
    path: "/AddCustomer"
  },
  CustomerCsvImport: {
    component: CustomerCsvImport,
    path: "/ImportCustomerCsv"
  },
  EmployeeCsvImport: {
    component: EmployeeCsvImport,
    path: "/EmployeeCsvImport"
  },
  InsurerCsvImport: {
    component: InsurerCsvImport,
    path: "/InsurerCsvImport"
  },

  PolicyRequestCsvImport: {
    component: PolicyRequestCsvImport,
    path: "/PolicyRequestCsvImport"
  },
  RevisionRequestCsvImport: {
    component: RevisionRequestCsvImport,
    path: "/RevisionRequestCsvImport"
  },
  CustomersProfileEdit: {
    component: CustomersProfile,
    path: "/ViewCustomer"
  },
  PolicyRequest: {
    component: PolicyRequest,
    path: "/PolicyRequest"
  },
  RevisionRequest: {
    component: RevisionRequest,
    path: "/RevisionRequest"
  },
  Request: {
    component: RequestListing,
    path: "/Requests"
  },
  Policies: {
    component: PolicyListing,
    path: "/Policies"
  },
  PolicyOverview: {
    component: PolicyOverview,
    path: "/PolicyOverview"
  },
  AddPolicy: {
    component: PolicyRequest,
    path: "/AddPolicy"
  },
  ViewPolicy: {
    component: PolicyRequest,
    path: "/ViewPolicy"
  },
  EditPolicy: {
    component: PolicyRequest,
    path: "/EditPolicy"
  },
  ImportPolicyCsv:{
    component:ImportPolicyCsv,
    path:"/ImportPolicyCsv"
  },
  Home: {
    component: Dashboard,
    path: '/',
    exact: true
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  Analytics:{
    component: AnalyticsListing,
    path:"/Analytics"
  },
  CSVExport:{
    component:CSVExport,
    path:"/CSVExport",
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

const mq = window.matchMedia('(max-width: 768px)');
const mqMIN = window.matchMedia('(min-width: 768px)');
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      LangToggle: (window.localStorage.getItem('lang') || 'en'),
      sidebarHideShow: true,
    };
    this.checkIfMobile = this.checkIfMobile.bind(this);
  }

  componentDidMount() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  }

  checkIfMobile() {
    const mobileMatch = window.matchMedia('(max-width: 768px)');
    this.setState({ sidebarHideShow: !(mobileMatch.matches) });
  }

  componentWillUnmount() {
    mq.removeListener(this.toggle);
    window.removeEventListener('resize', this.checkIfMobile);
  }

  maxToggle = () => {
    if (mqMIN.matches) {
      // do something here
      this.setState((prev) => ({sidebarHideShow: !prev.sidebarHideShow}))
    }
  }

  toggle = () => {
    if (mq.matches) {
      // do something here
      this.setState((prev) => ({sidebarHideShow: !prev.sidebarHideShow}))
    } else {
      // do something here
    }
  };

  sidebarToggle = () => {
    this.setState((prev) => ({sidebarHideShow: !prev.sidebarHideShow}))
  }

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    // window.addEventListener('storage', handleStorage)



    i18n
      .use(initReactI18next)
      .use(HttpApi)
      .init({
        lng: this.state.LangToggle,
        fallbackLng: this.state.LangToggle,

        interpolation: {
          escapeValue: false
        },

        // Configure backend to fetch translations via API
        backend: {
          loadPath: (lngs) => {
            const locales = Array.isArray(lngs) ? lngs.join(',') : "en";
            return `${baseURL}/bx_block_translation/translations/get_translated_data?locale=${locales}`;
          },
          requestOptions: {
            headers: {
              'Content-Type': 'application/json',
              'token': getLoggedUserToken()
            }
          }
        },
        react: {
          useSuspense: false,
        }
      });

    const showSideMenues = () => {
      const location = createBrowserHistory()
      const URLcheck = location.location.pathname;
      if ("/EmailAccountLoginBlock" === URLcheck || "/TermsAndConditions" === URLcheck || "/EmailAccountRegistration" === URLcheck || "/ForgotPassword" === URLcheck || "/NewPassword" === URLcheck) {
        return true;
      }
      if ("/AdminConsole" === URLcheck) {
        window.open(`${baseURL}/admin`, '_blank')
      }
      return false;
    }

    const langControll = (lang) => {
      this.setState({ LangToggle: lang })
    }


    return (
      <View style={{ height: '100vh', width: '100%', overflowX: 'hidden' }}>
        <Grid container className={this.state.LangToggle === 'en' ? "english" : "arabic"} style={{ overflow: 'hidden', flexWrap: 'nowrap', height: '100vh' }} dir={this.state.LangToggle === 'en' ? "ltr" : "rtl"}>
          {showSideMenues() ? (
            <Grid item xs={12} className='SiteCmpWrapperWithoutNavbar backGroundImage'>
              {/* <TopNav /> */}
              {WebRoutesGenerator({ routeMap })}
              <ModalContainer />
            </Grid>
          ) : (
            <ContextData.Provider value={this.state.LangToggle}>
              <TopBar langControll={langControll} sidebarToggle={this.sidebarToggle} />
              {/* <Grid item xs={3}> */}
              <div className={`${this.state.sidebarHideShow ? 'sidebarShow' : 'd-show-hide'} test`}>
                <SidebarMenu show={this.state.sidebarHideShow} onClickOutside={(val) => { this.setState({ sidebarHideShow: val }) }} />
              </div>
              <div className='SiteCmpWrapper'>
                {WebRoutesGenerator({ routeMap })}
                <ModalContainer />
              </div>
              {/* </Grid>  */}
            </ContextData.Provider>
          )}
        </Grid>
      </View>
    );
  }
}

export default App;
