import React from "react";
import {
    Container, 
} from "@material-ui/core";
// @ts-ignore
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import StageSixController ,{Props} from "./StageSixController";
import StageSixForm from "../../../../components/src/Policy/StageSixForm";

const theme = createTheme({
    palette: {
        primary: {
            main: "#47d10e",
            contrastText: "#fff",
        },
    }
});

export class StageSix extends StageSixController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div data-test-id="CustomerDetails">
                <ThemeProvider theme={theme} >
                  
                    <Container id="customerContainer" maxWidth="lg" dir={/* istanbul ignore next */  (window.localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"} >
                        {/* @ts-ignore */}
                        <StageSixForm {...this.props} />
                    </Container>
                </ThemeProvider>
            </div >
        )
    }
}



export default StageSix;
