// Customizable Area Start
import React from "react";
import CSVExportComponents from "../../../../components/src/Common/CSVExportComponents";
import ExportCSVController,{Props} from "./ExportCSVController.web";




// Customizable Area End
export default class CSVExport extends  ExportCSVController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <CSVExportComponents
      data-test-id="csv-export-container"
      templteColumnList={this.state.templteColumnList}
      selectedTemplateId={this.state.selectedTemplateId}
      handleTemplateIDChange={this.handleTemplateIDChange}
      handleDownloadfile={this.handleDownloadfile}
      handleShowAlert={this.handleShowAlert}
      updateEncodingValue={this.updateEncodingValue}
      isExistingTemplate={this.templateId}
      handleCreateNewTemplate={this.handleCreateNewTemplate}
      newTemplateName={this.state.newTemplateName}
      handleTemplateNameChange={this.handleTemplateNameChange}
      handleDragStart={this.handleDragStart}
      handleDragOver={this.handleDragOver}
      handleDragEnd={this.handleDragEnd}
      selectedColumnName={this.state.selectedColumnName}
      handleColumnNameChange={this.handleColumnNameChange}
      columnNamesList={this.state.columnNamesList}
      addColumn={this.addColumn}
      addEncoding={this.addEncoding}
      columnList={this.state.columnList}
      handleCreatetemplate={this.handleCreatetemplate}
      handleSaveTemplatePopUp={this.handleSaveTemplatePopUp}
      saveTemplate={this.saveTemplate}
      openCreateTemplatePopUp={this.state.openCreateTemplatePopUp}
      openSaveTemplatePopUp={this.state.openSaveTemplatePopUp}
      removeColumn={this.removeColumn}
      removeEncoding={this.removeEncoding}
      alertType={this.state.alertType}
      alertMessage={this.state.alertMessage}
      showAlert={this.state.showAlert}
      />
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
