import React, { useRef } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Pagination } from "@material-ui/lab";
import { getLoggedUserRole } from "../GetLoggedUserData";

const TransactionTable = (props: any) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { totalPage, currentPage, pageChange, tableData, transactionLoading } = props;
    const paginationButtonRef = useRef();

    const UserRole = {
        OPERATION : "Operations",
        SALES: "Sales",
        ADMIN: "Admin",
        CUSTOMER: "Customer",
        FINANCE: "Finance"
    }

    return (
        <div data-test-id="TransactionTable">
            <div className={classes.tableWithPagination}>
            <TableContainer className={classes.tabelContainer}>
                <Table>
                    <TableHead>
                        <TableRow className={classes.transactionTableHead}>
                            <TableCell className={`${classes.policyIdCell} ${classes.tabelHeading}`}>{t('policy_id')}</TableCell>
                            <TableCell className={`${classes.dateCell} ${classes.tabelHeading}`}>{t('date')}</TableCell>
                            <TableCell className={`${classes.transactionIdCell} ${classes.tabelHeading}`}>{getLoggedUserRole() === (UserRole.OPERATION) ? t('request_id') : t('transaction_id')}</TableCell>
                            <TableCell className={classes.tabelHeading}>{t('insurance_type')}</TableCell>
                            <TableCell className={classes.tabelHeading}>{t('customer')}</TableCell>
                            <TableCell className={classes.tabelHeading}>{getLoggedUserRole() === (UserRole.ADMIN || UserRole.FINANCE) ? t('paid_amount_ex_vat') : t('revenue_vat')}</TableCell>
                            {(getLoggedUserRole() !== (UserRole.OPERATION || UserRole.CUSTOMER)) && <TableCell className={classes.tabelHeading}>{t('siib_comm_vat')}</TableCell>}
                            {(getLoggedUserRole() !== (UserRole.OPERATION || UserRole.CUSTOMER)) && <TableCell className={classes.tabelHeading}>&nbsp;%&nbsp;</TableCell>}
                            {(getLoggedUserRole() !== (UserRole.OPERATION || UserRole.CUSTOMER)) && <TableCell className={`${classes.tabelHeading} ${classes.siibCommisionCellTotal}`}>{`${t('vat_on_siib_commision')} (15%)`}</TableCell>}
                            {(getLoggedUserRole() !== (UserRole.OPERATION || UserRole.CUSTOMER)) && <TableCell className={classes.tabelHeading}>{t('sales_comms')}</TableCell>}
                            {(getLoggedUserRole() !== (UserRole.OPERATION || UserRole.CUSTOMER)) && <TableCell className={classes.tabelHeading}>&nbsp;%&nbsp;</TableCell>}
                            <TableCell className={classes.tabelHeading}>{t('remaining_amount')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactionLoading &&
                            <TableRow>
                                <TableCell colSpan={11}>
                                    <Typography align="center" className={classes.tableLoading}>
                                        <CircularProgress size={50} />
                                    </Typography>
                                    <Typography align="center">
                                        {t('fetching_the_data_please_wait')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        }
                        {!transactionLoading && tableData.length > 0 ? tableData.map((rowData: any, index: number) => (
                            <React.Fragment key={index}>
                                {rowData.transactions.map((data: any, i: number) => (
                                    <TableRow key={i}>
                                        <TableCell className={`${classes.policyIdCell} ${classes.stickyCell} ${classes.whiteBackground}`}>
                                            {i === 0 && rowData.policy_id}
                                        </TableCell>
                                        <TableCell className={`${classes.dateCell} ${classes.stickyCell} ${classes.whiteBackground}` }>{data.date || "-"}</TableCell>
                                        <TableCell className={`${classes.transactionIdCell} ${classes.stickyCell} ${classes.whiteBackground}`}>{
                                            (getLoggedUserRole() === (UserRole.OPERATION) ? data.request : data.transaction_id ) || "-"
                                        }</TableCell>
                                        <TableCell className={classes.whiteBackground}>{data.types_of_insurance  || "-" }</TableCell>
                                        <TableCell className={classes.whiteBackground}>{data.customer  || "-" }</TableCell>
                                        <TableCell className={classes.whiteBackground}>
                                            {
                                                getLoggedUserRole() === (UserRole.ADMIN || UserRole.FINANCE) ? 
                                                (data.paid_amount ? `SAR ${data.paid_amount }` : "-" ) : 
                                                (data.revenue_change ? `SAR ${data.revenue_change }` : "-" )
                                            }
                                        </TableCell>
                                        {(getLoggedUserRole() !== (UserRole.OPERATION || UserRole.CUSTOMER)) && <TableCell className={classes.whiteBackground}>{data.siib_commission ?  `SAR ${data.siib_commission}` : "-" }</TableCell>}
                                        {(getLoggedUserRole() !== (UserRole.OPERATION || UserRole.CUSTOMER)) && <TableCell className={classes.whiteBackground}>{data.siib_commission_per && `${data.siib_commission_per}%`  || "-"}</TableCell>}
                                        {(getLoggedUserRole() !== (UserRole.OPERATION || UserRole.CUSTOMER)) && <TableCell className={classes.whiteBackground}>{data.vat_amt_siib_commission ? `SAR ${data.vat_amt_siib_commission}` : "-"}</TableCell>}
                                        {(getLoggedUserRole() !== (UserRole.OPERATION || UserRole.CUSTOMER)) && <TableCell className={classes.whiteBackground}>{data.sales_commission ? `SAR ${data.sales_commission}` : "-" }</TableCell>}
                                        {(getLoggedUserRole() !== (UserRole.OPERATION || UserRole.CUSTOMER)) && <TableCell className={classes.whiteBackground}>{data.sales_commission_per && `${data.sales_commission_per}%`  || "-"}</TableCell>}
                                        <TableCell className={classes.whiteBackground}>{data.remaining_amount  || "-" }</TableCell>
                                    </TableRow>
                                ))}
                                {(getLoggedUserRole() !== (UserRole.OPERATION || UserRole.CUSTOMER)) && (<TableRow>
                                    <TableCell className={`${classes.policyIdCell} ${classes.whiteBackground}`}></TableCell>
                                    <TableCell className={`${classes.dateCell} ${classes.tabelHeading} ${classes.whiteBackground}`}>{t('total')}</TableCell>
                                    <TableCell className={`${classes.transactionIdCell} ${classes.whiteBackground}`}></TableCell>
                                    <TableCell className={classes.whiteBackground}></TableCell>
                                    <TableCell className={classes.whiteBackground}></TableCell>
                                    {<TableCell className={`${classes.whiteBackground} ${classes.tabelHeading}`}>
                                        {
                                            getLoggedUserRole() === (UserRole.ADMIN || UserRole.FINANCE) ? 
                                            (rowData.total_paid_amount ? `SAR ${rowData.total_paid_amount }` : "-" ) : 
                                            (rowData.total_revenue_change ? `SAR ${rowData.total_revenue_change }` : "-" )
                                        }
                                    </TableCell>}
                                    {<TableCell className={`${classes.whiteBackground} ${classes.tabelHeading}`}>SAR {rowData.total_siib_commission}</TableCell>}
                                    {<TableCell className={classes.whiteBackground}></TableCell>}
                                    {<TableCell className={`${classes.whiteBackground} ${classes.tabelHeading}`}>SAR {rowData.total_vat_amt_siib_commission}</TableCell>}
                                    {<TableCell className={`${classes.whiteBackground} ${classes.tabelHeading}`}>SAR {rowData.total_sales_commission}</TableCell>}
                                    <TableCell className={classes.whiteBackground}></TableCell>
                                    {<TableCell className={`${classes.whiteBackground} ${classes.tabelHeading}`}>SAR {rowData.total_remaining_amount}</TableCell>}
                                </TableRow>)}
                            </React.Fragment>
                        )) : (
                            <TableRow>
                            <TableCell colSpan={11}>
                                <Typography align="center">
                                    { !transactionLoading && t('no_records_found')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
                <div dir="ltr" id="transactionPagination" className={classes.paginationSection}>
                    <div style={{ width: '100px' }} />
                    <Pagination
                        shape="rounded"
                        className={classes.paginationRoot}
                        count={totalPage}
                        page={currentPage}
                        onChange={pageChange}
                        showFirstButton
                        showLastButton
                        ref={paginationButtonRef}
                    />
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        primaryBtnStyle: {
            background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
            textTransform: "none",
            color: "white",
            height: "50px",
            width: "150px",
            borderRadius: "10px"
        },
        siibCommisionCellTotal:{
            width:"20%"
        },
        transactionTableHead: {
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        tableWithPagination:{
            border: "1px solid grey",
            borderRadius: "7px",
        },
        tabelContainer: {
            padding: 0,
            width: "100%",
            overflowX: "auto",
        },
        tabelHeading: {
            fontSize: "14px",
            fontWeight: 700,
            padding: "13px 15px"
        },
        stickyCell: {
            position: "sticky",
            zIndex: 1
        },
        policyIdCell: {
            position: "sticky",
            left: 0,
            zIndex: 3,
            width: "200px",
            minWidth: "100px",
            backgroundColor: "rgba(233, 244, 249, 1)",
        },
        dateCell: {
            position: "sticky",
            left: "100px",
            zIndex: 2,
            width: "200px",
            minWidth: "110px",
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        transactionIdCell: {
            position: "sticky",
            left: "205px",
            zIndex: 3,
            width: "200px",
            backgroundColor: "rgba(233, 244, 249, 1)",
            overflow: "hidden",
            minWidth: "max-content",
            "&:after": {
                content: '""',
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "5px",
                background: "linear-gradient(to right, #ccc, rgba(204, 204, 204, 0))"
            },
        },
        whiteBackground: {
            backgroundColor: "white"
        },
        paginationSection: {
            position: "sticky",
            top: 0,
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            zIndex: 2,
            marginLeft: "-1px",
        },
        paginationRoot: {
            "& .MuiPaginationItem-root": {
                borderRadius: "5%",
                width: "32px",
                height: "32px",
                // backgroundColor: "#ccc",
                margin: "0 4px",
                color: "#333",
            },
            "& .Mui-selected": {
                backgroundColor: "rgba(233, 244, 249, 1)",
            },
        },
        tableLoading:{
            padding:"50px"
        }
    })
);

export default TransactionTable;
