// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  
}

interface S {
  customerList: any;
  page: number;
  rowPerPage: number;
  totalPage: number;
  isArchived: boolean;
  searchQuery: string;
  alertMessage: string;
  showAlert: boolean;
  alertType: string;
  isArabicLenguage: boolean;
}

interface SS {
  id: any;
}

export default class CustomerListingController extends BlockComponent<Props, S, SS> {

  getAllCustomerListId: any;
  archiveCustomerId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      customerList: [],
      page: 1,
      rowPerPage: 10,
      totalPage: 1,
      isArchived: false,
      searchQuery: "",
      alertMessage: "",
      showAlert: false,
      alertType: "",
      isArabicLenguage: (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
    };

    this.checkNeedToShowAlert();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllCustomerListId != null &&
      this.getAllCustomerListId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          customerList: responseJson.data,
          totalPage: responseJson.meta.total_page,
        });
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.archiveCustomerId != null &&
      this.archiveCustomerId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        // this.setState({customerList:responseJson.data,totalPage:responseJson.meta.total_page})
        this.getAllCustomerList();
      }
    }
  }

  async  componentDidMount(){
    this.getAllCustomerList()
  }
  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {
    if (prevState.searchQuery !== this.state.searchQuery) {
      this.getAllCustomerList();
    }
    if (
      prevState.page !== this.state.page ||
      prevState.rowPerPage !== this.state.rowPerPage ||
      prevState.isArchived !== this.state.isArchived
    ) {
      this.getAllCustomerList();
    }
    if (prevState.showAlert !== this.state.showAlert) {
      setTimeout(() => {
        this.setState({
          showAlert: false,
          alertType: "",
          alertMessage: "",
        });
        localStorage.removeItem('alertDetail')
      }, 10000);
    }
  }

  handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
    this.setState({ page: page });
  };
  
  handleRowPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ rowPerPage: parseInt(event.target.value) });
    this.setState({ page: 1 });
  };
  handleArchiveCustomerTable = () => {
    this.setState({ isArchived: !this.state.isArchived, page: 1 });
  };

  handleSearchCustomerQuery = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState({ searchQuery: event.target.value });
  };

  showAddCustomerBtn = () => {
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const userRole = userData.role || '';
    if ((userRole !== "Finance") && (userRole !== "finance") && (userRole !== "operations") && (userRole !== "Operations")) {
      return true
    } else {
      return false
    }
  }

  closeAlertMessage = async () => {
    this.setState({
      showAlert: false,
      alertMessage: "",
      alertType: ""
    })

  }
  
  async getAllCustomerList() {
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const customerApiPath = `/bx_block_customer/customers/?archived=${
      this.state.isArchived
    }&page=${this.state.page}&per_page=${this.state.rowPerPage}&search=${
      this.state.searchQuery
    }`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllCustomerListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      customerApiPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  archiveCustomer = async (customerId: string | undefined) => {
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const archiveCustmerPath = `/bx_block_customer/customers/archive?id=${customerId}&archive=true`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.archiveCustomerId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      archiveCustmerPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async checkNeedToShowAlert() {
    const alertData = await JSON.parse(localStorage.getItem("alertDetail") || "{}");
    const currentPage = window.location.href.split('/').pop();
    if (alertData && currentPage === alertData.page) {
      this.setState({
        showAlert: true,
        alertType: alertData.alertType,
        alertMessage: this.state.isArabicLenguage ? alertData.arabicTxt : alertData.englishTxt
      })
    }
  }
}
// Customizable Area End
