import React, { useRef } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Pagination } from "@material-ui/lab";

const ROW_PER_PAGE_OPTION = [
    {
      label: "5",
      value: 5,
    },
    {
      label: "10",
      value: 10,
    },
    {
      label: "15",
      value: 15,
    },
  ];

const InsuranceInsurerTable = (props: any) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { totalPage, currentPage, handlePageChange, tableData, tableFor, insuranceLoading, perPage, handleRowPerPage } = props;
    const paginationButtonRef = useRef();

    return (
        <div data-test-id="InsuranceAndInsurerTable">
            <TableContainer className={classes.tabelContainer}>
            <Table>
    <TableHead>
        <TableRow id="InsuranceInsurerTableHead" className={classes.InsuranceAndInsurerTableHead}>
            <TableCell className={classes.tabelHeading} style={{borderRight: "1px solid grey"}} rowSpan={2}>{tableFor === "Insurer" ? t('insurer') : t('insurance_type')}</TableCell>
            <TableCell className={classes.tabelHeading} colSpan={3}>{t('new_policy')}</TableCell>
            <TableCell className={classes.tabelHeading} colSpan={3}>{t('renew_policies')}</TableCell>
            {tableFor==="Insurer" && <TableCell className={classes.tabelHeading} style={{borderLeft: "1px solid grey"}} rowSpan={2}>{t('balance')}</TableCell>}
        </TableRow>
        <TableRow className={classes.InsuranceAndInsurerTableHead}>
            <TableCell className={classes.tabelHeading}>{t('gross_written_premium')}</TableCell>
            <TableCell className={classes.tabelHeading}>{t('siib_commision')}</TableCell>
            <TableCell className={classes.tabelHeading}>{t('of_written_policy')}</TableCell>
            <TableCell className={classes.tabelHeading}>{t('gross_written_premium')}</TableCell>
            <TableCell className={classes.tabelHeading}>{t('siib_commision')}</TableCell>
            <TableCell className={classes.tabelHeading}>{t('of_written_policy')}</TableCell>
        </TableRow>
    </TableHead>
    <TableBody>
        {insuranceLoading &&
                <TableRow>
                    <TableCell colSpan={11}>
                        <Typography align="center" className={classes.tableLoading}>
                            <CircularProgress size={50} />
                        </Typography>
                        <Typography align="center">
                            {t('fetching_the_data_please_wait')}
                        </Typography>
                    </TableCell>
                </TableRow>
            }
        {!insuranceLoading && Object.keys(tableData).length > 0 ? Object.keys(tableData).map((data:any, rowIndex:number) => (
            <TableRow key={rowIndex}>
                <TableCell>{data}</TableCell>
                    <TableCell>SAR {tableData[data].new_policies.gross_written_premium}</TableCell>
                    <TableCell>SAR {tableData[data].new_policies.siib_commission}</TableCell>
                    <TableCell>{tableData[data].new_policies.policies_count}</TableCell>
                    <TableCell>SAR {tableData[data].renew_policies.gross_written_premium}</TableCell>
                    <TableCell>SAR {tableData[data].renew_policies.siib_commission}</TableCell>
                    <TableCell>{tableData[data].renew_policies.policies_count}</TableCell>
                {tableFor === "Insurer" && <TableCell>SAR {tableData[data].balance}</TableCell>}
            </TableRow>
        ))
        : (
            <TableRow>
            <TableCell colSpan={11}>
                <Typography align="center">
                    {!insuranceLoading && t('no_records_found')}
                </Typography>
            </TableCell>
        </TableRow>
        )
        }
    </TableBody>
</Table>
            {tableFor==="Insurer" && (
                <div dir="ltr" id="InsuranceAndInsurerPagination" className={classes.paginationSection}>
                    <div style={{ width: '100px' }} />
                    <Pagination
                        shape="rounded"
                        className={classes.paginationRoot}
                        count={totalPage}
                        page={currentPage}
                        onChange={handlePageChange}
                        showFirstButton
                        showLastButton
                        ref={paginationButtonRef}
                    />
                    <div className={classes.rowPerPageConatiner}>
                        <Typography className={classes.itemPerPage}>{t('items_per_page')}</Typography>
                        <select className={classes.rowPerPageSelect} onChange={handleRowPerPage}>
                            {
                            ROW_PER_PAGE_OPTION.map((options,index)=><option key={`${index}-${options.value}-${index}`} value={options.value} selected={options.value == (perPage || 10)}>{options.label}</option>)
                            }
                        </select>
                    </div>
                </div>
            )}
            </TableContainer>
        </div>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        primaryBtnStyle: {
            background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
            textTransform: "none",
            color: "white",
            height: "50px",
            width: "150px",
            borderRadius: "10px"
        },
        InsuranceAndInsurerTableHead: {
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        tabelContainer: {
            padding: 0,
            border: "1px solid grey",
            borderRadius: "7px",
        },
        tabelHeading: {
            fontSize: "14px",
            fontWeight: 700,
            padding: "13px 15px",
            borderBottom: "1px solid grey"
        },
        stickyCell: {
            position: "sticky",
            zIndex: 1
        },
        policyIdCell: {
            position: "sticky",
            left: 0,
            zIndex: 3,
            width: "120px",
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        policyIdCellBody: {
            position: "sticky",
            left: 0,
            zIndex: 3,
            width: "120px",
            background: "white",
        },
        dateCell: {
            position: "sticky",
            left: "7%",
            zIndex: 2,
            width: "20%",
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        dateCellBody: {
            position: "sticky",
            left: "7%",
            zIndex: 2,
            width: "20%",
            background: "white",
        },
        InsuranceAndInsurerIdCell: {
            position: "sticky",
            left: "27%",
            zIndex: 3,
            width: "calc(20% - 1px)",
            backgroundColor: "rgba(233, 244, 249, 1)",
            overflow: "hidden",
            "&:after": {
                content: '""',
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "5px",
                background: "linear-gradient(to right, #ccc, rgba(204, 204, 204, 0))"
            },
        },
        InsuranceAndInsurerIdCellBody: {
            position: "sticky",
            left: "27%",
            zIndex: 3,
            width: "calc(20% - 1px)",
            background: "white",
            overflow: "hidden",
            "&:after": {
                content: '""',
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "5px",
                background: "linear-gradient(to right, #ccc, rgba(204, 204, 204, 0))"
            },
        },
        paginationClass: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
          },
        paginationSection: {
            position: "sticky",
            top: 0,
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            zIndex: 2,
            marginLeft: "-1px",
        },
        paginationRoot: {
            "& .MuiPaginationItem-root": {
                borderRadius: "50%",
                width: "32px",
                height: "32px",
                backgroundColor: "#ccc",
                margin: "0 4px",
                color: "#333",
            },
            "& .Mui-selected": {
                backgroundColor: "rgba(233, 244, 249, 1)",
            },
        },
        rowPerPageConatiner: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        tableLoading:{
            padding:"50px"
        },
        itemPerPage:{
            fontSize: "revert",
            padding: "3px 8px",
            color: "grey"
        },
        rowPerPageSelect: {
            padding: "5px 11px",
            borderRadius: "4px",
            border: "1px solid grey",
            backgroundColor: "white",
        },
    })
);

export default InsuranceInsurerTable;
