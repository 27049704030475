import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

function SearchBar({searchQuery,value}:any){
  const { t } = useTranslation();
const classes = useStyles();

  return (
<div className={classes.searchbarContainer}>
    <SearchIcon style={{ color: "grey" }} />
      <Input
      value={value}
      placeholder={t('search') || ""}
      onChange={searchQuery}
      style={{width:"400px" , color: 'rgba(0, 0, 0, 0.6)', fontSize: '1.1rem'}}
      disableUnderline
      className={classes.searchbar}
      />
  </div>
  )
}

const useStyles = makeStyles(theme=>createStyles({
   searchbarContainer:{
    width:"285px",
    display:'flex',
    alignItems: 'center',
    padding:"8px 12px",
    backgroundColor:'white',
    borderRadius:'6px',
    border:'1px solid lightgrey',
    marginBottom:'15px'
   },
   searchbar:{
    fontSize: 'initial',
    color: 'black',
    padding: '0 8px'

   }
  }));
export default SearchBar