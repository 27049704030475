import * as Yup from 'yup';
// import { emptyStringToUndefined } from '../Common/EmptyStringToUndefined';

const StageSixFormSchema = (isArabicLanguage: boolean) => {
    const schema = {
        stageSixDetails: Yup.array(Yup.object().shape({




            paid_to: Yup.string().when('approval', {
                is: true,
                then: Yup.string().required(isArabicLanguage? 'التعليق مطلوب' : "Paid to is required."),
                otherwise: Yup.string().notRequired()
            }),
            payment_made_on: Yup.string().when('approval', {
                is: true,
                then: Yup.string().required(isArabicLanguage ? 'المبلغ مطلوب' : "Payment made on is required."),
                otherwise: Yup.string().notRequired()
            }),
            firm_order: Yup.mixed().when('approval', {
                is: true,
                then: Yup.mixed().test(
                    "isNotEmpty",
                    isArabicLanguage ? 'مطلوب أمر ثابت' : "Firm order is required.",
                    function (value) {
                        const firmOrderDocument = this.resolve(Yup.ref("firm_order"));
                        if (typeof firmOrderDocument === "string" && firmOrderDocument === "") {
                            return false;
                        } else if (
                            firmOrderDocument &&
                            typeof (firmOrderDocument) === "object" &&
                            (Object.keys(firmOrderDocument).length === 0)
                        ) {
                            
                            return false;
                        } else {
                            return true;
                        }
                    }

                ).test(
                    "fileSize",
                    isArabicLanguage ? "مستند السجل التجاري لمعرف الكفيل كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "Firm Order document too large, allowed only less than 10 MB",
                    (file) => {
                        if (typeof (file) === "string" && file !== "") {
                            return true;
                        } else
                            if (file && file.size) {
                                return file.size <= 10000000;
                            } else {
                                return true;
                            }
                    }
                ),
                otherwise: Yup.mixed().notRequired()

            }),



            vat_certificate: Yup.mixed().when('approval', {
                is: true,
                then: Yup.mixed().test(
                    "isNotEmpty",
                    isArabicLanguage ? 'مطلوب التحقق من ضريبة القيمة المضافة.' : "VAT verification is required.",
                    function (value) {
                        const vatCerificateDocument = this.resolve(Yup.ref("vat_certificate"));
                        if (typeof vatCerificateDocument === "string" && vatCerificateDocument === "") {
                            return false;
                        } else if (
                            vatCerificateDocument &&
                            typeof (vatCerificateDocument) === "object" &&
                            (Object.keys(vatCerificateDocument).length === 0)
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                )
                    .test(
                        "fileSize",
                        isArabicLanguage ? "مستند السجل التجاري لمعرف الكفيل كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "Firm Order document too large, allowed only less than 10 MB",
                        (file) => {
                            if (typeof (file) === "string" && file !== "") {
                                return true;
                            } else
                                if (file && file.size) {
                                    return file.size <= 10000000;
                                } else {
                                    return true;
                                }
                        }),
                otherwise: Yup.mixed().notRequired()
            }),
            owner_id: Yup.mixed().when('approval', {
                is: true,
                then: Yup.mixed().test(
                    "isNotEmpty",
                    isArabicLanguage ? 'مطلوب معرف الطلب' : "Owner ID  is required.",
                    function (value) {
                        const ownerIdDocument = this.resolve(Yup.ref("owner_id"));
                        if (typeof ownerIdDocument === "string" && ownerIdDocument === "") {
                            return false;
                        } else if (
                            ownerIdDocument &&
                            typeof (ownerIdDocument) === "object" &&
                            (Object.keys(ownerIdDocument).length === 0)
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                )
                    .test(
                        "fileSize",
                        isArabicLanguage ? "مستند السجل التجاري لمعرف الكفيل كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "Firm Order document too large, allowed only less than 10 MB",
                        (file) => {
                            if (typeof (file) === "string" && file !== "") {
                                return true;
                            } else
                                if (file && file.size) {
                                    return file.size <= 10000000;
                                } else {
                                    return true;
                                }
                        }
                    )
            },),

            facilities_credit: Yup.mixed().when('approval', {
                is: true,
                then: Yup.mixed().test(
                    "isNotEmpty",
                    isArabicLanguage ? 'مطلوب ائتمان التسهيلات.' : "Facilities credit is required.",
                    function (value) {
                        const facilitiesCreditDocument = this.resolve(Yup.ref("facilities_credit"));
                        if (typeof facilitiesCreditDocument === "string" && facilitiesCreditDocument === "") {
                            return false;
                        } else if (
                            facilitiesCreditDocument &&
                            typeof (facilitiesCreditDocument) === "object" &&
                            (Object.keys(facilitiesCreditDocument).length === 0)
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                )
                    .test(
                        "fileSize",
                        isArabicLanguage ? "مستند السجل التجاري لمعرف الكفيل كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "Firm Order document too large, allowed only less than 10 MB",
                        (file) => {
                            if (typeof (file) === "string" && file !== "") {
                                return true;
                            } else
                                if (file && file.size) {
                                    return file.size <= 10000000;
                                } else {
                                    return true;
                                }
                        }
                    )
            },),



            installment_form_filled_by_client: Yup.mixed().when('approval', {
                is: true,
                then: Yup.mixed()
                    .test(
                        "isNotEmpty",
                        isArabicLanguage ? 'مطلوب ائتمان التسهيلات.' : "Installment form filled by client is required.",
                        function (value) {
                            const instalmentFormFilledByClientDocument = this.resolve(Yup.ref("installment_form_filled_by_client"));
                            if (typeof instalmentFormFilledByClientDocument === "string" && instalmentFormFilledByClientDocument === "") {
                                return false;
                            } else if (
                                instalmentFormFilledByClientDocument &&
                                typeof (instalmentFormFilledByClientDocument) === "object" &&
                            (Object.keys(instalmentFormFilledByClientDocument).length === 0)
                            ) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    )
                    .test(
                        "fileSize",
                        isArabicLanguage ? "مستند السجل التجاري لمعرف الكفيل كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "Firm Order document too large, allowed only less than 10 MB",
                        (file) => {
                            if (typeof (file) === "string" && file !== "") {
                                return true;
                            } else
                                if (file && file.size) {
                                    return file.size <= 10000000;
                                } else {
                                    return true;
                                }
                        }
                    )
            },),
            client_iban: Yup.mixed().when('approval', {
                is: true,
                then: Yup.mixed()
                    .test(
                        "isNotEmpty",
                        isArabicLanguage ? 'مطلوب رقم الحساب المصرفي الدولي للعميل.' : "Client IBAN is required.",
                        function (value) {
                            const instalmentFormFilledByClientDocument = this.resolve(Yup.ref("client_iban"));
                            if (typeof instalmentFormFilledByClientDocument === "string" && instalmentFormFilledByClientDocument === "") {
                                return false;
                            } else if (
                                instalmentFormFilledByClientDocument &&
                                typeof (instalmentFormFilledByClientDocument) === "object" &&
                            (Object.keys(instalmentFormFilledByClientDocument).length ===0)
                            ) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    )
                    .test(
                        "fileSize",
                        isArabicLanguage ? "مستند السجل التجاري لمعرف الكفيل كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "Firm Order document too large, allowed only less than 10 MB",
                        (file) => {
                            if (typeof (file) === "string" && file !== "") {
                                return true;
                            } else
                                if (file && file.size) {
                                    return file.size <= 10000000;
                                } else {
                                    return true;
                                }
                        }
                    )
            },),
            payment_proof: Yup.mixed().when('approval', {
                is: true,
                then: Yup.mixed()
                    .test(
                        "isNotEmpty",
                        isArabicLanguage ? 'مطلوب إثبات الدفع.' : " Payment proof is required.",
                        function (value) {
                            const paymentProofDocument = this.resolve(Yup.ref("payment_proof"));
                            if (typeof paymentProofDocument === "string" && paymentProofDocument === "") {
                                return false;
                            } else if (
                                paymentProofDocument &&
                                typeof (paymentProofDocument) === "object" &&
                                (Object.keys(paymentProofDocument).length ===0)

                            ) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    )
                    .test(
                        "fileSize",
                        isArabicLanguage ? "مستند السجل التجاري لمعرف الكفيل كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "Firm Order document too large, allowed only less than 10 MB",
                        (file) => {
                            if (typeof (file) === "string" && file !== "") {
                                return true;
                            } else
                                if (file && file.size) {
                                    return file.size <= 10000000;
                                } else {
                                    return true;
                                }
                        }
                    )
            },),

            authorization_letter_for_other_person: Yup.mixed().when('approval', {
                is: true,
                then: Yup.mixed()
                    .test(
                        "isNotEmpty",
                        isArabicLanguage ? 'مطلوب خطاب تفويض من شخص آخر.' : "Authorisation letter from other person is required.",
                        function (value) {
                            const authorisationLetterDocument = this.resolve(Yup.ref("authorization_letter_for_other_person"));
                            if (typeof authorisationLetterDocument === "string" && authorisationLetterDocument === "") {
                                return false;
                            } else if (
                                authorisationLetterDocument &&
                                typeof (authorisationLetterDocument) === "object" &&
                                (Object.keys(authorisationLetterDocument).length ===0)
                            ) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    )
                    .test(
                        "fileSize",
                        isArabicLanguage ? "مستند السجل التجاري لمعرف الكفيل كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "Firm Order document too large, allowed only less than 10 MB",
                        (file) => {
                            if (typeof (file) === "string" && file !== "") {
                                return true;
                            } else
                                if (file && file.size) {
                                    return file.size <= 10000000;
                                } else {
                                    return true;
                                }
                        }
                    ),
            }),
            authorized_person_id: Yup.mixed().when('approval', {
                is: true,
                then: Yup.mixed()
                    .test(
                        "isNotEmpty",
                        isArabicLanguage ? 'مطلوب معرف الشخص المرخص.' : 'authorisation person id is required.',
                        function (value) {
                            const authorisationPersonIdDocument = this.resolve(Yup.ref("authorized_person_id"));
                            if (typeof authorisationPersonIdDocument === "string" && authorisationPersonIdDocument === "") {
                                return false;
                            } else if (
                                authorisationPersonIdDocument &&
                                typeof (authorisationPersonIdDocument) === "object" &&
                                (Object.keys(authorisationPersonIdDocument).length ===0)
                                
                            ) {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    )
                    .test(
                        "fileSize",
                        isArabicLanguage ? "مستند السجل التجاري لمعرف الكفيل كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "Firm Order document too large, allowed only less than 10 MB",
                        (file) => {
                            if (typeof (file) === "string" && file !== "") {
                                return true;
                            } else
                                if (file && file.size) {
                                    return file.size <= 10000000;
                                } else {
                                    return true;
                                }
                        }
                    )
            },),




            health_declarations: Yup.mixed().when('approval', {
                is: true,
                then: Yup.mixed().test(
                    "isNotEmpty",
                    isArabicLanguage ? 'مطلوب معرف الطلب' : "Health declarations  is required.",
                    function (value) {
                        const ownerIdDocument = this.resolve(Yup.ref("health_declarations"));
                        if (typeof ownerIdDocument === "string" && ownerIdDocument === "") {
                            return false;
                        } else if (
                            ownerIdDocument &&
                            typeof (ownerIdDocument) === "object" &&
                            (Object.keys(ownerIdDocument).length ===0)

                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                )
                    .test(
                        "fileSize",
                        isArabicLanguage ? "مستند السجل التجاري لمعرف الكفيل كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "Firm Order document too large, allowed only less than 10 MB",
                        (file) => {
                            if (typeof (file) === "string" && file !== "") {
                                return true;
                            } else
                                if (file && file.size) {
                                    return file.size <= 10000000;
                                } else {
                                    return true;
                                }
                        }
                    )
            },),
            sima_form: Yup.mixed().when('approval', {
                is: true,
                then: Yup.mixed().test(
                    "isNotEmpty",
                    isArabicLanguage ? 'مطلوب معرف الطلب' : "SIMA form  is required.",
                    function (value) {
                        const ownerIdDocument = this.resolve(Yup.ref("sima_form"));
                        if (typeof ownerIdDocument === "string" && ownerIdDocument === "") {
                            return false;
                        } else if (
                            ownerIdDocument &&
                            typeof (ownerIdDocument) === "object" &&
                            (Object.keys(ownerIdDocument).length ===0)

                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                )
                    .test(
                        "fileSize",
                        isArabicLanguage ? "مستند السجل التجاري لمعرف الكفيل كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "Firm Order document too large, allowed only less than 10 MB",
                        (file) => {
                            if (typeof (file) === "string" && file !== "") {
                                return true;
                            } else
                                if (file && file.size) {
                                    return file.size <= 10000000;
                                } else {
                                    return true;
                                }
                        }
                    )
            },),
            account_creation_form: Yup.mixed().when('approval', {
                is: true,
                then: Yup.mixed().test(
                    "isNotEmpty",
                    isArabicLanguage ? 'مطلوب معرف الطلب' : "Account creation form id  is required.",
                    function (value) {
                        const ownerIdDocument = this.resolve(Yup.ref("account_creation_form"));
                        if (typeof ownerIdDocument === "string" && ownerIdDocument === "") {
                            return false;
                        } else if (
                            ownerIdDocument &&
                            typeof (ownerIdDocument) === "object" &&
                            (Object.keys(ownerIdDocument).length ===0)

                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                )
                    .test(
                        "fileSize",
                        isArabicLanguage ? "مستند السجل التجاري لمعرف الكفيل كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" : "Firm Order document too large, allowed only less than 10 MB",
                        (file) => {
                            if (typeof (file) === "string" && file !== "") {
                                return true;
                            } else
                                if (file && file.size) {
                                    return file.size <= 10000000;
                                } else {
                                    return true;
                                }
                        }
                    )
            },),

        }

        )),
    }
    return schema;
}

export default StageSixFormSchema;