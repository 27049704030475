import React, {useState} from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, makeStyles, CircularProgress } from '@material-ui/core'


const useStyles = makeStyles({
    dialogWrapper:{
        "& [role='dialog']":{
            padding: '30px',
            borderRadius: '16px',
        }
    },
    headingStyle: {
        textAlign: 'center',
        '& h2': {
            fontWeight: 700,
        }
    },
    paraStyle:{
        textAlign: 'center',
    },
    btnWrapper:{
        justifyContent: 'center',
        '& button': {
            padding: '15px 0px',
            width: '150px',
            borderColor: '#3b8da2',
            borderRadius: '15px',
        }
    },
    primaryBtnStyle: {
      background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
      "text-transform": "none",
      "color": "white",
      borderRadius: "10px"
    },
    cancelBtnStyle: {
      background: "linear-gradient(to bottom, red, #C87674)",
      "text-transform": "none",
      "color": "white",
      borderRadius: "10px"
    },
})

type ConfirmationDialogProps = {
    openDialog:boolean,
    headingText: string,
    paragraphText: string,
    btnCancelText: string,
    btnOkText: string,
    handleOk: any;
    handleCancel:any;
    showRedButton?: boolean;
    btnLoading?:boolean;
}

const ConfirmationDialog = (props:ConfirmationDialogProps) => {
    const classes = useStyles();

  return (
    <>
        
      <Dialog
        open={props?.openDialog}
        onClose={props?.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialogWrapper}
      >
        <DialogTitle id="alert-dialog-title" className={classes.headingStyle}>{props?.headingText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"  className={classes.paraStyle}>
            {props?.paragraphText}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.btnWrapper}>
          <Button variant="outlined" style={{color: '#3b8da2',textTransform:"none"}} onClick={props?.handleCancel} color="primary">
            {props.btnCancelText}
          </Button>
          <Button variant="contained" disabled={props.btnLoading} className={props.showRedButton ? classes.cancelBtnStyle : classes.primaryBtnStyle} onClick={props?.handleOk} color="primary" autoFocus>
            {props.btnLoading ? <CircularProgress /> : props.btnOkText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ConfirmationDialog