// Customizable Area Start
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import { getStorageData } from "../../../../../../framework/src/Utilities";

export interface Props {
    id?:any;
    t?: any;
    classes?: any;
    policyId: string;
    navigation?: any;
    isExpanded?: boolean;
    isEdit?: boolean;
    onNext?:any;
    onReject?:any;
    setStageData?:any;
    handoverNote?:string;
    rrStageOneData?: any;
    rrStageSixData?: any;
    rrStageFourData:any;
    revisionRequestID:any;
}

interface S {
    policyId: string;
    isArabicLanguage: boolean;
    expandedAccordion: boolean;
    handOverNoteText: string;
    showNoteDialog: boolean;
    showRejectDialog: boolean;
    isBtnVisible: boolean;
    showAlert: boolean;
    alertMessage: string;
    alertType: string;
    hasViewPermissionList: string[];
    hasCreatePermissionList: string[];
    hasUpdatePermissionList: string[];
    disableAccordion: boolean;
    showNextDialog: boolean;
    loading: boolean;
    initialData: any;
}

interface SS {
  id: any;
}
// Customizable Area End

export default class CustomerReviewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  customerReviewTrackRequest:any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];


    this.state = {
      policyId: this.props.rrStageOneData?.data?.attributes.id || "",
      isArabicLanguage:
        (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      expandedAccordion: this.props.isExpanded || false,
      handOverNoteText: "",
      showNoteDialog: false,
      showRejectDialog: false,
      showAlert: false,
      alertMessage: "",
      alertType: "",
      hasViewPermissionList: this.props.rrStageFourData?.meta?.stage_six_view_permission_role || [],
      hasCreatePermissionList: this.props.rrStageFourData?.meta?.stage_six_create_permission_role || [],
      hasUpdatePermissionList: this.props.rrStageFourData?.meta?.stage_six_stage_update_permission_role || [],
      disableAccordion: false,
      showNextDialog: false,
      isBtnVisible: true,
      loading:false,
      initialData:this.getStageFourDataForStageSix()
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    if(this.props.rrStageFourData?.data?.attributes?.is_stage_7_submitted) this.setState({expandedAccordion: false})
  }
  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {

    if (this.state.showAlert) {
      setTimeout(() => {
        this.setState({
          showAlert: false,
          alertType: "",
          alertMessage: "",
        });
      }, 50000);
    }
  }
  apiCallCustomerReview(data: any) {
    const { contentType, method, endPoint, body } = data;
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const requestMessageForCustomerReviewApi = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForCustomerReviewApi.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageForCustomerReviewApi.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForCustomerReviewApi.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    requestMessageForCustomerReviewApi.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(requestMessageForCustomerReviewApi.id, requestMessageForCustomerReviewApi);
    return requestMessageForCustomerReviewApi.messageId;
  }

  checkStageSixCondition = () => {
      return this.state.expandedAccordion && this.props.rrStageSixData?.data?.attributes?.is_reject
  }

  trackRequest = () => {
    const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
		const token = userData.token || '';
    this.customerReviewTrackRequest = this.apiCallCustomerReview({
			contentType: 'application/json',
			method: 'POST',
			endPoint: `bx_block_revision_request/revision_requests/${this.state.policyId}/track_revision_requests?stage=6`,
			token: token || '',
			body: {
				"data": {
					"track_revision_request": {
						"stage_status":"next_stage",
						"comment": this.state.handOverNoteText,
            "current_stage": "payment_review"
					}
				}
			}
		});
  }
  trackRequestAPIReceive = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.customerReviewTrackRequest !== null &&
      this.customerReviewTrackRequest ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.props.setStageData({"handOverNoteForPaymentReview":this.state.handOverNoteText})
        this.setState({loading:false,showNoteDialog:false,showNextDialog:false})
        this.props.onNext(6)
        const  alertMessage = this.state.isArabicLanguage ? "لقد تم نقل طلبك إلى مراجعة الدفع" : "Your request has been moved to payment review!"
        this.setState({
          showAlert: true,
          alertMessage: alertMessage,
          alertType: "success",
          showNextDialog: false,
        })
      } else {
        this.setApiErrorMessageForCustomerReview(responseJson)
      }
    } else {
      this.setApiErrorMessageForCustomerReview(undefined)
    }
  }
  setApiErrorMessageForCustomerReview = (responseJson: any) => {
    if (!responseJson) return;
    let errorMessage = "Something went wrong"
    if (responseJson.errors) {
      errorMessage = typeof (responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error || "Something went wrong";
    }
    this.setState({
      alertType: "error",
      alertMessage: errorMessage,
      showAlert: true,
      showNoteDialog: false,
      showNextDialog: false
    })
  }
  receive(from: string, message: Message) {
    this.trackRequestAPIReceive(message);
  }
  getArabLangStageSix=async()=>{
    let arabData :boolean=  await getStorageData("lang") === "ar" ? true : false
    this.setState({isArabicLanguage:arabData})
}
handleAccordianStageSix =()=>{
    this.setState((prev: any) => ({
        ...prev,
        expandedAccordion: !prev.expandedAccordion,

      }))
      this.setState({initialData:this.getStageFourDataForStageSix()})
}
getStageFourDataForStageSix =()=>{
  if (this.props.rrStageFourData?.data) {
    let stageSixPrefill: any = {
      revision_number: this.props.rrStageFourData.data.attributes.revision_number,
      revision_date: this.props.rrStageFourData.data.attributes.revision_date,
      policy_impact: this.props.rrStageFourData.data.attributes.policy_impact,
      revision_confirmation: this.props.rrStageFourData.data.attributes.revision_confirmation,
      comment: this.props.rrStageFourData?.data?.attributes?.comment,
      credit_note_number: "",
      credit_note_date: "",
      credit_note_amount: "",
      revision_credit_note: "",
      invoice_date:"",
      invoice_number: "",
      invoice_amount: "",
      new_policy_value: this.props.rrStageFourData.data.attributes.new_policy_value,
      revision_invoice: ""
    }

    if (this.props.rrStageFourData?.data?.attributes?.policy_impact == "up") {
      stageSixPrefill = {
        ...stageSixPrefill,
        invoice_number: this.props.rrStageFourData.data.attributes.revision_invoice.data?.attributes.invoice_number,
        invoice_date: this.props.rrStageFourData.data.attributes.revision_invoice.data?.attributes.invoice_date,
        invoice_amount: this.props.rrStageFourData.data.attributes.revision_invoice.data?.attributes.invoice_amount,
        new_policy_value: this.props.rrStageFourData.data.attributes.new_policy_value,
        revision_invoice: this.props.rrStageFourData.data.attributes.revision_invoice.data?.attributes.revision_invoice,

      }
    } else if (this.props.rrStageFourData?.data?.attributes?.policy_impact == "down") {
      stageSixPrefill = {
        ...stageSixPrefill,
        credit_note_number: this.props.rrStageFourData.data.attributes.revision_credit_note.data.attributes.credit_note_number,
        credit_note_date: this.props.rrStageFourData.data.attributes.revision_credit_note.data.attributes.credit_note_date,
        credit_note_amount: this.props.rrStageFourData.data.attributes.revision_credit_note.data.attributes.credit_note_amount,
        revision_credit_note: this.props.rrStageFourData.data.attributes.revision_credit_note.data.attributes.revision_credit_note,
      }

    }
    return stageSixPrefill;
  }
 }
  // Customizable Area End
}
