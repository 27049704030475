// Customizable Area Start
import React from 'react';
import { 
  Container,
} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { withTranslation } from "react-i18next";

import PolicyOverviewController from './PolicyOverviewController'
import PolicyOverviewForm from './PolicyOverviewForm'

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  },
});

export class PolicyOverview extends PolicyOverviewController {

  render() {
    return (
      <div data-test-id="PolicyOverview">
        <ThemeProvider theme={theme}>
          <Container
            id="customerContainer"
            maxWidth="lg"
            dir={
              (window.localStorage.getItem("lang") || "en") === "ar"
                ? "rtl"
                : "ltr"
            }
          >
            {/* @ts-ignore */}
            <PolicyOverviewForm {...this.props} />
          </Container>
        </ThemeProvider>
      </div>
    )
  }
}

export default withTranslation()(PolicyOverview);
// Customizable Area End
