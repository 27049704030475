import * as Yup from 'yup';
// import { emptyStringToUndefined } from '../Common/EmptyStringToUndefined';

const StageFiveFormSchema = (isArabicLanguage: boolean) => {
    const schema = {
        // quotation_received: Yup.string().required(isArabicLanguage ? 'Please select quotation recived.' : "Please select quotation recived."),

        // quotation_amount: Yup.string().required(isArabicLanguage ? 'مبلغ الاقتباس مطلوب.' : "Quotation Amount is required."),
        // proposal: Yup.string().required(),
        // comment: Yup.string()
        //     .min(4, !isArabicLanguage ? "Comment is too short" : "اسم العميل قصير جدا")
        //     .max(250, !isArabicLanguage ? "Comment is too Long" : "اسم العميل طويل جدًا").required(isArabicLanguage ? 'الحالة مطلوبة' : "Comment is required."),
        note_to_the_customer: Yup.string().required(isArabicLanguage ? 'ملاحظة للعميل مطلوبة.' : "Note to the customer is a required."),
            // .min(4, !isArabicLanguage ? "Comment is too short" : "اسم العميل قصير جدا")
            // .max(250, !isArabicLanguage ? "Comment is too Long" : "اسم العميل طويل جدًا").required(isArabicLanguage ? 'التعليق مطلوب.' : "Comment is required."),
        // additional_attachment: Yup.boolean().required(isArabicLanguage ? 'مطلوب مرفق إضافي' : "Additional Attachment is required."),
        additional_attachment: Yup.mixed().test( "isNotEmpty", isArabicLanguage ? 'مطلوب مرفق إضافي' : "Additional Attachment is required.",
            function (value) {
                const document = this.resolve(Yup.ref("additional_attachment"));
                if ((typeof document === "string" && document === "" ) || (document === undefined)) {
                    return false;
                } else if (
                    document &&
                    typeof (document) === "object" &&
                    Object.keys(document).length===0
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        ).test(
            "fileSize",
            isArabicLanguage ? "المرفق الإضافي كبير جدًا ، ولا يُسمح إلا بأقل من 10 ميغابايت" :"Additional Attachment too large, allowed only less than 10 MB",
            (file) => {
                if (typeof (file) === "string" && file !== "") {
                    return true;
                } else
                    if (file && file.size) {
                        return file.size <= 10000000;
                    } else {
                        return true;
                    }
            }
        ),
        // .test("FILE_SIZE", "Uploaded file is too big.", 
        //     value => !value || (value && value?.size <= 10))
        // .test("FILE_FORMAT", "Uploaded file has unsupported format.", 
        // value => !value), // || (value && SUPPORTED_FORMATS.includes(value.type))
        // tawuniya_release_quotation_to_customer: Yup.string().required(isArabicLanguage ? 'اسم العميل مطلوب' : "Please select release quotation to customer."),

    }
    return schema;
}

export default StageFiveFormSchema;