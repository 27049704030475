// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import ActiveLanguage from "../../../../components/src/Common/ActiveLanguage";

export const configJSON = require("../config");
export interface Props {
    isExpanded?: boolean
    t?: any;
    classes?: any;
    setFieldValue?: any;
    values?: any;
    errors?: any;
    touched?: any;
    formikRef?: any;
    flowValue?: number;
    installmentData?: any;
    removeInstallmentData?: any;
    paymentRecipient?: string;
    installmentIndex?: number;
    onNext?: any;
    onReject?: any;
    stageFourData?: any;
    stageSixData?: any;
    stageSevenData?: any
    isEdit?: any
    dataIndex?: number
    title?: string;
    showRefundProps?: boolean;
    checkStepProps?: any;
    updateDocProps?: any;
    updateFlow?: any;
    dummyData?: any;
    checkDisable?: boolean;
}

interface S {
    approvedStageSix: any;
    policyId: string | number;
    showAlert: boolean;
    alertMessage: string;
    alertType: string;
    expandedAccordion: boolean;
    stageData?: any;
    errorSection?: string;
    role: string,
    viewPermission: any;
    isNextStageSubmitted: boolean;
    flowZeroData: any,
    flowOneData: any,
    flowTwoData: any
    flowThreeData: any
    flowFourData: any
    flowFiveData: any
    flowSixData: any
    flowSevenData: any
    flowEightData: any
    flowNineData?: any;
    flow: number;
    paid_amount: string,
    deduct_commission: string,
    payment_reciepent: string,
    showDialog: boolean,
    showRejectDialog: boolean,
    rejectReasonText: string,
    selectedStepFromReject: any,
    isReject: boolean,
    isNextStageSbmitted: boolean
    stageSixData: any,
    stageSevenData: any,
    isDraftAPIUsed: boolean,
    showNoteDialog: boolean,
    isBtnVisible: boolean,
    handOverNoteText?: string;
    showBalance?: boolean;
    showRefund?: boolean;
    showBalancebeforeRefund?:boolean;
    formPartOne?: boolean;
    formPartTwo?: boolean;
    formPartThree?: boolean;
    formPartFour?: boolean;
    showPaidTo?: boolean;
    showRevenue?: boolean;
    showInstallment?: boolean;
    voucherBtnLoading: boolean;
    siibCurrentInstallmentIndex: any;
    voucherBtnLoadingForRevisedPayment: boolean;
    revisedVoucherIndex: any;
    invAndRcptBtnLoadingForInvoice: boolean;
    voucherInsuranceBtnLoading:any;
    installmentIndex?: number;
    invAndRcptBtnLoadingForReceipt: boolean;
    isArabicLanguage: boolean
}

interface SS {
    id: any;
}
export default class StageSevenController extends BlockComponent<
    Props,
    S,
    SS
> {
    uploadFileApiCall: any = "";
    stageSevenGetAPIKey: string = "";
    createStageSevenAPIKey: string = '';
    updateStageSevenAPIKey: string = '';
    stageSevenTrackRequestAPI: string = '';
    stageSevenFileUploadAPIResponseKey: string = "";
    removeFileAPI:string="";
    createPaymentVoucherApi: any = "";
    createRevisedPaymentVoucherApi: any = "";
    generateRevisedPaymentVoucherForInsurerApi:any = "";
    formikRef: any = '';
    fieldName: string = ''
    stageSixGetAPIKey: string = "";
    generateReceiptApi: string = "";
    generateInvoiceApi: string = "";
    createPaymentVoucherForFlow5And6Api: string = "";
    generateVoucherForSiibInstallmentApi: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];
        this.state = {
            approvedStageSix: [{
                attributes: {
                    insurance_company_id: { short_name: "" },
                    quotation_amount: "0"
                }
            }],

    isArabicLanguage:
        (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
            showNoteDialog: false,
            isBtnVisible: true,
            handOverNoteText: '',
            policyId: "",
            isReject: false,
            showDialog: false,
            showAlert: false,
            alertMessage: "",
            alertType: "",
            isNextStageSubmitted: false,
            stageData: {},
            flowZeroData: {},
            flowOneData: {
                payment_date: "",
                insure_recipent_number: "",
                policy_release_requested: "no",
                proof_of_payment_doc: ""
            },
            flowTwoData: {
                payment_date: "",
                insure_recipent_number: "",
                customer_receipt_number: "",
                customer_payment_date: "",
                refund_amount_to_customer: "",
                create_revised_payment_voucher_id: '',
                policy_release_requested: "no",
                proof_of_payment_doc: "",
                revised_proof_of_payment_doc:"",
                revised_proof_of_payment_doc_id:"",
                revised_voucher_proof_of_payment_doc_0: ''
            },
            flowThreeData: {
                payment_recipient: '',
                siib_receipt_number: '',
                payment_date: '',
                proof_of_payment_doc: '',
                payment_date_2: '', // not required
                payment_voucher: '', // not required
                payment_voucher_id: '', // not required
                insurance_receipt_number: '',
                voucher_payment_date: '',
                voucher_proof_of_payment_doc: '',
                policy_release_requested: 'no',
                voucher_id_edit: ''
            },
            flowFourData: {
                payment_recipient: '',
                siib_receipt_number: '',
                payment_date: '',
                proof_of_payment_doc: '',
                payment_date_2: '', // not required
                payment_voucher: '', // not required
                payment_voucher_id: '', // not required
                insurance_receipt_number: '',
                voucher_payment_date: '',
                voucher_proof_of_payment_doc: '',
                policy_release_requested: 'no',
                revised_payment_voucher_0: '',
                revised_payment_voucher_id_0: '', //revised_insurance_receipt_number_0
                revised_insurance_receipt_number_0: '', //revised_insurance_receipt_number_0
                revised_voucher_payment_date_0: '',
                revised_voucher_proof_of_payment_doc_0: '',

                voucher_id_insurer: '',
                voucher_id_customer: ''
            },

            flowFiveData: {
                insurance_company_id: "",
                save_as_draft: '',
                balance: 0,
                vat_on_siib_commission: '',
                commission_rate: '',
                commission_amount: '',
                invoice_date: '',
                siib_invoice_id: '',
                invoice_doc: '',

                receipt_date: '',
                siib_receipt_id: '',
                receipt_doc: '',

                payable_to_insurer: '',
                payment_date_2: '',

                payment_voucher_doc: '',
                payment_voucher_id: '',
                insurance_receipt_number: '',
                payment_date: '',
                voucher_payment_date: '',
                voucher_proof_of_payment_doc: '',

                policy_release_requested: 'no',

                payment_voucher: '',
                commission: '',
                reciept: '',
            },
            flowSixData: {
                insurance_company_id: "",
                save_as_draft: '',
                balance: 0,
                vat_on_siib_commission: '',
                commission_rate: '',
                commission_amount: '',
                invoice_date: '',
                siib_invoice_id: '',
                invoice_doc: '',

                receipt_date: '',
                siib_receipt_id: '',
                receipt_doc: '',

                payable_to_insurer: '',
                payment_date_2: '',

                payment_voucher_doc: '',
                payment_voucher_id: '',
                insurance_receipt_number: '',
                voucher_payment_date: '',
                voucher_proof_of_payment_doc: '',
                policy_release_requested: 'no',

                revised_payment_voucher_0: '',
                revised_payment_voucher_id_0: '',
                revised_insurance_receipt_number_0: '',
                revised_voucher_payment_date_0: '',
                revised_voucher_proof_of_payment_doc_0: '',

                revised_payment_voucher_1: '',
                revised_payment_voucher_id_1: '',
                revised_insurance_receipt_number_1: '',
                revised_voucher_payment_date_1: '',
                revised_voucher_proof_of_payment_doc_1: '',
                revised_payment_voucher_edit_id: '',
                revised_payment_voucher_edit_id_2: '',
            },
            flowSevenData: {
                insure_recipent_number: "",
                payment_date: "",
                proof_of_payment_doc: "",
                policy_release_requested: "no",
                installment_attributes: [
                    {
                        installment_amount: "",
                        due_date: "",
                        installment_paid: "no",
                        insurance_receipt_number: "",
                        payment_date_2: "",
                        proof_of_payment_doc: "",
                        id: '',
                        showInstallmentAmount: false,

                    }
                ],
                balance: ""
            },
            flowEightData: {
                siib_receipt_number: "",
                payment_date: "",
                proof_of_payment_doc: "",
                insurance_receipt_number: "",
                voucher_payment_date: "",
                voucher_proof_of_payment_doc: "",
                policy_release_requested: "no",
                payment_date_2:"",
                payment_voucher: '',
                payment_voucher_id: '',
                installment_attributes: [
                    {
                        id: '',
                        installment_amount: "",
                        due_date: "",
                        installment_paid: "no",
                        siib_receipt_number: "",
                        payment_date: "",
                        proof_of_payment_doc: "",
                        insurer_receipt_number: "",
                        payment_date_2: "",
                        voucher_proof_of_payment_doc: "",
                        showInstallmentAmount: false,
                        payment_voucher_id:"",
                        payment_voucher:"",
                    }
                ],
                balance: ""
            },
            flowNineData: {

                commission_rate: "",
                commission_amount: "",
                vat_on_siib_commission: "",
                invoice_doc: "",
                receipt_doc: "",
                invoice_date: "",
                receipt_date: "",
                siib_invoice_id: "",
                siib_receipt_id: "",
                payable_to_insurer: "",
                payment_voucher: '',
                payment_voucher_id: '',
                insurance_receipt_number: "",
                voucher_payment_date: "",
                voucher_proof_of_payment_doc: "",
                policy_release_requested: "no",
                installment_attributes: [{
                    id: '',
                    installment_amount: "",
                    due_date: "",
                    installment_paid: "no",
                    commission_rate: "",
                    commission_amount: "",
                    vat_on_siib_commission: "",
                    invoice_doc: "",
                    receipt_doc: "",
                    invoice_date: "",
                    receipt_date: "",
                    siib_invoice_id: "",
                    siib_receipt_id: "",
                    voucher_proof_of_payment_doc: "",
                    payable_to_insurer: "",
                    voucher_payment_date: "",
                    payment_voucher_id:"",
                    payment_voucher:"",
                    insurer_receipt_number: "",
                    proof_of_payment_doc: "",
                    showInstallmentAmount: false

                }],
                balance: ""
            },

            errorSection: "",
            expandedAccordion: false,
            role: "",
            viewPermission: [],
            siibCurrentInstallmentIndex:0,
            flow: 0,
            paid_amount: "",
            deduct_commission: "",
            payment_reciepent: "",
            showRejectDialog: false,
            rejectReasonText: "",
            selectedStepFromReject: "",
            stageSixData: JSON.parse(localStorage.getItem('stageSixData') || '{}'),
            isNextStageSbmitted: false,
            isDraftAPIUsed: false,
            stageSevenData: JSON.parse(localStorage.getItem('stageSevenData') || '{}'),
            showBalance: false,
            showRefund: false,
            showPaidTo: false,
            formPartOne: false,
            formPartTwo: false,
            formPartThree: false,
            formPartFour: false,
            showRevenue: false,
            showInstallment: false,
            voucherBtnLoading: false,
            voucherBtnLoadingForRevisedPayment: false,
            revisedVoucherIndex: 0,
            invAndRcptBtnLoadingForInvoice: false,
            voucherInsuranceBtnLoading:false,
            invAndRcptBtnLoadingForReceipt: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        window.localStorage.removeItem('flowFromAPI');
        const loginData = JSON.parse(localStorage.getItem('loginData') ?? '{}');
        const stagePartOneData = JSON.parse(localStorage.getItem('stageOne') ?? '{}');
        const queryParameter: any = new URLSearchParams(window.location.search);
        const policyId = queryParameter?.get('id') ? queryParameter?.get('id') : stagePartOneData.policyId;
        const data = JSON.parse(window.localStorage.getItem('stageSevenData') || '{}')
        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') || '{}')

        const flow = parseInt(data?.attributes?.flow || '-1')
        window.localStorage.setItem('flowFromAPI', JSON.stringify(flow))
        if (flow > 0) this.setState({ flow: flow })
        this.setState({ policyId: policyId })

        if (loginData) {
            this.setState({ role: loginData.role })
        }
        /* istanbul ignore if */
        if (stageSixData?.attributes?.is_next_stage_submitted && policyId) {
       
                    this.setState({formPartOne:true,
                        formPartTwo: true,
                        formPartThree: true,
                        formPartFour: true})
      
            switch (flow) {
                case 1:
                    this.setFlowOneData(data)
                    break;
                case 2:
                    this.setFlowTwoData(data)
                    break;
                case 3:
                    this.setFlowThreeData(data)
                    break;
                case 4:
                    this.setFlowFourData(data)
                    break;
                case 5:
                    this.setFlowFiveData(data)
                    break;
                case 6:
                    this.setFlowSixData(data)
                    break;
                case 7:
                    this.setFlowSevenData(data)
                    break;
                case 8:
                    this.setFlowEightData(data)
                    break;
                case 9:
                    this.setFlowNineData(data)
                    break;
                default:
                    break;
            }
        }
        this.setState({viewPermission: stageSixData?.meta.next_stage_view_permission_role || []});
    }

    getSuffix(value: number) {
        const lastDigit = value % 10;
        const numberArr = [11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
        /* istanbul ignore if */if (numberArr.includes(value)) return "th";
        else if (lastDigit === 1) return "st";
        else if (lastDigit === 2) return "nd";
        else if (lastDigit === 3) return "rd";
        else return "th";
    }

    moveToStep(step: any) {
        this.setState({ selectedStepFromReject: step.value })
    }
    openRejectDialog = () => {
        this.setState({ showRejectDialog: true })
    }
    handleRejectReasonText = (inputText: string) => {
        this.setState({ rejectReasonText: inputText })
    }
    handleHandOverNoteText = (inputText: string) => {
        this.setState({ handOverNoteText: inputText })
    }
    closeNoteDialog = () => {
        this.setState({ showNoteDialog: false,handOverNoteText:"", isBtnVisible: true })
    }
    openNoteDialog = () => {
        this.setState({ showNoteDialog: true, isBtnVisible: false })
    }
    closeRejectDialog = () => {
        this.setState({ showRejectDialog: false, isBtnVisible: true })
    }

    successCloseRejectDialog = async () => {
        this.closeRejectDialog();
        this.trackRequest(this.state.policyId, 'reject_stage', this.state.rejectReasonText, this.state.selectedStepFromReject);

    }
    humanize(str: string) {
        let i, frags = str.split('_');
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }

    convertToWord(number: number): string {
        const wordMap: { [key: number]: string } = {
            1: "One",
            2: "Two",
            3: "Three",
            4: "Four",
            5: "Five",
            6: "Six",
            7: "Seven",
            8: "Eight",
            9: "Nine",
        };

        return wordMap[number];
    }
    checkStageSevenForm = (errorsData: any) => {
        if (Object.keys(errorsData).length === 0) {
            this.setState({ showDialog: true, errorSection: "" })
        } else {
            let errorSection = "";
            const keysValue = Object.keys(errorsData);

            const firstKeyValue = keysValue[0];

            let errorValue = ""
            this.checkFormConditionstageSeven(errorsData, firstKeyValue, errorSection, errorValue)

        }
    }
    checkFormConditionstageSeven = (errors: any, firstKeyValue: any, errorSection: string, errorValue: string) => {
        if ((firstKeyValue === "stageData") || (firstKeyValue === "branchDetail")) {
            const obj = (firstKeyValue === "stageData") ? errors.stageData : errors.branchDetail;
            const firstValue = Object.values(obj[`flow${this.convertToWord(this.state.flow)}Data`])[0] as string
            errorValue = this.checkFirstValue(firstValue) as string

            this.setState({
                showAlert: true,
                alertMessage: errorValue,
                alertType: "error",
                errorSection: errorSection
            })
            errorSection = firstKeyValue === "stageData" ? "p4" : "p2"
        }

    }
    checkFirstValue = (firstValue: any) => {
        if (typeof (firstValue) === "string") {
            return firstValue
        }
        else {

            let array = firstValue
            for (const element of array) {
                if ((element !== null) && (element !== undefined) && typeof (element === 'object')) {
                    const obj = element;
                    const msg = Object.values(obj)[0] as string
                    return msg
                }
            }
        }
    }
    checkStep = (values: any, flow: number) => {
        switch (flow) {

            case 1: this.stepbystepFlowOne(values);
                break;
            case 2: this.stepbystepFlowTwo(values);
                break;
            case 3: this.stepbystepFlowThree(values);
                break;
            case 4: this.stepbystepFlowFour(values);
                break;
            case 5: this.stepbystepFlowFive(values);
                break;
            case 6: this.stepbystepFlowSix(values);
                break;
            case 7: this.stepbystepFlowSeven(values);
                break;
            case 8: this.stepbystepFlowEight(values);
                break;
            case 9: this.stepbystepFlowNine(values);
                break;
        }

    }
    stepbystepFlowOne = (values: any) => {
        if (values.stageData.flowOneData.payment_date 
            && values.stageData.flowOneData.insure_recipent_number 
            && values.stageData.flowOneData.proof_of_payment_doc ) {
    
                this.setState({ showPaidTo: true, showBalance: true })
            }
        else {

            this.setState({

                showBalance: false,
                showRefund: false,
                showPaidTo: false,
                formPartOne: false,
                formPartTwo: false,
                formPartThree: false,
                formPartFour: false,
                showRevenue: false,
                showInstallment: false,
            })

        }
    }
    stepbystepFlowTwo = (values: any) => {
        if (values?.stageData.flowTwoData?.payment_date  && values?.stageData.flowTwoData?.insure_recipent_number && values?.stageData.flowTwoData?.proof_of_payment_doc) {
            this.setState({ formPartOne: true, showPaidTo: true,showBalance:true })
            if (values.stageData.flowTwoData?.customer_receipt_number &&values.stageData.flowTwoData?.customer_payment_date && values.stageData.flowTwoData?.revised_voucher_proof_of_payment_doc_0)
                this.setState({ showRefund: true,showBalance:true })
        }
        else {

            this.setState({

                showBalance: false,
                showRefund: false,
                showPaidTo: false,
                formPartOne: false,
                formPartTwo: false,
                formPartThree: false,
                formPartFour: false,
                showRevenue: false,
            })

        }
    }
    stepbystepFlowThree = (values: any) => {
        if (

            values?.stageData?.flowThreeData?.siib_receipt_number &&
            values?.stageData?.flowThreeData?.payment_date  &&
            values?.stageData?.flowThreeData?.proof_of_payment_doc  &&
            values?.stageData?.flowThreeData?.insurance_receipt_number  &&
            values?.stageData?.flowThreeData?.voucher_payment_date  &&
            values?.stageData?.flowThreeData?.voucher_proof_of_payment_doc

        ) {

            this.setState({ showPaidTo: true, showBalance: true })
        }
        else {

            this.setState({

                showBalance: false,
                showRefund: false,
                showPaidTo: false,
                formPartOne: false,
                formPartTwo: false,
                formPartThree: false,
                formPartFour: false,
                showRevenue: false,
            })

        }

    }
    stepbystepFlowFour = (values: any) => {
        if (

            values?.stageData?.flowFourData?.siib_receipt_number&&
            values?.stageData?.flowFourData?.payment_date  &&
            values?.stageData?.flowFourData?.proof_of_payment_doc &&
            values?.stageData?.flowFourData?.insurance_receipt_number &&
            values?.stageData?.flowFourData?.voucher_payment_date  &&
            values?.stageData?.flowFourData?.voucher_proof_of_payment_doc
        ) {

            this.setState({ formPartTwo: true, showPaidTo: true, showBalance: true })

            values?.stageData?.flowFourData?.revised_insurance_receipt_number_0  &&
                values?.stageData?.flowFourData?.revised_voucher_payment_date_0  &&
                values?.stageData?.flowFourData?.revised_voucher_proof_of_payment_doc_0 ? this.setState({ showRefund: true }) : this.setState({ showRefund: false })
        }
        else {

            this.setState({

                showBalance: false,
                showRefund: false,
                showPaidTo: false,
                formPartOne: false,
                formPartTwo: false,
                formPartThree: false,
                formPartFour: false,
                showRevenue: false,
            })

        }

    }
    stepbystepFlowFive = (values: any) => {
        if (values?.stageData?.flowFiveData?.commission_rate  &&
            values?.stageData?.flowFiveData?.vat_on_siib_commission &&
            values?.stageData?.flowFiveData?.invoice_date  &&
            values?.stageData?.flowFiveData?.receipt_date
        ) {

            this.setState({ showRevenue: true, showBalance: true })
            if (
                values?.stageData?.flowFiveData?.insurance_receipt_number &&
                values?.stageData?.flowFiveData?.voucher_payment_date&&
                values?.stageData?.flowFiveData?.voucher_proof_of_payment_doc
            ) { this.setState({ showBalance: true, showPaidTo: true }) }
            else {
                this.setState({ showPaidTo: false })

            }

        }
        else {

            this.setState({

                showBalance: false,
                showRefund: false,
                showPaidTo: false,
                formPartOne: false,
                formPartTwo: false,
                formPartThree: false,
                formPartFour: false,
                showRevenue: false,
            })

        }
    }
    stepbystepFlowSix = (values: any) => {
        if (

            values?.stageData?.flowSixData?.vat_on_siib_commission &&
            values?.stageData?.flowSixData?.commission_rate &&
            values?.stageData?.flowSixData?.invoice_date &&
            values?.stageData?.flowSixData?.receipt_date


        ) {
            this.setState({ showRevenue: true, showBalance: true })
            if(values?.stageData?.flowSixData?.payment_date_2&&
               values?.stageData?.flowSixData?.payment_voucher_id&&
               values?.stageData?.flowSixData?.payment_voucher_doc){
                 this.setState({showPaidTo:true,showBalancebeforeRefund:true,showBalance:true})
             }
            if (
                values?.stageData?.flowSixData?.insurance_receipt_number &&
                values?.stageData?.flowSixData?.voucher_payment_date &&
                values?.stageData?.flowSixData?.payment_date_2&&
                values?.stageData?.flowSixData?.voucher_proof_of_payment_doc
            ) {
                
                this.setState({ showBalance: true, showPaidTo: true, formPartTwo: true })

                if (values?.stageData?.flowSixData?.revised_insurance_receipt_number_0  &&
                    values?.stageData?.flowSixData?.revised_voucher_payment_date_0  &&
                    values?.stageData?.flowSixData?.revised_voucher_proof_of_payment_doc_0 &&
                    values?.stageData?.flowSixData?.revised_insurance_receipt_number_1  &&
                    values?.stageData?.flowSixData?.revised_voucher_payment_date_1
                ) {
                    this.setState({ showRefund: true,showBalancebeforeRefund:false })
                } else {
                    this.setState({ showRefund: false })
                }
            }


            else {
                this.setState({ showPaidTo: false, formPartTwo: false })

            }



        } else {
            this.setState({

                showBalance: false,
                showRefund: false,
                showPaidTo: false,
                formPartOne: false,
                formPartTwo: false,
                formPartThree: false,
                formPartFour: false,
                showRevenue: false,
                showInstallment: false,
            })
        }

    }
    stepbystepFlowSeven = (values: any) => {


        if (values?.stageData.flowSevenData?.payment_date  && values?.stageData.flowSevenData?.insure_recipent_number  && values?.stageData.flowSevenData?.proof_of_payment_doc ) {

            this.setState({ showInstallment: true, showBalance: true })

            values?.stageData.flowSevenData?.installment_attributes.map((item: any, index: number) => {
                if (
                    item.installment_amount  &&
                    item.due_date  &&
                    item.installment_paid &&
                    item.insurance_receipt_number &&
                    item.payment_date_2  &&
                    item.proof_of_payment_doc
                ) {


                    this.formikRef.current?.setFieldValue(`stageData.flowSevenData.installment_attributes[${index}].showInstallmentAmount`, true)
                    !item.payment_voucher_id && this.formikRef.current?.setFieldValue(`stageData.flowSevenData.installment_attributes[${index}].payment_voucher`, "")
                    !item.payment_voucher_id && this.formikRef.current?.setFieldValue(`stageData.flowSevenData.installment_attributes[${index}].payment_voucher_id`, "")
                } else {
                    this.formikRef.current?.setFieldValue(`stageData.flowSevenData.installment_attributes[${index}].showInstallmentAmount`, false)
                }
            })
        }

        else {

            this.setState({

                showBalance: false,
                showRefund: false,
                showPaidTo: false,
                formPartOne: false,
                formPartTwo: false,
                formPartThree: false,
                formPartFour: false,
                showRevenue: false,
                showInstallment: false
            })

        }
    }
    stepbystepFlowEight = (values: any) => {
        if (

            values?.stageData?.flowEightData?.siib_receipt_number &&
            values?.stageData?.flowEightData?.payment_date &&
            values?.stageData?.flowEightData?.proof_of_payment_doc &&
            values?.stageData?.flowEightData?.insurance_receipt_number &&
            values?.stageData?.flowEightData?.voucher_payment_date &&
            values?.stageData?.flowEightData?.voucher_proof_of_payment_doc
        ) {

            this.setState({ showInstallment: true, showBalance: true, showRevenue: true })

            values?.stageData.flowEightData?.installment_attributes.map((item: any, index: number) => {
                if (
                    item.installment_amount &&
                    item.due_date &&
                    item.installment_paid &&
                    item.siib_receipt_number &&
                    item.payment_date &&
                    item.proof_of_payment_doc &&
                    item.insurer_receipt_number &&
                    item.payment_date_2 &&
                    item.voucher_proof_of_payment_doc

                ) {


                    this.formikRef.current?.setFieldValue(`stageData.flowEightData.installment_attributes[${index}].showInstallmentAmount`, true)
                    !item.payment_voucher_id && this.formikRef.current?.setFieldValue(`stageData.flowEightData.installment_attributes[${index}].payment_voucher`, "")
                    !item.payment_voucher_id && this.formikRef.current?.setFieldValue(`stageData.flowEightData.installment_attributes[${index}].payment_voucher_id`, "")
                } else {
                    this.formikRef.current?.setFieldValue(`stageData.flowEightData.installment_attributes[${index}].showInstallmentAmount`, false)
                }
            })
        }



        else {

            this.setState({


                showRefund: false,
                showPaidTo: false,
                formPartOne: false,
                formPartTwo: false,
                formPartThree: false,
                formPartFour: false,
                showRevenue: false,
                showBalance: false
            })

        }

    }
    stepbystepFlowNine = (values: any) => {
        if (

            values?.stageData?.flowNineData?.vat_on_siib_commission &&
            values?.stageData?.flowNineData?.commission_rate &&
            values?.stageData?.flowNineData?.invoice_date &&
            values?.stageData?.flowNineData?.receipt_date &&
            values?.stageData?.flowNineData?.voucher_proof_of_payment_doc &&
            values?.stageData?.flowNineData?.voucher_payment_date &&
            values?.stageData?.flowNineData?.insurance_receipt_number
        ) {
            this.setState({ showRevenue: true, showBalance: true, showInstallment: true })

            values?.stageData.flowNineData?.installment_attributes.map((item: any, index: number) => {
                if (
                    item.installment_amount &&
                    item.due_date &&
                    item.installment_paid &&
                    item.commission_rate &&
                    item.commission_amount &&
                    item.vat_on_siib_commission &&

                    item.invoice_date &&
                    item.receipt_date &&

                    item.payable_to_insurer &&
                    item.voucher_payment_date &&
                    item.insurer_receipt_number &&
                    item.proof_of_payment_doc
                ) {


                    this.formikRef.current?.setFieldValue(`stageData.flowNineData.installment_attributes[${index}].showInstallmentAmount`, true)
                    !item.payment_voucher_id && this.formikRef.current?.setFieldValue(`stageData.flowNineData.installment_attributes[${index}].payment_voucher`, "")
                    !item.payment_voucher_id && this.formikRef.current?.setFieldValue(`stageData.flowNineData.installment_attributes[${index}].payment_voucher_id`, "")
                } else {

                    this.formikRef.current?.setFieldValue(`stageData.flowNineData.installment_attributes[${index}].showInstallmentAmount`, false)
                }
            })


        } else {
            this.setState({


                showRefund: false,
                showPaidTo: false,
                formPartOne: false,
                formPartTwo: false,
                formPartThree: false,
                formPartFour: false,
                showRevenue: false,
                showInstallment: false,
                showBalance: false
            })
        }

    }
    setFlowOneData = (data: any) => {
        this.setState({
            showPaidTo: true,
            showBalance: true,
            paid_amount: data?.attributes.payment_insurance_infos.data.attributes.paid_amount,
            payment_reciepent: data?.attributes.payment_insurance_infos.data.attributes.payment_recipient,
            flowOneData: {
                payment_date: data?.attributes.payment_insurance_infos.data.attributes.payment_date,
                insure_recipent_number: data?.attributes?.payment_insurance_infos?.data?.attributes?.insurance_receipt_number,
                policy_release_requested: data.attributes.payment_insurance_infos.data.attributes.policy_release_requested ? "yes" : "no",
              /* istanbul ignore next*/  proof_of_payment_doc: data?.attributes?.payment_insurance_infos?.data.attributes?.proof_of_payment,

            }
        },()=>{
            const values= this.updateInitialValues()
            this.checkStep(values,this.props.flowValue as number)
         })
    }

    setFlowTwoData = (data: any) => {
        const { payment_insurance_infos, payment_voucher } = data?.attributes;
        const payment_insurance_infos_attributes=payment_insurance_infos?.data?.attributes
        const payment_voucher_attributes=payment_voucher?.data[0]?.attributes
        this.setState({
            paid_amount: payment_insurance_infos_attributes?.paid_amount,
            payment_reciepent: payment_insurance_infos_attributes?.payment_recipient,
            flowTwoData: {
                payment_date: payment_insurance_infos_attributes?.payment_date,
                insure_recipent_number: payment_insurance_infos_attributes?.insurance_receipt_number,
               /* istanbul ignore next*/ policy_release_requested: payment_insurance_infos_attributes?.policy_release_requested ? "yes" : "no",
                customer_payment_date: payment_voucher_attributes?.payment_date,
               /* istanbul ignore next*/ proof_of_payment_doc: payment_insurance_infos_attributes?.proof_of_payment,
                // refund_amount_to_customer: payment_insurance_infos?.data?.attributes?.refund_payment_to_customer,
                customer_receipt_number: payment_voucher_attributes?.customer_receipt_number,
              /* istanbul ignore next*/  create_revised_payment_voucher_id: payment_voucher?.data[0]?.id,
                revised_voucher_proof_of_payment_doc_0: payment_voucher_attributes?.proof_of_payment_refund_to_cust,
                revised_proof_of_payment_doc: payment_voucher_attributes?.payment_voucher,
                revised_proof_of_payment_doc_id: payment_voucher_attributes?.payment_voucher_id,
            }
        },()=>{
           const values= this.updateInitialValues()
           this.checkStep(values,this.props.flowValue as number)
        })
    }
    /* istanbul ignore next*/
    setFlowThreeData = (data: any) => {
        const { payment_insurance_infos, payment_voucher: payment_voucher_api } = data.attributes;
        this.setState({
            paid_amount: payment_insurance_infos.data.attributes.paid_amount,
            deduct_commission: payment_insurance_infos.data.attributes.deduct_commission_from_payment,
            payment_reciepent: payment_insurance_infos.data.attributes.payment_recipient,
            flowThreeData: {
                siib_receipt_number: payment_insurance_infos.data.attributes.siib_receipt_number,
                payment_date: payment_insurance_infos.data.attributes.payment_date,
                proof_of_payment_doc: payment_insurance_infos?.data.attributes?.proof_of_payment,
                payment_date_2: payment_voucher_api?.data[0]?.attributes.payment_voucher_date,
                payment_voucher: payment_voucher_api?.data[0]?.attributes.payment_voucher,
                voucher_id_insurer: payment_voucher_api?.data[0]?.id,
                payment_voucher_id: payment_voucher_api?.data[0]?.attributes.payment_voucher_id,
                insurance_receipt_number: payment_voucher_api?.data[0]?.attributes.insurer_recipient_number,
                voucher_payment_date: payment_voucher_api.data[0]?.attributes.payment_date,
                voucher_proof_of_payment_doc:
                    payment_voucher_api?.data[0]?.attributes?.proof_of_payment_to_insurer,
                policy_release_requested: payment_insurance_infos.data.attributes.policy_release_requested ? 'yes' : 'no',
                voucher_id_edit: payment_voucher_api?.data[0]?.id
            }
        },()=>{
            const values= this.updateInitialValues()
            this.checkStep(values,this.props.flowValue as number)
         })
    }

    /* istanbul ignore next*/
    setFlowFourData = (data: any) => {
        const { attributes } = data;
        const { payment_insurance_infos, payment_voucher: payment_voucher_api } = attributes;
        const voucherSection = payment_voucher_api?.data?.find((item: any) => item.attributes.voucher_to === 'insurer')
        const revisedSection = payment_voucher_api?.data?.find((item: any) => item.attributes.voucher_to === 'customer')
        this.setState({
            formPartTwo:true,
            paid_amount: payment_insurance_infos?.data?.attributes?.paid_amount,
            deduct_commission: payment_insurance_infos?.data?.attributes?.deduct_commission_from_payment,
            payment_reciepent: payment_insurance_infos?.data?.attributes?.payment_recipient,
            flowFourData: {
                siib_receipt_number: payment_insurance_infos?.data?.attributes?.siib_receipt_number,
                payment_date: payment_insurance_infos?.data?.attributes?.payment_date,
                proof_of_payment_doc: payment_insurance_infos?.data?.attributes?.proof_of_payment,
                payment_date_2: voucherSection?.attributes?.payment_voucher_date,
                payment_voucher: voucherSection?.attributes.payment_voucher,
                payment_voucher_id: voucherSection?.attributes.payment_voucher_id,
                insurance_receipt_number: voucherSection?.attributes.insurer_recipient_number,
                voucher_payment_date: voucherSection?.attributes.payment_date,
                /* istanbul ignore next*/   voucher_proof_of_payment_doc: voucherSection?.attributes?.proof_of_payment_to_insurer,
                policy_release_requested: payment_insurance_infos.data?.attributes?.policy_release_requested ? 'yes' : 'no',
                revised_payment_voucher_0: revisedSection?.attributes?.payment_voucher,//revised payment voucher
                revised_payment_voucher_id_0: revisedSection?.attributes?.payment_voucher_id,//revised payment voucher id
                revised_insurance_receipt_number_0: revisedSection?.attributes?.insurer_recipient_number,
                revised_voucher_payment_date_0: revisedSection?.attributes?.payment_date,
                revised_voucher_proof_of_payment_doc_0: revisedSection?.attributes?.proof_of_payment_refund_to_cust,
                voucher_id_insurer: voucherSection?.id,
                voucher_id_customer: revisedSection?.id
            }

        },()=>{
            const values= this.updateInitialValues()
            this.checkStep(values,this.props.flowValue as number)
         })

    }

    updateInitialValues = () => {
        if (this.state.flow === 1) {
            return {
                stageData: {
                    flowOneData: this.state.flowOneData,
                },
                paid_amount: this.state.paid_amount,
                deduct_commission: this.state.deduct_commission,
                payment_reciepent: this.state.payment_reciepent,
            };
        }
        else if (this.state.flow === 2) {
            return {
                stageData: {
                    flowTwoData: this.state.flowTwoData,
                },
                paid_amount: this.state.paid_amount,
                deduct_commission: this.state.deduct_commission,
                payment_reciepent: this.state.payment_reciepent,
            };
        }
        else if (this.state.flow === 3) {
            return {
                stageData: {
                    flowThreeData: this.state.flowThreeData,
                },
                paid_amount: this.state.paid_amount,
                deduct_commission: this.state.deduct_commission,
                payment_reciepent: this.state.payment_reciepent,
            };
        }
        else if (this.state.flow === 4) {
            return {
                stageData: {
                    flowFourData: this.state.flowFourData,
                },
                paid_amount: this.state.paid_amount,
                deduct_commission: this.state.deduct_commission,
                payment_reciepent: this.state.payment_reciepent,
            };
        }
        else if (this.state.flow === 5) {
            return {
                stageData: {
                    flowFiveData: this.state.flowFiveData,
                },
                paid_amount: this.state.paid_amount,
                deduct_commission: this.state.deduct_commission,
                payment_reciepent: this.state.payment_reciepent,
            };
        }
        else if (this.state.flow === 6) {
            return {
                stageData: {
                    flowSixData: this.state.flowSixData,
                },
                paid_amount: this.state.paid_amount,
                deduct_commission: this.state.deduct_commission,
                payment_reciepent: this.state.payment_reciepent,
            };
        }
        else if (this.state.flow === 7) {
            return {
                stageData: {
                    flowSevenData: this.state.flowSevenData,
                },
                paid_amount: this.state.paid_amount,
                deduct_commission: this.state.deduct_commission,
                payment_reciepent: this.state.payment_reciepent,
            };
        }
        else if (this.state.flow === 8) {
            return {
                stageData: {
                    flowEightData: this.state.flowEightData,
                },
                paid_amount: this.state.paid_amount,
                deduct_commission: this.state.deduct_commission,
                payment_reciepent: this.state.payment_reciepent,
            };
        }
        else if (this.state.flow === 9) {
            return {
                stageData: {
                    flowNineData: this.state.flowNineData,
                },
                paid_amount: this.state.paid_amount,
                deduct_commission: this.state.deduct_commission,
                payment_reciepent: this.state.payment_reciepent,
            };
        }
        else {
            return {
                paid_amount: this.state.paid_amount,
                deduct_commission: this.state.deduct_commission,
                payment_reciepent: this.state.payment_reciepent,
            };
        }
    }

    submitProcessingData(values: any) {
        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') ?? '{}')
        if (this.state.policyId && stageSixData?.attributes?.is_next_stage_submitted) {
            this.updateStageSevenData(values, this.state.policyId as number, this.state.flow, false)
        } else {
            this.createStageSevenData(values, this.state.policyId as number, this.state.flow, false)
        }
    }

    handleOkDialog = (value: string) => {
        this.trackRequest(this.state.policyId, 'next_stage', value, 'issuance');
        const isInstalmentPaid = window.localStorage.getItem('InstallmentPaid')
        if (isInstalmentPaid == "false") {
            
            this.props.onNext(8)
        }
        else {
            this.props.onNext(7)
        }
    }

    saveDraftData = async (formikRef: any) => {
        const { current: formikInstance } = formikRef;
        /* istanbul ignore if */
        if (formikInstance) {
            const flowFromAPI = JSON.parse(window.localStorage.getItem('flowFromAPI') || '{}')
            const flowDidNotChange = flowFromAPI && this.state.flow === parseInt(flowFromAPI)
            const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') || '{}')
            if ((this.state.policyId && stageSixData?.attributes?.is_next_stage_submitted && flowDidNotChange) || this.state.isDraftAPIUsed) {
                this.updateStageSevenData(formikInstance.values, this.state.policyId as number, this.state.flow, true)
            } else {
                this.createStageSevenData(formikInstance.values, this.state.policyId as number, this.state.flow, true)
            }
            this.setState({ isDraftAPIUsed: true });
        }
    }
    hasOnlyViewRights = () => {
        return (this.state.policyId && this.state.viewPermission?.includes(this.state.role)) as boolean
    }
    handleAccordian = () => {
        if (this.state.policyId !== "" && this.hasOnlyViewRights()) {
            return false;
        } else {
            this.setState((prev: any) => ({
                ...prev,
                expandedAccordion: !prev.expandedAccordion
            }))
        }
    }

    /* istanbul ignore next*/
    getFlowOneFormData = (values: any, isDraft: boolean = false) => {
        const stageSixData = this.props.stageSixData;
        let approvedData = stageSixData?.attributes?.quotation_insurance_infos?.data?.filter((item: any) => {
            return item.attributes.approval === true
        })[0];
        const formData = new FormData();
        approvedData?.attributes?.insurance_company_id?.id && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][insurance_company_id]`, approvedData?.attributes?.insurance_company_id?.id)
        this.state.paid_amount && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][paid_amount]`, this.state.paid_amount)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_recipient]`, "Insurer")
        values?.stageData?.flowOneData?.insure_recipent_number && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][insurance_receipt_number]`, values?.stageData?.flowOneData?.insure_recipent_number)
        values?.stageData?.flowOneData?.payment_date && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_date]`, values?.stageData?.flowOneData?.payment_date)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][policy_release_requested]`, values?.stageData?.flowOneData?.policy_release_requested)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][balance]`, "0")
        isDraft ? formData.append(`data[quotation_payments][save_as_draft]`, 'yes') : formData.append(`data[quotation_payments][save_as_draft]`, 'no')

        return formData;
    }

    /* istanbul ignore next*/
    getFlowTwoFormData = (values: any, isDraft: boolean = false) => {

        const stageSixData = this.props.stageSixData;
        let approvedData = stageSixData?.attributes?.quotation_insurance_infos.data.filter((item: any) => {
            return item.attributes.approval === true
        })[0];
        const formData = new FormData();
        approvedData?.attributes?.insurance_company_id.id && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][insurance_company_id]`, approvedData?.attributes?.insurance_company_id.id)
        this.state.paid_amount && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][paid_amount]`, this.state.paid_amount)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_recipient]`, "Insurer")
        values.stageData?.flowTwoData.insure_recipent_number && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][insurance_receipt_number]`, values.stageData?.flowTwoData.insure_recipent_number)
        values.stageData?.flowTwoData.payment_date && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_date]`, values.stageData?.flowTwoData.payment_date)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][policy_release_requested]`, values?.stageData?.flowTwoData?.policy_release_requested)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][balance]`, "0")
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][refund_amount_to_customer]`, `${Number(this.state.paid_amount) - Number(approvedData?.attributes?.quotation_amount)}`)
        values?.stageData?.flowTwoData?.customer_payment_date && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][payment_date]`, values?.stageData?.flowTwoData?.customer_payment_date)
        values?.stageData?.flowTwoData?.customer_receipt_number && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][customer_receipt_number]`, values?.stageData?.flowTwoData?.customer_receipt_number)
        isDraft ? formData.append(`data[quotation_payments][save_as_draft]`, 'yes') : formData.append(`data[quotation_payments][save_as_draft]`, 'no')
        this.state.flowTwoData.create_revised_payment_voucher_id && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][id]`, values?.stageData?.flowTwoData?.create_revised_payment_voucher_id)
        if (stageSixData?.attributes?.is_next_stage_submitted) {
        }
        return formData;
    }

    /* istanbul ignore next*/
    getFlowThreeFormData = (data: any, isDraft: boolean = false) => {
        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') || '{}')
        let approvedData = stageSixData.attributes?.quotation_insurance_infos.data.filter((item: any) => {
            return item.attributes.approval === true
        })[0];
        const { stageData } = data;
        const form3Data = new FormData();
        form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][paid_amount]', data.paid_amount)
        form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_recipient]', data.payment_reciepent)
        form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][siib_receipt_number]', stageData?.flowThreeData.siib_receipt_number)
        form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_date]', stageData?.flowThreeData.payment_date)
        // data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_voucher_id]
        form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_voucher_id]', stageData?.flowThreeData.payment_voucher_id)

        form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][policy_release_requested]', stageData?.flowThreeData.policy_release_requested)
        !isDraft && form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][balance]', '0')
        form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payable_to_insurer]', data.paid_amount)
        approvedData?.attributes.insurance_company_id.id && form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][insurance_company_id]', approvedData.attributes.insurance_company_id.id)
        form3Data.append('data[quotation_payments][save_as_draft]', isDraft ? 'yes' : 'no')
        data.deduct_commission && form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][deduct_commission_from_payment]', data.deduct_commission)
        stageData?.flowThreeData?.voucher_payment_date && form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_date]', stageData?.flowThreeData?.voucher_payment_date)
        form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_voucher_date]', stageData?.flowThreeData.payment_date_2)
        stageData?.flowThreeData?.insurance_receipt_number && form3Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][insurer_recipient_number]', stageData?.flowThreeData?.insurance_receipt_number)
        if (stageSixData?.attributes?.is_next_stage_submitted) {
            this.state.flowThreeData?.voucher_id_edit && form3Data.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][id]`, this.state.flowThreeData?.voucher_id_edit)
        }
        return form3Data;
    }

    /* istanbul ignore next*/
    getFlowFourFormData = (data: any, isDraft: boolean = false) => {
        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') || '{}')
        let approvedData = stageSixData?.attributes?.quotation_insurance_infos.data.filter((item: any) => {
            return item.attributes.approval === true
        })[0];
        const { stageData } = data;
        const form4Data = new FormData();
        approvedData?.attributes.insurance_company_id.id && form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][insurance_company_id]', approvedData?.attributes.insurance_company_id.id)
        data.paid_amount && form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][paid_amount]', data.paid_amount)
        form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_recipient]', "Siib")
        form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][siib_receipt_number]', stageData?.flowFourData.siib_receipt_number)
        form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_date]', stageData?.flowFourData.payment_date)
        form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][policy_release_requested]', stageData?.flowFourData.policy_release_requested)
        !isDraft && form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][balance]', '0')
        !isDraft && form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][refund_amount_to_customer]', (Number(data.paid_amount) - Number(approvedData?.attributes?.quotation_amount)).toString())
        form4Data.append('data[quotation_payments][save_as_draft]', isDraft ? 'yes' : 'no')
        stageData?.flowFourData.revised_insurance_receipt_number_0 && form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][insurer_recipient_number]', stageData?.flowFourData.revised_insurance_receipt_number_0)
        stageData?.flowFourData.revised_voucher_payment_date_0 && form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][payment_date]', stageData?.flowFourData.revised_voucher_payment_date_0)
        form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][deduct_commission_from_payment]', data.deduct_commission)
        form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][insurer_recipient_number]', stageData?.flowFourData.insurance_receipt_number)
        form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_date]', stageData?.flowFourData.voucher_payment_date)
        form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_voucher_date]', stageData?.flowFourData.payment_date_2)

        !isDraft&&form4Data.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payable_to_insurer]', data.paid_amount)
        this.state.flowFourData.voucher_id_insurer && form4Data.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][id]`, this.state.flowFourData.voucher_id_insurer)
        this.state.flowFourData.voucher_id_customer && form4Data.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][id]`, this.state.flowFourData.voucher_id_customer)
        return form4Data;
    }

    /* istanbul ignore next*/
    getFlowFiveFormData = (values: any, isDraft: boolean = false, isUpdated: boolean = false) => {
        const formData = new FormData();
        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') ?? '{}')
        let approvedData = stageSixData.attributes?.quotation_insurance_infos.data.filter((item: any) => {
            return item.attributes.approval === true
        })[0];
        const stageSevenData = JSON.parse(window.localStorage.getItem('stageSevenData') || '{}')
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][insurance_company_id]`, approvedData?.attributes.insurance_company_id.id)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][paid_amount]`, values.paid_amount)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_recipient]`, "Siib")
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][policy_release_requested]`, (values.stageData?.flowFiveData?.policy_release_requested==="yes"||values.stageData?.flowFiveData?.policy_release_requested===true) ? "yes" : "no")
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][balance]`, values.stageData?.flowFiveData.balance)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][deduct_commission_from_payment]`, values.deduct_commission)
        formData.append(`data[quotation_payments][save_as_draft]`, isDraft ? 'yes' : 'no')

        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_date]`, values.stageData?.flowFiveData.voucher_payment_date)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_voucher_date]`, values.stageData?.flowFiveData.payment_date_2)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][insurer_recipient_number]`, values.stageData?.flowFiveData.insurance_receipt_number)

        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][deduct_commission_with_vat]`, values.stageData?.flowFiveData.vat_on_siib_commission)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][commission_rate]`, values.stageData?.flowFiveData.commission_rate)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][commission_amount]`, values.stageData?.flowFiveData.commission_amount)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][invoice_date]`, values?.stageData?.flowFiveData?.invoice_date)

        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][receipt_attributes][receipt_date]`, values?.stageData?.flowFiveData?.receipt_date)

        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payable_to_insurer]`, values.stageData?.flowFiveData?.payable_to_insurer)
        formData.append(`data[revenue_siib_commission]`, values.stageData?.flowFiveData.commission_amount)

        if (isUpdated && stageSixData?.attributes?.is_next_stage_submitted ) {
            values.stageData?.flowFiveData.siib_invoice_id&&formData.append(`data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][siib_invoice_id]`, values.stageData?.flowFiveData.siib_invoice_id)
            values.stageData?.flowFiveData.payment_voucher_id&&formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_voucher_id]`, values.stageData?.flowFiveData.payment_voucher_id)
            values.stageData?.flowFiveData.siib_receipt_id&&formData.append(`data[quotation_payments][payment_insurance_infos_attributes][receipt_attributes][siib_receipt_id]`, values.stageData?.flowFiveData.siib_receipt_id)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][id]`, stageSevenData?.attributes?.payment_voucher?.data?.[0]?.id)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][receipt_attributes][id]`, stageSevenData?.attributes?.reciept?.data?.id)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][id]`, stageSevenData?.attributes?.commission?.data?.id)
        }
        return formData
    }

    /* istanbul ignore next*/
    getFlowSixFormData = (values: any, isDraft: boolean = false, isUpdated: boolean = false) => {
        const formData = new FormData();
        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') ?? '{}')
        let approvedData = stageSixData.attributes?.quotation_insurance_infos.data.filter((item: any) => {
            return item.attributes.approval === true
        })[0];
        const stageSevenData = JSON.parse(window.localStorage.getItem('stageSevenData') || '{}')
        const insurer_voucher_api = stageSevenData?.attributes?.payment_voucher?.data?.filter((data: any) => data?.attributes?.voucher_to === 'insurer' && data?.attributes?.revised === false)[0];
        
        const customer_voucher = stageSevenData?.attributes?.payment_voucher?.data?.filter((data: any) => data?.attributes?.voucher_to === 'customer')[0];
        const insurer_voucher = stageSevenData?.attributes?.payment_voucher?.data?.filter((data: any) => data?.attributes?.voucher_to === 'insurer' && data?.attributes?.revised === true)[0];
        const extraAmount = Number(values.paid_amount) - Number(approvedData?.attributes?.quotation_amount) 
        const extraCommission = values.stageData?.flowSixData.vat_on_siib_commission == "yes" ? (extraAmount * Number(values.stageData?.flowSixData.commission_rate))/100 : (extraAmount/1.15 * Number(values.stageData?.flowSixData.commission_rate))/100;
        approvedData?.attributes.insurance_company_id.id && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][insurance_company_id]`, approvedData?.attributes.insurance_company_id.id)
        values.paid_amount && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][paid_amount]`, values.paid_amount)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_recipient]`, "Siib")
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][insurance_receipt_number]`, values.stageData?.flowSixData?.revised_insurance_receipt_number_1)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_date]`, values.stageData?.flowSixData?.revised_voucher_payment_date_1)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][policy_release_requested]`,(values.stageData?.flowSixData.policy_release_requested=="yes"||values.stageData?.flowSixData.policy_release_requested===true) ? "yes" : "no")
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][balance]`, values.stageData?.flowSixData.balance)
        formData.append(`data[quotation_payments][save_as_draft]`, isDraft ? 'yes' : 'no')
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][deduct_commission_from_payment]`, 'yes')
        //payment voucher
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payable_to_insurer]`, values.stageData?.flowSixData.payable_to_insurer)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][insurer_recipient_number]`, values.stageData?.flowSixData.insurance_receipt_number)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_date]`, values.stageData?.flowSixData.voucher_payment_date)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_voucher_date]`, values.stageData?.flowSixData.payment_date_2)
        // data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][id]

        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][deduct_commission_with_vat]`, values.stageData?.flowSixData.vat_on_siib_commission)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][commission_rate]`, values.stageData?.flowSixData.commission_rate)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][commission_amount]`, values.stageData?.flowSixData.commission_amount)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][invoice_date]`, values.stageData?.flowSixData.invoice_date)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][siib_invoice_id]`, values.stageData?.flowSixData.siib_invoice_id)

        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][receipt_attributes][receipt_date]`, values.stageData?.flowSixData.receipt_date)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][receipt_attributes][siib_receipt_id]`, values.stageData?.flowSixData.siib_receipt_id)
        // formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][0][payable_to_insurer]`, values.stageData?.flowSixData.payable_to_insurer)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][0][insurer_recipient_number]`, values?.stageData?.flowSixData?.revised_insurance_receipt_number_1)
        // formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][0][payment_date]`, values.stageData?.flowSixData?.revised_voucher_payment_date_1)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][0][payment_date]`, values?.stageData?.flowSixData?.revised_voucher_payment_date_1)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][0][payment_voucher_id]`, values.stageData?.flowSixData?.revised_payment_voucher_id_1)

        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][1][insurer_recipient_number]`, values.stageData?.flowSixData?.revised_insurance_receipt_number_0)
        // formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][1][payment_date]`, values.stageData?.flowSixData?.revised_voucher_payment_date_0)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][1][payment_date]`, values.stageData?.flowSixData?.revised_voucher_payment_date_0)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][1][payment_voucher_id]`, values.stageData?.flowSixData?.revised_payment_voucher_id_0 || '')

        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][refund_amount_to_customer]`, `${Number(values.paid_amount) - Number(approvedData?.attributes.quotation_amount)}`)
        formData.append(`data[revenue_siib_commission]`, `${(Number(values.stageData?.flowSixData.commission_amount) - Number(extraCommission)).toFixed(2)}`)

        // Edit data
        if (isUpdated) {
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][id]`, insurer_voucher_api?.id)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][0][id]`,insurer_voucher?.id)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][create_revised_payment_voucher][1][id]`,customer_voucher?.id)
        }

        return formData
    }

    getBalance = (quotationAmount: any, values: any, flowData: string) => {
        const instalmentPaid = values?.stageData?.[`${flowData}`]?.installment_attributes.reduce((accumulator: any, current: any) => {
            if (current.showInstallmentAmount && current.installment_paid === 'yes') {
                accumulator += Number(current.installment_amount);
            }
            return accumulator;
        }, 0) || 0;

        return ((quotationAmount - Number(values?.paid_amount))- Number(instalmentPaid))
    }

    /* istanbul ignore next*/
    getFlowSevenFormData = (values: any, isDraft: boolean = false) => {
        const stageSixData = this.props.stageSixData;
        const stageSevenData = JSON.parse(window.localStorage.getItem('stageSevenData') || '{}')
        let approvedData = stageSixData?.attributes?.quotation_insurance_infos?.data?.filter((item: any) => {
            return item.attributes.approval === true
        })[0];
        const balance = this.getBalance(Number(approvedData?.attributes?.quotation_amount), values, 'flowSevenData')
        
        const formData = new FormData();
        approvedData?.attributes?.insurance_company_id?.id && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][insurance_company_id]`, approvedData?.attributes?.insurance_company_id?.id)
        this.state.paid_amount && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][paid_amount]`, this.state.paid_amount)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_recipient]`, "Insurer")
        values.stageData?.flowSevenData.insure_recipent_number && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][insurance_receipt_number]`, values.stageData?.flowSevenData.insure_recipent_number)
        values.stageData?.flowSevenData.payment_date && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][payment_date]`, values.stageData?.flowSevenData.payment_date)
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][policy_release_requested]`, values.stageData?.flowSevenData.policy_release_requested)
        values?.stageData?.flowSevenData?.installment_attributes?.forEach((item: any, index: number) => {
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][installment_amount]`, item.installment_amount)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][due_date]`, item.due_date)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][installment_paid]`, item.installment_paid === 'yes' ? 'yes' : 'no')
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][insurer_receipt_number]`, item.installment_paid === 'yes' ? item.insurance_receipt_number : '')
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][insurer_receipt_date]`, item.installment_paid === 'yes' ? item.payment_date_2 : '')
            if (stageSixData?.attributes?.is_next_stage_submitted) {
                stageSevenData?.attributes?.installments?.data?.[index]?.id&& formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][id]`, stageSevenData?.attributes?.installments?.data?.[index]?.id)
            }
        })
        formData.append(`data[quotation_payments][payment_insurance_infos_attributes][balance]`, `${balance}`)
        formData.append(`data[quotation_payments][save_as_draft]`, isDraft ? 'yes' : 'no')
        return formData
    }

    /* istanbul ignore next*/
    getFlowEightFormData = (values: any, isUpdated: boolean = false, isDraft: boolean = false) => {
        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') || '{}')
        const stageSevenData = JSON.parse(window.localStorage.getItem('stageSevenData') || '{}')
        const dummyData = stageSixData?.attributes?.quotation_insurance_infos?.data?.filter((item: any) => {
            return item?.attributes?.approval === true
        });
        const balance = this.getBalance( Number(dummyData?.[0]?.attributes?.quotation_amount), values, 'flowEightData')
        
        const formData = new FormData();
        dummyData?.[0]?.attributes?.insurance_company_id?.id && formData.append('data[quotation_payments][payment_insurance_infos_attributes][insurance_company_id]', dummyData?.[0]?.attributes?.insurance_company_id?.id)
        values.payment_reciepent && formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_recipient]', values.payment_reciepent)
        values.paid_amount && formData.append('data[quotation_payments][payment_insurance_infos_attributes][paid_amount]', values.paid_amount)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][deduct_commission_from_payment]', values.deduct_commission)
        values.stageData?.flowEightData?.siib_receipt_number && formData.append('data[quotation_payments][payment_insurance_infos_attributes][siib_receipt_number]', values.stageData?.flowEightData?.siib_receipt_number)
        values.stageData?.flowEightData?.payment_date && formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_date]', values.stageData?.flowEightData?.payment_date)
        values.stageData?.flowEightData?.insurance_receipt_number && formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][insurer_recipient_number]', values.stageData?.flowEightData?.insurance_receipt_number)
        values.stageData?.flowEightData?.voucher_payment_date && formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_date]', values.stageData?.flowEightData?.voucher_payment_date)
        values.stageData?.flowEightData?.payment_date_2 && formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_voucher_date]', values.stageData?.flowEightData?.payment_date_2)

        values.stageData?.flowEightData?.policy_release_requested && formData.append('data[quotation_payments][payment_insurance_infos_attributes][policy_release_requested]', values.stageData?.flowEightData?.policy_release_requested)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][balance]', `${balance}`) //values.stageData?.flowEightData?.balance
        formData.append('data[quotation_payments][save_as_draft]', isDraft ? 'yes' : 'no')
        isUpdated && formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][id]', stageSevenData?.attributes?.payment_voucher?.data[0]?.id)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payable_to_insurer]', values.paid_amount)
        this.getStageEightIteration(values, formData, stageSevenData, isUpdated);

        return formData
    }

    getStageEightIteration = (values: any, formData: any, stageSevenData: any, isUpdated: boolean = false) => {
        return values?.stageData?.flowEightData?.installment_attributes?.forEach((item: any, index: number) => {
            item.installment_amount && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][installment_amount]`, item.installment_amount)
            item.due_date && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][due_date]`, item.due_date)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][installment_paid]`, item.installment_paid === 'yes' ? 'yes' : 'no')
            item.siib_receipt_number && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][siib_receipt_number]`, item.siib_receipt_number)
            item.payment_date_2 && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][payment_date]`, item.payment_date_2)
            item.insurer_receipt_number && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][payment_voucher][insurer_recipient_number]`, item.insurer_receipt_number)
            item.payment_date && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][payment_voucher][payment_date]`, item.payment_date)
            /*istanbul ignore next*/
            item.payment_voucher_date && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][payment_voucher][payment_voucher_date]`, item.payment_voucher_date)

            /*istanbul ignore next*/
            isUpdated &&stageSevenData?.attributes?.installments?.data?.[index]?.id&& formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][id]`, stageSevenData?.attributes?.installments?.data?.[index]?.id)
            item.installment_amount && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][payment_voucher][payable_to_insurer]`, item.installment_amount)

        });
    }

    /* istanbul ignore next*/
    getFlowNineFormData = (values: any, isUpdated: boolean = false, isDraft: boolean = false) => {
        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') ?? '{}')
        const stageSevenData = JSON.parse(window.localStorage.getItem('stageSevenData') ?? '{}')
        const dummyData = stageSixData?.attributes?.quotation_insurance_infos?.data?.filter((item: any) => {
            return item?.attributes?.approval === true
        });
        const balance = this.getBalance( Number(dummyData?.[0]?.attributes?.quotation_amount), values, 'flowNineData')
        let revenue_commission = Number(values.stageData?.flowNineData?.commission_amount);
        const formData = new FormData();
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][insurance_company_id]', dummyData?.[0].attributes.insurance_company_id.id)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_recipient]', values.payment_reciepent)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][paid_amount]', values.paid_amount)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][deduct_commission_from_payment]', values.deduct_commission)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][commission_rate]', values.stageData?.flowNineData?.commission_rate)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][commission_amount]', values.stageData?.flowNineData?.commission_amount)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][deduct_commission_with_vat]', values.stageData?.flowNineData?.vat_on_siib_commission)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][invoice_date]', values.stageData?.flowNineData?.invoice_date)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][receipt_attributes][receipt_date]', values.stageData?.flowNineData?.receipt_date)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][commission_attributes][siib_invoice_id]', values.stageData?.flowNineData?.siib_invoice_id)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][receipt_attributes][siib_receipt_id]', values.stageData?.flowNineData?.siib_receipt_id)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payable_to_insurer]', values.stageData?.flowNineData?.payable_to_insurer)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][insurer_recipient_number]', values.stageData?.flowNineData?.insurance_receipt_number)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_date]', values.stageData?.flowNineData?.voucher_payment_date)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][payment_voucher_date]', values.stageData?.flowNineData?.payment_date_2)

        formData.append('data[quotation_payments][payment_insurance_infos_attributes][policy_release_requested]', values.stageData?.flowNineData?.policy_release_requested)
        formData.append('data[quotation_payments][payment_insurance_infos_attributes][balance]', `${balance}`)
        formData.append(`data[quotation_payments][save_as_draft]`, isDraft ? 'yes' : 'No')
        if (isUpdated) formData.append('data[quotation_payments][payment_insurance_infos_attributes][payment_voucher][id]', stageSevenData?.attributes?.payment_voucher?.data[0]?.id)

        values?.stageData?.flowNineData?.installment_attributes?.forEach((item: any, index: number) => {
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][installment_amount]`, item.installment_amount)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][due_date]`, item.due_date)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][installment_paid]`, item.installment_paid === 'yes' ? 'yes' : 'no')
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][commission_attributes][commission_rate]`, item.commission_rate||"")
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][commission_attributes][commission_amount]`, item.commission_amount||"")
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][commission_attributes][deduct_commission_with_vat]`, item.vat_on_siib_commission)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][commission_attributes][invoice_date]`, item?.invoice_date)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][receipt_attributes][receipt_date]`, item?.receipt_date)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][commission_attributes][siib_invoice_id]`, item?.siib_invoice_id||"")
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][receipt_attributes][siib_receipt_id]`, item?.siib_receipt_id||"")
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][payment_voucher][insurer_recipient_number]`, item?.insurer_receipt_number||"")
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][payment_voucher][payable_to_insurer]`, item?.payable_to_insurer)
            formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][payment_voucher][payment_date]`, item?.voucher_payment_date||"")
            isUpdated && item?.id && formData.append(`data[quotation_payments][payment_insurance_infos_attributes][installment_attributes][${index}][id]`, item?.id||"")
        
        });
        formData.append(`data[revenue_siib_commission]`, `${revenue_commission}`)
        return formData
    }

    getFormData = (data: any, flow: number, isDraft: boolean = false, isUpdated: boolean = false) => {
        if (flow === 1) {
            const flowOneData = this.getFlowOneFormData(data, isDraft)
            return flowOneData;
        }
        if (flow === 2) {
            const flowTwoData = this.getFlowTwoFormData(data, isDraft)
            return flowTwoData;
        }
        if (flow === 3) {
            const flowThreeData = this.getFlowThreeFormData(data, isDraft)
            return flowThreeData

        }
        if (flow === 4) {
            const flowFourFormData = this.getFlowFourFormData(data, isDraft)
            return flowFourFormData
        }
        if (flow === 5) {
            const flowFiveFormData = this.getFlowFiveFormData(data, isDraft, isUpdated)
            return flowFiveFormData
        }
        if (flow === 6) {
            const flowSixFormData = this.getFlowSixFormData(data, isDraft, isUpdated)
            return flowSixFormData
        }
        if (flow === 7) {
            const flowSevenFormData = this.getFlowSevenFormData(data, isDraft)
            return flowSevenFormData
        }
        if (flow === 8) {
            const flowEightFormData = this.getFlowEightFormData(data, isUpdated, isDraft)
            return flowEightFormData
        }
        if (flow === 9) {
            const flowNineFormData = this.getFlowNineFormData(data, isUpdated, isDraft)
            return flowNineFormData
        }
    }
    /* istanbul ignore next*/
    getFormDataForFileUploadStageSeven = (key: string, fileData: any, formikRef: any, insatllmentNo: any = -1) => {
        const formData = new FormData();
        const installmentIndexVal = insatllmentNo >= 0 ? insatllmentNo + 2 : insatllmentNo;
        const stageSixData = JSON.parse(window.localStorage.getItem('stageSixData') || '{}')
        let approvedData = stageSixData?.attributes?.quotation_insurance_infos?.data?.filter((item: any) => {
            return item.attributes.approval === true
        })[0];
        const insurancerCompanyId = approvedData?.attributes?.insurance_company_id?.id
        formData.append("data[stage]", "7");
        insurancerCompanyId && formData.append('data[insurance_company_id]', insurancerCompanyId)
        key && formData.append("data[file_key]", key);
        formData.append(`data[multiple]`, 'false');
        fileData && formData.append("data[file]", fileData);
        installmentIndexVal > 0 && formData.append("data[meta]", `installment-${installmentIndexVal}`)

        return formData
    }

    getStepNumberforReject = (currentStep: string) => {
        switch (currentStep) {
            case ("info_gathering"):
                return 1
            case ("processing"):
                return 2
            case ("awaiting"):
                return 3
            case ("operations_review"):
                return 4
            case ("sales_review"):
                return 5
            case ("customer_review"):
                return 6
            default:
                return "";
        }
    }

    createStageSevenData = (values: any, policyId: number, flow: number, isDraft: boolean) => {
        this.createStageSevenAPIKey = this.apiCall({
            type: 'FormData',
            method: 'POST',
            body: this.getFormData(values, flow, isDraft),
            endPoint: `bx_block_policy_request/policy_requests?stage=7&id=${policyId}&flow=${flow}`,
        })
    }

    updateStageSevenData = (values: any, policyId: number, flow: number, isDraft: boolean) => {
        this.updateStageSevenAPIKey = this.apiCall({
            type: 'FormData',
            method: 'PUT',
            body: this.getFormData(values, flow, isDraft, true),
            endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=7&flow=${flow}`,
        })
    }

    uploadFileDoc = (file_key: string, fileData: any, formikRef: any, fieldName: string, insatllmentNo: any = -1) => {
        this.formikRef = formikRef;
        this.fieldName = fieldName;
        this.stageSevenFileUploadAPIResponseKey = this.apiCall({
            type: 'FormData',
            method: 'PUT',
            body: this.getFormDataForFileUploadStageSeven(file_key, fileData, formikRef, insatllmentNo),
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/file_upload`,
        })
    }
    removeFile = (fileId: number) => {        
        this.removeFileAPI = this.apiCall({
            body: { data: { file_id: fileId } },
            method: 'PUT',
            endPoint: `bx_block_policy_request/policy_requests/${this.state.policyId}/remove_file`,
            contentType: 'application/json',
        })
    }
    removeFileAPIReceiveSeven = (message:any) => {
      if (
          getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.removeFileAPI !== null &&
          this.removeFileAPI ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        )   {
        const responseJsonStageSeven = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
          if (responseJsonStageSeven?.data) {
            // this.formikRef.current.setFieldValue(this.fieldName,{})
              this.setState({
              alertMessage:responseJsonStageSeven?.data?.message, 
              showAlert:true,
              alertType:'warning',
              })
          }
      }
    }

    generateCreatePaymentVoucher = (formValues: any, dummyData: any, formikRef: any) => {
        this.formikRef = formikRef;
        this.setState({
            voucherBtnLoading: true,
            showAlert: true,
            alertType:"warning",
            alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
        });
        const insuranceData = this.getInsuranceCompanyIdFromStageSix();
        const flowData = formValues.stageData[`flow${this.convertToWord(this.props.flowValue as number)}Data`]
        const flow =this.getCurrentFlow(formValues.payment_reciepent, Number(formValues.paid_amount), Number(dummyData[0].attributes.quotation_amount), formValues.deduct_commission, this.formikRef)
        
        const body = {
            paid_amount: formValues.paid_amount,
            file_key: "payment_voucher_pdf",
            recipient_type: "Insurer",
            commission_amount: flowData?.commission_amount,
            payment_date: flowData?.payment_date_2,
            insurance_company_id: insuranceData?.attributes?.insurance_company_id.id || ""
        }
        this.createPaymentVoucherApi = this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=7&flow=${this.getCurrentFlow(formValues.payment_reciepent, Number(formValues.paid_amount), Number(dummyData[0].attributes.quotation_amount), formValues.deduct_commission, this.formikRef)}`,
            body: {
                data: body
            },
        })
    }

    generateRevisedPaymentVoucherForInsurer = (formValues:any,dummyData:any,formikRef:any) => {
        this.formikRef= formikRef;
        this.setState({
            voucherInsuranceBtnLoading:true,
            showAlert: true,
            alertType:"warning",
            alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
        });
        const insuranceData = this.getInsuranceCompanyIdFromStageSix();
        const flowData = formValues.stageData[`flow${this.convertToWord(this.props.flowValue as number)}Data`]

        const body = {
            paid_amount: formValues.paid_amount,
            file_key:"revised_payment_voucher_pdf",
            recipient_type:"customer",
            payment_date: flowData.payment_date,
            insurance_company_id: insuranceData?.attributes?.insurance_company_id.id || ""
        }
        this.generateRevisedPaymentVoucherForInsurerApi = this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=7&flow=${this.getCurrentFlow(formValues.payment_reciepent, Number(formValues.paid_amount), Number(dummyData.attributes.quotation_amount), formValues.deduct_commission, this.formikRef)}`,
            body: {
                data: body
            },
        })
    }
    generateCreateRevisedPaymentVoucher = (formValues:any,dummyData:any,revisedVoucherIndex:any,formikRef:any) => {

        this.formikRef = formikRef;
        const flow = this.getCurrentFlow(formValues.payment_reciepent, Number(formValues.paid_amount), Number(dummyData[0].attributes.quotation_amount), formValues.deduct_commission, this.formikRef)
        this.setState({
            voucherBtnLoadingForRevisedPayment: true,
            revisedVoucherIndex: revisedVoucherIndex,
            showAlert: true,
            alertType:"warning",
            alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
        });
        const flowData = formValues.stageData[`flow${this.convertToWord(this.props.flowValue as number)}Data`]

        const insuranceData = this.getInsuranceCompanyIdFromStageSix();
        const body = {
            paid_amount: formValues.paid_amount,
            file_key: "revised_payment_voucher_pdf",
            recipient_type: revisedVoucherIndex === 1 ? "Insurer" : "Customer",
            payment_date: flowData.payment_date_2,
            insurance_company_id: insuranceData?.attributes?.insurance_company_id.id || "",
            commission_rate: flowData.commission_rate,
            deduct_siib_commission_with_vat: flow == 6 ? flowData.vat_on_siib_commission : ""
        }
        this.createRevisedPaymentVoucherApi = this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=7&flow=${flow}`,
            body: {
                data: body
            },
        })
    }

    apiCall(data: any) {
        const { contentType, method, endPoint, body, type } = data;
        const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
        const token = userData.token || "";

        let header: any = {
            token: token,
        };

        if (contentType) {
            header = {
                ...header,
                "Content-Type": contentType,
            }
        }

        const stageSevenRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== 'FormData' ?
            stageSevenRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            ) : stageSevenRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== 'FormData' ?
            stageSevenRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            ) : stageSevenRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );

        runEngine.sendMessage(stageSevenRequestMessage.id, stageSevenRequestMessage);
        return stageSevenRequestMessage.messageId;
    }

    trackRequest = async (policyId: any, stageStatus: string, comment: string, currentStage: string) => {
        const queryParam = `?stage=7`

        this.setState({ isReject: !stageStatus.includes('next') })
        {
            this.stageSevenTrackRequestAPI = this.apiCall({
                contentType: 'application/json',
                method: 'POST',
                body: { data: { track_request: { stage_status: stageStatus, ...(comment && { comment }), current_stage: currentStage } } },
                endPoint: `bx_block_policy_request/policy_requests/${policyId}/track_requests${comment ? queryParam : ''}`,
            })
        }
    }
    getInsuranceCompanyIdFromStageSix = () => {
        const stageSixData = this.props.stageSixData || JSON.parse(window.localStorage.getItem('stageSixData') || '{}');
        let approvedDataOfInsurance = stageSixData?.attributes?.quotation_insurance_infos.data.filter((item: any) => {
            return item.attributes.approval === true
        })[0];
        return approvedDataOfInsurance
    }
    checkBtnDisabled = (values: any) => {
        const flowData = values.stageData[`flow${this.convertToWord(this.props.flowValue as number)}Data`];    
        if (
            flowData?.proof_of_payment_doc === "" ||
            flowData?.payment_date_2 === "" ||
            flowData?.payment_date === "" ||
            flowData?.payment_date_2 === null ||
            flowData?.payment_date === null ||
            flowData?.payment_date_2 === undefined ||
            flowData?.payment_date === undefined ||
            flowData?.payment_voucher_id ||
            flowData?.payment_voucher
        ) {
            return true;
        } else {
            return false;
        }
    }
    checkRevisedBtnDisabled = (values: any, index: any) => {
        const currentFlowString = this.convertToWord(this.props.flowValue as number);

        const flowData = values.stageData[`flow${currentFlowString}Data`];

        if (
            (flowData && flowData[`revised_payment_voucher_${index}`] || flowData && flowData[`revised_payment_voucher_id_${index}`])||
            (values.paid_amount === "" ||
                flowData && flowData?.proof_of_payment_doc === "" ||
                flowData && flowData?.payment_date_2 === "" ||
                flowData && flowData?.payment_date === "")
        ) {
            return true
        } else {
            return false
        }

    }
    checkBtnDisabledForInsurer = (values: any, isRevisedPaymentVisible?: boolean) => {
      const stageSixData=JSON.parse(localStorage.getItem('stageSixData') || '{}')
      const stageSevenData=JSON.parse(localStorage.getItem('stageSevenData') || '{}')

        const flowData = values.stageData[`flow${this.convertToWord(this.props.flowValue as number)}Data`];
        if (flowData.revised_proof_of_payment_doc_id || flowData.revised_proof_of_payment_doc) {
                return true;
            }   
        if (isRevisedPaymentVisible &&
            (flowData.payment_date === "" || flowData.proof_of_payment_doc === "" ||
             flowData.payment_date === undefined || flowData.proof_of_payment_doc === undefined ||
             flowData.payment_date === null || flowData.proof_of_payment_doc === null ||(stageSixData?.attributes?.is_next_stage_submitted &&!stageSevenData?.attributes?.save_as_draft ))
        ) {
            return true;
        }
     
        return false;
    }
    

    checkPaymentVoucherBtnDisabledForFlow5And6 = (values: any, flowValue: any) => {
        const flowData = values.stageData[`flow${this.convertToWord(flowValue as number)}Data`];

        if (
            flowData && (!flowData?.payable_to_insurer || !flowData?.payment_date_2) || (flowData?.payment_voucher_id || flowData?.payment_voucher_doc)
        ) {
            return true
        } else {
            return false
        }
    }
    checkPaymentVoucherBtnDisabledForFlow9 = (values: any, flowValue: any, installmentIndex:any) => {

        const flowData = values.stageData[`flow${this.convertToWord(flowValue as number)}Data`];

        if ((
                !flowData?.installment_attributes[installmentIndex]?.payable_to_insurer ||
                !flowData?.installment_attributes[installmentIndex]?.payment_date_2) ||
            (
                (flowData?.installment_attributes[installmentIndex]?.payment_voucher_id ) ||
                (flowData?.installment_attributes[installmentIndex]?.payment_voucher )
            )
        ) {
            return true
        } else {
            return false
        }
    }
    checkPaymentVoucherBtnDisabledForFlow8 = (values: any, flowValue: any, installmentIndex:any) => {

        const flowData = values.stageData[`flow${this.convertToWord(flowValue as number)}Data`];

        if ((
                !flowData?.installment_attributes[installmentIndex]?.installment_amount ||
                !flowData?.installment_attributes[installmentIndex]?.payment_voucher_date) ||
            (
                (flowData?.installment_attributes[installmentIndex]?.payment_voucher_id ) ||
                (flowData?.installment_attributes[installmentIndex]?.payment_voucher )
            )
        ) {
            return true
        } else {
            return false
        }
    }

    generateCreatePaymentVoucherForFlow5And6 = (formValues: any, dummyData: any, formikRef: any, flowValue: any) => {
        this.formikRef = formikRef
        this.setState({
            voucherBtnLoading: true,
            showAlert: true,
            alertType:"warning",
            alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
        });
        const insuranceData = this.getInsuranceCompanyIdFromStageSix() || { attributes: {} };
        const flowData = formValues.stageData[`flow${this.convertToWord(flowValue as number)}Data`]
        const body = {
            paid_amount: formValues.paid_amount,
            file_key: "payment_voucher_pdf",
            recipient_type:  "Insurer",
            payment_date: flowData?.payment_date_2,
            commission_amount: flowData?.commission_amount,
            insurance_company_id: insuranceData.attributes.insurance_company_id?.id || ""
        }
        this.createPaymentVoucherForFlow5And6Api = this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=7&flow=${this.getCurrentFlow(formValues.payment_reciepent, Number(formValues.paid_amount), Number(dummyData[0].attributes.quotation_amount), formValues.deduct_commission, this.formikRef)}`,
            body: {
                data: body
            },
        })
    }

    generateInvoiceAndReceipt = (formValues: any, dummyData: any, formikRef: any, flowValue: any) => {
        this.formikRef = formikRef;
        this.setState({
            invAndRcptBtnLoadingForInvoice: true,
            invAndRcptBtnLoadingForReceipt: true,
            showAlert: true,
            alertType:"warning",
            alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
        });
        const insuranceData = this.getInsuranceCompanyIdFromStageSix() || { attributes: {} };
        const flowData = formValues.stageData[`flow${this.convertToWord(flowValue as number)}Data`]
        let invoiceBody;

        invoiceBody = {
            paid_amount: formValues.paid_amount,
            file_key: "invoice_pdf",
            recipient_type: "Siib",
            invoice_date: flowData?.invoice_date,
            commission_rate: flowData?.commission_rate,
            insurance_company_id: insuranceData.attributes.insurance_company_id?.id || "",
            deduct_siib_commission_with_vat: flowData?.vat_on_siib_commission,
        }

        this.generateInvoiceApi = this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=7&flow=${this.getCurrentFlow(formValues.payment_reciepent, Number(formValues.paid_amount), Number(dummyData[0].attributes.quotation_amount), formValues.deduct_commission, this.formikRef)}`,
            body: {
                data: invoiceBody
            },
        })

        const receiptBody = {
            paid_amount: formValues.paid_amount,
            file_key: "receipt_pdf",
            recipient_type: "Siib",
            receipt_date: flowData?.receipt_date,
            insurance_company_id: insuranceData.attributes.insurance_company_id?.id || "",
            deduct_siib_commission_with_vat: flowData?.vat_on_siib_commission,
        }
        this.generateReceiptApi = this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=7&flow=${this.getCurrentFlow(formValues.payment_reciepent, Number(formValues.paid_amount), Number(dummyData[0].attributes.quotation_amount), formValues.deduct_commission, this.formikRef)}`,
            body: {
                data: receiptBody
            },
        })
    }

    generateInstallmentInvoiceAndReceipt = (formValues: any, dummyData: any, formikRef: any, flowValue: any, installmentIndex: any) => {
        this.formikRef = formikRef;
        this.setState({
            invAndRcptBtnLoadingForInvoice: true,
            invAndRcptBtnLoadingForReceipt: true,
            installmentIndex: installmentIndex,
            showAlert: true,
            alertType:"warning",
            alertMessage: this.state.isArabicLanguage ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
        });
        const insuranceData = this.getInsuranceCompanyIdFromStageSix() || { attributes: {} };
        const flowData = formValues.stageData[`flow${this.convertToWord(flowValue as number)}Data`]
        let invoiceBody;

        invoiceBody = {
            paid_amount: flowData?.installment_attributes[installmentIndex]?.installment_amount,
            file_key: "invoice_pdf",
            recipient_type: "Siib",
            invoice_date: flowData?.installment_attributes[installmentIndex]?.invoice_date,
            commission_rate: flowData?.installment_attributes[installmentIndex]?.commission_rate,
            insurance_company_id: insuranceData.attributes.insurance_company_id?.id || "",
            installment_no: installmentIndex + 2,
            deduct_siib_commission_with_vat: flowData?.vat_on_siib_commission,
        }

        this.generateInvoiceApi = this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=7&flow=${this.getCurrentFlow(formValues.payment_reciepent, Number(formValues.paid_amount), Number(dummyData[0].attributes.quotation_amount), formValues.deduct_commission, this.formikRef)}`,
            body: {
                data: invoiceBody
            },
        })

        const receiptBody = {
            paid_amount: flowData?.installment_attributes[installmentIndex]?.installment_amount,
            file_key: "receipt_pdf",
            recipient_type: "Siib",
            receipt_date: flowData?.installment_attributes[installmentIndex]?.receipt_date,
            insurance_company_id: insuranceData.attributes.insurance_company_id?.id || "",
            installment_no: installmentIndex + 2,
            deduct_siib_commission_with_vat: flowData?.vat_on_siib_commission,
        }
        this.generateReceiptApi = this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=7&flow=${this.getCurrentFlow(formValues.payment_reciepent, Number(formValues.paid_amount), Number(dummyData[0].attributes.quotation_amount), formValues.deduct_commission, this.formikRef)}`,
            body: {
                data: receiptBody
            },
        })
    }
/*istanbul ignore next*/
    checkInvAndRcptBtnDisabled = (values: any, flowValue: any) => {
        const flowData = values.stageData[`flow${this.convertToWord(flowValue as number)}Data`];
        if (
            flowData?.commission_rate === "" ||
            isNaN(flowData?.commission_amount) ||
            flowData?.vat_on_siib_commission === "" ||
            flowData.invoice_date === "" ||
            flowData.receipt_date === "" ||
            flowData?.commission_rate === null ||
            flowData?.commission_rate === undefined ||
            flowData.vat_on_siib_commission === null ||
            flowData.vat_on_siib_commission === undefined ||
            flowData.invoice_date === null ||
            flowData.invoice_date === undefined ||
            flowData.receipt_date === null ||
            flowData.receipt_date === undefined ||
            (flowData.siib_invoice_id && flowData.siib_invoice_id !== "" && flowData.siib_invoice_id !== null && flowData.siib_invoice_id !== undefined) ||
            (flowData.invoice_doc && flowData.invoice_doc !== "" && flowData.invoice_doc !== null && flowData.invoice_doc !== undefined) ||
            (flowData.siib_receipt_id && flowData.siib_receipt_id !== "" && flowData.siib_receipt_id !== null && flowData.siib_receipt_id !== undefined) ||
            (flowData.receipt_doc && flowData.receipt_doc !== "" && flowData.receipt_doc !== null && flowData.receipt_doc !== undefined)
        ) {
            return true
        } else {
            return false
        }
    }

    // To generate the payment voucher for flow 8 and 9 for SIIB
    generateVoucherForSiibInstallment = (formValues: any, installmentNumber: any, formikRef: any, flowValue: any, dummyData: any) => {

        this.setState({
            voucherBtnLoading: true,
            siibCurrentInstallmentIndex: installmentNumber,
            showAlert: true,
            alertType:"warning",
            alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
        });
        this.formikRef = formikRef;
        const userData = JSON.parse(window.localStorage.getItem('loginData') ?? '{}');
        const token = userData.token || '';
        const installmentData = formValues.stageData[`flow${this.convertToWord(flowValue as number)}Data`]
        const insuranceData = this.getInsuranceCompanyIdFromStageSix();

        let bodyData:any = {};

        if (flowValue === 8) {
            bodyData = {
                paid_amount: installmentData.installment_attributes[installmentNumber].installment_amount,
                file_key: "payment_voucher_pdf",
                recipient_type: "Insurer",
                payment_date: installmentData.installment_attributes[installmentNumber].payment_voucher_date,
                insurance_company_id:insuranceData?.attributes?.insurance_company_id.id || "",
                installment_no: installmentNumber + 2
            }
        }
        if (flowValue === 9) {
            bodyData = {
               paid_amount: installmentData.installment_attributes[installmentNumber].installment_amount,
               file_key: "payment_voucher_pdf",
               recipient_type: "Insurer",
               payment_date: installmentData.installment_attributes[installmentNumber].payment_date_2,
               commission_amount: installmentData.installment_attributes[installmentNumber].commission_amount,
               insurance_company_id: insuranceData?.attributes?.insurance_company_id.id || "",
               installment_no: installmentNumber + 2
           }
        }
        this.generateVoucherForSiibInstallmentApi = this.apiCall({
            contentType: 'application/json',
            token: token || '',
            method: 'POST',
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?policy_request_id=${this.state.policyId}&stage=7&flow=${this.getCurrentFlow(formValues.payment_reciepent, Number(formValues.paid_amount), Number(dummyData[0].attributes.quotation_amount), formValues.deduct_commission, this.formikRef)}`,
            body: {
                data: bodyData
            },
        })
    }

    checkInstallmentInvAndRcptBtnDisabled = (values: any, flowValue: any, installmentIndex: any) => {
        if (installmentIndex !== undefined && installmentIndex !== null) {
            const flowData = values.stageData[`flow${this.convertToWord(flowValue as number)}Data`];

            if (flowData && flowData.installment_attributes && flowData.installment_attributes[installmentIndex]) {
                const installmentData = flowData.installment_attributes[installmentIndex];

                const requiredFields = ['installment_amount', 'commission_amount', 'due_date', 'commission_rate', 'vat_on_siib_commission', 'receipt_date', 'invoice_date'];
                const hasEmptyFields = requiredFields.some(field => !installmentData[field]);

                const docAndIdFields = ['siib_invoice_id', 'invoice_doc', 'siib_receipt_id', 'receipt_doc'];
                const hasNonEmptyDocAndId = docAndIdFields.some(field =>installmentData[field] && installmentData[field] !== "");

                const isCommissionAmountNaN = isNaN(installmentData['commission_amount']);
                return hasEmptyFields || hasNonEmptyDocAndId || isCommissionAmountNaN;
            }
        } else {
            return false;
        }
    }

    getCurrentFlow = (paymentRecipientForm: string, paidAmountForm: number, quotationAmountForm: number, deductCommissionForm: string, formikRef: any) => {
        switch (true) {
            case (paymentRecipientForm === "Insurer" && paidAmountForm === quotationAmountForm):
                {
                    return 1;
                }
            case (paymentRecipientForm === "Insurer" && paidAmountForm > quotationAmountForm):
                {
                    return 2;
                }
            case (paymentRecipientForm === "Siib" && paidAmountForm === quotationAmountForm && deductCommissionForm === 'no'):
                {
                    return 3;
                }
            case (paymentRecipientForm === "Siib" && paidAmountForm > quotationAmountForm && deductCommissionForm === 'no'):
                {
                    return 4;
                }
            case (paymentRecipientForm === "Siib" && paidAmountForm === quotationAmountForm && deductCommissionForm === 'yes'):
                {
                    return 5;
                }
            case (paymentRecipientForm === "Siib" && paidAmountForm > quotationAmountForm && deductCommissionForm === 'yes'):
                {
                    return 6;
                }
            case (paymentRecipientForm === "Insurer" && paidAmountForm < quotationAmountForm):
                {
                    return 7;
                }
            case (paymentRecipientForm === "Siib" && paidAmountForm < quotationAmountForm && deductCommissionForm === 'no'):
                {
                    return 8;
                }
            case (paymentRecipientForm === "Siib" && paidAmountForm < quotationAmountForm && deductCommissionForm === 'yes'):
                {
                    return 9;
                }
        }

    }

    getStageSevenAlertMessage = (isDraft: boolean) => {
        const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
        const isInstalmentPaid = window.localStorage.getItem('InstallmentPaid')
        let alertMessage = "";
        if (isDraft) {
            alertMessage = isArabic ? /* istanbul ignore next */"تم حفظ بيانات طلب المراجعة كمسودة!" : "Data has been saved as draft!"
        } else {
            alertMessage = isArabic ? /* istanbul ignore next */(isInstalmentPaid === "false" ? "لقد تم نقل طلبك إلى مرحلة الفواتير" : "لقد تم نقل طلبك إلى مرحلة الإصدار") : (isInstalmentPaid === "false" ? "Your request has been moved to Invoicing stage" : "Your request has been moved to Issuance stage")
        }
        return alertMessage
    }

    setStageSevenApiErrorMessage = (responseJson: any) => {
        if (!responseJson) return;
        let errorMessage = "Something went wrong"
        if (responseJson?.errors) {
            errorMessage = typeof (responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error || "Something went wrong";
        }

        this.setState({
            alertType: "error",
            alertMessage: errorMessage,
            showAlert: true,
            showDialog: false,
            showNoteDialog: false
        })
    };


    createAPIResponseStageSeven = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.createStageSevenAPIKey != null &&
            this.createStageSevenAPIKey ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const createAPIResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (createAPIResponseJson?.data) {
                if (this.state.isDraftAPIUsed && /* istanbul ignore next */createAPIResponseJson.data.attributes.save_as_draft) {
                    this.setState({
                        showAlert: true,
                        alertType: "success",
                        alertMessage: this.getStageSevenAlertMessage(true)
                    })
                    window.localStorage.setItem('stageSevenData', JSON.stringify(createAPIResponseJson?.data))
                    return
                }
                this.setState({ isDraftAPIUsed: false, showNoteDialog: true })
                window.localStorage.setItem('stageSevenData', JSON.stringify(createAPIResponseJson?.data));
                this.setState({ expandedAccordion: false })
            } else if (createAPIResponseJson.errors) {
                this.setStageSevenApiErrorMessage(createAPIResponseJson)
            }
        }
    }

    updateAPIResponseStageSeven = (message: any) => {// NOSONAR: typescript:S3776

        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.updateStageSevenAPIKey != null &&
            this.updateStageSevenAPIKey ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const updateAPIResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (updateAPIResponseJson.data) {
                /* istanbul ignore next */
                if (this.state.isDraftAPIUsed && updateAPIResponseJson.data.attributes.save_as_draft) {
                    this.setState({
                        showAlert: true,
                        alertType: "success",
                        alertMessage: this.getStageSevenAlertMessage(true)
                    })
                    window.localStorage.setItem('stageSevenData', JSON.stringify(updateAPIResponseJson?.data))
                    return
                }
                this.setState({ isDraftAPIUsed: false })
                window.localStorage.setItem('stageSevenData', JSON.stringify(updateAPIResponseJson.data));
                this.setState({ expandedAccordion: false })
                const isInstalmentPaid = window.localStorage.getItem('InstallmentPaid')
                 /* istanbul ignore next*/
                if(isInstalmentPaid == "true"&& updateAPIResponseJson?.data?.attributes.is_next_stage_submitted &&(this.state.flow===8|| this.state.flow==9||this.state.flow==7) ){
                    this.setState({ showNoteDialog: false })
                    this.props.onNext(8)               
                }
                else{this.setState({ showNoteDialog: true })}
            } else if (updateAPIResponseJson.errors) {
                this.setStageSevenApiErrorMessage(updateAPIResponseJson)
            }

        }
    }

    stageSevenTrackAPIResponse = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.stageSevenTrackRequestAPI != null &&
            this.stageSevenTrackRequestAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const trackAPIResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (trackAPIResponseJson.data) {
                if (this.state.isReject) {
                    /* istanbul ignore next*/
                    this.setState({
                        showAlert: true,
                        alertType: "success",
                        alertMessage: `Your request has been moved to ${this.humanize(this.state.selectedStepFromReject)} stage`
                    })
                    const setpNumber = this.getStepNumberforReject(this.state.selectedStepFromReject)
                    setTimeout(() => {
                        this.props.onReject(setpNumber);
                    }, 700)
                } else {
                    this.setState({
                        showAlert: true,
                        alertType: "success",
                        alertMessage: this.getStageSevenAlertMessage(false),
                        showNoteDialog: false
                    })
                }

            } else if (trackAPIResponseJson.errors) {
                this.setStageSevenApiErrorMessage(trackAPIResponseJson)
            }
        }
    }
    /* istanbul ignore next*/
    setFlowFiveData = (data: any) => {
        const { attributes } = data;
        const flowFiveData = {
            policyId: attributes?.policy_request_id,
            paid_amount: attributes?.payment_insurance_infos.data?.attributes?.paid_amount,
            deduct_commission: attributes?.payment_insurance_infos.data?.attributes?.deduct_commission_from_payment ? "yes" : "no",
            flow: Number(attributes?.flow),
            payment_reciepent: attributes?.payment_insurance_infos.data?.attributes?.payment_recipient,
            flowFiveData: {
                save_as_draft: attributes?.save_as_draft,
                // payment_insurance_infos
                insurance_company_id: attributes?.payment_insurance_infos.data?.attributes?.insurance_company_id.id,
                balance: attributes?.payment_insurance_infos.data?.attributes?.balance,
                payment_date: attributes?.payment_insurance_infos.data?.attributes?.payment_date,
                policy_release_requested: attributes?.payment_insurance_infos.data?.attributes?.policy_release_requested?"yes":"no",
                payment_voucher_doc: attributes?.payment_voucher?.data[0]?.attributes?.payment_voucher,
                payment_date_2: attributes?.payment_voucher?.data[0]?.attributes?.payment_voucher_date,
                // payment_voucher | array
                voucher_payment_date: attributes?.payment_voucher?.data[0]?.attributes?.payment_date,
                payment_voucher_id: attributes?.payment_voucher?.data[0]?.attributes?.payment_voucher_id,
                insurance_receipt_number: attributes?.payment_voucher?.data[0]?.attributes?.insurer_recipient_number,
                payable_to_insurer: attributes?.payment_voucher?.data[0]?.attributes?.payable_to_insurer,
                voucher_proof_of_payment_doc: attributes?.payment_voucher?.data[0]?.attributes?.proof_of_payment_to_insurer,
                // commission
                vat_on_siib_commission: attributes?.commission?.data?.attributes?.deduct_commission_with_vat,
                commission_rate: attributes?.commission?.data?.attributes?.commission_rate,
                commission_amount: attributes?.commission?.data?.attributes?.commission_amount,
                invoice_date: attributes?.commission?.data?.attributes?.invoice_date,
                siib_invoice_id: attributes?.commission?.data?.attributes?.siib_invoice_id,
                invoice_doc: attributes?.commission?.data?.attributes?.invoice,
                // reciept
                receipt_date: attributes?.reciept?.data?.attributes?.receipt_date,
                siib_receipt_id: attributes?.reciept?.data?.attributes?.siib_receipt_id,
                receipt_doc: attributes?.reciept?.data?.attributes?.receipt,
                // Data Ids for edit data
                payment_voucher: attributes?.payment_voucher?.data[0]?.id,
                commission: attributes?.commission?.data.id,
                reciept: attributes?.reciept?.data.id,
            }
        }
        this.setState({ ...flowFiveData },()=>{
            const values= this.updateInitialValues()
            this.checkStep(values,this.props.flowValue as number)
         })
    }

    /* istanbul ignore next*/
    setFlowSixData = (data: any) => {
        //set the data
        const { attributes } = data;
        const { payment_voucher: payment_voucher_api, commission: commission_api, reciept: reciept_api } = attributes;
        const customer_revised_voucher_api = payment_voucher_api.data?.filter((data: any) => data?.attributes?.voucher_to === 'customer')[0];
        const insurer_voucher_api = payment_voucher_api.data?.filter((data: any) => data?.attributes?.voucher_to === 'insurer' && data?.attributes?.revised === false)[0];
        const insurer_revised_voucher_api = payment_voucher_api.data?.filter((data: any) => data?.attributes?.voucher_to === 'insurer' && data?.attributes?.revised === true)[0];

        const flowSixData = {
            policyId: attributes?.policy_request_id,
            paid_amount: attributes?.payment_insurance_infos.data.attributes.paid_amount,
            deduct_commission: attributes.payment_insurance_infos.data.attributes.deduct_commission_from_payment ? "yes" : "no",
            flow: Number(attributes.flow),
            payment_reciepent: attributes.payment_insurance_infos.data.attributes.payment_recipient,
            flowSixData: {
                save_as_draft: attributes.save_as_draft,
                // payment_insurance_infos
                insurance_company_id: attributes.payment_insurance_infos?.data?.attributes?.insurance_company_id?.id,
                balance: attributes.payment_insurance_infos.data.attributes.balance,
                policy_release_requested: attributes?.payment_insurance_infos?.data.attributes?.policy_release_requested ? 'yes' : 'no',
                
                revised_insurance_receipt_number_1: attributes?.payment_insurance_infos?.data.attributes?.insurance_receipt_number,
                revised_voucher_payment_date_1: attributes?.payment_insurance_infos?.data.attributes?.payment_date,
                revised_voucher_proof_of_payment_doc_1: insurer_revised_voucher_api.attributes?.proof_of_payment_to_insurer,
                revised_payment_voucher_id_1: insurer_revised_voucher_api?.attributes.payment_voucher_id,
                revised_payment_voucher_1: insurer_revised_voucher_api?.attributes?.payment_voucher,
                // payment_voucher
                payable_to_insurer: insurer_voucher_api?.attributes?.payable_to_insurer,
                payment_date_2: insurer_voucher_api?.attributes?.payment_voucher_date,
                payment_voucher_doc: insurer_voucher_api?.attributes.payment_voucher,
                payment_voucher_id: insurer_voucher_api?.attributes.payment_voucher_id,
                insurance_receipt_number: insurer_voucher_api?.attributes?.insurer_recipient_number,
                voucher_payment_date: insurer_voucher_api?.attributes.payment_date,
                voucher_proof_of_payment_doc: insurer_voucher_api?.attributes?.proof_of_payment_to_insurer,
                // commission
                vat_on_siib_commission: commission_api?.data?.attributes?.deduct_commission_with_vat,
                commission_rate: commission_api?.data?.attributes?.commission_rate,
                commission_amount: commission_api?.data?.attributes?.commission_amount,
                invoice_date: commission_api?.data?.attributes?.invoice_date,
                siib_invoice_id: commission_api?.data?.attributes?.siib_invoice_id,
                invoice_doc: commission_api?.data?.attributes?.invoice,
                // reciept
                receipt_date: reciept_api?.data?.attributes?.receipt_date,
                siib_receipt_id: reciept_api?.data?.attributes?.siib_receipt_id,
                receipt_doc: reciept_api?.data?.attributes?.receipt,
                // revised_payment_voucher
                revised_payment_voucher_id_0: customer_revised_voucher_api?.attributes?.payment_voucher_id,
                revised_payment_voucher_0: customer_revised_voucher_api?.attributes?.payment_voucher,
                revised_insurance_receipt_number_0: customer_revised_voucher_api?.attributes?.insurer_recipient_number,
                revised_voucher_payment_date_0: customer_revised_voucher_api?.attributes?.payment_date,
                revised_voucher_proof_of_payment_doc_0: customer_revised_voucher_api?.attributes?.proof_of_payment_refund_to_cust,

                revised_payment_voucher_edit_id: insurer_revised_voucher_api?.id,
                revised_payment_voucher_edit_id_2: insurer_voucher_api?.id,
            }
        };
        this.setState({ ...flowSixData },()=>{
            const values= this.updateInitialValues()
            this.checkStep(values,this.props.flowValue as number)
         })
    }

    /* istanbul ignore next*/
    setFlowSevenData = (data: any) => {
        //set the data
        let modifiedArray: any = []
        data?.attributes?.installments?.data.map((item: any, index: number) => {
            modifiedArray.push({
                isAlreadyPaid: item?.attributes?.installment_paid,
                installment_amount: item?.attributes?.installment_amount,
                due_date: item?.attributes?.due_date,
                installment_paid: item?.attributes?.installment_paid ? "yes" : "no",
                payment_date_2: item?.attributes?.insurer_receipt_date,
                insurance_receipt_number: item?.attributes?.insurer_receipt_number,
                showInstallmentAmount: item?.attributes?.installment_paid,
                proof_of_payment_doc: item?.attributes?.proof_of_payment,
                id: item?.id,

            })
        })
        this.setState({
            paid_amount: data?.attributes?.payment_insurance_infos?.data?.attributes?.paid_amount,
            payment_reciepent: data?.attributes?.payment_insurance_infos?.data.attributes?.payment_recipient,
            flowSevenData: {
                payment_date: data?.attributes?.payment_insurance_infos?.data?.attributes?.payment_date,
                insure_recipent_number: data?.attributes?.payment_insurance_infos?.data?.attributes?.insurance_receipt_number,
                policy_release_requested: data?.attributes?.payment_insurance_infos?.data?.attributes.policy_release_requested ? 'yes' : 'no',
                proof_of_payment_doc: data?.attributes?.payment_insurance_infos?.data?.attributes?.proof_of_payment,
                installment_attributes: modifiedArray,
                balance: 0
            },
        },()=>{
            const values= this.updateInitialValues()
            this.checkStep(values,this.props.flowValue as number)
         })

    }

    /* istanbul ignore next*/
    setFlowEightData = (data: any) => {
        //set the data
        let modifiedArray: any = []
        data?.attributes?.installments?.data.map((item: any, index: number) => {
            modifiedArray.push({
                isAlreadyPaid: item?.attributes?.installment_paid,
                installment_amount: data?.attributes?.installments?.data[index]?.attributes?.installment_amount,
                due_date: data?.attributes?.installments?.data[index]?.attributes?.due_date,
                installment_paid: data?.attributes?.installments?.data[index]?.attributes?.installment_paid ? "yes" : "no",
                siib_receipt_number: data?.attributes?.installments?.data[index]?.attributes?.siib_receipt_number,
                payment_voucher_date: data?.attributes?.installments?.data[index]?.attributes?.payment_voucher?.data?.attributes?.payment_voucher_date,
                payment_date: data?.attributes?.installments?.data[index]?.attributes?.payment_voucher?.data?.attributes?.payment_date,
                proof_of_payment_doc: data?.attributes?.installments?.data[index]?.attributes?.proof_of_payment,
                insurer_receipt_number: data?.attributes?.installments?.data[index]?.attributes?.payment_voucher?.data?.attributes?.insurer_recipient_number,
                voucher_payment_date: data?.attributes?.installments?.data[index]?.attributes?.payment_voucher?.data?.attributes?.payment_date,
                payment_date_2: data?.attributes?.installments?.data[index]?.attributes?.payment_date,
                payment_voucher: data?.attributes?.installments?.data[index]?.attributes?.payment_voucher?.data?.attributes?.payment_voucher,
                payment_voucher_id: data?.attributes?.installments?.data[index]?.attributes?.payment_voucher?.data?.attributes?.payment_voucher_id,
                voucher_proof_of_payment_doc: data?.attributes?.installments?.data[index]?.attributes?.payment_voucher?.data?.attributes?.proof_of_payment_to_insurer,
                showInstallmentAmount: data?.attributes?.installments?.data[index]?.attributes?.installment_paid,
                id: item?.id,
            })
        })
        this.setState({
            paid_amount: data?.attributes?.payment_insurance_infos?.data?.attributes?.paid_amount,
            payment_reciepent: data?.attributes?.payment_insurance_infos?.data?.attributes?.payment_recipient,
            deduct_commission: data?.attributes?.payment_insurance_infos?.data?.attributes?.deduct_commission_from_payment,
            flowEightData: {
                siib_receipt_number: data?.attributes?.payment_insurance_infos?.data?.attributes?.siib_receipt_number,
                payment_date: data?.attributes?.payment_insurance_infos?.data?.attributes?.payment_date,
                proof_of_payment_doc: data?.attributes?.payment_insurance_infos?.data?.attributes?.proof_of_payment,
                insurance_receipt_number: data?.attributes?.payment_voucher?.data[0]?.attributes?.insurer_recipient_number,
                voucher_payment_date: data?.attributes?.payment_voucher?.data[0]?.attributes?.payment_date,
                payment_date_2: data?.attributes?.payment_voucher?.data[0]?.attributes?.payment_voucher_date,
                payment_voucher: data?.attributes?.payment_voucher?.data[0]?.attributes?.payment_voucher,
                payment_voucher_id: data?.attributes?.payment_voucher?.data[0]?.attributes?.payment_voucher_id,
                voucher_proof_of_payment_doc: data?.attributes?.payment_voucher?.data[0]?.attributes?.proof_of_payment_to_insurer,
                policy_release_requested: data?.attributes?.payment_insurance_infos?.data?.attributes?.policy_release_requested ? 'yes' : 'no',
                installment_attributes: modifiedArray,
                balance: 0
            },
        },()=>{
            const values= this.updateInitialValues()
            this.checkStep(values,this.props.flowValue as number)
         })
    }

    /* istanbul ignore next*/
    setFlowNineData = (data: any) => {
        let modifiedArray: any = []
        data?.attributes?.installments?.data.map((item: any, index: number) => {
            modifiedArray.push({
                isAlreadyPaid: item?.attributes?.installment_paid,
                installment_amount: data.attributes.installments.data[index].attributes.installment_amount,
                due_date: data.attributes.installments.data[index].attributes.due_date,
                installment_paid: data.attributes.installments.data[index].attributes.installment_paid ? "yes" : "no",
                commission_rate: data.attributes.installments.data[index].attributes.commission.data.attributes.commission_rate,
                commission_amount: data.attributes.installments.data[index].attributes.commission.data.attributes.commission_amount,
                vat_on_siib_commission: data.attributes.installments.data[index].attributes.commission.data.attributes.deduct_commission_with_vat,
                invoice_doc: data?.attributes?.installments.data[index]?.attributes?.commission?.data?.attributes?.invoice,
                receipt_doc: data?.attributes?.installments.data[index]?.attributes?.reciept?.data?.attributes?.receipt,
                invoice_date: data.attributes.installments.data[index].attributes.commission.data.attributes.invoice_date,
                receipt_date: data.attributes.installments.data[index].attributes.reciept.data.attributes.receipt_date,
                siib_invoice_id: data.attributes.installments.data[index].attributes.commission.data.attributes.siib_invoice_id,
                siib_receipt_id: data.attributes.installments.data[index].attributes.reciept.data.attributes.siib_receipt_id,
                payable_to_insurer: data.attributes.installments.data[index].attributes.payment_voucher.data.attributes.payable_to_insurer,
                insurer_receipt_number: data.attributes.installments.data[index].attributes.payment_voucher.data.attributes.insurer_recipient_number,
                voucher_payment_date: data.attributes.installments.data[index].attributes.payment_voucher.data.attributes.payment_date,
                proof_of_payment_doc: data?.attributes?.installments?.data?.[index]?.attributes?.payment_voucher?.data?.attributes?.proof_of_payment_to_insurer,
                showInstallmentAmount: data.attributes.installments.data[index].attributes.installment_paid,
                payment_date_2: data?.attributes?.installments?.data?.[index]?.attributes?.payment_voucher?.data?.attributes?.payment_voucher_date,
                payment_voucher: data?.attributes?.installments?.data?.[index]?.attributes?.payment_voucher?.data?.attributes?.payment_voucher,
                payment_voucher_id: data?.attributes?.installments?.data?.[index]?.attributes?.payment_voucher?.data?.attributes?.payment_voucher_id,
                id: item?.id,
            })
        })
        this.setState({
            paid_amount: data?.attributes.payment_insurance_infos.data.attributes.paid_amount,
            payment_reciepent: data?.attributes.payment_insurance_infos.data.attributes.payment_recipient,
            deduct_commission: data?.attributes.payment_insurance_infos.data.attributes.deduct_commission_from_payment,
            flowNineData: {
                commission_rate: data.attributes.commission.data.attributes.commission_rate,
                commission_amount: data.attributes.commission.data.attributes.commission_amount,
                vat_on_siib_commission: data.attributes.commission.data.attributes.deduct_commission_with_vat,
                invoice_doc: data?.attributes?.commission?.data?.attributes?.invoice,
                invoice_date: data.attributes?.commission?.data.attributes?.invoice_date ,
                receipt_doc: data?.attributes?.reciept?.data?.attributes?.receipt,
                receipt_date: data.attributes.reciept.data.attributes.receipt_date,
                siib_invoice_id: data.attributes.commission.data.attributes.siib_invoice_id,
                siib_receipt_id: data.attributes.reciept.data.attributes.siib_receipt_id,
                payable_to_insurer: data.attributes.payment_voucher.data[0].attributes.payable_to_insurer,
                payment_date_2: data.attributes.payment_voucher.data[0].attributes.payment_voucher_date,
                payment_voucher: data.attributes.payment_voucher.data[0].attributes.payment_voucher,
                payment_voucher_id: data.attributes.payment_voucher.data[0].attributes.payment_voucher_id,
                insurance_receipt_number: data.attributes.payment_voucher.data[0].attributes.insurer_recipient_number,
                voucher_payment_date: data?.attributes?.payment_voucher?.data[0]?.attributes?.payment_date,
                voucher_proof_of_payment_doc: data?.attributes?.payment_voucher?.data[0]?.attributes?.proof_of_payment_to_insurer,
                policy_release_requested: data?.attributes.payment_insurance_infos.data.attributes.policy_release_requested ? 'yes' : 'no',
                installment_attributes: modifiedArray,
                balance: 0
            },
        },()=>{
            const values= this.updateInitialValues()
            this.checkStep(values,this.props.flowValue as number)
         })
    }

    setArabicLanguage = (value: boolean) => {
        const language = value === true ? "ar" : "en";
        window.localStorage.setItem("lang", language);
        this.setState({
          isArabicLanguage: value,
        });
      };

    fileUploadAPIResponse = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.stageSevenFileUploadAPIResponseKey != null &&
            this.stageSevenFileUploadAPIResponseKey ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const fileUploadStageSevenAPIResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (fileUploadStageSevenAPIResponse.data) {
                this.formikRef.current.setFieldValue(this.fieldName, fileUploadStageSevenAPIResponse.data?.uploaded_file)
                this.checkStep(this.formikRef.current.values, this.props.flowValue as number)
                const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
                /* istanbul ignore next*/this.setState({
                    showAlert: true,
                    alertMessage: isArabic ? "تم تحميل الملف بنجاح" : "File successfully uploaded",
                    alertType: "success"
                })
            } else if (fileUploadStageSevenAPIResponse.errors) {
                this.setStageSevenApiErrorMessage(fileUploadStageSevenAPIResponse)
            }
        }
    }
    createPaymentVoucherApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.createPaymentVoucherApi != null &&
            this.createPaymentVoucherApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const generatePaymentVoucherAPIResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (generatePaymentVoucherAPIResponse.data) {
                this.checkStep(this.formikRef.current.values, this.props.flowValue as number)
                this.formikRef.current.setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_voucher`, generatePaymentVoucherAPIResponse.data?.attributes?.payment_voucher_pdf)
                this.formikRef.current.setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_voucher_id`, generatePaymentVoucherAPIResponse.data?.attributes?.file_id)
                this.setState({
                    voucherBtnLoading: false,
                    showAlert: true,
                    alertMessage: this.state.isArabicLanguage ? "تم إنشاء قسيمة الدفع بنجاح" : "payment voucher successfully generated!",
                    alertType: "success"
                })
            } else if (generatePaymentVoucherAPIResponse.errors) {
                this.setState({
                    voucherBtnLoading: false
                })
                this.setStageSevenApiErrorMessage(generatePaymentVoucherAPIResponse)
            }
        }
    }

    createRevisedPaymentVoucherApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.createRevisedPaymentVoucherApi != null &&
            this.createRevisedPaymentVoucherApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const generatePaymentVoucherAPIResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (generatePaymentVoucherAPIResponse.data) {
                const revisedPaymentVoucherDoc = `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_payment_voucher_${this.state.revisedVoucherIndex}`
                const revisedPaymentVoucherId = `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_payment_voucher_id_${this.state.revisedVoucherIndex}`

                this.checkStep(this.formikRef.current.values, this.props.flowValue as number)
                this.setState({
                    voucherBtnLoadingForRevisedPayment: false,
                    revisedVoucherIndex: 0,
                    showAlert: true,
                    alertMessage: this.state.isArabicLanguage ? "تم إنشاء القسيمة المنقحة بنجاح" : "revised payment voucher successfully generated!",
                    alertType: "success"
                });
                this.formikRef.current.setFieldValue(revisedPaymentVoucherDoc, generatePaymentVoucherAPIResponse.data?.attributes?.revised_payment_voucher_pdf)
                this.formikRef.current.setFieldValue(revisedPaymentVoucherId, generatePaymentVoucherAPIResponse.data?.attributes?.file_id)
            } else if (generatePaymentVoucherAPIResponse.errors) {
                this.setState({ voucherBtnLoadingForRevisedPayment: false, revisedVoucherIndex: 0 });
                this.setStageSevenApiErrorMessage(generatePaymentVoucherAPIResponse)
            }
        }
    }
    generateRevisedPaymentVoucherForInsurerApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.generateRevisedPaymentVoucherForInsurerApi != null &&
            this.generateRevisedPaymentVoucherForInsurerApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const generatePaymentVoucherAPIResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (generatePaymentVoucherAPIResponse.data) {
                const revisedPaymentVoucherDocForInsurer = `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_proof_of_payment_doc`
                const revisedPaymentVoucherIdForInsurer = `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.revised_proof_of_payment_doc_id`

                this.checkStep(this.formikRef.current.values, this.props.flowValue as number)
                this.setState({
                    voucherInsuranceBtnLoading:false,
                    showAlert: true,
                    alertMessage: this.state.isArabicLanguage ? "تم إنشاء القسيمة المنقحة بنجاح" : "revised payment voucher successfully generated!",
                    alertType: "success"
                });
                this.formikRef.current.setFieldValue(revisedPaymentVoucherDocForInsurer, generatePaymentVoucherAPIResponse.data?.attributes?.revised_payment_voucher_pdf)
                this.formikRef.current.setFieldValue(revisedPaymentVoucherIdForInsurer, generatePaymentVoucherAPIResponse.data?.attributes?.file_id)
            } else if (generatePaymentVoucherAPIResponse.errors) {
                this.setState({voucherInsuranceBtnLoading:false});
               this.setStageSevenApiErrorMessage(generatePaymentVoucherAPIResponse)
            }
        }
    }

    createInvoiceApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.generateInvoiceApi != null &&
            this.generateInvoiceApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const generateInvAndRcptAPIResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (generateInvAndRcptAPIResponse.data) {
                const InvoiceDoc = `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.invoice_doc`
                const InvoiceId = `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.siib_invoice_id`
                const InstallmentInvoiceDoc = `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${this.state.installmentIndex}].invoice_doc`
                const InstallmentInvoiceId = `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${this.state.installmentIndex}].siib_invoice_id`

                if(this.state.installmentIndex !== undefined){
                    this.formikRef.current.setFieldValue(InstallmentInvoiceDoc, generateInvAndRcptAPIResponse.data?.attributes?.invoice_pdf)
                    this.formikRef.current.setFieldValue(InstallmentInvoiceId, generateInvAndRcptAPIResponse.data?.attributes?.file_id)
                }else{
                    this.formikRef.current.setFieldValue(InvoiceDoc, generateInvAndRcptAPIResponse.data?.attributes?.invoice_pdf)
                    this.formikRef.current.setFieldValue(InvoiceId, generateInvAndRcptAPIResponse.data?.attributes?.file_id)
                }

                this.checkStep(this.formikRef.current.values, this.props.flowValue as number)
                this.setState({
                    invAndRcptBtnLoadingForInvoice: false,
                    showAlert: true,
                    alertMessage: this.state.isArabicLanguage ? "تم إنشاء الفاتورة بنجاح" : "invoice successfully generated!",
                    alertType: "success"
                });

            } else if (generateInvAndRcptAPIResponse.errors) {
                this.setState({ invAndRcptBtnLoadingForInvoice: false });
                this.setStageSevenApiErrorMessage(generateInvAndRcptAPIResponse)
            }
        }
    }

    createReceiptApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.generateReceiptApi != null &&
            this.generateReceiptApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const generateInvAndRcptAPIResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (generateInvAndRcptAPIResponse.data) {
                const ReceiptDoc = `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.receipt_doc`
                const ReceiptId = `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.siib_receipt_id`
                const InstallmentReceiptDoc = `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${this.state.installmentIndex}].receipt_doc`
                const InstallmentReceiptId = `stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${this.state.installmentIndex}].siib_receipt_id`

                if(this.state.installmentIndex !== undefined){
                    this.formikRef.current.setFieldValue(InstallmentReceiptId, generateInvAndRcptAPIResponse.data?.attributes?.file_id)
                    this.formikRef.current.setFieldValue(InstallmentReceiptDoc, generateInvAndRcptAPIResponse.data?.attributes?.receipt_pdf)
                }else{
                    this.formikRef.current.setFieldValue(ReceiptId, generateInvAndRcptAPIResponse.data?.attributes?.file_id)
                    this.formikRef.current.setFieldValue(ReceiptDoc, generateInvAndRcptAPIResponse.data?.attributes?.receipt_pdf)
                }
                this.checkStep(this.formikRef.current.values, this.props.flowValue as number)
                this.setState({
                    invAndRcptBtnLoadingForReceipt: false,
                    showAlert: true,
                    alertMessage: this.state.isArabicLanguage ? "تم إنشاء الإيصال بنجاح" : "receipt successfully generated!",
                    alertType: "success"
                });
            } else if (generateInvAndRcptAPIResponse.errors) {
                this.setState({ invAndRcptBtnLoadingForReceipt: false });
                this.setStageSevenApiErrorMessage(generateInvAndRcptAPIResponse)
            }
        }
    }

    createPaymetVoucherForFlow5And6ApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.createPaymentVoucherForFlow5And6Api != null &&
            this.createPaymentVoucherForFlow5And6Api ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const generatePaymentVoucherAPIResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (generatePaymentVoucherAPIResponse.data) {
                this.formikRef.current.setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_voucher_doc`, generatePaymentVoucherAPIResponse.data?.attributes?.payment_voucher_pdf)
                this.formikRef.current.setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.payment_voucher_id`, generatePaymentVoucherAPIResponse.data?.attributes?.file_id)
                this.checkStep(this.formikRef.current.values, this.props.flowValue as number)
                this.setState({
                    voucherBtnLoading: false,
                    showAlert: true,
                    alertMessage: this.state.isArabicLanguage ? "تم إنشاء قسيمة الدفع بنجاح" : "payment voucher successfully generated!",
                    alertType: "success"
                })
            } else if (generatePaymentVoucherAPIResponse.errors) {
                this.setState({
                    voucherBtnLoading: false
                })
                this.setStageSevenApiErrorMessage(generatePaymentVoucherAPIResponse)
            }
        }
    }
    generateVoucherForSiibInstallmentApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.generateVoucherForSiibInstallmentApi !== null &&
            this.generateVoucherForSiibInstallmentApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    voucherBtnLoading: false,
                    showAlert: true,
                    alertMessage: this.state.isArabicLanguage ? "تم إنشاء قسيمة الدفع بنجاح" : "payment voucher successfully generated!",
                    alertType: "success"
                })
                this.formikRef.current.setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${this.state.siibCurrentInstallmentIndex}].payment_voucher`, responseJson.data.attributes.payment_voucher_pdf);
                this.formikRef.current.setFieldValue(`stageData.flow${this.convertToWord(this.props.flowValue as number)}Data.installment_attributes[${this.state.siibCurrentInstallmentIndex}].payment_voucher_id`, String(responseJson.data.attributes.file_id));
            } else {
                this.setState({
                    voucherBtnLoading: false
                })
                this.setStageSevenApiErrorMessage(responseJson)
            }
        }
    }
    async receive(from: string, message: Message) {
        this.removeFileAPIReceiveSeven(message)
        this.createAPIResponseStageSeven(message)
        this.updateAPIResponseStageSeven(message)
        this.stageSevenTrackAPIResponse(message)
        this.fileUploadAPIResponse(message)
        this.createPaymentVoucherApiReceive(message);
        this.createRevisedPaymentVoucherApiReceive(message);
        this.createInvoiceApiReceive(message);
        this.createReceiptApiReceive(message);
        this.generateRevisedPaymentVoucherForInsurerApiReceive(message);
        this.createPaymetVoucherForFlow5And6ApiReceive(message);
        this.generateVoucherForSiibInstallmentApiReceive(message);
    }
}
// Customizable Area End