import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Tooltip,
    Typography
} from "@material-ui/core";
import { Formik } from "formik";
import { ExpandMore, Description, ErrorOutlineRounded } from '@material-ui/icons'
// @ts-ignore
import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import StageThreeController, { Props } from "../../../blocks/TaskAllocator/src/PolicyStage/StageThreeController";
import LabelWithIcon from "../Common/LabelWithIcon";
import TextInput from "../Common/TextInput";
import CustomButton from "../Common/CustomButton";
import HandOverDialog from "../Common/HandOverDialog.web";
import RejectRequestDialog from "../Common/RejectRequestDialog.web";
import Toster from "../Common/Toster";
import * as Yup from 'yup';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';


const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

const CustomToolTip = withStyles({
    arrow: {
        "&::before": {
            color: "white"
        }
    },
    tooltip: {
        backgroundColor: "#f5f5f9",
        boxShadow: theme.shadows[8],
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: 14,
        maxWidth: 800
    },
    tooltipPlacementTop: {
        margin: "4px 0"
    }
})(Tooltip);

export class StageThreeForm extends StageThreeController {
    formik3ref: any;
    constructor(props: Props) {
        super(props);
        this.formik3ref = React.createRef();
    }
    handleSubmitFromOutside = () => {
        const { current: formikInstance } = this.formik3ref;
        if (formikInstance) {
            formikInstance.handleSubmit();
        }
    }

    render() {

        // @ts-ignore
        const { isEdit, t, classes, isExpanded } = this.props;
        const pushBackOption = [
            { value: "info_gathering", label: t('info_gathering') },
            { value: "processing", label: t('processing') }
        ];
        const stageTwoData = JSON.parse(
            localStorage.getItem("stageTwoData") ?? "{}"
        );

        const stageThreeData = JSON.parse(localStorage.getItem('stageThreeData') || '{}');


        return (
            <ThemeProvider theme={theme} >
                <React.Fragment>
                    <Toster
                        tosterText={this.state.alertMessage}
                        showToster={this.state.showAlert}
                        // @ts-ignore
                        tosterType={this.state.alertType}
                        handleCloseToster={() => this.setState({ showAlert: false })}
                    />
                    <div className={classes.accordianGap}>
                        <Accordion
                            data-test-id='p1'
                            className={classes.accordianSection}
                            expanded={(this.state.expandedAccordion)}
                            disabled={this.state.disableAccordian}
                            onChange={() => {
                                this.handleAccordian()
                            }} >
                            <AccordionSummary
                                aria-expanded={true}
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={classes.topSection}>
                                    <Typography className={classes.accordianHeading} >{t('awaiting_quotation')}</Typography>
                                    <div className={classes.iconAlignment}>
                                        {this.state.expandedAccordion && <DescriptionOutlinedIcon onClick={this.openNoteDialog} />}
                                        {this.state.expandedAccordion && stageThreeData?.attributes?.is_reject &&
                                            <ErrorOutlineRounded style={{ position: 'relative', left: '10px' }} onClick={() => this.setState({ isBtnVisible: false, showRejectDialog: true })} />
                                        }
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Formik
                                    innerRef={this.formik3ref}
                                    enableReinitialize={true}
                                    initialValues={{
                                        updateComments: this.state.stageData.updateComments,
                                    }}
                                    validateOnMount={true}
                                    validateOnBlur={true}
                                    onSubmit={async (values, actions) => {
                                        try {
                                            if (isEdit || this.state.isDraftAPIUsed) {
                                                await this.modifyUpdatedComments({ comment: values }, this.policyId as number)
                                            } else {
                                                await this.saveUpdateComment({ comment: values }, this.policyId as number)
                                            }
                                        } catch (error) {
                                            //ignore
                                        }
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        errors,
                                        touched,
                                        values,
                                        setFieldValue,
                                        validateForm
                                    }) => (
                                        <React.Fragment>
                                            <div className={classes.awatingStage}>
                                                <Grid container className={classes.contactForm} >
                                                    <Grid item xs={12}>
                                                        <LabelWithIcon label={t("post_updates_on_submissions_here_if_any")} />
                                                        <TextInput
                                                            id={"updateComments"}
                                                            className={classes.textInputStyle}
                                                            name="updateComments"
                                                            fullWidth
                                                            type="text"
                                                            disabled={!this.props.isExpanded}
                                                            multiline={true}
                                                            minRows={4}
                                                            data-test-id="updateCommentsTxtInput"
                                                        />
                                                        {touched.updateComments && errors.updateComments ? (
                                                            <Typography color="error" >{errors.updateComments}</Typography>
                                                        ) : null}
                                                    </Grid>
                                                </Grid>



                                            </div>
                                        </React.Fragment>
                                    )}
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                        {(this.state.expandedAccordion && !this.state.isViewOnly && isExpanded) && (
                            <div className={classes.displayFlexEnd}>
                                <div className={classes.btnStyle}>
                                    <CustomButton
                                        btnText={t('save_draft')}
                                        onClick={() => this.saveDraftData(this.formik3ref)}
                                        bgColor={'#fff'}
                                        textColor={'#007E98'}
                                    />
                                </div>
                                <div className={classes.btnStyle}>
                                    <CustomButton
                                        btnText={t('reject')}
                                        onClick={() => this.openRejectDialog()}
                                        bgColor={'#fff'}
                                        textColor={'#007E98'}
                                    />
                                </div>
                                <div>
                                    <CustomButton
                                        btnText={t('next_stage')}
                                        onClick={() => this.handleSubmitFromOutside()}
                                        bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                        textColor={'#fff'}
                                    />
                                </div>

                            </div>
                        )}
                    </div>

                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNoteDialog as boolean}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={this.state.isBtnVisible?`(${t('awaiting')} > ${t('submitting_quotation')})`:`(${t('processing')} > ${t('awaiting')})`}
                        handleInputText={this.handleHandOverNoteText}
                        inputLable={this.state.showNoteDialog ? t('note') : ""}
                        inputValue={this.state.isBtnVisible ? this.state.handOverNoteText : stageTwoData.attributes?.handover_note_awaiting}
                        handleOk={(value: string) => this.handOverNoteSubmit(value)}
                        inputDisable={!isExpanded}
                        handleCancel={this.closeNoteDialog}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                    />
                    <RejectRequestDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showRejectDialog as boolean}
                        textId={'rejectReason'}
                        pushBackOptions={pushBackOption}
                        inputValue={this.state.isBtnVisible ? this.state.rejectReasonText : stageThreeData?.attributes?.rejected_reason}
                        setSelectedPushBackTo={this.moveToSelectedStep}
                        stepValue={this.state.moveToStep}
                        headingText={t('rejecting')}
                        handleInputText={this.handleRejectReasonText}
                        handleOk={this.successCloseRejectDialog}
                        handleCancel={this.closeRejectDialog}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                        labelText={t('push_back')}
                        reasonLabel={t('reason')}
                    />
                </React.Fragment>

            </ThemeProvider >
        )
    }
}
const useStyles = ({
    awatingStage: {
        display: 'flex',
        "flex-flow": 'column',
        width: '100%'
    },
    displayFlexEnd: { display: "flex", justifyContent: "end", margin: '16px 0' },
    accordianHeading: {
        fontWeight: 600
    },
    displayFlex: {
        display: "flex",
    },
    contactForm: { width: "100%" },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    btnStyle: {
        margin: '0 10px'
    },
    topSection: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '& svg': {
            color: '#0090a1'
        }
    },
    iconAlignment: {
        display: 'flex',
        marginRight: "10px"
    },
    lostIcon: {
        marginLeft: "15px",
        '& svg': {
            color: '#0090a1'
        },
    },
    descriptionIcon: {
        color: '#007E98'
    },
    accordianGap: {
        padding: "10px 0"
    },
    accordianSection: {
        "border-radius": "10px !important",
        '&::before': {
            content: '" "',
            display: 'none'
        }
    },

});

export default withStyles(useStyles)(withTranslation()(StageThreeForm));