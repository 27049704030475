import React from "react";
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import PaymentReviewController, { Props } from "./PaymentReviewController";
import LabelWithIcon from "../../../../../../components/src/Common/LabelWithIcon";
import TextInput from "../../../../../../components/src/Common/TextInput";
import FileUpload from "../../../../../../components/src/Common/FileUpload";
import { ErrorMessage } from "formik";
import DownloadFile from "../../../../../../components/src/Common/DownloadFile";
import Toster from "../../../../../../components/src/Common/Toster";


export class PaymentReviewInsurerInstallmentForm extends PaymentReviewController {
    constructor(props: Props) {
        super(props);
    }

    instalmentFieldDisable = (isDisabled: boolean) => {
        return isDisabled || (this.props.isPayInstallment && /* istanbul ignore next*/this.props.installmentsData?.[this.props.installmentIndex as number]?.attributes?.installment_paid)
    }

    flowSixUiRender = () => {
        const { t, classes } = this.props;
        const isDisabled: any = !this.props.isExpanded || /* istanbul ignore next*/(this.props.installmentItem?.installment_paid === 'yes' ? false : true)

        return <> {this.props.flow === 6 &&
            <>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('commision_rate')} />
                        <TextInput
                            data-test-id="commission_rate"
                            disabled={this.instalmentFieldDisable(isDisabled)}
                            id="commission_rate"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][commission_rate]`}
                            type="text"
                            onChange={(event: any) => this.props.insurerInstallmentChnageHandler(event.target.value, 'commission_rate', this.props.installmentIndex, this.props.formikRef)}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][commission_rate]`}
                            className="field-error"
                            data-test-id="commission_rate__installment_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />

                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('commision_amount')} />
                        <TextInput
                            data-test-id="commission_amount"
                            disabled={true}
                            id="commission_amount"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][commission_amount]`}
                            type={"text"}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][commission_amount]`}
                            className="field-error"
                            data-test-id="commission_rate_installment_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />

                    </Grid>
                    <Grid item xs={4}>
                        <LabelWithIcon label={t('vat_on_commission')} />
                        <FormControl className={classes?.radioFormControl} style={{ marginLeft: 15, marginTop: 17 }}>
                            <RadioGroup
                                row
                                data-test-id="deduct_commission_with_vat"
                                name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][deduct_commission_with_vat]`}
                                value={this.props.installmentItem?.deduct_commission_with_vat}
                                onChange={(evt: any) => { this.props.insurerInstallmentChnageHandler(evt.target.value, 'deduct_commission_with_vat', this.props.installmentIndex, this.props.formikRef) }}
                            >
                                <FormControlLabel
                                    disabled={this.instalmentFieldDisable(isDisabled)}
                                    value={"yes"} control={<Radio color="primary" />} label={t("yes")} />
                                <FormControlLabel
                                    disabled={this.instalmentFieldDisable(isDisabled)}
                                    value={"no"} control={<Radio color="primary" />} label={t("no")} />
                            </RadioGroup>
                        </FormControl>
                        <br/>
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][deduct_commission_with_vat]`}
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                            data-test-id="deduct_commission_with_vat_error"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('invoice_date')} />
                        <TextInput
                            disabled={this.instalmentFieldDisable(isDisabled)}
                            data-test-id="invoice_date"
                            id="invoice_date"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][invoice_date]`}
                            type="date"
                            onChange={(event: any) => this.props.insurerInstallmentChnageHandler(event.target.value, 'invoice_date', this.props.installmentIndex, this.props.formikRef)}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][invoice_date]`}
                            className="field-error"
                            data-test-id="invoice_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />

                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('receipt_date')} />
                        <TextInput
                            data-test-id="receipt_date"
                            disabled={this.instalmentFieldDisable(isDisabled)}
                            id="receipt_date"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][receipt_date]`}
                            type={"date"}
                            onChange={(event: any) => this.props.insurerInstallmentChnageHandler(event.target.value, 'receipt_date', this.props.installmentIndex, this.props.formikRef)}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][receipt_date]`}
                            className="field-error"
                            data-test-id="receipt_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />

                    </Grid>
                    <Grid style={{ paddingRight: '25px', position: 'relative', top: '37px' }} item xs={4} >
                        <Button
                            data-test-id="invoice_receipt_button"
                            variant="contained"
                            color="primary"
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            disabled={this.checkInvoiceReceiptBtnDisabledForInstallment(this.props.values, this.props.installmentIndex)}
                            className={this.checkInvoiceReceiptBtnDisabledForInstallment(this.props.values, this.props.installmentIndex) ? classes.grayBtn : classes.primaryRvBtn}
                            onClick={
                                () => this.createInvoiceAndReceipt(this.props.values, this.props.flow, this.props.formikRef, this.props.installmentIndex)
                            }
                        >
                            {
                                (this.state.isinvoiceAndReceiptBtnLoading || this.state.receiptBtnLoading)
                                    ? <CircularProgress size={25} />
                                    : t("create_invoice_receipt")
                            }
                        </Button>
                        <ErrorMessage
                            data-test-id="siib_invoice_err"
                            className="field-error"
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}]['siib_invoice_id']`}
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('invoice')} />
                        <DownloadFile
                            data-test-id="invoice_doc"
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            inputName={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}]['invoice_doc']`}
                            disabled={true}
                            isEditPage={true}
                            fileName={this.props.values.fieldsData.flowFieldObj.installment_attributes[this.props.installmentIndex as number].invoice_file?.file_name || ""}
                            filePath={this.props.values.fieldsData.flowFieldObj.installment_attributes[this.props.installmentIndex as number].invoice_file?.url || ""}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}]['invoice_doc']`}
                            className="field-error"
                            data-test-id="invoice_doc_err"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('siib_invoice_id')} />
                            <TextInput
                                disabled={true}
                                className={classes.textInputStyle}
                                name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}]['siib_invoice_id']`}
                                type="text"
                                data-test-id="siib_invoice_id"
                            />

                        </React.Fragment>
                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('receipt')} />
                        <DownloadFile
                            data-test-id="receipt"
                            inputName={`receipt`}
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            disabled={true}
                            isEditPage={true}
                            fileName={this.props.values.fieldsData.flowFieldObj.installment_attributes[this.props.installmentIndex as number].receipt?.file_name || ""}
                            filePath={this.props.values.fieldsData.flowFieldObj.installment_attributes[this.props.installmentIndex as number].receipt?.url || ""}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}]['receipt']`}
                            className="field-error"
                            data-test-id="receipt_err"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <React.Fragment>
                            <LabelWithIcon label={t('siib_receipt_id')} />
                            <TextInput
                                className={classes.textInputStyle}
                                disabled={true}
                                name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}]['siib_receipt_id']`}
                                type="text"
                                data-test-id="siib_receipt_id"
                            />
                            <ErrorMessage
                                name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}]['siib_receipt_id']`}
                                className="field-error"
                                data-test-id="siib_receipt_err"
                                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />

                        </React.Fragment>
                    </Grid>
                </Grid>
                <Grid item xs={12} container className={classes.borderAlignment} style={{ marginBottom: 20 }}>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('payable_to_insurer')} />
                        <TextInput
                            data-test-id="payable_to_insurer"
                            id="payable_to_insurer"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][payable_to_insurer]`}
                            type={"text"}
                            disabled={true}
                        />
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>

                        <LabelWithIcon label={t('payment_voucher_date')} />
                        <TextInput
                            data-test-id="payment_review_payment_voucher_date"
                            id="payment_review_payment_voucher_date"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][payment_voucher_date]`}
                            type={"date"}
                            disabled={this.instalmentFieldDisable(isDisabled)}
                            onChange={(event: any) => this.props.insurerInstallmentChnageHandler(event.target.value, 'payment_voucher_date', this.props.installmentIndex, this.props.formikRef, this.props.formikRef)}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][payment_voucher_date]`}
                            className="field-error"
                            data-test-id="payment_voucher_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid item xs={4} container style={{ marginTop: 35, height: 50 }} >
                        <Button
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            color="primary"
                            data-test-id="installmentVoucher"
                            disabled={this.isPaymentVoucherBtnDisabledflow6Installments(this.props.values, this.props.installmentIndex)}
                            className={this.isPaymentVoucherBtnDisabledflow6Installments(this.props.values, this.props.installmentIndex) ? classes.grayBtn : classes.primaryRvBtn}
                            style={{ textTransform: 'none', marginRight: 25, borderRadius: 5, width: '100%', fontSize: 17, backgroundColor: "#979090", color: "white" }}
                            onClick={() => this.generatePaymentVoucherForFlowFiveInstallment(this.props.installmentIndex, this.props.flow, this.props.formikRef)}
                        >
                            {
                                this.state.isPaymentVoucherFlowFiveInstallmentBtnLoading
                                    ? <CircularProgress size={25} />
                                    : t('create_payment_voucher')}
                        </Button>
                        <ErrorMessage
                            data-test-id="payment_review_payment_voucher_id_error"
                            className="field-error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][payment_review_payment_voucher_id]`}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid item xs={4} style={{ paddingRight: '25px' }}>
                        <LabelWithIcon label={t('payment_voucher')} />
                        <FileUpload
                            data-test-id="payment_voucher"
                            fileName={this.props.installmentItem?.payment_review_payment_voucher?.file_name || ""}
                            filePath={this.props.installmentItem?.payment_review_payment_voucher?.url || ""}
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            disabled={true}
                            inputName={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][payment_review_payment_voucher]`}
                        />
                    </Grid>
                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                        <LabelWithIcon label={t('payment_voucher_id')} />
                        <TextInput
                            className={classes.textInputStyle}
                            disabled={true}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][payment_review_payment_voucher_id]`}
                            type="text"
                            data-test-id="payment_review_payment_voucher_id"
                        />

                    </Grid>
                </Grid>
            </>}
        </>
    }

    render() {
        const { t, classes } = this.props;
        const isDisabled: any = !this.props.isExpanded || (this.props.installmentItem?.installment_paid === 'yes' ? false : true)
        return (
            <>
                <Toster
                    tosterText={this.state.alertMessage}
                    showToster={this.state.showAlert}
                    tosterType={this.state.alertType}
                    handleCloseToster={this.closeAlertMessage}
                />
                <Grid item xs={12} container className={classes.btnGrid} >
                    <Grid item xs={4} className={classes.fieldAlignment} >
                        <LabelWithIcon label={t('installment_amount')} />
                        <TextInput
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][installment_amount]`}
                            type="text"
                            disabled={!this.props.isExpanded || this.props.isAmountConfigure}
                            data-test-id="installment_amount"
                            onChange={(evt: any) => this.props.insurerInstallmentChnageHandler(evt.target.value, 'installment_amount', this.props.installmentIndex, this.props.formikRef)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <div> &nbsp;SAR&nbsp;</div>
                                </InputAdornment>
                            }
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][installment_amount]`}
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                            data-test-id="installment_amount_error"
                            className="field-error"
                        />
                    </Grid>
                    <Grid item xs={4} className={classes.fieldAlignment} >
                        <LabelWithIcon label={t('due_date')} />
                        <TextInput
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][due_date]`}
                            type={"date"}
                            data-test-id="installment_due_date"
                            disabled={!this.props.isExpanded || this.props.isAmountConfigure}
                            onChange={(evt: any) => this.props.insurerInstallmentChnageHandler(evt.target.value, 'due_date', this.props.installmentIndex, this.props.formikRef)}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][due_date]`}
                            className="field-error"
                            data-test-id="due_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <LabelWithIcon label={t('installment_paid')} />
                        <FormControlLabel
                            color="primary"
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][installment_paid]`}
                            data-test-id="installment_paid"
                            control={
                                <Checkbox color="primary"
                                    disabled={!this.props.isExpanded || (this.props.installmentsData?.[this.props.installmentIndex as number]?.attributes?.installment_paid && this.props.isPayInstallment)}
                                    checked={this.props.installmentItem?.installment_paid === 'yes' ? true : false}
                                    onChange={/* istanbul ignore next*/(event: any) => {
                                        const myEvent = event;
                                        this.props.insurerInstallmentChnageHandler(myEvent.target.checked, 'installment_paid', this.props.installmentIndex,this.props.formikRef)
                                    }}
                                />
                            }
                            label={t('yes')}
                            value={'yes'}
                            style={{ marginLeft: 5, marginTop: 10 }}
                        />
                    </Grid>
                </Grid>
                {this.flowSixUiRender()}
                <Grid item xs={12} container>
                    <Grid item xs={4} className={classes.fieldAlignment} >
                        <LabelWithIcon label={t('insurer_receipt_number')} />
                        <TextInput
                            disabled={this.instalmentFieldDisable(isDisabled)}
                            data-test-id="installment_insurer_receipt_number"
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][insurer_receipt_number]`}
                            type="text"
                            onChange={(evt: any) => this.props.insurerInstallmentChnageHandler(evt.target.value,'insurer_receipt_number', this.props.installmentIndex, this.props.formikRef)}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][insurer_receipt_number]`}
                            className="field-error"
                            data-test-id="insurer_receipt_number_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid item xs={4} className={classes.fieldAlignment} >
                        <LabelWithIcon label={t("insurer_receipt_date")} />
                        <TextInput
                            disabled={this.instalmentFieldDisable(isDisabled)}
                            data-test-id="installment_payment_date"
                            id={`paymentDateTxtInput`}
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][payment_date]`}
                            type={"date"}
                            onChange={(evt: any) => this.props.insurerInstallmentChnageHandler(evt.target.value,'payment_date', this.props.installmentIndex, this.props.formikRef)}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][payment_date]`}
                            className="field-error"
                            data-test-id="payment_voucher_payment_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <LabelWithIcon label={t('proof_of_payment')} />
                        <FileUpload
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            disabled={this.instalmentFieldDisable(isDisabled)}
                            data-test-id="proofOfPayment"
                            inputName={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][installment_proof_of_payment]`}
                            fileName={this.props.installmentItem?.installment_proof_of_payment?.file_name || ""}
                            filePath={this.props.installmentItem?.installment_proof_of_payment?.url || ""}
                            onChange={(evt: any) => this.props.insurerInstallmentChnageHandler(evt.target.files[0], 'installment_proof_of_payment', this.props.installmentIndex, this.props.formikRef)}
                            onRemove={/*istanbul ignore next*/()=>{
                                this.props.formikRef?.current?.setFieldValue(`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][installment_proof_of_payment]`,{})
                                this.props.installmentItem?.installment_proof_of_payment?.id&&  this.removeFilePaymentReview(this.props.installmentItem?.installment_proof_of_payment?.id)
                                this.props.insurerInstallmentChnageHandler({}, 'installment_proof_of_payment', this.props.installmentIndex, this.props.formikRef)
                            }}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][installment_proof_of_payment]`}
                            className="field-error"
                            data-test-id="installment_proof_of_payment_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }
}

const useStyles = ({
    fieldAlignment: {
        paddingRight: '25px'
    },
    btnGrid: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: "30px",
        marginTop: "25px",
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    grayBtn: {
        "text-transform": 'none',
        padding: 13,
        borderRadius: 5,
        width: '100%',
        fontSize: 15,
        backgroundColor: "#979090",
        color: "white"
    },
    primaryRvBtn: {
        "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": 'none',
        "padding": 13,
        "borderRadius": 5,
        "width": '100%',
        "fontSize": 15,
        "color": "white"
    },
});

export default withStyles(useStyles)(withTranslation()(PaymentReviewInsurerInstallmentForm))