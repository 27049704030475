import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
const { debounce } = require("lodash");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  insurersList: any;
  page: number;
  rowPerPage: number;
  totalPage: number;
  isArchived: boolean;
  searchQuery: string;
  role: string;
  needtoShowAlert: boolean;
  isArabicLenguage: boolean;
  alertType: string;
  alertMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class InsurerListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllInsurersListId: any;
  archiveInsurerId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      insurersList: [],
      page: 1,
      rowPerPage: 10,
      totalPage: 1,
      isArchived: false,
      searchQuery: "",
      role: '',
      needtoShowAlert:false,
      alertType:"",
      alertMessage:"",
      isArabicLenguage: (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
    };
    this.checkNeedToShowAlert()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAllInsurersListId != null &&
      this.getAllInsurersListId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          insurersList: responseJson.data,
          totalPage: responseJson.meta.total_page
        });
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.archiveInsurerId != null &&
      this.archiveInsurerId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        // this.setState({customerList:responseJson.data,totalPage:responseJson.meta.total_page})
        this.getAllInsurerList();
        this.setState({needtoShowAlert:true,alertMessage: this.state.isArabicLenguage ? "تم أرشفة شركة التأمين" : "Insurer is archived" ,alertType:"success"})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getAllInsurerList();
    const loginData = JSON.parse(
      window.localStorage.getItem("loginData") || "{}"
    );

    if (loginData) {
      this.setState({ role: loginData.role });
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (prevState.searchQuery !== this.state.searchQuery) {
      this.getAllInsurerList();
    }
    if (
      prevState.page !== this.state.page ||
      prevState.rowPerPage !== this.state.rowPerPage ||
      prevState.isArchived !== this.state.isArchived
    ) {
      this.getAllInsurerList();
    }
    if (prevState.needtoShowAlert !== this.state.needtoShowAlert) {
      setTimeout(() => {
        this.setState({
          needtoShowAlert: false,
          alertType: "",
          alertMessage: "",
        });
        localStorage.removeItem('alertDetail')
      }, 10000);
    }
  }

  handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
    this.setState({ page: page });
  };

  handleRowPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ rowPerPage: parseInt(event.target.value) });
    this.setState({ page: 1 });
  };
  handleArchiveInsurerTable = () => {
    this.setState({ isArchived: !this.state.isArchived, page: 1 });
  };

  handleSearchInsurerQuery = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchInsurer = debounce((search: string) => {
      this.setState({ searchQuery: search, page: 1 });
    }, 1000);
    searchInsurer(event.target.value);
  };

  //get all insurers list
  async getAllInsurerList() {
    const { page, searchQuery, rowPerPage, isArchived } = this.state;
    const userData = JSON.parse(
      window.localStorage.getItem("loginData") || "{}"
    );
    const token = userData.token || "";

    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const insurereApiPath = `bx_block_insurance_companies/insurance_companies?page=${page}&search=${searchQuery}&archived=${isArchived}&per_page=${rowPerPage}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllInsurersListId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      insurereApiPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  archiveInsurer = async (customerId: string | undefined) => {
    const userData = JSON.parse(
      window.localStorage.getItem("loginData") || "{}"
    );
    const token = userData.token || "";

    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const archiveInsurerPath = `/bx_block_insurance_companies/insurance_companies/${customerId}/archive?archive=true`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.archiveInsurerId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      archiveInsurerPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async checkNeedToShowAlert() {
    const alertData = await JSON.parse(localStorage.getItem("alertDetail") || "{}");
    const currentPage = window.location.href.split('/').pop();
    if (alertData && currentPage === alertData.page) {
      this.setState({
        needtoShowAlert: true,
        alertType: alertData.alertType,
        alertMessage: this.state.isArabicLenguage ? alertData.arabicTxt : alertData.englishTxt
      })
    }
  }
  closeAlertMessage = async () => {
    this.setState({
      needtoShowAlert: false,
      alertMessage: "",
      alertType: ""
    })

  }

  // Customizable Area End
}
