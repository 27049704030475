import React from "react";
import {
    Container,
    Paper,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import NewPasswordForm from "../../../components/src/EmailRegistration/NewPasswordForm";
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const componentStyles = {
    centerContent: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    logoImage: {
        height: '200px',
        width: '200px'
    }
}
class NewPassword extends ForgotPasswordController {

    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>

                <div>

                    <ThemeProvider theme={theme}>
                        <Container maxWidth="sm" dir={(window.localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"} >

                            <div style={componentStyles.centerContent} >
                                <img style={componentStyles.logoImage} src="/assets/images/SIIB_WHITE_BG.jpg" alt="logo" />
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    padding: "10px 0px",
                                    width: "100%"
                                }}
                            >
                                <Paper style={{ width: '100%' }} elevation={4} >
                                    <div style={{ padding: "20px 20px" }}>
                                        {/* @ts-ignore */}
                                       <NewPasswordForm {...this.props} />
                                    </div>

                                </Paper>
                            </div>

                        </Container>
                    </ThemeProvider>
                </div>
            </React.Fragment>
        );
    }
}

export default NewPassword;