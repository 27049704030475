import React from "react";
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import PaymentReviewController from "./PaymentReviewController";
import LabelWithIcon from "../../../../../../components/src/Common/LabelWithIcon";
import TextInput from "../../../../../../components/src/Common/TextInput";
import FileUpload from "../../../../../../components/src/Common/FileUpload";
import { ErrorMessage } from "formik";
import Toster from "../../../../../../components/src/Common/Toster";

export class NoDeductedCommissionInstallmentForm extends PaymentReviewController {
    checkDisability = () => {
        const { installment_amount,
            due_date,
            installment_paid,
            insurance_receipt_number,
            insurer_receipt_date,
            proof_of_payment_installment, installment_payment_voucher } = this.props.installmentItem

        if (installment_amount !== "" &&
            due_date !== "" &&
            installment_paid === "yes" &&
            insurance_receipt_number !== "" &&
            insurer_receipt_date !== "" &&
            proof_of_payment_installment && Object.keys(installment_payment_voucher || {}).length === 0) {
            return false
        }
        return true
    }

    isInstallmentPaid = (isExpanded: boolean | undefined, isPayInstallment: boolean | undefined, isPaid: boolean = false) => {
      if(isPaid) return !isExpanded || (isPayInstallment && this.props.installmentsData[this.props.installmentIndex as number]?.attributes?.installment_paid)
      else return !isExpanded || isPayInstallment
    }

    isDisable = (isDisabled: boolean) => {

        return isDisabled || (this.props.isPayInstallment && this.props.installmentsData[this.props.installmentIndex as number]?.attributes?.installment_paid)
    }

    render() {
        const {
            t,
            classes
        } = this.props;
        const checkPaymentVoucherBtnIsDisabled = this.checkDisability()
        const isDisabled: any = !this.props.isExpanded || /* istanbul ignore next*/(this.props.installmentItem?.installment_amount !== "" && this.props.installmentItem?.due_date !=="" && this.props.installmentItem?.installment_paid === 'yes' ? false : true)
        return (
            <>
                <Toster tosterText={this.state.alertMessage} tosterType={this.state.alertType}
                    handleCloseToster={this.closeAlertMessage}
                    showToster={this.state.showAlert}
                />
                <Grid item xs={12} container className={classes.btnGrid}>
                    <Grid item xs={4} className={classes.fieldAlignment}>
                        <LabelWithIcon label={t("installment_amount")} />
                        <TextInput
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][installment_amount]`}
                            type="text"
                            disabled={this.props.isAmountConfigure}
                            data-test-id="ndcif_installment_amount"
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                this.props.flowFiveInstallmentChangeHandler(
                                    evt.target.value,
                                    "installment_amount",
                                    this.props.installmentIndex,
                                    this.props.formikRef
                                )
                            }
                            startAdornment={
                                <InputAdornment position="start">
                                    <div> &nbsp;SAR&nbsp;</div>
                                </InputAdornment>
                            }
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][installment_amount]`}
                            className="field-error"
                            data-test-id="installment_amount_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid item xs={4} className={classes.fieldAlignment}>
                        <LabelWithIcon label={t("due_date")} />
                        <TextInput
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][due_date]`}
                            type={"date"}
                            data-test-id="ndcif_due_date"
                            disabled={this.props.isAmountConfigure}
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                this.props.flowFiveInstallmentChangeHandler(
                                    evt.target.value,
                                    "due_date",
                                    this.props.installmentIndex,
                                    this.props.formikRef
                                )
                            }
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][due_date]`}
                            className="field-error"
                            data-test-id="due_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <LabelWithIcon label={t("installment_paid")} />
                        <FormControlLabel
                            color="primary"
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][installment_paid]`}
                            control={
                                <Checkbox
                                    color="primary"
                                    disabled={this.isInstallmentPaid(this.props.isExpanded, this.props.isPayInstallment, true)}
                                    checked={
                                        this.props.installmentItem.installment_paid === "yes" ? true : false
                                    }
                                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                        this.props.flowFiveInstallmentChangeHandler(
                                            evt.target.checked,
                                            "installment_paid",
                                            this.props.installmentIndex,
                                            this.props.formikRef
                                        )
                                    }
                                />
                            }
                            label={t("yes")}
                            value={"yes"}
                            style={{ marginLeft: 5, marginTop: 10 }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    style={{ marginBottom: 20, marginTop: 20 }}
                >
                    <Grid item xs={4} style={{ paddingRight: "25px" }}>
                        <LabelWithIcon label={t("insurance_receipt_number")} />
                        <TextInput
                            className={classes.textInputStyle}
                            disabled={this.isDisable(isDisabled)}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][insurance_receipt_number]`}
                            type="text"
                            data-test-id="ndcif_insurance_receipt_number"
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                this.props.flowFiveInstallmentChangeHandler(
                                    evt.target.value,
                                    "insurance_receipt_number",
                                    this.props.installmentIndex,
                                    this.props.formikRef
                                )
                            }
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][insurance_receipt_number]`}
                            className="field-error"
                            data-test-id="insurance_receipt_number_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid style={{ paddingRight: "25px" }} item xs={4}>
                        <LabelWithIcon label={t("insurer_receipt_date")} />
                        <TextInput
                            disabled={this.isDisable(isDisabled)}
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][insurer_receipt_date]`}
                            type={"date"}
                            data-test-id="ndcif_insurance_receipt_date"
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                this.props.flowFiveInstallmentChangeHandler(
                                    evt.target.value,
                                    "insurer_receipt_date",
                                    this.props.installmentIndex,
                                    this.props.formikRef
                                )
                            }
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][insurer_receipt_date]`}
                            className="field-error"
                            data-test-id="insurer_receipt_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid item xs={4}>
                    <LabelWithIcon label={t("proof_of_payment")} />
                        <FileUpload
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            disabled={this.isDisable(isDisabled)}
                            fileName={this.props.installmentItem?.proof_of_payment_installment?.file_name || ""}
                            filePath={this.props.installmentItem?.proof_of_payment_installment?.url || ""}
                            data-test-id="ndcif_proof_of_payment_installment"
                            onChange={(evt: any) =>
                                this.props.flowFiveInstallmentChangeHandler(
                                    evt.target.files[0],
                                    "proof_of_payment_installment",
                                    this.props.installmentIndex,
                                    this.props.formikRef
                                )
                            }
                            onRemove={/*istanbul ignore next*/()=>{
                                this.props.formikRef?.current?.setFieldValue(`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][proof_of_payment_installment]`,{})
                                this.props.installmentItem?.proof_of_payment_installment?.id&&  this.removeFilePaymentReview(this.props.installmentItem?.proof_of_payment_installment?.id)
                                this.props.flowFiveInstallmentChangeHandler(
                                    {},
                                    "proof_of_payment_installment",
                                    this.props.installmentIndex,
                                    this.props.formikRef
                                )
                            }}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][proof_of_payment_installment]`}
                            className="field-error"
                            data-test-id="proof_of_payment_installment_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid item xs={4} style={{ paddingRight: "25px" }}>
                        <Button
                            data-test-id="mainCreateInvoiceBtn"
                            disabled={checkPaymentVoucherBtnIsDisabled}
                            className={checkPaymentVoucherBtnIsDisabled ? classes.grayBtn : classes.primaryRvBtn}
                            aria-haspopup="true"
                            aria-controls="customized-menu"
                            variant="contained"
                            color="primary"
                            onClick={() => this.generatePaymentVoucherForFlowFiveInstallment(this.props.installmentIndex, this.props.flow, this.props.formikRef)}
                        >
                            {!this.state.isPaymentVoucherFlowFiveInstallmentBtnLoading && t('create_payment_voucher')}
                            {this.state.isPaymentVoucherFlowFiveInstallmentBtnLoading && (<CircularProgress size={25} />)}
                        </Button>
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][payment_voucher_id]`}
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                            className="field-error"
                            data-test-id="payment_voucher_id_error"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                    <Grid item xs={4} style={{ paddingRight: "25px" }}>
                    <LabelWithIcon label={t("proof_of_payment")} />
                        <FileUpload
                            data-test-id="ndcif_proof_of_payment_voucher_installment"
                            eachFileSizeInMB={10}
                            disabled={true}
                            maxFilesNumber={5}
                            fileName={this.props.installmentItem?.installment_payment_voucher?.file_name || ""}
                            filePath={this.props.installmentItem?.installment_payment_voucher?.url || ""}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][installment_payment_voucher]`}
                            className="field-error"
                            data-test-id="installment_payment_voucher_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid style={{ paddingRight: "25px" }} item xs={4}>
                        <LabelWithIcon label={t("payment_voucher_id")} />
                        <TextInput
                            className={classes.textInputStyle}
                            disabled={true}
                            data-test-id="ndcif_payment_voucher_id"
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][payment_voucher_id]`}
                            type="text"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid style={{ paddingRight: "25px" }} item xs={4}>
                        <LabelWithIcon label={t("insurer_receipt_number")} />
                        <TextInput
                            id={""}
                            disabled={this.isDisable(isDisabled)}
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][insurer_receipt_number]`}
                            type="text"
                            data-test-id="ndcif_insurer_receipt_number"
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                this.props.flowFiveInstallmentChangeHandler(
                                    evt.target.value,
                                    "insurer_receipt_number",
                                    this.props.installmentIndex,
                                    this.props.formikRef
                                )
                            }
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][insurer_receipt_number]`}
                            className="field-error"
                            data-test-id="insurer_receipt_number_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid style={{ paddingRight: "25px" }} item xs={4}>
                        <LabelWithIcon label={t("payment_date")} />
                        <TextInput
                            disabled={this.isDisable(isDisabled)}
                            className={classes.textInputStyle}
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][payment_date]`}
                            type={"date"}
                            data-test-id="ndcif_payment_date"
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                this.props.flowFiveInstallmentChangeHandler(
                                    evt.target.value,
                                    "payment_date",
                                    this.props.installmentIndex,
                                    this.props.formikRef
                                )
                            }
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][payment_date]`}
                            className="field-error"
                            data-test-id="payment_date_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                    <Grid item xs={4}>
                    <LabelWithIcon label={t("proof_of_payment")} />
                        <FileUpload
                            eachFileSizeInMB={10}
                            maxFilesNumber={5}
                            disabled={this.isDisable(isDisabled)}
                            fileName={this.props.installmentItem?.proof_of_payment_voucher_installment?.file_name || ""}
                            filePath={this.props.installmentItem?.proof_of_payment_voucher_installment?.url || ""}
                            data-test-id="ndcif_recipient_proof_of_payment"
                            onChange={(evt:any) =>
                                this.props.flowFiveInstallmentChangeHandler(
                                    evt.target.files[0],
                                    "proof_of_payment_voucher_installment",
                                    this.props.installmentIndex,
                                    this.props.formikRef
                                )
                            }
                            onRemove={/*istanbul ignore next*/()=>{
                                this.props.formikRef?.current?.setFieldValue(`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][proof_of_payment_voucher_installment]`,{})
                                this.props.installmentItem?.proof_of_payment_voucher_installment?.id&&  this.removeFilePaymentReview(this.props.installmentItem?.proof_of_payment_voucher_installment?.id)
                                this.props.flowFiveInstallmentChangeHandler(
                                    {},
                                    "proof_of_payment_voucher_installment",
                                    this.props.installmentIndex,
                                    this.props.formikRef
                                )
                            }}
                        />
                        <ErrorMessage
                            name={`fieldsData['flowFieldObj']['installment_attributes'][${this.props.installmentIndex}][proof_of_payment_voucher_installment]`}
                            className="field-error"
                            data-test-id="proof_of_payment_voucher_installment_error"
                            render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
                        />
                    </Grid>
                </Grid>
            </>
        );
    }
}

const useStyles = {
    fieldAlignment: {
        paddingRight: "25px",
    },
    btnGrid: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "30px",
        marginTop: "25px",
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    grayBtn: {
        "text-transform": "none",
        padding: 15,
        borderRadius: 5,
        width: "100%",
        fontSize: 15,
        backgroundColor: "#979090",
        color: "white",
    },
    primaryRvBtn: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        padding: 15,
        borderRadius: 5,
        width: "100%",
        fontSize: 15,
        color: "white",
    },
};

export default withStyles(useStyles)(
    withTranslation()(NoDeductedCommissionInstallmentForm)
);
