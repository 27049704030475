// Customizable Area Start

import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { getStageNumberByName } from "../../../../../../components/src/Common/PolicyRequest/StagesName";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import ActiveLanguage from "../../../../../../components/src/Common/ActiveLanguage";
import { t } from "i18next";
// Customizable Area End

export interface Props {
    // Customizable Area Start
    id?: string;
    t?: any;
    classes?: any;
    flowValue?: number | null;
    values?: any;
    removeInstallment?: any;
    installmentIndex?: number;
    flow?: any;
    setStageData?: any;
    insurerCommonFieldsChangeHandler?: any;
    onNext?: any;
    isExpanded?: boolean;
    parentPolicyID?: any;
    rrStageOneData?: any;
    rrStageFourData?: any;
    rrStageSevenData?: any;
    isPayInstallment?: boolean;
    isAmountConfigure?: boolean;
    installmentsData?: any;
    onReject?: any;
    insurerInstallmentChnageHandler?: any;
    flowFiveInstallmentChangeHandler?: any;
    installmentItem?: any;
    siiCommonFieldChangeHandler?: any;
    paidAmount?: any;
    deductedCommissionFromPayment?: any;
    paymentReceipent?: any;
    deductedCommissionFeildsHandlers?: any
    paymentVoucherFlowFourStateHandler?: any
    createInvoiceFlowFourStateHandler?: any
    createInvoiceInstallmentStateHandler?: any
    createReceiptInstallmentStateHandler?: any
    createVoucherInstallmentStateHandler?: any
    createReceiptFlowFourStateHandler?: any
    formikRef?: any;
    quotation_amount?: any
    balance?: any
    deductedCommissionFields?: any
    updateFlowFourBalance?: any;
    siibCommonFields?: any
    updatePaymentVoucherStateforFLowthreeAndFive?: any
    paymentVoucherFlowFiveInstallmentStateHandler?: any
    handoverNote?: string;
    handOverNoteForPaymentReview?: string;
    isEdit?: boolean;
    // Customizable Area End
}
interface InsurerCommonFields {
    payment_date: string;
    insurance_receipt_number: string;
    policy_release_requested: string;
    proof_of_payment: string;
}

interface SiibCommonFields {
    commission_rate: string;
    commission_amount: string | number;
    payable_to_insurer: string | number;
    insurance_receipt_number: string
    payment_date: string
    invoice_date: string;
    receipt_date: string;
    proof_of_payment: string
    policy_release_requested: string
    insurer_recipient_number: string
    payment_voucher_payment_date: string
    proof_of_payment_voucher: string
    payment_voucher_date?: string
    payment_review_payment_voucher_id?: string
    payment_review_payment_voucher?: any
    refund_amount_to_customer?: string
    invoice_file: string;
    siib_invoice_id: string;
    receipt: string;
    siib_receipt_id: string;
}

interface Installment {
    installment_amount: string;
    due_date: string;
    installment_paid: string;
    insurer_receipt_number: string;
    payment_date: string;
    installment_proof_of_payment: any
    isShownInstallmentAmount: boolean;
}
interface FlowFiveInstallments {
    installment_amount: string;
    due_date: string;
    installment_paid: string;
    insurance_receipt_number: string;
    insurer_receipt_date: string;
    proof_of_payment_installment: any;
    installment_payment_voucher: any
    payment_voucher_id: string;
    insurer_receipt_number: string;
    payment_date: string;
    proof_of_payment_voucher_installment: any;
    isShownInstallmentAmount: boolean;
}
interface DeductedCommissionFields {
    commission_rate: string;
    commission_amount: any;
    deduct_commission_with_vat: string;
    invoice_date: string;
    receipt_date: string;
    invoice_file: string;
    siib_invoice_id: string;
    receipt: string;
    siib_receipt_id: string;
    payable_to_insurer: string | number;
    payment_voucher_date: string;
    payment_voucher: any
    payment_voucher_id: string;
    insurer_recipient_number: string;
    payment_date: string;
    proof_of_payment_voucher: string;
    policy_release_requested: string;
}

export interface Balance {
    paid_amount?: string | null | number;
    totalBalance?: number | null | string;
    showRefundAmountValue?: boolean;
}
interface S {
    // Customizable Area Start
    quotation_amount: string;
    showAlert: boolean;
    showNoteDialog: boolean;
    alertMessage: string;
    alertType: string;
    expandedAccordion: boolean;
    flow: number | null;
    currentFlow: number
    paid_amount: string;
    payment_recipient: string;
    deduct_commission_from_payment: string;
    insurerCommonFields: InsurerCommonFields;
    siibCommonFields: SiibCommonFields;
    installments: any;
    deductedCommissionFields: DeductedCommissionFields;
    isPostResponseCalledOnce: boolean;
    showNextDialog: boolean;
    handOverNoteText: string;
    showHandOverNote: boolean;
    trackFor: string;
    showRejectDialog: boolean;
    isBtnVisible: boolean;
    rejectReasonText: string;
    moveToStep: any;
    isShownAddInstallmentBtn: boolean;
    balance: Balance;
    receiptBtnLoading: boolean,
    insurer_name: string;
    isinvoiceAndReceiptBtnLoading: boolean
    isPaymentVoucherFlowFourBtnLoading: boolean
    voucherBtnLoading: any;
    flowFiveInstallments: any;
    isPaymentVoucherFlowFiveInstallmentBtnLoading: boolean;
    currentInstallmentIndex: number;
    installmentIndex?: number;
    isEdit: boolean;
    isShownCommissionAmountFlowSix?: boolean;
    isShownPayableToInsurerFlowSix? : boolean;
    isShownComissionAmountFlowFour?: boolean;
    isShownPayableToInsurerFlowFour?: boolean;
    isShownPayableToInsurerFlowThree? : boolean;
    isShownPaybleToInsurerFlowFirst? : boolean;
    isShownDownPaymentFlowTwo?: boolean;
    isShownDownPaymentFlowFive?: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class PaymentReviewController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    createPolicyRequestAPIKey: string = "";
    trackRequestApiStageSeven: string = "";
    createInvoiceApiId: string = ""
    createPaymentVoucherFlowFourApiId: string = ""
    createReceiptApiId: string = "";
    removeFileAPI:string="";
    createVoucherForSiibApi: string = "";
    generatePaymentVoucherForFlowFiveInstallmentApi: string = ""
    formikRef: any = "";
    // Customizable Area End

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        const stageSevenData = this.props.rrStageSevenData?.data?.attributes;
        this.state = {
            quotation_amount: this.getQuotationAmount(),
            showAlert: false,
            showNoteDialog: false,
            alertMessage: "",
            alertType: "",
            expandedAccordion: this.props.isExpanded || false,
            flow: this.props.rrStageFourData?.data?.attributes?.policy_impact === "down" ? 7 : (Number(stageSevenData?.flow) || null),
            currentFlow: 0,
            paid_amount: stageSevenData?.paid_amount || "",
            payment_recipient: this.props.rrStageFourData?.data?.attributes?.policy_impact === "down" ? "Siib" : (stageSevenData?.payment_recipient || ""),
            deduct_commission_from_payment:
                stageSevenData?.deduct_commission_from_payment === undefined || ""
                    ? ""
                    : this.getValue(stageSevenData.deduct_commission_from_payment),
            insurerCommonFields: this.getInsurerCommonFields(stageSevenData),
            siibCommonFields: this.getSiibFields(stageSevenData),
            installments:
                this.getInstallments(stageSevenData?.revision_installments?.data, stageSevenData?.flow),
            deductedCommissionFields:
                this.getDeductedCommissionFields(stageSevenData),
            isPostResponseCalledOnce: false,
            showNextDialog: false,
            handOverNoteText: "",
            showHandOverNote: false,
            trackFor: "",
            showRejectDialog: false,
            isBtnVisible: true,
            rejectReasonText: "",
            moveToStep: {},
            isShownAddInstallmentBtn: false,
            receiptBtnLoading: false,
            balance: {
                paid_amount: stageSevenData?.paid_amount
                    ? stageSevenData.paid_amount
                    : "",
                totalBalance: stageSevenData?.balance ? stageSevenData.balance : this.getQuotationAmount(),
                showRefundAmountValue: false
            },
            insurer_name:
                this.props.rrStageOneData?.data?.attributes?.revision_input?.data
                    ?.attributes?.insurance_company_id?.allData?.short_name,
            isinvoiceAndReceiptBtnLoading: false,
            isPaymentVoucherFlowFourBtnLoading: false,
            voucherBtnLoading: false,
            flowFiveInstallments: this.getFlowFiveInstallments(stageSevenData?.revision_installments?.data),
            isPaymentVoucherFlowFiveInstallmentBtnLoading: false,
            currentInstallmentIndex: 0,
            isEdit: false,
            isShownCommissionAmountFlowSix: false,
            isShownPayableToInsurerFlowSix: false,
            isShownComissionAmountFlowFour: false,
            isShownPayableToInsurerFlowFour: false,
            isShownPayableToInsurerFlowThree: false,
            isShownPaybleToInsurerFlowFirst: false,
            isShownDownPaymentFlowTwo: false,
            isShownDownPaymentFlowFive: false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.createPolicyRequestAPIResponseHandler(message);
        this.createReceiptApiReceive(message);
        this.createInvoiceApiIdReceive(message);
        this.stageSevenTrackApiReceive(message);
        this.generateVoucherForSiibApiReceive(message);
        this.fileRemoveApiReceivePaymentReview(message)
        this.createPaymentVoucherFlowFourReceive(message)
        this.generatePaymentVoucherForFlowFiveInstallmentReceive(message)
        // Customizable Area End
    }

    // Customizable Area Start

    getInsurerCommonFields = (stageSevenData: any) => {
        return {
            payment_date: stageSevenData?.payment_date || "",
            insurance_receipt_number:
                stageSevenData?.insurance_receipt_number || "",
            policy_release_requested:
                (stageSevenData?.policy_release_requested && "yes") || "no",
            proof_of_payment: stageSevenData?.proof_of_payment || "",
        }
    }

    extractPaymentDetails = (stageSevenData: any) => {
        return {
            insurance_receipt_number: stageSevenData?.insurance_receipt_number || "",
            payment_date: stageSevenData?.payment_date || "",
            proof_of_payment: stageSevenData?.proof_of_payment || "",
            policy_release_requested: (stageSevenData?.policy_release_requested && "yes") || "no",
            insurer_recipient_number: stageSevenData?.revision_payment_voucher?.data?.attributes?.insurer_recipient_number || "",
            payment_voucher_payment_date: stageSevenData?.revision_payment_voucher?.data?.attributes?.payment_date || "",
            proof_of_payment_voucher: stageSevenData?.revision_payment_voucher?.data?.attributes?.proof_of_payment_voucher || "",
            payment_voucher_date: stageSevenData?.revision_payment_voucher?.data?.attributes?.payment_voucher_date || "",
            payment_review_payment_voucher_id: stageSevenData?.revision_payment_voucher?.data?.attributes?.payment_voucher_id || "",
            payment_review_payment_voucher: stageSevenData?.revision_payment_voucher?.data?.attributes?.payment_voucher || "",
            refund_amount_to_customer: stageSevenData?.refund_amount_to_customer || "",
            payable_to_insurer: stageSevenData?.revision_payment_voucher?.data?.attributes?.payable_to_insurer || "",
        };
    };

    extractCommissionDetails = (stageSevenData: any) => {
        return {
            commission_rate: stageSevenData?.commission?.data?.attributes?.commission_rate || "",
            commission_amount: stageSevenData?.commission?.data?.attributes?.commission_amount || "",
            deduct_commission_with_vat: stageSevenData?.commission?.data?.attributes?.deduct_commission_with_vat || "",
            invoice_date: stageSevenData?.commission?.data?.attributes?.invoice_date || "",
        };
    };

    getSiibFields = (stageSevenData: any) => {
        const paymentDetails = this.extractPaymentDetails(stageSevenData);
        const commissionDetails = this.extractCommissionDetails(stageSevenData);

        return {
            ...paymentDetails,
            ...commissionDetails,
            receipt_date: stageSevenData?.reciept?.data?.attributes?.receipt_date || "",
            invoice_file: stageSevenData?.commission?.data?.attributes?.invoice_file || {},
            siib_invoice_id: stageSevenData?.commission?.data?.attributes?.siib_invoice_id || "",
            receipt: stageSevenData?.reciept?.data?.attributes?.receipt || {},
            siib_receipt_id: stageSevenData?.reciept?.data?.attributes?.siib_receipt_id || "",
        };
    };


    getQuotationAmount = () => {
        if (this.props.rrStageFourData?.data?.attributes?.policy_impact === "down") return this.props.rrStageFourData.data.attributes.revision_credit_note?.data?.attributes?.credit_note_amount;
        else return this.props.rrStageFourData?.data?.attributes?.revision_invoice?.data?.attributes?.invoice_amount
    }

    getCommissionDataAttributes = (stageSevenData: any) => {
        return {
            commission_rate: stageSevenData?.commission?.data?.attributes?.commission_rate || "",
            commission_amount: stageSevenData?.commission?.data?.attributes?.commission_amount || "",
            deduct_commission_with_vat: !stageSevenData?.commission?.data?.attributes?.deduct_commission_with_vat ? "" : stageSevenData.commission.data.attributes.deduct_commission_with_vat,
            invoice_date: stageSevenData?.commission?.data?.attributes?.invoice_date || "",
            invoice_file: stageSevenData?.commission?.data?.attributes?.invoice_file || {},
            siib_invoice_id: stageSevenData?.commission?.data?.attributes?.siib_invoice_id || "",
        };
    };

    getReceiptDataAttributes = (stageSevenData: any) => {
        return {
            receipt_date: stageSevenData?.reciept?.data?.attributes?.receipt_date || "",
            receipt: stageSevenData?.reciept?.data?.attributes?.receipt || {},
            siib_receipt_id: stageSevenData?.reciept?.data?.attributes?.siib_receipt_id || "",
        };
    };

    getRevisionPaymentVoucherDataAttributes = (stageSevenData: any) => {
        return {
            payable_to_insurer: stageSevenData?.revision_payment_voucher?.data?.attributes?.payable_to_insurer || "",
            payment_voucher_date: stageSevenData?.revision_payment_voucher?.data?.attributes?.payment_voucher_date || "",
            payment_voucher: stageSevenData?.revision_payment_voucher?.data?.attributes?.payment_voucher || "",
            payment_voucher_id: stageSevenData?.revision_payment_voucher?.data?.attributes?.payment_voucher_id || "",
            insurer_recipient_number: stageSevenData?.revision_payment_voucher?.data?.attributes?.insurer_recipient_number || "",
            payment_date: stageSevenData?.revision_payment_voucher?.data?.attributes?.payment_date || "",
            proof_of_payment_voucher: stageSevenData?.revision_payment_voucher?.data?.attributes?.proof_of_payment_voucher || "",
        };
    };

    getPolicyReleaseRequestedValue = (stageSevenData: any) => {
        return !stageSevenData?.policy_release_requested ? "no" : this.getValue(stageSevenData.policy_release_requested);
    };

    getDeductedCommissionFields = (stageSevenData: any) => {
        return {
            ...this.getCommissionDataAttributes(stageSevenData),
            ...this.getReceiptDataAttributes(stageSevenData),
            ...this.getRevisionPaymentVoucherDataAttributes(stageSevenData),
            policy_release_requested: this.getPolicyReleaseRequestedValue(stageSevenData),
        };
    };

    setApiErrorMessageForPaymentReview = (apiErrorResponse: any) => {
        if (!apiErrorResponse) return;
        let errorMessage = "Something went wrong"
        if (apiErrorResponse.errors) {
            errorMessage = typeof (apiErrorResponse.errors) === "string" ? apiErrorResponse.errors : apiErrorResponse.errors.error || "Something went wrong";
        }

        this.setState({
            alertType: "error",
            alertMessage: errorMessage,
            showAlert: true,
            showNoteDialog: false
        })
    }

    handleAccordian = () => {
        this.setState((prev: any) => ({
            ...prev,
            expandedAccordion: !prev.expandedAccordion,
        }));
    };

    checkStageSevenCondition = () => {
        return (
            this.state.expandedAccordion &&
            this.props.rrStageSevenData?.data?.attributes?.is_reject
        );
    };

    openNoteDialog = () => {
        this.setState({ showNoteDialog: true });
    };

    closeNoteDialogStageThree = () => {
        this.setState({ showNoteDialog: false, handOverNoteText: "" });
    };

    isPaymentVoucherBtnDIsabledflow5And3 = () => {
        const { paidAmount, paymentReceipent, deductedCommissionFromPayment, siibCommonFields } = this.props
        const { siib_receipt_number, payment_date, payment_review_payment_voucher, payment_voucher_date, proof_of_payment } = siibCommonFields;
      
        if (
            paidAmount !== "" &&
            paymentReceipent === "Siib" &&
            deductedCommissionFromPayment === "no" &&
            siib_receipt_number !== "" && payment_date !== "" && payment_voucher_date !== "" && proof_of_payment !== "" && Object.keys(payment_review_payment_voucher || {}).length === 0
        ) {
            return false;
        }
        return true;
    }

    isPaymentVoucherBtnDisabledflow6 = (formValues: any) => {
        const values = formValues.fieldsData;
        if (
            values.paid_amount &&
            values.payment_recipient === "Siib" &&
            values.deduct_commission_from_payment === "yes" &&
            values.flowFieldObj.payment_voucher_date && values.flowFieldObj.commission_amount && values.flowFieldObj.payable_to_insurer &&
            Object.keys(values.flowFieldObj.payment_review_payment_voucher || {}).length === 0
        ) {
            return false;
        }
        return true;
    }

    isPaymentVoucherBtnDisabledflow6Installments = (formValues: any, installmentIndex: number | undefined) => {
        const values = formValues.fieldsData;
        if (installmentIndex !== undefined) {
            const installment = values.flowFieldObj.installment_attributes[installmentIndex];
            if (
                !installment.installment_amount ||
                installment.installment_paid !== "yes" ||
                !installment.commission_amount ||
                !installment.payable_to_insurer ||
                !installment.payment_voucher_date ||
                !installment.due_date ||
                installment.payment_review_payment_voucher_id ||
                Object.keys(installment.payment_review_payment_voucher).length > 0 
            ) {
                return true;
            }
            return false;
        }

    }

    isPaymentVoucherBtnDisabledflow7 = (formValues: any) => {
        const flowFieldObj = formValues.fieldsData.flowFieldObj
        if(
            flowFieldObj.policy_release_requested !== "yes" ||
            !flowFieldObj.refund_amount_to_customer ||
            !flowFieldObj.insurance_receipt_number ||
            !flowFieldObj.payment_date ||
            !flowFieldObj.proof_of_payment ||
            !flowFieldObj.payment_voucher_date ||
            flowFieldObj.payment_review_payment_voucher_id || 
            Object.keys(flowFieldObj.payment_review_payment_voucher).length > 0

        ){
            return true;
        }
        return false;
    }

    checkFlowValueForPaymentVoucherDisable = (values: any, flowValue: number | null | undefined) => {
        if(flowValue === 6){
            return this.isPaymentVoucherBtnDisabledflow6(values);
        }else if(flowValue === 7){
            return this.isPaymentVoucherBtnDisabledflow7(values);
        }
        return this.isPaymentVoucherBtnDIsabledflow5And3()
    }

    generateVoucherForSiib = (
        formValues: any,
        formikRef: any,
        flowValue: any,
        parentPolicyID: any
    ) => {
        this.formikRef = formikRef;
        this.setState({
            voucherBtnLoading: true,
            showAlert: true,
            alertType:"warning",
            alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
         });
        let voucherBody;
        if(flowValue === 7 ){
            voucherBody = {
                refund_amount: formValues.fieldsData.flowFieldObj.refund_amount_to_customer,
                file_key: "payment_voucher_pdf",
                recipient_type: "Customer",
                payment_date: formValues.fieldsData.flowFieldObj.payment_voucher_date,
            }
        }else{
        voucherBody = {
            paid_amount: formValues.fieldsData.paid_amount,
            file_key: "payment_voucher_pdf",
            recipient_type: "Siib",
            payment_date: formValues.fieldsData.flowFieldObj.payment_voucher_date,
            commission_amount: formValues.fieldsData.flowFieldObj.commission_amount,
        }
        }

        this.createVoucherForSiibApi = this.apiCall({
            contentType: "application/json",
            method: "POST",
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?revision_request_id=${parentPolicyID}&stage=7&flow=${flowValue}`,
            body: {
                data: voucherBody,
            },
        });
    };


    generatePaymentVoucherForFlowFiveInstallment = (installmentIndex: any, currentFlow: number, formikRef: any) => {
        this.formikRef= formikRef;
        const userData = JSON.parse(window.localStorage.getItem('loginData') ?? '{}');
        const token = userData.token || '';
        const { installmentItem, parentPolicyID } = this.props
        const formData = new FormData();

        formData.append("data[paid_amount]", installmentItem.installment_amount);
        formData.append("data[file_key]", "payment_voucher_pdf");
        formData.append("data[recipient_type]", "Insurer");
        formData.append("data[payment_date]", installmentItem.payment_voucher_date);
        formData.append("data[commission_amount]", installmentItem.commission_amount);
        formData.append("data[installment_no]", installmentIndex + 2);

        this.setState({
            currentFlow: currentFlow,
            isPaymentVoucherFlowFiveInstallmentBtnLoading: true,
            currentInstallmentIndex: installmentIndex,
            showAlert: true,
            alertType:"warning",
            alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
        }, () => {
            this.generatePaymentVoucherForFlowFiveInstallmentApi = this.apiCall({
                type: 'FormData',
                method: 'POST',
                endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?revision_request_id=${parentPolicyID}&stage=7&flow=${currentFlow}`,
                token: token || '',
                body: formData
            })
        });
    };


     extractBasicProperties=(installment:any, flow: string)=> {
        const isAllInstallmentFieldsFilled = 
            installment?.attributes?.installment_amount !== "" && 
            installment?.attributes?.due_date !== "" && 
            installment?.attributes?.installment_paid === "yes" &&
            installment?.attributes?.insurer_receipt_number !== "" &&
            installment?.attributes?.payment_date !== "" &&
            installment?.attributes?.installment_proof_of_payment !== ""

        return {
            installment_amount: installment?.attributes?.installment_amount || "",
            due_date: installment?.attributes?.due_date || "",
            installment_paid: installment?.attributes?.installment_paid && "yes" || "no",
            insurer_receipt_number: (flow === '2' ? installment?.attributes?.insurer_receipt_number : installment?.attributes?.payment_voucher?.data?.attributes?.insurer_recipient_number) || "",
            payment_date: (flow === '2' ? installment?.attributes?.payment_date : installment?.attributes?.payment_voucher?.data?.attributes?.payment_date) || "",
            isShownInstallmentAmount: isAllInstallmentFieldsFilled,
        };
    }

     extractComplexProperties= (installment:any, flow: string) =>{
        return {
            commission_rate: installment?.attributes?.commission?.data?.attributes?.commission_rate || "",
            commission_amount: installment?.attributes?.commission?.data?.attributes?.commission_amount || "",
            installment_proof_of_payment: flow == '6' ? installment?.attributes?.payment_voucher?.data?.attributes?.proof_of_payment_voucher_installment : (installment?.attributes?.proof_of_payment_installment || ""),
            deduct_commission_with_vat: installment?.attributes?.commission?.data?.attributes?.deduct_commission_with_vat || "",
            invoice_date: installment?.attributes?.commission?.data?.attributes?.invoice_date || "",
            receipt_date: installment?.attributes?.reciept?.data?.attributes?.receipt_date || "",
            invoice_file: installment?.attributes?.commission?.data?.attributes?.invoice_file_installment || "",
            siib_invoice_id: installment?.attributes?.commission?.data?.attributes?.siib_invoice_id || "",
            receipt: installment?.attributes?.reciept?.data?.attributes?.receipt_installment || "",
            siib_receipt_id: installment?.attributes?.reciept?.data?.attributes?.siib_receipt_id || "",
            payment_review_payment_voucher: installment?.attributes?.payment_voucher?.data?.attributes?.payment_voucher || "",
            payment_review_payment_voucher_id: installment?.attributes?.payment_voucher?.data?.attributes?.payment_voucher_id || "",
            payable_to_insurer: installment?.attributes?.payment_voucher?.data?.attributes?.payable_to_insurer || "",
            payment_voucher_date: installment?.attributes?.payment_voucher?.data?.attributes?.payment_voucher_date || "",
        };
    }


    getInstallments = (installments: any, flow: string) => {
        let installmentData: any = [];
        installments?.length > 0 && installments.forEach((installment: any, index: number) => {
            const installmentObj = {
                ...this.extractBasicProperties(installment, flow),
                ...this.extractComplexProperties(installment, flow)
            };
            installmentData = [...installmentData, installmentObj]
        });

        return installmentData
    }


    getFlowFiveInstallments = (installments: any) => {
        let installmentData: any = [];
        installments?.length > 0 && installments.forEach((item: any) => {
            const installmentObj = {
                installment_amount: item?.attributes?.installment_amount || "",
                due_date: item?.attributes?.due_date || "",
                installment_paid: item?.attributes?.installment_paid && "yes" || "no",
                insurance_receipt_number: item?.attributes?.insurer_receipt_number || "",
                insurer_receipt_date: item?.attributes?.insurer_receipt_date || "",
                proof_of_payment_installment: item?.attributes?.proof_of_payment_installment || "",
                installment_payment_voucher: item?.attributes?.payment_voucher?.data?.attributes?.payment_voucher || {},
                payment_voucher_id: item?.attributes?.payment_voucher?.data?.attributes?.payment_voucher_id || "",
                insurer_receipt_number: item?.attributes?.payment_voucher?.data?.attributes?.insurer_recipient_number || "",
                payment_date: item?.attributes?.payment_voucher?.data?.attributes?.payment_date || "",
                proof_of_payment_voucher_installment: item?.attributes?.payment_voucher?.data?.attributes?.proof_of_payment_voucher_installment || "",
                isShownInstallmentAmount: false
            }
            const isShownInstallmentAmount = installmentObj.installment_amount !== "" &&
                                         installmentObj.due_date !== "" &&
                                         installmentObj.installment_paid === "yes" &&
                                         installmentObj.insurance_receipt_number !== "" &&
                                         installmentObj.insurer_receipt_date !== "" &&
                                         installmentObj.proof_of_payment_installment !== "" &&
                                         installmentObj.installment_payment_voucher !== "" &&
                                         installmentObj.payment_voucher_id !== "" &&
                                         installmentObj.insurer_receipt_number !== "" &&
                                         installmentObj.payment_date !== "" &&
                                         installmentObj.proof_of_payment_voucher_installment !== "";

        installmentObj.isShownInstallmentAmount = isShownInstallmentAmount;
            installmentData = [...installmentData, installmentObj]
        });
        return installmentData
    }

    getFormFields = () => {
        const {
            insurerCommonFields,
            flow,
            installments,
            siibCommonFields,
            deductedCommissionFields,
            flowFiveInstallments,
        } = this.state;

        const flowsMapping: any = {
            1: { ...insurerCommonFields },
            2: {
                ...insurerCommonFields,
                installment_attributes: [...installments],
            },
            3: { ...siibCommonFields },
            4: { ...deductedCommissionFields },
            5: {
                ...siibCommonFields,
                installment_attributes: [...flowFiveInstallments],
            },
            6: {
                ...siibCommonFields,
                installment_attributes: [...installments],
            },
            7: {
                ...siibCommonFields,
            },
        };

        return flow ? flowsMapping[flow] : {};
    };

    initializeFormValue = () => {
        const { paid_amount, payment_recipient, deduct_commission_from_payment } =
            this.state;
        let fields = {
            flowFieldObj: this.getFormFields(),
            paid_amount: paid_amount,
            payment_recipient: payment_recipient,
            deduct_commission_from_payment: deduct_commission_from_payment,
        };
        return fields;
    };

    updateFlow = (text: string) => {
        const {
            quotation_amount,
            payment_recipient,
            paid_amount,
            deduct_commission_from_payment,
        } = this.state;
        const paidAmount = text !== "" ? Number(text) : Number(paid_amount);
        const quoteAmount = Number(quotation_amount);

        switch (true) {
            case payment_recipient === "Insurer" && paidAmount === quoteAmount: {
                this.setState({ flow: 1 });
                break;
            }
            case payment_recipient === "Insurer" && paidAmount < quoteAmount: {
                this.setState({ flow: 2 });
                break;
            }
            case payment_recipient === "Siib" &&
                paidAmount === quoteAmount &&
                deduct_commission_from_payment === "no": {
                    this.setState({ flow: 3 });
                    break;
                }
            case payment_recipient === "Siib" &&
                paidAmount === quoteAmount &&
                deduct_commission_from_payment === "yes": {
                    this.setState({ flow: 4 });
                    break;
                }
            case payment_recipient === "Siib" &&
                paidAmount < quoteAmount &&
                deduct_commission_from_payment === "no": {
                    this.setState({ flow: 5 });
                    break;
                }

            case payment_recipient === "Siib" &&
                paidAmount < quoteAmount &&
                deduct_commission_from_payment === "yes": {
                    this.setState({ flow: 6 });
                    break;
                }
        }
    };

    debounce = (delay: number) => {
        let timer: any;
        return (text: string) => {
            clearTimeout(timer);
            /* istanbul ignore next*/
            timer = setTimeout(() => {
                this.updateFlow(text);
            }, delay);
        };
    };

    debounceLog = this.debounce(500);

    addInstallment = () => {
        const installmentInitialAttribute = {
            installment_amount: "",
            due_date: "",
            installment_paid: "no",
            insurer_receipt_number: "",
            payment_date: "",
            installment_proof_of_payment: ""
        };

        if (this.state.flow === 5) {
            const flowFiveIntsallmentsAttributes = {
                installment_amount: "",
                due_date: "",
                installment_paid: "",
                insurance_receipt_number: "",
                insurer_receipt_date: "",
                proof_of_payment_installment: "",
                proof_of_payment_voucher_installment: "",
                payment_voucher_id: "",
                insurer_receipt_number: "",
                payment_date: "",
                isShownInstallmentAmount: false,
            };
            let cloneInstallments = [...this.state.flowFiveInstallments];
            cloneInstallments.push(flowFiveIntsallmentsAttributes);
            this.setState({ flowFiveInstallments: cloneInstallments });
        }
        if (this.state.flow === 6) {
            const flowSixInstallment = {
                commission_rate: "",
                commission_amount: "",
                deduct_commission_with_vat: "",
                invoice_date: "",
                receipt_date: "",
                payable_to_insurer: "",
                proof_of_payment_installment: "",
                invoice_file: "",
                siib_invoice_id: "",
                receipt: "",
                siib_receipt_id: "",
                payment_voucher_date: "",
                payment_voucher_payment_date: "",
                payment_review_payment_voucher: "",
                payment_review_payment_voucher_id: "",
                insurer_receipt_number: "",
                payment_date: "",
                isShownComissionValue: false,
                isShownPaybleToInsurer: false,
            };
            let cloneInstallments = [...this.state.installments];
            cloneInstallments.push({
                ...installmentInitialAttribute,
                ...flowSixInstallment,
            });
            this.setState({ installments: cloneInstallments });
        } else {
            let cloneInstallments = [...this.state.installments];
            cloneInstallments.push(installmentInitialAttribute);
            this.setState({ installments: cloneInstallments });
        }
    };

    removeInstallment = (installmentIndex: number) => {
        const { installments, balance, flow, flowFiveInstallments } = this.state
        let totalBalance = balance.totalBalance || 0;
        let updatedInstallments: any[] = [];

        if (flow === 5) {
            updatedInstallments = flowFiveInstallments
                .map((item: any, index: number) => {
                    if (
                        index === installmentIndex &&
                        item.installment_amount !== "" &&
                        item.installment_paid === "yes"
                    ) {
                        totalBalance =
                            Number(totalBalance) + Number(item.installment_amount);
                    }
                    return item;
                })
                .filter((item: any, index: number) => index !== installmentIndex);
            this.setState({
                flowFiveInstallments: updatedInstallments,
                balance: { ...balance, totalBalance: totalBalance },
            })
            return;
        }

        updatedInstallments = installments
            .map((item: any, index: number) => {
                if (
                    index === installmentIndex &&
                    item.installment_amount !== "" &&
                    item.installment_paid === "yes"
                ) {
                    totalBalance =
                        Number(totalBalance) + Number(item.installment_amount);
                }
                return item;
            })
            .filter((item: any, index: number) => index !== installmentIndex);
        this.setState({
            installments: updatedInstallments,
            balance: { ...balance, totalBalance },
        })
    };

    updateFlowOne = () => {
        const {
            payment_recipient,
            insurerCommonFields,
        } = this.state;
        const { payment_date, insurance_receipt_number, proof_of_payment } =
            insurerCommonFields;

        const isInsurerPaymentValidate =
            payment_recipient !== "" &&
            payment_date !== "" &&
            insurance_receipt_number !== "" &&
            proof_of_payment !== "" ;

        if (isInsurerPaymentValidate && !this.state.isShownPaybleToInsurerFlowFirst) {
            this.setState({isShownPaybleToInsurerFlowFirst: true})
        }
    }

    updateFlowThree = () => {
        const {
            payment_recipient,
            deduct_commission_from_payment,
            siibCommonFields,
            flow,
        } = this.state;
        const { insurance_receipt_number, payment_date, proof_of_payment, payment_voucher_date, payment_review_payment_voucher_id, payment_voucher_payment_date, proof_of_payment_voucher } =
            siibCommonFields;
        
        if (flow === 3 && deduct_commission_from_payment === "no") {
            const isSiibPaymentValidated =
                payment_recipient !== "" &&
                payment_date !== "" &&
                insurance_receipt_number !== "" &&
                proof_of_payment !== "" &&
                payment_voucher_date !== "" &&
                payment_review_payment_voucher_id !== "" &&
                payment_voucher_payment_date !== "" &&
                proof_of_payment_voucher !== "";

            if (isSiibPaymentValidated && !this.state.isShownPayableToInsurerFlowThree) {
                this.setState({
                    isShownPayableToInsurerFlowThree: true
                });
            }
        }
    }

    updateFlowSix = () => {
        const {
            flow,
            payment_recipient,
            paid_amount,
            deduct_commission_from_payment,
            siibCommonFields,
        } = this.state;

        const isSiibPaymentValidate = this.isValidSiibPaymentFlowSix(payment_recipient, paid_amount, deduct_commission_from_payment, siibCommonFields)
        const isSecondSiibPaymentValidate = this.isValidSecondSiibPaymentFlowSix(siibCommonFields)

        if (flow === 6 && isSiibPaymentValidate) {
            this.setState({ isShownCommissionAmountFlowSix: true });
            
              if (isSecondSiibPaymentValidate) {
                  this.setState({ 
                      isShownPayableToInsurerFlowSix: true, 
                      isShownAddInstallmentBtn: true 
                  });
              }
        }
    }

    async componentDidMount(): Promise<void> {
        this.updateFlowOne()
        this.updateFlowThree()
        this.updateFlowSix()
    }

    componentDidUpdate(previousProps: any, previousState: any) {
        const {
            flow,
            paid_amount,
            payment_recipient,
            showAlert,
            insurerCommonFields,
            quotation_amount,
        } = this.state;

        const { payment_date, insurance_receipt_number, proof_of_payment } =
            insurerCommonFields;

        if (showAlert) {
           /* istanbul ignore next*/
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                    alertType: "",
                    alertMessage: "",
                });
            }, 10000);
        }

        const isInsurerPaymentValidate =
            payment_recipient !== "" &&
            payment_date !== "" &&
            insurance_receipt_number !== "" &&
            proof_of_payment !== "" ;

        const calculateTotalBalance = () =>
            Number(quotation_amount) - Number(paid_amount);
        let calculatedBal = calculateTotalBalance();
        /* istanbul ignore if*/
        if (
            flow === 1 &&
            isInsurerPaymentValidate &&
            /* istanbul ignore next*/previousState.balance.totalBalance !== calculatedBal
        ) {
            this.setState({ 
                balance: { paid_amount, totalBalance: calculatedBal },
                isShownPaybleToInsurerFlowFirst: true
            });
        }

        this.manageFlowTwo(previousState, isInsurerPaymentValidate);
        this.manageflowthree(previousState, calculatedBal);
        this.manageFlowFour(previousProps);
        this.manageFlowFive();
        flow === 6 && this.manageFlowSix();
        flow === 7 && this.manageFlowSeven(previousState)
        if (previousProps.isExpanded !== this.props.isExpanded) {
            this.setState({expandedAccordion: this.props.isExpanded as boolean})
          }
    }

    formikSubmitHandler = (values: any, draft: string) => {
        const isEdit = this.props.isEdit || false
        if (values.fieldsData.payment_recipient === "Siib" && (values.fieldsData.deduct_commission_from_payment === "no" || this.state.flow === 6)) {
            this.siibSubmitHandler(values, draft, isEdit)
            return
        }
        if (values.fieldsData.payment_recipient === "Siib" && values.fieldsData.deduct_commission_from_payment === "yes" && this.state.flow !== 6) {
            this.deductedCommissionFormSubmitHandler(values.fieldsData, draft, isEdit)
            return
        }
        this.submitHandler(values, draft, isEdit);
    };

    getValue = (value: any) => {
        return value ? "yes" : "no";
    };

    manageFlowTwo = (previousState: any, isInsurerPaymentValidate: any) => {
        const {
            flow,
            paid_amount,
            isShownAddInstallmentBtn,
            installments,
            quotation_amount,
        } = this.state;
        if (flow === 2 && isInsurerPaymentValidate && !isShownAddInstallmentBtn) {
            const totalBalanceCal = Number(quotation_amount) - Number(paid_amount);
            this.setState({
                isShownAddInstallmentBtn: true,
                balance: { paid_amount, totalBalance: totalBalanceCal },
                isShownDownPaymentFlowTwo: true
            });
        }

        if (installments.length > 0) {
            let totalInstallmentBalance = 0;
            installments.forEach((item: any) => {
                const {
                    installment_amount,
                    due_date,
                    installment_paid,
                    insurer_receipt_number,
                    payment_date,
                    installment_proof_of_payment
                } = item;

                if (
                    installment_amount !== "" &&
                    due_date !== "" &&
                    installment_paid === "yes"  &&
                    insurer_receipt_number !== "" &&
                    payment_date !== "" &&
                    installment_proof_of_payment !== "" 
                ) {
                    item.isShownInstallmentAmount = true;
                    totalInstallmentBalance += Number(installment_amount);
                }
            });

            const totalremaining =
                Number(quotation_amount) -
                (Number(paid_amount) + totalInstallmentBalance);
            if (previousState.balance.totalBalance !== totalremaining) {
                // In order to bypass the maximum call stack error in the test environment
                if (process.env.NODE_ENV === "test") {
                    return;
                }
                this.setState({
                    balance: { paid_amount, totalBalance: totalremaining },
                });
            }
        }
    };

    manageflowthree = (previousState: any, calculatedBal: any) => {
        const {
            paid_amount,
            deduct_commission_from_payment,
            payment_recipient,
            siibCommonFields,
            flow,
        } = this.state;
        const { insurance_receipt_number, payment_date, proof_of_payment, payment_voucher_date, payment_review_payment_voucher_id, payment_voucher_payment_date, proof_of_payment_voucher } =
            siibCommonFields;

        if (flow === 3 && deduct_commission_from_payment === "no") {
            const isSiibPaymentValidated =
                payment_recipient !== "" &&
                payment_date !== "" &&
                insurance_receipt_number !== "" &&
                proof_of_payment !== "" &&
                payment_voucher_date !== "" &&
                payment_review_payment_voucher_id !== "" &&
                payment_voucher_payment_date !== "" &&
                proof_of_payment_voucher !== "";
            if (
                isSiibPaymentValidated &&
                previousState.balance.totalBalance !== calculatedBal
            ) {
                this.setState({
                    balance: { paid_amount, totalBalance: calculatedBal },
                });
            }

            if (isSiibPaymentValidated && !this.state.isShownPayableToInsurerFlowThree) {
                this.setState({
                    isShownPayableToInsurerFlowThree: true
                });
            }
        }
    };

    isValidPaymentFlowFour = (
        deductedCommissionFields: any,
        paymentReceipent: string,
        paidAmount: string,
        deductedCommissionFromPayment: string
    ): boolean => {
        const {
            commission_rate,
            commission_amount,
            deduct_commission_with_vat,
            invoice_date,
            receipt_date,
            siib_invoice_id,
            siib_receipt_id,
        } = deductedCommissionFields;
    
        return (
            paymentReceipent !== "" &&
            paidAmount !== "" &&
            deductedCommissionFromPayment !== "" &&
            commission_rate !== "" &&
            commission_amount !== "" &&  
            deduct_commission_with_vat !== "" &&
            invoice_date !== "" &&
            receipt_date !== "" &&
            siib_invoice_id !== "" &&
            siib_receipt_id !== ""
        );
    };

    isValidSecondPaymentFlowFour = (deductedCommissionFields: DeductedCommissionFields): boolean => {
        const {
            payable_to_insurer,
            payment_voucher_date,
            payment_voucher_id,
            insurer_recipient_number,
            payment_date,
            proof_of_payment_voucher,
        } = deductedCommissionFields;
    
        return (
            payable_to_insurer !== "" &&
            payment_voucher_date !== "" &&
            payment_voucher_id !== "" &&
            insurer_recipient_number !== "" &&
            payment_date !== "" &&
            proof_of_payment_voucher !== ""
        );
    };

    manageFlowFour = (previousProps: any) => {
        if (this.props.flowValue === 4) {
            const {
                paidAmount,
                deductedCommissionFields,
                deductedCommissionFromPayment,
                paymentReceipent,
                balance,
                updateFlowFourBalance,
            } = this.props;
            const {
                commission_amount,
                payable_to_insurer,
            } = deductedCommissionFields;

            const isValidPaymentFlowFour = this.isValidPaymentFlowFour(deductedCommissionFields, paymentReceipent, paidAmount, deductedCommissionFromPayment);
            const isValidSecondPaymentFlowFour = this.isValidSecondPaymentFlowFour(deductedCommissionFields);

            let calculatedBal = balance.totalBalance;
        
            if (balance.totalBalance !== 0) {
                calculatedBal = Number(paidAmount) - Number(commission_amount);
                if (isValidSecondPaymentFlowFour) {
                    calculatedBal -= Number(payable_to_insurer);
                }
            }

            if (isValidPaymentFlowFour && !this.state.isShownComissionAmountFlowFour) {
                this.setState({isShownComissionAmountFlowFour: true})
            }

            if (isValidSecondPaymentFlowFour && !this.state.isShownPayableToInsurerFlowFour) {
                this.setState({isShownPayableToInsurerFlowFour: true})
            }
            
            if (
                isValidPaymentFlowFour &&
                previousProps.balance.totalBalance !== calculatedBal
            ) {
                updateFlowFourBalance(calculatedBal);
            }
        }
    };


    manageFlowFiveInstallments = () => {
        const {
            paid_amount,
            quotation_amount,
            flowFiveInstallments,
        } = this.state;
        if (flowFiveInstallments.length > 0) {
            let totalInstallmentBalance = 0;
            let updateFlowFiveIntallmentsWithShowInstallment =
                flowFiveInstallments.map((item: any, index: number) => {
                    const {
                        installment_amount,
                        due_date,
                        installment_paid,
                        insurance_receipt_number,
                        insurer_receipt_date,
                        proof_of_payment_installment,
                        insurer_receipt_number,
                        payment_date,
                        proof_of_payment_voucher_installment
                    } = item;

                    if (
                        installment_amount !== "" &&
                        due_date !== "" &&
                        installment_paid === "yes" &&
                        insurance_receipt_number !== "" &&
                        insurer_receipt_date !== "" &&
                        proof_of_payment_installment !== "" &&
                        insurer_receipt_number !== "" &&
                        payment_date !== "" &&
                        proof_of_payment_voucher_installment !== ""
                    ) {
                        totalInstallmentBalance += Number(installment_amount);
                        item.isShownInstallmentAmount = true;
                        return item;
                    } else {
                        return item;
                    }
                });

            const totalremaining =
                Number(quotation_amount) - (Number(paid_amount) + totalInstallmentBalance);
            if (this.state.balance.totalBalance !== totalremaining) {
                // In order to bypass the maximum call stack error in the test environment
                if (process.env.NODE_ENV === "test") {
                    return;
                }
                this.setState({
                    balance: { paid_amount, totalBalance: totalremaining },
                    flowFiveInstallments: updateFlowFiveIntallmentsWithShowInstallment,
                });
            }
        }
    }

    manageFlowFive = () => {
        const {
            paid_amount,
            deduct_commission_from_payment,
            payment_recipient,
            quotation_amount,
            siibCommonFields,
            flow,
            isShownAddInstallmentBtn,
        } = this.state;
        const { insurance_receipt_number, payment_date, proof_of_payment, payment_voucher_date, payment_review_payment_voucher_id, payment_voucher_payment_date, proof_of_payment_voucher } =
            siibCommonFields;

        if (flow === 5 && deduct_commission_from_payment === "no") {
            const isSiibPaymentValidated =
                payment_recipient !== "" &&
                payment_date !== "" &&
                insurance_receipt_number !== "" && 
                proof_of_payment !== "" &&
                payment_voucher_date !== "" &&
                payment_review_payment_voucher_id !== "" &&
                payment_voucher_payment_date !== "" &&
                proof_of_payment_voucher !== "";

            if (isSiibPaymentValidated && !isShownAddInstallmentBtn) {
                const totalBalanceCal = Number(quotation_amount) - Number(paid_amount);
                this.setState({
                    isShownAddInstallmentBtn: true,
                    balance: { paid_amount, totalBalance: totalBalanceCal },
                    isShownDownPaymentFlowFive: true
                });
            }
            this.manageFlowFiveInstallments()
        }
    };

    updateFlowFourBalance = (calculatedBal: any) => {
        const { paid_amount, deductedCommissionFields } = this.state;
        this.setState({
            balance: {
                paid_amount:
                    Number(paid_amount) -
                    Number(deductedCommissionFields.commission_amount),
                totalBalance: calculatedBal,
            },
        });
    };

    isValidSiibPaymentFlowSix = (
        payment_recipient: string,
        paid_amount: string,
        deduct_commission_from_payment: string,
        siibCommonFields: SiibCommonFields
    ): boolean => {
        return (
            payment_recipient !== "" &&
            paid_amount !== "" &&
            deduct_commission_from_payment !== "" &&
            siibCommonFields.commission_amount !== "" &&
            siibCommonFields.commission_rate !== "" &&
            siibCommonFields.invoice_date !== "" &&
            siibCommonFields.receipt_date !== "" &&
            siibCommonFields.siib_invoice_id !== "" &&
            siibCommonFields.siib_receipt_id !== ""
        );
    };

    isValidSecondSiibPaymentFlowSix = (
        siibCommonFields: SiibCommonFields
      ): boolean => {
        return (
            siibCommonFields.payable_to_insurer !== "" &&
            siibCommonFields.payment_voucher_date !== "" &&
            siibCommonFields.payment_review_payment_voucher_id !== "" &&
            siibCommonFields.insurer_recipient_number !== "" &&
            siibCommonFields.payment_voucher_payment_date !== "" &&
            siibCommonFields.proof_of_payment_voucher !== ""
        );
    };

    isValidFirstInstallmentConditionFlowSix = (item: any) => {
        const {
            installment_amount,
            due_date,
            installment_paid,
            commission_amount,
            deduct_commission_with_vat,
            invoice_date,
            receipt_date,
            siib_invoice_id
        } = item;
    
        return (
            installment_amount !== "" &&
            due_date !== "" &&
            installment_paid === "yes" &&
            commission_amount !== "" &&
            deduct_commission_with_vat !== "" &&
            invoice_date !== "" &&
            receipt_date !== "" &&
            siib_invoice_id !== ""
        );
    };
    
    isValidSecondInstallmentConditionFlowSix = (item: any) => {
        const {
            payable_to_insurer,
            payment_review_payment_voucher,
            payment_voucher_date,
            payment_review_payment_voucher_id,
            insurer_receipt_number,
            payment_date,
            installment_proof_of_payment
        } = item;
    
        return (
            payable_to_insurer !== "" &&
            payment_review_payment_voucher !== "" &&
            payment_voucher_date !== "" &&
            payment_review_payment_voucher_id !== "" &&
            insurer_receipt_number !== "" &&
            payment_date !== "" &&
            installment_proof_of_payment !== ""
        );
    };

    manageFlowSix = () => {
        const {
            flow,
            payment_recipient,
            paid_amount,
            deduct_commission_from_payment,
            isShownAddInstallmentBtn,
            installments: siibInstallment,
            quotation_amount,
            siibCommonFields,
            balance
        } = this.state;

        const isSiibPaymentValidate = this.isValidSiibPaymentFlowSix(payment_recipient, paid_amount, deduct_commission_from_payment, siibCommonFields)
        const isSecondSiibPaymentValidate = this.isValidSecondSiibPaymentFlowSix(siibCommonFields)

        if (siibInstallment.length > 0) {
            let installmentBalance = 0;
            siibInstallment.forEach((item: any) => {       
                if (this.isValidFirstInstallmentConditionFlowSix(item)) {
                    installmentBalance += Number(item.commission_amount);
                    item.isShownComissionValue = true;
                }
    
                if (this.isValidSecondInstallmentConditionFlowSix(item)) {
                    installmentBalance += Number(item.payable_to_insurer);
                    item.isShownPaybleToInsurer = true;
                }
            });

            const totalremainingAmount =
                Number(quotation_amount) -
                ((Number(siibCommonFields.commission_amount) + Number(siibCommonFields.payable_to_insurer)) + installmentBalance);
            if (balance.totalBalance !== totalremainingAmount) {
                // In order to bypass the maximum call stack error in the test environment
                if (process.env.NODE_ENV === "test") {
                    return;
                }
                this.setState({
                    balance: { paid_amount, totalBalance: totalremainingAmount },
                });
            }
        }

        if (flow === 6 && isSiibPaymentValidate) {
            this.handleFlowSix(siibCommonFields, isSecondSiibPaymentValidate, isShownAddInstallmentBtn, quotation_amount, paid_amount);
        }
    };

    handleFlowSix = (siibCommonFields: any, isSecondSiibPaymentValidate: boolean, isShownAddInstallmentBtn: boolean, quotation_amount: string, paid_amount: string) => {
        const { balance } = this.state;
        let newTotalBalance = Number(quotation_amount) - Number(siibCommonFields.commission_amount);
      
        if (!this.state.isShownCommissionAmountFlowSix) {
          this.setState({ isShownCommissionAmountFlowSix: true });
        }
      
        if (isSecondSiibPaymentValidate) {
          newTotalBalance -= Number(siibCommonFields.payable_to_insurer);
          if (!this.state.isShownPayableToInsurerFlowSix) {
            this.setState({ 
                isShownPayableToInsurerFlowSix: true, 
                isShownAddInstallmentBtn: true 
            });
          }
        }
      
        if (balance.totalBalance !== newTotalBalance && !isShownAddInstallmentBtn) {
          this.setState({
            balance: { paid_amount, totalBalance: newTotalBalance },
          });
        }
      };

    manageFlowSeven = (previousState: any) => {
        const {
            flow,
            payment_recipient,
            siibCommonFields,
        } = this.state;

        const isSiibPaymentValidate =
            payment_recipient !== "" &&
            siibCommonFields.payment_date !== "" &&
            siibCommonFields.refund_amount_to_customer !== "" &&
            siibCommonFields.policy_release_requested !== "no" &&
            siibCommonFields.proof_of_payment !== "" &&
            siibCommonFields.payment_voucher_date !== "" &&
            siibCommonFields.payment_review_payment_voucher_id !== "";

        const calculatedBal = Number(this.state.quotation_amount) - Number(siibCommonFields.refund_amount_to_customer)
        if (flow === 7 && isSiibPaymentValidate && previousState.balance.totalBalance !== calculatedBal) {
            this.setState({
                balance: { showRefundAmountValue: true, totalBalance: calculatedBal },
            });
        }
    };

    removeFilePaymentReview = (fileId: any) => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
         this.removeFileAPI = this.apiCall({
            contentType: 'application/json',
            method: 'DELETE',
            endPoint: `bx_block_revision_request/revision_requests/${id}/remove_file`,
            body: {
                "data": {
                    "file_id": [fileId]
                }
            }
        });
    }
    fileRemoveApiReceivePaymentReview = (message:any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.removeFileAPI !== null &&
            this.removeFileAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            /*istanbul ignore next*/
            if (responseJson.data) {
                responseJson.data?.success && this.setState({
                    alertType: "success",
                    showAlert: true,
                    alertMessage: responseJson.data?.success,
                })
            }
        }
    }
    insurerCommonFieldsChangeHandler = (value: any, fieldToupdate: string) => {
        this.setState((prevState) => ({
            insurerCommonFields: {
                ...prevState.insurerCommonFields,
                [`${fieldToupdate}`]:
                    typeof value === "boolean" ? this.getValue(value) : value,
            },
        }));
    };

    insurerInstallmentChnageHandler = (
        value: any,
        fieldToupdate: string,
        index: number,
        formikRef: any
    ) => {
        this.formikRef=formikRef
        let cloneInstallments = [...this.state.installments];
        if (fieldToupdate === "deduct_commission_with_vat") {
            let commissionAmt = 0;
            
            if (value === 'no') {
                commissionAmt = (Number(cloneInstallments[index].installment_amount)/1.15) * Number(cloneInstallments[index].commission_rate) / 100
            } 
            else {
                commissionAmt = Number(cloneInstallments[index].installment_amount) * Number(cloneInstallments[index].commission_rate) / 100
            }
            cloneInstallments[index][`${fieldToupdate}` as keyof Installment] = value;
            cloneInstallments[index][`commission_amount` as keyof Installment] = (commissionAmt).toFixed(2)
            cloneInstallments[index][`payable_to_insurer` as keyof Installment] = (Number(cloneInstallments[index].installment_amount) - commissionAmt).toFixed(2);
        } else if (typeof value === "boolean" && fieldToupdate !== "commission_rate") {
            cloneInstallments[index][`${fieldToupdate}` as keyof Installment] = this.getValue(value);
        } else {
            cloneInstallments[index][`${fieldToupdate}` as keyof Installment] = value;
        }
        this.setState({ installments: cloneInstallments });
        if (formikRef !== undefined) {
            this.formikRef= formikRef;
            this.formikRef.current?.setFieldValue('fieldsData.flowFieldObj.installment_attributes', cloneInstallments)}
    };


    checkInvoiceReceiptBtnDisabledForInstallment = (formValues: any, installmentIndex: any) => {
        const values = formValues?.fieldsData;
        const installment = values?.flowFieldObj?.installment_attributes?.[installmentIndex];
        const {installment_amount, due_date, installment_paid, commission_rate, commission_amount, receipt_date, receipt, siib_receipt_id, deduct_commission_with_vat, siib_invoice_id, invoice_date, invoice_file} = installment || {}
        if (
            installment &&
            ((!installment_amount || !due_date || !installment_paid ||
                !commission_rate || !commission_amount || !deduct_commission_with_vat ||
                !invoice_date || !receipt_date) ||
                (invoice_file || siib_invoice_id || receipt || siib_receipt_id))
        ) {
            return true;
        } else {
            return false;
        }
    }

    checkCreateInvoiceReceiptBtnIsDisabled = (formValues: any) => {
        const { paymentReceipent, flowValue, paidAmount, deductedCommissionFromPayment, deductedCommissionFields, siibCommonFields } = this.props
        const { commission_amount, commission_rate, deduct_commission_with_vat, invoice_date, receipt_date, invoice_file, receipt } = flowValue === 6 ? siibCommonFields : (deductedCommissionFields || {})
        if (paymentReceipent === "Siib" && paidAmount !== "" && deductedCommissionFromPayment === "yes" && commission_amount !== "" && commission_rate !== "" && deduct_commission_with_vat !== "" && invoice_date !== "" && receipt_date !== "" && Object.keys(invoice_file || {}).length === 0 && Object.keys(receipt || {}).length === 0) {
            return false
        }

        return true
    }

    checkBtnPaymentVoucherBtnDisabledflowFour = () => {
        const { paymentReceipent, paidAmount, deductedCommissionFromPayment, deductedCommissionFields } = this.props
        const { commission_amount, commission_rate, payment_voucher_date, payment_voucher } = deductedCommissionFields
        if (paymentReceipent === "Siib" && paidAmount !== "" && deductedCommissionFromPayment === "yes" && commission_amount !== "" && commission_rate !== "" && payment_voucher_date !== "" && Object.keys(payment_voucher || {}).length === 0) {
            return false
        }
        return true
    }

    flowFiveInstallmentChangeHandler = (
        value: any,
        fieldToupdate: string,
        index: number,
        formikRef: any
    ) => {
        let cloneInstallments = [...this.state.flowFiveInstallments];
        if (typeof value === "boolean") {
            cloneInstallments[index][
                `${fieldToupdate}` as keyof FlowFiveInstallments
            ] = this.getValue(value);
        } else {
            cloneInstallments[index][
                `${fieldToupdate}` as keyof FlowFiveInstallments
            ] = value;
        }
        this.setState({ flowFiveInstallments: cloneInstallments });
        if (formikRef !== undefined) {
            this.formikRef= formikRef;
            this.formikRef.current?.setFieldValue('fieldsData.flowFieldObj.installment_attributes', cloneInstallments)}
    };

    siiCommonFieldChangeHandler = (value: any, fieldsName: string, formData: any = {}) => {
        let commissionRate =Number(formData?.fieldsData?.flowFieldObj?.commission_rate) || 0;
        
        if (fieldsName === "payment_review_voucher_payment_date") {
            this.setState((prevState) => ({
                siibCommonFields: {
                    ...prevState.siibCommonFields,
                    payment_voucher_payment_date: value,
                },
            }));
        } else if (fieldsName === "deduct_commission_with_vat") {
            let commissionAmt = 0;
            if (value === "no") {
                commissionAmt = (Number(this.state.paid_amount)/1.15) * commissionRate/100;
            }
            else {
                commissionAmt = Number(this.state.paid_amount) * commissionRate/100;
            }
            this.setState((prevState) => ({
                siibCommonFields: {
                    ...prevState.siibCommonFields,
                    commission_amount: commissionAmt.toFixed(2),
                    payable_to_insurer: (Number(this.state.paid_amount) - commissionAmt).toFixed(2),
                    [`${fieldsName}`]: value,
                },
            }));
        }
        else {
            this.setState((prevState) => ({
                siibCommonFields: {
                    ...prevState.siibCommonFields,
                    [`${fieldsName}`]:
                        typeof value === "boolean" ? this.getValue(value) : value,
                },
            }));
        }
    };
    deductedCommissionFeildsHandlers = (value: any, fieldsName: string) => {
        const paidAmmountInNumber = Number(this.state.paid_amount)
        if (fieldsName === "commission_rate") {
            let commissionAmt = Number((Number(value) * paidAmmountInNumber / 100).toFixed(2))
            let payableToInsurer = Number((paidAmmountInNumber - commissionAmt).toFixed(2))
            this.setState((prevState) => ({
                deductedCommissionFields: {
                    ...prevState.deductedCommissionFields,
                    commission_rate: value,
                    commission_amount: commissionAmt,
                    payable_to_insurer: payableToInsurer
                },
            }));
            return;
        }

        if (fieldsName === "deduct_commission_with_vat") {
            let commissionAmt = value === "yes" ? (Number(this.state.deductedCommissionFields.commission_rate) * paidAmmountInNumber / 100).toFixed(2) : (paidAmmountInNumber / 1.15 * (Number(this.state.deductedCommissionFields.commission_rate) / 100)).toFixed(2)
            let payableToInsurer = Number((paidAmmountInNumber - Number(commissionAmt)).toFixed(2))
            this.setState((prevState) => ({
                deductedCommissionFields: {
                    ...prevState.deductedCommissionFields,
                    deduct_commission_with_vat: value,
                    commission_amount: commissionAmt,
                    payable_to_insurer: payableToInsurer
                },
            }));
            return;
        }

        this.setState((prevState) => ({
            deductedCommissionFields: {
                ...prevState.deductedCommissionFields,
                [`${fieldsName}`]:
                    typeof value === "boolean" ? this.getValue(value) : value,
            },
        }));
    };
    submitHandler = (values: any, draft: string, isEdit: boolean) => {
        const getApiBodyData = this.createFormDataMethod(values.fieldsData, draft, isEdit);
        this.createPolicyRequestApiCallHandller(getApiBodyData, isEdit);
    };

    siibSubmitHandler = (values: any, draft: string, isEdit: boolean) => {
        const { flow } = this.state
        if (flow === 5) {
            const getSiibApiBodyFormDataFlowFive = this.createSiibApiBodyFormDataFlowFive(values.fieldsData, draft, isEdit)
            this.createPolicyRequestApiCallHandller(getSiibApiBodyFormDataFlowFive, isEdit);
            return;
        }
        const getSiibApiBodyFormData = this.createSiibFormDataMethod(
            values.fieldsData, draft, isEdit
        );
        this.createPolicyRequestApiCallHandller(getSiibApiBodyFormData, isEdit);
    };


    createSiibApiBodyFormDataFlowFive = (values: any, draft: string, isUpdate: boolean) => {
        const {
            paid_amount,
            payment_recipient,
            deduct_commission_from_payment,
            flowFieldObj,
        } = values;
        const {
            insurance_receipt_number,
            payment_date,
            proof_of_payment,
            payment_review_payment_voucher_id,
            policy_release_requested,
            insurer_recipient_number,
            payment_voucher_payment_date,
            proof_of_payment_voucher,
            installment_attributes,
            payment_voucher_date
        } = flowFieldObj;
        const formData = new FormData();
        formData.append('data[revision_quotation_payment][payment_recipient]', payment_recipient)
        formData.append('data[revision_quotation_payment][paid_amount]', paid_amount)
        formData.append('data[revision_quotation_payment][deduct_commission_from_payment]', deduct_commission_from_payment)
        formData.append('data[revision_quotation_payment][insurance_receipt_number]', insurance_receipt_number)
        formData.append('data[revision_quotation_payment][payment_date]', payment_date)
        if(!proof_of_payment.url){ 
           Object.keys(proof_of_payment).length||proof_of_payment.size&& formData.append('data[proof_of_payment]', proof_of_payment)
        }
        formData.append('data[revision_quotation_payment][payment_voucher][insurer_recipient_number]', insurer_recipient_number)
        formData.append('data[revision_quotation_payment][payment_voucher][payment_voucher_date]', payment_voucher_date)
        formData.append('data[revision_quotation_payment][payment_voucher][payment_date]', payment_voucher_payment_date)

        formData.append(`data[revision_quotation_payment][payment_voucher][payable_to_insurer]`, paid_amount);
        if(!proof_of_payment_voucher.url){
          Object.keys(proof_of_payment_voucher).length || proof_of_payment_voucher.size&& formData.append('data[proof_of_payment_voucher]', proof_of_payment_voucher)
       }
        formData.append('data[revision_quotation_payment][policy_release_requested]', policy_release_requested)

        installment_attributes && installment_attributes.length > 0 && installment_attributes.forEach((item: any, index: number) => {
            formData.append(`data[revision_quotation_payment][installment_attributes][${index}][installment_amount]`, item.installment_amount)
            formData.append(`data[revision_quotation_payment][installment_attributes][${index}][due_date]`, item.due_date)
            formData.append(`data[revision_quotation_payment][installment_attributes][${index}][installment_paid]`, item.installment_paid)
            formData.append(`data[revision_quotation_payment][installment_attributes][${index}][insurer_receipt_number]`, item.insurance_receipt_number)
            formData.append(`data[revision_quotation_payment][installment_attributes][${index}][insurer_receipt_date]`, item.insurer_receipt_date)
           if(!item.proof_of_payment_installment.url){
             Object.keys(item.proof_of_payment_installment).length ||item.proof_of_payment_installment.size&&  formData.append(`data[proof_of_payment_installment][${index}]`, item.proof_of_payment_installment)
           }
            formData.append(`data[revision_quotation_payment][installment_attributes][${index}][payment_voucher][payment_voucher_id]`, item.payment_voucher_id)
            formData.append(`data[revision_quotation_payment][installment_attributes][${index}][payment_voucher][insurer_recipient_number]`, item.insurer_receipt_number)
            formData.append(`data[revision_quotation_payment][installment_attributes][${index}][payment_voucher][payment_date]`, item.payment_date)
            formData.append(`data[revision_quotation_payment][installment_attributes][${index}][payment_voucher][payable_to_insurer]`, item.installment_amount)
            if(!item.proof_of_payment_voucher_installment.url){
               Object.keys(item.proof_of_payment_installment).length||item.proof_of_payment_installment.size&& formData.append(`data[proof_of_payment_vouch_installment][${index}]`, item.proof_of_payment_voucher_installment)
            }
            
            this.getId(isUpdate, index, this.props.rrStageSevenData?.data?.attributes?.revision_installments?.data?.[index]?.id, formData)
        });
        formData.append('data[revision_quotation_payment][balance]', String(this.state.balance.totalBalance))
        formData.append('data[revision_quotation_payment][save_as_draft]', draft)

        return formData
    }

    getId = (isUpdate: boolean, index: number, recordId: string, formData: any) => {
        return isUpdate && recordId && formData.append(`data[revision_quotation_payment][installment_attributes][${index}][id]`, recordId)
    }

    deductedCommissionFormSubmitHandler = (values: any, draft: string, isEdit: boolean) => {
        const formData = new FormData();
        const { balance } = this.state;
        const { flowFieldObj, paid_amount, payment_recipient, deduct_commission_from_payment } = values;
        const { commission_rate, commission_amount, deduct_commission_with_vat, invoice_date, payment_voucher_date, siib_invoice_id, siib_receipt_id, payable_to_insurer, payment_date, payment_voucher_id, insurer_recipient_number, receipt_date, proof_of_payment_voucher, policy_release_requested } = flowFieldObj || {}
        formData.append(`data[revision_quotation_payment][paid_amount]`, paid_amount)
        formData.append(`data[revision_quotation_payment][payment_recipient]`, payment_recipient)
        formData.append(`data[revision_quotation_payment][deduct_commission_from_payment]`, deduct_commission_from_payment)
        formData.append(`data[revision_quotation_payment][commission_attributes][commission_rate]`, commission_rate)
        formData.append(`data[revision_quotation_payment][commission_attributes][commission_amount]`, commission_amount)
        formData.append(`data[revision_quotation_payment][commission_attributes][deduct_commission_with_vat]`, deduct_commission_with_vat)
        formData.append(`data[revision_quotation_payment][commission_attributes][invoice_date]`, invoice_date)
        formData.append(`data[revision_quotation_payment][receipt_attributes][receipt_date]`, receipt_date)
        formData.append(`data[revision_quotation_payment][commission_attributes][siib_invoice_id]`, siib_invoice_id)
        formData.append(`data[revision_quotation_payment][receipt_attributes][siib_receipt_id]`, siib_receipt_id)
        formData.append(`data[revision_quotation_payment][payment_voucher][payable_to_insurer]`, payable_to_insurer)
        formData.append(`data[revision_quotation_payment][payment_voucher][payment_voucher_date]`, payment_voucher_date)
        formData.append(`data[revision_quotation_payment][payment_voucher][payment_voucher_id]`, payment_voucher_id)
        formData.append(`data[revision_quotation_payment][payment_voucher][insurer_recipient_number]`, insurer_recipient_number)
        formData.append(`data[revision_quotation_payment][payment_voucher][payment_date]`, payment_date)
        
        if(!proof_of_payment_voucher?.url){
            proof_of_payment_voucher&&  Object.keys(proof_of_payment_voucher).length||proof_of_payment_voucher?.size&& formData.append(`data[proof_of_payment_voucher]`, proof_of_payment_voucher)
        }
        formData.append(`data[revision_quotation_payment][policy_release_requested]`, policy_release_requested)
        formData.append(`data[revision_quotation_payment][balance]`, `${balance.totalBalance}`)
        formData.append(`data[revision_quotation_payment][save_as_draft]`, draft)
        formData.append(`data[revenue_siib_commission]`, commission_amount)

        this.createPolicyRequestApiCallHandller(formData, isEdit)
    }

    createSiibFormDataMethod = (values: any, draft: string, isUpdate: boolean) => {
        const formData = new FormData();
        const { flowFieldObj, ...restValues } = values;

        const { balance, flow } = this.state;
        let revenue_commission = this.getRevenueCommission(flow, flowFieldObj.commission_amount);

        restValues.balance = balance.totalBalance;

        const appendInstallmentAttributes = (
            installment: any,
            index: number
        ): void => {
            const {
                installment_amount,
                payment_voucher_date,
                due_date,
                installment_paid,
                insurer_receipt_number,
                payment_date,
                commission_rate,
                commission_amount,
                deduct_commission_with_vat,
                invoice_date,
                receipt_date,
                payable_to_insurer,
                installment_proof_of_payment,
                siib_invoice_id
            } = installment;
            const dataRevisionKey = `data[revision_quotation_payment][installment_attributes][${index}]`;

            formData.append(
                `${dataRevisionKey}[installment_amount]`,
                installment_amount
            );
            formData.append(`${dataRevisionKey}[due_date]`, due_date);
            formData.append(`${dataRevisionKey}[installment_paid]`, installment_paid);
            formData.append(
                `${dataRevisionKey}[commission_attributes][commission_rate]`,
                commission_rate
            );
            formData.append(
                `${dataRevisionKey}[commission_attributes][commission_amount]`,
                commission_amount
            );
            formData.append(
                `${dataRevisionKey}[commission_attributes][deduct_commission_with_vat]`,
                deduct_commission_with_vat
            );
            formData.append(
                `${dataRevisionKey}[commission_attributes][invoice_date]`,
                invoice_date
            );
            siib_invoice_id && formData.append(`${dataRevisionKey}[commission_attributes][siib_invoice_id]`, siib_invoice_id)
            formData.append(
                `${dataRevisionKey}[receipt_attributes][receipt_date]`,
                receipt_date
            );
            formData.append(
                `${dataRevisionKey}[payment_voucher][payable_to_insurer]`,
                payable_to_insurer
            );
            formData.append(
                `${dataRevisionKey}[payment_voucher][insurer_recipient_number]`,
                insurer_receipt_number
            );
            formData.append(`${dataRevisionKey}[payment_voucher][payment_date]`, payment_date);
            formData.append(`${dataRevisionKey}[payment_voucher][payment_voucher_date]`, payment_voucher_date);

            if(!installment_proof_of_payment?.url){
                installment_proof_of_payment && Object.keys(installment_proof_of_payment).length|| installment_proof_of_payment?.size &&formData.append(`data[proof_of_payment_vouch_installment][${index}]`, installment_proof_of_payment)
            }
            
            this.getId(isUpdate, index, this.props.rrStageSevenData?.data?.attributes?.revision_installments?.data?.[index]?.id, formData)
            revenue_commission += Number(commission_amount)
        };

        const appendField = ([key, value]: [string, any]): void => {
            if (key === "installment_attributes") {
                value.forEach(appendInstallmentAttributes);
            } else if(key==="proof_of_payment_voucher" && !value.url){
          Object.keys(value).length || value.size&& formData.append('data[proof_of_payment_voucher]', flowFieldObj.proof_of_payment_voucher)

            }else if(key==="proof_of_payment" && !value.url){
                Object.keys(value).length || value.size&&formData.append('data[proof_of_payment]', flowFieldObj.proof_of_payment)
            }
            else {
                this.getPaymentReviewKeys(key, formData, value, values)
            }
        };

        Object.entries(restValues).forEach(appendField);

        Object.entries(flowFieldObj).forEach(appendField);
        formData.append(`data[revision_quotation_payment][save_as_draft]`, draft)
        flow ==6 && formData.append(`data[revenue_siib_commission]`, `${revenue_commission}`)
        return formData;
    };

    getRevenueCommission = (flow: number | null, commission_amount: string) => {
        if (flow == 6) return Number(commission_amount)
        else return 0;
    }

    getPaymentReviewKeys = (key: string, formData: any, value: any, formDetails: any) => {
        if (key === "insurer_recipient_number" || key === "payment_voucher_payment_date" || key === "payment_voucher_date" || key === "payable_to_insurer") {
            let newKey = key === "payment_voucher_payment_date" ? "payment_date" : key
            formData.append(`data[revision_quotation_payment][payment_voucher][${newKey}]`, this.getVoucherFields(newKey, formDetails.paid_amount, value));
        } else if ((key.includes('commission') && key !== 'deduct_commission_from_payment') || key === 'invoice_date' || key === "siib_invoice_id") {
            formData.append(`data[revision_quotation_payment][commission_attributes][${key}]`, value);
        }
        else if (key === 'receipt_date') {
            formData.append(`data[revision_quotation_payment][receipt_attributes][${key}]`, value);
        }
        else if ((key === 'proof_of_payment' || key === 'proof_of_payment_voucher') && !value.url) {
            formData.append(`data[${key}]`, value);
        }
        else if(!(key === "invoice_file" || key === "receipt")) {
            formData.append(`data[revision_quotation_payment][${key}]`, value);
        }
        return formData
    }

    getVoucherFields = (newKey: string, paidAmount: string, value: any) => {
        return (this.state.flow===3 && newKey === "payable_to_insurer") ? paidAmount : value
    }

    createInvoiceAndReceipt = (formValues: any, flowValue: any, formikRef: any, installmentIndex: any) => {
        this.formikRef = formikRef;
        this.setState({
            installmentIndex: installmentIndex,
            isinvoiceAndReceiptBtnLoading: true,
            receiptBtnLoading: true,
            showAlert: true,
            alertType: "warning",
            alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
        });
        const userData = JSON.parse(window.localStorage.getItem('loginData') ?? '{}');
        const token = userData.token || '';
        const { id } = this.props.rrStageOneData?.data?.attributes 
        const insurance_company_id = this.props.rrStageOneData?.data?.attributes?.revision_input?.data?.attributes?.insurance_company_id?.value || ""

        const values = formValues?.fieldsData;
        let invoiceBody: any;
        let receiptBody: any;

        if(installmentIndex !== undefined && values){
            const installment = values?.flowFieldObj?.installment_attributes[installmentIndex]
            invoiceBody = {
                paid_amount: installment?.installment_amount,
                file_key: "invoice_pdf",
                recipient_type: "Siib",
                invoice_date: installment?.invoice_date,
                commission_rate: installment?.commission_rate,
                deduct_siib_commission_with_vat: installment?.deduct_commission_with_vat,
                installment_no: installmentIndex + 2,
            }

            receiptBody = {
                paid_amount: installment?.installment_amount,
                file_key: "receipt_pdf",
                recipient_type: "Siib",
                receipt_date: installment?.receipt_date,
                insurance_company_id: insurance_company_id,
                deduct_siib_commission_with_vat: installment?.deduct_commission_with_vat,
                installment_no: installmentIndex + 2,
            }
        } else {
            invoiceBody = {
                paid_amount: values?.paid_amount,
                file_key: "invoice_pdf",
                recipient_type: "Siib",
                invoice_date: formValues?.fieldsData?.flowFieldObj?.invoice_date,
                commission_rate: formValues?.fieldsData?.flowFieldObj?.commission_rate,
                deduct_siib_commission_with_vat: formValues?.fieldsData?.flowFieldObj?.deduct_commission_with_vat,
            }

            receiptBody = {
                paid_amount: values?.paid_amount,
                file_key: "receipt_pdf",
                recipient_type: "Siib",
                receipt_date: formValues?.fieldsData?.flowFieldObj?.receipt_date,
                insurance_company_id: insurance_company_id,
                deduct_siib_commission_with_vat: formValues?.fieldsData?.flowFieldObj?.deduct_commission_with_vat,
            }
        }

        this.createInvoiceApiId = this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?revision_request_id=${id}&stage=7&flow=${flowValue}`,
            token: token || '',
            body: {
                data: invoiceBody
            }
        })

        this.createReceiptApiId = this.apiCall({
            contentType: 'application/json',
            method: 'POST',
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?revision_request_id=${id}&stage=7&flow=${flowValue}`,
            token: token || '',
            body: {
                data: receiptBody
            }
        })

    }

    createPaymentVoucherFlowFour = () => {
        this.setState({
            isPaymentVoucherFlowFourBtnLoading: true,
            showAlert: true,
            alertType:"warning",
            alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
          });
        const userData = JSON.parse(window.localStorage.getItem('loginData') ?? '{}');
        const token = userData.token || '';
        const { paidAmount, deductedCommissionFields } = this.props
        const { payment_voucher_date, commission_amount } = deductedCommissionFields
        const formData = new FormData();

        formData.append("data[paid_amount]", paidAmount);
        formData.append("data[file_key]", "payment_voucher_pdf");
        formData.append("data[recipient_type]", "Insurer");
        formData.append("data[payment_date]", payment_voucher_date);
        formData.append("data[commission_amount]", commission_amount);

        this.createPaymentVoucherFlowFourApiId = this.apiCall({
            type: 'FormData',
            method: 'POST',
            endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?revision_request_id=${this.props.parentPolicyID}&stage=7&flow=4`,
            token: token || '',
            body: formData
        })
    }

    createFormDataMethod = (values: any, draft: string, isUpdate: boolean): FormData => {
        const formData = new FormData();
        delete values.deduct_commission_from_payment;
        const { flowFieldObj, ...restValues } = values;

        const { balance } = this.state;

        restValues.balance = balance.totalBalance;

        const appendInstallmentAttributes = (
            installment: any,
            index: number
        ): void => {
            const {
                installment_amount,
                due_date,
                installment_paid,
                insurer_receipt_number,
                payment_date,
                installment_proof_of_payment
            } = installment;
            const dataRevisionKey = `data[revision_quotation_payment][installment_attributes][${index}]`;

            formData.append(
                `${dataRevisionKey}[installment_amount]`,
                installment_amount
            );
            formData.append(`${dataRevisionKey}[due_date]`, due_date);
            formData.append(`${dataRevisionKey}[installment_paid]`, installment_paid);
            formData.append(
                `${dataRevisionKey}[insurer_receipt_number]`,
                insurer_receipt_number
            );
          formData.append(`${dataRevisionKey}[payment_date]`, payment_date);
          if(!installment_proof_of_payment.url){
            Object.keys(installment_proof_of_payment).length||installment_proof_of_payment.size&& formData.append(`data[proof_of_payment_installment][${index}]`, installment_proof_of_payment)
            }
            
            this.getId(isUpdate, index, this.props.rrStageSevenData?.data?.attributes?.revision_installments?.data?.[index]?.id, formData)
        };

        const appendField = ([key, value]: [string, any]): void => {
            if (key === "installment_attributes") {
                value.forEach(appendInstallmentAttributes);
            } else if (key === "payment_voucher_date") {
                formData.append(`data[revision_quotation_payment][payment_voucher][payment_voucher_date]`, value);
            } else if (key === "proof_of_payment" && !value.url || value?.size) {
               Object.keys(value).length||value?.size&&formData.append(`data[proof_of_payment]`, value);
            } else if (this.state.flow === 7 && key === "proof_of_payment") {
                formData.append(`data[${key}]`, value);
            } else {
                formData.append(`data[revision_quotation_payment][${key}]`, value);
            }
        };
        formData.append(`data[revision_quotation_payment][save_as_draft]`, draft)
        Object.entries(restValues).forEach(appendField);

        Object.entries(flowFieldObj).forEach(appendField);

        return formData;
    };

    createPolicyRequestApiCallHandller = (values: any, isEdit: boolean) => {
        this.createPolicyRequestAPIKey = this.apiCall({
            type: "FormData",
            method: isEdit ? "PUT" : "POST",
            body: values,
            endPoint: isEdit ? `bx_block_revision_request/revision_requests/${this.props.parentPolicyID}?stage=7&flow=${this.state.flow}` : `bx_block_revision_request/revision_requests?stage=7&id=${this.props.parentPolicyID}&flow=${this.state.flow}`,
        });
    };

    apiCall(data: any) {
        const { contentType, method, endPoint, body, type } = data;
        const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
        const token = userData.token || "";

        let header: any = {
            token: token,
        };

        if (contentType) {
            header = {
                ...header,
                "Content-Type": contentType,
            };
        }

        const stageSevenRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== "FormData"
            ? stageSevenRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : stageSevenRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        stageSevenRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== "FormData"
            ? stageSevenRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : stageSevenRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );

        runEngine.sendMessage(
            stageSevenRequestMessage.id,
            stageSevenRequestMessage
        );
        return stageSevenRequestMessage.messageId;
    }

    createPolicyRequestAPIResponseHandler = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.createPolicyRequestAPIKey != null &&
            this.createPolicyRequestAPIKey ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const createAPIResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (createAPIResponseJson.data) {
                this.props.setStageData("stageSeven", createAPIResponseJson);
                if (createAPIResponseJson.data.attributes.save_as_draft) {
                    this.setAlert(t("saved_as_draft_successfully"), "success");
                    return;
                }
                if (createAPIResponseJson.data.attributes.add_installment) this.props.onNext(8, true);
                else this.setState({ isPostResponseCalledOnce: true, showNextDialog: true });
                this.setState({ expandedAccordion: false });
            } else if (createAPIResponseJson.error) {
                const errorMessage =
                    createAPIResponseJson.error;
                this.setState({
                    alertType: "error",
                    alertMessage: errorMessage,
                    showAlert: true,
                });
            }
        }
    };

    updatePaymentVoucherStateforFLowthreeAndFive = (voucherData: any) => {
        this.setState((prevState) => ({
            siibCommonFields: {
                ...prevState.siibCommonFields,
                payment_review_payment_voucher: voucherData.attributes.payment_voucher_pdf,
                payment_review_payment_voucher_id: voucherData.attributes.file_id,
            },
        }));
    }

    generateVoucherForSiibApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.createVoucherForSiibApi != null &&
            this.createVoucherForSiibApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const generateVoucherAPIResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (generateVoucherAPIResponse.data) {
                this.setState({ voucherBtnLoading: false });
                this.props.updatePaymentVoucherStateforFLowthreeAndFive(generateVoucherAPIResponse.data)
            } else if (generateVoucherAPIResponse.errors) {
                this.setState({ voucherBtnLoading: false });
                const errorMessage =
                    generateVoucherAPIResponse.error || "Something went wrong";
                this.setState({
                    alertType: "error",
                    alertMessage: errorMessage,
                    showAlert: true,
                });
            }
        }
    };

    paymentVoucherFlowFourStateHandler = (generateVoucherAPIResponse: any) => {
        this.setState((prevState) => ({
            deductedCommissionFields: {
                ...prevState.deductedCommissionFields,
                payment_voucher_id: generateVoucherAPIResponse.data?.attributes?.file_id,
                payment_voucher: generateVoucherAPIResponse.data?.attributes?.payment_voucher_pdf
            }
        }))
    }

    createPaymentVoucherFlowFourReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.createPaymentVoucherFlowFourApiId != null &&
            this.createPaymentVoucherFlowFourApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const generateVoucherAPIResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (generateVoucherAPIResponse.data) {
                this.setState({ isPaymentVoucherFlowFourBtnLoading: false });
                this.props.paymentVoucherFlowFourStateHandler(generateVoucherAPIResponse)
            } else if (generateVoucherAPIResponse.errors) {
                this.setState({ isPaymentVoucherFlowFourBtnLoading: false });
                const errorMessage = generateVoucherAPIResponse.error || "Something went wrong";
                this.setState({
                    alertType: "error",
                    alertMessage: errorMessage,
                    showAlert: true,
                });
            }
        }
    }

    setAlert = (responseJson: any, alertType: string = "error") => {
        this.setState({
            showAlert: true,
            alertMessage: responseJson,
            alertType: alertType,
        });
    };
    closeAlertMessage = async () => {
        this.setState({
            showAlert: false,
            alertMessage: "",
            alertType: "",
        });
    };

    handleHandOverNoteTextStageSeven = (inputText: string) => {
        this.setState({ handOverNoteText: inputText });
        this.props.setStageData("handOverNoteForInvoicing", inputText)
    };

    closeNoteDialogStageSeven = () => {
        this.setState({ showNextDialog: false, handOverNoteText: "" });
    };
    getTrackApiCurrentStage = () => {
        return this.state.moveToStep?.value
            ? this.state.moveToStep.value.toLowerCase()
            : "";
    };

    trackRequestStageSeven = (action: string) => {
        this.setState({ trackFor: action });

        this.trackRequestApiStageSeven = this.apiCall({
            contentType: "application/json",
            method: "POST",
            body: {
                data: {
                    track_revision_request: {
                        stage_status: action === "next" ? "next_stage" : "reject_stage",
                        comment:
                            action === "next"
                                ? this.state.handOverNoteText
                                : this.state.rejectReasonText,
                        current_stage:
                            action === "next" ? "invoicing" : this.getTrackApiCurrentStage(),
                    },
                },
            },
            endPoint: `bx_block_revision_request/revision_requests/${this.props.parentPolicyID}/track_revision_requests?stage=7`,
        });
    };

    getNumberWithOrdinal = (num: any) => {
        let ordinalStringsArray = ["th", "st", "nd", "rd"],
            value = num % 100;
        return num + (ordinalStringsArray[(value - 20) % 10] || ordinalStringsArray[value] || ordinalStringsArray[0]);
    };

    stageSevenTrackApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.trackRequestApiStageSeven !== null &&
            this.trackRequestApiStageSeven ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson.data) {
                if (this.state.trackFor === "next") {
                    this.props.setStageData({
                        handOverNoteForPaymentReview: this.state.handOverNoteText,
                    });
                    this.props.setStageData({
                        handoverNote: this.state.handOverNoteText,
                    });
                    this.closeNoteDialogStageSeven();
                    this.props.onNext(8);
                } else {
                    this.closeRejectDialog();
                    const stageNumber = getStageNumberByName(
                        this.state.moveToStep.value
                    );
                    this.props.onReject(stageNumber);
                }
                this.setState({
                    showAlert: true,
                    alertMessage: this.getAlertMessageForTrackRequest(),
                    alertType: "success",
                    showRejectDialog: false,
                    showNextDialog: false,
                });
            }
        }
    };

    updatepaymentVoucherFlowFiveInstallmen = (voucherData: any) => {
        let currentIndex = this.state.currentInstallmentIndex
        const currentFlow = this.state.currentFlow;
        this.props.paymentVoucherFlowFiveInstallmentStateHandler(voucherData, currentIndex, currentFlow)
    }

    paymentVoucherFlowFiveInstallmentStateHandler = (voucherData: any, currentIndex: any, currentFlow: number) => {
        let cloneInstallments;
        if (currentFlow === 6) {
            cloneInstallments = [...this.state.installments]
            cloneInstallments[currentIndex]['payment_review_payment_voucher'] = voucherData.attributes.payment_voucher_pdf
            cloneInstallments[currentIndex]['payment_review_payment_voucher_id'] = voucherData.attributes.file_id
            this.setState({ installments: cloneInstallments })
        } else {
            cloneInstallments = [...this.state.flowFiveInstallments]
            cloneInstallments[currentIndex]['installment_payment_voucher'] = voucherData.attributes.payment_voucher_pdf
            cloneInstallments[currentIndex]['payment_voucher_id'] = voucherData.attributes.file_id
            this.setState({ flowFiveInstallments: cloneInstallments })
        }
    }

    generatePaymentVoucherForFlowFiveInstallmentReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.generatePaymentVoucherForFlowFiveInstallmentApi != null &&
            this.generatePaymentVoucherForFlowFiveInstallmentApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const generateVoucherAPIResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (generateVoucherAPIResponse.data) {
                if(this.state.currentFlow==6){
                this.updatepaymentVoucherFlowFiveInstallmen(generateVoucherAPIResponse.data) 
                this.setState({ isPaymentVoucherFlowFiveInstallmentBtnLoading: false });
                this.formikRef.current.setFieldValue(`fieldsData.flowFieldObj.installment_attributes[${this.state.currentInstallmentIndex}].payment_review_payment_voucher`, generateVoucherAPIResponse.data?.attributes?.payment_voucher_pdf)
                this.formikRef.current.setFieldValue(`fieldsData.flowFieldObj.installment_attributes[${this.state.currentInstallmentIndex}].payment_review_payment_voucher_id`, generateVoucherAPIResponse.data?.attributes?.file_id)
                }else{

                this.setState({ isPaymentVoucherFlowFiveInstallmentBtnLoading: false });
                this.formikRef.current.setFieldValue(`fieldsData.flowFieldObj.installment_attributes[${this.state.currentInstallmentIndex}].installment_payment_voucher`, generateVoucherAPIResponse.data?.attributes?.payment_voucher_pdf)
                this.formikRef.current.setFieldValue(`fieldsData.flowFieldObj.installment_attributes[${this.state.currentInstallmentIndex}].payment_voucher_id`, generateVoucherAPIResponse.data?.attributes?.file_id)
                this.updatepaymentVoucherFlowFiveInstallmen(generateVoucherAPIResponse.data) 
            }
            
            } else if (generateVoucherAPIResponse.errors) {
                this.setState({ isPaymentVoucherFlowFiveInstallmentBtnLoading: false });
                const errorMessage = generateVoucherAPIResponse.error || "Something went wrong";
                this.setState({
                    alertType: "error",
                    alertMessage: errorMessage,
                    showAlert: true,
                });
            }
        }
    }

    createReceiptApiReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.createReceiptApiId !== null &&
            this.createReceiptApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const invoiceReceiptApiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (invoiceReceiptApiResponse.data) {
                this.setState({
                    receiptBtnLoading: false,
                    showAlert: true,
                    alertType: "success",
                    alertMessage: ActiveLanguage() ? `تم إنشاء إيصال الفاتورة بنجاح` : `Invoice receipt generated successfully`
                })
                if (this.state.installmentIndex !== undefined) {
                    this.formikRef.current.setFieldValue(`fieldsData.flowFieldObj.installment_attributes[${this.state.installmentIndex}].receipt`, invoiceReceiptApiResponse.data.attributes.receipt_pdf)
                    this.formikRef.current.setFieldValue(`fieldsData.flowFieldObj.installment_attributes[${this.state.installmentIndex}].siib_receipt_id`, invoiceReceiptApiResponse.data.attributes.file_id)
                    this.props.createReceiptInstallmentStateHandler(invoiceReceiptApiResponse, this.state.installmentIndex)
                } else {
                    this.formikRef.current.setFieldValue(`fieldsData.flowFieldObj.siib_receipt_id`, invoiceReceiptApiResponse.data.attributes.file_id)
                    this.formikRef.current.setFieldValue(`fieldsData.flowFieldObj.receipt`, invoiceReceiptApiResponse.data.attributes.receipt_pdf)
                    this.props.createReceiptFlowFourStateHandler(invoiceReceiptApiResponse)
                }
            } else {
                this.setApiErrorMessageForPaymentReview(invoiceReceiptApiResponse)
            }
        }
        else {
            this.setApiErrorMessageForPaymentReview(undefined)
        }
    };


    createInvoiceFlowFourStateHandler = (generateVoucherAPIResponse: any) => {
        this.setState((prevState) => ({
            deductedCommissionFields: {
                ...prevState.deductedCommissionFields,
                invoice_file: generateVoucherAPIResponse.data?.attributes?.invoice_pdf,
                siib_invoice_id: generateVoucherAPIResponse.data?.attributes?.file_id
            }
        }))

        this.setState((prevState) => ({
            siibCommonFields: {
                ...prevState.siibCommonFields,
                invoice_file: generateVoucherAPIResponse.data?.attributes?.invoice_pdf,
                siib_invoice_id: generateVoucherAPIResponse.data?.attributes?.file_id
            }
        }))
    }

    createReceiptFlowFourStateHandler = (generateVoucherAPIResponse: any) => {
        this.setState((prevState) => ({
            deductedCommissionFields: {
                ...prevState.deductedCommissionFields,
                receipt: generateVoucherAPIResponse.data?.attributes?.receipt_pdf,
                siib_receipt_id: generateVoucherAPIResponse.data?.attributes?.file_id
            }
        }))

        this.setState((prevState) => ({
            siibCommonFields: {
                ...prevState.siibCommonFields,
                receipt: generateVoucherAPIResponse.data?.attributes?.receipt_pdf,
                siib_receipt_id: generateVoucherAPIResponse.data?.attributes?.file_id
            }
        }))
    }

    createInvoiceInstallmentStateHandler = (response: any, index:any) => {
        const updatedInstallments = [...this.state.installments];
            updatedInstallments[index].invoice_file = response.data?.attributes?.invoice_pdf
            updatedInstallments[index].siib_invoice_id= response.data?.attributes?.file_id
        this.setState({
            installments: updatedInstallments
        });
    }

    createReceiptInstallmentStateHandler = (response: any, index:any) => {
        const updatedInstallments = [...this.state.installments];
            updatedInstallments[index].receipt = response.data?.attributes?.receipt_pdf
            updatedInstallments[index].siib_receipt_id= response.data?.attributes?.file_id

        this.setState({
            installments: updatedInstallments
        });
    }

    createVoucherInstallmentStateHandler = (response: any, index:any) => {
        const updatedInstallments = [...this.state.installments];
            updatedInstallments[index].installment_payment_voucher = response.data?.attributes?.payment_voucher_pdf
            updatedInstallments[index].payment_voucher_id = response.data?.attributes?.file_id
        this.setState({
            installments: updatedInstallments,
        });
    }

    createInvoiceApiIdReceive = (message: any) => {
        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.createInvoiceApiId !== null &&
            this.createInvoiceApiId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.data) {
                this.setState({
                    isinvoiceAndReceiptBtnLoading: false,
                    showAlert: true,
                    alertType: "success",
                    alertMessage: ActiveLanguage() ? `تم إنشاء الفاتورة بنجاح` : `Invoice generated successfully`
                })
                if (this.state.installmentIndex !== undefined) {
                    this.formikRef.current.setFieldValue(`fieldsData.flowFieldObj.installment_attributes[${this.state.installmentIndex}].invoice_file`, responseJson.data.attributes.invoice_pdf)
                   this.formikRef.current.setFieldValue(`fieldsData.flowFieldObj.installment_attributes[${this.state.installmentIndex}].siib_invoice_id`, responseJson.data.attributes.file_id)
                    this.props.createInvoiceInstallmentStateHandler(responseJson, this.state.installmentIndex)

                } else {
                    this.formikRef.current.setFieldValue(`fieldsData.flowFieldObj.siib_invoice_id`, responseJson.data.attributes.file_id)
                    this.formikRef.current.setFieldValue(`fieldsData.flowFieldObj.invoice_file`, responseJson.data.attributes.invoice_pdf)
                    this.props.createInvoiceFlowFourStateHandler(responseJson)
                }

            } else {
                this.setApiErrorMessageForPaymentReview(responseJson)
            }
        }
        else {
            this.setApiErrorMessageForPaymentReview(undefined)
        }
    };

    updateInstallmentIndex = (index: number | undefined) => {
        this.setState({
            installmentIndex: index
        })
    }

    getAlertMessageForTrackRequest = () => {
        let alertMessage;
        if (this.state.trackFor === "next") {
            alertMessage = "Your request has been moved to Invoicing step!";
        } else {
            alertMessage = `Your request has been moved to selected stage!`;
        }
        return alertMessage;
    };

    openRejectDialogStageSeven = () => {
        this.setState({ showRejectDialog: true });
    };

    getRvRequestRejectOptions = () => {
        const array = [
            { value: "info_gathering", label: "Info gathering" },
            { value: "processing", label: "Processing" },
            { value: "awaiting", label: "Awaiting" },
            { value: "operations_review", label: "Operations review" },
        ];
        return array;
    };

    moveToSelectedStepStageSeven = (step: any) => {
        this.setState({ moveToStep: step });
    };

    handleRejectReasonText = (inputText: string) => {
        this.setState({ rejectReasonText: inputText });
    };

    closeRejectDialog = () => {
        this.setState({
            showRejectDialog: false,
            rejectReasonText: "",
            moveToStep: {},
            isBtnVisible: true,
        });
    };

    // Customizable Area End
}
