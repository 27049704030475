// Customizable Area Start
import React from "react";
import InsurerCsvImportController, {
    Props
  } from "./InsurerCsvImportController";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End
import CSVImportDetails from "../../../../components/src/Common/CSVImportDetails";
import { Container } from "@material-ui/core";
import {INSURER_CSV_TABLE_BODY} from "../../../../components/src/Common/Table/TableColumns"





// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {
      fontWeight: 500
    },
    subtitle1: {
      margin: "20px 0px"
    }
  }
});
// Customizable Area End



export default class InsurerCsvImport extends InsurerCsvImportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { 
      isUploadingCsvFile, 
      page, 
      totalPage, 
      alertMessage, 
      showAlert, 
      alertType, 
      insurerCsvData, 
      errorMessage, 
      errorFile, 
      isLoading 
    } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container
          id="insurerCSVImportPage"
          maxWidth="lg"
          dir={
            (window.localStorage.getItem("lang") || "en") === "ar"
              ? "rtl"
              : "ltr"
          }
        >
          {/* @ts-ignore */}
          <CSVImportDetails 
              importFor={"insurer"} 
              isUploadingCsvFile={isUploadingCsvFile}
              page={page}
              totalPage={totalPage} 
              alertMessage={alertMessage} 
              showAlert={showAlert}
              alertType={alertType} 
              csvData={insurerCsvData} 
              errorMessage={errorMessage} 
              errorFile={errorFile} 
              isLoading={isLoading}
              handleAnotherCSVUpload={this.handleInsurerAnotherCSVUpload}
              downloadFile={this.downloadInsurerFile}
              handleSaveCSVUpload={this.handleInsurerSaveCSVUpload}
              handleCSVUploader={this.handleInsurerCSVUploader}
              handleChangePage={this.handleInsurerChangePage}
              handleRowPerPage={this.handleInsurerRowPerPage}
              closeToast={this.closeInsurerToast}
              tableBody={INSURER_CSV_TABLE_BODY}
              downloadSampleCsv={this.downloadSampleCsvForInsurer}
            />
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
