// Customizable Area Start
import React from "react";
import { Container } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import StageTwoController, {Props} from "./StageTwoController";
import { withTranslation } from "react-i18next";
import StageTwoForm from './StageTwoForm';
const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff"
        }
    }
});

// Customizable Area End
export class StageTwo extends StageTwoController {

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container id="customerContainer" maxWidth="lg" dir={(this.state.lang || 'en') === "ar" ? "rtl" : "ltr"} >
                    <StageTwoForm {...this.props} />
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const useStyles = ({});
// Customizable Area End

export default withStyles(useStyles)(withTranslation()(StageTwo));
