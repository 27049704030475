import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    InputAdornment,
    Checkbox
} from "@material-ui/core";
import { ErrorOutlineRounded, ExpandMore } from '@material-ui/icons'
import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import PaymentReviewController, { Props } from "./PaymentReviewController";
import PaymentReviewInsurerForm from "./PaymentReviewInsurerForm"
import PaymentReviewSiibForm from "./PaymentReviewSiibForm"
import PaymentReviewDeductedCommisionForm from "./PaymentReviewDeductedCommissionForm"
import CustomButton from "../../../../../../components/src/Common/CustomButton";
import Toster from "../../../../../../components/src/Common/Toster";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import { ErrorMessage, Formik } from "formik";
import LabelWithIcon from "../../../../../../components/src/Common/LabelWithIcon";
import TextInput from "../../../../../../components/src/Common/TextInput";
import RejectRequestDialog from "../../../../../../components/src/Common/RejectRequestDialog.web"
import HandOverDialog from '../../../../../../components/src/Common/HandOverDialog.web'
import PaymentReviewSchema from '../../../../../../components/src/Common/RevisionRequest/FormSchema/RvRequestStageSevenSchema'
import * as Yup from "yup";
import ActiveLanguage from "../../../../../../components/src/Common/ActiveLanguage";


const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

export class PaymentReviewForm extends PaymentReviewController {
    formikRef: any;

    constructor(props: Props) {
        super(props);
        this.formikRef = React.createRef();
    }

    handleSubmitFromOutside = () => {
        const { current: formikInstance } = this.formikRef;
        if (formikInstance) {
            let installmentAmt = formikInstance.values?.fieldsData?.flowFieldObj?.installment_attributes?.reduce((total: number, currentValue: any) => {
                return total + Number(currentValue.installment_amount);
              }, 0);
              let totalAmount=this.state.flow==7?Number(formikInstance.values.fieldsData.flowFieldObj.refund_amount_to_customer):Number(formikInstance.values?.fieldsData?.paid_amount) + (installmentAmt || 0)
            if (totalAmount !== Number(this.state.quotation_amount)) {
                this.setState({
                showAlert: true,
                alertMessage:ActiveLanguage()?"يجب أن يكون مبلغ التكوين مساوياً لمبلغ الرصيد": "Configure amount should be equal to balance amount",
                alertType: "warning",
            });} 
            else formikInstance.handleSubmit();
        }
    }

    installmentBalanceUI = (installments: any, quotation_amount: any, balance: any) => {
        const { t } = this.props;
        return <>
            <Grid item xs={12} container style={{ display: "flex", justifyContent: "space-between", marginTop: 80 }}>
                <Typography>{t("revision_amount")}</Typography>
                <Typography>SAR {quotation_amount}</Typography>
            </Grid>
            <Grid item xs={12} container style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                <Typography>{t("down_payment")}</Typography>
                <Typography>{`-SAR ${(this.state.isShownDownPaymentFlowTwo || this.state.isShownDownPaymentFlowFive) && balance.paid_amount !== "" ? balance.paid_amount : 0}`}</Typography>

            </Grid>

            {installments && installments.length > 0 && installments.map((item: any, index: number) => {
                return <Grid key="instalment" item xs={12} container style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: 15 }}>
                    <Typography>{this.getNumberWithOrdinal(index + 2)} installment</Typography>
                    <Typography>{`-SAR ${item.isShownInstallmentAmount ? item.installment_amount : 0}`}</Typography>
                </Grid>
            })}
        </>
    }

    renderFlowSixBalance = (t: any, quotation_amount: any, insurer_name: string, siibCommonFields: any, installments: any) => {
        return (
            <>
                <Grid item xs={12} container style={{ display: "flex", justifyContent: "space-between", marginTop: 80 }}>
                    <Typography>{t("revision_amount")}</Typography>
                    <Typography>SAR {quotation_amount}</Typography>
                </Grid>
                <React.Fragment>
                    <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                        <Typography>{t("revenue_siib_commission_from")} {insurer_name} for {t('down_payment')}</Typography>     
                        <Typography> {"-SAR " + (this.state.isShownCommissionAmountFlowSix ? siibCommonFields.commission_amount : 0)}</Typography>
                    </Grid>
                    <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                        <Typography>{t("payment_made_to_insurer")} for {t('down_payment')}</Typography>
                        <Typography> {"-SAR " + (this.state.isShownPayableToInsurerFlowSix ? siibCommonFields.payable_to_insurer : 0)}</Typography>
                    </Grid>
                    {installments && installments.length > 0 && installments.map((item: any, index: number) => <>
                        <Grid key='instalment' item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                            <Typography>{t("revenue_siib_commission_from")} {insurer_name} for {this.getNumberWithOrdinal(index + 2)} instalment</Typography>
                            <Typography>{`-SAR ${item.isShownComissionValue ? item.commission_amount : 0}`}</Typography>
                        </Grid>
                        <Grid key='instalment' item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                            <Typography>{t("payment_made_to_insurer")} for {this.getNumberWithOrdinal(index + 2)} instalment</Typography>
                            <Typography>{`-SAR ${item.isShownInstallmentAmount ? item.payable_to_insurer : 0}`}</Typography>
                        </Grid>
                    </>)}
                </React.Fragment>
            </>
        );
    }


    renderBalanceUI = () => {
        const { t } = this.props;
        const { flow, installments,flowFiveInstallments, quotation_amount, balance, insurer_name, siibCommonFields } = this.state

        if (flow === 2) {
            return this.installmentBalanceUI(installments, quotation_amount, balance)
        }

        if (flow === 5) {
            return this.installmentBalanceUI(flowFiveInstallments, quotation_amount, balance)
        }

        if (flow === 4) {
            return (
                <></>
            );
        }

        if (flow === 6) return this.renderFlowSixBalance(t,quotation_amount, insurer_name, siibCommonFields, installments)
        
        let isShownPayableToInsurer = false;
        if (flow === 1) {
            isShownPayableToInsurer = this.state.isShownPaybleToInsurerFlowFirst || false;
        } else if (flow === 3) {
            isShownPayableToInsurer = this.state.isShownPayableToInsurerFlowThree || false;
        }

            return (
            <>
                <Grid item xs={12} container style={{ display: "flex", justifyContent: "space-between", marginTop: 80 }}>
                    <Typography>{flow === 7 ? "Credited" : "Revision"} amount</Typography>
                    <Typography>SAR {quotation_amount}</Typography>
                </Grid>

                {flow !== 7 && <Grid item xs={12} container style={{ display: "flex", justifyContent: "space-between", marginBottom: 15, marginTop: 20 }}>
                    <Typography>{t("payment_made_to_insurer")}</Typography>
                    <Typography>{`-SAR ${isShownPayableToInsurer && balance.paid_amount !== ""
                            ? balance.paid_amount
                            : 0
                    }`}</Typography>
                </Grid>}
                {flow === 7 && <Grid item xs={12} container style={{ display: "flex", justifyContent: "space-between", marginBottom: 15, marginTop: 20 }}>
                    <Typography>{t("refund_made_to_customer")}</Typography>
                    <Typography>
                     {`-SAR ${
                      (this.state.balance.showRefundAmountValue && this.state.siibCommonFields.refund_amount_to_customer)
                      ? Number(this.state.siibCommonFields.refund_amount_to_customer)
                      : 0
                     }`}
                  </Typography>
                </Grid>}
            </>
        );
    };

    manageFields = (fieldsData: any) => {
        const { t, classes } = this.props;
        if (this.props.rrStageFourData?.data?.attributes?.policy_impact === "down") return (<><Grid style={{ paddingRight: '25px' }} item xs={4}>
            <LabelWithIcon label={t('refund_paid_to_customer')} />
            <FormControlLabel
                color="primary"
                name={`fieldsData['flowFieldObj']['policy_release_requested']`}
                control={<Checkbox color="primary"
                    disabled={!this.props.isExpanded}
                    checked={fieldsData.flowFieldObj?.['policy_release_requested'] === "yes" ? true : false}
                    onChange={(evt: any) => this.siiCommonFieldChangeHandler(evt.target.checked, 'policy_release_requested')}
                />
                }
                data-test-id={`policy_release_requested`}
                label={t('yes')}
            />
            <ErrorMessage
                name="fieldsData['flowFieldObj']['policy_release_requested']"
                className="field-error"
                data-test-id="payment_date_error"
                render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>}
            />
        </Grid>
            <Grid item xs={4}>
                <LabelWithIcon label={t('refund_amount')} />
                <TextInput
                    className={classes?.textInputStyle}
                    name="fieldsData['flowFieldObj']['refund_amount_to_customer']"
                    type="text"
                    data-test-id="refund_amount"
                    disabled={!this.props.isExpanded}
                    onChange={((e: any) => {
                        this.siiCommonFieldChangeHandler(e.target.value, 'refund_amount_to_customer')
                    })}
                    startAdornment={
                        <InputAdornment position="start">
                            <div> &nbsp;SAR&nbsp;</div>
                        </InputAdornment>
                    }
                />
                <ErrorMessage
                    name="fieldsData['flowFieldObj']['refund_amount_to_customer']"
                    className="field-error"
                />
            </Grid>
        </>);
        else return (
            <Grid style={{ paddingRight: '25px' }} item xs={4}>
                <LabelWithIcon label={t('paid_amount')} />
                <TextInput
                    className={classes?.textInputStyle}
                    name="fieldsData['paid_amount']"
                    type="text"
                    data-test-id="paid_amount"
                    disabled={!this.props.isExpanded || (this.props.rrStageSevenData?.data?.attributes?.down_payment && this.props.rrStageSevenData.data.attributes.rejected_reason === "pay installments")}
                    onChange={((e: any) => {
                        this.setState({ paid_amount: e.target.value });
                        this.debounceLog(e.target.value);
                    })}
                    value={fieldsData['paid_amount']}
                    startAdornment={
                        <InputAdornment position="start">
                            <div> &nbsp;SAR&nbsp;</div>
                        </InputAdornment>
                    }
                />
                <ErrorMessage
                    name="fieldsData['paid_amount']"
                    className="field-error"
                />
            </Grid>
        )
    }

    getBalance = () => {
        const { balance, quotation_amount } = this.state;
        return <Typography>{`SAR ${Object.keys(balance).length > 0 && balance.totalBalance !== "" ? balance.totalBalance : quotation_amount}`}</Typography>
    }

    isFieldDisable = () => {
        const stageSevenRecord= this.props.rrStageSevenData?.data?.attributes
        return this.props.rrStageFourData?.data?.attributes?.policy_impact === "down" || (stageSevenRecord?.rejected_reason === "pay installments" && stageSevenRecord.down_payment)
    }

    isCommissionFieldDisable = () => {
        const stageSevenRecord= this.props.rrStageSevenData?.data?.attributes
        return !this.props.isExpanded || (stageSevenRecord?.down_payment && stageSevenRecord.rejected_reason === "pay installments")
    }

    getValidation = (flow: number | null,siibCommonFields: any, paid_amount: string, payment_recipient: string) => {
        return !((flow === 7 ? siibCommonFields.refund_amount_to_customer : paid_amount) && payment_recipient);
    }

    checkPayInstallment = () => {
        return !this.props.rrStageSevenData?.data?.attributes?.save_as_draft && this.props.rrStageSevenData?.data?.attributes?.down_payment && this.props.rrStageSevenData?.data?.attributes?.rejected_reason === "pay installments"
    }

    checkConfigureAmount = () => {
        return this.props.rrStageSevenData?.data?.attributes?.down_payment && this.props.rrStageSevenData?.data?.attributes?.rejected_reason === "pay installments"
    }

    render() {
        const { t, classes } = this.props;
        const { expandedAccordion, flow, quotation_amount, balance, paid_amount, payment_recipient, insurer_name, isShownAddInstallmentBtn, deductedCommissionFields, siibCommonFields } = this.state
        let isNextStepBtnEnable = this.getValidation(flow, siibCommonFields, paid_amount, payment_recipient)

        return (
            <ThemeProvider theme={theme} >
                <React.Fragment>
                    <Toster
                        tosterText={this.state.alertMessage}
                        showToster={this.state.showAlert}
                        tosterType={this.state.alertType}
                        handleCloseToster={this.closeAlertMessage}
                    />
                    <div className={classes?.accordianGap}>
                        <Accordion
                            data-test-id='p7'
                            expanded={expandedAccordion}
                            className={classes?.formSevenAccordian}
                            onChange={() => this.handleAccordian()} >
                            <AccordionSummary
                                aria-expanded={true}
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={classes?.topSection}>
                                    <div className={classes?.displayFlex}>
                                        <Typography className={classes?.accordianHeading} >{t('revision_payment')}</Typography>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        {expandedAccordion && <div className={classes?.descriptionIcon} >
                                            <DescriptionOutlinedIcon onClick={this.openNoteDialog} />
                                        </div>}
                                        {this.checkStageSevenCondition() &&
                                            <ErrorOutlineRounded data-test-id="errorIcon" style={{ position: 'relative', left: '8px', color: '#0090a1' }} onClick={() => this.setState({ showRejectDialog: true, isBtnVisible: false })} />
                                        }
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Formik
                                    innerRef={this.formikRef}
                                    enableReinitialize={true}
                                    initialValues={{ fieldsData: this.initializeFormValue() }}
                                    validationSchema={Yup.object().shape({
                                        fieldsData: Yup.object().shape(PaymentReviewSchema(flow as number, ActiveLanguage()))
                                    })}
                                    validateOnMount={true}
                                    validateOnBlur={true}
                                    onSubmit={(values) => this.formikSubmitHandler(values, "no")}
                                >
                                    {({
                                        values
                                    }) => {
                                        const { fieldsData } = values
                                        return (
                                            <Grid className={classes?.contactForm} >
                                                <h5>{insurer_name}</h5>
                                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {(flow==2||flow==5||flow==6) ||isShownAddInstallmentBtn  ? (<Grid><LabelWithIcon label={t('down_payment')} /></Grid>):null}
                                                    {isShownAddInstallmentBtn === true && (this.props.rrStageSevenData?.data?.attributes?.rejected_reason !== "pay installments") &&
                                                        <Button
                                                            aria-controls="customized-menu"
                                                            data-test-id="installment-button"
                                                            aria-haspopup="true"
                                                            variant="contained"
                                                            color="primary"
                                                            disabled={!this.props.isExpanded}
                                                            onClick={() => this.addInstallment()}
                                                            style={{
                                                                textTransform: 'none',
                                                                backgroundColor: "white",
                                                                color: "#007E98",
                                                                boxShadow: "none",
                                                                fontSize: 17,
                                                                padding: 0,
                                                                fontWeight: 400
                                                            }}
                                                        >
                                                            <AddIcon style={{ width: 15, height: 15 }} /> {t("add_installment")}
                                                        </Button>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} container style={{ marginBottom: 20 }}>
                                                    <Grid style={{ paddingRight: '25px' }} item xs={4}>
                                                        <LabelWithIcon label={t('payment_recipient')} />
                                                        <FormControl className={classes?.radioFormControl} style={{ marginLeft: 15, marginTop: 17 }}>
                                                            <RadioGroup
                                                                row
                                                                name="fieldsData['payment_recipient']"
                                                                data-test-id="payment_recipient"
                                                                value={fieldsData['payment_recipient']}
                                                                onChange={(evt: any) => this.setState({ payment_recipient: evt.target.value }, () => this.updateFlow(""))}
                                                            >
                                                                <FormControlLabel
                                                                    disabled={this.isFieldDisable()}
                                                                    value={"Siib"} control={<Radio color="primary" />} label="SIIB" />
                                                                <FormControlLabel
                                                                    disabled={this.isFieldDisable()}
                                                                    value={"Insurer"} control={<Radio color="primary" />} label={t("insurer")} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    {this.manageFields(fieldsData)}
                                                    {
                                                        /* istanbul ignore next*/(fieldsData.payment_recipient === "Siib" && this.props.rrStageFourData?.data?.attributes?.policy_impact !== "down" &&
                                                            <Grid item xs={4}>
                                                                <LabelWithIcon label={t('deduct_commission')} />
                                                                <FormControl className={classes?.radioFormControl} style={{ marginLeft: 15, marginTop: 17 }}>
                                                                    <RadioGroup
                                                                        row
                                                                        name="fieldsData['deduct_commission']"
                                                                        value={fieldsData['deduct_commission_from_payment']}
                                                                        onChange={(e: any) => this.setState({ deduct_commission_from_payment: e.target.value }, () => this.updateFlow(""))}
                                                                    >
                                                                        <FormControlLabel
                                                                            disabled={this.isCommissionFieldDisable()}
                                                                            value={'yes'} control={<Radio color="primary" />} label={t("yes")} />
                                                                        <FormControlLabel
                                                                            disabled={this.isCommissionFieldDisable()}
                                                                            value={'no'} control={<Radio color="primary" />} label={t("no")} />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                                <ErrorMessage
                                                                    name="fieldsData['deduct_commission']"
                                                                    className="field-error"

                                                                />
                                                            </Grid>)
                                                    }
                                                </Grid>
                                                {
                                                    fieldsData.payment_recipient === "Insurer" && (
                                                       <PaymentReviewInsurerForm
                                                            flowValue={flow}
                                                            formikRef={this.formikRef}
                                                            values={values}
                                                            insurerCommonFieldsChangeHandler={this.insurerCommonFieldsChangeHandler}
                                                            insurerInstallmentChnageHandler={this.insurerInstallmentChnageHandler}
                                                            removeInstallment={this.removeInstallment}
                                                            isExpanded={this.props.isExpanded}
                                                            rrStageFourData={this.props?.rrStageFourData}
                                                            rrStageSevenData={this.props?.rrStageSevenData}
                                                            installmentsData={this.props?.rrStageSevenData?.data?.attributes?.revision_installments?.data}
                                                            isPayInstallment={!this.props?.rrStageSevenData?.data?.attributes?.save_as_draft && this.props?.rrStageSevenData?.data?.attributes?.down_payment && this.props.rrStageSevenData?.data?.attributes?.rejected_reason === "pay installments"}
                                                            isAmountConfigure={this.props?.rrStageSevenData?.data?.attributes?.down_payment && this.props.rrStageSevenData?.data?.attributes?.rejected_reason === "pay installments"}
                                                        />
                                                    )
                                                }


                                                {
                                                   ( flow == 3 || flow === 6 || flow === 7 || flow === 5) &&
                                                    <PaymentReviewSiibForm
                                                        siiCommonFieldChangeHandler={this.siiCommonFieldChangeHandler}
                                                        insurerInstallmentChnageHandler={this.insurerInstallmentChnageHandler}
                                                        values={values}
                                                        isExpanded={this.props.isExpanded}
                                                        flowValue={this.state.flow}
                                                        formikRef={this.formikRef}
                                                        parentPolicyID={this.props.parentPolicyID}
                                                        removeInstallment={this.removeInstallment}
                                                        rrStageOneData={this.props.rrStageOneData}
                                                        isPayInstallment={this.checkPayInstallment()}
                                                        isAmountConfigure={this.checkConfigureAmount()}
                                                        flowFiveInstallmentChangeHandler={this.flowFiveInstallmentChangeHandler}
                                                        paidAmount={this.state.paid_amount}
                                                        deductedCommissionFromPayment={this.state.deduct_commission_from_payment}
                                                        paymentReceipent={this.state.payment_recipient}
                                                        siibCommonFields={this.state.siibCommonFields}
                                                        createReceiptFlowFourStateHandler={this.createReceiptFlowFourStateHandler}
                                                        createInvoiceInstallmentStateHandler={this.createInvoiceInstallmentStateHandler}
                                                        createVoucherInstallmentStateHandler={this.createVoucherInstallmentStateHandler}
                                                        createReceiptInstallmentStateHandler={this.createReceiptInstallmentStateHandler}
                                                        createInvoiceFlowFourStateHandler={this.createInvoiceFlowFourStateHandler}
                                                        updatePaymentVoucherStateforFLowthreeAndFive={this.updatePaymentVoucherStateforFLowthreeAndFive}
                                                        paymentVoucherFlowFiveInstallmentStateHandler={this.paymentVoucherFlowFiveInstallmentStateHandler}
                                                        installmentsData={this.props?.rrStageSevenData?.data?.attributes?.revision_installments?.data}
                                                    />
                                                }
                                                {
                                                    /* istanbul ignore next*/(fieldsData.payment_recipient === "Siib" && fieldsData.deduct_commission_from_payment === "yes" && flow === 4 &&
                                                        <PaymentReviewDeductedCommisionForm
                                                            isExpanded={this.props.isExpanded}
                                                            formikRef={this.formikRef}
                                                            rrStageOneData={this.props.rrStageOneData}
                                                            paidAmount={this.state.paid_amount}
                                                            deductedCommissionFromPayment={this.state.deduct_commission_from_payment}
                                                            paymentReceipent={this.state.payment_recipient}
                                                            flowValue={this.state.flow}
                                                            values={values}
                                                            deductedCommissionFeildsHandlers={this.deductedCommissionFeildsHandlers}
                                                            paymentVoucherFlowFourStateHandler={this.paymentVoucherFlowFourStateHandler}
                                                            createInvoiceFlowFourStateHandler={this.createInvoiceFlowFourStateHandler}
                                                            createReceiptFlowFourStateHandler={this.createReceiptFlowFourStateHandler}
                                                            quotation_amount={quotation_amount}
                                                            balance={balance}
                                                            deductedCommissionFields={deductedCommissionFields}
                                                            updateFlowFourBalance={this.updateFlowFourBalance}
                                                            parentPolicyID={this.props.parentPolicyID}
                                                        />)
                                                }
                                                
                                                {this.renderBalanceUI()}
                                                <Divider />
                                                <Grid item xs={12} container style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 7, paddingTop: 15 }}>
                                                    <Typography>{t("balance")}</Typography>
                                                    {this.getBalance()}
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                    }
                                </Formik>
                            </AccordionDetails>
                        </Accordion>
                        {(this.state.expandedAccordion && this.props.isExpanded) && (
                            <div className={classes?.displayFlexEnd}>
                                <div className={classes?.btnStyle}>
                                    <CustomButton
                                        btnText={t('save_draft')}
                                        onClick={() => this.formikSubmitHandler(this.formikRef.current?.values, "yes")}
                                        bgColor={'#fff'}
                                        textColor={'#007E98'}
                                        disabled={isNextStepBtnEnable}
                                        data-test-id="saveDraftBtnPaymentReview"
                                    />
                                </div>
                                <div className={classes?.btnStyle}>
                                    <CustomButton
                                        btnText={t('reject')}
                                        onClick={() => this.openRejectDialogStageSeven()}
                                        bgColor={'#fff'}
                                        textColor={'#007E98'}
                                        data-test-id="reject_btn_payment_review"
                                    />
                                </div>
                                <div>
                                    <CustomButton
                                        btnText={t('next_stage')}
                                        onClick={() => this.handleSubmitFromOutside()}
                                        bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                        textColor={'#fff'}
                                        disabled={isNextStepBtnEnable}
                                        data-test-id="nextBtnPaymentReview"
                                    />
                                </div>

                            </div>
                        )}
                    </div>

                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNoteDialog}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={`(${t('payment_review')} > ${t('invoicing')})`}
                        handleInputText={this.handleHandOverNoteTextStageSeven}
                        inputValue={this.props.handOverNoteForPaymentReview}
                        inputDisable={!this.props.isExpanded}
                        handleOk={() => this.closeNoteDialogStageThree}
                        handleCancel={this.closeNoteDialogStageThree}
                        showDialogBtns={false}
                        showCrossBtn={true}
                    />


                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNextDialog}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={`(${t('payment_review')} > ${t('invoicing')})`}
                        handleInputText={this.handleHandOverNoteTextStageSeven}
                        inputValue={this.state.handOverNoteText}
                        inputDisable={!this.props.isExpanded}
                        handleOk={() => this.trackRequestStageSeven("next")}
                        handleCancel={this.closeNoteDialogStageSeven}
                        showDialogBtns={true}
                        showCrossBtn={true}
                    />


                    <RejectRequestDialog
                        data-test-id="rejectDialog"
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showRejectDialog}
                        textId={'rejectReason'}
                        pushBackOptions={this.getRvRequestRejectOptions()}
                        inputValue={this.state.isBtnVisible ? this.state.rejectReasonText : this.props.rrStageSevenData?.data?.attributes?.rejected_reason}
                        setSelectedPushBackTo={this.moveToSelectedStepStageSeven}
                        stepValue={this.state.moveToStep}
                        headingText={t('rejecting')}
                        handleInputText={this.handleRejectReasonText}
                        handleOk={() => this.trackRequestStageSeven("reject")}
                        handleCancel={this.closeRejectDialog}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                        labelText={t('push_back')}
                        reasonLabel={t('reason')}
                    />
                </React.Fragment>
            </ThemeProvider >
        )
    }
}

const useStyles = ({
    accordianGap: {
        padding: "10px 0"
    },
    contactForm: { width: "100%" },
    btnStyle: {
        margin: '0 10px'
    },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    displayFlexEnd: { display: "flex", justifyContent: "end", margin: '16px 0' },
    errorSection: {
        border: "1px solid red"
    },
    formSevenAccordian: {
        "border-radius": "10px !important",
    },
    accordianHeading: {
        fontWeight: 600
    },
    displayFlex: {
        display: "flex",
    },
    topSection: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    descriptionIcon: {
        color: '#007E98'
    }
});

export default withStyles(useStyles)(withTranslation()(PaymentReviewForm));