import React, { useRef } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
    Typography,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Pagination } from "@material-ui/lab";

const SalesTable = (props: any) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { totalPage, currentPage, pageChange, tableData, salesLoading } = props;
    const paginationButtonRef = useRef();

    return (
        <div data-test-id="salesTable">
            <TableContainer className={classes.tabelContainer}>
                <Table>
                    <TableHead>
                        <TableRow id="salesTableHead" className={classes.salesTableHead}>
                            <TableCell className={classes.tabelHeading}>{t('sales_person')}</TableCell>
                            <TableCell className={classes.tabelHeading}>{t('time_period')}</TableCell>
                            <TableCell className={classes.tabelHeading}>{t('paid_amount_ex_vat')}</TableCell>
                            <TableCell className={classes.tabelHeading}>{t('siib_commision')}</TableCell>
                            <TableCell className={classes.tabelHeading}>{t('sales_commision')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {salesLoading &&
                            <TableRow>
                                <TableCell colSpan={11}>
                                    <Typography align="center" className={classes.tableLoading}>
                                        <CircularProgress size={50} />
                                    </Typography>
                                    <Typography align="center">
                                        {t('fetching_the_data_please_wait')}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        }
                        {!salesLoading && tableData.length > 0 ? tableData.map((salesPerson: any, rowIndex: number) => (
                            <React.Fragment key={rowIndex}>
                                {salesPerson.sales_commissions.map((salesData: any, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{index === 0 && salesPerson.sales_person}</TableCell>
                                        <TableCell>{salesData.time_period}</TableCell>
                                        <TableCell>SAR {salesData.revenue}</TableCell>
                                        <TableCell>SAR {salesData.siib_commission}</TableCell>
                                        <TableCell>SAR {salesData.sales_commission}</TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        )):
                        (
                            <TableRow>
                            <TableCell colSpan={11}>
                                <Typography align="center">
                                    {!salesLoading && t('no_records_found')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        )
                        }

                    </TableBody>
                </Table>
                <div dir="ltr" id="salesPagination" className={classes.paginationSection}>
                    <div style={{ width: '100px' }} />
                    <Pagination
                        shape="rounded"
                        className={classes.paginationRoot}
                        count={totalPage}
                        page={currentPage}
                        onChange={pageChange}
                        showFirstButton
                        showLastButton
                        ref={paginationButtonRef}
                    />
                </div>
            </TableContainer>
        </div>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        primaryBtnStyle: {
            background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
            textTransform: "none",
            color: "white",
            height: "50px",
            width: "150px",
            borderRadius: "10px"
        },
        salesTableHead: {
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        tabelContainer: {
            padding: 0,
            border: "1px solid grey",
            borderRadius: "7px",
        },
        tabelHeading: {
            fontSize: "14px",
            fontWeight: 700,
            padding: "13px 15px"
        },
        stickyCell: {
            position: "sticky",
            zIndex: 1
        },
        policyIdCell: {
            position: "sticky",
            left: 0,
            zIndex: 3,
            width: "120px",
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        policyIdCellBody: {
            position: "sticky",
            left: 0,
            zIndex: 3,
            width: "120px",
            background: "white",
        },
        dateCell: {
            position: "sticky",
            left: "7%",
            zIndex: 2,
            width: "20%",
            backgroundColor: "rgba(233, 244, 249, 1)"
        },
        dateCellBody: {
            position: "sticky",
            left: "7%",
            zIndex: 2,
            width: "20%",
            background: "white",
        },
        salesIdCell: {
            position: "sticky",
            left: "27%",
            zIndex: 3,
            width: "calc(20% - 1px)",
            backgroundColor: "rgba(233, 244, 249, 1)",
            overflow: "hidden",
            "&:after": {
                content: '""',
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "5px",
                background: "linear-gradient(to right, #ccc, rgba(204, 204, 204, 0))"
            },
        },
        salesIdCellBody: {
            position: "sticky",
            left: "27%",
            zIndex: 3,
            width: "calc(20% - 1px)",
            background: "white",
            overflow: "hidden",
            "&:after": {
                content: '""',
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                width: "5px",
                background: "linear-gradient(to right, #ccc, rgba(204, 204, 204, 0))"
            },
        },
        paginationSection: {
            position: "sticky",
            top: 0,
            display: "flex",
            justifyContent: "center",
            padding: "20px",
            zIndex: 2,
            marginLeft: "-1px",
        },
        paginationRoot: {
            "& .MuiPaginationItem-root": {
                borderRadius: "50%",
                width: "32px",
                height: "32px",
                backgroundColor: "#ccc",
                margin: "0 4px",
                color: "#333",
            },
            "& .Mui-selected": {
                backgroundColor: "rgba(233, 244, 249, 1)",
            },
        },
        rowPerPageConatiner: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        tableLoading:{
            padding:"50px"
        }
    })
);

export default SalesTable;
