import { makeStyles } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import React from "react";
import Select, { StylesConfig } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";


const useStyles = makeStyles({

    selectError: {
        '& > div': {
            // height: '56px',
            borderColor: 'red',
            backgroundColor: '#fff',
        }
    },
    selectRemoveicon: {
        '& [role="button"]': {
            display: 'none'
        }
    }
})
const colourStyles: StylesConfig = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', marginTop: "10px", marginBottom: "10px", height: '55px' }),
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: "#cbdeec",
        };
    },
};

type MyOption = {
    label: string;
    value: any;
};

type GroupedOption = {
    label: string; // group label
    options: MyOption[];
};

type Props = {
    name: string;
} & Omit<
    StateManagerProps<MyOption, false | true, GroupedOption>,
    "value" | "onChange"
>;

const MultiSelectFormik = (props: any) => {
    const { name, ...restProps } = props;
    const [field] = useField(name);
    const { setFieldValue } = useFormikContext();

    //flatten the options so that it will be easier to find the value
    const flattenedOptions = props?.options?.flatMap((o: any) => {
        const isNotGrouped = "value" in o;
        if (isNotGrouped) {
            return o;
        } else {
            return o.options;
        }
    });

    //get the value using flattenedOptions and field.value
    const value = flattenedOptions?.filter((o: any) => {
        const isArrayValue = Array.isArray(field.value);

        if (isArrayValue) {
            const values = field.value as Array<any>;
            return values.includes(o.value);
        } else {
            return field.value === o.value;
        }
    });

    const classes = useStyles();



    return (
        <div>
            <Select
                styles={colourStyles}
                {...restProps}
                options={props.options}
                isDisabled={props.disabled || false}
                className={`${props.showError ? classes.selectError : ''} ${props.disable ? classes.selectRemoveicon : ''}`}
                {...props}
                {...field}
                onChange={(newValue:any) => {
                    if (props.setOtherInput) {
                        props.setOtherInput(newValue.allData)
                    }
                    setFieldValue(name, newValue)
                }}
                value={field.value}
            />
        </div>
    )
}

export default MultiSelectFormik