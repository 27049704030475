// Customizable Area Start
import React from "react";
import { Container } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CustomerProfileController, { Props } from "./CustomerProfileController";
import CustomerDetails from "../../../../components/src/Customer/CustomerDetails";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  }
});

export default class CustomerProfile extends CustomerProfileController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    return (
      <ThemeProvider theme={theme} >
        <Container id="customerContainer" maxWidth="lg" dir={(window.localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"} >
          {/* @ts-ignore */}
          <CustomerDetails {...this.props} />
        </Container>
      </ThemeProvider>
    );
  }
}
// Customizable Area End