import React from "react";
import {
  Container,
} from "@material-ui/core";
import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import AnalyticsListingController from "./AnalyticsListingController";
import TablesTab from "../../../components/src/Common/transactionTables/TablesTab";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff",
    },
  }
});

const webStyle = {
  analyticsTabs: {
    fontWeight: 500,
    "text-transform": "none"
  },
  activeTab: {
    fontWeight: 600,
    "text-transform": "none",
  }
};
export class AnalyticsListing extends AnalyticsListingController {

  render() {

    return (
      <div data-test-id="AnalyticsListing">
        <ThemeProvider theme={theme} >
          <Container maxWidth="xl">
              <TablesTab
                data-test-id="table_tabs"
                {...this.props}
                disableFileCheck={this.getDateDisableState}
                IPageChange={this.handleInsurerPageChange}
                IRowPerPageChange={this.handleInsurerRowPerPage}
                IBpageChange={this.handleInsurerBalancePageChange}
                filterData={this.filterChange}
                clearFilterData={this.clearFilter}
                applyFilter={this.applyFilter}
                filterDialog={this.handleFilterDialog}
                insurerSelect={this.handlerInsurerSelector}
                clearInsuranceBalanceFilter={this.clearInsuranceBalanceFilter}
                filterInsurerBalance={this.filterInsurerBalance}
              />
          </Container>
        </ThemeProvider>
      </div >
    )
  }
}

const useStyles = ({});

export default withStyles(useStyles)(withTranslation()(AnalyticsListing));