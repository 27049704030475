import * as Yup from 'yup';

const StageOnePartTwoFormSchema = (isArabicLanguage: boolean) => {
    const schema = {
        industry: Yup.string().notRequired(),
        insuranceType: Yup.string().required( isArabicLanguage ? "نوع التأمين مطلوب" :"Insurance Type required."),
        existingInsurance: Yup.string().required(isArabicLanguage ? "الرجاء تحديد المدخل الموجود نعم أو لا" :'Please select existing insrance yes or no.'),
        nameOfExistingInsurer: Yup.string().matches(/^(?!\s)(?!.*\s$)(?!.*\s{2,}).*$/, isArabicLanguage ? 'التنسيق غير صالح. لا يُسمح بمسافات بادئة أو لاحقة أو متعددة.' : 'Invalid format. No leading, trailing, or multiple spaces allowed.').when('existingInsurance', {
            is: "yes",
            then: Yup.string().required(isArabicLanguage ? "الرجاء إدخال اسم شركة التأمين الحالية" : "Please enter name of existing insurer"),
            otherwise: Yup.string().notRequired()
        }),
        policyExpireDate: Yup.string().when('existingInsurance', {
            is: "yes",
            then: Yup.string().required(isArabicLanguage ? "يرجى تحديد تاريخ انتهاء الوثيقة" : "Please select policy expiry date"),
            otherwise: Yup.string().notRequired()
        }),
        tradeLicenseNumber: Yup.number().typeError(isArabicLanguage ? 'رقم الرخصة التجارية يجب أن يكون الحد الأدنى 1' : 'Trade License number should be minimm 1').min(1,"Trade License number should be minimm 1").max(999999999999999,isArabicLanguage ? "الحد الأقصى المسموح به هو 15 رقمًا في رقم الرخصة التجارية" : "Maximum 15 Digits allowed in trade license number.").required(isArabicLanguage ? "الرجاء إدخال رقم الرخصة التجارية" :"Please enter trade license number"),
        tradeLicenseFile: Yup.string().required(isArabicLanguage ? "الرجاء تحميل مستند الرخصة التجارية" :'Please upload trade license document'),
        brokerOfRecord: Yup.string().required(isArabicLanguage ? "يرجى تحميل وسيط أو وثيقة تسجيل" :'Please upload broker of record document'),
            
        
        LrClaimDoc:Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة مطالبة LR" :'Please upload LR claim document'),
        nationalAddress: Yup.string().matches(/^(?!\s)(?!.*\s$)(?!.*\s{2,}).*$/, isArabicLanguage ? 'التنسيق غير صالح. لا يُسمح بمسافات بادئة أو لاحقة أو متعددة.' : 'Invalid format. No leading, trailing, or multiple spaces allowed.').required(isArabicLanguage ? "العنوان الوطني مطلوب" : "National address is required."),
        proposalForm:Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة نموذج الاقتراح" : 'Please upload proposal form document'),
        insuranceCompany:  Yup.array().max(5, isArabicLanguage ? "يمكنك اختيار 5 شركات كحد أقصى" :'You can select a maximum of 5 companies.').required(isArabicLanguage ? "الرجاء تحديد شركة واحدة على الأقل" :'Please select at least one company.'),
        clientComment: Yup.string().notRequired(),
        motor: Yup.object().when('insuranceType', {
            is: 'motor',
            then: Yup.object().shape({
                numberOfVehiclesToInsure: Yup.number().typeError(isArabicLanguage ? "يجب ألا يقل عدد المركبات المراد التأمين عليها 1" : "Number of vehicles to insure should be minimum 1.").min(1, isArabicLanguage ? "يجب ألا يقل عدد المركبات المراد التأمين عليها 1" : "Number of vehicles to insure should be minimum 1.").max(150, "Number of vehicles to insure should be maximum 150.").required( isArabicLanguage ? "عدد المركبات لضمان الإدخال مطلوب في المحرك" : "Number of vehicles to insure input is required in motor"),
                policyType: Yup.string().required(isArabicLanguage ? "نوع السياسة مطلوب في المحرك" : "Policy type is required in motor"),
            }),
            otherwise: Yup.object().notRequired()
        }),
        medical: Yup.object().when('insuranceType', {
            is: 'medical',
            then: Yup.object().shape({
                numberOfEmployeeOfInsurer: Yup.number().typeError(isArabicLanguage ? "يجب ألا يقل عدد الموظفين لدى شركة التأمين عن 1" : "Number of employees to insurer should be minimum 1.").min(1, isArabicLanguage ? "يجب ألا يقل عدد الموظفين لدى شركة التأمين عن 1" : "Number of employees to insurer should be minimum 1.").max(150, isArabicLanguage ? "يجب أن يكون عدد الموظفين لدى شركة التأمين 150 كحد أقصى" : "Number of employees to insurer should be maximum 150.").required( isArabicLanguage ? "مطلوب إدخال عدد من الموظف إلى شركة التأمين في المجال الطبي" : "Number of employees to insurer input is required in medical"),
                prefferedHospital: Yup.string().matches(/^(?!\s)(?!.*\s$)(?!.*\s{2,}).*$/, isArabicLanguage ? 'التنسيق غير صالح. لا يُسمح بمسافات بادئة أو لاحقة أو متعددة.' : 'Invalid format. No leading, trailing, or multiple spaces allowed.').nullable().required(isArabicLanguage ? "الرجاء تحديد المستشفى المفضل في الطب" : "Please select preferred hospital in medical."),
                medicalSegment: Yup.string().nullable().required(isArabicLanguage ? "التأمين الطبي مطلوب" : "Medical segment type is required."),
            }),
            otherwise: Yup.object().notRequired()
        }),
        life: Yup.object().when('insuranceType',{
            is:'life',
            then: Yup.object().shape({
                numberOfEmployeeOfInsurer: Yup.number().typeError(isArabicLanguage ? "يجب ألا يقل عدد الموظفين لدى شركة التأمين عن 1" : "Number of employees to insurer should be minimum 1.").nullable(false).min(1,isArabicLanguage ? "يجب ألا يقل عدد الموظفين لدى شركة التأمين عن 1" : "Number of employees to insurer should be minimum 1.").max(150,isArabicLanguage ? "يجب أن يكون عدد الموظفين لدى شركة التأمين 150 كحد أقصى" : "Number of employees to insurer should be maximum 150.").required(isArabicLanguage ? "مطلوب إدخال عدد من الموظف إلى شركة التأمين في الحياة" :"Number of employees to insurer input is required in life"),
            }),
            otherwise: Yup.object().notRequired()
        }),
        fire: Yup.object().when('insuranceType',{
            is:'fire',
            then: Yup.object().shape({
                numberOfEmployeeOfInsurer: Yup.number().typeError(isArabicLanguage ? "يجب ألا يقل عدد الموظفين لدى شركة التأمين عن 1" : "Number of employees to insurer should be minimum 1.").min(1,isArabicLanguage ? "يجب ألا يقل عدد الموظفين لدى شركة التأمين عن 1" : "Number of employees to insurer should be minimum 1.").max(150,isArabicLanguage ? "يجب أن يكون عدد الموظفين لدى شركة التأمين 150 كحد أقصى" : "Number of employees to insurer should be maximum 150.").required( isArabicLanguage ? "مطلوب إدخال عدد من الموظف إلى شركة التأمين في الحريق" : "Number of employees to insurer input is required in fire"),
                gpsCordinate: Yup.string().nullable().required(isArabicLanguage ? "مطلوب إدخال GPS Cordinate في الحريق":"GPS Cordinate input is required in fire."),
                letterFormCivilDefenceDoc: Yup.string().required(isArabicLanguage ? "برجاء تحميل كتاب من مستند الدفاع المدني في حالة حريق" : 'Please upload Letter from civil defence document in fire'),
                muncipalCertificateDoc:Yup.string().required(isArabicLanguage ? "الرجاء تحميل وثيقة شهادة البلدية في النار" :'Please upload muncipal certificate document in fire'),
                surveyReportDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في النار" : 'Please upload survey report document in fire'),
                fireAndSaftyContractDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة عقد النار والسلامة في الحريق" :'Please upload Fire and safty contract document in fire'),
                samplePicture: Yup.string().required(isArabicLanguage ? "الرجاء تحميل نموذج من وثيقة الصورة في النار" :'Please upload Sample picture document in fire'),
            }),
            otherwise: Yup.object().notRequired()
        }),
        property: Yup.object().when('insuranceType',{
            is:'property',
            then: Yup.object().shape({
                numberOfEmployeeOfInsurer: Yup.number().typeError(isArabicLanguage ? "يجب ألا يقل عدد الموظفين لدى شركة التأمين عن 1" : "Number of employees to insurer should be minimum 1.").min(1,isArabicLanguage ? "يجب ألا يقل عدد الموظفين لدى شركة التأمين عن 1" : "Number of employees to insurer should be minimum 1.").max(150,isArabicLanguage ? "يجب أن يكون عدد الموظفين لدى شركة التأمين 150 كحد أقصى" :"Number of employees to insurer should be maximum 150.").required(isArabicLanguage ? "مطلوب إدخال عدد من الموظف إلى شركة التأمين في الممتلكات" : "Number of employees to insurer input is required in property"),
                letterFormCivilDefenceDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل كتاب من مستند الدفاع المدني في العقار" :'Please upload Letter from civil defence document in property'),
            }),
            otherwise: Yup.object().notRequired()
        }),
        engineering: Yup.object().when('insuranceType',{
            is:'engineering',
            then: Yup.object().shape({
                numberOfEmployeeOfInsurer: Yup.number().typeError(isArabicLanguage ? "يجب ألا يقل عدد الموظفين لدى شركة التأمين عن 1" : "Number of employees to insurer should be minimum 1.").min(1,isArabicLanguage ? "يجب ألا يقل عدد الموظفين لدى شركة التأمين عن 1" : "Number of employees to insurer should be minimum 1.").max(150,isArabicLanguage ? "Number of employees to insurer should be maximum 150" :"Number of employees to insurer should be maximum 150.").required(isArabicLanguage ? "عدد الموظفين لإدخال شركة التأمين مطلوب في الهندسة" :"Number of employees to insurer input is required in engineering"),
                gpsCordinate: Yup.string().nullable().required(isArabicLanguage ? "مطلوب إدخال الحبل GPS في الهندسة" : "GPS cordinate input is required in engineering."),
                awardContractDoc:Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة عقد المنح في الهندسة" :'Please upload Award contract document in engineering'),
                billOfQualityDoc:  Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة شهادة الجودة في الهندسة" :'Please upload Bill of quality document in engineering'),
                timeBarChatDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة مخطط شريط الوقت في الهندسة" : 'Please upload Time bar chart document in engineering'),
            }),
            otherwise: Yup.object().notRequired()
        }),
        marine: Yup.object().when('insuranceType',{
            is:'marine',
            then: Yup.object().shape({
                numberOfEmployeeOfInsurer: Yup.number().typeError(isArabicLanguage ? "يجب ألا يقل عدد الموظفين لدى شركة التأمين عن 1" : "Number of employees to insurer should be minimum 1.")
                .min(1, isArabicLanguage ? "يجب ألا يقل عدد الموظفين لدى شركة التأمين عن 1" : "Number of employees to insurer should be minimum 1.").max(150, isArabicLanguage ? "يجب أن يكون عدد الموظفين لدى شركة التأمين 150 كحد أقصى" : "Number of employees to insurer should be maximum 150.").required(isArabicLanguage ? "عدد الموظفين لإدخال شركة التأمين مطلوب في البحرية" :"Number of employees to insurer input is required in marine"),
            }),
            otherwise: Yup.object().notRequired()
        }),
        protectionAndSaving: Yup.object().when('insuranceType',{
            is:'protection & savings',
            then: Yup.object().shape({
                insuranceFileDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة ملف التأمين في الحماية والادخار" : 'Please upload Insurance file document in protection & savings'),
                gpsCordinate: Yup.string().nullable().required(isArabicLanguage ? "مطلوب إدخال حبال GPS للحماية والادخار" :"GPS cordinate input is required in protection & savings."),
                letterFormCivilDefenceDoc: Yup.string().required(isArabicLanguage ? "برجاء تحميل خطاب من مستند الدفاع المدني في الحماية والادخار" : 'Please upload Letter from civil defence document in protection & savings'),
                muncipalCertificateDoc:Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة شهادة البلدية في الحماية والادخار" : 'Please upload muncipal certificate document in protection & savings'),
                surveyReportDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة حماية تقرير الاستبيان والادخار" : 'Please upload survey report document protection & savings'),
                fireAndSaftyContractDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل ملف الحماية والادخار لعقد الأمان والحريق" :  'Please upload Fire and safty contract document protection & savings'),
                awardContractDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل حماية وثيقة عقد المكافأة والادخار" : 'Please upload Award contract document protection & savings'),
                billOfQualityDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة بوليصة الجودة في الحماية والادخار" :'Please upload Bill of quality document in protection & savings'),
                timeBarChatDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة مخطط شريط الوقت في الحماية والادخار" :  'Please upload Time bar chart document in protection & savings'),
                maintenanceHistoryRecordDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل مستند سجل الصيانة في الحماية والادخار" : 'Please upload maintence history record document in protection & savings'),
                samplePicture: Yup.string().required(isArabicLanguage ? "يرجى تحميل نموذج من وثيقة الصورة في الحماية والادخار" : 'Please upload Sample picture document in protection & savings'),
            }),
            otherwise: Yup.object().notRequired()
        }),
        generalBusiness: Yup.object().when('insuranceType',{
            is:'general business',
            then: Yup.object().shape({
                insuranceFileDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة ملف التأمين في الأعمال العامة" : 'Please upload Insurance file document in general business'),
                gpsCordinate: Yup.string().nullable().required(isArabicLanguage? "مطلوب إدخال الحبل GPS في الأعمال العامة" :"GPS cordinate input is required in general business."),
                letterFormCivilDefenceDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل خطاب من مستند الدفاع المدني في الأعمال العامة" : 'Please upload Letter from civil defence document in general business'),
                muncipalCertificateDoc:Yup.string().required(isArabicLanguage ?  'يرجى تحميل وثيقة شهادة البلدية في الأعمال العامة' : 'Please upload muncipal certificate document in general business'),
                surveyReportDoc: Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة تقرير المسح الأعمال العامة' : 'Please upload survey report document general business'),
                fireAndSaftyContractDoc: Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة عقد الحريق والسلامة الأعمال العامة' : 'Please upload Fire and safty contract document general business'),
                awardContractDoc: Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة عقد الجائزة الأعمال العامة' : 'Please upload Award contract document general business'),
                billOfQualityDoc:  Yup.string().required(isArabicLanguage ? "يرجى تحميل مستند فاتورة الجودة في الأعمال العامة" : 'Please upload Bill of quality document in general business'),
                timeBarChatDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل مستند الرسم البياني الشريطي الزمني في الأعمال العامة" : 'Please upload Time bar chart document in general business'),
                maintenanceHistoryRecordDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل مستند سجل تاريخ الصيانة في الأعمال العامة" : 'Please upload Maintence history record document in general business'),
                samplePicture: Yup.string().required(isArabicLanguage ? "يرجى تحميل نموذج مستند مصور في الأعمال العامة" : 'Please upload Sample picture document in general business'),
            }),
            otherwise: Yup.object().notRequired()
        }),
        aviation: Yup.object().when('insuranceType',{
            is:'aviation',
            then: Yup.object().shape({
                insuranceFileDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة ملف التأمين في الطيران" : 'Please upload Insurance file document in aviation'),
                gpsCordinate: Yup.string().nullable().required(isArabicLanguage ? "يلزم إدخال إحداثيات نظام تحديد المواقع العالمي (GPS) في مجال الطيران" : "GPS Cordinate input is required in aviation."),
                letterFormCivilDefenceDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل رسالة من وثيقة الدفاع المدني في الطيران" : 'Please upload Letter from civil defence document in aviation'),
                muncipalCertificateDoc:Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة الشهادة البلدية في الطيران' : 'Please upload muncipal certificate document in aviation'),
                surveyReportDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة تقرير المسح في مجال الطيران" : 'Please upload survey report document in aviation'),
                fireAndSaftyContractDoc: Yup.string().required(isArabicLanguage ? "يرجى تحميل وثيقة عقد الإطفاء والسلامة في الطيران" : 'Please upload Fire and safty contract document in aviation'),
                awardContractDoc: Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة عقد الجائزة في مجال الطيران' : 'Please upload Award contract document in aviation'),
                billOfQualityDoc:  Yup.string().required(isArabicLanguage ? 'يرجى تحميل مستند فاتورة الجودة في مجال الطيران' : 'Please upload Bill of quality document in aviation'),
                timeBarChatDoc: Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة الرسم البياني الشريطي الزمني في مجال الطيران' : 'Please upload Time bar chart document in aviation'),
                maintenanceHistoryRecordDoc: Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة سجل تاريخ الصيانة في الطيران' : 'Please upload Maintence history record document in aviation'),
                samplePicture: Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة صورة نموذجية في مجال الطيران' : 'Please upload Sample picture document in aviation'),
            }),
            otherwise: Yup.object().notRequired()
        }),
        energy:  Yup.object().when('insuranceType',{
            is:'energy',
            then: Yup.object().shape({
                insuranceFileDoc: Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة ملف التأمين في الطاقة' : 'Please upload Insurance file document in energy'),
                gpsCordinate: Yup.string().nullable().required(isArabicLanguage ? "مطلوب إدخال إحداثيات GPS في الطاقة" : "GPS Cordinate input is required in energy."),
                letterFormCivilDefenceDoc: Yup.string().required(isArabicLanguage ? 'يرجى تحميل رسالة من وثيقة الدفاع المدني في الطاقة' : 'Please upload Letter from civil defence document in energy'),
                muncipalCertificateDoc:Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة الشهادة البلدية في الطاقة' : 'Please upload muncipal certificate document in energy'),
                surveyReportDoc: Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة تقرير المسح في الطاقة' : 'Please upload survey report document in energy'),
                fireAndSaftyContractDoc: Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة عقد الحريق والسلامة في الطاقة' : 'Please upload Fire and safty contract document in energy'),
                awardContractDoc: Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة عقد الجائزة في الطاقة' : 'Please upload Award contract document in energy'),
                billOfQualityDoc:  Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة فاتورة الجودة في الطاقة' : 'Please upload Bill of quality document in energy'),
                timeBarChatDoc: Yup.string().required(isArabicLanguage ? 'يرجى تحميل وثيقة الرسم البياني الشريطي الزمني في الطاقة' : 'Please upload Time bar chart document in energy'),
                maintenanceHistoryRecordDoc: Yup.string().required(isArabicLanguage  ? 'يرجى تحميل مستند سجل تاريخ الصيانة في الطاقة' : 'Please upload Maintence history record document in energy'),
                samplePicture: Yup.string().required(isArabicLanguage ? 'يرجى تحميل نموذج مستند مصور في مجال الطاقة' : 'Please upload Sample picture document in energy'),
            }),
            otherwise: Yup.object().notRequired()
        }),
    }
    return schema;
}

export default StageOnePartTwoFormSchema;