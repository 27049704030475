  // Customizable Area Start
  import { IBlock } from "../../../../framework/src/IBlock";
  import { Message } from "../../../../framework/src/Message";
  import { BlockComponent } from "../../../../framework/src/BlockComponent";
  import MessageEnum, {
      getName,
  } from "../../../../framework/src/Messages/MessageEnum";
  import { runEngine } from "../../../../framework/src/RunEngine";
  import * as Yup from 'yup';


  export const configJSON = require("../config");

  export interface Props {      
      navigation?: any;
      isExpanded?: boolean
      isEdit?: boolean;
      onNext?:any;
      onReject?:any;
      onDraft?:any;
      t?:any;
      classes?:any;
      insurer?: any
      handleSetField?: any
      initialValues?: any;
      touched?: any;
      errors?:any;
      setFieldValue?: any;
      insurerIndex?: number;
      emailSent?: any;
      emailDraft?: any;
      values?:any;
      formikRefInst?:any
      setStageData?:any

  }

  interface S {
      customerName: string;
      policyId: string;
      isArabicLenguage: boolean;
      expandedAccordion: boolean;
      stageData?: any;
      errorSection?: string;
      contectOption?:any[];
      handOverNoteText?: string;
      rejectReasonText?: string;
      showNoteDialog?: boolean;
      showRejectDialog?: boolean;
      moveToStep?: string
      emailTemplate?: string
      insuranceData?: any,
      stage2submitted: boolean;
      showDialog:boolean;
      showAlert:boolean;
      alertMessage:string;
      alertType:string;
      isDraftAPIUsed?: boolean;
      disableAccordian: boolean;
      uploadedFileList?: any
      expandProcessingAccordian?: boolean
      hasViewPermissionList?: string[],
      hasUpdatePermissionList?: string[],
      loggedInRole: string,
      processingLoading: boolean,
      isViewOnly: boolean,
      fileUploadErrorIndexList: number[],
      fileLoading?:boolean;
      isBtnVisible?: boolean;
      openCSVDialog:boolean;
      selectedtemplateOption:string;
      columnList:any[],
      selectedTemplateID:any;
      templateList:any[];

  }

  interface SS {
      id: any;
  }

  export default class StageTwoController extends BlockComponent<
      Props,
      S,
      SS
  > {
      getProcessingDataAPI: string = ''
      stageOneAPIResponse: string = ''
      modifyProcessingDataAPI: string = ''
      setProcessingDataAPI: string = '';
      getPolicyDetailsAPI:string=""
      trackRequestAPI: string = ''
      insurerList: any = []
      insuranceType: string = ''
      policyId: number| null = null
      stageInfo: any = ''
      uploadFilesAPI: string = ''
      customerName: string = ''
      removeFileAPI: string = ''
      customerDetialsId:string=""
      handoverNoteApiStageTwo: string = ''
      saveDataAsDraftAPI: string = ''
      formikRef: any = null
      idToRemove:number= 0
      insurerIndex: number = -1;
      selectedTemplateID:any;
      getAllTemplateListApi:string=""
      


    constructor(props: Props) {
          super(props);
          this.receive = this.receive.bind(this);
          this.openNoteDialog = this.openNoteDialog.bind(this)
          this.closeNoteDialog = this.closeNoteDialog.bind(this)
          this.handleRejectReasonText = this.handleRejectReasonText.bind(this)
          this.closeRejectDialog = this.closeRejectDialog.bind(this)

          this.subScribedMessages = [
              getName(MessageEnum.CountryCodeMessage),
              getName(MessageEnum.RestAPIResponceMessage),
              getName(MessageEnum.ReciveUserCredentials),
          ];

          const queryParameter: any = new URLSearchParams(window.location.search);
          const policyId: string = queryParameter?.get('id');
          
            this.state = {
              customerName: "",
              policyId: policyId || "",
              isArabicLenguage: (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
              expandedAccordion: false,
              stageData: [],
              errorSection: "",
              handOverNoteText: '',
              rejectReasonText: '',
              showNoteDialog: false,
              showRejectDialog: false,
              moveToStep: '',
              emailTemplate: '',
              stage2submitted: false,
              showDialog:false,
              showAlert:false,
              alertMessage:"",
              alertType:"",
              isDraftAPIUsed: false,
              uploadedFileList: [],
              expandProcessingAccordian: false,
              hasViewPermissionList : [],
              hasUpdatePermissionList: [],
              loggedInRole : '',
              processingLoading: false,
              isViewOnly: false,
              fileUploadErrorIndexList: [],
              disableAccordian: false,
              fileLoading:false,
              isBtnVisible: true,
              openCSVDialog:false,
              selectedtemplateOption:"Create a new template",
              columnList:[],
              templateList:[],
              selectedTemplateID:"",
          };
          runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      }

      setInitialDataValues = (data: any,item: any) => {
            return ({
              contact: item?.insurance_contacts,
              id: item.id,
              sectionId: data?.id ? data?.id : '',
              lable: data ? data.attributes.insurance_company_id.short_name: item.short_name,
              insuaranceCompanyId: data ? data.attributes.insurance_company_id.id: '',
              insuaranceContactId: data ? data.attributes.insurance_contact_id: '',
              markAsSubmitted: data ? data?.attributes?.mark_as_submitted : false,
              emailSent: data ? data.attributes.email_sent: '',
              emailDraft: item.emailBody,
              emailSubject: item.emailSubject || '',
              email: data ? data.attributes.email: '',
              uploadedAttachments: data ? data?.attributes?.upload_submitted_attachments : [],
              fileUploadHasOneElement: !!data?.attributes
            })
      }

      initialProcessingForm = (stageTwoData: any = []) => {
        const stageData: any = [];
        const insuranceData  = this.extractInsurerData(this.insurerList)
        insuranceData?.forEach((item: any) => {
          if(item.insurance_contacts?.length > 0) {
            const data = stageTwoData?.find((val: any) =>  val?.attributes?.insurance_company_id?.id === item.id)
            const obj = this.setInitialDataValues(data, item);
            stageData.push(obj);
          }
        })
          this.setState({stageData, processingLoading: false})

      }

      capitalizeFirstCharacter = (str: string) => {
        if (str?.length === 0) {
          return str;
        }
        return str?.charAt(0)?.toUpperCase() + str?.slice(1);
      }
      /*istanbul ignore next*/
      generatetemplateString =(segment:any) =>{
        const selectedCustomerData = JSON.parse(localStorage.getItem("selectedCustomer") || '{}')
        return `
      Dear Team,

      Hope you are doing well.
          
      Kindly requested to quote your best terms of ${this.capitalizeFirstCharacter(this.insuranceType)} insurance for our client, with subject to segment${selectedCustomerData?.segment&&selectedCustomerData?.segment?.length > 1 ? 's' : ''} ${selectedCustomerData?.segment?.map((item:any) => typeof item !=="string"?item.label: item).join(',')}.
     
      ${selectedCustomerData?.branches && selectedCustomerData?.branches?.data.map((branch: any) => (`- ${branch?.attributes?.name}`))?.join('\n')}
          
      For your reference, please find attached all relevant information.
      Thank You,
      Best Regards`}

      extractInsurerData = (insurerList: any[]) => {
        const extractedData: any[] = [];
        for (const insurer of insurerList) {
        const {
            
            segment,
            insurer_id,
            legal_name,
            short_name,
            insurance_contacts,
            documents,
            id
            
        } = insurer.allData;

        const formattedInsuranceContacts = insurance_contacts?.data?.length > 0 ? insurance_contacts?.data?.map((contact: any) => {
            const { email,first_name, last_name, ...rest } = contact.attributes;
            return {
              ...rest,
              emailSubject: `RFQ: ${this.capitalizeFirstCharacter(this.insuranceType)} insurance for ${this.capitalizeFirstCharacter(this.customerName)} `,
              emailBody: this.generatetemplateString(segment),
              email,
              first_name,
            };
          }): [];
        const extractedInsurer: any = {
            insurance_contacts: formattedInsuranceContacts,
            documents: documents?.data,
            segment: segment?.length> 0 ?segment?.map((item: any) => item.value): [],
            value: insurer?.value,
            label: insurer?.label,
            insurer_id,
            legal_name,
            short_name,
            emailBody: this.generatetemplateString(segment?.length> 0 ?segment?.map((item: any) => item.value): []),
            emailSubject: `RFQ: ${this.capitalizeFirstCharacter(this.insuranceType)} insurance for ${this.capitalizeFirstCharacter(this.customerName)} `,
            id
        };
    
        extractedData.push(extractedInsurer);
      }
    
        return extractedData;
    }

    setValuesForEditAndView = async (policyId: number) => {
      await this.getProcessingData(policyId)
    }

    async componentDidMount() {
      const columnData={
        id:this.state.columnList.length+1,
        select_from_table:"",
        select_column:"",
        column_name:""
      }
      this.setState({columnList:[...this.state.columnList,columnData]})
      super.componentDidMount();
      this.setState({processingLoading: true})
      const queryParameter: any = new URLSearchParams(window.location.search);
      const policyId: string = queryParameter?.get('id');
      const hasOnlyView:boolean = window.location.href.includes('ViewPolicy') 
      const isCreateStage: boolean = window.location.href.includes('PolicyRequest') 
      const stageOneData = JSON.parse(localStorage.getItem("stageOne") || '{}')
      const stageOnePartTwoData = JSON.parse(localStorage.getItem("stageOnePartTwoData") || '{}')
      this.insurerList = stageOnePartTwoData.insuranceCompany;
      this.insuranceType = stageOnePartTwoData.insuranceType;
      this.customerName = stageOneData?.customer;
      this.setState({isViewOnly: hasOnlyView})
      this.setState({expandedAccordion: this.props.isExpanded as boolean})
      this.setState({stage2submitted: false})
      this.setState({isDraftAPIUsed: false})
      await this.getCustomerDetials(stageOneData?.customerId)
      /* istanbul ignore next */
      this.policyId = policyId ? parseInt(policyId) : stageOneData.policyId
      /* istanbul ignore next */
      this.policyId && this.getStageOneResponse(this.policyId);
       /* istanbul ignore if */
      //  const selectedCustomerData = JSON.parse(localStorage.getItem("selectedCustomer") || '{}')
       this.stageInfo = {...stageOnePartTwoData, ...stageOneData}
      /* istanbul ignore next */if(hasOnlyView || this.props.isEdit || /* istanbul ignore next */(isCreateStage && policyId && this.isStageTwoPolicyCreated())) {
        await this.setValuesForEditAndView(this.policyId as number);
        if(!this.state.hasViewPermissionList?.includes(this.state.loggedInRole)){
          this.setState({expandedAccordion: false})
        }
        
      } else /* istanbul ignore next */{
       
        const handoverNote = window.localStorage.getItem("handoverNote") || ''
        this.handleHandOverNoteText(handoverNote)
        this.initialProcessingForm()
      }
      
  }
  customerDetialsIdReceive = (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.customerDetialsId != null &&
      this.customerDetialsId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {

        localStorage.setItem('selectedCustomer',JSON.stringify(responseJson.data.attributes))
        this.stageInfo={...this.stageInfo,...responseJson.data.attributes}
        this.initialProcessingForm()
      }
    }
  }
  getCustomerDetials = async (customerId: string) => {
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const customerDetailApi = `/bx_block_customer/customers/${customerId}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.customerDetialsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      customerDetailApi
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

    isStageTwoPolicyCreated = () => {
      const stageOneData = JSON.parse(localStorage.getItem("stageOne") || '{}');
      return stageOneData?.isNextStageSubmitted

    }


      handleAccordian = () => {
        if(this.policyId && !this.hasOnlyViewRights() && this.isStageTwoPolicyCreated()) {

          const {role} = JSON.parse(window.localStorage.getItem('loginData') || '{}')
          this.setState({ 
            showAlert: true, 
            alertMessage: `RFQ processing is not accessible to ${role} role. Please login with other role`, 
            alertType: "warning"
          })
          return
        }
          this.setState((prev: any) => ({
            ...prev,
            expandedAccordion: !prev.expandedAccordion
          }))
          
      }
      handleHandOverNoteText = (inputText: string) => {
          this.setState({handOverNoteText: inputText })
      }
      handleRejectReasonText =(inputText: string) =>{
          this.setState({rejectReasonText: inputText})
      }

      handOverNoteSubmit = (value:string) => {
        this.trackRequest(this.policyId,'next_stage', value, 'awaiting');
    }

      closeNoteDialog =()=>{
          this.setState({showNoteDialog: false,handOverNoteText:"", isBtnVisible: true})
      }
      openNoteDialog =() =>{
          this.setState({showNoteDialog: true, isBtnVisible: false})
      }
      openRejectDialog = ()=> {
          this.setState({showRejectDialog: true})
      }
      closeRejectDialog =()=> {
          this.setState({showRejectDialog: false, isBtnVisible: true})
      }
      successCloseRejectDialog = () => {
        this.closeRejectDialog();
        this.trackRequest(this.policyId, 'reject_stage', this.state.rejectReasonText as string, 'info_gathering' );
      }
      /* istanbul ignore next */
      moveToSelectedStep = (step: any)=> {
          this.setState({moveToStep: step})
      }

      handleProcessingAccording = () => {
        this.setState((prevState: any) => ({
          ...prevState,
          expandProcessingAccordian: !prevState.expandProcessingAccordian
        }));
      }

      hasOnlyViewRights = () => {
        /* istanbul ignore next */
        return (this.isStageTwoPolicyCreated() && this.policyId && this.state.hasViewPermissionList?.includes(this.state.loggedInRole)) as boolean 
      }

      hasOnlyEditRights = () => {
        /* istanbul ignore next */
        return (this.isStageTwoPolicyCreated() && this.policyId && this.state.hasUpdatePermissionList?.includes(this.state.loggedInRole)) as boolean
      }
      
      async submitProcessingData(values: any){
        const formData = this.getFormData(values);
        if(this.isStageTwoPolicyCreated()||this.props.isEdit || this.state.isDraftAPIUsed ) {
          /* istanbul ignore next */
          this.modifyProcessingData(this.policyId, formData)
        } else {
          this.setProcessingData(this.policyId, formData)
        }      
      }

      getFormData = (data: any, isDraft: boolean = false) => {
        const formData = new FormData();
        const {stageData} = data;
        /* istanbul ignore next */
        stageData.forEach((item: any, index: number)=> {
          item.id && formData.append(`data[rfq_processing][processing_insurance_infos_attributes][${index}][insurance_company_id]`, item.id );
          item?.sectionId && formData.append(`data[rfq_processing][processing_insurance_infos_attributes][${index}][id]`, item?.sectionId );
          item.insuaranceContactId?.value && formData.append(`data[rfq_processing][processing_insurance_infos_attributes][${index}][insurance_contact_id]`, item.insuaranceContactId?.value );
          item.markAsSubmitted && formData.append(`data[rfq_processing][processing_insurance_infos_attributes][${index}][mark_as_submitted]`, item.markAsSubmitted );
          item.emailSent && formData.append(`data[rfq_processing][processing_insurance_infos_attributes][${index}][email_sent]`, item.emailSent );
          item?.uploadedAttachments.length > 0 && formData.append(`data[rfq_processing][processing_insurance_infos_attributes][${index}][upload_submitted_attachments]`, item.uploadedAttachments );
          (formData.append(`data[rfq_processing][save_as_draft]`, isDraft ? 'yes': 'no' ))
        })

        return formData;
      }

      getFormDataForFileUpload = (file_key: string, insurerId: number, fileData: any, isMultiUpload: boolean) => {
          
        if (isMultiUpload) {
            const formData = new FormData();
            formData.append("data[stage]", "2");
            formData.append("data[file_key]", file_key);
            formData.append("data[multiple]", "true");
            formData.append("data[insurance_company_id]", `${insurerId}`)
            formData.append("data[is_already_existing]", "true")

            for (const item of  fileData) {
                formData.append(`data[file][]`,item);
            }
            
            return formData;

        }
      }

      saveDraftData = async (formikRef: any) => {
        const { current: formikInstance } = formikRef;
        /* istanbul ignore if */
        if(formikInstance){
          const formData = this.getFormData(formikInstance.values, true);
          (this.props.isEdit || this.state.isDraftAPIUsed || this.isStageTwoPolicyCreated() ) ? 
           this.modifyProcessingData(this.policyId, formData) : 
           this.setProcessingData(this.policyId, formData)
          this.setState({isDraftAPIUsed: true});
        }
      }

      processingSchema = (isArabicLanguage: boolean) => {
          const schema = {
              insuaranceContactId: Yup.object().shape({value: Yup.number().required(isArabicLanguage ? 'جهة الاتصال للمُؤمن مطلوبة.' : "Insurer contact is required.")}),
              markAsSubmitted: Yup.bool().oneOf([true], isArabicLanguage ? 'العلامة كمُقدَّم مطلوبة.' : "Mark as submitted is required."),
              emailSent: Yup.string().required(isArabicLanguage ? 'البريد الإلكتروني المُرسَل مطلوب.' : "Sent email is required."),
              uploadedAttachments: Yup.array().min(1, isArabicLanguage ? 'يجب تحميل ملف واحد على الأقل باللغة العربية.' : "Atleast one file should be uploaded"),
            }
          return schema;
      }

      getProcessingData = async (policyId:any) => {
          this.getProcessingDataAPI = this.apiCall({
              contentType: 'application/json',
              method: 'GET',
              endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=2`,
          })
      }

      getStageOneResponse = async (policyId: number) => {
        this.stageOneAPIResponse = this.apiCall({
          contentType: "application/json",
          method: "GET",
          endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=1`,
        });
      };

      checkErrorForStageTwoForm = (formikInstance: any) => {
          if(formikInstance.errors.length > 0){
            this.setState({
              showAlert:true,
              alertMessage: 'Please fill all required fields',
              alertType:"error"
          })
          }
    }

      setProcessingData = (policyId:any, values: any) => {
          this.setProcessingDataAPI = this.apiCall({
              type : 'FormData',
              method: 'POST',
              body: values,
              endPoint: `bx_block_policy_request/policy_requests?stage=2&id=${policyId}`,
          })
      }
      getPolicyDetails = async (policyId:any) => {
        const userData = await JSON.parse(localStorage.getItem('loginData') || '{}');
        const token = await userData.token || '';

        this.getPolicyDetailsAPI = this.apiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=1`,
            token: token || ''
        })
    }

      uploadFileStageTwo = (file_key: string, insurerId: number, fileData: any, isMultiUpload: boolean, formikRef: any, insurerIndex: number) => {
        this.formikRef = formikRef;
        this.insurerIndex = insurerIndex
        this.uploadFilesAPI = this.apiCall({
          type : 'FormData',
          method: 'PUT',
          body: this.getFormDataForFileUpload(file_key, insurerId, fileData,isMultiUpload),
          endPoint: `bx_block_policy_request/policy_requests/${this.policyId}/file_upload`,
      })
      }

      removeFile = (fileId: number, insurerIndex: number, formikInstance: any) => {
        this.idToRemove = fileId;
        this.insurerIndex = insurerIndex;
        this.formikRef = formikInstance;
        this.removeFileAPI = this.apiCall({
          contentType: 'application/json',
          method: 'PUT',
          body: {data: { file_id: fileId}},
          endPoint: `bx_block_policy_request/policy_requests/${this.policyId}/remove_file`,
        })
      }

      modifyProcessingData = (policyId:any, values: any) => {
          this.modifyProcessingDataAPI = this.apiCall({
              type : 'FormData',
              method: 'PUT',
              body: values,
              endPoint: `bx_block_policy_request/policy_requests/${policyId}?stage=2`,
          })
      }

      trackRequest = (policyId: any, stageStatus: string, comment: string, currentStage: string) => {
        const stageInfo = stageStatus === 'reject_stage' ? '?stage=2': ''
          this.trackRequestAPI = this.apiCall({
              contentType: 'application/json',
              method: 'POST',
              body: {data: {track_request: {stage_status:stageStatus, ...(comment && { comment }), current_stage: currentStage }} },
              endPoint: `bx_block_policy_request/policy_requests/${policyId}/track_requests${stageInfo}`,
          })
      }

      getStageTwoAlertMessage = (isDraft: boolean, reject: boolean = false) => {
        const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false;
        let alertMessage = "";
        if (isDraft) {
          alertMessage = isArabic ?  /* istanbul ignore next */"تم حفظ بيانات طلب المراجعة كمسودة!" : "Data has been saved as draft!"
        } 
        else if(!isDraft && reject) {
          alertMessage = isArabic ?  /* istanbul ignore next */"لقد تم رفض طلبك إلى المرحلة المحددة" : "Your request has been reject to selected stage!"
        }
        else {
          alertMessage = isArabic? /* istanbul ignore next */"لقد تم نقل طلبك إلى مرحلة الإنتظار" : "Your request has been moved to Awaiting stage"
        }
        return alertMessage
      }
    
      setStageTwoApiErrorMessage = (responseJson:any) => {
        if (responseJson.errors) {
          const errorMessage = typeof(responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error  || "Something went wrong";
          this.setState({
            alertType: "error",
            alertMessage: errorMessage,
            showAlert: true,
            showNoteDialog: false
          })
        }
      }


      apiCall(data: any) {
          const { contentType, method, endPoint, body, type } = data;
          const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
          const token = userData.token || "";
      
          let header: any = {
            token: token,
          };

          if(contentType) {
            header = {
              ...header,
              "Content-Type": contentType,

            }
          }
      
          const stageTwoRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          stageTwoRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          stageTwoRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
          );
          stageTwoRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
          );
          body && type !== 'FormData' ?
            stageTwoRequestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(body)
            ) : stageTwoRequestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              body
            ) ;
      
          runEngine.sendMessage(stageTwoRequestMessage.id, stageTwoRequestMessage);
          return stageTwoRequestMessage.messageId;
      }

      navigateInsurerDetails = (insurerId: number) => {
        const msg: Message = new Message(
          getName(MessageEnum.NavigationInsurerDetailMessage)        
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
        msg.addData(getName(MessageEnum.InsurerDetailIdPayload), insurerId);
        this.send(msg);
      }

      createApiResponse = (message: any) => {

        if (
          getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.setProcessingDataAPI !== null &&
          this.setProcessingDataAPI ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          )   
          {
            const createStageTwoResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
              try {
                if (createStageTwoResponseJson.data) {
                  this.initialProcessingForm(createStageTwoResponseJson?.data?.attributes?.processing_insurance_infos?.data);
                   /* istanbul ignore if */
                  if(this.state.isDraftAPIUsed &&  createStageTwoResponseJson.data.attributes.save_as_draft){
                    this.setState({ 
                      showAlert: true, 
                      alertMessage: this.getStageTwoAlertMessage(true),
                      alertType: 'success',
                      showNoteDialog: false
                    })
                    return
                  }
                  this.setState({isDraftAPIUsed: false, showNoteDialog: true}) 
                  window.localStorage.setItem("stageTwoData", JSON.stringify(createStageTwoResponseJson?.data))
                  this.props.setStageData('stageTwoData',createStageTwoResponseJson?.data)
                  this.setState({expandedAccordion: false, stage2submitted: true})
                  this.getPolicyDetails(this.policyId)
                } else {
                    this.setStageTwoApiErrorMessage(createStageTwoResponseJson)
                }
              } catch (error) {
                //ignore
              }finally {
                /* istanbul ignore if */
                if(createStageTwoResponseJson.meta) {
                  const {current_stage_view_permission_role, current_stage_update_permission_role} = createStageTwoResponseJson.meta
                  const {role} = JSON.parse(window.localStorage.getItem('loginData')|| '{}')

                  this.setState({
                    hasViewPermissionList : current_stage_view_permission_role,
                    hasUpdatePermissionList: current_stage_update_permission_role, 
                    loggedInRole: role
                  })
                }
              }
          
          }
      }

      stageOneDataResponse = (message: any) => {

        if (
          getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.getPolicyDetailsAPI !== null &&
          this.getPolicyDetailsAPI ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          )   
          {
            const stageOneAPIResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
                if (stageOneAPIResponseJson.data) {
                  let stageOnedata = JSON.parse(window.localStorage.getItem("stageOne") || "{}");
                  window.localStorage.setItem('stageOne', JSON.stringify({ ...stageOnedata, isNextStageSubmitted:stageOneAPIResponseJson.data.attributes.is_next_stage_submitted }));
                } else {
                    this.setStageTwoApiErrorMessage(stageOneAPIResponseJson)
                }
             
          
          }
      }

      getApiResponse = (message: any) => {
        if (
          getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.getProcessingDataAPI !== null &&
          this.getProcessingDataAPI ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
            const getStageTwoResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
                /* istanbul ignore if */
                if (getStageTwoResponseJson?.data) {
                  this.initialProcessingForm(getStageTwoResponseJson?.data?.attributes?.processing_insurance_infos?.data)
                  // this.handleHandOverNoteText(getStageTwoResponseJson?.data?.attributes?.handover_note)
                  window.localStorage.setItem("stageTwoData", JSON.stringify(getStageTwoResponseJson?.data))
                  this.props.setStageData('stageTwoData',getStageTwoResponseJson?.data)


                  if(getStageTwoResponseJson?.meta) {
                    const {current_stage_view_permission_role, current_stage_update_permission_role} = getStageTwoResponseJson.meta
                    const {role} = JSON.parse(window.localStorage.getItem('loginData')|| '{}')

                    this.setState({
                      hasViewPermissionList : current_stage_view_permission_role,
                      hasUpdatePermissionList: current_stage_update_permission_role, 
                      loggedInRole: role
                    })
                  }

                } else {
                    this.setStageTwoApiErrorMessage(getStageTwoResponseJson)
                }
          }
        }

      getStageOneApiResponse = (message: any) => {
          if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.stageOneAPIResponse !== null &&
            this.stageOneAPIResponse ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
              const getStageTwoResponseJson = message.getData(
                  getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                  /* istanbul ignore if */
                  if (getStageTwoResponseJson?.data) {
                    window.localStorage.setItem(
                      "stageOneData",
                      JSON.stringify(getStageTwoResponseJson?.data)
                    );
  
                  } else {
                      this.setStageTwoApiErrorMessage(getStageTwoResponseJson)
                  }
            }
          }

        updateAPIResponse = (message: any) => {
          if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.modifyProcessingDataAPI !== null &&
            this.modifyProcessingDataAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          )   {
              const updateStageTwoResponseJson = message.getData(
                  getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
            try {
              if (updateStageTwoResponseJson.data) {
                this.initialProcessingForm(/* istanbul ignore next */updateStageTwoResponseJson?.data?.attributes?.processing_insurance_infos?.data);
                 /* istanbul ignore if */
                if(this.state.isDraftAPIUsed && /* istanbul ignore next */updateStageTwoResponseJson.data.attributes.save_as_draft) {
                  this.setState({ 
                    showAlert: true, 
                    alertMessage: this.getStageTwoAlertMessage(true),
                    alertType: 'success'
                  })
                  return
                }
                this.setState({isDraftAPIUsed: false, showNoteDialog: true}) 
                window.localStorage.setItem("stageTwoData", JSON.stringify(updateStageTwoResponseJson?.data))
                this.props.setStageData('stageTwoData',updateStageTwoResponseJson?.data)
                this.setState({expandedAccordion: false})
              } else {
                  this.setStageTwoApiErrorMessage(updateStageTwoResponseJson)
              }
            } catch (error) {
                //ignore
            } finally {
               /* istanbul ignore if */
              if(updateStageTwoResponseJson.meta) {
                const {current_stage_view_permission_role, current_stage_update_permission_role} = updateStageTwoResponseJson.meta
                const {role} = JSON.parse(window.localStorage.getItem('loginData')|| '{}')

                this.setState({
                  hasViewPermissionList : current_stage_view_permission_role,
                  hasUpdatePermissionList: current_stage_update_permission_role, 
                  loggedInRole: role
                })
              }
            }
          
          }
        }
        uploadFileAPIResponse = (message: any) => {
          if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.uploadFilesAPI !== null &&
            this.uploadFilesAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          )   {
             const fileUploadStageTwoResponseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
            if (fileUploadStageTwoResponseJson.data) {
              this.formikRef.current.setFieldValue(`stageData[${this.insurerIndex}].uploadedAttachments`, fileUploadStageTwoResponseJson?.data?.uploaded_file)
              const isArabic = (window.localStorage.getItem("lang") || "en") === "ar" ? true : false; 
              /* istanbul ignore next */
              this.setState({
                uploadedFileList: fileUploadStageTwoResponseJson?.data?.uploaded_file, 
                alertMessage: isArabic ? "تم تحميل الملف بنجاح" : "File successfully uploaded",
                showAlert: true, 
                alertType: 'success'
              })
  
            } else {
              this.setStageTwoApiErrorMessage(fileUploadStageTwoResponseJson)
            }
          
          }
        }
        removeFileAPIResponse = (message: any) => {
          if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.removeFileAPI !== null &&
            this.removeFileAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          )   {
          const removeFileStageTwoResponseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
           /* istanbul ignore if */
            if (removeFileStageTwoResponseJson.data) {
              const { current: formikInstance } = this.formikRef;
              const updatedFileList = formikInstance?.values?.stageData[this.insurerIndex]?.uploadedAttachments?.filter((file: {id: number, url: string}) => file.id !== this.idToRemove)
              this.formikRef.current.setFieldValue(`stageData[${this.insurerIndex}].uploadedAttachments`, updatedFileList)
              this.setState({ 
                showAlert: true, 
                alertMessage: removeFileStageTwoResponseJson?.data?.message, 
                alertType: 'warning'
              })
            }
            else{
              this.setStageTwoApiErrorMessage(removeFileStageTwoResponseJson)
            }
          
          }
        }

        trackAPIResponse = (message: any) => {
          if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.trackRequestAPI != null &&
            this.trackRequestAPI ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
            const trackRequestStageTwoResponseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (trackRequestStageTwoResponseJson.data) {
              /* istanbul ignore if */
              if(trackRequestStageTwoResponseJson.data.attributes.stage_status === 'reject_stage') {
                this.getStageTwoAlertMessage(false, true)
                setTimeout(()=> {
                  this.props.onReject(1);
                }, 700)
              }
              else {
                const stageTwoAlertMessage = this.getStageTwoAlertMessage(false)
                this.setState({showNoteDialog:false, expandedAccordion:false,showAlert:true,alertMessage: stageTwoAlertMessage,alertType:"success"});
                this.props.onNext(2)
              }
            } else {
               /* istanbul ignore next */
              this.setStageTwoApiErrorMessage(trackRequestStageTwoResponseJson);
            }
          }
        };
        handleopenCSVDialog=()=>{
          this.setState({openCSVDialog:!this.state.openCSVDialog})
        }
        handleSelectTemplateChange=(event:any)=>{
          this.setState({selectedtemplateOption:event.target.value},()=>{
            if(this.state.selectedtemplateOption==="Select from existing template"){
              this.getAllTemplateListApi=this.apiCall({
                contentType: 'application/json',
                method: 'GET',
                endPoint: `/bx_block_xmlcsvtemplatebuilder2/csv_templates?insurance_type=${this.insuranceType}`,
            })
            }else{
              this.setState({selectedtemplateOption:"Create a new template",selectedTemplateID:""})
            }
          })
        }
        getAllTemplateListAPIResponse = (message: any) => {
          if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getAllTemplateListApi !== null &&
            this.getAllTemplateListApi ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
              const getAllTemplateListJson = message.getData(
                  getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                 
                  if (getAllTemplateListJson?.data) { 
                    const transformedData=getAllTemplateListJson.data.map((ele:any)=>{
                      return {value:ele.id.toString(),label:ele.template_name}
                    })
                  this.setState({templateList:transformedData})  
                  }
            }
          }
        handleConfirmCSvDialog = () => {
          const state:{
            [key: string]: string;
          } = { insuranceType: this.insuranceType };
          const queryString = Object.keys(state)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(state[key]))
            .join('&');
            const templateID=this.state.selectedTemplateID.value ? `&templateID=${this.state.selectedTemplateID.value}`:""
          const newUrl = '/CSVExport?' + queryString+templateID;
          window.location.href = newUrl;
        }
        handleTemplateIDChange=(selectedOption:any)=>{
          this.setState({selectedTemplateID:selectedOption})
        }
      async receive(from: string, message: Message) {
        this.customerDetialsIdReceive(message)
        this.createApiResponse(message)
        this.getApiResponse(message)
        this.updateAPIResponse(message)
        this.uploadFileAPIResponse(message)
        this.removeFileAPIResponse(message)
        this.trackAPIResponse(message)
        this.getStageOneApiResponse(message)
        this.getAllTemplateListAPIResponse(message)
        this.stageOneDataResponse(message)
  }}
  // Customizable Area End

