import React from 'react'
import { Accordion,makeStyles, AccordionSummary, AccordionDetails, IconButton, Typography } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import MotorInsurance from './MotorInsurance.web';
import { useTranslation } from 'react-i18next';


const useStylesFunction = makeStyles({
    accordianHeading: {
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
    },
    lockedAccordion: {
        padding: '6px 10px',
        backgroundColor: '#e9f4f9',
        border: '1px solid #ccc',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 8px',
        '& svg': {
            marginRight: '6px',
        }
    },


    accordionWrapper: {
        padding: '0px 15px',
        '& .MuiAccordion-root': {
            backgroundColor: '#fff',
            margin: '15px 0px'
        }
    },
    btnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "color": "white",
        // "height": "50px",
        borderRadius: "10px",
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    headerButtonStyle: {
        display: "flex",
        justifyContent: "end"
    },
    dialogBtnWrapper: {
        justifyContent: 'center',
        marginBottom: '15px',
        textAlign: 'right',
        '@media(min-width: 500px)': {
            '& button': {
                padding: '12px 78px',
            }
        },
    },
    dialogBtnCancel: {
        color: 'rgb(0 0 0 / 54%)',
        textTransform: 'capitalize',
        margin: '0px 15px',
        borderRadius: '15px',
    },
    dialogBtnSave: {
        backgroundColor: '#45a3ad',
        textTransform: 'capitalize',
        borderColor: '#45a3ad',
        color: '#fff',
        "&:hover": {
            backgroundColor: '#45a3ad',
        }
    },
    addCmp: {
        '& > div': {
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 700,
        },
        '& > p': {
            color: 'rgba(0, 0, 0, 0.87)',
        }
    },
    accordionDetailsWrapper: {
        display: 'block',
        textAlign: 'right',
        '& > svg': {
            color: '#2f2f2f',
            cursor: 'pointer'
        }
    },
    lineSaprator: {
        backgroundColor: '#e2e2e2',
        height: '1.3px',
        width: '100%',
        margin: '18px 0px'
    },
    inputFile: {
        // '& input[type="file"]::file-selector-button':{
        //     display: 'none',
        // },
        color: '#7f7f7f',
        '& fieldset': {
            border: '1px dashed rgba(0, 0, 0, 0.23) !important'
        },
        '& > div': {
            height: '40px',
            overflow: 'hidden',
        },
        '& input': {
            opacity: '0',
            position: 'absolute',
            left: '0px',
            right: '0px',
            maxWidth: '75%',
        },
        '& .MuiInputAdornment-positionEnd': {
            marginLeft: 'auto',
            position: 'absolute',
            right: '0px',
            '& > *': {
                background: '#fff',
            }
        }
    },
    accordionStyle: {
        background: "white !important",
        "border-radius": "10px !important",
    },
    errorstyle: {
        border: '1px solid red !important',
        "border-radius": "10px !important",
    },
    editWrapper: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 15px',
        paddingTop: '15px',
        '.MuiGrid-root.MuiGrid-item':{
            fontWeight: 600,
        },
        '& button': {
            padding: '15px 0px',
            width: '150px',
            borderColor: '#3b8da2',
            borderRadius: '15px',
            textTransform: 'capitalize',
        }
    },
    editBtnStyle: {
        backgroundColor: '#3b8da2',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#3b8da2',
            color: '#fff',
        }
    },

})

const enumFormAction = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
}

type accordionTitleProps = {
    title: string,
    insuranceData: any,
    insurerId?: any
}

const AccordionTitle = (props: accordionTitleProps) => {
    const classes = useStylesFunction()
    const {t} = useTranslation()
    return (
        <Typography className={classes.accordianHeading}>
            {props.title}
            {
                !props.insurerId && props.insuranceData === null && (
                    <div className={classes.lockedAccordion}>
                        <LockIcon /> {t('please_select_insurance_type_to_unlock_this_fields')}
                    </div>
                )
            }

        </Typography>
    )
}


const MotorJSX: React.FC<any>  = (props: any) => {
    const classes = useStylesFunction();
    const {t} = useTranslation();

    return (
        props.insuranceData && props.insuranceData.types_of_insurance && props.insuranceData.types_of_insurance.map((item: any) => {
            if (item.value === 'Motor') {
                return (
                    <Accordion key={item} disabled={props.insuranceData === null ? true : false} className={props.errorSates.motor_insurance_attributes_error ? classes.errorstyle : classes.accordionStyle}>
                        <AccordionSummary
                            aria-expanded={true}
                            expandIcon={<ExpandMore />}
                            aria-controls="Motor Insurance"
                            id="Motor Insurance"
                        >
                            <AccordionTitle title={`${t("motor")} ${t("insurance")}`} insurerId={props.insurerId} insuranceData={props.insuranceData} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <MotorInsurance
                                motorInsuranceProps={props.handleMotorInsurance}
                                data={props.motor_insurance}
                                submitEvent={props.checkErrors}
                                formType={props.formType}
                            />
                        </AccordionDetails>
                    </Accordion>
                )
            }
        } )
    )
        
}

export default MotorJSX