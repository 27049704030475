// Customizable Area Start
import React from "react";
import { withTranslation } from "react-i18next";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Typography,
	Divider,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ErrorOutlineRounded, ExpandMore, FileCopyOutlined } from '@material-ui/icons'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { withStyles } from "@material-ui/core/styles";
import FileUploadMulti from "../../../../../../components/src/Common/FileUploadMulti";
import HandOverDialog from '../../../../../../components/src/Common/HandOverDialog.web'
import MultiSelectFormik from '../../../../../../components/src/Common/MultiSelectFormik'
import LabelWithIcon from "../../../../../../components/src/Common/LabelWithIcon";
import TextInput from "../../../../../../components/src/Common/TextInput";
import Toster from "../../../../../../components/src/Common/Toster";
import StageTwoController, { Props } from "./StageTwoController";
import CustomButton from "../../../../../../components/src/Common/CustomButton";
import RejectRequestDialog from "../../../../../../components/src/Common/RejectRequestDialog.web";
import  CSVExport  from "../../../../../../components/src/Common/CsvExportDetails";
import ActiveLanguage from "../../../../../../components/src/Common/ActiveLanguage";
// Customizable Area End
type Severity = "error" | "success" | "info" | "warning" | undefined;

export class StageTwoForm extends StageTwoController {
	  formikRef: any;
		emailRef: React.RefObject<HTMLInputElement>;
    emailDraftRef: React.RefObject<HTMLInputElement>;
    emailSentRef: React.RefObject<HTMLInputElement>;
    emailSubjectRef: React.RefObject<HTMLInputElement>;

    constructor(props: Props) {
        // Customizable Area Start
        super(props);
				this.formikRef = React.createRef();
				this.emailRef = React.createRef<HTMLInputElement>();
        this.emailDraftRef = React.createRef<HTMLInputElement>() as any;
        this.emailSubjectRef = React.createRef<HTMLInputElement>();
        this.emailSentRef = React.createRef<HTMLInputElement>();
        // Customizable Area End
    }

    // Customizable Area Start
			handleSubmitFromOutside = () => {
				const { current: formikInstance } = this.formikRef;
				if (formikInstance) {
					console.log('this.state.uploadedFileList?.length: ', this.state.uploadedFileList?.length);
					this.state.uploadedFileList?.length && formikInstance.setFieldTouched('uploadedAttachments', true, false)
					formikInstance.handleSubmit();
				}
			}
			
			getValue = (markAsSubmitted: boolean) => {
				return markAsSubmitted ? true : false
			}
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { t, classes, isExpanded, rrStageTwoData }: any = this.props;
				const {expandedAccordion, disableAccordian, stageData} = this.state;
				const getSelectedInsurerContact = (value: any, setFieldValue: any) => {
					const data = this.state.stageData.contact?.find((val:any)=> val.id === value.id)
					setFieldValue(`email`, data?.email)
					setFieldValue(`emailSubject`, data?.emailSubject)
					setFieldValue(`emailDraft`, data?.emailBody)
				}
				const contactList = (stageData.contact || []).map((val: any)=> ({label: val.first_name , value: val.id, allData: {id: val.id}} ))
				const handleCopyClick = (ref: React.RefObject<HTMLInputElement>) => {
					if (ref.current) {
						const textField = ref.current.querySelector('input') ?? ref.current.querySelector('textarea');
						textField?.select();
						document.execCommand('copy'); // NOSONAR typescript:S1874
					}
				};
				const pushBackOption = [
					{ value: "info_gathering", label: "Info Gathering" },
				];
				const getFilePath = (values: any[]) => {
					if(this.state.uploadedFileList.length) values = [...values, ...this.state.uploadedFileList]
					return values?.length > 0 ? values : [];
				}

        // Customizable Area End

        return (
					// Customizable Area Start
					<React.Fragment>
						<Toster
								tosterText={this.state.alertMessage}
								showToster={this.state.showAlert}
								tosterType={this.state.alertType as Severity}
								handleCloseToster={() => this.setState({showAlert:false})}
						/>
						<div className={classes.accordianGap}>
						<Formik
							data-test-id='formik'
							innerRef={this.formikRef}
							enableReinitialize={true}
							initialValues={this.state.stageData}
							validationSchema={this.processingSchema(ActiveLanguage(), !!this.state.uploadedFileList?.length)}
							validateOnMount={true}
							validateOnBlur={true}
							onSubmit={(values) => this.submitProcessingData(values, false)}
						>
						{({
							values,
							touched,
							errors,
							setFieldValue,
						}) => (
							<>
								<Accordion
										data-test-id='p2'
										className={classes.formTwoAccordian}
										expanded={expandedAccordion}
										disabled={disableAccordian}
										onChange={() => this.handleAccordian()} >
											<AccordionSummary
													aria-expanded={true}
													expandIcon={<ExpandMore />}
													aria-controls="panel1a-content"
													id="panel1a-header"
											>
													<div className={classes.topSection}>
															<div className={classes.displayFlex}>
																	<Typography className={classes.accordianHeading} >{t('revision_processing')}</Typography>
															</div>
															<div>
															{this.state.expandedAccordion && (
																<IconButton className={classes.descriptionIcon} data-test-id="noteDialog" onClick={() => this.handleNoteDialog()}>
																	<DescriptionOutlinedIcon/>
																</IconButton>
															)}
															{ this.checkStageTwoCondition() &&
                                                             <ErrorOutlineRounded style={{position: 'relative', top: '7px', color: '#0090a1'}} onClick={() => this.setState({showRejectDialog: true, isBtnVisible: false})}  />
                                                            }
															</div>
													</div>
											</AccordionSummary>
											<AccordionDetails>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<LabelWithIcon label={t("contact")} />
														<MultiSelectFormik
																disabled={!isExpanded}
																value={values.insuranceContactId}
																name={`insuranceContactId`}
																id='insuranceContactId'
																setOtherInput={(newValue: any) => getSelectedInsurerContact(newValue, setFieldValue)}
																options={contactList}
																data-testid="insuranceContactId"
														/>
														{touched.insuranceContactId && errors.insuranceContactId ? (
                                                            <Typography color="error" >{errors.insuranceContactId}</Typography>
                                                        ) : null}
													</Grid>
													<Grid item xs={6}>
														<LabelWithIcon label={t("email")} />
														<TextInput
															id={"email"}
															data-test-id="email"
															innerRef={this.emailRef}
															className={classes.textInputStyle}
															name={`email`}
															readOnly={true}
															disabled={!isExpanded}
															type="text"
															endAdornment={
																<InputAdornment position="end">
																	<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
																			<IconButton
																				data-test-id="email-copy"
																				edge="end"
																				style={{ pointerEvents: 'auto' }}
																				onClick={() => handleCopyClick(this.emailRef)}
																			>
																				<FileCopyOutlined />
																			</IconButton>
																	</div>
																</InputAdornment>
															}
														/>
													</Grid>
													<Grid item xs={12}>
														<LabelWithIcon label={t("email_subject")} />
														<TextInput
															id={"emailSubject"}
															innerRef={this.emailSubjectRef}
															className={classes.textInputStyle}
															name={`emailSubject`}
															type="text"
															disabled={!isExpanded}
															endAdornment={
																<InputAdornment position="end">
																		<IconButton
																			edge="end"
																			data-test-id="email-subject"
																			style={{ pointerEvents: 'auto' }}
																			onClick={() => handleCopyClick(this.emailSubjectRef)}
																		>
																			<FileCopyOutlined />
																		</IconButton>
																</InputAdornment>
															}
															readOnly={true}
															data-test-id="emailSubject"
														/>
													</Grid>
													<Grid item xs={12}>
														<LabelWithIcon label={t("email_draft")} />
														<TextInput
															id={"emailDraft"}
															innerRef={this.emailDraftRef}
															className={classes.textInputStyle}
															name={`emailDraft`}
															type="text"
															multiline={true}
															minRows={4}
															disabled={!isExpanded}
															endAdornment={
																<InputAdornment position="end">
																		<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
																				<IconButton
																					edge="end"
																					data-test-id="email-draft"
																					style={{ pointerEvents: 'auto' }}
																					onClick={() => handleCopyClick(this.emailDraftRef)}
																				>
																					<FileCopyOutlined />
																				</IconButton>
																		</div>
																</InputAdornment>
															}
															readOnly={true}
															data-test-id="emailDraft"
														/>
													</Grid>
													<Divider className={classes.textInputStyle} />
													<Grid item xs={12}>
														<FormControlLabel
															color="primary"
															data-test-id="markAsSubmitted"
															disabled={!values.insuranceContactId?.value || !isExpanded}
															control={
																<Checkbox color="primary"
																	data-test-id="mark-as-submitted"
																	checked={this.getValue(values.markAsSubmitted)}
																	onChange={(e: any) => {
																		setFieldValue(`markAsSubmitted`, e.target.checked )
																	}}
																/>
															}
															label={this.state.stage2submitted ? t("existing_insurance") : t("mark_as_submitted_without_questionmark")}
														/>
														<ErrorMessage
															data-test-id="markAsSubmitted-error"
															name={`markAsSubmitted`}
															className="field-error"
															render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
													</Grid>
													<Grid item xs={10}>
															<LabelWithIcon label={t("email_sent")} />
																<TextInput
																id={"emailSent"}
																innerRef={this.emailSentRef}
																className={classes.textInputStyle}
																name={`emailSent`}
																multiline={true}
																disabled={!values?.markAsSubmitted || !isExpanded}
																minRows={4}
																endAdornment={
																	<InputAdornment position="end">
																		<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
																			<IconButton
																				edge="end"
																				data-test-id="send-email-draft"
																				style={{ pointerEvents: 'auto' }}
																				onClick={() => handleCopyClick(this.emailSentRef)}
																			>
																				<FileCopyOutlined />
																			</IconButton>
																		</div>
																	</InputAdornment>
																}
																data-test-id="emailSent"
														/>
														<ErrorMessage
															data-test-id="emailSent-error"
															name={`emailSent`}
															className="field-error"
															render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
													</Grid>
													<Grid item xs={2} style={{display: 'grid', alignItems: 'end', marginBottom: '10px'}}>
														<div style={{display: 'flex', justifyContent: 'end'}}>
														<CSVExport
																disabled={!isExpanded}
																selectedTemplateID={this.state.selectedTemplateID}
																handleTemplateIDChange={this.handleTemplateIDChange}
																templateList={this.state.templateList}
																handleConfirmCSvDialog={this.handleConfirmCSvDialog}
																handleSelectTemplateChange={this.handleSelectTemplateChange}
																openCSVDialog={this.state.openCSVDialog}
																handleopenCSVDialog={this.handleopenCSVDialog}
																selectedtemplateOption={this.state.selectedtemplateOption}
																btnName={"Export Csv"} 
                                        				/>
														</div>
													</Grid>
													<Typography className={classes.noteMessage} >{t('csv_export_note')}</Typography>
													<Grid item xs={12}>
															<LabelWithIcon label={t("Upload submitted attachments")} />
															<FileUploadMulti
																	id={'uploadedAttachments'}
																	fileName="Upload Attachments"
																	data-test-id={`uploaded-attachments`}
																	name={`uploadedAttachments`}
																	inputName={`uploadedAttachments`}
																	isEditPage={true}
																	isFileLoading={this.state.fileLoading}
																	filePath={getFilePath(values?.uploadedAttachments || [])}
																	disabled={!values?.markAsSubmitted || !isExpanded}
																	onChange={(event: any) => {
																		setFieldValue('uploadedAttachments', [...values.uploadedAttachments, ...event.target.files])
																	}}
																	onRemove={({id, fileData}: {id: number, fileData: any[]}) => {
																		id && this.handleRemoveFileData(id);
																		id && this.setState({uploadedFileList: this.state.uploadedFileList?.filter((fileName: any) => fileName.id !== id)})
																		setFieldValue('uploadedAttachments', fileData)
																	}}
																	maxFilesNumber={5}
																	eachFileSizeInMB={10}
																/>
																<ErrorMessage
																	data-test-id="uploaded-error"
																	name={`uploadedAttachments`}
																	className="field-error"
																	render={(msg: string) => <div><Typography color="error">{msg}</Typography></div>} />
													</Grid>
												</Grid>
											</AccordionDetails>
								</Accordion>
								{(this.state.expandedAccordion && isExpanded) && (
									<div className={classes.stageOneRVBtnGroup}>
										<div className={classes.stageOneRVButtonsRightWrapper}>
											<CustomButton
												data-test-id="rv_savedraft_btn"
												btnText={t('save_draft')}
												disabled={!values.insuranceContactId}
												onClick={() => {
													this.setState({saveDraftLoading: true})
													this.submitProcessingData(values, true)}
												}
												loading={this.state.saveDraftLoading}
												bgColor={'#fff'}
												textColor={'#007E98'}
											/>
											<CustomButton
												data-test-id="rv_reject_btn"
												btnText={t('reject')}
												onClick={() => this.handleRejectDialog(true)}
												bgColor={'#fff'}
												textColor={'#007E98'}
											/>
											<CustomButton
												data-test-id="rv_nextBtn_btn"
												btnText={t('next_stage')}
												onClick={() => this.handleSubmitFromOutside()}
												bgColor={"linear-gradient(to bottom, #2d6f8f, #4fb7ba)"}
												textColor={'#fff'}
											/>
										</div>
									</div>
								)}

								{/* Note Dialog */}
								<HandOverDialog
									data-test-id="nextStageDialog"
									btnCancelText={t('cancel')}
									btnOkText={t('confirm')}
									openDialog={this.state.showNoteDialog}
									textId={'handOverNoteText-noteDialog'}
									headingText={t('handover_note')}
									subHeading={`(${t('info_gathering')} > ${t('processing')})`}
									inputDisable={!isExpanded}
									handleInputText={(value: string) => { this.setState({ handOverNoteText: value }) }}
									inputValue={this.props.handoverNote}
									inputLable={t("note")}
									handleOk={() => {this.handleNoteDialog();}}
									handleCancel={() => this.handleNoteDialog()}
									showDialogBtns={false}
									showCrossBtn={true}
								/>

								{/* Next stage Dialog */}
								<HandOverDialog
									data-test-id="nextStageDialog"
									btnCancelText={t('cancel')}
									btnOkText={t('confirm')}
									openDialog={this.state.showNextDialog}
									textId={'handOverNoteText'}
									headingText={t('handover_note')}
									subHeading={`(${t('processing')} > ${t('awaiting')})`}
									handleInputText={(value: string) => {
										this.setState({ handOverNoteText: value })
										this.props.setStageData("handOverNoteForAwaiting",value)
									}}
									inputValue={this.state.handOverNoteText}
									inputLable={t("note")}
									handleOk={(value: string) => this.handleTrackData('next')}
									handleCancel={() =>	this.handleNextDialog(false)}
									showDialogBtns={true}
									showCrossBtn={true}
								/>

								{/* Reject Dialog */}
								<RejectRequestDialog
									btnCancelText={t('cancel')}
									btnOkText={t('confirm')}
									openDialog={this.state.showRejectDialog}
									textId={'rvPolicyRejectReason'}
									pushBackOptions={pushBackOption}
									inputValue={this.state.isBtnVisible ? this.state.rejectReasonText : rrStageTwoData?.data?.attributes?.rejected_reason}
									setSelectedPushBackTo={(value:string) => this.setState({moveToStep: value}) }
									stepValue={this.state.moveToStep}
									headingText={t('rejecting')}
									handleInputText={(value:string) => this.setState({rejectReasonText:value})}
									handleOk={this.rejectPolicy}
									handleCancel={()=> this.handleRejectDialog(false)}
									showDialogBtns={this.state.isBtnVisible}
									showCrossBtn={true}
									labelText={t('push_back')}
            						reasonLabel={t('reason')}
								/>
							</>
						)}
						</Formik>
						</div>

					</React.Fragment>
					// Customizable Area End
        );
    }
}

// Customizable Area Start
const useStyles = ({
	accordianGap: {
		padding: "10px 0"
	},
	noContacts: {
		"font-weight": 'bold',
		'text-align': 'center',
		fontSize:"14px",
		display: "flex",
		justifyContent: "center"
	},
	cancelBtn: {
			marginRight: "10px",
			marginLeft: "10px",
			background: "white",
			borderRadius: "10px",
			"text-transform": "none",
			"height": "50px",
			"width": "150px",
	},

	formTwoAccordian: {
			"border-radius":"10px !important",
			zIndex: 99
	},
	accordianHeading: {
			fontWeight: 600
	},
	displayFlex: {
			display: "flex",
	},
	lockedAccordion: {
			fontSize: "12px",
			height: "35px",
			padding: "15px",
			border: '1px solid #ccc',
			borderRadius: '4px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			margin: '0 0 0 20px',
			backgroundColor: "lightgray",
			color: "darkgray",
			'& svg': {
					marginRight: '6px',
			}
	},
	topSection: {
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',
	},
	descriptionIcon: {
			color: '#007E98',
			zIndex: 999
	},
	textInputStyle: {
    marginTop: "10px",
    marginBottom: "10px"
	},
	formBorder: {
			border: "1px solid lightgray",
			"border-radius":"10px !important",
			'&::before': {
				content: '" "',
				display: 'none'
		}
	},
	openInTab: {
		fontSize: "12px",
		color: '#007E98',
		margin: "0 8px"
	},
	stageOneRVBtnGroup: {
        padding: "0px 15px",
    },
    stageOneRVButtonsRightWrapper: {
        display: "flex",
        gap: "15px",
        padding: "15px 0px",
        justifyContent: "flex-end",
    },
		noteMessage: {
			padding: "0px 8px",
			fontSize: "14px",
			marginBottom: "10px"
	}
});
// Customizable Area End

export default withStyles(useStyles)(withTranslation()(StageTwoForm));
