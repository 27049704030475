import GetLoggedUserData from "../GetLoggedUserData"

const UserPermission = () => {
    const userData = GetLoggedUserData();
    const route = window.location.pathname
    const navigationMenus = userData?.navigation_menu
    if (userData && userData.navigation_menu && navigationMenus.length > 0) {
        switch (route) {
            case ("/PolicyRequest"):
            case ("/ViewPolicy"):
            case ("/ImportPolicyCsv"):
            case ("/ImportEmployeeCsv"):
            case ("/RevisionRequest"):
            case ("/Requests"):
                !navigationMenus.includes("requests") && redirectUserToTheHomePage()
                break;
            case ("/CustomersDetail"):
            case ("/ViewCustomer"):
            case ("/AddCustomer"):
            case ("/ImportCustomerCsv"):
                !navigationMenus.includes("customers") && redirectUserToTheHomePage()
                break;
            case ("/PolicyOverview"):
            case ("/InsurerDetail"):
            case ("/ImportInsurerCsv"):
            case ("/Policies"):
                !navigationMenus.includes("policies") && redirectUserToTheHomePage()
                break;
            case ("/Settings"):
                !navigationMenus.includes("settings") && redirectUserToTheHomePage()
                break;
            case ("/Dashboard"):
                !navigationMenus.includes("dashboard") && redirectUserToTheHomePage()
                break;
            case ("/Analytics"):
                !navigationMenus.includes("analytics") && redirectUserToTheHomePage()
                break;
            case ("/Insurer"):
                !navigationMenus.includes("insurers") && redirectUserToTheHomePage()
                break;
            default:
                return "";
        }
    }
}
const redirectUserToTheHomePage = () => {
    window.localStorage.setItem('alertData', JSON.stringify({
        showAlert: true,
        alertType: "success",
        arabicMessage:"أنت شخص غير مصرح له بالدخول إلى هذه الصفحة",
        englishMessage:"You are unauthorized person to access this page!",
        page: "Dashboard"
    }))
    window.location.href = "/Dashboard"
}


export default UserPermission