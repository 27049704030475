import React from "react";

import {
    Button,
    Typography,
    OutlinedInput,
    Link,
} from "@material-ui/core";
import { Formik, useField } from 'formik'
import * as Yup from "yup";
import { Link as Redirect } from 'react-router-dom'
import ForgotPasswordController from '../../../blocks/forgot-password/src/ForgotPasswordController'
import { Alert } from '@material-ui/lab';
import { CancelRounded } from '@material-ui/icons';

const componentStyles = {
    centerContent: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "35px",
        paddingBottom: "10px"
    },
    textInputStyle: {
        marginTop: "10px"
    },
    displayFlex: {
        display: "flex"
    },
    alertIconStyleForEmail:{
        display: "flex",
        justifyContent: "start",
        "width": "70%" 
    },
    submitBtnStyle:{
        "background": "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "color": "white",
        "text-transform":"none",
        "width": "200px",
        "height": "50px",
        "borderRadius": "10px"
    }
}
const TextFieldControl = (props: any) => {
    const [field, meta] = useField(props);

    return (
        <OutlinedInput
            style={{
                direction: props.isArabicLenguage ? "rtl" : "ltr",
                marginTop: "10px",
                marginBottom: "10px"
            }}
            fullWidth
            type={props.type}
            {...field}
            {...props}
            error={meta.error && meta.touched}
        />
    );
};

export class ForgotPasswordForm extends ForgotPasswordController {
   
  render() {
    return (
        <div data-test-id="ForgotPasswordForm" dir={ this.state.isArabicLenguage === true ? "rtl" : "ltr"} >
        <Formik
            initialValues={{ accountType: "email_account", email: "" }}
            validationSchema={Yup.object().shape(this.forgotPasswordFormSchema(this.state.isArabicLenguage))}
            validateOnMount={true}
            validateOnChange={true}
            onSubmit={(values, actions) => {
                this.setShowPasswordRecovery(true, values.email);
                actions.setSubmitting(false);
            }}
        >
            {({
                handleChange,
                handleSubmit,
                errors,
                setFieldTouched,
                touched,
                values,
                resetForm
            }) => (
                <React.Fragment>
                    <div style={{ textAlign: "center" }}>
                        <div style={{ paddingTop: '25px' }} >
                            <Button
                                style={{
                                    color: !this.state.isArabicLenguage ? 'rgba(45, 111, 143, 1)' : 'black',
                                    textTransform: "none",
                                    textDecoration: !this.state.isArabicLenguage ? "underline" : "",
                                    fontWeight: !this.state.isArabicLenguage ? 'bolder' : 'normal',
                                    fontSize:"15px"
                                }}
                                onClick={() => {
                                    resetForm()
                                    this.setArabicLanguage(false)
                                }}>English</Button> |
                            <Button
                                style={{
                                    color: this.state.isArabicLenguage ? 'rgba(45, 111, 143, 1)' : 'black',
                                    fontWeight: this.state.isArabicLenguage ? 'bolder' : 'normal',
                                    fontSize:"15px"
                                }}
                                onClick={() => {
                                    resetForm()
                                    this.setArabicLanguage(true)
                                }}>العربي</Button>
                        </div>
                    </div>
                    {!this.state.showPasswordRecovery ? (
                        <React.Fragment>
                            <div style={{ textAlign: "center" }}>
                                <div style={{ paddingTop: '25px' }} >
                                    <Typography style={{ fontWeight: 600 }} variant="h5" component="h5">
                                        {this.state.isArabicLenguage ? this.ArabicLabelTextForForgotPassword : this.labelTextForForgotPassword}
                                    </Typography>
                                    <p style={{ color: "gray", fontSize: '12px' }} >{this.state.isArabicLenguage ? this.arabicSubTitle : this.subTitle}</p>
                                </div>
                            </div>
                            {this.state.emailNotValid ? (
                                <div style={{paddingTop:"10px"}} >
                                    <Alert style={componentStyles.alertIconStyleForEmail} icon={false} severity="error">
                                        {this.state.isArabicLenguage ? (
                                            <div style={componentStyles.displayFlex} >
                                                    <CancelRounded style={{marginTop:"-3px", verticalAlign:"bottom"}}/>
                                                    <Typography variant="body1">{this.ArabicEmailErrorMsg}</Typography>
                                            </div>
                                        ) : (
                                            <div style={componentStyles.displayFlex} >
                                                <CancelRounded style={{marginTop:"-3px", verticalAlign:"bottom"}}/>&nbsp; {this.emailErrorMsg}&nbsp;
                                            </div>
                                        )}
                                    </Alert>
                                </div>
                            ) : null}
                            <div style={{paddingTop:"40px"}} >
                                <label htmlFor="email" aria-controls="email" >  {this.state.isArabicLenguage ? this.ArabicTextEmail : this.emailTextBoxLabel} </label><br />
                                <TextFieldControl
                                    data-test-id="txtInputEmail"
                                    id={"txtInputEmail"}
                                    style={componentStyles.textInputStyle}
                                    isArabicLenguage={this.state.isArabicLenguage}
                                    name="email"
                                    type="email"
                                />
                                {touched.email && errors.email ? (
                                    <Typography color="error" >{errors.email}</Typography>
                                ) : null}
                            </div>
                            <div style={componentStyles.centerContent}>
                                <Button
                                    // @ts-ignore
                                    style={componentStyles.submitBtnStyle}
                                    data-test-id="btnGetOtpForEmailButton"
                                    id="btnGetOtpForEmailButton"
                                    title={this.buttonTextIsNext}
                                    color={this.buttonColorForNextButton}
                                    onClick={() => handleSubmit()}
                                >
                                    {this.state.isArabicLenguage ? this.ArabicButtonTextContinue : this.buttonTextContinue}
                                </Button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div>
                            <div style={{ textAlign: "center" }}>
                                <div style={{ paddingTop: '5px' }} >
                                    <Typography style={{ fontWeight: 600 }} variant="h5" component="h5">
                                        {this.state.isArabicLenguage ? this.ArabicLabelTextIsPasswordRecovery : this.labelTextIsPasswordRecovery}
                                    </Typography>
                                    <Typography style={{paddingTop:"20px"}} variant="h6" component="h6">
                                        {this.state.isArabicLenguage ? this.ArabicCheckMailText : this.checkMailText}
                                    </Typography>
                                    <p style={{ color: "gray", fontSize: '12px' }} >{this.state.isArabicLenguage ? this.ArabicPasswordRecovertSubTitle : this.passwordRecovertSubTitle}</p>
                                </div>
                            </div>
                            <div style={componentStyles.centerContent}>
                                <Button
                                    // @ts-ignore
                                    style={componentStyles.submitBtnStyle}
                                    id="btnGetOtpForEmailButton"
                                    title={this.buttonTextIsNext}
                                    color={this.buttonColorForNextButton}
                                    onClick={() => (this.sendForgotPasswordEmail(values.email))}
                                >
                                    {this.state.isArabicLenguage ? this.ArabicResendEmailText : this.resendEmailText}
                                </Button>
                            </div>
                            <div style={componentStyles.centerContent}>
                                <Redirect style={{
                                    textDecoration:"none"
                                }}  to="/EmailAccountLoginBlock">
                                    <Link underline="none" style={{textDecoration:"none",fontWeight:600}} href="#">{this.state.isArabicLenguage ? this.ArabicBackToLoginText : this.backToLoginText}</Link>
                                </Redirect>
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </Formik>
    </div>
    )
  }
}

export default ForgotPasswordForm