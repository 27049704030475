import React, { useEffect } from 'react';
import { Button, Grid, TextField } from "@material-ui/core";
import { GetApp, InsertDriveFile, Close } from "@material-ui/icons";
import { useField } from "formik";
import { makeStyles } from '@material-ui/styles';
import Toster from '../Common/Toster';
import { useTranslation } from "react-i18next";
import ActiveLanguage from './ActiveLanguage';

const useStyles = makeStyles({
    fileExistDesign: {
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid rgba(118, 118, 118, 0.5)",
        borderRadius: "5px",
        marginTop: "10px",
        height: "55px",
        padding: "15px"
    },
    fileNameStyle: {
        width: "80%"
    },
    uploadFileInput: {
        marginTop: "10px",
        boxShadow: 'none',
        border: "dashed lightgrey 2px",
        height: "55px",
        background: "transparent",
        width: "100%",
        textTransform: "none"
    },
    disabledUploadFileInput: {
        marginTop: "10px",
        boxShadow: 'none',
        border: "dashed lightgrey 2px",
        height: "55px",
        width: "100%",
        textTransform: "none",
        background: "hsl(0, 0%, 95%)",
        pointerEvents: "none"
    },
    downloaIcon: {
        cursor: "pointer"
    },
    textDesign: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        textTransform: "none"
    }
})

const DownloadFile = (props: any) => {
    const { t } = useTranslation();
    const [errorMsg, setErrorMsg] = React.useState<string>('')
    const [showToaster,setShowToaster] =  React.useState(false);
    const classes = useStyles();

    const downloadFile = async () => {
        try {
            const response = await fetch(props.filePath);
            const blob = await response.blob();
      
            // Create a link element
            const link = document.createElement('a');
      
            // Create a URL for the Blob
            const blobUrl = window.URL.createObjectURL(blob);
      
            // Set the download attribute to specify the file name
            link.href = blobUrl;
            link.download = 'downloaded_file';
      
            // Append the link to the document
            document.body.appendChild(link);
      
            // Trigger the click event on the link
            link.click();
      
            // Remove the link and revoke the Blob URL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
          } catch (error) {
            console.error('Error downloading the file:', error);
            setShowToaster(true);
            setErrorMsg(ActiveLanguage() ? "حدث خطأ ما في الملف ولم يتم تنزيله" : "Something went wrong file not download!")
          }
      
    }

    useEffect(()=> {
        setShowToaster(false);
    },[showToaster])

    return (
        <div>

            <Toster
                tosterText={errorMsg}
                showToster={showToaster}
                tosterType={'error'} // error, success, warning, info
                handleCloseToster={() => setShowToaster(false)}
            />

            {(props.fileName && props.filePath) ? (
                <Grid className={classes.fileExistDesign}>
                    <Button className={classes.fileNameStyle} component="label" >
                        <Grid item>
                            <InsertDriveFile />
                        </Grid>
                        <Grid className={classes.textDesign} item>
                            {props.fileName}
                        </Grid>
                    </Button>
                    {!props.disabled ? (<Grid item>
                        <GetApp className={classes.downloaIcon} onClick={(e) => {
                            e.stopPropagation();
                            downloadFile()
                        }} />
                    </Grid>) : (<Grid item>
                        <GetApp className={classes.downloaIcon} onClick={(e) => {
                            e.stopPropagation();
                            downloadFile()
                        }} />
                    </Grid>)}

                </Grid>
            ) : (
                <Button className={props.disabled ? classes.disabledUploadFileInput : classes.uploadFileInput} component="label" disabled={props.disabled} >
                    {t('upload_file_text')}
                </Button >
            )}
        </div>

    )
}

export default DownloadFile