// Customizable Area Start
import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, createStyles, withStyles, Grid } from '@material-ui/core'
import LabelWithIcon from '../LabelWithIcon';
import TextInput from '../TextInput';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';

type RequestEditDialog = {
    openDialog: boolean,
    t?: any,
    classes?: any,
    headingText: string,
    editFor?: string,
    handleCancel?: any,
    isArabic?: boolean,
    selectedData?: any,
    handleEditedData: (data: any) => void
}

export class EditDialog extends React.Component<RequestEditDialog> {
    formikRef: any;

    constructor(props: RequestEditDialog) {
        super(props);
        this.formikRef = React.createRef();
    }
    
    render() {
        const {
            t,
            classes,
            openDialog,
            handleCancel,
            headingText,
            isArabic,
            editFor,
            selectedData,
            handleEditedData
        } = this.props;

        const getInitialValue = () => {
            let stageValue: any = {};
            if (editFor === "Vehicle" || editFor === "Motor") {
                stageValue = {
                    serial_number: selectedData?.attributes?.serial_number,
                    make: selectedData?.attributes?.make,
                    model: selectedData?.attributes?.model,
                    type_of_body: selectedData?.attributes?.type_of_body,
                    plate_type: selectedData?.attributes?.plate_type,
                    number_of_seats: selectedData?.attributes?.number_of_seats,
                    year_make: selectedData?.attributes?.year_make,
                    chassis_number: selectedData?.attributes?.chassis_number,
                    registered_number: selectedData?.attributes?.registered_number,
                    vehicle_owner: selectedData?.attributes?.vehicle_owner,
                    sequence_number: selectedData?.attributes?.sequence_number,
                    mobile_number: selectedData?.attributes?.mobile_number,
                    owner_id: selectedData?.attributes?.owner_id,
                    hijri_date_of_istamarah: selectedData?.attributes?.hijri_date_of_istamarah,
                    color: selectedData?.attributes?.color,
                    cover: selectedData?.attributes?.cover,
                    value: selectedData?.attributes?.value,
                }
            }
            else {
                stageValue = {
                    employee_id: selectedData?.attributes?.employee_id,
                    member_name: selectedData?.attributes?.member_name,
                    gender: selectedData?.attributes?.gender,
                    marital_status: selectedData?.attributes?.marital_status,
                    nationality: selectedData?.attributes?.nationality,
                    relation: selectedData?.attributes?.relation,
                    date_of_birth: selectedData?.attributes?.date_of_birth,
                    sponsor_id: selectedData?.attributes?.sponsor_id,
                    class: selectedData?.attributes?.class,
                    mobile_number: selectedData?.attributes?.mobile_number,
                    employee_category: selectedData?.attributes?.employee_category,
                    occupation: selectedData?.attributes?.occupation,
                }
            }
            return stageValue
        }

        const getUpdatedValues = (values: any) => {
          const updatedObject = {
            ...selectedData, // Spread the original object
            attributes: {
              ...selectedData.attributes, // Spread the attributes object
              ...values,
            }
          };

          handleEditedData(updatedObject)
        }

        return (
            <>
                <Dialog
                    open={openDialog}
                    onClose={handleCancel}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="lg"
                    className={classes.dialogWrapper}
                >
                    <DialogTitle id="alert-dialog-title">
                        <div className={classes.dialogTitle}>
                            <div>
                                <span className={classes.headingStyle}>{headingText}</span>
                            </div>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Formik
                            innerRef={this.formikRef}
                            enableReinitialize={true}
                            initialValues={getInitialValue()}
                            validateOnMount={true}
                            validateOnBlur={true}
                            onSubmit={(values) => {
                                getUpdatedValues(values) }}
                        >
                            {({
                                handleSubmit
                            }) => (
                                <React.Fragment>
                                    {(editFor === "Vehicle" || editFor === "Motor") && <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('serial_num')} />
                                                <TextInput
                                                    id={"serial_number"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="serial_number"
                                                    type="number"
                                                    data-test-id="serial_number"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('make')} />
                                                <TextInput
                                                    id={"make"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="make"
                                                    type="text"
                                                    data-test-id="make"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('model')} />
                                                <TextInput
                                                    id={"model"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="model"
                                                    type="text"
                                                    data-test-id="model"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('type_body')} />
                                                <TextInput
                                                    id={"type_of_body"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="type_of_body"
                                                    type="text"
                                                    data-test-id="type_of_body"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('plate_type')} />
                                                <TextInput
                                                    id={"plate_type"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="plate_type"
                                                    type="number"
                                                    data-test-id="plate_type"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('seat_num')} />
                                                <TextInput
                                                    id={"number_of_seats"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="number_of_seats"
                                                    type="number"
                                                    data-test-id="number_of_seats"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('year_make')} />
                                                <TextInput
                                                    id={"year_make"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="year_make"
                                                    type="number"
                                                    data-test-id="year_make"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('chasis_num')} />
                                                <TextInput
                                                    id={"chassis_number"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="chassis_number"
                                                    type="text"
                                                    data-test-id="chassis_number"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('regd_num')} />
                                                <TextInput
                                                    id={"registered_number"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="registered_number"
                                                    type="text"
                                                    data-test-id="registered_number"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('vehicle_owner')} />
                                                <TextInput
                                                    id={"vehicle_owner"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="vehicle_owner"
                                                    type="text"
                                                    data-test-id="vehicle_owner"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('sequence_num')} />
                                                <TextInput
                                                    id={"sequence_number"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="sequence_number"
                                                    type="text"
                                                    data-test-id="sequence_number"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('mobile_num')} />
                                                <TextInput
                                                    id={"mobile_number"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="mobile_number"
                                                    type="number"
                                                    data-test-id="mobile_number"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('owner_id')} />
                                                <TextInput
                                                    id={"owner_id"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="owner_id"
                                                    type="number"
                                                    data-test-id="owner_id"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('hijri_date')} />
                                                <TextInput
                                                    id={"hijri_date_of_istamarah"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="hijri_date_of_istamarah"
                                                    type="text"
                                                    data-test-id="hijri_date_of_istamarah"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('color')} />
                                                <TextInput
                                                    id={"color"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="color"
                                                    type="text"
                                                    data-test-id="color"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('cover')} />
                                                <TextInput
                                                    id={"cover"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="cover"
                                                    type="text"
                                                    data-test-id="cover"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('sum_insured')} />
                                                <TextInput
                                                    id={"value"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="value"
                                                    type="number"
                                                    data-test-id="value"
                                                />
                                            </Grid>
                                        </Grid>
                                    </>}
                                    {(editFor === "Medical" || editFor === "Life") && <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('employee_id')} />
                                                <TextInput
                                                    id={"employee_id"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="employee_id"
                                                    type="text"
                                                    data-test-id="employee_id"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('member_name')} />
                                                <TextInput
                                                    id={"member_name"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="member_name"
                                                    type="text"
                                                    data-test-id="member_name"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('employee_gender')} />
                                                <TextInput
                                                    id={"gender"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="gender"
                                                    type="text"
                                                    data-test-id="gender"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('marital_status')} />
                                                <TextInput
                                                    id={"marital_status"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="marital_status"
                                                    type="text"
                                                    data-test-id="marital_status"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('employee_nation')} />
                                                <TextInput
                                                    id={"nationality"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="nationality"
                                                    type="text"
                                                    data-test-id="nationality"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('relation')} />
                                                <TextInput
                                                    id={"relation"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="relation"
                                                    type="text"
                                                    data-test-id="relation"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('employee_dob')} />
                                                <TextInput
                                                    id={"date_of_birth"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="date_of_birth"
                                                    type="text"
                                                    data-test-id="date_of_birth"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('sponsor_ID')} />
                                                <TextInput
                                                    id={"sponsor_id"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="sponsor_id"
                                                    type="number"
                                                    data-test-id="sponsor_id"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('class')} />
                                                <TextInput
                                                    id={"class"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="class"
                                                    type="text"
                                                    data-test-id="class"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('mobile_num')} />
                                                <TextInput
                                                    id={"mobile_number"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="mobile_number"
                                                    type="number"
                                                    data-test-id="mobile_number"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('employee_category')} />
                                                <TextInput
                                                    id={"employee_category"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="employee_category"
                                                    type="text"
                                                    data-test-id="employee_category"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <LabelWithIcon label={t('occupation')} />
                                                <TextInput
                                                    id={"occupation"}
                                                    className={classes.textInputStyle}
                                                    isArabicLenguage={isArabic}
                                                    name="occupation"
                                                    type="text"
                                                    data-test-id="occupation"
                                                />
                                            </Grid>
                                        </Grid>
                                    </>}
                                </React.Fragment>
                            )}
                        </Formik>
                    </DialogContent>
                    {<DialogActions className={classes.btnWrapper}>
                        <Button variant="outlined"
                            className={classes.cancelBtnStyle}
                            onClick={handleCancel}>
                            {t('cancel')}
                        </Button>
                        <Button variant="contained"
                            style={{ borderRadius: "8px" }}
                            className={classes.primaryBtnStyle}
                            onClick={() => {this.formikRef.current.handleSubmit()}} color="primary" autoFocus
                        >
                            {t('confirm')}
                        </Button>
                    </DialogActions>}
                </Dialog>
            </>
        )
    }

}

const useStyles = createStyles({
    primaryBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        color: "white",
        height: "50px",
        width: "150px",
        borderRadius: "10px",
    },
    cancelBtnStyle: {
        color: "#757575",
        height: "50px",
        width: "150px",
        borderRadius: "10px"
    },
    btnWrapper: {
        marginRight: "33px",
        marginBottom: "5px"
    },
});

export default withStyles(useStyles)(withTranslation()(EditDialog));
// Customizable Area End
