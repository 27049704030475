
import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    OutlinedInput,
    Radio,
    RadioGroup,
    Tooltip,
    Typography
} from "@material-ui/core";
import { ErrorMessage, FieldArray, Form, Formik, useField } from "formik";
import * as Yup from "yup";
import { ExpandMore, CloseSharp, Close, CheckCircle, Error, ErrorOutlineRounded } from '@material-ui/icons'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import StageFourFormSchema from './StageFourFormSchema';
// @ts-ignore
import { withTranslation } from 'react-i18next';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import StageFourController, { Props } from "../../../blocks/TaskAllocator/src/PolicyStage/StageFourController";
import LabelWithIcon from "../Common/LabelWithIcon";
import TextInput from "../Common/TextInput";
import FileUpload from "../Common/FileUpload";
import CustomButton from "../Common/CustomButton";
import RejectRequestDialog from "../Common/RejectRequestDialog.web";
import HandOverDialog from "../Common/HandOverDialog.web";
import Toster from "../Common/Toster";
import ActiveLanguage from "../Common/ActiveLanguage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

const enumYesNO = {
    YES: "yes",
    NO: "no",
}
const enumBtnType = {
    NEXT: 'NEXT',
    DRAFT: 'DRAFT',
}


export class StageFourForm extends StageFourController {
    formikRef: any;
    constructor(props: Props) {
        super(props);
        this.formikRef = React.createRef();  

    }
    render() {

        // @ts-ignore

        const { t, classes } = this.props;
        const { isEdit,isExpanded } = this.props;
        const stageThreeData = JSON.parse(
            localStorage.getItem("stageThreeData") ?? "{}"
          );
        const stageFourData = JSON.parse(window.localStorage.getItem('stageFourData') || '{}')
        
        const hasError = (index: number,errors:any) => {
            return (
                errors?.insurerDetails?.[index] && Object.keys(errors.insurerDetails[index]).length > 0
            );
        };
        return (
            <ThemeProvider theme={theme} >
                <React.Fragment>
                    <Toster
                        tosterText={this.state.alertMessage}
                        showToster={this.state.showAlert}
                        // @ts-ignore
                        tosterType={this.state.alertType}
                        handleCloseToster={() => this.setState({ showAlert: false })}
                    />
                    <div className={classes.accordianGap}>

                        <Grid>
                            <Formik
                                // enableReinitialize={isEdit ? true : false}
                                // innerRef={this.formikRef}

                                innerRef={(element) => {
                                    
                                    this.formRef.current = element;
                                }}
                                enableReinitialize
                                initialValues={{
                                    insurerDetails: this.state.insurerDetail,
                                    premiumCalculation: this.state.premiumCalculation,
                                    comparisionSheet: this.state.comparisionSheet,
                                }}
                                validationSchema={Yup.object().shape(StageFourFormSchema(ActiveLanguage()))}
                                validateOnMount={true}
                                validateOnBlur={true}
                                onSubmit={async (values, actions) => {
                                    const stageThreeData = JSON.parse(localStorage.getItem('stageThreeData') || '{}');

                                    if (stageThreeData?.attributes?.is_next_stage_submitted) {
                                        this.updateCustomerForm(values);
                                    } else {
                                        this.submitCustomerForm(values);
                                    }
                                }}
                            >
                                {({
                                    handleSubmit,
                                    errors,
                                    touched,
                                    values,
                                    setFieldValue,
                                    handleChange,
                                    validateForm
                                }) => (
                                    // <form className={classes.accordionWrapper} onSubmit={handleSubmit}>
                                    <React.Fragment>
                                        <Grid className={classes.customerDetailsAccordion} item>
                                            <Accordion data-test-id='p4' className={((this.state.errorSection === 'p4') && classes.errorSection) ? classes.errorSection : classes.accordionStyle} expanded={this.isP4Expanded(this.props.isExpanded)} disabled={this.state.accordianDisable} onChange={(event, isExpanded) => {
                                                this.handleAccordian(isExpanded, "p4")
                                            }} >
                                                <AccordionSummary
                                                    aria-expanded={true}
                                                    expandIcon={<ExpandMore />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <div className={classes.toggleContainer}>
                                                        <Typography style={useStyles.accordianHeading} >{t('submitting_quotation')}</Typography>
                                                        <div className={classes.iconAlignment}>
                                                         {this.state.expandedAccordion && <DescriptionOutlinedIcon onClick={this.openNoteDialog} />}
                                                         {this.state.expandedAccordion && stageFourData?.attributes?.is_reject &&
                                                            <ErrorOutlineRounded style={{position: 'relative', left: '10px'}} onClick={() => this.setState({isBtnVisible: false, showRejectDialog: true})} />
                                                         }
                                                        </div>
                                                    </div>
                                                </AccordionSummary>
                                                {/* @ts-ignore */}
                                                <FieldArray name="insurerDetails">
                                                    {() => (
                                                        <React.Fragment>
                                                            {values.insurerDetails.length > 0 && 
                                                                values.insurerDetails.map((item: any, index: number) => (

                                                                    <React.Fragment key={index}>
                                                                        <Accordion 
                                                                            elevation={0} 
                                                                            className={`${classes.contactsDetail} `}
                                                                        >
                                                                            <AccordionSummary
                                                                                aria-expanded={true}
                                                                                expandIcon={<ExpandMore />}
                                                                                aria-controls="panel1a-content"
                                                                                id="panel1a-header">
                                                                                <Typography className={classes.accordianHeading}>
                                                                                    {item.label}
                                                                                </Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <Grid container spacing={2} >
                                                                                    <Grid item xs={4}>
                                                                                        <LabelWithIcon label={t('quotation_received')} />
                                                                                        <FormControl
                                                                                            disabled={this.state.view || !this.props.isExpanded ? true : false}
                                                                                        >
                                                                                            <RadioGroup row aria-labelledby="demo-row-radiobutton-group-label" name={`insurerDetail[${index}].quotationReceived`}
                                                                                                defaultValue={item.quotationReceived ? enumYesNO.YES : enumYesNO.NO}
                                                                                                value={item.quotationReceived0 ? enumYesNO.YES : enumYesNO.NO}
                                                                                                onChange={(event: any) => setFieldValue(`insurerDetails[${index}].quotationReceived`, event.target.value)}
                                                                                            >
                                                                                                <FormControlLabel checked={item.quotationReceived === true || item.quotationReceived === "yes"} value={enumYesNO.YES} control={<Radio color="primary" />} label={t("yes")} />
                                                                                                <FormControlLabel checked={item.quotationReceived === false || item.quotationReceived === "no"} value={enumYesNO.NO} control={<Radio color="primary" />} label={t("no")} />
                                                                                            </RadioGroup>
                                                                                        </FormControl>
                                                                                        {/* @ts-ignore */}
                                                                                        <ErrorMessage
                                                                                            name={`insurerDetail[${index}].quotationReceived`}
                                                                                            className="field-error"
                                                                                            render={(msg: string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                        />
                                                                                    </Grid>

                                                                                </Grid>


                                                                            </AccordionDetails>
                                                                            <AccordionDetails>
                                                                                <Grid container spacing={2} >
                                                                                    <Grid item xs={4} >
                                                                                        <LabelWithIcon label={t('quotation_id')} />
                                                                                        <TextInput
                                                                                            id={`insurerDetails[${index}].quotationId`}
                                                                                            className={classes.textInputStyle}
                                                                                            isArabicLanguage={this.state.isArabicLanguage}
                                                                                            name={`insurerDetails[${index}].quotationId`}
                                                                                            type="text"
                                                                                            value={item.quotationId}
                                                                                            disabled={this.state.view || !this.props.isExpanded || item.quotationReceived == "no" || !item.quotationReceived ? true : false}
                                                                                            data-test-id={`insurerDetail[${index}].quotationId`}
                                                                                        />
                                                                                        {/* @ts-ignore */}
                                                                                        <ErrorMessage
                                                                                            name={`insurerDetails[${index}].quotationId`}
                                                                                            className="field-error"
                                                                                            render={(msg: string) => <div>
                                                                                                <Typography color="error" >{msg}</Typography></div>}
                                                                                        />
                                                                                    </Grid>

                                                                                    <Grid item xs={4}>
                                                                                        <LabelWithIcon label={t('dateOfReceiving')} />
                                                                                        <TextFieldControl
                                                                                            data-test-id={`insurerDetails[${index}].dateofReceiving`}
                                                                                            id={`insurerDetails[${index}].dateofReceiving`}
                                                                                            className={classes.textInputStyle}
                                                                                            isArabicaenguage={this.state.isArabicLanguage}
                                                                                            name={`insurerDetails[${index}].dateofReceiving`}
                                                                                            type={"date"}
                                                                                            value={item?.dateofReceiving}
                                                                                            disabled={this.state.view || !this.props.isExpanded || item.quotationReceived == "no" || !item.quotationReceived ? true : false}
                                                                                            onChange={handleChange}
                                                                                        />
                                                                                        {/* @ts-ignore */}
                                                                                        <ErrorMessage
                                                                                            name={`insurerDetails[${index}].dateofReceiving`}
                                                                                            className="field-error"
                                                                                            render={(msg: string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>

                                                                            </AccordionDetails>
                                                                            <AccordionDetails>
                                                                                <Grid container spacing={2} >
                                                                                    <Grid item xs={4}>
                                                                                        <LabelWithIcon label={t('quotation_amount')} />
                                                                                        <TextInput
                                                                                            id={`insurerDetails[${index}].quotationAmount`}
                                                                                            className={classes.textInputStyle}
                                                                                            isArabicLanguage={this.state.isArabicLanguage}
                                                                                            name={`insurerDetails[${index}].quotationAmount`}
                                                                                            type={this.state.view ? "text" : "number"}
                                                                                            value={item.quotationAmount}
                                                                                            startAdornment={
                                                                                                <InputAdornment position="start">
                                                                                                    <div> &nbsp;SAR&nbsp;</div>
                                                                                                </InputAdornment>
                                                                                            }
                                                                                            // disabled={item.quotationReceived=="no"||!item.quotationReceived||this.state.role!=enumRoles.Operations ? true : false}
                                                                                            disabled={this.state.view || !this.props.isExpanded || item.quotationReceived == "no" || !item.quotationReceived ? true : false}
                                                                                            // disabled={!this.state.enableEditing}
                                                                                            data-test-id={`insurerDetails[${index}].quotationAmount`}

                                                                                        />
                                                                                        {/* @ts-ignore */}
                                                                                        <ErrorMessage
                                                                                            name={`insurerDetails[${index}].quotationAmount`}
                                                                                            className="field-error"
                                                                                            render={(msg: string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <LabelWithIcon label={t('proposal')} />
                                                                                        <FileUpload
                                                                                            fileName={item.proposal_file_name || ""}
                                                                                            filePath={item?.proposal?.url || ""}
                                                                                            data-test-id={`insurerDetails[${index}].proposal`}
                                                                                            // disabled={!this.state.enableEditing}
                                                                                            disabled={this.state.view || !this.props.isExpanded || item.quotationReceived == "no" || !item.quotationReceived ? true : false}
                                                                                            // disabled={item.quotationReceived=="no"||!item.quotationReceived||this.state.role!=enumRoles.Operations ? true : false}
                                                                                            inputName={`insurerDetails[${index}].proposal`}
                                                                                            onChange={async(e: any) => {
                                                                                                let res: any = this.uploadFileStageFour("proposal", e.target.files[0], item.insurance_id,this.formRef.current,`insurerDetails[${index}].proposal`);
                                                                                                if (res) {
                                                                                                    setFieldValue(`insurerDetails[${index}].proposal`, e.target.files[0])
                                                                                                }
                                                                                            }}
                                                                                            onRemove={async () => {
                                                                                                if (!this.props.isExpanded) {
                                                                                                    return
                                                                                                }
                                                                                                else {
                                                                                                    setFieldValue(`insurerDetails[${index}].proposal`, {})
                                                                                                    this.setState({premiumCalculation:values.premiumCalculation,comparisionSheet:values.comparisionSheet,insurerDetail:values.insurerDetails})
                                                                                                    await this.removeFile(values.insurerDetails[index]?.proposal?.id||item.proposal.id, this.formRef.current,`insurerDetails[${index}].proposal`)
                                                                                                    
                                                                                                }
                                                                                            }
                                                                                            }
                                                                                        />
                                                                                        {/* @ts-ignore */}
                                                                                        <ErrorMessage
                                                                                            name={`insurerDetails[${index}].proposal`}
                                                                                            className="field-error"
                                                                                            render={(msg: string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>

                                                                            </AccordionDetails>
                                                                            <AccordionDetails>
                                                                                <Grid container spacing={2} >
                                                                                    <Grid item xs={12}>
                                                                                        <LabelWithIcon label={t('comment')} />
                                                                                        <TextInput
                                                                                            id={`insurerDetails[${index}].comment`}
                                                                                            className={classes.textInputStyle}
                                                                                            isArabicLanguage={this.state.isArabicLanguage}
                                                                                            name={`insurerDetails[${index}].comment`}
                                                                                            type="text"
                                                                                            fullWidth
                                                                                            multiline={true}
                                                                                            minRows={4}
                                                                                            value={item.comment}
                                                                                            disabled={this.state.view || !this.props.isExpanded ? true:false}
                                                                                            // disabled={!this.state.enableEditing}
                                                                                            data-test-id={`insurerDetails[${index}].comment`}
                                                                                        />
                                                                                        {/* @ts-ignore */}
                                                                                        <ErrorMessage
                                                                                            name={`insurerDetails[${index}].comment`}
                                                                                            className="field-error"
                                                                                            render={(msg: string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                                        />
                                                                                    </Grid>


                                                                                </Grid>

                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </React.Fragment>
                                                                ))
                                                            }

                                                        </React.Fragment>
                                                    )}
                                                </FieldArray>
                                                    <AccordionDetails className={classes.additionalStyle}>
                                                        <Grid container spacing={2} >
                                                            <Grid item xs={4}>
                                                                <LabelWithIcon label={t('premium_calculaton')} />
                                                                <FileUpload
                                                                    fileName={this.state.premiumCalculationFile || ""}
                                                                    filePath={values.premiumCalculation.url || ""}
                                                                    data-test-id="txtInputpremiumCalculation"
                                                                    // disabled={!this.state.enableEditing}
                                                                    disabled={this.state.view || !this.props.isExpanded ? true : false}
                                                                    inputName={`premiumCalculation`}
                                                                    onChange={(e: any) => {
                                                                        let res: any = this.uploadFileStageFour(`premium_calculation`, e.target.files[0], this.state.policyId,this.formRef.current,"premiumCalculation")
                                                                        if (res) {
                                                                            setFieldValue(`premiumCalculation`, e.target.files[0])
                                                                        }
                                                                    }}
                                                                    onRemove={async () => {
                                                                        if (!this.props.isExpanded) {
                                                                            return
                                                                        }
                                                                        else {
                                                                            setFieldValue(`premiumCalculation`, {})
                                                                            this.setState({premiumCalculation:values.premiumCalculation,comparisionSheet:values.comparisionSheet,insurerDetail:values.insurerDetails})
                                                                            await this.removeFile(values.premiumCalculation.id, this.formRef.current,`premiumCalculation`)
                                                                        }
                                                                    }
                                                                    }
                                                                />
                                                                {/* @ts-ignore */}
                                                                <ErrorMessage
                                                                    name={`premiumCalculation`}
                                                                    className="field-error"
                                                                    render={(msg: string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <LabelWithIcon label={t('comparision_sheet')} />
                                                                <FileUpload
                                                                    fileName={this.state.comparisionSheetFile || ""}
                                                                    filePath={values.comparisionSheet.url || ""}
                                                                    data-test-id="txtInputcomparisionSheet"
                                                                    // disabled={!this.state.enableEditing}
                                                                    disabled={this.state.view || !this.props.isExpanded ? true : false}
                                                                    inputName={`comparisionSheet`}
                                                                    onChange={(e: any) => {
                                                                        let res: any = this.uploadFileStageFour(`comparison_sheet`, e.target.files[0], this.state.policyId,this.formRef.current,"comparisionSheet")
                                                                        if (res) {
                                                                            setFieldValue(`comparisionSheet`, e.target.files[0])
                                                                        }
                                                                    }}
                                                                    onRemove={async () => {
                                                                        if (!this.props.isExpanded) {
                                                                            return
                                                                        }
                                                                        else {
                                                                            setFieldValue(`comparisionSheet`, {})
                                                                            this.setState({premiumCalculation:values.premiumCalculation,comparisionSheet:values.comparisionSheet,insurerDetail:values.insurerDetails})


                                                                            await this.removeFile(values.comparisionSheet.id, this.formRef.current,`comparisionSheet`)
                                                                        }
                                                                    }
                                                                    }
                                                                />
                                                                {/* @ts-ignore */}
                                                                <ErrorMessage
                                                                    name={`comparisionSheet`}
                                                                    className="field-error"
                                                                    render={(msg: string) => <div><Typography color="error" >{msg}</Typography></div>}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>


                                            </Accordion>
                                        </Grid>

                                        {(this.state.expandedAccordion  &&  !this.state.view && this.state.role) && this.props.isExpanded && (
                                            <div className={classes.BtnGroup}>
                                                <div>
                                                
                                                </div>
                                                <div className={classes.buttonsRightWrapper}>
                                                    <CustomButton
                                                        btnText={t('save_draft')}
                                                        onClick={() => this.handleDraftSubmit(enumBtnType.DRAFT, stageFourData?.id)}
                                                        bgColor='white'
                                                        textColor='#0090a1'
                                                    />
                                                    <CustomButton
                                                        btnText={t('reject')}
                                                        onClick={() => { this.handleReject() }}
                                                        bgColor='white'
                                                        textColor='#0090a1'
                                                    />
                                                    <CustomButton
                                                        btnText={t('next_stage')}
                                                        onClick={() => {
                                                            handleSubmit();
                                                            this.checkStageFourForm(errors)
                                                        }}
                                                        bgColor="linear-gradient(to bottom, #2d6f8f, #4fb7ba)"
                                                        textColor='#fff'
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}

                            </Formik>
                        </Grid>
                    </div>
                    <RejectRequestDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showRejectDialog}
                        textId={'rejectReason'}
                        pushBackOptions={this.getPoicyStageOption()}
                        setSelectedPushBackTo={(e: any) => {
                            this.setState({ rejectedStage: e.value })
                        }}
                        inputValue={this.state.isBtnVisible ? this.state.rejectReason : stageFourData?.attributes?.rejected_reason}
                        headingText={t('rejecting')}
                        handleInputText={(value: string) => this.handleChangeRejectReason(value)}
                        handleOk={() => this.changePolicyStep(this.state.rejectedStage, "reject_stage")}
                        handleCancel={() => this.handleRejectDialog(false)}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                        labelText={t('push_back')}
                        reasonLabel={t('reason')}
                    />
                    <HandOverDialog
                        btnCancelText={t('cancel')}
                        btnOkText={t('confirm')}
                        openDialog={this.state.showNoteDialog as boolean}
                        textId={'handOverNoteText'}
                        headingText={t('handover_note')}
                        subHeading={this.state.isBtnVisible?t('stage4_subheading'):`(${t('awaiting')} > ${t('submitting_quotation')})`}
                        handleInputText={this.handleHandOverNoteText}
                        inputLable={this.state.showNoteDialog ? t('note') : ""}
                        inputValue={this.state.isBtnVisible ? this.state.handOverNoteText : stageThreeData.attributes?.handover_note_operations_review}
                        handleOk={(value: string) => this.handOverNoteSubmit(value)}
                        handleCancel={this.closeNoteDialog}
                        showDialogBtns={this.state.isBtnVisible}
                        showCrossBtn={true}
                        inputDisable={!this.state.isBtnVisible}
                    />


                  
                </React.Fragment>
            </ThemeProvider >
        )
    }
}
const useStyles = ({
    saveBtnDiv: {
        marginTop: "28px"
    },
    accordianGap: {
        padding: "10px 0"
    },
    contactsDetail: {
        "width": "95%",
        "margin": "2.5% !important",
        "border": "1px solid lightgray",
        "border-radius":"10px !important"
        // borderRadius: "10px"
    },
    errorAccordion: {
        "border": "1px solid red",
    },
    insuranceTypeStyle: {
        "padding-bottom": "0px !important"
    },
    accordionStyle: {
        background: "white !important",
        "border-radius":"10px !important",       
    },
    additionalStyle: {
        display: "flex",
        padding: "8px 30px 16px",
    },
    BtnGroup: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 15px'
    },
    buttonsRightWrapper: {
        display: 'flex',
        gap: '15px',
        padding: '15px 0px',
        justifyContent: 'flex-end'
    },
    saveBtnStyle: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "height": "55px",
        "width": "150px",
        borderRadius: "10px"
    },
    displayFlexEnd: { display: "flex", justifyContent: "end" },
    cancelBtn: {
        marginRight: "10px",
        marginLeft: "10px",
        background: "white",
        borderRadius: "10px",
        "text-transform": "none",
        "height": "50px",
        "width": "150px",
    },
    errorSection: {
        border: "1px solid red"
    },
    accordianHeading: {
        fontWeight: 600
    },
    displayFlex: {
        display: "flex",
    },
    contactForm: { width: "100%" },
    textInputStyle: {
        marginTop: "10px",
        marginBottom: "10px"
    },
    divierStyle: {
        marginBottom: "10px",
        fontWeight: 500
    },
    noteMessage: {
        padding: "0px 8px",
        fontSize: "12px"
    },
    radioStyle: {
        display: "flex",
        height: "55px",
        paddingTop: "25px"
    },
    redAlertMessage: {
        backgroundColor: "#ec5655",
        color: "white",
        width: 'auto',
        height: "55px"
    },
    toggleContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        '& svg': {
            color: '#0090a1'
        }
    },
iconAlignment: {
    display: 'flex',
    marginRight: "10px"
},
lostIcon: {
    marginLeft: "15px",
        '& svg': {
        color: '#0090a1'
    },
},
    greenAlertMessage: {
        backgroundColor: "#028f5d",
        color: "white",
        width: 'auto',
        height: "55px"
    },
    alertCloseIconLeft: {
        color: "white",
        justifyContent: "end",
        cursor: "pointer",
        marginLeft: "20px"
    },
    alertCloseIconRight: {
        color: "white",
        justifyContent: "end",
        cursor: "pointer",
        marginRight: "20px"
    },
    alertIconStyle: {
        color: "white"
    },
    alertDivWithSearch: {
        display: "flex",
        "justify-content": "space-between",
    },
    alertDiv: {
        "display": "flex",
        "justify-content": "end",
        "padding-bottom": "15px",
    },
    alertCloseIcon: {
        color: "white",
        cursor: "pointer",
        paddingLeft: "10px"
    }
});
const TextFieldControl = (props: any) => {
    const [field, meta] = useField(props);

    return (
        <OutlinedInput
            style={{
                direction: props.isArabicLenguage ? "rtl" : "ltr",
                marginTop: "10px",
                marginBottom: "10px"
            }}
            fullWidth
            type={props.type}
            {...field}
            {...props}
            error={meta.error && meta.touched}
        />
    );
};

export default withStyles(useStyles)(withTranslation()(StageFourForm))