import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  customerEmail: string;
  showAlertMessage: boolean;
  alertType: string;
  alertMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  resetPasswordResponse: string = "";
  getCustomerEmailResponse: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      customerEmail: "",
      showAlertMessage: false,
      alertType: "",
      alertMessage: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        apiRequestCallId === this.getCustomerEmailResponse &&
        responseJson !== undefined
      ) {
        if (!responseJson?.errors) {
          this.setState({
            customerEmail: responseJson.data.attributes.email,
          });
        } else {
          this.setState({
            showAlertMessage: true,
            alertType: "error",
            alertMessage: responseJson?.errors[0]?.token,
          });
          setTimeout(() => {
            this.setState({
              showAlertMessage: false,
              alertType: "",
              alertMessage: "",
            });
          }, 10000);
        }
      }
      if (
        apiRequestCallId === this.resetPasswordResponse &&
        responseJson !== undefined
      ) {
        if (!responseJson?.errors) {
          this.setState({
            showAlertMessage: true,
            alertType: "success",
          });
          setTimeout(() => {
            this.setState({
              showAlertMessage: false,
              alertType: "",
            });
          }, 10000);
        } else {
          this.setState({
            showAlertMessage: true,
            alertType: "error",
            alertMessage: responseJson?.errors[0]?.otp,
          });
          setTimeout(() => {
            this.setState({
              showAlertMessage: false,
              alertType: "",
              alertMessage: "",
            });
          }, 10000);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getCustomerEmail();
  }

  getCustomerEmail = async () => {
    this.getCustomerEmailResponse = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: "/account_block/accounts/logged_user",
    });
  };

  resetPassword = async (email: string) => {
    this.resetPasswordResponse = await this.apiCall({
      contentType: "application/json",
      method: "POST",
      endPoint: "bx_block_forgot_password/send_email",
      body: {
        data: {
          email,
        },
        email,
      },
    });
  };

  handleResetPassword = () => {
    this.resetPassword(this.state.customerEmail);
  };

  apiCall = async (data: {
    contentType: string;
    method: string;
    endPoint: string;
    body?: {
      data: {
        email: string;
      };
      email: string;
    };
  }) => {
    const { contentType, method, endPoint, body } = data;
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token;
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestCallMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestCallMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestCallMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestCallMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    requestCallMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestCallMessage.id, requestCallMessage);
    return requestCallMessage.messageId;
  };

  // Customizable Area End
}
