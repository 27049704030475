// Customizable Area Start
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import getStageNameByNumber, { getStageNumberByName } from "../../../../../../components/src/Common/PolicyRequest/StagesName";
import * as Yup from "yup";
import ActiveLanguage from "../../../../../../components/src/Common/ActiveLanguage";

// Customizable Area End

export interface Props {
    // Customizable Area Start
		formikRef?: any;
		navigation?: any;
		isExpanded?: boolean;
		isEdit?: boolean;
		onNext?: any;
		onReject?: any;
		t: (value: string) => string;
		policyId?: number | string;
		setStageData?: any;
		rrStageOneData?: any;
		rrStageSevenData?: any;
		rrStageNineData?: any;
		handoverNote?: any;
		revisionRequestID?: any;
		isInstallment?: boolean
		installmentIndex?: number;
		classes?: any;
		values?: any;
		currentFlow?: number;
		setFieldValue?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
		loading: boolean;
		flow: number;
		policyId: any;
		expandedAccordion: string;
		showRejectDialog: boolean;
		isArabicLenguage: boolean;
		showAlert: boolean;
		alertMessage: string;
		alertType: string;
		errorSection: string;
		showNoteDialog?: boolean;
		handOverNote?: boolean;
		handOverNoteValue?: string;
		loggedInRole: string
		currentRejectedStage: string;
		stageNineRejectReason: string;
		moveToStep?: any
		hasViewPermissionList: any;
		hasUpdatePermissionList: any;
		isEdit: boolean;
		isStageSubmitted: boolean;
		saveDraftLoading?: boolean;
		isReject: boolean;
		isDraft: boolean;
		stageSevenInstallments: any[];
		rrStageSeven?: any;
		trackFor: string;
		isPayInstallment: boolean;
		voucherBtnLoading: boolean;
		invoiceBtnLoading: boolean;
		creditDebitBtnLoading: boolean;
		generatingInvoiceForNumber: any;
		isError: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class StageNineController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
		formikRef: any = null;
		stageNineSubmitApi: any = '';
		stageNineTrackApi: any = '';
		payInstallmentApi: any = '';
		removeFileAPIStageNine:any="";
		generateInvoiceApi: any = '';
		generateCreditDebitApi: any = '';
		generateInvoiceForInstallmentApi: any = '';
    // Customizable Area End

    constructor(props: Props) {
			// Customizable Area Start
			super(props);
			this.receive = this.receive.bind(this);
			this.subScribedMessages = [
				getName(MessageEnum.CountryCodeMessage),
				getName(MessageEnum.RestAPIResponceMessage),
				getName(MessageEnum.ReciveUserCredentials)
			];

			const loginData = JSON.parse(window.localStorage.getItem("loginData") || "{}")
			this.state = {
				loading: false,
				flow: 1,
				policyId: '',
				isStageSubmitted: false,
				expandedAccordion: "p9",
				showRejectDialog: false,
				handOverNote: false,
				handOverNoteValue: '',
				loggedInRole: loginData?.role || "",
				isArabicLenguage: (window.localStorage.getItem("lang") || "en") === "ar",
				showAlert: false,
				alertMessage: "",
				alertType: "",
				errorSection: "",
				showNoteDialog: false,
				currentRejectedStage: "",
				moveToStep: '',
				stageNineRejectReason: "",
				hasViewPermissionList: this.props.rrStageSevenData?.meta.next_stage_view_permission_role || [],
				hasUpdatePermissionList: this.props.rrStageSevenData?.meta.next_stage_update_permission_role || [],
				saveDraftLoading: false,
				isEdit: false,
				isReject: false,
				isDraft: false,
				stageSevenInstallments: [],
				rrStageSeven: this.props.rrStageSevenData,
				trackFor: '',
				isPayInstallment: false,
				voucherBtnLoading: false,
				invoiceBtnLoading: false,
				creditDebitBtnLoading: false,
				generatingInvoiceForNumber:0,
				isError: false
			};
			runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
			// Customizable Area End
    }

		async componentDidMount() {
			// Customizable Area Start
			super.componentDidMount();
			const { role } = JSON.parse(window.localStorage.getItem('loginData') || '{}')
      this.setState({loggedInRole: role, policyId: this.props.policyId})
			if (this.props.rrStageSevenData) {
				const installments = this.props.rrStageSevenData?.data?.attributes.revision_installments.data?.sort((a: any, b: any) => parseInt(a.attributes.installment_no) - parseInt(b.attributes.installment_no));
				this.setState({
					policyId: this.props.rrStageSevenData?.data.attributes.revision_request_id,
					flow: Number(this.props.rrStageSevenData?.data.attributes.flow),
					stageSevenInstallments: installments || []
				})
			}
			// Customizable Area End
		}

    // Customizable Area Start
		componentDidUpdate() {
			if (this.state.showAlert) {
				setTimeout(() => {
					this.setState({
						showAlert: false,
						alertType: "",
						alertMessage: "",
					});
				}, 10000);
			}
		}

		setArabicLanguage = (value: boolean) => {
			this.setState({
				isArabicLenguage: value,
			});
		};
		handleNoteDialog = (value: boolean = false) => {
			this.setState({ handOverNote: value });
			if(!value){
				this.setState({handOverNoteValue:""})
			}
		};
		handleHandOverNote = (value: boolean) => {
			this.setState({ showNoteDialog: value})
		}
		handleStageNineRejectDialog = (value: boolean) => {
			this.setState({ showRejectDialog: value });
			if (value === false) {
				this.setState({isReject:false})
			}
		};
		getPolicyOption = () => {
			const array = [
				{ value: "info_gathering", label: "Info gathering" },
				{ value: "processing", label: "Processing" },
				{ value: "awaiting", label: "Awaiting" },
				{ value: "operations_review", label: "Operations review" },
				{ value: "payment_review", label: "Payment review" },
			];
			return array;
		};

		isStageNineAccordionOpen = (isExpanded: any) => {
			if (isExpanded === true && this.state.expandedAccordion === "p9") {
				return true;
			}
			if (this.state.policyId !== "" && this.state.expandedAccordion === "p9") {
				return true;
			}
			return false;
		}

		stageNineViewRights = () => {
			return this.state.hasViewPermissionList?.includes(
				this.state.loggedInRole
			) as boolean;
		};

		getSuffix(value: number) {
			const lastDigit = value % 10;
			const numberArr = [11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
			if (numberArr.includes(value)) return "th";
			else if (lastDigit === 1) return "st";
			else if (lastDigit === 2) return "nd";
			else if (lastDigit === 3) return "rd";
			else return "th";
		}

		handleStageNineAccordian = (isExpanded: boolean, accordionName: string) => {
			if(this.state.policyId && !this.stageNineViewRights()) {
				this.setState({
					alertMessage: `Invoicing stage is not accessible to ${this.state.loggedInRole} role. Please login with other role. `,
					alertType: "warning",
					showAlert: true,
				});
			} else if (this.state.policyId !== "" && this.stageNineViewRights()) {
				this.setState({
					expandedAccordion: isExpanded ? accordionName : "", showAlert: false
				});
			} else {
				this.setState({
					expandedAccordion: isExpanded ? accordionName : "",
				});
			}
		};

		getFieldValue = (field: string): string => {
			if(field.includes('invoice')) if( this.state.flow === 7) return field.replace('invoice', 'credit_note')
			if(field.includes('Invoice')) if( this.state.flow === 7) return field.replace('Invoice', 'CreditNote')
			if(field.includes('credit')) if( this.state.flow === 7) return field.replace('credit', 'debit')
			if(field.includes('Credit')) if( this.state.flow === 7) return field.replace('Credit', 'Debit')

			return field
		}
		getUpdateFieldValue = (fieldKey: string, installmentIndex?: number, isInstallment?: boolean, ) => {
			if(isInstallment && installmentIndex !== undefined) {
				return `revision_installments[${installmentIndex}][${fieldKey}]`
			}
			else {
				return fieldKey
			}
		}
		validateInstallment = (isInstallment?: boolean, installmentIndex?: number) => {
			if(isInstallment && installmentIndex !== undefined) {
				if(this.state.rrStageSeven?.data?.attributes.revision_installments.data[installmentIndex]?.attributes.installment_paid) return false;
				else return true;
			}
			else return false
		}

		chekcDisableAfterStage7InstallmentPaid = (fieldname: string, commissionType: string, installmentIndex?: number) => {
			if(this.props.rrStageNineData?.data?.attributes?.[commissionType]?.data?.[installmentIndex !== undefined ? (installmentIndex + 1) : 0]?.attributes?.[fieldname]
			){
				return true;
			}else return false;
		}

		getSiibCommission = (isInstallment: any, installmentIndex: number | undefined): number => {
			if(this.state.flow === 7) return Number(this.state.rrStageSeven?.data?.attributes.refund_amount_to_customer || 0)
			if(isInstallment && installmentIndex !== undefined) {
				return Number(this.state.rrStageSeven?.data?.attributes.revision_installments?.data[installmentIndex]?.attributes?.installment_amount || 0)
			} else {
				return Number(this.state.rrStageSeven?.data?.attributes.paid_amount || 0)
			}
		}

		getCommission = (isInstallment: any, installmentIndex: number | undefined, currentFlow: number, values: any): number => {
			if(isInstallment && installmentIndex !== undefined) {
				return (currentFlow != 6) ?
					Number(values.revision_installments?.[installmentIndex].commissionAmount || 0) :
					Number(this.state.rrStageSeven?.data?.attributes.revision_installments.data[installmentIndex]?.attributes?.commission.data?.attributes.commission_amount || 0)
			} else {
				return (currentFlow != 4 && currentFlow != 6) ?
					Number(values.commissionAmount || 0) :
					Number(this.state.rrStageSeven?.data?.attributes.commission.data?.attributes.commission_amount || 0)
			}
		}

		getValueFromKey = (fieldKey: string, values: any, isInstallment:boolean= false, installmentIndex: number= 0) => {
			if(isInstallment && installmentIndex !== undefined) {
			  return values?.revision_installments?.[installmentIndex][fieldKey]
			}
			else {
			  return values?.[fieldKey]
			}
		  }

		forNoneInstallmentSchema = (isArabicLanguage: boolean) => ({
			commissionRate: Yup.number().required(isArabicLanguage ? 'مطلوب معدل العمولة' : "Commission rate is required"),
			invoiceDate: Yup.date().required(isArabicLanguage ? 'تاريخ الفاتورة مطلوب' : "Invoice date is required"),
			siibInvoiceId: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء الفاتورة' : "Please create invoice"),
			invoicePaid: Yup.string().when([], {
				is: (val) => val === 'no' || val === '',
				then: Yup.string().required(isArabicLanguage ? 'يجب التحقق من الفاتورة المدفوعة' : 'Invoice paid must be checked'),
				otherwise: Yup.string().notRequired()
			  }),
			paymentDate: Yup.date().required(isArabicLanguage ? 'تاريخ الدفع مطلوب' : "Payment date is required"),
			proofOfPaymentReceivedDoc: Yup.string().required(isArabicLanguage ? 'مطلوب مستند إثبات الدفع' : "Proof of payment document is required"),
			salesCommissionRate: Yup.number().required(isArabicLanguage ? 'مطلوب معدل عمولة المبيعات' : "Sales commission rate is required"),
			creditNoteDate: Yup.date().required(isArabicLanguage ? 'تاريخ مذكرة الائتمان مطلوب' : "Credit note date is required"),
			salesCreditNoteId: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء مذكرة الائتمان' : "Please create credit note")
		});

		forInstallmentSchema = (isArabicLanguage: boolean) => {
			return {
				...this.forNoneInstallmentSchema(isArabicLanguage),
			revision_installments: Yup.array().of(
				Yup.object().shape({...this.forNoneInstallmentSchema(isArabicLanguage)})
			),
		}};

		forSalesOnlySchema = (isArabic: boolean) => ({
			salesCommissionRate: Yup.number().required(isArabic ? 'مطلوب معدل عمولة المبيعات' : "Sales commission rate is required"),
			creditNoteDate: Yup.date().required(isArabic ? 'تاريخ مذكرة الائتمان مطلوب' : "Credit note date is required"),
			salesCreditNoteId: Yup.string().required(isArabic ? 'الرجاء إنشاء مذكرة الائتمان' : "Please create credit note")
		})

		forFlowSevenSchema = (isArabicLanguage: boolean) => ({
			commissionRate: Yup.number().required(isArabicLanguage ? 'مطلوب معدل العمولة' : "Commission rate is required"),
			invoiceDate: Yup.date().required(isArabicLanguage ? 'تاريخ الفاتورة مطلوب' : "Invoice date is required"),
			siibCreditNoteId: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء الفاتورة' : "Please create credit note"),
			invoicePaid: Yup.string().when([], {
				is: (val) => val === 'no' || val === '',
				then: Yup.string().required(isArabicLanguage ? 'يجب التحقق من الفاتورة المدفوعة' : 'Invoice paid must be checked'),
				otherwise: Yup.string().notRequired()
			  }),
			paymentDate: Yup.date().required(isArabicLanguage ? 'تاريخ الدفع مطلوب' : "Payment date is required"),
			proofOfPaymentReceivedDoc: Yup.string().required(isArabicLanguage ? 'مطلوب مستند إثبات الدفع' : "Proof of payment document is required"),
			salesCommissionRate: Yup.number().required(isArabicLanguage ? 'مطلوب معدل عمولة المبيعات' : "Sales commission rate is required"),
			debitNoteDate: Yup.date().required(isArabicLanguage ? 'تاريخ مذكرة الائتمان مطلوب' : "Credit note date is required"),
			salesDebitNoteId: Yup.string().required(isArabicLanguage ? 'الرجاء إنشاء مذكرة الائتمان' : "Please create debit note")
		})

		operationSchema = (currentFlow: number) => {
			const isArabic = ActiveLanguage();
			let schema = {}

			switch (currentFlow) {
				case 1:
				case 3:
					schema = this.forNoneInstallmentSchema(isArabic);
					break;
				case 4:
					schema = this.forSalesOnlySchema(isArabic)
					break;
				case 2:
				case 5:
					schema = this.forInstallmentSchema(isArabic);
					break;
				case 6:
					schema = {
						...this.forSalesOnlySchema(isArabic),
						revision_installments: Yup.array(Yup.object().shape(this.forSalesOnlySchema(isArabic)))
					};
					break;
				case 7:
					schema = this.forFlowSevenSchema(isArabic);
			}
			return schema;
		};

		getInstallmentData = (salesPerson: string) => {
			const stageSevenFormData = this.props.rrStageSevenData?.data;
			const objectData = {
				commissionRate: "",
				commissionAmount: "",
				invoiceDate: "",
				invoicePaid: "",
				paymentDate: "",
				proofOfPaymentReceivedDoc: "",
				salesCommissionRate: "",
				salesCommissionAmount: "",
				creditNoteDate: "",
				salesPerson: salesPerson,
				invoice:"",
				siibInvoiceId:"",
				salesCreditNoteId: "",
				salesCreditNoteDoc: "",
			}
			let installment: any = [objectData];

			stageSevenFormData?.attributes.revision_installments.data.map((item: any) => {
				if(item.attributes?.installment_paid) installment.push(objectData)
			})
			return installment;
		}
	
		getFormInitialValues = () => {// NOSONAR: typescript:S3776
			const stageOneData = this.props.rrStageOneData.data.attributes.sales_id.name;
			const stageNineFormData = this.props.rrStageNineData.data;
			const SiibData = [4, 6];
			let installment: any = this.getInstallmentData(stageOneData);

			!SiibData.includes(this.state.flow) && stageNineFormData?.attributes?.siib_commission?.data?.map((data: any, index: number) => {
				installment[index] = {
					...installment[index],
					commissionRate: data.attributes.commission_rate?.replace(/%/g, "") || "",
					commissionAmount: data.attributes.commission_amount || "",
					invoiceDate: data.attributes.invoice_date || "",
					invoicePaid: data.attributes.invoice_paid ? "yes" : "no",
					paymentDate: data.attributes.payment_date || "",
					proofOfPaymentReceivedDoc: data.attributes.upload_proof_of_payment_recevied || "",
					invoice: data.attributes.invoice_file_installment || "",
					siibInvoiceId: data.attributes.invoice_file_installment?.file_name.replace('.pdf', '') || "",
				}

			})
			stageNineFormData?.attributes?.sales_commission?.data?.map((data: any, index: number) => {
				installment[index] = {
					...installment[index],
					salesCommissionRate: data.attributes.commission_rate?.replace(/%/g, "") || "",
					salesCommissionAmount: data.attributes.commission_amount || "",
					salesPerson: data.attributes?.sales_person || stageOneData,
				}

			})
			stageNineFormData?.attributes?.credit_note?.data?.map((data: any, index: number) => {
				installment[index] = {
					...installment[index],
					creditNoteDate: data.attributes?.credit_note_date || "",
					salesCreditNoteId: data.attributes?.credit_note_id || "",
					salesCreditNoteDoc: data.attributes?.credit_note || "",
				}
		 	})

			const responseData = {
				// siib data
				commissionRate: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.commission_rate || "",
				commissionAmount: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.commission_amount || "",
				invoiceDate: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.invoice_date || "",
				siibInvoiceId: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.invoice?.file_name.replace('.pdf', '') || "",
				invoicePaid: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.invoice_paid || "",
				paymentDate: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.payment_date || "",
				proofOfPaymentReceivedDoc: stageNineFormData?.attributes?.siib_commission?.data[0].attributes.upload_proof_of_payment_recevied || "",
				salesCommissionRate: stageNineFormData?.attributes?.sales_commission?.data[0].attributes.commission_rate || "",
				salesCommissionAmount: stageNineFormData?.attributes?.sales_commission?.data[0].attributes.commission_amount || "",
				salesPerson: stageNineFormData?.attributes?.sales_commission?.data[0].attributes.sales_person || stageOneData,
				// credit data
				
				siibCreditNoteId:this.state.flow ==7 ? stageNineFormData?.attributes?.credit_note?.data[0]?.attributes.credit_note_id:"",
				invoice: this.state.flow ==7 ?stageNineFormData?.attributes?.credit_note?.data[0]?.attributes.credit_note:stageNineFormData?.attributes?.siib_commission?.data[0].attributes.invoice,
				creditNoteDate: stageNineFormData?.attributes?.credit_note?.data[0]?.attributes.credit_note_date || "",
				salesCreditNoteDoc: stageNineFormData?.attributes?.credit_note?.data[0]?.attributes.credit_note || "",
				salesCreditNoteId: stageNineFormData?.attributes?.credit_note?.data[0]?.attributes.credit_note_id || "",
				// installment data
      	        revision_installments: installment.slice(1),
				// debit data
				salesDebitNoteDoc:this.state.flow ==7 ?stageNineFormData?.attributes?.debit_note?.data?.attributes.debit_note:"",
				debitNoteDate: stageNineFormData?.attributes?.debit_note.data?.attributes?.debit_note_date || "",
				salesDebitNoteId: stageNineFormData?.attributes?.debit_note.data?.attributes?.debit_note_id || "",
			}
						return responseData;
		}

		setFlowOneToFourData = (values: any,isDraft: boolean, isUpdate: boolean, flow: number, rrStageNineData: any) => {
			const formData = new FormData();
			if(flow !== 4) {
				formData.append(`data[revision_invoicing][siib_commission][commission_rate]`, values?.commissionRate)
				formData.append(`data[revision_invoicing][siib_commission][commission_amount]`, values?.commissionAmount)
				formData.append(`data[revision_invoicing][siib_commission][invoice_date]`, values?.invoiceDate)
				formData.append(`data[revision_invoicing][siib_commission][invoice_paid]`, values?.invoicePaid ? "yes" : "no")
				formData.append(`data[revision_invoicing][siib_commission][payment_date]`, values?.paymentDate)
				// click button action generated
				// file upload
				!values?.proofOfPaymentReceivedDoc?.url && formData.append(`data[upload_proof_of_payment_received]`, values?.proofOfPaymentReceivedDoc);
				formData.append(`data[revenue_siib_commission]`, values?.commissionAmount);
				formData.append(`data[revenue_sales_commission]`, values?.salesCommissionAmount);
				isUpdate && formData.append(`data[revision_invoicing][siib_commissions][id]`, rrStageNineData.attributes?.siib_commission.data[0]?.id);
			}
			formData.append(`data[revision_invoicing][sales_commission][commission_rate]`, values?.salesCommissionRate);
			formData.append(`data[revision_invoicing][sales_commission][commission_amount]`, values?.salesCommissionAmount);
      isUpdate && formData.append(`data[revision_invoicing][sales_commissions][id]`, rrStageNineData.attributes?.sales_commission.data[0]?.id);

			if(flow === 7) {
				formData.append(`data[revision_invoicing][debit_note][debit_note_date]`, values?.debitNoteDate);
				// click button action generated
				values?.siibCreditNoteId&&formData.append(`data[revision_invoicing][credit_note][credit_note_id]`, values?.siibCreditNoteId)
				formData.append(`data[revision_invoicing][debit_note][debit_note_id]`, values?.salesDebitNoteId)
			}
			if(flow != 7) {
				formData.append(`data[revision_invoicing][credit_note][credit_note_date]`, values?.creditNoteDate);
				// click button action generated
				formData.append(`data[revision_invoicing][credit_note][credit_note_id]`, values?.salesCreditNoteId)
			}

      isUpdate && formData.append(`data[revision_invoicing][credit_notes][id]`, rrStageNineData.attributes?.credit_note.data[0]?.id);

			formData.append(`data[revision_invoicing][balance]`, flow !== 4 ? values?.commissionAmount : '0')
			formData.append(`data[revision_invoicing][save_as_draft]`, isDraft ? "yes" : 'no')

			return formData
		}

		setFlowTwoInstallmentData = (values: any,isDraft: boolean, isUpdate: boolean, flow: number, rrStageNineData: any) => {
			const formData = new FormData();
			let balance = 0;
			let siibRevenueCommission = 0;
			let salesRevenueCommission = 0;

			if(flow !== 6) {
        // siib_commissions
        formData.append(`data[revision_invoicing][siib_commissions][0][commission_rate]`, values?.commissionRate);
        formData.append(`data[revision_invoicing][siib_commissions][0][commission_amount]`, values?.commissionAmount);
        formData.append(`data[revision_invoicing][siib_commissions][0][invoice_date]`, values?.invoiceDate);
        formData.append(`data[revision_invoicing][siib_commissions][0][invoice_paid]`, values?.invoicePaid ? 'yes' : 'no');
        formData.append(`data[revision_invoicing][siib_commissions][0][payment_date]`, values?.paymentDate);		
		!values?.proofOfPaymentReceivedDoc?.url && formData.append(`data[upload_proof_of_payment_received][0]`, values?.proofOfPaymentReceivedDoc);

        isUpdate && formData.append(`data[revision_invoicing][siib_commissions][0][id]`, rrStageNineData.attributes?.siib_commission.data[0]?.id);
				balance = balance + Number(values?.commissionAmount);
				siibRevenueCommission = siibRevenueCommission + Number(values?.commissionAmount);
				salesRevenueCommission = salesRevenueCommission + Number(values?.salesCommissionAmount);
      }
      // sales_commissions
      formData.append(`data[revision_invoicing][sales_commissions][0][commission_rate]`, values?.salesCommissionRate);
      formData.append(`data[revision_invoicing][sales_commissions][0][commission_amount]`, values?.salesCommissionAmount);
      isUpdate && formData.append(`data[revision_invoicing][sales_commissions][0][id]`, rrStageNineData.attributes?.sales_commission.data[0]?.id);
      // credit_notes
      formData.append(`data[revision_invoicing][credit_notes][0][credit_note_date]`, values?.creditNoteDate);
			// click button action generated
      // formData.append(`data[revision_invoicing][credit_notes][0][credit_note_id]`, values?.salesCreditNoteId);
			// formData.append(`data[credit_note][0]`, values?.proofOfPaymentReceivedDoc);
      isUpdate && formData.append(`data[revision_invoicing][credit_notes][0][id]`, rrStageNineData.attributes?.credit_note.data[0]?.id);

      values?.revision_installments.map((data: any, index: number) => {
        if(flow !== 6) {
		  siibRevenueCommission = siibRevenueCommission + Number(data?.commissionAmount);
		  salesRevenueCommission = salesRevenueCommission + Number(data?.salesCommissionAmount);
		  
          // siib_commissions
          formData.append(`data[revision_invoicing][siib_commissions][${index + 1}][commission_rate]`, data.commissionRate);
          formData.append(`data[revision_invoicing][siib_commissions][${index + 1}][commission_amount]`, data.commissionAmount);
          formData.append(`data[revision_invoicing][siib_commissions][${index + 1}][invoice_date]`, data.invoiceDate);
          formData.append(`data[revision_invoicing][siib_commissions][${index + 1}][invoice_paid]`, data.invoicePaid ? 'yes' : 'no');
          formData.append(`data[revision_invoicing][siib_commissions][${index + 1}][payment_date]`, data.paymentDate);
		  !data?.proofOfPaymentReceivedDoc?.url && formData.append(`data[upload_proof_of_payment_received][${index + 1}]`, data?.proofOfPaymentReceivedDoc);

		  isUpdate && rrStageNineData.attributes?.siib_commission.data[index+1]?.id && formData.append(`data[revision_invoicing][siib_commissions][${index + 1}][id]`, rrStageNineData.attributes?.siib_commission.data[index+1]?.id);
					balance = balance + Number(data?.commissionAmount);
        }
        // sales_commissions
        formData.append(`data[revision_invoicing][sales_commissions][${index + 1}][commission_rate]`, data.salesCommissionRate);
        formData.append(`data[revision_invoicing][sales_commissions][${index + 1}][commission_amount]`, data.salesCommissionAmount);
        isUpdate && rrStageNineData.attributes?.sales_commission.data[index + 1]?.id && formData.append(`data[revision_invoicing][sales_commissions][${index + 1}][id]`, rrStageNineData.attributes?.sales_commission.data[index + 1]?.id);
        // credit_notes
        formData.append(`data[revision_invoicing][credit_notes][${index + 1}][credit_note_date]`, data.creditNoteDate);
				// click button action generated
        // formData.append(`data[revision_invoicing][credit_notes][${index + 1}][credit_note_id]`, data.salesCreditNoteId);
				// formData.append(`data[credit_note][${index + 1}]`, values?.proofOfPaymentReceivedDoc);

        isUpdate && rrStageNineData.attributes?.credit_note.data[index + 1]?.id && formData.append(`data[revision_invoicing][credit_notes][${index + 1}][id]`, rrStageNineData.attributes?.credit_note.data[index + 1]?.id);
      });

			formData.append("data[revision_invoicing][balance]", balance.toString());
      formData.append("data[revision_invoicing][save_as_draft]", isDraft ? "yes" : "no");
        this.getRevenueKeys(flow, formData, siibRevenueCommission, salesRevenueCommission)
			return formData
		}

		getRevenueKeys = (flow: number, formData: any, siibRevenueCommission: number, salesRevenueCommission: number) => {
			if (flow!=6) {
				formData.append(`data[revenue_siib_commission]`, `${siibRevenueCommission}`);
				formData.append(`data[revenue_sales_commission]`,`${salesRevenueCommission}`);
			}
			return formData;
		}

		getFormData = (values: any, flow: number, isDraft: boolean, isUpdate: boolean = false) => {
			let data = {};
			switch (flow) {
				case 1:
				case 3:
				case 4:
				case 7:
					data = this.setFlowOneToFourData(values, isDraft, isUpdate, this.state.flow, this.props.rrStageNineData?.data)
					break;
				case 2:
				case 5:
				case 6:
					data = this.setFlowTwoInstallmentData(values, isDraft, isUpdate, this.state.flow, this.props.rrStageNineData?.data)
					break;

				default:
					break;
			}

			return data
		}

		setAlert = (responseJson: any, alertType: string = 'error') => {
			this.setState({ showAlert: true, alertMessage: responseJson, alertType: alertType})
		}
		apiCall(data: any) {
			const { contentType, method, endPoint, body } = data;
			const authUserData = JSON.parse(localStorage.getItem("loginData") || "{}");
			const token = authUserData.token || "";

			const header = {
				"Content-Type": contentType,
				token: token,
			};
			contentType === 'FormData' && delete header["Content-Type"];

			const requestMessageForStageInvoice = new Message( getName(MessageEnum.RestAPIRequestMessage) );
			requestMessageForStageInvoice.addData( getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) );
			requestMessageForStageInvoice.addData( getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint );
			requestMessageForStageInvoice.addData( getName(MessageEnum.RestAPIRequestMethodMessage), method );
			body && contentType !== 'FormData' ?
				requestMessageForStageInvoice.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)) :
				requestMessageForStageInvoice.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
			runEngine.sendMessage(requestMessageForStageInvoice.id, requestMessageForStageInvoice);
			return requestMessageForStageInvoice.messageId;
		}

		getStepName = getStageNameByNumber;
  	getStepNumber = getStageNumberByName;
		setApiErrorMessage = (responseJson:any) => {
			if (!responseJson) return
			let errorMessage = "Something went wrong"
			if (responseJson?.errors) {
				errorMessage = typeof(responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error  || "Something went wrong";
			}
			this.setState({
				alertType: "error",
				alertMessage: errorMessage,
				showAlert: true,
				saveDraftLoading:false,
				showRejectDialog:false,
				showNoteDialog:false,
			})
	  }

		async submitProcessingData(values: any, isDraft: boolean = false){
			if(this.props.isEdit) {
				this.submitStageNine(values, this.props.isEdit, isDraft)
			} else {
				this.submitStageNine(values, false, isDraft)
			}
		}

		checkInvoiceBtnDisable = (values:any ) => {
			const requiredFields = ['commissionRate', 'invoiceDate', 'commissionAmount'];
    		const hasEmptyRequiredFields = requiredFields.some(field => values[field] === "" || values[field] === 0);

    		const hasNonEmptyInvoiceFields = (values['invoice'] && values['invoice'] !== "") || (values['siib_invoice_id'] && values['siib_invoice_id'] !== "");

    		return hasEmptyRequiredFields || hasNonEmptyInvoiceFields;
		}

		getClassNameOnInvoiceBtn = (values:any,index:any, isInstallment?: boolean) => {
			if ((index !== undefined &&  index >= 0)) {
				return this.isInstallmentInvoiceBtnDisabled(values,index) || this.validateInstallment(isInstallment, index) ? "grayBtn" : "primaryBtn"
			} else {
				return this.checkInvoiceBtnDisable(values)  || this.validateInstallment(isInstallment, index)? "grayBtn" : "primaryBtn"
			}
		}

		isInstallmentInvoiceBtnDisabled = (values:any , index:any) => {
			const installment = values.revision_installments[index];
			if ( installment &&
				(!installment.commissionRate ||
				!installment.commissionAmount ||
				!installment.invoiceDate ||
				installment.siibInvoiceId)
			) {
				return true; // Button should be disabled
			}

			return false;
		}

		generateInvoice = (values: any, formikRef: any) => {
			this.formikRef = formikRef;
			this.setState({
				invoiceBtnLoading: true,
				showAlert: true,
				alertType:"warning",
				alertMessage: this.state.isArabicLenguage ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
			});

			const invoiceBody = this.state.flow === 7 ? {
				commission_amount: values.commissionAmount,
				file_key: "credit_note_pdf",
				recipient_type: "Siib",
				commission_rate: values.commissionRate,
				credit_note_date: values.invoiceDate
			  } : {
				paid_amount: this.state.rrStageSeven?.data?.attributes.paid_amount,
				file_key: "invoice_pdf",
				recipient_type: "Siib",
				invoice_date: values.invoiceDate,
				commission_rate: values.commissionRate,
			}

			this.generateInvoiceApi = this.apiCall({
				contentType: 'application/json',
				method: 'POST',
				endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?revision_request_id=${this.state.policyId}&stage=9&flow=${this.state.flow}`,
				body: {
					data: invoiceBody
				},
			})
		}
		generateInvoiceForInstallment = (values:any, index:any,formikRef:any) => {
			this.formikRef = formikRef;
			const installment = values.revision_installments[index];

			this.setState({
				invoiceBtnLoading: true,
				showAlert: true,
				alertType:"warning",
				generatingInvoiceForNumber:index,
				alertMessage: ActiveLanguage() ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating file, please wait"
			});


			const invoiceBody = {
				paid_amount: this.state.rrStageSeven?.data?.attributes.revision_installments?.data[index]?.attributes?.installment_amount,
				file_key: "invoice_pdf",
				recipient_type: "Siib",
				invoice_date: installment?.invoiceDate,
				commission_rate: installment?.commissionRate,
				installment_no: index + 2
			}

			this.generateInvoiceForInstallmentApi = this.apiCall({
				contentType: 'application/json',
				method: 'POST',
				endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?revision_request_id=${this.state.policyId}&stage=9&flow=${this.state.flow}`,
				body: {
					data: invoiceBody
				},
			})
		}
		checkIfCreditNoteDisabled = (values: any,requiredFields:any, installmentIndex:number) => {
			const installment = values?.revision_installments[installmentIndex]
				const hasEmptyRequiredFields = requiredFields.some((field:any) => installment?.[field] === "" || installment?.[field] === 0);
				const hasNonEmptyInvoiceFields = this.state.flow === 7 ?
					(values['salesDebitNoteDoc'] && values['salesDebitNoteDoc'] !== "") || (values['salesDebitNoteId'] && values['salesDebitNoteId'] !== "") :
					(values?.revision_installments[installmentIndex]?.['salesCreditNoteDoc'] && values?.revision_installments[installmentIndex]?.['salesCreditNoteDoc'] !== "") || (values?.revision_installments[installmentIndex]?.['salesCreditNoteId'] && values?.revision_installments[installmentIndex]?.['salesCreditNoteId'] !== "");

				return hasEmptyRequiredFields || hasNonEmptyInvoiceFields;
		}

		checkCreditDebitNoteBtnDisable = (values: any, installmentIndex?:number|undefined) => {
			const requiredFields = ['salesCommissionRate', 'salesCommissionAmount', this.state.flow === 7 ? 'debitNoteDate' : 'creditNoteDate'];
			if(installmentIndex != undefined){
				return this.checkIfCreditNoteDisabled(values,requiredFields, installmentIndex);

			}else{
				const hasEmptyRequiredFields = requiredFields.some(field => values[field] === "" || values[field] === 0);
				const hasNonEmptyInvoiceFields = this.state.flow === 7 ?
					(values['salesDebitNoteDoc'] && values['salesDebitNoteDoc'] !== "") || (values['salesDebitNoteId'] && values['salesDebitNoteId'] !== "") :
					(values['salesCreditNoteDoc'] && values['salesCreditNoteDoc'] !== "") || (values['salesCreditNoteId'] && values['salesCreditNoteId'] !== "");

				return hasEmptyRequiredFields || hasNonEmptyInvoiceFields;
			}
		}
		getCreditDebitNote = (values: any, forField: string = 'file_name', installmentIndex ?: number|undefined) => {
			let returnValue = "";
			if(installmentIndex != undefined){
				returnValue = values?.revision_installments[installmentIndex]?.salesCreditNoteDoc?.[forField];
			}else{
				returnValue = this.state.flow === 7 ? values?.salesDebitNoteDoc?.[forField] : values?.salesCreditNoteDoc?.[forField];
			}
			return returnValue;
		}

		getFileInputValues = (values: any, field_name: string, key: string, isInstallment?: boolean, installmentIndex?: number|undefined) => {
            if(isInstallment && installmentIndex !== undefined) {
                return values.revision_installments?.[installmentIndex]?.[field_name]?.[key];
            } else {
                return values[field_name][key];
            }
        }
		/*istanbul ignore next*/
		removeFileInvoice = (fileId: any) => {
			const urlParams = new URLSearchParams(window.location.search);
			const id = urlParams.get('id');
			 this.removeFileAPIStageNine = this.apiCall({
				contentType: 'application/json',
				endPoint: `bx_block_revision_request/revision_requests/${id}/remove_file`,
				method: 'DELETE',
				body: {
					"data": {
						"file_id": [fileId]
					}
				}
			});
		}
		/*istanbul ignore next*/
		fileRemoveApiReceiveInvoicestage = (message:any) => {
			if (
				getName(MessageEnum.RestAPIResponceMessage) === message.id &&
				this.removeFileAPIStageNine !== null &&
				this.removeFileAPIStageNine ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
				) {
				const responseJsonInvoice = message.getData(
					getName(MessageEnum.RestAPIResponceSuccessMessage)
				);
				/*istanbul ignore next*/
				if (responseJsonInvoice.data) {
				
					responseJsonInvoice.data.error_file && this.setState({
						alertType: "error",
						alertMessage: responseJsonInvoice.data.error_file,
						showAlert: true,
					})
					responseJsonInvoice.data?.success && this.setState({
						alertType: "success",
						alertMessage: responseJsonInvoice.data?.success,
						showAlert: true,
						})
				
				}
			}
		}

		generateCreditDebitNote = (values: any, formikRef: any, installmentIndex?:number|undefined) => {
			this.formikRef = formikRef;
			this.setState({
				creditDebitBtnLoading: true,
				showAlert: true,
				alertType:"warning",
				alertMessage: this.state.isArabicLenguage ? "جارِ إنشاء الملف، يرجى الانتظار" : "Generating note file, please wait"
			});
			let body: any;
			if(installmentIndex != undefined){
				body = {
					commission_amount: (this.state.flow === 6) ? this.state.rrStageSeven?.data?.attributes?.revision_installments?.data[installmentIndex]?.attributes?.commission.data?.attributes.commission_amount : values?.revision_installments[installmentIndex]?.commissionAmount,
					file_key: this.getFieldValue("credit_note_pdf"),
					recipient_type: "Sales",
					commission_rate: values?.revision_installments[installmentIndex]?.salesCommissionRate,
					credit_note_date:values?.revision_installments[installmentIndex]?.creditNoteDate,
					installment_no: installmentIndex + 2
				}
			} else{
				let commissionAmount=0
				/*istanbul ignore next*/
                if(this.state.flow==4){
                    commissionAmount=((this.state.rrStageSeven?.data?.attributes?.commission?.data?.attributes?.commission_amount))
                }
                else if(this.state.flow==6){

                    commissionAmount=this.state.rrStageSeven?.data?.attributes?.commission?.data?.attributes?.commission_amount
                }else{
                    commissionAmount=values?.commissionAmount
                }
				body = {
					file_key: this.getFieldValue("credit_note_pdf"),
					recipient_type: "Sales",
					commission_rate: values?.salesCommissionRate,
					commission_amount:String(commissionAmount),
					commission_with_vat:this.state.flow==6 &&this.state.rrStageSeven?.data?.attributes?.commission?.data?.attributes?.deduct_commission_with_vat
				}
				if(this.state.flow === 7) body['debit_note_date'] = values?.debitNoteDate;
				else body['credit_note_date'] = values?.creditNoteDate;
			}

			this.generateCreditDebitApi = this.apiCall({
				contentType: 'application/json',
				method: 'POST',
				endPoint: `bx_block_invoicebilling/finance_files/generate_and_attach_pdf?revision_request_id=${this.state.policyId}&stage=9&flow=${this.state.flow}`,
				body: {
					data: body
				},
			})
		}

		createApiResponse = (message: any) => {
			if (
				getName(MessageEnum.RestAPIResponceMessage) === message.id && this.stageNineSubmitApi !== null && this.stageNineSubmitApi ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
				)
			{
				const createStageTwoResponseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
				try {
					if (createStageTwoResponseJson.data) {
						const responseData = createStageTwoResponseJson.data;
						this.getFormInitialValues();
						this.setState({isStageSubmitted: true})
						this.props.setStageData('stageNine', createStageTwoResponseJson)
						if(responseData.attributes.save_as_draft){
							this.setAlert(this.props.t('saved_as_draft_successfully'), 'success' )
							this.setState({saveDraftLoading: false})
							return
						} else {
							this.setState({saveDraftLoading: false})
							this.state.isPayInstallment && this.payInstallment();
							!this.state.isPayInstallment && this.handleHandOverNote(true);
						}
					} else {
						this.setApiErrorMessage(createStageTwoResponseJson);
					}
				} catch (error: any) {
				} finally {
					if(createStageTwoResponseJson.meta) {
						const {current_stage_view_permission_role, current_stage_update_permission_role} = createStageTwoResponseJson.meta
						this.setState({
							hasViewPermissionList : current_stage_view_permission_role,
							hasUpdatePermissionList: current_stage_update_permission_role,
						})
					}
				}
			}
		}

		submitStageNine = (values: any, editPolicy: boolean = false, isDraft: boolean = false) => {
			this.stageNineSubmitApi = this.apiCall({
				contentType: 'FormData',
				method: editPolicy ? 'PUT' : 'POST',
				endPoint: editPolicy ?
					`bx_block_revision_request/revision_requests/${this.state.policyId}?stage=9&flow=${this.state.flow}` :
					`bx_block_revision_request/revision_requests?stage=9&id=${this.state.policyId}&flow=${this.state.flow}`,
				body: this.getFormData(values, this.state.flow, isDraft, editPolicy)
			})
		}

		payInstallmentFlag = () => {
			this.setState({ isPayInstallment: true })
		}

		payInstallment = () => {
			this.payInstallmentApi = this.apiCall({
				contentType: 'application/json',
				method: 'POST',
				endPoint: `bx_block_revision_request/revision_requests/${this.state.policyId}/track_revision_requests?stage=7`,
				body: { data: { track_revision_request: { stage_status: 'reject_stage', comment: 'pay installments' , current_stage: 'payment_review'}, add_installment: true } }
			})
		}

		payInstallmentsApiReceive = (message: any) => {
			if (
				getName(MessageEnum.RestAPIResponceMessage) === message.id &&
				this.payInstallmentApi !== null &&
				this.payInstallmentApi ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
			) {
				const responseJson = message.getData(
					getName(MessageEnum.RestAPIResponceSuccessMessage)
				);
				if (responseJson.data) {
						this.setState({
							showRejectDialog: false,
							loading: false,
							isPayInstallment: false
						})

						this.props.onReject(7, true)
				} else {
					this.setApiErrorMessage(responseJson)
				}
			}
		}

		trackStageNine = (action: string) => {
			this.setState({trackFor: action})
			this.stageNineTrackApi = this.apiCall({
				contentType: 'application/json',
				method: 'POST',
				endPoint: `bx_block_revision_request/revision_requests/${this.state.policyId}/track_revision_requests?stage=9`,
				body: {
					"data": {
						"track_revision_request": {
							"stage_status": (action === "next") ? "next_stage" : "reject_stage",
							"comment": (action === "next") ? this.state.handOverNoteValue : this.state.stageNineRejectReason,
							"current_stage": (action === "next") ? "completed" : this.state.currentRejectedStage
						},
						"add_installment": false
					}
				}
			})
		}

		rejectRvPolicyApiReceive = (message:any) => {
			if (
				getName(MessageEnum.RestAPIResponceMessage) === message.id &&
				this.stageNineTrackApi !== null &&
				this.stageNineTrackApi ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
				) {
				const responseJson = message.getData(
					getName(MessageEnum.RestAPIResponceSuccessMessage)
				);
				if (responseJson.data) {
					const stageNumber = this.getStepNumber(this.state.currentRejectedStage)
					if (this.state.trackFor === "reject") {
						this.setState({
							showAlert: true,
							alertMessage: this.state.isArabicLenguage ? 'لقد تم رفض طلبك للمرحلة المحددة!' : `Your request has been reject to selected stage!`,
							alertType: "success",
							showRejectDialog: false,
							isReject: true,
							expandedAccordion: 'p9',
						})
						this.props.onReject(stageNumber);

					} else {
						this.props.setStageData({"handOverNoteForInvoicing": this.state.handOverNoteValue})
						this.setAlert(this.props.t('revision_complete'), 'success')
						this.setState({
							showNoteDialog: false,
							expandedAccordion: 'p9',
							handOverNoteValue: responseJson.data.attributes.comment
						})
						this.props.onNext(9);
						window.location.href = "/Requests";
					}
				} else {
					this.setApiErrorMessage(responseJson)
				}
			}
		}

		generateInvoiceApiReceive = (message: any) => {
			if (
				getName(MessageEnum.RestAPIResponceMessage) === message.id &&
				this.generateInvoiceApi != null &&
				this.generateInvoiceApi ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
			) {
				const generateInvoiceAPIResponse = message.getData(
					getName(MessageEnum.RestAPIResponceSuccessMessage)
				);
				if (generateInvoiceAPIResponse.data) {
					this.formikRef.current.setFieldValue("invoice", this.state.flow === 7 ? generateInvoiceAPIResponse.data?.attributes?.credit_note_pdf : generateInvoiceAPIResponse.data?.attributes?.invoice_pdf)
					this.state.flow === 7 ?  this.formikRef.current.setFieldValue(`siibCreditNoteId`, generateInvoiceAPIResponse.data?.attributes?.file_id) :this.formikRef.current.setFieldValue(`siibInvoiceId`, generateInvoiceAPIResponse.data?.attributes?.file_id)

					this.setState({
						invoiceBtnLoading: false,
						showAlert: true,
						alertMessage: this.state.isArabicLenguage ? "تم إنشاء الفاتورة بنجاح" : "invoice successfully generated!",
						alertType: "success"
					});
				} else if (generateInvoiceAPIResponse.errors) {
					this.setState({ invoiceBtnLoading: false });
					this.setApiErrorMessage(generateInvoiceAPIResponse)
				}
			}
		}
		generateInvoiceForInstallmentApiRecive = (message: any) => {
			if (
				getName(MessageEnum.RestAPIResponceMessage) === message.id &&
				this.generateInvoiceForInstallmentApi != null &&
				this.generateInvoiceForInstallmentApi ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
			) {
				const generateInvoiceAPIResponseForInstallment = message.getData(
					getName(MessageEnum.RestAPIResponceSuccessMessage)
				);
				if (generateInvoiceAPIResponseForInstallment.data) {

					this.formikRef.current.setFieldValue(`revision_installments[${this.state.generatingInvoiceForNumber}][invoice]`, generateInvoiceAPIResponseForInstallment.data?.attributes?.invoice_pdf)
					this.formikRef.current.setFieldValue(`revision_installments[${this.state.generatingInvoiceForNumber}][siibInvoiceId]`, generateInvoiceAPIResponseForInstallment.data?.attributes?.file_id);

					this.setState({
						invoiceBtnLoading: false,
						showAlert: true,
						alertMessage: this.state.isArabicLenguage ? "تم إنشاء الفاتورة بنجاح" : "invoice successfully generated!",
						alertType: "success"
					});
				} else if (generateInvoiceAPIResponseForInstallment.errors) {
					this.setState({ invoiceBtnLoading: false });
					this.setApiErrorMessage(generateInvoiceAPIResponseForInstallment)
				}
			}
		}

		setCreditNoteDocAndId = (generateCreditDebitApiResponse:any) => {
			let installment_no = generateCreditDebitApiResponse?.data?.attributes?.installment_no
						if(!installment_no){
							this.formikRef.current.setFieldValue(`salesCreditNoteDoc`, generateCreditDebitApiResponse?.data?.attributes?.credit_note_pdf)
							this.formikRef.current.setFieldValue(`salesCreditNoteId`, generateCreditDebitApiResponse?.data?.attributes?.file_id)
						}
						else {
							this.formikRef.current.setFieldValue(`revision_installments[${installment_no - 2}][salesCreditNoteDoc]`, generateCreditDebitApiResponse?.data?.attributes?.credit_note_pdf)
							this.formikRef.current.setFieldValue(`revision_installments[${installment_no - 2}][salesCreditNoteId]`, generateCreditDebitApiResponse?.data?.attributes?.file_id)
						}
		}

		generateCreditDebitNoteApiReceive = (message: any) => {
			if (
				getName(MessageEnum.RestAPIResponceMessage) === message.id &&
				this.generateCreditDebitApi != null &&
				this.generateCreditDebitApi ===
				message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
			) {
				const generateCreditDebitApiResponse = message.getData(
					getName(MessageEnum.RestAPIResponceSuccessMessage)
				);
				if (generateCreditDebitApiResponse.data) {
					if(this.state.flow === 7) {
						this.formikRef.current.setFieldValue("salesDebitNoteDoc", generateCreditDebitApiResponse.data?.attributes?.debit_note_pdf)
						this.formikRef.current.setFieldValue(`salesDebitNoteId`, generateCreditDebitApiResponse.data?.attributes?.file_id)
					} else {
						this.setCreditNoteDocAndId(generateCreditDebitApiResponse)
					}

					this.setState({
						creditDebitBtnLoading: false,
						showAlert: true,
						alertMessage: this.state.isArabicLenguage ? "تم إنشاء الملاحظة بنجاح!" : "Note successfully generated!",
						alertType: "success"
					});
				} else if (generateCreditDebitApiResponse.errors) {
					this.setState({ creditDebitBtnLoading: false });
					this.setApiErrorMessage(generateCreditDebitApiResponse)
				}
			}
		}
    // Customizable Area End

		async receive(from: string, message: Message) {
			// Customizable Area Start
			this.createApiResponse(message);
			this.fileRemoveApiReceiveInvoicestage(message)
			this.payInstallmentsApiReceive(message);
			this.rejectRvPolicyApiReceive(message);
			this.generateInvoiceApiReceive(message)
			this.generateInvoiceForInstallmentApiRecive(message)
			this.generateCreditDebitNoteApiReceive(message)
			// Customizable Area End
		}
}
