import React from "react";
// Customizable Area Start
import { Container } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import RevisionRequestCsvImportController, {
  Props
} from "./RevisionRequestCsvImportController";
import CSVImportDetails from "../../../../components/src/Common/CSVImportDetails";
import { EMPLOYEE_LIST_TABLE_BODY, VEHICLE_LIST_TABLE_BODY } from "../../../../components/src/Common/Table/TableColumns";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d6f8f",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End
export default class RevisionRequestCsvImport extends RevisionRequestCsvImportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

// Customizable Area Start
  // Customizable Area End

  render() {
    const { 
      isUploadingCsvFile, 
      page, 
      totalPage, 
      alertMessage, 
      showAlert, 
      alertType, 
      revisionRequestCsvData, 
      errorMessage, 
      errorFile, 
      isLoading 
    } = this.state;
    
    return (
       // Customizable Area Start
       <ThemeProvider theme={theme}>
       <Container
         id="revisionRequestCSVImportPage"
         maxWidth="lg"
         dir={
           (window.localStorage.getItem("lang") || "en") === "ar"
             ? "rtl"
             : "ltr"
         }
       >
         {/* @ts-ignore */}
         <CSVImportDetails 
             importFor={this.state.policyInfo.type =="emp" ? "employee":"vehicle"} 
             handleAnotherCSVUpload={this.handleRevisionRequestAnotherCSVUpload}
             downloadFile={this.downloadRevisionRequestFile}
             isUploadingCsvFile={isUploadingCsvFile}
             page={page}
             totalPage={totalPage} 
             alertMessage={alertMessage} 
             alertType={alertType} 
             csvData={revisionRequestCsvData} 
             errorMessage={errorMessage} 
             errorFile={errorFile} 
             isLoading={isLoading}
             handleSaveCSVUpload={this.handleRevisionRequestSaveCSVUpload}
             handleCSVUploader={this.handleRevisionRequestCSVUploader}
             handleChangePage={this.handleRevisionRequestChangePage}
             showAlert={showAlert}
             handleRowPerPage={this.handleRevisionRequestRowPerPage}
             closeToast={this.closeRevisionRequestToast}
             tableBody={this.state.policyInfo.type=="emp"? EMPLOYEE_LIST_TABLE_BODY:VEHICLE_LIST_TABLE_BODY}
             downloadSampleCsv={this.downloadSampleCsvForRevisionRequest}
             />
       </Container>
     </ThemeProvider>
      // Customizable Area End
    );
    
  }
}
// Customizable Area Start
// Customizable Area End
