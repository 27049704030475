// Customizable Area Start
import React from "react";
import {
    Container,
    Paper,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ForgotPasswordForm } from "../../../components/src/EmailRegistration/ForgotPasswordForm";

import ForgotPasswordController, {
    Props,
} from "./ForgotPasswordController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    },
});

const componentStyles = {
    centerContent: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "10px",
        paddingBottom: "10px"
    },
    logoImage: {
        height: '200px',
        width: '200px'
    }
}

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            // Required for all blocks 
            <div className="mainDiv">
                <ThemeProvider theme={theme}>
                    <Container id="Container" maxWidth="sm">
                        <div style={componentStyles.centerContent} >
                            <img style={componentStyles.logoImage} src="/assets/images/SIIB_WHITE_BG.jpg" alt="logo" />
                        </div>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                padding: "10px 0px",
                                width: "100%"
                            }}
                        >
                            <Paper style={{ width: '100%' }} elevation={4} >
                                <div style={{ padding: "20px 20px" }}>
                                    <ForgotPasswordForm {...this.props}/>
                                </div>
                            </Paper>
                        </div>
                    </Container>
                </ThemeProvider>
            </div>
        );
    }
}
// Customizable Area End