// Customizable Area Start
import { t } from "i18next";
import ActiveLanguage from "../../../../components/src/Common/ActiveLanguage";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("../../config.json");

export interface Props {
// Customizable Area Start  
navigation: any;
id: string;
t: any;
classes: any;
// Customizable Area End
}

interface S {
  // Customizable Area Start
  showAlert: boolean;
  alertMessage: string;
  alertType: string;
  columnList:any[];
  openCreateTemplatePopUp:boolean;
  openSaveTemplatePopUp:boolean;
  columnNamesList:{value:string,label:string}[];
  selectedColumnName:{value:string,label:string};
  draggedIndex:any;
  newTemplateName:string;
  templteColumnList:any[];
  selectedTemplateId:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ExportCSVController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getColumnNamesListAPI:string="";
  getTemplateColumnListAPI:string="";
  createNewTemplatetAPI:string=""
  putTemplateAPI:string="";
  templateId:string|null="";
  getCSVExportAPI:string="";
  getColumnsList:string=""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
  ];

    this.state = {
      // Customizable Area Start
      columnList:[],
      openCreateTemplatePopUp:false,
      openSaveTemplatePopUp:false,
      columnNamesList:[],
      selectedColumnName:{value:"",label:""},
      draggedIndex:null,
      newTemplateName:"",
      showAlert: false,
      alertMessage: "",
      alertType: "",
      templteColumnList:[],
      selectedTemplateId:""
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start


  async componentDidMount() {
    super.componentDidMount();
    this.setState({columnList:[{
        id: 1,
        column_name_on_export:"",
        column_name: "",
        order_no:1,
        encodingList: []
      }]})
      const queryParams = new URLSearchParams(window.location.search);
      const insuranceType = queryParams.get('insuranceType');

      this.templateId=queryParams.get("templateID")
      if (this.templateId) {
        this.getTemplateColumnListAPI = this.csvApiCall({
          contentType: 'application/json',
          method: 'GET',
          endPoint: `/bx_block_xmlcsvtemplatebuilder2/csv_templates/${this.templateId}/get_csv_template_columns`,
        });    
        
        this.getColumnsList = this.csvApiCall({
          contentType: 'application/json',
          method: 'GET',
          endPoint: `/bx_block_xmlcsvtemplatebuilder2/csv_templates?insurance_type=${insuranceType}`,
        }); 
      }
 
      this.getColumnNamesListAPI=this.csvApiCall({
        contentType: 'application/json',
        method: 'GET',
        endPoint: `/bx_block_xmlcsvtemplatebuilder2/csv_templates/get_column_names?insurance_type=${insuranceType}`,
    })
    
  }
  handleTemplateNameChange=(event:any)=>{
    this.setState({newTemplateName:event.target.value})
 }
  addColumn = () => {
    const nextId = this.state.columnList.length > 0 ? this.state.columnList[this.state.columnList.length - 1].id + 1 : 1;
    const columnData = {
      id: nextId,
      order_no: this.state.columnList.length+1,
      column_name: "",
      column_name_on_export:"",
      encodingList: []
    };
    this.setState({ columnList: [...this.state.columnList, columnData] });
  }
  

  removeColumn = (columnIndex: number) => {
    const { columnList } = this.state;
    const updatedColumnList = columnList.map(column => {
        if (column.id === columnIndex) {
            return { ...column, _destroy: 1 };
        }
        return { ...column };
    });
    let orderCounter = 1;
    for (const updatedColumn of updatedColumnList) {
        if (!updatedColumn._destroy) {
            updatedColumn.order_no = orderCounter;
            orderCounter++;
        }
    }
    this.setState({ columnList: updatedColumnList });
};

  

      
  removeEncoding = (columnIndex: string, encodingIndex: string | number): void => {
    this.setState(prevState => ({
        columnList: prevState.columnList.map(column => ({
            ...column,
            encodingList: column.id === columnIndex && column.encodingList.length > 0
                ? column.encodingList
                    .filter((encoding: any) => encoding.id !== encodingIndex)
                    .map((encoding: any, index: number) => ({ ...encoding, id: index + 1 }))
                : column.encodingList
        }))
    }));
};
updateEncodingValue = (columnId: string, encodingIndex: string | number, fieldName: string, value: any): void => {
  this.setState(prevState => ({
      columnList: prevState.columnList.map(column => ({
          ...column,
          encodingList: column.id === columnId
              ? column.encodingList.map((encoding:any) => ({
                  ...encoding,
                  [fieldName]: encoding.id === encodingIndex ? value : encoding[fieldName]
              }))
              : column.encodingList
      }))
  }));
};


       
      handleSaveTemplatePopUp=()=>{
        this.setState({openSaveTemplatePopUp:!this.state.openSaveTemplatePopUp})
}
saveTemplate=()=>{
  const formData=this.buildFormData()
  this.putTemplateAPI = this.csvApiCall({
      type: "FormData",
      method: 'PUT',
      body: formData,
      endPoint: `/bx_block_xmlcsvtemplatebuilder2/csv_templates/${this.templateId}`,
  });
  this.setState({ openSaveTemplatePopUp: false });
  
}
handleShowAlert=()=>{
  this.setState({showAlert:false})
}
saveTemplateAPIResponse = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.putTemplateAPI !== null &&
    this.putTemplateAPI ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const saveTemplateJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
         
        if (saveTemplateJson) {
          this.setState({
            alertMessage:t("template_saved_succefully"),
            alertType:"success",
            showAlert:true
          })
      }
    }
  }

      addEncoding = (index: string): void => {
        this.setState(prevState => {
          const { columnList } = prevState;
          const updatedColumnData = columnList.find(column => column.id === index);
      
          const encodingdata = {
            id: updatedColumnData.encodingList.length + 1,
            encoding_from: "",
            encoding_to: ""
          };
      
          const updatedColumnList = columnList.map(column =>
            parseInt(column.id) === parseInt(index)
              ? {
                  ...column,
                  encodingList: [...column.encodingList, encodingdata]
                }
              : column
          );
      
          return { columnList: updatedColumnList };
        });
      };
      handleColumnNameChange = (selectedOption: any, id: string | number, fieldName: string) => {
        let newValue = selectedOption;
        if (selectedOption && selectedOption.value && fieldName === 'column_name') {
            newValue = selectedOption.value;
        }
        this.setState(prevState => {
            const updatedList = prevState.columnList.map(item => {
                if (item.id === id) {
                    const updatedItem = { ...item };
                    if (fieldName === 'column_name') {
                        updatedItem[fieldName] = newValue;
                    } else if (fieldName === 'column_name_on_export') {
                        updatedItem[fieldName] = selectedOption;
                    }
                    return updatedItem;
                }
                return item;
            });
            return { columnList: updatedList };
        });
    }
    
      
      
    
      handleCreatetemplate=()=>{
        this.setState({openCreateTemplatePopUp:!this.state.openCreateTemplatePopUp})

      }
      
      buildFormData() {
        const { columnList} = this.state;
        const formData = new FormData();
        const queryParams = new URLSearchParams(window.location.search);
        const insuranceType=queryParams.get("insuranceType")
        formData.append("data[insurance_type]", `${insuranceType}`);
    
        columnList.forEach((ele, index) => {
          if(ele._destroy){
            formData.append(`data[csv_template_columns_attributes][${index}][_destroy]`, "1");

          }
            formData.append(`data[csv_template_columns_attributes][${index}][column_name]`, ele.column_name);
            formData.append(`data[csv_template_columns_attributes][${index}][column_name_on_export]`, ele.column_name_on_export);
            formData.append(`data[csv_template_columns_attributes][${index}][order_no]`, ele.order_no);
            if(ele.column_id){
              formData.append(`data[csv_template_columns_attributes][${index}][id]`,ele.column_id)
            }
            const encodings = ele.encodingList.reduce((acc:any, encoding:any) => {
                acc[encoding.encoding_from] = encoding.encoding_to;
                return acc;
            }, {});
            formData.append(`data[csv_template_columns_attributes][${index}][encodings]`, JSON.stringify(encodings));
        });
    
        return formData;
    }
      handleCreateNewTemplate = () => {
        let formData=this.buildFormData()
        formData.append("data[template_name]",`${this.state.newTemplateName}`)

        this.createNewTemplatetAPI = this.csvApiCall({
            type: "FormData",
            method: 'POST',
            body: formData,
            endPoint: `bx_block_xmlcsvtemplatebuilder2/csv_templates`,
        });
        this.setState({ openCreateTemplatePopUp: false });
    }
    
    createTemplateAPIResponse = (message: any) => {
            if (
              getName(MessageEnum.RestAPIResponceMessage) === message.id &&
              this.createNewTemplatetAPI !== null &&
              this.createNewTemplatetAPI ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
              ) {
                const createTemplateJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                  );
                   
                  if (createTemplateJson.data) {
                    this.setState({
                      alertMessage:ActiveLanguage()?"تم إنشاء القالب بنجاح":"Template created successfully",
                      alertType:"success",
                      showAlert:true
                    })
                }
              }
            }

      csvApiCall(data: any) {
        const { contentType, method, endPoint, body, type } = data;
        const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
        const token = userData.token || "";
        let csvheader: any = {
          token: token,
        };
        if(contentType) {
          csvheader = {
            ...csvheader,
            "Content-Type": contentType,
          }
        }
    
        const stageTwoRequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        stageTwoRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );     
        stageTwoRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(csvheader)
        );
        stageTwoRequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        body && type !== 'FormData' ?
          stageTwoRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          ) : stageTwoRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
          ) ;
        runEngine.sendMessage(stageTwoRequestMessage.id, stageTwoRequestMessage);
        return stageTwoRequestMessage.messageId;
    }

      getAllColumnNamesListAPIResponse = (message: any) => {
        if (
          getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.getColumnNamesListAPI !== null &&
          this.getColumnNamesListAPI ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
          ) {
            const getAllColumnNamesJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
               
                if (getAllColumnNamesJson.data) { 
                    const updatedColumnNameList=this.convertToOptionsArray(getAllColumnNamesJson.data)
                this.setState({columnNamesList:updatedColumnNameList})  
                }
          }
        }
        convertToOptionsArray(strings:string[]) {
          return strings.map(str => ({
            value: str,
            label: str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
          }));
        }
        getTemplateColumnsListAPIResponse = (message: any) => {
            if (
              getName(MessageEnum.RestAPIResponceMessage) === message.id &&
              this.getTemplateColumnListAPI !== null &&
              this.getTemplateColumnListAPI ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
              ) {
                const getTemplateColumnListJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                  );
                   
                  if (getTemplateColumnListJson.data) {
                    const updatedColumnList = getTemplateColumnListJson.data.sort((a:any, b:any) => a.order_no - b.order_no).map((column:any, index:number) => {
                        if (typeof column.encodings === 'object') {
                            const encodingList = Object.entries(column.encodings).map(([encoding_from, encoding_to], i) => ({
                                id: i + 1,
                                encoding_from,
                                encoding_to
                            }));
                            return { ...column, encodingList, column_id: column.id };
                        }
                        return { ...column, column_id: column.id };
                    });
                
                    this.setState({ columnList: updatedColumnList });
                }
              }
            }
      
        handleDragStart = (index:number) => {
                this.setState({draggedIndex:index})
              };
              handleDragOver = (index: number) => {
                const { draggedIndex, columnList } = this.state;
            
                if (draggedIndex === null || draggedIndex === index) return;
            
                const newList = [...columnList];
                const [removed] = newList.splice(draggedIndex, 1);
                newList.splice(index, 0, removed);
            
                const updatedList = newList.map((item, idx) => ({
                    ...item,
                    order_no: idx + 1
                }));
            
                this.setState({
                    draggedIndex: index,
                    columnList: updatedList
                });
            };
            
       
        handleDragEnd = () => {
            this.setState({draggedIndex:null})
              };
        
        handleDownloadfile=()=>{
          this.getCSVExportAPI = this.csvApiCall({
            contentType: 'application/json',
            method: 'GET',
            endPoint: `/bx_block_xmlcsvtemplatebuilder2/csv_templates/${this.templateId}/export_csv`,
          });
        }
        
        

        getCSVExportResponse = (message: any) => {
          if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getCSVExportAPI !== null &&
            this.getCSVExportAPI ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
            ) {
              const csavexportJson = message.getData(
                  getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                 
                if (csavexportJson.data) {
                  this.downloadFile(csavexportJson.data.csv_url)
              }
            }
          }
          downloadFile = (fileUrl: string) => {
            const link = document.createElement('a');
            link.href = fileUrl;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
          getTemplateColumnsListResponse = (message: any) => {
            if (
              getName(MessageEnum.RestAPIResponceMessage) === message.id &&
              this.getColumnsList !== null &&
              this.getColumnsList ===
              message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
              ) {
                const getAllTemplateListJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                  );
                   
                  if (getAllTemplateListJson.data) { 
                    const transformedData=getAllTemplateListJson.data.map((ele:any)=>{
                      return {value:ele.id.toString(),label:ele.template_name}
                    })
                    const queryParams = new URLSearchParams(window.location.search);

                  let templateId=queryParams.get("templateID")
                  const selectedTemplate=transformedData.filter((template:any)=>template.value===templateId)[0]
                  this.setState({templteColumnList:transformedData,selectedTemplateId:selectedTemplate},()=>console.log("temp",666,this.state.templteColumnList))  
                  }
              }
            }
            handleTemplateIDChange=(selectedTemplate:any)=>{
              this.templateId=selectedTemplate.value
                this.setState({selectedTemplateId:selectedTemplate},()=>{
                  this.getTemplateColumnListAPI = this.csvApiCall({
                    contentType: 'application/json',
                    method: 'GET',
                    endPoint: `/bx_block_xmlcsvtemplatebuilder2/csv_templates/${this.templateId}/get_csv_template_columns`,
                  });   
                })
            }
        async receive(from: string, message: Message) {
        this.getAllColumnNamesListAPIResponse(message);
        this.getTemplateColumnsListAPIResponse(message);
        this.createTemplateAPIResponse(message);
        this.saveTemplateAPIResponse(message)
        this.getCSVExportResponse(message);
        this.getTemplateColumnsListResponse(message)
      }
  // Customizable Area End
}
