// Customizable Area Start
import React from "react";
import { Container } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import StageOneController from "./StageOneController";
import StageOneForm from "../../../../components/src/Policy/StageOneForm";

const theme = createTheme({
    palette: {
        primary: {
            main: "#2d6f8f",
            contrastText: "#fff",
        },
    }
});

export default class StageOne extends StageOneController {
   
    render() {
        return (
            <ThemeProvider theme={theme} >
                <Container id="customerContainer" maxWidth="lg" dir={(window.localStorage.getItem('lang') || 'en') === "ar" ? "rtl" : "ltr"} >

                    {/* @ts-ignore */}
                    <StageOneForm {...this.props} />

                </Container>
            </ThemeProvider>
        );
    }
}
// Customizable Area End