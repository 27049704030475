import React, { useEffect } from 'react';
import { Button, Grid, TextField } from "@material-ui/core";
import { GetApp, InsertDriveFile, Close } from "@material-ui/icons";
import { useField } from "formik";
import { makeStyles } from '@material-ui/styles';
import Toster from '../Common/Toster';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  fileExistDesign: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid rgba(118, 118, 118, 0.5)",
    borderRadius: "5px",
    marginTop: "10px",
    height: "55px",
    padding: "15px"
  },
  fileNameStyle:{
    width:"80%"
  },
  uploadFileInput: {
    marginTop: "10px",
    boxShadow: 'none',
    border: "dashed lightgrey 2px",
    height: "55px",
    background: "transparent",
    width: "100%",
    textTransform: "none"
  },
  disabledUploadFileInput: {
    marginTop: "10px",
    boxShadow: 'none',
    border: "dashed lightgrey 2px",
    height: "55px",
    width: "100%",
    textTransform: "none",
    background: "hsl(0, 0%, 95%)",
    pointerEvents: "none"
  },
  downloaIcon: {
    cursor: "pointer"
  },
  textDesign:{
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textTransform: "none"
  }
})

const FileUpload = (props: any) => {
  const {t} = useTranslation();
  const [input, setInput] = React.useState<any>({});
  const [fileUrl, setFileUrl] = React.useState("");
  const [fileName, setFileName] =  React.useState("");
  const [errorMsg, setErrorMsg] = React.useState<string>('')
  const [showToaster, setShowToaster] = React.useState<boolean>(false)
  const [allowedFileTypes] = React.useState<string[]>(['.jpg', '.jpeg', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.csv'])
  const classes = useStyles();

  React.useEffect(() => {
    if (props.filePath === "" || (typeof props.filePath === "object" && Object.keys(props.filePath).length === 0)) {
     setFileName("")
      return;
    } else {
      (props.filePath);
      const getFileName = async () => {
        try {
          const fileData = await fetch(props.filePath, { method: 'GET' });
          const contentDisposition = await fileData.headers.get('content-disposition');
          // @ts-ignore
          const match = await contentDisposition.match(/filename="(.+)"/);
          // @ts-ignore
          const filename = await match ? match[1] : 'file';
          setFileName(filename)
          setFileUrl(fileData?.url)
        } catch (error) {
          console.log(error);
        }
      }
      getFileName()
    }
  });

  const handleFileUpload = (e: any) => {
    if(props.disabled)return
    const file = e.target.files[0];
    const fileSize = file.size; // size in bytes
    const alowedFileSize = props.maxSizeInMB? props.maxSizeInMB: 10;
    const maxSize = alowedFileSize * 1024 * 1024; // 10MB in bytes

    if (fileSize <= maxSize) {
      setInput(file);
      props.onChange(e);
    } else {
      setShowToaster(true)
      setErrorMsg(`File size should be less than or equal to ${alowedFileSize} MB`)
      return
    }
  };


  const downloadFile = async () => {
    // @ts-ignore
    if (input && input?.name) {
      // @ts-ignore
      const blob = new Blob([input], { type: input.type });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      // @ts-ignore
      link.download = input.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      const response = await fetch(fileUrl, { method: 'GET' });
      const contentDisposition = response.headers.get('content-disposition');
      // @ts-ignore
      const match = contentDisposition?.match(/filename="(.+)"/);
      const filename = match ? match[1] : 'file';
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${filename}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  }

  const showDownloadText = () => {
    if(props.disabled)return
    const filePath = typeof props.filePath === "string" && props.filePath;
    // @ts-ignore
    const fileData = input && input.name;
    if(filePath || fileData){
      return true;
    } else {
      return false;
    }

  }


  return (
    // @ts-ignore
     <div>

     <Toster
        tosterText={errorMsg}
        showToster={showToaster}
        tosterType={'error'} // error, success, warning, info
        handleCloseToster={() => setShowToaster(false)}
      />

      {(input?.name || fileName ) ? (
      <Grid className={classes.fileExistDesign}>
        <Button className={classes.fileNameStyle} component="label" >
          <Grid item>
            <InsertDriveFile />
            <input name={props.inputName} disabled={props.disabled} hidden onChange={handleFileUpload} accept={allowedFileTypes.join(',')}  type="file" />
          </Grid>
          <Grid className={classes.textDesign} item>
            {/* @ts-ignore */}
           {input.name ? input.name : props.fileName || fileName}
          </Grid>
        </Button>
        {!props.disabled ? (<Grid item>
          <GetApp className={classes.downloaIcon} onClick={(e) => {
            e.stopPropagation();
            downloadFile()
          }} />
          <Close className={classes.downloaIcon} onClick={(e) => {
            e.stopPropagation();
            setInput({})
            setFileUrl("")
            setFileName('')
            props.onRemove()
          }} />
        </Grid>):(<Grid item>
          <GetApp className={classes.downloaIcon} onClick={(e) => {
            e.stopPropagation();
            downloadFile()
          }} />
        </Grid>)}

      </Grid>
    ) : (
      <Button className={props.disabled ? classes.disabledUploadFileInput : classes.uploadFileInput} component="label" disabled={props.disabled} >
        {t('upload_file_text')}
        <input name={props.inputName} hidden onChange={handleFileUpload} accept={allowedFileTypes.join(',')} type="file" />
      </Button >
    )}
     </div>

  )
}

export default FileUpload