import React from 'react'
import { useTranslation } from 'react-i18next';

const GetHeadingTitle = (isEdit:boolean,isCancel:any) =>{
    const { t } = useTranslation();
    if (isEdit) {
        return isCancel === "cancel" ? t('are_you_sure') : t('save_changes')
    } else {
        return isCancel === "cancel" ? t('are_you_sure') : t('add_customer')
    }
}

export const GetParagraphTxt = (isEdit:boolean,isCancel:any) => {
    const { t } = useTranslation();
    if (isEdit) {
        return isCancel === "cancel" ? t('leave_page_without_save_text') : t('do_you_want_to_save_the_changes')
    } else {
        return isCancel === "cancel" ? t('leave_page_without_save_text') : t('are_you_sure_you_want_to_add_this_customer')
    }
}

export const GetBtnTxt = (isEdit:boolean,isCancel:any) => {
    const { t } = useTranslation();
    if (isEdit) {
        return isCancel === "cancel" ? t('leave') : t('save')
    } else {
        return isCancel === "cancel" ? t('leave') : t('add')
    }
}


export default GetHeadingTitle;