import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t: any;
  classes: any;
  importFor: string;
  isRevisionRequestUploadingCsvFile: boolean,
  revisionRequestPage: number;
  revisionRequestRowPerPage: number;
  revisionRequestTotalPage: number;
  isLoading:boolean;
  revisionRequestCsvData:object[],
  revisionRequestShowAlert: boolean,
  revisionRequestAlertMessage: string;
  revisionRequestAlertType: string;
  revisionRequestErrorMessage: string;
  revisionRequestErrorFile: string;
  isRevisionRequestSaveBtnClicked: boolean;
  handleRevisionRequestAnotherCSVUpload: any;
  downloadRevisionRequestFile: any,
  handleRevisionRequestSaveCSVUpload: any;
  handleRevisionRequestCSVUploader: any;
  handleRevisionRequestChangePage: any;
  handlerevisionRequestRowPerPage: any;
  revisionRequestTableBody: any;
  closeRevisionRequestToast: any 
  // Customizable Area End

}

interface S {
  // Customizable Area Start
  isArabicLanguage: boolean;
  isUploadingCsvFile: boolean;
  page: number;
  rowPerPage: number;
  totalPage: number;
  isLoading:boolean;
  revisionRequestCsvData:object[],
  showAlert: boolean,
  alertMessage: string;
  alertType: string;
  errorMessage: string;
  errorFile: string;
  isSaveBtnClicked: boolean;
  policyInfo:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RevisionRequestCsvImportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadRevisionRequestCsvAPIMessage: string = '';
  getUploadedRevisionRequestCsvAPIMessage: string = '';
  saveRevisionRequestCsvAPIMessage: string = ''
  revisionRequestFileId: any;
  deleteRevisionRequestCsvAPIMessage: string = '';
  sampleCsvRevisionRequestAPIKey: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
     // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage)

    ];

    this.state = {
     // Customizable Area Start
     isArabicLanguage:
        (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      isUploadingCsvFile: false,
      page: 1,
      rowPerPage: 10,
      totalPage: 1,
      isLoading:false,
      revisionRequestCsvData:[],
      alertMessage: '',
      showAlert: false,
      alertType: '',
      errorMessage: '',
      errorFile: '',
      isSaveBtnClicked: false,
      policyInfo:{}
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
 
    // Customizable Area Start
    // Customizable Area End
  }

  

 
  async receive(from: string, message: Message) {
     // Customizable Area Start
     this.setUpdateRevisionRequestCsv(message);
     this.setSaveRevisionRequestCsv(message);
     this.fetchRevisionRequestCsvList(message);
     this.deleteRevisionRequestUploadedCsvList(message);
     this.getNavigationParamsCSVImport(message)
     this.receiveDownloadedSampleCsvForRevisionRequest(message)
     // Customizable Area End

  }
  
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
   
    
  }
// Customizable Area Start

handleRevisionRequestCSVUploader=(file: any)=>{
  this.setState({isUploadingCsvFile:true})
  this.uploadRevisionRequestCsv(file)
}

closeRevisionRequestToast = () => {
  this.setState({ showAlert: false })
}

handleRevisionRequestAnotherCSVUpload=(file: any)=>{
  this.uploadRevisionRequestCsv(file, this.revisionRequestFileId)
}

handleRevisionRequestSaveCSVUpload=()=>{
  this.setState({isSaveBtnClicked: true})
  this.saveRevisionRequestCsv(this.revisionRequestFileId)
}

handleRevisionRequestChangePage = (event: any, page: number) => {
  this.setState({ page: page });
};

handleRevisionRequestRowPerPage = (event: any) => {
  this.setState({ rowPerPage: parseInt(event.target.value) });
  this.setState({ page: 1 });
};

downloadRevisionRequestFile = (fileUrl: string) => {
  
  const revisionRequestlink = document.createElement('a');
  revisionRequestlink.href = fileUrl;
  revisionRequestlink.style.display = 'none';
  document.body.appendChild(revisionRequestlink);
  revisionRequestlink.click();
  document.body.removeChild(revisionRequestlink);
} 

setAlertRevisionRequest = (responseMessage: string,alertType: string = 'error') => {
  this.setState({ showAlert: true, alertMessage: responseMessage, alertType: alertType})
}

async componentDidUpdate(
  prevProps: Readonly<Props>,
  prevStateRevisionRequest: Readonly<S>,
  snapshot?: SS | undefined
) {
  if (
    (prevStateRevisionRequest.page !== this.state.page ||
      prevStateRevisionRequest.rowPerPage !== this.state.rowPerPage)
  ) {
    const {page, rowPerPage} = this.state
    this.getRevisionRequestUploadedCsv(page, rowPerPage);
  }

}

getRevisionRequestFormData = (file :  any, file_id: number| string) => {
  const formData = new FormData();
  file && formData.append('data[file]',file)
  file_id && formData.append('data[file_id]', file_id as string)
  
  return formData
}

uploadRevisionRequestCsv = (file: any, file_id?: number) => {
  this.setState({
    isLoading:true
  })
  this.uploadRevisionRequestCsvAPIMessage = this.apiRevisionRequestCall({
    type : 'FormData',
    method: 'POST',
    body: this.getRevisionRequestFormData(file, file_id as number),
    endPoint: `bx_block_revision_request/revision_requests/upload_csv?id=${this.state.policyInfo.id}`,
  })
}

getRevisionRequestUploadedCsv = (page: number, perPage: number) => {
  this.getUploadedRevisionRequestCsvAPIMessage = this.apiRevisionRequestCall({
    type : 'application/json',
    method: 'GET',
    endPoint: `bx_block_revision_request/revision_requests/fetch_csv_uploaded_data?page=${page}&per_page=${perPage}&file_id=${this.revisionRequestFileId}&id=${this.state.policyInfo.id}`,
  })
}

saveRevisionRequestCsv = (file_id: number ) => {
  this.saveRevisionRequestCsvAPIMessage = this.apiRevisionRequestCall({
    type : 'FormData',
    method: 'PUT',
    body: this.getRevisionRequestFormData(null, file_id),
    endPoint: `bx_block_revision_request/revision_requests/update_csv_uploaded_data?id=${this.state.policyInfo.id}`
  })
}

destroyRevisionRequestUploadedCsv = (fileId: string) => {
  this.deleteRevisionRequestCsvAPIMessage = this.apiRevisionRequestCall({
    type : 'application/json',
    method: 'DELETE',
    endPoint: `bx_block_revision_request/revision_requests/destroy_csv_uploaded_data?file_id=${fileId}&id=${this.state.policyInfo.id}`
  })
}

apiRevisionRequestCall(data: any) {
  const { contentType, method, endPoint, body, type } = data;
  const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
  const token = userData.token || "";

  let header: any = {
    token: token,
    "Content-Type": contentType
  };

  const revisionRequestCsvImportMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  revisionRequestCsvImportMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  revisionRequestCsvImportMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  revisionRequestCsvImportMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );
  body && type !== 'FormData' ?
  revisionRequestCsvImportMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    ) : revisionRequestCsvImportMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    ) ;

  runEngine.sendMessage(revisionRequestCsvImportMessage.id, revisionRequestCsvImportMessage);
  return revisionRequestCsvImportMessage.messageId;
}
getNavigationParamsCSVImport =(message:any)=>{
  if(getName(MessageEnum.NavigationPayLoadMessage)===message.id){
    const naviParams = message.getData(getName(MessageEnum.RevisionRequestPayload))
    this.setState({policyInfo:JSON.parse(naviParams)})
  }
}
setUpdateRevisionRequestCsv = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.uploadRevisionRequestCsvAPIMessage !== null &&
    this.uploadRevisionRequestCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const updateRevisionRequestCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
    try {
      if(updateRevisionRequestCsvAPIResponse?.data){
        if (updateRevisionRequestCsvAPIResponse?.data.error_message || updateRevisionRequestCsvAPIResponse?.data.error_file) {
          this.setState({errorMessage: updateRevisionRequestCsvAPIResponse?.data.error_message, errorFile: updateRevisionRequestCsvAPIResponse?.data.error_file})
          this.setAlertRevisionRequest(updateRevisionRequestCsvAPIResponse?.data.error_message)
        } 
        if(updateRevisionRequestCsvAPIResponse?.data.file_id) {
          if(!updateRevisionRequestCsvAPIResponse?.data.error_message){
            this.setState({errorMessage: '', errorFile: ''})
          }
          window.localStorage.setItem("insurer_file_id", JSON.stringify(updateRevisionRequestCsvAPIResponse?.data?.file_id))
          this.revisionRequestFileId = updateRevisionRequestCsvAPIResponse?.data?.file_id;
          this.getRevisionRequestUploadedCsv(this.state.page, this.state.rowPerPage)
        }
      } else {
        this.setAlertRevisionRequest(updateRevisionRequestCsvAPIResponse?.error)
      }
    } catch (error) {
      //ignore
    } finally {
      this.setState({isLoading:false})
    }
  }
}
navigateToRevisionRequestScreen =()=>{
  const msg: Message = new Message(
    getName(MessageEnum.NavigationRevisionRequestMessage)        
  );
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
  msg.addData(getName(MessageEnum.RevisionRequestIdPayload), JSON.stringify({id: this.state.policyInfo.id,isTable:true}));
  this.send(msg);
}
setSaveRevisionRequestCsv = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.saveRevisionRequestCsvAPIMessage !== null &&
    this.saveRevisionRequestCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const saveRevisionRequestCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
  );

    if (saveRevisionRequestCsvAPIResponse?.message) {
      this.setAlertRevisionRequest(saveRevisionRequestCsvAPIResponse?.message, 'success')
      this.revisionRequestFileId = ''
      setTimeout(() => {
        this.navigateToRevisionRequestScreen()
      }, 700)
    } else {
      this.setAlertRevisionRequest(saveRevisionRequestCsvAPIResponse.errors)
    }
  }
}

fetchRevisionRequestCsvList = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.getUploadedRevisionRequestCsvAPIMessage !== null &&
    this.getUploadedRevisionRequestCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const fetchRevisionRequestCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (fetchRevisionRequestCsvAPIResponse?.data) {
      this.setState({isUploadingCsvFile: true})
      this.setState({revisionRequestCsvData: fetchRevisionRequestCsvAPIResponse.data, totalPage: fetchRevisionRequestCsvAPIResponse.meta.total_page})
    } else {
      this.setAlertRevisionRequest(fetchRevisionRequestCsvAPIResponse.errors)
    }
  }
}

deleteRevisionRequestUploadedCsvList = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.deleteRevisionRequestCsvAPIMessage !== null &&
    this.deleteRevisionRequestCsvAPIMessage ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const deleteRevisionRequestCsvAPIResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (deleteRevisionRequestCsvAPIResponse?.message) {
      this.setAlertRevisionRequest(deleteRevisionRequestCsvAPIResponse?.message);
    } else {
      this.setAlertRevisionRequest(deleteRevisionRequestCsvAPIResponse?.errors)
    }
  }
}

downloadSampleCsvForRevisionRequest = () => {
  this.sampleCsvRevisionRequestAPIKey = this.apiRevisionRequestCall({
    type: "application/json",
    method: "GET",
    endPoint: `/bx_block_sample_file/sample_files?name=${this.state.policyInfo.type === 'emp' ? 'Employee' : 'Vehicle'}`,
  });
};

  receiveDownloadedSampleCsvForRevisionRequest = (message: any) => {
  if (
    getName(MessageEnum.RestAPIResponceMessage) === message.id &&
    this.sampleCsvRevisionRequestAPIKey !== null &&
    this.sampleCsvRevisionRequestAPIKey ===
    message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
  )   {
    const saveSampleCsv = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (saveSampleCsv?.url) {
      this.downloadRevisionRequestFile(saveSampleCsv?.url)
    } else {
      this.setAlertRevisionRequest(saveSampleCsv?.error)
    }
  }

}

setArabicLanguage = (value: boolean) => {
  const language = value === true ? "ar" : "en";
  window.localStorage.setItem("lang", language);
  this.setState({
    isArabicLanguage: value
  });
};
componentWillUnmount(): any {
  this.revisionRequestFileId && this.destroyRevisionRequestUploadedCsv(this.revisionRequestFileId)
}
  // Customizable Area End
}
