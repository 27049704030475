// Customizable Area Start
import { IBlock } from "../../../../../../framework/src/IBlock";
import { Message } from "../../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../../framework/src/RunEngine";
import getStageNameByNumber, { getStageNumberByName } from "../../../../../../components/src/Common/PolicyRequest/StagesName";

export interface Props {
  isExpanded?: boolean;
  isEdit?: boolean;
  onNext?: any;
  onReject?: any;
  t?: any;
  classes?: any;
  setStageData?: any;
  rrStageOneData?: any;
  rrStageThreeData?: any;
  policyId: string;
  rrStageTwoData?: any;
  handOverNoteForAwaiting?: any;
}

interface S {
  policyId: string;
  isArabicLenguage: boolean;
  expandedAccordion: boolean;
  handOverNoteText: string;
  rejectReasonText: string;
  showNoteDialog: boolean;
  showRejectDialog: boolean;
  isBtnVisible: boolean;
  showNextDialog: boolean;
  moveToStep: any;
  showAlert: boolean;
  alertMessage: string;
  alertType: string;
  isDraftAPIUsed: boolean;
  hasViewPermissionList: string[];
  hasUpdatePermissionList: string[];
  hasCreatePermissionList: string[];
  awaitingComment: string;
  disableAccordion: boolean;
  saveDraftLoading: boolean;
  loading:boolean;
  isFormSubmitted: boolean;
  trackFor: string;
}

interface SS {
  id: any;
}
// Customizable Area End

export default class StageThreeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUpdatedCommentAPI: any = "";
  saveUpdatedCommentAPI: any = "";
  modifiedUpdatedCommentAPI: any = "";
  trackRequestAPI: any = "";
  policyId: number | null = null;
  stageTwoAPIResponse: any = null;
  submitFormDataApi: any = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      policyId: this.props.rrStageOneData.data.attributes.id || "",
      isArabicLenguage:
        (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      expandedAccordion: this.props.isExpanded || false,
      handOverNoteText: "",
      rejectReasonText: "",
      showNoteDialog: false,
      showRejectDialog: false,
      isBtnVisible: true,
      moveToStep: null,
      showAlert: false,
      alertMessage: "",
      alertType: "",
      isDraftAPIUsed: false,
      hasViewPermissionList: this.props.rrStageTwoData?.meta?.next_stage_view_permission_role || [],
      hasCreatePermissionList: this.props.rrStageTwoData?.meta?.next_stage_view_permission_role || [],
      hasUpdatePermissionList: this.props.rrStageTwoData?.meta?.next_stage_create_permission_role || [],
      awaitingComment: this.props.rrStageThreeData?.data?.attributes.post_updates || "",
      disableAccordion: false,
      saveDraftLoading: false,
      isFormSubmitted: false,
      showNextDialog: false,
      loading:false,
      trackFor:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    if(this.props.rrStageThreeData?.data?.attributes?.is_next_stage_submitted) this.setState({expandedAccordion: false})
  }
  componentDidUpdate(
    prevProps: Readonly<Props>
  ) {
    if (this.state.showAlert) {
      setTimeout(() => {
        this.setState({
          showAlert: false,
          alertType: "",
          alertMessage: "",
        });
      }, 50000);
    }
    if (prevProps.isExpanded !== this.props.isExpanded) {
      this.setState({expandedAccordion: this.props.isExpanded as boolean})
    }
  }

  getStepName = getStageNameByNumber;
  getStepNumber = getStageNumberByName;
  setApiErrorMessage = (responseJson: any) => {
    if (!responseJson) return;
    let errorMessage = "Something went wrong"
    if (responseJson?.errors) {
      errorMessage = typeof (responseJson.errors) === "string" ? responseJson.errors : responseJson.errors.error || "Something went wrong";
    }
    this.setState({
      alertType: "error",
      alertMessage: errorMessage,
      showAlert: true,
      saveDraftLoading: false,
      showRejectDialog: false,
      showNoteDialog: false,
      showNextDialog: false
    })
  }
  apiCallStageThree(data: any) {
    const { contentType, method, endPoint, body } = data;
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const requestMessageForRvRequestStageThree = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForRvRequestStageThree.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageForRvRequestStageThree.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForRvRequestStageThree.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
    requestMessageForRvRequestStageThree.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(requestMessageForRvRequestStageThree.id, requestMessageForRvRequestStageThree);
    return requestMessageForRvRequestStageThree.messageId;
  }

  checkStageThree = () => {
    return this.state.expandedAccordion && this.props.rrStageThreeData?.data?.attributes?.is_reject
  }

  handleAccordionStageThree = () => {
    if (!this.hasOnlyViewRightsStageThree()) {
      const { role } = JSON.parse(
        window.localStorage.getItem("loginData") || "{}"
      );
      this.setAlertStageThree(
        `Awaiting revision is not accessible to ${role} role. Please login with other role`,
        "warning"
      )
      return;
    }
    this.setState((prev: any) => ({
      ...prev,
      expandedAccordion: !prev.expandedAccordion,
    }));
  };

  hasOnlyViewRightsStageThree = () => {
    const { role } = JSON.parse(
      window.localStorage.getItem("loginData") || "{}"
    );

    return (this.state.hasViewPermissionList.includes(role)) as boolean;
  };

  hasOnlyEditRightsStageThree = () => {
    const { role } = JSON.parse(
      window.localStorage.getItem("loginData") || "{}"
    );

    return (this.state.hasUpdatePermissionList.includes(role)) as boolean;
  };
  hasOnlyCreateRightsStageThree = () => {
    const { role } = JSON.parse(
      window.localStorage.getItem("loginData") || "{}"
    );

    return (this.state.hasCreatePermissionList.includes(role)) as boolean;
  };

  handleHandOverNoteTextStageThree = (inputText: string) => {
    this.setState({ handOverNoteText: inputText });
  };
  handleRejectReasonTextStageThree = (inputText: string) => {
    this.setState({ rejectReasonText: inputText });
  };
  closeNoteDialogStageThree = () => {
    this.setState({ showNoteDialog: false, handOverNoteText:"" });
  };
  openNoteDialogStageThree = () => {
    this.setState({ showNoteDialog: true });
  };
  openRejectDialogStageThree = () => {
    this.setState({ showRejectDialog: true });
  };
  closeRejectDialogStageThree = () => {
    this.setState({ showRejectDialog: false, rejectReasonText:"", moveToStep:"", isBtnVisible: true });
  };
  closeNextDialogStageThree = () => {
    this.setState({ showNextDialog: false, handOverNoteText: "", expandedAccordion: false })
  }
  moveToSelectedStepStageThree = (step: any) => {
    this.setState({ moveToStep: step });
  };

  setAlertStageThree = (responseJson: any, alertType: string = "error") => {
    this.setState({
      showAlert: true,
      alertMessage: responseJson,
      alertType: alertType,
    });
  };
  receive(from: string, message: Message) {
    this.submitFormDataApiReceive(message);
    this.trackRequestAPIReceive(message);
  }
  submitFormDataApiReceive = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.submitFormDataApi !== null &&
      this.submitFormDataApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const alertMessage = this.getAlertMessaegForSaveData();
      if (responseJson.data) {
        this.setState({
          showRejectDialog: false,
          showNoteDialog: false,
          isFormSubmitted: true,
          saveDraftLoading:false
        })
        if (responseJson.data.attributes.save_as_draft) this.setState({
          showAlert: true,
          alertMessage: alertMessage,
          alertType: "success",
        })
        this.props.setStageData('stageThree',responseJson)
      } else {
        this.setApiErrorMessage(responseJson)
      }
    } else {
      this.setApiErrorMessage(undefined)
    }
  }
  getAlertMessaegForSaveData = () => {
    let alertMessage = "";
    if (this.state.saveDraftLoading) {
      alertMessage = this.state.isArabicLenguage ? "تم حفظ بيانات طلب المراجعة كمسودة!" : "Revision request data has been saved as draft!"
    } else {
      alertMessage = this.state.isArabicLenguage ? "لقد تم نقل طلبك إلى مراجعة العمليات" : `Your request has been moved to operations review!`
    }
    return alertMessage
  }
  trackRequestAPIReceive = (message: any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.trackRequestAPI !== null &&
      this.trackRequestAPI ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.data) {
        if (this.state.trackFor === "next") {
          this.props.setStageData("handOverNoteForOperationsReview",this.state.handOverNoteText)
          this.closeNextDialogStageThree();
          this.props.onNext(3)
        } else {
          const stageNumber = this.getStepNumber(this.state.moveToStep.value.toLowerCase())
          this.closeRejectDialogStageThree()
          this.props.onReject(stageNumber);
        }
        let alertMessage =  this.getAlertMessageForTrackRequest();
        this.setState({
          showAlert: true,
          alertMessage: alertMessage,
          alertType: "success",
          showRejectDialog: false,
          showNextDialog: false,
        })

        this.closeRejectDialogStageThree()
      } else {
        this.setApiErrorMessage(responseJson)
      }
    } else {
      this.setApiErrorMessage(undefined)
    }
  }
  getAlertMessageForTrackRequest = () => {
    let alertMessage;
    if (this.state.trackFor === "next") {
      alertMessage = this.state.isArabicLenguage ? "لقد تم نقل طلبك إلى مراجعة العمليات" : "Your request has been moved to operations review!"
    } else {
      alertMessage = this.state.isArabicLenguage ? "لقد تم نقل طلبك إلى المرحلة المحددة" : `Your request has been moved to selected stage!`
    }
    return alertMessage
  }
  checkUserPermission = () => {
    let permission = "yes";
    if (this.props.isEdit) {
      if (!this.hasOnlyEditRightsStageThree()) {
        const { role } = JSON.parse(
          window.localStorage.getItem("loginData") || "{}"
        );
        this.setAlertStageThree(
          this.state.isArabicLenguage ? `ليس لدى دور ${role} إذن لتحرير بيانات النموذج` : `${role} role has no permission for edit form data`,
          "warning"
        )
        permission = "no"
      }
    } else {
      if (!this.hasOnlyCreateRightsStageThree()) {
        const { role } = JSON.parse(
          window.localStorage.getItem("loginData") || "{}"
        );
        this.setAlertStageThree(
          this.state.isArabicLenguage ? `دور ${role} ليس لديه إذن بإرسال النموذج` : `${role} role has no permission for submit form`,
          "warning"
        )
        permission = "no"
      }
    }
    return permission
  }
  submitFormData = (saveAsDraft:boolean) => {
    const userLoginData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
    const loginToken = userLoginData.token || '';
    const body :any = {
      "data": {
        "revision_awaiting_quotation": {
          "save_as_draft": saveAsDraft ? "yes" : "no",
        }
      }
    };

    if (this.state.awaitingComment !== "") {
      body.data.revision_awaiting_quotation.post_updates = this.state.awaitingComment;
    }
    this.submitFormDataApi = this.apiCallStageThree({
      contentType: 'application/json',
      method: (this.props.isEdit || this.state.isFormSubmitted) ? 'PUT' : 'POST',
      endPoint: (this.props.isEdit || this.state.isFormSubmitted) ? `bx_block_revision_request/revision_requests/${this.state.policyId}?stage=3` : `bx_block_revision_request/revision_requests?id=${this.state.policyId}&stage=3`,
      token: loginToken || '',
      body: body
    })
  }
  trackRequest = (action:string) => {
    this.setState({trackFor:action})
    const userData = JSON.parse(window.localStorage.getItem('loginData') || '{}');
		const token = userData.token || '';
		this.trackRequestAPI = this.apiCallStageThree({
			contentType: 'application/json',
			method: 'POST',
			endPoint: `bx_block_revision_request/revision_requests/${this.state.policyId}/track_revision_requests?stage=3`,
			token: token || '',
			body: {
				"data": {
					"track_revision_request": {
						"stage_status": (action === "next") ? "next_stage" : "reject_stage",
						"comment": (action === "next") ? this.state.handOverNoteText : this.state.rejectReasonText,
            "current_stage": (action === "next") ? "operations_review" : (this.state.moveToStep && this.state.moveToStep.value) ? this.state.moveToStep.value.toLowerCase() : ""
					}
				}
			}
		});
  }
  updateStageThreeData = (saveAsDraft:boolean) => {

    const isUserHasPermission = this.checkUserPermission();
    if (isUserHasPermission === "no") return

    if (this.state.policyId) {
      this.submitFormData(saveAsDraft)
      this.setState({
        showNextDialog: !saveAsDraft,
        saveDraftLoading: saveAsDraft,
      });
    }
  }

  // Customizable Area End
}
