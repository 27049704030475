import React from "react";

import {
    Button,
    Typography,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Grid,
    Link,
    FormControlLabel,
    Checkbox,
    SvgIcon,
} from "@material-ui/core";
import { Formik, useField } from "formik";
import * as Yup from 'yup';
import { CheckCircle, VisibilityOff, Visibility, CancelRounded, ErrorRounded } from '@material-ui/icons';
import { Link as Redirect } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import EmailRegistrationFormSchema from "./EmailRegistrationFormSchema";
const SaudiFlagSVG = require('../../../web/public/assets/images/icons/saudi-arabia-flag-icon.svg');

const componentStyles = {
    labelStyle: { marginTop: "15px" },
    centerContent: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "25px",
    },
    submitButton: {
        background: "linear-gradient(to bottom, #2d6f8f, #4fb7ba)",
        "text-transform": "none",
        "color": "white",
        "width": "200px",
        "height": "50px",
        borderRadius: "10px"
    },
    passwordValidationStyleDiv: {
        display: "flex"
    },
    ArabicCheckdivStyle: {
        marginRight: "15px",
        marginTop: "20px"
    },
    displayFlex: {
        display: "flex"
    },
    countryLogoStyleForPhoneInput: {
        paddingRight: "5px"
    },
    phoneInputLine: {
        borderLeft: "2px solid gray",
        height: "25px",
        marginLeft: "5px"
    },
    loginLinkStyle: { color: "black", textDecoration: "none" }
}
const TextFieldControl = (props: any) => {
    const [field, meta] = useField(props);

    return (
        <OutlinedInput
            style={{
                marginTop: "10px",
                marginBottom: "10px"
            }}
            fullWidth
            type={props.type || 'text'}
            autoComplete="none"
            {...field}
            {...props}
            error={meta.error && meta.touched}
            testID={props.testIDProps}
        />
    );
};

const EmailRegistrationForm = (props: any) => {
    return (
        <div date-test-id="emailRegitrationForm">
            <Formik
                initialValues={{
                    firstName: props.firstName,
                    lastName: props.lastName,
                    phoneNumber: props.phoneNumber,
                    password: "",
                    email: props.email,
                    termAndCondition: false
                }}
                validationSchema={Yup.object().shape(EmailRegistrationFormSchema(props.isArabicLenguage))}
                validateOnMount={true}
                validateOnBlur={true}
                onSubmit={async (values, actions) => {
                    props.signUpUser(values)
                    await actions.setSubmitting(false);
                    await actions.resetForm();
                }}
            >
                {({
                    handleChange,
                    handleSubmit,
                    errors,
                    setFieldTouched,
                    touched,
                    values,
                    resetForm
                }) => (
                    <React.Fragment>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ paddingTop: '25px' }} >
                                <Button style={{
                                    color: !props.isArabicLenguage ? 'rgba(45, 111, 143, 1)' : 'black',
                                    textTransform: "none",
                                    textDecoration: !props.isArabicLenguage ? "underline" : "",
                                    fontWeight: !props.isArabicLenguage ? 'bolder' : 'normal',
                                    fontSize: "15px"
                                }} data-test-id="switchToEngBtn" onClick={() => {
                                    resetForm()
                                    props.setArabicLanguage(false)
                                }} color={!props.isArabicLenguage ? 'primary' : 'default'}>English</Button> |
                                <Button style={{
                                    color: props.isArabicLenguage ? 'rgba(45, 111, 143, 1)' : 'black',
                                    fontWeight: props.isArabicLenguage ? 'bolder' : 'normal',
                                    fontSize: "15px"
                                }} data-test-id="switchtoArbBtn" onClick={() => {
                                    resetForm()
                                    props.setArabicLanguage(true)
                                }} color={props.isArabicLenguage ? 'primary' : 'default'} >العربي</Button>
                            </div>
                            <div style={{ paddingTop: '5px' }} >
                                <Typography style={{ fontWeight: 600, paddingTop: "10px", paddingBottom: "25px" }} variant="h5" component="h5">
                                    {props.isArabicLenguage ? props.ArabicSignUpTitle : props.singnUpTitle}
                                </Typography>
                            </div>
                        </div>
                        <div>
                            {touched.termAndCondition && errors.termAndCondition ? (
                                <Alert style={{ width: "90%" }} icon={false} severity="error">
                                    {props.isArabicLenguage ? (
                                        <div style={componentStyles.displayFlex} >
                                            <ErrorRounded style={{ marginTop: "-3px", verticalAlign: "bottom" }} />
                                            <Typography variant="body1">{props.ArabicTermAndConditionErrorMessage}</Typography>
                                        </div>
                                    ) : (
                                        <div style={componentStyles.displayFlex} >
                                            <ErrorRounded style={{ marginTop: "-3px", verticalAlign: "bottom" }} />&nbsp; {props.termAndConditionErrorMessage}&nbsp;
                                        </div>
                                    )}
                                </Alert>
                            ) : null}
                            {props.apiError && (
                                <Alert style={{ width: "50%" }} icon={false} severity="error">
                                    {props.isArabicLenguage ? (
                                        <div style={componentStyles.displayFlex} >
                                            <CancelRounded style={{ marginTop: "-3px", verticalAlign: "bottom" }} />
                                            <Typography variant="body1">{props.ArabicPleaseTryAgainText}</Typography>
                                        </div>
                                    ) : (
                                        <div style={componentStyles.displayFlex} >
                                            <CancelRounded style={{ marginTop: "-3px", verticalAlign: "bottom" }} />&nbsp; {props.pleaseTryAgainText}&nbsp;
                                        </div>
                                    )}
                                </Alert>
                            )}
                            <Grid container spacing={2} >
                                <Grid style={componentStyles.labelStyle} item xs={6}>
                                    <label aria-controls="firstName" >{props.isArabicLenguage ? props.ArabicFirstNameText : props.firstNameText}</label><br />

                                    <TextFieldControl
                                        isArabicLenguage={props.isArabicLenguage}
                                        name="firstName"
                                        type="text"
                                        testIDProps="txtInputFirstName"
                                    />

                                    {touched.firstName && errors.firstName ? (
                                        <Typography color="error" >{errors.firstName}</Typography>
                                    ) : null}

                                </Grid>
                                <Grid style={componentStyles.labelStyle} item xs={6}>

                                    <label aria-controls="lastName" >{props.isArabicLenguage ? props.ArabicLastNameText : props.lastNameText}</label><br />
                                    <TextFieldControl
                                        isArabicLenguage={props.isArabicLenguage}
                                        name="lastName"
                                        type="text"
                                        testIDProps="txtInputLastName"
                                    />
                                    {touched.lastName && errors.lastName ? (
                                        <Typography color="error" >{errors.lastName}</Typography>
                                    ) : null}


                                </Grid>
                            </Grid>
                            <Grid style={componentStyles.labelStyle}>
                                <label aria-controls="phoneNumber" >{props.isArabicLenguage ? props.ArabicPhoneNumberText : props.phoneNumberText}</label><br />
                                <TextFieldControl
                                    name="phoneNumber"
                                    type="number"
                                    testIDProps="txtInputPhone"
                                    isArabicLenguage={props.isArabicLenguage}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img style={componentStyles.countryLogoStyleForPhoneInput} src="/assets/images/icons/saudi-arabia-flag-icon.svg" height="50px" width="50px" />&nbsp;+ 966&nbsp;<div style={componentStyles.phoneInputLine} />&nbsp;
                                        </InputAdornment>
                                    }
                                />
                                {touched.phoneNumber && errors.phoneNumber ? (
                                    <Typography color="error" >{errors.phoneNumber}</Typography>
                                ) : null}

                            </Grid>
                            <Grid style={componentStyles.labelStyle}>
                                <label aria-controls="email" >{props.isArabicLenguage ? props.ArabicEmailText : props.emailText}</label><br />
                                <TextFieldControl
                                    isArabicLenguage={props.isArabicLenguage}
                                    disabled={values.email ? true : false}
                                    name="email"
                                    type="email"
                                    testIDProps="txtInputEmail"
                                    endAdornment={
                                        <React.Fragment>
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="email"
                                                    onClick={props.handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    <img style={componentStyles.countryLogoStyleForPhoneInput} src="/assets/images/correct_email.svg" height="25px" width="25px" />
                                                </IconButton>
                                            </InputAdornment>
                                        </React.Fragment>
                                    }
                                />
                                {touched.email && errors.email ? (
                                    <Typography color="error" >{errors.email}</Typography>
                                ) : null}

                            </Grid>
                            <Grid style={componentStyles.labelStyle}>
                                <label aria-label="password" >{props.isArabicLenguage ? props.ArabicPasswordText : props.passwordText}</label>
                                <TextFieldControl
                                    type={props.enablePasswordField ? "password" : "text"}
                                    name="password"
                                    testIDProps="txtInputPassword"
                                    fullWidth={true}
                                    isArabicLenguage={props.isArabicLenguage}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={props.handleClickShowPassword}
                                                edge="end"
                                            >
                                                {props.enablePasswordField ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {touched.password && errors.password ? (
                                    <Typography color="error" >{errors.password}</Typography>
                                ) : null}
                            </Grid>
                            <Grid style={componentStyles.labelStyle}>
                                <div>
                                    <div style={componentStyles.passwordValidationStyleDiv}>
                                        <CheckCircle style={{ color: `${/^(?=.*[A-Z])/.test(values.password) ? "green" : "gray"}` }} />
                                        <Typography>{props.isArabicLenguage ? props.ArabicAtLeastOnceUpperCaseLetterText : props.atLeastOnceUpperCaseLetterText}</Typography>
                                    </div>
                                    <div style={componentStyles.passwordValidationStyleDiv}>
                                        <CheckCircle style={{ color: `${/^(?=.*[a-z])/.test(values.password) ? "green" : "gray"}` }} />
                                        <Typography>{props.isArabicLenguage ? props.ArabicAtLeasetOnceLowerCaseLetterText : props.atLeasetOnceLowerCaseLetterText}</Typography>
                                    </div>
                                    <div style={componentStyles.passwordValidationStyleDiv}>
                                        <CheckCircle style={{ color: `${/^(?=.*[0-9])/.test(values.password) ? "green" : "gray"}` }} />
                                        <Typography>{props.isArabicLenguage ? props.ArabicAtLeaseOneNumaricCharacterText : props.atLeaseOneNumaricCharacterText}</Typography>
                                    </div>
                                    <div style={componentStyles.passwordValidationStyleDiv}>
                                        <CheckCircle style={{ color: `${values.password.length >= 8 ? "green" : "gray"}` }} />
                                        <Typography>{props.isArabicLenguage ? props.ArabicMinimum8CharctorText : props.minimum8CharctorText}</Typography>
                                    </div>
                                </div>
                                <div style={componentStyles.ArabicCheckdivStyle}>

                                    <FormControlLabel
                                        color="primary"
                                        labelPlacement={props.isArabicLenguage ? "start" : "end"}
                                        control={<Checkbox color="primary" checked={values.termAndCondition} onChange={handleChange("termAndCondition")} />}
                                        label={<label>{props.isArabicLenguage ? props.ArabicTermAndConditionFirstText : props.TermAndConditionFirstText} <Link color="primary" underline="always" href="/TermsAndConditions" target="_blank">{props.isArabicLenguage ? props.ArabicTermAndConditionSecondText : props.TermAndConditionSecondText}</Link></label>}
                                    />
                                </div>
                            </Grid>

                            <div style={componentStyles.centerContent}>
                                <Button
                                    // @ts-ignore
                                    style={componentStyles.submitButton}
                                    id="btnforUserSignUP"
                                    title={props.btnTextSignUp}
                                    onClick={() => handleSubmit()}
                                >
                                    {props.isArabicLenguage ? props.ArabicSignUpTitle : props.signUpText}
                                </Button>
                            </div>
                        </div>
                        <div style={componentStyles.centerContent}>
                            <Redirect style={componentStyles.loginLinkStyle} to="/EmailAccountLoginBlock">
                                <p>{props.isArabicLenguage ? props.ArabicAlreadyHaveAcconutTitle : props.alreadyHaveAccountTitle}&nbsp;
                                    <Link color="primary" href="#" underline="always">{props.isArabicLenguage ? props.ArabicLoginText : props.loginText}</Link>
                                </p>
                            </Redirect>
                        </div>
                    </React.Fragment>
                )}
            </Formik>
        </div>
    )
}

export default EmailRegistrationForm