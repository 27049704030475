// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  existingPolicyDataTable: any;
  existingPolicyDataTableMeta: any;
  onGoingPolicyDataTable: any;
  onGoingPolicyDataTableMeta: any;
  customerDetail:any;
  customerContacts:any;
  page: number;
  rowPerPage: number;
  totalPage: number;
  alertMessage: string;
  showAlert: boolean;
  alertType: string;
  isArabicLenguage: boolean;
  anchorEl:any;
  isDialogOpen:boolean;
}

interface SS {
  id: any;
}



export default class CustomerController extends BlockComponent<Props, S, SS> {
  getExistingPoliciesApi: any;
  getOnGoingPoliciesApi: any;
  updateUserDetailsApi: any;
  customerDetialsId:any;
  customerContactId:any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      existingPolicyDataTable: [],
      existingPolicyDataTableMeta: {},
      onGoingPolicyDataTable: [],
      onGoingPolicyDataTableMeta: {},
      customerDetail:{},
      customerContacts:[],
      page: 1,
      rowPerPage: 5,
      totalPage: 1,
      alertMessage: "",
      showAlert: false,
      alertType: "",
      isArabicLenguage: (window.localStorage.getItem("lang") || "en") === "ar" ? true : false,
      anchorEl:"",
      isDialogOpen:false,
    };


    this.checkNeedToShowAlert();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getAlertMessage = (status:string, name:string) => {
    switch (status) {
      case "invite_sent":
        return "Invite sent!"
      case "deactivated":
        return `${name} Account deactivated`
      default:
        return `${name} Account activated`
    }
  }
  getExistingPoliciesApiReceive = (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getExistingPoliciesApi != null &&
      this.getExistingPoliciesApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson && !responseJson.errors && responseJson.data) {
          const policyData = responseJson.data?.map((policy: any, index: number) => ({
            id: policy.id,
            pNo: policy.attributes.policy_id,
            insurer: policy.attributes.insurance_company_id.short_name,
            policyType: policy.attributes.types_of_insurance,
            activationDate: policy.attributes.policy_activation_date,
            expiryDate: policy.attributes.policy_expiry_date,
            issuedBy: policy.attributes.issued_by.name,
          }))

          this.setState({ existingPolicyDataTable: policyData, existingPolicyDataTableMeta: responseJson.meta });
        }
    }
  }
  getOnGoingPoliciesApiReceive = (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getOnGoingPoliciesApi != null &&
      this.getOnGoingPoliciesApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        const policyData = responseJson.data?.map((policy: any) => ({
          id: policy.id,
          requestID: policy.attributes.request_id,
          requestType: policy.attributes.request_type,
          insuranceType: policy.attributes.types_of_insurance,
          assignees: policy.attributes.assignee,
          lastUpdateOn: policy.attributes.updated_at,
          status: policy.attributes.current_status,
          class_type: policy.attributes.class_type
        }))

        this.setState({ onGoingPolicyDataTable: policyData, onGoingPolicyDataTableMeta: responseJson.meta });
      }
    }
  }
  updateUserDetailsApiReceive = (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.updateUserDetailsApi != null &&
      this.updateUserDetailsApi ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
          const status = responseJson.data.attributes.status;
          const name = `${responseJson.data.attributes.first_name} ${responseJson.data.attributes.last_name}`
          const alertMessage = this.getAlertMessage(status,name);
          localStorage.setItem('alertDetail',JSON.stringify({
            showAlert: true,
            alertType: "success",
            alertMessage: alertMessage,
            page:"CustomersDetail"
          }))
          window.location.reload();
      }
    }
  }
  customerDetialsIdReceive = (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.customerDetialsId != null &&
      this.customerDetialsId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {

        localStorage.setItem('selectedCustomer',JSON.stringify(responseJson.data.attributes))
        const details = {
          name:responseJson.data.attributes.name,
          statusId:responseJson.data.attributes.status,
          customerId:responseJson.data.attributes.customer_id,
          industry:responseJson.data.attributes.industry,
          segment:responseJson.data.attributes.segment,
          is_archived:responseJson.data.attributes.is_archived,
        }
        this.setState({customerDetail:details})
      }
    }
  }
  customerContactIdReceive = (message:any) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.customerContactId != null &&
      this.customerContactId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({customerContacts:responseJson.data,totalPage:responseJson.meta.total_page})
      }
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    this.getExistingPoliciesApiReceive(message);
    this.getOnGoingPoliciesApiReceive(message);
    this.updateUserDetailsApiReceive(message)
    this.customerDetialsIdReceive(message);
    this.customerContactIdReceive(message);
  }

  async componentDidMount(){
    const queryParameter: any = new URLSearchParams(window.location.search);
    const userId: string = queryParameter?.get('id');

    if (userId) {
      this.getCustomerDetials(userId)
      this.getCustomerContact(userId)
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ) {

    if (
      prevState.page !== this.state.page ||
      prevState.rowPerPage !== this.state.rowPerPage
    ){
      const queryParameter: any = new URLSearchParams(window.location.search);
      const userId: string = queryParameter?.get('id');
     if(userId){
      this.getCustomerContact(userId)
     }

    }
    if (prevState.showAlert !== this.state.showAlert) {
      setTimeout(() => {
        this.setState({
          showAlert: false,
          alertType: "",
          alertMessage: "",
        });
        localStorage.removeItem('alertDetail')
      }, 10000);
    }
  }

  fetchExistingPolicy = (customerId: number, page: number = 1, perPage: number = 5) => {
    this.getExistingPoliciesApi = this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_policies/policies?expired_policy=true&filter[customer_id]=${customerId}&page=${page}&per_page=${perPage}`,
    });
  }

  async checkNeedToShowAlert() {

    const alertData = await JSON.parse(localStorage.getItem("alertDetail") || "{}");
    const currentPage = window.location.href.split('/').pop();
    if (alertData && ((currentPage === alertData.page) || (currentPage?.includes('CustomersDetail')))) {
      this.setState({
        showAlert: true,
        alertType: alertData.alertType,
        alertMessage: alertData.alertMessage || (this.state.isArabicLenguage ? alertData.arabicTxt : alertData.englishTxt)
      })
    }
  }

  closeAlertMessage = async () => {
    this.setState({
      showAlert: false,
      alertMessage: "",
      alertType: ""
    })

  }

  fetchOnGoingPolicy = (customerId: number, page: number = 1, perPage: number = 5) => {
    this.getOnGoingPoliciesApi = this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_policy_request/policy_requests?filter[customer_ids]=${customerId}&page=${page}&per_page=${perPage}`,
    });
  }
  handleNavigateToRevisionRequest =(id:number, isTable: boolean = false)=>{
    const msg: Message = new Message(
      getName(MessageEnum.NavigationRevisionRequestMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.RevisionRequestIdPayload), JSON.stringify({id:id, isTable: isTable}));
    this.send(msg);
  }

  handleChangePage = (event: ChangeEvent<unknown>, page: number) => {
    this.setState({ page: page });
  };

  handleRowPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ rowPerPage: parseInt(event.target.value) });
    this.setState({ page: 1 });
  };

  showAddCustomerBtn = () => {
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const userRole = userData.role || '';
    if ((userRole !== "Finance") && (userRole !== "finance") && (userRole !== "operations") && (userRole !== "Operations")) {
      return true
    } else {
      return false
    }
  }
  updateUserStatus(contact_id: string, status: string) {
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const apiURL =
      (status === "sendInvite")
        ? "account_block/accounts/send_invite"
        : "account_block/accounts/update_status";
    let bodyData = {};
    if (status === "sendInvite") {
      bodyData = {
        contact_id: contact_id,
      };
    } else {
      bodyData = {
        contact_id: contact_id,
        status: status,
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserDetailsApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    bodyData &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }


    getCustomerDetials = async (customerId: string) => {
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const customerDetailApi = `/bx_block_customer/customers/${customerId}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.customerDetialsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      customerDetailApi
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomerContact = async (customerId: string) => {
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const customerContactApi = `/bx_block_customer/customers/contacts?id=${customerId}&page=${this.state.page}&per_page=${this.state.rowPerPage}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.customerContactId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      customerContactApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  apiCall(data: any) {
    const { contentType, method, endPoint, body } = data;
    const userData = JSON.parse(localStorage.getItem("loginData") || "{}");
    const token = userData.token || "";

    const header = {
      "Content-Type": contentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
}
// Customizable Area End
